import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import { colorScheme } from 'Utilities/colors';
import { t } from 'Utilities/i18n';
import { notEmpty } from 'Utilities/underdash';

export interface RankingDistributionSeries {
  date: number;
  keywords0To3: number;
  keywords4To10: number;
  keywords11To20: number;
  keywords21To50: number;
  keywordsAbove50: number;
  keywordsUnranked: number;
  keywordsTotal: number;
}

export type RankingDistributionFormatedSeries = {
  name: string;
  id: string;
  color: string;
  marker: {
    symbol: string;
    radius: number;
  };
  showInNavigator: boolean;
  turboThreshold: number;
  type: string;
  pointPlacement: string;
  data:
    | {
        x: number;
        y: number;
        totalKeywords: number;
      }[]
    | undefined;
};

const SERIA_ID = 'rankingDistribution';
const KEYWORDS0TO3_SERIA = '1-3';
const KEYWORDS4TO10_SERIA = '4-10';
const KEYWORDS11TO20_SERIA = '11-20';
const KEYWORDS21TO50_SERIA = '21-50';
const KEYWORDS50ANDUP_SERIA = '51-100';
const KEYWORDS0TO3_RESPONSE = 'keywords0To3';
const KEYWORDS4TO10_RESPONSE = 'keywords4To10';
const KEYWORDS11TO20_RESPONSE = 'keywords11To20';
const KEYWORDS21TO50_RESPONSE = 'keywords21To50';
const KEYWORDS50ANDUP_RESPONSE = 'keywordsAbove50';
const KEYWORDSUNRANKED_RESPONSE = 'keywordsUnranked';
const colors = colorScheme.rankDistribution;

const seriesKeys = [
  KEYWORDS0TO3_RESPONSE,
  KEYWORDS4TO10_RESPONSE,
  KEYWORDS11TO20_RESPONSE,
  KEYWORDS21TO50_RESPONSE,
  KEYWORDS50ANDUP_RESPONSE,
  KEYWORDSUNRANKED_RESPONSE,
];
const responseToSeriaMap = {
  [KEYWORDS0TO3_RESPONSE]: () => KEYWORDS0TO3_SERIA,
  [KEYWORDS4TO10_RESPONSE]: () => KEYWORDS4TO10_SERIA,
  [KEYWORDS11TO20_RESPONSE]: () => KEYWORDS11TO20_SERIA,
  [KEYWORDS21TO50_RESPONSE]: () => KEYWORDS21TO50_SERIA,
  [KEYWORDS50ANDUP_RESPONSE]: () => KEYWORDS50ANDUP_SERIA,
  [KEYWORDSUNRANKED_RESPONSE]: () => t('Not ranking'),
};

export const getRankingDistributionSeries = (
  overviewRankingDistribution?: (any | null)[] | null,
  isBaseRankingDistribution?: boolean,
): RankingDistributionSeries[] | undefined => {
  let series;

  if (isBaseRankingDistribution) {
    series = overviewRankingDistribution?.filter(Boolean)?.map((e) => ({
      date: e?.date ?? '',
      keywords0To3: e?.countBase1To3 ?? 0,
      keywords4To10: e?.countBase4To10 ?? 0,
      keywords11To20: e?.countBase11To20 ?? 0,
      keywords21To50: e?.countBase21To50 ?? 0,
      keywordsAbove50: e?.countBase51To100 ?? 0,
      keywordsUnranked: e?.countBaseUnranked ?? 0,
      keywordsTotal: e?.keywords ?? 0,
    }));
  } else {
    series = overviewRankingDistribution?.filter(Boolean)?.map((e) => ({
      date: e?.date ?? '',
      keywords0To3: e?.count1To3 ?? 0,
      keywords4To10: e?.count4To10 ?? 0,
      keywords11To20: e?.count11To20 ?? 0,
      keywords21To50: e?.count21To50 ?? 0,
      keywordsAbove50: e?.count51To100 ?? 0,
      keywordsUnranked: e?.countUnranked ?? 0,
      keywordsTotal: e?.keywords ?? 0,
    }));
  }

  if (series?.length === 1) {
    const fakeFirstDate = cloneDeep(series[0]);
    fakeFirstDate.date = moment(series[0].date).subtract(1, 'day').toDate().getTime();
    series = [fakeFirstDate, series[0]];
  }
  return series;
};

export const formatSeries = (
  data?: RankingDistributionSeries[],
): RankingDistributionFormatedSeries[] => {
  return seriesKeys.map((name, idx) => {
    return {
      name: responseToSeriaMap[name](),
      id: SERIA_ID + idx,
      color: colors[idx % colors.length],
      marker: {
        symbol: 'circle',
        radius: 3,
      },
      showInNavigator: true,
      turboThreshold: 500000,
      type: 'areaspline',
      pointPlacement: 'between',
      data: data?.filter(notEmpty)?.map((dataPoint) => ({
        x: dataPoint.date,
        y: dataPoint[name as keyof typeof dataPoint] || 0,
        totalKeywords: dataPoint.keywordsTotal,
      })),
    };
  });
};

export const getCsvData = (series) => () => {
  const header = `Date,${series.map((s) => s.name).join(',')},Total keywords` + '\n';
  let i;
  let rows = '';
  for (i = 0; i < series[0].data.length; i++) {
    const reverseIdx = series[0].data.length - 1 - i;
    rows += `${moment.unix(series[0].data[reverseIdx].x / 1000).format('YYYY-MM-DD')}`;
    series.map((s) => (rows += `,${s.data[reverseIdx].y}`));
    rows += `,${series[0].data[reverseIdx].totalKeywords}`;
    rows += '\n';
  }
  return header + rows;
};
