import { useCallback, useEffect, useState } from 'react';
import { useFocusWithin } from '@mantine/hooks';

// eslint-disable-next-line import/no-unused-modules
export interface UseKeyboardFocusWithinOptions {
  onFocus?: (event: FocusEvent) => void;
  onBlur?: (event: FocusEvent) => void;
}

export function useKeyboardFocusWithin({ onBlur, onFocus }: UseKeyboardFocusWithinOptions = {}) {
  const [isKeyboardFocused, setIsKeyboardFocused] = useState(false);
  const [wasMouseUsed, setWasMouseUsed] = useState(false);

  const handleMouseMove = useCallback(() => {
    setWasMouseUsed(true);
    setIsKeyboardFocused(false);
  }, []);

  const handleKeyDown = useCallback(() => {
    setWasMouseUsed(false);
  }, []);

  const handleFocus = useCallback(
    (event: FocusEvent) => {
      if (!wasMouseUsed) {
        setIsKeyboardFocused(true);
        onFocus?.(event);
      }
    },
    [wasMouseUsed, onFocus],
  );

  const handleBlur = useCallback(
    (event: FocusEvent) => {
      setIsKeyboardFocused(false);
      onBlur?.(event);
    },
    [onBlur],
  );

  const { ref, focused } = useFocusWithin({
    onFocus: handleFocus,
    onBlur: handleBlur,
  });

  useEffect(() => {
    if (ref.current) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('keydown', handleKeyDown);

      return () => {
        window.removeEventListener('mousemove', handleMouseMove);
        window.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [handleMouseMove, handleKeyDown, ref]);

  return {
    ref,
    focused: focused && isKeyboardFocused,
  };
}
