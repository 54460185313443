import { useMemo } from 'react';
import { Checkbox, Flex, Group } from '@mantine/core';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import DemoContentBadge from 'Components/DemoContent/DemoContentBadge';
import AccText from 'Components/Text/AccText';
import {
  useCheckboxSelection,
  useToggleCheckbox,
} from 'Pages/Domains/support/useCheckboxSelection';
import { t } from 'Utilities/i18n';
import { useDomainContext } from './DomainContext';
import styles from '../domainCard.module.scss';

const CardFooter = () => {
  const { selectId, isDemoDomain } = useDomainContext();
  const { checked, disabled } = useCheckboxSelection(selectId);
  const toggleCheckbox = useToggleCheckbox(selectId);

  const Comp = useMemo(
    () => (
      <Flex px="lg" py={8} className={styles.domainFooter}>
        <AccTooltip tooltip={t('Max number of selected domains reached.')} disable={!disabled}>
          <Group align="center" justify="space between" onClick={toggleCheckbox} w="100%">
            <Group gap="xs">
              <Checkbox
                color="snorlax"
                checked={checked}
                classNames={{ input: styles.checkbox }}
                disabled={disabled}
                readOnly
              />
              <AccText size="xs" lh="xl" c="inherit" className={styles.checkboxLabel}>
                {t('Compare')}
              </AccText>
            </Group>
            {isDemoDomain && <DemoContentBadge />}
          </Group>
        </AccTooltip>
      </Flex>
    ),
    [disabled, toggleCheckbox, checked],
  );
  return Comp;
};

export default CardFooter;
