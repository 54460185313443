import React from 'react';
import { Menu } from '@mantine/core';
import { IconDotsVertical, IconTrash } from '@tabler/icons-react';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import styles from 'Pages/Keywords/Overview/components/KebabMenu/kebab-menu.module.scss';
import { t } from 'Utilities/i18n/index';

export const KpiBoxDropdown = ({ removeChart }) => {
  return (
    <Menu shadow="md" position={'right-start'}>
      <AccTooltip tooltip={t('More options')}>
        <Menu.Target>
          <AccActionIcon
            className={styles.kebabSelected}
            data-no-dnd="true"
            variant="subtle"
            mr="-6px"
          >
            <IconDotsVertical size={16} />
          </AccActionIcon>
        </Menu.Target>
      </AccTooltip>
      <Menu.Dropdown data-no-dnd="true">
        <Menu.Item leftSection={<IconTrash size={14} />} onClick={removeChart}>
          {t('Remove from Dashboard')}
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};
