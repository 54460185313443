import { useState } from 'react';
import { useNavigate } from 'react-router';
import { Flex } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';
import AccessControl from 'Components/AccessControl';
import { HomeButton, LastBreadcrumbChild } from 'Components/Breadcrumbs/Breadcrumbs';
import Filters from 'Components/Filters';
import PageHeaderContainer from 'Components/PageHeaderContainer';
import { useModal } from 'Hooks/base/useModal';
import * as Actions from 'Pages/Layout/ActionsMenu/Actions';
import ActionbarContainer from 'Pages/Layout/ActionsMenu/components/ActionbarContainer';
import { PeriodFilterContainer } from 'Pages/Layout/ActionsMenu/components/PeriodFilterContainer';
import { t } from 'Utilities/i18n';
import reusableStyles from 'css/reusable-styles.module.scss';
import GroupsTable from './GroupsTable/GroupsTable';

const GroupsPage = () => {
  const [dataKey, setDataKey] = useState(1);
  const updateTable = () => {
    setTimeout(() => setDataKey((key) => key + 1), 2000);
  };

  const { showModal } = useModal();
  const navigate = useNavigate();

  const handleAddGroup = () => {
    showModal({
      modalType: 'AddGroup',
      modalTheme: 'light',
      modalProps: {
        refetch: updateTable,
      },
    });
  };

  return (
    <>
      <Flex justify="space-between">
        <PageHeaderContainer breadcrumbs>
          <HomeButton navigate={() => navigate('/domains')} />
          <IconChevronRight />
          <LastBreadcrumbChild>{t('Groups')}</LastBreadcrumbChild>
        </PageHeaderContainer>
        <PeriodFilterContainer />
      </Flex>
      <Filters />
      <ActionbarContainer>
        <AccessControl withSuperuserPermission>
          <Actions.AddAction key="add" label={t('Add group')} onClick={handleAddGroup} />
        </AccessControl>
      </ActionbarContainer>
      <div className={reusableStyles.paper}>
        <GroupsTable dataKey={dataKey} updateTable={updateTable} />
      </div>
    </>
  );
};

export default GroupsPage;
