import { useEffect, useRef } from 'react';
import { usePublicOverviewHistoryGraphsQuery } from 'Ghql';
import { AverageRankChartContent } from 'Pages/Keywords/Overview/components/HistoryCharts/AverageRank/AverageRankChartContent';
import { getAverageRankSeries } from 'Pages/Keywords/Overview/components/HistoryCharts/AverageRank/helpers';
import { FilterBase } from 'Types/Filter';

type AvgRankChartProps = {
  viewkey: string;
  domainSlug: string;
  filters: FilterBase[];
};

export const PublicAvgRankChart = (props: AvgRankChartProps) => {
  const { domainSlug, viewkey, filters } = props;
  const periodFilter = filters?.find((filter) => filter.attribute === 'period');
  const { data, loading } = usePublicOverviewHistoryGraphsQuery({
    variables: { filters, viewkey, domainSlug, charts: ['average_rank', 'ranking_distribution'] },
    skip: !periodFilter || !filters.length,
  });
  const chartSeries = getAverageRankSeries(data?.graphs?.publicOverviewHistoryGraphs);
  const tableKey = useRef<string>(JSON.stringify(periodFilter?.value || {}));

  // Update the key to force update when the period filter changes
  useEffect(() => {
    tableKey.current = JSON.stringify(periodFilter?.value || {});
  }, [JSON.stringify(periodFilter?.value || {})]);

  return (
    <AverageRankChartContent
      key={tableKey.current}
      chartSeries={chartSeries}
      loading={loading}
      isGroupView={false}
      isEmptyGroup={false}
      onMissingDataOverlayClick={undefined}
      navigateToKeywordslistFromChart={false}
      showFakeData={false}
      includeKebabMenu={false}
      boardControls={{
        removeChart: null,
        disabledDnd: true,
      }}
    />
  );
};
