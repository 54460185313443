import { useSelector } from 'react-redux';
import cn from 'classnames';
import AccButton from 'Components/AccButton/AccButton';
import withFiltersEditor from 'Components/HOC/withFiltersEditor';
import type { FiltersEditorProps } from 'Components/HOC/withFiltersEditor';
import { NonRequiredFiltersSelector } from 'Selectors/FiltersSelector';
import type { FilterBase } from 'Types/Filter';
import { t } from 'Utilities/i18n';
import './table-empty-state.scss';

type Props = {
  list: any;
  title?: string;
  subTitle?: string;
  noDataWithFiltersTitle?: string;
  noDataWithFiltersSubTitle?: string;
  onBtnClick?: (...args: Array<any>) => any;
  btnTitle?: string;
  filtersPossible?: boolean;
  style: Record<string, any>;
  fullWidth?: boolean;
  btnDisable?: boolean;
  btnTooltip?: string;
} & FiltersEditorProps;

const TableEmptyState = (props: Props) => {
  const {
    list,
    title,
    subTitle,
    noDataWithFiltersTitle,
    noDataWithFiltersSubTitle,
    onBtnClick,
    btnTitle,
    filtersPossible,
    style,
    fullWidth,
    btnDisable,
    btnTooltip,
    filtersEditor,
  } = props;
  const filters: Array<FilterBase> = useSelector(NonRequiredFiltersSelector);
  const renderButton = (onClick: ((...args: Array<any>) => any) | void, label: string | void) => {
    if (!onClick) return null;
    return (
      <AccButton
        variant="primary"
        onClick={onClick}
        disabled={btnDisable}
        tooltip={btnTooltip}
        tooltipWrapperProps={{ mx: 'auto' }}
      >
        {(label ?? '') as any}
      </AccButton>
    );
  };
  const renderNoData = () => {
    return (
      <div className={cn('table-empty-state', { fullWidth })} style={style}>
        <div className="title">{title}</div>
        <div className="sub-title">{subTitle}</div>
        {renderButton(onBtnClick, btnTitle)}
      </div>
    );
  };

  const resetFilter = () => {
    filtersEditor.resetFilters();
  };

  const renderNoDataFilters = () => {
    return (
      <div className={cn('table-empty-state', { fullWidth })} style={style}>
        <div className="title">{noDataWithFiltersTitle || t('No data')}</div>
        <div className="sub-title">
          {noDataWithFiltersSubTitle ||
            t(
              'There is currently no data to display, this could be due to your active filters. Clear your filters and try again.',
            )}
        </div>
        {renderButton(resetFilter, t('Clear filters'))}
      </div>
    );
  };
  if (list?.length) return null;
  if (filtersPossible && filters?.length) return renderNoDataFilters();
  return renderNoData();
};

export default withFiltersEditor(TableEmptyState) as any;
