import { useState } from 'react';
import { useIntercom } from 'react-use-intercom';
import { Center } from '@mantine/core';
import AccAlert from 'Components/AccAlert/AccAlert';
import AccButton from 'Components/AccButton/AccButton';
import AccText from 'Components/Text/AccText';
import { t } from 'Utilities/i18n';
import IntercomIcon from 'icons/intercom-icon.svg?inline';
import styles from './crossSellBanner.module.scss';

type Props = {
  title: string;
  children: React.ReactNode;
  crossSellId: string;
};

const CrossSellBanner = ({ title, children, crossSellId }: Props) => {
  const [bannerClosed, setBannerClosed] = useState<boolean>(
    sessionStorage.getItem(crossSellId) === 'true',
  );
  const { hide, show, isOpen } = useIntercom();

  if (bannerClosed) return null;

  const onClose = () => {
    setBannerClosed(true);
    sessionStorage.setItem(crossSellId, 'true');
  };
  const onSupportClick = () => {
    isOpen ? hide() : show();
  };
  return (
    <AccAlert
      title={title}
      withCloseButton
      severity="info"
      variant="standard"
      mb="lg"
      onClose={onClose}
      className={styles.crossSellBanner}
    >
      <AccText size="sm" mb="0">
        {children}
      </AccText>
      <AccButton
        className={styles.contactSupportBtn}
        variant="primary"
        onClick={onSupportClick}
        leftSection={
          <Center w={14} h={14}>
            <IntercomIcon />
          </Center>
        }
      >
        {isOpen ? t('Close support') : t('Contact support')}
      </AccButton>
    </AccAlert>
  );
};

export default CrossSellBanner;
