import cn from 'classnames';
import StopImpersonating from 'Components/ImpersonateDropdown/StopImpersonating';
import { useUser } from 'Hooks/data/user/useUser';
import styles from './top-navbar.module.scss';

const TopNavbar = () => {
  const user = useUser();

  return (
    <nav className={cn(styles.mainNavbar)}>{user?.isImpersonating && <StopImpersonating />}</nav>
  );
};

export default TopNavbar;
