import cn from 'classnames';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { getFaviconByDomain } from 'Utilities/favicon';
import styles from './domainFavicon.module.scss';

const iconSize = {
  xs: 16,
  md: 16,
  lg: 24,
};
export const DomainFavicon = ({
  domain,
  size = 'md',
  disabledTooltip = false,
}: {
  domain?: string | null;
  size?: 'xs' | 'md' | 'lg';
  disabledTooltip?: boolean;
}) => {
  if (!domain) {
    return null;
  }
  const faviconUrl = getFaviconByDomain(domain, iconSize[size]);
  const favicon = (
    <img
      src={faviconUrl}
      alt={domain ?? ''}
      className={cn({
        [styles.xs]: size === 'xs',
        [styles.md]: size === 'md',
        [styles.lg]: size === 'lg',
      })}
    />
  );
  if (disabledTooltip) return favicon;
  return (
    <AccTooltip tooltip={domain} disable={!domain}>
      {favicon}
    </AccTooltip>
  );
};
