import * as React from "react";
const SvgCtrMax = (props) => /* @__PURE__ */ React.createElement("svg", { xmlSpace: "preserve", width: 18.918, height: 21.58, viewBox: "0 0 18.918 21.58", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M11.184 8.414h1.425v-3.31c0-.75-.129-1.853-.204-2.602h.043l.632 1.863 1.232 3.331h.91l1.221-3.33.643-1.864h.053c-.085.75-.203 1.853-.203 2.602v3.31h1.446V.477h-1.757l-1.317 3.717c-.171.493-.31 1.017-.482 1.52h-.054c-.16-.503-.31-1.027-.482-1.52L12.941.477h-1.757z", "aria-label": "M", style: {
  fontWeight: 700,
  fontSize: "10.7104px",
  lineHeight: 1.25,
  fontFamily: "'Noto Sans CJK TC'",
  InkscapeFontSpecification: "'Noto Sans CJK TC, Bold'",
  strokeWidth: 0.267759
} }), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", { id: "ctr_max_svg__a", clipPathUnits: "userSpaceOnUse" }, /* @__PURE__ */ React.createElement("path", { d: "M1 2h22v25H1Zm12.97 13.358v7.795h8.726v-7.795Z", className: "ctr_max_svg__powerclip", style: {
  strokeWidth: 1.75748
} })), /* @__PURE__ */ React.createElement("clipPath", { id: "ctr_max_svg__b", clipPathUnits: "userSpaceOnUse" }, /* @__PURE__ */ React.createElement("path", { d: "M-1-3h24v18H-1Zm15.574 5.108v8.257h8.163V2.108Z", className: "ctr_max_svg__powerclip", style: {
  fill: "#ff0",
  strokeWidth: 1.6548
} }))), /* @__PURE__ */ React.createElement("g", { style: {
  fontSize: "10.6667px",
  lineHeight: 1.25
} }, /* @__PURE__ */ React.createElement("path", { d: "M17.769 17.733q0 .97-.502 1.493-.49.523-1.29.523-.395 0-.726-.128-.33-.139-.565-.395-.235-.256-.373-.629-.128-.384-.128-.864 0-.981.49-1.493.502-.523 1.302-.523t1.29.523q.502.512.502 1.493zm-1.014 0q0-.565-.213-.843-.203-.288-.565-.288-.363 0-.566.288-.202.278-.202.843t.202.853q.203.278.566.278.362 0 .565-.278.213-.288.213-.853zm3.179-1.856h1.227l-4.171 7.392h-1.227zm2.816 5.547q0 .97-.501 1.493-.491.523-1.291.523-.395 0-.725-.139-.331-.128-.566-.384-.234-.256-.373-.63-.128-.383-.128-.863 0-.982.49-1.494.502-.522 1.302-.522t1.29.522q.502.512.502 1.494zm-1.013 0q0-.566-.214-.843-.202-.288-.565-.288-.363 0-.565.288-.203.277-.203.843 0 .565.203.853.202.277.565.277.363 0 .565-.277.214-.288.214-.853z", "aria-label": "%", style: {
  fontWeight: 500,
  fontFamily: "Ubuntu",
  InkscapeFontSpecification: "'Ubuntu Medium'"
}, transform: "translate(-4 -2)" })), /* @__PURE__ */ React.createElement("g", { clipPath: "url(#ctr_max_svg__a)", transform: "translate(-4 -2)" }, /* @__PURE__ */ React.createElement("path", { d: "M17 22h-7c-.379 0-.725-.214-.895-.553l-3-6A1 1 0 0 1 7 14h2V8a1 1 0 0 1 1.316-.949l3 1c.409.137.684.519.684.949v4.219l3.242.811A1 1 0 0 1 18 15v6a1 1 0 0 1-1 1zm-6.382-2H16v-4.219l-3.242-.81A1.002 1.002 0 0 1 12 14V9.721l-1-.333V15a1 1 0 0 1-1 1H8.618Z" })), /* @__PURE__ */ React.createElement("g", { clipPath: "url(#ctr_max_svg__b)", transform: "translate(-4 -2)" }, /* @__PURE__ */ React.createElement("path", { d: "M4 8h3v2H4Zm2.757-4.656L8.88 5.465 7.465 6.88 5.343 4.758zM10 2h2v3h-2zm6.656 2.758L14.535 6.88 13.12 5.465l2.121-2.12zM15 8h3v2h-3z" })));
export default SvgCtrMax;
