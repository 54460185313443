import React, { useState } from 'react';
import { selectors } from 'react-virtualized-tree';
import { Group } from '@mantine/core';
import cn from 'classnames';
import { TreeNode } from 'Components/Table/TreeTable';
import { useIsSelected, useSelectedNodes } from 'Hooks/useSelectedNodes';
import { SitemapNode } from 'Pages/SiteMapping/support/types';
import { getIndentClassNames } from 'Utilities/Table/Tree/treeUtils';
import { customPropsIsComparison } from 'Utilities/compare';
import { t } from 'Utilities/i18n';
import { isOtherNode } from '../../support/helpers';
import { OtherRow } from './OtherRow';
import styles from './tree-table.module.scss';

interface TableRowProps {
  updateSelectedNode: (
    node: TreeNode,
    event?: React.MouseEvent | React.KeyboardEvent | React.ChangeEvent,
  ) => void;
  rootNode: TreeNode;
  expandDataKey: string;
  RowContentComponent: (props) => JSX.Element;
  gridTableRows: string;
  disableRowSelect?: boolean;
  onDragEnd?: (event: React.DragEvent<HTMLDivElement>, text?) => void;
  [key: string]: any;
}

export interface TableRowChildProps extends TableRowProps {
  hideConnectors?: string[];
  onChange?: (patch: any) => void;
}

/**Recursive component for rows in the TreeTable
 * If the node has children, it will render a TableRowChild component for each child
 * */
export const TableRowChild = React.memo(
  (props: TableRowChildProps): JSX.Element => {
    const {
      rootNode,
      expandDataKey,
      updateSelectedNode,
      RowContentComponent,
      gridTableRows,
      disableRowSelect,
      onChange,
      index,
      onDragEnd,
      handleOnToggle,
    } = props;
    const { selectedNodes } = useSelectedNodes();
    const [dragStartY, setDragStartY] = useState(0);
    const isSelected = useIsSelected(rootNode[expandDataKey], expandDataKey);

    const { isExpanded } = selectors.getNodeRenderOptions(rootNode as any);

    const handleToggleExpand = (e: any) => {
      e.stopPropagation();
      handleOnToggle?.(rootNode);
      onChange?.({ ...selectors.updateNode(rootNode as any, { expanded: !isExpanded }), index });
    };

    const resultClassName = getIndentClassNames(rootNode, isExpanded);

    if (isOtherNode(rootNode as SitemapNode)) {
      return (
        <OtherRow
          // eslint-disable-next-line react/no-array-index-key
          key={`${index}-${rootNode[expandDataKey]}`}
          rootNode={rootNode}
          selectedNodes={selectedNodes}
          updateSelectedNode={updateSelectedNode}
          className={resultClassName}
          gridTableRows={gridTableRows}
          isSelected={isSelected}
        />
      );
    }

    return (
      <Group
        data-testid="TableRowChild"
        data-scroll={rootNode?.name}
        tabIndex={0}
        aria-selected={isSelected}
        onClick={disableRowSelect ? undefined : (event) => updateSelectedNode(rootNode, event)}
        onKeyDown={disableRowSelect ? undefined : (event) => updateSelectedNode(rootNode, event)}
        wrap="nowrap"
        className={cn(styles.fullRow, resultClassName, { [styles.selected]: isSelected })}
        style={{ gridTemplateColumns: gridTableRows }}
        draggable={!!onDragEnd}
        onDragStart={(e) => {
          setDragStartY(e.clientY);
          e.dataTransfer.setDragImage(document.createElement('span'), 0, 0);
        }}
        onDragEnd={(e) => {
          if (Math.abs(e.clientY - dragStartY) > 30) {
            onDragEnd?.(
              e,
              t(
                'It looks like you\'re trying to organize your tags. Do you want to enter the folder editing mode?',
              ),
            );
          }
        }}
      >
        <RowContentComponent
          rootNode={rootNode}
          isOpen={isExpanded}
          isSelected={isSelected}
          handleToggleExpand={handleToggleExpand}
          updateSelectedNode={updateSelectedNode}
          className={resultClassName}
          hideConnectors={(rootNode as any)?.hideConnectors}
          expandDataKey={props.expandDataKey}
          onDragEnd={onDragEnd}
        />
      </Group>
    );
  },
  /**
   * To display actual data - we need only re-render once rootNode changed, skipping children to avoid expensive comparison on long list.
   */
  customPropsIsComparison([
    // To display actual data - we need only re-render once rootNode changed, skipping children to avoid expensive comparison on long list.
    (e) => ({ ...e, children: null }),
    'expandDataKey',
    'gridTableRows',
    'disableRowSelect',
  ]),
);
TableRowChild.displayName = 'TableRowChild';
