import React, { useContext } from 'react';
import { OptimizationConfig } from '../../types';
import { OptimizationStoreType } from '../OptimizationStore';

export interface OptimizationContextType {
  enabled: boolean;
  config: OptimizationConfig | false | undefined;
  store: OptimizationStoreType;
}

export const OptimizationStoreContext = React.createContext<OptimizationContextType | null>(
  null,
) as React.Context<OptimizationContextType | null>;

export const useOptimizationContext = (): OptimizationContextType | null => {
  return useContext(OptimizationStoreContext);
};
