import { useState } from 'react';
import { usePasswordPerformPasswordResetMutation } from 'Ghql';
import { WithRouter, withRouter } from 'Utilities/Router';
import { extractFormErrors } from '../../../Hooks/Graphql/helpers';
import AuthPageContainer from '../Shared/AuthPageContainer/AuthPageContainer';
import { PasswordChangeForm, PasswordChangeFormValues } from './components/PasswordChangeForm';
import { SuccessPasswordChange, WrongLinkBlock } from './components/PasswordChangeStatusBlocks';

type Props = WithRouter;

/**
 * Page to set new password using token and uuidb64
 * @example url: 'app/resetpassword/Nw/5op-ddb4f23ca00dc26a571c/'
 */
function PasswordSetupPage(props: Props) {
  const [passChanged, setIsPassChanged] = useState(false);
  const [fetchPasswordUpdate] = usePasswordPerformPasswordResetMutation();
  const { uidb: uidb64, token } = props.match.params || {};

  const changePassword = async (form: PasswordChangeFormValues) => {
    const res = await fetchPasswordUpdate({
      variables: {
        input: form,
      },
    });
    const formErrors = extractFormErrors(res);

    if (formErrors) {
      return formErrors;
    }

    setIsPassChanged(true);
  };

  const invalidLink = !uidb64 || !token;
  return (
    <AuthPageContainer>
      {invalidLink ? (
        <WrongLinkBlock />
      ) : passChanged ? (
        <SuccessPasswordChange />
      ) : (
        <PasswordChangeForm
          onSubmit={changePassword}
          initialValues={{
            uidb64,
            token,
            newPassword1: '',
            newPassword2: '',
          }}
        />
      )}
    </AuthPageContainer>
  );
}

export default withRouter(PasswordSetupPage);
