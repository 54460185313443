import { Divider } from '@mantine/core';
import { useTableStore } from 'Components/DataTable';
import { useDisplayCurrency } from 'Hooks/displayCurrency';
import { useFilterAndOrderByWithVariables } from 'Hooks/useFilterAndOrderByWithVariables';
import useKeyword from 'Hooks/useKeyword';
import * as Actions from 'Pages/Layout/ActionsMenu/Actions';
import { DomainInfo, DomainTypeChoices } from 'Query';
import { TableID } from 'Types/Table';
import { EventName, useMixpanel } from 'Utilities/Analytics/mixpanel';
import { t, tct } from 'Utilities/i18n';
import MoreActions from './MoreActions';
import MoreRemoveActions from './MoreRemoveActions';
import { WithKeywordActionMenuDataProps } from './withKeywordActionMenuData';

type Props = {
  tableName: TableID;
  domainInfo?: DomainInfo;
  isAllSelected: boolean;
  totalResults: number;
  manualSelectedKeywords: number[];
  showCompare?: boolean;
  withCount: boolean;
  withDivider?: boolean;
  showActions: boolean;
  /*darkTheme colors on action buttons and menus */
  darkTheme?: boolean;
};

const BulkActions = ({
  tableName,
  manualSelectedKeywords,
  isAllSelected,
  totalResults,
  domainId,
  filters,
  actions,
  totalSelected,
  showCompare,
  domainInfo,
  withCount = true,
  showActions,
  withDivider = false,
  darkTheme = false,
}: Props & WithKeywordActionMenuDataProps) => {
  const tableStore = useTableStore(tableName);
  const { displayCurrency } = useDisplayCurrency(true);
  const filterAndOrderByWithVariables = useFilterAndOrderByWithVariables(tableName);
  const trackEvent = useMixpanel();
  const { showModal } = actions;
  const {
    updateKeywordsStarred,
    updateKeywordsRefresh,
    showLandingPageModal,
    showRemoveTagsModal,
    showDeleteKeywordsModal,
    showAddNoteModal,
    showAddTagsModal,
    showEditKeywordsModal,
    showMoveKeywordsModal,
    showCopyToClipboardModal,
    showDuplicateModal,
    showCompareModal,
  } = useKeyword({ showModal });

  const { domainType } = domainInfo || {};
  const isRefreshDisabled = domainType === DomainTypeChoices.A_7;

  if (!showActions || !domainId) return null;
  return (
    <>
      <MoreActions
        totalSelected={totalSelected}
        selectedKeywords={manualSelectedKeywords}
        isAllSelected={isAllSelected}
        domainId={domainId}
        totalResults={totalResults}
        darkTheme={darkTheme}
        withCount={withCount}
        updateKeywordsRefresh={
          isRefreshDisabled
            ? undefined
            : () => {
                tableStore?.updateSelectedItems((data) => {
                  data.updatingKeyword = true;
                });
                updateKeywordsRefresh({
                  keywords: manualSelectedKeywords,
                  isAllSelected,
                  isDisabled: false,
                  filters,
                });
                trackEvent(EventName.RefreshKeywordSingle, {
                  numberOfKeywords: manualSelectedKeywords?.length ?? 0,
                  source: 'MoreActions',
                });
              }
        }
        showAddNoteModal={() =>
          showAddNoteModal({
            keywords: manualSelectedKeywords,
            isAllSelected,
            domainId,
            filters,
          })
        }
        showAddTagsModal={() =>
          showAddTagsModal({
            keywords: manualSelectedKeywords,
            isAllSelected,
            filters,
          })
        }
        updateKeywordsStarred={() => {
          tableStore?.updateSelectedItems((data) => {
            data.starred = true;
          });
          updateKeywordsStarred({
            keywords: manualSelectedKeywords,
            isAllSelected,
            filters,
            starred: true,
          });
        }}
        showEditKeywordsModal={() =>
          showEditKeywordsModal({
            keywords: manualSelectedKeywords,
            isAllSelected,
            filters,
            domainId: domainId.toString(),
            numResults: totalResults,
          })
        }
        showLandingPageModal={() =>
          showLandingPageModal({
            keywords: manualSelectedKeywords,
            isAllSelected,
            filters,
            domainId,
          })
        }
        showDuplicateModal={() =>
          showDuplicateModal({
            keywords: manualSelectedKeywords,
            isAllSelected,
            filters,
            domainId,
            numResults: totalResults,
          })
        }
        showMoveKeywordsModal={() =>
          showMoveKeywordsModal({
            keywords: manualSelectedKeywords,
            isAllSelected,
            filters,
            domainId,
          })
        }
        showCopyToClipboardModal={() =>
          showCopyToClipboardModal({
            tableStore,
            filterAndOrderByWithVariables,
            filters,
            displayCurrency,
          })
        }
      />
      {withDivider && <Divider h={24} my="auto" orientation="vertical" />}
      <MoreRemoveActions
        selectedKeywords={manualSelectedKeywords}
        isAllSelected={isAllSelected}
        totalResults={totalResults}
        withCount={withCount}
        darkTheme={darkTheme}
        showRemoveTagsModal={() =>
          showRemoveTagsModal({
            keywords: manualSelectedKeywords,
            isAllSelected,
            filters,
            domainId,
          })
        }
        showDeleteKeywordsModal={() => {
          showDeleteKeywordsModal({
            keywords: manualSelectedKeywords,
            isAllSelected,
            filters,
            onAction: () => {
              tableStore?.deleteSelectedItems();
            },
          });
        }}
        updateKeywordsStarred={() => {
          tableStore?.updateSelectedItems((data) => {
            data.starred = false;
            // version used to update state of components that don't update store and post only to backend
            data.version = (data.version || 0) + 1;
          });
          updateKeywordsStarred({
            keywords: manualSelectedKeywords,
            isAllSelected,
            filters,
            starred: false,
          });
        }}
      />
      {withDivider && <Divider h={24} my="auto" orientation="vertical" />}
      {showCompare && (
        <>
          <Actions.CompareAction
            darkTheme={darkTheme}
            key="compare"
            label={
              withCount ? tct('Compare [amount]', { amount: `(${totalSelected})` }) : t('Compare')
            }
            onClick={() =>
              showCompareModal({
                keywords: manualSelectedKeywords,
              })
            }
          />
          {withDivider && <Divider h={24} my="auto" orientation="vertical" />}
        </>
      )}
    </>
  );
};

export default BulkActions;
