import { useCallback, useEffect, useMemo, useState } from 'react';
import { usePrevious } from '@mantine/hooks';
import * as Sentry from '@sentry/react';
import { useModal } from 'Hooks/base/useModal';
import { initLanguage, t } from 'Utilities/i18n';
import { CONNECTED, FORCE_RELOAD, useSubscribeToTopic } from 'Utilities/websocket';
import { useIsLoadingInitialState } from '../../../Hooks/data/user/useUser';
import { StoreUserType } from '../../../Types/Store';
import { redirectToRoot, removeInitialLoader } from '../../../Utilities/underdash';
import { ModalTypes } from '../../Modal/Content';
import { setDefaultLanguage } from '../helpers';
import { connectedCallback } from './services';

type ModalConfigItem = {
  modal: {
    modalType: ModalTypes;
    modalProps: Record<string, unknown>;
  };
  getNextModalProps: (openModal: Function) => object;
};

export const useInitModals = () => {
  const isLoadingInitialState = useIsLoadingInitialState();
  const { hideModal, showModal } = useModal();

  const isIE =
    navigator?.userAgent?.includes?.('MSIE') || navigator?.appVersion?.includes?.('Trident/');
  const modals: ModalConfigItem[] = [];

  if (isIE) {
    modals.push({
      modal: {
        modalType: 'Confirmation',
        modalProps: {
          title: t('Warning: Internet Explorer usage'),
          description: t(
            'You could see multiple issues while using this app in IE, please use other browser to avoid negative user experience.',
          ),
          lockDuration: 0,
          confirmLabel: t('OK'),
          showCancelLabel: false,
        },
      },
      getNextModalProps: (openNextModal) => ({
        action: openNextModal,
        cancelAction: openNextModal,
      }),
    });
  }

  const openModalChain = (modalObject?: ModalConfigItem, modalObjects?: ModalConfigItem[]) => {
    if (!modalObject) {
      hideModal();
      return;
    }

    showModal({
      ...modalObject?.modal,
      modalProps: {
        ...modalObject?.modal.modalProps,
        ...modalObject?.getNextModalProps?.(() => {
          openModalChain(modalObjects?.[0], modalObjects?.slice(1));
        }),
      },
    });
  };

  useEffect(() => {
    if (!isLoadingInitialState && modals?.[0]) {
      openModalChain(modals?.[0], modals?.slice(1)?.filter(Boolean) ?? []);
    }
  }, [isLoadingInitialState]);
};

export const useAppInitSubscriptions = (isLoadingInitialState: boolean) => {
  const { showModal } = useModal();

  const subscriptions = useMemo(() => {
    return isLoadingInitialState
      ? []
      : [
          {
            action: CONNECTED,
            cb: connectedCallback(showModal),
          },
          {
            action: FORCE_RELOAD,
            cb: redirectToRoot,
          },
        ];
  }, [!isLoadingInitialState]);

  useEffect(() => {
    if (!isLoadingInitialState) {
      removeInitialLoader();
    }
  }, [isLoadingInitialState]);
  useSubscribeToTopic(subscriptions);
};

export const useLanguageListener = (language?: string) => {
  const [version, setVersion] = useState(0);
  const updatePage = useCallback(() => setVersion((v) => v + 1), []);
  const previousLanguage = usePrevious(language);

  // Called once on initial render, emulate constructor
  useState(() => initLanguage());

  useEffect(() => {
    if (language !== previousLanguage && language && previousLanguage) {
      updatePage();
      setDefaultLanguage(language);
    }
  }, [language, previousLanguage]);
  return [version as number, updatePage] as [number, () => void];
};

export const useSentryChangeListener = (user: StoreUserType) => {
  const prevEmail = usePrevious(user?.email);

  useEffect(() => {
    if (user.email !== prevEmail) {
      Sentry.setUser({
        email: user.email ?? '',
        id: user.id ?? '',
      });
    }
  }, [user]);
};
