import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import Skeleton from 'Components/Skeleton';
import SkeletonTableBody from 'Components/Skeleton/TableBody';
import { CountrylocaleFragment, useCountrylocalesQuery } from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import { t } from 'Utilities/i18n';
import { notEmpty } from 'Utilities/underdash';

const LoadingSkeleton = () => (
  <SkeletonTableBody>
    <Skeleton
      linesConfig={[
        {
          type: 'text',
          options: {
            width: '80%',
            marginBottom: '10px',
          },
        },
      ]}
    />
    <Skeleton
      linesConfig={[
        {
          type: 'text',
          options: {
            width: '55%',
            marginBottom: '10px',
          },
        },
      ]}
    />
    <Skeleton
      linesConfig={[
        {
          type: 'text',
          options: {
            width: '40%',
            marginBottom: '10px',
          },
        },
      ]}
    />
  </SkeletonTableBody>
);

const Header = () => (
  <thead>
    <tr>
      <th>{t('Country')}</th>
      <th>{t('Language')}</th>
      <th>{t('Use in CSV')}</th>
      <th>{t('Supported search engines')}</th>
    </tr>
  </thead>
);

const TableRow = ({
  item,
  supportedSearchEngines,
}: {
  item: CountrylocaleFragment;
  supportedSearchEngines: string[];
}) => (
  <tr>
    <td>{item?.region}</td>
    <td>{item?.locale}</td>
    <td>{`${item?.localeShort}-${item?.countryCode.toLowerCase()}`}</td>
    <td>{supportedSearchEngines.join(', ')}</td>
  </tr>
);

const SupportedCountriesList = () => {
  const { data, loading } = useCountrylocalesQuery();

  const { hideModal } = useModal();

  const countrylocales = data?.countrylocales?.filter(notEmpty);

  const Body = () => (
    <tbody>
      {countrylocales?.map((item) => {
        const supportedSearchEngines: string[] = [];
        item?.googleSupport && supportedSearchEngines.push('Google');
        item?.bingSupport && supportedSearchEngines.push('Bing');
        item?.yandexSupport && supportedSearchEngines.push('Yandex');
        item?.baiduSupport && supportedSearchEngines.push('Baidu');
        item?.youtubeSupport && supportedSearchEngines.push('YouTube');
        return (
          <TableRow key={item?.id} item={item} supportedSearchEngines={supportedSearchEngines} />
        );
      })}
    </tbody>
  );

  if (loading) {
    return <LoadingSkeleton />;
  }

  return (
    <>
      <ModalBorder title={t('List of countries')} onClose={hideModal}>
        <table className="data-table table">
          <Header />
          <Body />
        </table>
      </ModalBorder>
    </>
  );
};

export default SupportedCountriesList;
