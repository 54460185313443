import keys from 'lodash/keys';
import uniqueId from 'lodash/uniqueId';
import {
  ADD_ELEMENT,
  CHANGE_BRAND_COLOR,
  LOAD_TEMPLATE,
  MOVE_ELEMENT,
  REMOVE_ELEMENT,
  RENAME_TEMPLATE,
  REPLACE_ELEMENT,
  RESET_TEMPLATE,
} from 'Actions/ReportTemplateAction';
import type { Action } from 'Actions/ReportTemplateAction';
import type { ReportElement } from 'Types/ReportElement';

export type ReportTemplateState = {
  readonly name: string;
  readonly color: string;
  readonly elements: Array<ReportElement>;
};
const initialState: ReportTemplateState = {
  name: '',
  color: '#ff9e30',
  elements: [],
};

const getDefaultValue = (widget: Record<string, any>) => ({
  id: '',
  type: widget.type,
  ...keys(widget.settings).reduce(
    (obj, key) => ({ ...obj, [key]: widget.settings[key].value }),
    {},
  ),
});

export default function (
  state: ReportTemplateState = initialState,
  action: Action,
): ReportTemplateState {
  switch (action.type) {
    case ADD_ELEMENT: {
      const newElement = { ...action.element, id: uniqueId('report') };
      const newElements = [...state.elements, newElement];
      return { ...state, elements: newElements };
    }

    case REMOVE_ELEMENT: {
      const indexToRemove = action.index;
      const newElements = state.elements.filter((_, index) => index !== indexToRemove);
      return { ...state, elements: newElements };
    }

    case REPLACE_ELEMENT: {
      const indexToReplace = action.index;
      const newElement = action.element;
      const newElements = state.elements.map((element, index) =>
        (index === indexToReplace ? newElement : element),
      );
      return { ...state, elements: newElements };
    }

    case MOVE_ELEMENT: {
      const indexFrom = action.from;
      const indexTo = action.to;
      const elements = state.elements;
      const newElements = elements.filter((_, index) => index !== indexFrom);
      newElements.splice(indexTo, 0, elements[indexFrom]);
      return { ...state, elements: newElements };
    }

    case RENAME_TEMPLATE:
      return { ...state, name: action.name };

    case CHANGE_BRAND_COLOR:
      return { ...state, color: action.color };

    case RESET_TEMPLATE:
      return initialState;

    // TODO FixTSignore
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    case LOAD_TEMPLATE:
      return {
        ...state,
        // TODO FixTSignore
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        name: action.name,
        // TODO FixTSignore
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        color: action.brandColor,
        // TODO FixTSignore
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        elements:
          (action as any)?.elements?.map((element) => ({
            ...getDefaultValue(element),
            id: uniqueId('report'),
          })) ?? [],
      };

    default:
      return state;
  }
}
