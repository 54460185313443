import { useSelector } from 'react-redux';
import { Flex } from '@mantine/core';
import { DataTable } from 'Components/DataTable';
import { useFilteredTableColumns } from 'Components/Modal/Content/TableSettingsModal/useFilteredColumns';
import AccTitle from 'Components/Title/AccTitle';
import { useModal } from 'Hooks/base/useModal';
import * as Actions from 'Pages/Layout/ActionsMenu/Actions';
import { DomainInfo } from 'Query';
import { selectDomainId } from 'Selectors/DomainSelector';
import { TableIDs } from 'Types/Table';
import { t } from 'Utilities/i18n';
import { useFetchCompetitorData } from '../hooks/useCompetitorData';
import { useCompetitorsTableInfo } from '../hooks/useCompetitorsTableInfo';
import reusableStyles from '../../../../css/reusable-styles.module.scss';

interface CompetitorsTableProps {
  domainInfo?: DomainInfo;
}

const CompetitorsTable = ({ domainInfo }: CompetitorsTableProps): JSX.Element => {
  const isPercentage = domainInfo?.shareOfVoicePercentage ?? false;
  const domainId = useSelector(selectDomainId);

  const fetchCompetitorData = useFetchCompetitorData(isPercentage);

  const { tableLabels, columns, defaultOrdering } = useCompetitorsTableInfo({
    domainInfo,
    isPercentage,
    domainId: Number(domainId),
  });

  const resultColumns = useFilteredTableColumns(columns, TableIDs.COMPETITORS).filter(
    (column) => column.id !== 'checkbox',
  );

  const { showModal } = useModal();

  const showTableSettings = () =>
    showModal({
      modalType: 'TableSettingsModal',
      modalTheme: 'light',
      modalProps: { tableName: TableIDs.COMPETITORS },
    });

  const showReportModal = () => {
    showModal({
      modalType: 'CompetitorsReport',
      modalTheme: 'light',
      modalProps: {
        domainId,
      },
    });
  };

  return (
    <div>
      <Flex mb="xs">
        <AccTitle type="h3">{t('Competitor Statistics')}</AccTitle>
        <Actions.SettingsAction m="0 0 0 auto" key="settings" onClick={showTableSettings} />
        <Actions.ReportAction m="0 0 0 12" key="report" onClick={showReportModal} />
      </Flex>
      <DataTable
        className={reusableStyles.paper}
        tableId={TableIDs.COMPETITORS}
        columns={resultColumns}
        fetchData={fetchCompetitorData}
        defaultOrdering={defaultOrdering}
        labels={tableLabels}
        dataKey={isPercentage?.toString()}
        skip={!domainInfo}
        key={domainInfo?.id}
        unmountStore
      />
    </div>
  );
};

export default CompetitorsTable;
