import { t } from 'Utilities/i18n';
import styles from './urlpreview.module.scss';

type Props = {
  title?: string | null;
  url?: string | null;
  text?: string | null;
};

const UrlSerpPreview = ({ title, url, text }: Props) => {
  if (!title && !url && !text) return <div>{t('Not available')}</div>;
  return (
    <div className={styles.urlPreview}>
      <div className="title"> {title || ''} </div>
      <div className="url">{url || ''}</div>
      <div className="text">{text || ''}</div>
    </div>
  );
};

export default UrlSerpPreview;
