import AccTooltip from '../AccTooltip/AccTooltip';
import styles from './url.module.scss';

interface Props {
  url: string;
  tooltip?: string;
  shortenedUrl?: string;
}

export const UrlEllipsis = (props: Props) => {
  return (
    <span className={styles.link}>
      <AccTooltip tooltip={props.tooltip}>
        <a href={props.url} target="_blank" rel="noopener noreferrer">
          <span lang="en">&lrm;{props.shortenedUrl ? props.shortenedUrl : props.url}&lrm;</span>
        </a>
      </AccTooltip>
    </span>
  );
};
