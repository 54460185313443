export const VIEW_MODE = {
  default: 'default',
  comfortable: 'comfortable',
  compact: 'compact',
};
export type ViewMode = 'default' | 'comfortable' | 'compact';

export const TableIDs = {
  NOTIFICATIONS: 'keywordsNotifications',
  KEYWORDS: 'keywords',
  KEYWORDS_OVERVIEW: 'keywords-overview',
  LANDING_PAGES: 'landingPages',
  MESSAGES: 'messages',
  USERS: 'users',
  EXTERNAL_ACCESS: 'external_access',
  TAG_CLOUD: 'tagCloud',
  DYNAMIC_TAGS: 'dynamicTags',
  IMPORT_GSC: 'importGsc',
  COMPETITORS_LEGEND: 'competitorsLegend',
  DOMAIN_DETAILS: 'domainDetails',
  COMPETITORS: 'competitors',
  ACCOUNT_KEYWORD_OVERUSE: 'accountKeywordOveruse',
  COMPETITOR_RANKINGS: 'competitorRankings',
  NOTES: 'notes',
  SCHEDULED_REPORTS: 'scheduledReports',
  GENERATED_REPORTS: 'generatedReports',
  REPORT_TEMPLATES: 'reportTemplates',
  INVOICES: 'invoices',
  CONNECTED_ACCOUNTS: 'connectedAccounts',
  PAUSED_DOMAINS: 'pausedDomains',
  GROUPS: 'groups',
  DASHBOARD: 'dashboard',
  DOMAINS: 'domains',
  KEYWORD_DISCOVERY: 'keywordDiscovery',
  WALLET: 'wallet',
  KEYWORD_HISTORY: 'keywordHistory',
  IMPORT_UNIVERSAL: 'importUniversal',
  SALES_CUSTOMERS: 'salesCustomers',
  SALES_INVOICE_CUSTOMERS: 'salesInvoiceCustomers',
  UNMATCHED_INVIOCES: 'UnmatchedInvoices',
  UNMATCHED_PAYMENTS: 'UnmatchedPayments',
  SALES_DASHBOARD: 'salesDashboard',
  SALES_PLANS: 'salesPlans',
  SALES_PLAN_CHOICES: 'salesPlanChoices',
  SALES_MAILS: 'salesMails',
  AFFILIATE_VISITORS: 'affiliateVisitors',
  AFFILIATE_PAYMENTS: 'affiliatePayments',
  AFFILIATE_COMMISSIONS: 'affiliateCommissions',
  AFFILIATE_CUSTOMERS: 'affiliateCustomers',
  UNKNOWN_COMPETITOR_RANKS: 'unknownCompetitorRanks',
  KEYWORDS_ALL_HISTORY: 'keywordAllHistory',
  SUB_ACCOUNTS: 'subAccounts',
  PENDING_REQUESTS: 'pendingRequests',
  INTEGRATION_APIS: 'integrationAPIs',
  EXTERNAL_ACCESS_OWNERS: 'owners',
  UNMATCHED_INVOICES: 'unmatched_invoices',
  EXTERNAL_ACCESS_PENDING_OWNERS: 'pending_owners',
  RESEARCH: 'research',
  FOLDERS: 'folders',
  WORD_CLOUD: 'wordCloud',
  ACCU_API_SEARCH_HISTORY: 'accuApisSearchHistory',
  WORKSPACES: 'workspaces',
  PUBLIC_KEYWORDS_REPORT_TABLE: 'publicKeywordsReportTable',
  HOME_TREE_VIEW: 'homeTreeView',
  RANKING_DISTRIBUTION_NEIGHBORLY: 'rankingDistributionNEIGHBORLY',
  LOCAL_RANKING_DISTRIBUTION_NEIGHBORLY: 'localRankingDistributionNEIGHBORLY',
  SEARCH_TREND_NEIGHBORLY: 'searchTrendNEIGHBORLY',
  AI_SEARCH_TREND_NEIGHBORLY: 'aiSearchTrendNEIGHBORLY',
} as const;

export type TableID = typeof TableIDs[keyof typeof TableIDs];
