import React from 'react';
import { Flex, Menu, ScrollArea } from '@mantine/core';
import { IconGraph, IconPlus } from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import AccText from 'Components/Text/AccText';
import { t } from 'Utilities/i18n';
import { GET_KPI_DESCRIPTION, GET_KPI_DISPLAY_NAME } from '..';

export const AddKpiDropdown = ({ addItemList, addItem }) => {
  if (!addItemList?.length) {
    return null;
  }

  return (
    <Menu shadow="md" position={'right-start'} width={320}>
      <Menu.Target>
        <AccButton
          leftSection={<IconPlus size={20} />}
          variant="tertiary"
          style={{ marginTop: '4px' }}
        >
          {t('Add KPI')}
        </AccButton>
      </Menu.Target>
      <Menu.Dropdown data-no-dnd="true">
        <ScrollArea.Autosize
          mah={512}
          scrollbars={'y'}
          offsetScrollbars={addItemList.length > 5}
          type="hover"
        >
          {addItemList?.map((item) => {
            return (
              <Menu.Item key={item} onClick={() => addItem(item)}>
                <Flex direction="row" gap={8} mih={80}>
                  <div>
                    <IconGraph size={32} color={'#515672'} />
                  </div>
                  <div>
                    <AccText variant="label">{GET_KPI_DISPLAY_NAME()[item]}</AccText>
                    <AccText inactive size="xs">
                      {GET_KPI_DESCRIPTION()[item]}
                    </AccText>
                  </div>
                </Flex>
              </Menu.Item>
            );
          })}
        </ScrollArea.Autosize>
      </Menu.Dropdown>
    </Menu>
  );
};
