import { gql } from '@apollo/client';

export const QUERY_COMPARE_CHART_NOTES = gql`
  query CompareChartNotesQuery(
    $filters: [FilterInput]!
    $fakePagination: PaginationInput!
    $fakeOrdering: OrderingInput!
  ) {
    keywords(filters: $filters, pagination: $fakePagination, ordering: $fakeOrdering) {
      notes {
        id
        createdAt
        user {
          id
          fullName
        }
        note
        numberOfKeywords
      }
    }
  }
`;
