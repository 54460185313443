import { Box, Center, Flex } from '@mantine/core';
import AccAlert from 'Components/AccAlert/AccAlert';
import AccTitle from 'Components/Title/AccTitle';
import { PricingPlanNode, UnconfirmedUserNode } from 'Ghql';
import { t } from 'Utilities/i18n';
import { PAY, TRIAL_CC_AND_PAY } from '../index';
import styles from '../register.module.scss';

type Props = {
  pricingPlan?: PricingPlanNode;
  unconfirmedUser?: UnconfirmedUserNode;
};

type GuardedProps = {
  plan: PricingPlanNode;
};

const H1 = ({ children }: { children: React.ReactNode }) => (
  <Center>
    <AccTitle type="h2" ta="center" className={styles.planInfoTitle}>
      {children}
    </AccTitle>
  </Center>
);
const H2 = ({ children }: { children: React.ReactNode }) => (
  <Center>
    <AccTitle type="h3" c="grey" ta="center" className={styles.planInfoSubTitle}>
      {children}
    </AccTitle>
  </Center>
);

const TrialAccount = () => (
  <>
    <H1>{t('You are almost there, final step')}</H1>
    <H2>{t('Activate your AccuRanker trial account - No credit card required')}</H2>
  </>
);

const PayNowAccount = ({ plan }: GuardedProps) => (
  <>
    <H1>{t('Final step, create your AccuRanker account')}</H1>
    <H2>
      {t(
        'Standard plan - %s %s per month with %s keywords',
        plan.priceMonthly,
        plan.currency,
        plan.maxKeywords,
      )}
    </H2>

    <Flex mt={20} justify="center">
      <AccAlert
        severity={'error'}
        title={t('This is not a trial')}
        maw={{ base: '100%', xl: '90%', sm: '100%', xs: '80%' }}
        className={styles.planInfoAlert}
      >
        {t(
          'You are signing up for a paid plan and paying right away. If you are looking for a trial please contact us at support@accuranker.com',
        )}
      </AccAlert>
    </Flex>
  </>
);

const PlanInfo = ({ pricingPlan, unconfirmedUser }: Props) => {
  if (!pricingPlan || !unconfirmedUser) return null;

  const payNowPlan =
    unconfirmedUser?.signupType === TRIAL_CC_AND_PAY || unconfirmedUser?.signupType === PAY;

  return (
    <Box mb="sm" className={styles.planInfoContainer}>
      {payNowPlan ? <PayNowAccount plan={pricingPlan} /> : <TrialAccount />}
    </Box>
  );
};

export default PlanInfo;
