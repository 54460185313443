import { MouseEvent } from 'react';
import { Divider, Flex } from '@mantine/core';
import { IconChartHistogram, IconTrash } from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import AccTag from 'Components/AccTag';
import { FolderMetric, useDeleteTagsMutation } from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import { useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import toast from 'Hooks/useToast';
import useUserPermission from 'Hooks/useUserPermission';
import { TrackingKey } from 'Utilities/Analytics/mixpanel';
import { useInvalidateKeywordCache } from 'Utilities/Graphql/invalidateCache';
import { t, tct } from 'Utilities/i18n';
import { sleep } from 'Utilities/promise';
import { useFoldersGraph } from '../../HistoryGraph/foldersGraph';
import { FolderType } from '../../groupingTypes';
import { useGroupingStore } from '../../support/groupingsStore';

type Props = {
  selectedNodes: FolderType[];
  getSelectedNodes: () => FolderType[];
  setSelectedNodes: (nodes: FolderType[]) => void;
  withCount?: boolean;
  withDivider?: boolean;
  darkTheme?: boolean;
};

const GroupingBulkActions = ({
  withCount = true,
  withDivider = false,
  selectedNodes,
  getSelectedNodes,
  setSelectedNodes,
  darkTheme = false,
}: Props) => {
  const invalidateKeywordCache = useInvalidateKeywordCache();
  const { userHasWriteAccess } = useUserPermission();
  const groupingStore = useGroupingStore();
  const { showModal } = useModal();
  const { domainId, isDemoDomain } = useQueryDomainInfo();
  const [deleteStaticTags] = useDeleteTagsMutation();

  const tags = selectedNodes.filter(({ is_folder }) => !is_folder);
  // Separate static and dynamic tags. Get sum of keywords affected
  const { staticTags, dynamicTags, keywordsAffected } = tags.reduce(
    (acc, { name, is_dynamic, keywords = 0 }) => {
      acc[is_dynamic ? 'dynamicTags' : 'staticTags'].push(name);
      acc.keywordsAffected += keywords;
      return acc;
    },
    { staticTags: [], dynamicTags: [], keywordsAffected: 0 } as {
      staticTags: string[];
      dynamicTags: string[];
      keywordsAffected: number;
    },
  );

  const KEYWORD_THRESHOLD = 1000;

  const handleDeleteTags = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (domainId) {
      showModal({
        modalType: 'Confirmation',
        modalTheme: 'light',
        modalProps: {
          title: t('Delete tags?'),
          lockDuration: 0,
          description: tct(
            'The following tags will be permanently deleted and removed from all keywords: [tags]',
            {
              tags: (
                <Flex gap="xs" direction="row" wrap="wrap" align="start" mt="xl">
                  {tags.map(({ name, is_dynamic }) => (
                    <AccTag key={name} tag={name} dynamic={is_dynamic} maxWidth="100%" />
                  ))}
                </Flex>
              ),
            },
          ),
          confirmLabel: t('Delete'),
          cancelLabel: t('Cancel'),
          action: async () => {
            await deleteStaticTags({
              variables: { staticTags, dynamicTags, domainId: parseInt(domainId, 10) },
            });
            invalidateKeywordCache();

            // wait for tags to be deleted when more than 1000 keywords are affected
            // copy of logic used in PathCell.tsx
            if (keywordsAffected > KEYWORD_THRESHOLD) {
              await sleep(1000);
            }

            await groupingStore.refetch(false);

            // remove tags from selected nodes, keep only folders
            setSelectedNodes(selectedNodes.filter(({ is_folder }) => is_folder));
            toast.success(t('Selected tags were deleted!'));
          },
        },
      });
    }
  };

  const showFoldersModal = useFoldersGraph(getSelectedNodes, FolderMetric.EstimatedVisits);
  return (
    <>
      {tags.length > 0 && userHasWriteAccess && !isDemoDomain && (
        <>
          <AccButton
            c={`${darkTheme ? 'white' : 'snorlax'}`}
            variant="tertiary"
            leftSection={<IconTrash width={18} height={18} />}
            onClick={handleDeleteTags}
          >
            {t('Delete tags')}
            {withCount && ` (${tags.length})`}
          </AccButton>
          {withDivider && <Divider h={24} my="auto" orientation="vertical" />}
        </>
      )}
      {selectedNodes.length > 0 && (
        <>
          <AccButton
            c={`${darkTheme ? 'white' : 'snorlax'}`}
            onClick={showFoldersModal}
            variant="tertiary"
            disabled={!selectedNodes.length}
            leftSection={<IconChartHistogram width={18} height={18} />}
            ml={selectedNodes.length ? '0' : 'auto'}
            trackingKey={TrackingKey.CompareSelected}
          >
            {t('Compare selected')}
            {withCount && ` (${selectedNodes.length})`}
          </AccButton>
          {withDivider && <Divider h={24} my="auto" orientation="vertical" />}
        </>
      )}
    </>
  );
};

export default GroupingBulkActions;
