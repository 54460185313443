import uniq from 'lodash/uniq';
import { StoreType } from 'Types/Store';
import {
  filterSelectedOptions,
  normalizeOptions,
} from '../../NextTableSettings/support/common/helpers';
import { getDefaultItemsForGroupSettings } from '../../NextTableSettings/support/helpers';
import { TableSettingGroup } from '../../NextTableSettings/support/types';
import { selectFlatColumnIds } from '../common/helpers';
import { GroupSettingsItem } from '../types';

const selectHasOldGroupConfig = (groupsKey: string) => (state: StoreType) => {
  const groups = state.user?.[groupsKey];

  return !groups?.length;
};

const selectGroupsByColumnsV1 = (
  columns: string[],
  groupsConfigs: TableSettingGroup[],
): GroupSettingsItem[] => {
  return groupsConfigs
    .map((e) => {
      const enabled = e.fixed || e.options?.some(filterSelectedOptions(columns));
      return { id: e.id, enabled };
    })
    .filter(Boolean);
};

const selectColumnsByColumnsV1 = (
  columns: string[],
  groupsConfigs: TableSettingGroup[],
): string[] => {
  const extraColumns = uniq(
    groupsConfigs
      .map((e) => {
        const options = normalizeOptions(e.options);
        const paramsOptions = options.filter((el) => el.isParameter);
        const optionsWithOldIds = options.filter((el) => el.oldId);

        const resultIds: string[] = options
          .filter((el) => el.hidden || el.disabled)
          .map((el) => el.id);
        if (paramsOptions?.length) {
          const parentIds = paramsOptions
            .filter((el) => el.parentId && columns?.includes(el.parentId))
            .map((el) => el.id);

          resultIds.push(...(parentIds || []));
        }
        if (optionsWithOldIds?.length) {
          const oldIds = optionsWithOldIds
            .filter((el) => el.oldId && columns?.includes(el.oldId))
            .map((el) => el.id);
          resultIds.push(...(oldIds || []));
        }
        return resultIds;
      })
      .filter((e) => e.length)
      .reduce((acc, options) => [...acc, ...(options || [])], []),
  );

  return [...(columns || []), ...(extraColumns || [])].filter(Boolean);
};

export const selectColumns =
  (groups: TableSettingGroup[], propertyKey: string, groupPropertyKey: string) =>
  (state: StoreType) => {
    const columns = state.user?.[propertyKey] ? selectFlatColumnIds(state.user[propertyKey]) : null;

    const isOldFormat = selectHasOldGroupConfig(groupPropertyKey)(state);
    if (!columns?.length) {
      return getDefaultItemsForGroupSettings(groups)?.columns ?? [];
    } else if (isOldFormat) {
      return selectColumnsByColumnsV1(columns as string[], groups);
    }
    return (columns as string[])?.filter(Boolean) || [];
  };

export const selectGroups = (
  groups: TableSettingGroup[],
  propertyKey: string,
  groupPropertyKey: string,
) => {
  return (state: StoreType): GroupSettingsItem[] => {
    const resultGroups = state.user?.[groupPropertyKey]
      ? selectFlatColumnIds<GroupSettingsItem[] | null>(state.user?.[groupPropertyKey])
      : [];
    if (!resultGroups?.length) {
      return (
        selectGroupsByColumnsV1(
          selectColumns(groups, propertyKey, groupPropertyKey)(state),
          groups,
        )?.filter(Boolean) || []
      );
    }

    return resultGroups?.filter(Boolean) || [];
  };
};
