import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { ModalPayloadConfig } from 'Reducers/ModalReducer';
import { selectModal } from 'Selectors/ModalSelector';
import { EventName, useMixpanel } from 'Utilities/Analytics/mixpanel';
import { devError } from '../../Utilities/log';
import Backdrop from '../Backdrop';
import Portal from '../Portal/portal';
import ModalContentTypes from './Content';

type Props = {
  updatePage: () => void;
};

const Modal = (props: Props) => {
  const trackEvent = useMixpanel();
  const modals = useSelector(selectModal);
  const { list = [] } = modals;

  const renderModalContent = (item: ModalPayloadConfig, index: number) => {
    const { modalType, modalProps, modalTheme } = item;
    const SpecificModal = ModalContentTypes[modalType];

    if (!SpecificModal) {
      devError(`No modal for modalType: "${modalType}"`);
    }
    return (
      <Fragment key={index}>
        <Portal>
          <Backdrop
            showLogo={false}
            shown={!!modalType}
            className="modal-container"
            theme={modalTheme}
          >
            <SpecificModal
              {...modalProps}
              refresh={modalProps?.refreshPageAction ? props.updatePage : modalProps?.refresh}
              // wrap onSubmit if it exists to track event
              {...(modalProps?.onSubmit
                ? {
                    onSubmit: (...args) => {
                      trackEvent(EventName.ModalSubmit, { 'Modal Type': modalType });
                      return modalProps?.onSubmit?.(...args);
                    },
                  }
                : {})}
            />
          </Backdrop>
        </Portal>
      </Fragment>
    );
  };

  return (
    <>
      {list?.map((item: ModalPayloadConfig, index: number) => renderModalContent(item, index)) ??
        null}
    </>
  );
};

export default React.memo(Modal);
