import { Component } from 'react';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import { Field } from 'redux-form';
import toFormField from 'Components/Forms/toFormField';
import { t } from 'Utilities/i18n';
import Validator from 'Utilities/validation';
import MultiOptionsInputFactory from '../../Common/MultiOptionsEditor/InputFactory';

const campaignsQuery = gql`
  query affiliateInput_campaigns {
    affiliate {
      campaignChoices
    }
  }
`;
const CampaignsInput = MultiOptionsInputFactory(
  graphql(campaignsQuery, {
    props: ({ data: { error, loading, affiliate } }: any) => ({
      error,
      loading,
      items: affiliate ? affiliate.campaignChoices : [],
    }),
  }),
);
const CampaignsField = toFormField(CampaignsInput);

class CampaignsEditor extends Component<{}> {
  render() {
    return (
      <div>
        <Field
          name="value"
          component={CampaignsField}
          validate={Validator.array}
          placeholder={t('Enter campaigns')}
          noValueItem={t('No campaign')}
        />
      </div>
    );
  }
}

export default CampaignsEditor;
