import * as React from 'react';
import isObjectLike from 'lodash/isObjectLike';
import type { FieldProps } from 'redux-form';
import FormField from './FormField';

type FormFieldProps = {
  helpText: string;
  /**
   * By default select store value in the store, but if you want to store it as SelectItem - specify defaultBehaviour: true
   * @deprecated Should not be used for new places, instead of it use primitive value *(id, instead of full entity)
   */
  defaultBehaviour?: boolean;
} & FieldProps;

function handleSelectOnBlur(field) {
  if (!field?.input) return;
  const {
    input,
    input: { value },
  } = field;

  input.onBlur(value);
}

/**
 * @deprecated
 * Use react-final-form instead of redux-form. To adopt component for react-final-form -
 * use: `/src/Components/Fields/hooks/WithFieldForm/WithFieldForm.tsx`
 */
function toFormField<InputProps extends {}>(
  Component: React.ComponentType<
    React.PropsWithChildren<
      {
        value?: unknown;
        onChange?: (...args: Array<unknown>) => void;
        showError?: boolean | null;
        disabled?: boolean;
      } & InputProps
    >
  >,
  { select }: { select?: boolean } = { select: false },
): React.ComponentType<React.PropsWithChildren<InputProps & FormFieldProps>> {
  return function FormFieldWrapper(props: InputProps & FormFieldProps) {
    const { input, meta, helpText, ...otherProps } = props;
    const error = meta && meta.touched && meta.error;

    const onSelectChange = (value, option) => {
      const nextValue = props.defaultBehaviour
        ? props.options?.find((e) => e?.value === value) || option
        : value;

      input.onChange(nextValue);
    };
    const controlProps = select
      ? {
          value: input.value?.value ?? (isObjectLike(input.value) ? undefined : input.value),
          onChange: onSelectChange,
        }
      : {};
    return (
      <FormField helpText={helpText} {...props}>
        <Component
          {...input}
          {...controlProps}
          onBlur={() => handleSelectOnBlur(props)}
          showError={!!error}
          disabled={meta?.submitting}
          {...otherProps}
        />
      </FormField>
    );
  };
}

export default toFormField;
