import { MouseEventHandler } from 'react';
import { Flex } from '@mantine/core';
import { IconCaretRightFilled, IconTag } from '@tabler/icons-react';
import styles from './keywordTag.module.scss';

export const KwdExtraTagsCompactPlaceholder = ({
  tagsLength,
  onMouseEnter,
}: {
  tagsLength: number;
  onMouseEnter: MouseEventHandler<HTMLDivElement>;
}) => (
  <Flex
    className={styles.extraTagsCompactContainer}
    gap={4}
    align="center"
    onMouseEnter={onMouseEnter}
  >
    <IconTag size={12} />
    <span>{`${tagsLength}`}</span>
    <IconCaretRightFilled className={styles.caretDown} size={10} />
  </Flex>
);
