import { useEffect } from 'react';
import { DataTable, QueryOrder } from 'Components/DataTable';
import { TableIDs } from 'Types/Table';
import { t } from 'Utilities/i18n';
import { subscribeToScheduledReport } from 'Utilities/websocket';
import { useFetchScheduledReports, useScheduledReportsTableColumns } from './helpers/hooks';

type ScheduledReportsTableProps = {
  searchTerm: string;
  handleUpdateTable: () => void;
  dataKey: number;
  handleLoading: (isLoading: boolean) => void;
};

const ScheduledReportsTable = ({
  handleUpdateTable,
  dataKey,
  searchTerm,
  handleLoading,
}: ScheduledReportsTableProps): JSX.Element => {
  useEffect(() => {
    const subscription = subscribeToScheduledReport(() => {
      handleUpdateTable();
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [handleUpdateTable]);

  const fetchData = useFetchScheduledReports({ searchTerm, handleLoading });
  const columns = useScheduledReportsTableColumns();

  return (
    <DataTable
      tableId={TableIDs.SCHEDULED_REPORTS}
      dataKey={`${dataKey?.toString()}-${searchTerm}`}
      fetchData={fetchData}
      columns={columns}
      emptyOptions={{
        title: t('No Scheduled Reports'),
        subTitle: t('There are currently no scheduled reports.'),
      }}
      defaultOrdering={{
        order: QueryOrder.DESC,
        orderBy: 'reportType',
      }}
      offlineFilter={{
        tableName: TableIDs.SCHEDULED_REPORTS,
        skipAll: true,
      }}
    />
  );
};

export default ScheduledReportsTable;
