import NumberEditor from 'Components/Filters/Common/NumberEditor';
import { numberLabelFunc } from 'Components/Filters/Common/labelFunc';
import { BaseRankFilter, FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import { t } from 'Utilities/i18n/index';
import NoFilterIcon from 'icons/less-than.svg?inline';
import RankIcon from 'icons/rank.svg?inline';
import { onNumberFilterUpdate } from '../shared/helpers';

const defaultValue: BaseRankFilter = {
  attribute: FilterAttribute.BASE_RANK,
  type: FilterValueType.NUMBER,
  comparison: FilterComparison.LT,
  value: '' as any,
};

const getData = () => ({
  defaultValue,
  title: t('Base Rank'),
  icon: RankIcon,
  editor: NumberEditor,
  tableEditor: NumberEditor,
  onChange: onNumberFilterUpdate,
  tableEditorProps: {
    iconDropdown: true,
    noFilterIcon: NoFilterIcon,
  },
  labelFunc: numberLabelFunc,
});

export default getData;
