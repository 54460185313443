import { SEARCH_TYPE_NAVER, SearchEngineIds } from 'Components/Modal/Content/AddSearchEngine/types';
import { ValueBarColor } from 'Components/Table/TableRow/ValueBar';
import { t } from 'Utilities/i18n';
import NaCell from './helpers/NaCell';
import ValueBarCell from './valueBarCell';

type Props = {
  value: {
    data: {
      competition: number;
      searchVolumeValue: number;
      validForSearchVolume: boolean;
      updatingKeyword: boolean;
      searchEngineId: number;
    };
    loadingData: boolean;
  };
};

const CompetitionCell = (props: Props) => {
  const colorMapper = (competition: number) => {
    let color = ValueBarColor.SUCCESS;
    if (competition) {
      if (competition > 1 / 3) {
        color = ValueBarColor.WARNING;
      }
      if (competition > 2 / 3) {
        color = ValueBarColor.DANGER;
      }
    }
    return color;
  };

  if (props.value.data?.searchEngineId === SearchEngineIds[SEARCH_TYPE_NAVER]) {
    return <NaCell tooltip={t('No Ads Competition for this search engine')} />;
  }

  return (
    <ValueBarCell
      {...props}
      colorMapper={colorMapper}
      valueBarValue={props.value.data.competition}
    />
  );
};

export default CompetitionCell;
