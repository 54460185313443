import { Checkbox, Flex, Group } from '@mantine/core';
import { IconSitemap } from '@tabler/icons-react';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { Field } from 'Components/Fields';
import MultiSelectValue from 'Components/MultiSelectValue';
import AccText, { AccTextProps } from 'Components/Text/AccText';
import { QuickNavigationDataQueryHookResult, useQuickNavigationDataQuery } from 'Ghql';
import { t, tct, tn } from 'Utilities/i18n';

type Props = {
  defaultValue?: string[];
  name: string;
  hasAllGroups?: boolean;
  withinPortal?: boolean;
  size?: 'default' | 'sm' | 'md' | 'lg';
  searchMinWidth?: number;
  maxDisplayedValues?: number;
};

const ContainerSizes = {
  sm: '36',
  md: '42',
  lg: '50',
  default: '42',
};

const GroupsToSelectItems = (data: QuickNavigationDataQueryHookResult['data']) => {
  const options = data?.user?.quickNavigationData
    ?.map((client) => {
      if (!client?.clientId) return null;
      return {
        value: client.clientId.toString(),
        label: client.clientName,
        domains: client.domains?.length,
      };
    })
    .filter(Boolean);
  return options || [];
};

const GroupPill = (props: AccTextProps & { label: string }) => {
  const { label, maw } = props;
  const maxWidth = maw as string | number | undefined;
  return (
    <MultiSelectValue label={label} maw={maxWidth} icon={<IconSitemap size={15} opacity={0.3} />} />
  );
};

const FieldGroupAccess = (props: Props) => {
  const { data, loading } = useQuickNavigationDataQuery();
  const options = GroupsToSelectItems(data);

  if (props.hasAllGroups) {
    return (
      <Flex mih={ContainerSizes[props.size || 'default']} align="center">
        {t('All Groups')}
      </Flex>
    );
  }

  return (
    <Field.MultiSelect
      value={props.defaultValue}
      options={options}
      name={props.name}
      placeholder={t('Pick a group from the list')}
      noResultsText={t('There are no groups with that name')}
      withinPortal={props.withinPortal}
      size={props.size}
      isLoading={loading}
      clearable={false}
      creatable={false}
      searchMinWidth={props.searchMinWidth}
      maxDisplayedValues={props.maxDisplayedValues}
      hidePickedOptions={false}
      removeOnBackspace={false}
      stackComponent={({ moreValue }) => {
        const moreItemsLabel = tct('+[count] more', {
          count: moreValue,
        });
        return <MultiSelectValue label={moreItemsLabel} isStack />;
      }}
      optionComponent={({ label, domains, isSelected }) => {
        return (
          <Group wrap="nowrap" align="center">
            <Checkbox checked={isSelected} color={'snorlax.3'} readOnly />
            <AccText span size="sm" truncate>
              {label}
            </AccText>
            <AccText span size="xs" c="gray.4" nowrap>
              ({tn('1 domain', '%s domains', domains)})
            </AccText>
          </Group>
        );
      }}
      valueComponent={({ label }) => {
        return (
          <AccTooltip tooltip={label}>
            <GroupPill label={label} maw={70} />
          </AccTooltip>
        );
      }}
    />
  );
};

FieldGroupAccess.defaultProps = {
  size: 'default',
  maxDisplayedValues: 3,
  searchMinWidth: 100,
};

export default FieldGroupAccess;
