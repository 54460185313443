// Used to throw errors in development that should be ignored in production,
// to prevent seeing blank screen in case minor errors happened
export const devError = (...args: unknown[]) => {
  if (process.env.NODE_ENV === 'production') {
    // eslint-disable-next-line no-console
    console.error(...args);
  } else {
    throw new Error(`Failed: ${args?.join('\\n')}`);
  }
};
