import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import {
  RankingDistributionFormatedSeries,
  RankingDistributionSeries,
} from 'Pages/Keywords/Overview/components/HistoryCharts/RankingDistribution/helpers';
import { colorScheme } from 'Utilities/colors';
import { t } from 'Utilities/i18n';
import { notEmpty } from 'Utilities/underdash';

const SERIA_ID = 'rankingDistribution';
const KEYWORDS0TO3_SERIA = '1';
const KEYWORDS4TO10_SERIA = '2-5';
const KEYWORDS11TO20_SERIA = '6-10';
const KEYWORDS21TO50_SERIA = '11-20';
const KEYWORDS50ANDUP_SERIA = '21-100';
const KEYWORDS0TO3_RESPONSE = 'rank1';
const KEYWORDS4TO10_RESPONSE = 'rank2To5';
const KEYWORDS11TO20_RESPONSE = 'rank6To10';
const KEYWORDS21TO50_RESPONSE = 'rank11To20';
const KEYWORDS50ANDUP_RESPONSE = 'rank21To100';
const KEYWORDSUNRANKED_RESPONSE = 'nonRanking';
const colors = colorScheme.rankDistribution;

const seriesKeys = [
  KEYWORDS0TO3_RESPONSE,
  KEYWORDS4TO10_RESPONSE,
  KEYWORDS11TO20_RESPONSE,
  KEYWORDS21TO50_RESPONSE,
  KEYWORDS50ANDUP_RESPONSE,
  KEYWORDSUNRANKED_RESPONSE,
];
const responseToSeriaMap = {
  [KEYWORDS0TO3_RESPONSE]: () => KEYWORDS0TO3_SERIA,
  [KEYWORDS4TO10_RESPONSE]: () => KEYWORDS4TO10_SERIA,
  [KEYWORDS11TO20_RESPONSE]: () => KEYWORDS11TO20_SERIA,
  [KEYWORDS21TO50_RESPONSE]: () => KEYWORDS21TO50_SERIA,
  [KEYWORDS50ANDUP_RESPONSE]: () => KEYWORDS50ANDUP_SERIA,
  [KEYWORDSUNRANKED_RESPONSE]: () => t('Not ranking'),
};

export const getRankingDistributionSeries = (
  overviewRankingDistribution?: (any | null)[] | null,
) => {
  // overviewRankingDistribution is on the format of {date: {rank1: 0, rank2-5: 0, ...}, ...}

  let series: any[] = [];

  if (!overviewRankingDistribution) {
    return [];
  }

  // iterate through object entries
  for (const [date, ranking] of Object.entries(overviewRankingDistribution)) {
    // iterate through object entries

    // skip if not date
    if (!date) {
      continue;
    }

    let totalKeywords = 0;
    const record = { date: moment(date).unix() * 1000, keywordsTotal: totalKeywords };
    for (const [rank, count] of Object.entries(ranking)) {
      // if the rank is not 'date'
      if (rank !== 'rank1To10') {
        // set the rank to the count
        record[rank] = count;
        totalKeywords += count as number;
      }
      record.keywordsTotal = totalKeywords;
    }
    series.push(record);
  }

  if (series?.length === 1) {
    const fakeFirstDate = cloneDeep(series[0]);
    fakeFirstDate.date = moment(series[0].date).subtract(1, 'day').toDate().getTime();
    series = [fakeFirstDate, series[0]];
  }
  return series;
};

export const formatSeries = (
  data?: RankingDistributionSeries[],
): RankingDistributionFormatedSeries[] => {
  return seriesKeys.map((name, idx) => {
    return {
      name: responseToSeriaMap[name](),
      id: SERIA_ID + idx,
      color: colors[idx % colors.length],
      marker: {
        symbol: 'circle',
        radius: 3,
      },
      showInNavigator: true,
      turboThreshold: 500000,
      type: 'areaspline',
      pointPlacement: 'between',
      data: data?.filter(notEmpty)?.map((dataPoint) => ({
        x: dataPoint.date,
        y: dataPoint[name as keyof typeof dataPoint] || 0,
        totalKeywords: dataPoint.keywordsTotal,
      })),
    };
  });
};

export const getCsvData = (series) => () => {
  const header = `Date,${series.map((s) => s.name).join(',')},Total keywords` + '\n';
  let i;
  let rows = '';
  for (i = 0; i < series[0].data.length; i++) {
    const reverseIdx = series[0].data.length - 1 - i;
    rows += `${moment.unix(series[0].data[reverseIdx].x / 1000).format('YYYY-MM-DD')}`;
    series.map((s) => (rows += `,${s.data[reverseIdx].y}`));
    rows += `,${series[0].data[reverseIdx].totalKeywords}`;
    rows += '\n';
  }
  return header + rows;
};
