import { Component } from 'react';
import { connect } from 'react-redux';
import { Flex } from '@mantine/core';
import { hideModal, showModal } from 'Actions/ModalAction';
import AccButton from 'Components/AccButton/AccButton';
import { ModalTypes } from 'Components/Modal/Content';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import {
  IntegrationOAuthProvider,
  IntegrationSimpleProvider,
  Integrations,
} from 'Types/Integration';
import { t } from 'Utilities/i18n/index';
import SelectProvider from './SelectProvider';

type TModalOptions = {
  modalType: ModalTypes;
  modalTheme: 'light';
  modalProps?: {
    refresh?: () => void;
    integration: IntegrationOAuthProvider | IntegrationSimpleProvider;
    isAdding: true;
  };
};

type AddAccountProps = {
  refresh?: () => void;
  showModal: (config: TModalOptions) => void;
  hideModal: () => void;
};

const options = [
  {
    provider: Integrations.GOOGLE_ACCOUNT,
    modal: 'ConnectToGA' as const,
  },
  {
    provider: Integrations.ADOBE,
    modal: 'ConnectToAdobe' as const,
  }, // {
  //   provider: Integrations.HUBSPOT,
  //   modal: 'ConnectToOAuth',
  // },
];

class AddAccount extends Component<AddAccountProps> {
  handleSelect = (provider: IntegrationOAuthProvider | IntegrationSimpleProvider) => {
    const option = options.find((item) => item.provider === provider);
    this.props.showModal({
      modalType: option?.modal || 'ConnectToGA',
      modalTheme: 'light',
      modalProps: {
        refresh: this.props.refresh,
        integration: provider,
        isAdding: true,
      },
    });
  };

  render() {
    return (
      <ModalBorder title={t('Select Connection Provider')} onClose={this.props.hideModal}>
        <Flex gap={'xl'} align={'center'} justify={'center'}>
          <SelectProvider
            providers={options.map((option) => option.provider)}
            onSelect={this.handleSelect}
          />
        </Flex>
        <ModalFooter
          primaryButtonSlot={
            <AccButton variant="tertiary" onClick={this.props.hideModal}>
              {t('Close this window')}
            </AccButton>
          }
        />
      </ModalBorder>
    );
  }
}

export default connect(null, {
  showModal,
  hideModal,
})(AddAccount);
