import { Component } from 'react';
import cookie from 'react-cookies';
import { t } from 'Utilities/i18n';
import './dev-bar.scss';

type Props = {};
export default class DevBar extends Component<Props> {
  disable = () => {
    cookie.remove('ar_dev', {
      path: '/',
    });
    window.location.reload();
  };

  render() {
    const useNew = !!(cookie.load('ar_dev') || '');
    return useNew ? (
      <div className="dev-bar" onClick={this.disable}>
        {t('RUNNING IN DEVELOPMENT MODE (click to disable)')}
      </div>
    ) : null;
  }
}
