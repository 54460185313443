import { useIntl } from 'react-intl';
import Cell from 'Components/AccuTable/CellRenderer/HelperComponents/AccuCell';
import { intlFormatNumber } from 'Components/FormatNumber/helpers';
import FormatNumber from 'Components/FormatNumber/new';
import { useDisplayCurrency } from 'Hooks/displayCurrency';
import { t } from 'Utilities/i18n';
import AccTooltip from '../../../../../../Components/AccTooltip/AccTooltip';
import { LandingTableItem } from '../support/types';

export const LandingCostValueCell = (props: { record: LandingTableItem }) => {
  const intl = useIntl();
  const { displayCurrency } = useDisplayCurrency(true);

  const tooltipLabel = `${t(
    'Weighted avg. CPC: %s',
    intlFormatNumber(intl)({
      value: props.record?.avgCostPerClick,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }) +
      ' ' +
      displayCurrency,
  )}`;

  return (
    <Cell rightAligned>
      <AccTooltip tooltip={tooltipLabel}>
        <span>
          <FormatNumber
            value={props.record?.costValue}
            minimumFractionDigits={2}
            maximumFractionDigits={2}
          />
        </span>
      </AccTooltip>
    </Cell>
  );
};
