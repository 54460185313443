import { Stack } from '@mantine/core';
import { IconCirclePlus } from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import AccessControl from 'Components/AccessControl';
import AccText from 'Components/Text/AccText';
import AccTitle from 'Components/Title/AccTitle';
import { useModal } from 'Hooks/base/useModal';
import { TrackingKey } from 'Utilities/Analytics/mixpanel';
import { t } from 'Utilities/i18n';

type Props = {
  groupId?: number | null;
};

const NoDomains = ({ groupId }: Props) => {
  const { showModal } = useModal();
  const handleAddDomain = () => {
    showModal({
      modalType: 'BuildDomain',
      modalTheme: 'light',
      modalProps: {
        groupId,
      },
    });
  };
  return (
    <Stack justify="center" align="center" py="lg">
      <AccTitle type="h2">{t('No domains found')}</AccTitle>
      <AccText>{t('You can add new domain by clicking the "Add domain" button.')}</AccText>
      <AccessControl>
        <AccButton
          leftSection={<IconCirclePlus size={18} />}
          trackingKey={TrackingKey.AddAction}
          variant="primary"
          onClick={handleAddDomain}
        >
          {t('Add domain')}
        </AccButton>
      </AccessControl>
    </Stack>
  );
};

export default NoDomains;
