import { memo } from 'react';
import { Box, Flex, Group, Progress, useMantineTheme } from '@mantine/core';
import isEqual from 'lodash/isEqual';
import FormatNumber from 'Components/FormatNumber/new';
import AccText from 'Components/Text/AccText';
import { colorScheme } from 'Utilities/colors';
import { t } from 'Utilities/i18n';
import { ResultData } from '.';
import { WinnerLoserBarLabel } from './components/WinnerLoserBarLabel';
import { WinnerLoserHeaderSkeleton } from './components/WinnerLoserSkeleton';

const BAR_SPACING = 0.25;

type KeywordsValueProps = {
  keywords?: number;
  percentage?: number;
  align: 'flex-start' | 'flex-end';
  color: string;
};

const KeywordsValue = ({ keywords, percentage, color, align }: KeywordsValueProps) => {
  const theme = useMantineTheme();
  return (
    <Flex direction="column" align={align}>
      <Flex direction="row" align="baseline" gap="4px">
        <AccText size="lg" c={color} fw={700} lh={1}>
          {keywords}
        </AccText>
        <AccText size="xs" c={color} lh={1}>
          (
          <FormatNumber value={percentage} maximumFractionDigits={0} />
          %)
        </AccText>
      </Flex>
      <AccText c={theme.colors.gray[7]} size="xs" mt="2px">
        {t('Keywords')}
      </AccText>
    </Flex>
  );
};

export const WinnerLosersBar = memo(
  ({
    useAi = false,
    loading = true,
    resultData,
  }: {
    useAi?: boolean;
    loading?: boolean;
    resultData: ResultData;
  }) => {
    const total = (resultData.winner || 0) + (resultData.losers || 0);
    const winnerPercentage = total ? ((resultData.winner || 0) / total) * 100 : 0;
    const loserPercentage = total ? ((resultData.losers || 0) / total) * 100 : 0;
    const winnerColor = colorScheme.increase;
    const loserColor = colorScheme.decrease;
    // If winner percentage or loser percentage are 0, don't show the spacing between the bars
    const spacing = winnerPercentage && loserPercentage ? BAR_SPACING : 0;
    // If the percentage is smaller or equal value then remove the spacing from opposite value.
    const winnerProgress =
      loserPercentage <= spacing ? winnerPercentage - spacing : winnerPercentage - spacing / 2;
    const loserProgress =
      winnerPercentage <= spacing ? loserPercentage - spacing : loserPercentage - spacing / 2;
    if (loading) {
      return <WinnerLoserHeaderSkeleton />;
    }
    return (
      <Box py="lg">
        <Group justify="space-between">
          <WinnerLoserBarLabel
            label={t('Winners')}
            isWinner={true}
            keywords={resultData.winner}
            periodFrom={resultData.periodFrom}
            periodTo={resultData.periodTo}
            shareOfVoiceChange={resultData.winnerSov}
            useAi={useAi}
          />
          <WinnerLoserBarLabel
            label={t('Losers')}
            isWinner={false}
            keywords={resultData.losers}
            periodFrom={resultData.periodFrom}
            periodTo={resultData.periodTo}
            shareOfVoiceChange={resultData.loserSov}
            useAi={useAi}
          />
        </Group>
        <Flex wrap="nowrap" align="center" gap="xs" mt="xs">
          <KeywordsValue
            align="flex-start"
            keywords={resultData.winner}
            percentage={winnerPercentage}
            color={winnerColor}
          />
          <Progress.Root w="100%" size="md">
            <Progress.Section color={winnerColor} value={winnerProgress} />
            <Progress.Section color={'white'} value={spacing} />
            <Progress.Section color={loserColor} value={loserProgress} />
          </Progress.Root>
          <KeywordsValue
            align="flex-end"
            keywords={resultData.losers}
            percentage={loserPercentage}
            color={loserColor}
          />
        </Flex>
      </Box>
    );
  },
  isEqual,
);
WinnerLosersBar.displayName = 'WinnerLosersBar';
