import { CHART_NAMES } from 'Pages/Keywords/Overview/components/ChartBox';
import { KPI_NAMES } from 'Pages/Keywords/Overview/components/KpiBar';

const mapNaverCharts = (chartName: string): string => {
  if (chartName === CHART_NAMES.ESTIMATED_VISITS) {
    return CHART_NAMES.SHARE_OF_VOICE;
  }
  if (chartName === CHART_NAMES.AI_SHARE_OF_VOICE_BY) {
    return CHART_NAMES.SHARE_OF_VOICE_BY;
  }
  return chartName;
};
const naverChartBlacklist = [
  CHART_NAMES.AI_SHARE_OF_VOICE_BY,
  CHART_NAMES.ESTIMATED_VISITS,
  CHART_NAMES.GOOGLE_ANALYTICS,
  CHART_NAMES.SEARCH_INTENT,
  CHART_NAMES.TRAFFIC_VALUE,
];
const naverBlacklistedKpis = [
  KPI_NAMES.DYNAMIC_CTR,
  KPI_NAMES.AI_SHARE_OF_VOICE,
  KPI_NAMES.TRAFFIC_VALUE,
  KPI_NAMES.AI_TRAFFIC_VALUE,
];
const mapAiSovToSov = (kpi: string): string => {
  return kpi === KPI_NAMES.AI_SHARE_OF_VOICE ? KPI_NAMES.SHARE_OF_VOICE : kpi;
};

export function adjustChartsAndKpisForNaver(
  leftChartNames: string[],
  rightChartNames: string[],
  kpis: string[],
) {
  leftChartNames = leftChartNames
    ?.map((chartName: string) => mapNaverCharts(chartName))
    .filter(
      (chartName: string) =>
        !naverChartBlacklist.includes(chartName) && !rightChartNames.includes(chartName),
    );

  rightChartNames = rightChartNames
    ?.map((chartName: string) => mapNaverCharts(chartName))
    .filter(
      (chartName: string) =>
        !naverChartBlacklist.includes(chartName) && !leftChartNames.includes(chartName),
    );

  kpis = kpis
    ?.map((kpi: string) => mapAiSovToSov(kpi))
    .filter((kpi: string) => !(naverBlacklistedKpis as string[]).includes(kpi));

  // remove duplicates. Note!! This will cause errors if we at some point allow editing overview in naver mode, as edit mode relies on duplicate CHART_NAMES.NONE
  rightChartNames = [...new Set(rightChartNames)];
  leftChartNames = [...new Set(leftChartNames)];
  kpis = [...new Set(kpis)];
  return { leftChartNames, rightChartNames, kpis };
}
