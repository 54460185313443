import { useMemo } from 'react';
import { notEmpty } from 'Utilities/underdash';
import { RawClient } from './useDropdownData';

export const useDerivedState = (
  groups: RawClient[],
  selectedGroup: number | null,
  selectedDomains: string[],
) => {
  const numberOfKeywordsInGroup = useMemo(() => {
    const currentGroup = groups?.find((client) => client.clientId === selectedGroup) || groups?.[0];
    return currentGroup?.totalKeywords || 0;
  }, [groups, selectedGroup]);

  const totalKeywordsForSelectedDomains = useMemo(() => {
    const currentGroup = groups?.find((client) => client.clientId === selectedGroup) || groups?.[0];
    const currentGroupDomains = currentGroup?.domains?.filter(notEmpty) || [];
    const totalKeywords = currentGroupDomains.reduce((total, domain) => {
      if (selectedDomains.includes(domain.id.toString())) {
        return total + (domain.totalKeywords || 0);
      }
      return total;
    }, 0);
    return totalKeywords;
  }, [groups, selectedDomains, selectedGroup]);

  const domainsInGroup = useMemo(() => {
    return selectedGroup
      ? groups
          ?.find((client) => client.clientId === selectedGroup)
          ?.domains?.map((domain) => domain?.id)
          .filter(notEmpty) || []
      : groups?.[0]?.domains?.map((domain) => domain?.id).filter(notEmpty) || [];
  }, [groups, selectedGroup]);

  return { numberOfKeywordsInGroup, totalKeywordsForSelectedDomains, domainsInGroup };
};
