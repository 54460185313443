import { useEffect, useMemo, useRef } from 'react';
import isEqual from 'lodash/isEqual';

export function useDeepMemo<T>(factory: () => T, dependencies) {
  const depRef = useRef();
  if (!isEqual(depRef.current, dependencies)) {
    depRef.current = dependencies;
  }
  /* eslint-disable react-hooks/exhaustive-deps */
  return useMemo(factory, [depRef.current]);
}

export function useDeepEffect(callback, dependencies) {
  const depRef = useRef();
  if (!isEqual(depRef.current, dependencies)) {
    depRef.current = dependencies;
  }
  useEffect(callback, [depRef.current]);
}
