import { Component } from 'react';
import cn from 'classnames';
import noop from 'lodash/noop';
import uniqueId from 'lodash/uniqueId';
import AccTooltip from 'Components/AccTooltip/AccTooltip';

type Props = {
  className?: string;
  icon?: any;
  tooltip?: string;
  onClick?: (...args: Array<any>) => any;
  onMouseEnter?: (...args: Array<any>) => any;
  onMouseLeave?: (...args: Array<any>) => any;
  ignored?: boolean;
  isTooltipOpened?: boolean;
  inline?: boolean;
};

class Icon extends Component<Props> {
  iconId: string;
  static defaultProps = {
    onClick: noop,
  };

  constructor(props: Props) {
    super(props);
    this.iconId = uniqueId('icon');
  }

  render() {
    const {
      icon: IconTag,
      ignored,
      tooltip,
      className,
      onClick,
      onMouseEnter,
      onMouseLeave,
    } = this.props;
    return (
      <span
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
        className={cn(
          'icon-with-tooltip',
          {
            ignored,
          },
          className,
        )}
      >
        <AccTooltip tooltip={tooltip} placement="top" old>
          <span id={this.iconId}>
            <IconTag />
          </span>
        </AccTooltip>
      </span>
    );
  }
}

export default Icon;
