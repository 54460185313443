import React from 'react';
import isNil from 'lodash/isNil';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import Cell from 'Components/AccuTable/CellRenderer/HelperComponents/AccuCell';
import FormatNumber from 'Components/FormatNumber/new';
import { t } from 'Utilities/i18n';

interface Props {
  value: number;
  currency?: boolean;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
}

export const NumberCell: React.FC<React.PropsWithChildren<Props>> = ({
  value,
  currency,
  minimumFractionDigits,
  maximumFractionDigits,
}) => {
  if (isNil(value)) {
    return (
      <Cell rightAligned>
        <AccTooltip tooltip={t('No value for this date')}>
          <div>-</div>
        </AccTooltip>
      </Cell>
    );
  }

  return (
    <Cell rightAligned>
      <FormatNumber
        value={value ?? 0}
        currency={currency ? 'USD' : undefined}
        minimumFractionDigits={minimumFractionDigits}
        maximumFractionDigits={maximumFractionDigits}
      />
    </Cell>
  );
};
