import { useField } from 'react-final-form';
import AccSwitch, { AccSwitchProps } from 'Components/AccSwitch/Switch';

interface Props {
  name: string;
  defaultChecked?: boolean;
  customOnChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FieldSwitch = (props: Props & AccSwitchProps) => {
  const { name, defaultChecked, customOnChange, ...rest } = props;

  const { input } = useField(name, { type: 'checkbox', initialValue: defaultChecked });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    input.onChange(e);
    customOnChange?.(e);
  };

  return <AccSwitch checked={input.checked} onChange={handleChange} {...rest} />;
};

export default FieldSwitch;
