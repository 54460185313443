import { Flex } from '@mantine/core';
import { IconCirclePlus } from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import { t } from 'Utilities/i18n';

const FiltersShell = () => {
  return (
    <Flex className="filters">
      <AccButton
        m="0.25rem 0.5rem"
        variant="tertiary"
        leftSection={<IconCirclePlus />}
        tooltip={t('Filters are disabled for this page')}
        disabled
        styles={{
          section: {
            paddingRight: 4,
          },
        }}
      >
        {t('Add filter')}
      </AccButton>
    </Flex>
  );
};

export default FiltersShell;
