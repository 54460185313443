import { useMemo, useState } from 'react';
import { useField } from 'react-final-form';
import { Box, Flex, useMantineTheme } from '@mantine/core';
import { IconArrowNarrowRight } from '@tabler/icons-react';
import SimpleSelect from 'Components/SimpleSelect/SimpleSelect';
import AccTitle from 'Components/Title/AccTitle';
import {
  useTransferMultiAccountDomainFormDomainsQuery,
  useTransferMultiAccountDomainFormMultiAccountsDomainsQuery,
} from 'Ghql';
import { t } from 'Utilities/i18n';

const TransferMultiAccountDomainForm = () => {
  const mantineTheme = useMantineTheme();

  const [selectedAccount, setSelectedAccount] = useState('');
  const [selectedClient, setSelectedClient] = useState('');
  const [selectedAccountDestination, setSelectedAccountDestination] = useState('');

  const { data: multiAccountsDomainsData, loading: loadingMulti } =
    useTransferMultiAccountDomainFormMultiAccountsDomainsQuery({ fetchPolicy: 'network-only' });
  const { data: domainsData, loading: loadingSingle } =
    useTransferMultiAccountDomainFormDomainsQuery({
      fetchPolicy: 'network-only',
    });

  const { input: clientInputFrom } = useField('client_from');
  const { input: clientInputTo } = useField('client_to');

  const { input: domainInputFrom } = useField('domain_from');

  const clearFromFields = () => {
    clientInputFrom.onChange(undefined);
    clientInputTo.onChange(undefined);
    domainInputFrom.onChange(undefined);
  };

  const mergedAccounts = useMemo(() => {
    if (loadingSingle || loadingMulti) return [];

    const tmpMergedAccounts: any[] = [];

    setSelectedAccount(domainsData?.user?.organization?.id || '');
    setSelectedAccountDestination(domainsData?.user?.organization?.id || '');

    tmpMergedAccounts.push(domainsData?.user?.organization);

    multiAccountsDomainsData?.user?.organization?.multiAccounts?.forEach((org) => {
      if (org?.toOrganization) {
        tmpMergedAccounts.push(org?.toOrganization);
      }
    });

    return tmpMergedAccounts;
  }, [
    multiAccountsDomainsData?.user?.organization?.multiAccounts,
    domainsData?.user?.organization,
    loadingSingle,
    loadingMulti,
  ]);

  const accountOptions = useMemo(() => {
    if (mergedAccounts && mergedAccounts.length) {
      return mergedAccounts.map((acc) => ({
        label: acc?.name,
        value: acc?.id,
      }));
    }
    return [];
  }, [mergedAccounts]);

  const clientOptions = useMemo(() => {
    if (mergedAccounts && mergedAccounts.length && selectedAccount) {
      const tmpClientOptions: any[] = [];
      mergedAccounts
        .filter((acc) => acc.id === selectedAccount)
        .forEach((acc) => {
          acc?.clients?.forEach((client) => {
            tmpClientOptions.push({
              label: client?.name,
              value: client?.id,
            });
          });
        });
      return tmpClientOptions;
    }
    return [];
  }, [mergedAccounts, selectedAccount]);

  const domainOptions = useMemo(() => {
    if (mergedAccounts && mergedAccounts.length && selectedAccount && selectedClient) {
      const tmpDomainOptions: any[] = [];
      mergedAccounts
        .filter((acc) => acc.id === selectedAccount)
        .forEach((acc) => {
          acc?.clients
            ?.filter((client) => client.id === selectedClient)
            .forEach((client) => {
              client?.domains?.forEach((domain) => {
                tmpDomainOptions.push({
                  label: `${domain?.displayName} (${domain?.domain})`,
                  value: domain?.id,
                });
              });
            });
        });
      return tmpDomainOptions;
    }
    return [];
  }, [mergedAccounts, selectedAccount, selectedClient]);

  const clientDestinationOptions = useMemo(() => {
    if (mergedAccounts && mergedAccounts.length && selectedAccount) {
      const tmpClientOptions: any[] = [];
      mergedAccounts
        .filter((acc) => acc.id === selectedAccountDestination)
        .forEach((acc) => {
          acc?.clients?.forEach((client) => {
            tmpClientOptions.push({
              label: client?.name,
              value: client?.id,
            });
          });
        });
      return tmpClientOptions;
    }
    return [];
  }, [mergedAccounts, selectedAccountDestination, selectedAccount]);

  if (loadingSingle || loadingMulti) return <></>;

  return (
    <Flex gap="xl">
      <Flex direction="column" w="100%" gap="md">
        <AccTitle type="h3" mb="sm">
          {t('Select domain to transfer')}
        </AccTitle>
        <SimpleSelect
          searchable
          defaultValue={domainsData?.user?.organization?.id}
          name="account_from"
          label={t('From organization')}
          placeholder={t('Select organization')}
          data={accountOptions}
          onChange={(item) => {
            setSelectedAccount(item?.value || '');
            clearFromFields();
          }}
        />
        <SimpleSelect
          searchable
          name="client_from"
          label={t('From group')}
          placeholder={t('Select group')}
          nothingFoundMessage={t('No groups found')}
          data={clientOptions}
          disabled={!selectedAccount}
          onChange={(item) => {
            setSelectedClient(item?.value || '');
            domainInputFrom.onChange('');
          }}
        />
        <SimpleSelect
          searchable
          name="domain_from"
          label={t('Domain')}
          placeholder={t('Select domain')}
          nothingFoundMessage={t('No domains found')}
          data={domainOptions}
          disabled={!selectedClient}
          required
        />
      </Flex>

      <Box m={'auto'}>
        <IconArrowNarrowRight size={60} color={mantineTheme.colors.gray[5]} />
      </Box>

      <Flex direction="column" w="100%" gap="md">
        <AccTitle type="h3" mb="sm">
          {t('Select destination group')}
        </AccTitle>
        <SimpleSelect
          searchable
          defaultValue={domainsData?.user?.organization?.id}
          name="account_to"
          label={t('To organization')}
          placeholder={'Select destination organization'}
          data={accountOptions}
          onChange={(item) => {
            setSelectedAccountDestination(item?.value || '');
            clientInputTo.onChange('');
          }}
        />
        <SimpleSelect
          searchable
          name="client_to"
          label={t('To group')}
          placeholder={t('Select destination group')}
          nothingFoundMessage={t('No groups found')}
          data={clientDestinationOptions}
          disabled={!selectedAccount}
          required
        />
      </Flex>
    </Flex>
  );
};

export default TransferMultiAccountDomainForm;
