import { Suspense, lazy } from 'react';
import { Box, Skeleton } from '@mantine/core';
import { RichTextEditorProps } from 'Components/Controls/RichTextEditor';
import styles from 'Components/Controls/RichTextEditor/richTextEditor.module.scss';

const RichTextEditor = lazy(() => import('Components/Controls/RichTextEditor'));

const RichTextEditorFallback = () => (
  <>
    <Box py="xs" px="lg" h={47} className={styles.defaultFallbackToolbar}>
      <Skeleton className={styles.skeleton} h={27} />
    </Box>
    <Box p="lg" h={303} className={styles.defaultFallbackRoot}>
      <Skeleton className={styles.skeleton} h={263} />
    </Box>
  </>
);

export type RichTextInputProps = {
  value: string;
  onChange: (content: string) => void;
  customOnChange?: (content: string) => void;
  disabled?: boolean;
  fallback?: React.ReactNode;
  loading?: boolean;
} & Omit<RichTextEditorProps, 'content' | 'setContent' | 'type'>;

/** Lazy load the RichTextEditor to not include it in the main app bundle */
export const RichTextEditorAdapter = (props: RichTextInputProps) => {
  const {
    disabled,
    value,
    onChange,
    customOnChange,
    fallback = <RichTextEditorFallback />,
    loading,
    ...rest
  } = props;

  if (loading) {
    return <RichTextEditorFallback />;
  }

  return (
    <Suspense fallback={fallback}>
      <RichTextEditor
        {...rest}
        content={value}
        setContent={(content) => {
          onChange(`${content}`);
          customOnChange?.(`${content}`);
        }}
        type="HTML"
        disabled={disabled}
      />
    </Suspense>
  );
};
