import * as React from 'react';
import { connect } from 'react-redux';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import compose from 'lodash/flowRight';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import AccButton from 'Components/AccButton/AccButton';
import FormatNumber from 'Components/FormatNumber';
import { Checkbox, Select, TextField } from 'Components/Forms/Fields';
import FormErrors from 'Components/Forms/FormErrors';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import AccText from 'Components/Text/AccText';
import toast from 'Hooks/useToast';
import { throwNetworkError, throwSubmitErrors } from 'Utilities/errors';
import { t, tct } from 'Utilities/i18n';
import Validator from 'Utilities/validation';
import formStyles from 'css/layout/form-layout.module.scss';

type Plan = {
  id: string;
  name: string;
};
type Props = {
  handleSubmit: (...args: Array<any>) => any;
  invalid: boolean;
  submitting: boolean;
  createAndPaySubAccount: (...args: Array<any>) => any;
  createSubAccount: (...args: Array<any>) => any;
  plans: Array<Plan>;
  onClose: (...args: Array<any>) => any;
  refresh: (...args: Array<any>) => any;
  whoPays: Record<string, any>;
};
type State = {
  isLoading: boolean;
};

class AddSubAccountForm extends React.Component<Props, State> {
  static defaultProps = {
    whoPays: {
      value: 1,
    },
  };
  state = {
    isLoading: false,
  };
  billingCycleOptions = [
    {
      label: t('Monthly'),
      value: 1,
    },
    {
      label: t('Annually'),
      value: 2,
    },
  ];
  payerOptions = [
    {
      label: t('My account'),
      value: 1,
    },
    {
      label: t('New sub-account'),
      value: 2,
    },
  ];
  handleSubmit = ({ organizationName, fullName, email, plan, billingCycle }) => {
    this.setState({
      isLoading: true,
    });
    const { whoPays, createAndPaySubAccount, createSubAccount } = this.props;

    if (whoPays.value === 1) {
      return createAndPaySubAccount({
        variables: {
          input: {
            organizationName,
            plan: plan.value,
            billingCycle: billingCycle.value,
          },
        },
      })
        .then(
          ({
            data: {
              createAndPaySubAccount: { success, errors },
            },
          }) => {
            if (!success) {
              throwSubmitErrors(errors);
            } else {
              toast.success(t('New sub-account created and paid'));
              this.setState({
                isLoading: false,
              });
              this.props.refresh();
              this.props.onClose();
            }
          },
          throwNetworkError,
        )
        .catch((error) => {
          this.setState({
            isLoading: false,
          });
          throw error;
        });
    }

    return createSubAccount({
      variables: {
        input: {
          organizationName,
          fullName,
          email,
        },
      },
    })
      .then(
        ({
          data: {
            createSubAccount: { success, errors },
          },
        }) => {
          if (!success) {
            throwSubmitErrors(errors);
          } else {
            toast.success(t('New sub-account created'));
            this.setState({
              isLoading: false,
            });
            this.props.refresh();
            this.props.onClose();
          }
        },
        throwNetworkError,
      )
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        throw error;
      });
  };

  renderFromOrgPays() {
    const { plans, submitting } = this.props;

    return (
      <div>
        <div className={formStyles.formRow}>
          <div className="form-label required">{t('Plan')}</div>
          <Field
            name="plan"
            defaultBehaviour
            component={Select}
            disabled={submitting}
            placeholder={t('Select plan')}
            options={plans}
            validate={[Validator.required]}
            displayLabel
          />
        </div>
        <div className={formStyles.formRow}>
          <div className="form-label required">{t('Billing Cycle')}</div>
          <Field
            name="billingCycle"
            defaultBehaviour
            component={Select}
            disabled={submitting}
            placeholder={t('Select billing cycle')}
            options={this.billingCycleOptions}
            useFirstOptionAsDefault
            searchable={false}
            validate={[Validator.required]}
          />
        </div>
      </div>
    );
  }

  renderSubAccountPays() {
    const { submitting } = this.props;
    return (
      <div>
        <div className={formStyles.formRow}>
          <div className="form-label required">{t('Users Full Name')}</div>
          <Field
            name="fullName"
            placeholder={t('Users Full Name')}
            disabled={submitting}
            component={TextField}
            validate={[Validator.required]}
          />
        </div>
        <div className={formStyles.formRow}>
          <div className="form-label required">{t('Users Email Address')}</div>
          <Field
            name="email"
            type="email"
            placeholder={t('Users Email Address')}
            disabled={submitting}
            component={TextField}
            validate={[Validator.required]}
          />
        </div>
      </div>
    );
  }

  render() {
    const { invalid, submitting, handleSubmit, whoPays } = this.props;
    const { isLoading } = this.state;
    return (
      <form onSubmit={handleSubmit(this.handleSubmit)}>
        <FormErrors />
        <div className={formStyles.formRow}>
          <div className="form-label required">{t('Who Pays?')}</div>
          <Field
            name="payer"
            defaultBehaviour
            disabled={submitting}
            component={Select}
            options={this.payerOptions}
            useFirstOptionAsDefault
            validate={[Validator.required]}
            searchable={false}
            autoFocus={true}
          />
        </div>
        <div className={formStyles.formRow}>
          <div className="form-label required">{t('Company Name')}</div>
          <Field
            name="organizationName"
            placeholder={t('Company Name')}
            disabled={submitting}
            component={TextField}
            validate={[Validator.required]}
          />
        </div>
        {whoPays.value === 1 ? this.renderFromOrgPays() : this.renderSubAccountPays()}
        <div className={formStyles.formRow}>
          <Field
            name="terms"
            component={Checkbox}
            disabled={submitting}
            validate={[Validator.required]}
          >
            {tct('I agree to the [link:Terms and Conditons]', {
              link: <a href="https://www.accuranker.com/terms" target="_blank" rel="noreferrer" />,
            })}
          </Field>
        </div>
        <ModalFooter
          primaryButtonSlot={
            <AccButton
              disabled={invalid || submitting || isLoading}
              loading={submitting || isLoading}
              type="submit"
              variant="primary"
            >
              {whoPays.value === 1 ? t('Create and pay') : t('Create and send email')}
            </AccButton>
          }
          secondaryButtonSlot={
            <AccButton variant="tertiary" onClick={this.props.onClose}>
              {t('Cancel')}
            </AccButton>
          }
          textSlot={
            <AccText variant="label">
              <div>
                {whoPays.value === 1
                  ? t(
                      'The account will be created and billed instantly using the credit card that is already on your account.',
                    )
                  : t(
                      'The account will be created and the user entered will receive an email with login info. There is no trial period on the account, and it must be paid to be usable.',
                    )}
              </div>
            </AccText>
          }
        />
      </form>
    );
  }
}

const createAndPaySubAccountQuery = gql`
  mutation addSubAccountForm_createAndPaySubAccount($input: CreateAndPaySubAccountInput!) {
    createAndPaySubAccount(input: $input) {
      errors {
        field
        messages
      }
      success
    }
  }
`;
const createSubAccountQuery = gql`
  mutation addSubAccountForm_createSubAccount($input: CreateSubAccountInput!) {
    createSubAccount(input: $input) {
      errors {
        field
        messages
      }
      success
    }
  }
`;
const pricingPlansQuery = gql`
  query addSubAccountForm_pricingPlansSubaccounts {
    pricingPlansSubaccounts {
      id
      name
      priceMonthly
      priceYearly
      currency
    }
  }
`;

const formatPrice = (price, currency) => <FormatNumber currency={currency}>{price}</FormatNumber>;

const formatPlanLabel = (plan) => (
  <span>
    {plan.name} ({formatPrice(plan.priceMonthly, plan.currency)} /{' '}
    {formatPrice(plan.priceYearly, plan.currency)})
  </span>
);

const selector = formValueSelector('AddSubAccountForm');

const mapStateToProps = (state) => ({
  whoPays: selector(state, 'payer'),
});

export default compose(
  graphql(createAndPaySubAccountQuery, {
    name: 'createAndPaySubAccount',
  }),
  graphql(createSubAccountQuery, {
    name: 'createSubAccount',
  }),
  graphql(pricingPlansQuery, {
    props: ({ ownProps, data: { loading, error, pricingPlansSubaccounts } }: any) => ({
      ...ownProps,
      loading,
      error,
      plans: pricingPlansSubaccounts
        ? pricingPlansSubaccounts.reduce((acc, plan) => {
            acc.push({
              label: plan.name,
              displayLabel: formatPlanLabel(plan),
              value: plan.id,
            });
            return acc;
          }, [])
        : [],
    }),
  }),
)(
  connect(mapStateToProps)(
    reduxForm({
      form: 'AddSubAccountForm',
    })(AddSubAccountForm),
  ),
) as any;
