import FoldersGraph from '../../../Pages/Keywords/Groupings/HistoryGraph/foldersGraph';
import ConnectToAdobe from '../Wizard/ConnectToAdobe';
import ConnectToDrive from '../Wizard/ConnectToDrive';
import ConnectToGA from '../Wizard/ConnectToGA';
import ConnectToGSC from '../Wizard/ConnectToGSC';
import ConnectToOAuth from '../Wizard/ConnectToOAuth';
import CreateAffiliateLink from '../Wizard/CreateAffiliateLink';
import AddAccount from './AddAccount';
import AddGroup from './AddGroup';
import AddKeywords from './AddKeywords';
import AddNote from './AddNote';
import AddSearchEngine from './AddSearchEngine';
import AddSubAccount from './AddSubAccount';
import AddTags from './AddTags';
import AddUser from './AddUser';
import AddWalletItem from './AddWalletItem';
import AddWorkspace from './AddWorkspace';
import AffiliateInfo from './AffiliateInfo';
import BuildCompetitor from './BuildCompetitor';
import BuildDomain from './BuildDomain';
import CancelPlan from './CancelPlan';
import CompetitorsRanksReport from './CompetitorsRanksReport';
import CompetitorsReport from './CompetitorsReport';
import Confirmation from './Confirmation';
import ConnectToAnalytics from './ConnectToAnalytics';
import CopyToClipboard from './CopyToClipboard';
import CreateOrEditDynamicTag from './CreateTagModal/CreateOrEditDynamicTagModal';
import CreateOrEditStaticTag from './CreateTagModal/CreateOrEditStaticTagModal';
import DomainsComparison from './DomainsComparison';
import EditAccount from './EditAccount';
import EditAdobeAccount from './EditAdobeAccount';
import EditFilterGroup from './EditFilterGroup';
import EditGroup from './EditGroup';
import EditKeywords from './EditKeywords';
import EditNote from './EditNote';
import EditUser from './EditUser';
import EditWorkspace from './EditWorkspace';
import ExportAdTracking from './ExportAdTracking';
import FoldersReport from './FoldersReport';
import GeneralError from './GeneralError';
import Integration from './Integration';
import Invoices from './Invoices';
import KeywordHistoryReport from './KeywordHistoryReport';
import KeywordInfo from './KeywordInfo';
import KeywordsComparison from './KeywordsComparison';
import LandingPage from './LandingPage';
import Loading from './Loading';
import Messages from './Messages';
import MessagesNew from './MessagesNew';
import MoveDomain from './MoveDomain';
import MoveKeywords from './MoveKeywords';
import NewVersion from './NewVersion';
import NotAllowedModal from './NotAllowedModal';
import NotOrgAdmin from './NotOrgAdmin';
import OneTimeReport from './OneTimeReport';
import PartialScrape from './PartialScrape';
import PaymentFailed from './PaymentFailed';
import PaymentSuccess from './PaymentSuccess';
import PermissionDeniedModal from './PermissionDeniedModal';
import PermissionUpdatedModal from './PermissionUpdatedModal';
import RegisterFailed from './RegisterFailed';
import RegisterPhoneVerify from './RegisterPhoneVerify';
import RemoveTags from './RemoveTags';
import RequestAccess from './RequestAccess';
import SERPInfo from './SERPInfo';
import SalesCreateInvoice from './Sales/CreateInvoice';
import SalesDemoContentForm from './Sales/DemoContent';
import SalesExtendForm from './Sales/Extend';
import SalesLockOrganization from './Sales/LockOrganization';
import SalesMailDetails from './Sales/MailDetails';
import SalesMakeAffiliateForm from './Sales/MakeAffiliate';
import OrganizationNote from './Sales/OrganizationNote';
import SalesOrganizationPlan from './Sales/OrganizationPlan';
import SalesPayAffiliateForm from './Sales/PayAffiliate';
import SalesRefundForm from './Sales/Refund';
import SalesUpdateInvoiceContactInfo from './Sales/UpdateInvoiceContactInfo';
import SalesOrganizationAbuseLog from './SalesOrganizationAbuseLog';
import SalesOrganizationDomains from './SalesOrganizationDomains';
import SelectTagType from './SelectTagTypeModal';
import SessionBlockModal from './SessionBlockModal/SessionBlockModal';
import Shortcuts from './Shortcuts';
import ShowServiceMessageModal from './ShowServiceMessageModal';
import SupportedCountriesList from './SupportedCountriesList';
import TableSettingsModal from './TableSettingsModal/TableSettingsModal';
import UpsellModal from './UpsellModal';

export type ModalTypes =
  | 'Integration'
  | 'Loading'
  | 'CancelPlan'
  | 'MoveKeywords'
  | 'PaymentSuccess'
  | 'PaymentFailed'
  | 'GeneralError'
  | 'RegisterFailed'
  | 'RegisterPhoneVerify'
  | 'AddKeywords'
  | 'AddSearchEngine'
  | 'Confirmation'
  | 'BuildDomain'
  | 'SalesOrganizationAbuseLog'
  | 'SalesOrganizationDomains'
  | 'BuildCompetitor'
  | 'EditKeywords'
  | 'AddTags'
  | 'RemoveTags'
  | 'AddNote'
  | 'EditNote'
  | 'LandingPage'
  | 'OneTimeReport'
  | 'KeywordHistoryReport'
  | 'AddGroup'
  | 'EditGroup'
  | 'ConnectToGSC'
  | 'ConnectToGA'
  | 'Messages'
  | 'MessagesNew'
  | 'AddUser'
  | 'EditUser'
  | 'AddWorkspace'
  | 'EditWorkspace'
  | 'RequestAccess'
  | 'SERPInfo'
  | 'Shortcuts'
  | 'AddSubAccount'
  | 'NotOrgAdmin'
  | 'SessionBlockModal'
  | 'CompetitorsRanksReport'
  | 'CompetitorsReport'
  | 'ConnectToAnalytics'
  | 'ConnectToOAuth'
  | 'ConnectToAdobe'
  | 'ConnectToDrive'
  | 'NewVersion'
  | 'EditAdobeAccount'
  | 'News'
  | 'SupportedCountriesList'
  //| 'UploadCSV'
  | 'AddAccount'
  | 'EditAccount'
  | 'EditFilterGroup'
  | 'SalesExtendForm'
  | 'SalesRefundForm'
  | 'SalesUpdateInvoiceContactInfo'
  | 'SalesDemoContentForm'
  | 'SalesMakeAffiliateForm'
  | 'SalesPayAffiliateForm'
  | 'SalesOrganizationPlan'
  | 'SalesLockOrganization'
  | 'CopyToClipboard'
  | 'UpsellModal'
  | 'NotAllowedModal'
  | 'KeywordInfo'
  | 'CreateAffiliateLink'
  | 'AffiliateInfo'
  | 'Invoices'
  | 'MoveDomain'
  | 'ExportAdTracking'
  | 'KeywordsComparison'
  | 'DomainsComparison'
  | 'SalesMailDetails'
  | 'PartialScrape'
  | 'TableSettingsModal'
  | 'SalesCreateInvoice'
  | 'AddWalletItem'
  | 'OrganizationNote'
  | 'SelectTagType'
  | 'CreateOrEditDynamicTag'
  | 'CreateOrEditStaticTag'
  | 'FoldersReport'
  | 'FoldersGraph'
  | 'ShowServiceMessageModal'
  | 'PermissionDeniedModal'
  | 'PermissionUpdatedModal'
  | 'UpgradePlanModal'
  | 'RequestUpgradePlanModal'
  | 'EnableOveruseModal'
  | 'RequestEnableOveruseModal';

export default {
  Integration,
  Loading,
  CancelPlan,
  MoveKeywords,
  PaymentSuccess,
  PaymentFailed,
  GeneralError,
  RegisterFailed,
  RegisterPhoneVerify,
  AddKeywords,
  AddSearchEngine,
  Confirmation,
  BuildDomain,
  SalesOrganizationAbuseLog,
  SalesOrganizationDomains,
  BuildCompetitor,
  EditKeywords,
  AddTags,
  RemoveTags,
  AddNote,
  EditNote,
  LandingPage,
  OneTimeReport,
  KeywordHistoryReport,
  AddGroup,
  EditGroup,
  ConnectToGSC,
  ConnectToGA,
  Messages,
  MessagesNew,
  AddUser,
  EditUser,
  AddWorkspace,
  EditWorkspace,
  RequestAccess,
  SERPInfo,
  Shortcuts,
  AddSubAccount,
  NotOrgAdmin,
  SessionBlockModal,
  CompetitorsRanksReport,
  CompetitorsReport,
  ConnectToAnalytics,
  ConnectToOAuth,
  ConnectToAdobe,
  ConnectToDrive,
  NewVersion,
  EditAdobeAccount,
  SupportedCountriesList,
  //UploadCSV,
  AddAccount,
  EditAccount,
  EditFilterGroup,
  SalesExtendForm,
  SalesRefundForm,
  SalesUpdateInvoiceContactInfo,
  SalesDemoContentForm,
  SalesMakeAffiliateForm,
  SalesPayAffiliateForm,
  SalesOrganizationPlan,
  SalesLockOrganization,
  CopyToClipboard,
  UpsellModal,
  NotAllowedModal,
  KeywordInfo,
  CreateAffiliateLink,
  AffiliateInfo,
  Invoices,
  MoveDomain,
  ExportAdTracking,
  KeywordsComparison,
  DomainsComparison,
  SalesMailDetails,
  PartialScrape,
  TableSettingsModal,
  SalesCreateInvoice,
  AddWalletItem,
  OrganizationNote,
  SelectTagType,
  CreateOrEditDynamicTag,
  CreateOrEditStaticTag,
  FoldersReport,
  FoldersGraph,
  ShowServiceMessageModal,
  PermissionDeniedModal,
  PermissionUpdatedModal,
};
