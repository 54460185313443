import clone from 'lodash/clone';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import set from 'lodash/set';

export const toNestedReducer =
  (reducer: (...args: Array<any>) => any, getStatePath: (...args: Array<any>) => any) =>
  (state: Record<string, any>, action: Record<string, any>) => {
    const statePath = getStatePath(action);
    const hasPath = !isEmpty(statePath);
    const stateByPath = hasPath ? get(state, statePath) : state;
    const reducerResult = reducer(stateByPath, action);
    return hasPath ? set(clone(state), statePath || [], reducerResult) : reducerResult;
  };
