import { t } from 'Utilities/i18n/index';

export default function () {
  return [
    {
      party: t('Agency Analytics'),
      label: t('Imports all your data.'),
    },
    {
      party: t('Authoritylabs'),
      label: t('Contact support for more information.'),
    },
    {
      party: t('AWR Cloud'),
      label: t('Imports history for a single domain.'),
    },
    {
      party: t('AWR Desktop'),
      label: t('Imports history for a single domain.'),
    },
    {
      party: t('Link Assistant Rank Tracker'),
      label: t('Imports history for a single domain.'),
    },
    {
      party: t('Positionly'),
      label: t('Contact support for more information.'),
    },
    {
      party: t('ProRankTracker'),
      label: t('Imports all your data.'),
    },
    {
      party: t('Rank Ranger'),
      label: t('Imports history for a single domain.'),
    },
    {
      party: t('SERanking'),
      label: t('Contact support for more information.'),
    },
    {
      party: t('Serpbook'),
      label: t('Imports all your data.'),
    },
    {
      party: t('Serpfox'),
      label: t('Imports one or more domains.'),
    },
  ];
}
