import HelpGuideButton from 'Components/HelpGuideButton';
import Segment, { Images } from 'Components/Segment';
import styles from 'Pages/KeywordDiscovery/KeywordDiscovery.module.scss';
import { keydisHelpGuideURL } from 'Pages/KeywordDiscovery/components/Actions/Actions';
import KeydisScreenshot from 'Pages/KeywordDiscovery/support/keydisScreenshot.png';
import { t } from 'Utilities/i18n';
import { ActionsContainer } from '../../../Layout/ActionsMenu/Actions';

export const KeywordDiscoveryPlaceholderPage = () => (
  <>
    <ActionsContainer className={styles.actionsMenu}>
      <div className={styles.actionGroupContainer}>
        <div className={styles.actionGroup}>
          <HelpGuideButton helpguideLink={keydisHelpGuideURL} />
        </div>
      </div>
    </ActionsContainer>
    <div className={styles.contentContainer}>
      <div className={styles.tableContainer}>
        <div className={styles.paidContentWrapper}>
          <Segment placeholder image={Images.ChillingTiger} className={styles.paidContentSegment}>
            <h3>{t('Paid Content')}</h3>
            <p>{t('The Keyword Discovery tool is only available on paid plans.')}</p>
          </Segment>
          <img src={KeydisScreenshot} className={styles.placeholderImage} />
        </div>
      </div>
    </div>
  </>
);
