import React from 'react';
import { Popover, PopoverDropdownProps, PopoverProps } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import cn from 'classnames';
import isFunction from 'lodash/isFunction';
import styles from './popover.module.scss';

export type InjectedPopoverBodyProps = {
  closePopover: () => void;
  openPopover: () => void;
};

type AccPopoverProps = {
  target: JSX.Element;
  children:
    | JSX.Element
    | JSX.Element[]
    | React.FC<{ [key: string]: unknown } & InjectedPopoverBodyProps>;
  position?: PopoverProps['position'];
  width?: number;
  body?: PopoverDropdownProps;
  withArrow?: boolean;
  trapFocus?: boolean;
  withPortal?: boolean;
  disabled?: boolean;
  /** max width for the dropdown container */
  maxWidth?: number;
  targetToggle?: boolean;
  offset?: number;

  /**
   * Called after popover closed
   */
  onClose?: Function;

  controlsRef?: React.MutableRefObject<InjectedPopoverBodyProps | null>;
};

/**
 * Display popover section relative to given target element
 * opens on click
 * @docs
 * - Opening popover outside the component can work not properly if target not memoized, solution is to wrap target with useMemo
 * - target should call `onClick` properly, should work out of the box of html elements, for custom components need to properly call it on event
 */
export const AccPopover = (props: AccPopoverProps) => {
  const [opened, { close, open, toggle }] = useDisclosure(false);
  const onClose = () => {
    close();
    if (props.onClose) {
      // requestAnimationFrame required to avoid UI jumping during hide process, it fire event after we already closed popover
      requestAnimationFrame(() => props.onClose?.());
    }
  };
  const controls = { closePopover: onClose, openPopover: open };

  if (props.controlsRef) {
    props.controlsRef.current = controls;
  }

  return (
    <Popover
      width={props.width ?? 'auto'}
      position={props.position ?? 'bottom'}
      shadow="md"
      disabled={props.disabled}
      opened={opened}
      onClose={onClose}
      withArrow={props.withArrow}
      trapFocus={props.trapFocus}
      transitionProps={{ exitDuration: 0 }}
      withinPortal={props.withPortal}
      closeOnEscape
      offset={props.offset}
    >
      <Popover.Target>
        {React.cloneElement(props.target, {
          onClick: props.targetToggle ? toggle : open,
        })}
      </Popover.Target>
      <Popover.Dropdown
        {...props.body}
        className={cn(styles.menu, { [styles.closed]: !opened })}
        maw={props.maxWidth}
      >
        {isFunction(props.children) ? props.children(controls) : (props.children as JSX.Element)}
      </Popover.Dropdown>
    </Popover>
  );
};

AccPopover.defaultProps = {
  withArrow: true,
};
