import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import * as Sentry from '@sentry/react';
import { createSelector } from 'reselect';
import {
  GroupItem,
  GroupsDomainItem,
} from 'Components/Controls/Dropdowns/GroupsAndDomainsDropdown';
import {
  useGroupsAndDomainsDeleteDomainMutation,
  useGroupsAndDomainsUpdateClientMutation,
  useQuickNavigationDataQuery,
} from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import toast from 'Hooks/useToast';
import { pathToLabelMap } from 'Pages/Layout/DashboardTemplate/SideNavbar/support/useKeywordNavItems';
import { useOrganizationInfo } from 'Selectors/OrganizationSelector';
import SpecificFilterSelector from 'Selectors/SpecificFilterSelector';
import { FilterAttribute } from 'Types/Filter';
import { EventName, trackEventMixpanel } from 'Utilities/Analytics/mixpanel';
import {
  useSmartRouterSubscription,
  useStableNavigate,
} from 'Utilities/Router/components/LocationPathnameContext';
import {
  domainDeleteConfirmation,
  groupDeleteConfirmation,
} from 'Utilities/getConfirmationDescription';
import { t } from 'Utilities/i18n';
import { sleep } from 'Utilities/promise';
import { notEmpty, redirectToExternalUrl } from 'Utilities/underdash';
import { DOMAIN, GROUP, useSubscribeToTopic } from 'Utilities/websocket';
import { buildGroups, getGroupLink } from './helpers';

const domainsFilterSelector = createSelector(
  SpecificFilterSelector(FilterAttribute.DOMAINS),
  (item) => item?.value.join(',') ?? '',
);

const WAIT_TIME = 1000;

export const useGroupsMenuData = () => {
  const { data, refetch, loading } = useQuickNavigationDataQuery();
  const domainIdsString = useSelector(domainsFilterSelector);
  const location = useLocation();

  const domainIds = (domainIdsString || '')?.split?.(',') ?? [];

  const groups = buildGroups(data?.user?.quickNavigationData);
  //Fallback for empty groups where we cannot filter on domainId's
  const groupItemFromRouterState = groups.find(
    (group) => group.id === (location.state?.groupId as number | undefined),
  );
  const selectedGroupItem =
    groups.find((client) => client.domainIds?.includes(domainIds[0])) || groupItemFromRouterState;
  const domains = selectedGroupItem ? selectedGroupItem.domains : [];
  const selectedDomainItem =
    domainIds.length === 1
      ? domains?.filter(notEmpty).find((domain) => domain.id === domainIds[0])
      : null;

  useSubscribeToTopic([
    {
      action: DOMAIN,
      cb: () => refetch(),
    },
    {
      action: GROUP,
      cb: () => refetch(),
    },
  ]);

  return {
    groups,
    loading,
    refetch,
    selectedGroupItem,
    selectedDomainItem,
    domainIds,
  };
};

export const useGroupsNavigationActions = (
  domainIds: string[],
  refetch: () => Promise<any>,
  callbacks: {
    onAdd?: (groupId: string, data: any, tries: number) => void;
    afterGroupDelete?: (groupId: number, groupIncludesCurrentDomain: boolean) => void;
    onEditDomain?: () => void;
  },
) => {
  const handleRefetch = async () => {
    await sleep(WAIT_TIME);
    return refetch();
  };
  const { showModal } = useModal();
  const navigate = useStableNavigate();
  const { organization } = useOrganizationInfo();

  const [deleteClientMutation] = useGroupsAndDomainsUpdateClientMutation();
  const [deleteDomainMutation] = useGroupsAndDomainsDeleteDomainMutation();

  const onGroupSelect = (group: GroupItem) => {
    navigate(getGroupLink(group), { state: { groupId: group.id } });
  };

  const onAddGroup = () => {
    showModal({
      modalType: 'AddGroup',
      modalTheme: 'light',
      modalProps: {
        refetch,
        onAddCallback: callbacks?.onAdd,
      },
    });
  };

  const onEditGroup = (event: React.MouseEvent<HTMLButtonElement>, groupObj: GroupItem) => {
    event.stopPropagation();
    showModal({
      modalType: 'EditGroup',
      modalTheme: 'light',
      modalProps: {
        groupId: groupObj.id,
        refetch: handleRefetch,
        initialValues: {
          groupName: groupObj.displayName,
        },
      },
    });
  };

  const showDeleteConfirmation = (
    type: 'group' | 'domain',
    action: (() => Promise<void>) | (() => void),
    groupObj: GroupItem | GroupsDomainItem,
  ) => {
    const displayName = groupObj.displayName;
    const domain = 'domain' in groupObj && groupObj.domain;

    showModal({
      modalType: 'Confirmation',
      modalProps: {
        title: type === 'group' ? t('Delete Group?') : t('Delete Domain?'),
        description:
          type === 'group'
            ? groupDeleteConfirmation(displayName || '')
            : domainDeleteConfirmation(domain || ''),
        cancelLabel: t('Cancel'),
        confirmLabel: type === 'group' ? t('Delete group') : t('Delete domain'),
        action,
      },
    });
  };

  const onDeleteGroup = (event: React.MouseEvent<HTMLButtonElement>, groupObj: GroupItem) => {
    event?.stopPropagation?.();
    const { id, displayName, domainIds: domainIdList } = groupObj;
    const onDeleteGroupMutation = () =>
      deleteClientMutation({
        variables: {
          input: {
            id: id.toString(),
            name: displayName,
            organization: organization?.id || '',
            delete: true,
          },
        },
      }).then((res) => {
        const errors = res.data?.updateClient?.errors;
        if (errors && errors.length) {
          toast.error(t('Could not delete group'));
          return;
        }

        toast.success(t('Group deleted'));
        handleRefetch();

        const containsDomains =
          (domainIdList && domainIdList.some((domainId) => domainIds.includes(domainId))) || false;
        callbacks?.afterGroupDelete?.(id, containsDomains);
      });

    showDeleteConfirmation('group', onDeleteGroupMutation, groupObj);
  };

  const onDomainsDelete = (
    event: React.MouseEvent<HTMLButtonElement>,
    domainObj: GroupsDomainItem,
  ) => {
    event?.stopPropagation?.();

    const { id } = domainObj;
    const deleteDomain = () => {
      deleteDomainMutation({
        variables: {
          input: {
            id,
          },
        },
      }).then((res) => {
        const errors = res?.data?.deleteDomain?.errors;
        if (errors && errors.length) {
          toast.error(t('Could not delete domain'));
          trackEventMixpanel(EventName.DeleteDomainFailed, '', {
            'Deleted Domain ID': id,
            Source: 'GroupsAndDomains',
            Error: `${errors?.[0]?.field}: ${errors?.[0]?.messages?.join('')}`,
          });
          return;
        }

        trackEventMixpanel(EventName.DeleteDomainSuccess, '', {
          'Deleted Domain ID': id,
          Source: 'GroupsAndDomains',
        });

        toast.success(t('Domain deleted'));
        handleRefetch();

        if (!Array.isArray(domainIds)) {
          Sentry.captureException(`Domain ids is not an array: ${domainIds}`);
        }

        const firstDomain = Array.isArray(domainIds) ? domainIds.at(0) : undefined;
        if (firstDomain === id) {
          redirectToExternalUrl('/domains');
        }
      });
    };

    return showDeleteConfirmation('domain', deleteDomain, domainObj);
  };

  const onDomainsAdd = (groupId?: number) => () => {
    showModal({
      modalType: 'BuildDomain',
      modalTheme: 'light',
      modalProps: {
        groupId,
        onClose: handleRefetch,
      },
    });
  };

  const onDomainsEdit = (
    event: React.MouseEvent<HTMLButtonElement>,
    domainObj: GroupsDomainItem,
  ) => {
    event.stopPropagation();
    showModal({
      modalType: 'BuildDomain',
      modalTheme: 'light',
      modalProps: {
        domainId: domainObj.id,
        onClose: () => {
          handleRefetch;
          callbacks?.onEditDomain?.();
        },
      },
    });
  };

  return {
    onGroupSelect,
    onEditGroup,
    onAddGroup,
    onDeleteGroup,
    onDomainsAdd,
    onDomainsEdit,
    onDomainsDelete,
  };
};

export const useKeywordsPageHeader = () => {
  const labelMap = pathToLabelMap();

  const getPathnameMatch = (pathname: string, map: Record<string, string>) => {
    for (const substring in map) {
      if (pathname.includes(substring)) {
        return map[substring];
      }
    }
    return '';
  };

  return useSmartRouterSubscription((pathname) => {
    return getPathnameMatch(pathname, labelMap);
  });
};
