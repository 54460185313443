import * as React from 'react';
import { useEffect } from 'react';
import { EventName, useMixpanel } from 'Utilities/Analytics/mixpanel';
import { useRouteMatch } from 'Utilities/Router/hooks/useRouteMatch';
import { updateTitleTag } from 'Utilities/titleTag';

const withAnalytics = (
  Component: React.ComponentType<React.PropsWithChildren<unknown>>,
  getTitle: () => string,
) => {
  const WrappedComponent = (props: unknown) => {
    const trackEvent = useMixpanel();
    const match = useRouteMatch();
    useEffect(() => {
      const fullPath = `/app${match?.path}`;

      trackEvent(EventName.PageView, { Page: fullPath });
      updateTitleTag({
        prefix: '',
        content: getTitle() || '',
      });
    }, [match.path]);

    return Component ? <Component {...(props as object)} /> : null;
  };
  WrappedComponent.displayName = `withAnalytics(${WrappedComponent.displayName || 'Component'})`;
  return WrappedComponent;
};

export default withAnalytics;
