import { useSelector } from 'react-redux';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { useContextTableStore } from 'Components/DataTable/store/components/TableStoreContext';
import useSticky from 'Components/DataTable/table-core/hooks/useSticky';
import AccText from 'Components/Text/AccText';
import { tn } from 'Utilities/i18n';
import { VisibleFiltersSelector } from '../../../../../Selectors/FiltersSelector';
import { tableClassName } from '../../../helpers';
import {
  getIsAllSelected,
  getSelectButtonText,
  getSelectedTextInfo,
  getShowSelectAll,
} from './helpers';
import './style.scss';

// Temporary solution until new sticky select actions are implemented
const HEADER_HEIGHT = 40;

type DynamicSelectionInfoProps = {
  skipSelectedCount?: boolean;
};

// - initial requirements https://accuranker.myjetbrains.com/youtrack/issue/ARR-1557
// - additional requirements for keydis case https://accuranker.myjetbrains.com/youtrack/issue/ARR-2086
export const DynamicSelectionInfo = observer(({ skipSelectedCount }: DynamicSelectionInfoProps) => {
  const { offsetHeader } = useSticky(false, '');
  const tableStore = useContextTableStore();
  // TODO add filter type in future
  const filters: any[] = useSelector(VisibleFiltersSelector);

  const hasVisibleFilter = filters?.length > 0;
  if (!tableStore) {
    return null;
  }

  const allSelected = getIsAllSelected(tableStore);
  const hasMaxSelect = tableStore.hasMaxSelect;
  if (allSelected && !skipSelectedCount) {
    return (
      <div
        className={cn(tableClassName('info-row'), tableClassName('info-row--selection'))}
        style={{ top: offsetHeader + HEADER_HEIGHT }}
      >
        <div data-info-row-content>
          <span>
            {getSelectedTextInfo(
              tableStore.totalSelectedCount ?? 0,
              hasVisibleFilter,
              hasMaxSelect,
            )}
          </span>
        </div>
      </div>
    );
  }

  const showSelectAll = getShowSelectAll(tableStore);

  if (!showSelectAll) {
    return null;
  }
  const maxSelectCount = hasMaxSelect
    ? tableStore?.currentMaxSelectCount ?? 0
    : tableStore?.totalDataLength;

  return (
    <div
      className={cn(tableClassName('info-row'), tableClassName('info-row--selection'))}
      style={{ top: offsetHeader + HEADER_HEIGHT }}
    >
      <div data-info-row-content="true">
        {tableStore.singleRowSelect ? (
          <span>
            {tn(
              '%s keyword on this page selected.',
              '%s keywords on this page selected.',
              tableStore?.pageSelected,
            )}
          </span>
        ) : (
          <span>
            {tn('%s keyword selected.', '%s keywords selected.', tableStore.totalSelectedCount)}
          </span>
        )}
        <AccText variant="link" fw={'bold'} onClick={tableStore.selectAll} size={'md'} ml={8}>
          {getSelectButtonText(hasVisibleFilter, maxSelectCount, hasMaxSelect)}
        </AccText>
      </div>
    </div>
  );
});
