import { useMemo } from 'react';
import { BubbleChartData } from 'Components/Chart/BubbleChart/support/types';
import { useCompetitorBubbleChartQuery, useKeydisCompetitorsQuery } from 'Ghql';
import { useFilters } from 'Hooks';
import { useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import { FilterAttribute } from 'Types/Filter';
import { getCompetitorAndDomainColors } from 'Utilities/colors';
import { t } from 'Utilities/i18n';

export const useBubbleCompetitors = ({ isKeydis, country }) => {
  const filters = useFilters();
  const { domainInfo, colorMap, displayNameMap, loadingDomainInfo, isDemoDomain } =
    useQueryDomainInfo();

  const noDomainsFilter = !filters.find(
    (f) =>
      f.attribute === FilterAttribute.FREE_TEXT_DOMAIN || f.attribute === FilterAttribute.DOMAINS,
  );

  const skipKeydisCompetitors = !isKeydis || !country || noDomainsFilter;
  const {
    data: keydisData,
    loading: keydisLoading,
    refetch: onKeydisRefetch,
  } = useKeydisCompetitorsQuery({
    variables: { filters, country },
    skip: skipKeydisCompetitors,
  });

  const keydisRefetch = () => {
    if (!skipKeydisCompetitors) {
      return onKeydisRefetch();
    }
  };
  const {
    data: grankData,
    loading: grankLoading,
    refetch: grankRefresh,
  } = useCompetitorBubbleChartQuery({
    variables: { filters, name: 'competitorBubbleChart' },
    skip: isKeydis,
  });

  const data = keydisData?.keydisCompetitors || grankData?.charts?.competitorStats;
  const loading = keydisLoading || grankLoading;
  const refetch = keydisRefetch || grankRefresh;

  const domains = data?.map((competitor) => competitor?.domain) || [];
  const colors = getCompetitorAndDomainColors(colorMap, domains);

  const resultData: BubbleChartData[] = useMemo(() => {
    return (
      data?.map((e, i) => ({
        x: (e?.keywords || 0.000001) + Math.random() * 0.0001, // jitter - points with the same x value are fucked sometimes, see https://accuranker.myjetbrains.com/youtrack/agiles/124-5/current?issue=ARR-2826
        y: e?.searchVolume || 0.000001, // For unknown reasons, 0s are not displayed properly on the chart
        z: e?.shareOfVoice || 0.000001,
        color: colors[i],
        primary: i === 0,
        name: e?.domain ?? '',
        displayName: (e?.domain && displayNameMap?.[e?.domain]) || '',
        highlighted: e?.domain === domainInfo?.domain,
      })) ?? []
    ).sort((a, b) => b.x - a.x);
  }, [data, domainInfo?.domain]);

  const fakeCompetitorData: BubbleChartData[] = useMemo(() => {
    const coordinates = [
      { x: 3, y: 2, z: 30 },
      { x: 4, y: 4, z: 40 },
      { x: 6, y: 6, z: 50 },
      { x: 16, y: 8, z: 60 },
    ];
    const names = [resultData?.[0]?.name || '', 'adidas.co.uk', 'amazon.com', 'google.com'];
    const fakeColors = getCompetitorAndDomainColors(colorMap, names);
    const displayNames = [
      resultData?.[0]?.displayName || '',
      t('Competitor 1'),
      t('Competitor 2'),
      t('Competitor 3'),
    ];
    const primaryArray = [true, false, false, false];
    const highlightedArray = [true, false, false, false];

    return Array.from(Array(4)).map((_, index) => ({
      x: coordinates[index].x,
      y: coordinates[index].y,
      z: coordinates[index].z,
      color: fakeColors[index % fakeColors.length],
      name: names[index],
      primary: primaryArray[index],
      displayName: displayNames[index],
      highlighted: highlightedArray[index],
    }));
  }, [data]);

  return {
    data: resultData,
    loading: loading || loadingDomainInfo,
    refetch,
    fakeCompetitorData,
    isDemoDomain,
  };
};
