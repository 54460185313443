import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import { useInView } from 'Hooks/intersectionObserver/useInView';
import useUserPermission from 'Hooks/useUserPermission';
import { withRowOptimization } from '../../hoc/withRowOptimization';
import Cell from '../Cell';
import BodyContext from '../context/BodyContext';
import RowContext, { RowContextProps } from '../context/RowContext';
import TableContext from '../context/TableContext';
import type {
  CustomizeComponent,
  ExpandableRowsConfig,
  GetComponentProps,
  GetRowKey,
  Key,
} from '../interface';
import { getColumnsKey } from '../utils/valueUtil';
import { ExtraBodyRows } from './ExtraBodyRows';

interface BodyRowProps<RecordType> {
  record: RecordType;
  index: number;
  className?: string;
  style?: React.CSSProperties;
  recordKey: Key;
  expandedKeys: Set<Key>;
  rowComponent: CustomizeComponent;
  cellComponent: CustomizeComponent;
  onRow: GetComponentProps<RecordType>;
  rowExpandable: (record: RecordType) => boolean;
  indent?: number;
  rowKey: React.Key;
  getRowKey: GetRowKey<RecordType>;
  childrenColumnName: string;
  expandRowConfig?: ExpandableRowsConfig;
  isPlaceholder?: boolean;
  primaryColumns?: string[];
}

function BodyRow<RecordType extends { children?: readonly RecordType[] }>(
  props: BodyRowProps<RecordType>,
) {
  const {
    className,
    style,
    record,
    index,
    rowKey,
    rowExpandable,
    onRow,
    indent = 0,
    rowComponent: RowComponent,
    cellComponent,
  } = props;
  const { ref, inView } = useInView({
    rootMargin: '200px',
    triggerOnce: true,
  });
  const { userHasWriteAccess } = useUserPermission();
  const { prefixCls, fixedInfoList } = React.useContext(TableContext);
  const { flattenColumns, expandableType, expandRowByClick, onTriggerExpand, rowClassName } =
    React.useContext(BodyContext);
  const rowSupportExpand = expandableType === 'row' && (!rowExpandable || rowExpandable(record));
  // Only when row is not expandable and `children` exist in record
  const nestExpandable = expandableType === 'nest';
  const mergedExpandable = rowSupportExpand || nestExpandable;

  // ======================== Expandable =========================
  const onExpandRef = React.useRef(onTriggerExpand);
  onExpandRef.current = onTriggerExpand;

  const onInternalTriggerExpand = (...args: Parameters<typeof onTriggerExpand>) => {
    onExpandRef.current(...args);
  };

  // =========================== onRow ===========================
  let additionalProps: React.HTMLAttributes<HTMLElement> | any;
  if (onRow) {
    additionalProps = onRow(record, index);
  }

  const onClick: React.MouseEventHandler<HTMLElement> = (event, ...args) => {
    if (expandRowByClick && mergedExpandable) {
      onInternalTriggerExpand(record, event);
    }

    if (additionalProps && additionalProps.onClick) {
      additionalProps.onClick(event, ...args);
    }
  };

  // ======================== Base tr row ========================
  let computeRowClassName: string | undefined;
  if (typeof rowClassName === 'string') {
    computeRowClassName = rowClassName;
  } else if (typeof rowClassName === 'function') {
    computeRowClassName = rowClassName(record, index, indent);
  }

  const nonVisibleColsLength = flattenColumns.filter(
    (e) => !props.primaryColumns?.includes(e.id || ''),
  )?.length;
  let showed = false;

  const columnsKey = getColumnsKey(flattenColumns);
  const baseRowNode = (
    <RowComponent
      ref={ref}
      {...additionalProps}
      data-row-key={rowKey}
      className={classNames(
        className,
        `${prefixCls}-row`,
        `${prefixCls}-row-level-${indent}`,
        computeRowClassName,
        additionalProps && additionalProps.className,
      )}
      style={{
        ...style,
        ...(additionalProps ? additionalProps.style : null),
      }}
      data-index={index}
      onClick={onClick}
    >
      {flattenColumns.map((column: any, colIndex) => {
        const { render, dataIndex, className: columnClassName } = column;

        if (props.isPlaceholder && !props.primaryColumns?.includes(column.id)) {
          if (!showed) {
            showed = true;
            return <td colSpan={nonVisibleColsLength} key={colIndex} />;
          }
          return null;
        }

        const key = columnsKey[colIndex];
        const fixedInfo = fixedInfoList[colIndex];
        let additionalCellProps: React.HTMLAttributes<HTMLElement> | any;
        if (column.onCell) {
          additionalCellProps = column.onCell(record, index);
        }

        return (
          <Cell
            cellId={column?.id}
            className={columnClassName}
            ellipsis={column.ellipsis}
            align={column.align}
            component={cellComponent}
            prefixCls={prefixCls}
            key={key}
            record={record}
            index={index}
            dataIndex={dataIndex}
            render={render}
            shouldCellUpdate={column.shouldCellUpdate}
            {...fixedInfo}
            additionalProps={additionalCellProps as any}
          />
        );
      })}
    </RowComponent>
  );
  let expandRowNode: React.ReactElement | null = null;

  const [expandExtraRows, setExpandExtraRows] = useState<boolean>(false);
  const [customExpand, setCustomExpand] = useState<boolean>(false);
  const [expandComponentProps, setExpandComponentProps] = useState<{ [key: string]: boolean }>({});

  const rowContext: RowContextProps = useMemo(
    () => ({
      id: rowKey,
      expandExtraRows,
      customExpand,
      inView,
      userHasWriteAccess,
      toggleExpandRows: () => {
        setCustomExpand(false);
        setExpandExtraRows(!expandExtraRows);
      },
      toggleCustomExpand: (toggleCustomExpandProps: { [key: string]: boolean }) => {
        setExpandExtraRows(false);
        setCustomExpand(!customExpand);
        setExpandComponentProps(toggleCustomExpandProps || {});
        if (
          // Specific case for switching between AI and Non AI Search Volume dropdowns
          // Make sure we open new one when switching between AI/Non AI
          toggleCustomExpandProps &&
          expandComponentProps?.useAi !== toggleCustomExpandProps?.useAi
        ) {
          setCustomExpand(true);
        }
      },
      expandComponentProps,
    }),
    [
      expandExtraRows,
      setExpandExtraRows,
      customExpand,
      setCustomExpand,
      rowKey,
      inView,
      expandComponentProps,
    ],
  );

  const { customExpandComponent: ExpandComponent } = props.expandRowConfig || ({} as any);

  if (expandExtraRows && props.expandRowConfig) {
    expandRowNode = (
      <ExtraBodyRows record={record} bodyRowProps={props} expandRowConfig={props.expandRowConfig} />
    );
  }
  const customExpandElement = customExpand && ExpandComponent && (
    <ExpandComponent {...{ ...expandComponentProps, id: rowKey }} />
  );
  return (
    <RowContext.Provider value={rowContext}>
      {baseRowNode}
      {customExpandElement}
      {expandRowNode}
    </RowContext.Provider>
  );
}

BodyRow.displayName = 'BodyRow';

const MemoBodyRow = withRowOptimization(React.memo<any>(BodyRow, isEqual));
export default MemoBodyRow;
