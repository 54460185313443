import { Component } from 'react';
import { withRouter } from 'Utilities/Router';
import UpdatePaymentMethodWidget from './UpdatePaymentMethodWidget';
import styles from './update-payment.module.scss';

type Props = {
  backLink?: string;
  history: Record<string, any>;
};

class UpdatePaymentMethod extends Component<Props> {
  static defaultProps = {
    backLink: null,
  };
  handleBack = () => {
    const { history, backLink } = this.props;

    if (backLink) {
      history.push(backLink);
    } else {
      // Wait 5 seconds before redirecting. This is done in case we are here because of a failed payment that needs some time to run.
      setTimeout(() => {
        // TODO FixTSignore
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.location = '/app/account/billing';
      }, 5000);
    }
  };

  render() {
    return (
      <div className={styles.updatePaymentContainer}>
        <UpdatePaymentMethodWidget onBack={this.handleBack} onUpdate={this.handleBack} />
      </div>
    );
  }
}

export default withRouter(UpdatePaymentMethod as any) as any;
