import { TableSize } from 'Components/DataTable';

const tagSize = {
  CHARACTER_WIDTH: 5,
  TOTAL_WIDTH: 180,
  TAG_OFFSET: 15,
  TAG_MAX_WIDTH: 77,
  INFO_KEYWORD: 17,
};

export interface BaseTag {
  tag: string;
  dynamic: boolean;
}

export const concatTags = (tags?: string[], dynamicTags?: string[]): BaseTag[] => {
  return [
    ...(tags?.map((e) => ({ tag: e, dynamic: false })) ?? []),
    ...(dynamicTags?.map((e) => ({ tag: e, dynamic: true })) ?? []),
  ];
};

export const getVisibleTags = (
  tags?: BaseTag[],
  view?: string,
  infoIconsLength?: number,
): number => {
  if (view !== TableSize.DEFAULT) {
    return 0;
  }
  let width = tagSize.TOTAL_WIDTH - (infoIconsLength ?? 0) * tagSize.INFO_KEYWORD;

  return (
    tags
      ?.map((e) =>
        Math.min(
          e.tag?.length * tagSize.CHARACTER_WIDTH + tagSize.TAG_OFFSET,
          tagSize.TAG_MAX_WIDTH,
        ),
      )
      ?.reduce((acc: number, num: number) => {
        width -= num;
        if (width > 0) {
          return acc + 1;
        }
        return acc;
      }, 0) ?? 0
  );
};
