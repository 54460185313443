import times from 'lodash/times';
import { STICKY_FILTER_HEIGHT } from '../../../../Constants/sticky';
import { devError } from '../../../../Utilities/log';
import { OVER_SCAN_ITEMS_COUNT, ROW_SIZE, VIRTUALIZED_TABLE_ID } from './constants';

/**
 * Calculate index and top offset of the rows.
 * we have 3 stages and logic of calculating top position, based on sticky body states:
 * - non-stick at the top, in this case we have top as 0, since container will be scrollable
 * - sticky in the middle, we need to calculate proper offset, to emulate scrolling like movement of the rows
 * - non-sticky at the bottom, where we calculate specific top position, so body scroll will be natural.
 *
 */
export const calculateElementsPosition = (itemsPerScreen, containerRef, itemsCount) => {
  const items = times(itemsPerScreen);

  if (!containerRef?.current || !itemsCount) {
    return items.map((_, i) => ({ top: i * ROW_SIZE, index: i }));
  }

  const containerTop = containerRef.current.getBoundingClientRect().top;

  const containerHeight = itemsCount * ROW_SIZE;

  let scrollTop = Math.abs(Math.max(-containerTop, 0));
  let scrollY = scrollTop % (itemsPerScreen * ROW_SIZE);

  const stickyContainerHeight = itemsPerScreen * ROW_SIZE;

  const isStickyBottom = containerTop + containerHeight - stickyContainerHeight <= 0;

  if (isStickyBottom) {
    // calculate last scrollY since block is not sticky more and it will move without any top updates required
    scrollY = (containerHeight - stickyContainerHeight) % (itemsPerScreen * ROW_SIZE);
    scrollTop = containerHeight - stickyContainerHeight;
  }
  const totalIndex = Math.floor(scrollTop / ROW_SIZE);
  const index = Math.floor(scrollY / ROW_SIZE) % items.length;

  const shiftEmptyRows = ROW_SIZE * Math.max(0, itemsPerScreen - itemsCount + totalIndex);

  return items.map((_, i) => {
    if (i < index) {
      return {
        top: (items.length + i) * ROW_SIZE - scrollY + shiftEmptyRows,
        index: totalIndex + items.length - index + i,
      };
    }

    return { top: i * ROW_SIZE - scrollY + shiftEmptyRows, index: totalIndex - index + i };
  });
};

export const calculateItemsPerScreen = (itemsCount: number) => {
  return Math.min(itemsCount, Math.floor(window.innerHeight / ROW_SIZE) + OVER_SCAN_ITEMS_COUNT);
};

export const updateBodyScrolling = (isScrolling: boolean) => {
  const nextPointerEvents = isScrolling ? 'none' : '';

  if (document.body.style.pointerEvents !== nextPointerEvents) {
    document.body.style.pointerEvents = nextPointerEvents;
  }
};

export const getStoreKey = (itemsPerScreen, itemsCount) => `${itemsPerScreen}#${itemsCount}`;

export const getTopOffsetForVirtualizedTable = (topStickyOffset: number = 0) => {
  const DEFAULT_TOP_OFFSET = 300;
  try {
    const result =
      document.getElementById(VIRTUALIZED_TABLE_ID)!.getBoundingClientRect().top -
      document.getElementById('root')!.getBoundingClientRect().top -
      STICKY_FILTER_HEIGHT -
      topStickyOffset;

    if (!result) {
      devError('Top offset was calculated wrong');
      return DEFAULT_TOP_OFFSET;
    }
    return result;
  } catch (e) {
    return DEFAULT_TOP_OFFSET;
  }
};

export const getIsBottomOfVirtualizedTableSticky = () => {
  const result =
    document.getElementById(VIRTUALIZED_TABLE_ID)!.getBoundingClientRect().bottom -
    window.innerHeight;

  return result > -ROW_SIZE;
};

export const getIsTopOfVirtualizedTableSticky = (topStickyOffset: number) => {
  const result = getTopOffsetForVirtualizedTable(topStickyOffset) - window.scrollY;

  return result < 0;
};
