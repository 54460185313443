import { useMantineTheme } from '@mantine/core';
import { AccFastIcon } from 'Components/AccFastIcon';
import { t } from 'Utilities/i18n';
import CheckIcon from 'icons/check-1.svg';
import DashedCircleIcon from 'icons/circle-dashed.svg';
import XIcon from 'icons/x.svg';
import { RankingPageStatus } from './constants';

export const calculateHighestRankingPageStatus = (data: any) => {
  if (!data?.preferredLandingPageId) {
    return RankingPageStatus.ORANGE;
  } else if (data?.preferredLandingPageId?.toString() === data?.landingPageId?.toString()) {
    return RankingPageStatus.GREEN;
  }

  return RankingPageStatus.RED;
};

export const useGetContentAndTooltip = (
  status: number,
  userHasWriteAccess: boolean,
): { content: JSX.Element; tooltip: string } => {
  const theme = useMantineTheme();

  if (status === RankingPageStatus.GREEN) {
    return {
      content: (
        <div className="url-status-green">
          <AccFastIcon size={8} color={theme.white} src={CheckIcon} />
        </div>
      ),
      tooltip: t('Preferred URL matches ranked page'),
    };
  }
  if (status === RankingPageStatus.ORANGE) {
    return {
      content: (
        <div>
          <AccFastIcon size={16} color={theme.colors.yellow[9]} src={DashedCircleIcon} />
        </div>
      ),
      tooltip: userHasWriteAccess
        ? t('Set the preferred URL for this keyword')
        : t('No preferred URL set'),
    };
  }
  return {
    content: (
      <div className="url-status-red">
        <AccFastIcon size={8} color={theme.white} src={XIcon} />
      </div>
    ),
    tooltip: t('Not ranked for correct preferred URL'),
  };
};
