import { useMemo, useRef } from 'react';
import AccButton from 'Components/AccButton/AccButton';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import Skeleton from 'Components/Skeleton';
import { useGetAffiliateLinkDetailsQuery } from 'Ghql';
import { useClipboard } from 'Hooks';
import { t, tct } from 'Utilities/i18n';
import formStyles from 'css/layout/form-layout.module.scss';
import CopyIcon from 'icons/copy.svg?inline';
import './get-affiliate-link.scss';

type Props = {
  link: string;
  placement: string;
  campaign: string;
  onBack: (...args: Array<any>) => any;
};

const GetAffiliateLink = (props: Props) => {
  const { link, placement, campaign, onBack } = props;

  const linkInput = useRef<HTMLInputElement>(null);
  const urlInput = useRef<HTMLInputElement>(null);

  const { data, loading, error } = useGetAffiliateLinkDetailsQuery();

  const clipboard = useClipboard({ timeout: 1000 });

  const initialValues = useMemo(() => {
    return data?.affiliate
      ? (() => {
          let generatedUrl = `${link}?aaid=${data?.affiliate?.details?.affiliateId}`;

          if (placement) {
            generatedUrl = `${generatedUrl}&p=${placement}`;
          }

          if (campaign) {
            generatedUrl = `${generatedUrl}&c=${campaign}`;
          }

          return {
            generatedUrl,
            generatedLink: `<a rel='nofollow' href="${generatedUrl}">AccuRanker</a>`,
          };
        })()
      : null;
  }, [data]);

  const renderSkeleton = () => {
    return (
      <Skeleton
        className="indented-form-group form-group"
        linesConfig={[
          {
            type: 'text',
            options: {
              width: '30%',
            },
          },
          {
            type: 'input',
          },
          {
            type: 'text',
            options: {
              width: '30%',
            },
          },
          {
            type: 'input',
          },
          {
            type: 'button',
            options: {
              width: '15%',
              alignment: 'right',
            },
          },
        ]}
      />
    );
  };

  const renderUrlInput = () => {
    return <input value={initialValues?.generatedUrl} disabled ref={urlInput} />;
  };

  const renderLinkInput = () => {
    return <input value={initialValues?.generatedLink} disabled ref={linkInput} />;
  };

  if (loading || error) {
    return renderSkeleton();
  }

  return (
    <form className="get-affiliate-link-form">
      <div className={formStyles.formRow}>
        <p>
          {t(
            'Your affiliate link has been generated. Use it to link to the AccuRanker website to track your referrals.',
          )}
        </p>

        <p>
          {tct(
            'You can track additional parameters such as \'campaign\', \'placement\' and \'unique\'. Simply use the query parameters \'c\', \'p\', and \'u\'. (e.g. \'[link]&c=test-campaign&p=test-position&u=123\')',
            {
              link: initialValues?.generatedUrl,
            },
          )}
        </p>
      </div>
      <div className={formStyles.formRow}>
        <div className="form-label">{t('Affiliate URL')}</div>
        <div className="public-report-options__input-container">
          {renderUrlInput()}
          <AccTooltip old tooltip={clipboard.copied && t('Copied!')}>
            <span className="copy-button" onClick={() => clipboard.copy(urlInput?.current?.value)}>
              <CopyIcon />
            </span>
          </AccTooltip>
        </div>
      </div>
      <div className={formStyles.formRow}>
        <div className="form-label">{t('Affiliate Link')}</div>
        <div className="public-report-options__input-container">
          {renderLinkInput()}
          <AccTooltip old tooltip={clipboard.copied && t('Copied!')}>
            <span className="copy-button" onClick={() => clipboard.copy(linkInput?.current?.value)}>
              <CopyIcon />
            </span>
          </AccTooltip>
        </div>
      </div>
      <div className={formStyles.formRow}>
        <div className="confirmation-button-wrapper text-right">
          <AccButton onClick={onBack} variant="tertiary">
            {t('Get another link')}
          </AccButton>
        </div>
      </div>
    </form>
  );
};

export default GetAffiliateLink;
