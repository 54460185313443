import NumberEditor from 'Components/Filters/Common/NumberEditor';
import { numberLabelFunc } from 'Components/Filters/Common/labelFunc';
import { FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import type { PageSerpFeaturesRankFilter } from 'Types/Filter';
import { t } from 'Utilities/i18n';
import NoFilterIcon from 'icons/less-than.svg?inline';
import RankIcon from 'icons/rank.svg?inline';

const defaultValue: PageSerpFeaturesRankFilter = {
  attribute: FilterAttribute.PAGE_SERP_FEATURES_RANK,
  type: FilterValueType.NUMBER,
  comparison: FilterComparison.LT,
  value: '' as any,
};

const getData = () => ({
  defaultValue,
  title: t('SERP feature rank'),
  icon: RankIcon,
  editor: NumberEditor,
  tableEditor: NumberEditor,
  tableEditorProps: {
    iconDropdown: true,
    noFilterIcon: NoFilterIcon,
  },
  labelFunc: numberLabelFunc,
});

export default getData;
