import { useState } from 'react';
import { IconCirclePlus } from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import Breadcrumbs from 'Components/Breadcrumbs/Breadcrumbs';
import CrossSellBanner from 'Components/CrossSellBanner';
import { Form } from 'Components/Fields';
import HelpGuideButton from 'Components/HelpGuideButton';
import AccTitle from 'Components/Title/AccTitle';
import TotalUsed from 'Components/TotalUsed';
import { useTotalUsersQuery } from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import ActionbarContainer from 'Pages/Layout/ActionsMenu/components/ActionbarContainer';
import { t } from 'Utilities/i18n/index';
import UsersTable from './UsersTable';
import styles from './usersTable.module.scss';

const UsersPage = () => {
  const [dataKey, setDataKey] = useState<number>(0);
  const { data, loading, refetch } = useTotalUsersQuery({ fetchPolicy: 'network-only' });
  const usersQty = data?.user?.organization?.numberOfUsers ?? 0;
  const maxQty = data?.user?.organization?.activePlan?.maxUsers ?? 0;
  // maximum users reached
  const isMaxQtyUsers = maxQty >= 0 && usersQty >= maxQty;
  const handleUpdateTable = () => {
    refetch();
    setDataKey((prev) => prev + 1);
  };

  const { showModal } = useModal();

  const handleAdd = () => {
    showModal({
      modalType: 'AddUser',
      modalTheme: 'light',
      modalProps: {
        refresh: handleUpdateTable,
      },
    });
  };

  return (
    <>
      <Breadcrumbs />
      <div className={styles.usersTable} key={`${dataKey}-container`}>
        <AccTitle type="h3">{t('Users')}</AccTitle>
        <ActionbarContainer mt="md" key={`${dataKey}-actionbar`}>
          <AccButton
            key="new"
            variant="primary"
            onClick={handleAdd}
            leftSection={<IconCirclePlus size={18} />}
            disabled={isMaxQtyUsers}
            tooltip={isMaxQtyUsers ? t('Maximum users reached') : undefined}
          >
            {t('Add user')}
          </AccButton>
          <HelpGuideButton
            helpguideLink={'https://www.accuranker.com/help/account/user-management/'}
          />
          <ActionbarContainer.Sidebar>
            <TotalUsed loading={loading} label={t('Users')} used={usersQty} total={maxQty} />
          </ActionbarContainer.Sidebar>
        </ActionbarContainer>
        {!loading && isMaxQtyUsers && (
          <CrossSellBanner crossSellId="users" title={t('Need more Users?')}>
            {t('Contact Customer Support to request more Users')}
          </CrossSellBanner>
        )}
        {/* set key to reset form state */}
        <Form onSubmit={() => {}} key={`${dataKey}-form`}>
          <UsersTable
            handleUpdateTable={handleUpdateTable}
            refetchQty={refetch}
            dataKey={dataKey}
          />
        </Form>
      </div>
    </>
  );
};

export default UsersPage;
