import times from 'lodash/times';
import { KeywordRankWithCompetitorRankNode, SimpleCompetitorsNode } from 'Ghql';
import { CompetitorIndexInfo } from './types';

export const getCompetitorRanksExtraColumns = (
  data?: KeywordRankWithCompetitorRankNode | null,
  competitors?: (SimpleCompetitorsNode | null)[] | null,
): CompetitorIndexInfo[] => {
  return times(10)
    .map((i) => {
      const index = i + 1;
      const competitor = competitors?.find(
        (e) => e?.competitorId === data?.[`competitor${index}CompetitorId`],
      );
      if (competitor) {
        return {
          index,
          name: competitor.displayName,
          domain: competitor.domain,
        };
      }
      return;
    })
    .filter(Boolean)
    .map((e) => e as CompetitorIndexInfo);
};
