import { DataTable, QueryOrder } from 'Components/DataTable';
import { TableIDs } from 'Types/Table';
import { t } from 'Utilities/i18n';
import { useAccountsRequestsTableColumns, useFetchPendingRequest } from './support/hooks';

type AccountsRequestsTableProps = {
  handleUpdateTable: () => void;
  dataKey: number;
};

const AccountsRequestsTable = ({
  handleUpdateTable,
  dataKey,
}: AccountsRequestsTableProps): JSX.Element => {
  const fetchData = useFetchPendingRequest();
  const columns = useAccountsRequestsTableColumns({ handleUpdateTable });

  return (
    <DataTable
      tableId={TableIDs.PENDING_REQUESTS}
      dataKey={dataKey.toString()}
      fetchData={fetchData}
      columns={columns}
      emptyOptions={{
        title: t('No Requests'),
        subTitle: t('There are currently no requests.'),
      }}
      defaultOrdering={{
        order: QueryOrder.DESC,
        orderBy: 'toEmail',
      }}
      offlineFilter={{
        tableName: TableIDs.PENDING_REQUESTS,
        skipAll: true,
      }}
    />
  );
};

export default AccountsRequestsTable;
