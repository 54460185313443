import { Group, Stack, Text } from '@mantine/core';

interface OverViewPanelProps {
  title: string;
  leftSlot: JSX.Element;
  rightSlot: JSX.Element;
  aligned: 'left' | 'right';
}

export const OverviewPanel = ({ title, leftSlot, rightSlot, aligned }: OverViewPanelProps) => {
  return (
    <Stack
      style={{
        borderRadius: 5,
        padding: 20,
        gap: 18,
        boxShadow: '0 1px 0 #cecfd2',
        borderTopLeftRadius: aligned === 'left' ? 5 : 0,
        borderTopRightRadius: aligned === 'left' ? 0 : 5,
        borderBottomRightRadius: aligned === 'left' ? 0 : 5,
        borderBottomLeftRadius: aligned === 'left' ? 5 : 0,
        border: '1px solid #cecfd2',
        borderLeftWidth: aligned === 'left' ? 1 : 0,
        flexGrow: 1,
      }}
    >
      <Text size="md" fw={700} color="gray.9">
        {title}
      </Text>
      <Group style={{ gap: 10, width: '100%' }}>
        {leftSlot}
        {rightSlot}
      </Group>
    </Stack>
  );
};
