import { useCallback, useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import isEqual from 'lodash/isEqual';
import { QueryOrder } from 'Constants';
import {
  FolderStructureForDomainDocument,
  FolderStructureForDomainQuery,
  TreeDashboardQuery,
} from 'Ghql';
import { useDeepEffect } from 'Hooks/useDeep';
import toast from 'Hooks/useToast';
import { FolderType } from 'Pages/Keywords/Groupings/groupingTypes';
import { FilterBase } from 'Types/Filter';
import { generateSelectId, getDomainId, selectIdIsDomain } from 'Utilities/generateSelectId';
import { t } from 'Utilities/i18n';
import { notEmpty } from 'Utilities/underdash';
import { ProcessedDashboardTreeNode, TreeDashboard } from './types';

// overwrite path for folder and tag nodes
const processFolderStructure = (folderStructure: FolderType[], domainPath: string) => {
  return folderStructure.map((node) => {
    const type = node.is_folder ? 'folder' : 'tag';
    const path = generateSelectId(type, node.path, domainPath, node.is_dynamic);
    return {
      ...node,
      path,
      isDomainsComparison: true,
      children: node.children?.length ? processFolderStructure(node.children, domainPath) : [],
    };
  });
};

// get only domains selectIds and remove duplicates
const getExpandedDomains = (expandedNodes: string[]) => {
  return [...new Set(expandedNodes.filter(selectIdIsDomain).filter(notEmpty))];
};

export const useTreeStructure = (
  expandedNodes: string[],
  filters: FilterBase[],
  { order, orderBy }: { order: QueryOrder; orderBy: string },
  childrenLoadingKey: string,
  setExpandNodes: (nodes?: string | string[] | ((prev: string[]) => string[])) => string[],
) => {
  const client = useApolloClient();
  const [refetchListener, setRefetchListener] = useState(0);
  const [data, setData] = useState<ProcessedDashboardTreeNode[]>([]);
  const [treeStructureData, setTreeData] = useState<TreeDashboardQuery | undefined>(undefined);
  const [expandedDomains, setExpandedDomains] = useState<string[]>(
    getExpandedDomains(expandedNodes),
  );

  const refetch = useCallback(() => {
    setRefetchListener((prev) => prev + 1);
  }, []);

  const expandDomains = useCallback(
    (nodes: string[]) => {
      const newExpandedDomains = getExpandedDomains(nodes);
      if (!isEqual(newExpandedDomains.sort(), expandedDomains.sort())) {
        setExpandedDomains(newExpandedDomains);
      }
    },
    [expandedDomains],
  );

  useDeepEffect(() => {
    const clients: TreeDashboard['data']['clients'] | undefined =
      treeStructureData?.treeDashboard?.data?.clients;
    if (!clients?.length) {
      setData([]);
    } else {
      const structure: ProcessedDashboardTreeNode[] = clients.map(({ domains, ...group }) => ({
        ...group,
        name: group.client,
        is_group: true,
        searchKey: group.client,
        isDomainsComparison: true,
        path: generateSelectId('group', group.id),
        children: domains.map((domain) => {
          const domainPath = generateSelectId('domain', domain.id, group.id.toString());
          return {
            ...domain,
            searchKey: `${domain.domain} ${domain.display_name}`,
            is_domain: true,
            isDomainsComparison: true,
            path: domainPath,
            [childrenLoadingKey]: true,
          };
        }),
      }));
      setData(structure);
    }
  }, [treeStructureData, childrenLoadingKey]);

  useEffect(() => {
    if (!treeStructureData) return;
    const domainIds = expandedDomains.map(getDomainId).filter(notEmpty);
    const domainFolderStructures = {};
    const fetchFolderStructures = async () => {
      await Promise.all(
        domainIds.map(async (domainId) => {
          try {
            const { data: folderData } = await client.query<FolderStructureForDomainQuery>({
              query: FolderStructureForDomainDocument,
              variables: {
                filters,
                nested: true,
                orderBy,
                order,
                domainId: Number(domainId),
              },
              fetchPolicy: 'network-only',
            });
            const folderStructure: FolderType[] =
              folderData?.folderStructureForDomain?.folderStructure;
            domainFolderStructures[domainId] = folderStructure;
          } catch (e) {
            console.error(
              `an error occured while feching folderStructure for domainId: ${domainId}`,
            );
            toast.error(t('Woops - something went wrong when trying to unfold this domain!'));
            setExpandNodes((prev) => prev.filter((node) => getDomainId(node) !== domainId));
            setExpandedDomains((prev) => prev.filter((node) => getDomainId(node) !== domainId));
          }
        }),
      );
      setData((prevData) =>
        prevData.map((group) => ({
          ...group,
          children: group.children.map((domain) => {
            if (domainFolderStructures[domain.id]) {
              return {
                ...domain,
                children: processFolderStructure(domainFolderStructures[domain.id], domain.path),
                [childrenLoadingKey]: false,
              };
            }
            return domain;
          }),
        })),
      );
    };
    fetchFolderStructures();
  }, [
    expandedDomains,
    filters,
    order,
    client,
    orderBy,
    childrenLoadingKey,
    treeStructureData,
    refetchListener,
    expandDomains,
  ]);

  return { data, expandDomains, setTreeData, refetch };
};
