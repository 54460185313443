import { Component } from 'react';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import compose from 'lodash/flowRight';
import { AccMultiSelect } from 'Components/AccSelect';
import Skeleton from 'Components/Skeleton';
import { t } from 'Utilities/i18n';

type Client = {
  id: string;
  name: string;
};
type Props = {
  clients: Client[];
  loading: false;
  error: '';
  value: string[];
  onChange: (value: string[]) => void;
  showError?: boolean;
  disabled?: boolean;
};
const clientsQuery = gql`
  query clientsInput_clients {
    clients {
      id
      name
    }
  }
`;

class ClientsInput extends Component<Props> {
  static defaultProps = {
    disabled: false,
  };

  render() {
    if (this.props.loading || this.props.error) {
      return (
        <Skeleton
          linesConfig={[
            {
              type: 'text',
              options: {
                width: '120px',
                marginBottom: '10px',
              },
            },
          ]}
        />
      );
    }

    const value = this.props.value;

    const options = this.props.clients
      ?.map((e) => ({
        value: e?.id,
        label: e?.name,
      }))
      .filter((e) => e.value);
    return (
      <AccMultiSelect
        autoFocus
        value={value}
        options={options}
        onChange={this.props.onChange}
        showError={this.props.showError}
        disabled={this.props.disabled}
        placeholder={t('Enter group names')}
        creatable={false}
        inputMaxWidth={570}
      />
    );
  }
}

export default compose(
  graphql(clientsQuery, {
    props: ({ data: { error, loading, clients } }: any) => ({
      error,
      loading,
      clients,
    }),
  }),
)(ClientsInput);
