import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import { Container, Flex, MantineShadow, Stack, useMantineTheme } from '@mantine/core';
import {
  IconArrowRight,
  IconGripVertical,
  IconTrendingDown,
  IconTrendingUp,
} from '@tabler/icons-react';
import cn from 'classnames';
import FormatNumber from 'Components/FormatNumber/new';
import PercentageOfMaxBar from 'Components/PercentageOfMaxBar/percentageOfMaxBar';
import Skeleton from 'Components/Skeleton';
import AccTitle from 'Components/Title/AccTitle';
import { KpiBoxDropdown } from 'Pages/Keywords/Overview/components/KpiBar/components/KpiBoxDropdown';
import { KPI_NAMES, getKpiTooltipText } from 'Pages/Keywords/Overview/components/KpiBar/index';
import { PUBLIC_KPI_NAMES } from 'Pages/PublicReport/publicKpiBar/Boxes';
import { colorScheme } from 'Utilities/colors';
import { percentageChange } from 'Utilities/format';
import style from './kpi-box.module.scss';

export interface KpiBoxSizeProps {
  size: 'large' | 'small';
  draggable?: false | undefined | { removeChart?: Function } | true;
}

export interface KpiBoxProps extends KpiBoxSizeProps {
  id?: string;
  title: string;
  loading: boolean;
  value: number | string | undefined;
  maxValue?: number | null;
  beforeValue: number | string | undefined;
  isRank?: boolean;
  isKeywords?: boolean;
  showPercentage?: boolean;
  currency?: string | null;
  precision?: number;
  /** Inversely reflect changes on colors and arrows; E.g., a decrease displays an arrow indicating increase, and vice versa. */
  changeNegation?: boolean;
  helper?: React.ReactNode;
  valueClassName?: string;
  shadow?: MantineShadow | undefined;
  hidden?: boolean;
  empty?: boolean;
}

const KpiTitle = (props: {
  title: string;
  helper?: React.ReactNode;
  draggable?: boolean;
  dragged?: boolean;
}) => {
  return (
    <Flex
      className={cn(
        style.titleContainer,
        props.dragged && style.dragActive,
        props.draggable && style.draggable,
      )}
    >
      {props.draggable && (
        <div className={style.gripIcon}>
          <IconGripVertical size={16} />
        </div>
      )}
      <AccTitle
        w={props.draggable ? 'fit-content' : 600}
        type="h5"
        helper={props.helper}
        boxProps={{
          className: style.title,
        }}
        iconProps={{ 'data-no-dnd': 'true', className: style.titleIcon }}
      >
        {props.title}
      </AccTitle>
    </Flex>
  );
};

const DraggableTitle = SortableHandle<{
  title: string;
  helper?: React.ReactNode;
  dragged?: boolean;
}>((props) => <KpiTitle {...props} draggable />);

const KpiBox = ({
  id,
  title,
  loading,
  value,
  maxValue,
  beforeValue,
  isRank,
  isKeywords,
  size,
  showPercentage = false,
  currency,
  precision,
  changeNegation = false,
  valueClassName,
  hidden,
  empty,
  draggable,
  helper,
}: KpiBoxProps): JSX.Element => {
  const theme = useMantineTheme();
  let icon: JSX.Element | null = null;

  // If value or beforeValue is undefined change is set to null - used primarily for new domains
  let change =
    typeof value === 'number' &&
    typeof beforeValue === 'number' &&
    value !== undefined &&
    beforeValue !== undefined
      ? value - beforeValue
      : null;

  if (changeNegation && change) {
    change *= -1;
  }

  let changePercentage: number | false = false;
  const increase = change !== null && change >= 0;

  const color = increase ? colorScheme.increase : colorScheme.decrease;

  if (change) {
    const ArrowIcon = increase ? IconTrendingUp : IconTrendingDown;
    icon = <ArrowIcon size={36} stroke={2} color={color} className={style.trendingIcon} />;
  } else if (loading) {
    icon = <IconArrowRight size={36} stroke={2} color={theme.colors.gray[1]} />;
  }

  if (!showPercentage) {
    if (beforeValue) {
      changePercentage = percentageChange(beforeValue, value);
    }
  }

  const isSmall = size === 'small';

  const removeChart: Function | undefined = (draggable as any)?.removeChart;
  const helperText =
    helper || id
      ? getKpiTooltipText()[id as keyof typeof KPI_NAMES | keyof typeof PUBLIC_KPI_NAMES] || ''
      : '';
  return (
    <div className={cn(style.kpiBox, { [style.small]: isSmall, [style.hidden]: hidden })}>
      {empty ? null : (
        <>
          <Flex wrap="nowrap" gap={0}>
            {draggable ? (
              <DraggableTitle
                title={title}
                helper={helperText}
                dragged={(draggable as any)?.dragged}
              />
            ) : (
              <KpiTitle title={title} helper={helperText} />
            )}
            {removeChart && (
              <Flex ml="auto">
                <KpiBoxDropdown removeChart={removeChart} />
              </Flex>
            )}
          </Flex>
          <div className={style.statsRow}>
            {loading ? (
              <Skeleton
                linesConfig={[
                  {
                    type: 'title',
                    options: {
                      width: '100px',
                      height: isSmall ? '20px' : '40px',
                    },
                  },
                  {
                    type: 'title',
                    options: {
                      width: '100px',
                      height: isSmall ? '4px' : '8.58px', // Avoid jumping when switching between loading and not loading
                    },
                  },
                ]}
              />
            ) : (
              <Stack>
                <span className={style.value}>
                  <Flex direction="column" pb="sm">
                    <Flex gap={8}>
                      <FormatNumber
                        maximumFractionDigits={
                          typeof value === 'number' && value && Math.abs(value) > 100
                            ? 0
                            : precision
                        }
                        style={showPercentage ? 'percent' : currency ? 'currency' : undefined}
                        currency={currency}
                        currencyDisplay="narrowSymbol"
                        value={typeof value === 'number' && value ? Math.abs(value) : value}
                        className={valueClassName}
                      />
                      {icon}
                    </Flex>
                    <span
                      className={cn(style.deltaRow, {
                        [style.decrease]: !increase,
                        [style.increase]: increase,
                        [style.small]: isSmall,
                      })}
                    >
                      {change !== null && (
                        <span className={style.deltas}>
                          <FormatNumber
                            value={change ? Math.abs(change) : change}
                            maximumFractionDigits={change && Math.abs(change) > 100 ? 0 : precision}
                            className={style.delta}
                            style={showPercentage ? 'percent' : undefined}
                            currency={currency}
                          />
                          {changePercentage !== false && (
                            <div
                              className={cn(style.deltaPercent, {
                                [style.decrease]: !increase,
                                [style.increase]: increase,
                              })}
                            >
                              (
                              <FormatNumber
                                value={changePercentage}
                                maximumFractionDigits={
                                  changePercentage && Math.abs(changePercentage * 100) > 100 ? 0 : 2
                                }
                                style="percent"
                              />
                              )
                            </div>
                          )}
                        </span>
                      )}
                    </span>
                  </Flex>
                </span>
              </Stack>
            )}
          </div>
        </>
      )}
      {maxValue ? (
        <Container className={style.percentageOfMaxContainer}>
          <PercentageOfMaxBar
            value={typeof value === 'number' ? value : 0}
            maxValue={maxValue}
            isRank={isRank}
            isKeywords={isKeywords}
            isPercentages={showPercentage}
          />
        </Container>
      ) : null}
    </div>
  );
};

export default KpiBox;
