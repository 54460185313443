import { useEffect, useState } from 'react';
import { ApolloClient, useApolloClient } from '@apollo/client';
import { BaseSubscriptionOptions } from '@apollo/client/react/types/types';
import { useTableStore } from 'Components/DataTable';
import { TableStoreType } from 'Components/DataTable/store/TableStore';
import { refreshKeywordStore } from 'Components/RefreshKeywords/RefreshKeywordStore';
import { useFilters } from 'Hooks';
import { useMultipleSubscriptions } from 'Hooks/useSubscription';
import { formatKeyword } from 'Pages/Keywords/Table/hooks/keyword/useTableKeyword';
import { getIsLatestFilter } from 'Pages/Keywords/Table/support/helpers';
import { TableID } from 'Types/Table';
import { updateDomainInfoCache } from 'Utilities/Graphql/domainInfo';
import { invalidateCache } from 'Utilities/Graphql/invalidateCache';
import { getTableKeywordNodeCacheId } from 'Utilities/Graphql/keywords';
import { WebsocketActionType } from './constants';
import { LIVE_CHANGES_DOMAIN_QUERY, UPDATE_KEYWORD_FRAGMENT } from './queries';

type WebsocketConfig = {
  subscribeTo: null | 'domain';
  subscribeToArg: string | string[];
};

const getRowId = (rowData: any) => rowData.id?.toString();

const updateKeywordGhqlCache =
  (client: ApolloClient<any>) =>
  ({ id, ...data }: { id: any } & any) => {
    try {
      client.cache.writeFragment({
        id: getTableKeywordNodeCacheId({ id, ...data }),
        fragment: UPDATE_KEYWORD_FRAGMENT,
        data: { id, ...data },
      });
    } catch (e) {
      console.error('Failed', e);
    }
  };

// https://accuranker.myjetbrains.com/youtrack/issue/ARR-1522
// once keyword reload finished we need to prevent refresh button loading
const onFullScrapeComplete = (client: ApolloClient<any>, domainId: string) => {
  refreshKeywordStore.stopLoading(domainId);
  updateDomainInfoCache({
    client,
    domainId,
    patch: {
      canRefresh: true,
    },
  });
};

const onSubscriptionData =
  ({
    subscription,
    websocket,
    tableStore,
    client,
  }: {
    subscription?: any;
    websocket: WebsocketConfig;
    tableStore: TableStoreType | null;
    client: ApolloClient<object>;
  }): BaseSubscriptionOptions['onSubscriptionData'] =>
  (params) => {
    if (!subscription) {
      return;
    }

    if (websocket.subscribeTo === 'domain') {
      const liveChangesDomain = params?.subscriptionData?.data?.liveChangesDomain;
      if (liveChangesDomain.action === 'MIX') {
        const changes = liveChangesDomain.obj;
        let requireCacheClean = false;
        let itemAdded = 0;
        let itemRemoved = 0;

        if (changes && Array.isArray(changes)) {
          // we do it inside setData so we only do it 1 time per websocket message.
          tableStore?.setData((oldData) => {
            let newData = [...oldData];
            let appliedChanges = false;
            changes.forEach((change) => {
              if (change._action === WebsocketActionType.CREATED) {
                itemAdded++;
                appliedChanges = true;
                return;
              } else if (change._action === WebsocketActionType.DELETED) {
                itemRemoved++;
                appliedChanges = true;
                return;
              } else if (change.bustCache) {
                // https://accuranker.myjetbrains.com/youtrack/issue/ARR-1549
                // When we receive this flag from backend, we should reset apollo cache
                requireCacheClean = true;
              }

              const index = newData.findIndex((x) => getRowId(x) === getRowId(change));

              if (index !== -1) {
                if (change.deleted) {
                  appliedChanges = true;
                  newData = newData.splice(index, 1);
                } else {
                  const noChanges = Object.keys(change || {}).every(
                    (k) =>
                      newData?.[index]?.[k] === changes?.[k as any] ||
                      ['id', '_action'].includes(k),
                  );
                  if (!noChanges) {
                    appliedChanges = true;
                    newData[index] = {
                      ...newData[index],
                      // we need to format the keyword to make sure we include `expandChildren`,
                      // if extra ranks are empty formatKeyword return object without 'expandChildren' key for proper merge
                      // @issues: ARR-3067 ARR-2300
                      ...formatKeyword(change),
                      id: change?.id?.toString(),
                    };
                  }
                }
              }

              updateKeywordGhqlCache(change);
            });
            return appliedChanges ? newData : oldData;
          });

          const updatedCount = itemAdded > 0 || itemRemoved > 0;
          if (updatedCount) {
            // https://accuranker.myjetbrains.com/youtrack/issue/ARR-1479
            invalidateCache(client.cache);
            tableStore?.updateChangeInfo({ added: itemAdded, removed: itemRemoved });
          } else if (requireCacheClean) {
            invalidateCache(client.cache);
          }
        }
      } else if (liveChangesDomain.action === 'fullScrapeComplete') {
        // we do not refresh keywords
        if (!Array.isArray(websocket.subscribeToArg)) {
          onFullScrapeComplete(client, websocket.subscribeToArg);
        }
      }
    }
  };

/**
 * @docs https://accuranker.myjetbrains.com/youtrack/issue/ARR-1479
 *       https://accuranker.myjetbrains.com/youtrack/issue/ARR-1521
 *
 * @description
 * Websocket behavior logic
 * ## Add keywords - Expected behavior
 * - Frontend will receive a websocket message with a new _action property [WebsocketActionType]('./constants')
 * - Invalidate Apollo cache
 * - Display a (new) inline notification: "#counter# keywords added to this domain. <link>Refresh</link>"
 *
 * ## Deleted keywords - Expected behavior
 * - Frontend will receive a websocket message with the _action property
 * - Invalidate Apollo cache
 * - Display an inline notification: "#counter# keywords deleted from domain. <link>Refresh</link>"
 * - Actions that should erase the Apollo cache
 *
 * ## Rank change
 * In principle the cache should be invalidated when we receive a websocket with rank updates.
 * It could be that the rank change would result in reordering of the keyword or disappear.
 * It should not trigger re-fetch of data or a banner.
 * Data should just be fetched when the user navigates, change filter or ordering etc
 *
 * ## Starring a keyword (or un-starring one) (but the resulting WS update will cause a cache invalidation)
 * - Selecting or unselecting a keyword (using the checkboxes)
 * - Paginating
 * - Clicking "Refresh keyword" (but the resulting WS update will cause a cache invalidation)
 * - Inline notification
 */
export const useTableSubscription = (
  tableName: TableID,
  websocket: WebsocketConfig,
  initTableStore?: boolean,
) => {
  const client = useApolloClient();
  const [subscription, setSubscription] = useState(false);
  const filters = useFilters();
  const tableStore = useTableStore(tableName, initTableStore);

  useEffect(() => {
    if (websocket.subscribeTo === 'domain') {
      // make sure we are only using websocket if we are looking on latest date
      const periodFilter = filters.find((f) => f.attribute === 'period');

      if (periodFilter) {
        setSubscription(getIsLatestFilter(filters));
      }
    }
  }, [filters]);

  const ids = (
    Array.isArray(websocket.subscribeToArg) ? websocket.subscribeToArg : [websocket.subscribeToArg]
  )
    .map((e) => parseInt(e, 10))
    .filter(Boolean);

  useMultipleSubscriptions(
    ids?.map((id) => ({
      query: LIVE_CHANGES_DOMAIN_QUERY,
      variables: { id },
      onSubscriptionData: onSubscriptionData({
        subscription,
        websocket,
        tableStore,
        client,
      }),
    })),
  );
};
