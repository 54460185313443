import { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'lodash/flowRight';
import AccButton from 'Components/AccButton/AccButton';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import { t, tct } from 'Utilities/i18n';

type Props = {
  hideModal: (...args: Array<any>) => any;
  oldVersion: number;
  newVersion: number;
  autoRefreshAfter?: number;
}; // TODO: Load changelog using the old and new version and display it to the user.

class NewVersion extends Component<Props, any> {
  state = {
    autoRefreshAfter: this.props.autoRefreshAfter ?? 30,
  };

  timerId: number | any = -1;

  componentDidMount() {
    if (this.props.autoRefreshAfter) {
      this.timerId = setInterval(this.handleTimer, 1000);
    }
  }

  componentWillUnmount() {
    if (this.timerId !== -1) {
      clearInterval(this.timerId);
      this.timerId = -1;
    }
  }

  handleTimer = () => {
    let newDuration = this.state.autoRefreshAfter - 1;

    if (newDuration <= 0) {
      newDuration = 0;
      clearInterval(this.timerId);
      this.timerId = -1;
      // Try to force reload without cache, we have experienced some issues when using .reload() method
      window.location.href = window.location.href + '?nocache=' + new Date().getTime();
    }

    this.setState({
      autoRefreshAfter: newDuration,
    });
  };

  render() {
    const { autoRefreshAfter } = this.state;
    return (
      <ModalBorder className="new-version" title={t('AccuRanker Has Been Updated')}>
        <div>
          {tct(
            'Please refresh AccuRanker to see the new version. See the latest big releases [link: here].',
            {
              link: (
                <a
                  href={'https://www.accuranker.com/changelog/'}
                  target="_blank"
                  rel="noreferrer"
                />
              ),
            },
          )}
        </div>
        <ModalFooter
          primaryButtonSlot={
            <AccButton
              type="submit"
              variant="primary"
              onClick={
                () =>
                  (window.location.href = window.location.href + '?nocache=' + new Date().getTime()) // Try to force reload without cache, we have experienced some issues when using .reload() method
              }
            >
              {t('Refresh AccuRanker')} ({autoRefreshAfter})
            </AccButton>
          }
        />
      </ModalBorder>
    );
  }
}

export default compose(connect(null, null))(NewVersion);
