// Remember to edit development.js as well!
const config = {
  defaultGraphqlEndpoint: '/graphql',
  defaultGraphqlWebSocketEndpoint: 'wss://app.accuranker.com/graphql-ws',
  defaultImportWebSocketEndpoint: 'wss://app.accuranker.com/import-ws',
  baseUrl: 'https://app.accuranker.com',
  registerUrl: 'https://www.accuranker.com/free-trial/?signup',
  baseRootUrl: 'app.accuranker.com',
  publicPath: '/app/',
  basename: '/app',
  services: {
    sentry: 'https://eb569589705641199db8461c23f36169@sentry.servers.ac/4',
    sentryEnvironment: 'production',
  },
  intercomAppId: 'e0db10a7c5db6863136cef9e2b9fd077e1bb33f6',
  recaptchaSitekey: '6LcMpCQUAAAAAGoLDMwH1zGJoCrGhjuvazPVFyAz',
  analyticsTrackingId: 'UA-47515230-1',
  analyticsDebug: false,
  gtmId: 'GTM-K9GLDXR',
  trialId: '8ad49c7d-c55a-49b3-bb5d-0915bce4ec26',
  hotjarId: 12747,
};

export default config;
