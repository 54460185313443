import moment from 'moment';

type Props = {
  value: {
    data: {
      searchDate: string;
      updatingKeyword: boolean;
    };
    loadingData: boolean;
  };
};

const KeywordTimeAgoCell = (props: Props) => {
  const {
    value: { data },
  } = props;
  const { searchDate } = data;

  return <>{searchDate ? moment(new Date(searchDate)).fromNow(true) : null}</>;
};

export default KeywordTimeAgoCell;
