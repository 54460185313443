import isNil from 'lodash/isNil';
import ErrorBoundary from 'Components/ErrorBoundary';
import KpiBox, { KpiBoxSizeProps } from 'Pages/Keywords/Overview/components/KpiBar/KpiBox';
import { KPI_NAMES } from 'Pages/Keywords/Overview/components/KpiBar/index';
import { t } from 'Utilities/i18n';
import { KpiBarQueryProps } from './useKpiBarQueryWithDisplayCurrency';

const TrafficValueKpiBox = ({
  size,
  kpiBarQueryProps,
  draggable,
}: KpiBoxSizeProps & { kpiBarQueryProps: KpiBarQueryProps }) => {
  const { displayCurrency, data, loading } = kpiBarQueryProps;

  return (
    <ErrorBoundary>
      <KpiBox
        id={KPI_NAMES.TRAFFIC_VALUE}
        size={size}
        title={t('Traffic Value') + (!loading && displayCurrency ? ` (${displayCurrency})` : '')}
        loading={loading || !displayCurrency}
        value={
          !loading && !isNil(data?.kpis?.allKpis?.trafficValue)
            ? data?.kpis?.allKpis?.trafficValue
            : '-'
        }
        beforeValue={
          !loading && data?.kpis?.allKpis?.trafficValueBefore
            ? data?.kpis?.allKpis?.trafficValueBefore
            : '-'
        }
        precision={0}
        maxValue={data?.kpis?.allKpis?.maxTrafficValue}
        draggable={draggable}
      />
    </ErrorBoundary>
  );
};

export default TrafficValueKpiBox;
