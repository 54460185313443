import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as Sentry from '@sentry/react';
import { hideModal } from 'Actions/ModalAction';
import AccButton from 'Components/AccButton/AccButton';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import AccText from 'Components/Text/AccText';
import { t } from 'Utilities/i18n';

type PaymentFailedProps = {
  errorType: string;
};

const PaymentFailed = ({ errorType = '' }: PaymentFailedProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    Sentry.captureException('We were unable to process your payment');
  }, []);

  const handleHideModal = () => dispatch(hideModal());

  return (
    <ModalBorder title={t('We were unable to process your payment')} onClose={handleHideModal}>
      <AccText>
        {t('The error message states:')} {errorType}.
      </AccText>
      <ModalFooter
        primaryButtonSlot={
          <AccButton onClick={handleHideModal} variant="secondary">
            {t('Back to payment page')}
          </AccButton>
        }
      />
    </ModalBorder>
  );
};

export default PaymentFailed;
