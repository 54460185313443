import { useLocation } from 'react-router-dom';
import type { DataAttributes, IntercomProps, MessengerAttributes } from 'react-use-intercom';
import { useMantineTheme } from '@mantine/core';
import { useIntercomMultiAccountUserQuery } from 'Ghql';
import { useUser } from 'Hooks/data/user/useUser';

const BLACKLISTED_ROUTES = ['/reports/pdf/'];

export const useIntercomConfig = ({
  boot,
}: {
  boot: (props?: IntercomProps | undefined) => void;
}) => {
  const user = useUser();
  const theme = useMantineTheme();
  const backgroundColor = theme.colors?.snorlax[5];
  const messengerAttributes: MessengerAttributes = {
    customLauncherSelector: '#intercom-button',
    horizontalPadding: 0,
    verticalPadding: 0,
    backgroundColor,
  };

  const { pathname } = useLocation();

  const { data: multiAccountUserData, loading, error } = useIntercomMultiAccountUserQuery();

  const isBlacklisted = () => {
    return BLACKLISTED_ROUTES.some((route) => pathname.includes(route));
  };

  if (error || loading || user.isImpersonating || isBlacklisted()) {
    return undefined;
  }

  const { id, email, fullName, intercomHash, dateJoined, language } =
    multiAccountUserData &&
    multiAccountUserData.user &&
    multiAccountUserData.user.multiaccountOriginUser
      ? multiAccountUserData.user.multiaccountOriginUser
      : user;
  const organization =
    multiAccountUserData &&
    multiAccountUserData.user &&
    multiAccountUserData.user.multiaccountOrganization
      ? multiAccountUserData.user.multiaccountOrganization
      : user.organization;

  const cfg: DataAttributes = {
    userId: id || undefined,
    email: email || undefined,
    name: fullName || undefined,
    userHash: intercomHash || undefined,
    createdAt: dateJoined || undefined,
    customAttributes: {
      site_language: language,
      latest_used_version: 2,
      frontend_version: COMMITHASH,
      hide_default_launcher: false,
      widget: {
        activator: '#IntercomDefaultWidget',
        use_counter: true,
      },
    },
  };

  try {
    if (organization !== null) {
      const { activePlan } = organization || {};
      cfg.company = {
        companyId: organization?.id || '',
        name: organization?.name,
        createdAt: organization?.dateAdded.toString(),
        plan: activePlan ? activePlan.category || undefined : undefined,
        monthlySpend: activePlan ? activePlan.priceMonthly || 0 : 0,
        customAttributes: {
          max_keywords: activePlan ? activePlan.maxKeywords : 0,
          percentage_keyword_use:
            activePlan && activePlan.maxKeywords > 0
              ? Math.round((organization?.numberOfKeywords || 0 / activePlan.maxKeywords) * 100)
              : 0,
          trial: activePlan ? activePlan.isTrial : true,
          active_plan: !!activePlan,
          active_plan_end_at: activePlan ? activePlan.endDate : undefined,
          active_plan_billing_cycle: activePlan ? activePlan.billingCycleInMonths : undefined,
          organization_active: organization?.active,
          organization_is_partner: organization?.isPartner,
          number_of_domains: organization?.numberOfDomains,
          number_of_domains_with_ga: organization?.numberOfDomainsWithGa,
          number_of_domains_with_gwt: organization?.numberOfDomainsWithGwt,
          number_of_keywords: organization?.numberOfKeywords,
          company_type: organization?.type,
          phone: organization?.phoneNumber,
          sales_organization_link: `https://app.accuranker.com/app/sales/organization/${organization?.id}`,
        },
      };
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`[Intercom] ${e}`);
  }

  if (multiAccountUserData) {
    const bootIntercomWithConfiguration = () => boot({ ...cfg, ...messengerAttributes });

    return bootIntercomWithConfiguration;
  }
};
