import AccButton from 'Components/AccButton/AccButton';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import AccText from 'Components/Text/AccText';
import { EventName, useMixpanel } from 'Utilities/Analytics/mixpanel';
import { t } from 'Utilities/i18n';

type OrganizationPlan = {
  id: any;
  name: any;
};
type PaymentInfo = {
  //transaction
  billysbillingInvoiceId: any;
  amount: any;
  amountBeforeVat: any;
  couponCode: any;
  //item
  organizationPlan: OrganizationPlan;
};
type Props = {
  paymentInfo: PaymentInfo;
  modalBodyText: string;
};

const PaymentSuccess = ({ modalBodyText }: Props) => {
  const trackEvent = useMixpanel();

  return (
    <ModalBorder title={t('Payment Confirmed')}>
      <AccText>{modalBodyText}</AccText>
      <ModalFooter
        primaryButtonSlot={
          <a href="/app/account/billing">
            <AccButton onClick={() => trackEvent(EventName.PaymentSuccessModal)} variant="success">
              {t('Go to the billing overview')}
            </AccButton>
          </a>
        }
      />
    </ModalBorder>
  );
};

export default PaymentSuccess;
