import { ApolloError } from '@apollo/client';
import { useFilterOptionsQuery } from 'Ghql';
import { useFilters } from 'Hooks';
import { FilterAttribute } from 'Types/Filter';
import getIsKeyDis from 'Utilities/getKeyDis';
import { t } from 'Utilities/i18n';
import Baidu from 'icons/baidu.svg?inline';
import Bing from 'icons/bing.svg?inline';
import Google from 'icons/google.svg?inline';
import Naver from 'icons/naver.svg?inline';
import YouTube from 'icons/youtube.svg?inline';

type SearchItemType = {
  id: string;
  value: number;
  label: string;
  icon: (props: any) => JSX.Element;
  count?: number;
};

export const getItems = () => {
  return [
    {
      id: '1',
      value: 1,
      label: t('Google'),
      icon: Google,
    },
    {
      id: '2',
      value: 2,
      label: t('Bing'),
      icon: Bing,
    },
    {
      id: '5',
      value: 5,
      label: t('YouTube'),
      icon: YouTube,
    },
    {
      id: '3',
      value: 3,
      label: t('Baidu'),
      icon: Baidu,
    },
    {
      id: '6',
      value: 6,
      label: t('Naver'),
      icon: Naver,
    },
  ];
};

export const useSearchEngineData = (): {
  data: SearchItemType[];
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const items = getItems();
  const filters = useFilters();
  const {
    data: optionsData,
    loading: optionsLoading,
    error: optionsError,
  } = useFilterOptionsQuery({
    fetchPolicy: 'cache-first',
    variables: {
      filters: filters.filter((f) => f.attribute !== FilterAttribute.SEARCH_ENGINE),
      isKeydis: getIsKeyDis(),
      filterType: FilterAttribute.SEARCH_ENGINE,
    },
  });

  const filterOptions = optionsData?.filterData?.filterOptions;
  const options = optionsData?.filterData?.filterOptions === null ? null : filterOptions || [];
  let data = items;
  if (!optionsLoading && !optionsError && options !== null) {
    data = items.reduce((accumulator: SearchItemType[], item) => {
      const count = options.find((o) => o.option === item.id)?.count || 0;
      accumulator.push({
        ...item,
        count,
      });
      return accumulator;
    }, []);
  }

  return { data, loading: optionsLoading, error: optionsError };
};
