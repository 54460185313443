import moment from 'moment';
import { EARLIEST, LATEST } from 'Components/PeriodFilter/model';
import { ReportsPublicReportSettingsDefaultDeltaChoices } from 'Ghql';
import {
  CompareToFilter,
  FilterAttribute,
  FilterBase,
  FilterComparison,
  FilterValueType,
  PeriodFilter,
} from 'Types/Filter';

export const getCompare = (
  defaultCompareFrom: ReportsPublicReportSettingsDefaultDeltaChoices | '_1' | undefined,
) => {
  switch (defaultCompareFrom) {
    case 'A_1':
      return {
        from: moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD'),
        to: LATEST,
      };
    case 'A_7':
      return {
        from: moment(new Date()).subtract(1, 'week').format('YYYY-MM-DD'),
        to: LATEST,
      };
    case 'A_30':
      return {
        from: moment(new Date()).subtract(1, 'month').format('YYYY-MM-DD'),
        to: LATEST,
      };

    case 'A_365':
      return {
        from: moment(new Date()).subtract(1, 'year').format('YYYY-MM-DD'),
        to: LATEST,
      };
    case '_1':
      return {
        from: EARLIEST,
        to: LATEST,
      };
    case 'A_-1':
      return {
        from: EARLIEST,
        to: LATEST,
      };
    default:
      return {
        from: moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD'),
        to: LATEST,
      };
  }
};

/**
 * Get filters from the URL and insert fallback period- and compareTO filters if they are missing
 */
export const useFiltersWithFallback = (
  filters: FilterBase[],
  defaultDelta: ReportsPublicReportSettingsDefaultDeltaChoices | undefined,
): FilterBase[] => {
  const periodFilter = filters.find((filter) => filter.attribute === FilterAttribute.PERIOD) as
    | PeriodFilter
    | undefined;

  const compareToFilter = filters.find(
    (filter) => filter.attribute === FilterAttribute.COMPARE_TO,
  ) as CompareToFilter | undefined;

  const compare = getCompare(defaultDelta);

  const restFilters = filters.filter(
    (filter) =>
      filter.attribute !== FilterAttribute.PERIOD &&
      filter.attribute !== FilterAttribute.COMPARE_TO,
  );

  const fallbackPeriodFilter: PeriodFilter = {
    attribute: FilterAttribute.PERIOD,
    comparison: FilterComparison.BETWEEN,
    type: FilterValueType.DATETIME,
    value: JSON.stringify([compare.from, compare.to]),
  };

  const fallbackCompareToFilter: CompareToFilter = {
    attribute: FilterAttribute.COMPARE_TO,
    comparison: FilterComparison.EQ,
    type: FilterValueType.DATETIME,
    value: compare.from,
  };

  return [
    ...restFilters,
    periodFilter || fallbackPeriodFilter,
    compareToFilter || fallbackCompareToFilter,
  ];
};
