import { useSelector } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import moment from 'moment';
import { QueryOrder } from 'Components/DataTable';
import type { ColumnType } from 'Components/DataTable/types';
import { SORTING_TYPE } from 'Constants';
import {
  PendingRequestFragment,
  PendingRequestsDocument,
  PendingRequestsQuery,
  PendingRequestsQueryVariables,
  useMultiAccountRemoveAccessRequestMutation,
} from 'Ghql';
import toast from 'Hooks/useToast';
import LocaleSelector from 'Selectors/LocaleSelector';
import { t } from 'Utilities/i18n';
import { notEmpty } from 'Utilities/underdash';
import { ColumnIDs } from './ColumnIDs';

type RemoveAccessMutation = ReturnType<typeof useMultiAccountRemoveAccessRequestMutation>[0];

const onRemoveRequestClick = (
  id: string,
  removeAccessMutation: RemoveAccessMutation,
  handleUpdateTable: () => void,
) => {
  const RemoveMultiAccountRequestInput = {
    id,
  };
  removeAccessMutation({
    variables: {
      input: RemoveMultiAccountRequestInput,
    },
  }).then((response) => {
    const errors = response.errors;
    if (!errors?.length) {
      toast.success(t('Request removed'));
      handleUpdateTable();
    } else {
      toast.error(t('Unable to remove access'));
    }
  });
};

export const useFetchPendingRequest = () => {
  const client = useApolloClient();

  return async () => {
    return client
      .query<PendingRequestsQuery, PendingRequestsQueryVariables>({
        query: PendingRequestsDocument,
      })
      .then((response) => {
        const pendingMultiAccounts =
          response.data?.user?.organization?.pendingMultiAccounts?.filter(notEmpty) || [];

        return { data: pendingMultiAccounts, length: pendingMultiAccounts.length };
      });
  };
};

export const useAccountsRequestsTableColumns = ({
  handleUpdateTable,
}: {
  handleUpdateTable: () => void;
}) => {
  const fullLocale = useSelector(LocaleSelector);
  const [removeAccessMutation] = useMultiAccountRemoveAccessRequestMutation();

  // eslint-disable-next-line @typescript-eslint/ban-types
  const columns: ColumnType<PendingRequestFragment, {}>[] = [
    {
      id: ColumnIDs.SEND_TO,
      title: t('Send To'),
      flex: true,
      width: 100,
      cellRenderer: (props) => <span>{props.record.toEmail}</span>,
      onHeaderCell: () => ({
        ordering: {
          defaultOrder: QueryOrder.ASC,
          orderBy: 'toEmail',
          sortingKey: SORTING_TYPE.alphabetical,
        },
      }),
    },
    {
      id: ColumnIDs.USER_TYPE,
      title: t('Account access as'),
      flex: true,
      width: 60,
      cellRenderer: (props) => (
        <span className="hidden-sm-down">
          {props.record.isOrgAdmin ? t('Admin user') : t('Normal user')}
        </span>
      ),
      onHeaderCell: () => ({
        ordering: {
          defaultOrder: QueryOrder.ASC,
          orderBy: 'isOrgAdmin',
          sortingKey: SORTING_TYPE.alphabetical,
        },
      }),
    },
    {
      id: ColumnIDs.CREATED_AT,
      title: t('Created At'),
      flex: true,
      width: 100,
      cellRenderer: (props) => (
        <span>
          {moment(props.record.createdAt)
            .locale(fullLocale || 'en')
            .format('lll')}
        </span>
      ),
      onHeaderCell: () => ({
        ordering: {
          defaultOrder: QueryOrder.ASC,
          orderBy: ColumnIDs.CREATED_AT,
          sortingKey: SORTING_TYPE.recurency,
        },
      }),
    },
    {
      id: ColumnIDs.ACTIONS,
      title: t('Actions'),
      width: 200,
      cellRenderer: (props) => (
        <a
          onClick={() =>
            onRemoveRequestClick(props.record.id, removeAccessMutation, handleUpdateTable)
          }
        >
          {t('Delete request')}
        </a>
      ),
    },
  ];
  return columns;
};
