import cn from 'classnames';
import FormatNumber from 'Components/FormatNumber/new';
import Skeleton from 'Components/Skeleton';
import styles from './filter-count.module.scss';

type Props = {
  count?: number | null;
  inDropdown?: boolean;
  inOption?: boolean;
  loading?: boolean;
};

const FilterCount = ({ count, inDropdown, inOption, loading }: Props): JSX.Element | null => {
  if (loading) {
    return (
      <div
        className={cn(styles.filterCount, {
          [styles.filterCountDropdown]: inDropdown,
          [styles.filterCountOption]: inOption,
        })}
      >
        <Skeleton
          linesConfig={[
            {
              type: 'text',
              options: {
                width: '20px',
                height: '16px',
              },
            },
          ]}
        />
      </div>
    );
  }
  if (count === null || count === undefined) return null;
  return (
    <span
      className={cn(styles.filterCount, {
        [styles.filterCountDropdown]: inDropdown,
        [styles.filterCountOption]: inOption,
      })}
    >
      (<FormatNumber value={count} />)
    </span>
  );
};

export default FilterCount;
