import { Link } from 'react-router-dom';
import { Flex } from '@mantine/core';
import AccButton from 'Components/AccButton/AccButton';
import { Field } from 'Components/Fields';
import { Form, OnSubmitCallback } from 'Components/Fields/Form/Form';
import AccText from 'Components/Text/AccText';
import { LoginTwoFactorInput } from 'Ghql';
import { t } from 'Utilities/i18n';
import Validator from 'Utilities/validation';

export type AuthTokenFormValues = Omit<LoginTwoFactorInput, 'clientMutationId'>;

type Props = {
  onSubmit: OnSubmitCallback<AuthTokenFormValues>;
  onBack: React.MouseEventHandler<HTMLAnchorElement>;
};
export default function AuthTokenForm({ onSubmit, onBack }: Props) {
  return (
    <Form onSubmit={onSubmit}>
      {/* eslint-disable-next-line react/no-unused-prop-types */}
      {({ submitting }: { submitting: boolean }) => (
        <Flex gap="md" direction="column">
          <AccText ta="center">
            {t('Please enter the token generated by your token generator.')}
          </AccText>
          <Flex gap="sm" direction="column">
            <Field.TextInput
              boxProps={{ mt: 'xxs' }}
              name="otpToken"
              placeholder={t('Token')}
              autoComplete="one-time-code"
              validate={Validator.required}
              autoFocus={true}
            />
            <AccText size="xs" fw={400} ml="auto" mt={-10}>
              <Link to="/login" tabIndex={-1} onClick={onBack}>
                {t('Back to Login')}
              </Link>
            </AccText>
            <Form.Errors />
            <div>
              <AccButton size="lg" loading={submitting} type="submit" variant="primary" fullWidth>
                {t('Log In')}
              </AccButton>
            </div>
          </Flex>
        </Flex>
      )}
    </Form>
  );
}
