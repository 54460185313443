import { Flag } from 'Components/Flag';
import Icon from 'Components/Icon/new';
import { MetaDataCountrylocaleFragment } from 'Ghql';
import Cell from './HelperComponents/AccuCell';

type Props = {
  value: {
    data: {
      countrylocaleId: number;
      location?: string;
    };
    loadingData?: boolean;
  };
  countryLocales: MetaDataCountrylocaleFragment[];
  compact?: boolean;
};

const CountryCell = (props: Props) => {
  const {
    value: { data, loadingData },
    countryLocales,
  } = props;
  // if (loadingData) return <LoadingSpinner />;
  if (loadingData) return '';
  const { countrylocaleId, location } = data;
  let countryLocale: MetaDataCountrylocaleFragment | undefined;
  let countryLocaleTooltip;
  let locationToolTip;

  if (countrylocaleId) {
    countryLocale = countryLocales.find((e) => e.id === countrylocaleId);
    countryLocaleTooltip = `${countryLocale?.region} (${countryLocale?.locale})`;
    // https://accuranker.myjetbrains.com/youtrack/issue/ARR-1301
    locationToolTip = (location || '')
      .concat(location?.includes(countryLocale?.region || '') ? '' : `, ${countryLocale?.region}`)
      .concat(` (${countryLocale?.locale})`);
    if (locationToolTip.startsWith(', ')) locationToolTip = locationToolTip.slice(2);
  }

  return (
    <Cell className={'flex-center'}>
      {countryLocale?.countryCode && (
        <Icon tooltip={locationToolTip || countryLocaleTooltip || ''}>
          <Flag size="sm" mx={0} country={countryLocale?.countryCode} fadedColors />
        </Icon>
      )}
    </Cell>
  );
};
CountryCell.defaultProps = {
  countryLocales: [],
};
export default CountryCell;
