import { gql, useQuery } from '@apollo/client';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { useFilters } from 'Hooks';
import { useModal } from 'Hooks/base/useModal';
import { t } from 'Utilities/i18n';
import LandingPageForm from './LandingPageForm/new';
import './landing-page.scss';

type Props = {
  keywords: number[];
  domainId: number;
  isAllSelected: boolean;
  preferredLandingPageId?: number;
  highestRankingPage?: string;
};

const landingPageQuery = gql`
  query landingPageForm_getLandingPage($id: ID!) {
    landingPage(id: $id) {
      id
      path
    }
  }
`;

const LandingPage = ({
  keywords,
  domainId,
  isAllSelected,
  preferredLandingPageId,
  highestRankingPage,
}: Props) => {
  const { hideModal } = useModal();
  const filters = useFilters();
  let path;

  if (preferredLandingPageId) {
    const { loading, data } = useQuery(landingPageQuery, {
      variables: {
        id: preferredLandingPageId,
      },
    });
    if (loading) return null; // todo loading animation

    path = data.landingPage.path;
  } else if (highestRankingPage) {
    const el = document.createElement('a');
    el.href = highestRankingPage;
    path = el.pathname + el.search + el.hash;
  }

  return (
    <ModalBorder className="landing-page" title={t('Change Preferred URL')} onClose={hideModal}>
      <LandingPageForm
        initialValues={{
          preferredLandingPage: path,
        }}
        preferredLandingPageIsSet={!!preferredLandingPageId}
        domainId={domainId}
        keywords={keywords}
        isAllSelected={isAllSelected}
        onClose={hideModal}
        filters={filters}
      />
    </ModalBorder>
  );
};

export default LandingPage;
