import AccTooltip from 'Components/AccTooltip/AccTooltip';
import TextInput from 'Components/Controls/TextInput';
import { Field } from 'Components/Fields';
import AccText from 'Components/Text/AccText';
import { UnrankedWeightConstants } from 'Constants';
import { t } from 'Utilities/i18n/index';
import { FieldName } from '../helpers';

export const IncludeUnrankedHelperText = () => {
  return (
    <AccText size="xs">
      {t(
        'This setting controls if "Not in top 100" rank keywords are included in the average rank calculation. This setting will be applied to all domains by default. You can override this setting for each domain.',
      )}
    </AccText>
  );
};

export const UnrankedWeightHelperText = () => {
  return (
    <AccText size="xs">
      {t(
        'This setting controls how much "Not in top 100" rank keywords will affect the average rank calculation. If you set the weight higher, these keywords will have more impact on the average rank.',
      )}
    </AccText>
  );
};

export const EnabledUnrankedWeight = () => {
  return (
    <Field.TextInput
      isNumber
      min={UnrankedWeightConstants.MIN_UNRANKED_WEIGHT}
      max={UnrankedWeightConstants.MAX_UNRANKED_WEIGHT}
      name={FieldName.unrankedWeight}
      boxProps={{
        h: 36,
        w: 75,
      }}
      inheritWidthHeight
    />
  );
};

export const DisabledUnrankedWeight = () => {
  const value = '';
  return (
    <AccTooltip
      tooltip={t(
        '"Not in top 100" ranks are not included in average rank calculation because of the above setting.',
      )}
    >
      <TextInput
        disabled
        value={value}
        showError={false}
        placeholder={'N/A'}
        boxProps={{
          h: 36,
          w: 75,
        }}
        inheritWidthHeight
      />
    </AccTooltip>
  );
};
