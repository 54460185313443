import { useKeywordCompareChartQuery } from 'Ghql';
import { useFilters } from 'Hooks';

export const useKeywordsCompetitors = (keywords?: string[]) => {
  const filters = useFilters();

  const { data, loading } = useKeywordCompareChartQuery({
    variables: {
      filters,
      keywordIds: keywords?.map(String) || [],
    },
    skip: !keywords,
  });

  return { series: data?.graphs?.compareKeywordsChart, loading };
};
