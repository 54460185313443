import * as React from 'react';
import keys from 'lodash/keys';
import noop from 'lodash/noop';
import sortBy from 'lodash/sortBy';
import { Field } from 'Components/Fields';
import { Form } from 'Components/Fields/Form/Form';
import { devError } from 'Utilities/log';

export type ElementData = {
  defaultValue: any;
  editor: React.ComponentType<
    React.PropsWithChildren<{
      value: any;
      onChange: (...args: Array<any>) => any;
    }>
  >;
  getTitle: (...args: Array<any>) => any;
  getDescription: (...args: Array<any>) => any;
};

type Props = {
  onChange: (...args: Array<any>) => any;
  value: any;
};

type ServerWidget = {
  type: string;
  order: number;
  label: string;
  description: string;
  settings: Record<string, any> | null | undefined;
};

const getElement = (
  key: string,
  type: string,
  description?: string,
  values?: any[],
  value?: any,
) => {
  switch (type) {
    case 'text':
      return <Field.TextInput key={key} placeholder={description} name={key} />;
    case 'textarea':
      return <Field.TextAreaInput key={key} placeholder={description} name={key} />;
    case 'boolean':
      return (
        <Field.Checkbox
          key={key}
          kind="toggle"
          label={description}
          name={key}
          defaultChecked={value}
        />
      );
    case 'select':
      return (
        <Field.Select
          key={key}
          options={values?.map?.((e) => ({ ...e, id: e?.value })) ?? []}
          name={key}
          placeholder={description}
          label={description}
        />
      );
    default:
      devError('[ReportTemplateBuilder.getElementData] Type not handled:', type);
      return null;
  }
};

const buildReactComponent = (widget: ServerWidget) => {
  const result = (props: Props) => {
    const settings = widget.settings;
    const items = sortBy(
      keys(settings || {}).map((key) => ({ ...(settings || {})[key], key })),
      'order',
    );

    return (
      <Form
        onSubmit={noop}
        initialValues={props.value}
        onChange={(values) => props.onChange(values)}
      >
        {items?.map((setting) => {
          const element = getElement(
            setting.key,
            setting.type,
            setting.label,
            setting.values,
            props.value?.[setting?.key],
          );
          return <React.Fragment key={setting.key}>{element}</React.Fragment>;
        })}
      </Form>
    );
  };
  result.displayName = 'ReportTemplateBuilder';

  return result;
};

const getDefaultValue = (widget: ServerWidget) => ({
  id: '',
  type: widget.type,
  // $FlowFixMe: widget.settings[key] is never null or undefined
  ...keys(widget.settings).reduce(
    (obj, key) => ({ ...obj, [key]: widget.settings?.[key]?.value }),
    {},
  ),
});

export const buildElement = (widget: ServerWidget) => {
  const defaultValue = getDefaultValue(widget);
  return {
    defaultValue,
    editor: buildReactComponent(widget),
    getTitle: () => widget.label,
    getDescription: () => widget.description,
  };
};
