import type { ComponentType } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { StoreType } from 'Types/Store';
import { extractNextPath } from '../PrivateRoute/private-routes';

type Props = {
  component: ComponentType<React.PropsWithChildren<unknown>>;
  isAuthenticated: boolean;
  state: StoreType;
};

/**
 * Redirect user to default route if user authorized
 * used to prevent user to go auth pages when user has active session
 */
const PublicRoute = ({ isAuthenticated, component: ResultComponent }: Props) => {
  if (isAuthenticated) {
    const nextPath = extractNextPath();
    return <Navigate replace to={nextPath ? `/?next=${nextPath}` : '/'} />;
  }
  return <ResultComponent />;
};

const mapStateToProps = (state) => ({
  isAuthenticated: (state.user || {}).isAuthenticated,
  state,
});

export default connect(mapStateToProps)(PublicRoute);
