import { KeydisKpisQuery } from 'Ghql';

export const useKpiFakeData = () => {
  const fakeData: KeydisKpisQuery['keydisKpis'] = {
    discoveredKeywords: 1168462,
    trafficValue: 2872910,
    estimatedVisits: 3220106,
    averageRank: 35.49,
    aboveTheFold: 88230,
    averageCtr: 0.0138,
  };

  return { fakeData };
};
