import React from 'react';
import { Flex } from '@mantine/core';
import cn from 'classnames';
import { t } from 'Utilities/i18n';
import { concatRulesNestedName, getIsGroupToken } from '../../../../support/helpers';
import { TokenGroup, TokenItem } from '../../../../support/types';
import { MAX_RULES_DEPTH } from '../../support/constants';
import { BuilderToken } from '../BuilderToken/BuilderToken';
import { TokenGroupActions } from './components/TokenGroupActions';
import { getActiveGroupDetails } from './support/helpers';
import { UseBuilderTokenGroupProps, useBuilderTokenGroup } from './support/hooks';
import styles from '../../dynamic-tags.module.scss';

type Props = UseBuilderTokenGroupProps & {
  index?: number;
  activeGroup?: string;
  setActiveGroup?: Function;
};

export const BuilderTokenGroup: React.FC<React.PropsWithChildren<Props>> = ({
  nestedLevel,
  name,
  deleteItem,
  index,
  ...props
}) => {
  const { error, isOrExpression, hasError, visibleGroupLine, isOddNestedGroup, actions, value } =
    useBuilderTokenGroup({ name, nestedLevel: nestedLevel ?? 0, deleteItem });
  const { activeEvents, groupDataKey } = getActiveGroupDetails(
    props.setActiveGroup,
    nestedLevel,
    index,
  );
  const isActive = groupDataKey === props.activeGroup;

  const renderToken = (element: TokenGroup | TokenItem, i) => {
    const nestedName = concatRulesNestedName(name, i);
    const deleteNestedItem = () => actions.onDelete(i);
    const isGroup = getIsGroupToken(element);
    const isTokenDeleteGroup = !isGroup && value?.tokens?.length === 1;
    return (
      <React.Fragment key={i}>
        {isGroup ? (
          <BuilderTokenGroup
            name={nestedName}
            nestedLevel={(nestedLevel ?? 0) + 1}
            deleteItem={deleteNestedItem}
            activeGroup={props.activeGroup}
            setActiveGroup={props.setActiveGroup}
            index={i}
          />
        ) : (
          <BuilderToken
            name={nestedName}
            deleteItem={deleteNestedItem}
            activeEvents={isTokenDeleteGroup ? activeEvents : undefined}
          />
        )}
      </React.Fragment>
    );
  };

  const isRoot = !nestedLevel;

  return (
    <div>
      {isRoot && (
        <div className={cn(styles.tokenGroupTitle, { [styles.tokenError]: hasError })}>
          <Flex gap={'4px'}>
            {t('Rules')} <div className={styles.requiredStar}>{'*'}</div>
          </Flex>
        </div>
      )}
      <div
        className={cn(styles.tokenGroup, {
          [styles.nestedOddGroup]: isOddNestedGroup,
          [styles.activeGroup]: isActive,
        })}
        data-rule-group={groupDataKey}
      >
        {visibleGroupLine && (
          <div className={styles.line} data-or-operator={isOrExpression?.toString()}>
            <div className={styles.operator} onClick={actions.toggleOperator}>
              {isOrExpression ? t('OR') : t('AND')}
            </div>
          </div>
        )}
        <div>
          <div>{value?.tokens?.map(renderToken)}</div>
          <TokenGroupActions
            actions={actions}
            hideDelete={isRoot && !value.tokens?.length}
            hideAddGroup={(nestedLevel ?? 0) > MAX_RULES_DEPTH}
            activeEvents={activeEvents}
          />
        </div>
        {error && <div className={styles.tokenGroupError}>{error}</div>}
      </div>
    </div>
  );
};
