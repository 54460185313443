import { useApolloClient } from '@apollo/client';
import { TableFetchDataCallBack } from 'Components/DataTable';
import { InvoicesTableDocument, InvoicesTableQuery } from '../../../Ghql';
import { useUser } from '../../../Hooks/data/user/useUser';

export const useFetchInvoices = (organizationId): TableFetchDataCallBack => {
  const client = useApolloClient();
  const user = useUser();

  return async () => {
    return client
      .query<InvoicesTableQuery>({
        query: InvoicesTableDocument,
        variables: { id: organizationId || user?.organization?.id },
      })
      .then((e) => {
        const data = e.data.organization?.invoices ?? [];
        return { data, length: data.length };
      });
  };
};
