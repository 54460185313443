import colorScheme from './colors';

function generateColorPalette(count, multipier = 100) {
  const palette: string[] = colorScheme.graphs.getColors();
  const saturationLightnessPairs = [
    [50, 60],
    [40, 50],
    [30, 40],
  ];

  for (let i = 0; i < count; i++) {
    const sectionIndex = Math.floor(i / 19);
    const [saturation, lightness] = saturationLightnessPairs[sectionIndex];
    const hue = (i * multipier) % 359;

    const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    palette.push(color);
  }

  return palette;
}

const colorPalette = generateColorPalette(50, 100);

export default colorPalette;
