import { useMemo } from 'react';
import { Avatar, Checkbox, Group, Pill, Stack } from '@mantine/core';
import AccAvatar from 'Components/AccAvatar';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { Field } from 'Components/Fields';
import AccText from 'Components/Text/AccText';
import { adminRole, allGroupsId, allGroupsUserRoles, defaultRole } from 'Constants/workspaces';
import { UsersQueryHookResult, useUsersQuery } from 'Ghql';
import { useUser } from 'Hooks/data/user/useUser';
import { t } from 'Utilities/i18n';
import { getUserTypeLabel } from 'Utilities/workspaces';
import styles from './field.module.scss';

type Props = {
  defaultValue?: string[];
  name: string;
  withinPortal?: boolean;
  size?: 'default' | 'sm' | 'md' | 'lg';
  searchMinWidth?: number;
  maxDisplayedValues?: number;
  isAllGroupsWorkspace: boolean;
};

const UsersToSelectItems = (
  data: UsersQueryHookResult['data'],
  isAdmin: boolean,
  isAllGroupsWorkspace: boolean,
  userId?: string | null,
) => {
  const options = data?.users
    ?.map((user) => {
      if (!user?.id) return null;
      const userType = user.userType || defaultRole;
      return {
        value: user.id,
        label: user.fullName,
        email: user.email,
        userType,
        inAllGroups: isAllGroupsWorkspace
          ? false
          : user.workspaces?.some((workspace) => workspace?.id === allGroupsId),
        isSelected: isAllGroupsWorkspace && allGroupsUserRoles.includes(userType),
        // disable selecting org admin if the current user is not an org admin
        disabled:
          (!isAdmin && userType === adminRole) ||
          user.id === userId ||
          (isAllGroupsWorkspace && allGroupsUserRoles.includes(userType)),
      };
    })
    .filter((user) => {
      if (!user) return false;
      if (!isAllGroupsWorkspace && allGroupsUserRoles.includes(user!.userType)) return false;
      return true;
    })
    .sort((userA, userB) => {
      if (userA!.disabled && userA!.isSelected) return 1;
      if (userB!.disabled && userB!.isSelected) return -1;
      return 0;
    });
  return options || [];
};

const UserOption = ({ label, email, isSelected, disabled, userType, inAllGroups }) => (
  <Group wrap="nowrap" gap="xs">
    <Checkbox checked={isSelected} color="snorlax.3" disabled={disabled} readOnly />
    <AccAvatar name={label || email || ''} email={email} size={26} />
    <Stack gap={1}>
      <AccText size="md" truncate>
        {label}
      </AccText>
      <Group gap="4">
        <Pill size="xs" c="white" bg="snorlax.1">
          {getUserTypeLabel(userType)}
        </Pill>
        {inAllGroups && (
          <Pill size="xs" c="white" bg="blue.0">
            {t('All Groups')}
          </Pill>
        )}
      </Group>
    </Stack>
  </Group>
);

const UserValue = ({ label, email }: { label: string; email?: string }) => {
  if (!email) return null;
  const avatar = <AccAvatar name={label || email || ''} email={email} size={28} hideTooltip />;
  return (
    <AccTooltip tooltip={label}>
      <div className={styles.userAvatar}>{avatar}</div>
    </AccTooltip>
  );
};

const StackComponent = ({ moreValue }) => {
  return (
    <Avatar
      variant="filled"
      size={28}
      className={styles.userAvatar}
      classNames={{
        root: styles.userGroup,
      }}
    >
      +{moreValue}
    </Avatar>
  );
};

const FieldUsers = (props: Props) => {
  const {
    defaultValue,
    name,
    withinPortal,
    size,
    searchMinWidth,
    maxDisplayedValues,
    isAllGroupsWorkspace,
  } = props;
  const user = useUser();
  const { data, loading } = useUsersQuery({ fetchPolicy: 'network-only' });

  const options = useMemo(
    () => UsersToSelectItems(data, user.userType === adminRole, isAllGroupsWorkspace, user.id),
    [data, user.userType, isAllGroupsWorkspace, user.id],
  );

  return (
    <Field.MultiSelect
      value={defaultValue || []}
      options={options}
      name={name}
      placeholder={t('Select users')}
      noResultsText={t('There are no users with that name')}
      withinPortal={withinPortal}
      size={size}
      isLoading={loading}
      clearable={false}
      creatable={false}
      searchMinWidth={searchMinWidth}
      maxDisplayedValues={maxDisplayedValues}
      hidePickedOptions={false}
      removeOnBackspace={false}
      optionComponent={UserOption}
      valueComponent={UserValue}
      stackComponent={StackComponent}
    />
  );
};

FieldUsers.defaultProps = {
  size: 'default',
  maxDisplayedValues: 6,
  searchMinWidth: 80,
};

export default FieldUsers;
