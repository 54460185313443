import { useEffect, useRef, useState } from 'react';
import { Box, LoadingOverlay } from '@mantine/core';
import { t } from 'Utilities/i18n';

/** Timeout in ms.
 *
 * Used to manually set an error state if it takes too long to load the preview */
const PREVIEW_TIMEOUT = 60 * 1000;

// eslint-disable-next-line import/no-unused-modules
export const PDFPreview = ({ url }: { url: string }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const embedRef = useRef<HTMLEmbedElement | null>(null);
  const timeoutRef = useRef<number | null>(null);

  const setLoaded = () => {
    setLoading(false);
    setError(null);
  };

  //track if the pdf is loaded
  useEffect(() => {
    const embed = embedRef.current;
    embed?.addEventListener('load', setLoaded);

    // Set a timeout to hide the loader and set an error message - we cannot get a proper error event from the embed
    timeoutRef.current = window.setTimeout(() => {
      if (loading) {
        setLoading(false);
        console.error('PDF preview took too long to load');
        setError(t('The PDF preview took too long to load. Please try again later.'));
      }
    }, PREVIEW_TIMEOUT);

    return () => {
      embed?.removeEventListener('load', setLoaded);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [loading]);

  useEffect(() => {
    setError(null);
    setLoading(true);
  }, [url]);

  return (
    // set min height to make sure loader is visible in Firefox
    <Box pos="relative" mih="1000px">
      {error ? (
        <div className="empty-preview">
          <p className="alert alert-info">{error}</p>
        </div>
      ) : (
        <>
          <LoadingOverlay
            visible={loading}
            zIndex={1000}
            styles={{ root: { alignItems: 'flex-start', paddingTop: 256 } }}
            overlayProps={{ blur: 2 }}
            loaderProps={{ color: 'orange', type: 'bars' }}
          />
          <embed
            title={t('Preview of the PDF report')}
            ref={embedRef}
            type="application/pdf"
            width="100%"
            height="1000px"
            src={url}
          />
        </>
      )}
    </Box>
  );
};
