export const ColumnIDs = {
  GRAVATAR: 'gravatar',
  FULL_NAME: 'fullName',
  EMAIL: 'email',
  USER_ROLE: 'userType',
  WORKSPACE: 'workspaces',
  DATE_JOINED: 'dateJoined',
  LAST_EDITED: 'lastEditOfWorkspaces',
  ACTIONS: 'actions',
} as const;
