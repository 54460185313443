import React from 'react';
import cn from 'classnames';
import ChillingTigerSvg from 'icons/grump/Chilling-tiger2.svg?inline';
import GrumpySmileSvg from 'icons/grump/GrumpySmile.svg?inline';
import StandingCautiousTigerSvg from 'icons/grump/StandingCautiousTiger.svg?inline';
import styles from './segment.module.scss';

export enum Images {
  GrumpySmile = 1,
  ChillingTiger = 2,
  StandingCautiousTiger = 3,
}

type SegmentProps = {
  primary?: boolean;
  placeholder?: boolean;
  info?: boolean;
  small?: boolean;
  hFull?: boolean;
  image?: Images;
  narrow?: boolean;
  className?: string;
  children: React.ReactNode;
};

const getImage = (image: Images | undefined): JSX.Element | null => {
  if (!image) {
    return null;
  }
  if (image === Images.GrumpySmile) {
    return <GrumpySmileSvg height={'180px'} />;
  }
  if (image === Images.ChillingTiger) {
    return <ChillingTigerSvg height={'100px'} width={'100px'} />;
  }
  if (image === Images.StandingCautiousTiger) {
    return <StandingCautiousTigerSvg height={'180px'} />;
  }
  return null;
};

const Segment = ({
  primary,
  placeholder,
  info,
  small,
  hFull,
  image,
  narrow,
  children,
  className,
}: SegmentProps): JSX.Element => {
  const klass = cn(
    styles.accuSegment,
    {
      [styles.placeholder]: placeholder,
      [styles.primary]: primary,
      [styles.info]: !primary && info,
      [styles.small]: small,
      [styles.hFull]: hFull,
    },
    className,
  );

  const contentClass = cn(styles.content, {
    [styles.narrow]: narrow,
  });

  const imageClass = cn(styles.image, narrow ? styles.smallMargin : styles.largeMargin);

  return (
    <div className={klass}>
      {image && (
        <>
          <div className={imageClass}>{getImage(image)}</div>
          <div className={contentClass}>{children}</div>
        </>
      )}
      {!image && <>{children}</>}
    </div>
  );
};

export default Segment;
