import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { useModal } from 'Hooks/base/useModal';
import { t } from 'Utilities/i18n';
import BuildCompetitorForm from './BuildCompetitorForm';
import './build-competitor.scss';

export type BuildCompetitorProps = {
  refresh: (...args: Array<any>) => any;
  competitorId?: string;
  domainId: string;
  domainToAdd?: string;
};

const BuildCompetitor = (props: BuildCompetitorProps) => {
  const { refresh, competitorId, domainId, domainToAdd } = props;

  const { hideModal } = useModal();

  return (
    <ModalBorder
      className="build-competitor"
      title={competitorId ? t('Edit Competitor') : t('Add Competitor')}
      onClose={hideModal}
    >
      <BuildCompetitorForm
        domainToAdd={domainToAdd}
        domainId={domainId}
        refresh={refresh}
        competitorId={competitorId}
      />
    </ModalBorder>
  );
};

export default BuildCompetitor;
