import moment from 'moment';
import { DateRangeType } from './CompareToContentSegmented';
import { DateRange, EARLIEST, LATEST } from './model';

export function getSegmentedDateRangeValue(
  value: DateRange,
  min: Date,
  notViewingLatestData?: boolean,
): DateRangeType {
  if (notViewingLatestData) return 'custom';

  const from = value.from as Date;
  const to = value.to;

  const yesterday = moment(new Date()).subtract(1, 'days').toDate();
  const weekAgo = moment(new Date()).subtract(1, 'week').toDate();
  const twoWeeksAgo = moment(new Date()).subtract(2, 'week').toDate();
  const lastMonth = moment(new Date()).subtract(1, 'month').toDate();
  const lastYear = moment(new Date()).subtract(1, 'year').toDate();

  if (
    moment(from).isBefore(min, 'day') ||
    (typeof from === 'string' && from === EARLIEST && to === LATEST)
  ) {
    return 'initial';
  }

  if (to === LATEST) {
    if (moment(from).isSame(yesterday, 'day')) {
      return 'yesterday';
    }

    if (moment(from).isSame(weekAgo, 'day')) {
      return 'last-week';
    }

    if (moment(from).isSame(twoWeeksAgo, 'day')) {
      return 'last-two-weeks';
    }

    if (moment(from).isSame(lastMonth, 'day')) {
      return 'last-month';
    }

    if (moment(from).isSame(lastYear, 'day')) {
      return 'last-year';
    }
  }
  return 'custom';
}
