import { useDispatch } from 'react-redux';
import { hideModal } from 'Actions/ModalAction';
import StandardReportForm from 'Components/Modal/Content/CompetitorsReport/CompetitorsReportForm';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { useDownloadFolderReportMutation } from 'Ghql';
import { useDomainId } from 'Hooks/data/domain/useQueryDomainInfo';
import { t } from 'Utilities/i18n';

export default function FoldersReport() {
  const domainId = useDomainId() as string;

  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(hideModal());
  };

  const [createReportMutation] = useDownloadFolderReportMutation();

  const nextModalType = 'FoldersReport';

  return (
    <ModalBorder title={t('Create Report')} onClose={handleClose}>
      <StandardReportForm
        domainId={domainId}
        createReportMutation={createReportMutation}
        nextModalType={nextModalType}
        dataField={'scheduleFoldersReport'}
      />
    </ModalBorder>
  );
}
