/* eslint-disable no-confusing-arrow */
import { memo, useState } from 'react';
import { Stack } from '@mantine/core';
import isEqual from 'lodash/isEqual';
import { AllSerpTypes } from 'Components/AccuTable/CellRenderer/helpers/serp-features';
import SerpFeatureBox from 'Components/SerpFeatureBox/SerpFeatureBox';
import AccText from 'Components/Text/AccText';
import AccTitle from 'Components/Title/AccTitle';
import { useSpecificFilter } from 'Hooks';
import { useRemoveFilter, useSetOrOverwriteFilter } from 'Hooks/data/filter/setFilters';
import { useKeydisKpis } from 'Pages/KeywordDiscovery/support/hooks/kpis';
import {
  FilterAttribute,
  FilterComparison,
  FilterValueType,
  PageSerpFeaturesFilter,
  PageSerpFeaturesOwnedFilter,
} from 'Types/Filter';
import { FilterTrackingKey } from 'Utilities/Analytics/mixpanel';
import { t, tct } from 'Utilities/i18n';
import { useSerpFakeData } from './useSerpFakeData';

const serpGuideUrl = 'https://www.accuranker.com/help/serp-features/';

interface SERPFeaturesProps {
  countryChoice: string | undefined;
  usePlaceholderData?: boolean;
}

// eslint-disable-next-line import/no-unused-modules
export const SERPFeatures = memo(
  ({ countryChoice, usePlaceholderData = false }: SERPFeaturesProps) => {
    const SERPfilter = useSpecificFilter(FilterAttribute.PAGE_SERP_FEATURES);
    const SERPfilterOwned = useSpecificFilter(FilterAttribute.PAGE_SERP_FEATURES_OWNED);
    const setFilter = useSetOrOverwriteFilter();
    const removeFilter = useRemoveFilter();

    const { data: filterCounts, loading: dataLoading } = useKeydisKpis(countryChoice);
    const { fakeData } = useSerpFakeData();
    const serpFeatureCounts: { [key: string]: number } = filterCounts?.serpFeatures || {};

    const sortedSerpFeatureCounts = Object.entries(serpFeatureCounts).sort(
      ([, val1], [, val2]) => val2 - val1,
    );

    const serpFeatures = usePlaceholderData ? fakeData : sortedSerpFeatureCounts;
    const loading = usePlaceholderData ? false : dataLoading;

    const [owned, setOwned] = useState(false);

    /**Toogle between owned and available SERP features */
    const handleChangeOwnership = () => {
      setOwned(!owned);
    };

    /**Update SERP filter */
    const handleSetSelectedSERPS = (SERPId: AllSerpTypes) => {
      const prevSERPArray = (owned ? SERPfilterOwned?.value : SERPfilter?.value) || [];

      const newSERPArray = prevSERPArray.includes(SERPId)
        ? prevSERPArray.filter((serp) => serp !== SERPId)
        : [...prevSERPArray, SERPId];

      const filterAttribute = owned
        ? FilterAttribute.PAGE_SERP_FEATURES_OWNED
        : FilterAttribute.PAGE_SERP_FEATURES;

      if (!newSERPArray.length) {
        if (owned) {
          removeFilter(filterAttribute);
        } else {
          removeFilter(filterAttribute);
        }
        return;
      }

      const nextFilter: PageSerpFeaturesOwnedFilter | PageSerpFeaturesFilter = {
        attribute: filterAttribute,
        type: FilterValueType.ARRAY,
        comparison: FilterComparison.ALL,
        value: newSERPArray,
      };
      setFilter(nextFilter, FilterTrackingKey.DiscoveryQuickAction);
    };

    return (
      <Stack gap={'xl'} style={{ flexGrow: 1, flexShrink: 0, flexBasis: '35%' }}>
        <SerpFeatureBox
          serpFeatures={serpFeatures}
          loading={loading}
          isOwnedFilter={owned}
          handleChangeOwnership={handleChangeOwnership}
          handleSetSelectedSERPS={handleSetSelectedSERPS}
          selectedSerpFeatures={owned ? SERPfilterOwned?.value : SERPfilter?.value}
          togglePlacement={'bottom'}
          label={
            <AccTitle
              type="h3"
              helper={
                <>
                  <AccTitle fw={600} type="h5">
                    {t('SERP Features')}
                  </AccTitle>
                  <AccText size="sm">
                    {tct(
                      'This box shows the SERP Features and their related keywords count.[br]Toggle between them to see which ones are owned and which ones are available.[br][guide]',
                      {
                        br: <br />,
                        guide: (
                          <a href={serpGuideUrl} target={'_blank'} rel="noopener noreferrer">
                            {t('Click to learn more')}
                          </a>
                        ),
                      },
                    )}
                  </AccText>
                </>
              }
            >
              {t('SERP Features')}
            </AccTitle>
          }
        />
      </Stack>
    );
  },
  isEqual,
);
SERPFeatures.displayName = 'SERPFeatures';
