/* eslint-disable @typescript-eslint/ban-ts-comment */

/* eslint-disable import/no-unresolved */
import { useEffect } from 'react';
import cookie from 'react-cookies';

declare global {
  interface Window {
    fp?: string;
  }
}

const COOKIE_NAME = 'fp';

export const useFingerprintTracking = () => {
  const setCookie = (value: string) => {
    cookie.save(COOKIE_NAME, value, {
      path: '/',
      domain: 'accuranker.com',
      sameSite: 'lax',
      secure: true,
    });
  };
  useEffect(() => {
    const savedFp: string | undefined = cookie.load(COOKIE_NAME);
    if (savedFp) {
      window.fp = savedFp;
      setCookie(window.fp);
    } else {
      import(
        // @ts-ignore
        'https://accuranker.com/SxXKHkbGMzWmkq8o/BhOEVDyLmu32oJK5?apiKey=IJmdR868Ho'
      )
        .then((FingerprintJS) =>
          FingerprintJS.load({
            endpoint: [
              'https://accuranker.com/SxXKHkbGMzWmkq8o/hGkmMgGjf2YHK0o7?region=eu',
              FingerprintJS.defaultEndpoint,
            ],
            region: 'eu',
          }),
        )
        .then((fp) => fp.get())
        .then((result) => {
          const visitorId = result.visitorId;
          window.fp = visitorId;
          if (window.fp) {
            setCookie(window.fp);
          }
        })
        .catch((error) => {
          console.error('FingerprintJS failed to load', error);
        });
    }
  }, []);
};
