import { tct } from 'Utilities/i18n';

export const domainDeleteConfirmation = (domain: string) => {
  return tct('The domain [name] and all its keywords will be permanently deleted.', {
    name: <strong>{domain}</strong>,
  });
};
export const groupDeleteConfirmation = (group: string) => {
  return tct('The group [name] and all the domains within it will be permanently deleted.', {
    name: <strong>{group}</strong>,
  });
};
