import isObject from 'lodash/isObject';

const KEY_SEPARATOR = '_';

/**
 * Create react key from dependencies list
 * used to force component re-render ignoring memo
 */
export const createReactKey = (deps: unknown): string => {
  if (Array.isArray(deps)) {
    return deps.map((e) => createReactKey(e)).join(KEY_SEPARATOR);
  } else if (isObject(deps)) {
    return Object.keys(deps || {})
      .map((key) => createReactKey(deps?.[key]))
      .join(KEY_SEPARATOR);
  }

  return deps?.toString() ?? '';
};
