import cn from 'classnames';
import AccButton from 'Components/AccButton/AccButton';
import Text from 'Components/Text/AccText';
import AccTitle from 'Components/Title/AccTitle';
import style from '../welcome.module.scss';

interface Props {
  title: string;
  body: string;
  actionText: string;
  action: () => void;
  icon: React.ReactNode;
  primary?: boolean;
}

const Action = (props: Props) => {
  const { title, body, actionText, action, primary, icon } = props;

  return (
    <div className={cn(style.actionRoot, primary && style.primaryAction)}>
      <AccTitle type="h5">{title}</AccTitle>
      <Text className={style.actionText} ta="justify" mt={8}>
        {body}
      </Text>
      <AccButton
        mt="auto"
        onClick={action}
        variant={primary ? 'primary' : 'secondary'}
        leftSection={icon}
      >
        {actionText}
      </AccButton>
    </div>
  );
};

export default Action;
