import { observer } from 'mobx-react';
import { useOptimizationContext } from '../store/components/OptimizationContext';

export const withRowOptimization = (Component: any) => {
  return observer((props: any) => {
    const optimization = useOptimizationContext();
    const store = optimization?.store;
    const primaryColumns: string[] | undefined = optimization?.config
      ? optimization?.config?.primaryColumns
      : undefined;
    const isPlaceholder =
      !props.isSubRow && optimization?.enabled
        ? props.index < (store?.from || 0) || props.index > (store?.to || Number.MAX_SAFE_INTEGER)
        : false;

    return <Component {...props} isPlaceholder={isPlaceholder} primaryColumns={primaryColumns} />;
  });
};
