import { AccMultiSelect, SelectItem } from 'Components/AccSelect';
import Skeleton from 'Components/Skeleton';
import { useDomainsInput_DomainsListQuery } from 'Ghql';
import { formatDomainOption } from 'Utilities/format';
import { t } from 'Utilities/i18n';
import { notEmpty } from 'Utilities/underdash';

type DomainsInputProps = {
  value: string[];
  onChange: (value: string[]) => void;
  showError?: boolean;
  disabled?: boolean;
};

const DomainsInputSkeleton = () => (
  <Skeleton
    linesConfig={[
      {
        type: 'text',
        options: {
          width: '120px',
          marginBottom: '10px',
        },
      },
    ]}
  />
);

const DomainsInput = ({ value, onChange, showError, disabled = false }: DomainsInputProps) => {
  const { data, loading } = useDomainsInput_DomainsListQuery();
  const domains: SelectItem<string>[] =
    data?.domainsList?.filter(notEmpty)?.map(formatDomainOption) || [];

  if (loading) {
    return <DomainsInputSkeleton />;
  }

  return (
    <AccMultiSelect
      autoFocus
      value={value}
      options={domains}
      onChange={onChange}
      showError={showError}
      disabled={disabled}
      placeholder={t('Enter domains')}
      creatable={false}
      inputMaxWidth={570}
    />
  );
};

export default DomainsInput;
