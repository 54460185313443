import { Link } from 'react-router-dom';
import { Flex } from '@mantine/core';
import AccText from 'Components/Text/AccText';
import AccTitle from 'Components/Title/AccTitle';
import { t } from 'Utilities/i18n';

export const SuccessPasswordChange = () => {
  return (
    <Flex direction="column" align="center" rowGap="md">
      <AccTitle type="h2" ta="center" mb="sm">
        {t('Password reset')}
      </AccTitle>
      <AccText mb="lg">{t('Your password has been reset.')}</AccText>
      <p>
        <Link to="/login">
          <b>{t('Continue to Log in')}</b>
        </Link>
      </p>
    </Flex>
  );
};
export const WrongLinkBlock = () => {
  return (
    <Flex direction="column" align="center" rowGap="md">
      <AccTitle type="h2" ta="center" mb="sm">
        {t('Invalid Link')}
      </AccTitle>
      <AccText mb="lg" ta="center">
        {t('Link is not valid or expired, please request new link to reset password.')}
      </AccText>
      <p>
        <Link to="/forgotpassword">
          <b>{t('Request new link to reset password')}</b>
        </Link>
      </p>
    </Flex>
  );
};
