import Breadcrumbs from 'Components/Breadcrumbs/Breadcrumbs';
import { t } from 'Utilities/i18n';
import ElementTypes from './ElementTypes';
import ReportTemplate from './ReportTemplate';
import TemplateParams from './TemplateParams';
import './report-template-builder.scss';

type ReportTemplateBuilderProps = {
  handleSave: (templateName: string, color: string, elements: string) => Promise<void>;
  backLink?: string;
};

const ReportTemplateBuilder = (props: ReportTemplateBuilderProps) => (
  <>
    <Breadcrumbs
      customParentTitle={t('Reporting')}
      customParentLink="/reports/templates"
      customTitle={t('Template Builder')}
    />
    <div className="report-template-builder">
      <div className="column-left">
        <ElementTypes />
      </div>
      <div className="column-right">
        <TemplateParams />
        <ReportTemplate handleSave={props.handleSave} backLink={props.backLink} />
      </div>
    </div>
  </>
);

export default ReportTemplateBuilder;
