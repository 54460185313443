import { useCallback } from 'react';
import { useStore } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import { TableFetchDataCallBack } from 'Components/DataTable';
import { getEnabledColumnQueriesByTable } from 'Components/Modal/Content/TableSettingsModal/support/helpers';
import { CompetitorsDocument, CompetitorsQuery, CompetitorsQueryVariables } from 'Ghql';
import { selectCurrentFilters } from 'Selectors/FilterSelector';
import { TableIDs } from 'Types/Table';
import { devError } from 'Utilities/log';

export const useFetchCompetitorData = (isPercentage: boolean): TableFetchDataCallBack<any> => {
  const client = useApolloClient();
  const store = useStore();
  return useCallback(
    async ({ ordering, pagination }: any) => {
      try {
        const filters = selectCurrentFilters(store.getState());
        const queryOptions = getEnabledColumnQueriesByTable(TableIDs.COMPETITORS)(store.getState);

        const variables: CompetitorsQueryVariables = {
          ...(queryOptions as object as CompetitorsQueryVariables),
          filters,
          ordering,
          pagination,
        };
        const result = await client.query<CompetitorsQuery, CompetitorsQueryVariables>({
          fetchPolicy: 'network-only',
          query: CompetitorsDocument,
          variables,
        });

        const data = result?.data?.competitorList?.competitors ?? [];
        return {
          data,
          length: data.length ?? 0,
        };
      } catch (e) {
        devError(e);
        return { data: [], length: 0 };
      }
    },
    [client, store, isPercentage],
  );
};
