import { useField } from 'react-final-form';
import isString from 'lodash/isString';
import { getDefaultToken, getEmptyGroupToken } from '../../../../../support/helpers';
import { TokenGroupType } from '../../../../../support/types';

export interface UseBuilderTokenGroupProps {
  name: string;
  nestedLevel: number;
  deleteItem?: any;
}

export const useBuilderTokenGroup = ({
  name,
  nestedLevel,
  deleteItem,
}: UseBuilderTokenGroupProps) => {
  const {
    input: { value, onChange },
    meta,
  } = useField(name);

  const isOrExpression = value.expression === TokenGroupType.OR;
  const isOddNestedGroup = (nestedLevel ?? 0) % 2;

  const actions = {
    onDelete: (index: number) => {
      const nextTokens = value.tokens.filter((_, i) => i !== index);

      if (!nextTokens?.length && nestedLevel !== 0) {
        deleteItem?.();
        return;
      }

      onChange({ ...value, tokens: value.tokens.filter((_, i) => i !== index) });
    },
    addItem: () => {
      onChange({ ...value, tokens: [...value.tokens, getDefaultToken()] });
    },
    addGroupItem: () => {
      onChange({ ...value, tokens: [...value.tokens, getEmptyGroupToken()] });
    },
    toggleOperator: () => {
      onChange({ ...value, expression: isOrExpression ? TokenGroupType.AND : TokenGroupType.OR });
    },
    deleteGroup: (): void => {
      deleteItem ? deleteItem() : onChange({ ...value, tokens: [] });
    },
  };
  const visibleGroupLine = value.tokens?.length > 1;
  const hasError =
    (meta.submitFailed ? !meta.dirtySinceLastSubmit : meta.pristine) && meta.submitError;
  const error = isString(meta.submitError) ? meta.submitError : '';
  return {
    error,
    hasError,
    visibleGroupLine,
    isOddNestedGroup,
    actions,
    isOrExpression,
    value,
    onChange,
  };
};

export type BuilderTokenActions = ReturnType<typeof useBuilderTokenGroup>['actions'];
