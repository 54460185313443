import AccText from 'Components/Text/AccText';
import { UNCATEGORIZED_FOLDER_ID } from 'Pages/Keywords/Groupings/EditMode/components/DeleteFolderModal';
import { t, tct } from 'Utilities/i18n';
import { parseObjectStr } from 'Utilities/underdash';
import { SourceKey } from '../components/DynamicTagsBuilder/support/constants';
import { StringOperation } from './constants';
import { DynamicTagFormValues, DynamicTagItem, TokenGroup, TokenItem } from './types';

export const getDefaultToken = (): Partial<TokenItem> => ({
  source: SourceKey.KEYWORD,
  operator: StringOperation.CONTAINS,
  value: undefined,
});

export const getEmptyGroupToken = () => ({ expression: 'and', tokens: [{ ...getDefaultToken() }] });
export const convertDynamicTagsToInitialValues = (value: DynamicTagItem): DynamicTagFormValues => {
  const rawRuleset = value.rawRuleset
    ? parseObjectStr(value.rawRuleset)
    : (getEmptyGroupToken() as TokenGroup);

  return {
    id: value?.id,
    tag: value?.tag || '',
    folder: value?.folder || UNCATEGORIZED_FOLDER_ID,
    rawRuleset,
  };
};

export const getIsGroupToken = (val: TokenGroup | TokenItem): val is TokenGroup => {
  return !!(val as TokenGroup).expression;
};

export const concatRulesNestedName = (name: string, index: number) => {
  return `${name ? `${name}.` : ''}tokens[${index}]`;
};

export const CPCHelperText = () => {
  return (
    <>
      <AccText size="xs">
        {t(
          'Select your custom currency that fits your market. This will reflect on your CPC & traffic values on this domain.',
        )}
        <br />
        <br />
        {tct(
          'If set to \'[accountDefault]\', this domain will follow your account\'s CPC currency setting.',
          { accountDefault: t('Account Default') },
        )}
      </AccText>
    </>
  );
};

export const LocalSearchHelperText = () => {
  return (
    <>
      <AccText size="xs">
        {t(
          'When enabled, all search volume figures and related metrics (AI Share of Voice, Cost per Click, Ad Competition, Share of Voice, and Traffic Value) where a specific keyword location is specified will display local metrics. If disabled, all related metrics will be based on country values. Learn more',
        )}{' '}
        <a
          href={'https://www.accuranker.com/help/keywords/searches/'}
          target={'_blank'}
          rel="noreferrer"
        >
          {t('here.')}
        </a>
      </AccText>
    </>
  );
};
