import { useRef } from 'react';
import { useNavigate } from 'react-router';
import { Options } from 'highcharts';
import { LineChart } from 'Components/Chart/LineChart';
import {
  SeriesDataItem,
  TooltipConfiguration,
  chartTooltipTypes,
} from 'Components/Chart/LineChart/support/types';
import { useQueryNotes } from 'Components/Chart/Notes';
import ExportButton from 'Components/ExportButton';
import linkToKeywordListFromSerpChart from 'Components/Filters/LinkToKeywordListFromSerpChart';
import Segment from 'Components/Segment';
import { getSerpFeatureData } from 'Components/Table/TableRow/SERPOptions/data';
import { useDomainId } from 'Hooks/data/domain/useQueryDomainInfo';
import { getPointField } from 'Pages/Keywords/Overview/components/StockTooltipContent/helpers';
import { colorScheme } from 'Utilities/colors';
import { t, tct } from 'Utilities/i18n';
import './serps-chart-history.scss';

type ChartHistoryProps = {
  data: SeriesDataItem[];
  selectedSerp: string;
  isLoading: boolean;
  ownedChart: boolean;
  updateKey: number;
};

const ChartHistoryChart = ({
  data,
  selectedSerp,
  isLoading,
  ownedChart,
  updateKey,
}: ChartHistoryProps) => {
  const { notes } = useQueryNotes();

  const navigate = useNavigate();
  const domainId = useDomainId();

  const exportContainer = useRef<HTMLDivElement>(null);
  if ((!data || !data.length) && !isLoading) {
    const noDataMsg = t('No SERP feature data for the selected filters');
    return <Segment placeholder>{noDataMsg}</Segment>;
  }

  const extraOptions: Options = {
    navigator: {
      series: {
        lineColor: colorScheme.graphs.graphBlueColors[0],
      },
    },
    plotOptions: {
      areaspline: { fillColor: colorScheme.graphs.graphBlueColors[4] },
      series: {
        point: {
          events: {
            click: (event) => {
              if (event.point.category) {
                navigate(
                  linkToKeywordListFromSerpChart(
                    domainId,
                    event.point.category,
                    false,
                    selectedSerp,
                  ),
                );
              }
            },
          },
        },
      },
    },
  };

  const serpFeature = getSerpFeatureData(selectedSerp).label?.toLowerCase();

  const tooltipConfiguration: TooltipConfiguration = {
    type: chartTooltipTypes.STOCK,
    title: ownedChart ? t('Owned percentage') : t('Total count'),
    showPercentage: false,
    dontShowTotalKeywords: true,
    customText: (context: Highcharts.TooltipFormatterContextObject) =>
      tct(
        'You owned [ownedCount] out of [totalCount] available [serpFeature].[lineBreak][lineBreak]Click to see keywords with [serpFeature] on this date.',
        {
          totalCount: <strong>{getPointField(context.points, 'totalCount')}</strong>,
          ownedCount: <strong>{getPointField(context.points, 'totalOwnedCount')}</strong>,
          serpFeature,
          lineBreak: <br />,
        },
      ),
  };

  const dataToPlot = data.map((item) => {
    return {
      totalCount: item.totalCount || 0,
      totalOwnedCount: item.totalOwnedCount || 0,
      x: item.x,
      y: ownedChart
        ? typeof item.totalOwnedCount === 'number'
          ? item.totalCount
            ? (100 * item.totalOwnedCount) / item.totalCount
            : 0
          : null
        : typeof item.totalCount === 'number'
        ? item.totalCount
        : null,
    };
  });

  return (
    <div className="chart-content" ref={exportContainer}>
      <ExportButton content={() => exportContainer.current} />
      <LineChart
        key={updateKey}
        series={[
          {
            color: colorScheme.graphs.graphBlueColors[0],
            data: dataToPlot,
            name: ownedChart ? t('Percentage owned') : t('Total count'),
          },
        ]}
        notes={notes}
        chartType="area"
        showPercentage={ownedChart}
        tooltip={tooltipConfiguration}
        extraOptions={extraOptions}
        enableRangeSelector
        loaderType="line"
        disableLegend
        exportButton={false}
        isLoading={isLoading}
      />
    </div>
  );
};

export default ChartHistoryChart;
