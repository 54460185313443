import isNumber from 'lodash/isNumber';
import { FilterComparison } from 'Types/Filter';

// modify filter before setting into state
export const onNumberFilterUpdate = (filter: any) => {
  // filters value:<number> and comparison:<FilterComparison.NO_FILTER> is not valid filter
  // in such case we need to reset comparison to defaultComparison [ARR-1244]

  if (filter.comparison === FilterComparison.NO_FILTER && isNumber(filter.value)) {
    return { ...filter, comparison: FilterComparison.LT };
  }

  return filter;
};
