import * as React from 'react';
import uniqueId from 'lodash/uniqueId';
import { AccFastIcon } from 'Components/AccFastIcon';
import FilterCount from 'Components/Filters/Common/FilterCount';
import Hint from 'Components/Hint';
import Checkbox from '../Checkbox';
import './checkbox-group.scss';

export type ValueLabel = {
  value: any;
  label: string;
  Icon?: string | (() => JSX.Element);
  hint?: string;
  children?: Array<ValueLabel>;
  count?: number;
};
// eslint-disable-next-line import/no-unused-modules
export type CheckboxGroupProps = {
  onChange: (...args: Array<any>) => void;
  items: ValueLabel[];
  value: Array<string>;
  disabled?: boolean;
  className?: string;
  column?: boolean;
};

const CheckboxGroup = ({
  onChange,
  value,
  disabled = false,
  className = '',
  items,
  column,
}: CheckboxGroupProps): JSX.Element => {
  const defaultGroupName = uniqueId('checkboxgroup');

  const handleOptionChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const optionValue = event.currentTarget.value;
    let newValue: any;

    if (event.currentTarget.checked) {
      newValue = [...(value || []), optionValue];
    } else {
      newValue = (value || []).filter((currentOptionValue) => currentOptionValue !== optionValue);
    }

    onChange(newValue);
  };

  const renderIcon = (option: ValueLabel) => {
    if (!option.Icon) return null;
    return (
      <span style={{ marginLeft: 'auto' }}>
        {typeof option.Icon === 'string' ? (
          <AccFastIcon src={option.Icon} size={16} color={'#A4A6B3'} fit={'contain'} />
        ) : (
          <option.Icon />
        )}
      </span>
    );
  };

  const renderInColumn = (elements: ValueLabel[] | undefined) => {
    if (!elements) return null;

    return elements.map((option) => {
      if (option.children && option.children.length) {
        return (
          <React.Fragment key={`section-${option.value}`}>
            <span className="column-label">{option.label}</span>
            <section className="column-container">{renderInColumn(option.children)}</section>
          </React.Fragment>
        );
      }

      return (
        <div className="column-item" key={`item-${option.value}`}>
          <Checkbox
            value={option.value}
            name={defaultGroupName}
            checked={(value as any).indexOf(option.value) !== -1}
            disabled={disabled}
            onChange={handleOptionChange}
          >
            {option.label}
            <FilterCount count={option.count} inDropdown />
            {renderIcon(option)}
          </Checkbox>
        </div>
      );
    });
  };

  const getOptions = (elements: Array<ValueLabel>) => {
    const showItems: any = [];
    elements.forEach((option) => {
      if (option.children && option.children.length) {
        showItems.push(<span className="label">{option.label}</span>);
        showItems.push(...getOptions(option.children));
      } else {
        showItems.push(
          <Checkbox
            key={option.value}
            value={option.value}
            name={defaultGroupName}
            checked={(value as any).indexOf(option.value) !== -1}
            disabled={disabled}
            onChange={handleOptionChange}
          >
            {option.label}
            {option.hint && <Hint help={option.hint} />}
            <FilterCount count={option.count} inDropdown />
            {renderIcon(option)}
          </Checkbox>,
        );
      }
    });
    return showItems;
  };

  if (column) {
    return <div className="checkboxgroup-container">{renderInColumn(items)}</div>;
  }

  return (
    <div className={`checkbox-group ${className || ''}`}>
      {items.length > 0 && getOptions(items)}
    </div>
  );
};

export default CheckboxGroup;
