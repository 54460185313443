import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { gql, useMutation } from '@apollo/client';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { hideModal } from 'Actions/ModalAction';
import AccButton from 'Components/AccButton/AccButton';
import PhoneInput from 'Components/Forms/PhoneInput';
import Icon from 'Components/Icon';
import Spinner from 'Components/Spinner';
import { t } from 'Utilities/i18n';
import Validator from 'Utilities/validation';
import './register-phone-verify.scss';

const formName = 'PhoneVerifyForm';
const performPhoneVerificationMutation = gql`
  mutation registerPage_userPhoneVerification($input: RegisterPhoneVerificationInput!) {
    userPhoneVerification(input: $input) {
      errors {
        field
        messages
      }
      phoneNumberVerificationSid
      verified
    }
  }
`;

const phoneFieldRenderer = ({ input }) => {
  return <PhoneInput id="phoneNumber" input={input} name="phoneNumber" {...input} />;
};

// TODO props: unconfirmedUserId, phoneNumberVerificationSid, initialValues, callback
const PhoneVerifyForm = (props) => {
  const [channel, setChannel] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [editPhoneEnabled, setEditPhoneEnabled] = useState(false);
  const [codeSend, setCodeSend] = useState(props.phoneNumberVerificationSid !== null);
  const [performPhoneVerification, { data: performPhoneVerificationData }] = useMutation(
    performPhoneVerificationMutation,
  );
  const [errors, setErrors] = useState<any>([]);
  const formRef = useRef(null);
  useEffect(() => {
    if (performPhoneVerificationData && performPhoneVerificationData.userPhoneVerification) {
      const { userPhoneVerification } = performPhoneVerificationData;

      if (userPhoneVerification.errors.length === 0) {
        setErrors([]);

        if (editPhoneEnabled) {
          setEditPhoneEnabled(false);
        }

        setCodeSend(userPhoneVerification.phoneNumberVerificationSid !== null);

        if (userPhoneVerification.verified !== null) {
          props.hideModal && props.hideModal();
          props.callback(props.formValues.phoneNumber);
        }
      } else {
        setErrors(userPhoneVerification.errors);
      }
    }
  }, [performPhoneVerificationData]);

  function handleSubmit(values) {
    setIsLoading(true);

    if (editPhoneEnabled && !codeSend) {
      // update phone number
      const updatePhone = performPhoneVerification({
        variables: {
          input: {
            unconfirmedUserId: props.unconfirmedUserId,
            phone: values.phoneNumber,
          },
        },
      });
      const sendCode = performPhoneVerification({
        variables: {
          input: {
            unconfirmedUserId: props.unconfirmedUserId,
            channel: values.channel,
          },
        },
      });
      return Promise.all([updatePhone, sendCode]).then(() => setIsLoading(false));
    } else if (!codeSend) {
      // send new code
      return performPhoneVerification({
        variables: {
          input: {
            unconfirmedUserId: props.unconfirmedUserId,
            channel: values.channel,
          },
        },
      }).then(() => setIsLoading(false));
    }

    // else validate code
    return performPhoneVerification({
      variables: {
        input: {
          unconfirmedUserId: props.unconfirmedUserId,
          code: values.code,
        },
      },
    }).then(() => setIsLoading(false));
  }

  const handleEditPhone = () => {
    setErrors([]);
    setEditPhoneEnabled(!editPhoneEnabled);
    setCodeSend(false);
  };

  const handleSetChannel = (updatedChannel) => {
    props.handleSubmit(handleSubmit({ ...props.formValues, channel: updatedChannel }));
    setChannel(updatedChannel);
  };

  return (
    <form ref={formRef} onSubmit={props.handleSubmit(handleSubmit)}>
      <div className="phone-register-verify">
        <div className="form-wrapper">
          <div>
            {editPhoneEnabled && (
              <div>
                <Field
                  name="phoneNumber"
                  component={phoneFieldRenderer}
                  validate={[Validator.required, Validator.phone]}
                />
              </div>
            )}
          </div>
          {!codeSend && (
            <div>
              <div className="channel-selector">
                <div
                  className={'channel-selector__item '.concat(
                    channel === 'sms' ? 'channel-selector__item--active' : '',
                  )}
                  onClick={() => handleSetChannel('sms')}
                >
                  <div className="channel-selector__item__action-icon">
                    <Icon className="icon-phone" icon="icon-phone" />
                  </div>
                  <div>{t('Send code by SMS')}</div>
                  <div className="channel-selector__item__forward-icon">
                    <Icon className="icon-arrow-right" icon="arrow-right" />
                  </div>
                </div>
                <div
                  className={'channel-selector__item '.concat(
                    channel === 'call' ? 'channel-selector__item--active' : '',
                  )}
                  onClick={() => handleSetChannel('call')}
                >
                  <div className="channel-selector__item__action-icon">
                    <Icon className="icon-chat" icon="icon-chat" />
                  </div>
                  <div>{t('Send code by automated voice call')}</div>
                  <div className="channel-selector__item__forward-icon">
                    <Icon className="icon-arrow-right" icon="arrow-right" />
                  </div>
                </div>
              </div>
            </div>
          )}
          {codeSend && (
            <div>
              <label>
                <span>{t('Please enter the code you have received:')}</span>
                <div className="code-container">
                  <div className="code-container__input">
                    <Field
                      label={t('Code')}
                      placeholder={t('Enter code')}
                      className="required"
                      name="code"
                      elementType="input"
                      component="input"
                      type="text"
                      validate={[Validator.required]}
                    />
                  </div>
                </div>
              </label>
            </div>
          )}
          <div className="form-group">
            {errors.length > 0 && (
              <div>
                <span className="error-message">{errors[0]?.messages?.[0]}</span>
              </div>
            )}
            {codeSend && (
              <AccButton
                variant="primary"
                type="submit"
                disabled={props.submitting || props.invalid || isLoading}
              >
                {editPhoneEnabled
                  ? t('Update & send')
                  : codeSend
                  ? t('Validate code')
                  : t('Send code')}
              </AccButton>
            )}
            {isLoading && <Spinner />}

            {!editPhoneEnabled && codeSend && (
              <div className="channel-selector" onClick={() => handleEditPhone()}>
                <div className={'channel-selector__item channel-selector__item--active'}>
                  <div className="channel-selector__item__action-icon">
                    <Icon className="icon-phone" icon="icon-phone" />
                  </div>
                  <div>
                    <strong>{props.formValues.phoneNumber}</strong>
                    <br />
                    {t('Edit phone number or verification method')}
                  </div>
                  <div className="channel-selector__item__forward-icon">
                    <Icon className="icon-arrow-right" icon="arrow-right" />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = (state) => ({
  formValues: getFormValues(formName)(state) || {}, //This basically gives us the form values in the props and it gets updated on keydown.
});

const decoratedComponent = connect(mapStateToProps, {
  hideModal,
})(PhoneVerifyForm);
export default reduxForm({
  form: formName,
  enableReinitialize: true,
  shouldAsyncValidate: ({ trigger }) => ['blur'].includes(trigger),
})(decoratedComponent);
