/* eslint-disable @typescript-eslint/no-non-null-assertion */
import flatMap from 'lodash/flatMap';
import { ColumnSetting, ColumnType } from 'Components/DataTable';
import {
  FolderColumnID,
  NaverFolderColumnBlacklist,
} from 'Pages/Keywords/Groupings/support/constants';
import { NaverBlacklist } from 'Pages/Keywords/Table/useKeywordTableInfo';
import { DomainTypeChoices } from 'Query';
import { GetState, StoreType } from 'Types/Store';
import { capitalizeFirstChar } from 'Utilities/underdash';
import {
  getActiveGroups,
  getVisibleColumnsByTableSettings,
} from '../NextTableSettings/support/helpers';
import { TableSettingGroup } from '../NextTableSettings/support/types';
import { TableSettingId, getTableSetting } from './config';
import { GroupSettingsItem, TableSettingVersion } from './types';

const getIsColumnConfigVisible = (
  column: ColumnSetting | undefined,
  hasAdvancedMetrics: boolean | undefined,
) => {
  const tableSetting = column?.tableSetting;
  return (
    !(tableSetting?.required || tableSetting?.disabled) &&
    (hasAdvancedMetrics || !tableSetting?.requiresAdvancedMetrics)
  );
};

export const extractVisibleColumns = (
  columns: ColumnSetting[],
  hasAdvancedMetrics: boolean | undefined,
) => {
  return columns.filter((columnSetting) =>
    getIsColumnConfigVisible(columnSetting, hasAdvancedMetrics),
  );
};

export const filterColumnsByDomainType = (
  tableName: string,
  columns: ColumnSetting[],
  domainType: DomainTypeChoices | null | undefined,
) => {
  if (tableName === 'keywords' && domainType === DomainTypeChoices.A_7) {
    return columns.filter((columnSetting) => !NaverBlacklist.includes(columnSetting.id));
  }
  if (tableName === 'folders' && domainType === DomainTypeChoices.A_7) {
    return columns.filter(
      (columnSetting) => !NaverFolderColumnBlacklist.includes(columnSetting.id as FolderColumnID),
    );
  }
  return columns;
};

export const getDefaultVisibleColumns = (columns: ColumnSetting[]): string[] => {
  return columns
    .filter(
      (e) =>
        !e.tableSetting?.disabled && (!e?.tableSetting?.defaultHide || e.tableSetting.required),
    )
    .filter(Boolean)
    .map((el) => el?.id);
};

export const filterVisibleColumns = (
  columns: ColumnType[],
  columnSettings: ColumnSetting[],
  tableSettings: any[] | string | null,
) => {
  const visibleColumnIds: string[] = !tableSettings
    ? getDefaultVisibleColumns(columnSettings)
    : columnSettings
        .filter((e) => e.tableSetting?.required || tableSettings?.includes(e?.id))
        ?.map((e) => e?.id);
  return columns.filter((e) => visibleColumnIds?.includes(e.id ?? '')) ?? [];
};

/**
 * Used to add fields that are not visible for selection, but we need to include them:
 * - required columns
 * - group columns, that part of initial column *(for example SoV and SoV Change)
 */
export const formatSavedColumnsData = <T extends string = string>(
  columnIds: T[],
  columns: ColumnSetting<T>[],
) => {
  return columns
    .filter((e) => {
      return e.tableSetting.required || columnIds.some((id) => id === e.id);
    })
    .map((e, index) => ({
      index,
      id: e.id,
      pinned: 'center',
    }));
};

const getActiveParams = (
  configItems: TableSettingGroup[] | null,
  groups: GroupSettingsItem[] | null,
  columns: string[] | null,
): Record<string, Record<string, boolean>> => {
  const activeGroups = getActiveGroups(configItems, groups);

  const result: Record<string, Record<string, boolean>> = {};

  activeGroups?.forEach((group) => {
    group?.options.forEach((option) => {
      const isActiveParameter =
        option.isParameter && (columns?.includes(option.id) || option.disabled);

      if (isActiveParameter && option.parentId) {
        result[option.parentId] = { ...(result[option.parentId] || []), [option.id]: true };
      }
    });
  });

  return result;
};

export const formatTableColumnsByTableSettings = ({
  columns,
  groups,
  groupSettings,
  tableSettings,
}: {
  columns: ColumnType[];
  groups: TableSettingGroup[] | null;
  groupSettings: GroupSettingsItem[] | null;
  tableSettings: string[] | null;
}) => {
  const visibleColumns = getVisibleColumnsByTableSettings(groups, groupSettings, tableSettings);
  const params = getActiveParams(groups, groupSettings, tableSettings);

  return visibleColumns
    .map((id) => columns.find((el) => el.id === id))
    .filter(Boolean)
    .map((column) => {
      if (column?.id && params?.[column.id]) {
        const extraParams = params?.[column.id];
        column.cellRendererParams = {
          ...column.cellRendererParams,
          ...extraParams,
        };
      }

      return column;
    });
};

export const getFormColumnIds = (
  savedItems: string[] | null,
  columns: ColumnSetting[],
  hasAdvancedMetrics: boolean,
) => {
  const resultColumns = savedItems ?? getDefaultVisibleColumns(columns);

  return resultColumns
    .filter((id) =>
      getIsColumnConfigVisible(columns.find((el) => el.id === id)!, hasAdvancedMetrics),
    )
    .filter(Boolean);
};

export const getAdvancedMetricsColumns = (columns: ColumnSetting[]) => {
  return (
    columns?.filter(
      (columnSetting) =>
        columnSetting?.tableSetting?.requiresAdvancedMetrics &&
        getIsColumnConfigVisible(columnSetting, true),
    ) ?? []
  );
};

export const getIsOrderColumnWasRemoved = (
  formColumnIds: string[],
  columns: ColumnSetting[],
  orderBy: string,
) => {
  return !formColumnIds
    .map((e) => columns.find((el) => el.id === e))
    .filter(Boolean)
    .some((e) => e?.queryWithClauses?.includes(orderBy ?? ''));
};

const getEnabledColumnQueries = (
  columnIds: string[] | undefined,
  columnSettings: ColumnSetting[],
  initParams: { [key: string]: boolean },
) => {
  return flatMap(columnIds?.map((id) => columnSettings.find((e) => e.id === id)?.queryWithClauses))
    .filter(Boolean)
    .reduce((acc, e) => ({ ...acc, [`with${capitalizeFirstChar(e)}`]: true }), initParams);
};

const getEnabledColumnQueriesWithGroups = ({
  selector,
  selectGroups,
  columns,
  groups,
  getState,
  queryOptions,
}: {
  selector: (a: StoreType) => any;
  selectGroups;
  columns;
  groups: TableSettingGroup[] | null;
  getState: GetState;
  queryOptions;
}) => {
  const tableSettings = selector(getState());
  const groupSettings = selectGroups(getState());

  const visibleColumnIds = getVisibleColumnsByTableSettings(groups, groupSettings, tableSettings);
  return getEnabledColumnQueries(visibleColumnIds, columns ?? [], queryOptions ?? {});
};

const getEnabledColumnQueriesWithColumns = ({ selector, getState, columns, queryOptions }) => {
  const columnIds = selector(getState()) || getDefaultVisibleColumns(columns ?? []);
  return getEnabledColumnQueries(columnIds, columns ?? [], queryOptions ?? {});
};

export const getEnabledColumnQueriesByTable = (tableName: TableSettingId) => {
  return (getState: GetState) => {
    const {
      selector,
      columns,
      version,
      groups = null,
      selectGroups,
      queryOptions,
    } = getTableSetting({
      key: tableName,
    });

    if (version === TableSettingVersion.GROUPS) {
      return getEnabledColumnQueriesWithGroups({
        selector,
        selectGroups,
        columns,
        groups,
        getState,
        queryOptions,
      });
    }
    return getEnabledColumnQueriesWithColumns({ selector, getState, columns, queryOptions });
  };
};

export const getIsValidOrdering = (
  tableName: TableSettingId,
  getState: () => StoreType,
  orderBy: string,
) => {
  const {
    selector,
    columns,
    groups: groupSettings,
    selectGroups,
    version,
  } = getTableSetting({ key: tableName });
  const groups = selectGroups?.(getState()) || null;
  const columnIds = (selector(getState()) || getDefaultVisibleColumns(columns ?? [])) as string[];

  const columnId = columns?.find(
    (e) => e?.tableSetting?.orderBy === orderBy || e?.id === orderBy,
  )?.id;

  const columnOrderBy = columns?.find((column) => column.tableSetting?.orderBy === orderBy)?.id;

  const visibleColumns = getVisibleColumnsByTableSettings(groupSettings || null, groups, columnIds);

  //validate groups tablesettings against visible columns and classic settings against stored columnIds
  return !!(version === TableSettingVersion.GROUPS
    ? columnOrderBy && visibleColumns?.includes(columnOrderBy)
    : columnId && columnIds?.includes(columnId));
};
