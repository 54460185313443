import { normalizeTree } from '../../../../../../Utilities/Table/Tree/treeUtils';
import { ROOT_ID, UNCATEGORIZED_FOLDER_ID } from '../constants';
import { convertListToTree, getChildrenFromTree } from '../helpers';

/**
 * Delete a node from a tree, also handle deleting children or not deleting children
 * - deleteContent - if true, delete all children of the node, if false move all children to uncategorized folder;
 */
export const useDeleteNode = (
  setTreeNested: (nodes: any) => any,
  setSelectedNodes: (nodes: any) => any,
  setIsDirty: (isDirty: boolean) => void,
) => {
  return (deleteId: string) => {
    setTreeNested((tree) => {
      const resultTree = normalizeTree(tree);
      const item = resultTree.find((e) => e.id === deleteId);
      const children = item?.isFolder ? getChildrenFromTree(item).map((e) => e.id) : [];
      const itemsToDelete = [deleteId];

      if (children?.length) {
        let hasUncategorized = false;
        let requireUncategorized = false;
        resultTree.forEach((e) => {
          if (e.id === UNCATEGORIZED_FOLDER_ID) {
            hasUncategorized = true;
          }
          if (children.includes(e.id) && e.id !== deleteId) {
            requireUncategorized = true;
            e.parent = UNCATEGORIZED_FOLDER_ID;
          }
        });

        if (!hasUncategorized && requireUncategorized) {
          resultTree.push({
            id: UNCATEGORIZED_FOLDER_ID,
            name: UNCATEGORIZED_FOLDER_ID,
            droppable: 1,
            isFolder: 1,
            text: UNCATEGORIZED_FOLDER_ID,
            children: [],
            parent: ROOT_ID,
          });
        }
      }
      setIsDirty(true);
      setSelectedNodes((nodes) => {
        return nodes.filter((e) => !itemsToDelete?.includes(e?.id));
      });
      return convertListToTree(resultTree.filter((e) => !itemsToDelete.includes(e.id)));
    });
  };
};
