import { Box } from '@mantine/core';
import AccButton from 'Components/AccButton/AccButton';
import { Field, Form } from 'Components/Fields';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import { t } from 'Utilities/i18n';
import Validator from 'Utilities/validation';
import styles from './groupModal.module.scss';

export interface GroupFormValues {
  groupName: string;
}

type Props = {
  title: string;
  hideModal: () => any;
  handleSubmit: (...args: Array<any>) => any;
  initialValues?: GroupFormValues;
};

const GroupModalView = (props: Props) => {
  const { title, hideModal, handleSubmit, initialValues } = props;
  return (
    <ModalBorder className={styles.groupModal} title={title} onClose={hideModal}>
      <Form onSubmit={handleSubmit} initialValues={initialValues}>
        {({ submitting, invalid, pristine }) => (
          <>
            <Box>
              <div className="form-label required">{t('Group Name')}</div>
              <Field.TextInput
                autoFocus
                name="groupName"
                placeholder={t('Group name')}
                required
                validate={[Validator.required]}
              />
            </Box>
            <ModalFooter
              primaryButtonSlot={
                <AccButton
                  variant="primary"
                  type="submit"
                  disabled={submitting || invalid || pristine}
                >
                  {t('Save')}
                </AccButton>
              }
              secondaryButtonSlot={
                <AccButton variant="tertiary" disabled={submitting} onClick={hideModal}>
                  {t('Cancel')}
                </AccButton>
              }
            />
          </>
        )}
      </Form>
    </ModalBorder>
  );
};

export default GroupModalView;
