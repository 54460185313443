import { useCallback, useEffect, useMemo, useState } from 'react';
import { Flex } from '@mantine/core';
import { DatePicker, DateValue } from '@mantine/dates';
import dayjs from 'dayjs';
import 'dayjs/locale/da';
import { useLanguage } from 'Hooks/data/domain/useQueryDomainInfo';

type DateRange = {
  from: Date;
  to: Date;
};
type Props = {
  value: DateRange;
  min: Date | string;
  max: Date | string;
  onChange: (value: DateRange) => void;
};

const clampDate = (value: Date, min: Date, max: Date) => {
  const valueMoment = dayjs(value);
  return valueMoment.isAfter(max) ? max : valueMoment.isBefore(min) ? min : value;
};

const forceDate = (value: string | Date): Date => {
  return typeof value === 'string' ? new Date(value) : value;
};

const DatesPicker = (props: Props) => {
  const {
    value: { from, to },
    min,
    max,
    onChange,
  } = props;

  const [showDateFrom, setShowDateFrom] = useState(new Date());
  const [showDateTo, setShowDateTo] = useState(new Date());

  const minDate = useMemo(() => forceDate(min), [min]);
  const maxDate = useMemo(() => forceDate(max), [max]);

  const fromDate = useMemo(() => forceDate(from), [from]);
  const toDate = useMemo(() => forceDate(to), [to]);

  const clampFrom = useCallback(
    (fromParam: Date) => {
      return clampDate(fromParam, minDate, toDate);
    },
    [toDate, minDate],
  );

  const clampTo = useCallback(
    (toParam: Date) => {
      return clampDate(toParam, fromDate, maxDate);
    },
    [fromDate, maxDate],
  );

  const handleStartChange = useCallback(
    (value: DateValue) => {
      onChange({
        from: clampFrom(dayjs(value).startOf('day').toDate()),
        to: toDate,
      });
    },
    [fromDate, toDate],
  );

  const handleEndChange = useCallback(
    (value: DateValue) => {
      onChange({
        from: fromDate,
        to: clampTo(dayjs(value).startOf('day').toDate()),
      });
    },
    [fromDate, toDate],
  );

  const valueFrom = useMemo(() => {
    const result = dayjs(fromDate).isBefore(minDate) ? minDate : fromDate;
    return dayjs(result).isAfter(maxDate) ? maxDate : result;
  }, [fromDate, minDate, maxDate]);

  const valueTo = useMemo(() => {
    const result = dayjs(toDate).isAfter(maxDate) ? maxDate : toDate;
    return dayjs(result).isBefore(minDate) ? minDate : result;
  }, [toDate, minDate, maxDate]);

  const fromMax = useMemo(() => {
    const result = dayjs(toDate).subtract(1, 'day').toDate();
    return dayjs(result).isBefore(minDate) ? minDate : result;
  }, [toDate, minDate]);

  const toMin = useMemo(() => {
    const result = dayjs(fromDate).add(1, 'day').toDate();
    return dayjs(result).isAfter(maxDate) ? maxDate : result;
  }, [fromDate, maxDate]);

  useEffect(() => {
    setShowDateFrom(dayjs(fromDate).isAfter(fromMax) ? fromMax : fromDate);
  }, [fromDate, fromMax]);

  useEffect(() => {
    setShowDateTo(dayjs(toDate).isBefore(toMin) ? toMin : toDate);
  }, [toDate, toMin]);

  const language = useLanguage();

  return (
    <Flex justify="center" gap={10}>
      <DatePicker
        size="xs"
        value={valueFrom}
        date={showDateFrom}
        minDate={minDate}
        maxDate={fromMax}
        onDateChange={setShowDateFrom}
        onChange={handleStartChange}
        locale={language || 'en'}
      />
      <DatePicker
        size="xs"
        value={valueTo}
        date={showDateTo}
        minDate={toMin}
        maxDate={maxDate}
        onDateChange={setShowDateTo}
        onChange={handleEndChange}
        locale={language || 'en'}
      />
    </Flex>
  );
};

export default DatesPicker;
