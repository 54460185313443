import StringEditor from 'Components/Filters/Common/StringEditor';
import { stringLabelFunc } from 'Components/Filters/Common/labelFunc';
import { FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import { t } from 'Utilities/i18n/index';
import Validation from 'Utilities/validation';
import Icon from 'icons/highest-rankings.svg?inline';
import NoFilterIcon from 'icons/no-filter.svg?inline';

const defaultValue = {
  attribute: FilterAttribute.COMPETITOR_DOMAIN,
  type: FilterValueType.STRING,
  comparison: FilterComparison.EQ,
  value: '',
};

const comparisonOptions = [FilterComparison.EQ];

const getData = () => ({
  defaultValue,
  title: t('Dynamic Competitor Domain'),
  icon: Icon,
  editor: StringEditor,
  editorProps: {
    placeholder: t('Enter Domain'),
    comparisonOptions,
    validate: [Validation.required, Validation.isUrl],
  },
  tableEditor: StringEditor,
  tableEditorProps: {
    placeholder: t('Dynamic Competitor Domain'),
    comparisonOptions,
    showComparisonOptions: false,
    noFilterIcon: NoFilterIcon,
  },
  labelFunc: stringLabelFunc,
});

export default getData;
