import {
  GenericGetUserDocument,
  GenericSubscribeDocument,
  Generic_UpdateUserSettingsDocument,
} from 'Ghql';

export default {
  subscriptions: {
    initSubscription: GenericSubscribeDocument,
  },
  queries: {
    getUser: GenericGetUserDocument,
  },
  mutations: {
    updateUserSettings: Generic_UpdateUserSettingsDocument,
  },
};
