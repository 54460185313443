import React, { MouseEvent } from 'react';
import { ActionIcon, Flex, Group, Skeleton } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import AccButton from 'Components/AccButton/AccButton';
import { AccFastIcon } from 'Components/AccFastIcon';
import AccTag from 'Components/AccTag';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import AccText from 'Components/Text/AccText';
import { getDisabledDemoText } from 'Constants/messages';
import { useDynamicTagRulesetFromTagLazyQuery, useStaticTagInformationLazyQuery } from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import { useDomainId, useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import useUserPermission from 'Hooks/useUserPermission';
import { SitemapNode } from 'Pages/SiteMapping/support/types';
import { t, tn } from 'Utilities/i18n';
import PlusIcon from 'icons/button-plus.svg?inline';
import { IconEdit } from 'icons/tag-cloud';

const MAX_TAGS_TO_SHOW = 25;
export interface TagsSectionProps {
  showAddTagModal: () => void;
  loading: boolean;
  selectedNode: SitemapNode | null;
  includingChildren: boolean;
}

const AccTagWithHover = ({ tag, dynamic, key, tooltipText }) => {
  const { hovered, ref } = useHover();
  const [getDynamicTagData] = useDynamicTagRulesetFromTagLazyQuery();
  const { showModal } = useModal();
  const domainId = useDomainId();
  const [getStaticTagData] = useStaticTagInformationLazyQuery();
  const handleEditDynamicTag = async (e) => {
    e.stopPropagation();
    const { data } = await getDynamicTagData({
      variables: { domainId: parseInt(domainId as string), tag },
      fetchPolicy: 'network-only',
    });
    const dynamicTagData = data?.dynamicTagRulesetFromTag;
    showModal({
      modalType: 'CreateOrEditDynamicTag',
      modalTheme: 'light',
      modalProps: {
        isEdit: true,
        initialValues: {
          id: dynamicTagData?.id,
          folder: dynamicTagData?.folderPath,
          tag: dynamicTagData?.tag,
          rawRuleset: dynamicTagData?.rawRuleset,
        },
        updatedAt: dynamicTagData?.updatedAt,
        createdBy: dynamicTagData?.createdBy,
      },
    });
  };

  const handleEditStaticTag = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const { data } = await getStaticTagData({
      variables: { domainId: parseInt(domainId as string), tag },
      fetchPolicy: 'network-only',
    });
    const staticTagData = data?.staticTagInformation;
    showModal({
      modalType: 'CreateOrEditStaticTag',
      modalTheme: 'light',
      modalProps: {
        initialValues: {
          tag: staticTagData?.tag,
          folder: staticTagData?.folderPath,
          keywords: staticTagData?.keywords,
        },
      },
    });
  };

  return (
    <Flex ref={ref}>
      <AccTag tag={tag} dynamic={dynamic} key={key} tooltipText={tooltipText} />
      {hovered ? (
        <AccTooltip tooltip={dynamic ? t('Edit dynamic tag') : t('Edit static tag')}>
          <ActionIcon
            variant="transparent"
            onClick={dynamic ? handleEditDynamicTag : handleEditStaticTag}
            size={16}
          >
            <AccFastIcon src={IconEdit} size={16} color={'gray'} />
          </ActionIcon>
        </AccTooltip>
      ) : null}
    </Flex>
  );
};

export const TagsSection = ({
  loading,
  selectedNode,
  includingChildren,
  showAddTagModal,
}: TagsSectionProps) => {
  const { userHasWriteAccess } = useUserPermission();
  const tags = includingChildren ? selectedNode?.tagsIC : selectedNode?.tagsEC;

  if (loading) {
    return (
      <Group align={'flex-start'} style={{ gap: 5, lineHeight: 1, maxWidth: 600 }}>
        <Skeleton height={18} width={40} />
        <Skeleton height={18} width={60} />
        <Skeleton height={18} width={20} />
        <Skeleton height={18} width={90} />
      </Group>
    );
  }
  if (includingChildren && !selectedNode?.children) {
    return <></>;
  }

  return (
    <Group align={'center'} style={{ columnGap: 6, rowGap: 8, lineHeight: 1, maxWidth: 600 }}>
      {!tags?.length && !userHasWriteAccess && (
        <AccText c="gray.5" size="sm">
          {t('No tags have been created for this page yet.')}
        </AccText>
      )}
      {tags?.slice(0, MAX_TAGS_TO_SHOW - 1).map((tag) => {
        const TagComponent = userHasWriteAccess ? AccTagWithHover : AccTag;
        return (
          <TagComponent
            tag={tag[1]}
            dynamic={!!tag[2]}
            key={`${selectedNode?.pathSegment}_${tag[0]}_${tag[2]}_${tag[1]}`}
            tooltipText={tn(
              '%s keyword on the selected landing page has this %s tag',
              '%s keywords on the selected landing page have this %s tag',
              tag[0],
              tag[2] ? t('dynamic ') : '',
            )}
          />
        );
      })}
      {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
      <AddTagButton onClick={showAddTagModal} visible={userHasWriteAccess} />
    </Group>
  );
};

const AddTagButton = ({ onClick, visible }: { onClick: () => void; visible }) => {
  const { isDemoDomain } = useQueryDomainInfo();
  if (!visible) return null;
  return (
    <AccButton
      variant="secondary"
      leftSection={<PlusIcon />}
      onClick={onClick}
      ml="sm"
      disabled={isDemoDomain}
      tooltip={
        isDemoDomain
          ? getDisabledDemoText()
          : t('Create a dynamic tagging rule for this landing page')
      }
    >
      {t('Add dynamic tag')}
    </AccButton>
  );
};
