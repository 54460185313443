import { MultiAccountPendingRequestsQuery } from 'Ghql';

export const getMultiAccountRequestDetails = (
  data: any,
  id: string,
): {
  isOrgAdmin: boolean | null;
  name: string | null;
} => {
  const item = data?.user?.organization?.pendingMultiAccountOwners?.find((e) => e.id === id) || {};
  return {
    isOrgAdmin: item?.isOrgAdmin ?? null,
    name: item?.fromOrganization?.name ?? null,
  };
};
export const combineMutationError = (
  ...results: ({
    errors?: string;
  } & any)[]
) => {
  return results?.find((e) => e?.errors)?.errors ?? undefined;
};

export const getDeletedPendingRequests = (data: MultiAccountPendingRequestsQuery | undefined) => {
  const deletedPendingRequests =
    data?.user?.organization?.deletedMultiAccountOwners?.map((mal) => mal.id) ?? [];
  return deletedPendingRequests;
};

export const getExistingMultiAccountLinks = (
  data: MultiAccountPendingRequestsQuery | undefined,
) => {
  const existingMultiAccountLinks =
    data?.user?.organization?.existingMultiAccountOwners?.map((mal) => mal.id) ?? [];
  return existingMultiAccountLinks;
};

export const getExistingMultiAccountNameById = (
  data: MultiAccountPendingRequestsQuery | undefined,
): Record<string, string> => {
  const entries =
    data?.user?.organization?.existingMultiAccountOwners?.map((mal) => [
      mal.id,
      mal.fromOrganization?.name,
    ]) ?? [];
  return Object.fromEntries(entries);
};
