import AccTooltip from 'Components/AccTooltip/AccTooltip';
import Cell from './HelperComponents/AccuCell';

type CountryLocale = {
  id: number;
  countryCode: string;
  locale: string;
  region: string;
};
type Props = {
  value: {
    data: {
      countrylocaleId: number;
      location: string;
    };
    loadingData: boolean;
  };
  countryLocales: CountryLocale[];
  compact?: boolean;
};

const LanguageCell = (props: Props) => {
  const {
    value: { data, loadingData },
    countryLocales,
  } = props;
  // if (loadingData) return <LoadingSpinner />;
  if (loadingData) return '';
  const { countrylocaleId } = data;
  let countryLocale;

  if (countrylocaleId) {
    countryLocale = countryLocales.find((e) => e.id === countrylocaleId);
  }

  return (
    <AccTooltip tooltip={countryLocale && countryLocale.locale}>
      <Cell className="accu-table-cell_lang">{countryLocale && countryLocale.locale}</Cell>
    </AccTooltip>
  );
};
LanguageCell.defaultProps = {
  countryLocales: [],
};
export default LanguageCell;
