import * as React from 'react';
import { Input } from '@mantine/core';
import { formatKeys } from 'Components/Modal/Content/Shortcuts';
import { useAutoFocus } from 'Hooks/useAutoFocus';
import { t } from 'Utilities/i18n/index';
import SearchIcon from 'icons/search.svg?inline';
import './search-input.scss';

type Props = {
  value?: string;
  onChange: (...args: Array<any>) => any;
  autoFocus?: boolean;
  dark?: boolean;
  placeholder?: string;
  keyboardShortcut?: string;
  disabled?: boolean;
  onKeyDown?: (...args: Array<any>) => any;
};

const SearchInput = React.forwardRef((props: Props, ref: React.ForwardedRef<HTMLInputElement>) => {
  const { dark, autoFocus, value, onChange, onKeyDown, placeholder, keyboardShortcut, disabled } =
    props;
  useAutoFocus(ref, autoFocus, 10);
  let rightSectionProps = {};
  if (keyboardShortcut) {
    rightSectionProps = {
      rightSection: <div style={{ whiteSpace: 'nowrap' }}>{formatKeys(keyboardShortcut)}</div>,
      rightSectionWidth: 85,
    };
  }
  return (
    <div className={`search-input ${dark ? 'dark' : ''}`}>
      <Input
        ref={ref}
        value={value}
        disabled={disabled}
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder={placeholder || t('Search')}
        type="search"
        {...rightSectionProps}
      />
      <SearchIcon />
    </div>
  );
});

SearchInput.displayName = 'SearchInput';

SearchInput.defaultProps = {
  dark: false,
  autoFocus: false,
  onKeyDown: () => {},
};

export default SearchInput;
