const initialState = {
  serpFeatures: [],
  countrylocales: [],
  searchEngines: [],
  searchTypes: [],
};
export default function (state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
