import { Box } from '@mantine/core';
import { Field, Fields } from 'redux-form';
import FilterCountField from 'Components/Filters/FilterCountField';
import { LocaleDropdownField } from 'Components/Forms/Fields';
import { t } from 'Utilities/i18n/index';
import Validator from 'Utilities/validation';
import FieldLoader from '../FieldLoader';
import { useCountryLocalesData } from './hooks';

const parse = (value: string) => parseInt(value, 10);
const format = (value: any) => value && value.toString();

const CountryLocaleEditor = () => {
  const { data, loading, error } = useCountryLocalesData();

  if (loading || error) return <FieldLoader />;

  return (
    <Box pos="relative">
      <Fields
        names={['comparison', 'value', 'type', 'attribute']}
        component={FilterCountField}
        pos="absolute"
        right="28px"
        top="11px"
      />
      <Field
        autoFocus
        name="value"
        component={LocaleDropdownField}
        format={format}
        parse={parse}
        placeholder={t('Select locale')}
        validate={[Validator.required, Validator.numeric]}
        locales={data}
      />
    </Box>
  );
};

export default CountryLocaleEditor;
