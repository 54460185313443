import TableEmptyState from 'Components/TableEmptyState';
import { useModal } from 'Hooks/base/useModal';
import useUserPermission from 'Hooks/useUserPermission';
import { t } from 'Utilities/i18n';
import { getDisabledDemoText } from '../../../../../Constants/messages';
import { useQueryDomainInfo } from '../../../../../Hooks/data/domain/useQueryDomainInfo';

export const GroupingsEmptyContent = ({
  activeSearch,
  resetSearch,
}: {
  activeSearch?: string;
  resetSearch?: () => void;
}) => {
  const { isDemoDomain } = useQueryDomainInfo();
  const { userHasWriteAccess } = useUserPermission();
  const { showModal } = useModal();
  const showButton = userHasWriteAccess || isDemoDomain;
  const onCreateTagClick = () =>
    showModal({
      modalType: 'SelectTagType',
      modalTheme: 'light',
    });
  return (
    <TableEmptyState
      list={[]}
      title={t('No tags or folders found')}
      subTitle={
        activeSearch
          ? t('We found no tags or folders to show for the specified search.')
          : t('We found no tags or folders to show for this domain.')
      }
      filtersPossible={true}
      noDataWithFiltersTitle={t('No tags or folders found')}
      noDataWithFiltersSubTitle={t(
        'We found no tags or folders to show, this could be due to your active filters. Clear your filters and try again.',
      )}
      onBtnClick={activeSearch ? resetSearch : showButton ? onCreateTagClick : null}
      btnTitle={activeSearch ? t('Clear Search') : t('Create tag')}
      fullWidth
      btnDisable={!activeSearch && isDemoDomain}
      btnTooltip={!activeSearch && isDemoDomain && getDisabledDemoText()}
    />
  );
};
