import React from 'react';
import { Placement } from '@floating-ui/dom';
import { AccComplexTooltip } from './AccComplexTooltip';
import { AccTooltipSimple } from './AccTooltipSimple';
import './style.scss';

export type AccTooltipProps = {
  children: React.ReactNode;
  disable?: boolean;
  tooltip?: string | React.ReactNode;
  old?: boolean;
  // Complex tooltip
  placement?: Placement;
  transparent?: boolean;
  /** Tooltip delay in ms - applied to the AccTooltipSimple component. */
  delay?: number;
};

/**
 *
 * Two versions of tooltip:
 * - light - using react-tooltip
 * Tooltip component, calculate position automatically.
 * @example https://popper.js.org/react-popper/v2/
 */
function AccTooltip({
  children,
  tooltip,
  disable,
  old,
  delay,
  ...props
}: AccTooltipProps): JSX.Element {
  if (!tooltip) {
    return <>{children}</>;
  }

  if (!old) {
    return (
      <AccTooltipSimple tooltip={tooltip as string} disable={disable} delay={delay}>
        {children}
      </AccTooltipSimple>
    );
  }

  return (
    <AccComplexTooltip tooltip={tooltip} disable={disable} {...(props as any)}>
      {children}
    </AccComplexTooltip>
  );
}

AccTooltip.defaultProps = {
  old: false,
};

export default AccTooltip;
