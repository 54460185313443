import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Flex } from '@mantine/core';
import moment from 'moment';
import AccButton from 'Components/AccButton/AccButton';
import { Form } from 'Components/Fields';
import FormatNumber from 'Components/FormatNumber';
import AccTitle from 'Components/Title/AccTitle';
import { useCancelPlanMutation } from 'Ghql';
import toast from 'Hooks/useToast';
import { StoreType } from 'Types/Store';
import { useUpdateStoredUserSettings } from 'Utilities/Graphql/hooks/useUpdateStoredUserSettings';
import { t, tct } from 'Utilities/i18n/index';
import FormSkeleton from './FormSkeleton';
import './cancel-plan.scss';

const DAYS_BEFORE_CANCELATION = 14;

const CancelPlanForm = () => {
  const resetUserSettings = useUpdateStoredUserSettings();
  const { activePlan, user } = useSelector((store: StoreType) => ({
    activePlan: store.user.organization?.activePlan,
    user: store.user,
  }));
  const [success, setSuccess] = useState<boolean>(false);
  const [cancelPlan, { loading, error }] = useCancelPlanMutation();
  const params = new URLSearchParams(window.location?.search);
  const { endDate } = activePlan || {};
  const daysDiff = Math.abs(moment().diff(endDate, 'days'));
  const ignoreMinCancelationPeriod = params?.get('aWdub3') === 'true' || false;

  const onSubmit = () => {
    const cancelPlanInput = {
      ignoreMinCancelationPeriod,
    };

    return cancelPlan({
      variables: {
        cancelPlanInput,
      },
    })
      .then(({ data }) => {
        const errors = data?.cancelPlan?.errors;
        if (errors && errors.length && errors[0]) {
          toast.error(errors[0].messages[0]);
          return;
        }
        setSuccess(true);
        resetUserSettings();
      })
      .catch((err) => {
        console.error('error', err);
        toast.error(t('Unable to cancel plan'));
      });
  };

  if (loading || error) return <FormSkeleton />;

  // It is unlikely that activePlan is not defined.
  if (!activePlan) return null;

  if (success) {
    return (
      <Flex className="cancel-plan-page content-row" direction="column">
        <AccTitle type="h3" mb="xs">
          {t('Your subscription has been canceled')}
        </AccTitle>
        <p>
          {t(
            'You can access your AccuRanker account until %s',
            daysDiff >= DAYS_BEFORE_CANCELATION || ignoreMinCancelationPeriod
              ? activePlan.endDate
              : activePlan.endDateIfCancelledNow,
          )}
        </p>

        <p>
          {t(
            'If you at one point would like to come back to AccuRanker, we will be storing your data, so you won’t lose your valuable historical data.',
          )}
        </p>
        <p>{t('Sincerely, The AccuRanker Team')}</p>
        <AccButton variant="secondary" link="/account/billing" mt="sm">
          {t('Go to billing')}
        </AccButton>
      </Flex>
    );
  }

  return (
    <Flex className="cancel-plan-page content-row">
      <Form onSubmit={onSubmit}>
        {({ invalid, submitting }) => {
          return (
            <>
              <AccTitle type="h3" mb="xs">
                {t('Cancellation of your AccuRanker subscription')}
              </AccTitle>
              <p>
                {t('Hi %s', user.fullName)}
                <br />
                {t('You are about to cancel your subscription.')}
              </p>

              <p>
                <strong>{t('Details')}</strong>
                <br />
                {t(
                  'Plan: %s with %s keywords',
                  activePlan.isFree ? activePlan.name : activePlan.category,
                  activePlan.maxKeywords,
                )}
                <br />

                {tct('Price: [price] [period]', {
                  price: (
                    <FormatNumber currency={activePlan.currency || undefined}>
                      {activePlan.billingCycleInMonths === 1
                        ? activePlan.priceMonthly
                        : activePlan.priceYearly}
                    </FormatNumber>
                  ),
                  period:
                    activePlan.billingCycleInMonths === 1 ? t('paid monthly') : t('paid yearly'),
                })}
                <br />

                {t(
                  'Your subscription will be accessible until: %s',
                  ignoreMinCancelationPeriod
                    ? activePlan.endDate
                    : activePlan.endDateIfCancelledNow,
                )}
              </p>

              <Flex justify="end" className="confirmation-button-wrapper text-right" gap="sm">
                <AccButton variant="tertiary" link="/account/billing">
                  {t('Go to billing')}
                </AccButton>
                <AccButton disabled={invalid || submitting} type="submit" variant="destructive">
                  {t('Cancel subscription')}
                </AccButton>
              </Flex>
            </>
          );
        }}
      </Form>
    </Flex>
  );
};

export default CancelPlanForm;
