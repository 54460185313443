import { useMemo } from 'react';
import { FieldInputProps } from 'react-final-form';
import { AccSelect } from 'Components/AccSelect';
import {
  FormComponentProps,
  withFieldForm,
} from 'Components/Fields/hooks/WithFieldForm/WithFieldForm';
import { SelectOptionType } from '../../../support/types';

interface FieldSelectProps {
  name: string;
  options: SelectOptionType[];
  placeholder?: string;
  searchable?: boolean;
  customChange?: ({ prev, next }: { prev?: string; next?: string }) => void;
  useFirstOptionAsDefault?: boolean;
}

export const FieldSelect = withFieldForm((props: FieldSelectProps & FieldInputProps<string>) => {
  const value = props.options?.find((e) => e?.value === props.value)?.value;
  const defaultProps = useMemo(
    () => ({
      searchable: true,
      clearable: false,
      groupHidden: true,
    }),
    [],
  );
  return (
    <>
      <AccSelect
        {...defaultProps}
        {...props}
        size="default"
        bg="gray"
        value={value?.toString()}
        onChange={(val) => {
          props.onChange(val);
          props.customChange?.({ prev: props.value, next: val?.toString() });
        }}
      />
    </>
  );
}) as React.FC<React.PropsWithChildren<FieldSelectProps & FormComponentProps>>;
