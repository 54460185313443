import { useCallback, useEffect, useMemo, useState } from 'react';

const recursiveSearch = (data: any, search: string, searchKey: string) => {
  if (search === '') return data;
  return data?.reduce((acc, item) => {
    const isMatch = item[searchKey]?.toLowerCase().includes(search.toLowerCase().trim());
    if (isMatch) {
      acc.push(item);
    } else if (item.children?.length) {
      const children = recursiveSearch(item.children, search, searchKey);
      if (children?.length) {
        acc.push({ ...item, children });
      }
    }
    return acc;
  }, []);
};

export const useClientSideSearch = <T>(
  rootNodes: T[],
  searchKey: string,
  initData: T[],
): {
  nestedData: T[];
  submitSearch: (query?: string) => void;
  activeSearch?: string;
} => {
  const [nestedData, setNestedData] = useState<T[]>(rootNodes);
  const [searchPhrase, setSearchPhrase] = useState<string | undefined>(undefined);

  const resultData = useMemo(() => {
    return searchPhrase ? recursiveSearch(nestedData, searchPhrase, searchKey) : nestedData;
  }, [nestedData, searchPhrase, searchKey]);

  useEffect(() => {
    setNestedData(rootNodes);
  }, [rootNodes]);

  const submitSearch = useCallback(
    (newSearchPhrase?: string) => {
      if (rootNodes) {
        setSearchPhrase(newSearchPhrase);
        setNestedData(newSearchPhrase ? rootNodes : initData);
      }
    },
    [rootNodes, initData],
  );

  return { nestedData: resultData, submitSearch, activeSearch: searchPhrase };
};
