import { Box } from '@mantine/core';
import { IconFilterFilled } from '@tabler/icons-react';
import getFilterData from 'Components/Filters/getFilterData';
import Icon from 'Components/Icon/new';

const FilterTabsItem = (props) => {
  const getTabName = (filterAttribute: string | undefined) => {
    const { title } = getFilterData(filterAttribute) || {};
    return title;
  };

  let isActive = false;

  if (props.filterAttribute && props.currentActiveFilters) {
    isActive =
      props.currentActiveFilters.filter((f) => f.attribute === props.filterAttribute).length > 0;
  }

  return (
    <div
      onClick={() => props.onClick(props.index + 1)}
      className={`tabs-filter__item ${
        props.currentTab === props.index + 1 ? 'tabs-filter__item--selected' : 'tabs-filter__item'
      }`}
    >
      <span>
        {getTabName(props.filterAttribute)}
        {isActive ? (
          <Box ml="xs" className={'active-filter'}>
            <Icon width={16} height={16}>
              <IconFilterFilled />
            </Icon>
          </Box>
        ) : null}
      </span>
    </div>
  );
};

export default FilterTabsItem;
