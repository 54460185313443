import React from 'react';
import { connect } from 'react-redux';
import clone from 'lodash/clone';
import omit from 'lodash/omit';
import { deleteFilters } from 'Actions/FilterAction';
import { setFilteredPath } from 'Components/Filters/helpers';
import type { FilterBase, FilterGroup } from 'Types/Filter';
import { FilterAttribute } from 'Types/Filter';
import type { FilterSet } from 'Types/FilterSet';
import { isRequiredFilter } from 'Types/FilterSet';
import { WithRouter, withRouter } from 'Utilities/Router';

type Props = {
  filterGroup: FilterGroup;
  filterSet: FilterSet;
  children: React.ReactElement;
  skipPropsInject?: string;
};

export interface FiltersEditor {
  addFilter: (newFilter: FilterBase) => void;
  resetFilters: () => void;
  removeFilter: (attribute: string, filter?: FilterBase) => void;
  updateFilters: (
    _newFilters: FilterBase | FilterBase[],
    _oldFilters: FilterBase | FilterBase[],
  ) => void;
  selectFilterGroup: (filterGroup: FilterGroup) => void;
} // TODO - do we need to prevent re-render even if filters changed?

const zip = (a, b) => a.map((k, i) => [k, b[i]]);

const formatFilters = (filters: FilterBase[]) => {
  const result = filters.map((e) => omit(e, 'additionFilter'));
  const additionFilters = filters.map((e: any) => e?.additionFilter);
  return [...(result || []), ...(additionFilters || [])].filter(Boolean);
};

export const filtersYouCannotHaveMultipleOf: string[] = [
  FilterAttribute.DOMAINS,
  FilterAttribute.CLIENTS,
  FilterAttribute.FREE_TEXT_DOMAIN,
  FilterAttribute.ABOVE_THE_FOLD,
  FilterAttribute.SEARCH_TYPE,
  FilterAttribute.HIGHEST_RANKING_PAGE_MATCH,
  FilterAttribute.TOP_COMPETITOR,
];

class FiltersEditorComponent extends React.Component<Props & WithRouter> {
  currentRequiredFilters() {
    const filterSet = this.props.filterSet;
    const filterGroup = this.props.filterGroup;
    // Clear non required filters only in current filter set
    return filterGroup.filters.filter((filter) => isRequiredFilter(filter.attribute, filterSet));
  }

  addFilter(nextFilter: FilterBase) {
    // TODO FixTSignore
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore

    const { additionFilter, ...newFilter } = nextFilter;
    const filterGroup = this.props.filterGroup;

    const alreadyExists = filterGroup.filters.find(
      (filter) =>
        filtersYouCannotHaveMultipleOf.includes(filter.attribute as string) &&
        filter.attribute === newFilter.attribute,
    );
    let newFilters;

    if (alreadyExists) {
      newFilters = filterGroup.filters.map((filter) =>
        (filtersYouCannotHaveMultipleOf.includes(filter.attribute as string) &&
        newFilter.attribute === filter.attribute
          ? newFilter
          : filter),
      );
    } else {
      newFilters = [...filterGroup.filters, newFilter];
    }

    if (additionFilter) {
      newFilters = [...newFilters, additionFilter];
    }

    this.navigate(newFilters.filter(Boolean), filterGroup.id);
  }

  resetFilters() {
    // Clear non required filters only in current filter set
    const newFilters = this.currentRequiredFilters();
    this.navigate(newFilters, '');
  }

  /**
   * Removes a filter or all filters. If given only attribute, it will remove all filters with this attribute
   * If given a specific filter, it will try to find this filter in the current filters and delete only that specific filter
   */
  removeFilter(attribute: string, filter?: FilterBase) {
    const filterGroup = this.props.filterGroup;
    let newFilters: FilterBase[];
    if (filter) {
      newFilters = filterGroup.filters.filter(
        (currentFilter) => JSON.stringify(filter) !== JSON.stringify(currentFilter),
      );
    } else {
      newFilters = filterGroup.filters.filter((f) => attribute !== f?.attribute);
    }
    // TODO FixTSignore
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.props.deleteFilters?.([attribute]);
    this.navigate(newFilters, filterGroup.id);
  }

  updateFilters(_newFilters: FilterBase | FilterBase[], _oldFilters: FilterBase | FilterBase[]) {
    // Takes a list of new filters and a list of corresponding old filters, such that these two arrays match by index.
    // Then we do the corresponding updates in the filters array stored on the filter group and switch the URL.
    const filterGroup = this.props.filterGroup;

    const oldFilters = formatFilters(Array.isArray(_oldFilters) ? _oldFilters : [_oldFilters]);
    const newFilters = formatFilters(Array.isArray(_newFilters) ? _newFilters : [_newFilters]);
    let updatedFilters = clone(filterGroup.filters);

    for (const [oldFilter, newFilter] of zip(oldFilters, newFilters)) {
      updatedFilters = updatedFilters.map((currentFilter) => {
        // Unfortunately filters have no unique id to identify them, so we identify by force
        if (JSON.stringify(currentFilter) === JSON.stringify(oldFilter)) {
          return newFilter;
        }
        return currentFilter;
      });
    }

    this.navigate(updatedFilters, filterGroup.id);
  }

  selectFilterGroup(filterGroup: FilterGroup) {
    const requiredFilters = this.currentRequiredFilters();
    const newFilters = [...requiredFilters, ...filterGroup.filters];
    this.navigate(newFilters, filterGroup.id);
  }

  navigate(newFilters: FilterBase[], filterGroupId: string) {
    const { match, history } = this.props;
    return setFilteredPath(newFilters, filterGroupId, match, history);
  }

  render() {
    return React.cloneElement(
      this.props.children,
      this.props.skipPropsInject
        ? {}
        : {
            filtersEditor: this,
            filterGroup: this.props.filterGroup,
            filterSet: this.props.filterSet,
          },
    );
  }
}

const mapStateToProps = (state) => ({
  filterGroup: state.filter.filterGroup,
  filterSet: state.filter.filterSet,
});

export default withRouter(
  connect(mapStateToProps, {
    deleteFilters,
  })(FiltersEditorComponent as any),
);
