import { memo } from 'react';
import { useFolderOrTagFilter } from 'Components/Filters/LinkWithFolders/linkWithFolders';
import LinkToKeywordListCell from 'Pages/Keywords/Groupings/ViewMode/components/cells/LinkToKeywordListCell';
import { ChangeFilter, FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import { propsIsEqualComparison } from 'Utilities/compare';
import { t } from 'Utilities/i18n';
import { FolderCellProps } from '../../utils/useFoldersTableConfig';

const WinnersOrLosersCell = ({
  props,
  cellType,
}: {
  props: FolderCellProps;
  cellType: 'winners' | 'losers';
}) => {
  const foldersOrTagFilter = useFolderOrTagFilter({
    isFolder: props.rootNode.is_folder,
    folderOrTag: props.rootNode.is_folder ? props.rootNode.path : props.rootNode.name,
  });

  const winnerOrLosersFilter: ChangeFilter = {
    attribute: FilterAttribute.RANK_CHANGE,
    type: FilterValueType.NUMBER,
    comparison: cellType === 'winners' ? FilterComparison.LT : FilterComparison.GT,
    value: 0,
  };

  const value = cellType === 'winners' ? props.rootNode.winners : props.rootNode.losers;

  return (
    <LinkToKeywordListCell
      props={props}
      filters={[foldersOrTagFilter, winnerOrLosersFilter]}
      value={value}
      tooltip={
        cellType === 'winners'
          ? t('Go to keywords list and filter by winners')
          : t('Go to keywords list and filter by losers')
      }
    />
  );
};

export default memo(WinnersOrLosersCell, propsIsEqualComparison);
