import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Portal } from '@mantine/core';

/**
 * Component that should be included in render of the page to apply tooltips *(react-tooltip)
 * In case we add new tooltips, we should call ReactTooltip.rebuild
 */
export const GlobalTooltipEngine = () => {
  return (
    <Portal>
      <ReactTooltip
        place="top"
        variant="dark"
        anchorSelect="[data-tooltip-content],[data-tooltip-html]"
        noArrow={true}
        className="acr-tooltip"
        disableStyleInjection={true}
      />
    </Portal>
  );
};
