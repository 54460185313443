import React from 'react';
import { WithRowContext, useRowContext } from 'Components/DataTable';

export const withRowContext = <T extends WithRowContext = WithRowContext>(
  Component: React.ComponentType<React.PropsWithChildren<T>>,
) => {
  const ResultComponent = (props: T): any => {
    const rowContext = useRowContext();
    return (<Component {...props} rowContext={rowContext} />) as React.ReactNode;
  };

  const displayName = Component.displayName || Component.name || 'Component';
  ResultComponent.displayName = `withRowContext(${displayName})`;
  return ResultComponent;
};
