import { useQueryIsDemoClientQuery } from 'Ghql';

export const useQueryIsDemoClient = (domainListId: string[] | null, isDemoDomain?: boolean) => {
  const { data: firstDomain } = useQueryIsDemoClientQuery({
    variables: {
      input: domainListId?.[0] || '',
    },
    // skip if there are none or 1 domain, meaning we are at a domain page and not a client (group) page
    skip: !domainListId?.length || domainListId?.length === 1 || isDemoDomain,
    fetchPolicy: 'cache-first',
  });
  const isDemoClient = firstDomain?.domain?.client?.isDemoClient;
  const demoClientBannerInfo = firstDomain?.domain?.demoDomainBannerInfo || {};

  return { demoClientBannerInfo, isDemoClient };
};
