import type { ComponentType } from 'react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { adminRole, defaultRole, hasWriteAccess, superuserRole } from 'Constants/workspaces';
import { StoreUserType } from 'Types/Store';
import { graphqlOK, redirectToExternalUrl } from 'Utilities/underdash';
import { extractNextPath } from './private-routes';

export type PrivateRouterConfig = {
  isTrialExpiredOk?: boolean;
  isPlanExpiredOk?: boolean;
  isFailedPaymentOk?: boolean;
  isNoPermissionOk?: boolean;
  isRegisterPage?: boolean;
  forceUnauthenticated?: boolean;
  isAccuApiUserOK?: boolean;
  /** Restrict a private route to a specific user role - eg. `accuApi` */
  accessRestriction?: 'accuApi';
  orgAdminRestriction?: boolean;
  superuserRestriction?: boolean;
  writeOnlyRestriction?: boolean;
};

type PrivateRoutePublicProps = {
  component: ComponentType<React.PropsWithChildren<any>>;
  path: any;
};

type Props = {
  // should this page only be visible to logged out users
  user?: StoreUserType;
} & PrivateRoutePublicProps &
  PrivateRouterConfig;

const TRIAL_EXPIRED_PAGE = '/error/trial-expired';
const PLAN_EXPIRED_PAGE = '/error/plan-expired';
const FAILED_PAYMENT_PAGE = '/error/failed-payment';
const ACCOUNT_BLOCKED_PAGE = '/error/blocked';
const NO_PERMISSION = '/error/no-access';

class PrivateRoute extends Component<Props> {
  render() {
    if (!graphqlOK(this.props)) {
      return null;
    }

    const {
      path,
      isTrialExpiredOk,
      isPlanExpiredOk,
      isFailedPaymentOk,
      isNoPermissionOk,
      isRegisterPage,
      forceUnauthenticated,
      isAccuApiUserOK = false,
      accessRestriction,
      orgAdminRestriction,
      superuserRestriction,
      writeOnlyRestriction,
      component: WrappedComponent,
      user,
      ...rest
    } = this.props;

    const { isAuthenticated, organization, unconfirmedUserId, userType, workspaces } = user || {};
    const userHasWriteAccess = hasWriteAccess.includes(userType || defaultRole);
    const noPermission = !workspaces?.length;
    const isAdmin = userType === adminRole;
    const isSuperuser = userType === superuserRole;
    const hasAccuApiAccess = organization?.activePlan?.featureAccuApi || false;

    if (forceUnauthenticated && isAuthenticated && !isRegisterPage) {
      redirectToExternalUrl('/app');
      return null;
    }

    if (!isAuthenticated && !forceUnauthenticated && !isRegisterPage) {
      const redirectBackUrl = window.location.pathname?.replace('/app', '') || '/';
      const requireRedirect = redirectBackUrl && redirectBackUrl !== '/';
      return (
        <Navigate replace to={requireRedirect ? `/login?next=${redirectBackUrl}` : '/login'} />
      );
    }
    const { trialExpired, planExpired, failedPayment } =
      (organization && organization.errors) || {};
    const accountBlocked = organization?.accountBlocked || false;

    if (accountBlocked && path !== ACCOUNT_BLOCKED_PAGE) {
      return <Navigate replace to={ACCOUNT_BLOCKED_PAGE} />;
    }

    if (!isNoPermissionOk && noPermission && path !== NO_PERMISSION) {
      return <Navigate replace to={NO_PERMISSION} />;
    }

    if (!isTrialExpiredOk && trialExpired && path !== TRIAL_EXPIRED_PAGE) {
      return <Navigate replace to={TRIAL_EXPIRED_PAGE} />;
    }

    if (!isPlanExpiredOk && planExpired && path !== PLAN_EXPIRED_PAGE) {
      return <Navigate replace to={PLAN_EXPIRED_PAGE} />;
    }

    if (!isFailedPaymentOk && failedPayment && path !== FAILED_PAYMENT_PAGE) {
      return <Navigate replace to={FAILED_PAYMENT_PAGE} />;
    }

    if (
      (!trialExpired && path === TRIAL_EXPIRED_PAGE) ||
      (!planExpired && path === PLAN_EXPIRED_PAGE) ||
      (!failedPayment && path === FAILED_PAYMENT_PAGE) ||
      (!accountBlocked && path === ACCOUNT_BLOCKED_PAGE) ||
      (orgAdminRestriction && !isAdmin) ||
      (superuserRestriction && !(isSuperuser || isAdmin)) ||
      (writeOnlyRestriction && !userHasWriteAccess)
    ) {
      return <Navigate replace to="/" />;
    }

    if (
      !isRegisterPage &&
      !trialExpired &&
      organization?.wizardShouldBeShown &&
      unconfirmedUserId
    ) {
      return <Navigate replace to={`/register/${unconfirmedUserId}`} />;
    }

    if (
      (accessRestriction === 'accuApi' && !hasAccuApiAccess) ||
      (isAccuApiUserOK === false && hasAccuApiAccess)
    ) {
      return <Navigate replace to="/account" />;
    }

    const nextPath = extractNextPath();
    if (nextPath) {
      return <Navigate replace to={nextPath} />;
    }

    return <WrappedComponent {...rest} />;
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(PrivateRoute) as ComponentType<
  React.PropsWithChildren<PrivateRoutePublicProps & PrivateRouterConfig>
>;
