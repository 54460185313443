import { memo, useRef, useState } from 'react';
import { Box } from '@mantine/core';
import cn from 'classnames';
import { AccFastIcon } from 'Components/AccFastIcon';
import AccLazyPopover from 'Components/AccLazyPopover/AccLazyPopover';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { useRowContext } from 'Components/DataTable';
import Icon from 'Components/Icon/new';
import Skeleton from 'Components/Skeleton';
import UrlSerpPreview from 'Components/UrlSerpPreview';
import styles from 'Components/UrlSerpPreview/urlpreview.module.scss';
import { getDisabledDemoText } from 'Constants/messages';
import { useTitleDescriptionByIdLazyQuery } from 'Ghql';
import { t } from 'Utilities/i18n';
import { getUrlPath } from 'Utilities/parser';
import UrlIcon from 'icons/url.svg';
import Cell from '../HelperComponents/AccuCell';
import { calculateHighestRankingPageStatus, useGetContentAndTooltip } from './helpers';
import urlIconStyles from './urlCell.module.scss';

type Props = {
  value: {
    data: {
      id: number;
      domainId: number;
      title: string;
      description: string;
      highestRankingPage: string;
      preferredLandingPageId: number;
      highestRankingPageStatus: string;
      updatingKeyword: boolean;
      isSubRow?: boolean;
      path?: string;
      titleDescriptionId: number;
    };
    loadingData: boolean;
  };
  path?: string;
  hideStatus?: boolean;
  onClick: (...args: Array<any>) => any; // props: id,domainId,highestRankingPage,preferredLandingPageId
  isDemoDomain?: boolean;
};

const LazyDetails = ({
  id,
  highestRankingPage,
  data,
}: {
  id: number;
  highestRankingPage: string;
  data: { title?: string; description?: string; [key: string]: any };
}) => {
  const [fetchData, { loading, data: titleDescriptionData }] = useTitleDescriptionByIdLazyQuery({
    variables: {
      titleDescriptionId: id,
    },
  });

  const [popoverOpen, setPopoverOpen] = useState(false);

  const title = data?.title || titleDescriptionData?.titleDescription?.title;
  const description = data?.description || titleDescriptionData?.titleDescription?.description;

  return (
    <AccLazyPopover
      onToggle={() => {
        setPopoverOpen(!popoverOpen);
        fetchData();
      }}
      target={
        highestRankingPage && (
          <div className={cn('url-preview', urlIconStyles.urlIconContainer)}>
            <AccFastIcon
              src={UrlIcon}
              size={14}
              color={'var(--popover-color)'}
              tooltip={popoverOpen ? '' : t('Show title and description on the SERP')}
            />
          </div>
        )
      }
    >
      <div className={styles.urlPreview}>
        {loading ? (
          <Skeleton
            linesConfig={[
              {
                type: 'text',
                options: {
                  width: '100%',
                  height: '20px',
                },
              },
              {
                type: 'text',
                options: {
                  width: '100%',
                  height: '20px',
                },
              },
              {
                type: 'text',
                options: {
                  width: '100%',
                  height: '40px',
                },
              },
            ]}
          />
        ) : (
          <UrlSerpPreview title={title} url={highestRankingPage} text={description} />
        )}
      </div>
    </AccLazyPopover>
  );
};

const urlPropsAreEqual = (prevProps: Props, nextProps: Props) => {
  return (
    prevProps.value.data.id === nextProps.value.data.id &&
    prevProps.value.data.domainId === nextProps.value.data.domainId &&
    prevProps.value.data.preferredLandingPageId === nextProps.value.data.preferredLandingPageId &&
    prevProps.value.data.highestRankingPage === nextProps.value.data.highestRankingPage &&
    prevProps.value.data.path === nextProps.value.data.path
  );
};

const UrlWithStatusCell = (props: Props): JSX.Element | null => {
  const elementRef = useRef(null);
  const { inView, userHasWriteAccess } = useRowContext();
  const {
    value: { data, loadingData },
    onClick,
    isDemoDomain,
  } = props;

  const { id, domainId, preferredLandingPageId, highestRankingPage, path } = data;

  const status = calculateHighestRankingPageStatus(data);
  const { content, tooltip } = useGetContentAndTooltip(status, Boolean(userHasWriteAccess));
  if (!inView || loadingData) return null;
  const url = getUrlPath(highestRankingPage);
  const hideStatus = props.hideStatus || data?.isSubRow;
  const showRankIcon = !data?.isSubRow && url;
  const clickableStatus = !isDemoDomain && userHasWriteAccess;
  return (
    <Cell className="accu-table-cell__url">
      {(url || path) && (
        <div className="accu-table-cell__url__value">
          {showRankIcon && (
            <LazyDetails
              id={data?.titleDescriptionId}
              data={data}
              highestRankingPage={highestRankingPage}
            />
          )}
          <Box ml="xxs" className={cn('accu-table-cell__url__url-value u-rtl')}>
            {url && (
              <AccTooltip tooltip={highestRankingPage}>
                <a href={highestRankingPage} target="_blank" rel="noopener noreferrer">
                  <span ref={elementRef} lang="en" style={{ unicodeBidi: 'plaintext' }}>
                    {url}
                  </span>
                </a>
              </AccTooltip>
            )}
            {path && (
              <AccTooltip tooltip={path}>
                <span ref={elementRef} lang="en" style={{ unicodeBidi: 'plaintext' }}>
                  {path}
                </span>
              </AccTooltip>
            )}
          </Box>
        </div>
      )}

      {!hideStatus && (
        <div
          className={cn({
            'url-status': clickableStatus,
            'url-status--nonclickable': !clickableStatus,
          })}
          onClick={() =>
            clickableStatus && onClick(id, domainId, highestRankingPage, preferredLandingPageId)
          }
        >
          <Icon
            disabled={isDemoDomain}
            width={16}
            tooltip={isDemoDomain ? t('Preferred URL. %s.', getDisabledDemoText()) : tooltip}
          >
            {content}
          </Icon>
        </div>
      )}
    </Cell>
  );
};

export default memo(UrlWithStatusCell, urlPropsAreEqual);
