import { FormSpy, useField, useForm } from 'react-final-form';
import { Flex } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import { Field, Form } from 'Components/Fields';
import Hint from 'Components/Hint';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import { t, tct } from 'Utilities/i18n';
import { usePropertyOptionsMultiple } from './utils';
import styles from './property.module.scss';

type Props = {
  connectionId: string;
  accountIds: string[];
  onSubmit: (arg: { propertyId: string; accountId: string }) => void;
  onBack: (...args: Array<any>) => any;
};

const PropertyFields = ({
  connectionId,
  accountIds,
  onBack,
  values,
}: Pick<Props, 'connectionId' | 'accountIds' | 'onBack'> & {
  values: Record<string, string | boolean>;
}) => {
  const { input: showUAPropertiesInput } = useField('showUAProperties', {
    type: 'checkbox',
    initialValue: true,
  });

  const [propertyOptions, loading] = usePropertyOptionsMultiple(
    connectionId,
    accountIds,
    !!showUAPropertiesInput.checked,
  );

  /** Dictionary mapping property id's to accountIds */
  const propertyToAccountId = propertyOptions.reduce((acc, cur) => {
    const property_id = cur.value as string;
    acc[property_id] = cur.accountId;
    return acc;
  }, {});
  const form = useForm();

  return (
    <>
      <Flex direction="column" gap="xl">
        <Flex gap="sm" justify="space-between">
          <Field.Select
            name="property"
            options={propertyOptions}
            label={t('Property')}
            isLoading={loading}
            disabled={loading}
            className={styles.propertySelect}
          />
          <FormSpy
            subscription={{ values: true }}
            onChange={({ values: _values }) => {
              const newAccountId = propertyToAccountId[_values.property as string];
              //update accountId formField every time a new property is selected to get the correct accountId of the property
              form.change('accountId', newAccountId);
            }}
          />
          <Flex align="end" mb="xs" className={styles.toggleContainer}>
            <Field.SwitchInput
              name={showUAPropertiesInput.name}
              checked={showUAPropertiesInput.checked}
              onChange={showUAPropertiesInput.onChange}
              label={t('Hide UA Properties?')}
              onLabel={t('Yes')}
              offLabel={t('No')}
              defaultChecked={showUAPropertiesInput.checked}
              labelPosition="left"
            />
            <Hint
              help={tct(
                'By default, the switch is toggled on to hide Universal Analytics properties from available options, because we are in the process of migrating to Google Analytics 4.' +
                  '[br][br]This ensures that only relevant properties are displayed for you to choose from.' +
                  '[br][br]If you need to select a Universal Analytics property, simply toggle the switch to show all available options.',
                {
                  br: <br />,
                },
              )}
            />
          </Flex>
        </Flex>
      </Flex>
      <ModalFooter
        primaryButtonSlot={
          <AccButton
            variant="primary"
            type="submit"
            disabled={loading || !Object.keys(values).includes('property')}
          >
            {t('Select property')}
          </AccButton>
        }
        secondaryButtonSlot={
          <AccButton variant="tertiary" onClick={onBack} leftSection={<IconArrowLeft />}>
            {t('Account')}
          </AccButton>
        }
      />
    </>
  );
};

export const Property = ({ connectionId, accountIds, onSubmit, onBack }: Props) => {
  return (
    <Form
      onSubmit={(values) => {
        const propertyId = values.property;
        const accountId = values.accountId;
        onSubmit({
          propertyId,
          accountId,
        });
      }}
      subscription={{ values: true }}
      initialValues={{ property: '', showUAProperties: true, accountId: '' }}
    >
      {(formContext) => {
        const { values } = formContext;
        return (
          <PropertyFields
            connectionId={connectionId}
            accountIds={accountIds}
            onBack={onBack}
            values={values}
          />
        );
      }}
    </Form>
  );
};
