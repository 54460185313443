import { ColumnSetting } from 'Components/DataTable';
import { CompetitorRowNode } from 'Ghql';
import { t } from 'Utilities/i18n';

export enum CompetitorColumnID {
  CHECKBOX = 'checkbox',
  COMPETITOR_INFO = 'domain',
  ACTIONS = 'actions',
  ORGANIC_TRAFFIC = 'organic_traffic',
  ORGANIC_TRAFFIC_CHANGE = 'organic_traffic_change',
  DYNAMIC_CTR = 'dynamic_ctr',
  DYNAMIC_CTR_CHANGE = 'dynamic_ctr_change',
  SHARE_OF_VOICE = 'share_of_voice',
  SHARE_OF_VOICE_CHANGE = 'share_of_voice_change',
  KEYWORDS_0_TO_3 = 'keywords_0_to_3',
  KEYWORDS_0_TO_3_CHANGE = 'keywords_0_to_3_change',
  KEYWORDS_4_TO_10 = 'keywords_4_to_10',
  KEYWORDS_4_TO_10_CHANGE = 'keywords_4_to_10_change',
  KEYWORDS_11_TO_20 = 'keywords_11_to_20',
  KEYWORDS_11_TO_20_CHANGE = 'keywords_11_to_20_change',
  KEYWORDS_21_TO_50 = 'keywords_21_to_50',
  KEYWORDS_21_TO_50_CHANGE = 'keywords_21_to_50_change',
  KEYWORDS_ABOVE_50 = 'keywords_above_50',
  KEYWORDS_ABOVE_50_CHANGE = 'keywords_above_50_change',
  KEYWORDS_UNRANKED = 'keywords_unranked',
  KEYWORDS_UNRANKED_CHANGE = 'keywords_unranked_change',
  KEYWORDS_AVG_RANK = 'avg_rank',
  KEYWORDS_AVG_RANK_CHANGE = 'avg_rank_change',
}

export const COMPETITOR_QUERY_OPTIONS = {
  withId: true,
  withDomain: true,
  withFaviconUrl: true,
  withDisplayName: true,
  withOrganicTraffic: false,
  withOrganicTrafficCompare: false,
  withDynamicCtrValue: false,
  withDynamicCtrCompare: false,
  withShareOfVoiceValue: false,
  withShareOfVoiceCompare: false,
  withShareOfVoicePercentageValue: false,
  withShareOfVoicePercentageValueCompare: false,
  withCount1To3: false,
  withCount1To3Compare: false,
  withCount4To10: false,
  withCount4To10Compare: false,
  withCount11To20: false,
  withCount11To20Compare: false,
  withCount21To50: false,
  withCount21To50Compare: false,
  withCount51To100: false,
  withCount51To100Compare: false,
  withCountUnranked: false,
  withCountUnrankedCompare: false,
  withAvgRankValue: true,
  withAvgRankCompare: true,
};

export const competitorTableSettings = (
  accessRestricted?: boolean,
): ColumnSetting<keyof CompetitorRowNode>[] => [
  {
    id: CompetitorColumnID.CHECKBOX,
    queryWithClauses: ['id'],
    tableSetting: { required: true },
  },
  {
    id: CompetitorColumnID.COMPETITOR_INFO,
    queryWithClauses: ['id', 'domain', 'faviconUrl', 'displayName'],
    tableSetting: { required: true },
  },
  {
    id: CompetitorColumnID.ORGANIC_TRAFFIC,
    queryWithClauses: ['organicTraffic'],
    tableSetting: {
      getLabel: () => t('AI SoV'),
      defaultHide: false,
      required: false,
    },
  },
  {
    id: CompetitorColumnID.ORGANIC_TRAFFIC_CHANGE,
    queryWithClauses: ['organicTrafficCompare'],
    tableSetting: {
      getLabel: () => t('AI SoV +/-'),
      defaultHide: false,
      grouping: CompetitorColumnID.ORGANIC_TRAFFIC,
    },
  },
  {
    id: CompetitorColumnID.DYNAMIC_CTR,
    queryWithClauses: ['dynamicCtrValue'],
    tableSetting: {
      getLabel: () => t('Average CTR'),
      defaultHide: false,
      required: false,
    },
  },
  {
    id: CompetitorColumnID.DYNAMIC_CTR_CHANGE,
    queryWithClauses: ['dynamicCtrCompare'],
    tableSetting: {
      defaultHide: false,
      grouping: CompetitorColumnID.DYNAMIC_CTR_CHANGE,
    },
  },
  {
    id: CompetitorColumnID.SHARE_OF_VOICE,
    queryWithClauses: ['shareOfVoiceValue', 'shareOfVoicePercentageValue'],
    tableSetting: {
      getLabel: () => t('SoV'),
      defaultHide: false,
      required: false,
    },
  },
  {
    id: CompetitorColumnID.SHARE_OF_VOICE_CHANGE,
    queryWithClauses: ['shareOfVoiceCompare', 'shareOfVoicePercentageValueCompare'],
    tableSetting: {
      getLabel: () => t('SoV +/-'),
      defaultHide: false,
      grouping: CompetitorColumnID.SHARE_OF_VOICE,
    },
  },
  {
    id: CompetitorColumnID.KEYWORDS_AVG_RANK,
    queryWithClauses: ['avgRankValue'],
    tableSetting: {
      getLabel: () => t('Average Rank'),
      defaultHide: true,
    },
  },
  {
    id: CompetitorColumnID.KEYWORDS_AVG_RANK_CHANGE,
    queryWithClauses: ['avgRankCompare'],
    tableSetting: {
      grouping: CompetitorColumnID.KEYWORDS_AVG_RANK,
      defaultHide: true,
    },
  },
  {
    id: CompetitorColumnID.KEYWORDS_0_TO_3,
    queryWithClauses: ['count1To3'],
    tableSetting: {
      getLabel: () => t('1-3'),
      defaultHide: false,
      required: false,
    },
  },
  {
    id: CompetitorColumnID.KEYWORDS_0_TO_3_CHANGE,
    queryWithClauses: ['count1To3Compare'],
    tableSetting: {
      getLabel: () => t('1-3 +/-'),
      defaultHide: false,
      grouping: CompetitorColumnID.KEYWORDS_0_TO_3,
      required: false,
    },
  },
  {
    id: CompetitorColumnID.KEYWORDS_4_TO_10,
    queryWithClauses: ['count4To10'],
    tableSetting: {
      getLabel: () => t('4-10'),
      defaultHide: false,
      required: false,
    },
  },
  {
    id: CompetitorColumnID.KEYWORDS_4_TO_10_CHANGE,
    queryWithClauses: ['count4To10Compare'],
    tableSetting: {
      getLabel: () => t('4-10 +/-'),
      grouping: CompetitorColumnID.KEYWORDS_4_TO_10,
      defaultHide: false,
      required: false,
    },
  },
  {
    id: CompetitorColumnID.KEYWORDS_11_TO_20,
    queryWithClauses: ['count11To20'],
    tableSetting: {
      getLabel: () => t('11-20'),
      defaultHide: false,
      required: false,
    },
  },
  {
    id: CompetitorColumnID.KEYWORDS_11_TO_20_CHANGE,
    queryWithClauses: ['count11To20Compare'],
    tableSetting: {
      getLabel: () => t('11-20 +/-'),
      grouping: CompetitorColumnID.KEYWORDS_11_TO_20,
      defaultHide: false,
      required: false,
    },
  },
  {
    id: CompetitorColumnID.KEYWORDS_21_TO_50,
    queryWithClauses: ['count21To50'],
    tableSetting: {
      getLabel: () => t('21-50'),
      defaultHide: false,
      required: false,
    },
  },
  {
    id: CompetitorColumnID.KEYWORDS_21_TO_50_CHANGE,
    queryWithClauses: ['count21To50Compare'],
    tableSetting: {
      getLabel: () => t('21-50 +/-'),
      grouping: CompetitorColumnID.KEYWORDS_21_TO_50,
      defaultHide: false,
      required: false,
    },
  },
  {
    id: CompetitorColumnID.KEYWORDS_ABOVE_50,
    queryWithClauses: ['count51To100'],
    tableSetting: {
      getLabel: () => t('51-100'),
      defaultHide: false,
      required: false,
    },
  },
  {
    id: CompetitorColumnID.KEYWORDS_ABOVE_50_CHANGE,
    queryWithClauses: ['count51To100Compare'],
    tableSetting: {
      getLabel: () => t('51-100 +/-'),
      grouping: CompetitorColumnID.KEYWORDS_ABOVE_50,
      defaultHide: false,
      required: false,
    },
  },
  {
    id: CompetitorColumnID.KEYWORDS_UNRANKED,
    queryWithClauses: ['countUnranked'],
    tableSetting: {
      getLabel: () => t('Unranked'),
      defaultHide: false,
      required: false,
    },
  },
  {
    id: CompetitorColumnID.KEYWORDS_UNRANKED_CHANGE,
    queryWithClauses: ['countUnrankedCompare'],
    tableSetting: {
      getLabel: () => t('Unranked +/-'),
      grouping: CompetitorColumnID.KEYWORDS_UNRANKED,
      defaultHide: false,
      required: false,
    },
  },
  {
    id: CompetitorColumnID.ACTIONS,
    queryWithClauses: ['id'],
    tableSetting: {
      getLabel: () => t('Actions'),
      defaultHide: false,
      required: false,
      disabled: accessRestricted,
    },
  },
];
