import ErrorBoundary from 'Components/ErrorBoundary';
import KpiBox, { KpiBoxSizeProps } from 'Pages/Keywords/Overview/components/KpiBar/KpiBox';
import { KPI_NAMES } from 'Pages/Keywords/Overview/components/KpiBar/index';
import { t } from 'Utilities/i18n';
import { KpiBarQueryProps } from './useKpiBarQueryWithDisplayCurrency';

const AverageRankKpiBox = ({
  size,
  kpiBarQueryProps,
  draggable,
}: KpiBoxSizeProps & { kpiBarQueryProps: KpiBarQueryProps }) => {
  const { displayCurrency, data, loading } = kpiBarQueryProps;

  return (
    <ErrorBoundary>
      <KpiBox
        size={size}
        id={KPI_NAMES.AVERAGE_RANK}
        title={t('Average Rank')}
        loading={loading || !displayCurrency}
        value={loading ? '-' : data?.kpis?.allKpis?.avgRank ?? '-'}
        beforeValue={
          !loading && data?.kpis?.allKpis?.avgRankBefore ? data?.kpis?.allKpis?.avgRankBefore : '-'
        }
        isRank={true}
        maxValue={loading || !data?.kpis?.allKpis?.avgRank ? undefined : 1}
        precision={2}
        changeNegation={true}
        draggable={draggable}
      />
    </ErrorBoundary>
  );
};

export default AverageRankKpiBox;
