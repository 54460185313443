import { useEffect, useState } from 'react';
import { Flex } from '@mantine/core';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { setFilters } from 'Actions/FilterAction';
import AccButton from 'Components/AccButton/AccButton';
import AccSegmentedControl from 'Components/AccSegmentedControl/AccSegmentedControl';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { useTableStore } from 'Components/DataTable';
import { Flag } from 'Components/Flag';
import AccTitle from 'Components/Title/AccTitle';
import { useDomainInfoQuery, useGscKeywordsQuery } from 'Ghql';
import { useFilters, useSpecificFilter } from 'Hooks';
import { useModal } from 'Hooks/base/useModal';
import { useUpdateEffect } from 'Hooks/common/useUpdateEffect';
import {
  useAddFilter,
  useRemoveFilter,
  useSetOrOverwriteFilter,
} from 'Hooks/data/filter/setFilters';
import { useMetaData } from 'Hooks/data/user/useMetaData';
import * as Actions from 'Pages/Layout/ActionsMenu/Actions';
import ActionbarContainer from 'Pages/Layout/ActionsMenu/components/ActionbarContainer';
import { FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import { TableIDs } from 'Types/Table';
import { FilterTrackingKey, TrackingKey } from 'Utilities/Analytics/mixpanel';
import { t, tn } from 'Utilities/i18n/index';
import { notEmpty } from 'Utilities/underdash';
import GSCKeywordsTable from './GSCKeywordTable';
import { GSC_KEYWORD_SEPARATOR, useFilterVariables } from './GSCKeywordTable/helpers/hooks';
import '../imports.scss';
import styles from './gsc.module.scss';

const ImportFromGSC = observer(() => {
  const {
    domainId,
    keywordComparison,
    countryName,
    hideTrackedKeywords,
    impressionsComparison,
    clicksComparison,
    gscCtrComparison,
    gscPositionComparison,
  } = useFilterVariables();

  const [dataKey, setDataKey] = useState<number>(0);

  const handleUpdate = () => {
    setDataKey((prev) => prev + 1);
  };

  const countryFilter = useSpecificFilter(FilterAttribute.COUNTRY_NAME);

  const { showModal } = useModal();

  const tableStore = useTableStore(TableIDs.IMPORT_GSC);
  const { selectedRows, isAllSelected, data, totalDataLength } = tableStore || {};

  const selectedKeywordsSize = selectedRows?.length || 0;

  const filters = useFilters();

  const showOnlyTrackedKeywordsFilter = {
    attribute: FilterAttribute.GSC_EXISTS,
    type: FilterValueType.BOOL,
    comparison: FilterComparison.EQ,
    value: false,
  };

  const { data: domainData } = useDomainInfoQuery({
    variables: { id: domainId || '' },
    skip: !domainId,
  });

  const domainLocales = domainData?.domain?.defaultSearchSettings || [];

  const metaData = useMetaData();

  const countryLocales = metaData?.countrylocales || [];

  const defaultDomainId = domainData?.domain?.defaultSearchSettings?.[0]?.countrylocale.id;
  const defaultCountry = countryLocales.find(
    (countrylocaleEntry) => countrylocaleEntry?.id === Number(defaultDomainId),
  );

  const [segmentedControlValue, setSegmentedControlValue] = useState(
    defaultCountry?.region ? defaultCountry?.region : 'All',
  );

  useUpdateEffect(() => {
    if (!countryFilter) {
      setSegmentedControlValue('All');
    }
  }, [countryFilter]);

  const segmentedControlCountries = () => {
    return domainLocales.map((locale) => {
      const countrylocale = countryLocales.find(
        (countrylocaleEntry) => countrylocaleEntry?.id === Number(locale?.countrylocale.id),
      );
      //Flex mih={'14px'} is used to make the flag centered within the segmented control
      return {
        label: (
          <AccTooltip tooltip={countrylocale?.region}>
            <Flex mih={'14px'}>
              <Flag country={countrylocale?.countryCode || ''} />
            </Flex>
          </AccTooltip>
        ),
        value: countrylocale?.region || '',
      };
    });
  };

  const segmentedControlDataCountry = [
    ...segmentedControlCountries(),
    {
      label: t('All'),
      value: 'All',
    },
  ];

  const addFilter = useAddFilter();
  const removeFilter = useRemoveFilter();
  const setFilter = useSetOrOverwriteFilter();

  const handleSetFilter = () => {
    if (!defaultCountry?.region) return;

    setFilter(
      {
        attribute: FilterAttribute.COUNTRY_NAME,
        type: FilterValueType.STRING,
        comparison: FilterComparison.CONTAINS,
        value: defaultCountry?.region,
      },
      'no tracking',
    );
  };

  useEffect(() => {
    setFilters(filters.filter((f) => f.attribute !== FilterAttribute.DOMAINS));
    handleSetFilter();
  }, []);

  const getSelectedKeywords = () => {
    const allSelectedKeywords = data?.filter((keywordData) => {
      return !selectedRows?.includes(keywordData.id.toString());
    });
    return isAllSelected
      ? allSelectedKeywords || {}
      : selectedRows?.map((keyword) => ({
          // ARR-4860/ARR-5677 get only the keyword name from the id. This is necessary
          // because the tablestore is paginated and we only have access to the current page of keywords but all ids
          keyword: keyword.split(GSC_KEYWORD_SEPARATOR)[0],
        }));
  };

  const handleAddAction = () => {
    showModal({
      modalType: 'AddKeywords',
      modalTheme: 'light',
      modalProps: {
        domainId,
        gscKeywords: getSelectedKeywords(),
        refresh: handleUpdate,
      },
    });
  };

  const { data: keywordsData } = useGscKeywordsQuery({
    variables: {
      domainId: domainId || '',
    },
    fetchPolicy: 'cache-first',
  });

  const importAll = () => {
    const keywords: { keyword: string }[] = [];
    (keywordsData?.domain?.gscKeywords?.filter(notEmpty) || []).forEach((row) => {
      keywords.push({ keyword: row[5] });
    });

    showModal({
      modalType: 'AddKeywords',
      modalTheme: 'light',
      modalProps: {
        domainId,
        gscKeywords: keywords,
        refresh: handleUpdate,
      },
    });
  };

  const importFiltered = async () => {
    // We do not have the non-paginated, filtered data available in the tablestore, so we need to fetch it..
    const keywords = await tableStore
      ?.fetchData?.({
        tableStore,
        ordering: toJS(tableStore?.ordering),
        pagination: {
          page: 1,
          results: 0,
          startIndex: 0,
          stopIndex: 999999,
        },
      } as any)
      .then((res) => {
        return res?.data?.filter(notEmpty) || [];
      });

    showModal({
      modalType: 'AddKeywords',
      modalTheme: 'light',
      modalProps: {
        domainId,
        gscKeywords: keywords,
        refresh: handleUpdate,
      },
    });
  };

  const toggleHideTrackedKeywords = () => {
    if (!hideTrackedKeywords) {
      addFilter(showOnlyTrackedKeywordsFilter, FilterTrackingKey.GSCKeywordsTable);
    } else {
      removeFilter(FilterAttribute.GSC_EXISTS);
    }
  };

  const isFiltered =
    hideTrackedKeywords ||
    !!countryName ||
    !!impressionsComparison ||
    !!clicksComparison ||
    !!keywordComparison ||
    gscCtrComparison ||
    gscPositionComparison;

  const segmentedControlData = [
    {
      label: t('Hide tracked keywords'),
      value: 'hideTrackedKeywords',
    },
    {
      label: t('Show tracked keywords'),
      value: 'showTrackedKeywords',
    },
  ];

  const handleChange = (value: string) => {
    // Inside a set timeout such that segmented control feels responsive
    // and then filters are set triggering full rerender
    setSegmentedControlValue(value);

    setTimeout(() => {
      if (value === 'All') {
        removeFilter(FilterAttribute.COUNTRY_NAME);
      } else {
        const newFilter = {
          attribute: FilterAttribute.COUNTRY_NAME,
          type: FilterValueType.STRING,
          comparison: FilterComparison.CONTAINS,
          value,
        };

        removeFilter(FilterAttribute.COUNTRY_NAME);

        addFilter(newFilter, FilterTrackingKey.GSCKeywordsTable);
      }
    }, 50);
  };

  return (
    <div className={styles.gsc}>
      <AccTitle type="h3" mb={'xs'}>
        {t('Import keywords directly from Google Search Console')}
      </AccTitle>

      <ActionbarContainer>
        <Actions.AddAction
          disabled={!selectedKeywordsSize}
          key="addSelected"
          label={tn(
            'Import selected keyword (%s)',
            'Import selected keywords (%s)',
            selectedKeywordsSize,
          )}
          onClick={
            selectedKeywordsSize || isAllSelected
              ? handleAddAction
              : () => {
                  null;
                }
          }
        />
        <AccButton
          variant="secondary"
          disabled={!totalDataLength}
          onClick={!totalDataLength ? () => {} : isFiltered ? importFiltered : importAll}
          trackingKey={TrackingKey.AddAction}
        >
          {isFiltered ? t('Import filtered (%s)', totalDataLength) : t('Import all')}
        </AccButton>
        <ActionbarContainer.Sidebar>
          <AccSegmentedControl
            onChange={(value) => handleChange(value)}
            size="sm"
            data={segmentedControlDataCountry}
            value={segmentedControlValue}
          />
          <AccSegmentedControl
            value={
              filters.find((f) => f.attribute === FilterAttribute.GSC_EXISTS)
                ? 'hideTrackedKeywords'
                : 'showTrackedKeywords'
            }
            onChange={toggleHideTrackedKeywords}
            data={segmentedControlData}
            transitionDuration={100}
          />
        </ActionbarContainer.Sidebar>
      </ActionbarContainer>
      <div className="gsc-keywords-table">
        <GSCKeywordsTable domainId={domainId} dataKey={dataKey} />
      </div>
    </div>
  );
});

export default ImportFromGSC;
