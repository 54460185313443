const SEND_TO = 'send_to';
const USER_TYPE = 'user_type';
const CREATED_AT = 'createdAt';
const ACTIONS = 'actions';

export const ColumnIDs = {
  SEND_TO,
  USER_TYPE,
  CREATED_AT,
  ACTIONS,
} as const;
