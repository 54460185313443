import type { Integration } from '../types/Integration';
import IntegrationCard from './IntegrationCard';

type Props = {
  title: string;
  subtitle?: any;
  integrations: Integration[];
};

const IntegrationCategory = ({ title, subtitle, integrations }: Props) => (
  <div className="integrations-category">
    <div className="integrations-title">{title}</div>
    {subtitle && <div className="integrations-subtitle">{subtitle}</div>}
    <div className="integrations-list">
      {integrations.map((integration) => (
        <div key={`int-${integration.id}`} className="integration">
          <IntegrationCard integration={integration} />
        </div>
      ))}
    </div>
  </div>
);

export default IntegrationCategory;
