export enum GroupColumnIDs {
  GROUP_DISPLAY_NAME = 'name',
  NUMBER_OF_DOMAINS = 'number_of_domains',
  NUMBER_OF_KEYWORDS = 'number_of_keywords',
  DATE_ADDED = 'created_at',
  ACTIONS = 'actions',
}

// dateAdded:"2023-11-06"
// domains:[{
//     id: string
// }]
// id:"508"
// index:2
// name:"Tester"
// numberOfDomains:1
// numberOfKeywords: 0
// organization: {
//     id: string
// }

// export type GroupNode = {
//   __typename?: 'GroupNode';
//   avgRank?: Scalars['Float'];
//   avgRankCompare?: Scalars['Float'];
//   canUpdate?: Scalars['Boolean'];
//   client?: Scalars['String'];
//   clientId?: Scalars['String'];
//   count1To3?: Scalars['Int'];
//   count4To10?: Scalars['Int'];
//   count11To20?: Scalars['Int'];
//   count21To50?: Scalars['Int'];
//   count51To100?: Scalars['Int'];
//   countKeywords?: Scalars['Int'];
//   countNotifications?: Scalars['Int'];
//   countUnranked?: Scalars['Int'];
//   country?: Scalars['String'];
//   dateAdded?: Scalars['Date'];
//   displayName?: Scalars['String'];
//   domain?: Scalars['String'];
//   faviconUrl?: Scalars['String'];
//   id?: Scalars['Int'];
//   isDemoDomain?: Scalars['Boolean'];
//   organicTraffic?: Scalars['Int'];
//   organicTrafficCompare?: Scalars['Int'];
//   shareOfVoice?: Scalars['Int'];
//   shareOfVoiceCompare?: Scalars['Int'];
//   shareOfVoiceIsPercentage?: Scalars['Boolean'];
//   shareOfVoicePercentageValue?: Scalars['Float'];
//   shareOfVoicePercentageValueCompare?: Scalars['Float'];
//   untrackedKeywords?: Scalars['Int'];
// };
