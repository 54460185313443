import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import AccTooltip from '../AccTooltip/AccTooltip';
import './icon.scss';

type IconProps = {
  id?: string;
  width: number;
  height: number;
  color: string;
  children: any;
  tooltip?: string;
  useToolTipPopover?: boolean;
  useMinWidthTooltip?: boolean;
  useToolTipMaxWidth?: boolean;
  disabled?: boolean;
  tooltipPlacement: string;
  onClick?: (...args: Array<any>) => any;
  style?: any;
  tooltipKey?: any;
  active?: boolean;
  colorType?: 'default' | 'inactive';
  link?: any | undefined;
  className?: string;
  nofill?: boolean;
};

const Icon = React.memo((props: IconProps | any) => {
  const inlineStyle = {
    width: `${props.width}px`,
    height: `${props.height}px`,
    color: props.color,
    cursor: props.onClick ? (props.disabled ? 'not-allowed' : 'pointer') : '',
    ...props.style,
  };
  const IconContent = () => (
    <>
      {props.children}
      {props.tooltip && !props.useToolTipPopover && (
        <div
          className={`icon-new__tooltip ${
            props.useToolTipMaxWidth ? 'icon-new__tooltip--max-width' : ''
          }`}
        >
          {props.tooltip}
        </div>
      )}
    </>
  );

  return (
    <AccTooltip
      tooltip={props.tooltip}
      disable={!props.useToolTipPopover}
      placement={props.tooltipPlacement}
      key={props.tooltipKey}
    >
      <span
        id={props.id ? props.id : null}
        className={cn(
          'icon-new',
          { 'icon-new--visible-overflow': props.visibleOverflow },
          !props.disabled && (props.onClick || props.link) ? 'icon-new__button' : '',
          props.colorType === 'default' && `icon-new--color-${props.colorType}`,
          props.colorType === 'inactive' && `icon-new--color-${props.colorType}`,
          { active: props.active },
          props.className,
          { nofill: props.nofill },
        )}
        style={inlineStyle}
        onClick={props.onClick && !props.disabled ? props.onClick : null}
      >
        {props.link ? (
          <Link to={props.link}>
            <IconContent />
          </Link>
        ) : (
          <IconContent />
        )}
      </span>
    </AccTooltip>
  );
}) as any;

Icon.displayName = 'Icon';

Icon.defaultProps = {
  useToolTipPopover: true,
  useToolTipMaxWidth: false,
  useMinWidthTooltip: false,
  tooltipPlacement: 'top',
};
export default Icon;
