import { Link } from 'react-router-dom';
import { Box, Flex, useMantineTheme } from '@mantine/core';
import { IconListSearch, IconTrendingDown, IconTrendingUp } from '@tabler/icons-react';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import linkWithFilters from 'Components/Filters/linkWithFilters';
import FormatNumber from 'Components/FormatNumber/new';
import AccText from 'Components/Text/AccText';
import { useFilters } from 'Hooks';
import { useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import { formatFiltersWithRankChange } from 'Hooks/data/filter/useFilters';
import { KeywordsTableSortType } from 'Pages/Keywords/Table/support/constants';
import {
  FilterAttribute,
  FilterBase,
  FilterComparison,
  FilterValueType,
  PERIOD,
  PeriodFilter,
} from 'Types/Filter';
import { KEYWORDS_FILTER_SET } from 'Types/FilterSet';
import colorScheme from 'Utilities/colors';
import { t } from 'Utilities/i18n';

function getLinkToKeywordTable(
  filters: FilterBase[],
  periodFrom: string | undefined,
  periodTo: string | undefined,
  isWinner: boolean,
) {
  // Period is needed to get the correct data for the table in group view, as overview data may use different dates than keyword list data
  // since overview only shows data the latest day where all domains in the group are scraped
  const newPeriodFilter: PeriodFilter = {
    attribute: FilterAttribute.PERIOD,
    type: FilterValueType.DATETIME,
    comparison: FilterComparison.BETWEEN,
    value: `["${periodFrom}", "${periodTo}"]`,
  };
  // periodFilter: newPeriodFilter,
  const newFilters = filters.filter((e) => e.attribute !== PERIOD).concat(newPeriodFilter);
  return linkWithFilters({
    to: '/keywords/list',
    overwriteFilters: formatFiltersWithRankChange(newFilters, isWinner),
    filterSet: KEYWORDS_FILTER_SET,
  })?.concat(
    `?sort=${
      isWinner
        ? KeywordsTableSortType.SHARE_OF_VOICE_CHANGE_DESC
        : KeywordsTableSortType.SHARE_OF_VOICE_CHANGE_ASC
    }`,
  );
}

type WinnerLoserBarLabelProps = {
  label: string;
  isWinner: boolean;
  keywords?: number;
  shareOfVoiceChange?: number;
  periodFrom?: string;
  periodTo?: string;
  useAi?: boolean;
};

export const WinnerLoserBarLabel = ({
  label,
  isWinner,
  keywords,
  shareOfVoiceChange,
  periodFrom,
  periodTo,
  useAi,
}: WinnerLoserBarLabelProps) => {
  const { asPercentage: regularSoVPerAsPercentage } = useQueryDomainInfo();
  const filters = useFilters();
  const theme = useMantineTheme();
  const link = getLinkToKeywordTable(filters, periodFrom, periodTo, isWinner);
  const asPercentage = useAi ? false : regularSoVPerAsPercentage;
  const change = shareOfVoiceChange || 0;
  const winnerColor = colorScheme.increase;
  const loserColor = colorScheme.decrease;
  const direction = isWinner ? 'row' : 'row-reverse';
  const prefix = !change ? '' : isWinner && change > 0 ? '+' : '-';
  const color = isWinner && change >= 0 ? winnerColor : loserColor;
  const ArrowIcon = isWinner && change >= 0 ? IconTrendingUp : IconTrendingDown;

  return (
    <Box>
      <Flex direction={direction} justify="flex-start" wrap="nowrap" align="center" gap="8px">
        <AccText c="snorlax" size="md" tt="uppercase" fw={700}>
          {label}
        </AccText>
        {!!keywords && (
          <Link to={link}>
            <AccTooltip
              tooltip={t('See %s in keyword list', isWinner ? t('winners') : t('losers'))}
            >
              <AccActionIcon variant="navigation" tabIndex={-1}>
                <IconListSearch />
              </AccActionIcon>
            </AccTooltip>
          </Link>
        )}
      </Flex>
      <Flex direction={direction} justify="flex-start" wrap="nowrap" align="center" gap="6px">
        <AccText size="xl" fw={600} c={theme.colors.gray[9]}>
          {prefix}
          <FormatNumber
            value={Math.abs(change)}
            style={asPercentage ? 'percent' : 'decimal'}
            maximumFractionDigits={0}
          />
        </AccText>
        <AccText size="sm" mt="5px" fw={400} c={theme.colors.gray[7]}>
          {useAi ? t('AI Share of Voice') : t('Share of Voice')}
        </AccText>
        <ArrowIcon size={24} stroke={2} color={color} />
      </Flex>
    </Box>
  );
};
