import { useFilters } from 'Hooks';
import { useSetOrOverwriteFilter } from 'Hooks/data/filter/setFilters';
import { ClientsFilter, FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';

export const useInitialGroup = () => {
  const filtersFromURL = useFilters();
  const setFilter = useSetOrOverwriteFilter();
  const initialGroup = (
    filtersFromURL.find((filter) => filter.attribute === FilterAttribute.CLIENTS) as
      | ClientsFilter
      | undefined
  )?.value;

  if (initialGroup?.length && initialGroup?.length > 1) {
    setFilter(
      {
        attribute: FilterAttribute.CLIENTS,
        type: FilterValueType.LIST,
        comparison: FilterComparison.CONTAINS,
        value: [initialGroup[0]],
      },
      'no tracking',
    );
  }

  return initialGroup?.[0] ? Number(initialGroup?.[0]) : null;
};
