import { Component } from 'react';
import { connect } from 'react-redux';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import compose from 'lodash/flowRight';
import { Field, reduxForm } from 'redux-form';
import { hideModal } from 'Actions/ModalAction';
import AccButton from 'Components/AccButton/AccButton';
import { TextField } from 'Components/Forms/Fields';
import FormErrors from 'Components/Forms/FormErrors';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import toast from 'Hooks/useToast';
import { t } from 'Utilities/i18n/index';
import Validator from 'Utilities/validation';

type Props = {
  accountId: string;
  hideModal: () => void;
  updateAdobeConnection: (...args: Array<any>) => any;
  adobeConnection: Record<string, any>;
  handleSubmit: (...args: Array<any>) => any;
  submitting: boolean;
  refresh?: () => void;
  adobeConnections?: any;
};

class EditAdobeAccount extends Component<Props> {
  onSubmit = ({ description }) => {
    const { accountId } = this.props;
    return this.props
      .updateAdobeConnection({
        variables: {
          input: {
            id: accountId,
            description,
            delete: false,
          },
        },
      })
      .then(
        ({
          data: {
            updateAdobeMarketingConnection: { errors },
          },
        }) => {
          if (errors && errors.length) {
            Validator.throwSubmissionError(errors);
          } else {
            this.props.hideModal();
            this.props.refresh && this.props.refresh();
            this.props.adobeConnections?.refetch();
          }
        },
      )
      .catch((error) => {
        toast.error(t('Failed to update'));
        throw error;
      });
  };

  render() {
    const { handleSubmit, submitting } = this.props;
    return (
      <ModalBorder
        className="edit-adobe-account"
        onClose={submitting ? this.props.hideModal : undefined}
        title={t('Edit Adobe Account')}
      >
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <FormErrors />
          <div className="form-label required">{t('Description')}</div>
          <Field
            name="description"
            placeholder={t('Description')}
            component={TextField}
            disabled={submitting}
            validate={Validator.required}
            autoFocus={true}
          />
          <ModalFooter
            primaryButtonSlot={
              <AccButton variant="primary" disabled={submitting} type="submit">
                {t('Edit connection')}
              </AccButton>
            }
            secondaryButtonSlot={
              <AccButton variant="tertiary" disabled={submitting} onClick={this.props.hideModal}>
                {t('Cancel')}
              </AccButton>
            }
          />
        </form>
      </ModalBorder>
    );
  }
}

const adobeConnectionsQuery = gql`
  query editAdobeAccount_adobeConnections {
    user {
      id
      organization {
        id
        adobeMarketingConnections {
          id
          description
        }
      }
    }
  }
`;
const updateAdobeConnectionQuery = gql`
  mutation editAdobeAccount_updateAdobeConnection($input: UpdateAdobeMarketingConnectionInput!) {
    updateAdobeMarketingConnection(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;
export default compose(
  graphql(adobeConnectionsQuery, {
    name: 'adobeConnections',
    options: () => ({
      fetchPolicy: 'network-only',
    }),
    props: ({ ownProps, adobeConnections }: any) => {
      const { accountId } = ownProps;
      let description = '';

      if (!adobeConnections.loading && !adobeConnections.error) {
        const connection =
          adobeConnections.user &&
          adobeConnections.user.organization.adobeMarketingConnections.find(
            (conn) => conn.id === accountId,
          );
        description = connection ? connection.description : '';
      }

      return {
        ...ownProps,
        initialValues: {
          description,
        },
        adobeConnections,
      };
    },
  }),
  graphql(updateAdobeConnectionQuery, {
    name: 'updateAdobeConnection',
  }),
  connect(null, {
    hideModal,
  }),
)(
  reduxForm({
    form: 'EditAdobeAccount',
    enableReinitialize: true,
  })(EditAdobeAccount),
);
