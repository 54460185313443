import { NodeModel } from 'Pages/Keywords/Groupings/EditMode/react-dnd-treeview/types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const mutateTree = <T>(
  tree: NodeModel[],
  dragSourceId: NodeModel['id'],
  dropTargetId: NodeModel['id'],
): NodeModel[] =>
  tree.map((node) => {
    if (node.id === dragSourceId) {
      return {
        ...node,
        parent: dropTargetId,
      };
    }

    return node;
  });
