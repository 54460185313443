import noop from 'lodash/noop';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { TableSize } from '../constants';
import { useContextTableStore } from '../store/components/TableStoreContext';
import { CellContext } from '../types';

/**
 * Inject table context into component, as an example used to get viewMode
 */
export const withCellContext = <T extends {}>(
  WrappedComponent: React.ComponentType<React.PropsWithChildren<T>>,
): React.ComponentType<React.PropsWithChildren<T & { context: CellContext }>> => {
  const ResultComponent: any = (props: T) => {
    const tableStore = useContextTableStore();
    const context: CellContext = {
      viewMode: tableStore?.viewMode ?? TableSize.DEFAULT,
      toggleExpandRow: tableStore?.toggleExpandRow ?? noop,
      toggleExpandRowConfig: tableStore?.toggleExpandRowConfig ?? noop,
      expandedRows: toJS(tableStore?.expandedRows) ?? [],
      expandedExtraRows: toJS(tableStore?.expandRowConfig?.rowKeys) || [],
    };
    return <WrappedComponent {...props} context={context} />;
  };
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
  ResultComponent.displayName = `withCellContext(${displayName})`;
  ResultComponent.defaultProps = WrappedComponent.defaultProps as any;
  return observer(ResultComponent);
};
