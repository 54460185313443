import { useMemo, useRef, useState } from 'react';
import { IconFilterPlus } from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import SearchItemsList from 'Components/Controls/Dropdowns/SearchItemsList';
import FilterEditorForm from 'Components/Filters/FilterEditorForm';
import { getAllFiltersCombo } from 'Components/Modal/Content/Shortcuts';
import { AccPopover, InjectedPopoverBodyProps } from 'Components/Popover/AccPopover';
import { FilterAttribute } from 'Types/Filter';
import type { FilterBase } from 'Types/Filter';
import { getFilterBarFilterAttributes } from 'Types/FilterSet';
import type { FilterSet } from 'Types/FilterSet';
import { t } from 'Utilities/i18n';
import { useMousetrap } from 'Utilities/mousetrap';
import { isMac, notEmpty } from 'Utilities/underdash';
import { checkForFilterRegexErrorReduxForm } from 'Utilities/validation';
import getFilterData, { FilterData } from '../getFilterData';
import styles from './addFilter.module.scss';

type AddFilterProps = {
  onAdd: (filter: FilterBase) => void;
  filters: FilterBase[];
  filterSet: FilterSet;
  disabled?: boolean;
  flip?: boolean;
  hasPrimaryFilterAdded?: boolean;
};

const primaryFilters = {
  KEYWORD: [FilterAttribute.KEYWORD, FilterAttribute.KEYWORDS],
  DOMAIN: [FilterAttribute.DOMAIN, FilterAttribute.DOMAINS],
};

function excludePrimaryFilters(filters: FilterBase[]) {
  const primaryFilterValues = [
    FilterAttribute.DOMAINS,
    FilterAttribute.FREE_TEXT_DOMAIN,
    FilterAttribute.PERIOD,
    FilterAttribute.COMPARE_TO,
  ];
  return filters.filter((filter) => !(filter.attribute in primaryFilterValues));
}

export const getFilterSearchShortCutDescription = (attribute: string | undefined) => {
  return attribute && primaryFilters.KEYWORD.find((attrs) => attrs.includes(attribute))
    ? t('Open keywords filter')
    : t('Open domains filter');
};

export const getIsPrimaryFilter = (attribute: string) => {
  return Object.values(primaryFilters).find((arr: string[]) => arr.includes(attribute));
};

const usePrimaryFilter = (filterSet: FilterSet) => {
  const filterAttrs = getFilterBarFilterAttributes(filterSet);

  const getAttribute = (filterAttributes: string[], attribute: string): string | null => {
    if (filterAttributes?.includes(attribute)) {
      return attribute;
    }
    return null;
  };
  const keywordsAttribute = primaryFilters.KEYWORD.find((attr) => getAttribute(filterAttrs, attr));
  const domainsAttribute = primaryFilters.DOMAIN.find((attr) => getAttribute(filterAttrs, attr));
  const activeAttribute: string | undefined = keywordsAttribute || domainsAttribute;
  const description = getFilterSearchShortCutDescription(activeAttribute);
  return { activeAttribute, description };
};

const AddFilter = ({ filters, filterSet, disabled, onAdd, ...props }: AddFilterProps) => {
  const popoverControlsRef: any = useRef<InjectedPopoverBodyProps | null>(null) as {
    current: InjectedPopoverBodyProps | null;
  };

  const [filterAttribute, setFilterAttribute] = useState<string | null | undefined>(null);
  const { description, activeAttribute } = usePrimaryFilter(filterSet);
  const shortcut = activeAttribute ? (isMac() ? 'command+f' : 'ctrl+f') : null;
  const onClose = () => {
    popoverControlsRef.current?.closePopover();
  };
  const onOpenFilter = () => {
    popoverControlsRef.current?.openPopover();
    setFilterAttribute(activeAttribute);
  };
  useMousetrap({
    combination: shortcut,
    description,
    callback: onOpenFilter,
    disabled: props.hasPrimaryFilterAdded,
  });

  const addFilterShortcut = activeAttribute ? (isMac() ? 'command+shift+f' : 'ctrl+shift+f') : null;
  const addFilterDescription = t('Open all filters');
  const onOpenAllFilters = () => {
    popoverControlsRef.current.openPopover();
    setFilterAttribute(null);
  };
  useMousetrap({
    combination: addFilterShortcut,
    description: addFilterDescription,
    callback: onOpenAllFilters,
  });

  const handleEscape = () => {
    onClose();
  };

  useMousetrap({
    combination: 'esc',
    description: t('Close this modal'),
    callback: handleEscape,
  });

  const toggleDropdown = () => {
    setFilterAttribute(null);
    onClose();
  };

  const onSubmit = (filter: FilterBase) => {
    checkForFilterRegexErrorReduxForm(filter);

    toggleDropdown();
    onAdd(filter);
  };

  const onSelect = (filterItem) => {
    setFilterAttribute(filterItem.defaultValue.attribute);
  };

  const onBack = () => {
    setFilterAttribute(null);
  };

  let dropdownContent: JSX.Element;
  if (!filterAttribute) {
    let filteredFilterList: string[] | FilterData[] = getFilterBarFilterAttributes(filterSet);

    filteredFilterList = filteredFilterList
      .map((fAttribute) => getFilterData(fAttribute))
      .filter(notEmpty);
    dropdownContent = (
      <SearchItemsList
        items={filteredFilterList}
        labelFunc={(filterItem) => filterItem.title}
        iconFunc={(filterItem) => <filterItem.icon />}
        onSelect={onSelect}
        title={t('Select a type of filter')}
        keyboardCombo={getAllFiltersCombo()}
        closeOnSelect={false}
        emptyText={t('No filter matches your search')}
      />
    );
  } else {
    const filter = getFilterData(filterAttribute)?.defaultValue;
    dropdownContent = (
      <FilterEditorForm
        isOpen={true}
        isNew={true}
        onSubmit={onSubmit}
        filter={filter}
        initialValues={filter}
        onCancel={onBack}
      />
    );
  }

  const visibleFilters = excludePrimaryFilters(filters);

  const compact = visibleFilters?.length > 4;

  // useMemo required for proper position of popover body on shortcut usage
  const popoverTarget = useMemo(
    () => (
      <AccButton
        variant="tertiary"
        leftSection={<IconFilterPlus size={20} />}
        disabled={disabled}
        className={styles.addFilterButton}
      >
        {t('Add filter')}
      </AccButton>
    ),
    [disabled, compact],
  );
  return (
    <div className="simple-dropdown">
      <AccPopover
        disabled={disabled}
        key={disabled?.toString()}
        target={popoverTarget}
        targetToggle={true}
        position={'bottom-start'}
        body={{ p: 0 }}
        withArrow={false}
        onClose={onBack}
        controlsRef={popoverControlsRef}
        trapFocus
      >
        <div className="simple-dropdown">
          <div className="popover-menu dropdown-menu-overlap" style={{ maxWidth: '600px' }}>
            {dropdownContent}
          </div>
        </div>
      </AccPopover>
    </div>
  );
};

export default AddFilter;
