import { Component } from 'react';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import compose from 'lodash/flowRight';
import AccButton from 'Components/AccButton/AccButton';
import Skeleton from 'Components/Skeleton';
import SkeletonTableBody from 'Components/Skeleton/TableBody';
import TableEmptyState from 'Components/TableEmptyState';
import { t } from 'Utilities/i18n/index';
import { graphqlOK } from 'Utilities/underdash';

type Props = {
  adobeSuites: Record<string, any>;
  connectionId: string;
  onSubmit: (...args: Array<any>) => any;
  onBack: (...args: Array<any>) => any;
};

class AdobeSuite extends Component<Props> {
  getRows = () => {
    const {
      connectionId,
      adobeSuites: {
        user: {
          organization: { adobeMarketingConnections },
        },
      },
    } = this.props;
    const currentConnection = adobeMarketingConnections.find(
      (connection) => connection.id === connectionId,
    );
    return (currentConnection && currentConnection.suiteIds) || [];
  };

  renderBody() {
    if (!graphqlOK(this.props)) {
      return this.renderSkeleton();
    }

    return (
      <tbody>
        {this.getRows().map((suite) => (
          <tr key={suite.id}>
            <td>
              <a
                tabIndex={0}
                onClick={() =>
                  this.props.onSubmit({
                    suiteId: suite.id,
                  })
                }
              >
                {suite.label}
              </a>
            </td>
          </tr>
        ))}
      </tbody>
    );
  }

  renderSkeleton = () => (
    <SkeletonTableBody>
      <Skeleton
        linesConfig={[
          {
            type: 'text',
            options: {
              width: '20%',
              marginBottom: '10px',
            },
          },
        ]}
      />
    </SkeletonTableBody>
  );

  render() {
    const { onBack } = this.props;
    return (
      <div>
        <table className="data-table">
          <thead>
            <tr>
              <th>{t('Suite')}</th>
            </tr>
          </thead>
          {this.renderBody()}
        </table>
        {graphqlOK(this.props) && (
          <TableEmptyState
            list={this.getRows()}
            title={t('No Data')}
            subTitle={t('There are currently no suites.')}
          />
        )}
        <div className="confirmation-button-wrapper text-right">
          <AccButton variant="tertiary" onClick={onBack}>
            {t('Back')}
          </AccButton>
        </div>
      </div>
    );
  }
}

const adobeSuitesQuery = gql`
  query adobeSuite_adobeSuites {
    user {
      id
      organization {
        id
        adobeMarketingConnections {
          id
          suiteIds {
            id
            label
          }
        }
      }
    }
  }
`;
export default compose(
  graphql(adobeSuitesQuery, {
    name: 'adobeSuites',
    options: () => ({
      fetchPolicy: 'network-only',
    }),
  }),
)(AdobeSuite);
