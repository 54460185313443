import { MouseEvent, memo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Flex, Group, Progress, Skeleton, useMantineTheme } from '@mantine/core';
import cn from 'classnames';
import isEqual from 'lodash/isEqual';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import FormatNumber from 'Components/FormatNumber/new';
import AccText from 'Components/Text/AccText';
import styles from './styles.module.scss';

interface BarChartItem {
  value: number;
  background?: string;
  label?: string;
  labelColor?: string;
  icon?: JSX.Element;
  color?: string;
  link?: string;
  tooltip?: string;
  onClick?: () => void;
  labelActionButtons?: JSX.Element[];
  isOwnDomain?: boolean | null;
}

type Props = {
  items: BarChartItem[];
  total?: number;
  normalNumberOfItems?: number;
  loading?: boolean;
  enableLabelTooltips?: boolean;
  formatNumberStyle?: 'decimal' | 'percent' | 'currency';
  /**  This term highlights that a single item can have multiple contributing values that overlap within the total. */
  enableMultiValueOverlap?: boolean;
};

type LabelActionProps = {
  text?: string;
  tooltip?: string;
  callback?: () => void;
  component?: JSX.Element;
};

// Used below to add a react router link conditionally if a bar has a value > 0 and a link
const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: {
  condition?: unknown;
  wrapper: (_: React.ReactNode) => JSX.Element;
  children: JSX.Element;
}): JSX.Element => (condition ? wrapper(children) : children);

const BarChartLoader = ({ numberOfItems }) => {
  if (numberOfItems > 1) {
    return (
      <>
        <Group justify="space-between" gap="xs">
          <Skeleton height={11} mt={10} width="10%" radius="xl" />
          <Skeleton height={11} mt={10} width="80%" radius="xl" />
          <Skeleton height={11} mt={10} width="5%" radius="xl" />
        </Group>
        {[...Array(numberOfItems - 1)].map((_, i) => (
          <Group justify="space-between" gap="xs" key={`barchart-skeleton-${i}`}>
            <Skeleton height={11} mt={25} width="10%" radius="xl" />
            <Skeleton height={11} mt={25} width="80%" radius="xl" />
            <Skeleton height={11} mt={25} width="5%" radius="xl" />
          </Group>
        ))}
      </>
    );
  }
  return (
    <Group justify="space-between" gap="xs">
      <Skeleton height={11} mt={10} width="10%" radius="xl" />
      <Skeleton height={11} mt={10} width="80%" radius="xl" />
      <Skeleton height={11} mt={10} width="5%" radius="xl" />
    </Group>
  );
};

export const LabelActionButton = ({ text, tooltip, callback, component }: LabelActionProps) => {
  const theme = useMantineTheme();
  const cb = (e: MouseEvent) => {
    e.stopPropagation();
    callback && callback();
  };
  if (!component) {
    const btn = (
      <AccActionIcon
        variant="filled"
        size={'sm'}
        color={theme.colors.gray[0]}
        onClick={cb}
        radius="sm"
        c={theme.colors.gray[4]}
      >
        {text ?? '+'}
      </AccActionIcon>
    );
    component = tooltip ? (
      <AccTooltip tooltip={tooltip} disable={!tooltip}>
        {btn}
      </AccTooltip>
    ) : (
      btn
    );
  }

  return <div>{component}</div>;
};

/**
 * A bar horizontal chart with a max value - total (if not specified, this is inferred from the data) such that
 * each bar corresponds to a percentage of this total.
 * The chart can optionally have icons or labels to the left of the bar and links on click of the bars.
 * The chart also supports tooltips on hover and is colored either through background property (to support gradients)
 * or color property (using color prop to Mantine progress component, see https://mantine.dev/core/progress/)
 */
export const BarChart = memo(
  ({
    items,
    total,
    loading,
    normalNumberOfItems,
    enableLabelTooltips,
    formatNumberStyle = 'decimal',
    enableMultiValueOverlap = false,
  }: Props) => {
    const [enabledIndices, setEnabledIndices] = useState<boolean[]>(new Array(11).fill(true));

    if (enabledIndices.length !== items.length) {
      setEnabledIndices(new Array(items.length).fill(true));
    }

    const enabledItems = items.filter((_, i) => enabledIndices[i]);
    const disabledItems = items.filter((_, i) => !enabledIndices[i]);

    if (loading && normalNumberOfItems) {
      return <BarChartLoader numberOfItems={normalNumberOfItems} />;
    } else if (items.length === 0) {
      return null;
    }

    let resultTotal: number;

    if (enableMultiValueOverlap) {
      const allEnabled =
        enabledIndices.filter((indexValue) => indexValue === true).length === items.length;
      resultTotal = allEnabled
        ? //use total as resultTotal or fallback to all values summarized
          total || enabledItems.reduce((partialSum, a) => partialSum + a.value, 0)
        : //set resultTotal to the largest value in the list of enabled items
          Math.max(...enabledItems.map((item) => item.value));
    } else {
      resultTotal = total
        ? total - disabledItems.reduce((partialSum, a) => partialSum + a.value, 0)
        : enabledItems.reduce((partialSum, a) => partialSum + a.value, 0) || 1;
    }

    const maxDigits = formatNumberStyle === 'percent' ? 1 : 0;

    return (
      <>
        <Flex direction={'row'} gap={'md'}>
          <Flex direction={'column'}>
            {items.map((value, i) => (
              <div
                className={cn(styles.title, styles.row, styles.label)}
                style={enabledIndices[i] ? {} : { opacity: 0.5 }}
                key={`icon-${value.label}-${i}`}
                onClick={() =>
                  setEnabledIndices((prev) => {
                    const tmpIndicies = [...prev];
                    tmpIndicies[i] = !tmpIndicies[i];
                    return tmpIndicies;
                  })
                }
              >
                <div>{value.icon}</div>
                {value.label && (
                  <AccTooltip tooltip={value.label} disable={!enableLabelTooltips}>
                    <AccText
                      size="xs"
                      c={value.isOwnDomain ? 'orange' : value?.labelColor || 'snorlax'}
                      overflowEllipsis
                      noOverflowTooltip
                      fw={600}
                    >
                      {value.label}
                    </AccText>
                  </AccTooltip>
                )}
                {value.labelActionButtons && (
                  <Flex gap={4} ml="auto" c="blue">
                    {value.labelActionButtons}
                  </Flex>
                )}
              </div>
            ))}
          </Flex>
          <Flex direction={'column'} w="100%">
            {items.map((value, i) => (
              <ConditionalWrapper
                key={`${value.background}-${value.value}-${value.label}`}
                condition={value.link}
                wrapper={(children) => <Link to={value.link as string}>{children}</Link>}
              >
                <div
                  className={styles.row}
                  key={`bar-${value.label}`}
                  data-bar-chart-progress-color={value.color}
                  style={{ cursor: value.onClick ? 'pointer' : 'default' }}
                >
                  <AccTooltip tooltip={value.tooltip} disable={!value.tooltip}>
                    <Progress
                      className={styles.progressRoot}
                      data-with-hover-styles={!!value.onClick || null}
                      value={100 * (enabledIndices[i] ? value.value / resultTotal : 0)}
                      onClick={value.onClick}
                      styles={{
                        section: {
                          background: value.background,
                        },
                      }}
                    />
                  </AccTooltip>
                </div>
              </ConditionalWrapper>
            ))}
          </Flex>
          <Flex direction={'column'}>
            {items.map((e, i) => (
              <div
                className={cn(styles.value, styles.row)}
                key={`number-${e.label}-${i}`}
                style={{ cursor: 'default' }}
              >
                <FormatNumber
                  value={e.value}
                  style={formatNumberStyle}
                  maximumFractionDigits={maxDigits}
                  minimumFractionDigits={maxDigits}
                />
              </div>
            ))}
          </Flex>
        </Flex>
      </>
    );
  },
  isEqual,
);
BarChart.displayName = 'BarChart';
