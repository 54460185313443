import { Component, ComponentType } from 'react';
import { removeInitialLoader } from 'Utilities/underdash';

export default class NoStoreRoute<T extends object> extends Component<{
  component: ComponentType<React.PropsWithChildren<T>>;
}> {
  constructor(props) {
    super(props);
    removeInitialLoader();
  }

  render() {
    const { component: WrappedComponent } = this.props;
    return <WrappedComponent {...(this.props as T)} />;
  }
}
