import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import FormatNumber from 'Components/FormatNumber/new';
import { SEARCH_TYPE_NAVER, SearchEngineIds } from 'Components/Modal/Content/AddSearchEngine/types';
import { t } from 'Utilities/i18n';
import Cell from './HelperComponents/AccuCell';
import NaCell from './helpers/NaCell';
import './styles.scss';

type Props = {
  value: {
    data: {
      id: number;
      searchVolumeValue: number;
      validForSearchVolume: boolean;
      organicTraffic: number;
      updatingKeyword: boolean;
      searchEngineId: number;
    };
    loadingData: boolean;
  };
  updatingKeyword?: boolean;
};

// AI SoV
const TrafficCell = (props: Props) => {
  const {
    value: { data },
  } = props;
  const { organicTraffic, validForSearchVolume } = data;

  if (data?.searchEngineId === SearchEngineIds[SEARCH_TYPE_NAVER]) {
    return <NaCell tooltip={t('No AI SoV for this search engine')} align={'right'} />;
  }

  if ((!validForSearchVolume && !isNumber(organicTraffic)) || organicTraffic === null) {
    return null;
  }

  const showCellData = !isNil(organicTraffic);
  return (
    <Cell className={'text-right'}>{showCellData && <FormatNumber value={organicTraffic} />}</Cell>
  );
};

export default TrafficCell;
