import { Component } from 'react';
import cn from 'classnames';
import forOwn from 'lodash/forOwn';
import transform from 'lodash/transform';
import underdash from 'Utilities/underdash';
import SkeletonTypes from './Types';

const ALIGNMENT_CENTER = 'center';
const ALIGNMENT_RIGHT = 'right';
type Options = {
  width?: string;
  height?: string;
  alignment?: typeof ALIGNMENT_CENTER | typeof ALIGNMENT_RIGHT;
  display?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
  flex?: string;
  borderRadius?: string;
};

export type LineConfig = {
  type:
    | 'text'
    | 'title'
    | 'subtitle'
    | 'spacer'
    | 'spacer-underline'
    | 'input'
    | 'button'
    | 'icon-sm'
    | 'chart'
    | 'pie-chart';
  options?: Options;
};

type Props = {
  lines: number;
  linesConfig: Array<LineConfig>;
  className?: string;
};

const lineTypes = {
  spacer: true,
  'spacer-underline': true,
  title: true,
  subtitle: true,
  text: true,
  label: true,
  input: true,
  button: true,
  'icon-sm': true,
  'image-circle': true,
  'image-square': true,
  'image-rectangle': true,
  chart: true,
  'pie-chart': true,
};
const pascalLineTypes = transform(
  lineTypes,
  (acc, _, key) => {
    acc[key] = underdash.stringToPascalCase(key);
    return acc;
  },
  {},
);
const styleMapping = {
  alignment: {
    center: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    right: {
      marginLeft: 'auto',
      marginRight: '0',
    },
  },
};

class Skeleton extends Component<Props> {
  static defaultProps = {
    lines: 3,
    linesConfig: [],
  };

  // Don't update skeleton as it's should be static
  shouldComponentUpdate() {
    return false;
  }

  renderDefault() {
    const renderedLines: any = [];
    const { lines } = this.props;

    for (let linesLeft: number = lines; linesLeft !== 0; linesLeft--) {
      renderedLines.push(
        this.renderRow(
          {
            type: 'text',
            options: {},
          },
          linesLeft,
        ),
      );
    }

    return renderedLines;
  }

  renderFromConfig() {
    const { linesConfig } = this.props;
    return linesConfig.map(this.renderRow);
  }

  renderRow = (line: LineConfig, idx: number) => {
    const { type: lcType, options: config } = line;
    const style = transform(
      config as any,
      (acc, value, key) => {
        const styleMap = styleMapping[key];

        if (!styleMap) {
          return (acc[key] = value);
        }

        const styleMapValue = styleMap[value];

        if (styleMapValue) {
          forOwn(styleMap[value], (item, itemKey) => {
            acc[itemKey] = item;
          });
        }

        return acc;
      },
      {
        display: 'block',
      },
    );
    const SkeletonType = SkeletonTypes[pascalLineTypes[lcType]];

    if (SkeletonType) {
      return <SkeletonType key={idx} style={style} />;
    }

    const Type = pascalLineTypes[lcType];
    if (lineTypes[lcType]) {
      return <Type key={idx} style={style as any} />;
    }

    return null;
  };

  renderRows() {
    const { linesConfig } = this.props;
    return linesConfig.length ? this.renderFromConfig() : this.renderDefault();
  }

  render() {
    const { className } = this.props;
    return (
      <div data-testid="skeletonWrapper" className={cn('skeleton-wrapper', className)}>
        {this.renderRows()}
      </div>
    );
  }
}

export default Skeleton;
