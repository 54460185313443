import uniqBy from 'lodash/uniqBy';
import { SelectItem } from 'Components/AccSelect';
import { t } from 'Utilities/i18n';

type TagOption = {
  label: string;
  value: string;
  group: string;
  isDynamic: boolean;
};
export const getTagsOptions = (
  tagsResponse: any,
  domainIds?: string[],
  onlyDynamic?: boolean,
  excludeDynamic?: boolean,
) => {
  const options = domainIds?.length
    ? tagsResponse?.domainsList.filter((val) => domainIds?.includes(val.id))
    : tagsResponse?.domainsList;

  if (!options) {
    return [];
  }

  // Get tags from all domains in group, typically only one domain when you are visiting that domain and not a group
  // Prioritize dynamic tags over static tags
  const tagOptions: TagOption[] = options
    .map((domainTags) => {
      if (!domainTags?.tags && !domainTags?.dynamicTags) {
        return [];
      }
      const dynamicTagOptions =
        domainTags?.dynamicTags?.map((tag) => ({
          label: tag,
          value: tag,
          group: t('Dynamic Tags'),
          isDynamic: true,
        })) || [];

      if (onlyDynamic) return dynamicTagOptions;

      const staticTagOptions =
        domainTags?.tags?.map((tag) => ({
          // Only include tags not included as dynamic tags
          label: tag,
          value: tag,
          group: t('Tags'),
          isDynamic: false,
        })) || [];

      return [...dynamicTagOptions, ...staticTagOptions];
    })
    .flat()
    .sort((a, b) => a.group === t('Dynamic Tags') && a.label.localeCompare(b.label));

  if (excludeDynamic) {
    return tagOptions.filter((tag) => !tag?.isDynamic);
  }

  // Duplicate values in multiselect cause havoc. Tag will be shown as either static or dynamic even if it is both
  return uniqBy(tagOptions as SelectItem<string>[], 'value');
};
