import { IconChess } from '@tabler/icons-react';
import { FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import type { FilterBase, TopCompetitorFilter } from 'Types/Filter';
import { t } from 'Utilities/i18n';
import TopCompetitorEditor from './index';

const defaultValue: TopCompetitorFilter = {
  attribute: FilterAttribute.TOP_COMPETITOR,
  type: FilterValueType.ARRAY,
  comparison: FilterComparison.ANY,
  value: [],
};

const getData = () => {
  return {
    defaultValue,
    title: t('Top Competitor'),
    icon: IconChess,
    editor: TopCompetitorEditor,
    labelFunc: (filter: FilterBase) =>
      `'${(filter.value as string[])?.map((x) => x || t('Local Result')).join(t('\' or \''))}'`,
  };
};

export default getData;
