import { useDispatch, useSelector } from 'react-redux';
import { IconEdit } from '@tabler/icons-react';
import { updateFilterGroupFilters } from 'Actions/FilterAction';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import AccButton from 'Components/AccButton/AccButton';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import generateCreateUserFilterInput from 'Components/Filters/generateCreateUserFilterInput';
import { useUpdateUserFilterMutation } from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import toast from 'Hooks/useToast';
import { isRequiredFilter } from 'Types/FilterSet';
import { StoreType } from 'Types/Store';
import { TrackingKey } from 'Utilities/Analytics/mixpanel';
import { t } from 'Utilities/i18n/index';
import styleVariables from 'css/base/variables.module.scss';

type Props = {
  compact?: boolean;
};

const UpdateFilter = (props: Props) => {
  const dispatch = useDispatch();
  const { showModal } = useModal();
  const { filterGroup, filterSet } = useSelector((state: StoreType) => ({
    filterGroup: state.filter.filterGroup,
    filterSet: state.filter.filterSet,
  }));
  const [updateUserFilter] = useUpdateUserFilterMutation();
  const onUpdate = () => {
    const { name, filters, id } = filterGroup;
    const availableFilters = filters.filter(
      (filter) => !isRequiredFilter(filter.attribute, filterSet),
    );
    const updateUserFilterInput = generateCreateUserFilterInput('', availableFilters, filterSet, {
      name,
    });
    updateUserFilter({
      variables: {
        input: {
          id,
          ...updateUserFilterInput,
        },
      },
    }).then((res) => {
      const { errors } = res?.data?.updateFilter || {};
      if (errors && errors.length) {
        toast.error(t('Unable to update segment'));
        return;
      }

      toast.success(t('Segment updated'));
      dispatch(updateFilterGroupFilters(id, availableFilters));
    });
  };

  const showModalOnUpdate = () => {
    showModal({
      modalType: 'Confirmation',
      modalProps: {
        lockDuration: 0,
        title: t('Update Segment?'),
        description: t('The segment will be permanently updated.'),
        confirmLabel: t('Update segment'),
        cancelLabel: t('Cancel'),
        action: onUpdate,
      },
    });
  };

  return !props.compact ? (
    <AccButton
      variant="tertiary"
      onClick={showModalOnUpdate}
      leftSection={<IconEdit size={18} />}
      trackingKey={TrackingKey.UpdateSegment}
    >
      {t('Update segment')}
    </AccButton>
  ) : (
    <AccTooltip tooltip={t('Update segment')}>
      <AccActionIcon variant="subtle" onClick={showModalOnUpdate} c={styleVariables.snorlax4}>
        <IconEdit size={18} />
      </AccActionIcon>
    </AccTooltip>
  );
};

export default UpdateFilter;
