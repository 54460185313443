import { Dispatch, SetStateAction } from 'react';
import { Collapse } from '@mantine/core';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import cn from 'classnames';
import { observer } from 'mobx-react';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { useTableStore } from 'Components/DataTable';
import { CountByCountryNode } from 'Ghql';
import { TableIDs } from 'Types/Table';
import { t } from 'Utilities/i18n';
import { ResearchPanel } from '../ResearchPanel';
import styles from '../../KeywordDiscovery.module.scss';

type ResearchDashboardProps = {
  /**Selected country value with fallback */
  countryChoice: string | undefined;
  selectedCountry: CountByCountryNode | undefined;
  tableType: typeof TableIDs.KEYWORD_DISCOVERY | typeof TableIDs.RESEARCH;
  domainIsSelected: boolean;
  selectedCount: number;
  openedResearchPanel: boolean;
  setOpenedResearchPanel: Dispatch<SetStateAction<boolean>>;
  keywordsCardDomainSlot: JSX.Element | undefined;
  setDomain?: (
    event: Highcharts.SeriesLegendItemClickEventObject,
    domain: string | undefined,
  ) => void;
};

//observer will make the component rerender when tablestore updates
export const ResearchDashboard = observer(
  ({
    countryChoice,
    selectedCountry,
    tableType,
    domainIsSelected,
    selectedCount,
    openedResearchPanel,
    setOpenedResearchPanel,
    keywordsCardDomainSlot,
    setDomain,
  }: ResearchDashboardProps) => {
    const tableStore = useTableStore(tableType);
    const maxKeywords = tableStore?.totalDataLength || 0;
    const tableStoreLoading = tableStore?.loading;
    const disableButton = tableStoreLoading || !selectedCount || selectedCount > maxKeywords;

    return (
      <div className={styles.collapseContainer}>
        <AccTooltip
          delay={0}
          tooltip={openedResearchPanel ? t('Hide this panel') : t('Show more details')}
        >
          <button
            onClick={() => {
              setOpenedResearchPanel((opened) => !opened);
            }}
            className={cn(styles.researchDashboardToggleButton, {
              [styles.opened]: openedResearchPanel,
            })}
            aria-label={
              openedResearchPanel
                ? t('Hide the quick actions research panel')
                : t('Show the quick actions research panel')
            }
          >
            {openedResearchPanel ? <IconChevronUp /> : <IconChevronDown />}
          </button>
        </AccTooltip>
        <Collapse aria-hidden={!openedResearchPanel} in={openedResearchPanel} w="100%">
          <ResearchPanel
            disabledKeywordImport={disableButton}
            numberOfSelectedKeywords={selectedCount}
            numberOfavailableKeywords={maxKeywords}
            countryChoice={countryChoice}
            selectedCountry={selectedCountry ?? undefined}
            domainIsSelected={domainIsSelected}
            tableType={tableType}
            setDomain={setDomain}
            keywordsCardDomainSlot={keywordsCardDomainSlot}
          />
        </Collapse>
      </div>
    );
  },
);
