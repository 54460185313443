import { getRoutePath } from 'Utilities/Router/helpers';
import { useSmartRouterSubscription } from '../components/LocationPathnameContext';

export type RouteMatch = {
  params?: Record<string, string | undefined>;
  path: string;
};

export const useRouteMatch = (): RouteMatch => {
  return useSmartRouterSubscription((pathname, paramsMap) => {
    return {
      path: getRoutePath(pathname, paramsMap),
      params: paramsMap,
    };
  });
};
