import { Box } from '@mantine/core';
import { Field, Fields } from 'redux-form';
import { TopCompetitorFieldInput } from 'Components/Filters/Editors/TopCompetitor/components/TopCompetitorFieldInput';
import FilterCountField from 'Components/Filters/FilterCountField';
import FieldLoader from '../FieldLoader';
import { useTopCompetitorData } from './support/hooks';

const TopCompetitorEditor = () => {
  const { loading } = useTopCompetitorData();
  if (loading) return <FieldLoader />;

  return (
    <Box pos="relative">
      <Fields
        names={['comparison', 'value', 'type', 'attribute']}
        component={FilterCountField}
        pos="absolute"
        right="28px"
        top="11px"
      />
      <Field name="value" component={TopCompetitorFieldInput} />
    </Box>
  );
};

export default TopCompetitorEditor;
