import noop from 'lodash/noop';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { useModal } from 'Hooks/base/useModal';
import { t } from 'Utilities/i18n';
import EditNoteForm from './EditNoteForm';

type Props = {
  noteId: string;
  onBack?: () => void;
  refresh?: () => void;
  enableEditingKeywords: boolean;
  defaultEditMode?: boolean;
};

const EditNote = ({ noteId, onBack, refresh = noop, enableEditingKeywords }: Props) => {
  const { hideModal } = useModal();
  return (
    <ModalBorder className="edit-note" title={t('Update Note')} onClose={hideModal}>
      <EditNoteForm
        noteId={noteId}
        onBack={onBack}
        hideModal={hideModal}
        refresh={refresh}
        defaultEditMode={true}
        enableEditingKeywords={enableEditingKeywords}
      />
    </ModalBorder>
  );
};

export default EditNote;
