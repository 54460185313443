import ColumnChecklistEditor from 'Components/Filters/Common/ColumnChecklistEditor';
import { someOfOptionsWithChildren } from 'Components/Filters/Common/labelFunc';
import {
  GOOGLE_SEARCH_ENGINE_ID,
  YOUTUBE_SEARCH_ENGINE_ID,
  getSerpFeatureData,
  getSerpFeaturesGroups,
} from 'Components/Table/TableRow/SERPOptions/data';
import { FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import type { PageSerpFeaturesFilter } from 'Types/Filter';
import { t } from 'Utilities/i18n/index';
import Icon from 'icons/window.svg?inline';
import { store } from '../../../../Store';
import './serp_features.scss';

const defaultValue: PageSerpFeaturesFilter = {
  attribute: FilterAttribute.PAGE_SERP_FEATURES,
  type: FilterValueType.ARRAY,
  comparison: FilterComparison.ANY,
  value: [],
};

const getData = () => {
  const state = store.getState();
  const featureNaver = state.user.organization?.activePlan?.featureNaver;

  const items = [
    {
      value: '1',
      label: featureNaver ? t('Google / Naver') : t('Google'),
      children: getSerpFeaturesGroups(GOOGLE_SEARCH_ENGINE_ID)?.map((s) => {
        const serp = getSerpFeatureData(s?.id || '');
        return {
          label: serp.label,
          value: serp.id,
          Icon: serp.icon,
        };
      }),
    },
    {
      value: '2',
      label: t('YouTube'),
      children: getSerpFeaturesGroups(YOUTUBE_SEARCH_ENGINE_ID)?.map((s) => {
        const serp = getSerpFeatureData(s?.id || '');
        return {
          label: serp.label,
          value: serp.id,
          Icon: serp.icon,
        };
      }),
    },
  ];
  return {
    defaultValue,
    title: t('SERP Features'),
    icon: Icon,
    isLargeFilter: true,
    editor: ColumnChecklistEditor,
    editorProps: {
      items,
      showSearchBox: true,
      className: 'serp_features',
    },
    labelFunc: someOfOptionsWithChildren(items),
  };
};

export default getData;
