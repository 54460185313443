import { ColumnSetting } from 'Components/DataTable';
import { t } from 'Utilities/i18n';

export const FOLDERS_QUERY_OPTIONS = {
  withId: true,
};

export enum FolderColumnID {
  CHECKBOX = 'checkbox',
  FOLDER = 'folder',
  KEYWORDS = 'keywords',
  DEVICE = 'device',
  INTENT = 'intent',
  ORGANIC_TRAFFIC = 'organic_traffic',
  ORGANIC_TRAFFIC_CHANGE = 'organic_traffic_change',
  DYNAMIC_CTR = 'dynamic_ctr',
  DYNAMIC_CTR_CHANGE = 'dynamic_ctr_change',
  DYNAMIC_CTR_MAX = 'dynamic_ctr_max',
  ABOVE_THE_FOLD = 'above_the_fold',
  SHARE_OF_VOICE = 'share_of_voice',
  SHARE_OF_VOICE_CHANGE = 'share_of_voice_change',
  SEARCH_VOLUME = 'search_volume',
  AVG_RANK_VALUE = 'avg_rank',
  AVG_BASE_RANK = 'avg_base_rank',
  COST_VALUE = 'cost_value',
  AI_TRAFFIC_VALUE = 'ai_traffic_value',
  DYNAMIC_COMPETITOR_POSITION = 'dynamic_competitor_position',
  RANKING_DISTRIBUTION = 'ranking_distribution',
  DISCOVERED_KEYWORDS = 'discovered_keywords',
  DESCRIPTION = 'description',
  WINNERS = 'winners',
  LOSERS = 'losers',
}

export const NaverFolderColumnBlacklist = [
  FolderColumnID.COST_VALUE,
  FolderColumnID.INTENT,
  FolderColumnID.ABOVE_THE_FOLD,
  FolderColumnID.ORGANIC_TRAFFIC,
  FolderColumnID.ORGANIC_TRAFFIC_CHANGE,
  FolderColumnID.DYNAMIC_CTR,
  FolderColumnID.DYNAMIC_CTR_CHANGE,
  FolderColumnID.DYNAMIC_CTR_MAX,
];

export const foldersTable: ColumnSetting[] = [
  {
    id: FolderColumnID.CHECKBOX,
    queryWithClauses: ['id'],
    tableSetting: { required: true },
  },
  {
    id: FolderColumnID.FOLDER,
    queryWithClauses: [FolderColumnID.FOLDER],
    tableSetting: { required: true, getLabel: () => t('Folder') },
  },
  {
    id: FolderColumnID.DESCRIPTION,
    queryWithClauses: [FolderColumnID.DESCRIPTION],
    tableSetting: { required: false, defaultHide: true, getLabel: () => t('Description') },
  },
  {
    id: FolderColumnID.DEVICE,
    queryWithClauses: [FolderColumnID.DEVICE],
    tableSetting: { required: false, defaultHide: true, getLabel: () => t('Device') },
  },
  {
    id: FolderColumnID.KEYWORDS,
    queryWithClauses: [FolderColumnID.KEYWORDS],
    tableSetting: { required: false, getLabel: () => t('Keywords') },
  },
  {
    id: FolderColumnID.DISCOVERED_KEYWORDS,
    queryWithClauses: [FolderColumnID.DISCOVERED_KEYWORDS],
    tableSetting: { defaultHide: false, required: false, getLabel: () => t('Discovered keywords') },
  },
  {
    id: FolderColumnID.DYNAMIC_COMPETITOR_POSITION,
    queryWithClauses: [FolderColumnID.DYNAMIC_COMPETITOR_POSITION],
    tableSetting: { required: false, getLabel: () => t('Market position') },
  },
  {
    id: FolderColumnID.ORGANIC_TRAFFIC,
    queryWithClauses: [FolderColumnID.ORGANIC_TRAFFIC],
    tableSetting: {
      defaultHide: false,
      required: false,
      getLabel: () => t('AI SoV'),
    },
  },
  {
    id: FolderColumnID.SHARE_OF_VOICE,
    queryWithClauses: [FolderColumnID.SHARE_OF_VOICE],
    tableSetting: { defaultHide: true, required: false, getLabel: () => t('SoV') },
  },
  {
    id: FolderColumnID.AVG_RANK_VALUE,
    queryWithClauses: [FolderColumnID.AVG_RANK_VALUE],
    tableSetting: {
      getLabel: () => t('Average Rank'),
    },
  },
  {
    id: FolderColumnID.AVG_BASE_RANK,
    queryWithClauses: [FolderColumnID.AVG_BASE_RANK],
    tableSetting: {
      getLabel: () => t('Avg. Base Rank'),
      defaultHide: true,
    },
  },
  {
    id: FolderColumnID.SEARCH_VOLUME,
    queryWithClauses: [FolderColumnID.SEARCH_VOLUME],
    tableSetting: { required: false, getLabel: () => t('Searches') },
  },
  {
    id: FolderColumnID.AI_TRAFFIC_VALUE,
    queryWithClauses: [FolderColumnID.AI_TRAFFIC_VALUE],
    tableSetting: { required: false, getLabel: () => t('AI Traffic value') },
  },
  {
    id: FolderColumnID.COST_VALUE,
    queryWithClauses: [FolderColumnID.COST_VALUE],
    tableSetting: { required: false, defaultHide: true, getLabel: () => t('Traffic value') },
  },
  {
    id: FolderColumnID.DYNAMIC_CTR,
    queryWithClauses: [FolderColumnID.DYNAMIC_CTR],
    tableSetting: { defaultHide: false, required: false, getLabel: () => t('CTR') },
  },

  {
    id: FolderColumnID.DYNAMIC_CTR_MAX,
    queryWithClauses: [FolderColumnID.DYNAMIC_CTR_MAX],
    tableSetting: { defaultHide: true, required: false, getLabel: () => t('Max CTR') },
  },
  {
    id: FolderColumnID.INTENT,
    queryWithClauses: [FolderColumnID.INTENT],
    tableSetting: { required: false, defaultHide: false, getLabel: () => t('Intent') },
  },

  {
    id: FolderColumnID.WINNERS,
    queryWithClauses: [FolderColumnID.WINNERS],
    tableSetting: { required: false, getLabel: () => t('Winners') },
  },
  {
    id: FolderColumnID.LOSERS,
    queryWithClauses: [FolderColumnID.WINNERS],
    tableSetting: { required: false, getLabel: () => t('Losers') },
  },

  {
    id: FolderColumnID.RANKING_DISTRIBUTION,
    queryWithClauses: [FolderColumnID.RANKING_DISTRIBUTION],
    tableSetting: {
      getLabel: () => t('Ranking distribution'),
      defaultHide: true,
    },
  },
  {
    id: FolderColumnID.ABOVE_THE_FOLD,
    queryWithClauses: [FolderColumnID.ABOVE_THE_FOLD],
    tableSetting: { defaultHide: true, required: false, getLabel: () => t('Above the fold') },
  },
];
