import { useEffect, useState } from 'react';

export const useOpenWithDelay = (
  hideDialog: boolean,
  delay: number,
  disableWhenDialogIsHidden?: boolean,
): boolean => {
  const [opened, setOpened] = useState<boolean>(false);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    if (!hideDialog && !opened) {
      timeout = setTimeout(() => {
        setOpened(true);
      }, delay);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [hideDialog, opened, delay]);
  return hideDialog && disableWhenDialogIsHidden ? true : opened;
};
