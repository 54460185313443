import { Group } from '@mantine/core';
import { IconDeviceDesktop, IconDeviceMobile } from '@tabler/icons-react';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import FormatNumber from 'Components/FormatNumber/new';
import AccText from 'Components/Text/AccText';
import { t } from 'Utilities/i18n';
import { useDomainContext } from './components/DomainContext';

const KeywordsDistribution = () => {
  const { countKeywords, countDesktop, countMobile } = useDomainContext();
  if (!countKeywords) return null;
  const desktop = countDesktop ?? 0;
  const mobile = countMobile ?? 0;
  return (
    <Group c="gray.6" gap={2} wrap="nowrap" justify="center">
      <IconDeviceDesktop size={20} />
      <AccTooltip tooltip={t('Keywords distribution for desktop device.')}>
        <AccText size="sm" lts="-0.02em" c="gray.6">
          <FormatNumber value={desktop / countKeywords} style="percent" />
        </AccText>
      </AccTooltip>
      <IconDeviceMobile size={20} />
      <AccTooltip tooltip={t('Keywords distribution for mobile device.')}>
        <AccText size="sm" lts="-0.02em" c="gray.6">
          <FormatNumber value={mobile / countKeywords} style="percent" />
        </AccText>
      </AccTooltip>
    </Group>
  );
};

export default KeywordsDistribution;
