import { Box, Divider } from '@mantine/core';
import AccPaper from 'Components/AccPaper';
import { ChartLoader } from 'Components/Chart/ChartLoader/ChartLoader';
import Skeleton from 'Components/Skeleton';

const BORDER_RADIUS = '4px';

const DomainCardLoader = () => {
  return (
    <AccPaper px="0" py="0" shadow="sm">
      <Box px="lg" py={8}>
        <Skeleton
          linesConfig={[
            {
              type: 'text',
              options: {
                width: '180px',
                height: '22px',
                marginBottom: '4px',
                borderRadius: BORDER_RADIUS,
              },
            },
            {
              type: 'text',
              options: {
                width: '180px',
                height: '16px',
                borderRadius: BORDER_RADIUS,
              },
            },
          ]}
        />
      </Box>
      <Divider />
      <Box px="lg" py="lg">
        <Skeleton
          linesConfig={[
            {
              type: 'text',
              options: {
                width: '100%',
                height: '16px',
                marginBottom: '12px',
                borderRadius: BORDER_RADIUS,
              },
            },
            {
              type: 'text',
              options: {
                width: '100%',
                height: '24px',
                borderRadius: BORDER_RADIUS,
              },
            },
          ]}
        />
      </Box>
      <Divider />
      <Box px="lg" pt="lg">
        <Skeleton
          linesConfig={[
            {
              type: 'text',
              options: {
                width: '100%',
                height: '12px',
                marginBottom: '8px',
                borderRadius: BORDER_RADIUS,
              },
            },
            {
              type: 'text',
              options: {
                width: '100%',
                height: '14px',
                marginBottom: '8px',
                borderRadius: BORDER_RADIUS,
              },
            },
            {
              type: 'text',
              options: {
                width: '100%',
                height: '12px',
                marginBottom: '15px',
                borderRadius: BORDER_RADIUS,
              },
            },
          ]}
        />
        <Divider />
        <Box mt={12} mb={8}>
          <ChartLoader loading={true} loaderType="line" height={130} />
        </Box>
      </Box>
      <Divider />
      <Box h={36} pt={8} style={{ background: '#f8f8f8' }}>
        <Skeleton
          linesConfig={[
            {
              type: 'text',
              options: {
                width: '82px',
                height: '20px',
                marginBottom: '8px',
                marginLeft: '20px',
                borderRadius: BORDER_RADIUS,
              },
            },
          ]}
        />
      </Box>
    </AccPaper>
  );
};
export default DomainCardLoader;
