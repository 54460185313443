import { useSelector } from 'react-redux';
import {
  BillingPricingPlanCalculatedQuery,
  BillingPricingPlanQuery,
  useBillingPricingPlanCalculatedQuery,
  useBillingPricingPlanQuery,
} from 'Ghql';
import { useRouteMatch } from 'Utilities/Router/hooks/useRouteMatch';

export type PricingPlan = BillingPricingPlanQuery['pricingPlan'];

export type CalculatedPlan = BillingPricingPlanCalculatedQuery['pricingPlanCalculated'];

/**
 * This function calculates and returns the total value of all applicable discounts for a given plan.
 *
 * It mirrors the logic found in the backend component located at grank.apps.billing.utils.BillingHelper.get_total_price_excl_vat.
 *
 * The primary purpose of this function is to facilitate GA4 tracking.
 */
function getDiscount(calculatedPlan: CalculatedPlan) {
  const discount =
    (calculatedPlan?.receivable ?? 0) +
    (calculatedPlan?.signonDiscount ?? 0) +
    (calculatedPlan?.couponDiscount ?? 0) +
    (calculatedPlan?.purseCredit ?? 0) +
    (calculatedPlan?.keywordOveruseCredit ?? 0) +
    (calculatedPlan?.subAccountDiscount ?? 0);
  return discount;
}

/**
 * This function calculates and returns the total price of a plan after applying all applicable discounts, excluding VAT.
 *
 * The primary purpose of this function is to facilitate GA4 tracking.
 */
export function getDiscountedPrice(calculatedPlan: CalculatedPlan) {
  const price = (calculatedPlan?.price ?? 0) - getDiscount(calculatedPlan);
  return price;
}

/**
 * This function returns an object that can be used as an item in a GA4 ecommerce event.
 *
 * See https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#begin_checkout_item
 */
export function getGa4Item(calculatedPlan: CalculatedPlan, pricingPlan: PricingPlan) {
  const discount = getDiscount(calculatedPlan);
  const price = calculatedPlan?.price;
  const item_id = pricingPlan?.id;
  const item_name = pricingPlan?.name;

  return { discount, price, item_id, item_name, quantity: 1 };
}

export type WithPricingPlanAndCalculatedPlanProps = {
  pricingPlan?: PricingPlan;
  calculatedPlan?: CalculatedPlan;
  loading: boolean;
};

export const withPricingPlanAndCalculatedPlan = <P extends object>(
  WrappedComponent: React.ComponentType<WithPricingPlanAndCalculatedPlanProps & P>,
) => {
  const WithPricingPlanAndCalculatedPlanWrapper: React.FC<P> = (props) => {
    const { params } = useRouteMatch();

    const { data: dataPlan, loading: loadingPlan } = useBillingPricingPlanQuery({
      variables: { id: params?.id ?? '' },
      skip: !params?.id,
    });

    const form = useSelector((state: Record<string, any>) => state.form.CompanyInfoForm);
    const countryId = form?.values?.country?.value;

    const { data: dataCalculated, loading: loadingCalculated } =
      useBillingPricingPlanCalculatedQuery({
        variables: {
          id: params?.id ?? '',
          billingCycle: parseInt(params?.cycle ?? ''),
          countryId,
        },
        skip: !params?.id || !params?.cycle || !countryId,
      });

    const loading = loadingPlan || loadingCalculated || !dataPlan || !dataCalculated || !countryId;

    // Determine if we should wait for the queries to finish before rendering the component
    // If we are on the checkout page, we should wait for the queries to finish
    // Otherwise, they are skipped (because of no id and cycle route params), so we should not wait
    const onCheckoutPage = window.location.pathname.includes('/app/checkout/');
    return onCheckoutPage ? (
      <WrappedComponent
        {...props}
        loading={loading}
        pricingPlan={dataPlan?.pricingPlan}
        calculatedPlan={dataCalculated?.pricingPlanCalculated}
      />
    ) : (
      <WrappedComponent {...props} loading={false} />
    );
  };
  return WithPricingPlanAndCalculatedPlanWrapper;
};
