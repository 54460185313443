import { Flex } from '@mantine/core';
import AccButton from 'Components/AccButton/AccButton';
import SimpleModal from 'Components/SimpleModal';
import { t, tct } from 'Utilities/i18n';

export { UNCATEGORIZED_FOLDER_ID } from '../support/constants';

export const DeleteFolderModal = ({
  nodeId,
  nodeName,
  opened,
  setOpened,
  isFolder,
  onDelete,
}: any) => {
  return (
    <SimpleModal
      opened={opened}
      setOpened={setOpened}
      title={isFolder ? t('Delete folder') : t('Delete tag')}
    >
      {isFolder
        ? tct(
            'You are about to delete the folder "[folderName]". All folders and tags within this folder will be moved to the folder "Uncategorized".',
            { folderName: nodeName },
          )
        : tct(
            'You are about to delete the tag "[tagName]". The tag will no longer appear on any of your keywords.',
            {
              tagName: nodeName,
            },
          )}
      <Flex mt={'sm'} justify={'right'} gap={'sm'}>
        <AccButton variant={'tertiary'} onClick={() => setOpened(false)}>
          {t('Cancel')}
        </AccButton>
        <AccButton
          variant="primary"
          onClick={() => {
            onDelete(nodeId);
            setOpened(false);
          }}
        >
          {t('Delete')}
        </AccButton>
      </Flex>
    </SimpleModal>
  );
};
