import * as React from 'react';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import Skeleton from 'Components/Skeleton';
import { formatDomain } from 'Utilities/format';
import { t } from 'Utilities/i18n';
import { graphqlOK } from 'Utilities/underdash';

type Props = {
  domainIds: string[];
  data: Record<string, any>;
};

class DomainsLabel extends React.Component<Props> {
  render() {
    if (!graphqlOK(this.props)) {
      return (
        <Skeleton
          linesConfig={[
            {
              type: 'text',
              options: {
                width: '120px',
                marginBottom: '10px',
              },
            },
          ]}
        />
      );
    }
    const {
      data: { domainsList: domains },
      domainIds,
    } = this.props;
    const domainsMap = domains.reduce((acc, domain) => {
      acc[domain.id] = domain;
      return acc;
    }, {});

    return (
      <span>
        {domainIds
          .map((domainId) =>
            domainId in domainsMap
              ? formatDomain(domainsMap[domainId])
              : t('Domain not on this account'),
          )
          .join(', ')}
      </span>
    );
  }
}

const domainsQuery = gql`
  query domainsLabel_domainsList {
    domainsList {
      id
      domain
      displayName
    }
  }
`;
export default graphql(domainsQuery)(DomainsLabel as any) as any;
