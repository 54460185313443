import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Stack } from '@mantine/core';
import { Severity, captureException, captureMessage } from '@sentry/react';
import { FORM_ERROR } from 'final-form';
import AccButton from 'Components/AccButton/AccButton';
import { Field, Form } from 'Components/Fields';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import { AppLanguage, getLanguageOptions } from 'Constants/i18n';
import { ReportType, getReportTypeOptions } from 'Constants/report';
import { useCompetitorsReportForm_CreateReportMutationMutation } from 'Ghql';
import { useFilters } from 'Hooks';
import { useModal } from 'Hooks/base/useModal';
import { getDefaultEmailBody, getDefaultEmailSubject } from 'Pages/ScheduledReportBuilder/data';
import { DOMAINS, stringifyFilters } from 'Types/Filter';
import { StoreType } from 'Types/Store';
import { t } from 'Utilities/i18n';
import { downloadFile } from 'Utilities/underdash';
import Validator from 'Utilities/validation';
import {
  SubscriptionCallback,
  SubscriptionDataGeneratedReportOtherType,
  subscribeToGeneratedReport,
} from 'Utilities/websocket';

type Props = {
  domainId: string;
  createReportMutation: ReturnType<typeof useCompetitorsReportForm_CreateReportMutationMutation>[0];
  nextModalType: string;
  dataField: string;
  generatingReport?: boolean;
};

export default function StandardReportForm({
  domainId,
  createReportMutation,
  nextModalType,
  dataField,
  generatingReport,
}: Props) {
  const [scheduledReportId, setScheduledReportId] = useState(0);
  const [reportURL, setReportURL] = useState<string | null>(null);
  const [reportIsGoogleSheets, setReportIsGoogleSheets] = useState(false);
  const [handleDownloadError, setHandleDownloadError] = useState('');

  const languageOptions = getLanguageOptions();
  const userLanguage = useSelector((state: StoreType) => state.user.language) as AppLanguage;
  languageOptions.sort((a, _) => (a.value === userLanguage ? -1 : 1));

  const reportTypeOptions = getReportTypeOptions().filter((option) =>
    [ReportType.EXCEL, ReportType.CSV, ReportType.GOOGLE_SHEETS].includes(option.id),
  );

  const userHasGoogleConnection = useSelector(
    (state: StoreType) =>
      !!state.user.googleConnections && state.user.googleConnections?.length > 0,
  );

  const { showModal } = useModal();

  const handleDownload: SubscriptionCallback = ({ data }) => {
    const other = data.other as SubscriptionDataGeneratedReportOtherType | undefined;
    if (scheduledReportId !== other?.scheduled_report || !other?.url) {
      captureMessage(
        `${scheduledReportId} !== ${other?.scheduled_report} || ${!other?.url}`,
        Severity.Critical,
      );
      setHandleDownloadError(
        t('An error occurred. Please report it to customer support. Sorry for the inconvenience.'),
      );
      return;
    }
    setReportURL(other?.url);
    if (other?.report_type === ReportType.GOOGLE_SHEETS) {
      setReportIsGoogleSheets(true);
      // early return because shouldn't download Google Sheets URL
      return;
    }
    downloadFile(other?.url);
  };

  useEffect(() => {
    if (scheduledReportId === 0) {
      return;
    }
    const generatedReportSub = subscribeToGeneratedReport(handleDownload);
    return () => {
      generatedReportSub.unsubscribe();
    };
  }, [scheduledReportId]);

  type TypedFields = {
    reportType: ReportType;
    language: AppLanguage;
    emails: string[];
  };

  const { hideModal } = useModal();

  const filters = useFilters();
  const noDomainsFilters = filters.filter((filter) => filter.attribute !== DOMAINS);

  const handleSubmit = async ({
    reportType,
    language,
    emails,
  }: {
    reportType: ReportType;
    language: AppLanguage;
    emails: string[];
  }) => {
    try {
      const { data, errors } = await createReportMutation({
        variables: {
          input: {
            reportType,
            emailSubject: getDefaultEmailSubject(),
            emailBody: getDefaultEmailBody(),
            language,
            recipients: emails ?? [],
            filters: stringifyFilters(noDomainsFilters),
            domainId,
          },
        },
        fetchPolicy: 'network-only',
      });

      if (errors && errors.length > 0) {
        captureMessage(JSON.stringify(errors), Severity.Critical);
        return {
          [FORM_ERROR]: t(
            'An error occurred. Please report it to customer support. Sorry for the inconvenience.',
          ),
        };
      }

      if (reportType === ReportType.GOOGLE_SHEETS && !userHasGoogleConnection) {
        showModal({
          modalType: 'ConnectToDrive',
          modalTheme: 'light',
          modalProps: {
            message: t(
              'You do not have a Google Drive connection setup with AccuRanker. Please connect to your Google account to allow AccuRanker to create spreadsheet reports. AccuRanker will only have access to the files it creates, and cannot read other files.',
            ),
            nextModalType,
            lastState: data,
            domainId,
          },
        });
      }
      const report_id = data?.[dataField]?.scheduledReport?.id;
      if (!report_id) {
        throw new Error(`Bad report_id '${report_id}' in mutation response`);
      }
      setScheduledReportId(parseInt(report_id));
    } catch (e) {
      captureException(e);
      return {
        [FORM_ERROR]: t(
          'An error occurred. Please report it to customer support. Sorry for the inconvenience.',
        ),
      };
    }
  };

  return (
    <Form<TypedFields> onSubmit={handleSubmit}>
      {({ submitting, submitSucceeded, submitError }) => (
        <>
          {handleDownloadError && <div className="alert alert-danger">{handleDownloadError}</div>}

          {submitError && <div className="alert alert-danger">{submitError}</div>}

          {!submitSucceeded && (
            <Stack gap={'md'}>
              <Field.Select
                key="reportType"
                name="reportType"
                label={t('Report type')}
                options={reportTypeOptions}
                useFirstOptionAsDefault
                validate={Validator.required}
                searchable={false}
              />
              <Field.Select
                key="language"
                name="language"
                options={languageOptions}
                label={t('Language')}
                useFirstOptionAsDefault
                validate={Validator.required}
                searchable={false}
              />
              <Field.EmailsInput
                key="emails"
                name="emails"
                label={t('Email to')}
                validate={Validator.emails}
                //Do not include the word "email" in the placeholder - as this will trigger password managers to suggest email addresses
                placeholder={t('You can enter multiple addresses')}
              />
              <ModalFooter
                primaryButtonSlot={
                  <AccButton
                    type="submit"
                    variant="primary"
                    disabled={submitting}
                    loading={submitting}
                  >
                    {t('Download')}
                  </AccButton>
                }
                secondaryButtonSlot={
                  <AccButton variant="tertiary" onClick={hideModal}>
                    {t('Cancel')}
                  </AccButton>
                }
              />
            </Stack>
          )}

          {submitSucceeded && !reportURL && (
            <>
              <p>{t('Your report is being generated. Please wait…')}</p>
              <p>
                {t(
                  'Please note that reports are queued up and can take a few minutes to complete.',
                )}
              </p>
              <p>
                {t(
                  'If nothing happens, your browser may be blocking the download. You can probably find your report in the ',
                )}
                <Link to="/reports/generated">{t('generated reports tab.')}</Link>
              </p>
              <ModalFooter
                primaryButtonSlot={
                  <AccButton
                    type="submit"
                    variant="primary"
                    disabled={submitting || generatingReport}
                    loading={submitting || generatingReport}
                  >
                    {t('Download')}
                  </AccButton>
                }
                secondaryButtonSlot={
                  <AccButton variant="tertiary" onClick={hideModal}>
                    {t('Cancel')}
                  </AccButton>
                }
              />
            </>
          )}

          {submitSucceeded && reportURL && (
            <>
              <div className="alert alert-info">
                {reportIsGoogleSheets
                  ? t(
                      'Your report is ready. To view it, please click the \'Open Google Sheets\' button below.',
                    )
                  : t(
                      'Your report is ready. The download should have started automatically. If it hasn\'t, please click the download button below.',
                    )}
              </div>
              <ModalFooter
                primaryButtonSlot={
                  <AccButton variant="primary" href={reportURL}>
                    {reportIsGoogleSheets ? t('Open Google Sheets') : t('Download')}
                  </AccButton>
                }
              />
            </>
          )}
        </>
      )}
    </Form>
  );
}
