import { useState } from 'react';
import { Flex } from '@mantine/core';
import { IconDotsVertical } from '@tabler/icons-react';
import AccLazyPopover from 'Components/AccLazyPopover/AccLazyPopover';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import AccText from 'Components/Text/AccText';
import { t } from 'Utilities/i18n';
import SerpPopover from './HelperComponents/SerpPopOver/SerpPopover';
import { SerpFeaturesItem } from './HelperComponents/SerpPopOver/types';

type Props = {
  count: number;
  serpFeaturesData: SerpFeaturesItem[];
  searchEngineId: number;
};

export const SerpDropdown = ({ count, serpFeaturesData, searchEngineId }: Props) => {
  const [active, setActive] = useState(false);

  return (
    <Flex ml={'auto'}>
      <AccLazyPopover
        target={
          <AccTooltip tooltip={t('Show all SERP Features')} disable={active}>
            {count === 0 ? (
              <span className="serp-icon-container" data-active={active}>
                {count === 0 ? <IconDotsVertical size={16} strokeWidth={2} /> : +{ count }}
              </span>
            ) : (
              <AccText size={'xs'} className="serp-icon-container" data-active={active}>
                +{count}
              </AccText>
            )}
          </AccTooltip>
        }
        onToggle={() => setActive(!active)}
      >
        {() => <SerpPopover serpFeatures={serpFeaturesData} searchEngineId={searchEngineId} />}
      </AccLazyPopover>
    </Flex>
  );
};
