import { useCallback } from 'react';
import { useSelector, useStore } from 'react-redux';
import { setFilters } from 'Actions/FilterAction';
import { setFilteredPath } from 'Components/Filters/helpers';
import { useStandardFilters } from 'Hooks';
import { filtersSelector } from 'Selectors/FiltersSelector';
import { FilterBase, FilterComparison, FilterValueType } from 'Types/Filter';
import { EventName, FilterTrackingKey, useMixpanel } from 'Utilities/Analytics/mixpanel';
import { useRouteMatch } from 'Utilities/Router/hooks/useRouteMatch';
import { useHistory } from 'Utilities/Router/withRouter';
import { useActions } from '../../redux/useActions';

const filterGroupSelector = (state) => state.filter.filterGroup;

const useSetFilteredPath = () => {
  const match = useRouteMatch();
  const history = useHistory();
  return useCallback(
    (filters: FilterBase[], filterGroupId: string) =>
      setFilteredPath(filters, filterGroupId, match, history),
    [match, history],
  );
};

// eslint-disable-next-line import/no-unused-modules
export const useDeleteFiltersWithAttribute = () => {
  const store = useStore();
  const actions = useActions({ setFilters });
  const filterGroup = useSelector(filterGroupSelector);
  const setPath = useSetFilteredPath();

  return useCallback(
    (attribute) => {
      const prevFilters = filtersSelector(store.getState());
      const newFilters = prevFilters.filter((e) => e.attribute !== attribute);
      actions.setFilters(newFilters);
      setPath(newFilters, filterGroup.id);
    },
    [store, actions.setFilters],
  );
};

export const useResetFilters = () => {
  const standardFilters = useStandardFilters();
  const actions = useActions({ setFilters });
  const filterGroup = useSelector(filterGroupSelector);
  const setPath = useSetFilteredPath();

  return useCallback(() => {
    actions.setFilters(standardFilters as FilterBase[]);
    setPath(standardFilters, filterGroup.id);
  }, [actions.setFilters, setPath]);
};

/**
 * Set or overwrite a specific filter
 * @example
 * const setFilter = useSetOrOverwriteFilter();
 * //---
 * setFilter({
        attribute: FilterAttribute.DOMAINS,
        type: FilterValueType.LIST,
        comparison: FilterComparison.CONTAINS,
        value: ['value'],
      }, TrackingKey.fiterBar);
 */
export const useSetOrOverwriteFilter = () => {
  const store = useStore();
  const actions = useActions({ setFilters });
  const filterGroup = useSelector(filterGroupSelector);
  const setPath = useSetFilteredPath();
  const trackEvent = useMixpanel();

  return useCallback(
    (
      newFilter: FilterBase,
      trackingKey: FilterTrackingKey | 'no tracking',
      scrollToTop = false,
    ) => {
      const prevFilters = filtersSelector(store.getState());
      const newFilters = prevFilters
        .filter((e) => e.attribute !== newFilter.attribute)
        .concat(newFilter);
      actions.setFilters(newFilters);
      trackingKey !== 'no tracking' &&
        trackEvent(EventName.AddFilter, {
          Attribute: `${newFilter.attribute}`,
          Type: newFilter.type,
          Comparison: newFilter.comparison,
          Value: `${newFilter.value}`,
          'Tracking Key': trackingKey,
        });
      setPath(newFilters, filterGroup.id);
      if (scrollToTop) {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      }
    },
    [store, actions, filterGroup.id, setPath, trackEvent],
  );
};

/**
 * Add a specific filter. If there already is one of these filters, it will add a second
 * @example
 * const addFilter = useAddFilter();
 * //---
 * addFilter({
        attribute: FilterAttribute.KEYWORD,
        type: FilterValueType.STRING,
        comparison: FilterComparison.EQ,
        value: 'value',
      }, TrackingKey.fiterBar);
 */
export const useAddFilter = () => {
  const store = useStore();
  const actions = useActions({ setFilters });
  const filterGroup = useSelector(filterGroupSelector);
  const setPath = useSetFilteredPath();
  const trackEvent = useMixpanel();

  return useCallback(
    (newFilter: FilterBase, trackingKey: FilterTrackingKey | 'no tracking') => {
      const prevFilters = filtersSelector(store.getState());
      const newFilters = prevFilters.concat(newFilter);
      actions.setFilters(newFilters);
      trackingKey !== 'no tracking' &&
        trackEvent(EventName.AddFilter, {
          Attribute: `${newFilter.attribute}`,
          Type: newFilter.type,
          Comparison: newFilter.comparison,
          Value: `${newFilter.value}`,
          'Tracking Key': trackingKey,
        });
      setPath(newFilters, filterGroup.id);
    },
    [store, actions.setFilters],
  );
};

/**
 * Remove specific filter
 * @example
 * const removeFilter = useRemoveFilter();
 * //---
 * removeFilter(FilterAttribute.KEYWORD);
 */
export const useRemoveFilter = () => {
  const store = useStore();
  const actions = useActions({ setFilters });
  const filterGroup = useSelector(filterGroupSelector);
  const setPath = useSetFilteredPath();
  return useCallback(
    (filterAttribute: string, scrollToTop = false) => {
      const prevFilters = filtersSelector(store.getState());

      if (!prevFilters.some((e) => e.attribute === filterAttribute)) {
        return;
      }

      const newFilters = prevFilters.filter((e) => e.attribute !== filterAttribute);
      actions.setFilters(newFilters);
      setPath(newFilters, filterGroup.id);
      if (scrollToTop) {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      }
    },
    [store, actions.setFilters],
  );
};

export const getUpdatedArrayFilter = (filters, value, attribute) => {
  // Construct new array filter. If there already is a filter with this attribute,
  // we need to add this filter to the existing one.

  const currentFilters = filters?.find((f) => f.attribute === attribute);

  const newFilterValue = currentFilters ? [...currentFilters.value] : [value];
  if (!newFilterValue.includes(value)) {
    newFilterValue.push(value);
  }

  const newFilter: FilterBase = {
    attribute,
    type: FilterValueType.ARRAY,
    comparison: FilterComparison.ALL,
    value: newFilterValue,
  };

  return newFilter;
};
