import { Component } from 'react';
import './alert.scss';

type Props = {
  children: React.ReactNode;
  type: 'danger' | 'info' | 'warning';
  className: string;
  color?: string;
};
export default class Alert extends Component<Props> {
  static defaultProps = {
    children: null,
    type: 'info',
    className: '',
  };

  render() {
    const { children, type, className } = this.props;
    return <div className={`${className} alert-custom alert alert-${type}`}>{children}</div>;
  }
}
