import { useField } from 'react-final-form';
import { getGroupByOptionId } from '../helpers';
import { TableSettingGroup } from '../types';

/**
 * - Change option selection
 * - Select/unselect sub-options
 * - for last selected option in the group - unselect group
 * - for first selected option in the group - it select group
 */
export const useOptionsField = (
  items: TableSettingGroup[],
  onGroupsChange: (id: string, value?: boolean) => void,
) => {
  const { input: inputColumns } = useField('columns');
  const onOptionsStatusChange = (id: string, value?: boolean) => {
    const groupConfig = getGroupByOptionId(items, id);
    const subOptionIds = groupConfig?.options?.find((e) => e.id === id)?.children?.map((e) => e.id);

    const affectedItems = [id].concat(subOptionIds ? subOptionIds : []);

    // checking/unchecking our checkbox and sub-options if exists
    inputColumns.onChange(
      value
        ? [...inputColumns.value, ...(affectedItems || [])]
        : [...inputColumns.value].filter((e) => !affectedItems?.includes(e)),
    );

    if (groupConfig?.id && !groupConfig?.fixed) {
      if (value) {
        const isFirstSelected = groupConfig?.options?.every(
          (e) => !inputColumns.value?.includes(e.id),
        );

        if (isFirstSelected) {
          onGroupsChange(groupConfig?.id, true);
        }
      } else {
        const isLastUnselectedOption = groupConfig?.options?.every(
          (e) => !inputColumns.value?.includes(e.id) || e.id === id,
        );

        if (isLastUnselectedOption) {
          onGroupsChange(groupConfig?.id, false);
        }
      }
    }
  };
  return [inputColumns.value, onOptionsStatusChange];
};
