import { ApolloError } from '@apollo/client';
import { useFilterOptionsQuery } from 'Ghql';
import { useFilters } from 'Hooks';
import { FilterAttribute } from 'Types/Filter';
import { t } from 'Utilities/i18n';

type SearchItemType = {
  id: string;
  value: boolean;
  label: string;
  count?: number;
};

export const getItems = () => {
  return [
    {
      id: '1',
      value: true,
      label: t('More than one rank'),
    },
    {
      id: '0',
      value: false,
      label: t('Zero or one rank'),
    },
  ];
};

export const useExtraRanksData = (): {
  data: SearchItemType[];
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const items = getItems();
  const filters = useFilters();
  const {
    data: optionsData,
    loading: optionsLoading,
    error: optionsError,
  } = useFilterOptionsQuery({
    fetchPolicy: 'cache-first',
    variables: {
      filters: filters.filter((f) => f.attribute !== FilterAttribute.HAS_EXTRA_RANKS),
      isKeydis: false,
      filterType: FilterAttribute.HAS_EXTRA_RANKS,
    },
  });
  const filterOptions = optionsData?.filterData?.filterOptions;
  const options = optionsData?.filterData?.filterOptions === null ? null : filterOptions || [];
  let data = items;
  if (!optionsLoading && !optionsError && options !== null) {
    data = items.reduce((accumulator: SearchItemType[], item) => {
      const count = options.find((o) => o.option === item.id)?.count || 0;
      accumulator.push({
        ...item,
        count,
      });
      return accumulator;
    }, []);
  }

  return { data, loading: optionsLoading, error: optionsError };
};
