import Skeleton, { LineConfig } from 'Components/Skeleton';

const lines: LineConfig[] = [
  {
    type: 'text',
    options: {
      width: '30%',
    },
  },
  {
    type: 'input',
  },
  {
    type: 'text',
    options: {
      width: '30%',
    },
  },
  {
    type: 'input',
  },
  {
    type: 'text',
    options: {
      width: '30%',
    },
  },
  {
    type: 'input',
  },
  {
    type: 'text',
    options: {
      width: '30%',
    },
  },
  {
    type: 'input',
  },
  {
    type: 'button',
    options: {
      width: '15%',
      alignment: 'right',
    },
  },
];

const BuildCompetitorSkeleton = () => (
  <Skeleton className="indented-form-group form-group" linesConfig={lines} />
);

export default BuildCompetitorSkeleton;
