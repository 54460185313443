import { Flex } from '@mantine/core';

const SYMBOLS = {
  circle: {
    symbol: '●',
    sizeFactor: 1,
  },
  diamond: {
    symbol: '◆',
    sizeFactor: 0.8,
  },
  square: {
    symbol: '■',
    sizeFactor: 1,
  },
  triangle: {
    symbol: '▲',
    sizeFactor: 0.7,
  },
  'triangle-down': {
    symbol: '▼',
    sizeFactor: 0.7,
  },
};

type Props = {
  symbol?: string;
  color: string;
  size: number;
};

const HighchartsSymbol = ({ symbol = 'circle', color, size = 16 }: Props) => {
  const currentSymbol = SYMBOLS[symbol] || SYMBOLS.circle;
  // scale the symbols to get the correct visual size
  return (
    <Flex
      display="inline-flex"
      className="chart-tooltip-bullet"
      c={color}
      w={size}
      h={size}
      lh={`${size}px`}
      justify="center"
      align="center"
      style={{ fontSize: size * currentSymbol.sizeFactor, overflow: 'hidden' }}
    >
      {currentSymbol.symbol}
    </Flex>
  );
};

export default HighchartsSymbol;
