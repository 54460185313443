/* eslint-disable react/display-name */
import { useEffect } from 'react';
import AccText from 'Components/Text/AccText';
import { appStorage } from 'Utilities/AppStorage';
import { t } from 'Utilities/i18n';
import { useAddKeywordsFields } from '../hooks';
import { AddKeywordsModalType, AddKeywordsMode } from '../types';
import { KeywordsFieldNoForm } from './KeywordsField';

export default ({ mode, domainId }: { mode: AddKeywordsModalType; domainId: string }) => {
  const fields = useAddKeywordsFields();

  useEffect(() => {
    if (mode === AddKeywordsMode.DEFAULT) {
      const currentKeywords = fields.keywords.input.value;
      if (currentKeywords.length > 0) {
        appStorage.setKeywordModalContent(currentKeywords, domainId);
      }
    }
  }, [domainId, fields.keywords.input.value, mode]);

  return (
    <div>
      <AccText variant="label">{t('Keywords')}</AccText>
      <div style={{ margin: '0.2rem 0 0 0' }}>
        <KeywordsFieldNoForm
          value={fields.keywords.input.value}
          onChange={fields.keywords.input.onChange}
          placeholder={t('Enter your keywords (one per line)')}
          mode={mode}
        />
      </div>
    </div>
  );
};
