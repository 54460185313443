import AccText from 'Components/Text/AccText';
import { MetaDataCountrylocaleNode } from 'Ghql';
import AccTooltip from '../../AccTooltip/AccTooltip';
import Cell from './HelperComponents/AccuCell';

type Props = {
  value: {
    data: {
      countrylocaleId: number;
      location: string;
    };
    loadingData?: boolean;
  };
  countryLocales: MetaDataCountrylocaleNode[];
  compact?: boolean;
};

const LocationCell = (props: Props) => {
  const {
    value: { data, loadingData },
    countryLocales,
    compact,
  } = props;
  // if (loadingData) return <LoadingSpinner />;
  if (loadingData) return '';
  const { countrylocaleId, location } = data;
  let countryLocale;
  let locationToolTip;

  if (countrylocaleId) {
    countryLocale = countryLocales.find((e) => e.id === countrylocaleId);
    // https://accuranker.myjetbrains.com/youtrack/issue/ARR-1301
    locationToolTip = (location || '').concat(
      location?.includes(countryLocale.region) ? '' : `, ${countryLocale.region}`,
    );
  }

  return (
    <Cell>
      {location && !compact && (
        <AccTooltip tooltip={locationToolTip}>
          <AccText overflowEllipsis noOverflowTooltip nowrap={true} size={'xs'}>
            {location}
          </AccText>
        </AccTooltip>
      )}
    </Cell>
  );
};
LocationCell.defaultProps = {
  countryLocales: [],
};
export default LocationCell;
