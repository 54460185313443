import { GradientColorObject, PatternObject } from 'highcharts';

export const tooltipFormatter = (
  value: string,
  color: string | GradientColorObject | PatternObject | undefined,
  name: string | undefined,
) => `<div class="chart-tooltip-table-tr">
                    <div class="chart-tooltip-table-td">
                        <span class="chart-tooltip-bullet" style="color: ${color};">●</span> ${name}
                    </div>
                    <div class="chart-tooltip-table-td chart-tooltip-table-right" style="text-align: right;">
                      ${value}
                    </div>
                  </div>`;
