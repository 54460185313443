import { Box, Center } from '@mantine/core';
import { IconLock } from '@tabler/icons-react';
import AccText from 'Components/Text/AccText';
import AccTitle from 'Components/Title/AccTitle';
import { t } from 'Utilities/i18n';
import '../error-page.scss';

const AccessDenied = () => {
  return (
    <Box className="error-page" mt="xxl">
      <Center mb="lg" c="#a4a6b3">
        <IconLock size={150} stroke={1} />
      </Center>
      <AccTitle type="h1" fw={600} c="gray.7">
        {t('Access Denied')}
      </AccTitle>
      <AccText size="xl" maw={600} mb="xl">
        {t(
          'It appears you don’t have permission to access this page. Please contact your organization administrator to get permission.',
        )}
      </AccText>
    </Box>
  );
};

export default AccessDenied;
