import { useCallback, useEffect, useRef, useState } from 'react';
import { IconCirclePlus } from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import { Field, Form } from 'Components/Fields';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import { useGoaAccountsQuery } from 'Ghql';
import { IntegrationOAuthProviders } from 'Types/Integration';
import { EventName, TrackingKey, useMixpanel } from 'Utilities/Analytics/mixpanel';
import { t, tct } from 'Utilities/i18n/index';
import Validator from 'Utilities/validation';

type Props = {
  handleSubmit: (...args: Array<any>) => any;
  onAdd: (...args: Array<any>) => any;
  onRemove: (...args: Array<any>) => any;
  accountId: string;
  onCancel: (...args: Array<any>) => any;
  submitting?: any;
  invalid?: any;
};

type SelectGoogleAccountFormValues = { goaAccount: string };

const SelectGoogleAccount = (props: Props) => {
  const { handleSubmit, submitting, invalid } = props;

  const [goaAccount, setGoaAccount] = useState<{ label; value }>();

  const { data, loading } = useGoaAccountsQuery();

  const currentAccount = data?.user?.organization?.googleConnections?.find(
    (connection) => props.accountId === connection?.id,
  );

  if (
    data?.user?.organization?.googleConnections?.find(
      (connection) => props.accountId === connection?.id,
    )
  ) {
    setGoaAccount({
      label: currentAccount?.description,
      value: currentAccount?.id,
    });
  }

  const trackEvent = useMixpanel();

  const trackClick = useCallback(
    (eventName: string) => {
      const extraProps = {
        Variant: eventName,
        TrackingKey: TrackingKey.SelectGoogleAccount,
      };
      trackEvent(EventName.ConnectToGSC, extraProps);
    },
    [trackEvent],
  );

  const eventTracked = useRef(false);

  useEffect(() => {
    if (eventTracked.current) {
      return;
    }
    trackClick('Open');
    eventTracked.current = true;
  }, [trackClick]);

  const onSubmit = (value: SelectGoogleAccountFormValues) => {
    handleSubmit({
      connectionId: value.goaAccount,
    });
  };

  const options: { value: string; label: string }[] = loading
    ? []
    : data?.user?.organization?.googleConnections
        ?.filter((item) => item?.type === IntegrationOAuthProviders.GOOGLE_ACCOUNT.type)
        .map((item) => {
          const { id, description } = item || {};
          return {
            value: id || '',
            label: description || t('Google Search Console (%s)', id),
          };
        }) || [];

  if ((options?.length || 0) > 0 || loading) {
    return (
      <Form<SelectGoogleAccountFormValues> onSubmit={onSubmit}>
        <div className="form-label">{t('Select the Google connection you want to use.')}</div>
        <div>
          <Field.Select
            name="goaAccount"
            id="goaAccount"
            type="text"
            placeholder={''}
            disabled={loading}
            isLoading={loading}
            options={options}
            validate={Validator.required}
            useFirstOptionAsDefault
          />
          <AccButton
            mt={'4px'}
            variant="tertiary"
            onClick={props.onAdd}
            leftSection={<IconCirclePlus size={18} />}
          >
            {t('Add new connection')}
          </AccButton>
        </div>

        {props.accountId != null && (
          <div className="alert alert-warning">
            <div className="form-label">
              {tct(
                'This domain is already connected to [account] Google Account, you can connect to another account above or disconnect [link:here].',
                {
                  account: <strong>{goaAccount ? goaAccount.label : null}</strong>,
                  link: (
                    <a style={{ color: '#F89537' }} onClick={props.onRemove} className="link" />
                  ),
                },
              )}
            </div>
          </div>
        )}
        <ModalFooter
          primaryButtonSlot={
            <AccButton variant="primary" type="submit" disabled={loading || invalid || submitting}>
              {t('Select connection')}
            </AccButton>
          }
          secondaryButtonSlot={
            <AccButton variant="tertiary" onClick={props.onCancel}>
              {t('Cancel')}
            </AccButton>
          }
        />
      </Form>
    );
  }

  return (
    <div>
      <p>{t('We could not find a connected Google Account, please set one up first.')}</p>
      <ModalFooter
        primaryButtonSlot={
          <AccButton variant="primary" onClick={props.onAdd}>
            {t('Connect a Google account')}
          </AccButton>
        }
        secondaryButtonSlot={
          <AccButton
            variant="tertiary"
            onClick={() => {
              props.onCancel();
              trackClick('Close');
            }}
          >
            {t('Cancel')}
          </AccButton>
        }
      />
    </div>
  );
};

export default SelectGoogleAccount;
