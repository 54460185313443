import { useMemo } from 'react';
import { Indicator, Stack } from '@mantine/core';
import {
  IconAffiliate,
  IconApi,
  IconCommand,
  IconLogout2,
  IconMail,
  IconNotes,
  IconUser,
} from '@tabler/icons-react';
import AccAvatar from 'Components/AccAvatar';
import AccText from 'Components/Text/AccText';
import { useMessagesDropdownTotalMessagesCountQuery } from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import { useUser } from 'Hooks/data/user/useUser';
import { t } from 'Utilities/i18n';
import { getUserTypeLabel } from 'Utilities/workspaces';
import { useAccountInfoActions } from './useAccountInfoActions';

export const useMenuItems = () => {
  const user = useUser();

  const actions = useAccountInfoActions();

  const { showModal } = useModal();

  const { data: totalMessagesCountData } = useMessagesDropdownTotalMessagesCountQuery({
    skip: !user?.id,
  });
  const totalMessagesCount = totalMessagesCountData?.totalMessagesCount || 0;
  const messageCountLabel = totalMessagesCount > 20 ? '20+' : totalMessagesCount;

  const menuItems = useMemo(() => {
    return [
      {
        label: (
          <Stack gap={0}>
            <div>{user.fullName || t('Profile')}</div>
            <AccText span size="xs" c="gray.4" nowrap>
              {getUserTypeLabel(user.userType)}
            </AccText>
          </Stack>
        ),
        link: '/profile',
        icon: <AccAvatar name={user.fullName || user.email || ''} email={user.email} size={28} />,
      },
      {
        label: t('Edit Profile'),
        link: '/profile',
        icon: <IconUser />,
      },
      {
        label: t('API docs'),
        onSelect: () => window.open('/api/read-docs', '_blank', 'noopener,noreferrer'),
        icon: <IconApi />,
      },
      {
        label: t('Messages'),
        icon: (
          <Indicator
            color="red"
            inline
            disabled={!messageCountLabel}
            label={messageCountLabel}
            size={12}
          >
            <IconMail />
          </Indicator>
        ),
        onSelect: () => showModal({ modalType: 'MessagesNew' }),
      },
      {
        label: t('What\'s new?'),
        onSelect: () =>
          window.open('https://www.accuranker.com/changelog/', '_blank', 'noopener,noreferrer'),
        icon: <IconNotes />,
      },
      {
        label: t('Shortcuts'),
        onSelect: actions.handleShowShortcuts,
        icon: <IconCommand />,
      },
      {
        label: t('Affiliate Program'),
        onSelect: actions.handleAffiliate,
        icon: <IconAffiliate />,
        disabled: !user?.isAffiliate && !user?.isAffiliateAdmin,
      },
      {
        label: t('Sign Out'),
        onSelect: actions.handleSignOut,
        icon: <IconLogout2 />,
      },
    ].filter((e) => !e?.disabled);
  }, [
    user,
    actions.handleShowShortcuts,
    actions.handleAffiliate,
    actions.handleSignOut,
    messageCountLabel,
    showModal,
  ]);

  return { menuItems };
};
