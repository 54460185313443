import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { selectFilterSet, setDefaultFilters, setFilters } from 'Actions/FilterAction';
import type { FilterBase } from 'Types/Filter';
import type { FilterSet } from 'Types/FilterSet';
import { KEYDIS_FILTER_SET, getRequiredFilterAttributes } from 'Types/FilterSet';
import { WithRouter, withRouter } from 'Utilities/Router';
import underdash from 'Utilities/underdash';
import { decodeFilters } from './serialization';

type Props = {
  children: React.ReactNode;
  filterSet?: FilterSet;
  selectFilterSet: (filterSet: FilterSet) => void;
  setDefaultFilters: () => void;
  setFilters: (filters: FilterBase[], segmentId: string) => void;
  filterParam?: string;
} & WithRouter;

class FilterUrlSyncHandler extends PureComponent<Props> {
  lastFilterHash?: string;

  constructor(props) {
    super(props);
    const { filterSet } = this.props;

    if (filterSet) {
      this.props.selectFilterSet(filterSet);
    }

    this.loadFilterFromHash(this.props);
  }

  componentDidUpdate(prevProps) {
    if (this.props.filterSet && this.props.filterSet !== prevProps.filterSet) {
      this.props.selectFilterSet(this.props.filterSet!);
    }

    if (
      this.props.filterParam !== prevProps.filterParam ||
      this.props.filterSet !== prevProps.filterSet
    ) {
      this.loadFilterFromHash(this.props);
    }
  }

  loadFilterFromHash = (props: Props) => {
    const { history, filterParam } = props;
    const dataString = filterParam;

    if (dataString) {
      // Validate filters
      let isValid = underdash.base64IsValidJSON(dataString);

      if (isValid) {
        const data = decodeFilters(dataString);
        isValid = this.hasRequiredFilters(data.filters);
        if (isValid) {
          this.props.setFilters(data.filters, data.segmentId);
          return data.filters;
        }
      }

      console.error('loadFilterFromHash - filters are invalid', filterParam); // eslint-disable-line

      setTimeout(() => {
        // Filters are invalid, reset to homepage
        this.props.setDefaultFilters();
        history.replace('/');
      }, 0);
    } else {
      // No filters provided, use default filters
      this.props.setDefaultFilters();
    }
  };
  // Checks if current filters contains required filters
  hasRequiredFilters = (filters) => {
    const { filterSet } = this.props;

    if (!filterSet) {
      return true;
    }
    if (filterSet === KEYDIS_FILTER_SET) {
      // For keyword research we have a special case.
      // The reason is that it is required to have EITHER a free_text_domain or a domains filter
      // Simple check is to see if there is at least 3 out of 4 required filters.
      const filterAttributes = filters.map((filter) => filter.attribute);
      return (
        getRequiredFilterAttributes(filterSet).filter((attribute) =>
          filterAttributes.includes(attribute),
        ).length >= 3
      );
    }

    return getRequiredFilterAttributes(filterSet).every((attribute) =>
      filters.find((filter) => filter.attribute === attribute),
    );
  };

  render() {
    return this.props.children ?? null;
  }
}

export default withRouter(
  connect(null, {
    setFilters,
    selectFilterSet,
    setDefaultFilters,
  })(FilterUrlSyncHandler),
) as any;
