import { ReactNode } from 'react';
import { Badge, BadgeProps, MantineSize, MantineStyleProps } from '@mantine/core';
import cn from 'classnames';
import { t } from '../../Utilities/i18n';
import AccTooltip from '../AccTooltip/AccTooltip';
import styles from './accBadge.module.scss';

export type BadgeType = 'error' | 'warning' | 'success' | 'primary' | 'gray' | 'brand'; // red, yellow, green, blue, gray, orange

type OrgBadgeProps = MantineStyleProps & {
  type?: BadgeType;
  variant?: BadgeProps['variant'];
  size?: MantineSize;
  tooltip?: string;
  children: ReactNode;
  className?: string;
};

const AccBadge = ({
  type = 'primary',
  variant = 'filled',
  size = 'sm',
  tooltip,
  children,
  ...styleProps
}: OrgBadgeProps) => {
  const badge = (
    <Badge
      {...styleProps}
      size={size}
      variant={variant}
      className={cn(
        styles.badge,
        {
          [styles.error]: type === 'error',
          [styles.warning]: type === 'warning',
          [styles.primary]: type === 'primary',
          [styles.success]: type === 'success',
          [styles.gray]: type === 'gray',
          [styles.brand]: type === 'brand',
          [styles.filled]: variant === 'filled',
          [styles.light]: variant === 'light',
          [styles.dot]: variant === 'dot',
          [styles.outline]: variant === 'outline',
          [styles.gradient]: variant === 'gradient',
        },
        styleProps.className,
      )}
    >
      {children}
    </Badge>
  );

  if (tooltip) {
    return <AccTooltip tooltip={tooltip}>{badge}</AccTooltip>;
  }
  return badge;
};

export default AccBadge;

type LabelProps = { label?: string };

export const Yes = ({ label }: LabelProps) => (
  <AccBadge size={'md'} type={'success'}>
    {label ?? t('Yes')}
  </AccBadge>
);
export const No = ({ label }: LabelProps) => (
  <AccBadge size={'md'} type={'error'}>
    {label ?? t('No')}
  </AccBadge>
);

export const YesNo = ({ label, value }: { label: string; value: boolean }) => (
  <>
    <span>{`${label}: `}</span>
    {value ? <Yes /> : <No />}
  </>
);
