import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { GroupItem } from 'Components/Controls/Dropdowns/GroupsAndDomainsDropdown';
import { useDomainsInGroupQuery, useGroupsListQuery } from 'Ghql';
import SpecificFilterSelector from 'Selectors/SpecificFilterSelector';
import { FilterAttribute } from 'Types/Filter';
import { notEmpty } from 'Utilities/underdash';
import { DOMAIN, GROUP, useSubscribeToTopic } from 'Utilities/websocket';

type ClientDomain = {
  client: string;
  clientId: number;
  displayName: string;
  domain: string;
  id: number;
  isDemoDomain: boolean;
  totalKeywords: number;
};

export type RawClient = {
  client: string;
  clientId: number;
  domains: ClientDomain[];
  isDemoGroup: boolean;
  totalDomains: number;
  totalKeywords: number;
};

const buildGroups = (clients: RawClient[] | undefined = []): GroupItem[] => {
  return clients
    .map((client) => {
      const domains = client.domains || [];
      const domainIds = domains.map((domain) => domain?.id).filter(notEmpty) || [];

      return {
        id: client.clientId,
        displayName: client.client,
        domainIds: domainIds.map((x) => x.toString()),
        domains: domains
          .map((domain) => {
            if (!domain) return null;
            return {
              id: domain.id.toString(),
              domain: domain.domain,
              displayName: domain.displayName,
            };
          })
          .filter(notEmpty),
      };
    })
    .sort(
      // Now sort by length of domains
      (a, b) => b.domains.length - a.domains.length,
    )
    .filter(notEmpty);
};

const domainsFilterSelector = createSelector(
  SpecificFilterSelector(FilterAttribute.DOMAINS),
  (item) => item?.value.join(',') ?? '',
);

export const useDropdownData = ({ selectedGroup }: { selectedGroup: number | null }) => {
  const { data, refetch, loading } = useGroupsListQuery();

  const clientsRaw: RawClient[] | undefined = data?.clientsRaw;

  const domainIdsString = useSelector(domainsFilterSelector);

  const domainIds = (domainIdsString || '')?.split?.(',') ?? [];

  const groups = buildGroups(clientsRaw || []);

  //Fallback for empty groups where we cannot filter on domainId's

  const {
    data: domainData,
    loading: domainDataLoading,
    refetch: refetchDomains,
  } = useDomainsInGroupQuery({
    variables: { clientId: selectedGroup },
  });

  const selectedGroupItem = groups.find((groupItem) => groupItem.id === selectedGroup);
  const domains = domainData?.domainsList?.filter(notEmpty) || [];
  const selectedDomainItem =
    domainIds.length === 1
      ? domains?.filter(notEmpty).find((domain) => domain.id === domainIds[0])
      : null;

  useSubscribeToTopic([
    {
      action: DOMAIN,
      cb: () => refetch(),
    },
    {
      action: GROUP,
      cb: () => refetch(),
    },
  ]);

  return {
    groups,
    groupDataLoading: loading,
    domainDataLoading,
    refetch,
    refetchDomains,
    domains,
    selectedGroupItem,
    selectedDomainItem: selectedDomainItem || undefined,
    domainIds,
  };
};
