import { ColumnSetting, QueryOrder } from 'Components/DataTable';
import { TableIDs } from 'Types/Table';
import { t } from 'Utilities/i18n';

export const LANDING_TABLE_ID = TableIDs.LANDING_PAGES;

export const LANDING_ROOT_PATH = '/';

export const LandingTableIDs = {
  CHECKBOX: 'checkbox',
  PATH: 'path',
  KEYWORDS: 'keywords',
  SEARCH_VOLUME: 'search_volume',
  SHARE_OF_VOICE: 'share_of_voice',
  AVG_REVENUE: 'avg_revenue',
  AVG_REVENUE_COMPARE: 'avg_revenue_compare',
  SHARE_OF_VOICE_CHANGE: 'share_of_voice_change',
  COST_VALUE: 'cost_value',
  UNTRACKED_KEYWORDS: 'untracked_keywords',
  COUNTRY: 'country',
  ORGANIC_TRAFFIC: 'organic_traffic',
  ORGANIC_TRAFFIC_CHANGE: 'organic_traffic_change',
  DYNAMIC_CTR: 'dynamic_ctr',
  DYNAMIC_CTR_CHANGE: 'dynamic_ctr_change',
};

export const DEFAULT_LANDING_ORDERING = {
  order: QueryOrder.DESC,
  orderBy: LandingTableIDs.KEYWORDS,
};

export const landingPageTable: ColumnSetting[] = [
  {
    id: LandingTableIDs.CHECKBOX,
    queryWithClauses: ['id'],
    tableSetting: { required: true },
  },
  {
    id: LandingTableIDs.PATH,
    queryWithClauses: [LandingTableIDs.PATH],
    tableSetting: { required: true },
  },
  {
    id: LandingTableIDs.KEYWORDS,
    queryWithClauses: [LandingTableIDs.KEYWORDS],
    tableSetting: { required: true },
  },
  {
    id: LandingTableIDs.SEARCH_VOLUME,
    queryWithClauses: [LandingTableIDs.SEARCH_VOLUME],
    tableSetting: { required: false, getLabel: () => t('Search Volume') },
  },
  {
    id: LandingTableIDs.SHARE_OF_VOICE,
    queryWithClauses: [LandingTableIDs.SHARE_OF_VOICE],
    tableSetting: { required: false, getLabel: () => t('SoV') },
  },
  {
    id: LandingTableIDs.SHARE_OF_VOICE_CHANGE,
    queryWithClauses: [LandingTableIDs.SHARE_OF_VOICE_CHANGE],
    tableSetting: { required: true },
  },
  {
    id: LandingTableIDs.COST_VALUE,
    queryWithClauses: [LandingTableIDs.COST_VALUE],
    tableSetting: { getLabel: () => t('Traffic value') },
  },
  {
    id: LandingTableIDs.ORGANIC_TRAFFIC,
    queryWithClauses: [LandingTableIDs.ORGANIC_TRAFFIC],
    tableSetting: { required: false, getLabel: () => t('AI SoV') },
  },
  {
    id: LandingTableIDs.ORGANIC_TRAFFIC_CHANGE,
    queryWithClauses: [LandingTableIDs.ORGANIC_TRAFFIC_CHANGE],
    tableSetting: { required: true },
  },
  {
    id: LandingTableIDs.DYNAMIC_CTR,
    queryWithClauses: [LandingTableIDs.DYNAMIC_CTR],
    tableSetting: { required: false, getLabel: () => t('CTR') },
  },
  {
    id: LandingTableIDs.DYNAMIC_CTR_CHANGE,
    queryWithClauses: [LandingTableIDs.DYNAMIC_CTR_CHANGE],
    tableSetting: { required: true },
  },
];
