import { IconSquare } from '@tabler/icons-react';
import { clearEverything } from 'Actions/ResetAction';
import AccButton from 'Components/AccButton/AccButton';
import { useUser } from 'Hooks/data/user/useUser';
import { useActions } from 'Hooks/redux/useActions';
import { t } from 'Utilities/i18n';
import { LinkedAccountSelect } from './LinkedAccountSelect/LinkedAccountSelect';
import './linked-account-dropdown.scss';

type Props = {
  navbarCollapsed?: boolean;
};

const LinkedAccountsDropdown = (props: Props) => {
  const { navbarCollapsed } = props;

  const user = useUser();
  const actions = useActions({ clearEverything });
  const logoutClick = () => actions.clearEverything();

  const renderLogoutButton = () => (
    <AccButton
      variant="destructive"
      onClick={logoutClick}
      href="/org/multiaccount/logout/"
      target="_self"
      fullWidth
      p={navbarCollapsed ? '0 !important' : undefined}
    >
      {navbarCollapsed ? <IconSquare size={18} /> : t('Logout from account')}
    </AccButton>
  );

  const renderSelect = () => {
    return navbarCollapsed ? null : <LinkedAccountSelect />;
  };

  if (user?.isOnMultiaccount) {
    return (
      <>
        {renderSelect()}
        {renderLogoutButton()}
      </>
    );
  }
  if (!user.organization?.isPartner) {
    return null;
  }
  return <div className="linked-account-select-wrapper">{renderSelect()}</div>;
};

export default LinkedAccountsDropdown;
