import { Fragment, memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { ScrollArea } from '@mantine/core';
import { NavItemKeys } from '../../support/types';
import { useActiveNavKey } from '../../support/useActiveNavKey';
import { useSidebarItems } from '../../support/useSidebarItems';
import NavigationItem from './components/NavigationItem';
import styles from './navbar-items.module.scss';

type Props = {
  navbarCollapsed: boolean;
};

const NavbarItems = (props: Props) => {
  const { navbarCollapsed } = props;

  const navItems = useSidebarItems();

  const activeNavKey = useActiveNavKey();

  const navigate = useNavigate();

  const [expandedItem, setExpandedItem] = useState<string | undefined>(
    navItems.find((item) => item?.subPages?.some((page) => page.link === activeNavKey))?.key ||
      undefined,
  );

  // expand home when navigating from breadcrumbs
  useEffect(() => {
    if (activeNavKey === '/domains' || activeNavKey === '/') setExpandedItem(NavItemKeys.HOME);
  }, [activeNavKey]);

  return (
    <ScrollArea
      type="always"
      scrollbars="y"
      classNames={{
        root: styles.scrollContainer,
        thumb: styles.thumb,
        scrollbar: styles.scrollbar,
        corner: styles.corner,
      }}
    >
      <div className={styles.itemsContainer}>
        {navItems.map((item) => (
          <Fragment key={item.key}>
            <NavigationItem
              item={item}
              activeKey={activeNavKey}
              navbarExpanded={!navbarCollapsed}
              itemExpanded={expandedItem === item.key}
              onExpand={(key) => setExpandedItem(key)}
              onNavigate={(link) => navigate(link)}
            />
          </Fragment>
        ))}
      </div>
    </ScrollArea>
  );
};

export default memo(NavbarItems);
