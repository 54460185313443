const DOMAIN_NAME = 'domain_name';
const BELONGS_TO_GROUP = 'belongs_to_group';
const KEYWORDS = 'keywords';
const CREATED_AT = 'created_at';
const PAUSED_AT = 'paused_at';
const ACTIONS = 'actions';

export const ColumnIDs = {
  DOMAIN_NAME,
  BELONGS_TO_GROUP,
  KEYWORDS,
  CREATED_AT,
  PAUSED_AT,
  ACTIONS,
};
