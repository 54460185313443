import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { IconAlertCircle } from '@tabler/icons-react';
import cn from 'classnames';
import { resetServiceMessageRead } from 'Actions/ServiceMessageActions';
import { useActions } from 'Hooks/redux/useActions';
import { selectServiceMessageIsRead } from 'Selectors/ServiceMessageSelector';
import { useStatusServiceMessagesQuery } from '../../../../../../../Ghql';
import { useModal } from '../../../../../../../Hooks/base/useModal';
import {
  SubscriptionHandle,
  subscribeToServiceMessage,
} from '../../../../../../../Utilities/websocket';
import style from './service-message-status.module.scss';

export enum ServiceMessageSeverities {
  info = 1,
  warning = 2,
  error = 3,
}

const Status = () => {
  const subHandler = useRef<SubscriptionHandle>();

  const { data, refetch } = useStatusServiceMessagesQuery();

  const isMessageRead = useSelector(selectServiceMessageIsRead);
  const actions = useActions({ resetServiceMessageRead });

  const { showModal } = useModal();

  const { serviceMessageHighestSeverity } = data || {};

  const getSeverityClass = (severity: ServiceMessageSeverities) => {
    switch (severity) {
      case ServiceMessageSeverities.info:
        return style.info;
      case ServiceMessageSeverities.warning:
        return style.warning;
      case ServiceMessageSeverities.error:
        return style.error;
      default:
        return '';
    }
  };

  useEffect(() => {
    subHandler.current = subscribeToServiceMessage(() => {
      refetch();
      actions.resetServiceMessageRead();
    });
  }, []);

  const handleShowModal = () => {
    showModal({
      modalType: 'ShowServiceMessageModal',
      modalTheme: 'light',
    });
  };

  if (!serviceMessageHighestSeverity) return null;

  return (
    <div
      className={cn(
        style.container,
        !isMessageRead ? getSeverityClass(serviceMessageHighestSeverity) : '',
      )}
      onClick={handleShowModal}
    >
      <IconAlertCircle size={22} />
    </div>
  );
};

export default Status;
