import {
  UserRole,
  adminRole,
  readUserRole,
  superuserRole,
  writeUserRole,
} from 'Constants/workspaces';
import { t } from './i18n';

export const getUserTypeLabel = (userType?: UserRole | null): string => {
  switch (userType) {
    case adminRole:
      return t('Admin');
    case superuserRole:
      return t('Super User');
    case writeUserRole:
      return t('Write User');
    case readUserRole:
      return t('Read User');
    default:
      return '?';
  }
};
export const getUserTypeDescription = (userType?: UserRole | null): string => {
  switch (userType) {
    case adminRole:
      return t('Full Access to all Workspaces and can manage users and billing.');
    case superuserRole:
      return t(
        'Full Access to all Workspaces and can manage API, Groups, Sub-accounts & Workspaces.',
      );
    case writeUserRole:
      return t('Can add and delete keywords and domains but only within assigned Workspaces.');
    case readUserRole:
      return t('Can only view data within assigned Workspaces.');
    default:
      return '?';
  }
};
