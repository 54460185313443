import { useMemo } from 'react';
import { getUrlPath } from 'Utilities/parser';
import AccTooltip from '../AccTooltip/AccTooltip';
import styles from './reverse-ellipsis-link.module.scss';

type Props = {
  noTransform?: boolean;
  url?: string | null;
  onClick?: () => void;
};

export const ReverseEllipsisLink = ({ url, noTransform, onClick }: Props) => {
  const displayUrl = useMemo(() => (noTransform ? url : getUrlPath(url)), [url, noTransform]);
  if (!url) {
    return null;
  }

  return (
    <span className={styles.link}>
      <AccTooltip tooltip={url}>
        <a
          href={url}
          onClick={onClick}
          target="_blank"
          rel="noopener noreferrer"
          className="table-link"
        >
          <span lang="en">&lrm;{displayUrl}&lrm;</span>
        </a>
      </AccTooltip>
    </span>
  );
};
