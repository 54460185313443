import { Link } from 'react-router-dom';
import { Flex } from '@mantine/core';
import AccButton from 'Components/AccButton/AccButton';
import { Field } from 'Components/Fields';
import { Form, OnSubmitCallback } from 'Components/Fields/Form/Form';
import AccText from 'Components/Text/AccText';
import AccTitle from 'Components/Title/AccTitle';
import { PerformPasswordResetInput } from 'Ghql';
import { t } from 'Utilities/i18n';
import Validator from 'Utilities/validation';

export type PasswordChangeFormValues = Omit<PerformPasswordResetInput, 'clientMutationId'>;

type Props = {
  onSubmit: OnSubmitCallback<PasswordChangeFormValues>;
  initialValues?: PasswordChangeFormValues;
};
export const PasswordChangeForm = ({ onSubmit, initialValues }: Props) => {
  return (
    <Flex gap="md" direction="column">
      <AccTitle type="h2" ta="center" mb="sm">
        {t('Change Password')}
      </AccTitle>
      <Form
        subscription={{ values: true, submitting: true, invalid: true, pristine: true }}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ submitting, invalid, pristine, values }) => (
          <Flex gap="md" direction="column">
            <Field.TextInput
              boxProps={{ mt: 'xxs' }}
              autoFocus
              name="newPassword1"
              placeholder={t('New Password')}
              type="password"
              autoComplete="off"
              validate={[Validator.required, Validator.passwordMinLen, Validator.passwordMaxLen]}
            />
            <Field.TextInput
              boxProps={{ mt: 'xxs' }}
              name="newPassword2"
              placeholder={t('New Password Confirmation')}
              type="password"
              autoComplete="off"
              validate={[Validator.required, Validator.passwordsMatch(values.newPassword1)]}
            />
            <Form.Errors />
            <AccText mt={-12} size="xs" ta="center" fw={400} ml="auto">
              <Link to="/login" tabIndex={-1}>
                {t('Back to Login')}
              </Link>
            </AccText>
            <AccButton
              variant="primary"
              fullWidth
              loading={submitting}
              type="submit"
              disabled={invalid || pristine || submitting}
            >
              {t('Reset Password')}
            </AccButton>
          </Flex>
        )}
      </Form>
    </Flex>
  );
};
