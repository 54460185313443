import { useTableStore } from 'Components/DataTable';
import {
  KEYWORD_QUERY_OPTIONS,
  keywordTableConfig,
} from 'Pages/Keywords/Table/hooks/keyword/constants';
import { TableID } from 'Types/Table';
import { capitalizeFirstChar } from 'Utilities/underdash';
import { useFilters } from './data/filter/useFilters';

export function useFilterAndOrderByWithVariables(tableName: TableID) {
  const tableStore = useTableStore(tableName);
  const filters = useFilters();

  const withFilters =
    filters?.map((filter) =>
      keywordTableConfig
        .find((config) => config.id === filter.attribute)
        ?.queryWithClauses.map((clause) => `with${capitalizeFirstChar(clause)}`),
    ) || [];
  const withOrderBy =
    keywordTableConfig
      .find((config) => config.tableSetting.orderBy === tableStore?.ordering?.orderBy)
      ?.queryWithClauses.map((clause) => `with${capitalizeFirstChar(clause)}`) || [];
  const withAll = [...withFilters, ...withOrderBy];

  const withVariables = {};
  for (const key of Object.keys(KEYWORD_QUERY_OPTIONS)) {
    withVariables[key] = withAll.includes(key);
  }

  return withVariables;
}
