import React, { ReactElement, useMemo } from 'react';
import FiltersShell from 'Components/Filters/FiltersShell';
import { useShellConfig } from '../hooks/useShellConfig';

const defaultShellConfig = { showFilters: true, showSegments: true };

export const WithShellConfigFilters = ({ children }: { children: ReactElement }) => {
  const config = useShellConfig((callback) => callback?.shell);

  const shellConfig = { ...defaultShellConfig, ...config };
  return useMemo(
    () =>
      (shellConfig.showFilters ? (
        React.cloneElement(children, { showSegments: shellConfig.showSegments })
      ) : shellConfig.showFiltersShell ? (
        <FiltersShell />
      ) : null),
    [shellConfig.showFilters, shellConfig.showSegments, shellConfig.showFiltersShell],
  );
};
