import { useCallback } from 'react';
import isEqual from 'lodash/isEqual';
import { updateUserSettings } from 'Actions/UserAction';
import { useUpdateOverviewChartsMutation } from 'Ghql';
import { useActions } from 'Hooks/redux/useActions';
import { useShouldUseAiSoV } from 'Hooks/useShouldUseAiSoV';
import toast from 'Hooks/useToast';
import { CHART_NAMES } from 'Pages/Keywords/Overview/components/ChartBox';
import { KPI_NAMES } from 'Pages/Keywords/Overview/components/KpiBar';
import { t } from 'Utilities/i18n';

const COL_LENGTH = 8;

export const useDefaultCharts = () => {
  const showAISoV = useShouldUseAiSoV();

  return {
    leftColumn: [
      showAISoV ? CHART_NAMES.ESTIMATED_VISITS : CHART_NAMES.SHARE_OF_VOICE,
      CHART_NAMES.DYNAMIC_COMPETITORS_BY,
      CHART_NAMES.RANKING_DISTRIBUTION,
      showAISoV ? CHART_NAMES.AI_SHARE_OF_VOICE_BY : CHART_NAMES.SHARE_OF_VOICE_BY,
      CHART_NAMES.COMPETITORS,
      CHART_NAMES.AI_SEARCH_TREND,
      CHART_NAMES.NONE,
      CHART_NAMES.NONE,
    ],
    rightColumn: [
      CHART_NAMES.AVERAGE_RANK,
      showAISoV ? CHART_NAMES.AI_WINNERS_AND_LOSERS : CHART_NAMES.WINNERS_AND_LOSERS,
      CHART_NAMES.SEARCH_INTENT,
      CHART_NAMES.GOOGLE_ANALYTICS,
      CHART_NAMES.SEARCH_TREND,
      CHART_NAMES.NONE,
      CHART_NAMES.NONE,
      CHART_NAMES.NONE,
    ],
    kpis: [
      KPI_NAMES.KEYWORDS,
      KPI_NAMES.AVERAGE_RANK,
      showAISoV ? KPI_NAMES.AI_TRAFFIC_VALUE : KPI_NAMES.TRAFFIC_VALUE,
      showAISoV ? KPI_NAMES.AI_SHARE_OF_VOICE : KPI_NAMES.SHARE_OF_VOICE,
      KPI_NAMES.ABOVE_THE_FOLD,
      KPI_NAMES.DYNAMIC_CTR,
      CHART_NAMES.NONE,
      CHART_NAMES.NONE,
      CHART_NAMES.NONE,
      CHART_NAMES.NONE,
      CHART_NAMES.NONE,
      CHART_NAMES.NONE,
    ],
  };
};

const findDuplicates = (arr) => arr.filter((item, index) => arr.indexOf(item) !== index);

export const useChartControls = (
  selectedCharts: Record<string, string[]>,
  setSelectedCharts: (value: Record<string, string[]>) => void,
  storedCharts: Record<string, string[]>,
) => {
  const updateUserAction = useActions({ updateUserSettings });
  const [updateOverviewChartsMutation] = useUpdateOverviewChartsMutation();
  const defaultCharts = useDefaultCharts();

  const cancelChartEdits = () => {
    setSelectedCharts(storedCharts);
  };

  const saveChartEdits = (nextSelectedCharts?: any) => {
    const chartColumns: any = nextSelectedCharts || selectedCharts;
    if (isEqual(chartColumns, storedCharts)) {
      cancelChartEdits();
    }
    const duplicateCharts = findDuplicates(
      chartColumns.leftColumn
        .concat(chartColumns.rightColumn)
        .concat(chartColumns.kpis)
        .filter((c) => c !== CHART_NAMES.NONE),
    );
    if (duplicateCharts.length > 0) {
      toast.error(
        t(
          'Cannot save chart configuration due to duplicate charts. Duplicate charts: %s',
          duplicateCharts.join(', '),
        ),
      );
      return;
    }

    // First we make sure that CHART_NAMES.None all go in the end of the list
    let leftColumn = chartColumns.leftColumn.filter((e) => e !== CHART_NAMES.NONE);
    if (leftColumn.length < COL_LENGTH) {
      leftColumn = leftColumn.concat(Array(COL_LENGTH - leftColumn.length).fill(CHART_NAMES.NONE));
    }
    let rightColumn = chartColumns.rightColumn.filter((e) => e !== CHART_NAMES.NONE);
    if (rightColumn.length < COL_LENGTH) {
      rightColumn = rightColumn.concat(
        Array(COL_LENGTH - rightColumn.length).fill(CHART_NAMES.NONE),
      );
    }

    // Then we save the new chart order in database, redux and component state
    const charts = { leftColumn, rightColumn, kpis: chartColumns.kpis };

    const isDefaultCharts = isEqual(charts, defaultCharts);

    updateOverviewChartsMutation({
      variables: {
        input: {
          defaultOverviewCharts: isDefaultCharts ? null : JSON.stringify(charts),
        },
      },
    });
    updateUserAction.updateUserSettings({ defaultOverviewCharts: charts });
    setSelectedCharts(charts);
  };

  const restoreDefaultCharts = () => {
    setSelectedCharts(defaultCharts);
  };

  const handleSetSelectedChart = useCallback(
    ({ name, column, idx }) => {
      const newChartsLeft = [
        ...selectedCharts.leftColumn.map((colName) =>
          (name === colName ? CHART_NAMES.NONE : colName),
        ),
      ];
      const newChartsRight = [
        ...selectedCharts.rightColumn.map((colName) =>
          (name === colName ? CHART_NAMES.NONE : colName),
        ),
      ];
      const newChartsKpis = [
        ...(selectedCharts?.kpis.map((colName) =>
          (name === colName ? CHART_NAMES.NONE : colName),
        ) || []),
      ];

      if (column === 'leftColumn') {
        newChartsLeft[idx] = name;
      } else if (column === 'kpis') {
        newChartsKpis[idx] = name;
      } else {
        newChartsRight[idx] = name;
      }

      setSelectedCharts({
        leftColumn: newChartsLeft,
        rightColumn: newChartsRight,
        kpis: newChartsKpis,
      });
    },
    [JSON.stringify(selectedCharts)],
  );
  return {
    saveChartEdits,
    restoreDefaultCharts,
    cancelChartEdits,
    handleSetSelectedChart,
  };
};
