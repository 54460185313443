import { someOfOptions } from 'Components/Filters/Common/labelFunc';
import Icon from 'Components/Icon/new';
import { FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import type { SearchIntentFilter } from 'Types/Filter';
import { t } from 'Utilities/i18n';
import SearchIntentMainIcon from 'icons/search-intent/main-search-intent-icon.svg?inline';
import SearchIntentEditor from '.';
import { getItems } from './getItems';

const defaultValue: SearchIntentFilter = {
  attribute: FilterAttribute.SEARCH_INTENT,
  type: FilterValueType.ARRAY,
  comparison: FilterComparison.ALL,
  value: [],
};

const getData = () => {
  const items = getItems();
  return {
    defaultValue,
    title: t('Search intent'),
    icon: SearchIntentMainIcon,
    editor: SearchIntentEditor,
    editorProps: {
      iconDropdown: true,
      disallowEmptySelection: true,
      withCount: true,
    },
    tableEditor: SearchIntentEditor,
    tableEditorProps: {
      iconDropdown: true,
      showLabel: false,
      noFilterIcon: Icon,
    },
    labelFunc: someOfOptions(items),
  };
};

export default getData;
