import { useEffect, useMemo, useState } from 'react';
import { FilterInput, useFilterCountQuery } from 'Ghql';
import { useFilters } from 'Hooks';
import { DOMAINS, FilterBase, FilterComparison } from 'Types/Filter';
import getIsKeyDis from 'Utilities/getKeyDis';
import { getRegexError } from 'Utilities/validation';
import { useDebouncedFilterValues } from './useDebouncedFilterValues';

const useInvalidRegex = (comparison: string, value: string) => {
  return useMemo(() => {
    if (comparison === FilterComparison.REGEX || comparison === FilterComparison.NOT_REGEX) {
      return !!getRegexError(value);
    }
    return false;
  }, [comparison, value]);
};

const useFilterCount = (filter: FilterInput) => {
  const isKeydis = getIsKeyDis();
  const isGscImport = window.location.href.includes('/import/gsc');

  const filters = useFilters();
  const [count, setCount] = useState<number | null>(null);
  const { value, comparison, type } = filter;

  const { debouncedValue, debouncedComparison, debouncedType } = useDebouncedFilterValues(
    value,
    comparison,
    type,
  );

  const invalidRegex = useInvalidRegex(debouncedComparison, debouncedValue);

  // On keyword research, the filter count does not work in general.. It is because "country" is not set as a filter
  // but is state instead on the keyword research page. We capture this, and more general filter issues by checking
  // if a domains filter is present (which it is not on keyword research in general).
  const doesNotHaveDomainsFilter = useMemo(
    () => filters.every((f) => f.attribute !== DOMAINS),
    [filters],
  );

  const skip = isGscImport || doesNotHaveDomainsFilter || invalidRegex;

  // Store the initial state of the filter
  const [{ initialValue, initialComparison }] = useState({
    initialValue: filter.value,
    initialComparison: filter.comparison,
  });

  // Avoid both the stored filter and the filter being edited in redux-form being included in the filter count query (i.e. when editing in the filter bar)
  const filtersBesidesCurrent = useMemo(
    () =>
      filters.filter(
        (f) =>
          !(
            f.attribute === filter.attribute &&
            f.value === initialValue &&
            f.comparison === initialComparison
          ),
      ),
    [filters, filter.attribute, initialValue, initialComparison],
  );

  const newFilters: FilterBase[] = useMemo(() => {
    const tempFilters =
      debouncedValue === ''
        ? filters
        : [
            ...filtersBesidesCurrent,
            {
              value: debouncedValue,
              comparison: debouncedComparison,
              attribute: filter.attribute,
              type: debouncedType,
            },
          ];

    return tempFilters.filter((f) => f.attribute !== '') as FilterBase[];
  }, [
    debouncedValue,
    filters,
    filtersBesidesCurrent,
    debouncedComparison,
    filter.attribute,
    debouncedType,
  ]);

  const { data } = useFilterCountQuery({
    variables: {
      filters: newFilters,
      isKeydis,
    },
    skip,
  });

  // The useEffect might seem redundant, but it is there such that
  // when data is fetching, count is not shown as undefined
  // but as previous state value.
  useEffect(() => {
    if (data) {
      setCount(data?.filterData?.filterCount ?? null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.filterData?.filterCount]);
  return invalidRegex ? 0 : count;
};

export default useFilterCount;
