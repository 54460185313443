import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ApolloError } from '@apollo/client';
import {
  Box,
  Button,
  Center,
  Checkbox,
  Collapse,
  Container,
  Divider,
  Group,
  Stack,
} from '@mantine/core';
import cn from 'classnames';
import { scaleSequentialLog } from 'd3-scale';
import { interpolateMagma } from 'd3-scale-chromatic';
import { toJS } from 'mobx';
import ReactD3Tree from 'Components/Chart/TreeChart';
import { Point, RawNodeDatum } from 'Components/Chart/TreeChart/types/common';
import Loader from 'Components/Loader';
import { useSelectedNodes } from 'Hooks/useSelectedNodes';
import TreeHelpIcons from 'Pages/SiteMapping/components/TreeHelpIcons';
import {
  siteMapGridTemplateColumns,
  useSitemapHeader,
} from 'Pages/SiteMapping/components/TreeTable/Header';
import { useRowContentComponent } from 'Pages/SiteMapping/components/TreeTable/RowContent';
import { t } from 'Utilities/i18n';
import { createReactKey } from 'Utilities/react';
import ChevronUp from 'icons/chevron-up.svg?inline';
import { filterD3Events } from '../support/helpers';
import { logspace } from '../support/legendHelpers';
import { useSitemapChartStore } from '../support/store';
import { ColorMap, SiteMapMode, SitemapNode, TreeMode, TreeViewMode } from '../support/types';
import { RenderLink } from './RenderLink';
import { RenderSVGNode } from './RenderNode';
import TreeLegend from './TreeLegend';
import { TreeModeSwitch } from './TreeModeSwitch';
import { TreeTable } from './TreeTable';
import styles from '../siteMapping.module.scss';
import collapseToggleStyles from './collapseToggleButton.module.scss';

const chartElmId = 'tree-elm';

interface Config {
  zoom: any;
  translate: Point | undefined;
  onUpdate: (state: any) => void;
  saveExpandedNodes: ((nodeIds: string[]) => void) | undefined;
  expandedNodes: string[] | undefined;
  expandDataKey: string;
}
type SiteMapTreeComponentProps = {
  rootNode: SitemapNode;
  selectedNode: SitemapNode;
  nodeSelectionHandler: (node: SitemapNode | null) => void;
  config: Config;
  treeViewMode: TreeViewMode;
  viewModeId: SiteMapMode;
  setTreeViewMode: (newMode: TreeViewMode) => void;
  setOrderBy: (orderBy: string) => void;
  orderBy?: string;
  loading: boolean;
};

const TreeChart = ({ rootNode, config, selectedNode, nodeSelectionHandler, loading }) => {
  const [openedLegend, setOpenLegend] = useState(false);

  if (loading) {
    return <Loader style={{ height: '100%', position: 'absolute' }} noBackdrop={true} />;
  }

  const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);
  const totalKeywords = rootNode.kwICT + rootNode.kwICU;
  const totalSearchVolume = rootNode.svICT + rootNode.svICU;

  const colorMap: ColorMap = scaleSequentialLog(
    (x: number) => interpolateMagma(x / 1.2 + 0.2), // Start colormap at 0.2 to avoid black and divide by 1.2 to avoid light yellow, see https://stackoverflow.com/questions/49675872/in-d3-sequential-interpolator-is-it-possible-to-only-use-a-portion-of-the-spect
  ).domain([totalSearchVolume, 1]); // Reverse colorscale

  const mapToZeroOneIntervalKeywords = scaleSequentialLog().domain([1, totalKeywords]);

  const orderOfMagnitude = (x: number) =>
    Math.max(1, Math.floor(Math.log(x + 0.0001) / Math.LN10 + 0.0001));

  const KEYWORDS_ORDER_OF_MAGNITUDE = orderOfMagnitude(totalKeywords);
  const SEARCH_VOLUME_ORDER_OF_MAGNITUDE = orderOfMagnitude(totalSearchVolume);

  const colorLegendValues = logspace(
    1,
    SEARCH_VOLUME_ORDER_OF_MAGNITUDE,
    SEARCH_VOLUME_ORDER_OF_MAGNITUDE,
  );

  const keywordsToNodeSize = (keywords) =>
    clamp(mapToZeroOneIntervalKeywords(keywords, totalKeywords) * 30, 8, 30);

  const sizeLegendValues = logspace(1, KEYWORDS_ORDER_OF_MAGNITUDE, KEYWORDS_ORDER_OF_MAGNITUDE);
  const selectedPath = selectedNode?.pathSegment;

  const linkStyle = (link) => {
    const nodeData = link?.target?.data as unknown as SitemapNode; // Do something about this horrible hack

    if (`${selectedPath}/`.startsWith(`${nodeData.pathSegment}/`)) {
      // Adding "/" to make sure that selecting /hello does not highlight /helloworld
      return styles.highlightedTreePath;
    }
    return styles.treePath;
  };

  const RenderLinkPath = (l) => (
    <RenderLink
      linkData={l}
      totalSearchVolume={totalSearchVolume}
      colorMap={colorMap}
      selectedPath={selectedPath}
    />
  );

  const renderNode = (node) => (
    <RenderSVGNode
      node={node}
      selectedNode={selectedNode}
      nodeSelectionHandler={nodeSelectionHandler}
      hoverNodeHandler={undefined} // setHoveredNode
      totalSearchVolume={totalSearchVolume}
      colorMap={colorMap}
      keywordsToNodeSize={keywordsToNodeSize}
    />
  );
  return (
    <div id={chartElmId} className={styles.chart}>
      <Collapse in={openedLegend}>
        <TreeLegend
          colorMap={colorMap}
          colorLegendValues={colorLegendValues}
          sizeLegendValues={sizeLegendValues}
          totalKeywords={totalKeywords}
        />
        <Divider color="gray.1" />
      </Collapse>
      <Button
        onClick={() => {
          setOpenLegend((isOpen) => !isOpen);
        }}
        data-open={openedLegend || null}
        size="xs"
        className={cn(collapseToggleStyles.collapseToggleButton)}
      >
        <ChevronUp />
      </Button>

      <ReactD3Tree
        data={rootNode as RawNodeDatum}
        rootNodeClassName={styles.treeRootNode}
        // branchNodeClassName={styles.treeBranchNode} // Controlled instead by renderSVGNode
        leafNodeClassName={styles.treeLeafNode}
        pathClassFunc={linkStyle}
        initialDepth={1}
        // onNodeClick={onSelectNode} // Controlled instead by renderSVGNode
        renderCustomNodeElement={renderNode}
        // shouldCollapseNeighborNodes={true}
        zoomable
        separation={{ nonSiblings: 1, siblings: 0.5 }}
        nodeSize={{ x: 450, y: 130 }}
        customZoomFilter={filterD3Events}
        renderCustomLink={RenderLinkPath}
        // toolTipProps={toolTipProps}
        // NodeToolTip={NodeTooltip}
        resetPositionButton={true}
        {...config}
      />
    </div>
  );
};

const TreeTableLegend = (
  setHoveredThisPage: (value: ((prevState: boolean) => boolean) | boolean) => void,
  includeThisPage: boolean,
  setIncludeThisPage: (value: ((prevState: boolean) => boolean) | boolean) => void,
  hoveredThisPage: boolean,
  hoveredIncludingChildPages: boolean,
  setHoveredIncludingChildPages: (value: ((prevState: boolean) => boolean) | boolean) => void,
  includeChildPages: boolean,
  setIncludeChildPages: (value: ((prevState: boolean) => boolean) | boolean) => void,
) => {
  return (
    <Stack style={{ textAlign: 'left', gap: 2 }} m={0} p={0}>
      <Box
        onMouseEnter={() => setHoveredThisPage(true)}
        onMouseLeave={() => setHoveredThisPage(false)}
      >
        <Checkbox
          label={t('Data for landing page')}
          size={'xs'}
          checked={includeThisPage}
          color={'snorlax'}
          onChange={(e) => {
            setIncludeThisPage(e.currentTarget.checked);
          }}
          m={0}
          className={cn(styles.landingPageCheckbox, { [styles.hovered]: hoveredThisPage })}
        />
      </Box>
      <Box
        onMouseEnter={() => setHoveredIncludingChildPages(true)}
        onMouseLeave={() => setHoveredIncludingChildPages(false)}
      >
        <Checkbox
          label={t('Data including subpages')}
          size={'xs'}
          checked={includeChildPages}
          color={'snorlax'}
          m={0}
          onChange={(e) => {
            setIncludeChildPages(e.currentTarget.checked);
          }}
          className={cn(styles.subpagesCheckbox, { [styles.hovered]: hoveredIncludingChildPages })}
        />
      </Box>
    </Stack>
  );
};

const SiteMapTreeComponent = ({
  rootNode,
  selectedNode,
  nodeSelectionHandler,
  config,
  treeViewMode,
  viewModeId,
  setTreeViewMode,
  setOrderBy,
  orderBy,
  loading,
}: //sitemapChartStore,
SiteMapTreeComponentProps) => {
  const [hoveredThisPage, setHoveredThisPage] = useState(false);
  const [includeThisPage, setIncludeThisPage] = useState(true);
  const [hoveredIncludingChildPages, setHoveredIncludingChildPages] = useState(false);
  const [includeChildPages, setIncludeChildPages] = useState(true);

  const rowProps = {
    hoveredThisPage,
    hoveredIncludingChildPages,
    includeThisPage,
    includeChildPages,
    viewModeId,
  };
  const RowContentComponent = useRowContentComponent(rowProps);

  const SitemapHeader = useSitemapHeader(rootNode, setOrderBy);

  const { setSelectedNodes } = useSelectedNodes();
  const onSelectNode = useCallback(
    (node) => {
      nodeSelectionHandler(node || null);
      setSelectedNodes(node ? [node] : []);
    },
    [setSelectedNodes],
  );
  useEffect(() => {
    if (rootNode) {
      setSelectedNodes([rootNode]);
    }
  }, [!!rootNode]);
  return (
    <div id={chartElmId} className={styles.chart}>
      <Center style={{ position: 'relative', flex: '1 1 auto', alignItems: 'flex-start' }}>
        {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}

        {/* UpdateBanner is disabled for now - until websockets functionality is working correctly in the component*/}
        {/* <UpdateBanner onClick={handleBannerButtonClick} /> */}
        {treeViewMode.id === TreeMode.TREE && (
          <TreeChart
            rootNode={rootNode}
            config={config}
            selectedNode={selectedNode}
            nodeSelectionHandler={nodeSelectionHandler}
            loading={loading}
          />
        )}
        {treeViewMode.id === TreeMode.TABLE && (
          <Group m="69px 20px 20px" style={{ overflow: 'auto', width: '100%' }}>
            <TreeTable
              rootNodes={rootNode ? [rootNode] : []}
              saveExpandedNodes={config.saveExpandedNodes}
              expandedNodes={config.expandedNodes}
              expandDataKey={config.expandDataKey}
              gridTableRows={siteMapGridTemplateColumns}
              Header={SitemapHeader}
              headerProps={{ orderBy }}
              RowContentComponent={RowContentComponent}
              setSelectedNodes={onSelectNode}
              loading={loading}
              tableRowKey={createReactKey(rowProps)}
            />
          </Group>
        )}
        <Container
          style={{
            position: 'absolute',
            left: 10,
            top: 23,
            zIndex: 1,
          }}
        >
          <TreeHelpIcons />
        </Container>
        <Container
          style={{
            position: 'absolute',
            right: 10,
            top: 23,
            zIndex: 1,
          }}
        >
          <Group style={{ fontSize: '12px', gap: 25 }}>
            {treeViewMode.id === TreeMode.TABLE &&
              TreeTableLegend(
                setHoveredThisPage,
                includeThisPage,
                setIncludeThisPage,
                hoveredThisPage,
                hoveredIncludingChildPages,
                setHoveredIncludingChildPages,
                includeChildPages,
                setIncludeChildPages,
              )}
            <TreeModeSwitch viewMode={treeViewMode} setViewMode={setTreeViewMode} />
          </Group>
        </Container>
      </Center>
    </div>
  );
};

const getZoom = (sitemapChartStore, numberOfNodes) => {
  let zoom = toJS(sitemapChartStore?.pagesTreeState?.zoom);

  if (zoom) {
    return zoom;
  }

  if (numberOfNodes < 20) {
    zoom = 0.5;
  } else if (numberOfNodes < 30) {
    zoom = 0.4;
  } else if (numberOfNodes) {
    zoom = 0.3;
  } else {
    zoom = undefined;
  }

  sitemapChartStore?.setPagesTreeState({ zoom, translate: undefined });
  return zoom;
};

type GraphCompProps = {
  rootNode: SitemapNode;
  error: ApolloError | undefined;
  loading: boolean;
  nodeSelectionHandler: (node: SitemapNode | null) => void;
  selectedNode: SitemapNode;
  setOrderBy: (orderBy: string) => void;
  treeViewMode: TreeViewMode;
  setTreeViewMode: (newNode: TreeViewMode) => void;
  viewModeId: SiteMapMode;
};

const SitemapTree = ({
  loading,
  error,
  rootNode,
  nodeSelectionHandler,
  selectedNode,
  setOrderBy,
  treeViewMode,
  setTreeViewMode,
  viewModeId,
}: GraphCompProps): JSX.Element | null => {
  const sitemapChartStore = useSitemapChartStore();

  const expandedNodes = useMemo(
    () => toJS(sitemapChartStore?.expandedNodeIds),
    [sitemapChartStore?.expandedNodeIds],
  );

  const translate = useMemo(
    () => toJS(sitemapChartStore?.pagesTreeState?.translate),
    [sitemapChartStore?.pagesTreeState?.translate],
  );

  const config: Config = {
    zoom: getZoom(sitemapChartStore, rootNode?.children?.length),
    translate,
    onUpdate: (state) => sitemapChartStore?.setPagesTreeState(state),
    saveExpandedNodes: sitemapChartStore?.saveExpandedNodes,
    expandedNodes,
    expandDataKey: 'pathSegment',
  };

  return (
    <>
      {!error && config && (
        <SiteMapTreeComponent
          rootNode={rootNode}
          selectedNode={selectedNode}
          nodeSelectionHandler={nodeSelectionHandler}
          config={config}
          treeViewMode={treeViewMode}
          viewModeId={viewModeId}
          setTreeViewMode={setTreeViewMode}
          setOrderBy={setOrderBy}
          orderBy={sitemapChartStore?.orderBy}
          loading={loading}
        />
      )}
      {error && <>{t('Error loading data')}</>}
    </>
  );
};

export default SitemapTree;
