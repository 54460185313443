import moment from 'moment';
import { FilterAttribute } from 'Types/Filter';

export const getIsLatestFilter = (filters) => {
  const periodFilterValue = filters.find((f) => f.attribute === FilterAttribute.PERIOD)?.value;
  try {
    const [from, to] = JSON.parse(periodFilterValue);

    return (
      to === 'latest' ||
      (to === moment().format('YYYY-MM-DD') &&
        from === moment().subtract(1, 'day').format('YYYY-MM-DD'))
    );
  } catch (e) {
    return true;
  }
};
