import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebouncedValue } from '@mantine/hooks';
import uniqBy from 'lodash/uniqBy';
import { createCustomSelectOption } from 'Components/AccSelect/components/CustomSelectOption';
import { DelayedSpinner } from 'Components/AccSelect/components/DelayedSpinner';
import { formatOptions, getIsSearchPromptVisible } from 'Components/AccSelect/support/helpers';
import { t } from 'Utilities/i18n';
import { devError } from 'Utilities/log';
import { SelectItem } from './types';

const SELECT_SEARCH_DEBOUNCE_DELAY = 300;

export const useOptions = <Item extends SelectItem>({
  options,
  search,
  searchable,
  loadOptions,
  valueOptions,
  values,
  minQueryLength,
  externalIsLoading,
  showResultsForEmptySearch,
}: {
  options?: Item[];
  search?: string;
  searchable?: boolean;
  loadOptions?: (search?: string) => Promise<Item[]>;
  valueOptions?: Item[];
  values?: Item['value'][] | null;
  minQueryLength?: number;
  // loading status passed from parent component
  externalIsLoading?: boolean;
  showResultsForEmptySearch?: boolean;
}) => {
  const asyncOptions = !!loadOptions;
  const [loading, setLoading] = useState(asyncOptions && !minQueryLength);
  const [firstLoad, setFirstLoad] = useState(true);
  const [stateOptions, setOptions] = useState<Item[]>([]);
  const [cachedValues, setCachedValue] = useState<Item[]>([]);
  // Merge values into the options if they are not there, required for mantine to work corretly
  // check `AccSelect/AccMultiSelect.tsx` > `valueOptions` property description
  const formattedOptions = useMemo(() => {
    return formatOptions(stateOptions, values, options, valueOptions, cachedValues);
  }, [stateOptions, options, values, valueOptions]);

  const [debouncedSearch] = useDebouncedValue(search, SELECT_SEARCH_DEBOUNCE_DELAY);
  const isValidSearch =
    (search || showResultsForEmptySearch) &&
    (!minQueryLength || minQueryLength <= (debouncedSearch?.length || 0));
  const isLoading = loading || externalIsLoading;

  const addToCache = (nextOptions: Item[]) => {
    setCachedValue((cachedOptions) => uniqBy([...cachedOptions, ...(nextOptions || [])], 'value'));
  };
  const clearFetchedOptions = () => {
    if (searchable && asyncOptions) {
      setOptions([]);
    }
  };

  useEffect(() => {
    if (asyncOptions && (isValidSearch || firstLoad)) {
      if (firstLoad) {
        setFirstLoad(false);
      } else if (!searchable) {
        return;
      }
      setLoading(true);
      loadOptions(debouncedSearch).then((resOptions) => {
        setLoading(false);
        setOptions(resOptions ?? []);
        addToCache(resOptions);
      });
    }
  }, [debouncedSearch, asyncOptions, isValidSearch, firstLoad, searchable]);

  const onCreate = useCallback(
    (query: string) => {
      const resultQuery = query?.trim?.();
      if (!resultQuery || formattedOptions?.some((e) => e?.value === resultQuery)) {
        return;
      }
      //TODO: Do we need to handle onCreate differently when values are boolean or number?
      const item = { value: resultQuery, label: resultQuery };
      setOptions((current) => uniqBy([...(current || []), item], 'value') as Item[]);
      return item;
    },
    [formattedOptions, setOptions],
  );

  return {
    options: formattedOptions,
    onCreate,
    isLoading,
    clearFetchedOptions,
  };
};

/**
 * Set first option as default value.
 * should not be used with clearable option since on clear we will select first option
 */
export const useSetDefaultSelectValue = ({
  options,
  value,
  useFirstOptionAsDefault,
  onChange,
  clearable,
}) => {
  return useEffect(() => {
    if (useFirstOptionAsDefault && clearable) {
      devError('Can not have both "useFirstOptionAsDefault" and "clearable" as true');
    }
    let timeout;
    const nextValue = options?.[0]?.value;
    if (useFirstOptionAsDefault && !value && nextValue) {
      timeout = setTimeout(() => {
        onChange(nextValue);
      }, 0);
    }
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [useFirstOptionAsDefault, onChange, value, options, clearable]);
};

export type SelectNotFoundConfig = {
  minQueryLength?: number;
  searchPromptText?: string;
  nothingFoundText?: string;
};

/**
 * - Fetching options start after `minQueryLength` characters are typed, before that we show `searchPromptText`
 * - while we are loading we show spinner
 */
export const useSelectNotFound = ({
  nothingFoundText,
  minQueryLength,
  search,
  searchPromptText = '',
  isLoading,
}: SelectNotFoundConfig & { isLoading?: boolean; search?: string }): string => {
  let resultNotFound: React.ReactNode = nothingFoundText ?? t('No results found');
  if (getIsSearchPromptVisible({ minQueryLength, search, searchPromptText })) {
    resultNotFound = searchPromptText;
  } else if (isLoading) {
    resultNotFound = <DelayedSpinner />;
  }
  return resultNotFound as string;
};
/**
 * - itemComponent wrapped with default wrapper that handle passing styles and attributes. Cover most of the cases.
 * - itemComponent gives full control over option rendering. Used for example to show border for option.
 */
export const useCustomItemComponent = <Item extends SelectItem>(
  itemComponent: ((props: Item) => JSX.Element) | undefined,
  displayLabel?: boolean,
) => {
  return useMemo(() => {
    if (displayLabel && itemComponent) {
      return createCustomSelectOption(itemComponent);
    }
    return itemComponent;
  }, [itemComponent, displayLabel]);
};
