import { observer } from 'mobx-react';
import { useClearSelectedOnUnmount, useSelectedNodes } from 'Hooks/useSelectedNodes';
import { t } from 'Utilities/i18n';
import Toolbar from '../../Toolbar';

const TreeViewToolbar = observer(() => {
  useClearSelectedOnUnmount();
  const { setSelectedNodes, selectedNodes } = useSelectedNodes();
  const selectedIds = selectedNodes.map((node) => node.path);
  return (
    <Toolbar
      initialSelect={selectedIds}
      deselectAll={() => setSelectedNodes([])}
      singularLabel={t('item selected')}
      pluralLabel={t('items selected')}
    />
  );
});

export default TreeViewToolbar;
