import { useApolloClient } from '@apollo/client';
import { updateUserSettings } from 'Actions/UserAction';
import { ColumnSetting, useTableStore } from 'Components/DataTable';
import { UpdateTableSettingMutationDocument } from 'Ghql';
import { useActions } from 'Hooks/redux/useActions';
import toast from 'Hooks/useToast';
import { getIsColumnsUpdated } from 'Pages/Keywords/Table/Actions/helpers';
import { TableID } from 'Types/Table';
import { t } from 'Utilities/i18n';
import { formatSavedColumnsData, getIsOrderColumnWasRemoved } from '../helpers';
import { ColumnSettingKeyType, TableSettingForm } from '../types';

interface UseSaveTableSettingsParams {
  tableName: TableID;
  prevColumnIds: string[] | null;
  columns: ColumnSetting[];
  columnSettingsName: ColumnSettingKeyType;
  onSuccess: Function;
  isFlat?: boolean;
  disableTableViewType?: boolean;
  groupSettingsName?: string;
}

export const useSaveTableSettings = ({
  tableName,
  prevColumnIds,
  columns,
  columnSettingsName,
  groupSettingsName,
  onSuccess,
  isFlat,
}: UseSaveTableSettingsParams) => {
  const tableStore = useTableStore(tableName);
  const client = useApolloClient();
  const actions = useActions({ updateUserSettings });
  return async (form: TableSettingForm) => {
    const isGroupMode = !!groupSettingsName;
    const saveColumns = formatSavedColumnsData(form.columns, columns);
    const formColumnIds = isGroupMode ? form.columns : saveColumns.map((e) => e.id);
    const columnsModified = isGroupMode || getIsColumnsUpdated(prevColumnIds, formColumnIds);
    const resultColumnData = isFlat ? JSON.stringify(formColumnIds) : saveColumns;

    if (!isGroupMode && columnsModified) {
      tableStore?.startLoading();
    }

    // When we remove column we should remove ordering by this column
    // - since we won't request it and query can fail
    if (getIsOrderColumnWasRemoved(formColumnIds, columns, tableStore?.ordering?.orderBy ?? '')) {
      tableStore?.resetOrdering();
    }

    try {
      if (columnsModified) {
        setTimeout(() => {
          const input = {
            [columnSettingsName]: resultColumnData,
          };

          if (groupSettingsName) {
            input[groupSettingsName] = JSON.stringify(
              form.groups?.map((e) => ({ ...e, enabled: e.enabled ? 1 : 0 })),
            );
          }
          client
            .mutate({
              mutation: UpdateTableSettingMutationDocument,
              variables: { input },
            })
            .catch(() => {
              actions.updateUserSettings({ [columnSettingsName]: resultColumnData });
              toast.error(t('Failed to save new table settings.'));
            });
          actions.updateUserSettings(input);
        }, 0);
      }
      onSuccess?.();
    } catch (e) {
      toast.error(t('Failed to save new table settings.'));
    }
  };
};
