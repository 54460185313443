import times from 'lodash/times';
import moment from 'moment';
import { t } from 'Utilities/i18n';
import { OveruseChartItem } from '../../../types';
import { seriesColor } from './constants';
import { OveruseChartData, OveruseChartHighlight } from './types';

enum OveruseTense {
  PRESENT = 'present',
  FUTURE = 'future',
  PAST = 'past',
}

export const formatCategoryDate = (item: OveruseChartItem): string => {
  if (!item?.date) {
    return '';
  } else if ([OveruseTense.PRESENT, OveruseTense.FUTURE].includes(item.tense as OveruseTense)) {
    return `<b>${item.tense === OveruseTense.PRESENT ? t('Today') : t('Tomorrow')}</b>`;
  }
  const momentDate = moment(item?.date);
  return momentDate.isValid() ? momentDate.format('MMM DD, YYYY') : '-';
};

export const getIsChartHighlightVisible = (columnVisibilityStatus: {
  [key: string]: boolean;
}): boolean => {
  return Object.values(columnVisibilityStatus || {}).some((e) => e);
};

export const getOveruseChartData = (data: OveruseChartItem[]) => {
  const result: OveruseChartData = {
    includedItems: [],
    exceededItems: [],
    overuseItems: [],
    categories: [],
    tenses: data?.map((e) => e.tense) ?? [],
  };

  data?.map((e) => {
    result.includedItems.push(e.countGreen ?? 0);
    result.exceededItems.push(e.countYellow ?? 0);
    result.overuseItems.push(e.countRed ?? 0);
    result.categories.push(formatCategoryDate(e));
  });

  return result;
};

export const getDayHighlight = (data: OveruseChartData, index: number): OveruseChartHighlight => {
  const items: number[] = times(data.includedItems?.length ?? 0, () => 0);

  const included = data.includedItems[index] ?? 0;
  const exceeded = data.exceededItems[index] ?? 0;
  const overuse = data.overuseItems[index] ?? 0;
  const total = included + exceeded + overuse;
  items[index] = total ? 1.1 * total : 0;
  const middlePercent = exceeded ? (included + exceeded / 2) / total : 0;
  return {
    items,
    middlePercent,
    total,
  };
};

export const getHighlightColumnChart = (highlightConfig: OveruseChartHighlight) => {
  if (!highlightConfig?.total || !highlightConfig.items?.length) {
    return null;
  }
  return {
    name: 'skip',
    label: {
      enabled: false,
      style: {
        fontWeight: 'bold',
      },
    },
    showInLegend: false,
    color: {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: [
        [0, '#fde0df'],
        highlightConfig.middlePercent && [highlightConfig.middlePercent, '#feead6'], // middle
        [1, '#d7efdf'],
      ].filter(Boolean),
    },
    borderWidth: '1',
    borderColor: {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: [
        [0, '#f75c5b'],
        highlightConfig.middlePercent && [highlightConfig.middlePercent, '#f9aa5e'], // middle
        [1, '#29ae60'],
      ].filter(Boolean),
    },
    dashStyle: 'LongDash',
    pointWidth: '60',
    yAxis: 0,
    data: highlightConfig.items,
  };
};

export const getChartHighlightInfo = (data: OveruseChartData): any[] => {
  return data.tenses
    ?.map((e, i) =>
      [OveruseTense.PRESENT, OveruseTense.FUTURE].includes(e as OveruseTense)
        ? { index: i, category: e }
        : null,
    )
    .filter((e) => !!e)
    .map((e) => getHighlightColumnChart(getDayHighlight(data, e!.index)));
};

export const getChartSeries = (
  chartData: OveruseChartData,
  chartHighlightInfoItems: any[],
): any[] => {
  return [
    ...(chartHighlightInfoItems || []),
    {
      name: t('Exceeded keywords, save money by upgrading'),
      color: seriesColor.OVERUSE,
      pointWidth: '40',
      stacking: 'normal',
      yAxis: 0,
      legendIndex: 2,
      data: chartData.overuseItems,
    },
    {
      name: t('Exceeded keywords'),
      color: seriesColor.EXCEEDED,
      pointWidth: '40',
      stacking: 'normal',
      yAxis: 0,
      legendIndex: 1,
      data: chartData.exceededItems,
    },
    {
      name: t('Discovered keywords'),
      color: seriesColor.INCLUDED,
      colorIndex: 1,
      pointWidth: '40',
      stacking: 'normal',
      pointPadding: -0.4,
      yAxis: 0,
      legendIndex: 0,
      data: chartData.includedItems,
    },
  ].filter(Boolean);
};

export const formatOveruseTooltipMessage = (tooltipData: { x: number; points: any[] }) => {
  const itemsDisplay = tooltipData?.points
    ?.filter((e) => Object.values(seriesColor).includes(e?.color))
    .map((e) => `<span style="color: ${e?.color};margin-right: 5px;">\u25CF</span>  <b>${e?.y}</b>`)
    .join('<br/>');
  return `<b>${tooltipData?.x}</b><br/>${itemsDisplay}`;
};
