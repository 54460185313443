import cn from 'classnames';
import { AccDropdownMenu } from 'Components/AccDropdownMenu';
import './form-dropdown-list.scss';

type Props = {
  items: Array<any>;
  value: any;
  onChange: (...args: Array<any>) => any;
  valueFunc: (...args: Array<any>) => any;
  placeholder?: string;
  labelFunc: (...args: Array<any>) => any;
  dropDownLabelFunc: (...args: Array<any>) => any;
  handleSelect?: (...args: Array<any>) => any;
  iconFunc?: any;
  className?: string;
  itemClassName?: string;
  disabled?: boolean;
  withinPortal?: boolean;
  hideDropdownArrow?: boolean;
};

const defaultValueFunc = (item: any) => {
  if (item.value !== undefined) return item.value;
  if (item.id !== undefined) return item.id;
  return item;
};

const FormDropdownList = (props: Props) => {
  const {
    placeholder,
    value,
    valueFunc,
    items,
    labelFunc,
    iconFunc,
    dropDownLabelFunc,
    disabled,
    className,
    itemClassName,
    withinPortal,
    hideDropdownArrow,
    handleSelect,
    onChange,
  } = props;

  const onSelect = (item: any) => {
    const val = valueFunc ? valueFunc(item) : item?.value;
    onChange(val);
    setTimeout(() => handleSelect?.(val));
  };

  const item = items.find((currentItem) => valueFunc(currentItem) === value);
  // first try to use dropDownLabelFunc then labelFunc
  const label =
    item && dropDownLabelFunc
      ? dropDownLabelFunc(item)
      : item && labelFunc
      ? labelFunc(item)
      : placeholder;
  return (
    <div className="simple-dropdown form-dropdown-list simple-dropdown dropdown">
      <AccDropdownMenu
        items={items.map((e) => ({ ...e, icon: iconFunc?.(e) }))}
        width="target"
        onSelect={onSelect}
        itemClassName={itemClassName}
        closeOnItemClick
        withinPortal={withinPortal}
      >
        <div
          tabIndex={0}
          className={cn('menu-toggle', className, {
            disabled,
            'hide-dropdown-arrow': hideDropdownArrow,
          })}
        >
          {label}
        </div>
      </AccDropdownMenu>
    </div>
  );
};

FormDropdownList.defaultProps = {
  labelFunc: (item: any) => item.label || item,
  dropDownLabelFunc: (item: any) => item.label || item,
  iconFunc: null,
  value: null,
  onChange: () => {},
  handleSelect: () => {},
  valueFunc: defaultValueFunc,
  item: null,
  placeholder: '',
  className: '',
  disabled: false,
};

export default FormDropdownList;
