import { useSelector } from 'react-redux';
import { updateDomain } from 'Actions/DomainsAction';
import { SelectItem } from 'Components/AccSelect';
import { useAvailableDisplayCurrenciesQuery, useDomainDisplaySettingsQuery } from 'Ghql';
import { selectDomainId } from 'Selectors/DomainSelector';
import {
  selectOrganizationDisplayCurrency,
  useOrganizationSelector,
} from 'Selectors/OrganizationSelector';
import { StoreType } from 'Types/Store';
import { t } from 'Utilities/i18n';
import { useActions } from './redux/useActions';

/**
 * To differentiate between "null-domain" and "null-organization"
 * NB: Don't change values as they are used in backend
 */
export enum DefaultValue {
  organization = 'default_organization',
  domain = 'default_domain',
}

function toSelectItems(
  availableDisplayCurrencies: string[],
  topCurrencies: string[],
  defaultLabel: string,
  defaultValue: DefaultValue,
): SelectItem<string>[] {
  const TOP = 'TOP';
  return (
    availableDisplayCurrencies
      .filter((currency) => currency !== defaultLabel) // filter out USD if defaultLabel is USD
      ?.map((currency) => ({
        key: currency,
        value: currency,
        label: currency,
        group: topCurrencies.includes(currency) ? TOP : undefined,
      }))
      ?.concat({
        key: 'default',
        value: defaultValue,
        label: defaultLabel,
        group: TOP,
      }) || []
  );
}

export function useDisplayCurrencies(
  topCurrencies: string[],
  defaultLabel: string,
  defaultValue: DefaultValue,
) {
  const { data } = useAvailableDisplayCurrenciesQuery();
  const availableDisplayCurrencies =
    data?.exchangeRate?.availableDisplayCurrencies?.map((currency) => String(currency)) || [];
  const currencyItems: SelectItem<string>[] = toSelectItems(
    availableDisplayCurrencies,
    topCurrencies,
    defaultLabel,
    defaultValue,
  );

  return currencyItems;
}

export function useTopCurrencies(accountDefault: boolean = true) {
  const ACCOUNT_DEFAULT = t('Account Default');

  const organization = useOrganizationSelector();

  const topCurrencies = Array.from(
    new Set<string>([
      organization?.primaryCountryCurrency || '',
      'USD',
      'EUR',
      'GBP',
      accountDefault ? ACCOUNT_DEFAULT : '',
    ]),
  );
  return topCurrencies;
}

/**
 * Returns displayCurrency
 *
 * translateDefault argument should be true, except for where mutation is necessary, e.g. domain or account settings
 */
export function useDisplayCurrency(translateDefault: boolean = true) {
  const organizationDisplayCurrency = useSelector(selectOrganizationDisplayCurrency);
  const actions = useActions({ updateDomainAction: updateDomain });
  const domainId = useSelector(selectDomainId) as string;

  let domainDisplayCurrency: string | undefined = useSelector(
    (state: StoreType) => state.domains[domainId]?.displayCurrency,
  );
  const { data, loading } = useDomainDisplaySettingsQuery({
    variables: { id: domainId },
    skip: !!domainDisplayCurrency || !domainId,
  });
  if (!domainDisplayCurrency && !loading) {
    const fetchedDisplayCurrency = data?.domain?.displayCurrency || DefaultValue.domain;
    domainDisplayCurrency = fetchedDisplayCurrency;
    actions.updateDomainAction(domainId, { displayCurrency: fetchedDisplayCurrency });
  }

  if (!domainDisplayCurrency) {
    return { displayCurrency: '', domainId, loading };
  }

  let displayCurrency = domainDisplayCurrency;

  if (translateDefault) {
    if (displayCurrency === DefaultValue.domain) {
      displayCurrency = organizationDisplayCurrency || 'USD';
    }
    if (displayCurrency === DefaultValue.organization) {
      displayCurrency = 'USD';
    }
  }

  return { displayCurrency, domainId, loading };
}
