import { Link } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { ColumnType, QueryOrder, TableFetchDataCallBack } from 'Components/DataTable';
import { SORTING_TYPE } from 'Constants';
import {
  SubAccountsTableAvailableAccountsDocument,
  SubAccountsTableAvailableAccountsQuery,
  SubAccountsTableRemoveAccessDocument,
} from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import { useUser } from 'Hooks/data/user/useUser';
import toast from 'Hooks/useToast';
import useUserPermission from 'Hooks/useUserPermission';
import { CellRendererParams } from 'Types/LegacyTable';
import { t, tct } from 'Utilities/i18n';
import { redirectToExternalUrl } from 'Utilities/underdash';
import { ColumnIDs } from './constants';

export const useFetchSubAccounts = (): TableFetchDataCallBack => {
  const client = useApolloClient();

  return async () => {
    return client
      .query<SubAccountsTableAvailableAccountsQuery>({
        query: SubAccountsTableAvailableAccountsDocument,
      })
      .then((e) => {
        const data = e.data?.user?.organization?.multiAccounts;
        return { data: data || [], length: data?.length ?? 0 };
      });
  };
};

const useHandleRemoveSubAccount = (resetTable: Function) => {
  const client = useApolloClient();
  const { showModal } = useModal();
  return (id: string, fromOrganizationPays: boolean) =>
    showModal({
      modalType: 'Confirmation',
      modalProps: {
        cancelLabel: t('Cancel'),
        confirmLabel: fromOrganizationPays
          ? t('Remove access and cancel plan')
          : t('Remove access'),
        lockDuration: 0,
        title: t('Remove Access to Sub-account?'),
        description: fromOrganizationPays
          ? t(
              'This sub-account is paid by you. Removing access will cancel the sub-accounts subscription.',
            )
          : t('Access to the sub-account will be permanently removed.'),
        action: () => {
          client
            .mutate({
              mutation: SubAccountsTableRemoveAccessDocument,
              variables: {
                removeAccessInput: {
                  id,
                },
              },
            })
            .then(
              ({
                data: {
                  removeMultiAccount: { errors },
                },
              }) => {
                if (!errors.length) {
                  toast.success(t('Access removed'));
                  resetTable(true);
                } else {
                  toast.error(t('Unable to remove access'));
                }
              },
            );
        },
      },
    });
};
export const useSubAccountsColumnsConfig = (resetTable: Function): ColumnType[] => {
  const user = useUser();
  const { isAdmin, isSuperuser } = useUserPermission();
  const isSubAccount = user?.isOnMultiaccount;
  const handleLoginUserClick = (id: string) => {
    redirectToExternalUrl(`/org/multiaccount/change/${id}/`);
  };
  const handleRemove = useHandleRemoveSubAccount(resetTable);

  const cols: ColumnType[] = [
    {
      id: ColumnIDs.ACCOUNT_NAME,
      title: t('Account Name'),
      width: 100,
      flex: true,
      cellRenderer: (props) => (
        <span
          className={isSubAccount ? '' : 'custom-link'}
          onClick={() => !isSubAccount && handleLoginUserClick(props.record?.id)}
        >
          {props.record?.toOrganization?.name}
        </span>
      ),

      onHeaderCell: () => ({
        ordering: {
          orderBy: ColumnIDs.ACCOUNT_NAME,
          defaultOrder: QueryOrder.DESC,
          sortingKey: SORTING_TYPE.alphabetical,
        },
      }),
    },
    {
      id: ColumnIDs.USER_TYPE,
      title: t('Account access as'),
      width: 50,
      flex: true,
      cellRenderer: (props) => (
        <span className="hidden-sm-down">
          {props.record?.isOrgAdmin ? t('Admin user') : t('Normal user')}
        </span>
      ),
      onHeaderCell: () => ({
        ordering: {
          orderBy: ColumnIDs.USER_TYPE,
          defaultOrder: QueryOrder.DESC,
          sortingKey: SORTING_TYPE.alphabetical,
        },
      }),
    },
    {
      id: ColumnIDs.PAID_BY_YOU,
      title: t('Paid By You'),
      width: 50,
      flex: true,
      cellRenderer: (props) => (
        <span className="hidden-sm-down">
          {props.record?.fromOrganizationPays ? t('Yes') : t('No')}
        </span>
      ),
      onHeaderCell: () => ({
        ordering: {
          orderBy: ColumnIDs.PAID_BY_YOU,
          defaultOrder: QueryOrder.DESC,
          sortingKey: SORTING_TYPE.alphabetical,
        },
      }),
    },
    {
      id: ColumnIDs.NEXT_PAYMENT,
      title: t('Next Payment'),
      width: 50,
      flex: true,
      cellRenderer: (props) => (
        <span className="hidden-sm-down">
          {props.record?.toOrganization && props.record?.toOrganization?.activePlan
            ? props.record?.toOrganization?.activePlan?.endDate
            : '-'}
        </span>
      ),
      onHeaderCell: () => ({
        ordering: {
          orderBy: ColumnIDs.NEXT_PAYMENT,
          defaultOrder: QueryOrder.DESC,
          sortingKey: SORTING_TYPE.recurency,
        },
      }),
    },
  ];

  if (isAdmin) {
    cols.push({
      id: ColumnIDs.INVOICES,
      title: t('Invoices'),
      width: 150,
      flex: true,
      cellRenderer: (props) => (
        <span className="hidden-sm-down">
          {props.record?.fromOrganizationPays ? (
            tct('Find in [link:your invoice list]', {
              link: <Link to={'/account/billing'} />,
            })
          ) : props.record?.isOrgAdmin ? (
            <Link to={`/accounts/invoices/${props.record?.toOrganization?.id}`}>
              {t('Download')}
            </Link>
          ) : null}
        </span>
      ),
      onHeaderCell: () => ({
        ordering: {
          orderBy: ColumnIDs.INVOICES,
          defaultOrder: QueryOrder.DESC,
          sortingKey: SORTING_TYPE.alphabetical,
        },
      }),
    });
  }
  if (isAdmin || isSuperuser) {
    cols.push({
      id: ColumnIDs.ACTIONS,
      width: 150,
      cellRenderer: (props: CellRendererParams) => (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handleRemove(props.record?.id, props.record?.fromOrganizationPays);
          }}
        >
          {t('Remove access')}
        </a>
      ),
      className: 'no-border',
    });
  }
  return cols;
};
