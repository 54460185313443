import { Component } from 'react';
import { connect } from 'react-redux';
import { hideModal } from 'Actions/ModalAction';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { t } from 'Utilities/i18n';
import MoveDomainForm from './MoveDomainForm';

type Props = {
  hideModal?: any;
  initialValues: {
    domainId: string;
    groupId: string;
  } | null;
};

class MoveDomain extends Component<Props> {
  render() {
    const { initialValues } = this.props;
    return (
      <ModalBorder className="move-domain" title={t('Move Domain')} onClose={this.props.hideModal}>
        <MoveDomainForm initialValues={initialValues} />
      </ModalBorder>
    );
  }
}

export default connect(null, {
  hideModal,
})(MoveDomain);
