import React from 'react';
import cn from 'classnames';
import { CheckboxBodyCell } from './components/CheckboxBodyCell';
import './cell.scss';

const defaultCell = {
  checkbox: CheckboxBodyCell,
};

interface BodyCellBuiltinProps {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  cellId?: string;
  cellClassName?: string;
}

interface BodyCellProps {
  data: any;
  type: keyof typeof defaultCell;
  hideCondition?: any;
}

export const BodyCell = React.memo(
  ({
    children,
    className,
    type,
    data,
    hideCondition,
    ...props
  }: BodyCellProps & BodyCellBuiltinProps) => {
    const resultClassName = cn(className, '');
    let content = children;

    if (type && defaultCell[type]) {
      const DefaultContent: any = defaultCell[type];
      content = <DefaultContent data={data} {...props} />;
    }

    if (hideCondition?.(data)) {
      content = null;
    }
    return (
      <td {...props} className={resultClassName}>
        {content}
      </td>
    );
  },
);
BodyCell.displayName = 'BodyCell';
