import snakeCase from 'lodash/snakeCase';
import { getSERPimageUrl } from 'Components/AccuTable/CellRenderer/helpers/serp-features';
import { store } from 'Store';

export const GOOGLE_SEARCH_ENGINE_ID = 1;
export const YOUTUBE_SEARCH_ENGINE_ID = 5;

type SearchEngineId = typeof GOOGLE_SEARCH_ENGINE_ID | typeof YOUTUBE_SEARCH_ENGINE_ID;

export const getSerpFeaturesGroups = (searchEngineId: SearchEngineId) => {
  const state = store.getState();

  if (searchEngineId === GOOGLE_SEARCH_ENGINE_ID) {
    return state.metaData?.serpFeatures?.filter((e) => {
      return !e?.id?.includes('youtube_');
    });
  } else if (searchEngineId === YOUTUBE_SEARCH_ENGINE_ID) {
    return state.metaData?.serpFeatures?.filter((e) => {
      return e?.id?.includes('youtube_');
    });
  }

  return [];
};
export const getSerpFeatureData = (id: string) => {
  const snakeCaseId = snakeCase(id); // transform eg. "adsTop" to "ads_top"

  const state = store.getState();
  const serp = state?.metaData?.serpFeatures?.find(
    (feature) =>
      (feature?.id && feature.id === snakeCaseId) ||
      (feature?.owned && feature.owned === snakeCaseId),
  );
  const icon = getSERPimageUrl(snakeCaseId);

  return { ...serp, icon };
};
