import { useEffect } from 'react';
import { getIsKeywordLoading } from 'Components/AccuTable/CellRenderer/HelperComponents/CellLoaders/withKeywordCellLoader';
import { useRowContext } from 'Components/DataTable/table-core/context/RowContext';
import { WithKeywordTableExpandChildren } from 'Pages/Keywords/Table/support/types';
import type { ExpandableRowsConfig } from '../interface';
import BodyRow from './BodyRow';
import './body.scss';

interface ExtraBodyRowProps<T extends WithKeywordTableExpandChildren = any> {
  bodyRowProps: any;
  expandRowConfig: ExpandableRowsConfig;
  record: T;
}

export const ExtraBodyRows = ({ record, bodyRowProps, expandRowConfig }: ExtraBodyRowProps) => {
  const rowContext = useRowContext();
  const isLoading = getIsKeywordLoading()(record);

  useEffect(() => {
    if (isLoading && rowContext.expandExtraRows) {
      // https://accuranker.myjetbrains.com/youtrack/issue/ARR-2320
      rowContext.toggleExpandRows();
    }
  }, [isLoading]);

  if (isLoading || !expandRowConfig) {
    return null;
  }

  return (
    <>
      {(record as WithKeywordTableExpandChildren)?.expandChildren?.map((subRecord) => {
        const rowKey = `${subRecord?.highestRankingPage || ''}_${bodyRowProps['data-row-key']}`;
        return (
          <BodyRow
            key={rowKey}
            isSubRow
            {...bodyRowProps}
            data-row-key={rowKey}
            record={subRecord}
            cellComponent={({ cellId, ...props }) => {
              const visible = expandRowConfig.columnIds?.includes(cellId);
              return <td {...props}>{visible ? props.children : null}</td>;
            }}
          />
        );
      })}
    </>
  );
};
