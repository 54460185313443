import { Component } from 'react';
import { connect } from 'react-redux';
import { hideModal } from 'Actions/ModalAction';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import type { FilterSet } from 'Types/FilterSet';
import { t } from 'Utilities/i18n';
import EditFilterGroupForm from './EditFilterGroupForm';

type Props = {
  hideModal: (...args: Array<any>) => any;
  filterGroup: Record<string, any>;
  filterSet: FilterSet;
  onEdit: (...args: Array<any>) => any;
};

class EditFilterGroup extends Component<Props> {
  render() {
    const { onEdit, filterSet, filterGroup } = this.props;
    return (
      <ModalBorder
        className="edit-filter-group"
        title={t('Edit Segment')}
        onClose={this.props.hideModal}
      >
        <EditFilterGroupForm
          onClose={this.props.hideModal}
          onEdit={onEdit}
          filterGroup={filterGroup}
          filterSet={filterSet}
        />
      </ModalBorder>
    );
  }
}

export default connect(null, {
  hideModal,
})(EditFilterGroup);
