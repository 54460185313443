import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Box, Flex } from '@mantine/core';
import { IconShoppingCart } from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import FormatNumber from 'Components/FormatNumber';
import AccText from 'Components/Text/AccText';
import AccTitle from 'Components/Title/AccTitle';
import { adminRole } from 'Constants/workspaces';
import { useTotalWorkspacesQuery } from 'Ghql';
import { PlanOrganizationPlansInfo } from 'Query';
import { t } from 'Utilities/i18n';
import IconInfoBubble from 'icons/info-bubble.svg?inline';
import { QUERY_ORGANIZATION_PLAN_INFO } from '../../overuse.query';
import { getIsShowPaymentInfo } from '../../support/helpers';
import { OveruseInfoDetails } from './components/OveruseInfoDetails';
import { OveruseInfoPanelBlockList } from './components/OveruseInfoPanelBlockList';
import { OveruseInfoPlaceholder } from './components/OveruseInfoPlaceholder';
import './overuse-info-panel.scss';

export const OveruseInfoPanel = () => {
  const { data, loading } = useQuery<PlanOrganizationPlansInfo>(QUERY_ORGANIZATION_PLAN_INFO);
  const { data: workspaces } = useTotalWorkspacesQuery({ fetchPolicy: 'network-only' });
  if (loading) {
    return <OveruseInfoPlaceholder />;
  }
  const saveOnUpgrade = data?.keywordPrice?.saveOnUpgrade;
  const pricePerKeywordPerDay = data?.keywordPrice?.pricePerKeywordPerDay;
  const hasSaving = (saveOnUpgrade?.amount ?? 0) > 0;
  const organization = data?.user?.organization;
  const activePlan = organization?.activePlan;
  const permRoles = workspaces?.workspacesByOrganization?.length ?? 0;
  const maxNumbersOfWorkspaces =
    workspaces?.user?.organization?.activePlan?.maxNumbersOfWorkspaces ?? 0;
  const showPaymentInfo = getIsShowPaymentInfo(activePlan);
  const isOrg = data?.user?.userType === adminRole;
  const organizationActive = organization?.active;
  return (
    <Flex className="overuse-info-panel">
      <Flex style={{ flex: 2 }} direction="column">
        <Box mb="lg">
          <AccTitle type="h3" mb="md">
            {t('Plan Info')}
          </AccTitle>
          {isOrg ? (
            <OveruseInfoDetails
              isActive={organizationActive}
              activePlan={activePlan}
              nextPlan={organization?.nextPlan}
            />
          ) : (
            <div className="billing-box">
              {t('You need to be organization admin to update plan')}
            </div>
          )}
        </Box>
        {isOrg && (
          <OveruseInfoPanelBlockList
            keywordsCount={organization?.numberOfKeywords ?? 0}
            domainsCount={organization?.numberOfDomains ?? 0}
            usersCount={organization?.numberOfUsers ?? 0}
            permRoles={permRoles}
            maxKeywords={activePlan?.maxKeywords}
            maxDomains={activePlan?.maxDomains}
            maxUsers={activePlan?.maxUsers}
            maxPermRoles={maxNumbersOfWorkspaces}
          />
        )}
      </Flex>
      <Flex style={{ flex: 1 }} pl="lg">
        {isOrg && showPaymentInfo && organizationActive && (
          <div className="base-container-padding overuse-info-panel__price-info">
            {(pricePerKeywordPerDay?.amount ?? 0) > 0 && (
              <>
                <AccTitle type="h3" mb="md">
                  {t('Keyword price')}
                </AccTitle>
                <table>
                  <tbody>
                    <tr>
                      <td>{t('Current daily keyword price:')} </td>
                      <td className={'overuse-info-panel_td'}>
                        <b className="overuse-info-panel__price-info__price">
                          <FormatNumber
                            currency={pricePerKeywordPerDay?.currency ?? 'USD'}
                            precision={4}
                            customCurrency
                          >
                            {(pricePerKeywordPerDay?.amount ?? 0) /
                              (1 + parseFloat(organization?.keywordOveruseExtraCost))}
                          </FormatNumber>
                        </b>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td>{t('Exceeded daily keyword price:')} </td>
                      <td className={'overuse-info-panel_td'}>
                        <b className="overuse-info-panel__price-info__price">
                          <FormatNumber
                            currency={pricePerKeywordPerDay?.currency ?? 'USD'}
                            precision={4}
                            customCurrency
                          >
                            {pricePerKeywordPerDay?.amount ?? 0}
                          </FormatNumber>
                        </b>{' '}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
              </>
            )}

            <p>
              <br />
              <IconInfoBubble />
              <AccText size="sm" inline ml="xs">
                {t('You should upgrade your account to a ')}
                <b>
                  <FormatNumber>{saveOnUpgrade?.maxKeywordsOnUpgradePlan}</FormatNumber>

                  {t(' keywords')}
                </b>
                {!hasSaving && <>{t(' plan')}</>}
                {hasSaving && (
                  <>
                    {t(' plan, as it will save you ')}
                    <b>
                      <FormatNumber currency={saveOnUpgrade?.currency ?? 'USD'}>
                        {(saveOnUpgrade?.billingPeriod || 12) === 12
                          ? saveOnUpgrade?.amount || 0
                          : (saveOnUpgrade?.amount || 0) * 12}
                      </FormatNumber>
                    </b>
                    {t(' a year')}
                  </>
                )}
              </AccText>
            </p>
            <br />
            <Link to="/billing/package/select">
              <AccButton variant="primary" leftSection={<IconShoppingCart size={18} />}>
                {t('Upgrade my plan')}
              </AccButton>
            </Link>
          </div>
        )}
      </Flex>
    </Flex>
  );
};
