import { Link } from 'react-router-dom';
import { Box, Flex } from '@mantine/core';
import AccText from 'Components/Text/AccText';
import AccTitle from 'Components/Title/AccTitle';
import { t } from 'Utilities/i18n';

export const SuccessResetPassword = () => {
  return (
    <Flex direction="column" align="center" rowGap="md">
      <AccTitle type="h2" ta="center">
        {t('Reset your password')}
      </AccTitle>
      <AccText ta="center">
        {t('We have sent you a password reset email. Please check your inbox.')}
      </AccText>
      <Box mt="md">
        <Link to="/login" className="bold">
          <b>{t('Back to Log in')}</b>
        </Link>
      </Box>
    </Flex>
  );
};
