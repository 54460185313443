import NumberChangeEditor from 'Components/Filters/Common/NumberChangeEditor';
import { ChangeFilter, FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import { t } from 'Utilities/i18n/index';
import NoFilterIcon from 'icons/arrow-both-updown.svg?inline';
import RankIcon from 'icons/rank.svg?inline';
import { changeFilterLabelFunc } from '../helpers';

const defaultValue: ChangeFilter = {
  attribute: FilterAttribute.RANK_CHANGE,
  type: FilterValueType.NUMBER,
  comparison: FilterComparison.LT,
  value: 1,
};

const getData = () => ({
  defaultValue,
  title: t('Rank change'),
  icon: RankIcon,
  editor: NumberChangeEditor,
  editorProps: {
    isReversed: true,
  },
  tableEditor: NumberChangeEditor,
  tableEditorProps: {
    iconDropdown: true,
    noFilterIcon: NoFilterIcon,
  },
  labelFunc: (filter: ChangeFilter) => changeFilterLabelFunc(filter, true),
});

export default getData;
