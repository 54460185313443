import { useMemo } from 'react';
import { Box, Center } from '@mantine/core';
import { IconPlayerPause, IconWorldWww } from '@tabler/icons-react';
import AccSegmentedControl from 'Components/AccSegmentedControl/AccSegmentedControl';
import { t } from 'Utilities/i18n';

export type DisplayMode = 'domains' | 'paused';
export const PauseSwitch = ({
  display,
  canPause,
  onChange,
}: {
  display: DisplayMode;
  canPause: boolean;
  onChange: (value: DisplayMode) => void;
}) => {
  const segmentedControlData = useMemo(
    () => [
      {
        label: (
          <Center>
            <IconWorldWww size={14} />
            <Box ml="xs">{t('Domains')}</Box>
          </Center>
        ),
        value: 'domains',
      },
      {
        label: (
          <Center>
            <IconPlayerPause size={14} />
            <Box ml="xs">{t('Paused Domains')}</Box>
          </Center>
        ),
        value: 'paused',
      },
    ],
    [],
  );

  if (!canPause) {
    return null;
  }

  return (
    <AccSegmentedControl
      value={display}
      onChange={(value) => onChange(value as DisplayMode)}
      data={segmentedControlData}
      transitionDuration={100}
    />
  );
};
