import { Box } from '@mantine/core';
import { TooltipFormatterContextObject } from 'highcharts';
import { formatChartNumber } from 'Components/Chart/LineChart';
import { NOT_IN_TOP_100_VALUE } from 'Components/Chart/LineChart/support/constants';
import HighchartsSymbol from 'Components/Chart/Symbol';
import {
  getPointDate,
  getPointKeywordsCount,
} from 'Pages/Keywords/Overview/components/StockTooltipContent/helpers';
import { InjectedIntl } from 'Types/Intl';
import { t } from 'Utilities/i18n';
import { isPlaceholderPoint, placeholderTooltip } from '../HistoryCharts/support/lowData';

export type StockTooltipContentProps = {
  /**
   * Can't use `useIntl` - since we render it as a string inside highcharts.
   * so intl should be passed as property
   */
  intl: InjectedIntl;
  isRank?: boolean;
  title: string;
  point: TooltipFormatterContextObject;
  showPercentage: boolean;
  customNumFormatter?: any;
  bottomText?: string;
  customText?: string;
  dontShowTotalKeywords?: boolean;
};

export type MultiSeriesStockTooltipContentProps = {
  intl: InjectedIntl;
  isRank: boolean;
  point: TooltipFormatterContextObject;
  showPercentage: boolean;
  customNumFormatter?: any;
  bottomRightText?: string;
  includeTimeStamp?: boolean;
};

export const StockTooltipContent = ({
  intl,
  isRank,
  title,
  point,
  showPercentage,
  customNumFormatter,
  bottomText,
  customText,
  dontShowTotalKeywords,
}: StockTooltipContentProps) => {
  if (isPlaceholderPoint(point)) return placeholderTooltip();

  const totalKeywords = getPointKeywordsCount(point);
  const date = getPointDate(point);

  const getFormattedNumber = (num) => {
    if (isRank && num === NOT_IN_TOP_100_VALUE) {
      return t('Not in top 100');
    }
    return customNumFormatter?.(num) ?? formatChartNumber(intl, num || 0, showPercentage);
  };

  const num = getFormattedNumber(point.y);
  const keywords = formatChartNumber(intl, totalKeywords);
  const minWidth = 150;
  return (
    <div>
      <Box style={{ display: 'table', background: 'white', minWidth }}>
        <tr>
          <Box style={{ paddingBottom: '12px', color: '#7f8081' }}>{date}</Box>
        </tr>
        <tr>
          <Box style={{ display: 'flex', justifyContent: 'space-between', minWidth }}>
            <Box pr={12} style={{ flexWrap: 'nowrap' }}>
              {title}
            </Box>
            <Box style={{ textAlign: 'right', fontWeight: 'bold', flexWrap: 'nowrap' }}>{num}</Box>
          </Box>
        </tr>
        {customText ? (
          <tr>
            <Box style={{ display: 'flex', justifyContent: 'space-between', minWidth }}>
              {customText}
            </Box>
          </tr>
        ) : null}
        {dontShowTotalKeywords ? null : (
          <tr>
            <Box style={{ display: 'flex', justifyContent: 'space-between', minWidth }}>
              <div>{t('Total keywords')}</div>
              <Box style={{ textAlign: 'right', fontWeight: 'bold' }}>{keywords}</Box>
            </Box>
          </tr>
        )}
      </Box>
      {bottomText && (
        <Box
          style={{
            marginTop: '12px',
            color: 'gray',
            whiteSpace: 'initial',
            padding: 0,
          }}
        >
          {bottomText}
        </Box>
      )}
    </div>
  );
};

export const MultiSeriesStockTooltipContent = ({
  intl,
  isRank,
  point,
  showPercentage,
  customNumFormatter,
  includeTimeStamp,
}: MultiSeriesStockTooltipContentProps) => {
  const date = getPointDate(point, includeTimeStamp);
  const points = point.points;

  const getFormattedNumber = (num: number) => {
    if (isRank && num === NOT_IN_TOP_100_VALUE) {
      return t('Not in top 100');
    }
    return customNumFormatter?.(num) ?? formatChartNumber(intl, num || 0, showPercentage);
  };

  return (
    <div>
      <div className="chart-tooltip-table">
        <div className="chart-tooltip-table-tr">
          <div className="chart-tooltip-table-th">{date}</div>
        </div>
        {points
          ?.sort((a, b) => (isRank ? (a?.y || 0) - (b?.y || 0) : (b?.y || 0) - (a?.y || 0)))
          .map((e: any) => {
            return (
              <div className="chart-tooltip-table-tr" key={e?.series?.name}>
                <div className="chart-tooltip-table-td--no-padding">
                  <HighchartsSymbol symbol={e?.series?.symbol} color={e.color} size={16} />{' '}
                  {e?.series?.name}
                </div>
                <div
                  className="chart-tooltip-table-td--no-padding chart-tooltip-table-right"
                  style={{ textAlign: 'right', fontWeight: 'bold' }}
                >
                  {getFormattedNumber(e.y)}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
