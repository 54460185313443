import React from 'react';
import AccPaper from 'Components/AccPaper/index';
import AccTitle from 'Components/Title/AccTitle';
import styles from 'Pages/Keywords/Overview/components/ChartContainer/container-header.module.scss';
import { t } from 'Utilities/i18n/index';
import { OverviewNotificationsTable } from './OverviewNotificationsTable';

export const OverviewNotifications = () => {
  return (
    <AccPaper px="lg" py="md" className={styles.container}>
      <AccTitle
        boxProps={{ mb: 'xs' }}
        type="h3"
        helper={t(
          'View any keyword notifications for the keyword set. Messages are only created ' +
            'for keywords that are starred. You can star a keyword from the keyword list if you wish to ' +
            'follow them closely. ',
        )}
      >
        {t('Notifications')}
      </AccTitle>
      <OverviewNotificationsTable />
    </AccPaper>
  );
};
