import { Skeleton } from '@mantine/core';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { t } from 'Utilities/i18n';
import { fixLeadingSlash, getSubdomainIfSelected, isOtherNode, pathLink } from '../support/helpers';
import { SitemapNode } from '../support/types';
import styles from '../siteMapping.module.scss';

type PathAndTitleViewProps = {
  loading: boolean;
  selectedNode: SitemapNode | null;
  includingChildren: boolean;
};

const isRootNode = (node: SitemapNode | null) => node?.title === '/';

const titleOrDefaultValue = (node: SitemapNode | null) => {
  if (isOtherNode(node)) {
    return <i>{t('No page title found')}</i>;
  }

  if (node?.kwECT === 0) {
    return <i>{t('Page title available only with tracked keywords')}</i>;
  }

  const title = node?.title;
  if (!title || title === '-') {
    return <i>{t('No page title found')} </i>;
  }
  return title;
};

const getNodeTitle = (node: SitemapNode | null) => {
  return isRootNode(node) ? node?.name : titleOrDefaultValue(node);
};

// Special case names for root node and "Other" nodes
const pathToShow = (node: SitemapNode | null, subpages: boolean) => {
  if (isOtherNode(node)) {
    return t('Remaining subpages');
  }
  if (node?.pathSegment === '/') {
    return getSubdomainIfSelected() ?? node?.name; // Domain in this case
  }
  const path = fixLeadingSlash(node?.pathSegment || '');

  return subpages ? `${String.fromCharCode(0x2217)}/${path}` : path;
};

const PathAndTitleView = ({
  loading,
  selectedNode,
  includingChildren,
}: PathAndTitleViewProps): JSX.Element => {
  if (loading) {
    return (
      <div className={styles.detailPanel}>
        <Skeleton width={200} height={20} mb={'sm'} mt={'sm'} />
        <Skeleton width={400} height={14} mb={6} />
      </div>
    );
  }

  const showTT = selectedNode?.url && selectedNode?.url?.length > 1;
  return (
    <div className={styles.detailPanel}>
      <AccTooltip tooltip={selectedNode?.url} disable={!showTT}>
        <a
          className="table-link"
          href={pathLink(selectedNode)}
          target={'_blank'}
          rel="noopener noreferrer"
        >
          <span className={styles.mainPathLink}>{pathToShow(selectedNode, includingChildren)}</span>
        </a>
      </AccTooltip>
      <div>
        {includingChildren ? <span>{t('Including subpages')} </span> : getNodeTitle(selectedNode)}
      </div>
    </div>
  );
};

export default PathAndTitleView;
