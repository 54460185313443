import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { t } from 'Utilities/i18n';
import LoadingClock from './LoadingClock';
import './support/keyword-loading-cell.scss';

type CellLoaderConfig = {
  dependsOnSearchVolume?: boolean;
  getIsLoading?: (data: any) => boolean;
  onlyGoogle?: boolean;
};

export type CellLoaderProps = {
  getTooltip?: (data: any) => string;
  emptyContent?: boolean;
  overlayOnly?: boolean;
} & CellLoaderConfig;

export const SEARCH_ENGINE_GOOGLE = 1;

export const getIsKeywordLoading =
  <T extends CellLoaderConfig>(config?: T) =>
  (data) => {
    // Including the check for searchVolumeValue logic should not always apply.
    // Only for search volume cell, cpc cell, share of voice cell, AI SoV cell, ad comp cell, traffic value cell.
    // The reason the check is needed for some cells and not for others is that some cells depend on the search volume
    let isLoading = config?.getIsLoading
      ? config.getIsLoading(data)
      : data?.updatingKeyword ||
        (config?.dependsOnSearchVolume ? data?.searchVolumeValue === null : false);

    if (
      (config?.dependsOnSearchVolume && !data?.validForSearchVolume) ||
      (config?.onlyGoogle && data?.searchEngineId !== SEARCH_ENGINE_GOOGLE)
    ) {
      isLoading = false;
    }

    return isLoading;
  };

export const withKeywordCellLoader = <T extends object>(
  Component: React.ComponentType<React.PropsWithChildren<T>>,
  config?: CellLoaderProps,
) => {
  function ResultComponent(props: T & any): JSX.Element | null {
    const data = props.value?.data;
    const isLoading = getIsKeywordLoading(config)(data);

    if (props.loadingData) {
      return null;
    }

    const tooltipMessage = config?.getTooltip?.(data) || t('Refreshing data…');

    return (
      <>
        {Component && <Component {...props} />}
        {isLoading && (
          <>
            <AccTooltip tooltip={tooltipMessage}>
              <div className="keyword-cell-overlay" data-testid="keyword-cell-overlay" />
            </AccTooltip>
            {!config?.overlayOnly && <LoadingClock className="keyword-cell-loader" />}
          </>
        )}
      </>
    );
  }
  ResultComponent.displayName = `withKeywordCellLoader(${
    Component?.displayName || Component?.name || 'Component'
  })`;

  return ResultComponent;
};
