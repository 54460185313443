import cn from 'classnames';
import FormatNumber from 'Components/FormatNumber/new';
import { PausedDomainFragment } from 'Ghql';
import styles from 'Pages/Domains/DomainsTable/domains-table.module.scss';

type Props = {
  record: PausedDomainFragment;
};

const KeywordsCell = ({ record }: Props) => {
  const { totalKeywords } = record;

  return (
    <div className={cn(styles.domainsCellContainer, styles.numberCell)}>
      {totalKeywords && <FormatNumber className={styles.left} value={totalKeywords} />}
    </div>
  );
};

export default KeywordsCell;
