import React, { useEffect, useRef, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import Breadcrumbs from 'Components/Breadcrumbs/Breadcrumbs';
import { useTableStore } from 'Components/DataTable';
import { getSearchCombo } from 'Components/Modal/Content/Shortcuts';
import SearchInput from 'Components/SearchInput';
import { GeneratedReportFragment, useDeleteGeneratedReportMutation } from 'Ghql';
import toast from 'Hooks/useToast';
import ActionbarContainer from 'Pages/Layout/ActionsMenu/components/ActionbarContainer';
import { TableIDs } from 'Types/Table';
import { invalidateCache } from 'Utilities/Graphql/invalidateCache';
import { t } from 'Utilities/i18n';
import * as Mousetrap from 'Utilities/mousetrap';
import { isMac } from 'Utilities/underdash';
import reusableStyles from 'css/reusable-styles.module.scss';
import GeneratedReportsTable from './generatedReportsTable';

type State = {
  searchTerm: string;
  isLoading: boolean;
  dataKey: number;
};

const initialState = {
  searchTerm: '',
  isLoading: false,
  dataKey: 0,
};

const GeneratedReports = () => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [{ searchTerm, isLoading, dataKey }, setState] = useState<State>(initialState);

  const tableStore = useTableStore(TableIDs.GENERATED_REPORTS);
  const client = useApolloClient();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [deleteGeneratedReport, _rest] = useDeleteGeneratedReportMutation();

  useEffect(() => {
    const shortcut = isMac() ? 'command+f' : 'ctrl+f';
    Mousetrap.bind(shortcut, '', () => {
      return inputRef?.current?.focus() || false;
    });
    return () => {
      Mousetrap.unbind(['command+f', 'ctrl+f']);
    };
  }, []);

  const handleUpdate = () => {
    setState((prevState) => ({
      ...prevState,
      dataKey: prevState.dataKey + 1,
    }));
  };

  const handleChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      searchTerm: (event.target as HTMLInputElement).value,
    }));
  };

  const handleLoading = (loading: boolean) =>
    setState((prevState) => ({
      ...prevState,
      isLoading: loading,
    }));

  const handleDeleteGeneratedReport = ({ id }: GeneratedReportFragment) => {
    const input = {
      id,
      delete: true,
    };

    deleteGeneratedReport({
      variables: {
        input,
      },
    }).then((response) => {
      const generatedReport = response.data?.updateGeneratedReport?.generatedReport;

      if (generatedReport) {
        toast.error(t('Unable to delete report'));
      } else {
        tableStore?.deleteItem(id);
        toast.success(t('Report deleted'));
        invalidateCache(client.cache, 'generatedReports');
      }
    });
  };

  return (
    <>
      <Breadcrumbs />
      <ActionbarContainer mt="md">
        <SearchInput
          ref={inputRef}
          key="search-input"
          value={searchTerm}
          disabled={isLoading}
          onChange={handleChange}
          placeholder={t('Reports…')}
          keyboardShortcut={getSearchCombo()}
          autoFocus
        />
      </ActionbarContainer>
      <div className={reusableStyles.paper}>
        <GeneratedReportsTable
          handleDelete={handleDeleteGeneratedReport}
          searchTerm={searchTerm}
          handleLoading={handleLoading}
          dataKey={dataKey}
          handleUpdateTable={handleUpdate}
        />
      </div>
    </>
  );
};

export default GeneratedReports;
