import { useContext } from 'react';
import { TreeContext } from 'Pages/Keywords/Groupings/EditMode/react-dnd-treeview/providers';
import { TreeState } from 'Pages/Keywords/Groupings/EditMode/react-dnd-treeview/types';

export const useTreeContext = <T>(): TreeState<T> => {
  const treeContext = useContext<TreeState<T>>(
    TreeContext as unknown as React.Context<TreeState<T>>,
  );

  return treeContext;
};
