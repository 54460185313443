export const AuthRoutePath = {
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgotpassword',
  CHANGE_PASSWORD: '/resetpassword',
} as const;
export const RoutePath = {
  ACCOUNT_REQUEST_EXTERNAL_REQUEST: '/account/multiaccount/allow/:code',
  ACCOUNT_EXTERNAL_ACCESS: '/account/external-access',
  ACCOUNT_KEYWORD_OVERUSE: '/account/keyword-usage',
} as const;

export const StandAlonePath = {
  SALES_MARKET_ANALYSIS: '/market-analysis',
  REGISTER: '/register',
  CHECKOUT: '/checkout',
  PUBLIC_REPORT: 'reports/public',
} as const;
