import { SelectItem } from 'Components/AccSelect';

export const createCustomSelectOption = <T extends SelectItem = SelectItem>(
  customOption: (props: T) => JSX.Element,
) => {
  const CustomOptionComponent = customOption;
  const resultComponent = (props: T) => {
    return <CustomOptionComponent {...props} />;
  };
  resultComponent.displayName = 'CustomSelectOption';
  return resultComponent;
};
