import Switch from 'Components/Switch';
import { t } from 'Utilities/i18n';
import { siteMappingTreeModes } from '../support/helpers';
import { TreeViewMode } from '../support/types';

interface TreeModeSwitchProps {
  viewMode: TreeViewMode;
  setViewMode: (newMode: TreeViewMode) => void;
}

export const TreeModeSwitch = ({ viewMode, setViewMode }: TreeModeSwitchProps) => {
  return (
    <Switch
      key="treeViewMode"
      activeId={viewMode.id}
      width={86}
      style={{ marginLeft: 'auto' }}
      disabled={false}
      els={siteMappingTreeModes}
      onClick={(el: TreeViewMode) => {
        setViewMode(el);
      }}
      activeById
      tooltips={[t('View tree'), t('View table')]}
    />
  );
};
