import { ApolloClient } from '@apollo/client';
import { DomainInfoDocument, DomainInfoDomainFragment } from 'Ghql';

export const updateDomainInfoCache = ({
  client,
  domainId,
  name,
  patch,
  currentDomainInfoQueryDomain,
}: {
  client: ApolloClient<any>;
  domainId: string;
  patch: Partial<DomainInfoDomainFragment>;
  name?: string;
  currentDomainInfoQueryDomain?: DomainInfoDomainFragment;
}) => {
  if (!domainId) return;
  const domain: DomainInfoDomainFragment = {
    ...(currentDomainInfoQueryDomain || { id: domainId, __typename: 'DomainNode' }),
    ...patch,
  } as DomainInfoDomainFragment;
  try {
    client.writeQuery({
      query: DomainInfoDocument,
      variables: { input: { id: domainId, name } },
      data: {
        domain,
      },
    });
  } catch (e) {
    console.error('Failed to update cache in onFullScrapeComplete', e);
  }
};
