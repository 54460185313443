import { useMemo } from 'react';
import { DataTable, QueryOrder, SelectRowType } from 'Components/DataTable';
import { TableIDs } from 'Types/Table';
import { t } from 'Utilities/i18n';
import { ColumnIDs } from './helpers/ColumnIDs';
import { useFetchGSCKeywords, useGSCKeywordsColumns } from './helpers/hooks';

type GSCKeywordsTableProps = {
  dataKey: number;
  domainId: string | undefined;
};

const GSCKeywordsTable = ({ domainId, dataKey }: GSCKeywordsTableProps) => {
  const fetchData = useFetchGSCKeywords({ domainId });
  const columns = useGSCKeywordsColumns();

  const emptyOptions = useMemo(
    () => ({
      title: t('No Keywords'),
      subTitle: t('There are currently no keywords to import.'),
    }),
    [],
  );

  const selectConfig = useMemo(() => ({ selectRowType: SelectRowType.MULTI_PAGE_SELECT }), []);
  const defaultOrdering = useMemo(
    () => ({
      order: QueryOrder.DESC,
      orderBy: ColumnIDs.IMPRESSIONS,
    }),
    [],
  );

  const offlineFilter = useMemo(
    () => ({
      skip: ['domains'],
      tableName: TableIDs.IMPORT_GSC,
      skipAll: false,
      mappings: {
        gscExists: 'exists',
        gscPosition: 'position',
        gscCtr: 'ctr',
      },
    }),
    [],
  );

  return (
    <DataTable
      tableId={TableIDs.IMPORT_GSC}
      selectConfig={selectConfig}
      dataKey={dataKey?.toString()}
      fetchData={fetchData}
      columns={columns}
      emptyOptions={emptyOptions}
      defaultOrdering={defaultOrdering}
      offlineFilter={offlineFilter}
    />
  );
};

export default GSCKeywordsTable;
