import { defaultRole } from 'Constants/workspaces';
import {
  CHANGE_IS_PARTNER,
  CHANGE_LANGUAGE,
  DISABLE_LOCALE_DEBUG,
  ENABLE_LOCALE_DEBUG,
  UPDATE_USER_GOOGLE_CONNECTIONS,
  UPDATE_USER_ORGANIZATION,
  UPDATE_USER_SETTINGS,
} from '../Actions/UserAction';
import { getDefaultLanguage } from '../Components/BaseContainer/helpers';

const initialState = {
  email: null,
  fullName: null,
  userType: defaultRole,
  language: getDefaultLanguage(),
  organization: {
    isPartner: false,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return { ...state, language: action.language };

    case ENABLE_LOCALE_DEBUG:
      return { ...state, debug: true };

    case DISABLE_LOCALE_DEBUG:
      return { ...state, debug: false };

    case CHANGE_IS_PARTNER:
      return { ...state, organization: { ...state.organization, isPartner: action.isPartner } };

    case UPDATE_USER_SETTINGS:
      return { ...state, ...action.userSettings };

    case UPDATE_USER_GOOGLE_CONNECTIONS:
      return { ...state, googleConnections: action.googleConnections };

    case UPDATE_USER_ORGANIZATION:
      return { ...state, organization: { ...state.organization, ...action.payload } };

    default:
      return state;
  }
}
