import { useSelector } from 'react-redux';
import { NO_SUB_DOMAIN_NAME } from 'Components/Filters/Editors/Subdomain/constants';
import { useSubDomainFilterData } from 'Components/Filters/Editors/Subdomain/support/hooks';
import { selectAllFilters } from 'Selectors/FilterSelector';
import { FilterAttribute, FilterComparison, FilterValueType, SUBDOMAIN } from 'Types/Filter';

export const useSiteMappingFilters = () => {
  const filters = useSelector(selectAllFilters);
  const { data, loading } = useSubDomainFilterData();
  const defaultSubdomain = data?.domainsList?.[0]?.subdomains?.[0]; // Hint: Despite naming, the subdomains in this context are actually all the different subdomains including no subdomain, e.g. [example.com, sub.example.com, sub.sub.example.com]
  const subdomainFilter = filters?.find((e) => e.attribute === FilterAttribute.SUBDOMAIN);

  const defaultSubdomainFilter =
    subdomainFilter || defaultSubdomain === NO_SUB_DOMAIN_NAME
      ? undefined
      : {
          attribute: SUBDOMAIN,
          type: FilterValueType.STRING,
          comparison: FilterComparison.EQ,
          value: defaultSubdomain,
        }; // In case there is no subdomain filter, we set a "hidden" subdomain filter which shows the default subdomain.

  return {
    loadingSubdomains: loading,
    defaultSubdomainFilter,
  };
};
