import React from 'react';
import { Divider } from '@mantine/core';
import cn from 'classnames';
import NavbarFooter from './components/NavbarFooter';
import NavbarHeader from './components/NavbarHeader';
import NavbarItems from './components/NavbarItems';
import styles from './side-navbar.module.scss';

type Props = {
  navbarCollapsed: boolean;
  toggleNavbarCollapse: () => void;
};

const SideNavbar = (props: Props): JSX.Element => {
  const { toggleNavbarCollapse, navbarCollapsed } = props;

  return (
    <div className={cn(styles.navbarContainer, { [styles.collapsed]: navbarCollapsed })}>
      <NavbarHeader navbarCollapsed={navbarCollapsed} />

      <Divider color="gray.6" className={styles.divider} />

      <NavbarItems navbarCollapsed={navbarCollapsed} />

      <Divider color="gray.6" mt="auto" className={styles.divider} />

      <NavbarFooter navbarCollapsed={navbarCollapsed} toggleNavbarCollapse={toggleNavbarCollapse} />
    </div>
  );
};

export default React.memo(SideNavbar);
