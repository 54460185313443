import { useDragLayer } from 'react-dnd';
import { DragLayerMonitorProps } from 'Pages/Keywords/Groupings/EditMode/react-dnd-treeview/types';

export const useTreeDragLayer = (): DragLayerMonitorProps => {
  return useDragLayer((monitor) => {
    const itemType = monitor.getItemType();

    return {
      item: monitor.getItem(),
      clientOffset: monitor.getClientOffset(),
      isDragging: monitor.isDragging() && itemType === 'item',
    };
  });
};
