import { useMemo } from 'react';
import { ColumnType } from 'Components/DataTable';
import { StoreType } from 'Types/Store';
import { useTableSetting } from './support/config';
import { filterVisibleColumns, formatTableColumnsByTableSettings } from './support/helpers';
import { useStateColumns } from './support/hooks/useStateColumns';
import { TableSettingVersion } from './support/types';

export const useFilteredTableColumns = (columns: ColumnType[], tableName: string): any[] => {
  const {
    selector,
    selectGroups = (_a: StoreType) => null,
    columns: columnSettings,
    version,
    groups,
  } = useTableSetting(tableName);
  const tableSettings = useStateColumns(selector);
  const groupSettings = useStateColumns(selectGroups);

  return useMemo(() => {
    if (version === TableSettingVersion.GROUPS) {
      return formatTableColumnsByTableSettings({
        columns,
        groups: groups || null,
        groupSettings,
        tableSettings,
      });
    }
    return filterVisibleColumns(columns, columnSettings || [], tableSettings);
  }, [columnSettings, tableSettings, columns, version, groups, groupSettings]);
};
