import { Box, BoxComponentProps } from '@mantine/core';
import FormatNumber from 'Components/FormatNumber/new';
import useFilterCount from 'Hooks/useFilterCount';
import styles from './filters.module.scss';

type Props = BoxComponentProps & {
  comparison: any;
  value: any;
  type: any;
  attribute: any;
};
const FilterCountField = (props: Props) => {
  const { comparison, value, type, attribute, ...rest } = props;
  const count = useFilterCount({
    comparison: comparison.input.value,
    value: value.input.value,
    type: type.input.value,
    attribute: attribute.input.value,
  });
  if (count === null || count === undefined) return null;
  return (
    <Box className={styles.filterCountField} {...rest}>
      (<FormatNumber value={count} />)
    </Box>
  );
};

export default FilterCountField;
