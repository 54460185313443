import { Grid } from '@mantine/core';
import Breadcrumbs from 'Components/Breadcrumbs/Breadcrumbs';
import AccTitle from 'Components/Title/AccTitle';
import { useRouteMatch } from 'Utilities/Router/hooks/useRouteMatch';
import { t } from 'Utilities/i18n';
import Preview from './Preview';
import AddForm from './ScheduledReportBuilderForm/Add';
import EditForm from './ScheduledReportBuilderForm/Edit';
import './scheduled-report-builder.scss';

const ScheduledReportBuilder = () => {
  const match = useRouteMatch();
  const id = match?.params?.id;
  const Form = id ? EditForm : AddForm;

  return (
    <>
      <Breadcrumbs
        customParentTitle={t('Reporting')}
        customParentLink="/reports/scheduled"
        customTitle={t('Report Builder')}
      />
      <Grid className="scheduled-report-builder-wrapper">
        <Grid.Col span={{ xs: 5 }}>
          <Form id={id} />
        </Grid.Col>
        <Grid.Col span={{ xs: 7 }}>
          <div className="preview-header">
            <AccTitle type="h2">{t('Preview')}</AccTitle>
            <p>
              <small>{t('The preview is PDF only and includes no more than 100 keywords.')}</small>
            </p>
            <p>
              <small>
                <strong>
                  {t(
                    'Please note, this is only visual preview. The data in the preview does not reflect the real data!',
                  )}
                </strong>
              </small>
            </p>
            <hr />
          </div>
          <Preview />
        </Grid.Col>
      </Grid>
    </>
  );
};

export default ScheduledReportBuilder;
