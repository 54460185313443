import every from 'lodash/every';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';
import some from 'lodash/some';
import moment, { MomentInput } from 'moment';
import type { FilterBase } from 'Types/Filter';
import { FilterComparison, FilterValueType } from 'Types/Filter';

const compareDateTime = (val: string, filter: FilterBase) => {
  if (filter.comparison === FilterComparison.BETWEEN) {
    return Array.isArray(filter.value)
      ? moment(val).isBetween(filter.value[0], filter.value[1])
      : false;
  }
  if (!isString(filter.value)) {
    return false;
  }
  const value = isString(filter.value) ? filter.value : null;

  switch (filter.comparison) {
    case FilterComparison.EQ:
      return value ? moment(val).isSame(value as MomentInput) : false;
    case FilterComparison.LT:
      return value ? moment(val).isSameOrBefore(value as MomentInput) : false;
    case FilterComparison.GT:
      return value ? moment(val).isSameOrAfter(value as MomentInput) : false;
    default:
      return false;
  }
};

const regexCache = {};

function testWithCache(pattern, string) {
  // Check if the pattern is already in the cache
  if (!regexCache[pattern]) {
    // If not, create a new RegExp object and save it to the cache
    regexCache[pattern] = new RegExp(pattern);
  }

  // Use the cached RegExp object to test the string
  return regexCache[pattern].test(string);
}

const compareString = (compareVal: string, filter: FilterBase) => {
  const value = filter.value?.toString()?.toLowerCase();
  const val = compareVal?.toString()?.toLowerCase();
  switch (filter.comparison) {
    case FilterComparison.EQ:
      return val === filter.value;

    case FilterComparison.NE:
      return val !== filter.value;

    case FilterComparison.CONTAINS:
      return val.includes(value);

    case FilterComparison.NOT_CONTAINS:
      return !val.includes(value);

    case FilterComparison.STARTS_WITH:
      return val.startsWith(value);

    case FilterComparison.ENDS_WITH:
      return val.endsWith(value);

    case FilterComparison.REGEX:
      return testWithCache(value, val);

    case FilterComparison.NOT_REGEX:
      return !testWithCache(value, val);

    default:
      return false;
  }
};

const compareBool = (val: boolean, filter: FilterBase) => {
  switch (filter.comparison) {
    case FilterComparison.EQ:
      return val === filter.value;

    default:
      return false;
  }
};

const compareNumber = (val: number, filter: FilterBase) => {
  switch (filter.comparison) {
    case FilterComparison.BETWEEN:
      return Number(filter.value[0]) <= val && val <= Number(filter.value[1]);

    case FilterComparison.EQ:
      return val === filter.value;

    case FilterComparison.NE:
      // TODO FixTSignore
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return val !== filter.value;

    case FilterComparison.LT:
      return val < Number(filter.value);

    case FilterComparison.LTE:
      return val <= Number(filter.value);

    case FilterComparison.GT:
      return val > Number(filter.value);

    case FilterComparison.GTE:
      return val >= Number(filter.value);

    default:
      return false;
  }
};

const compareArray = (val: string[], filter: FilterBase) => {
  const value = Array.isArray(filter.value) ? filter.value : [];
  switch (filter.comparison) {
    case FilterComparison.ANY:
      return some(value, (filterVal) => ~val.indexOf((filterVal as any)?.value || filterVal));
    case FilterComparison.ALL:
      return every(value, (filterVal) => ~val.indexOf((filterVal as any)?.value || filterVal));
    case FilterComparison.NONE:
      return every(
        value,
        (filterVal) => !~val.indexOf((filterVal as any)?.value || filterVal || ''),
      );
    default:
      return false;
  }
};

const compareList = (val: string[], filter: FilterBase) => {
  const value = Array.isArray(filter.value) ? filter.value : [];
  switch (filter.comparison) {
    case FilterComparison.CONTAINS:
      return some(value, (filterVal) => val?.includes((filterVal as any)?.value || filterVal));

    default:
      console.error('Unknown filter comparision:', filter.comparison); // eslint-disable-line
      console.info('val', val); // eslint-disable-line
      console.info('filter', filter); // eslint-disable-line

      return false;
  }
};

const compareWithFilter = (val: any, filter: FilterBase) => {
  switch (filter.type) {
    case FilterValueType.DATETIME:
      return compareDateTime(val, filter);

    case FilterValueType.STRING:
      return compareString(val, filter);

    case FilterValueType.NUMBER:
      return compareNumber(val, filter);

    case FilterValueType.BOOL:
      return compareBool(val, filter);

    case FilterValueType.ARRAY:
      return compareArray(val, filter);

    case FilterValueType.LIST:
      return compareList(val, filter);

    default:
      console.error('Unknown filter type:', (filter as any)?.type ?? ''); // eslint-disable-line

      console.info('val', val); // eslint-disable-line

      console.info('filter', filter); // eslint-disable-line

      return false;
  }
};
export default compareWithFilter;

/**
 * Compare function that ignores functions and use isEqual to make sure events.
 * Use case:
 * - we pass a group of functions (not wrapped with useCallback) and some values,
 * *(without this function memo won't work since function reference always change)
 */
export const propsIsEqualComparison = (nextProps: object, prevProps: object) => {
  return Object.keys(nextProps).every((key) => {
    if (isFunction(nextProps[key]) && isFunction(prevProps[key])) {
      return true;
    }
    return isEqual(nextProps, prevProps);
  });
};

export const customPropsIsComparison =
  <T>(propsToCompare: (keyof T | ((object: T) => unknown))[]) =>
  (prevProps, nextProps) => {
    return propsToCompare.every((path) => {
      const getValue = (props) => {
        if (isFunction(path)) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return path(props);
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return get(props, path);
      };
      return isEqual(getValue(nextProps), getValue(prevProps));
    });
  };
