import { SiteMapMode, SitemapNode } from '../../support/types';

/**
 * Weighted average for CTR. The CTR is weighted by search volume.
 */
const getCombinedCtr = (rootNode: SitemapNode, includingChildren: boolean) => {
  let clickThroughValue: number;
  if (includingChildren) {
    if (rootNode.svICU + rootNode.svICT > 0) {
      clickThroughValue =
        (rootNode.ctrICU * rootNode.svICU + rootNode.ctrICT * rootNode.svICT) /
        (rootNode.svICU + rootNode.svICT);
    } else {
      // If the total search volume is 0, we just make a regular average of untracked and tracked CTR
      clickThroughValue = rootNode.ctrICU + rootNode.ctrICT / 2;
    }
  } else if (rootNode.svECU + rootNode.svECT > 0) {
    clickThroughValue =
      (rootNode.ctrECU * rootNode.svECU + rootNode.ctrECT * rootNode.svECT) /
      (rootNode.svECU + rootNode.svECT);
  } else {
    // If the total search volume is 0, we just make a regular average of untracked and tracked CTR
    clickThroughValue = rootNode.ctrECU + rootNode.ctrECT / 2;
  }
  return clickThroughValue;
};

/**
 * Get various data fields in the correct format for the TreeTable
 *
 * will return data depending on the viewmode of the Organic Site Explorer tab
 * */
export const getTreeTableData = (rootNode: SitemapNode, viewModeId: SiteMapMode) => {
  let estimatedVisits: number,
    estimatedVisitsIncludingChildren: number,
    trafficValue: number,
    trafficValueIncludingChildren: number,
    clickThroughValue: number,
    clickThroughValueIncludingChildren: number,
    searchVolume: number,
    searchVolumeIncludingChildren: number;

  // There are two viewmodes, one in which we only show stats for tracked keywords, and one where
  // it is the sum of tracked and untracked keywords
  if (viewModeId === SiteMapMode.TRACKED_ONLY) {
    estimatedVisits = rootNode.tECT;
    estimatedVisitsIncludingChildren = rootNode.tICT;
    clickThroughValue = rootNode.ctrECT;
    clickThroughValueIncludingChildren = rootNode.ctrICT;
    trafficValue = rootNode.tvECT;
    trafficValueIncludingChildren = rootNode.tvICT;

    searchVolume = rootNode.svECT;
    searchVolumeIncludingChildren = rootNode.svICT;
  } else {
    estimatedVisits = rootNode.tECU + rootNode.tECT;
    estimatedVisitsIncludingChildren = rootNode.tICU + rootNode.tICT;
    clickThroughValue = getCombinedCtr(rootNode, false);
    clickThroughValueIncludingChildren = getCombinedCtr(rootNode, true);
    trafficValue = rootNode.tvECU + rootNode.tvECT;
    trafficValueIncludingChildren = rootNode.tvICU + rootNode.tvICT;
    searchVolume = rootNode.svECU + rootNode.svECT;
    searchVolumeIncludingChildren = rootNode.svICU + rootNode.svICT;
  }

  const trackedKeywords = rootNode.kwECT;
  const trackedKeywordsIncludingChildren = rootNode.kwICT;

  const includeUntrackedKeywords = viewModeId === SiteMapMode.TRACKED_AND_UNTRACKED;
  const untrackedKeywords = includeUntrackedKeywords ? rootNode.kwECU : '-';
  const untrackedKeywordsIncludingChildren = includeUntrackedKeywords ? rootNode.kwICU : '-';

  return {
    estimatedVisits,
    estimatedVisitsIncludingChildren,
    trafficValue,
    trafficValueIncludingChildren,
    clickThroughValue,
    clickThroughValueIncludingChildren,
    trackedKeywords,
    trackedKeywordsIncludingChildren,
    untrackedKeywords,
    untrackedKeywordsIncludingChildren,
    searchVolume,
    searchVolumeIncludingChildren,
  };
};
