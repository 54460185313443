import cn from 'classnames';
import styles from './page-header-container.module.scss';

type Props = {
  children?: React.ReactNode;
  className?: string;
  breadcrumbs?: boolean;
};
const PageHeaderContainer = ({ children, className, breadcrumbs }: Props) => {
  return (
    <div
      className={cn(styles.pageHeaderContainer, className, {
        [styles.breadcrumbsContainer]: breadcrumbs,
      })}
    >
      {children}
    </div>
  );
};

export default PageHeaderContainer;
