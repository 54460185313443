import { Center, Flex } from '@mantine/core';
import { scaleSequentialLog } from 'd3-scale';
import { BaseType } from 'd3-selection';
import { CustomNodeElementProps } from 'Components/Chart/TreeChart';
import linkWithFilters from 'Components/Filters/linkWithFilters';
import {
  defaultBillionsSettings,
  defaultMillionsSettings,
  defaultThousandsSettings,
  formatNumberHelper,
} from 'Components/FormatNumber/formatNumberHelper';
import { useFilters } from 'Hooks';
import {
  COMPETITOR_DOMAIN,
  COMPETITOR_URL,
  CompetitorDomainFilter,
  FilterAttribute,
  FilterBase,
  FilterComparison,
  FilterValueType,
  HighestRankingPageFilter,
  SUBDOMAIN,
  StringComparison,
} from 'Types/Filter';
import { KEYDIS_FILTER_SET, SITE_MAPPING_FILTER_SET } from 'Types/FilterSet';
import { t } from 'Utilities/i18n';
import { trimTrailingSlash } from 'Utilities/underdash';
import KeyDisIcon from 'icons/menu/compass-keydis.svg?inline';
import KeywordsIcon from 'icons/menu/keywords.svg?inline';
import NodeTreeIcon from 'icons/node-tree.svg?inline';
import TableIcon from 'icons/table.svg?inline';
import { TokenGroup, TokenGroupType } from '../../DomainSettings/support/types';
import { OTHER } from './constants';
import { SiteMapMode, SiteMapViewMode, SitemapNode, TreeMode, TreeViewMode } from './types';

export const linkToKeydisCompetitor = (domain: string, path?: string): string => {
  const competitorDomainFilter: CompetitorDomainFilter = {
    attribute: FilterAttribute.COMPETITOR_DOMAIN,
    type: FilterValueType.STRING,
    comparison: FilterComparison.EQ,
    value: domain,
  };

  const filters = [competitorDomainFilter];

  if (path) {
    const highestRankingPageFilter: any = {
      attribute: FilterAttribute.COMPETITOR_URL,
      type: FilterValueType.STRING,
      comparison: FilterComparison.EQ,
      value: path,
    };
    filters.push(highestRankingPageFilter);
  }

  return linkWithFilters({
    to: '/keywords/keyword_discovery',
    overwriteFilters: filters,
    filterSet: KEYDIS_FILTER_SET,
    removeFilters: [SUBDOMAIN],
  });
};

export const getParentPath = (selectedNode: SitemapNode | null): string | undefined =>
  selectedNode?.pathSegment.slice(0, selectedNode?.pathSegment.indexOf(selectedNode.endOfPath));

export const getPercentageTracked = (selectedNode: SitemapNode): number => {
  const totalKeywords = selectedNode.kwECT + selectedNode.kwECU;

  return totalKeywords > 0 ? selectedNode.kwECT / totalKeywords : 0;
};

export const isOtherNode = (selectedNode: SitemapNode | null): boolean | undefined =>
  selectedNode?.endOfPath?.endsWith(OTHER.endOfPath);

export const getPathFilter = (
  path: string | undefined,
  comparison: StringComparison,
): HighestRankingPageFilter => ({
  attribute: FilterAttribute.HIGHEST_RANKING_PAGE,
  type: FilterValueType.STRING,
  comparison,
  // TODO FixTSignore
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  value: path,
});

export const linkToKeydis = (path: string | undefined, comparison: StringComparison): string => {
  const highestRankingPageFilter = getPathFilter(path, comparison);
  const filters = [highestRankingPageFilter];

  return linkWithFilters({
    to: '/keywords/keyword_discovery',
    newFilters: filters,
    filterSet: KEYDIS_FILTER_SET,
    removeFilters: [COMPETITOR_URL, COMPETITOR_DOMAIN],
  });
};

export const removeProtocolFromUrl = (url: string) =>
  url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '');

export const linkToKeywordList = (path: string | undefined, comparison: any): string => {
  const highestRankingPageFilter: any = {
    attribute: FilterAttribute.HIGHEST_RANKING_PAGE,
    type: FilterValueType.STRING,
    comparison,
    // TODO FixTSignore
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    value: path,
  };

  return linkWithFilters({
    to: '/keywords/list',
    newFilters: [highestRankingPageFilter],
    filterSet: SITE_MAPPING_FILTER_SET,
  });
};

export const linkToKeywordListCompetitor = (
  path: string | undefined,
  competitorUrl: string | undefined,
  comparison: string,
  isOwnDomain: boolean | undefined,
): string => {
  const highestRankingPageFilter: any = {
    attribute: FilterAttribute.HIGHEST_RANKING_PAGE,
    type: FilterValueType.STRING,
    comparison,
    value: path,
  };

  const competitorUrlFilter: any = {
    attribute: FilterAttribute.COMPETITOR_URL,
    type: FilterValueType.STRING,
    comparison,
    value: competitorUrl,
  };
  const filters = [highestRankingPageFilter];

  if (!isOwnDomain) {
    filters.push(competitorUrlFilter);
  }

  return linkWithFilters({
    to: '/keywords/list',
    newFilters: filters,
    filterSet: SITE_MAPPING_FILTER_SET,
  });
};

export const getAddTagTokenGroup = (
  source: string,
  operator: string,
  value: string,
): TokenGroup => ({
  expression: TokenGroupType.AND,
  tokens: [
    {
      source,
      operator,
      value,
    },
  ],
});

export const siteMappingViewModes: SiteMapViewMode[] = [
  {
    id: SiteMapMode.TRACKED_AND_UNTRACKED,
    name: (
      <Flex justify="center" gap={4}>
        <KeywordsIcon className="Switch-icon" /> + <KeyDisIcon className="Switch-icon" />
      </Flex>
    ),
  },
  {
    id: SiteMapMode.TRACKED_ONLY,
    name: (
      <Center>
        <KeywordsIcon className="Switch-icon" />
      </Center>
    ),
  },
];

export const siteMappingTreeModes: TreeViewMode[] = [
  {
    id: TreeMode.TREE,
    name: (
      <Center>
        <NodeTreeIcon className="Switch-icon-tree" />
      </Center>
    ),
  },
  {
    id: TreeMode.TABLE,
    name: (
      <Center>
        <TableIcon className="Switch-icon" />
      </Center>
    ),
  },
];

export const nodeName = (node: CustomNodeElementProps): string =>
  (node.nodeDatum?.name === OTHER.name ? t(node.nodeDatum?.name) : node.nodeDatum?.name);

export const pathFromURL = (url: string): string => {
  try {
    const urlObject = new URL(url);
    return trimTrailingSlash(urlObject.pathname);
  } catch (_) {
    // E.g. if is a path
    return trimTrailingSlash(url);
  }
};

/**
 * Remove the highest ranking page filter if it matches with the selectedPath (coming from the state).
 * Should only be called on the first render!
 */
const filterSiteMappingFilters = (filters: FilterBase[], selectedPath?: string) =>
  filters.filter((e) => {
    if (
      (e.attribute !== FilterAttribute.HIGHEST_RANKING_PAGE &&
        e.attribute !== FilterAttribute.COMPETITOR_URL) ||
      !selectedPath
    ) {
      return true;
    } else if (e.attribute === FilterAttribute.COMPETITOR_URL) {
      return false;
    }
    let value = e.value as string;
    if (value?.endsWith('/') && selectedPath !== '/') {
      // Sometimes the backend will send a trailing slash in the url, but not in the pathSegment.
      // so for now we just compare pathSegment to url without trailing slash when deleting filters.
      value = value?.slice(0, -1);
    }

    return !value?.endsWith(selectedPath);
  });

export const shouldDeleteURLFilter = (filters, selectedPath) =>
  filterSiteMappingFilters(filters, selectedPath).length !== filters?.length;

const numberFormatOptions = {
  billionsSettings: defaultBillionsSettings,
  millionsSettings: defaultMillionsSettings,
  thousandsSettings: defaultThousandsSettings,
};

export const formatNumber = (value: number): string =>
  formatNumberHelper(Object.assign(numberFormatOptions || {}, { value }));

export const filterD3Events = (event: BaseType) => {
  // prevent d3 from zooming on double click
  // https://accuranker.myjetbrains.com/youtrack/issue/ARR-1945
  if (event.type === 'dblclick') {
    return false;
  }
  // prevent d3 from zooming instead of scrolling
  // and allow to zoom only if shift or ctrl pressed
  return event.type === 'wheel' ? event.shiftKey : true;
};

const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);

const getMapToZeroOneIntervalKeywords = (totalKeywords: number) =>
  scaleSequentialLog().domain([1, totalKeywords]);

export const keywordsToNodeSize = (keywords: string[], totalKeywords: number) =>
  clamp(getMapToZeroOneIntervalKeywords(totalKeywords)(keywords, totalKeywords) * 30, 8, 30);

export const getSubdomainIfSelected = () => {
  const filters = useFilters();
  const subdomainFilter = filters.find((filter) => filter.attribute === FilterAttribute.SUBDOMAIN);
  return subdomainFilter?.value;
};

/**
 * If "str" starts with a slash ("/"), then move that to the end of "str".
 * To alleviate a problem the rtl text treating slash as a mirrored character.
 * E.g. "/products/shoes"  =>  "products/shoes/"
 *
 * Reason why we need this: We want to truncate the text from the left-hand side, on overflow.
 * @param str
 */
export const fixLeadingSlash = (str: string): string => {
  if (!str) return '';
  if (str.startsWith('/')) return `${str.replace('/', '')}/`;
  return str;
};

/** Check SitemapNodes for empty url sections containing `-` */
export const pathLink = (node: SitemapNode | null) => {
  if (!node?.url || node?.url === '-') {
    return undefined;
  }
  return node?.url;
};
