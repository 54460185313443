import { useTableSetting } from 'Components/Modal/Content/TableSettingsModal/support/config';
import { useStateColumns } from 'Components/Modal/Content/TableSettingsModal/support/hooks/useStateColumns';
import { ColumnTreeInput } from 'Ghql';
import { TableID, TableIDs } from 'Types/Table';
import { homeTreeViewTable } from './constants';

const useSelectedColumnsIds = (tableName: TableID) => {
  const { selector } = useTableSetting(tableName);
  const savedColumnsIds = useStateColumns(selector) || [];

  const defaultSelectionColumnsIds = homeTreeViewTable
    .filter((column) => column.tableSetting.defaultHide !== true)
    .map((column) => column.id);

  return savedColumnsIds.length ? savedColumnsIds : defaultSelectionColumnsIds;
};

/**
 * This hook construct the `ColumnTreeInput` object that is passed as a variable to the `TreeDashboard` GraphQL query.
 *
 * The `ColumnTreeInput` object is a dictionary where each key is a backend field name, and each value is `true` or `false`.
 * The values indicate whether each field should be included in the GraphQL query response.
 * For example: `{ countryCode: true, avgRank: false, ... }`.
 *
 * This hook iterates over `homeTreeViewTable`, which is an array of column settings (`ColumnSetting[]`) for the home tree view table.
 * For each column setting, it checks if it's required or selected, and if so, it sets the corresponding backend field names to `true`.
 * For example, the column setting ID `country` is required, so this hook's output will include `countryCode: true`.
 *
 * Note: Using a variable to dynamically determine which fields to include in the GraphQL query response is not a common pattern in GraphQL.
 * However, it is a common pattern in our codebase, because it plays well with our code generation strategy.
 * The pattern allows us to easily add or remove columns from the GraphQL query response without changing the GraphQL query itself.
 * This allows us to write a single GraphQL query and use code generation to generate the types, variables and apollo hooks.
 *
 * Note: Each column setting in `homeTreeViewTable` has an associated list of backend field names in `queryWithClauses`.
 * The name `queryWithClauses` can be confusing, because it actually refers to a list of backend field names.
 * The name stems from another GraphQL query, `PaginatedTableKeywords`, which passes each field separately (e.g., `$withId` or `$withKeyword`).
 * Here for the `TreeDashboard` GraphQL query, we pass all fields in a single object, so the name is a bit misleading.
 *
 * Note: We can't just camelcase the Column IDs, because the backend field name can be too different, and there could be column IDs with multiple backend fields.
 * For example, the column setting ID `country` maps to the backend field `country_code`.
 */
export const useColumnsInput = (): ColumnTreeInput => {
  const selectedColumnsIds = useSelectedColumnsIds(TableIDs.HOME_TREE_VIEW);

  return homeTreeViewTable.reduce((acc, column) => {
    const columnIsRequired = column.tableSetting.required === true;
    const columnIsSelected = selectedColumnsIds.includes(column.id);

    for (const fieldName of column.queryWithClauses) {
      acc[fieldName] = columnIsRequired || columnIsSelected;
    }

    return acc;
  }, {} as ColumnTreeInput);
};
