import { RefObject, useEffect, useRef } from 'react';

type Handler = (event: MouseEvent) => void;

export function useModalClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  callback: Handler,
): void {
  const target = useRef<EventTarget | null>(null);
  const handleClick = (e: MouseEvent): void => {
    if (e.target !== target.current) {
      return;
    }
    if (ref.current && !ref.current.contains(e.target as Node)) {
      callback(e);
    }
  };
  const handleMouseDown = (event) => {
    target.current = event.target;
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleMouseDown, true);
    document.addEventListener('click', handleClick, true);
    return () => {
      document.removeEventListener('click', handleClick, true);
      document.removeEventListener('mousedown', handleMouseDown, true);
    };
  });
}
