import endsWith from 'lodash/endsWith';
import startsWith from 'lodash/startsWith';

export const resolveUrl = (url: string | undefined, ...paths: string[]): string => {
  return paths.reduce((acc, path) => {
    if (endsWith(acc, '/') && startsWith(path, '/')) {
      return acc.concat(path.substring(1) || '');
    }
    return acc.concat(path);
  }, url || window.origin);
};
