import { useSelector } from 'react-redux';
import { ScrollArea } from '@mantine/core';
import clone from 'lodash/clone';
import ErrorBoundary from 'Components/ErrorBoundary';
import AddFilter from 'Components/Filters/AddFilter';
import { getIsPrimaryFilter } from 'Components/Filters/AddFilter/component';
import FilterItem from 'Components/Filters/FilterItem';
import scrollbarStyles from 'Pages/Layout/DashboardTemplate/SideNavbar/components/NavbarItems/navbar-items.module.scss';
import type { FilterBase } from 'Types/Filter';
import { filterGroupFiltersSelector, useFilterSet } from '../../../Selectors/FilterGroupsSelector';
import { getIsFilterActive } from '../helpers';

type Props = {
  visibleFilters: Array<FilterBase>;
};

const FilterItemList = ({ visibleFilters }: Props) => {
  const props: any = {};

  const filterSet = useFilterSet();
  const filterGroupFilters = useSelector(filterGroupFiltersSelector);

  const primaryFilterAttribute = visibleFilters?.find((el) =>
    getIsPrimaryFilter(el.attribute),
  )?.attribute;
  const seenCounts = {};

  return (
    <div className="filter-item-list">
      <AddFilter {...props} hasPrimaryFilterAdded={!!primaryFilterAttribute} />
      <ScrollArea
        scrollbars="x"
        type="auto"
        pt={4}
        h={56}
        offsetScrollbars="x"
        scrollbarSize={10}
        classNames={{
          thumb: scrollbarStyles.thumb,
          scrollbar: scrollbarStyles.corner,
        }}
      >
        <div className="filter-item-wrapper">
          {visibleFilters.map((filter, i) => {
            const primaryFilter = primaryFilterAttribute === filter?.attribute;
            // TODO: FIX THIS, related to page serp features filter
            // const filterConfig = getFilterByAttribute(filter.attribute, filterGroup) ?? filter;

            if (!seenCounts[filter.attribute]) {
              seenCounts[filter.attribute] = 1;
            } else {
              seenCounts[filter.attribute] += 1;
            }

            const filterConfigs =
              filterGroupFilters?.filter((f) => f.attribute === filter.attribute) ?? [];

            // Get the right version of the filter with this attribute in case more exist
            const filterConfig = clone(filterConfigs?.[seenCounts[filter.attribute] - 1] ?? filter);

            if (!filterConfig) {
              return null;
            }
            const key = filter.attribute + i;
            return (
              <ErrorBoundary key={key} page={false}>
                <FilterItem
                  filter={filterConfig}
                  filters={visibleFilters}
                  filterSet={filterSet}
                  active={getIsFilterActive(filterSet, filter?.attribute)}
                  primaryFilter={primaryFilter}
                />
              </ErrorBoundary>
            );
          })}
        </div>
      </ScrollArea>
    </div>
  );
};

export default FilterItemList;
