import { Component } from 'react';
import startsWith from 'lodash/startsWith';
import { Field, reduxForm } from 'redux-form';
import AccButton from 'Components/AccButton/AccButton';
import { TextField } from 'Components/Forms/Fields';
import { t, tct } from 'Utilities/i18n';
import Validator from 'Utilities/validation';
import formStyles from 'css/layout/form-layout.module.scss';
import './enter-link.scss';

type Props = {
  invalid: boolean;
  submitting: boolean;
  handleSubmit: (...args: Array<any>) => any;
  onClose: (...args: Array<any>) => any;
  onSubmit: (...args: Array<any>) => any;
};

class EnterLink extends Component<Props, any> {
  handleSubmit = ({ link, placement, campaign }) => {
    this.props.onSubmit({
      link: startsWith(link, 'https://www.accuranker.com/') ? link : 'https://www.accuranker.com/',
      placement,
      campaign,
    });
  };

  render() {
    const { invalid, submitting, handleSubmit } = this.props;
    return (
      <form className="enter-link-form" onSubmit={handleSubmit(this.handleSubmit)}>
        <div className={formStyles.formRow}>
          <p>
            {tct(
              'To create an affiliate link paste any URL you\'d like to link to from the [link:AccuRanker website] below. You will then be able to copy paste a version of this URL with your affiliate ID added.',
              {
                link: <a href="https://www.accuranker.com/" target="_blank" rel="noreferrer" />,
              },
            )}
          </p>

          <p>
            {t(
              'You can track additional parameters such as \'campaign\' and \'placement\', these fields are optional and dynamic.',
            )}
          </p>
        </div>
        <div className={formStyles.formRow}>
          <Field
            name="link"
            placeholder={t('URL to link to (e.g. https://www.accuranker.com/product)')}
            component={TextField}
            validate={[Validator.required]}
          />
        </div>
        <div className="form-label">{t('Optional fields')}</div>

        <div className={formStyles.formGroup}>
          <div className={formStyles.formCol}>
            <Field name="campaign" placeholder={t('(optional) campaign')} component={TextField} />
          </div>

          <div className={formStyles.formCol}>
            <Field name="placement" placeholder={t('(optional) placement')} component={TextField} />
          </div>
        </div>

        <div className={formStyles.formRow}>
          <div className="confirmation-button-wrapper text-right">
            <AccButton disabled={invalid || submitting} type="submit" variant="primary">
              {t('Create link')}
            </AccButton>
          </div>
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: 'EnterLink',
  enableReinitialize: true,
})(EnterLink);
