import { useHasKeydisAccess } from 'Hooks/data/organization/useOrganizationInfo';
import KeywordDiscoveryPage from 'Pages/KeywordDiscovery';
import { KeywordDiscoveryPlaceholderPage } from 'Pages/KeywordDiscovery/components/Placeholders';
import { TableIDs } from 'Types/Table';

/**
 * Show a placeholder for trials or flagged plans (OrganizationPlanNode.hasKeydisAccess).
 * Show the regular Keyword Discovery page for all others.
 */
const ResearchPageComponent = () => {
  const hasKeydisAccess = useHasKeydisAccess();
  if (hasKeydisAccess) return <KeywordDiscoveryPage pageType={TableIDs.RESEARCH} />;
  return <KeywordDiscoveryPlaceholderPage />;
};

export default ResearchPageComponent;
