import { gql } from '@apollo/client';

export const QUERY_LANDING_PAGES = gql`
  query LandingTableQuery(
    $filters: [FilterInput]!
    $pagination: PaginationInput!
    $ordering: OrderingInput!
    $displayCurrency: String!
  ) {
    tableLandingPages(
      filters: $filters
      pagination: $pagination
      ordering: $ordering
      displayCurrency: $displayCurrency
    ) {
      landingPages {
        id
        path
        keywords
        searchVolumeValue
        organicTraffic
        organicTrafficCompare
        dynamicCtrValue
        dynamicCtrCompare
        organicTrafficCompare
        shareOfVoiceValue
        shareOfVoiceCompare
        shareOfVoicePercentageValue
        shareOfVoicePercentageValueCompare
        costValue
        avgCostPerClick
        avgRevenue
        avgRevenueCompare
        untrackedKeywords
        country
      }
      pagination {
        page
        results
        numResults
        numPages
      }
    }
  }
`;
