import React, { memo, useCallback, useMemo, useState } from 'react';
import { Flex, FlexProps, Popover, PopoverProps } from '@mantine/core';
import { useClickOutside } from '@mantine/hooks';
import cn from 'classnames';
import AccTitle, { AccTitleProps } from 'Components/Title/AccTitle';
import { propsIsEqualComparison } from 'Utilities/compare';

type Props = {
  /** The target element, which will handle open and close of the dropdown */
  target: ((isOpen: boolean) => JSX.Element) | React.ReactNode;
  /** The contents of the dropdown container. To style the dropdown container, use the dropdownContainerProps prop. */
  children: ((onClose: () => void) => JSX.Element) | React.ReactNode;
  // children: React.ReactNode;

  /** The outer container containing the target. Takes all mantine flexbox props. Only use when necessary, to keep things aligned. */
  outerContainerProps?: FlexProps;
  /** The container containing the dropdiwn. Takes all mantine flexbox props. Only use when necessary, to keep things aligned. */
  dropdownContainerProps?: FlexProps;
  dropdownTitle?: string;
  /** Props applied to the dropdown title. Takes all mantine title props. Only use when necessary, to keep things aligned. */
  dropdownTitleProps?: AccTitleProps;
  noPadding?: boolean;
  noHover?: boolean;
  onToggle?: () => void;
  withinPortal?: boolean;
  popoverZIndex?: number;
} & Omit<PopoverProps, 'children'>;

const AccLazyPopover = (props: Props) => {
  const {
    target,
    children,
    dropdownContainerProps,
    dropdownTitle,
    dropdownTitleProps,
    outerContainerProps,
    noPadding = false,
    noHover = false,
    withinPortal = true,
    popoverZIndex = 1000,
    onToggle,
    ...rest
  } = props;

  const [showPopover, setShowPopover] = useState(false);

  const [control, setControl] = useState<HTMLDivElement | null>(null);
  const [dropdown, setDropdown] = useState<HTMLDivElement | null>(null);

  const handleToggle = useCallback(() => {
    setShowPopover((prev) => !prev);
    onToggle?.();
  }, [onToggle]);

  useClickOutside(() => handleToggle(), null, [control, dropdown]);

  // Target can be a function which returns a JSX element and gets the prop of whether the popover is open or not - this is used to style the target element.  See TableFilter component.
  const trigger = typeof target === 'function' ? target(showPopover) : target;

  const triggerComp = useMemo(
    () => (
      <div
        className={cn({
          'popover-trigger': !noHover,
        })}
        onClick={handleToggle}
      >
        {trigger}
      </div>
    ),
    [handleToggle, noHover, trigger],
  );

  return (
    <Flex ref={setControl} {...outerContainerProps}>
      {showPopover === false ? (
        triggerComp
      ) : (
        <Popover
          withinPortal={withinPortal}
          defaultOpened
          closeOnEscape
          shadow="xs"
          opened
          zIndex={popoverZIndex}
          {...rest}
          keepMounted={false}
          onClose={() => setShowPopover(false)}
        >
          <Popover.Target>{triggerComp}</Popover.Target>
          <Popover.Dropdown style={{ padding: noPadding ? 0 : '' }}>
            <Flex ref={setDropdown} direction="column" {...dropdownContainerProps}>
              {dropdownTitle && (
                <AccTitle type="h4" mb={'md'} {...dropdownTitleProps}>
                  {dropdownTitle}
                </AccTitle>
              )}
              {/* children(handleToggle) is used to make an element inside the popover able to close the popover, e.g. a submit button. See TableFilter component.*/}
              {typeof children === 'function' ? children(handleToggle) : children}{' '}
            </Flex>
          </Popover.Dropdown>
        </Popover>
      )}
    </Flex>
  );
};

export default memo(AccLazyPopover, propsIsEqualComparison);
