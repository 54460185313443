import { useMemo } from 'react';
import { usePaymentMethodQuery } from 'Ghql';
import useUserPermission from 'Hooks/useUserPermission';
import { t, tct } from 'Utilities/i18n';
import PaymentMethodSkeleton from './PaymentMethodSkeleton';

const PaymentMethod = () => {
  const { isAdmin } = useUserPermission();
  const { data, loading, error } = usePaymentMethodQuery({
    fetchPolicy: 'network-only',
  });
  const contact = data?.user?.organization?.paymentcontact;

  const content = useMemo(() => {
    if (!isAdmin) {
      return t('You need to be organization admin to see payment method information.');
    }
    if (contact && contact.paymentMethod === 'A_1') {
      return (
        <div>
          <p>{t('Your account is paid via invoice.')}</p>
          <p>{t('Your payment terms are %s days.', contact.paymentTerms)}</p>
          <p>
            {t(
              'The invoice is emailed to your payment contact. If none is set, the invoices are sent to all account admins.',
            )}
          </p>
        </div>
      );
    }
    if (
      !contact ||
      !contact.braintreeCustomer ||
      (!contact.braintreeCustomer.card && !contact.braintreeCustomer.paypal)
    ) {
      return t('No payment method selected');
    }

    const { card, paypal } = contact.braintreeCustomer;
    return [
      card && [
        <span key="card-image" className="info-row">
          <strong className="info-label-cell" />
          <span className="info-value-cell">
            {card.imageUrl ? <img src={card.imageUrl} /> : null}
          </span>
        </span>,
        <span key="number" className="info-row">
          <strong className="info-label-cell">{t('Card Number')}</strong>
          <span className="info-value-cell">{card.maskedNumber}</span>
        </span>,
        <span key="expiration" className="info-row last-card-row">
          <strong className="info-label-cell">{t('Expiration Date')}</strong>
          <span className="info-value-cell">{card.expirationDate}</span>
        </span>,
        <small key="help">
          {tct(
            'We do not store credit card information on our servers. [link:Our payment processor] is a validated Level 1 PCI DSS Compliant Service Provider.',
            {
              link: (
                <a
                  href="https://www.braintreepayments.com/features/data-security"
                  target="_blank"
                  rel="noreferrer"
                />
              ),
            },
          )}
        </small>,
      ],
      paypal && [
        <span key="paypal-image" className="info-row">
          <strong className="info-label-cell" />
          <span className="info-value-cell">
            {paypal.imageUrl ? <img src={paypal.imageUrl} /> : null}
          </span>
        </span>,
        <span key="paypal" className="info-row">
          <strong className="info-label-cell">{t('PayPal Account')}</strong>
          <span className="info-value-cell">{paypal.email}</span>
        </span>,
      ],
    ];
  }, [contact, isAdmin]);

  if (loading || error) {
    return <PaymentMethodSkeleton />;
  }

  return <div className="billing-box">{content}</div>;
};

export default PaymentMethod;
