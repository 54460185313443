import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import cn from 'classnames';
import { AddChartBox } from 'Pages/Keywords/Overview/NextChartsDashboard/DraggableChartListV2/components/AddChartBox';
import { FreezeRerender } from 'Pages/Keywords/Overview/NextChartsDashboard/DraggableChartListV2/components/FreezeRerender';
import styles from 'Pages/Keywords/Overview/NextChartsDashboard/DraggableChartListV2/dashboard.module.scss';
import { ChartBox } from 'Pages/Keywords/Overview/components/ChartBox';
import { DraggableItemProps } from './DndBoard';

type SortableItemProps = {
  id: string;
  columnId?: string;
  index?: number;
  placeholder?: boolean;
} & DraggableItemProps;

export default function SortableItem(props: SortableItemProps) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging, active } =
    useSortable({
      id: props.id,
      disabled: props.disableDnd,
    });

  // ARR-4786 Postpone the rendering of `AddChartBox` as this is a very heavy component
  const [renderAddChartBox, setRenderAddChartBox] = React.useState(false);

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0 : 1,
    maxHeight: props.placeholder ? '400px' : undefined,
    pointerEvents: active ? 'none' : 'auto',
  };

  const dragProps = {
    ...attributes,
    ...listeners,
  };

  const containerProps = {
    ...props.allProps,
    dragging: isDragging,
    dragRef: dragProps,
    boardControls: props.disableDnd
      ? { disableDnd: true }
      : {
          removeChart: () => props.removeChart(props.id),
        },
  };

  const showAddButton =
    !props.placeholder &&
    !isDragging &&
    !!props.addColumnOptions?.length &&
    !props.disableDnd &&
    renderAddChartBox;
  const freeze = !!active?.data;

  return (
    <div
      onMouseEnter={() => setRenderAddChartBox(true)}
      ref={setNodeRef}
      style={style}
      className={cn('overview-chart-container', styles.item)}
    >
      {showAddButton && (
        <AddChartBox
          addColumnOptions={props.addColumnOptions}
          index={props.index || 0}
          addItem={props.addItem}
          columnId={props.columnId}
          position="top"
        />
      )}
      {props.placeholder ? (
        <ChartBox chartName={props.id} alwaysVisible={true} otherProps={containerProps} />
      ) : (
        <FreezeRerender
          freeze={freeze}
          item={
            <ChartBox
              chartName={props.id}
              alwaysVisible={(props.index || 0) <= 2}
              otherProps={containerProps}
            />
          }
        />
      )}
      {showAddButton && (
        <AddChartBox
          addColumnOptions={props.addColumnOptions}
          index={(props.index || 0) + 1}
          addItem={props.addItem}
          columnId={props.columnId}
          position="bottom"
        />
      )}
    </div>
  );
}
