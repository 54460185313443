import React, { useState } from 'react';
import { TextInput } from '@mantine/core';
import { useDebouncedCallback } from '@mantine/hooks';
import { IconSearch, IconX } from '@tabler/icons-react';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import { t } from 'Utilities/i18n';
import styleVariables from 'css/base/variables.module.scss';
import { useComparisonContext } from '../useComparisonContext';

const { gray4: iconColor } = styleVariables;

const useSearch = () => {
  const { setSearch } = useComparisonContext();
  const [currentSearch, setCurrentSearch] = useState<string>('');

  const handleSearch = useDebouncedCallback((value: string) => {
    setSearch(value);
  }, 300);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.currentTarget.value;
    setCurrentSearch(newValue);
    handleSearch(newValue);
  };

  const cleanSearch = () => {
    setCurrentSearch('');
    handleSearch('');
  };

  return { currentSearch, handleChange, cleanSearch };
};

const CollapseSearch: React.FC = () => {
  const { currentSearch, handleChange, cleanSearch } = useSearch();
  return (
    <TextInput
      value={currentSearch}
      name="search"
      placeholder={t('Search')}
      rightSection={
        currentSearch ? (
          <AccActionIcon variant="subtle" onClick={cleanSearch}>
            <IconX size={20} color={iconColor} />
          </AccActionIcon>
        ) : (
          <IconSearch size={20} color={iconColor} />
        )
      }
      onChange={handleChange}
    />
  );
};

export default CollapseSearch;
