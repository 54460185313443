import React from 'react';
import { Flex, SimpleGrid } from '@mantine/core';
import AccPaper from 'Components/AccPaper/index';
import AccText from 'Components/Text/AccText';
import AccTitle from 'Components/Title/AccTitle';
import { useNeighborlyChartsFilter } from 'Hooks/data/organization/useOrganizationInfo';
import { filterColumnCharts } from 'Pages/Keywords/Overview/NextChartsDashboard/DraggableChartListV2/utils';
import styles from 'Pages/Keywords/Overview/components/ChartContainer/container-header.module.scss';
import { t } from 'Utilities/i18n/index';
import { CHART_MENU, CHART_NAMES, GET_CHART_DESCRIPTION, NEIGHBORLY_CHART_NAMES } from '../ChartBox';
import style from './add-charts.module.scss';

export const OverviewAddChart = ({ columns, addColumn }) => {
  const neighborlyChartsFilter = useNeighborlyChartsFilter(NEIGHBORLY_CHART_NAMES);
  const addColumnOptions = Object.values(CHART_NAMES)
    .filter((id) => !columns.includes(id))
    .filter(filterColumnCharts)
    .filter(neighborlyChartsFilter)
    .map((e) => ({
      title: e,
      description: GET_CHART_DESCRIPTION()[e],
      icon: CHART_MENU[e],
    }));

  if (!addColumnOptions?.length) {
    return null;
  }

  return (
    <AccPaper px="lg" py="md" className={styles.container}>
      <AccTitle type="h3">{t('Available Widgets')}</AccTitle>
      <SimpleGrid cols={6} pt={8}>
        {addColumnOptions?.map((e) => {
          return (
            <div
              key={e.title}
              className={style.addChartContainer}
              onClick={() => addColumn(e.title)}
            >
              <Flex gap={8} align={'center'} pb={4}>
                {e.icon}
                <AccText size="md" variant="label">
                  {e.title}
                </AccText>
              </Flex>
              <AccText>{e.description}</AccText>
            </div>
          );
        })}
      </SimpleGrid>
    </AccPaper>
  );
};
