import type { Params } from 'react-router-dom';
import endsWith from 'lodash/endsWith';

/**
 *
 * in react-router v6 they removed possibility to get pattern `keywords/:id`
 * this function converts path like /user/123 to /user/:id - to reproduce previous value
 */
export const getRoutePath = (
  pathName: string,
  params: Params,
  includeParams: boolean = true,
): string => {
  let path = pathName;

  if (!Object.keys(params || {}).length) {
    return path ?? '';
  }

  Object.entries(params || {})?.forEach(([paramName, paramValue]) => {
    if (paramValue) {
      path = includeParams
        ? path.replace(paramValue, `:${paramName}`)
        : path.replace(`/${paramValue}/`, '');

      if (endsWith(path, `${paramName}/`)) {
        path = path?.slice(0, -1);
      }
    }
  });

  return path ?? '';
};

export const getIsReactLazy = (component?: unknown) => {
  return (
    component &&
    typeof component === 'object' &&
    component.hasOwnProperty('$$typeof') &&
    (component as Record<string, string>)?.$$typeof?.toString() === 'Symbol(react.lazy)'
  );
};
