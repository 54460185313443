/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { hideModal } from 'Actions/ModalAction';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { allGroupsId } from 'Constants/workspaces';
import { useGetWorkspaceQuery } from 'Ghql';
import { EventName, TrackingKey, useMixpanel } from 'Utilities/Analytics/mixpanel';
import { t } from 'Utilities/i18n';
import AddEditWorkspaceForm, { WorkspaceInitialValues } from '../AddEditWorkspaceForm';
import { useUpdateWorkspace } from './useUpdateWorkspace';

type Props = {
  refresh: (...args: Array<any>) => any;
  id: string;
};

/**
 * EditWorkspace component for editing workspace details.
 *
 * @param refresh - A function to refresh the workspaces list after editing a workspace.
 * @param id - The ID of the workspace being edited.
 * @returns The JSX element representing the modal content for editing a existing workspace.
 */
const EditWorkspace = ({ refresh, id }: Props) => {
  const trackEvent = useMixpanel();

  useEffect(() => {
    trackEvent(EventName.WorkspacePage, {
      'Tracking key': TrackingKey.OpenEditModal,
    });
  }, []);

  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(hideModal());
  };

  const handleSubmit = useUpdateWorkspace({ id, refresh, onClose });

  const { data, loading, error } = useGetWorkspaceQuery({
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
  });

  const users = data?.workspace?.users?.map((user) => user?.id).filter(Boolean) as string[];

  const clients = data?.workspace?.clients?.map((client) => client?.id).filter(Boolean) as string[];

  const initialValues: WorkspaceInitialValues = useMemo(
    () => ({
      name: data?.workspace?.name,
      description: data?.workspace?.description,
      clients: clients || [],
      users: users || [],
    }),
    [data?.workspace?.name, data?.workspace?.description, clients?.toString(), users?.toString()],
  );

  return (
    <ModalBorder title={t('Edit Workspace')} onClose={onClose}>
      <AddEditWorkspaceForm
        type="edit"
        onClose={onClose}
        handleSubmit={handleSubmit}
        initialValues={initialValues}
        loading={loading}
        error={error}
        hasAllGroups={id === allGroupsId}
      />
    </ModalBorder>
  );
};

EditWorkspace.defaultProps = {
  refresh: () => {},
};

export default EditWorkspace;
