import { useDispatch } from 'react-redux';
import { Box, Flex } from '@mantine/core';
import { deselectAllDomains } from 'Actions/DomainsPageActions';
import AccFeatureCallout from 'Components/AccButton/AccFeatureCallout';
import { ViewMode } from 'Components/ViewMode';
import { useUser } from 'Hooks/data/user/useUser';
import { TableID } from 'Types/Table';
import { t, tct } from 'Utilities/i18n';

type Props = {
  tableName: TableID;
  isSimple: boolean;
};

const TreeViewViewCalloutKey = 'HomeDashboardTreeViewCallout';

const DomainsViewMode = ({ tableName, isSimple }: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dispatch = useDispatch();
  const user = useUser();
  const isTrial = user.organization?.activePlan?.isTrial;
  const onChange = () => {
    dispatch(deselectAllDomains());
  };

  return (
    <Flex direction="column">
      <ViewMode tableName={tableName} simple={isSimple} onChange={onChange} />
      {!isTrial && (
        <AccFeatureCallout
          popoverTitle={t('New Feature!')}
          popoverText={tct(
            'Explore the new [b:tree view] for your domain list. ' +
              'This view mode provides a hierarchical display of all your groups, domains, and tags!',
            {
              b: <strong />,
            },
          )}
          localStorageKey={TreeViewViewCalloutKey}
          popoverTarget={<Box w={32} ml="auto" />}
          popoverPosition="bottom-end"
        />
      )}
    </Flex>
  );
};
export default DomainsViewMode;
