import { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
// components
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { useModal } from 'Hooks/base/useModal';

export type StepTo = (step: string, data?: any) => void;

export type WizardContentWrapper = (content: any, currentStep: string) => any;

type WizardStepProps = {
  stepTo: StepTo;
  data: Record<string, any>;
};

type WizardStepTitleProps = {
  data: Record<string, any>;
};

export type WizardStepDetails = {
  component: (props: WizardStepProps) => JSX.Element;
  // FIXME returns component
  title: string | ((props: WizardStepTitleProps) => string);
  name?: string;
};

type WizardSteps = Record<string, WizardStepDetails | undefined>;

type WizardStepDataDetails = {
  data: Record<string, any>;
  prevStep: string;
};

type WizardStepsData = Record<string, WizardStepDataDetails>;

type Props = {
  className: string;
  steps: WizardSteps;
  defaultStep: string;
  contentWrapper?: WizardContentWrapper;
  // automatic
  defaultData?: Record<string, any>;
};

type State = {
  currentStep: string;
  data: WizardStepsData;
};

export const stepWithProps = (stepTo: StepTo, step: string) => (props: any) => stepTo(step, props);

const Wizard = (props: Props) => {
  const { hideModal } = useModal();
  if (isEmpty(props.steps)) {
    return null;
  }

  const [state, setState] = useState<State>({
    currentStep: props.defaultStep,
    data: props.defaultData ?? {},
  });

  const handleStepTo = (step: string, dataForStep: Record<string, any>) => {
    const { currentStep, data } = state;

    const { skippedStep, ...rest } = dataForStep ?? {};
    const newData = skippedStep
      ? { ...data, ...skippedStep, [currentStep]: rest }
      : { ...data, [currentStep]: dataForStep };

    setState({
      currentStep: step,
      data: newData,
    });
  };

  const wrapStepContent = (content, step: string) => {
    const { contentWrapper } = props;
    return contentWrapper ? contentWrapper(content, step) : content;
  };

  const { steps, className } = props;
  const { currentStep, data } = state;

  if (isEmpty(steps)) {
    return null;
  }

  const { title, component: StepComponent } = steps[currentStep] || {};
  const titleResult =
    typeof title === 'function'
      ? title({
          data,
        })
      : title;
  return (
    <ModalBorder className={className} title={titleResult} onClose={hideModal}>
      {wrapStepContent(
        StepComponent ? <StepComponent stepTo={handleStepTo} data={data} /> : undefined,
        currentStep,
      )}
    </ModalBorder>
  );
};

export default Wizard;
