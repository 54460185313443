import { ChangeFilter, FilterComparison } from 'Types/Filter';
import { t } from 'Utilities/i18n';

export const getCompareLabels = (key: string, isReversed?: boolean) => {
  const items = {
    [isReversed ? FilterComparison.LT : FilterComparison.GT]: {
      selectLabel: t('Moved up more than'),
      valueLabel: (filterValue) => t('Moved up by more than %s', filterValue),
    },
    [isReversed ? FilterComparison.GT : FilterComparison.LT]: {
      selectLabel: t('Moved down more than'),
      valueLabel: (filterValue) => t('Moved down by more than %s', filterValue),
    },
    [isReversed ? FilterComparison.LTE : FilterComparison.GTE]: {
      selectLabel: t('Moved up'),
      valueLabel: (filterValue) => t('Moved up by %s or more', filterValue),
    },
    [isReversed ? FilterComparison.GTE : FilterComparison.LTE]: {
      selectLabel: t('Moved down'),
      valueLabel: (filterValue) => t('Moved down by %s or more', filterValue),
    },
    [FilterComparison.EQ]: {
      selectLabel: t('Did not change'),
      valueLabel: () => t('Did not change'),
    },
  };
  return items[key];
};

export const changeFilterLabelFunc = (filter: ChangeFilter, isReversed?: boolean) => {
  const filterValue = filter.value;
  return getCompareLabels(filter.comparison, isReversed)?.valueLabel?.(filterValue);
};
