import React, { useMemo } from 'react';

export interface DistributionItem {
  value: number;
  color: string;
  id: string;
  label?: string;
  tooltip?: string;
}

type Props = {
  width: number;
  height: number;
  labelSize?: number;
  /**
   * @example
   * { value: 50, color: 'pink', id: '1234567', label: 'I', tooltip: 'Informational' }
   */
  items?: DistributionItem[];
  backgroundColor?: string;
  /** Use this prop if the accumulated items don't necessarily sum to the total value */
  totalValue?: number;
};

const AccDistribution = (props: Props) => {
  const {
    width: totalWidth,
    height,
    items,
    totalValue: propsTotalValue,
    backgroundColor,
    labelSize = 11,
  } = props;

  const renderItems = useMemo(() => {
    const totalValue = propsTotalValue ?? items?.reduce((summ, item) => summ + item.value, 0) ?? 0;
    let x = 0;

    if (!items?.length) return <></>;
    return items?.map((item) => {
      if (!item?.value) return null;

      const width = (item.value / totalValue) * totalWidth;
      const currentX = x;
      x += width;

      return (
        <g key={item.id}>
          <rect fill={item.color} x={currentX} y={0} width={width} height="100%" id={item.id} />
          {item?.label && (
            <text
              x={(currentX || 1) + width / 2}
              textAnchor="middle"
              y="50%"
              style={{ transform: `translateY(${labelSize / 3}px)` }} // move text vertically to 50% height plus a fraction of the label size to center it
              fill="#fff"
              fontWeight={600}
              fontSize={labelSize}
              fontFamily="inherit"
            >
              {item.label}
            </text>
          )}
          {item?.tooltip && <title>{item.tooltip}</title>}
        </g>
      );
    });
  }, [items, totalWidth]);

  return (
    <svg
      style={{ borderRadius: 2, backgroundColor, cursor: 'pointer' }}
      width={totalWidth}
      height={height}
    >
      {renderItems}
    </svg>
  );
};

export default AccDistribution;
