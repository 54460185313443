import React from 'react';
import { Box, BoxProps, Center } from '@mantine/core';
import Loader from 'Components/Loader';
import { LINE_CHART_HEIGHT } from 'Pages/Keywords/Overview/constants';
import { t } from 'Utilities/i18n';
import BubbleChartLoader from './loaders/bubbleLoader.svg?inline';
import ColumnChartLoader from './loaders/columnLoader.svg?inline';
import CompetitorChartLoader from './loaders/dynamicCompetitorLoader.svg?inline';
import FlippedColumnLoader from './loaders/flippedColumnLoader.svg?inline';
import OverviewChartLoader from './loaders/graphLoader.svg?inline';
import KeywordOveruseLoader from './loaders/keywordOveruseLoader.svg?inline';
import PieLoader from './loaders/pieLoader.svg?inline';
import StackChartLoader from './loaders/stackLoader.svg?inline';

// eslint-disable-next-line import/no-unused-modules
export type LoaderTypes = Record<
  | 'line'
  | 'bubble'
  | 'pie'
  | 'stack'
  | 'column'
  | 'flippedColumn'
  | 'competitor'
  | 'default'
  | 'keywordOveruse',
  () => JSX.Element
>;

const Loaders: LoaderTypes = {
  line: OverviewChartLoader,
  bubble: BubbleChartLoader,
  pie: PieLoader,
  stack: StackChartLoader,
  column: ColumnChartLoader,
  flippedColumn: FlippedColumnLoader,
  competitor: CompetitorChartLoader,
  keywordOveruse: KeywordOveruseLoader,
  default: () => (
    <Center>
      <Loader
        style={{
          position: 'relative',
          height: '300px',
        }}
        noBackdrop={true}
        loadingText={t('Loading…')}
      />
    </Center>
  ),
};

type Props = {
  loaderType?: keyof LoaderTypes;
  loading?: boolean;
  children?: React.ReactNode | React.ReactNode[];
  height?: number;
  containerBoxProps?: BoxProps;
};

export type ChartLoaderType = keyof typeof Loaders;

export const ChartLoader: React.FC<React.PropsWithChildren<Props>> = ({
  loading,
  children,
  loaderType,
  containerBoxProps,
  height,
}: Props) => {
  if (loading) {
    const SelectedLoader = Loaders[loaderType || 'default'] ?? Loaders.line;
    return (
      <Box h={height || LINE_CHART_HEIGHT} mb={0} {...containerBoxProps}>
        <SelectedLoader />
      </Box>
    );
  }
  return <>{children}</>;
};
