import type { FieldProps } from 'redux-form';
import TextInput, { TextInputProps } from 'Components/Controls/TextInput/index';
import FormField from 'Components/Forms/FormField';
import useFilterCount from 'Hooks/useFilterCount';

type FieldWrapperProps = {
  onChange?: (...args: Array<any>) => any;
};

type FieldsProps = {
  attribute: FieldProps;
  type: FieldProps;
  value: FieldProps;
  comparison: FieldProps;
};
const TextInputWithFilterCount = (props: TextInputProps & FieldWrapperProps & FieldsProps) => {
  const filterCount = useFilterCount({
    attribute: props.attribute.input.value,
    value: props.value.input.value,
    type: props.type.input.value,
    comparison: props.comparison.input.value,
  });

  const onValueChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    if (event.currentTarget.value === '') {
      props.value.input.onChange('');
      return;
    }
    props.value.input.onChange(event.currentTarget.value);
  };

  return (
    <FormField meta={props.value.meta}>
      <TextInput
        {...props}
        count={filterCount}
        value={props.value.input.value}
        customOnChange={onValueChange}
      />
    </FormField>
  );
};

export default TextInputWithFilterCount;
