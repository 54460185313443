import * as React from 'react';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import config from 'config';
import compose from 'lodash/flowRight';
import { Field, reduxForm } from 'redux-form';
import AccButton from 'Components/AccButton/AccButton';
import { TextField } from 'Components/Forms/Fields';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import toast from 'Hooks/useToast';
import { IntegrationOAuthProviders } from 'Types/Integration';
import { EventName, TrackingKey, trackEventMixpanel } from 'Utilities/Analytics/mixpanel';
import { t } from 'Utilities/i18n';
import Validator from 'Utilities/validation';
import { resolveUrl } from './helpers';

type FormValues = {
  description: string;
};
type Props = {
  modalParams?: any;
  handleSubmit: (...args: Array<any>) => any;
  onSubmit?: (...args: Array<any>) => any;
  googleConnectionUrl: (...args: Array<any>) => any;
  onCancel: (...args: Array<any>) => any;
  submitting: boolean;
  passedState: Record<string, any>;
};

const redirectUri = resolveUrl(
  config.baseUrl,
  config.basename || '/',
  '/account/googleoauth/callback',
);

const generateState = () => {
  const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let array = new Uint8Array(40);
  window.crypto.getRandomValues(array);
  array = array.map((x) => validChars.charCodeAt(x % validChars.length));
  return String.fromCharCode.apply(null, array as any);
};

class ConnectOAuthAccount extends React.Component<Props> {
  onSubmit = (values: FormValues) =>
    this.props
      .googleConnectionUrl({
        variables: {
          input: {
            redirectUri,
            type: parseInt(this.props.modalParams.modalProps.integration.type),
          },
        },
      })
      .then(
        ({
          data: {
            googleConnectionUrl: { authorizeUrl },
          },
        }) => {
          const state = generateState();
          sessionStorage.setItem(
            'oAuthData',
            JSON.stringify({
              url: window.location.pathname.replace(config.basename, ''),
              description: values.description,
              state,
              passedState: this.props.passedState,
              modalParams: this.props.modalParams,
              redirectUri,
              type: this.props.modalParams.modalProps.integration.type,
            }),
          );
          // TODO FixTSignore
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          window.location = `${authorizeUrl}&state=${state}`;
          this.props.onSubmit && this.props.onSubmit();
        },
        () => {
          toast.error(t('Failed to add connection'));
        },
      );

  componentDidMount(): void {
    trackEventMixpanel(EventName.ConnectToGSC, '', {
      Variant: 'Open',
      TrackingKey: TrackingKey.ConnectOAuthAccount,
    });
  }

  render() {
    const { handleSubmit, submitting } = this.props;
    let style = {};

    if (
      this.props.modalParams.modalProps.integration.type ===
      IntegrationOAuthProviders.GOOGLE_DRIVE.type
    ) {
      style = {
        display: 'none',
      };
    }

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div style={style}>
          <div className="form-label required">{t('Connection Name')}</div>
          <div>
            <Field
              name="description"
              placeholder={t('Connection name')}
              component={TextField}
              disabled={submitting}
              validate={Validator.required}
              autoFocus={true}
              helpText={t('The name is used to identify the connection in the integrations list.')}
            />
          </div>
        </div>
        <ModalFooter
          primaryButtonSlot={
            <AccButton
              variant="primary"
              disabled={submitting}
              type="submit"
              onClick={() => {
                trackEventMixpanel(EventName.ConnectToGSC, '', {
                  Variant: 'Continue',
                  TrackingKey: TrackingKey.ConnectOAuthAccount,
                });
              }}
            >
              {t('Add connection')}
            </AccButton>
          }
          secondaryButtonSlot={
            <AccButton
              variant="tertiary"
              disabled={submitting}
              onClick={() => {
                this.props.onCancel();
                trackEventMixpanel(EventName.ConnectToGSC, '', {
                  Variant: 'Close',
                  TrackingKey: TrackingKey.ConnectOAuthAccount,
                });
              }}
            >
              {t('Cancel')}
            </AccButton>
          }
        />
      </form>
    );
  }
}

const googleConnectionUrlQuery = gql`
  mutation connectOAuthAccount_oAuthUrls($input: GoogleAccountUrlInput!) {
    googleConnectionUrl(input: $input) {
      authorizeUrl
    }
  }
`;
export default compose(
  graphql(googleConnectionUrlQuery, {
    name: 'googleConnectionUrl',
  }),
  reduxForm({
    form: 'ConnectOAuthAccount',
  }),
)(ConnectOAuthAccount) as any;
