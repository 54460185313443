import { linkHorizontal } from 'd3-shape';

export const RenderLink = ({ linkData, colorMap, totalSearchVolume, selectedPath }) => {
  const { source, target } = linkData;

  const d = linkHorizontal()({
    source: [source.y, source.x],
    target: [target.y, target.x],
  });
  const nodeSearchVolume = Math.max(target?.data?.svICT + target?.data?.svICU, 10); // Minimum 10 to avoid colors not on colormap
  const color = colorMap(nodeSearchVolume, totalSearchVolume);

  const isSelected = `${selectedPath}/`.startsWith(`${target?.data.pathSegment}/`);

  const strokeWidth = isSelected ? '3px' : '1px';
  const opacity = isSelected ? 1 : 0.5;

  return (
    <path
      style={{ stroke: color, fill: 'none', strokeWidth, opacity }}
      d={d}
      data-source-id={linkData.source.id}
      data-target-id={linkData.target.id}
    />
  );
};
