import moment from 'moment';
import linkWithFilters from 'Components/Filters/linkWithFilters';
import type { CompareToFilter, DomainsFilter, FilterBase, PeriodFilter } from 'Types/Filter';
import { FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import { KEYWORDS_FILTER_SET } from 'Types/FilterSet';
import { camelToSnakeCase } from 'Utilities/underdash';

export default function linkToKeywordListFromSerpChart(
  domainId: any,
  category: any,
  _: boolean,
  selectedSerp: any,
) {
  const domainsFilter: DomainsFilter = {
    attribute: FilterAttribute.DOMAINS,
    type: FilterValueType.LIST,
    comparison: FilterComparison.CONTAINS,
    value: Array.isArray(domainId) ? domainId : [domainId.toString()],
  };
  const date = moment(category);
  const today = date.format('YYYY-MM-DD');
  const yesterday = date.subtract(1, 'day').format('YYYY-MM-DD');
  const periodFilter: PeriodFilter = {
    attribute: FilterAttribute.PERIOD,
    type: FilterValueType.DATETIME,
    comparison: FilterComparison.BETWEEN,
    value: JSON.stringify([yesterday, today]),
  };
  const compareToFilter: CompareToFilter = {
    attribute: FilterAttribute.COMPARE_TO,
    type: FilterValueType.DATETIME,
    comparison: FilterComparison.EQ,
    value: yesterday,
  };
  const overwriteFilters: any = [domainsFilter, periodFilter, compareToFilter];

  const newFilters: FilterBase[] = [];
  if (selectedSerp !== 'all') {
    newFilters.push({
      attribute: FilterAttribute.PAGE_SERP_FEATURES,
      type: FilterValueType.ARRAY,
      comparison: FilterComparison.ALL,
      value: [camelToSnakeCase(selectedSerp)], // Filters should be snake cased
    });
  }

  return linkWithFilters({
    to: '/keywords/list',
    newFilters,
    overwriteFilters,
    filterSet: KEYWORDS_FILTER_SET,
  });
}
