import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';

type Props = {
  children: React.ReactNode;
  parent?: HTMLElement;
  className?: string;
};
const Portal = ({ children, parent, className }: Props) => {
  const el = React.useMemo(() => document.createElement('div'), []);
  useEffect(() => {
    const target = parent?.appendChild ? parent : document.body;
    const classList = ['portal-container'];
    if (className) className.split(' ').forEach((item) => classList.push(item));
    classList.forEach((item) => el.classList.add(item));
    target.appendChild(el);
    return () => {
      target.removeChild(el);
    };
  }, [el, parent, className]);
  return createPortal(children, el);
};

export default Portal;
