import { memo, useCallback } from 'react';
import { Flex, Indicator, Progress, Stack, UnstyledButton, useMantineTheme } from '@mantine/core';
import cn from 'classnames';
import isEqual from 'lodash/isEqual';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import FormatNumber from 'Components/FormatNumber/new';
import {
  COMMERCIAL,
  INFORMATIONAL,
  NAVIGATIONAL,
  TRANSACTIONAL,
} from 'Components/Modal/Content/AddSearchEngine/types';
import AccTitle from 'Components/Title/AccTitle';
import { useSpecificFilter } from 'Hooks';
import { useRemoveFilter, useSetOrOverwriteFilter } from 'Hooks/data/filter/setFilters';
import { useKeydisKpis } from 'Pages/KeywordDiscovery/support/hooks/kpis';
import {
  FilterAttribute,
  FilterComparison,
  FilterValueType,
  SearchIntent as IntentTypes,
} from 'Types/Filter';
import { FilterTrackingKey } from 'Utilities/Analytics/mixpanel';
import { colorScheme } from 'Utilities/colors';
import { t, tct } from 'Utilities/i18n';
import SearchIntentIcon from 'icons/search-intent/searchIntentIcon';
import reusableStyles from '../../../../../css/reusable-styles.module.scss';
import styles from './searchIntent.module.scss';

const intentTypes: IntentTypes[] = [NAVIGATIONAL, INFORMATIONAL, COMMERCIAL, TRANSACTIONAL];

const getIntent = (intent: IntentTypes) => {
  const intentIndex: Record<IntentTypes, string> = {
    T: 'transactional',
    C: 'commercial',
    N: 'navigational',
    I: 'informational',
  };
  return intentIndex[intent];
};

interface SearchIntentProps {
  countryChoice: string | undefined;
}

export const SearchIntent = memo(({ countryChoice }: SearchIntentProps) => {
  const SearchIntentfilter = useSpecificFilter(FilterAttribute.SEARCH_INTENT);
  const setFilter = useSetOrOverwriteFilter();
  const removeFilter = useRemoveFilter();
  const theme = useMantineTheme();

  const { data: filterCounts, loading } = useKeydisKpis(countryChoice);

  const discKeywords = filterCounts?.discoveredKeywords || 0;

  const handleSetSelectedIntentTypes = useCallback(
    (intentType: IntentTypes) => () => {
      const prevIntentArray: IntentTypes[] = SearchIntentfilter?.value ?? [];

      const newIntentArray = prevIntentArray?.includes(intentType)
        ? prevIntentArray.filter((intent) => intent !== intentType)
        : [...prevIntentArray, intentType];

      if (!newIntentArray.length) {
        removeFilter(FilterAttribute.SEARCH_INTENT);
        return;
      }
      setFilter(
        {
          attribute: FilterAttribute.SEARCH_INTENT,
          type: FilterValueType.ARRAY,
          comparison: FilterComparison.ALL,
          value: newIntentArray,
        },
        FilterTrackingKey.DiscoveryQuickAction,
      );
    },
    [SearchIntentfilter?.value, setFilter, removeFilter],
  );

  const backGroundColors: Record<IntentTypes, string> = {
    N: colorScheme.graphs.searchIntent.N,
    I: colorScheme.graphs.searchIntent.I,
    C: colorScheme.graphs.searchIntent.C,
    T: colorScheme.graphs.searchIntent.T,
  };

  const primarySnorlax = theme.colors.snorlax[theme.primaryShade as number];

  return (
    <Stack gap={'xl'} style={{ flexBasis: 200, flexShrink: 0 }}>
      <AccTitle type="h3">{t('Search Intent')}</AccTitle>
      <Stack pb={8}>
        {intentTypes.map((intent) => {
          const intentName = getIntent(intent);
          const intentCount = filterCounts?.[intentName] || 0;
          return (
            <Flex
              gap="sm"
              justify="flex-start"
              align="center"
              direction="row"
              wrap="nowrap"
              key={intent}
              onClick={handleSetSelectedIntentTypes(intent)}
              className={cn('group', reusableStyles.clickable)}
            >
              <Indicator
                label={
                  <FormatNumber value={loading ? '-' : intentCount} maximumFractionDigits={1} />
                }
                size={16}
                radius={6}
                position="bottom-start"
                color={primarySnorlax}
                styles={{
                  root: {
                    fontSize: 11,
                    padding: 4,
                  },
                  indicator: { zIndex: 1, left: '50%', bottom: '4px' },
                }}
              >
                <UnstyledButton
                  mb={5}
                  aria-selected={SearchIntentfilter?.value?.includes(intent) ?? false}
                  aria-label={tct('Filter by [intentName]', { intentName })}
                >
                  <SearchIntentIcon
                    searchIntent={intent}
                    size={26}
                    withFilterTooltip
                    inverted={!SearchIntentfilter?.value?.includes(intent)}
                  />
                </UnstyledButton>
              </Indicator>
              <AccTooltip
                delay={0}
                old
                tooltip={
                  <>
                    <FormatNumber value={intentCount} maximumFractionDigits={1} />
                    <span>{` ${t('of')} `}</span>
                    <FormatNumber value={discKeywords} maximumFractionDigits={1} />
                    <span>{` ${t('keywords')}`}</span>
                  </>
                }
              >
                <Progress
                  value={(intentCount / discKeywords) * 100 || 0}
                  className={styles.progress}
                  styles={{
                    section: {
                      background: backGroundColors[intent],
                    },
                  }}
                />
              </AccTooltip>
            </Flex>
          );
        })}
      </Stack>
    </Stack>
  );
}, isEqual);
SearchIntent.displayName = 'SearchIntent';
