import { useSelector } from 'react-redux';
import { StoreType } from 'Types/Store';

/**
 * Get the current report template state from the Redux store.
 *
 * @example
 * const { elements, name, color } = useReportTemplate();
 */
export const useReportTemplate = () => useSelector((state: StoreType) => state.reportTemplate);
