import { useCallback, useMemo } from 'react';
import { useApolloClient } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { NotesType } from 'Components/Chart/LineChart';
import { fakePeriodFilter, fakeTableDetails } from 'Constants';
import { ChartNotesDocument, useChartNotesQuery, useChartNotesWithKeywordsQuery } from 'Ghql';
import { useStandardFilters } from 'Hooks';
import { useModal } from 'Hooks/base/useModal';
import { FilterAttribute } from 'Types/Filter';
import { useSmartQuery } from '../../../../Utilities/Graphql/hooks/useSmartQuery';

const useRefetchNotesQuery = () => {
  const client = useApolloClient();
  return useCallback(() => {
    client.refetchQueries({
      include: [ChartNotesDocument],
    });
  }, [client]);
};

export const useNotesSelect = (onShow?: Function) => {
  const { showModal } = useModal();
  const refetchNotes = useRefetchNotesQuery();

  return (noteId: string): void => {
    if (!noteId) {
      Sentry.captureException('Show note: No selected note');
    }

    onShow?.();
    showModal({
      modalType: 'EditNote',
      nested: true,
      modalProps: {
        theme: 'light',
        noteId,
        refresh: refetchNotes,
        defaultEditMode: true,
      },
    });
  };
};

export const useQueryNotes = (): { notes: NotesType[]; isLoadingNotes: boolean } => {
  const filters = useStandardFilters();
  const { data: notesData, loading: isLoadingNotes } = useChartNotesQuery({
    variables: { filters, ...fakeTableDetails },
    fetchPolicy: 'cache-first',
  });
  return { notes: (notesData?.keywords?.notes as NotesType[]) ?? [], isLoadingNotes };
};

export const useQueryNotesByKeywordId = (keywordId?: string) => {
  const filters = useStandardFilters();
  const filtersButWithFakePeriod = useMemo(
    () => filters.map((f) => (f?.attribute === FilterAttribute.PERIOD ? fakePeriodFilter : f)),
    [filters],
  );

  const { data: notesData, loading: isLoadingNotes } = useSmartQuery(
    useChartNotesWithKeywordsQuery,
    {
      variables: {
        filters: filtersButWithFakePeriod,
        ...fakeTableDetails,
        keywordId: keywordId || '',
      },
      fetchPolicy: 'cache-and-network',
      skip: !keywordId,
    },
  );
  const notes = (notesData?.keywords?.notes as NotesType[]) || [];

  return { notes, isLoadingNotes };
};
