import AccTitle from 'Components/Title/AccTitle';
import LogoBrand from 'icons/logo-brand.svg';
import Logo from 'icons/logo.svg';
import styles from './auth-page.module.scss';

type Props = {
  children: React.ReactNode;
  formHeader?: React.ReactNode;
  heroText?: React.ReactNode;
};
const AuthPageContainer = (props: Props) => {
  const { children, formHeader, heroText } = props;

  return (
    <div className={styles.authPageOuterContainer}>
      <div className={styles.authPageInnerContainer}>
        <div className={styles.contentContainer}>
          <div className={styles.formHeaderWrapper}>
            <img src={LogoBrand} alt={'AccuRanker'} className={styles.formHeaderLogo} />
            {formHeader}
          </div>
          <div className={styles.formContainer}>{children}</div>
        </div>

        <div className={styles.heroContainer}>
          <div className={styles.logo}>
            <img src={Logo} alt={'AccuRanker'} />
          </div>
          {heroText && (
            <AccTitle mt="xxs" type="h3" fw={400} c="white">
              {heroText}
            </AccTitle>
          )}
        </div>
      </div>
    </div>
  );
};

export default AuthPageContainer;
