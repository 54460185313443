import React, { Children, FC } from 'react';

type Props = {
  children: React.ReactNode;
  count?: number;
  className?: string;
};

const SkeletonTableBody: FC<Props> = ({ children, count = 3, className }) => {
  return (
    <tbody className={className}>
      {Array.from({ length: count }, (_, index) => (
        <tr key={index}>
          {Children.map(children, (child, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={idx}>
              {React.isValidElement(child) &&
              child.type &&
              (child.type as any).displayName !== 'SkeletonTableCell' &&
              (child.type as any) !== 'td' ? (
                <td>{child}</td>
              ) : (
                child
              )}
            </React.Fragment>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

export default React.memo(SkeletonTableBody);
