import { createSelector } from 'reselect';
import { StoreType } from 'Types/Store';
import { AppLanguage } from '../Constants';

const languageSelector = (state: StoreType) => state.user?.language ?? AppLanguage.ENGLISH;

const convertLanguageToLocale = (language: string) => {
  switch (language) {
    case AppLanguage.DANISH:
      return 'da_DK';

    case AppLanguage.ENGLISH:
      return 'en_US';

    default:
      return language;
  }
};

export default createSelector(languageSelector, convertLanguageToLocale);
