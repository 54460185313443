export const connectedCallback = (showModal: Function) => () => {
  const runningVersion = COMMITHASH;

  fetch(`/app/buildversion.json?bust=${Date.now()}`)
    .then((response) => response.json())
    .then((data) => {
      const version = data.version;

      const isNotLocalhost = location.hostname.indexOf('localhost') !== 0;
      const hasNewVersion = runningVersion !== version;
      if (hasNewVersion && isNotLocalhost) {
        // The server says there is a newer version than the one we are running. Force people to reload.
        showModal({
          modalType: 'NewVersion',
          modalProps: {
            oldVersion: runningVersion,
            newVersion: version,
            autoRefreshAfter: Math.floor(Math.random() * 300) + 30, // Random number so we do not DDoS ourselves by reloading all at once!
          },
        });
      }
    });
};
