import { memo } from 'react';
import isEqual from 'lodash/isEqual';
import { ChartLoader } from 'Components/Chart/ChartLoader/ChartLoader';
import { COLUMN_CHART_HEIGHT } from 'Components/Chart/ColumnChart/support/constants';
import {
  UseColumnChartConfig,
  useColumnChartConfig,
} from 'Components/Chart/ColumnChart/support/hooks/useColumnChartConfig';
import { ReactHighchartsChart } from 'Components/Chart/ReactHighcarts';

export const ColumnChart = memo((props: UseColumnChartConfig) => {
  const chartConfig = useColumnChartConfig(props);
  return (
    <ChartLoader
      loaderType="column"
      loading={props.loading}
      containerBoxProps={{ h: props.height || COLUMN_CHART_HEIGHT + 20, mb: 10 }}
    >
      <ReactHighchartsChart config={chartConfig} />
    </ChartLoader>
  );
}, isEqual);
ColumnChart.displayName = 'ColumnChart';
