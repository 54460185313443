import { gql } from '@apollo/client';

export const QUERY_KEYWORDS_OVERUSE = gql`
  query KeywordOveruseOverview {
    keywordOveruseOverview {
      dates {
        date
        countGreen
        countYellow
        countRed
        tense
        missingScrapes
      }
    }
  }
`;

export const QUERY_KEYWORD_OVERUSE_WALLET = gql`
  query KeywordOveruseWallet($periodFrom: String!, $periodTo: String!) {
    keywordOveruseWallet(periodFrom: $periodFrom, periodTo: $periodTo) {
      periodFrom
      periodTo
      dates {
        date
        overusedKeywords
        price
        currency
      }
      planPeriods {
        periodTo
        periodFrom
      }
    }
  }
`;

export const QUERY_ORGANIZATION_PLAN_INFO = gql`
  query PlanOrganizationPlansInfo {
    keywordPrice {
      pricePerKeywordPerDay {
        amount
        currency
      }
      saveOnUpgrade {
        amount
        currency
        billingPeriod
        maxKeywordsOnUpgradePlan
      }
    }
    user {
      id
      userType
      organization {
        id
        active
        numberOfKeywords
        numberOfDomains
        numberOfUsers
        keywordOveruseExtraCost

        activePlan {
          id
          name
          isFree
          isTrial
          category
          priceMonthly
          priceYearly
          endDate
          billingCycleInMonths
          currency
          maxKeywords
          maxDomains
          maxUsers
        }
        nextPlan {
          id
          name
          startDate
        }
      }
    }
  }
`;
