import { useApolloClient } from '@apollo/client';
import { Flex } from '@mantine/core';
import AccButton from 'Components/AccButton/AccButton';
import { STEPS } from 'Components/Modal/Wizard/Base/Account';
import { ACCOUNT, PROPERTY } from 'Components/Modal/Wizard/ConnectToGA';
import AccTitle from 'Components/Title/AccTitle';
import { universalAnalyticsIdPrefix } from 'Constants';
import {
  GetDomainInfoQuery,
  useRemoveAdobeAnalyticsAccountMutation,
  useRemoveGoogleAnalyticsAccountMutation,
  useRemoveGoogleSearchConsoleAccountMutation,
} from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import toast from 'Hooks/useToast';
import { t } from 'Utilities/i18n';

export type ConnectionSettingsProps = {
  domainInfo?: GetDomainInfoQuery['domain'];
  refetchDomainData?: () => void;
  onClose?: () => void;
};

const ConnectionSettings = (props: ConnectionSettingsProps) => {
  const { domainInfo, refetchDomainData, onClose } = props;

  const [removeAdobeAccount] = useRemoveAdobeAnalyticsAccountMutation();
  const [removeGAAccount] = useRemoveGoogleAnalyticsAccountMutation();
  const [removeGSCAccount] = useRemoveGoogleSearchConsoleAccountMutation();

  const apolloCache = useApolloClient().cache;

  const { gaPropertyId, isDemoDomain, id: domainId, gaAccountId } = domainInfo || {};
  const isAdobeMarketingConnected = !!(
    domainInfo?.adobeMarketingConnection && domainInfo?.adobeMarketingConnection?.id
  );
  const isGAConnected = !!(
    domainInfo?.googleOauthConnectionGa && domainInfo?.googleOauthConnectionGa.id
  );
  const isGSCConnected = !!(
    domainInfo?.googleOauthConnectionGsc && domainInfo?.googleOauthConnectionGsc.id
  );
  const gaConnectionId = domainInfo?.googleOauthConnectionGa?.id;
  const shouldConnectToAnalytics = !isGAConnected && !isAdobeMarketingConnected;

  const { showModal } = useModal();

  const handleUpgradeToGa4 = () => {
    showModal({
      modalType: 'ConnectToGA',
      modalTheme: 'light',
      modalProps: {
        domainId,
        skipToStep: PROPERTY,
        skippedData: {
          [ACCOUNT]: { accountId: gaAccountId },
          [STEPS.SELECT]: { connectionId: gaConnectionId },
        },
      },
    });
  };

  const handleConnectToAnalytics = () => {
    showModal({
      modalType: 'ConnectToAnalytics',
      modalTheme: 'light',
      modalProps: {
        domainId,
        refresh: refetchDomainData,
      },
    });
  };

  const handleDisconnectFromAnalytics = () => {
    let removeAccount;
    if (isAdobeMarketingConnected) {
      removeAccount = removeAdobeAccount;
    } else if (isGAConnected) {
      removeAccount = removeGAAccount;
    }
    removeAccount &&
      removeAccount({
        variables: {
          input: {
            domainId,
          },
        },
      }).then(
        () => {
          toast.success(t('Account removed'));
          apolloCache.reset();
          onClose?.();
        },
        () => {
          toast.error(t('Failed to remove account'));
        },
      );
  };

  const handleConnectToGSC = () => {
    showModal({
      modalType: 'ConnectToGSC',
      modalTheme: 'light',
      modalProps: {
        domainId,
        refresh: refetchDomainData,
      },
    });
  };

  const handleDisconnectFromGSC = () => {
    removeGSCAccount({
      variables: {
        input: {
          domainId: domainId || '',
        },
      },
    }).then(
      () => {
        toast.success(t('Account removed'));
        onClose?.();
      },
      () => {
        toast.error(t('Failed to remove account'));
      },
    );
  };

  return (
    <div>
      <AccTitle type="h3">{t('Connections')}</AccTitle>
      <Flex gap={8}>
        {gaPropertyId?.startsWith(universalAnalyticsIdPrefix) && (
          <AccButton variant="secondary" onClick={handleUpgradeToGa4} disabled={isDemoDomain}>
            {t('Upgrade to GA4')}
          </AccButton>
        )}
        <AccButton
          variant="secondary"
          onClick={
            shouldConnectToAnalytics ? handleConnectToAnalytics : handleDisconnectFromAnalytics
          }
          disabled={isDemoDomain}
        >
          {shouldConnectToAnalytics ? t('Connect to Analytics') : t('Disconnect from Analytics')}
        </AccButton>
        <AccButton
          variant="secondary"
          onClick={!isGSCConnected ? handleConnectToGSC : handleDisconnectFromGSC}
          disabled={isDemoDomain}
        >
          {!isGSCConnected ? t('Connect to Search Console') : t('Disconnect from Search Console')}
        </AccButton>
      </Flex>
    </div>
  );
};

export default ConnectionSettings;
