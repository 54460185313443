import { useUser } from 'Hooks/data/user/useUser';
import useUserPermission from 'Hooks/useUserPermission';
import * as Actions from 'Pages/Layout/ActionsMenu/Actions';
import ActionbarContainer from 'Pages/Layout/ActionsMenu/components/ActionbarContainer';
import { t } from 'Utilities/i18n';
import { useModal } from '../../../../Hooks/base/useModal';

export const SubAccountActions = ({ updatePage }: { updatePage: () => void }) => {
  const { showModal } = useModal();
  const user = useUser();
  const { isAdmin } = useUserPermission();
  const isSubAccount = user?.isOnMultiaccount;
  const addSubAccount = () =>
    showModal({
      modalType: 'AddSubAccount',
      modalProps: { refresh: updatePage },
    });

  const handleAdd = () =>
    showModal({
      modalType: 'RequestAccess',
      modalProps: { refresh: updatePage },
    });

  return (
    <ActionbarContainer mt="md">
      <Actions.AddAction
        key="addSubAccount"
        label={t('Add sub-account')}
        onClick={addSubAccount}
        disabled={isSubAccount || !isAdmin}
        showTooltip={isSubAccount || !isAdmin}
        tooltip={
          isSubAccount
            ? t('Adding sub-accounts is not possible from a sub-account')
            : t('Admin and Super users can create new sub-accounts')
        }
      />
      <Actions.AddAction
        key="add"
        label={t('Request access to another account')}
        disabled={!isAdmin}
        showTooltip={!isAdmin}
        onClick={handleAdd}
        tooltip={t('Only admin users can request access to another account')}
      />
    </ActionbarContainer>
  );
};
