import { useClickOutside, useMergedRef } from '@mantine/hooks';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import AccPaper from 'Components/AccPaper';
import Portal from 'Components/Portal/portal';
import styles from './popover.module.scss';

export const ChartPopover = observer(({ store, content: Content }) => {
  const onClose = store.hidePopper;
  const clickOutsideRef = useClickOutside(
    () => {
      return onClose();
    },
    undefined,
    [store.target, store.content].filter(Boolean),
  );

  const resultRef = useMergedRef(store.setContent, clickOutsideRef);
  return (
    <Portal>
      <div style={{ display: store.visible ? 'initial' : 'none' }}>
        <AccPaper shadow="xs" p="lg" withBorder ref={resultRef} className={styles.container}>
          <Content {...(toJS(store.contentProps) || {})} onClose={onClose} key={store.visible} />
        </AccPaper>
      </div>
    </Portal>
  );
});
