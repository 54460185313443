import React, { useContext, useRef } from 'react';
import { Box } from '@mantine/core';
import cn from 'classnames';
import { useDropRoot, useTreeContext } from '../react-dnd-treeview/hooks';
import { PlaceholderContext } from '../react-dnd-treeview/providers';
import { isDroppable } from '../react-dnd-treeview/utils/isDroppable';
import { ROOT_ID, ROOT_WRAPPER_ID } from '../support/constants';
import styles from '../edit-mode.module.scss';

export const RootWrapper = ({ children, loading }) => {
  const ref = useRef<HTMLDivElement>(null);
  const treeContext = useTreeContext();
  const placeholderContext = useContext(PlaceholderContext);
  const [isOver, dragSource, drop] = useDropRoot(ref);

  if (isDroppable(dragSource?.id, treeContext.rootId, treeContext)) {
    drop(ref);
  }

  const isTarget = placeholderContext.dropTargetId === treeContext.rootId || isOver;

  return (
    <Box
      ref={ref}
      p="1px"
      className={cn(styles.rootWrapper, {
        [styles.isTarget]: isTarget,
        [styles.isLoading]: loading,
      })}
      id={ROOT_WRAPPER_ID}
      data-drop-id={ROOT_ID}
    >
      {children}
    </Box>
  );
};
