import React, { useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { observe, toJS } from 'mobx';
import { SCROLL_FINISH_RE_RENDER_DELAY } from './constants';
import { viewStore } from './store';

const useDelayedScroll = () => {
  const [, setVersion] = useState(0);

  useEffect(() => {
    let timeout;
    const unsubscribe = observe(viewStore, (change: any) => {
      clearTimeout(timeout);
      if (change.name === 'indexMap') {
        return;
      }
      const nextSelected = toJS(change?.newValue);
      const currentSelected = toJS(change?.oldValue);

      if (!nextSelected && currentSelected) {
        timeout = setTimeout(() => {
          setVersion((v) => v + 1);
        }, SCROLL_FINISH_RE_RENDER_DELAY);
      } else if (nextSelected) {
        clearTimeout(timeout);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);
};

export const withScrollOptimize = (WrappedComponent) => {
  const ResultComp = React.memo(WrappedComponent, (a, b) =>
    viewStore.scrolling ? true : shallowEqual(a, b),
  );

  const ResultComponent = (props) => {
    useDelayedScroll();
    return <ResultComp {...props} />;
  };
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
  ResultComponent.displayName = `withScrollOptimize(${displayName})`;
  ResultComponent.defaultProps = WrappedComponent.defaultProps;
  return ResultComponent;
};
