import { IconChevronDown, IconChevronRight, IconFolder } from '@tabler/icons-react';
import AccTag from 'Components/AccTag';

type Props = {
  droppable: boolean;
  isOpen: boolean;
  isDynamic: boolean;
  label?: string;
  onClick?: (arg: any) => void;
};
export const NodeIcon = (props: Props) => {
  const { droppable, isOpen, isDynamic, label, onClick } = props;

  let result: JSX.Element | null;
  if (droppable) {
    const folderProps = { onClick, color: 'gray', size: 26 };
    result = isOpen ? (
      <>
        <IconChevronDown size={18} color="gray" />
        <IconFolder {...folderProps} />
      </>
    ) : (
      <>
        <IconChevronRight size={18} color="gray" />
        <IconFolder {...folderProps} />
      </>
    );
  } else {
    result = <AccTag dynamic={isDynamic} tag={label} maxWidth={'100%'} />;
  }

  return result;
};
