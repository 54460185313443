import { Group } from '@mantine/core';
import { TablerIconsProps } from '@tabler/icons-react';
import AccText from 'Components/Text/AccText';
import styles from '../winners-losers.module.scss';

type TabLabelProps = {
  label: string;
  color: string;
  icon: (props: TablerIconsProps) => JSX.Element;
  keywords?: number;
};

const SIZE = 20;

export const WinnerLoserTabLabel = ({
  label,
  color,
  icon: ArrowIcon,
  keywords = 0,
}: TabLabelProps) => {
  return (
    <Group gap="xs" my="-2px">
      <ArrowIcon size={SIZE} stroke={2} color={color} />
      <span>{label}</span>
      <AccText size="sm" miw={SIZE} h={SIZE} className={styles.tabKeywordCount}>
        {keywords}
      </AccText>
    </Group>
  );
};
