import { useEffect, useRef } from 'react';
import { Flex } from '@mantine/core';
import toInteger from 'lodash/toInteger';
import moment from 'moment';
import { setServiceMessageRead } from 'Actions/ServiceMessageActions';
import { DEFAULT_DATE_TIME_FORMAT } from 'Constants';
import { useActions } from 'Hooks/redux/useActions';
import { ServiceMessageSeverities } from 'Pages/Layout/DashboardTemplate/SideNavbar/components/NavbarHeader/ServiceMessageStatus';
import { useModalServiceMessagesQuery } from '../../../../Ghql';
import { useModal } from '../../../../Hooks/base/useModal';
import { t } from '../../../../Utilities/i18n';
import { SubscriptionHandle, subscribeToServiceMessage } from '../../../../Utilities/websocket';
import AccAlert from '../../../AccAlert/AccAlert';
import ModalBorder from '../../Layout/ModalBorder';

const ShowServiceMessageModal = () => {
  const _subHandler = useRef<SubscriptionHandle>();

  const { data, refetch } = useModalServiceMessagesQuery();
  const actions = useActions({ setServiceMessageRead });

  useEffect(() => {
    _subHandler.current = subscribeToServiceMessage(() => {
      refetch();
    });
  }, []);

  const { serviceMessages } = data || {};

  const { hideModal } = useModal();

  const handleCloseModal = () => {
    actions.setServiceMessageRead();
    hideModal();
  };

  const getSeverity = (level: ServiceMessageSeverities) => {
    switch (level) {
      case ServiceMessageSeverities.error:
        return 'error';
      case ServiceMessageSeverities.warning:
        return 'warning';
      case ServiceMessageSeverities.info:
        return 'info';
      default:
        return 'info';
    }
  };

  return (
    <ModalBorder title={t('Service Message')} onClose={handleCloseModal}>
      <Flex direction={'column'} gap={12}>
        {serviceMessages?.map((serviceMessage) => {
          return (
            <AccAlert
              variant="block"
              title={serviceMessage.title}
              key={serviceMessage.id}
              severity={getSeverity(toInteger(serviceMessage.level))}
            >
              {
                <div>
                  <div>{serviceMessage.message}</div>
                  <i style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {moment(serviceMessage.createdAt).format(DEFAULT_DATE_TIME_FORMAT)}
                  </i>
                </div>
              }
            </AccAlert>
          );
        })}
      </Flex>
    </ModalBorder>
  );
};

export default ShowServiceMessageModal;
