import { useMemo } from 'react';
import { Stack } from '@mantine/core';
import AccButton from 'Components/AccButton/AccButton';
import { Field, Form } from 'Components/Fields';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import { useRequestAccessMutation, useUserOrganizationsQuery } from 'Ghql';
import toast from 'Hooks/useToast';
import { t } from 'Utilities/i18n/index';
import { notEmpty } from 'Utilities/underdash';
import Validator, { Errors } from 'Utilities/validation';
import FormSkeleton from './FormSkeleton';

type RequestAccessFormValues = {
  toEmail?: string;
  message?: string;
  subject?: string;
  sendACopy?: boolean;
  isOrgAdmin?: boolean;
  fromOrganization?: string;
};

type Props = {
  initialValues?: RequestAccessFormValues;
  refresh: (...args: Array<any>) => any;
  onClose: (...args: Array<any>) => any;
};

const RequestAccessForm = (props: Props) => {
  const { onClose, refresh, initialValues } = props;

  const [requestAccess] = useRequestAccessMutation();

  const { data: userOrg, loading, error } = useUserOrganizationsQuery();
  const { user } = userOrg || {};
  const { organizations } = user || {};

  const values = useMemo(
    () => ({
      toEmail: initialValues?.toEmail,
      message: initialValues?.message,
      sendACopy: initialValues?.sendACopy,
      subject: initialValues?.subject,
      isOrgAdmin: false,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialValues?.sendACopy, initialValues?.subject],
  );

  const handleSubmit = ({
    fromOrganization,
    toEmail,
    subject,
    message,
    isOrgAdmin,
    sendACopy,
  }: RequestAccessFormValues) => {
    const requestAccessInput = {
      fromOrganization: fromOrganization || '',
      toEmail: toEmail || '',
      subject: subject || '',
      message: message || '',
      isOrgAdmin: !!isOrgAdmin,
      sendACopy: !!sendACopy,
    };
    return requestAccess({
      variables: {
        requestAccessInput,
      },
    }).then(({ data }) => {
      const { multiAccount, errors } = data?.addMultiAccountRequest || {};
      if (!multiAccount) {
        toast.error('Something went wrong');
        if (errors && errors.length) {
          Validator.setResponseErrors(Validator.throwSubmissionError, errors as Errors);
        }
      } else {
        onClose();
        refresh();
        toast.success(t('Request sent'));
      }
    });
  };

  if (loading || error) {
    return <FormSkeleton />;
  }

  const options =
    organizations?.filter(notEmpty).map((org) => ({
      label: org.name,
      value: org.id,
    })) || [];

  return (
    <Form<RequestAccessFormValues> onSubmit={handleSubmit} initialValues={values}>
      {({ invalid, submitting }) => (
        <>
          <Stack gap="xl" pb="sm">
            <Field.Select
              required
              name="fromOrganization"
              label={t('From organization')}
              type="text"
              placeholder={t('Select your organization')}
              validate={Validator.required}
              options={options}
              autoFocus={true}
            />
            <Field.TextInput
              required
              name="toEmail"
              label={t('To email')}
              type="email"
              placeholder={t('To email')}
              validate={[Validator.required, Validator.email]}
            />
            <Field.TextInput
              required
              name="subject"
              label={t('Subject')}
              placeholder={t('Subject')}
              validate={[Validator.required]}
            />
            <Field.TextAreaInput
              name="message"
              label={t('Message')}
              placeholder={t('Message')}
              validate={[]}
            />
            <Stack gap="md">
              <Field.Checkbox name="isOrgAdmin" label={t('Access the account as an admin user')} />
              <Field.Checkbox name="sendACopy" label={t('Send a copy to myself')} defaultChecked />
            </Stack>
          </Stack>
          <ModalFooter
            primaryButtonSlot={
              <AccButton disabled={invalid || submitting} type="submit" variant="primary">
                {t('Send request')}
              </AccButton>
            }
            secondaryButtonSlot={
              <AccButton onClick={onClose} variant="tertiary">
                {t('Cancel')}
              </AccButton>
            }
          />
        </>
      )}
    </Form>
  );
};

export default RequestAccessForm;
