import isNil from 'lodash/isNil';
import ErrorBoundary from 'Components/ErrorBoundary';
import KpiBox, { KpiBoxSizeProps } from 'Pages/Keywords/Overview/components/KpiBar/KpiBox';
import { KPI_NAMES } from 'Pages/Keywords/Overview/components/KpiBar/index';
import { t } from 'Utilities/i18n';
import { KpiBarQueryProps } from './useKpiBarQueryWithDisplayCurrency';

const DynamicCtrKpiBox = ({
  size,
  kpiBarQueryProps,
  draggable,
}: KpiBoxSizeProps & { kpiBarQueryProps: KpiBarQueryProps }) => {
  const { displayCurrency, data, loading } = kpiBarQueryProps;

  const ctr = isNil(data?.kpis?.allKpis?.ctr) ? undefined : data?.kpis?.allKpis?.ctr;
  const ctrBefore = isNil(data?.kpis?.allKpis?.ctrBefore)
    ? undefined
    : data?.kpis?.allKpis?.ctrBefore;

  return (
    <ErrorBoundary>
      <KpiBox
        id={KPI_NAMES.DYNAMIC_CTR}
        size={size}
        title={t('Average CTR')}
        loading={loading || !displayCurrency}
        value={!loading && !isNil(ctr) ? ctr / 100 : '-'}
        beforeValue={!loading && ctrBefore ? ctrBefore / 100 : '-'}
        showPercentage={true}
        precision={2}
        maxValue={data?.kpis?.allKpis?.maxCtr ? data?.kpis?.allKpis?.maxCtr / 100 : undefined}
        draggable={draggable}
      />
    </ErrorBoundary>
  );
};

export default DynamicCtrKpiBox;
