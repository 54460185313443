import { useSelector } from 'react-redux';
import { daysInPeriod } from 'Components/PeriodFilter/model';
import PieChart from 'Components/PieChart';
import { getSerpFeatureData } from 'Components/Table/TableRow/SERPOptions/data';
import SpecificFilterSelector from 'Selectors/SpecificFilterSelector';
import { FilterAttribute } from 'Types/Filter';
import { colorScheme } from 'Utilities/colors';
import { t } from 'Utilities/i18n/index';
import { notEmpty } from 'Utilities/underdash';
import { SerpFeatureStats } from '../index';
import './serps-pie-distribution.scss';

type Props = {
  serpFeatureData: SerpFeatureStats | undefined;
  keywordCount: number | undefined;
  onClick: (serp: any) => void;
  isLoading?: boolean;
};
const colors = colorScheme.defaultLines;

const convertToPieChartData = (
  serpFeatureData: SerpFeatureStats | undefined,
  keywordCount: number,
) => {
  if (!serpFeatureData) return [];
  const allChartData = Object.entries(serpFeatureData) // convert to chart data objects
    .map(([feature, stats], index) => {
      if (stats?.owned || keywordCount) {
        return {
          name: getSerpFeatureData(feature).label,
          serp: feature,
          y: stats.owned || 0,
          totalKeywords: keywordCount,
          color: colors[index % colors.length],
        };
      }
    })
    .filter(notEmpty) // sort by count (ie. y axis value)
    .sort((a, b) => {
      return b.y - a.y;
    });
  // truncate tail end into a single chart data item
  const cutoff = 10;
  const first10 = allChartData.slice(0, cutoff);
  const tailEnd = allChartData.slice(cutoff);
  const tailCount = tailEnd.reduce((a, c) => a + c.y, 0);
  // rest/other
  const lastItem = {
    name: t('Other'),
    y: tailCount,
    color: colors[first10.length % colors.length],
  };
  if (first10.length) {
    return [...first10, lastItem];
  }
  return [];
};

const SerpPieDistribution = ({ serpFeatureData, keywordCount = 0, onClick, isLoading }: Props) => {
  const periodFilter = useSelector(SpecificFilterSelector(FilterAttribute.PERIOD));
  const period = periodFilter && daysInPeriod(periodFilter);

  let chartData:
    | {
        name: any;
        y: number;
        color: string;
      }[] = [];

  if (!isLoading) {
    chartData = convertToPieChartData(serpFeatureData, keywordCount);
  }

  return (
    <PieChart
      totalLabel={t('Total owned')}
      seriesLabel={t('Owned')}
      data={chartData}
      loading={isLoading ?? false}
      period={period}
      noDataMessage={t('No SERP feature data for the selected filters')}
      watermark
      watermarkBig
      watermarkCutNumber={1}
      showZeros={false}
      onClick={onClick}
      dataLabels={{
        enabled: true,
        format: '<b>{point.name}</b>',
        alignTo: 'plotEdges',
      }}
    />
  );
};

export default SerpPieDistribution;
