import { useContext } from 'react';
import { AccMultiSelect, AccSelect, SelectItem } from 'Components/AccSelect';
import FakeFiltersContext from 'Components/Filters/FakeFiltersContext';
import { useDomainFoldersQuery } from 'Ghql';
import { useDomainIds } from 'Hooks/data/domain/useQueryDomainInfo';
import { replaceFolderSeparator } from 'Pages/Keywords/Groupings/EditMode/support/helpers';
import { notEmpty, toArrayOrEmpty } from 'Utilities/underdash';

type Props = {
  onChange: (...args: Array<unknown>) => void;
  autoFocus?: boolean;
  placeholder: string;
  showError: boolean;
  disabled?: boolean;
  // show only dynamic tags
};

const useFolderOptions = () => {
  const domainIds = useDomainIds();

  const filtersContext = useContext(FakeFiltersContext);
  const resultDomainIds = toArrayOrEmpty(filtersContext?.domainId ?? domainIds) as
    | string[]
    | undefined;

  const { data, loading } = useDomainFoldersQuery({
    variables: { ids: resultDomainIds },
    fetchPolicy: 'network-only',
  });

  const options: SelectItem<string>[] | undefined = !loading
    ? data?.domainsList?.[0]?.folders?.filter(notEmpty).map((folder) => ({
        label: replaceFolderSeparator(folder || ''),
        value: folder,
      }))
    : [];

  return { loading, options };
};

export const FolderInput = (props: Props & { value: string | null }) => {
  const { loading, options } = useFolderOptions();
  return (
    <AccSelect
      groupHidden
      value={props.value}
      onChange={props.onChange}
      placeholder={props.placeholder}
      showError={props.showError}
      autoFocus={props.autoFocus}
      disabled={props.disabled}
      creatable={false}
      isLoading={loading}
      options={options ?? []}
      size="md"
    />
  );
};

export const FoldersInput = (props: Props & { value: string[] | null }) => {
  const { loading, options } = useFolderOptions();
  return (
    <AccMultiSelect
      value={props.value}
      onChange={props.onChange}
      placeholder={props.placeholder}
      showError={props.showError}
      autoFocus={props.autoFocus}
      creatable={false}
      disabled={props.disabled}
      isLoading={loading}
      options={options ?? []}
    />
  );
};

FolderInput.defaultProps = {
  value: undefined,
  onChange: () => {},
  showError: false,
  options: [],
  disabled: false,
  creatable: true,
};
