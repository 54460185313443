import { useMemo, useRef } from 'react';
import { Box } from '@mantine/core';
import { Options } from 'highcharts';
import { LineChart, NotesType } from 'Components/Chart/LineChart';
import { chartTooltipTypes } from 'Components/Chart/LineChart/support/types';
import { useHistoryChartsQuery } from 'Ghql';
import { useFilters } from 'Hooks';
import { MissingDataOverlay } from 'Pages/Keywords/Overview/MissingDataOverlay';
import { ChartContainer } from 'Pages/Keywords/Overview/components/ChartContainer/index';
import { getPixelsFromTopSeries } from 'Pages/Keywords/Overview/components/HistoryCharts/PixelsFromTop/helpers';
import { useFakeData } from 'Pages/Keywords/Overview/hooks/useFakeData';
import { useGroupViewInfo } from 'Pages/Keywords/Overview/hooks/useGroupViewInfo';
import { WithDragDetails } from 'Pages/Keywords/Overview/support/types';
import { colorScheme } from 'Utilities/colors';
import { t } from 'Utilities/i18n';
import { useAddKeywordsModal } from '../support/util';

interface PixelsFromTopChartProps {
  domainId: number;
  notesLoading: boolean;
  notes: NotesType[] | undefined;
  enabledHistoryCharts: string[];
}

export const PixelsFromTopChart = (props: PixelsFromTopChartProps & WithDragDetails) => {
  const filters = useFilters();
  const { isEmptyGroup, isGroupView } = useGroupViewInfo(filters);
  const chartRef = useRef<HTMLDivElement>(null);
  const { SovChartFakeData } = useFakeData();
  const { data, loading, refetch } = useHistoryChartsQuery({
    variables: { filters, name: 'historyCharts', charts: props.enabledHistoryCharts },
    skip: isEmptyGroup,
  }); // use all history chart names to reuse query
  const addKeywordsModal = useAddKeywordsModal(props.domainId, refetch);

  const extraOptions: Options = useMemo(
    () => ({
      id: 'pixelsFromTopOverview',
      navigator: { series: { lineColor: colorScheme.graphs.colors.turqoise } },
      plotOptions: {
        areaspline: { fillColor: `${colorScheme.graphs.colors.turqoise}9d` },
      },
    }),
    [],
  );

  const chartSeries = getPixelsFromTopSeries(data?.graphs?.overviewHistoryGraphs);

  const filtersAdded = filters.length > 3;
  const showFakeData = !filtersAdded && !data?.graphs?.overviewHistoryGraphs?.length;

  return (
    <ChartContainer
      title={t('Average Pixels From Top')}
      tooltip={[
        t(
          'This chart shows the average pixels from top for the keywords in the current filtering.',
        ),
      ]}
      dragRef={props.dragRef}
      boardControls={props.boardControls}
      chartRef={chartRef}
      chartSeries={chartSeries}
    >
      <Box pos="relative" ref={chartRef}>
        {!loading && showFakeData && (
          <MissingDataOverlay
            isGroupView={isGroupView}
            isEmptyGroup={isEmptyGroup}
            type="missingKeywords"
            onClick={addKeywordsModal}
          />
        )}
        <LineChart
          series={showFakeData ? SovChartFakeData : chartSeries}
          extraOptions={extraOptions}
          chartType="area"
          isLoading={loading || props.notesLoading}
          notes={props.notes}
          showPercentage={false}
          enableRangeSelector
          loaderType="line"
          disableLegend
          tooltip={{
            type: chartTooltipTypes.STOCK,
            title: t('Average Pixels From Top'),
            showPercentage: false,
          }}
          exportButton={false}
        />
      </Box>
    </ChartContainer>
  );
};
