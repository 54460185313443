import { Component } from 'react';
import { connect } from 'react-redux';
import { hideModal } from 'Actions/ModalAction';
import AccButton from 'Components/AccButton/AccButton';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { t } from 'Utilities/i18n';
import './serp-info.scss';

type Props = {
  hideModal: (...args: Array<any>) => any;
  label: string;
  text: string;
  image: string;
  onBack?: any;
};

class SERPInfo extends Component<Props> {
  render() {
    const { image, label, text, onBack } = this.props;
    return (
      <ModalBorder className="serp-info-modal" title={label} onClose={this.props.hideModal}>
        <div className="serp-info-modal-content">
          <img className="text-right" src={image} />
          <p>{text}</p>
        </div>
        {onBack && (
          <div className="confirmation-button-wrapper text-right">
            <AccButton variant="tertiary" onClick={onBack}>
              {t('Back')}
            </AccButton>
          </div>
        )}
      </ModalBorder>
    );
  }
}

export default connect(
  (state: any) => ({
    currentModal: state.modal,
  }),
  {
    hideModal,
  },
)(SERPInfo);
