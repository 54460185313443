import { Component } from 'react';
import { Field, Fields } from 'redux-form';
import { DropdownField, TextField, TextFieldWithFilterCount } from 'Components/Forms/Fields';
import { t } from 'Utilities/i18n';
import Validation from 'Utilities/validation';
import { getComparisonLabels } from '../labelFunc';

type Props = {
  placeholder: string;
  comparisonOptions?: string[];
  showComparisonOptions?: boolean;
  autoFocus: boolean;
  handleReset: (...args: Array<any>) => any;
  isEmpty: boolean;
  validate?: typeof Validation[];
  withFilterCount?: boolean;
};

class StringEditor extends Component<Props> {
  static defaultProps = {
    showComparisonOptions: true,
    autoFocus: true,
    validate: [Validation.required],
    withFilterCount: false,
  };
  comparisonLabels = getComparisonLabels();

  render() {
    let comparisonOptions: any = null;
    const { showComparisonOptions, autoFocus, handleReset, isEmpty, validate, withFilterCount } =
      this.props;

    if (this.props.comparisonOptions && this.props.comparisonOptions.length > 1) {
      comparisonOptions = this.props.comparisonOptions.map((optionValue) => ({
        label: this.comparisonLabels[optionValue],
        value: optionValue,
      }));
    }

    if (withFilterCount) {
      return (
        <div>
          <Fields
            names={['comparison', 'value', 'attribute', 'type']}
            component={TextFieldWithFilterCount}
            autoFocus={autoFocus}
            name="value"
            onReset={handleReset}
            showReset={!isEmpty && handleReset}
            resetTooltip={t('Reset filter')}
            placeholder={this.props.placeholder}
            validate={validate}
          />
          {comparisonOptions && showComparisonOptions && (
            <Field
              name="comparison"
              className="filter-button"
              component={DropdownField}
              items={comparisonOptions}
            />
          )}
        </div>
      );
    }

    return (
      <div>
        <Field
          component={TextField}
          autoFocus={autoFocus}
          name="value"
          onReset={handleReset}
          showReset={!isEmpty && handleReset}
          resetTooltip={t('Reset filter')}
          placeholder={this.props.placeholder}
          validate={validate}
        />
        {comparisonOptions && showComparisonOptions && (
          <Field
            name="comparison"
            className="filter-button"
            component={DropdownField}
            items={comparisonOptions}
          />
        )}
      </div>
    );
  }
}

export default StringEditor;
