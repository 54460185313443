import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { gaSetFromCallback, gaSetPassedState, gaToggleRefetch } from 'Actions/GoogleAccountsAction';
import { useModal } from 'Hooks/base/useModal';
import { t } from 'Utilities/i18n';

const GoogleAccountsCallback = () => {
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const { showModal } = useModal();

  useEffect(() => {
    const oAuthResult = {
      error: search.get('error'),
      code: search.get('code'),
      state: search.get('state'),
    };
    const oAuthData = JSON.parse(sessionStorage.getItem('oAuthData') || '{}');
    const { url: previousUrl, passedState } = oAuthData;
    gaSetFromCallback(true);

    if (passedState) {
      gaSetPassedState(passedState);
    }

    navigate(previousUrl, { replace: true });

    setTimeout(() => {
      // TODO ugly hack. no idea why the timeout is now necessary (after updating react-router to v6)
      // Open modal if we had modal before
      if (oAuthData.modalParams.modalType) {
        showModal({
          ...oAuthData.modalParams,
          modalProps: {
            ...oAuthData.modalParams.modalProps,
            refresh: () => gaToggleRefetch(),
            onError: () => gaSetFromCallback(false),
            oAuthResult,
          },
        });
      }
    }, 1000);
  }, []);

  return t('Waiting for Google Analytics...');
};

export default GoogleAccountsCallback;
