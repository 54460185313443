import { useApolloClient } from '@apollo/client';
import { Menu } from '@mantine/core';
import { IconDotsVertical, IconEdit, IconTrash } from '@tabler/icons-react';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { DomainsInGroupDocument, useDeleteDomainsMutation } from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import toast from 'Hooks/useToast';
import { EventName, useMixpanel } from 'Utilities/Analytics/mixpanel';
import { invalidateCache } from 'Utilities/Graphql/invalidateCache';
import { domainDeleteConfirmation } from 'Utilities/getConfirmationDescription';
import { t } from 'Utilities/i18n';
import { devError } from 'Utilities/log';
import { useDomainContext } from './DomainContext';

const DomainMenu = () => {
  const { id, clientId, domain, isDemoDomain } = useDomainContext();
  const { showModal } = useModal();
  const client = useApolloClient();

  const trackEvent = useMixpanel();

  const [deleteDomain] = useDeleteDomainsMutation({
    onCompleted(data, clientOptions) {
      toast.success(t('Domain is being deleted. This might take a few seconds.'));
      trackEvent(EventName.DeleteDomainSuccess, {
        'Deleted Domain ID': clientOptions?.variables?.input?.id ?? '',
        Source: 'Domain card',
      });
      invalidateCache(client.cache, [
        'clientsRaw',
        `domain({"id":"${id}"})`,
        'domains',
        'domainsList',
        'tableDashboard',
      ]);
    },
    onError(error, clientOptions) {
      toast.error(t('Could not delete domain'));
      trackEvent(EventName.DeleteDomainFailed, {
        'Deleted Domain ID': clientOptions?.variables?.input?.id ?? '',
        Source: 'Domain card',
        Error: `${error.name}: ${error.message}`,
      });
    },
  });
  const handleEditDomain = () => {
    showModal({
      modalType: 'BuildDomain',
      modalTheme: 'light',
      modalProps: {
        domainId: id,
        groupId: clientId,
        onClose: () => {
          // Refetch data used in the useDropdownData hook
          client.refetchQueries({
            include: [DomainsInGroupDocument],
          });
        },
      },
    });
  };
  const handleDeleteDomain = () => {
    if (!id) {
      devError('Failed to delete: Missing domain ID');
      return;
    }

    deleteDomain({
      variables: {
        input: {
          id: `${id}`,
        },
      },
    });
  };
  const showDeleteConfirmation = () => {
    showModal({
      modalType: 'Confirmation',
      modalProps: {
        title: t('Delete Domain?'),
        description: domainDeleteConfirmation(domain),
        confirmLabel: t('Delete domain'),
        action: handleDeleteDomain,
      },
    });
  };
  return (
    <Menu shadow="md" position={'bottom-end'}>
      <AccTooltip tooltip={t('More options')}>
        <Menu.Target>
          <AccActionIcon variant="subtle" color="snorlax.4">
            <IconDotsVertical size={20} />
          </AccActionIcon>
        </Menu.Target>
      </AccTooltip>

      <Menu.Dropdown>
        <Menu.Item
          leftSection={<IconEdit size={14} />}
          onClick={handleEditDomain}
          disabled={isDemoDomain}
        >
          {t('Edit domain')}
        </Menu.Item>
        <Menu.Item leftSection={<IconTrash size={14} />} onClick={showDeleteConfirmation}>
          {t('Delete domain')}
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default DomainMenu;
