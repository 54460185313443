/* eslint-disable react/display-name */
import { useRef, useState } from 'react';
import { Box } from '@mantine/core';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import cn from 'classnames';
import fetchJsonp from 'fetch-jsonp';
import AccButton from 'Components/AccButton/AccButton';
import { t } from 'Utilities/i18n';
import { useAddKeywordsFields, useCountryLocales } from '../hooks';
import { ShowSuggestionsState } from '../types';
import styles from './keywords-suggestions-input.module.scss';

const useSearchLocale = () => {
  const fields = useAddKeywordsFields();

  const engines = fields.engines.fields.value;
  const localeId = engines[0]?.countrylocale; // TODO: props.localeId? props.initialValues.localeId? These were used in legacy code, but I don't understand when they would be set

  const locales = useCountryLocales();

  if (!locales) {
    // Loading
    return null;
  }

  return locales.find((localObj) => localObj.id === localeId);
};

export default ({ showSuggestions, setShowSuggestions }: ShowSuggestionsState) => {
  const [suggestionSearchText, setSuggestionSearchText] = useState('');
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const searchLocale = useSearchLocale();
  const inputRef = useRef<HTMLInputElement>(null);

  const fields = useAddKeywordsFields();

  const fetchKeywordSuggestions = (text: string) => {
    fetchJsonp(
      `https://suggestqueries.google.com/complete/search?q=${text}&hl=${
        !searchLocale ? 'en' : searchLocale.localeShort
      }&client=youtube&_=1531379428615&gl=${
        !searchLocale ? 'us' : searchLocale.countryCode.toLowerCase()
      }`,
      {
        mode: 'no-cors',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any,
    )
      .then((response) => response.json())
      .then((responseData) => {
        setSuggestions(responseData[1].map((el) => el[0]));
      });
  };

  const handleSuggestionSearchText = (text: string) => {
    setSuggestionSearchText(text);
    fetchKeywordSuggestions(text);
  };
  return (
    <div className={styles.suggestions}>
      <AccButton
        onClick={() => {
          setShowSuggestions(!showSuggestions);
          inputRef?.current?.focus();
        }}
        variant="tertiary"
        fullWidth
        mb="md"
        leftSection={showSuggestions ? <IconChevronUp size={18} /> : <IconChevronDown size={18} />}
      >
        {showSuggestions ? t('Hide keyword suggestions') : t('Show keyword suggestions')}
      </AccButton>

      <div
        className={cn({
          [styles.suggestionsList]: showSuggestions,
        })}
      >
        <input
          ref={inputRef}
          type="text"
          className={cn(styles.suggestionsInput, {
            [styles.hide]: !showSuggestions,
          })}
          value={suggestionSearchText}
          onChange={(e) => handleSuggestionSearchText(e.target.value)}
          placeholder={t('Start typing for keyword suggestions…')}
        />

        {showSuggestions && suggestions.length ? (
          <Box w="400px" maw="400px">
            <div className={styles.suggestionDisclaimer}>
              {t('Click on a keyword to add/remove it in the list above')}
            </div>
            {suggestions.map((el) => {
              const keywords = fields.keywords.input.value;
              const exists = keywords.includes(el);
              return (
                <div
                  key={el}
                  className={cn(styles.suggestionTag, {
                    [styles.disabled]: exists,
                  })}
                  onClick={() =>
                    fields.keywords.input.onChange(
                      exists ? keywords.filter((kw) => kw !== el) : [...keywords, el],
                    )
                  }
                >
                  {el}
                </div>
              );
            })}
          </Box>
        ) : null}
      </div>
    </div>
  );
};
