import ConditionalTooltipWrapper from 'Components/ConditionalTooltipWrapper/ConditionalTooltipWrapper';
import RefreshKeywords from 'Components/RefreshKeywords';
import { ViewMode } from 'Components/ViewMode';
import { getDisabledDemoText } from 'Constants/messages';
import { useModal } from 'Hooks/base/useModal';
import * as Actions from 'Pages/Layout/ActionsMenu/Actions';
import ActionbarContainer from 'Pages/Layout/ActionsMenu/components/ActionbarContainer';
import queryDomainInfo from 'Pages/queryDomainInfo';
import { DomainInfo } from 'Query';
import { TableID } from 'Types/Table';
import ActionMenuList from './ActionMenuList';
import { WithKeywordActionMenuDataProps } from './withKeywordActionMenuData';

interface KeywordActionMenuProps {
  tableName: TableID;
  domainId: string;
  domainInfo?: DomainInfo;
  columnsSettingsName: any;
}

const KeywordActionMenu = ({
  tableName,
  domainInfo,
  domainId,
  columnsSettingsName,
}: KeywordActionMenuProps & WithKeywordActionMenuDataProps) => {
  const { showModal } = useModal();
  return (
    <ActionbarContainer>
      <ActionMenuList
        tableName={tableName}
        domainInfo={domainInfo}
        domainId={domainId}
        columnsSettingsName={columnsSettingsName}
      />
      <ActionbarContainer.Sidebar>
        <Actions.SettingsAction
          key="settings"
          onClick={() =>
            showModal({
              modalType: 'TableSettingsModal',
              modalTheme: 'light',
              modalProps: {
                tableName: 'keywords',
              },
            })
          }
        />
        <ViewMode tableName={tableName} />
        <ConditionalTooltipWrapper
          tooltip={domainInfo?.isDemoDomain ? getDisabledDemoText() : undefined}
        >
          <RefreshKeywords disabled={domainInfo?.isDemoDomain} />
        </ConditionalTooltipWrapper>
      </ActionbarContainer.Sidebar>
    </ActionbarContainer>
  );
};

export default queryDomainInfo()(KeywordActionMenu);
