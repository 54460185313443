import cn from 'classnames';
import styles from 'Pages/Domains/DomainsTable/domains-table.module.scss';

type Props = {
  date: string;
};

const DateCell = ({ date }: Props) => {
  return <div className={cn(styles.domainsCellContainer, styles.textCell)}>{date}</div>;
};

export default DateCell;
