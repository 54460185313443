import { Flex } from '@mantine/core';
import { IconTrendingDown, IconTrendingUp } from '@tabler/icons-react';
import Tabs from 'Components/AccTabs/Tabs';
import FormatNumber from 'Components/FormatNumber/new';
import AccText from 'Components/Text/AccText';
import colorScheme from 'Utilities/colors';
import { percentageChange } from 'Utilities/format';
import styles from '../domainCard.module.scss';

const ChartTab = ({
  panel,
  title,
  value,
  beforeValue,
  precision = 0,
}: {
  panel: string;
  title: string;
  value: number;
  beforeValue?: number | null;
  precision?: number;
}) => {
  let icon: JSX.Element | null = null;
  const change =
    typeof value === 'number' &&
    typeof beforeValue === 'number' &&
    value !== undefined &&
    beforeValue !== undefined
      ? value - beforeValue
      : null;

  const changePercentage: number | false = beforeValue
    ? percentageChange(beforeValue, value)
    : false;
  const increase = change !== null && change >= 0;
  const color = increase ? colorScheme.increase : colorScheme.decrease;

  if (change) {
    const ArrowIcon = increase ? IconTrendingUp : IconTrendingDown;
    icon = <ArrowIcon size={16} stroke={2} color={color} />;
  }

  return (
    <Tabs.Tab value={panel}>
      <AccText c="gray.6" size="xs">
        {title}
      </AccText>
      <Flex direction="column" className={styles.tabValue}>
        <Flex gap="xxs" align="center" justify="inherit">
          <AccText fs="lg" fw={600}>
            <FormatNumber
              maximumFractionDigits={
                typeof value === 'number' && value && Math.abs(value) > 100 ? 0 : precision
              }
              value={value ? Math.abs(value) : value}
            />
          </AccText>
          {icon}
        </Flex>
        {change !== null && (
          <Flex gap="xxs" c={color} fw={600} align="baseline" justify="inherit">
            <FormatNumber
              value={change ? Math.abs(change) : change}
              maximumFractionDigits={change && Math.abs(change) > 100 ? 0 : precision}
            />
            {changePercentage !== false && (
              <AccText size="xs" c="inherit">
                (
                <FormatNumber
                  value={changePercentage}
                  maximumFractionDigits={
                    changePercentage && Math.abs(changePercentage * 100) > 100 ? 0 : 1
                  }
                  style="percent"
                />
                )
              </AccText>
            )}
          </Flex>
        )}
      </Flex>
    </Tabs.Tab>
  );
};

export default ChartTab;
