import { observer } from 'mobx-react';
import { useContextTableStore } from '../../../store/components/TableStoreContext';
import { TableLabels } from '../../../types';
import Pagination from '../Pagination/Pagination';
import { scrollToFirstRow } from '../Pagination/helpers';

type TableFooterContainerProps = {
  tableLabels?: TableLabels;
} & any;

const TableFooterContainer = observer((props: TableFooterContainerProps) => {
  const tableStore = useContextTableStore();

  const setCurrentPage = (nextPage: number) => {
    const pageChanged = tableStore?.changePage(nextPage);
    if (pageChanged) {
      tableStore?.startLoading();
      if (tableStore?.scrollOnPageChange) {
        scrollToFirstRow(tableStore.tableName);
      }
      setTimeout(() => {
        tableStore?.getData();
      }, 0);
    }
  };

  return (
    <>
      {props.stickyScroll}
      <Pagination
        currentPage={tableStore?.pagination?.page ?? 1}
        setCurrentPage={setCurrentPage}
        onPagination={tableStore?.changePage}
        onPageSizeChange={tableStore?.changePaginationSize}
        paginationPageSize={tableStore?.paginationSize ?? 0}
        totalDataLength={tableStore?.totalDataLength ?? 0}
        loading={tableStore?.loading ?? false}
        tableLabels={props.tableLabels}
      />
    </>
  );
});

const TableFooter = (props: TableFooterContainerProps) => <TableFooterContainer {...props} />;
TableFooter.displayName = 'TableFooter';

export default TableFooter;
