import {
  IconBolt,
  IconChessKnight,
  IconClockPlay,
  IconHandClick,
  IconTags,
  IconWorldSearch,
} from '@tabler/icons-react';
import AccText from 'Components/Text/AccText';
import { t } from 'Utilities/i18n';
import styles from '../expired-page.module.scss';

type BulletType = {
  title: string;
  text: string;
  icon: JSX.Element;
};

const CoreFeatureBullets = () => {
  const bullets: BulletType[] = [
    {
      title: t('Performance'),
      text: t(
        'Experience the same fast response whether you are tracking 5.000 or 500.000 keywords.',
      ),
      icon: <IconBolt />,
    },
    {
      title: t('Real-time data'),
      text: t(
        'All data is updated every 24 hours, with the possibility to do on-demand updates on entire domains or single keywords at no extra cost.',
      ),
      icon: <IconClockPlay />,
    },
    {
      title: t('AI CTR'),
      text: t(
        'Gives you insight into any given keyword, the actual CTR, and the maximum potential CTR that can be achieved.',
      ),
      icon: <IconHandClick />,
    },
    {
      title: t('Segmentation'),
      text: t(
        'Slice & dice your data on more than 30 parameters. Save them as segments. Available in the API as well.',
      ),
      icon: <IconTags />,
    },
    {
      title: t('Dynamic competitors'),
      text: t(
        'Know exactly who is stealing your organic traffic, it might not be who you think it is.',
      ),
      icon: <IconChessKnight />,
    },
    {
      title: t('Keyword research'),
      text: t(
        '24B+ keywords global database gives you instant insight into which keywords you and your competitors are ranking for.',
      ),
      icon: <IconWorldSearch />,
    },
  ];
  return (
    <div className={styles.coreFeaturesContainer}>
      {bullets.map((bullet) => (
        <div className={styles.feature} key={bullet.title}>
          {bullet.icon}
          <AccText c="white" inline fw={700} size="16px" pr="xs">
            {bullet.title}
          </AccText>
          <AccText fw={300} c="white">
            {bullet.text}
          </AccText>
        </div>
      ))}
    </div>
  );
};

export default CoreFeatureBullets;
