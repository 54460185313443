import { MouseEvent } from 'react';
import { useNavigate } from 'react-router';
import { linkToGroupings } from 'Components/Filters/LinkToDomain';
import { useModal } from 'Hooks/base/useModal';
import { TreeViewRootNode } from 'Pages/Domains/TreeView/useTreeViewTableConfig';
import { getDomainId, getFolderTagPath } from 'Utilities/generateSelectId';
import { t } from 'Utilities/i18n';

export const useFolderActions = (rootNode: TreeViewRootNode) => {
  const { showModal } = useModal();
  const navigate = useNavigate();
  const domainId = getDomainId(rootNode.path);

  const handleAddTag = (e: MouseEvent<HTMLButtonElement>) => {
    const folderPath = getFolderTagPath(rootNode.path);
    e.stopPropagation();
    showModal({
      modalType: 'SelectTagType',
      modalTheme: 'light',
      modalProps: {
        folderId: folderPath,
        domainId,
      },
    });
  };

  const handleAddFolder = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    showModal({
      modalType: 'Confirmation',
      modalTheme: 'light',
      modalProps: {
        action: () => navigate(linkToGroupings(domainId), { state: { editMode: true } }),
        confirmLabel: t('Yes'),
        cancelLabel: t('Cancel'),
        title: t('Redirect to "Tag Cloud" page?'),
        lockDuration: 0,
        description: t(
          'You can only add folders in "Tag Cloud" page in edit mode. Do you want to enter edit mode?',
        ),
        showExclamationInTitle: false,
        enableOutsideClick: true,
      },
    });
  };

  return {
    handleAddTag,
    handleAddFolder,
  };
};
