import { useEffect, useRef, useState } from 'react';
import { Flex, Group } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import TextInput from 'Components/Controls/TextInput';
import { useSpecificFilter } from 'Hooks';
import { useRemoveFilter } from 'Hooks/data/filter/setFilters';
import { FilterAttribute, FilterComparison, FilterValueType, KeywordFilter } from 'Types/Filter';
import { FilterTrackingKey } from 'Utilities/Analytics/mixpanel';
import { t } from 'Utilities/i18n';
import styleVariables from 'css/base/variables.module.scss';
import { useUpdateUrlFilter } from '../support/useUpdateUrlFilter';

export const PublicKeywordsSearchField = () => {
  const removeFilter = useRemoveFilter();
  const updateUrl = useUpdateUrlFilter();

  const keywordsFilter = useSpecificFilter(FilterAttribute.KEYWORD);
  // Store the keywords filter value in a ref to check if it's updated from outside the component
  const keywordsFilterValueRef = useRef<string | undefined>(keywordsFilter?.value || undefined);

  const [searchValue, setSearchValue] = useState('');

  // Check if the keywords filter is updated from outside the component (keywordsTable)
  useEffect(() => {
    if (keywordsFilterValueRef.current !== keywordsFilter?.value) {
      setSearchValue(keywordsFilter?.value || '');
    }
  }, [keywordsFilter?.value]);

  const handleSearchKeywords = () => {
    if (searchValue === '') {
      removeFilter(FilterAttribute.KEYWORD);
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const keywordFilter: KeywordFilter = {
      attribute: FilterAttribute.KEYWORD,
      type: FilterValueType.STRING,
      comparison: FilterComparison.CONTAINS,
      value: searchValue,
    };
    updateUrl([keywordFilter], FilterTrackingKey.PublicReportKeywordsSearch);
    keywordsFilterValueRef.current = searchValue;
  };

  const handleReset = () => {
    removeFilter(FilterAttribute.KEYWORD);
    setSearchValue('');
    keywordsFilterValueRef.current = undefined;
  };

  const { gray4: iconColor } = styleVariables;

  return (
    <Flex gap="xs">
      <Group align="center" wrap="nowrap" gap="xs">
        <TextInput
          name="keyword"
          value={searchValue}
          onChange={(e) => setSearchValue(e.currentTarget.value)}
          placeholder={t('Search keywords')}
          onReset={handleReset}
          showReset
          style={{
            height: 34,
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSearchKeywords();
            }
          }}
          leftSection={<IconSearch size={20} color={iconColor} />}
        />
        <AccButton
          type="submit"
          variant="secondary"
          onClick={handleSearchKeywords}
          disabled={!searchValue}
        >
          {t('Search')}
        </AccButton>
      </Group>
    </Flex>
  );
};
