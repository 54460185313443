import { useMemo, useRef } from 'react';
import { useNavigate } from 'react-router';
import { Box } from '@mantine/core';
import { LineChart, NotesType } from 'Components/Chart/LineChart';
import { TooltipConfiguration, chartTooltipTypes } from 'Components/Chart/LineChart/support/types';
import linkToKeywordListFromChart from 'Components/Filters/LinkToKeywordListFromChart';
import { useHistoryChartsQuery } from 'Ghql';
import { useFilters } from 'Hooks';
import { MissingDataOverlay } from 'Pages/Keywords/Overview/MissingDataOverlay';
import { ChartContainer } from 'Pages/Keywords/Overview/components/ChartContainer/index';
import { getAverageCtrSeries } from 'Pages/Keywords/Overview/components/HistoryCharts/AverageCTR/helpers';
import { getHistoryChartCsvData } from 'Pages/Keywords/Overview/components/HistoryCharts/AverageRank/AverageRankChart';
import { useFakeData } from 'Pages/Keywords/Overview/hooks/useFakeData';
import { useGroupViewInfo } from 'Pages/Keywords/Overview/hooks/useGroupViewInfo';
import { WithDragDetails } from 'Pages/Keywords/Overview/support/types';
import { FilterAttribute } from 'Types/Filter';
import { colorScheme } from 'Utilities/colors';
import { t } from 'Utilities/i18n';
import { useAddKeywordsModal } from '../support/util';

interface AverageCtrChartProps {
  domainId: number;
  notesLoading: boolean;
  notes: NotesType[] | undefined;
  enabledHistoryCharts: string[];
}

export const AverageCtrChart = (props: AverageCtrChartProps & WithDragDetails) => {
  const filters = useFilters();
  const { isEmptyGroup, isGroupView } = useGroupViewInfo(filters);
  const chartRef = useRef<HTMLDivElement>(null);
  const { SovChartFakeData } = useFakeData();
  const { data, loading, refetch } = useHistoryChartsQuery({
    variables: { filters, name: 'historyCharts', charts: props.enabledHistoryCharts },
    skip: isEmptyGroup,
  }); // use all history chart names to reuse query
  const addKeywordsModal = useAddKeywordsModal(props.domainId, refetch);
  const navigate = useNavigate();

  const extraOptions = useMemo(
    () => ({
      id: 'avgCtrOverview',
      navigator: { series: { lineColor: colorScheme.graphs.colors.darkGreen } },
      plotOptions: {
        areaspline: {
          fillColor: '#a9dbae',
        },
        series: {
          point: {
            events: {
              click: (event) => {
                if (event.point.category) {
                  navigate(
                    linkToKeywordListFromChart(event.point.category, FilterAttribute.DYNAMIC_CTR),
                  );
                }
              },
            },
          },
        },
      },
    }),
    [],
  );

  const tooltipConfig: TooltipConfiguration = {
    type: chartTooltipTypes.STOCK,
    title: t('Average CTR'),
    showPercentage: true,
    bottomText: t('Click to see keywords with CTR greater than 0'),
  };

  const chartSeries = getAverageCtrSeries(data?.graphs?.overviewHistoryGraphs);

  const filtersAdded = filters.length > 3;
  const showFakeData = !filtersAdded && !data?.graphs?.overviewHistoryGraphs?.length;

  const tooltip = (
    <>
      {t(
        'The average click-through rate (CTR) is the average CTR for your individual keywords weighted by their search volume.',
      )}
      <br /> <br />
      {t(
        'The CTR for the individual keywords is estimated based on a machine learning model. Learn more',
      )}{' '}
      <a
        href={
          'https://www.accuranker.com/blog/click-through-rate-what-it-is-and-how-we-calculate-it/'
        }
        target={'_blank'}
        rel="noreferrer"
      >
        {t('here.')}
      </a>
    </>
  );
  return (
    <ChartContainer
      title={t('Average CTR')}
      getCsvData={chartSeries ? () => getHistoryChartCsvData(chartSeries, t('CTR')) : undefined}
      tooltip={[tooltip]}
      dragRef={props.dragRef}
      boardControls={props.boardControls}
      chartRef={chartRef}
    >
      <Box pos="relative" ref={chartRef}>
        {!loading && showFakeData && (
          <MissingDataOverlay
            type="missingKeywords"
            onClick={addKeywordsModal}
            isGroupView={isGroupView}
            isEmptyGroup={isEmptyGroup}
          />
        )}
        <LineChart
          series={showFakeData ? SovChartFakeData : chartSeries}
          tooltip={tooltipConfig}
          extraOptions={extraOptions}
          chartType="area"
          isLoading={props.dragging || loading || props.notesLoading}
          notes={props.notes}
          showPercentage={true}
          loaderType="line"
          disableLegend
          exportButton={false}
          enableRangeSelector
        />
      </Box>
    </ChartContainer>
  );
};
