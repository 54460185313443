import { useEffect, useState } from 'react';
import { Progress } from '@mantine/core';
import Alert from 'Components/Alert';
import { t, tct } from 'Utilities/i18n';
import { UploadImport } from '../types';
import styles from './processing.module.scss';

const getETA = (
  uploadImport: UploadImport,
  subscriptionDataTime: string | null,
  nowDateTime: Date,
) => {
  const {
    rankProcessingStartedAt,
    rankProcessingLastRowIdx,
    rankProcessingResumedAt,
    rankProcessingResumedRankIdx,
    rankProcessingTotalRows,
  } = uploadImport;

  if (!rankProcessingStartedAt || !rankProcessingTotalRows || !rankProcessingLastRowIdx) {
    return t('Initializing');
  }

  const startDateTime = new Date(rankProcessingResumedAt || rankProcessingStartedAt);
  const endDateTime = new Date(subscriptionDataTime || nowDateTime);

  const msElapsed = endDateTime.getTime() - startDateTime.getTime();

  const secondsIndicatingPaused = 2;
  if (
    subscriptionDataTime === null ||
    (nowDateTime.getTime() - endDateTime.getTime()) / 1000 > secondsIndicatingPaused
  ) {
    return t('Waiting for import to resume');
  }

  const rowsProcessed = rankProcessingLastRowIdx ? rankProcessingLastRowIdx + 1 : 0;
  const rowsProcessedSinceStart =
    rowsProcessed - (rankProcessingResumedRankIdx ? rankProcessingResumedRankIdx + 1 : 0);

  const rowsLeft = rankProcessingTotalRows - rowsProcessed;

  const msRateSinceStart = msElapsed / rowsProcessedSinceStart;

  const msRateSinceStartRounded = Math.ceil(msRateSinceStart / 10) * 10;

  const msLeft = msRateSinceStartRounded * rowsLeft;
  const left = new Date(msLeft);
  const [hours, minutes, seconds] = [
    left.getUTCHours(),
    left.getUTCMinutes(),
    left.getUTCSeconds(),
  ].map(String);

  const hoursString = tct('[hours] hours, ', { hours });
  const minutesString = tct('[minutes] minutes and ', { minutes });
  const secondsString = tct('[seconds] seconds.', { seconds });
  const leftHumanReadable = tct(
    'Estimated time remaining: [hoursString][minutesString][secondsString]',
    {
      hoursString: hours !== '0' ? hoursString : '',
      minutesString: minutes !== '0' || (minutes === '0' && hours !== '0') ? minutesString : '',
      secondsString,
    },
  );

  return leftHumanReadable;
};

export const ImportValidationStatusProcessing = (props: {
  uploadImport: UploadImport;
  subscriptionDataTime: string | null;
}) => {
  const { uploadImport, subscriptionDataTime } = props;

  const progress = uploadImport.progress / 100;

  const [nowDateTime, setNowDateTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setNowDateTime(new Date());
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const ETA = getETA(uploadImport, subscriptionDataTime, nowDateTime);

  return (
    <>
      <Alert>
        <p>{ETA}</p>
      </Alert>
      <div className={styles.progress}>
        <Progress.Root size="xl">
          <Progress.Section value={progress}>
            <Progress.Label>{`${progress.toString()}%`}</Progress.Label>
          </Progress.Section>
        </Progress.Root>
      </div>
    </>
  );
};
