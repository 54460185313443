import React from 'react';
import { Box, Flex, Group, Paper, Stack } from '@mantine/core';
import { observer } from 'mobx-react';
import AccBadge from 'Components/AccBadge';
import { useSelectedNodes } from '../../../../../Hooks/useSelectedNodes';
import { NodeIcon } from './NodeItem/NodeIcon';

const SingleDragPreview = (props) => {
  const item = props.monitorProps.item;

  return (
    <Flex align="center" h={30} ml={-20} bg="transparent">
      <NodeIcon droppable={item.droppable} isOpen={false} isDynamic={item?.isDynamic} />
      <div>{item.name}</div>
    </Flex>
  );
};

const MultipleDragPreview = (props) => {
  return (
    <Paper p="xs" pos="relative" bg="transparent">
      <Box pos="absolute" top={-3}>
        <AccBadge type={'gray'}>{props.dragSources.length}</AccBadge>
      </Box>
      <Stack>
        {props.dragSources?.map((node) => (
          <Group key={`multidragpreview-${node.id}`}>
            <Flex bg="transparent" mb={4} h={30} align="center">
              <NodeIcon droppable={node.droppable} isOpen={false} isDynamic={node?.isDynamic} />
              {node.name}
            </Flex>
          </Group>
        ))}
      </Stack>
    </Paper>
  );
};

export const DragPreviewRender = observer((props) => {
  const { selectedNodes } = useSelectedNodes();
  if ((selectedNodes?.length || 0) <= 1) {
    return <SingleDragPreview monitorProps={props} />;
  }
  return <MultipleDragPreview dragSources={selectedNodes} />;
});
