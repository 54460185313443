import { useEffect, useRef, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { Progress } from '@mantine/core';
import * as Sentry from '@sentry/react';
import AccButton from 'Components/AccButton/AccButton';
import {
  AddGoogleAnalyticsAccountDocument,
  AddGoogleAnalyticsAccountMutation,
  AddGoogleAnalyticsAccountMutationVariables,
  useAddGoogleAnalyticsAccountMutation,
} from 'Ghql';
import toast from 'Hooks/useToast';
import { EventName, ExtraProps, useMixpanel } from 'Utilities/Analytics/mixpanel';
import { t } from 'Utilities/i18n';
import { DomainPropertyPairs } from '../PropertyBulkImport';

type AddGAAccountProps = {
  onSubmit: (...args: Array<any>) => any;
  onCancel: (...args: Array<any>) => any;
  connectionId: string;
  accountId?: string;
  propertyId?: string;
  domainPropertyPairs?: DomainPropertyPairs;
  domainId?: string;
  profileId?: string;
};

const AddGAAccount = (props: AddGAAccountProps) => {
  const failedConnections = useRef<number>(0);
  const errorMessage = useRef<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const client = useApolloClient();
  const trackEvent = useMixpanel();

  useEffect(() => {
    if (!props.domainPropertyPairs || isLoading) {
      return;
    }
    const bulkConnect = async () => {
      setIsLoading(true);
      const propertyPairs = Object.keys(props.domainPropertyPairs || {});
      await Promise.all(
        propertyPairs.map(async (propertyKey) => {
          const { propertyValue, gaAccountId = '' } =
            props.domainPropertyPairs?.[propertyKey] || {};
          const addGAAccountInput = {
            domainId: propertyKey,
            connectionId: props.connectionId,
            gaAccountId,
            gaPropertyId: propertyValue || '',
            gaProfileId: props.profileId,
          };
          await client
            .mutate<AddGoogleAnalyticsAccountMutation, AddGoogleAnalyticsAccountMutationVariables>({
              mutation: AddGoogleAnalyticsAccountDocument,
              variables: {
                addGoogleAnalyticsAccountInput: addGAAccountInput,
              },
            })
            .then((response) => {
              if (response.errors) {
                // eslint-disable-next-line no-console
                console.error(response.errors);
                Sentry.captureException(response.errors);
                failedConnections.current = failedConnections.current + 1;
              } else {
                addGAAccountInput.gaProfileId = addGAAccountInput.gaProfileId || '-';
                trackEvent(EventName.ConnectToGA, addGAAccountInput as ExtraProps);
              }
            });
        }),
      )
        .then(() => {
          setIsLoading(false);
          if (failedConnections.current > 0) {
            toast.error(
              t(`Failed to connect ${failedConnections.current} domains to Google Analytics`),
            );
          } else {
            toast.success(t('Connected all selected domains to Google Analytics'));
          }
        })
        .finally(() => {
          props.onSubmit();
        });
    };
    bulkConnect();
  }, []);

  const [addGoogleAnalyticsAccountMutation] = useAddGoogleAnalyticsAccountMutation();

  //Connection a single domain to GA
  if (props.domainId && props.propertyId && props.accountId) {
    const addGoogleAnalyticsAccountInput = {
      domainId: props.domainId,
      connectionId: props.connectionId,
      gaAccountId: props.accountId,
      gaPropertyId: props.propertyId,
      gaProfileId: props.profileId,
    };

    addGoogleAnalyticsAccountMutation({
      variables: {
        addGoogleAnalyticsAccountInput,
      },
    })
      .then((response) => {
        if (response.data) {
          const domainObj = response.data?.addGoogleAnalyticsAccount?.domain;
          props.onSubmit(domainObj);
        }
        if (response.errors) {
          // eslint-disable-next-line no-console
          console.error(response.errors);
          Sentry.captureException(response.errors);
          errorMessage.current = t('Failed to connect to Google Analytics');
        } else {
          trackEvent(EventName.ConnectToGA, addGoogleAnalyticsAccountInput as ExtraProps);
        }
      })
      .then(() => {
        setIsLoading(false);
      });
  }

  const error = errorMessage.current;
  let content: any = null;

  if (isLoading) {
    content = <Progress size="lg" animated value={100} />;
  }

  if (error) {
    content = (
      <div>
        <p>{error}</p>
        <AccButton variant="primary" onClick={props.onCancel}>
          {t('Cancel')}
        </AccButton>
      </div>
    );
  }

  return <div className="save-google-account">{content}</div>;
};

export default AddGAAccount;
