import { Component } from 'react';
import RankDistribution from 'Components/RankDistribution';

type Props = {
  domainId: string;
  rankingDistributionData: Record<string, any>;
  linkable?: boolean;
  width?: number;
};

class RankingDistributionCell extends Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    return (
      nextProps.rankingDistributionData !== this.props.rankingDistributionData ||
      nextProps.domainId !== this.props.domainId
    );
  }

  render() {
    const { domainId, rankingDistributionData, width, linkable = true } = this.props;
    return (
      <RankDistribution
        linkable={linkable}
        domainId={domainId}
        rankingDistribution={rankingDistributionData}
        width={width}
      />
    );
  }
}

export default RankingDistributionCell;
