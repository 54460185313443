import * as React from 'react';
import { ComponentType } from 'react';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import linkToKeywords from 'Components/Filters/LinkToKeywords';
import { withRouter } from 'Utilities/Router';
import colorScheme from 'Utilities/colors';

type Item = {
  id: string;
  label: string;
  value: number;
  range: string;
  domainId: string;
};
type Props = {
  items: Item[];
  linkable?: boolean;
  history?: Record<string, any>;
  width?: number;
};
const colors = colorScheme.rankDistribution;
// Sizes are harcoded, do we need dynamic?
const HEIGHT = 15;
const GAP = 0;

class Distribution extends React.Component<Props> {
  static defaultProps = {
    linkable: false,
    width: 380,
  };

  redirectToPageWithFilter(label: string, domainId: string) {
    this.props.history?.push(linkToKeywords(label, domainId));
  }

  render() {
    const items = this.props.items;
    const propWidth = this.props.width || 380;
    const totalValue = items.reduce((summ, item) => summ + item.value, 0);
    const totalGapsWidth = GAP * (items.length - 1);
    let x = 0;

    const renderItem = (item: Item, index: number) => {
      if (item.value < 1) return null;
      const currentX = x;
      const width = ((propWidth - totalGapsWidth) * item.value) / totalValue;
      x += width + GAP;
      const color = colors[index % colors.length];
      const style: any = {};
      if (this.props.linkable) style.cursor = 'pointer';
      return (
        <AccTooltip tooltip={item.label} old placement="top" key={item.id}>
          <g key={item.id}>
            <rect
              onClick={() =>
                this.props.linkable && this.redirectToPageWithFilter(item.range, item.domainId)
              }
              fill={color}
              x={currentX}
              y={0}
              width={width}
              height={'100%'}
              id={item.id}
              style={style}
            />
          </g>
        </AccTooltip>
      );
    };

    return (
      <svg width={propWidth} height={HEIGHT}>
        {items.map(renderItem)}
      </svg>
    );
  }
}

export default withRouter(Distribution as any) as unknown as ComponentType<
  React.PropsWithChildren<Props>
>;
