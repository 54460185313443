import { Center } from '@mantine/core';
import { IconCirclePlus } from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import { useModal } from 'Hooks/base/useModal';
import useUserPermission from 'Hooks/useUserPermission';
import { TrackingKey } from 'Utilities/Analytics/mixpanel';
import { t } from 'Utilities/i18n';
import style from './addDomain.module.scss';

type Props = {
  groupId?: number | null;
};
const AddDomain = ({ groupId }: Props) => {
  const { userHasWriteAccess } = useUserPermission();
  const { showModal } = useModal();
  const handleAddDomain = () => {
    showModal({
      modalType: 'BuildDomain',
      modalTheme: 'light',
      modalProps: {
        groupId,
      },
    });
  };
  if (!userHasWriteAccess) return null;
  return (
    <Center className={style.addDomain} onClick={handleAddDomain} mih={430}>
      <AccButton
        className={style.addDomainButton}
        leftSection={<IconCirclePlus size={18} />}
        trackingKey={TrackingKey.AddAction}
        variant="secondary"
      >
        {t('Add domain')}
      </AccButton>
    </Center>
  );
};

export default AddDomain;
