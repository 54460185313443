import { memo } from 'react';
import cn from 'classnames';
import times from 'lodash/times';
import styles from './siteMappingRowIndentations.module.scss';

type Props = {
  /**
   * A list of strings used to calculate which indentations should not be connected.
   *
   * The string number will determine the depth of the indentation to be hidden.
   * @example ["hide1", "hide2", "hide5"]
   */
  hideConnectors?: string[];
  /**
   * The width of the icon/button that toggles rows to expand.
   *
   * The value is needed to calculate the position of the connectors.
   * @example "24px"
   */
  expandIconWidth: string;
  className?: string;
  depth: number;
};

const RowIndentations = (props: Props) => {
  const { className, expandIconWidth, hideConnectors, depth } = props;

  const hideIndexes = hideConnectors?.map((string) => parseInt(string.replace('hide', ''), 10));
  const results = times(depth ?? 0);

  return (
    <>
      {results.map((_, i) => (
        <div
          key={i}
          style={{
            width: expandIconWidth,
            opacity: hideIndexes?.some((index) => index - 2 === i) ? 0 : 1,
          }}
          className={cn(styles.container, className, { [styles.connector]: i + 1 === depth })}
        />
      ))}
    </>
  );
};

export default memo(RowIndentations);
