import { makeAutoObservable } from 'mobx';
import { Point } from 'Components/Chart/TreeChart';
import { useDomainId } from '../../../Hooks/data/domain/useQueryDomainInfo';
import { siteMappingTreeModes, siteMappingViewModes } from './helpers';
import { SiteMapViewMode, SitemapNode, TreeViewMode } from './types';

type PageTreeState = {
  zoom?: number;
  translate?: Point;
};

const defaultTreeState = {
  translate: { x: 100, y: 400 },
  node: null,
  zoom: undefined,
};

class SitemapStore {
  constructor() {
    makeAutoObservable(this);
  }

  selectedNode: SitemapNode | null = null;
  expandedNodeIds: string[] | undefined = undefined;
  pagesTreeState: PageTreeState = defaultTreeState;
  viewModes = siteMappingViewModes;
  treeViewModes = siteMappingTreeModes;
  viewMode: SiteMapViewMode = this.viewModes[0];
  treeViewMode: TreeViewMode = this.treeViewModes[0];
  orderBy: string = 'total_keywords';
  maxChildren: number = 40;

  setViewMode = (mode: SiteMapViewMode) => {
    this.viewMode = mode;
  };

  setMaxChildren = (value: number) => {
    this.maxChildren = value;
  };

  setTreeViewMode = (mode: TreeViewMode) => {
    this.treeViewMode = mode;
  };

  setOrderBy = (mode: string) => {
    this.orderBy = mode;
  };

  setSelectedNode = (selectedNode: SitemapNode | null) => {
    if (selectedNode === null) {
      // Reset
      this.selectedNode = null;
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { children, ...node } = selectedNode; // Extract node without children to avoid heavy copy of all children
    this.selectedNode = { ...node, children: ['Dummy' as unknown as SitemapNode] }; // Need dummy children such that children is not none when returning
  };

  saveExpandedNodes = (nodeIds: string[]) => {
    this.expandedNodeIds = nodeIds;
  };

  setPagesTreeState = ({ translate, zoom }: PageTreeState) => {
    if (translate) {
      this.pagesTreeState.translate = translate;
    }
    if (zoom) {
      this.pagesTreeState.zoom = zoom;
    }
  };

  setDefaultState = () => {
    this.pagesTreeState = defaultTreeState;
  };
}

const domainSitemaps: { [key: string]: SitemapStore } = {};

export const useSitemapChartStore = () => {
  const domainId = useDomainId();

  if (!domainId) {
    return null;
  } else if (!domainSitemaps?.[domainId]) {
    domainSitemaps[domainId] = new SitemapStore();
  }
  return domainSitemaps[domainId];
};
