import { useCallback, useEffect, useState } from 'react';
import { ApolloClient, useApolloClient } from '@apollo/client';
import { DataTable, QueryOrder } from 'Components/DataTable';
import { useReportWidgetsQuery } from 'Ghql';
import { ReportElement, ReportWidget } from 'Types/ReportElement';
import { TableIDs } from 'Types/Table';
import { t } from 'Utilities/i18n';
import { useFetchReportTemplates, useReportTemplateColumns } from './helpers/hooks';

export type ReportWidgets = Record<ReportWidget, ReportElement>;

const clearTableData = (client: ApolloClient<object>) => {
  client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'reportTemplates' });
  client.cache.gc();
};

const useParsedWidgets = (reportWidgets: string | undefined) => {
  const parsedReportWidgets: ReportWidgets = JSON.parse(reportWidgets || '[]').reduce(
    (acc, widget) => ({ ...acc, [widget.type]: widget }),
    {},
  );
  return parsedReportWidgets;
};

type ReportTemplatesTableProps = {
  searchTerm: string;
  handleLoading: (isLoading: boolean) => void;
};

const ReportTemplatesTable = ({ searchTerm, handleLoading }: ReportTemplatesTableProps) => {
  const client = useApolloClient();

  const [dataKey, setDataKey] = useState<number>(0);

  const handleUpdateTable = useCallback(() => {
    setDataKey((prevKey) => prevKey + 1);
  }, []);

  useEffect(() => {
    //make sure to show fresh trials data when the tab is reloaded
    clearTableData(client);
  }, [client]);

  const { data: reportWidgetsData } = useReportWidgetsQuery();

  const reportWidgets = useParsedWidgets(reportWidgetsData?.reportWidgets || '[]');

  const fetchData = useFetchReportTemplates({ searchTerm, handleLoading, reportWidgets });
  const columns = useReportTemplateColumns({ handleUpdateTable, reportWidgets });

  return (
    <DataTable
      tableId={TableIDs.REPORT_TEMPLATES}
      dataKey={`${dataKey?.toString()}-${searchTerm}`}
      fetchData={fetchData}
      columns={columns}
      emptyOptions={{
        title: t('No Report Templates'),
        subTitle: t('There are currently no report templates.'),
      }}
      defaultOrdering={{
        order: QueryOrder.DESC,
        orderBy: 'name',
      }}
      offlineFilter={{
        tableName: TableIDs.REPORT_TEMPLATES,
        skipAll: true,
      }}
    />
  );
};

export default ReportTemplatesTable;
