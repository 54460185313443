import { useState } from 'react';
import { Flex } from '@mantine/core';
import { observer } from 'mobx-react';
import AccLazyPopover from 'Components/AccLazyPopover/AccLazyPopover';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { t } from 'Utilities/i18n';
import { KeywordTag } from './KeywordTag';
import styles from './keywordTag.module.scss';

interface KeywordExtraTagsProps {
  keywordId: number;
  tags?: any[];
  onTagClick: any;
  onTagRemoveClick: any;
}

export const KeywordExtraTags = observer((props: KeywordExtraTagsProps & any) => {
  const { tags } = props;

  if (!tags?.length) {
    return null;
  }

  const popoverTags = tags.slice(2);

  const [popoverOpen, setPopoverOpen] = useState(false);

  return (
    <AccLazyPopover
      target={
        <AccTooltip tooltip={t('Show all tags')}>
          <div className={styles.extraTagsContainer}>{`+${popoverTags?.length}`}</div>
        </AccTooltip>
      }
      classNames={{ dropdown: styles.keywordPopover }}
      position="right"
      opened={popoverOpen}
      offset={2}
      onToggle={() => setPopoverOpen(!popoverOpen)}
    >
      <Flex ml={-26} wrap="wrap" gap={3} direction={'row'}>
        {popoverTags.map(({ tag, dynamic }) => (
          <KeywordTag
            key={`${tag}_${dynamic}`}
            keywordId={props.keywordId}
            renderPopover={true}
            tag={tag}
            maxWidth="100px"
            onTagClick={() => {
              props.onTagClick(tag);
              setPopoverOpen(false);
            }}
            onTagRemoveClick={dynamic ? undefined : props.onTagRemoveClick}
            dynamic={dynamic}
            tooltipText={
              dynamic
                ? t('Add as filter or edit tag: ') + tag
                : t('Add as filter or remove tag: ') + tag
            }
            withinPortal={false}
            withinPopover={true}
          />
        ))}
      </Flex>
    </AccLazyPopover>
  );
});
