import { Box } from '@mantine/core';
import AccTag from 'Components/AccTag';
import { useShareOfVoiceTagsQuery } from 'Ghql';
import { useFilters } from 'Hooks';
import { useModal } from 'Hooks/base/useModal';
import { getUpdatedArrayFilter, useSetOrOverwriteFilter } from 'Hooks/data/filter/setFilters';
import { MissingDataOverlay } from 'Pages/Keywords/Overview/MissingDataOverlay';
import { BarChart } from 'Pages/Keywords/Overview/components/BarChart/BarChart';
import { getSoVTagsColor } from 'Pages/Keywords/Overview/components/ShareOfVoiceByType/support/helpers';
import { useGroupViewInfo } from 'Pages/Keywords/Overview/hooks/useGroupViewInfo';
import { FilterComparison, FilterValueType, TAGS } from 'Types/Filter';
import { FilterTrackingKey } from 'Utilities/Analytics/mixpanel';
import { t } from 'Utilities/i18n';

type SovTagsIconProps = {
  isDynamic?: boolean;
  tag?: string;
};

// Color coded, overlapping tag icons
const SovTagsIcon = ({ isDynamic, tag }: SovTagsIconProps) => {
  return <AccTag dynamic={isDynamic} tooltipText={tag} tag={tag} />;
};

const fakeDataRows = Array.from(Array(5)).map((_, index) => ({
  isDynamic: index < 2,
  isStatic: index >= 2,
  key: `Tag ${index + 1}`,
  value: (index + 1) * 10,
}));

export const ShareOfVoiceTags = ({ useAi }) => {
  const { showModal } = useModal();
  const filters = useFilters();
  const { isEmptyGroup, isGroupView } = useGroupViewInfo(filters);
  const setFilter = useSetOrOverwriteFilter();
  const { data, loading } = useShareOfVoiceTagsQuery({
    variables: {
      filters,
      asPercentage: false,
      name: `SoVTagsChart${useAi ? 'AI' : ''}`,
      useAi,
    },
  });
  const numberOfTags = data?.charts?.shareOfVoiceByTags?.rows?.length ?? 0;
  const showAddTagModal = filters.length <= 3 && numberOfTags <= 1; // The backend will always send at least one "tag" which is No tags
  const dataRows = showAddTagModal ? fakeDataRows : data?.charts?.shareOfVoiceByTags?.rows;

  const addDynamicTagsModal = () =>
    showModal({
      modalType: 'SelectTagType',
      modalTheme: 'light',
    });

  return (
    <Box pos="relative">
      {showAddTagModal && !loading && (
        <MissingDataOverlay
          onClick={addDynamicTagsModal}
          type="tags"
          isGroupView={isGroupView}
          isEmptyGroup={isEmptyGroup}
        />
      )}
      <BarChart
        normalNumberOfItems={11}
        loading={loading}
        items={
          dataRows?.map((e, i: number) => {
            const { name, color } = getSoVTagsColor(e, i);
            return {
              value: e?.value ?? 0,
              onClick: () => {
                if (e.otherTags) {
                  return;
                }
                if (e.key === '') {
                  setFilter(
                    {
                      attribute: TAGS,
                      type: FilterValueType.ARRAY,
                      comparison: FilterComparison.EMPTY,
                      value: [],
                    },
                    FilterTrackingKey.ShareOfVoiceChart,
                  );
                } else {
                  setFilter(
                    getUpdatedArrayFilter(filters, name, TAGS),
                    FilterTrackingKey.ShareOfVoiceChart,
                  );
                }
              },
              tooltip: (!e.otherTags && t('Click to see your keywords with tag: ') + name) || '', // Note: tct for some reason does not work here
              background: color,
              icon: <SovTagsIcon isDynamic={e?.isDynamic} tag={name} />,
              id: e?.value,
            };
          }) ?? []
        }
      />
    </Box>
  );
};
