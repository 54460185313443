import { useCallback } from 'react';
import { observer } from 'mobx-react';
import { AccContextToolbar } from 'Components/AccContextToolbar/AccContextToolbar';
import { useSelectedNodes } from 'Hooks/useSelectedNodes';
import { t } from 'Utilities/i18n';
import GroupingBulkActions from './GroupingsBulkActions';

const GroupingsToolbar = observer(() => {
  const { selectedNodes, getSelectedNodes, setSelectedNodes } = useSelectedNodes();

  const deselectAll = useCallback(() => setSelectedNodes([]), [setSelectedNodes]);
  const totalSelected = selectedNodes.length;

  if (!totalSelected) return null;
  return (
    <AccContextToolbar
      enabled={!!totalSelected}
      onDisable={deselectAll}
      items={totalSelected}
      singularLabel={t('item selected')}
      pluralLabel={t('items selected')}
    >
      <GroupingBulkActions
        selectedNodes={selectedNodes}
        getSelectedNodes={getSelectedNodes}
        setSelectedNodes={setSelectedNodes}
        withCount={false}
        withDivider={true}
        darkTheme
      />
    </AccContextToolbar>
  );
});

export default GroupingsToolbar;
