import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import capitalize from 'lodash/capitalize';
import { resetTemplate } from 'Actions/ReportTemplateAction';
import { Error, useAddReportTemplateMutation, useReportTemplateUserOrgQuery } from 'Ghql';
import toast from 'Hooks/useToast';
import { useHistory } from 'Utilities/Router/withRouter';
import { t } from 'Utilities/i18n';
import TemplateBuilder from '../';

/**Get one errorMessage return from a list of errors.
 *
 * Used to return errors from invalid input in formfields.
 */
const getErrorMessage = (errorList: Error[]) => {
  const errorString = errorList
    .map((error) => `${capitalize(error?.field ?? undefined)} : ${error.messages}`)
    .join(' | ');
  return errorString;
};

// Dispatch resetTemplate action to the ReportTemplateReducer in Redux Store
const CreateTemplate = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [saveTemplate, _rest] = useAddReportTemplateMutation();

  const { data: userOrg } = useReportTemplateUserOrgQuery();

  const dispatch = useDispatch();

  const history = useHistory();

  const handleSave = async (templateName: string, color: string, elements: string) => {
    const organizationId = userOrg?.user?.organization?.id || '';

    const input = {
      organization: organizationId,
      brandColor: color,
      name: templateName,
      template: elements,
    };

    const res = await saveTemplate({
      variables: {
        input,
      },
    });

    const errors = res.data?.addReportTemplate?.errors;
    if (errors?.length) {
      const errorMessage = getErrorMessage(errors as Error[]);
      toast.error(t('Unable to save template: %s', errorMessage));
    } else {
      toast.success(t('Template saved'));
      history.push('/reports/templates');
    }
  };

  useEffect(() => {
    dispatch(resetTemplate());
  }, [dispatch]);

  return <TemplateBuilder handleSave={handleSave} />;
};

export default CreateTemplate;
