import { IconKey, IconUserEdit } from '@tabler/icons-react';
import Tabs from 'Components/AccTabs/Tabs';
import Breadcrumbs from 'Components/Breadcrumbs/Breadcrumbs';
import { t } from 'Utilities/i18n';
import ChangePasswordPage from './components/ChangePassword';
import EditProfile from './components/EditProfile';
import styles from './profile.module.scss';

const Profile = () => {
  return (
    <>
      <Breadcrumbs customParentTitle={t('Profile')} />
      <Tabs
        defaultValue="edit"
        className={styles.profileTabs}
        classNames={{
          panel: styles.profileTabsPanel,
        }}
      >
        <Tabs.List>
          <Tabs.Tab value="edit" leftSection={<IconUserEdit />}>
            {t('Edit Profile')}
          </Tabs.Tab>
          <Tabs.Tab value="password" leftSection={<IconKey />}>
            {t('Change Password')}
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="edit" pt="xs">
          <EditProfile />
        </Tabs.Panel>

        <Tabs.Panel value="password" pt="xs">
          <ChangePasswordPage />
        </Tabs.Panel>
      </Tabs>
    </>
  );
};

export default Profile;
