import { useRef } from 'react';
import { useIntl } from 'react-intl';
import { Chart as ChartType, TooltipFormatterContextObject } from 'highcharts';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import { ChartContainer } from 'Components/Chart/ChartContainer/ChartContainer';
import { LoaderTypes } from 'Components/Chart/ChartLoader/ChartLoader';
import { formatChartNumber } from 'Components/Chart/LineChart';
import { getChartPositionerTooltip } from 'Components/Chart/LineChart/support/helpers';
import { getRangeSelectorButtonConfig } from 'Components/Chart/LineChart/support/hooks/useChartConfig';
import { NotesModifier } from 'Components/Chart/Notes';
import { ReactStockedChart } from 'Components/Chart/ReactHighcarts';
import {
  DEFAULT_NAVIGATOR,
  DEFAULT_STOCK_CHART_CONFIG,
  ENABLED_RANGE_SELECTOR,
  TOOLTIP_CHART_FORMATTING,
} from 'Components/Chart/support/constants';
import { formatDisplayNumber } from 'Components/FormatNumber/formatNumberHelper';
import { RankingDistributionFormatedSeries } from 'Pages/Keywords/Overview/components/HistoryCharts/RankingDistribution/helpers';
import { getPointDate } from 'Pages/Keywords/Overview/components/StockTooltipContent/helpers';
import { LINE_CHART_HEIGHT } from 'Pages/Keywords/Overview/constants';
import { t } from 'Utilities/i18n';

type RankingDistributionChartProps = {
  notes: any[];
  series: RankingDistributionFormatedSeries[];
  domainId: string | undefined;
  period: number;
  loaderType?: keyof LoaderTypes;
  onNotesSelect: () => void;
  watermark: boolean;
  watermarkBig: boolean;
  animate: boolean;
};

export const LocalRankingDistributionNeighborlyChart = (props: RankingDistributionChartProps) => {
  const intl = useIntl();
  const chartRef = useRef<ChartType | null>(null);

  const seriesConfig = props.series;

  const formatNumber = (x: number) => formatChartNumber(intl, x);

  const getTooltip = (points: TooltipFormatterContextObject[]) => {
    let html = '';

    points.forEach((point) => {
      const formattedKeywordsNumber = formatDisplayNumber(
        Math.round(((point?.total || 0) * (point?.percentage || 0)) / 100),
      );
      html += `<div class='chart-tooltip-table-tr' style='margin-bottom: 10px'>
      <div>
      <span class='chart-tooltip-bullet' style='color: ${point.color};'>●</span> ${
        point.series.name
      }</div>
      <div class='chart-tooltip-table-td--no-min-width chart-tooltip-table-right' style='text-align: right;'>
        ${intl.formatNumber(point.percentage / 100, {
          style: 'percent',
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        })}
      </div>
      <div class='chart-tooltip-table-td--no-min-width chart-tooltip-table-right' style='text-align: right; font-weight: bold'>${formattedKeywordsNumber}</div>
    </div>`;
    });
    return html;
  };

  const config = {
    chart: {
      height: LINE_CHART_HEIGHT,
      animation: false,
      colorCount: 6,
      zoomType: 'x',
    },
    navigator: {
      ...DEFAULT_NAVIGATOR,
      baseSeries: 1,
      xAxis: {
        min: seriesConfig?.[0]?.data?.[0]?.x || seriesConfig?.[0]?.data?.[0]?.[0],
        labels: { y: 10 },
      },
      dataGrouping: true,
      margin: 50,
      series: {
        type: 'areaspline',
        stacking: 'percent',
        pointPlacement: 'between',
        boostThreshold: 10000,
        turboThreshold: 500000,
        marker: {
          enabled: false,
        },
        fillOpacity: 1,
      },
    },
    boost: {
      useGPUTranslations: true,
      seriesThreshold: 1,
    },
    tooltip: {
      ...TOOLTIP_CHART_FORMATTING,
      padding: 15,
      shared: true,
      split: false,
      positioner(labelWidth, labelHeight, point) {
        return getChartPositionerTooltip(labelWidth, labelHeight, point, this as any);
      },
      formatter(this: any) {
        if (!this.points && !(this.points.length > 0)) {
          return;
        }
        const date = getPointDate(this);
        let totalKeywords = this.points[0].point.totalKeywords;

        if (this.points[0].point.dataGroup) {
          totalKeywords = this.points[0].point.dataGroup.options.totalKeywords;
        }

        return `
            <div style='padding: 10px;'>
              <div class="chart-tooltip-table-tr">
                <div class="chart-tooltip-table-th">${date}</div>
              </div>
             ${getTooltip(this.points)}
              <div class='chart-tooltip-table-tr'>
                <div class='chart-tooltip-table-td--no-min-width' style='padding-top: 6px'>${t(
                  'Total keywords',
                )}</div>
                <div class='chart-tooltip-table-td--no-min-width chart-tooltip-table-right'>&nbsp;</div>
                <div class='chart-tooltip-table-td--no-min-width chart-tooltip-table-right' style='text-align: right; padding-top: 6px; font-weight: bold'>
                  ${formatNumber(totalKeywords)}
                </div>
              </div>
            </div>
          `;
      },
      valueDecimals: 0,
      xDateFormat: '%b %e, %Y',
    },
    plotOptions: {
      areaspline: {
        stacking: 'percent',
        connectNulls: false,
      },
      series: {
        animation: false,
        cursor: 'pointer',
        connectNulls: false,
        lineWidth: 2,
        dataGrouping: true,
        boostThreshold: 100,
        turboThreshold: 500000,
      },
    },
    xAxis: {
      type: 'datetime',
      minPadding: 0,
      maxPadding: 0,
      allowDecimals: false,
      crosshair: false,
      minTickInterval: 24 * 3600 * 1000,
      tickWidth: 0,
      labels: {
        overflow: 'justify',
        y: 20,
      },
    },
    legend: {
      enabled: true,
      layout: 'vertical',
      itemMarginBottom: 6,
      y: 35,
      verticalAlign: 'top',
      align: 'right',
      padding: 0,
      labelFormatter() {
        return (this as any).name;
      },
    },
    yAxis: [
      {
        title: null,
        min: 0,
        max: 100,
        showLastLabel: true,
        tickInterval: 50,
        endOnTick: false,
        top: '0%',
        height: '100%',
        opposite: false,
        labels: {
          align: 'right',
          y: 4,
          style: {
            whiteSpace: 'nowrap',
            textOverflow: 'none',
          },
          formatter() {
            return `${(this as any)?.value}%`;
          },
        },
      },
    ],
    series: seriesConfig,
    ...DEFAULT_STOCK_CHART_CONFIG,
  };

  const finalConfig = merge(cloneDeep(config), {
    rangeSelector: { ...ENABLED_RANGE_SELECTOR, ...getRangeSelectorButtonConfig() },
  });

  return (
    <>
      <NotesModifier
        getChart={() => chartRef.current}
        notes={props.notes}
        config={{ isRankDistribution: true }}
      >
        {({ withNotes, handleNotesClick }) => (
          <ChartContainer onClick={handleNotesClick} exportButton={false} noPadding>
            <ReactStockedChart
              ref={chartRef}
              config={withNotes(finalConfig)}
              style={{ width: '100%' }}
            />
          </ChartContainer>
        )}
      </NotesModifier>
    </>
  );
};
