import { Component } from 'react';
import { FormattedNumber } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import compose from 'lodash/flowRight';
import { formValueSelector } from 'redux-form';
import { showModal } from 'Actions/ModalAction';
import { t, tn } from 'Utilities/i18n';
import underdash from 'Utilities/underdash';
import OrderWidgetSkeleton from './skeleton';
import OrderPlanWrapper from './wrapper';
import './order-plan-widget.scss';

type Props = {
  isSticky?: boolean;
  style?: Record<string, any>;
  vatValid?: boolean;
  selectedCountry?: Record<string, any>;
  planId: string;
  billingCycleId: string;
  coupon?: string;
  pricingPlanCalculatedData: Record<string, any>;
  pricingPlanData: Record<string, any>;
  billingCycleData: Record<string, any>;
  showModal: (...args: Array<any>) => any;
  basepath: string;
  isRegister: boolean;
  onTotal: (...args: Array<any>) => any;
};

class OrderPlanWidget extends Component<Props> {
  static defaultProps = {
    isSticky: false,
    style: {},
    vatValid: false,
    selectedCountry: null,
    coupon: null,
  };

  componentDidUpdate(prevProps: Props) {
    if (
      !this.props.pricingPlanCalculatedData?.loading &&
      prevProps.pricingPlanCalculatedData?.loading &&
      prevProps.onTotal
    ) {
      prevProps.onTotal(
        this.props.pricingPlanCalculatedData.pricingPlanCalculated?.threedSecurePrice,
      );
    }
  }

  renderBillingCycle = (billingCycle: Record<string, any>) => (
    <tr key="billingCycle">
      <td>{t('Billing cycle')}</td>
      <td>
        {billingCycle.months} {tn('month', 'months', billingCycle.months)}
      </td>
    </tr>
  );

  renderPrice = (pricingPlanCalculated: Record<string, any>, billingCycle: Record<string, any>) => (
    <tr key="price">
      <td>
        {billingCycle.long} {t('price')}
      </td>
      <td>
        <FormattedNumber
          style="currency"
          value={pricingPlanCalculated.price}
          currency={pricingPlanCalculated.currency}
          currencyDisplay="code"
        />
      </td>
    </tr>
  );

  renderReceivableRow(pricingPlanCalculated: Record<string, any>) {
    if (pricingPlanCalculated.receivable <= 0) return null;
    return (
      <tr>
        <td>{t('Receivable from current plan')}</td>
        <td>
          <FormattedNumber
            style="currency"
            value={pricingPlanCalculated.receivable * -1}
            currency={pricingPlanCalculated.currency}
            currencyDisplay="code"
          />
        </td>
      </tr>
    );
  }

  renderSignonDiscountRow(pricingPlanCalculated: Record<string, any>) {
    if (pricingPlanCalculated.signonDiscount <= 0) return null;
    return (
      <tr key="signonDiscount">
        <td>{t('Sign-on discount')}</td>
        <td>
          <FormattedNumber
            style="currency"
            value={pricingPlanCalculated.signonDiscount * -1}
            currency={pricingPlanCalculated.currency}
            currencyDisplay="code"
          />
        </td>
      </tr>
    );
  }

  renderSignonDiscountAdditionalRow(pricingPlanCalculated: Record<string, any>) {
    if (
      pricingPlanCalculated.signonDiscount <= 0 ||
      pricingPlanCalculated.signonDiscountMonths <= 1
    ) {
      return null;
    }

    return (
      <tr key="signonDiscountAdditional">
        <td colSpan={2} className="additional-info">
          {t(
            'Discount will be applied monthly for %s months',
            pricingPlanCalculated.signonDiscountMonths,
          )}
        </td>
      </tr>
    );
  }

  renderCouponDiscountRow(pricingPlanCalculated: Record<string, any>) {
    if (pricingPlanCalculated.couponDiscount <= 0) return null;
    return (
      <tr key="couponDiscount">
        <td>{t('Coupon discount')}</td>
        <td>
          <FormattedNumber
            style="currency"
            value={pricingPlanCalculated.couponDiscount * -1}
            currency={pricingPlanCalculated.currency}
            currencyDisplay="code"
          />
        </td>
      </tr>
    );
  }

  renderSubAccountDiscountRow(pricingPlanCalculated: Record<string, any>) {
    if (pricingPlanCalculated.subAccountDiscount <= 0) return null;
    return (
      <tr key="subAccountDiscount">
        <td>{t('Sub-account discount')}</td>
        <td>
          <FormattedNumber
            style="currency"
            value={pricingPlanCalculated.subAccountDiscount * -1}
            currency={pricingPlanCalculated.currency}
            currencyDisplay="code"
          />
        </td>
      </tr>
    );
  }

  renderWalletRow(pricingPlanCalculated: Record<string, any>) {
    if (pricingPlanCalculated.purseCredit === 0) return null;
    return (
      <tr>
        <td>{pricingPlanCalculated.purseCredit > 0 ? t('Wallet credit') : t('Wallet debit')}</td>
        <td>
          <FormattedNumber
            style="currency"
            value={pricingPlanCalculated.purseCredit * -1}
            currency={pricingPlanCalculated.currency}
            currencyDisplay="code"
          />
        </td>
      </tr>
    );
  }

  renderKeywordOveruseWalletRow(pricingPlanCalculated: Record<string, any>) {
    if (pricingPlanCalculated.keywordOveruseCredit === 0) return null;
    return (
      <tr>
        <td>
          {pricingPlanCalculated.keywordOveruseCredit < 0
            ? t('Keyword overuse')
            : t('Keyword overuse refund')}
        </td>
        <td>
          <FormattedNumber
            style="currency"
            value={pricingPlanCalculated.keywordOveruseCredit * -1}
            currency={pricingPlanCalculated.currency}
            currencyDisplay="code"
          />
        </td>
      </tr>
    );
  }

  renderTotalBeforeVAT = (pricingPlanCalculated: Record<string, any>) => (
    <tr key="totalBeforeVAT">
      <td>{t('Total before VAT')}</td>
      <td>
        <FormattedNumber
          style="currency"
          value={pricingPlanCalculated.totalBeforeVat}
          currency={pricingPlanCalculated.currency}
          currencyDisplay="code"
        />
      </td>
    </tr>
  );

  renderVAT = (pricingPlanCalculated: Record<string, any>) => (
    <tr key="VAT">
      <td>
        {t('VAT')} ({pricingPlanCalculated.vatRate}
        {'%'})
      </td>
      <td>
        <FormattedNumber
          style="currency"
          value={pricingPlanCalculated.vat}
          currency={pricingPlanCalculated.currency}
          currencyDisplay="code"
        />
      </td>
    </tr>
  );

  renderTotal = (pricingPlanCalculated: Record<string, any>) => (
    <tr key="total">
      <td>{t('Total')}</td>
      <td className="total">
        <b>
          <FormattedNumber
            style="currency"
            value={pricingPlanCalculated.total}
            currency={pricingPlanCalculated.currency}
            currencyDisplay="code"
          />
        </b>
      </td>
    </tr>
  );

  renderChangePlan = () => (
    <tr key="changePlan">
      <td colSpan={2} style={{ textAlign: 'left' }}>
        <Link to={'/billing/package/select'}>
          <span className="custom-link">{t('Change Plan')}</span>
        </Link>
      </td>
    </tr>
  );

  renderChangeBillingCycle() {
    const { billingCycleId, planId, basepath, coupon } = this.props;
    const [linkLabel, nextBillingCycleId] =
      billingCycleId === '1'
        ? [t('Change to yearly payment'), '2']
        : [t('Change to monthly payment'), '1'];
    return (
      <tr key="changeBillingCycle">
        <td colSpan={2} style={{ textAlign: 'left' }}>
          <Link
            className="custom-link"
            to={`/${basepath}/${nextBillingCycleId}/${planId}/${coupon ? coupon : ''}`}
          >
            {linkLabel}
          </Link>
        </td>
      </tr>
    );
  }

  renderTrialInfo() {
    const {
      pricingPlanCalculatedData: {
        pricingPlanCalculated: { nextPlanAfterTrial },
      },
      pricingPlanData: {
        pricingPlan: {
          nextPlanAfterTrial: { name: nextPlanName },
        },
      },
      billingCycleData: { billingCycle },
    } = this.props;
    return [
      <tr key="afterTrialHeader">
        <td
          colSpan={2}
          style={{
            textAlign: 'left',
          }}
        >
          <span>
            {t('After the trial, you will continue on')}
            <br />
            {nextPlanName}
            {', '}
            <FormattedNumber
              value={nextPlanAfterTrial.price}
              style="currency"
              currencyDisplay="code"
              currency={nextPlanAfterTrial.currency}
            />
            {'/'}
            <span className="cycle-abbreviation">{billingCycle.short}</span>
          </span>
        </td>
      </tr>, //TODO Real text
      this.renderBillingCycle(billingCycle),
      this.renderPrice(nextPlanAfterTrial, billingCycle),
      this.renderSignonDiscountRow(nextPlanAfterTrial),
      this.renderCouponDiscountRow(nextPlanAfterTrial),
      this.renderSubAccountDiscountRow(nextPlanAfterTrial),
      this.renderTotalBeforeVAT(nextPlanAfterTrial),
      this.renderVAT(nextPlanAfterTrial),
      this.renderTotal(nextPlanAfterTrial),
      this.renderSignonDiscountAdditionalRow(nextPlanAfterTrial),
    ];
  }

  renderStandard() {
    const {
      pricingPlanCalculatedData: { pricingPlanCalculated },
      pricingPlanData: { pricingPlan },
      billingCycleData: { billingCycle },
    } = this.props;
    return (
      <OrderPlanWrapper isSticky={!!this.props.isSticky} style={this.props.style}>
        <h3>{t('Selected plan')}</h3>
        <span className="order-info">
          {pricingPlan.name}
          {', '}
          <FormattedNumber
            value={pricingPlanCalculated.price}
            style="currency"
            currencyDisplay="code"
            currency={pricingPlanCalculated.currency}
          />
          {'/'}
          <span className="cycle-abbreviation">{billingCycle.short}</span>
        </span>
        <table className="order-plan-table">
          <tbody>
            {this.renderBillingCycle(billingCycle)}
            {this.renderPrice(pricingPlanCalculated, billingCycle)}
            {this.renderReceivableRow(pricingPlanCalculated)}
            {this.renderSignonDiscountRow(pricingPlanCalculated)}
            {this.renderCouponDiscountRow(pricingPlanCalculated)}
            {this.renderSubAccountDiscountRow(pricingPlanCalculated)}
            {this.renderWalletRow(pricingPlanCalculated)}
            {this.renderKeywordOveruseWalletRow(pricingPlanCalculated)}
            {this.renderTotalBeforeVAT(pricingPlanCalculated)}
            {this.renderVAT(pricingPlanCalculated)}
            {this.renderTotal(pricingPlanCalculated)}
            {this.renderSignonDiscountAdditionalRow(pricingPlanCalculated)}
            {/* {this.renderChangeBillingCycle()} */}
            {this.renderChangePlan()}
          </tbody>
        </table>
      </OrderPlanWrapper>
    );
  }

  renderTrial() {
    const {
      pricingPlanCalculatedData: { pricingPlanCalculated },
      pricingPlanData: { pricingPlan },
    } = this.props;
    return (
      <OrderPlanWrapper isSticky={!!this.props.isSticky} style={this.props.style}>
        <>
          <h3>{t('Selected plan')}</h3>
          <span className="order-info">{pricingPlan.name}</span>
          <table className="order-plan-table">
            <tbody>
              {this.renderTotal(pricingPlanCalculated)}
              {this.renderTrialInfo()}
              {this.renderChangeBillingCycle()}
              {this.renderChangePlan()}
            </tbody>
          </table>
        </>
      </OrderPlanWrapper>
    );
  }

  render() {
    const gqlError = underdash.graphqlError({ ...this.props });
    const gqlLoading = underdash.graphqlLoading({ ...this.props });

    if (gqlError || gqlLoading) {
      return (
        <OrderPlanWrapper isSticky={!!this.props.isSticky} style={this.props.style}>
          <OrderWidgetSkeleton isRegister={this.props.isRegister} />
        </OrderPlanWrapper>
      );
    }

    const {
      pricingPlanCalculatedData: {
        pricingPlanCalculated: { isTrial },
      },
    } = this.props;
    const {
      pricingPlanData: {
        pricingPlan: { nextPlanAfterTrial },
      },
    } = this.props;

    // TODO should we show trials if there is no next plan????
    if (isTrial && nextPlanAfterTrial) {
      return this.renderTrial();
    }

    return this.renderStandard();
  }
}

const selector = formValueSelector('CompanyInfoForm');

const mapStateToProps = (state) => ({
  vatValid: state.orderPlan.vatValid,
  selectedCountry: selector(state, 'country'),
});

const pricingDetailsQuery = gql`
  query orderPlanWidget_getPricingDetails(
    $id: ID!
    $billingCycle: Int!
    $validVat: Boolean
    $countryId: String
    $coupon: String
  ) {
    pricingPlanCalculated(
      id: $id
      billingCycle: $billingCycle
      validVat: $validVat
      countryId: $countryId
      coupon: $coupon
    ) {
      id
      price
      threedSecurePrice
      receivable
      upgrade
      downgrade
      signonDiscount
      signonDiscountMonths
      subAccountDiscount
      couponDiscount
      purseCredit
      keywordOveruseCredit
      totalBeforeVat
      vat
      vatRate
      total
      startDate
      endDate
      isTrial
      currency
      nextPlanAfterTrial {
        id
        price
        receivable
        upgrade
        downgrade
        signonDiscount
        signonDiscountMonths
        couponDiscount
        purseCredit
        keywordOveruseCredit
        totalBeforeVat
        vat
        vatRate
        total
        startDate
        endDate
        isTrial
        currency
      }
    }
  }
`;
const pricingPlanQuery = gql`
  query orderPlanWidget_pricingPlan($id: ID!) {
    pricingPlan(id: $id) {
      id
      name
      currency
      nextPlanAfterTrial {
        name
      }
    }
  }
`;
const billingCycleQuery = gql`
  query orderPlanWidget_billingCycle($id: ID!) {
    billingCycle(id: $id) {
      id
      months
      short
      long
    }
  }
`;
export default compose(
  connect(mapStateToProps, {
    showModal,
  }),
  graphql(pricingDetailsQuery, {
    name: 'pricingPlanCalculatedData',
    options: (props: any) => {
      const { planId, billingCycleId, selectedCountry, vatValid, coupon } = props;
      let variables = {
        id: planId,
        billingCycle: parseInt(billingCycleId),
        validVat: vatValid,
        countryId: selectedCountry ? selectedCountry.countryCode : null,
      };
      // TODO FixTSignore
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (coupon) variables = { ...variables, coupon };
      return {
        fetchPolicy: 'network-only',
        variables,
      };
    },
  }),
  graphql(pricingPlanQuery, {
    name: 'pricingPlanData',
    options: ({ planId }: any) => ({
      variables: {
        id: planId,
      },
      fetchPolicy: 'network-only',
    }),
  }),
  graphql(billingCycleQuery, {
    name: 'billingCycleData',
    options: ({ billingCycleId }: any) => ({
      variables: {
        id: parseInt(billingCycleId),
      },
      fetchPolicy: 'network-only',
    }),
  }),
)(OrderPlanWidget);
