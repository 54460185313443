/* eslint-disable no-case-declarations */
import moment from 'moment';
import Ellipsis from 'Components/Ellipsis';
import { t } from 'Utilities/i18n';
import type { Report } from './../ReportActionCell';

type ReportScheduleCellProps = { report: Report };

const ReportScheduleCell = (props: ReportScheduleCellProps) => {
  const renderSchedule = (schedule: string, scheduledDay?: number) => {
    switch (schedule) {
      case 'A_1':
        return t('Runs daily');

      case 'A_2':
        const days = [
          t('monday'),
          t('tuesday'),
          t('wednesday'),
          t('thursday'),
          t('friday'),
          t('saturday'),
          t('sunday'),
        ];
        return t(
          'Runs every %s',
          typeof scheduledDay === 'number' ? days[scheduledDay] : 'unknown',
        );

      case 'A_3':
        return t('Runs on the %s. day of every month', scheduledDay);

      default:
        return 'unknown';
    }
  };

  const renderDateField = (report: Report) => {
    if ('createdAt' in report) {
      return moment(report.createdAt).format('LLL');
    }

    return renderSchedule(report.schedule, report.scheduledDay ?? undefined);
  };

  return <Ellipsis>{renderDateField(props.report)}</Ellipsis>;
};

export default ReportScheduleCell;
