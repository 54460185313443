import { Box } from '@mantine/core';
import { Field, Fields } from 'redux-form';
import FilterCount from 'Components/Filters/Common/FilterCount';
import FilterCountField from 'Components/Filters/FilterCountField';
import { Select } from 'Components/Forms/Fields';
import AccText from 'Components/Text/AccText';
import { t } from 'Utilities/i18n/index';
import Validator from 'Utilities/validation';
import { useLocationData } from './hooks';

type Props = {
  placeholder?: string;
};

const LocationEditor = (props: Props) => {
  const { placeholder } = props;
  const { data, loading } = useLocationData();

  return (
    <Box pos="relative">
      <Fields
        names={['comparison', 'value', 'type', 'attribute']}
        component={FilterCountField}
        pos="absolute"
        right="28px"
        top="11px"
      />
      <Field
        autoFocus
        name="value"
        component={Select}
        placeholder={placeholder || t('Select location')}
        validate={[Validator.required]}
        itemComponent={({ label, count }) => (
          <Box pos="relative">
            <AccText size="sm" fw={'normal'} overflowEllipsis nowrap>
              {label}
            </AccText>
            <FilterCount count={count} />
          </Box>
        )}
        creatable
        isLoading={loading}
        options={data}
      />
    </Box>
  );
};

export default LocationEditor;
