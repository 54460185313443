import { useMemo } from 'react';
import { useViewportSize } from '@mantine/hooks';
import * as Highcharts from 'highcharts';
import { SeriesOptionsType } from 'highcharts';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import { COLUMN_CHART_HEIGHT } from 'Components/Chart/ColumnChart/support/constants';
import { ColumnChartItem } from 'Components/Chart/ColumnChart/support/types';
import { getOrderOfMagnitude, getYMax } from 'Components/Chart/ColumnChart/support/utils';
import { ChartButtonTheme } from 'Components/Chart/support/buttonStyleConfig';
import { DEFAULT_CHART_CONFIG, TOOLTIP_CHART_FORMATTING } from 'Components/Chart/support/constants';
import { labelsFormatter } from 'Components/Chart/support/helpers';
import { updateLoader } from 'Components/HighchartsLoader';

export interface UseColumnChartConfig {
  loading?: boolean;
  showLegend?: boolean;
  items: ColumnChartItem[];
  noDataText?: string;
  tooltipFormatter?: (item: Highcharts.TooltipFormatterContextObject) => string;
  colWidth?: number;
  height?: number;
  stack?: boolean;
}

export const toMonth = (date: number) => {
  return moment.unix(date / 1000).month();
};

const useSeries = (items: ColumnChartItem[], yMax: number, stack: boolean) => {
  return useMemo(() => {
    const result: SeriesOptionsType[] = [];

    items.map((chartItem) => {
      const data = sortBy(chartItem.data, 'date')?.map((el) => ({ x: el.date, y: el.value }));
      if (chartItem.type === 'line') {
        result.push({
          name: chartItem.title,
          type: 'spline',
          data: sortBy(chartItem.data, 'date')?.map((el) => ({ x: el.date, y: el.value })),
          color: chartItem.color,
          lineWidth: 3,
          marker: {
            enabled: false,
          },
          // the opposite value may be missing on some data-series but we need it here
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          opposite: false,
        });
      } else if (chartItem.type === 'column') {
        if (!stack) {
          result.push({
            name: '-',
            marker: {
              symbol: 'circle',
              radius: 3,
            },
            color: '#f3f4f7',
            stack: chartItem.title,
            borderColor: 'transparent',
            type: 'column' as any,
            linkedTo: chartItem.title,
            data:
              data.map((e) => ({
                ...e,
                y: (yMax || 0) - (e.y || 0),
              })) ?? [],
            showInLegend: false,
            enableMouseTracking: false, // Do not show tooltips for background bars
          });
        }
        result.push({
          name: chartItem.title,
          stack: stack ? '0' : chartItem.title,
          id: chartItem.title,
          color: chartItem.color,
          borderColor: 'transparent',
          type: 'column',
          data,
          point: {
            events: {
              mouseOver() {
                const points = this.series.points;
                for (let i = 0; i < points.length; i++) {
                  if (toMonth(this.category as number) === toMonth(points[i].category as number)) {
                    points[i].setState('hover');
                  }
                }
              },
              mouseOut() {
                const points = this.series.points;
                for (let i = 0; i < points.length; i++) {
                  points[i]?.setState?.('normal');
                }
              },
            },
          },
        });
      }
    });

    return result;
  }, [items]);
};

const getColSize = (width: number, maxWidth: number | undefined) => {
  if (width >= 1500) {
    return maxWidth || 11;
  }
  if (width >= 1350) {
    return 9;
  }
  if (width >= 1200) {
    return 8;
  }
  return 7;
};

export const useColumnChartConfig = (props: UseColumnChartConfig): Highcharts.Options => {
  const { width } = useViewportSize();
  const defaultColWidth = getColSize(width, props.colWidth);
  const hasNotes = false;
  const chart = useMemo(
    () => ({
      height: props.height || COLUMN_CHART_HEIGHT,
      marginTop: 5,
      marginRight: 5,
      marginBottom: props.showLegend ? 80 : 45,
      marginLeft: 45,
      colorCount: 6,
      zoomType: 'x',
      zooming: {
        resetButton: {
          position: {
            // align: 'right', // by default
            // verticalAlign: 'top', // by default
            x: 0,
            y: -5,
          },
          //style the button similar to AccButton secondary
          theme: ChartButtonTheme,
        },
      },
      events: {
        load() {
          updateLoader(this);
        },
      },
    }),
    [updateLoader],
  );
  const yMax = Math.max(...props.items.map((item) => getYMax(item))) || undefined;
  const series = useSeries(props.items, yMax || 0, !!props.stack);

  const plotOptions = useMemo(
    () =>
      ({
        line: {
          pointPlacement: 'between',
        },
        area: {
          stacking: 'percent',
        },
        column: {
          stacking: props.stack ? 'normal' : 'stream',
          pointWidth: props.colWidth || defaultColWidth,
          borderRadius: 2,
          groupPadding: 0.1,
          grouping: !props.stack,
          states: { inactive: { opacity: 0 }, hover: { brightness: 0.2 } },
          minPointLength: 2,
        },
        series: {
          animation: true,
        },
      } as const),
    [props.colWidth, defaultColWidth],
  );

  const yAxis = useMemo(
    () => ({
      title: {
        text: null,
      },
      min: 0,
      max: props.stack ? undefined : yMax,
      top: hasNotes ? '2%' : '0%',
      alignTicks: false, // alignTicks and tickInterval used to force highcharts to respect y max value. See https://www.highcharts.com/forum/viewtopic.php?t=41059
      tickInterval: props.items
        ? Math.pow(10, getOrderOfMagnitude(Math.max(...props.items.map((item) => getYMax(item)))))
        : undefined,
      labels: {
        style: {
          whiteSpace: 'nowrap',
          textOverflow: 'none',
        },
        formatter() {
          return labelsFormatter(this, true);
        },
      },
    }),
    [props.items],
  );
  const xAxis = useMemo(
    () =>
      ({
        type: 'datetime',
        dateTimeLabelFormats: {
          day: '%e-%m',
          week: '%e %m',
          month: '%b \'%y',
          year: '%Y',
        },
        labels: {
          align: 'center',
          step: 1,
        },
        ordinal: true,
      } as const),
    [],
  );

  return {
    chart,
    plotOptions,
    series,
    xAxis,
    yAxis,
    legend: props.showLegend
      ? {
          enabled: true,
          itemMarginTop: 0,
          align: 'left',
        }
      : { enabled: false },
    tooltip: {
      ...TOOLTIP_CHART_FORMATTING,
      shared: true,
      padding: 10,
      style: {
        lineHeight: '20px',
      },
      formatter: props.tooltipFormatter
        ? function () {
            return props.tooltipFormatter!(this);
          }
        : undefined,
    },
    ...DEFAULT_CHART_CONFIG,
  };
};
