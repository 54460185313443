import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useIntercom } from 'react-use-intercom';
import { Box, Divider, Flex, Loader, Alert as MantineAlert, Progress } from '@mantine/core';
import { MIME_TYPES, MS_EXCEL_MIME_TYPE } from '@mantine/dropzone';
import * as Sentry from '@sentry/react';
import debounce from 'lodash/debounce';
import AccButton from 'Components/AccButton/AccButton';
import Alert from 'Components/Alert';
import Breadcrumbs from 'Components/Breadcrumbs/Breadcrumbs';
import Dropzone from 'Components/Dropzone';
import AccText from 'Components/Text/AccText';
import AccTitle from 'Components/Title/AccTitle';
import { useImportPageUploadedImportsQuery } from 'Ghql';
import { useHistory } from 'Utilities/Router/withRouter';
import { t, tct } from 'Utilities/i18n';
import type { SubscriptionHandle } from 'Utilities/websocket';
import { subscribeToImport } from 'Utilities/websocket';
import reusableStyles from 'css/reusable-styles.module.scss';
import ConnectingIcon from 'icons/connecting.svg?inline';
import ImportTable from './importTable';
import { ImportWebsocketProducer } from './websocket';
import styles from './import.module.scss';
import './import.scss';

const validFileTypes = [MIME_TYPES.csv, ...MS_EXCEL_MIME_TYPE];

const GiB_1 = 1024 * 1024 * 1024;

const ImportPage = () => {
  const [error, setError] = useState<string | null>(null);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadFilename, setUploadFilename] = useState<string | null>(null);
  const [uploadProcessing, setUploadProcessing] = useState(false);
  const { push } = useHistory();
  const { showNewMessage } = useIntercom();

  const _subHandler = useRef<SubscriptionHandle>();

  const importWebsocketProducerRef = useRef<ImportWebsocketProducer>();

  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      if (importWebsocketProducerRef.current) {
        importWebsocketProducerRef.current.forceClose();
      }
    };
  }, []);

  const {
    data: uploadedImportsData,
    loading,
    refetch: refetchUploadedImports,
  } = useImportPageUploadedImportsQuery({
    variables: {},
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    _subHandler.current = subscribeToImport(debounce(() => refetchUploadedImports(), 1000));
    return () => _subHandler.current?.unsubscribe();
  }, [uploadedImportsData]);

  const onDrop = (files: File[]) => {
    const file = files?.[0];

    if (!file) {
      return;
    }

    setUploadFilename(file.name);

    setUploadingFile(true);

    const importWebsocketProducer = new ImportWebsocketProducer();
    importWebsocketProducerRef.current = importWebsocketProducer;
    importWebsocketProducer
      .sendFile(file, setUploadProgress, setUploadProcessing)
      .then((fulfillResult) => {
        setUploadingFile(false);
        push?.(`/import/validate/${fulfillResult.id}`);
      })
      .catch((rejectResult) => {
        Sentry.captureException(rejectResult);
        setError(t('Oops! Something went wrong'));
      });
  };

  const ImportTablePanel = () => (
    <div>
      <AccTitle type="h3">{t('Imports')}</AccTitle>
      <ImportTable
        uploadedImports={uploadedImportsData?.uploadedImports ?? []}
        onDelete={refetchUploadedImports}
        loading={loading}
      />
    </div>
  );

  const handleThirdParty = (thirdParty: { name: string }) => {
    showNewMessage(
      `Hello, I would like to import full history data from ${thirdParty.name} into AccuRanker.`,
    );
  };

  return (
    <>
      <Breadcrumbs />
      <div className="import-page">
        <Flex gap="lg" className={reusableStyles.paperContainer} mb="lg">
          <div className={'upload-file'}>
            <AccTitle type="h3">{t('From file')}</AccTitle>

            <Box mt="md">
              <Alert type="warning">
                <p className={'title'}>{t('How to import from a CSV/Excel file')}</p>
                <ol>
                  <li>
                    {tct('Make sure your CSV/Excel file is in the format specified [link].', {
                      link: <a onClick={() => navigate('/import/examples')}>{t('here')}</a>,
                    })}
                  </li>
                  <li>{t('Upload the file below.')}</li>
                  <li>{t('Follow the steps to setup the file and import.')}</li>
                  {tct(
                    '[br]NB. importing keywords might lead to exceeding your plan limit. Read more about keyword exceedings [here].',
                    {
                      br: <br />,
                      here: (
                        <a
                          href="https://www.accuranker.com/help/account/dynamic-keyword-usage"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {' '}
                          here{' '}
                        </a>
                      ),
                    },
                  )}
                </ol>
              </Alert>
            </Box>
            {uploadingFile ? (
              <div className={styles.uploadWrapper}>
                <span className={styles.uploadProgress}>
                  {uploadProcessing ? (
                    <span>
                      {t('Preparing')}
                      <Loader
                        className={styles.uploadProcessingLoader}
                        variant="dots"
                        size="xs"
                        color="black"
                      />
                    </span>
                  ) : (
                    `${uploadProgress}%`
                  )}
                </span>
                <span className={styles.uploadFilename}>{uploadFilename || 'filename.csv'}</span>
                <Progress value={uploadProgress} size="xl" />
              </div>
            ) : (
              <Dropzone
                onDropFile={onDrop}
                acceptFiles={validFileTypes}
                invalidFileMessage={t(
                  'Invalid file. Please provide a CSV/Excel file as described above.',
                )}
                activateOnClick={true}
                multiple={false}
                fileSize={GiB_1}
                icon="file"
              />
            )}

            {error && (
              <MantineAlert color="red" mt="xs">
                {error}
              </MantineAlert>
            )}
          </div>

          <Divider orientation="vertical" color="gray.1" />

          <div className={'third-parties'}>
            <AccTitle
              type="h3"
              helper={
                <AccText>
                  {t(
                    'To get information on how to import from a third-party, please click the button below and send your message to our support team. They will then provide the information you need to get started.',
                  )}
                </AccText>
              }
            >
              {t('From third-parties')}
            </AccTitle>

            <Box mt="md">
              <Alert type="warning">
                <Flex align="center">
                  <Box mr="md" className="connecting-icon">
                    <ConnectingIcon />
                  </Box>
                  <div>
                    <p className={'title'}>{t('Import from a third party?')}</p>
                    <p>
                      {t('Need to import your data from a third party?')}
                      <br />
                      {t('Dont worry, we\'ve got you covered.')}
                      <br />
                      <br />
                      {t(
                        'Send your message to our support team. They will then provide the information you need to get started.',
                      )}
                    </p>
                  </div>
                </Flex>
              </Alert>

              <AccButton
                onClick={() =>
                  handleThirdParty({
                    name: 'a third party',
                  })
                }
                variant="secondary"
              >
                {t('Connect to a third party')}
              </AccButton>
            </Box>
          </div>
        </Flex>

        <div className={reusableStyles.paperContainer}>
          <ImportTablePanel />
        </div>
      </div>
    </>
  );
};

export default ImportPage;
