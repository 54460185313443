import React, { memo } from 'react';
import { Flex } from '@mantine/core';
import cn from 'classnames';
import PercentageOfMaxCircular from 'Components/PercentageOfMaxCircular/percentageOfMaxCircular';
import ValueIndicator from 'Components/ValueIndicator/value-indicator';
import { TreeViewRootNode } from 'Pages/Domains/TreeView/useTreeViewTableConfig';
import { useFoldersGraph } from 'Pages/Keywords/Groupings/HistoryGraph/foldersGraph';
import { FolderType } from 'Pages/Keywords/Groupings/groupingTypes';
import { propsIsEqualComparison } from 'Utilities/compare';
import { t } from 'Utilities/i18n';

type Props = {
  rootNode?: FolderType | TreeViewRootNode;
  defaultTab: string;
  className?: string;
  disabled?: boolean;
  children: React.ReactNode;

  beforeValue: number | null;
  value: number | null;
  /** Show ringprogress when value is zero */
  showProgessOnEmpty?: boolean;
  negate?: boolean;
  indicatorStyle?: 'decimal' | 'currency' | 'percent';
  maxDecimals?: number;
  maxValue?: number;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};
const ValueAndIndicatorCell = (props: Props) => {
  const {
    rootNode,
    className,
    defaultTab,
    indicatorStyle,
    disabled,
    value,
    beforeValue,
    negate,
    children,
    maxDecimals,
    maxValue,
    onClick,
    showProgessOnEmpty,
  } = props;
  const showGraph = useFoldersGraph(
    () => (rootNode ? [rootNode as FolderType] : undefined),
    defaultTab,
    rootNode?.isDomainsComparison ? 'DomainsComparison' : 'FoldersGraph',
  );

  return (
    <Flex columnGap="xs" align="center" justify="end">
      <Flex>
        <ValueIndicator
          beforeValue={beforeValue}
          afterValue={value}
          negate={negate || !beforeValue}
          style={indicatorStyle}
          maxDecimals={maxDecimals}
          noCompareString={t('N/A')}
        />
      </Flex>
      <button
        className={cn(className, 'table-link-like')}
        key={`${rootNode?.path}-modal-link-${defaultTab}`}
        disabled={disabled}
        onClick={
          !disabled
            ? (event) => {
                event.stopPropagation();
                onClick?.(event);
                showGraph();
              }
            : undefined
        }
      >
        {children}
      </button>
      {((maxValue !== undefined && maxValue !== null && maxValue !== 0) || showProgessOnEmpty) && (
        <PercentageOfMaxCircular
          maxValue={maxValue || 0}
          value={value ?? undefined}
          isPercentages={indicatorStyle === 'percent'}
        />
      )}
    </Flex>
  );
};

export default memo(ValueAndIndicatorCell, propsIsEqualComparison);
