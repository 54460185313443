import {
  SEARCH_TYPE_BAIDU,
  SEARCH_TYPE_BING,
  SEARCH_TYPE_GOOGLE,
  SEARCH_TYPE_NAVER,
  SEARCH_TYPE_YOUTUBE,
  SearchEngineTypes,
} from '../../Components/Modal/Content/AddSearchEngine/types';

export type LocaleDataSearchEngine = {
  id: string;
  name: string;
  searchTypes: number[];
};

type LocaleData = {
  id: string;
  region: string;
  countryCode: string;
  localeShort: string;
  locale: string;
  searchEngines: LocaleDataSearchEngine[];
};

export const transformLocalesData = <T extends object = any>(
  data: T[],
  featureBaidu: boolean,
  featureYoutube: boolean,
  youTubeDomain: boolean,
): LocaleData[] => {
  return data?.map((localeData) => {
    const searchEngines: LocaleDataSearchEngine[] = [];

    if ('googleSupport' in localeData && localeData?.googleSupport) {
      searchEngines.push({
        id: SearchEngineTypes[SEARCH_TYPE_GOOGLE],
        name: SEARCH_TYPE_GOOGLE,
        searchTypes: [],
      });
    }

    if (
      featureYoutube &&
      'youtubeSupport' in localeData &&
      localeData.youtubeSupport &&
      youTubeDomain
    ) {
      searchEngines.push({
        id: SearchEngineTypes[SEARCH_TYPE_YOUTUBE],
        name: SEARCH_TYPE_YOUTUBE,
        searchTypes: [],
      });
    }

    if ('bingSupport' in localeData && localeData.bingSupport) {
      searchEngines.push({
        id: SearchEngineTypes[SEARCH_TYPE_BING],
        name: SEARCH_TYPE_BING,
        searchTypes: [],
      });
    }

    if (featureBaidu && 'baiduSupport' in localeData && localeData.baiduSupport) {
      searchEngines.push({
        id: SearchEngineTypes[SEARCH_TYPE_BAIDU],
        name: SEARCH_TYPE_BAIDU,
        searchTypes: [],
      });
    }

    if ('naverSupport' in localeData && localeData.naverSupport) {
      searchEngines.push({
        id: SearchEngineTypes[SEARCH_TYPE_NAVER],
        name: SEARCH_TYPE_NAVER,
        searchTypes: [],
      });
    }

    return {
      id: (localeData as LocaleData)?.id || '',
      region: (localeData as LocaleData)?.region || '',
      countryCode: (localeData as LocaleData)?.countryCode || '',
      localeShort: (localeData as LocaleData)?.localeShort || '',
      locale: (localeData as LocaleData)?.locale || '',
      searchEngines,
    };
  });
};
