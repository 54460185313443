const TYPE = 'type';
const NAME = 'name';
const REPORT_FOR = 'report_for';
const TEMPLATE = 'template';
const SCHEDULE = 'schedule';
const ACTIONS = 'actions';

export const ColumnIDs = {
  TYPE,
  NAME,
  REPORT_FOR,
  TEMPLATE,
  SCHEDULE,
  ACTIONS,
} as const;
