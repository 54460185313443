import Skeleton from 'Components/Skeleton';

const PaymentContactSkeleton = () => {
  return (
    <Skeleton
      linesConfig={
        [
          {
            type: 'chart',
            options: {
              flex: '1',
              height: '250px',
            },
          },
        ] as any
      }
    />
  );
};

export default PaymentContactSkeleton;
