import { oneOfOptions } from 'Components/Filters/Common/labelFunc';
import { DESKTOP, FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import type { SearchTypeFilter } from 'Types/Filter';
import { t } from 'Utilities/i18n/index';
import Icon from 'icons/devices.svg?inline';
import SearchTypeEditor from '.';
import { getItems } from './hooks';

const defaultValue: SearchTypeFilter = {
  attribute: FilterAttribute.SEARCH_TYPE,
  type: FilterValueType.NUMBER,
  comparison: FilterComparison.EQ,
  value: DESKTOP,
};

const getData = () => {
  const items = getItems();
  return {
    defaultValue,
    title: t('Device type'),
    icon: Icon,
    editor: SearchTypeEditor,
    editorProps: {
      items,
      iconDropdown: true,
    },
    tableEditor: SearchTypeEditor,
    tableEditorProps: {
      items,
      iconDropdown: true,
      showLabel: false,
      noFilterIcon: Icon,
    },
    labelFunc: oneOfOptions(items),
  };
};

export default getData;
