import { useMantineTheme } from '@mantine/core';
import cn from 'classnames';
import { AccFastIcon } from 'Components/AccFastIcon';
import ClockIcon from 'icons/clock.svg';

type Props = {
  className?: string;
};

/**
 * Table cell content for when data is being refreshed.
 * I.e when we are refreshing either rank or search volume.
 * Renders as a "clock" icon.
 */
const LoadingClock: React.FC<React.PropsWithChildren<Props>> = ({ className }: Props) => {
  const theme = useMantineTheme();
  return (
    <div
      data-testid="cell-loader-clock"
      className={cn('icon-new', 'icon-new--color-inactive', className)}
    >
      <AccFastIcon size={24} src={ClockIcon} color={theme.other.iconInactive} />
    </div>
  );
};

export default LoadingClock;
