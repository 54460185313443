import { memo } from 'react';
import AccText from 'Components/Text/AccText';
import { useCountdown } from 'Hooks/useCountdown';
import { t } from 'Utilities/i18n';

type Props = {
  endDate: number;
};
const CountDownToDate = (props: Props) => {
  const { endDate } = props;
  const [days, hours, minutes, seconds] = useCountdown(endDate);

  return (
    <AccText inline fw={400} size={'12px'}>
      {t('%s days, %s hours, %s minutes, %s seconds', days, hours, minutes, seconds)}
    </AccText>
  );
};

export default memo(CountDownToDate);
