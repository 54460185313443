import { useCallback, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import moment from 'moment/moment';
import { DataTable, TableSize } from 'Components/DataTable';
import { formatDisplayNumber } from 'Components/FormatNumber/formatNumberHelper';
import { EARLIEST, LATEST } from 'Components/PeriodFilter/model';
import {
  LocalRankingDistributionNeighborlyDocument,
  LocalRankingDistributionNeighborlyQuery,
} from 'Ghql';
import { useFiltersWithHash, useSpecificFilter } from 'Hooks';
import { useGroupViewInfo } from 'Pages/Keywords/Overview/hooks/useGroupViewInfo';
import { FilterAttribute } from 'Types/Filter';
import { TableIDs } from 'Types/Table';
import { t } from 'Utilities/i18n/index';
import DatePickerCellRenderer from '../support/DatePickerCellRenderer';
import DiffCellRenderer from '../support/DiffCellRenderer';

export const percentageDifference = (value, compareValue): string => {
  if (compareValue === 0) {
    if (value === 0) {
      return '0';
    }
    return '∞';
  }
  return (((value - compareValue) / compareValue) * 100).toFixed(1);
};

const getRankingDistributionNeighborlyColumns = () => {
  return [
    {
      id: 'date',
      title: t('Date'),
      width: 150,
      cellRenderer: ({ record }) => (
        <DatePickerCellRenderer
          onDateChange={record.onDateChange}
          date={record.firstColumn}
          minDate={record.minDate}
          maxDate={record.maxDate}
        />
      ),
    },
    {
      id: 'rank1',
      title: t('Rank 1'),
      width: 90,
      cellRenderer: ({ record }) => <DiffCellRenderer content={record.rank1} />,
      onHeaderCell: () => ({ reverseDirection: true }),
    },
    {
      id: 'rank2',
      title: t('Rank 2'),
      width: 90,
      cellRenderer: ({ record }) => <DiffCellRenderer content={record.rank2} />,
      onHeaderCell: () => ({ reverseDirection: true }),
    },
    {
      id: 'rank3',
      title: t('Rank 3'),
      width: 90,
      cellRenderer: ({ record }) => <DiffCellRenderer content={record.rank3} />,
      onHeaderCell: () => ({ reverseDirection: true }),
    },
    {
      id: 'rankGt3',
      title: t('Greater than 3'),
      width: 90,
      cellRenderer: ({ record }) => <DiffCellRenderer content={record.rankGt3} />,
      onHeaderCell: () => ({ reverseDirection: true }),
    },
    {
      id: 'nonRanking',
      title: t('Not ranking'),
      width: 90,
      cellRenderer: ({ record }) => <DiffCellRenderer content={record.nonRanking} />,
      onHeaderCell: () => ({ reverseDirection: true }),
    },
  ];
};

export const LocalRankingDistributionNeighborlyTable = () => {
  const columns = getRankingDistributionNeighborlyColumns();

  const [filters, filtersHash] = useFiltersWithHash();
  const client = useApolloClient();
  const { isEmptyGroup } = useGroupViewInfo(filters);
  const periodFilter = useSpecificFilter(FilterAttribute.PERIOD);
  const [customCompareDate, setCustomCompareDate] = useState<string | null>(null);
  const [customLatestDate, setCustomLatestDate] = useState<string | null>(null);

  const periodFilterValue = JSON.parse((periodFilter?.value as string) ?? '[]');
  const isLatest = periodFilterValue[1] === LATEST;
  const isInitial = periodFilterValue[0] === EARLIEST;

  // WARNING: For some reason, pulling this logic out in a custom hook causes the table not to respond
  // to filter changes (only responds on every second change then).. So please keep this inline
  const fetchData = useCallback(async () => {
    return client
      .query<LocalRankingDistributionNeighborlyQuery>({
        query: LocalRankingDistributionNeighborlyDocument,
        variables: {
          filters,
        },
      })
      .then((e) => {
        if (isEmptyGroup) {
          return {
            data: [],
            length: 0,
          };
        }
        const rankingDistributionData = e.data.graphs.localRankingDistributionNeighborly;

        const absoluteMaxDate =
          Object.keys(rankingDistributionData)[Object.keys(rankingDistributionData).length - 1];
        const latestDate = customLatestDate
          ? customLatestDate
          : isLatest
          ? absoluteMaxDate
          : periodFilterValue[1];
        const value = rankingDistributionData[latestDate];

        const absoluteMinDate = Object.keys(rankingDistributionData)[0];
        const compareDate = customCompareDate
          ? customCompareDate
          : isInitial
          ? absoluteMinDate
          : periodFilterValue[0];
        const compareValue = rankingDistributionData[compareDate];

        return {
          data: [
            {
              firstColumn: compareDate,
              rank1: formatDisplayNumber(compareValue.rank1),
              rank2: formatDisplayNumber(compareValue.rank2),
              rank3: formatDisplayNumber(compareValue.rank3),
              rankGt3: formatDisplayNumber(compareValue.rankGt3),
              nonRanking: formatDisplayNumber(compareValue.nonRanking),
              minDate: new Date(absoluteMinDate),
              maxDate: moment(latestDate).subtract(1, 'd').toDate(),
              onDateChange: setCustomCompareDate,
            },
            {
              firstColumn: latestDate,
              rank1: formatDisplayNumber(value.rank1),
              rank2: formatDisplayNumber(value.rank2),
              rank3: formatDisplayNumber(value.rank3),
              rankGt3: formatDisplayNumber(value.rankGt3),
              nonRanking: formatDisplayNumber(value.nonRanking),
              minDate: moment(compareDate).add(1, 'd').toDate(),
              maxDate: new Date(absoluteMaxDate),
              onDateChange: setCustomLatestDate,
            },
            {
              firstColumn: t('% Difference'),
              rank1: {
                value: `${percentageDifference(value.rank1, compareValue.rank1)}%`,
                diff: value.rank1 - compareValue.rank1,
              },
              rank2: {
                value: `${percentageDifference(value.rank2, compareValue.rank2)}%`,
                diff: value.rank2 - compareValue.rank2,
              },
              rank3: {
                value: `${percentageDifference(value.rank3, compareValue.rank3)}%`,
                diff: value.rank3 - compareValue.rank3,
              },
              rankGt3: {
                value: `${percentageDifference(value.rankGt3, compareValue.rankGt3)}%`,
                diff: value.rankGt3 - compareValue.rankGt3,
              },
              nonRanking: {
                value: `${percentageDifference(value.nonRanking, compareValue.nonRanking)}%`,
                diff: value.nonRanking - compareValue.nonRanking,
              },
            },
            {
              firstColumn: t('# Difference'),
              rank1: {
                value: formatDisplayNumber(value.rank1 - compareValue.rank1),
                diff: value.rank1 - compareValue.rank1,
              },
              rank2: {
                value: formatDisplayNumber(value.rank2 - compareValue.rank2),
                diff: value.rank2 - compareValue.rank2,
              },
              rank3: {
                value: formatDisplayNumber(value.rank3 - compareValue.rank3),
                diff: value.rank3 - compareValue.rank3,
              },
              rankGt3: {
                value: formatDisplayNumber(value.rankGt3 - compareValue.rankGt3),
                diff: value.rankGt3 - compareValue.rankGt3,
              },
              nonRanking: {
                value: formatDisplayNumber(value.nonRanking - compareValue.nonRanking),
                diff: value.nonRanking - compareValue.nonRanking,
              },
            },
          ],
          length: 4,
        };
      });
  }, [filtersHash, client, customLatestDate, customCompareDate]);

  return (
    <DataTable
      tableId={TableIDs.LOCAL_RANKING_DISTRIBUTION_NEIGHBORLY}
      columns={columns}
      fetchData={fetchData}
      pageSize={4}
      viewMode={TableSize.DEFAULT}
      defaultOrdering={undefined}
      pagination={false}
      dataKey={`${customCompareDate}-${customLatestDate}-${filtersHash}`} // trigger table re-render when dates change
      skipClearStoreOnUnmount
    />
  );
};
