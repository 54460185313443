import { forwardRef, useRef } from 'react';
import { Group, Menu } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';
import { QueryOrder } from 'Constants';
import { Ordering } from 'Types/Sort';
import { getSortingIcon, getSortingText } from 'Utilities/getSortingInfo';
import { SortingMenuItem } from '.';
import styles from './sort.module.scss';

type Props = {
  option: SortingMenuItem;
  onSortClick: (order: Ordering) => () => void;
};

const SubItem = forwardRef(
  (
    { option, onSortClick, order }: Props & { order: QueryOrder },
    ref: React.Ref<HTMLButtonElement>,
  ) => {
    const isDesc = order === QueryOrder.DESC;
    return (
      <Menu.Item
        onClick={onSortClick({
          orderBy: option.orderBy,
          defaultOrder: order,
          sortingKey: option.sortingKey,
        })}
        ref={ref}
      >
        <Group gap="md">
          {getSortingIcon(isDesc, true, option)}
          <span>{getSortingText(!isDesc, true, option)}</span>
        </Group>
      </Menu.Item>
    );
  },
);
SubItem.displayName = 'SubItem';

const SortItem = ({ option, onSortClick }: Props) => {
  const firstItem = useRef<HTMLButtonElement>(null);
  return (
    <Menu
      width={200}
      shadow="md"
      position="right-start"
      trigger="click"
      closeOnItemClick={false}
      classNames={{
        dropdown: styles.sortDropdown,
        item: styles.sortItem,
      }}
      offset={0}
      closeOnEscape={true}
      menuItemTabIndex={0}
      returnFocus={true}
      onOpen={() => {
        // set timeout to focus first item after menu is opened
        setTimeout(() => {
          firstItem.current?.focus();
        }, 50);
      }}
    >
      <Menu.Target>
        <Menu.Item rightSection={<IconChevronRight className={styles.sortItemChevron} size={16} />}>
          {option.label}
        </Menu.Item>
      </Menu.Target>

      <Menu.Dropdown>
        <SubItem ref={firstItem} option={option} onSortClick={onSortClick} order={QueryOrder.ASC} />
        <SubItem option={option} onSortClick={onSortClick} order={QueryOrder.DESC} />
      </Menu.Dropdown>
    </Menu>
  );
};

export default SortItem;
