import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { Flex } from '@mantine/core';
import AccButton from 'Components/AccButton/AccButton';
import { Field, Form } from 'Components/Fields';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import SoMeFormFields from 'Components/SoMeFormFields/SoMeFormFields';
import { SoMePlatform } from 'Components/SoMeFormFields/SocialMediaIcons';
import AccText from 'Components/Text/AccText';
import { useGetCompetitorDataQuery } from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import { TrackingKey } from 'Utilities/Analytics/mixpanel';
import { t } from 'Utilities/i18n/index';
import { notEmpty } from 'Utilities/underdash';
import Validator from 'Utilities/validation';
import { BuildCompetitorProps } from '..';
import Skeleton from '../utils/Skeleton';
import { useSubmitCompetitor } from '../utils/useSubmitCompetitor';

const FormFields = () => {
  const [activeSocials, setActiveSocials] = useState<SoMePlatform[]>([]);

  const form = useForm();
  const { initialValues } = useFormState();
  const { hideModal } = useModal();

  // show social media fields if they have an initial value
  useEffect(() => {
    const initialActiveSocials: SoMePlatform[] = [];
    Object.entries(initialValues).forEach(([key, value]) => {
      if (Object.values<string>(SoMePlatform).includes(key) && value !== undefined) {
        initialActiveSocials.push(key as SoMePlatform);
      }
    });
    setActiveSocials(initialActiveSocials);
  }, [initialValues]);

  const handleSocialIconToggle = useCallback(
    (platform: SoMePlatform) => {
      setActiveSocials((prevSocials) => {
        const updatedSocials = [...prevSocials];
        const index = prevSocials?.indexOf(platform);

        if (index === -1) {
          updatedSocials.push(platform);
        } else {
          form.change(platform, '');
          updatedSocials.splice(index, 1);
        }

        return updatedSocials;
      });
    },
    [form],
  );

  return (
    <>
      <Field.TextInput
        name="domain"
        label={t('Competitor Domain')}
        placeholder={t('Example: amazon.co.uk')}
        required
        autoComplete="organization"
        validate={Validator.required}
        autoFocus={true}
      />

      <Field.TextInput
        name="displayName"
        label={t('Competitor Display Name')}
        placeholder={t('Example: Amazon UK')}
      />

      <Field.MultiSelect
        name="googleBusinessNameList"
        label={t('Competitor Google Business names')}
        placeholder={t('Competitor Google Business names...')}
        noResultsText={t('Enter name of the business and hit enter')}
        size="md"
      />

      <SoMeFormFields
        onIconToggle={handleSocialIconToggle}
        activeSocials={activeSocials}
        titleProps={{ mb: 'xs' }}
        isCompetitor={true}
      />

      <ModalFooter
        primaryButtonSlot={
          <AccButton type="submit" variant="primary" trackingKey={TrackingKey.AddStaticCompetitor}>
            {t('Save')}
          </AccButton>
        }
        secondaryButtonSlot={
          <AccButton variant="tertiary" onClick={hideModal}>
            {t('Cancel')}
          </AccButton>
        }
        textSlot={
          <AccText variant="label">
            {t('Competitor data will be ready around 10 seconds after saving.')}
          </AccText>
        }
      />
    </>
  );
};

const BuildCompetitorForm = (props: BuildCompetitorProps) => {
  const { competitorId, refresh, domainId, domainToAdd } = props;

  const { handleSubmit } = useSubmitCompetitor({ competitorId, refresh, domainId });

  const { data: competitorData, loading } = useGetCompetitorDataQuery({
    variables: {
      domainId,
      competitorId: competitorId || '',
    },
    skip: !domainId || !competitorId,
  });
  const competitor = competitorData?.domain?.competitor;

  // use competitor data if object exists (edit competitor),
  // otherwise use empty values (add new competitor)
  const initialValues = useMemo(
    () => ({
      domain: competitor?.domain || domainToAdd || '',
      displayName: competitor?.displayName || '',
      googleBusinessNameList: competitor?.googleBusinessNameList?.filter(notEmpty) || [],
      [SoMePlatform.X]: competitor?.twitterHandle || undefined,
      [SoMePlatform.FACEBOOK]: competitor?.facebookProfile || undefined,
      [SoMePlatform.LINKEDIN]: competitor?.linkedinProfile || undefined,
      [SoMePlatform.TIKTOK]: competitor?.tiktokProfile || undefined,
      [SoMePlatform.INSTAGRAM]: competitor?.instagramProfile || undefined,
      delete: undefined,
    }),
    [JSON.stringify(competitorData), domainToAdd],
  );

  if (loading) {
    return <Skeleton />;
  }

  return (
    <Form onSubmit={handleSubmit} initialValues={initialValues}>
      <Flex direction="column" rowGap="xl">
        <Form.Errors />
        <FormFields />
      </Flex>
    </Form>
  );
};

export default BuildCompetitorForm;
