import AccTooltip from 'Components/AccTooltip/AccTooltip';
import Cell from 'Components/AccuTable/CellRenderer/HelperComponents/AccuCell';
import FormatNumber from 'Components/FormatNumber/new';
import { KeywordRankWithCompetitorRankNode } from 'Ghql';
import { t } from 'Utilities/i18n';

type Props = { record: KeywordRankWithCompetitorRankNode; rankKey: string };

const CompetitorRankCell: React.FC<React.PropsWithChildren<Props>> = ({
  record,
  rankKey,
}: Props) => {
  const rankValue = record?.[rankKey];
  return (
    <Cell className="u-flex u-items-center" rightAligned={true}>
      {rankValue ? (
        <FormatNumber value={rankValue} />
      ) : (
        <AccTooltip tooltip={t('Not in top 100')}>
          <span>{t('> 100')}</span>
        </AccTooltip>
      )}
    </Cell>
  );
};

export const createRankCell = (rankKey: string): React.FC<React.PropsWithChildren<Props>> => {
  const result = (props: Props) => {
    return <CompetitorRankCell {...props} rankKey={rankKey} />;
  };
  result.displayName = 'createRankCell';
  return result;
};
