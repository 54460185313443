import Alert from 'Components/Alert';
import Skeleton from 'Components/Skeleton';
import Text from 'Components/Text/AccText';
import { UnrankedWeightConstants } from 'Constants';
import { useFieldValue } from 'Hooks/form/useFieldValue';
import { t, tct } from 'Utilities/i18n/index';
import styles from './account-settings.module.scss';

export const AccountSkeleton = () => (
  <Skeleton
    className="indented-form-group form-group"
    linesConfig={[
      {
        type: 'chart',
      },
      {
        type: 'chart',
      },
      {
        type: 'chart',
      },
      {
        type: 'chart',
      },
      {
        type: 'chart',
      },
      {
        type: 'chart',
      },
      {
        type: 'chart',
      },
      {
        type: 'chart',
      },
      {
        type: 'chart',
      },
    ]}
  />
);

export const removeUpload = () =>
  fetch('/org/update_logo/', {
    method: 'POST',
    credentials: 'same-origin',
  });

export const upload = (logo: File | null) => {
  if (!logo) return null;
  const formData = new FormData();
  formData.append('logo', logo);
  return fetch('/org/update_logo/', {
    method: 'POST',
    body: formData,
    credentials: 'same-origin',
  });
};

export const SubAccountHelp = (props: { isPartner: boolean; isSubAccount: boolean }) => (
  <div className={styles.accountHelpTextContainer}>
    <Text fw={300}>
      {t(
        'With this enabled you can access and create other AccuRanker accounts from your own account.',
      )}
    </Text>
    {props.isSubAccount && (
      <Text fw={300} mt={14} fs="italic">
        {t('Enabling sub-accounts is not possible from a sub-account.')}
      </Text>
    )}
    {props.isPartner && (
      <Text fw={300} mt={14} fs="italic">{`${t(
        'Already enabled. To disable contact support.',
      )}`}</Text>
    )}
  </div>
);

export const KeywordExceedingsHelp = (props: {
  keywordOveruseEnabled: boolean;
  isTrial: boolean;
}) => (
  <div className={styles.accountHelpTextContainer}>
    <Text fw={300}>
      {t('With this enabled you can add as many keywords to your plan as you like.')}
    </Text>
    {props.keywordOveruseEnabled && (
      <Text fw={300} mt={14} fs="italic">{`${t(
        'Already enabled. To disable contact support.',
      )}`}</Text>
    )}
    {!props.keywordOveruseEnabled && props.isTrial && (
      <Text fw={300} mt={14} fs="italic">{`${t('Not available on trial accounts.')}`}</Text>
    )}
  </div>
);

export enum FieldName {
  name = 'name',
  logo = 'logo',
  isPartner = 'isPartner',
  keywordOveruseEnabled = 'keywordOveruseEnabled',
  displayCurrency = 'displayCurrency',
  showLocalSearchVolume = 'showLocalSearchVolume',
  unrankedWeight = 'unrankedWeight',
  unrankedWeightEnabled = 'unrankedWeightEnabled',
}

export const EnableLocalSearchHelp = () => {
  const field = useFieldValue(FieldName.showLocalSearchVolume);

  return (
    <div className={styles.accountHelpTextContainer}>
      <Text fw={300}>
        {tct(
          'Show search volume metrics based on local or country values for local keywords. This setting applies to all domains set to \'Account Default\'.',
          {
            break: <br />,
          },
        )}
      </Text>
      {!field && (
        <Alert type="warning" className={styles.accountHelpTextWarning}>
          <Text fw={300} fs="italic">
            {tct(
              '[bold:Note:] If unchecked, national search volume metrics will be displayed regardless of keyword location.',
              {
                bold: <b />,
              },
            )}
          </Text>
        </Alert>
      )}
    </div>
  );
};

export const IncludeLocalSearchHelperText = () => {
  return (
    <>
      <Text size="xs">
        {t(
          'When enabled, all search volume figures and related metrics (AI Share of Voice, Cost per Click, Ad Competition, Share of Voice, and Traffic Value) where a specific keyword location is specified will display local metrics. If disabled, all related metrics will be based on country values. Learn more',
        )}{' '}
        <a
          href={'https://www.accuranker.com/help/keywords/searches/'}
          target={'_blank'}
          rel="noreferrer"
        >
          {t('here.')}
        </a>
      </Text>
    </>
  );
};

export const DisplayCurrencyHelp = () => (
  <div className={styles.accountHelpTextContainer}>
    <Text fw={300}>
      {t(
        'The currency will reflect on your CPC & traffic values across AccuRanker. This setting applies to all domains set to \'Account Default\'.',
      )}
    </Text>
  </div>
);

export const validateUnrankedWeight = (values: Record<FieldName, any>, errors) => {
  if (values[FieldName.unrankedWeightEnabled]) {
    const unrankedWeight = parseInt(values[FieldName.unrankedWeight], 10);
    if (unrankedWeight < UnrankedWeightConstants.MIN_UNRANKED_WEIGHT) {
      errors[FieldName.unrankedWeight] = ' ';
    } else if (unrankedWeight > UnrankedWeightConstants.MAX_UNRANKED_WEIGHT) {
      errors[FieldName.unrankedWeight] = ' ';
    }
  }
};
