import { useState } from 'react';
import { IconCheck, IconChecks, IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import cn from 'classnames';
import range from 'lodash/range';
import AccButton from 'Components/AccButton/AccButton';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import Skeleton from 'Components/Skeleton';
import AccText from 'Components/Text/AccText';
import {
  useMessagesDropdownMessagesQuery,
  useMessagesDropdownTotalMessagesCountQuery,
  useMessagesDropdownUpdateAllReadMutation,
  useMessagesDropdownUpdateReadMutation,
} from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import { useUser } from 'Hooks/data/user/useUser';
import { TrackingKey } from 'Utilities/Analytics/mixpanel';
import { t } from 'Utilities/i18n';
import styles from './messages-new.module.scss';

const MessagesNew = () => {
  const pageSize = 100;
  const [{ startIdx, stopIdx }, setPagination] = useState<{
    startIdx: number;
    stopIdx: number;
  }>({
    startIdx: 0,
    stopIdx: pageSize,
  });
  const user = useUser();

  const { showModal, hideModal } = useModal();

  const {
    data: messagesData,
    loading: messagesLoading,
    refetch: refetchMessages,
  } = useMessagesDropdownMessagesQuery({
    variables: { startIdx, stopIdx },
  });
  const messages = messagesData?.messages;

  const { data: totalMessagesCountData, refetch: refetchTotalMessagesCount } =
    useMessagesDropdownTotalMessagesCountQuery({ skip: !user?.id });
  const totalMessagesCount = totalMessagesCountData?.totalMessagesCount;

  const [updateRead] = useMessagesDropdownUpdateReadMutation();

  const [updateAllRead] = useMessagesDropdownUpdateAllReadMutation();

  return (
    <ModalBorder title={t('Messages')} onClose={hideModal}>
      <div className={styles.messagesWrapper}>
        <div className={styles.messagesHeader}>
          <div className={styles.messagesHeaderContent}>
            <>
              <AccText>{t('New messages')}</AccText>
              <AccText size="sm" fw="bold">
                {totalMessagesCount && totalMessagesCount > pageSize
                  ? `${startIdx + 1} - ${stopIdx}`
                  : ''}
              </AccText>
            </>
            <div
              className={styles.readAllWrapper}
              onClick={() =>
                updateAllRead({ variables: { read: true } }).then(() =>
                  Promise.all([refetchTotalMessagesCount(), refetchMessages()]),
                )
              }
            >
              <AccText className={styles.readAllText} size="xs">
                {t('Mark all as read')}
              </AccText>
              <IconChecks strokeWidth="3px" className={styles.iconChecks} />
            </div>
          </div>
        </div>
        <div className={styles.messagesContent}>
          {messagesLoading ? (
            <Skeleton
              linesConfig={range(stopIdx % pageSize === 0 ? pageSize : stopIdx % pageSize).map(
                () => ({
                  type: 'text',
                  options: {
                    width: '100%',
                    height: '80px',
                  },
                }),
              )}
            />
          ) : messages?.length === 0 ? (
            <div className={styles.noNewMessages}>
              <AccText>{t('No new messages.')}</AccText>
            </div>
          ) : (
            <>
              {messages?.map((message) => (
                <div className={styles.message} key={`message-${message.id}`}>
                  <AccTooltip tooltip={message.level}>
                    <div
                      className={cn({
                        [styles.messageInfo]: message.levelId === 1,
                        [styles.messageWarning]: message.levelId === 2,
                        [styles.messageError]: message.levelId === 3,
                      })}
                    />
                  </AccTooltip>
                  <AccText className={styles.messageDate} size="xs">
                    {new Date(message.createdAt).toLocaleString(
                      user.language === 'da' ? 'da-DK' : 'en-GB',
                      { dateStyle: 'medium', timeStyle: 'short' },
                    )}
                  </AccText>
                  <div
                    className={styles.readWrapper}
                    onClick={() => {
                      updateRead({ variables: { input: { ids: [message.id], read: true } } }).then(
                        () =>
                          Promise.all([refetchTotalMessagesCount(), refetchMessages()]).then(() => {
                            if (stopIdx - 1 === startIdx) {
                              setPagination({
                                startIdx: Math.max(startIdx - pageSize, 0),
                                stopIdx: startIdx,
                              });
                            } else {
                              setPagination({
                                startIdx,
                                stopIdx: stopIdx === totalMessagesCount ? stopIdx - 1 : stopIdx,
                              });
                            }
                          }),
                      );
                    }}
                  >
                    <AccText className={styles.readText} size="xs">
                      {t('Mark as read')}
                    </AccText>
                    <IconCheck strokeWidth="3px" className={styles.iconCheck} />
                  </div>
                  <AccText className={styles.messageText} size="sm">
                    {message.text}
                  </AccText>
                </div>
              ))}
            </>
          )}
        </div>
        <div className={styles.messagesFooter}>
          <div className={styles.iconChevronLeftContainer}>
            {startIdx > 0 && (
              <IconChevronLeft
                className={styles.iconChevron}
                onClick={() =>
                  setPagination({
                    startIdx: Math.max(startIdx - pageSize, 0),
                    stopIdx: startIdx,
                  })
                }
              />
            )}
          </div>
          <AccButton
            trackingKey={TrackingKey.InboxViewReadMessages}
            variant="secondary"
            onClick={() =>
              showModal({
                modalType: 'Messages',
                modalTheme: 'light',
              })
            }
          >
            {t('View read messages')}
          </AccButton>
          <div className={styles.iconChevronRightContainer}>
            {totalMessagesCount && stopIdx < totalMessagesCount ? (
              <IconChevronRight
                className={styles.iconChevron}
                onClick={() =>
                  setPagination({
                    startIdx: stopIdx,
                    stopIdx: Math.min(stopIdx + pageSize, totalMessagesCount),
                  })
                }
              />
            ) : (
              <> </>
            )}
          </div>
        </div>
      </div>
    </ModalBorder>
  );
};

export default MessagesNew;
