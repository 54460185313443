import { useMemo } from 'react';
import { Box } from '@mantine/core';
import { useShareOfVoiceCompetitorsQuery } from 'Ghql';
import { useFilters } from 'Hooks';
import { useModal } from 'Hooks/base/useModal';
import { useDomainId, useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import { MissingDataOverlay } from 'Pages/Keywords/Overview/MissingDataOverlay';
import { BarChart } from 'Pages/Keywords/Overview/components/BarChart/BarChart';
import { useGroupViewInfo } from 'Pages/Keywords/Overview/hooks/useGroupViewInfo';
import { colorScheme } from 'Utilities/colors';
import { t } from 'Utilities/i18n';

export const ShareOfVoiceCompetitors = ({ useAi }) => {
  const filters = useFilters();
  const { isEmptyGroup, isGroupView } = useGroupViewInfo(filters);
  const { domainInfo } = useQueryDomainInfo();
  const { data, loading, refetch } = useShareOfVoiceCompetitorsQuery({
    variables: {
      filters,
      asPercentage: false,
      name: `SoVCompetitorChart${useAi ? 'AI' : ''}`,
      useAi,
    },
  });

  const getIsOwnDomain = (domain?: string | null) => {
    return domainInfo?.domain === domain;
  };

  const dataItems = useMemo(() => {
    return (
      data?.charts?.shareOfVoiceCompetitors?.map((e, _) => {
        const isOwnDomain = getIsOwnDomain(e?.domain);

        return {
          value: e?.shareOfVoice ?? 0,
          label: e.displayName || e.domain,
          isOwnDomain,
          background: isOwnDomain ? colorScheme.orange : colorScheme.shareVoice,
          icon: <img src={e?.faviconUrl || ''} alt="" />,
          id: e?.domain,
        };
      }) ?? []
    );
  }, [data, getIsOwnDomain]);

  const fakeDataItems = useMemo(() => {
    const values = [dataItems?.[0]?.value || 0, 100, 90, 70, 20];
    const labels: string[] = [
      dataItems?.[0]?.label || '',
      t('Competitor 1'),
      t('Competitor 2'),
      t('Competitor 3'),
      t('Competitor 4'),
    ];
    const faviconUrl = data?.charts?.shareOfVoiceCompetitors?.[0]?.faviconUrl || '';
    const iconSources = [
      faviconUrl,
      '/screenshots/favicon?url=amazon.com&size=16',
      '/screenshots/favicon?url=google.com&size=16',
      '/screenshots/favicon?url=adidas.co.uk&size=16',
      '/screenshots/favicon?url=facebook.com&size=16',
    ];

    return Array.from(Array(5)).map((_, index) => ({
      value: values[index],
      label: labels[index],
      background: dataItems?.[0]?.background,
      icon: <img src={iconSources[index] || ''} alt="" />,
      id: labels[index],
    }));
  }, [data, getIsOwnDomain]);

  const nCompetitors = dataItems?.length > 1;
  //const nCompetitors = 0; // TODO use fake data for test - delete this line before merge

  const { showModal } = useModal();
  const domainId = useDomainId();

  const addCompetitorModal = () =>
    showModal({
      modalType: 'BuildCompetitor',
      modalTheme: 'light',
      modalProps: {
        domainId,
        refresh: () => refetch?.(),
      },
    });

  return (
    <Box pos="relative">
      {!nCompetitors && !loading && (
        <MissingDataOverlay
          onClick={addCompetitorModal}
          type="competitors"
          isGroupView={isGroupView}
          isEmptyGroup={isEmptyGroup}
        />
      )}
      <BarChart
        normalNumberOfItems={11}
        loading={loading}
        items={nCompetitors ? dataItems : fakeDataItems}
      />
    </Box>
  );
};
