import AccButton from 'Components/AccButton/AccButton';
import Alert from 'Components/Alert';
import { t } from 'Utilities/i18n/index';

export const ImportValidationStatusCompleted = () => {
  return (
    <>
      <Alert>
        <p>{t('Your import is completed.')}</p>
      </Alert>

      <AccButton variant="primary" link="/import">
        {t('Back to imports')}
      </AccButton>
    </>
  );
};
