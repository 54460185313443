import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDebouncedValue } from '@mantine/hooks';
import { IconCirclePlus } from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import AccessControl from 'Components/AccessControl';
import Breadcrumbs from 'Components/Breadcrumbs/Breadcrumbs';
import SearchInput from 'Components/SearchInput';
import ActionbarContainer from 'Pages/Layout/ActionsMenu/components/ActionbarContainer';
import { t } from 'Utilities/i18n';
import * as Mousetrap from 'Utilities/mousetrap';
import { isMac } from 'Utilities/underdash';
import reusableStyles from 'css/reusable-styles.module.scss';
import { getSearchCombo } from '../../../Components/Modal/Content/Shortcuts';
import ScheduledReportsTable from './ScheduledReportsTable';

const initialState = {
  searchValue: '',
  isLoading: false,
  dataKey: 1,
};

type State = {
  searchValue: string;
  isLoading: boolean;
  dataKey: number;
};

const ScheduledReports = () => {
  const [{ searchValue, isLoading, dataKey }, setState] = useState<State>(initialState);
  const [searchTerm] = useDebouncedValue(searchValue, 250);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleUpdate = () => {
    setState((prevState) => ({
      ...prevState,
      dataKey: prevState.dataKey + 1,
    }));
  };

  useEffect(() => {
    const shortcut = isMac() ? 'command+f' : 'ctrl+f';
    Mousetrap.bind(shortcut, '', () => {
      return inputRef?.current?.focus() || false;
    });
    return () => {
      Mousetrap.unbind(['command+f', 'ctrl+f']);
    };
  }, []);

  const handleChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      searchValue: (event.target as HTMLInputElement)?.value,
    }));
  };

  const handleLoading = (loading: boolean) => {
    setState((prevState) => ({
      ...prevState,
      isLoading: loading,
    }));
  };

  const ReportTable = React.useMemo(() => {
    const RepTable = () => (
      <div className={reusableStyles.paper}>
        <ScheduledReportsTable
          searchTerm={searchTerm}
          handleUpdateTable={handleUpdate}
          dataKey={dataKey}
          handleLoading={handleLoading}
        />
      </div>
    );
    return RepTable;
  }, [dataKey, searchTerm]);

  const Input = React.useMemo(() => {
    const Search = () => (
      <SearchInput
        ref={inputRef}
        key="search-input"
        value={searchValue}
        disabled={isLoading}
        onChange={handleChange}
        placeholder={t('Reports…')}
        keyboardShortcut={getSearchCombo()}
        autoFocus
      />
    );
    return Search;
  }, [isLoading, searchValue]);

  return (
    <>
      <Breadcrumbs />
      <ActionbarContainer mt="md">
        <AccessControl>
          <Link key="new" to="/reports/schedule">
            <AccButton variant="primary" leftSection={<IconCirclePlus size={18} />}>
              {t('New scheduled report')}
            </AccButton>
          </Link>
        </AccessControl>
        <Input />
      </ActionbarContainer>
      <ReportTable />
    </>
  );
};

export default ScheduledReports;
