import { Group } from '@mantine/core';
import { AccMultiSelect, SelectItem } from 'Components/AccSelect';
import { DomainFavicon } from 'Components/DomainFavicon';
import FilterCount from 'Components/Filters/Common/FilterCount';
import AccText from 'Components/Text/AccText';
import { t } from 'Utilities/i18n';
import { useTopCompetitorData } from '../support/hooks';

type Props = {
  input: {
    value: string;
    onChange: (string) => void;
  };
};

export const TopCompetitorFieldInput = (props: Props) => {
  const { loading, data } = useTopCompetitorData();

  const options = data?.filterData?.filterOptions?.map((e) => ({
    label: e.option || t('Local result'),
    value: e.option,
    count: e.count,
  })) as SelectItem<string>[];

  const onChange = (val) => {
    props.input?.onChange(val);
  };

  const optionComponent = (item) => (
    <Group align={'apart'} pos="relative">
      <Group gap={'xs'} maw={200} wrap="nowrap" pr="30">
        <DomainFavicon domain={item.label} />
        <AccText size="sm" fw={'normal'} ta="right" target="_blank" overflowEllipsis nowrap>
          {item.label}
        </AccText>
      </Group>
      <FilterCount count={item.count} />
    </Group>
  );

  return (
    <AccMultiSelect
      value={props.input?.value}
      options={options}
      onChange={onChange}
      disabled={loading}
      clearable={false}
      inputMaxWidth={570}
      optionComponent={optionComponent}
      creatable={false}
      autoFocus
      placeholder={t('Type to search...')}
      mb="sm"
      limit={25}
    />
  );
};
