import { MouseEvent } from 'react';
import {
  useDeleteDynamicTagRulesetFromTagMutation,
  useDeleteStaticTagMutation,
  useDynamicTagRulesetFromTagLazyQuery,
  useStaticTagInformationLazyQuery,
} from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import toast from 'Hooks/useToast';
import { useTreeViewStore } from 'Pages/Domains/TreeView/support/treeViewStore';
import { ProcessedFolderTreeNode } from 'Pages/Domains/TreeView/support/types';
import { useInvalidateKeywordCache } from 'Utilities/Graphql/invalidateCache';
import { getDomainId } from 'Utilities/generateSelectId';
import { t, tct } from 'Utilities/i18n';
import { sleep } from 'Utilities/promise';

export const useTagActions = (rootNode: ProcessedFolderTreeNode) => {
  const domainIdString = getDomainId(rootNode.path);
  const domainId = domainIdString ? parseInt(domainIdString, 10) : null;
  const { showModal } = useModal();
  const treeViewStore = useTreeViewStore();

  const [getDynamicTagData] = useDynamicTagRulesetFromTagLazyQuery();
  const [getStaticTagData] = useStaticTagInformationLazyQuery();
  const [deleteDynamicTag] = useDeleteDynamicTagRulesetFromTagMutation();
  const [deleteStaticTag] = useDeleteStaticTagMutation();
  const invalidateKeywordCache = useInvalidateKeywordCache();

  const handleEditDynamicTag = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (!domainId) return;
    const { data } = await getDynamicTagData({
      variables: { domainId, tag: rootNode.name },
      fetchPolicy: 'network-only',
    });
    const dynamicTagData = data?.dynamicTagRulesetFromTag;
    showModal({
      modalType: 'CreateOrEditDynamicTag',
      modalTheme: 'light',
      modalProps: {
        isEdit: true,
        initialValues: {
          id: dynamicTagData?.id,
          folder: dynamicTagData?.folderPath,
          tag: dynamicTagData?.tag,
          rawRuleset: dynamicTagData?.rawRuleset,
        },
        updatedAt: dynamicTagData?.updatedAt,
        createdBy: dynamicTagData?.createdBy,
        domainId: domainIdString,
      },
    });
  };

  const handleCloneDynamicTag = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (!domainId) return;
    const { data } = await getDynamicTagData({
      variables: { domainId, tag: rootNode.name },
      fetchPolicy: 'network-only',
    });
    const dynamicTagData = data?.dynamicTagRulesetFromTag;
    showModal({
      modalType: 'CreateOrEditDynamicTag',
      modalTheme: 'light',
      modalProps: {
        isEdit: false,
        initialValues: {
          folder: dynamicTagData?.folderPath,
          tag: `${dynamicTagData?.tag} ${t('(copy)')}`,
          rawRuleset: dynamicTagData?.rawRuleset,
        },
        domainId: domainIdString,
      },
    });
  };

  const handleDeleteDynamicTag = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (!domainId) return;
    showModal({
      modalType: 'Confirmation',
      modalTheme: 'light',
      modalProps: {
        title: t('Delete dynamic tag?'),
        lockDuration: 0,
        description: tct(
          'The dynamic tag "[tag]" will be permanently deleted and be removed from all matching keywords.',
          { tag: rootNode.name },
        ),
        confirmLabel: t('Delete'),
        cancelLabel: t('Cancel'),
        action: async () => {
          await deleteDynamicTag({
            variables: { tag: rootNode.name, domainId },
          });

          invalidateKeywordCache();
          if (rootNode?.keywords && rootNode?.keywords > 1000) {
            // Sleep some more since dynamic tags take longer to delete when there is many keywords affected
            await sleep(1000);
          }
          await treeViewStore.refetch(false); // Dont refetch immediately as dynamic tags takes small amount of time to delete
          toast.success(t('Dynamic tag "%s" was deleted!', rootNode.name));
        },
      },
    });
  };

  const handleDeleteStaticTag = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (!domainId) return;
    showModal({
      modalType: 'Confirmation',
      modalTheme: 'light',
      modalProps: {
        title: t('Delete static tag?'),
        lockDuration: 0,
        description: tct(
          'The static tag "[tag]" will be permanently deleted and be removed from all keywords.',
          { tag: rootNode.name },
        ),
        confirmLabel: t('Delete'),
        cancelLabel: t('Cancel'),
        action: async () => {
          await deleteStaticTag({
            variables: { tag: rootNode.name, domainId },
          });
          invalidateKeywordCache();
          await treeViewStore.refetch(false); // Dont refetch immediately as dynamic tags takes small amount of time to delete
          toast.success(t('Static tag "%s" was deleted!', rootNode.name));
        },
      },
    });
  };

  const handleEditStaticTag = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (!domainId) return;
    const { data } = await getStaticTagData({
      variables: { domainId, tag: rootNode.name },
      fetchPolicy: 'network-only',
    });
    const staticTagData = data?.staticTagInformation;
    showModal({
      modalType: 'CreateOrEditStaticTag',
      modalTheme: 'light',
      modalProps: {
        initialValues: {
          tag: staticTagData?.tag,
          folder: staticTagData?.folderPath,
          keywords: staticTagData?.keywords,
        },
        domainId: domainIdString,
      },
    });
  };

  return {
    handleEditDynamicTag,
    handleCloneDynamicTag,
    handleDeleteDynamicTag,
    handleEditStaticTag,
    handleDeleteStaticTag,
  };
};
