import { memo } from 'react';
import cn from 'classnames';
import isEqual from 'lodash/isEqual';
import { useDisplayCurrency } from 'Hooks/displayCurrency';
import { useKeydisKpis } from 'Pages/KeywordDiscovery/support/hooks/kpis';
import style from 'Pages/Keywords/Overview/components/KpiBar/kpi-bar.module.scss';
import { t, tct } from 'Utilities/i18n';
import DiscKpiBox from './DiscKpiBox';
import { useKpiFakeData } from './useKpiFakeData';

interface KeyDisKpiBarProps {
  countryChoice: string | undefined;
  usePlaceholderData?: boolean;
}

const KeyDisKpiBar = ({ countryChoice, usePlaceholderData = false }: KeyDisKpiBarProps) => {
  const { data: realData, loading: dataLoading } = useKeydisKpis(countryChoice);

  const { fakeData } = useKpiFakeData();
  const { displayCurrency = 'USD' } = useDisplayCurrency(true);
  const data = usePlaceholderData ? fakeData : realData;
  const loading = usePlaceholderData ? false : dataLoading;

  return (
    <div className={cn(style.kpiBar, style.discovery)}>
      <DiscKpiBox
        size="large"
        loading={loading}
        value={data?.discoveredKeywords || 0}
        title={t('Keywords')}
      />
      <DiscKpiBox
        size="large"
        loading={loading}
        value={data?.trafficValue || 0}
        title={tct('Traffic val. ([currency])', { currency: displayCurrency })}
      />
      <DiscKpiBox
        size="large"
        loading={loading}
        value={data?.estimatedVisits || 0}
        title={t('AI Share of Voice')}
      />
      <DiscKpiBox
        size="large"
        loading={loading}
        value={data?.averageRank || 0}
        title={t('Average Rank')}
      />
      <DiscKpiBox
        size="large"
        loading={loading}
        value={data?.aboveTheFold || 0}
        title={t('Above the Fold')}
      />
      <DiscKpiBox
        size="large"
        loading={loading}
        value={data?.averageCtr || 0}
        title={t('Average CTR')}
        precision={2}
        showPercentage
      />
    </div>
  );
};

export default memo(KeyDisKpiBar, isEqual);
