import { memo, useMemo } from 'react';
import { useApolloClient } from '@apollo/client';
import { Box } from '@mantine/core';
import isEqual from 'lodash/isEqual';
import { AccFastIcon } from 'Components/AccFastIcon';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { ColumnType, QueryOrder, useTableStore } from 'Components/DataTable';
import Ellipsis from 'Components/Ellipsis';
import ActionsCell from 'Components/Table/TableRow/ActionsCell';
import { SORTING_TYPE } from 'Constants';
import { getDisabledDemoText } from 'Constants/messages';
import { EditNoteDocument, NoteNode } from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import { useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import toast from 'Hooks/useToast';
import useUserPermission from 'Hooks/useUserPermission';
import { NotesTableIDs } from 'Pages/NotesList/NotesTable/support/types';
import { TableIDs } from 'Types/Table';
import { invalidateCache } from 'Utilities/Graphql/invalidateCache';
import { formatDate } from 'Utilities/format';
import { t } from 'Utilities/i18n';
import styleVariables from 'css/base/variables.module.scss';
import IconEdit from 'icons/IconEdit.svg';
import { IconTrash } from 'icons/tag-cloud';

interface NotesTableRenderProps {
  record: NoteNode;
}

export const useNotesTableInfo = () => {
  const tableStore = useTableStore(TableIDs.NOTES);
  const { showModal } = useModal();
  const client = useApolloClient();
  const { isDemoDomain } = useQueryDomainInfo();
  const { userHasWriteAccess } = useUserPermission();

  const deleteAction = (noteId) => {
    client
      .mutate({
        mutation: EditNoteDocument,
        variables: {
          input: {
            createdAt: formatDate(new Date()),
            note: '~~DELETED~~',
            id: noteId,
            delete: true,
          },
        },
      })
      .then(({ data: { updateDomain: { errors } = {} as any } }: any) => {
        if (errors && errors.length) {
          toast.error(t('Could not delete note. Something went wrong, please try again.'));
          return;
        }
        tableStore?.deleteItem(noteId);
        toast.success(t('Note deleted'));
        // invalidate keywords cache that includes notes
        invalidateCache(client.cache, ['keywords', 'domain']);
      });
  };
  const onDelete = (noteId) => {
    return showModal({
      modalType: 'Confirmation',
      modalProps: {
        title: t('Delete Note?'),
        description: t('The note will be permanently deleted.'),
        confirmLabel: t('Delete note'),
        cancelLabel: t('Cancel'),
        action: () => deleteAction(noteId),
      },
    });
  };
  const onEdit = (id) =>
    showModal({
      modalType: 'EditNote',
      modalTheme: 'light',
      modalProps: {
        noteId: id,
        refresh: () => {
          invalidateCache(client.cache, ['keywords', 'domain']);
          tableStore?.getData();
        },
        enableEditingKeywords: true,
      },
    });
  const KeywordsComponent = ({ record }: NotesTableRenderProps) => {
    const totalKeywords = record.keywords?.length || 0;
    const maxKeywordsToShowInTooltip = 50;
    const fullText = record?.keywords
      ?.slice(0, maxKeywordsToShowInTooltip)
      ?.map((e) => e?.keyword)
      .filter(Boolean)
      .join(', ');
    let textToShow = fullText;
    const cellWidth = 68;
    const textExceedCellWidth = textToShow?.length && textToShow?.length > cellWidth;
    if (textExceedCellWidth) {
      textToShow = `${textToShow?.slice(0, cellWidth)}...`;
    }
    const tooltipText =
      totalKeywords > maxKeywordsToShowInTooltip
        ? `${fullText}, and ${totalKeywords - maxKeywordsToShowInTooltip} more...`
        : textToShow;
    return (
      <AccTooltip tooltip={<Box maw={200}>{tooltipText}</Box>} old disable={!textExceedCellWidth}>
        <span>{textToShow}</span>
      </AccTooltip>
    );
  };
  KeywordsComponent.displayName = 'KeywordsComponent';

  const columns: ColumnType<NotesTableRenderProps>[] = useMemo(() => {
    const cols: ColumnType<NotesTableRenderProps>[] = [
      {
        id: NotesTableIDs.DATE_CREATED,
        width: 300,
        title: t('Created At'),
        onHeaderCell: () => ({
          tooltip: t('Sort by date created'),
          ordering: {
            defaultOrder: QueryOrder.DESC,
            orderBy: NotesTableIDs.DATE_CREATED,
            sortingKey: SORTING_TYPE.recurency,
          },
        }),
        cellRenderer: ({ record }: NotesTableRenderProps) => record.createdAt,
      },
      {
        id: NotesTableIDs.NOTE,
        onHeaderCell: () => ({
          tooltip: t('Sort by note'),
          ordering: {
            defaultOrder: QueryOrder.DESC,
            orderBy: NotesTableIDs.NOTE,
            sortingKey: SORTING_TYPE.alphabetical,
          },
        }),
        title: t('Note'),
        cellRenderer: ({ record }: NotesTableRenderProps) => <Ellipsis>{record.note}</Ellipsis>,
      },
      {
        id: NotesTableIDs.CREATED_BY,
        onHeaderCell: () => ({
          tooltip: t('Sort by creator'),
          ordering: {
            defaultOrder: QueryOrder.DESC,
            orderBy: NotesTableIDs.CREATED_BY,
            sortingKey: SORTING_TYPE.alphabetical,
          },
        }),
        title: t('Created By'),
        cellRenderer: ({ record }: NotesTableRenderProps) => record.user?.fullName,
      },
      {
        id: NotesTableIDs.KEYWORDS,
        title: t('Keywords'),
        onHeaderCell: () => ({
          tooltip: t('Sort by keywords'),
          ordering: {
            defaultOrder: QueryOrder.DESC,
            orderBy: NotesTableIDs.KEYWORDS,
            sortingKey: SORTING_TYPE.alphabetical,
          },
        }),

        cellRenderer: memo(KeywordsComponent, isEqual),
      },
    ];
    if (userHasWriteAccess) {
      cols.push({
        id: NotesTableIDs.ACTIONS,
        width: 76,
        cellRenderer: ({ record }: NotesTableRenderProps) => {
          const { snorlax4: iconColor, gray4: disabledIconColor } = styleVariables;
          return (
            <ActionsCell
              shouldUpdateIndicator={record}
              actions={[
                {
                  onSelect: () => onEdit(record.id),
                  label: isDemoDomain ? getDisabledDemoText() : t('Edit note'),
                  fastIcon: (
                    <AccFastIcon
                      src={IconEdit}
                      size={20}
                      color={isDemoDomain ? disabledIconColor : iconColor}
                    />
                  ),
                  disabled: isDemoDomain,
                },
                {
                  onSelect: () => onDelete(record.id),
                  label: isDemoDomain ? getDisabledDemoText() : t('Delete note'),
                  fastIcon: <AccFastIcon src={IconTrash} size={20} color={iconColor} />,
                  disabled: isDemoDomain,
                },
              ]}
            />
          );
        },
      });
    }
    return cols;
  }, [onDelete, onEdit, userHasWriteAccess]);
  return { columns };
};
