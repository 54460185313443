import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Flex } from '@mantine/core';
// eslint-disable-next-line import/no-unresolved
import { FallbackRender } from '@sentry/react/types/errorboundary';
import AccButton from 'Components/AccButton/AccButton';
import { initLanguage, t } from 'Utilities/i18n';
import Furious from './furious.svg?inline';
import '../error-page.scss';

const PageError = (errorData: Parameters<FallbackRender>[0]): React.ReactElement => {
  useEffect(() => {
    initLanguage();
  }, []);

  return (
    <div className="error-page">
      <h1 className="title">
        {t('Oops! Something went wrong')} {/*<img*/}
        <Furious width={'75px'} height={'75px'} />
      </h1>
      <p className="description">
        {t('This page didn\'t load correctly, please reload the page and try again.')}
      </p>
      <Flex direction="column">
        <AccButton
          variant="secondary"
          onClick={() => {
            errorData.resetError();
          }}
        >
          {t('Reset')}
        </AccButton>
        <Link to="/">
          <AccButton variant="primary">{t('Go to dashboard')}</AccButton>
        </Link>
      </Flex>
    </div>
  );
};

export default PageError;
