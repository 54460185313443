import { Component } from 'react';
import { FormattedNumber, injectIntl } from 'react-intl';
import uniqueId from 'lodash/uniqueId';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import {
  defaultBillionsSettings,
  defaultMillionsSettings,
  defaultThousandsSettings,
  formatNumberHelper,
} from './formatNumberHelper';

type Props = {
  className?: string;
  showTitle?: boolean;
  precision?: number;
  cutOffPrecision?: number;
  currency?: string;
  zeroAllowed?: boolean;
  percentage?: boolean;
  thousandsSettings?: {
    thousandsLabel: string;
    thousandsCutoff: number;
  };
  millionsSettings?: {
    millionsLabel: string;
    millionsCutoff: number;
  };
  billionsSettings?: {
    billionsLabel: string;
    billionsCutoff: number;
  };
  trimZeroes?: boolean;
  hasSpaceBetweenNumberAndLabel?: boolean;
  children: any;
  intl: any;
  isEmptyAllowed?: boolean;
  suffix?: string | null;
  isFinal?: boolean;
  customCurrency?: boolean;
};

class FormatNumber extends Component<Props> {
  static defaultProps = {
    showTitle: true,
    precision: 0,
    cutOffPrecision: 1,
    percentage: false,
    billionsSettings: defaultBillionsSettings,
    millionsSettings: defaultMillionsSettings,
    thousandsSettings: defaultThousandsSettings,
    hasSpaceBetweenNumberAndLabel: false,
    zeroAllowed: true,
    isEmptyAllowed: false,
  };
  id = uniqueId('formatnumber');

  shouldComponentUpdate(nextProps) {
    return nextProps !== this.props;
  }

  hasEmptyChildren = () => this.props.isEmptyAllowed && this.props.children === '';
  hasNonValidChildren = () =>
    typeof this.props.children !== 'number' && isNaN(Number(this.props.children));
  isZeroNonValidChildren = () =>
    (!this.props.zeroAllowed && this.props.children === 0) || this.props.children === '0';
  isCurrency = () => this.props.currency;

  renderFormattedValue() {
    const {
      showTitle,
      className,
      precision,
      cutOffPrecision,
      percentage,
      thousandsSettings,
      millionsSettings,
      billionsSettings,
      hasSpaceBetweenNumberAndLabel,
      intl,
      suffix,
      children,
    } = this.props;
    let cutoffValue = Number.MAX_VALUE;

    if (billionsSettings) {
      cutoffValue = billionsSettings.billionsCutoff;
    }

    if (millionsSettings) {
      cutoffValue = millionsSettings.millionsCutoff;
    }

    if (thousandsSettings) {
      cutoffValue = thousandsSettings.thousandsCutoff;
    }

    const val = formatNumberHelper({
      value: children,
      precision: children >= cutoffValue ? cutOffPrecision : precision,
      percentage,
      thousandsSettings,
      millionsSettings,
      billionsSettings,
      hasSpaceBetweenNumberAndLabel,
      intlFormatNumber: intl.formatNumber,
    });
    // Tooltip precision defaults to 2.
    // Except when number is an integer (eg. "1234.00") and no prefix is provided
    // (currencies are always displayed with two decimals).
    let tooltipPrecision = 2;

    if (Number.isInteger(children) && suffix === undefined && !this.isCurrency()) {
      tooltipPrecision = 0;
    }

    const rawVal = formatNumberHelper({
      value: children,
      precision: tooltipPrecision,
      percentage,
      hasSpaceBetweenNumberAndLabel,
      intlFormatNumber: intl.formatNumber,
    });
    const showTooltip = showTitle && children >= cutoffValue;
    return (
      <span className={className}>
        <AccTooltip
          tooltip={
            showTooltip ? (
              <>
                {rawVal}
                &thinsp;
                {suffix}
              </>
            ) : null
          }
          placement="top"
          disable={!showTooltip}
          old
        >
          <span id={this.id}>
            {val}
            &thinsp;
            {suffix}
          </span>
        </AccTooltip>
      </span>
    );
  }

  render() {
    if (this.hasEmptyChildren()) {
      return <span />;
    }

    if (this.hasNonValidChildren()) {
      return <span>-</span>;
    }

    if (this.isZeroNonValidChildren()) {
      return <span>-</span>;
    }

    if (this.isCurrency()) {
      // by default we show only 2 digits for currency, in some cases we need more accurate price
      // for example for price per keyword
      const currencyProps = this.props.customCurrency && {
        maximumFractionDigits: this.props.precision,
      };
      return (
        <FormattedNumber
          style="currency"
          value={this.props.children ?? 0}
          currency={this.props.currency}
          currencyDisplay="symbol"
          {...currencyProps}
        />
      );
    }

    return this.renderFormattedValue();
  }
}

export default injectIntl(FormatNumber);
