import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import { IconGripVertical } from '@tabler/icons-react';
import cn from 'classnames';
import noop from 'lodash/noop';
import { t } from 'Utilities/i18n';
import { TableSettingGroup, TableSettingOptionProps } from '../support/types';
import { TableSettingGroupCheckbox } from './TableCheckboxes';
import { TableSettingSubOption } from './TableSettingOption';
import styles from './tableSettings.module.scss';

const DragHandler = SortableHandle<{ className?: string; children: React.ReactNode }>(
  ({ className, children }) => <div className={className}>{children}</div>,
);

export type TableSettingOptionGroupProps = {
  item: TableSettingGroup;
} & TableSettingOptionProps;

export const TableSettingOptionGroup = (props: TableSettingOptionGroupProps) => {
  const { item } = props;

  const groupSelected = props.groupStatuses?.find((e) => e.id === item.id)?.enabled || item.fixed;
  const onGroupChange = () => {
    props.onGroupStatusChange(item.id, !groupSelected);
  };

  if (item?.hidden) return null;

  const visibleOptions = item.options?.filter((e) => !e?.hidden) ?? [];
  return (
    <div className={styles.container}>
      <DragHandler
        className={cn(styles.headerBlock, { [styles.headerBlockActive]: groupSelected })}
      >
        <div className={styles.headerTitle}>
          <div data-ignore-dnd={true}>
            <TableSettingGroupCheckbox
              onChange={item.fixed ? noop : onGroupChange}
              checked={groupSelected}
              disabledCheck={item.fixed}
            >
              <div
                className={cn(
                  styles.headerTitleText,
                  groupSelected && styles.headerTitleTextActive,
                )}
              >
                {item.label?.()}
              </div>
            </TableSettingGroupCheckbox>
          </div>
        </div>
        {item.fixed ? (
          <div className={styles.fixedHeaderControl}>{t('(can’t be reordered)')}</div>
        ) : (
          <div className={styles.headerControl}>{<IconGripVertical size={16} />}</div>
        )}
      </DragHandler>
      <div className={styles.bodyList}>
        {visibleOptions.map((e) => (
          <TableSettingSubOption
            item={e}
            key={e.id}
            indent={0}
            optionStatuses={props.optionStatuses}
            onOptionsStatusChange={props.onOptionsStatusChange}
            active={groupSelected}
          />
        ))}
      </div>
    </div>
  );
};
