import { Link } from 'react-router-dom';
import AccButton from 'Components/AccButton/AccButton';
import AccessControl from 'Components/AccessControl';
import { Field, Form } from 'Components/Fields';
import generateCreateUserFilterInput from 'Components/Filters/generateCreateUserFilterInput';
import { FormErrors } from 'Components/Forms/FormErrors';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import { useUpdateUserFilterMutation } from 'Ghql';
import toast from 'Hooks/useToast';
import { KEYWORDS_FILTER_SET, isRequiredFilter } from 'Types/FilterSet';
import type { FilterSet } from 'Types/FilterSet';
import { throwNetworkError, throwSubmitErrors } from 'Utilities/errors';
import { t, tct } from 'Utilities/i18n';
import Validator from 'Utilities/validation';
import formStyle from 'css/layout/form-layout.module.scss';

type Props = {
  filterGroup: Record<string, any>;
  filterSet: FilterSet;
  onClose: (...args: Array<any>) => any;
  onEdit: (filterGroupId: string, filterGroupName: string, isAPIFilter: boolean) => void;
};

const EditFilterGroupForm = (props: Props) => {
  const { filterGroup, filterSet, onClose, onEdit } = props;
  const [updateUserFilter] = useUpdateUserFilterMutation();

  const handleSubmit = ({ name, isAPIFilter: apiFilter }) => {
    const updateUserFilterInput = generateCreateUserFilterInput(
      name,
      filterGroup.filters.filter((filter) => !isRequiredFilter(filter.attribute, filterSet)),
      filterSet,
      {
        defaultForDomains: filterGroup.defaultForDomains || false,
        defaultForKeywords: filterGroup.defaultForKeywords || [],
        apiFilter,
      },
    );

    return updateUserFilter({
      variables: {
        input: {
          id: filterGroup.id,
          ...updateUserFilterInput,
        },
      },
    })
      .then((res) => {
        const { errors } = res?.data?.updateFilter || {};
        if (errors && errors.length) {
          const filteredErrors = errors.filter((error) => error !== null);
          throwSubmitErrors(filteredErrors as any);
        }
        onEdit(filterGroup.id, name, apiFilter);
        onClose();
      }, throwNetworkError)
      .catch((error) => {
        toast.error(t('Unable to edit segment'));
        throw error;
      });
  };
  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{
        name: filterGroup.name,
        isAPIFilter: filterGroup.apiFilter,
      }}
    >
      {({ submitting, invalid, submitErrors }) => (
        <>
          <FormErrors errors={submitErrors} />
          <div className={formStyle.formRow}>
            <div className="form-label required">{t('Segment name')}</div>
            <Field.TextInput
              name="name"
              placeholder={t('Segment name')}
              validate={[Validator.required]}
            />
          </div>
          <AccessControl>
            {filterSet === KEYWORDS_FILTER_SET && (
              <div className={formStyle.formRow}>
                <Field.Checkbox
                  name="isAPIFilter"
                  helpTextPopover={tct(
                    'Mark this segment to be used in API as API filter. See more details [link:here].',
                    {
                      link: <Link to="/integrations/api" />,
                    },
                  )}
                  label={t('API filter')}
                />
              </div>
            )}
          </AccessControl>
          <ModalFooter
            primaryButtonSlot={
              <AccButton
                disabled={invalid || submitting}
                type="submit"
                variant="primary"
                loading={submitting}
              >
                {t('Save')}
              </AccButton>
            }
            secondaryButtonSlot={
              <AccButton variant="tertiary" onClick={onClose}>
                {t('Cancel')}
              </AccButton>
            }
          />
        </>
      )}
    </Form>
  );
};

export default EditFilterGroupForm;
