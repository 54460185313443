import { useState } from 'react';
import Breadcrumbs from 'Components/Breadcrumbs/Breadcrumbs';
import { useModal } from 'Hooks/base/useModal';
import * as Actions from 'Pages/Layout/ActionsMenu/Actions';
import ActionbarContainer from 'Pages/Layout/ActionsMenu/components/ActionbarContainer';
import { t } from 'Utilities/i18n';
import reusableStyles from 'css/reusable-styles.module.scss';
import ConnectedAccountsTable from './ConnectedAccountsTable';

const ConnectedAccounts = () => {
  const [dataKey, setDataKey] = useState<number>(0);

  const handleUpdateTable = () => {
    setDataKey((prevKey) => prevKey + 1);
  };

  const { showModal } = useModal();
  const handleAdd = () => {
    showModal({
      modalType: 'AddAccount',
      modalTheme: 'light',
      modalProps: {
        refresh: () => handleUpdateTable(),
      },
    });
  };

  return (
    <>
      <Breadcrumbs />
      <ActionbarContainer mt="md">
        <Actions.AddAction onClick={handleAdd} label={t('Connect integration')} />
      </ActionbarContainer>
      <div className={reusableStyles.paper}>
        <ConnectedAccountsTable
          showModal={showModal}
          handleUpdateTable={handleUpdateTable}
          dataKey={dataKey}
        />
      </div>
    </>
  );
};

export default ConnectedAccounts;
