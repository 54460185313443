export const NotificationOverviewColumnId = {
  DOMAIN: 'domain',
  KEYWORD: 'keyword',
  SEARCH_ENGINE: 'search_engine',
  LOCATION: 'location',
  RANK: 'rank',
  RANK_CHANGE: 'rank_change',
  URL: 'url',
  TIME_AGO: 'time_ago',
};
