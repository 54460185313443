import { useState } from 'react';
import { FormSpy } from 'react-final-form';
import { Flex } from '@mantine/core';
import AccButton from 'Components/AccButton/AccButton';
import { Field, Form } from 'Components/Fields';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import AccText from 'Components/Text/AccText';
import { t, tct } from 'Utilities/i18n';
import Validation from 'Utilities/validation';
import { NOTE_KEYWORD_ORDER } from '../../EditNote/EditNoteForm';
import './add-note-form.scss';

type Props = {
  loading: boolean;
  onSubmit: (...args) => void;
  isAllSelected?: boolean;
  keywords: number[];
  enableAddingKeywords?: boolean;
  initialValues?: object;
  onCancel?: (...args) => void;
};

export default function AddNoteForm(props: Props) {
  const { loading, onSubmit: handleSubmit, isAllSelected, keywords, enableAddingKeywords } = props;

  const [isAllSelectedLocal, setIsAllSelectedLocal] = useState(isAllSelected || false);

  return (
    <Form onSubmit={handleSubmit} formClassName="add-note-form">
      {() => {
        return (
          <>
            <Field.TextAreaInput
              key="note"
              name="note"
              label={t('Note')}
              required
              placeholder={t('Enter note')}
              validate={Validation.required}
              autoFocus
            />
            <Field.DateInput
              key="createdAt"
              name="createdAt"
              label={t('Date')}
              initialValue={new Date()}
            />
            {enableAddingKeywords && (
              <Flex direction="column" rowGap="md">
                <Field.Checkbox
                  key="forAllKeywords"
                  name="forAllKeywords"
                  label={t('Add for all keywords')}
                  defaultChecked={isAllSelectedLocal}
                />
                <FormSpy
                  subscription={{ values: true }}
                  onChange={({ values }) => setIsAllSelectedLocal(!!values.forAllKeywords)}
                />
                <Field.KeywordsInput
                  name="keywords"
                  label={!isAllSelectedLocal ? t('Keywords') : t('Keywords to exclude')}
                  ordering={NOTE_KEYWORD_ORDER}
                  fullWidth
                />
              </Flex>
            )}
            <ModalFooter
              primaryButtonSlot={
                <AccButton type="submit" variant="primary" loading={loading} disabled={loading}>
                  {t('Add note')}
                </AccButton>
              }
              secondaryButtonSlot={
                <AccButton variant="tertiary" onClick={props.onCancel}>
                  {t('Cancel')}
                </AccButton>
              }
              textSlot={
                enableAddingKeywords ? (
                  <></>
                ) : (
                  <AccText variant="label">
                    <div key="label">
                      {!isAllSelectedLocal
                        ? tct('The note will reference [amount] keywords.', {
                            amount: keywords.length,
                          })
                        : t('The note will reference all keywords')}
                    </div>
                  </AccText>
                )
              }
            />
          </>
        );
      }}
    </Form>
  );
}
