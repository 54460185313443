import type { ViewMode } from 'Types/Table';

export const RESET_TABLE = 'reset_table';
export const CHANGE_NUMBER_OF_ROWS = 'change_number_of_rows';
export const CHANGE_PAGE = 'change_page';
export const CHANGE_SORTING = 'change_sorting';
export const SET_TABLE_VIEW_MODE = 'set_table_view_mode';
type ChangeSortingPayload = {
  tableName: string;
  sortField: string;
  descDefault: boolean;
};
type ChangeRowsNumberPayload = {
  tableName: string;
  numberOfRows: number;
};

type ResetTablePayload = {
  tableName: string;
  numberOfRows: number;
  sortField: string;
  descDefault: boolean;
};
type SetViewModePayload = {
  tableName: string;
  viewMode: ViewMode;
};
type ChangeRowsNumber = {
  type: typeof CHANGE_NUMBER_OF_ROWS;
  payload: ChangeRowsNumberPayload;
};
type ResetTable = {
  type: typeof RESET_TABLE;
  payload: ResetTablePayload;
};
type ChangeSorting = {
  type: typeof CHANGE_SORTING;
  payload: ChangeSortingPayload;
};
type SetViewMode = {
  type: typeof SET_TABLE_VIEW_MODE;
  payload: SetViewModePayload;
};
export type Action =
  | ChangeRowsNumber
  | ChangeSorting
  | ResetTable
  | SetViewMode
  | { payload: any; type: any };

export function setTableViewMode(tableName: string, viewMode: ViewMode) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_TABLE_VIEW_MODE,
      payload: {
        tableName,
        viewMode,
      },
    });

    try {
      localStorage.setItem('table', JSON.stringify(getState().table));
    } catch (e) {
      console.error('Failed to set table viewMode', tableName, viewMode);
    }
  };
}
