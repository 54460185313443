import React, { useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import { Placement } from '@floating-ui/dom';

interface UseTooltipProps {
  placement: Placement;
  disable?: boolean;
  extraConfig?: any;
}

export const useTooltip = ({ placement, disable, extraConfig }: UseTooltipProps) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const [isMount, setIsMount] = React.useState<boolean>(false);
  const timeoutRef = React.useRef<any | null>(null);
  // Note: the usePopper hook intentionally takes the DOM node, not refs,
  // in order to be able to update when the nodes change.
  // A callback ref is used here to permit this behaviour, and useState is an appropriate way to implement this.
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const { styles, attributes, forceUpdate } = usePopper(referenceElement, popperElement, {
    placement,
    ...extraConfig,
  });

  const clearVisibilityTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };
  const setVisibility = (nextVisible: boolean) => () => {
    clearVisibilityTimeout();
    setIsMount(nextVisible);
    setTimeout(() => {
      setVisible(nextVisible);
    }, 10);
  };

  // Hiding tooltip on scroll, since it has high index-z and can be shown on top of other elements
  // https://accuranker.myjetbrains.com/youtrack/issue/ARR-1325
  useEffect(() => {
    const handleScroll = () => {
      if (visible) {
        setVisible(false);
      }
    };
    if (visible && referenceElement) {
      window.addEventListener('scroll', handleScroll);
    } else {
      window.removeEventListener('scroll', handleScroll);
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [visible, referenceElement]);

  useEffect(() => {
    forceUpdate && forceUpdate();
    return clearVisibilityTimeout;
  }, [referenceElement, popperElement, visible]);

  const showTooltip = !disable;
  const childProps = {
    ref: setReferenceElement,
    onMouseEnter: setVisibility(true),
    onMouseLeave: setVisibility(false),
  };
  const tooltipProps = {
    ref: setPopperElement as any,
    style: styles.popper,
    ...attributes.popper,
  };

  return { childProps, tooltipProps, isMount, showTooltip, visible };
};
