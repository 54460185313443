import { useEffect, useState } from 'react';
import { useField } from 'react-final-form';
import { useFieldArray } from 'react-final-form-arrays';
import { captureException } from '@sentry/react';
import {
  CountryLocalesQuery,
  DomainInfoAddKeywordsQuery,
  useAddKeywordsFormAccountUsageUserQuery,
  useAddKeywordsFormKeywordOveruseEnabledQuery,
  useAddKeywordsFormSimpleLogMutation,
  useCountryLocalesQuery,
  useDomainInfoAddKeywordsQuery,
} from 'Ghql';
import { useAccountMaxKeywords } from 'Hooks/useMaxKeywords';
import { AddKeywordsModalType, AddKeywordsMode, FieldName, MappedSearchEngine } from './types';
import { getAddKeywordsCountInfo } from './utils';

export const useAddKeywordsFields = () => {
  const engines = useFieldArray<MappedSearchEngine>(FieldName.ENGINES);
  const keywords = useField<string[]>(FieldName.KEYWORDS);
  const tags = useField(FieldName.TAGS);
  return { engines, keywords, tags };
};

export const useCountryLocales = () => {
  const { data, loading } = useCountryLocalesQuery();
  if (loading || !data || !data.countrylocales) {
    return null;
  }
  type CountryLocaleNode = NonNullable<NonNullable<CountryLocalesQuery['countrylocales']>[number]>; // Fixing overly nullable generated type
  return data.countrylocales as CountryLocaleNode[];
};

/** Returns the number of keywords that will be added, not taking into account the selected engines and locales, but only the raw count of keywords (or the supplied count of keywords if keydis import) */
export const useKeywordRawCount = (mode: AddKeywordsModalType, keydisKeywordsCounter?: number) => {
  const fields = useAddKeywordsFields();
  const keywordsCount = fields.keywords.input.value.length;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return mode === AddKeywordsMode.IMPORT ? keydisKeywordsCounter! : keywordsCount;
};

/** Returns the number of keywords that will be added and a description of the count, taking into account the selected engines and locales */
export const useKeywordCountInfo = (keywordsCount: number) => {
  const fields = useAddKeywordsFields();
  const locales = useCountryLocales();
  return getAddKeywordsCountInfo(fields.engines.fields.value, keywordsCount, locales);
};

const useAccountCurrentKeywords = () => {
  const { data, refetch } = useAddKeywordsFormAccountUsageUserQuery();

  const accountCurrentKeywords = data?.user?.organization?.numberOfKeywords ?? null;

  return { accountCurrentKeywords, refetch };
};

const useKeywordOveruseEnabled = () => {
  const { data, refetch } = useAddKeywordsFormKeywordOveruseEnabledQuery();
  const keywordOveruseEnabled = data?.user?.organization?.keywordOveruseEnabled ?? null;
  return { keywordOveruseEnabled, refetch };
};

export const useKeywordOveruse = (keywordsRawCount: number) => {
  const { keywordsCounter } = useKeywordCountInfo(keywordsRawCount);
  const { accountCurrentKeywords, refetch: refetchAccountCurrentKeywords } =
    useAccountCurrentKeywords();
  const { keywordOveruseEnabled, refetch: refetchKeywordOveruseEnabled } =
    useKeywordOveruseEnabled();
  const refetch = () => {
    refetchAccountCurrentKeywords();
    refetchKeywordOveruseEnabled();
  };

  const maxKeywords = useAccountMaxKeywords();

  const [keywordsOveruseLogged, setKeywordsOveruseLogged] = useState(false);

  const [addKeywordsFormSimpleLogMutation] = useAddKeywordsFormSimpleLogMutation({
    variables: {
      input: {
        action: 'NO_MORE_KEYWORDS_ALLOWED_ON_PLAN',
        value: 1,
      },
    },
  });

  let difference: number | null;
  if (maxKeywords === null || accountCurrentKeywords === null) {
    // Loading
    difference = null;
  } else {
    difference = maxKeywords - (accountCurrentKeywords + keywordsCounter);
  }

  // Adds a simpleLog entry the first time an organization goes into overuse
  useEffect(() => {
    if (difference === null) {
      // Loading
      return;
    }
    if (difference < 0 && !keywordsOveruseLogged && !keywordOveruseEnabled) {
      setKeywordsOveruseLogged(true);
      addKeywordsFormSimpleLogMutation().catch((err) => {
        captureException(err);
      });
    }
  }, [
    maxKeywords,
    keywordsCounter,
    accountCurrentKeywords,
    keywordsOveruseLogged,
    keywordOveruseEnabled,
    addKeywordsFormSimpleLogMutation,
    difference,
  ]);

  // Refetches accountCurrentKeywords and keywordOveruseEnabled every 30 seconds
  useEffect(() => {
    const delay = 30 * 1000; // 30 seconds
    const interval = setInterval(() => {
      refetch();
    }, delay);
    // clear interval on unMount
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { accountCurrentKeywords, keywordOveruseEnabled, maxKeywords, difference };
};

export const useShowSuggestionsState = () => {
  const [showSuggestions, setShowSuggestions] = useState(false);
  // eslint-disable-next-line react/no-unused-prop-types
  return { showSuggestions, setShowSuggestions };
};

type DomainInfo = NonNullable<DomainInfoAddKeywordsQuery['domain']>; // Fixing overly nullable generated type

export const useDomainInfo = (domainId: string) => {
  const { data, loading } = useDomainInfoAddKeywordsQuery({ variables: { domainId } });
  if (loading || !data) {
    return null;
  }
  return data.domain as DomainInfo;
};
