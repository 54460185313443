import { Box, Container, SimpleGrid, Stack } from '@mantine/core';
import FormatNumber from 'Components/FormatNumber/new';
import AccText from 'Components/Text/AccText';
import AccTitle from 'Components/Title/AccTitle';
import { CountByCountryNode } from 'Ghql';
import { useSpecificFilter } from 'Hooks';
import { FilterAttribute } from 'Types/Filter';
import { TableIDs } from 'Types/Table';
import { t } from 'Utilities/i18n';
import reusableStyles from 'css/reusable-styles.module.scss';
import ChilledTiger from 'icons/grump/chilled.svg?inline';
import keywordsInTable from '../../support/keywordsInTable.png';
import { ImportButton } from './importButton';

const FAKE_SELECTED_KEYWORDS = 244;

interface ImportKeywordsCardProps {
  selectedKeywords: number;
  availableKeywords: number;
  disabled: boolean;
  selectedCountry: CountByCountryNode | undefined;
  usePlaceholderData?: boolean;
  tableType: typeof TableIDs.KEYWORD_DISCOVERY | typeof TableIDs.RESEARCH;
  domainSlot: JSX.Element | undefined;
}

export const ImportKeywordsCard = ({
  selectedKeywords,
  availableKeywords,
  disabled,
  selectedCountry,
  usePlaceholderData,
  tableType,
  domainSlot,
}: ImportKeywordsCardProps) => {
  const freetextFilter = useSpecificFilter(FilterAttribute.FREE_TEXT_DOMAIN);

  const isFreeTextSearch = !!freetextFilter?.value;

  const isDisabled = !isFreeTextSearch && !usePlaceholderData && disabled;

  return (
    <Box
      className={reusableStyles.paperContainer}
      p="md"
      style={{ alignSelf: 'stretch', position: 'relative' }}
    >
      <Box
        m="md"
        style={{ position: 'absolute', inset: 0, overflow: 'hidden', pointerEvents: 'none' }}
      >
        <Container
          style={{
            position: 'absolute',
            scale: '500%',
            bottom: 0,
            right: 0,
            opacity: 0.07,
            filter: isDisabled ? 'grayscale(1)' : undefined,
            pointerEvents: 'none',
          }}
        >
          <ChilledTiger />
        </Container>
      </Box>
      <Stack gap={0} justify="space-between" h="100%">
        <AccTitle
          type="h3"
          helper={
            <>
              <AccTitle fw={600} type="h5">
                {t('Keyword Import')}
              </AccTitle>
              <AccText size="sm">
                {t(
                  'To import your desired keywords, simply mark them in the table below and then click on the "import keywords" button to import them to your domain.',
                )}
              </AccText>
              <img
                src={keywordsInTable}
                alt={t('screenshot of table listing keywords')}
                width={360}
              />
            </>
          }
        >
          {t('Keyword Import')}
        </AccTitle>
        <SimpleGrid cols={2} spacing={48} h="40%" style={{ alignItems: 'end', flexShrink: 0 }}>
          <Stack gap={0} miw={180}>
            <AccText size="44px" fw={600} ta="center" style={{ lineHeight: 1 }}>
              <FormatNumber
                value={usePlaceholderData ? FAKE_SELECTED_KEYWORDS : selectedKeywords || 0}
              />
            </AccText>
            <AccText ta="center" size="sm" fw={700}>
              {t('Selected keywords')}
            </AccText>
          </Stack>
          <Stack gap={0} miw={180}>
            <AccText size="44px" fw={600} ta="center" style={{ lineHeight: 1 }}>
              <FormatNumber value={availableKeywords || 0} />
            </AccText>
            <AccText ta="center" size="sm" fw={700}>
              {t('Available keywords')}
            </AccText>
          </Stack>
        </SimpleGrid>
        <Stack justify="flex-start" mih="25%" style={{ flexShrink: 0 }} gap={0} pb={8}>
          <Stack justify="center" align="center" mih="25%" style={{ flexShrink: 0 }} mb={8}>
            <ImportButton
              selectedKeywords={selectedKeywords}
              isDisabled={isDisabled}
              selectedCountry={selectedCountry}
              tableType={tableType}
            />
          </Stack>
          {!!domainSlot && (
            <>
              <AccText ta="center" size="sm" fw={500}>
                {t('to')}
              </AccText>
              <Stack justify="center" align="center">
                {domainSlot}
              </Stack>
            </>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};
