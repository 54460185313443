import { gql } from '@apollo/client';

export const removeAdobeAccountQuery = gql`
  mutation editDomainForm_removeAdobeAnalyticsAccount($input: RemoveAdobeMarketingAccountInput!) {
    removeAdobeMarketingAccount(input: $input) {
      domain {
        id
      }
    }
  }
`;
export const removeGoogleAccountQuery = gql`
  mutation editDomainForm_removeGoogleAnalyticsAccount($input: RemoveGoogleAnalyticsAccountInput!) {
    removeGoogleAnalyticsAccount(input: $input) {
      domain {
        id
      }
    }
  }
`;
export const removeGSCAccountQuery = gql`
  mutation editDomainForm_removeGoogleSearchConsoleAccount(
    $input: RemoveGoogleSearchConsoleAccountInput!
  ) {
    removeGoogleSearchConsoleAccount(input: $input) {
      domain {
        id
      }
    }
  }
`;
