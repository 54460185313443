import ErrorBoundary from 'Components/ErrorBoundary';
import { useDiscoveredKeywordsQuery } from 'Ghql';
import { useFilters } from 'Hooks';
import { useIsGroupDomains } from 'Hooks/data/domain/useQueryDomainInfo';
import KpiBox, { KpiBoxSizeProps } from 'Pages/Keywords/Overview/components/KpiBar/KpiBox';
import { KPI_NAMES } from 'Pages/Keywords/Overview/components/KpiBar/index';
import { t } from 'Utilities/i18n';
import { KpiBarQueryProps } from './useKpiBarQueryWithDisplayCurrency';

const KeywordsCountKpiBox = ({
  size,
  kpiBarQueryProps,
  draggable,
  hideProgressbar,
}: KpiBoxSizeProps & { kpiBarQueryProps: KpiBarQueryProps } & { hideProgressbar?: boolean }) => {
  const { displayCurrency, data, loading } = kpiBarQueryProps;
  const filters = useFilters();

  const isGroupView = useIsGroupDomains();

  const { data: discoveredKeywordsData, loading: loadingDiscovered } = useDiscoveredKeywordsQuery({
    variables: {
      filters,
    },
    skip: hideProgressbar,
  });

  return (
    <ErrorBoundary>
      <KpiBox
        size={size}
        id={KPI_NAMES.KEYWORDS}
        title={t('Keywords')}
        loading={loading || !displayCurrency}
        value={loading ? 0 : data?.kpis?.allKpis?.keywords ?? undefined}
        beforeValue={loading ? 0 : data?.kpis?.allKpis?.keywordsBefore ?? undefined}
        precision={0}
        draggable={draggable}
        isKeywords={true}
        maxValue={
          loadingDiscovered || loading || isGroupView
            ? undefined
            : (discoveredKeywordsData?.keydisKpis?.discoveredKeywords || 0) +
              (data?.kpis?.allKpis?.keywords || 0)
        }
      />
    </ErrorBoundary>
  );
};

export default KeywordsCountKpiBox;
