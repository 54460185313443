import Cell from 'Components/AccuTable/CellRenderer/HelperComponents/AccuCell';
import ValueIndicator from 'Components/ValueIndicator/value-indicator';
import { KeywordRankWithCompetitorRankNode } from 'Ghql';
import { t } from 'Utilities/i18n';

type Props = { record: KeywordRankWithCompetitorRankNode; rankKey: string; rankCompareKey: string };

const CompetitorRankChangeCell: React.FC<React.PropsWithChildren<Props>> = ({
  record,
  rankKey,
  rankCompareKey,
}: Props) => {
  const rankValue = record?.[rankKey];
  const rankCompare = record?.[rankCompareKey];
  return (
    <Cell className="accu-table-cell__rank-change u-flex u-items-center">
      <ValueIndicator
        negate={true}
        beforeValue={rankCompare}
        afterValue={rankValue}
        noCompareString={t('Not in top 100')}
      />
    </Cell>
  );
};

export const createRankChangeCell = (config: {
  rankKey: string;
  rankCompareKey: string;
}): React.FC<React.PropsWithChildren<Props>> => {
  const result = (props: Props) => {
    return (
      <CompetitorRankChangeCell
        {...props}
        rankKey={config.rankKey}
        rankCompareKey={config.rankCompareKey}
      />
    );
  };
  result.displayName = 'createRankChangeCell';
  return result;
};
