export const areIllegalSiblings = (node1, node2) => {
  // trim names to avoid false positives due to whitespace
  const name1 = node1.name.trim();
  const name2 = node2.name.trim();
  // Nodes are illegal siblings if they have the same name and one of them is a folder, or if they are both dynamic or both static tags
  return (
    name1 === name2 &&
    (node1.isFolder || node2.isFolder || Boolean(node1.isDynamic) === Boolean(node2.isDynamic)) &&
    node1.id !== node2.id
  );
};
