import { useSubDomainsListQuery } from 'Ghql';
import { useDomainId, useDomainIds } from 'Hooks/data/domain/useQueryDomainInfo';
import { EMPTY_FILTER_SET } from '../../../../../Types/FilterSet';
import { linkToPageWithDomains } from '../../../LinkToDomain';
import { NO_SUB_DOMAIN_NAME } from '../constants';

export const useSubDomainFilterData = (cached: boolean = false) => {
  const domainId = useDomainId();
  const { loading, data } = useSubDomainsListQuery({
    fetchPolicy: cached ? 'cache-only' : undefined,
    variables: {
      ids: domainId ? (Array.isArray(domainId) ? domainId : [domainId]) : [],
    },
  });
  const subDomainDisabled = data?.domainsList?.[0]?.subdomains?.[0] === NO_SUB_DOMAIN_NAME;
  return { data, loading, subDomainDisabled };
};

export const useSettingsPageLink = () => {
  const domainIds = useDomainIds() || [];
  return linkToPageWithDomains('/settings', domainIds, EMPTY_FILTER_SET);
};
