import { useEffect, useState } from 'react';
import { useField } from 'react-final-form';
import { Box, Flex } from '@mantine/core';
import AccSwitch from 'Components/AccSwitch/Switch';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import Checkbox from 'Components/Controls/Checkbox';
import AccTitle from 'Components/Title/AccTitle';
import { UnrankedWeightConstants } from 'Constants';
import { t } from 'Utilities/i18n';
import { FieldName, UnrankedWeightEnabled } from '..';
import {
  DisabledWeight,
  EnabledWeight,
  IncludeUnrankedHelperText,
  UnrankedWeightHelperText,
} from './utils';

export type AverageRankSettingsProps = {
  values: Record<FieldName, any>;
  domainUnrankedWeight: number | null;
  organizationUnrankedWeight: number | null;
};

export const AverageRankSettings = ({
  values,
  domainUnrankedWeight,
  organizationUnrankedWeight,
}: AverageRankSettingsProps) => {
  const unrankedWeightEnabled = values[FieldName.unrankedWeightEnabled];
  const isYes = unrankedWeightEnabled === UnrankedWeightEnabled.yes;
  const isAccountDefault = unrankedWeightEnabled === UnrankedWeightEnabled.accountDefault;
  const organizationUnrankedWeightEnabled = organizationUnrankedWeight !== null;
  const isEnabled = isYes || (organizationUnrankedWeightEnabled && isAccountDefault);

  const unrankedWeight = useField(FieldName.unrankedWeight);
  const fieldUnrankedWeightEnabled = useField(FieldName.unrankedWeightEnabled);

  const [unrankedWeightEnabledToggled, setUnrankedWeightEnabledToggled] = useState(
    !!domainUnrankedWeight,
  );
  const [unrankedWeightAccountDefaultChecked, setUnrankedWeightAccountDefaultChecked] = useState(
    domainUnrankedWeight === UnrankedWeightConstants.ACCOUNT_DEFAULT,
  );

  const updateWeight = unrankedWeight.input.onChange;

  useEffect(() => {
    if (!isYes) {
      // so form retains pristine state
      updateWeight(
        domainUnrankedWeight ? domainUnrankedWeight : UnrankedWeightConstants.DEFAULT_IF_DISABLED,
      );
    }
  }, [isYes, domainUnrankedWeight]);

  useEffect(() => {
    setUnrankedWeightAccountDefaultChecked(
      domainUnrankedWeight === UnrankedWeightConstants.ACCOUNT_DEFAULT,
    );
    setUnrankedWeightEnabledToggled(!!domainUnrankedWeight);
  }, [domainUnrankedWeight]);

  const updateEnabled = fieldUnrankedWeightEnabled.input.onChange;

  useEffect(() => {
    if (unrankedWeightAccountDefaultChecked) {
      updateEnabled(UnrankedWeightEnabled.accountDefault);
      setUnrankedWeightEnabledToggled(organizationUnrankedWeightEnabled);
    } else {
      updateEnabled(
        unrankedWeightEnabledToggled ? UnrankedWeightEnabled.yes : UnrankedWeightEnabled.no,
      );
    }
  }, [
    unrankedWeightAccountDefaultChecked,
    unrankedWeightEnabledToggled,
    organizationUnrankedWeightEnabled,
  ]);

  return (
    <Flex direction="column" rowGap="sm">
      <AccTitle type="h5" helper={<IncludeUnrankedHelperText />} boxProps={{ mt: 'sm' }}>
        {t('Include "Not in top 100" rank keywords')}
      </AccTitle>
      <Flex columnGap="xl">
        <AccTooltip
          tooltip={unrankedWeightAccountDefaultChecked ? t('Controlled by account settings') : ''}
        >
          <Box w={70}>
            {/* tooltip doesn't seem to work on AccSwitch, so wrapped in Box */}
            <AccSwitch
              checked={unrankedWeightEnabledToggled}
              onChange={(event) => {
                setUnrankedWeightAccountDefaultChecked(false);
                setUnrankedWeightEnabledToggled(event.target.checked);
              }}
              label={isEnabled ? t('Yes') : t('No')}
              w="min-content"
            />
          </Box>
        </AccTooltip>

        <Checkbox
          checked={unrankedWeightAccountDefaultChecked}
          onChange={(event) => setUnrankedWeightAccountDefaultChecked(event.target.checked)}
        >
          {t('Use account settings')}
        </Checkbox>
      </Flex>

      <AccTitle type="h5" helper={<UnrankedWeightHelperText />} boxProps={{ mt: 'sm' }}>
        {t('Rank value of "Not in top 100" rank keywords')}
      </AccTitle>

      {isYes ? (
        <EnabledWeight />
      ) : (
        <DisabledWeight
          isEnabled={isEnabled}
          isAccountDefault={isAccountDefault}
          organizationUnrankedWeight={organizationUnrankedWeight}
        />
      )}
    </Flex>
  );
};
