import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import { Box } from '@mantine/core';
import reusableStyles from 'css/reusable-styles.module.scss';
import styles from './stickyScrollContainer.module.scss';

export const StickyScrollContainer = ({ children, width }) => (
  <ScrollSync horizontal>
    <Box className={reusableStyles.positionRelative}>
      <ScrollSyncPane>{children}</ScrollSyncPane>
      <ScrollSyncPane>
        <div className={styles.container}>
          <div style={{ width }}>&nbsp;</div>
        </div>
      </ScrollSyncPane>
    </Box>
  </ScrollSync>
);
