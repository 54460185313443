import NumberEditor, { NumberEditorProps } from 'Components/Filters/Common/NumberEditor';
import { numberLabelFunc } from 'Components/Filters/Common/labelFunc';
import {
  FilterAttribute,
  FilterBase,
  FilterComparison,
  FilterValueType,
  LocalPackRankFilter,
} from 'Types/Filter';
import { t } from 'Utilities/i18n/index';
// import NoFilterIcon from 'icons/less-than.svg?inline';
import RankIcon from 'icons/rank.svg?inline';

const defaultValue: LocalPackRankFilter = {
  attribute: FilterAttribute.LOCAL_PACK_RANK,
  type: FilterValueType.NUMBER,
  comparison: FilterComparison.LT,
  value: '' as any,
};

const noValueFilter = (filter: FilterBase) => {
  if (filter.comparison === FilterComparison.IS_NULL && filter.value) {
    return t('No value');
  }

  return t('Not a no-value filter');
};

const dynamicLabelFunc = (filter: FilterBase) => {
  const typeToLabelFunc = {
    [FilterValueType.NUMBER]: numberLabelFunc,
    [FilterValueType.BOOL]: noValueFilter,
  };

  return typeToLabelFunc[filter.type](filter);
};

const getData = (): { [key: string]: any; editorProps: NumberEditorProps } => ({
  defaultValue,
  title: t('Local Pack Rank'),
  icon: RankIcon,
  editor: NumberEditor,
  editorProps: {
    includeNoValueFilter: true,
  },
  tableEditor: NumberEditor,
  labelFunc: dynamicLabelFunc,
});

export default getData;
