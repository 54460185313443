import { v4 as uuid } from 'uuid';
import { t } from 'Utilities/i18n';
import { NodeLike } from '../../../../../Components/Table/TreeTable';
import { ROOT_ID } from './constants';
import { FolderStructureData, NestedTreeItem } from './types';

export const FOLDER_SEPARATOR = '¦';
const FOLDER_SEPARATOR_TO_SHOW = ' >> ';

export const replaceFolderSeparator = (folderName: string) =>
  folderName.replaceAll(FOLDER_SEPARATOR, FOLDER_SEPARATOR_TO_SHOW);

const sortGroupChildren = (a, b) => (a.isFolder ? (b.isFolder ? 0 : -1) : !b.isFolder ? 0 : 1);

export const getAddNodeItem = (folderName?: string): NestedTreeItem => {
  const resultFolderName = folderName ?? t('Add Folder');
  return {
    id: uuid(),
    name: resultFolderName,
    text: resultFolderName,
    isAdd: true,
    isFolder: 1,
    droppable: 1,
    children: [],
    parent: ROOT_ID,
    depth: 0,
  };
};

export const convertNormalizedToExternalNode = (node: NestedTreeItem): FolderStructureData => {
  return {
    text: node.text,
    droppable: node.droppable ? 1 : 0,
    id: node.id,
    isFolder: node.isFolder,
    is_dynamic: !!node.isDynamic,
    parent: node.parent,
    description: node.description,
  };
};

export const convertListToTree = (
  treeData: FolderStructureData[],
  initExpanded?: string[],
): NestedTreeItem[] => {
  const childrenTreeMap = treeData.reduce((treeMap, e) => {
    const parentKey = e.parent?.toString() ?? ROOT_ID?.toString();
    treeMap[parentKey] = [...(treeMap[parentKey] || []), e];
    return treeMap;
  }, {});
  const listToTree = (
    item: FolderStructureData,
    list: FolderStructureData[],
    depth: number,
    hiddenConnectors: string[],
    lastChild: boolean,
  ): NestedTreeItem => {
    const children = (item.isFolder && childrenTreeMap[item.id]) || [];

    const newHiddenConnectors =
      item.isFolder && lastChild ? [...hiddenConnectors, `hide${depth}`] : hiddenConnectors;

    const isExpanded = initExpanded?.includes(item.id);
    const resultItem: NestedTreeItem = {
      ...item,
      id: item.id,
      name: (item as NodeLike).name || item.text,
      state: isExpanded ? { expanded: true } : (item as NestedTreeItem).state,
      isDynamic: item.is_dynamic,
      depth,
    };

    resultItem.children =
      children
        ?.map((el, index) =>
          listToTree(el, list, depth + 1, newHiddenConnectors, index === children.length - 1),
        )
        .sort(sortGroupChildren) ?? [];

    return resultItem;
  };

  return (
    treeData
      ?.filter((e) => e.parent === ROOT_ID)
      ?.map((e) => listToTree(e, treeData, 0, [], false))
      .sort(sortGroupChildren) ?? []
  );
};

export const getChildrenFromTree = <T extends { children?: T[] }>(node: T) => {
  const stack = [node];
  let next = stack.pop();
  const result: T[] = [];
  while (next) {
    result.push(next);
    stack.push(...(next.children || []));
    next = stack.pop();
  }
  return result;
};

export const getIsTreeHasChildren = (node, targetId) => {
  return getChildrenFromTree(node).some((e) => e.id === targetId);
};

export function smoothScrollBy(distance: number, duration: number, checkIfBreak?: () => boolean) {
  if (!distance || !duration) {
    return () => {};
  }
  let animationId;
  let cancel = false;
  const element = document.documentElement;
  const start = element.scrollTop;
  const startTime = performance.now();

  function linearInterpolation(ta, b, c, d) {
    return c * (ta / d) + b;
  }

  function scrollStep(timestamp) {
    if (cancel) {
      return;
    } else if (checkIfBreak?.()) {
      cancel = true;
      return;
    }
    const currentTime = timestamp || performance.now();
    const elapsed = currentTime - startTime;
    const scrollAmount = linearInterpolation(elapsed, start, distance, duration);
    element.scrollTop = scrollAmount;

    if (elapsed < duration) {
      animationId = window.requestAnimationFrame(scrollStep);
    }
  }

  animationId = window.requestAnimationFrame(scrollStep);

  return function unsubscribe() {
    cancel = true;
    if (animationId) {
      window.cancelAnimationFrame(animationId);
    }
  };
}
