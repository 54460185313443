import { Link } from 'react-router-dom';
import Breadcrumbs from 'Components/Breadcrumbs/Breadcrumbs';
import { tct } from 'Utilities/i18n';
import reusableStyles from 'css/reusable-styles.module.scss';
import IntegrationAPIsTable from './IntegrationAPIsTable/index';
import './integration-api.scss';

const IntegrationApiPage = () => (
  <>
    <Breadcrumbs />
    <div className={reusableStyles.paperContainer}>
      <p className="alert alert-info">
        {tct(
          'API filters can be used to setup filters that you can apply on API call. You create an API filter by creating a normal keywords filter and then converting it to an API filter. Learn how to use API filters in the [link:API docs].',
          {
            link: <Link target="_blank" to={'/api#section/Using-filter'} />,
          },
        )}
      </p>
      <div className="table-container">
        <IntegrationAPIsTable />
      </div>
    </div>
  </>
);

export default IntegrationApiPage;
