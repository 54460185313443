import Cell from 'Components/AccuTable/CellRenderer/HelperComponents/AccuCell';
import FormatNumber from 'Components/FormatNumber';
import { useIsPercentage } from 'Hooks/data/domain/useQueryDomainInfo';

interface Props {
  record: {
    currentValuePercentage?: number | null;
    currentValue?: number | null;
  };
}

export const CombinedNumberCell: React.FC<React.PropsWithChildren<Props>> = ({ record }) => {
  const isPercentage = useIsPercentage();
  if (isPercentage) {
    if (record.currentValuePercentage) {
      return (
        <Cell rightAligned>
          <FormatNumber percentage precision={2}>
            {record.currentValuePercentage}
          </FormatNumber>
        </Cell>
      );
    }
    return <Cell rightAligned>{0}</Cell>;
  }

  return (
    <Cell rightAligned>
      <FormatNumber>{record.currentValue ?? 0}</FormatNumber>
    </Cell>
  );
};
