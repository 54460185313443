import { DatePicker, DateValue } from '@mantine/dates';
import moment from 'moment';
import type { FieldProps } from 'redux-form';
import { Fields } from 'redux-form';
import DropdownList from 'Components/Controls/DropdownList';
import FormField from 'Components/Forms/FormField';
import DatesPicker from 'Components/PeriodFilter/CompareToContent/DatesPicker';
import { useLanguage } from 'Hooks/data/domain/useQueryDomainInfo';
import { FilterComparison } from 'Types/Filter';
import { formatDate } from 'Utilities/format';
import { t } from 'Utilities/i18n/index';
import Validator from 'Utilities/validation';
import './date-filter-editor.scss';

const getComparisonOptions = () => [
  {
    id: FilterComparison.GT,
    value: FilterComparison.GT,
    label: t('After'),
  },
  {
    id: FilterComparison.LT,
    value: FilterComparison.LT,
    label: t('Before'),
  },
  {
    id: FilterComparison.EQ,
    value: FilterComparison.EQ,
    label: t('On'),
  },
  {
    id: FilterComparison.BETWEEN,
    value: FilterComparison.BETWEEN,
    label: t('Between'),
  },
];

type DateEditorProps = {
  value: FieldProps;
  comparison: FieldProps;
};

const DateEditor = (props: DateEditorProps) => {
  const handleComparisonChange = (newComparison: string) => {
    const { value, comparison } = props;

    if (newComparison === comparison.input.value) {
      return;
    }

    comparison.input.onChange(newComparison);

    if (newComparison === FilterComparison.BETWEEN) {
      value.input.onChange([formatDate(new Date()), formatDate(new Date())]);
    } else if (comparison.input.value === FilterComparison.BETWEEN) {
      value.input.onChange(formatDate(new Date()));
    }
  };

  const handleRangeChange = ({ to, from }) => {
    props.value.input.onChange([formatDate(from), formatDate(to)]);
  };

  const handleValueChange = (val: DateValue) => {
    props.value.input.onChange(formatDate(val));
  };

  const getDatePickerValue = () => {
    const resultValue = moment(props.value.input.value);
    return resultValue?.isValid() ? resultValue.toDate() : null;
  };

  const comparisonOptions = getComparisonOptions();

  const language = useLanguage();

  const { value, comparison } = props;

  const DateComponent = () => {
    if (comparison.input.value === FilterComparison.BETWEEN) {
      return (
        <DatesPicker
          value={{
            from: value.input.value[0],
            to: value.input.value[1],
          }}
          min={new Date(0)}
          max={
            new Date()
            /*TODO*/
          }
          onChange={handleRangeChange}
        />
      );
    }
    return (
      <DatePicker
        date={
          value.input.value instanceof Date
            ? value.input.value
            : new Date(formatDate(value.input.value))
        }
        maxDate={new Date()}
        value={getDatePickerValue()}
        onChange={handleValueChange}
        onDateChange={handleValueChange}
        locale={language || 'en'}
      />
    );
  };

  return (
    <div className="date-filter-editor">
      <FormField meta={comparison.meta}>
        <DropdownList
          items={comparisonOptions}
          value={comparison.input.value}
          onChange={handleComparisonChange}
        />
      </FormField>
      <FormField meta={value.meta}>
        <DateComponent />
      </FormField>
    </div>
  );
};

const DateEditorComponent = () => (
  <div>
    <Fields
      component={DateEditor}
      names={['comparison', 'value']}
      validate={{
        value: Validator.required,
      }}
    />
  </div>
);
DateEditorComponent.displayName = 'DateEditorComponent';

export default DateEditorComponent;
