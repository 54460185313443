/* eslint-disable import/no-unused-modules */
import { AccMultiSelect, AccSelect } from 'Components/AccSelect';
import CheckboxInput from 'Components/Controls/Checkbox';
import CheckBoxGroupInput from 'Components/Controls/CheckboxGroup';
import DatePicker from 'Components/Controls/DatePicker';
import DropdownList from 'Components/Controls/DropdownList';
import LocaleSelect from 'Components/Controls/Dropdowns/LocaleSelect';
import { FoldersInput } from 'Components/Controls/FolderInput';
import LocationInput from 'Components/Controls/LocationInput';
import { TagsInput } from 'Components/Controls/TagsInput';
import TextArea from 'Components/Controls/TextArea';
import TextInput from 'Components/Controls/TextInput';
import TextInputWithFilterCount from 'Components/Controls/TextInput/textInputWithFilterCount';
import UploadInput from 'Components/Controls/UploadInput';
import KeywordsInput from 'Components/Filters/Editors/KeywordsList/KeywordsInput';
import PhoneInput from 'Components/Forms/PhoneInput';
import { KeywordListSelect } from 'Components/KeywordListSelect';
import toFormField from './toFormField';

/**
 * @deprecated
 * Use react-final-form controls, instead of redux-form. Check: `/src/Components/Fields/index.ts`
 * We can continue using it for old forms, but should avoid creating new forms with these controls.
 */
export const PhoneField = toFormField(PhoneInput);
export const TextField = toFormField(TextInput);

export const TextFieldWithFilterCount = TextInputWithFilterCount; // Wrapped with to Form Field inside
export const UploadField = toFormField(UploadInput as any);
export const TextAreaField = toFormField(TextArea as any);
export const DropdownField = toFormField(DropdownList);
export const LocaleDropdownField = toFormField(LocaleSelect as any);
export const TagsField = toFormField(TagsInput);

export const FoldersField = toFormField(FoldersInput);
export const Checkbox = toFormField(CheckboxInput);
export const LocationField = toFormField(LocationInput);
export const DateField = toFormField(DatePicker);
export const CheckBoxGroup = toFormField(CheckBoxGroupInput as any);
export const Select = toFormField(AccSelect, { select: true });
export const KeywordsInputField = toFormField(KeywordsInput);
export const MultiSelectInputField = toFormField(AccMultiSelect);

export const KeywordsListSelectInputField = toFormField(KeywordListSelect);
