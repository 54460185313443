import { Box, Flex, Group, Stack } from '@mantine/core';
import Skeleton from 'Components/Skeleton';

const LabelSkeleton = ({ align }: { align: 'flex-start' | 'flex-end' }) => {
  return (
    <Stack gap={0} align={align}>
      <Skeleton
        linesConfig={[
          {
            type: 'text',
            options: {
              width: '100px',
              marginBottom: '10px',
              height: '18px',
            },
          },
        ]}
      />
      <Skeleton
        linesConfig={[
          {
            type: 'text',
            options: {
              width: '180px',
              height: '20px',
            },
          },
        ]}
      />
    </Stack>
  );
};
const KeywordsSkeleton = () => {
  return (
    <Skeleton
      linesConfig={[
        {
          type: 'text',
          options: {
            width: '65px',
            height: '18px',
            marginBottom: '4px',
          },
        },
        {
          type: 'text',
          options: {
            width: '65px',
            height: '14px',
          },
        },
      ]}
    />
  );
};

export const WinnerLoserHeaderSkeleton = () => {
  return (
    <Box py="lg">
      <Group justify="apart" mt="xxs">
        <LabelSkeleton align="flex-start" />
        <LabelSkeleton align="flex-end" />
      </Group>
      <Flex wrap="nowrap" align="center" gap="xs" mt="md">
        <KeywordsSkeleton />
        <Box w="100%">
          <Skeleton
            linesConfig={[
              {
                type: 'text',
                options: {
                  width: '100%',
                  height: '8px',
                },
              },
            ]}
          />
        </Box>
        <KeywordsSkeleton />
      </Flex>
    </Box>
  );
};
