import { useField } from 'react-final-form';
import { ActionIcon, Box, Checkbox, Divider, Group, SimpleGrid, Stack } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import noop from 'lodash/noop';
import { ColumnSetting } from 'Components/DataTable';
import { AccPopover } from 'Components/Popover/AccPopover';
import { t } from 'Utilities/i18n';

interface ColumnCheckboxGroupFieldProps {
  name: string;
  columns: ColumnSetting[];
  advancedMetricsColumns: ColumnSetting[];
  hasAnalytics?: boolean;
  hasAdvancedMetrics?: boolean;
}

function TableSettingCheckbox(
  isSelected: boolean,
  isIndeterminate: boolean,
  onItemChange: (id: any) => () => void,
  id,
  resultLabel,
  requiresAnalytics,
) {
  return (
    <Group wrap="nowrap" gap="xs" key={id}>
      <Checkbox
        checked={isSelected}
        indeterminate={isIndeterminate}
        onChange={onItemChange(id)}
        variant="outline"
      />
      <span onClick={onItemChange(id)}>
        {resultLabel} {requiresAnalytics && <strong>*&nbsp;</strong>}
      </span>
    </Group>
  );
}

export const ColumnCheckboxGroupField = ({
  name,
  columns,
  advancedMetricsColumns,
  hasAnalytics,
  hasAdvancedMetrics,
}: ColumnCheckboxGroupFieldProps) => {
  const { input } = useField(name);
  const isAllSelected = input?.value?.length === columns?.length;
  const isPartial = input?.value?.length > 0 && !isAllSelected;
  const getIsSelectedItem = (id: any) => input?.value?.includes(id);
  const onItemChange = (id: any) => () => {
    const isSelected = getIsSelectedItem(id);
    const groupIds = columns
      .filter((col: any) => col?.tableSetting?.grouping === id)
      .map((e: any) => e.id);
    const isIndeterminate = groupIds?.length
      ? isSelected && groupIds.some((colId) => !input.value?.includes(colId))
      : false;

    input.onChange(
      isSelected && !isIndeterminate
        ? input.value?.filter((e: any) => e !== id && !groupIds?.includes(e))
        : [...new Set([...(input.value || []), id, ...groupIds])],
    );
  };
  const toggleAllSelected = () => {
    input.onChange(isAllSelected ? [] : columns?.map((e: ColumnSetting) => e.id));
  };
  const showAnalyticsInfo =
    !hasAnalytics && columns.some((e) => e?.tableSetting?.requiresAnalytics);

  return (
    <div style={{ userSelect: 'none' }}>
      <label className="u-flex u-items-center">
        <Checkbox
          checked={isAllSelected}
          indeterminate={isPartial}
          onChange={toggleAllSelected}
          variant="outline"
        />
        <Box ml="xs" className="acc-checkbox-label">
          {isAllSelected ? t('Uncheck all') : t('Check all')}
        </Box>
      </label>
      <Divider my="sm" color="gray.1" />
      <SimpleGrid cols={3} spacing="xxs" verticalSpacing="sm">
        {columns.map(
          ({ tableSetting: { requiresAnalytics, label, getLabel, grouping }, id }: any) => {
            if (grouping) {
              return null;
            }
            const resultLabel = getLabel?.() ?? label;
            const isSelected = input.value?.includes(id);

            const grouped = columns.filter((e: any) => e?.tableSetting?.grouping === id);
            const isIndeterminate = grouped?.length
              ? isSelected && grouped.some(({ id: colId }) => !input.value?.includes(colId))
              : false;

            return (
              <div className="checkbox-element" key={id}>
                {TableSettingCheckbox(
                  isSelected,
                  isIndeterminate,
                  onItemChange,
                  id,
                  resultLabel,
                  requiresAnalytics,
                )}
                {isSelected && grouped?.length > 0 && (
                  <AccPopover
                    width={220}
                    body={{ p: 10 }}
                    target={
                      <ActionIcon variant="subtle" size={'xs'} className={'more-btn'}>
                        <IconPlus style={{ width: '12px', height: '12px' }} />
                      </ActionIcon>
                    }
                    targetToggle={true}
                  >
                    <Stack p={'sm'}>
                      {grouped.map(
                        ({
                          tableSetting: {
                            requiresAnalytics: groupedColRequireAnalytics,
                            label: groupedColLabel,
                            getLabel: groupedColGetLabel,
                          },
                          id: colId,
                        }: any) => {
                          const groupedResultLabel = groupedColGetLabel?.() ?? groupedColLabel;
                          const groupedIsSelected = input.value?.includes(colId);
                          return TableSettingCheckbox(
                            groupedIsSelected,
                            false,
                            onItemChange,
                            colId,
                            groupedResultLabel,
                            groupedColRequireAnalytics,
                          );
                        },
                      )}
                    </Stack>
                  </AccPopover>
                )}
              </div>
            );
          },
        )}
      </SimpleGrid>
      {showAnalyticsInfo && (
        <p>
          <strong>*&nbsp;</strong>
          {t('Analytics account required')}
        </p>
      )}
      {!hasAdvancedMetrics && !!advancedMetricsColumns?.length && (
        <div>
          <Divider my="sm" color="gray.1" />
          <p className="alert alert-warning">
            {t(
              'The following columns are included in any plan with advanced metrics, upgrade now to unlock these metrics.',
            )}
          </p>
          <SimpleGrid cols={3} spacing="xxs" verticalSpacing="sm">
            {advancedMetricsColumns?.map(({ id, tableSetting: { getLabel } }) => (
              <div className="checkbox-element" key={id}>
                <Checkbox checked={false} disabled onChange={noop} />
                <span>{getLabel?.()}</span>
              </div>
            ))}
          </SimpleGrid>
        </div>
      )}
    </div>
  );
};
