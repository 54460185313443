import { useDispatch, useSelector } from 'react-redux';
import { IconCircleCheck } from '@tabler/icons-react';
import { saveFilterGroup } from 'Actions/FilterAction';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import AccButton from 'Components/AccButton/AccButton';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import generateCreateUserFilterInput from 'Components/Filters/generateCreateUserFilterInput';
import { AccPopover } from 'Components/Popover/AccPopover';
import { useCreateUserFilterMutation } from 'Ghql';
import toast from 'Hooks/useToast';
import { NonRequiredFiltersSelector } from 'Selectors/FiltersSelector';
import { StoreType } from 'Types/Store';
import { TrackingKey } from 'Utilities/Analytics/mixpanel';
import { t } from 'Utilities/i18n/index';
import styleVariables from 'css/base/variables.module.scss';
import SaveFilterForm from './SaveFilterForm';
import styles from './saveFilterGroup.module.scss';

type Props = {
  compact?: boolean;
};

const SaveFilter = (props: Props) => {
  const dispatch = useDispatch();
  const { filters, filterSet, filterGroup, filterGroups } = useSelector((state: StoreType) => ({
    filters: NonRequiredFiltersSelector(state),
    filterSet: state.filter.filterSet,
    filterGroup: state.filter.filterGroup,
    filterGroups: state.filter.filterGroups,
  }));
  const [createUserFilter] = useCreateUserFilterMutation();
  const onSave =
    (close: () => void) =>
    ({ name }) => {
      const createUserFilterInput = generateCreateUserFilterInput(name, filters, filterSet);
      createUserFilter({
        variables: {
          input: createUserFilterInput,
        },
      }).then(
        (res) => {
          const { errors, filter } = res?.data?.createFilter || {};
          if (errors && errors.length) {
            toast.error(t('Failed to save segment'));
            return;
          }

          close();
          dispatch(
            saveFilterGroup({
              id: filter!.id,
              name: filter!.name,
              type: filter!.type,
              filters,
            }),
          );
        },
        () => {
          toast.error(t('Failed to save segment'));
        },
      );
    };

  return (
    <AccPopover
      targetToggle
      target={
        !props.compact ? (
          <AccButton
            className={styles.saveSegmentButton}
            variant="tertiary"
            trackingKey={TrackingKey.SaveSegment}
            leftSection={<IconCircleCheck size={20} />}
          >
            {t('Save segment')}
          </AccButton>
        ) : (
          <AccActionIcon variant="subtle" c={styleVariables.snorlax4}>
            {/* AccTooltip cannot wrap AccActionIcon as Tooltip prevents opening Popover */}
            <AccTooltip old tooltip={t('Save segment')}>
              <IconCircleCheck size={20} />
            </AccTooltip>
          </AccActionIcon>
        )
      }
      withArrow={false}
      body={{ p: 0 }}
      withPortal
      trapFocus
      position="bottom-start"
    >
      {({ closePopover }) => (
        <SaveFilterForm
          filterGroup={filterGroup}
          filterGroups={filterGroups}
          onSubmit={onSave(closePopover)}
          onCancel={closePopover}
        />
      )}
    </AccPopover>
  );
};

export default SaveFilter;
