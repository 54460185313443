import { useEffect, useMemo } from 'react';
import { Box } from '@mantine/core';
import type { FieldProps } from 'redux-form';
import DropdownList from 'Components/Controls/DropdownList';
import TextInput from 'Components/Controls/TextInput';
import FormField from 'Components/Forms/FormField';
import useFilterCount from 'Hooks/useFilterCount';
import { FilterComparison, FilterValueType, NO_FILTER } from 'Types/Filter';
import { t } from 'Utilities/i18n/index';
import BetweenIcon from 'icons/arrow-both-sides.svg?inline';
import EqualIcon from 'icons/equal.svg?inline';
import GreaterThanIcon from 'icons/greater-than.svg?inline';
import LessThanIcon from 'icons/less-than.svg?inline';
import FilterCount from '../FilterCount';
import '../common.scss';

type ReduxFormFieldNamesProps = {
  attribute: FieldProps;
  type: FieldProps;
  value: FieldProps;
  comparison: FieldProps;
};

export type InputFieldProps = {
  iconDropdown?: boolean;
  noFilterIcon?: Element;
  handleSelect?: (...args: Array<any>) => any;
  autoFocus?: boolean;
  isEmpty?: boolean;
  withinPortal?: boolean;
  includeNoValueFilter?: boolean;
  isDecimal?: boolean;
};

type Props = ReduxFormFieldNamesProps & InputFieldProps;

const InputField = (props: Props) => {
  const {
    attribute,
    type,
    value,
    comparison,
    iconDropdown,
    noFilterIcon,
    handleSelect,
    autoFocus,
    isEmpty,
    withinPortal,
    includeNoValueFilter,
    isDecimal,
  } = props;

  useEffect(() => {
    if (value.input.value === '') {
      value.input.onChange(0);
    }
  }, [value.input.value]);

  const comparisonOptions = useMemo(() => {
    const items: any = [
      {
        id: FilterComparison.EQ,
        label: t('Equal ='),
        icon: EqualIcon,
      },
      {
        id: FilterComparison.LT,
        label: t('Less than <'),
        icon: LessThanIcon,
      },
      {
        id: FilterComparison.GT,
        label: t('Greater than >'),
        icon: GreaterThanIcon,
      },
      {
        id: FilterComparison.BETWEEN,
        label: t('Between'),
        icon: BetweenIcon,
      },
    ];

    if (iconDropdown) {
      items.push({
        id: NO_FILTER,
        label: t('No filter'),
        icon: noFilterIcon,
      });
    }

    if (includeNoValueFilter) {
      items.push({
        id: FilterComparison.IS_NULL,
        label: t('No value'),
      });
    }

    return items.map((e) => ({ ...e, value: e.id }));
  }, [iconDropdown, noFilterIcon, includeNoValueFilter]);

  const count = useFilterCount({
    attribute: attribute.input.value,
    value: value.input.value,
    type: type.input.value,
    comparison: comparison.input.value,
  });

  const onComparisonChange = (newComparison: string) => {
    let actualValue = value.input.value;

    if (newComparison === comparison.input.value) {
      return;
    }

    comparison.input.onChange(newComparison);

    type.input.onChange(
      newComparison === FilterComparison.IS_NULL ? FilterValueType.BOOL : FilterValueType.NUMBER,
    );

    actualValue = 0;

    if (newComparison === FilterComparison.IS_NULL) {
      // if we change to IS_NULL filter, set default value
      actualValue = true;
    }

    if (newComparison === FilterComparison.BETWEEN) {
      // if we change to between filter, set default value
      actualValue = [0, 0];
    }

    value.input.onChange(actualValue);

    // to submit proper filter after redux form state updated
    setTimeout(() => {
      if (handleSelect) {
        handleSelect({
          value: newComparison,
          reset: newComparison === NO_FILTER,
        });

        if (newComparison !== NO_FILTER) {
          handleSelect({
            value: actualValue,
          });
        }
      }
    });
  };

  const labelFunc = (item: any) => {
    if (iconDropdown) {
      const Icon = !isEmpty ? item.icon : noFilterIcon;
      return <Icon className={`icon ${isEmpty ? 'no-filter' : ''}`} />;
    }

    return item.label || item;
  };
  const onValueChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const val = event.currentTarget.value;
    if (val === '') {
      value.input.onChange('');
      return;
    }
    const numberValue = isDecimal ? parseFloat(val) : parseInt(val, 10);
    value.input.onChange(isNaN(numberValue) ? '' : numberValue);
  };
  const onRangeChange = (index: number) => {
    return (event: React.SyntheticEvent<HTMLInputElement>) => {
      const val = event.currentTarget.value;
      const numberValue = isDecimal ? parseFloat(val) : parseInt(val, 10);
      const newValue = [...value.input.value];
      newValue[index] = isNaN(numberValue) ? '' : numberValue;
      value.input.onChange(newValue);
    };
  };

  let content: any = null;
  const hasError = value.meta.error && value.meta.touched;

  if (comparison.input.value === FilterComparison.IS_NULL) {
    content = null;
  } else if (comparison.input.value === FilterComparison.BETWEEN) {
    const tupleValue: [number, number] = value.input.value;
    content = [
      <TextInput
        key="from"
        showError={(tupleValue[0] as any) === '' || tupleValue[0] > tupleValue[1]}
        autoFocus={autoFocus}
        value={tupleValue[0]}
        onChange={onRangeChange(0)}
        isNumber
        isPositive
      />,
      <TextInput
        key="to"
        value={tupleValue[1]}
        onChange={onRangeChange(1)}
        showError={tupleValue[1] === ('' as any) || tupleValue[0] > tupleValue[1]}
        isNumber
        isPositive
      />,
    ];
  } else {
    content = (
      <TextInput
        autoFocus={autoFocus}
        value={value.input.value}
        onChange={onValueChange}
        isNumber
        showError={hasError}
        isPositive
      />
    );
  }

  if (comparisonOptions.filter((e) => e.id === comparison.input.value).length <= 0) {
    // eslint-disable-next-line
    console.error(
      '[NumberEditor/InputField.js] Using a not allowed comparison',
      comparisonOptions,
      props,
    );
  }

  return (
    <FormField meta={value.meta}>
      <Box className="number-filter-row">
        <DropdownList
          className="filter-button"
          items={comparisonOptions}
          value={comparison.input.value}
          onChange={onComparisonChange}
          dropDownLabelFunc={labelFunc}
          withinPortal={withinPortal}
          hideDropdownArrow={comparison.input.value === FilterComparison.IS_NULL}
        />
        {content}
        <FilterCount count={count} />
      </Box>
    </FormField>
  );
};

InputField.defaultProps = {
  iconDropdown: false,
  autoFocus: true,
  handleSelect: () => {},
};

export default InputField;
