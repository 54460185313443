import * as React from 'react';
import cn from 'classnames';
import './form-field.scss';

type FormFieldProps = {
  helpText?: string;
  children: React.ReactNode;
  meta: {
    touched: boolean;
    error?: any;
  };
};

class FormField extends React.Component<FormFieldProps> {
  render() {
    const { meta, helpText, children } = this.props;
    const error = meta && meta.touched && meta.error;
    return (
      <div
        className={cn('form-field', {
          error,
        })}
      >
        {error && (
          <div className="form-field-error-details">
            <div className="error-message">{error}</div>
          </div>
        )}
        {children}
        <div className="form-field-help-text">{helpText}</div>
      </div>
    );
  }
}

export default FormField;
