import { memo } from 'react';
import {
  ActionIcon,
  Flex,
  Group,
  Indicator,
  Skeleton,
  Stack,
  Switch,
  useMantineTheme,
} from '@mantine/core';
import cn from 'classnames';
import isEqual from 'lodash/isEqual';
import AccSegmentedControl from 'Components/AccSegmentedControl/AccSegmentedControl';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import SerpIcon from 'Components/AccuTable/CellRenderer/HelperComponents/SerpPopOver/serpIcon';
import {
  AllSerpTypes,
  ownedSerpTypes,
  serpTypes,
} from 'Components/AccuTable/CellRenderer/helpers/serp-features';
import FormatNumber, {
  FormatSettings,
  defaultBillionsSettings,
  defaultMillionsSettings,
  defaultThousandsSettings,
} from 'Components/FormatNumber/new';
import { store } from 'Store';
import { t, tct } from 'Utilities/i18n';
import { notEmpty } from 'Utilities/underdash';
import styles from './serpFeatureBox.module.scss';

const ICON_SIZE = 32;

const NUMBER_FORMAT_SETTINGS: FormatSettings = {
  spaceBetweenNumberAndLabel: false,
  billionsSettings: { ...defaultBillionsSettings, maximumFractionDigits: 1 },
  millionsSettings: { ...defaultMillionsSettings, maximumFractionDigits: 1 },
  thousandsSettings: { ...defaultThousandsSettings, maximumFractionDigits: 1 },
};

type SerpFeatureBoxProps = {
  /** All available SERPfeatures */
  serpFeatures: [string, number][];
  loading: boolean;
  isOwnedFilter: boolean;
  handleChangeOwnership: () => void;
  handleSetSelectedSERPS?: (SERPId: AllSerpTypes) => void;
  /**Selected SERP features passed from the pageSerpFeaturesFilter */
  selectedSerpFeatures?: string[];
  iconSize?: number;
  label?: React.ReactNode;
  darkTheme?: boolean;
  togglePlacement?: 'top' | 'bottom';
};

const SerpFeatureBox = ({
  serpFeatures,
  loading,
  isOwnedFilter,
  handleChangeOwnership,
  handleSetSelectedSERPS,
  selectedSerpFeatures,
  iconSize = ICON_SIZE,
  label,
  darkTheme = false,
  // serpFeatureMetaData,
  togglePlacement = 'top',
}: SerpFeatureBoxProps) => {
  const theme = useMantineTheme();
  const primarySnorlax = theme.colors.snorlax[theme.primaryShade as number];
  const state = store.getState();
  const userSerpFeatures = state.metaData?.serpFeatures;

  //If no serpFeatureMetaData is passed we get it from the redux store
  // const serpFeatureData = serpFeatureMetaData || userSerpFeatures;
  const serpFeatureData = userSerpFeatures;

  const segmentValue = isOwnedFilter ? 'owned' : 'available';

  return (
    <Stack gap={'xl'} h="100%" w="100%">
      <Flex align="center">
        {label}
        {togglePlacement === 'top' && (
          <Switch
            classNames={{
              root: styles.root,
              track: styles.track,
              trackLabel: styles.trackLabel,
              body: styles.body,
            }}
            onLabel={t('Owned')}
            offLabel={t('Available')}
            onChange={handleChangeOwnership}
            checked={isOwnedFilter}
            size="md"
            color="gray"
          />
        )}
      </Flex>
      <Flex rowGap="lg" justify="space-between" h="100%" direction="column">
        <Group wrap="wrap">
          {!loading &&
            serpFeatures.map(([_name, count], idx) => {
              const isOwnedButToggleSetToAvailable = !isOwnedFilter && _name.includes('owned');
              const isNotOwnedAndToggleSetToOwned = isOwnedFilter && !_name.includes('owned');
              if (isOwnedButToggleSetToAvailable || isNotOwnedAndToggleSetToOwned) {
                return null;
              }
              const name = isOwnedFilter ? _name.replace('_owned', '') : _name;
              const key = `${name}_${idx}`;

              const serpLabel: string =
                serpFeatureData?.find((serpFeature) => serpFeature?.id === name)?.label || '';
              const featureIncludesName = selectedSerpFeatures?.includes(name);
              return (
                (name in serpTypes || name in ownedSerpTypes) && (
                  <Indicator
                    key={key}
                    label={<FormatNumber value={count} formatSettings={NUMBER_FORMAT_SETTINGS} />}
                    size={16}
                    radius={6}
                    position="bottom-start"
                    color={darkTheme ? '#050a27' : primarySnorlax}
                    onClick={() =>
                      handleSetSelectedSERPS && handleSetSelectedSERPS(name as AllSerpTypes)
                    }
                    classNames={{
                      root: styles.indicatorCommon,
                      indicator: styles.indicator,
                    }}
                  >
                    <AccTooltip
                      delay={0}
                      tooltip={tct('Add "[serpLabel]" as filter', { serpLabel })}
                      old
                    >
                      <ActionIcon
                        variant={featureIncludesName ? 'filled' : 'outline'}
                        size={iconSize}
                        radius="md"
                        aria-label={serpLabel}
                        className={cn({
                          [styles.darkTheme]: darkTheme,
                          [styles.serpSelected]: featureIncludesName,
                          [styles.serpUnselected]: !featureIncludesName,
                        })}
                        mb={5}
                        aria-pressed={featureIncludesName}
                      >
                        <SerpIcon
                          id={name}
                          size={0.5 * iconSize}
                          disableInteraction={true}
                          serpFeatures={serpFeatureData?.filter(notEmpty)}
                          noTooltip
                          color={featureIncludesName ? '#fff' : darkTheme ? '#fff' : primarySnorlax}
                        />
                      </ActionIcon>
                    </AccTooltip>
                  </Indicator>
                )
              );
            })}
          {loading &&
            [...Array(10)].map((_, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Skeleton key={`SerpSkeleton${i}`} height={iconSize} width={iconSize} radius="md" />
            ))}
        </Group>
        {togglePlacement === 'bottom' && (
          <AccSegmentedControl
            value={segmentValue}
            onChange={handleChangeOwnership}
            data={[
              { label: t('Available'), value: 'available' },
              { label: t('Owned'), value: 'owned' },
            ]}
          />
        )}
      </Flex>
    </Stack>
  );
};

export default memo(SerpFeatureBox, isEqual);
