import { LineChart } from 'Components/Chart/LineChart';
import { TooltipConfiguration, chartTooltipTypes } from 'Components/Chart/LineChart/support/types';
import { useQueryNotesByKeywordId } from 'Components/Chart/Notes/support/hooks';
import { useGoogleTrendsChartQuery } from 'Ghql';
import { useRequiredFilters } from 'Hooks/data/filter/useFilters';
import colorScheme from 'Utilities/colors';
import { t } from 'Utilities/i18n';

export const GoogleTrendsChart = ({ keywordId }: { keywordId: string }) => {
  const filters = useRequiredFilters();
  const { data, loading } = useGoogleTrendsChartQuery({
    variables: { filters, keywordId },
  });
  const { notes, isLoadingNotes } = useQueryNotesByKeywordId(keywordId);
  const historyData = data?.graphs?.googleTrendsHistory ?? [];

  const series = [
    {
      name: t('Google trends'),
      primary: true,
      color: colorScheme.graphs.colors.darkBlue,
      data: historyData,
    },
  ];

  const tooltipConfig: TooltipConfiguration = {
    type: chartTooltipTypes.STOCK,
    title: t('Trend'),
    dontShowTotalKeywords: true,
  };

  return (
    <LineChart
      series={series}
      isLoading={loading || isLoadingNotes}
      notes={notes}
      loaderType="line"
      disableLegend={false}
      tooltip={tooltipConfig}
      stoked
      exportButton
      enableRangeSelector={true}
      extraOptions={{
        rangeSelector: {
          selected: 5,
        },
      }}
      yAxisLabel={t('Trend')}
    />
  );
};
