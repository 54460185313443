import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import cn from 'classnames';
import { TableSettingGroup, TableSettingOptionProps } from '../support/types';
import { TableSettingOptionGroup, TableSettingOptionGroupProps } from './TableSettingGroup';
import styles from './tableSettings.module.scss';

const SortableItem = SortableElement<TableSettingOptionGroupProps>(TableSettingOptionGroup);

type Props = {
  items: TableSettingGroup[];
  grabbingCursor?: boolean;
  itemProps: TableSettingOptionProps;
};

export const SortableTableSettingsList = SortableContainer<Props>(
  ({ items, itemProps, grabbingCursor }: Props) => {
    const orderedItemIds = itemProps.groupStatuses.map((e) => e.id);
    // use itemsMap instead find for performance optimization
    const itemsMap = Object.fromEntries(items.map((item) => [item.id, item]));

    return (
      <div className={cn(styles.listContainer, { [styles.useGrabbing]: grabbingCursor })}>
        {orderedItemIds
          ?.map((id, index) => {
            const value = itemsMap[id];
            if (!value) return null;
            const Element = value.fixed ? TableSettingOptionGroup : SortableItem;
            return <Element key={`item-${id}`} index={index} item={value} {...itemProps} />;
          })
          .filter(Boolean)}
      </div>
    );
  },
);
