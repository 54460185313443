import { Navigate, Route } from 'react-router-dom';

type Props = {
  from?: string;
  to: string;
};

export const Redirect = ({ from, to }: Props) => {
  if (from) {
    return <Route path={from} element={<Navigate to="about-us" />} />;
  }
  return <Navigate to={to} />;
};
