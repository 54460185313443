import { Dispatch, SetStateAction } from 'react';
import { LazyQueryExecFunction } from '@apollo/client';
import { ComboboxItem } from '@mantine/core';
import { SelectItem } from 'Components/AccSelect';
import { Exact, ValidateDomainQuery } from 'Ghql';
import { useRemoveFilter, useSetOrOverwriteFilter } from 'Hooks/data/filter/setFilters';
import toast from 'Hooks/useToast';
import { removeProtocolFromUrl } from 'Pages/SiteMapping/support/helpers';
import { FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import { EventName, trackEventMixpanel } from 'Utilities/Analytics/mixpanel';
import { t } from 'Utilities/i18n';
import validation from 'Utilities/validation';
import { DomainItem } from './components/KeydisDomainSelect';
import {
  extractDomainInfoFromValue,
  useSearchHistory,
} from './components/KeydisDomainSelect/helpers';
import { SelectedDomain } from './index';

interface useHandleSetDomainProps {
  invalidDomainError: string | undefined;
  setInvalidDomainError: Dispatch<SetStateAction<string | undefined>>;
  setValidatingDomain: Dispatch<SetStateAction<boolean>>;
  validateDomain: LazyQueryExecFunction<
    ValidateDomainQuery,
    Exact<{
      domain: string;
    }>
  >;
  setSelectedDomain: Dispatch<SetStateAction<SelectedDomain>>;
  selectItems: DomainItem[];
  inKeyDis: boolean | undefined;
  onDialogOverlay: boolean;
  navigateToKeydis: (domainId: string) => void;
}

/**
 * Get the HandleSetDomain method used in DomainSelect
 * @example
 * const handleSetDomain = useHandleSetDomain({
 *   selectItems,
 *   invalidDomainError,
 *   setInvalidDomainError,
 *   setValidatingDomain,
 *   validateDomain,
 *   setSelectedDomain,
 *   selectItems,
 *   inKeyDis,
 *   onDialogOverlay,
 *   navigateToKeydis
 * });
 */
export const useHandleSetDomain = ({
  invalidDomainError,
  setInvalidDomainError,
  setValidatingDomain,
  validateDomain,
  setSelectedDomain,
  selectItems,
  inKeyDis,
  onDialogOverlay,
  navigateToKeydis,
}: useHandleSetDomainProps) => {
  const setFilter = useSetOrOverwriteFilter();
  const removeFilter = useRemoveFilter();
  const { addRecentSearch } = useSearchHistory();

  const handleSetDomain = async (
    selectedValue: string,
    option?: ComboboxItem | DomainItem | SelectItem<string>,
  ) => {
    const { domain, domainId: domainIdValue } =
      (option as DomainItem) || extractDomainInfoFromValue(selectedValue);

    const inputNoProtocol = removeProtocolFromUrl(domain?.trim() || '');
    //remove fragment and query-params + limit to 255 characters
    const inputValue = inputNoProtocol.split('#')[0].split('?')[0].substring(0, 255);

    invalidDomainError && setInvalidDomainError(undefined);

    const urlIsValid = validation.isUrl(inputValue) === undefined;

    if (inputValue && !urlIsValid && selectedValue) {
      trackEventMixpanel(EventName.DomainValidationFailed, '/research/:filter', {
        selectedValue,
        domain: domain ?? '',
        inputValue,
        clientSide: true,
        serverSide: false,
      });
    }
    if (!inputValue || !urlIsValid) {
      setSelectedDomain({ domain: undefined, domainId: undefined });
      return;
    }

    setValidatingDomain(true);

    const domainValidationResult = await validateDomain({
      variables: {
        domain: inputValue,
      },
      onCompleted: (data) => {
        setValidatingDomain(false);
        const redirectDomain = data.validateDomain?.domainValidation?.redirectTo;
        if (redirectDomain) {
          setInvalidDomainError(undefined);
          toast.info(inputValue + t(' is redirected to %s', redirectDomain));
        }
      },
    });

    if (domainValidationResult.data?.validateDomain?.domainValidation?.valid) {
      trackEventMixpanel(EventName.DomainValidationSuccess, '/research/:filter', {
        inputValue,
      });
    } else {
      selectedValue &&
        trackEventMixpanel(EventName.DomainValidationFailed, '/research/:filter', {
          selectedValue,
          domain: domain ?? '',
          inputValue,
          clientSide: false,
          serverSide: true,
        });
    }

    // If domain redirect is set, we use that instead of the input value
    const actualDomain =
      domainValidationResult?.data?.validateDomain?.domainValidation?.redirectTo || inputValue;

    //Get domainId from selected option or selectItems if selected value is identical to existing domain
    const domainId =
      (option as DomainItem)?.domainId ||
      domainIdValue ||
      selectItems?.find((domainItem) => domainItem.domain === actualDomain)?.domainId;

    setSelectedDomain({ domain: actualDomain, domainId });

    // If this is one of our own domains
    if (domainId) {
      if (onDialogOverlay) {
        return;
      }
      setFilter(
        {
          attribute: FilterAttribute.DOMAINS,
          type: FilterValueType.LIST,
          comparison: FilterComparison.CONTAINS,
          value: [domainId],
        },
        'no tracking',
      );
      if (!inKeyDis) {
        removeFilter(FilterAttribute.FREE_TEXT_DOMAIN);
        removeFilter(FilterAttribute.COMPETITOR_DOMAIN);
        // Redirect to keyword Discovery
        navigateToKeydis(domainId);
      }
    } else {
      addRecentSearch(actualDomain);
      if (onDialogOverlay) {
        return;
      }
      //if in keyword discovery set the selected domain as a competitor domain
      if (inKeyDis) {
        setFilter(
          {
            attribute: FilterAttribute.COMPETITOR_DOMAIN,
            type: FilterValueType.STRING,
            comparison: FilterComparison.EQ,
            value: actualDomain,
          },
          'no tracking',
        );
      } else {
        setFilter(
          {
            attribute: FilterAttribute.FREE_TEXT_DOMAIN,
            type: FilterValueType.STRING,
            comparison: FilterComparison.CONTAINS,
            value: actualDomain,
          },
          'no tracking',
        );
        //make sure we have no competitor domain in keyword research
        removeFilter(FilterAttribute.COMPETITOR_DOMAIN);
      }
    }
  };
  return handleSetDomain;
};
