import { BadgeType } from 'Components/AccBadge';
import { t } from 'Utilities/i18n';

const statusToInt = (s) => {
  return s.toString().replace('A_', '');
};

export const statusToText = (s, progress) => {
  const status = statusToInt(s);

  switch (status) {
    case '0':
      return t('Setup');

    case '1':
      if (progress <= 0) {
        return t('In queue (Validating)');
      }

      return t('Validating');

    case '2':
      return t('Failed');

    case '3':
      if (progress <= 0) {
        return t('In queue (Importing)');
      }

      return t('Importing');

    case '4':
      return t('Completed');

    default:
      return 'n/a';
  }
};

export const getBadgeType = (status): BadgeType => {
  switch (Number(statusToInt(status))) {
    case 0:
    case 1:
    case 3:
      return 'gray';
    case 2:
      return 'error';
    case 4:
      return 'success';
    default:
      return 'gray';
  }
};

export const rankType = 'rank';
export const keywordType = 'keyword';
