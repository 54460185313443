import { IconArrowMoveRight } from '@tabler/icons-react';
import { observer } from 'mobx-react';
import AccButton from 'Components/AccButton/AccButton';
import { useSelectedNodes } from 'Hooks/useSelectedNodes';
import { t } from 'Utilities/i18n';
import classes from './MoveSelected.module.scss';

export const MoveSelectedNodesOptions = observer(
  ({ setSelectedNodes, handleMoveItems, isAddMode, isEditingNode, animateMoveSelected }) => {
    const { selectedNodes } = useSelectedNodes();
    const hasSelectedNodes = selectedNodes.length > 0;

    return hasSelectedNodes ? (
      <>
        <AccButton variant="tertiary" onClick={() => setSelectedNodes([])} ml="auto">
          {t('Clear selection ')}({selectedNodes.length})
        </AccButton>
        <AccButton
          onClick={handleMoveItems}
          ml={'sm'}
          variant="primary"
          disabled={isAddMode || isEditingNode}
          leftSection={<IconArrowMoveRight />}
          classNames={{ root: animateMoveSelected ? classes.wiggleAnimation : '' }}
        >
          {t('Move (%s) selected', selectedNodes.length)}
        </AccButton>
      </>
    ) : null;
  },
);
