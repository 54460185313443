import { KeywordColumnID } from 'Pages/Keywords/Table/support/constants';
import { t } from 'Utilities/i18n';
import { TableSettingGroup } from './types';

const KeywordsTableParams = {
  ENGINE: 'engine',
  DEVICE_TYPE: 'deviceType',
  ACTION_SERP: 'actionSerp',
  ACTION_REFRESH: 'actionRefresh',
};

export const keywordGroupItems: TableSettingGroup[] = [
  {
    id: 'default',
    label: () => t('Keyword information'),
    fixed: true,
    options: [
      { id: KeywordColumnID.CHECKBOX, label: () => null, hidden: true },
      { id: KeywordColumnID.CHECKBOX_STARRED, label: () => null, hidden: true },
      { id: KeywordColumnID.DOMAIN, label: () => t('Domain'), hidden: true, defaultEnabled: true }, // Default shown in group view, not in single domain view. Controlled in useKeywordTableInfo hook
      {
        id: KeywordColumnID.KEYWORD,
        label: () => t('Keyword'),
        disabled: true,
        defaultEnabled: true,
      },
      {
        id: KeywordsTableParams.ENGINE,
        parentId: KeywordColumnID.SEARCH_TYPE,
        label: () => t('Search Type'),
        defaultEnabled: true,
        isParameter: true,
      },
      { id: KeywordColumnID.SEARCH_INTENT, label: () => t('Search Intent'), defaultEnabled: true },
      { id: KeywordColumnID.DESCRIPTION, label: () => t('Description') },
    ],
  },
  {
    id: 'information',
    label: () => t('Location information'),
    options: [
      {
        id: KeywordColumnID.COUNTRY,
        label: () => t('Country'),
        defaultEnabled: true,
        oldId: KeywordColumnID.COUNTRY_LOCALE,
      },
      {
        id: KeywordColumnID.LOCATION,
        label: () => t('Location'),
        defaultEnabled: true,
        oldId: KeywordColumnID.COUNTRY_LOCALE,
      },
      { id: KeywordColumnID.LOCALE, label: () => t('Language'), defaultEnabled: false },
    ],
  },
  {
    id: 'rank',
    label: () => t('Rank'),
    options: [
      {
        id: KeywordColumnID.RANK,
        label: () => t('Rank'),
        defaultEnabled: true,
        children: [
          { id: KeywordColumnID.RANK_CHANGE, label: () => t('Change'), defaultEnabled: true },
        ],
      },
      {
        id: KeywordColumnID.BASE_RANK,
        label: () => t('Base Rank'),
        children: [
          { id: KeywordColumnID.BASE_RANK_CHANGE, label: () => t('Change') },
          { id: KeywordColumnID.MAX_RANK, label: () => t('Max Base Rank') },
        ],
      },
      {
        id: KeywordColumnID.LOCAL_PACK_RANK,
        label: () => t('Local Pack Rank'),
        children: [{ id: KeywordColumnID.LOCAL_PACK_RANK_CHANGE, label: () => t('Change') }],
      },
      {
        id: KeywordColumnID.PIXELS_FROM_TOP,
        label: () => t('Pixels from Top'),
        children: [{ id: KeywordColumnID.PIXELS_FROM_TOP_CHANGE, label: () => t('Change') }],
      },
    ],
  },
  {
    id: 'essentials',
    label: () => t('Essentials'),
    options: [
      { id: KeywordColumnID.URL, label: () => t('URL'), defaultEnabled: true },
      {
        id: KeywordColumnID.DYNAMIC_CTR,
        label: () => t('Click-through Rate'),
        defaultEnabled: true,
        children: [
          {
            id: KeywordColumnID.DYNAMIC_CTR_CHANGE,
            label: () => t('Change'),
            defaultEnabled: true,
          },
          {
            id: KeywordColumnID.DYNAMIC_CTR_MAX,
            label: () => t('Max Click-through Rate'),
            defaultEnabled: true,
          },
        ],
      },
      { id: KeywordColumnID.SEARCH_VOLUME, label: () => t('Search Volume'), defaultEnabled: true },
      {
        id: KeywordColumnID.AI_SEARCH_VOLUME,
        label: () => t('AI Search Volume'),
        defaultEnabled: true,
      },
      { id: KeywordColumnID.SERP, label: () => t('SERP Features'), defaultEnabled: true },
    ],
  },
  {
    id: 'share_of_voice',
    label: () => t('Share of Voice'),
    options: [
      {
        id: KeywordColumnID.ORGANIC_TRAFFIC,
        label: () => t('AI Share of Voice'),
        defaultEnabled: true,
        children: [
          {
            id: KeywordColumnID.ORGANIC_TRAFFIC_CHANGE,
            label: () => t('Change'),
            defaultEnabled: true,
          },
          {
            id: KeywordColumnID.MAX_AI_SOV,
            label: () => t('Max AI Share of Voice'),
            defaultEnabled: true,
          },
        ],
      },
      {
        id: KeywordColumnID.SHARE_OF_VOICE,
        label: () => t('Share of Voice'),
        children: [
          { id: KeywordColumnID.SHARE_OF_VOICE_CHANGE, label: () => t('Change') },
          { id: KeywordColumnID.MAX_SOV, label: () => t('Max Share of Voice') },
        ],
      },
    ],
  },
  {
    id: 'traffic_value',
    label: () => t('Traffic Value'),
    options: [
      {
        id: KeywordColumnID.AI_TRAFFIC_VALUE,
        label: () => t('AI Traffic Value'),
        defaultEnabled: true,
        children: [
          {
            id: KeywordColumnID.AI_TRAFFIC_VALUE_CHANGE,
            label: () => t('Change'),
            defaultEnabled: true,
          },
          {
            id: KeywordColumnID.MAX_AI_TRAFFIC_VALUE,
            label: () => t('Max AI Traffic Value'),
            defaultEnabled: true,
          },
        ],
      },
      {
        id: KeywordColumnID.SHARE_OF_VOICE_CPC,
        label: () => t('Traffic Value'),
        children: [
          { id: KeywordColumnID.SHARE_OF_VOICE_CPC_CHANGE, label: () => t('Change') },
          { id: KeywordColumnID.MAX_TRAFFIC_VALUE, label: () => t('Max Traffic Value') },
        ],
      },
      { id: KeywordColumnID.CPC, label: () => t('Cost per Click'), defaultEnabled: true },
    ],
  },
  {
    id: 'competition',
    label: () => t('Competition'),
    options: [
      {
        id: KeywordColumnID.AD_COMPETITION,
        label: () => t('Keyword Competition'),
        defaultEnabled: true,
      },
      {
        id: KeywordColumnID.TOP_DOMAIN,
        label: () => t('Top Competitor'),
      },
    ],
  },
  {
    id: 'timestamps',
    label: () => t('Timestamp'),
    options: [
      { id: KeywordColumnID.DATE_ADDED, label: () => t('Created On') },
      { id: KeywordColumnID.KEYWORD_TIME_AGO, label: () => t('Last Update'), defaultEnabled: true },
    ],
  },
  {
    id: 'actions',
    label: () => t('Actions'),
    fixed: true,
    hidden: true,
    options: [
      {
        id: KeywordsTableParams.ACTION_SERP,
        parentId: KeywordColumnID.ACTIONS,
        label: () => t('View SERP'),
        defaultEnabled: true,
        isParameter: true,
        disabled: true,
      },
      {
        id: KeywordsTableParams.ACTION_REFRESH,
        parentId: KeywordColumnID.ACTIONS,
        label: () => t('Refresh Keyword'),
        defaultEnabled: true,
        isParameter: true,
        disabled: true,
      },
    ],
  },
];
