import { Component } from 'react';
import { IconCirclePlus } from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import SimpleItemsList from 'Components/Controls/Dropdowns/SimpleItemsList';
import { formatKeys } from 'Components/Modal/Content/Shortcuts';
import SearchInput from 'Components/SearchInput';
import { t } from 'Utilities/i18n';
import styles from './search-items-list.module.scss';

type Props = {
  items: Array<any>;
  item?: any | null | undefined;
  labelFunc: (...args: Array<any>) => any;
  valueFunc?: (...args: Array<any>) => any;
  iconFunc?: ((...args: Array<any>) => any) | null | undefined;
  onSelect?: (...args: Array<any>) => any;
  title: string;
  addItemLabel: string | null | undefined;
  onAdd: ((...args: Array<any>) => any) | null | undefined;
  closeOnSelect?: boolean;
  onDelete?: ((...args: Array<any>) => any) | null | undefined;
  onEdit?: ((...args: Array<any>) => any) | null | undefined;
  placeholder?: string;
  onClose?: (...args: Array<any>) => any;
  emptyText?: string;
  keyboardCombo?: string;
};
type State = {
  searchTerm: string;
  selectedItem: number;
  selectWithMouse: boolean;
};

class SearchItemsList extends Component<Props, State> {
  _isMounted?: boolean;
  static defaultProps = {
    labelFunc: (item: any) => item,
    iconFunc: null,
    item: null,
    addItemLabel: null,
    onAdd: null,
    closeOnSelect: true,
    onDelete: null,
    onEdit: null,
  };
  state = {
    searchTerm: '',
    selectedItem: -1,
    selectWithMouse: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      selectedItem: this.lowestItemNotHeader(''),
      selectWithMouse: true,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener('keydown', this.handleKeyDown as any);
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('keydown', this.handleKeyDown as any);
  }

  onMouseOver = (index: number) => {
    if (this.state.selectWithMouse) {
      if (index !== -1) {
        !this.props.items[index].header &&
          this.setState({
            selectedItem: index,
          });
      } else {
        this.setState({
          selectedItem: index,
        });
      }
    }
  };
  onMouseMove = () =>
    this.state.selectWithMouse ||
    this.setState({
      selectWithMouse: true,
    });
  getFilteredItems = (searchTerm: string) => {
    const { labelFunc } = this.props;
    let { items } = this.props;
    const valueFunc = this.props.valueFunc || labelFunc;
    let newItems: any[] = [];

    if (searchTerm) {
      //Filter empty headers out, currently no other way because tobias magic logic.
      items = items.filter((item) => {
        const label = valueFunc(item);
        return item.header || ~label.toLowerCase().indexOf(searchTerm.toLowerCase());
      });
      let currentGroup = '';

      for (let row: number = items.length - 1; row >= 0; row--) {
        const currentItem = items[row];
        currentItem.belongsTo && (currentGroup = currentItem.belongsTo);
        if (
          !currentItem.header ||
          (currentItem.header && currentItem.headerText === currentGroup)
        ) {
          newItems.push(currentItem);
        }
      }

      newItems = newItems.reverse();
    }

    return searchTerm ? newItems : items;
  };
  lowestItemNotHeader = (searchTerm: string) => {
    const items = this.getFilteredItems(searchTerm);

    for (let index = 0; index < items.length; index++) {
      const item = items[index];

      if (item && !item.header) {
        return index;
      }
    }

    return -1;
  };
  handleChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const newSearchTerm = event.currentTarget.value;
    this.setState({
      searchTerm: newSearchTerm,
      selectedItem: this.lowestItemNotHeader(newSearchTerm),
    });
  };
  handleKeyDown = (event: any) => {
    const { onClose } = this.props;
    const items = this.getFilteredItems(this.state.searchTerm);
    let nextItem = this.state.selectedItem;
    let selectWithMouse = true;
    let item;

    switch (event.key) {
      case 'Escape':
        if (onClose) {
          onClose();
        }

        break;

      case 'Enter':
        event.preventDefault();
        if (nextItem === -1) {
          this.props.onAdd && this.props.onAdd();
          return;
        }

        item = items[nextItem];

        if (item && !item.header) {
          this.props.onSelect?.(item);
        }

        break;

      case 'ArrowUp':
        event.preventDefault();
        selectWithMouse = false;

        if (nextItem === -1) {
          nextItem = items.length;
        }

        while (nextItem > 0 && items[--nextItem].header) {
          //empty
        }

        if (nextItem === 0 && items[nextItem].header) {
          nextItem = this.state.selectedItem;
        }

        break;

      case 'ArrowDown':
        event.preventDefault();
        selectWithMouse = false;

        if ((nextItem === items.length - 1 && !this.state.searchTerm) || nextItem === -1) {
          nextItem = -1;
        } else {
          while (nextItem < items.length - 1 && items[++nextItem].header) {
            //empty
          }

          if (nextItem === items.length || items[nextItem].header) {
            nextItem = this.state.selectedItem;
          }
        }

        break;

      default:
        break;
    }

    if (this._isMounted) {
      if (nextItem === -1) {
        this.setState({
          selectedItem: nextItem,
          selectWithMouse,
        });
        return;
      }

      if (items[nextItem] && !items[nextItem].header) {
        this.setState({
          selectedItem: Math.max(Math.min(nextItem, items.length - 1), 0),
          selectWithMouse,
        });
      }
    }
  };

  render() {
    const { title, addItemLabel, onAdd, placeholder, emptyText } = this.props;
    const searchTerm = this.state.searchTerm;
    const items = this.getFilteredItems(searchTerm);
    let addItemContent: any = null;

    if (addItemLabel && onAdd && !searchTerm) {
      addItemContent = (
        <div className={styles.footer} onMouseOver={() => this.onMouseOver(-1)}>
          <AccButton variant="tertiary" leftSection={<IconCirclePlus />} onClick={onAdd}>
            {addItemLabel}
          </AccButton>
        </div>
      );
    }

    return (
      <div className={styles.searchItemsList} onMouseMove={this.onMouseMove}>
        <div className={styles.header}>
          <div className={styles.title}>
            <span>{title}</span>
            {this.props.keyboardCombo && <span>{formatKeys(this.props.keyboardCombo)}</span>}
          </div>
          <SearchInput
            placeholder={placeholder}
            autoFocus
            value={searchTerm}
            onChange={this.handleChange}
          />
        </div>
        <SimpleItemsList
          onMouseOver={this.onMouseOver}
          selectedItem={this.state.selectedItem}
          {...this.props}
          items={items}
          emptyText={emptyText ? emptyText : t('No domain matches your search')}
        />
        {addItemContent}
      </div>
    );
  }
}

export default SearchItemsList;
