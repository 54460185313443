import { RefObject, useCallback, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { QUERY_KEYWORDS_OVERUSE } from 'Pages/KeywordOverusePage/overuse.query';
import { seriesColor } from '../support/constants';
import * as helpers from '../support/helpers';

export const useChartConfig = ({
  chartref,
}: {
  chartref: RefObject<Highcharts.Chart>;
}): { chartConfig: Highcharts.Options; loading: boolean } => {
  const { data: res, loading } = useQuery(QUERY_KEYWORDS_OVERUSE);

  const chartData = useMemo(() => {
    const data = res?.keywordOveruseOverview?.dates ?? [];
    return helpers.getOveruseChartData(data);
  }, [res?.keywordOveruseOverview?.dates]);

  const chartHighlightInfoItems = helpers.getChartHighlightInfo(chartData);

  const series = helpers.getChartSeries(chartData, chartHighlightInfoItems);

  // Used to hide highlight if green *(include) column is off
  // https://accuranker.myjetbrains.com/youtrack/issue/ARR-1450#focus=Comments-4-2388.0-0
  const legendClickHandler = useCallback(
    () => (event: any) => {
      const includeIndex = series?.findIndex((e) => e.color === seriesColor.INCLUDED);
      if (event?.target?._i === includeIndex) {
        const hide = event?.target?.visible;
        // eslint-disable-next-line
        // @ts-ignore
        chartref?.current?.chart?.series
          ?.slice(0, chartHighlightInfoItems?.length)
          ?.map((e: any) => (hide ? e?.hide() : e?.show()));
      }
    },
    [chartHighlightInfoItems?.length, chartref, series],
  );

  const xAxis: Highcharts.XAxisOptions = {
    labels: {
      style: {
        fontSize: '12px',
      },
    },
    categories: chartData.categories,
  };
  const yAxis: Highcharts.YAxisOptions = {
    min: 0,
    title: {
      text: '',
    },
    stackLabels: {
      enabled: false,
    },
  };
  const legend: Highcharts.LegendOptions = {
    align: 'center',
    verticalAlign: 'bottom',
    shadow: false,
    enabled: true,
  };
  const tooltip: Highcharts.TooltipOptions = {
    shared: true,
    headerFormat: '<b>s<b><br/>',
    // eslint-disable-next-line
    // @ts-ignore
    stickyTracking: true,
    formatter() {
      return helpers.formatOveruseTooltipMessage(this as any);
    },
  };
  const plotOptions: Highcharts.PlotOptions = {
    column: {
      grouping: false,
      shadow: false,
      borderWidth: 0,
      dataLabels: {
        enabled: false,
      },
    },
    series: {
      events: {
        legendItemClick: legendClickHandler,
      },
    },
  };

  return {
    chartConfig: {
      chart: {
        type: 'column',
      },
      credits: {
        enabled: false,
      },
      title: {
        text: '',
      },
      xAxis,
      yAxis,
      legend,
      tooltip,
      plotOptions,
      series,
    },
    loading,
  };
};
