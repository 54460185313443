import { ownedSerpTypes, serpTypes } from 'Components/AccuTable/CellRenderer/helpers/serp-features';
import {
  FilterAttribute,
  FilterBase,
  PageSerpFeaturesFilter,
  PageSerpFeaturesOwnedFilter,
} from 'Types/Filter';

const filtersToValidate: string[] = [
  FilterAttribute.PAGE_SERP_FEATURES,
  FilterAttribute.PAGE_SERP_FEATURES_OWNED,
];

const getValidSerpFeatureFilters = (filters: FilterBase[]): FilterBase[] => {
  const serpFeaturesFilter = filters.find(
    (f) => f.attribute === FilterAttribute.PAGE_SERP_FEATURES,
  ) as PageSerpFeaturesFilter | undefined;
  const serpFeaturesOwnedFilter = filters.find(
    (f) => f.attribute === FilterAttribute.PAGE_SERP_FEATURES_OWNED,
  ) as PageSerpFeaturesOwnedFilter | undefined;

  const filtersToReturn: FilterBase[] = [];

  if (serpFeaturesFilter) {
    const value = serpFeaturesFilter.value.filter((f) => serpTypes[f]);
    filtersToReturn.push({
      ...serpFeaturesFilter,
      value,
    });
  }
  /** Make sure we only use supported SERPtypes */
  if (serpFeaturesOwnedFilter) {
    const value = serpFeaturesOwnedFilter.value.filter((f) => ownedSerpTypes[`${f}_owned`]);
    filtersToReturn.push({
      ...serpFeaturesOwnedFilter,
      value,
    });
  }

  return filtersToReturn;
};

/**
 * Clientside validation of filters.
 * Map through filters and return only valid filter variants.
 * */
export const getValidFilters = (filters: FilterBase[]): FilterBase[] => {
  if (!filters) return [];
  if (!filters.some((f) => filtersToValidate.includes(f.attribute))) return filters;

  const validSerpFeatureFilters = getValidSerpFeatureFilters(filters);

  return [
    ...filters.filter((f) => !filtersToValidate.includes(f.attribute)),
    ...validSerpFeatureFilters,
  ].filter(Boolean);
};
