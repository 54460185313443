import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import pick from 'lodash/pick';
import { observer } from 'mobx-react';
// eslint-disable-next-line import/namespace
import { chartTooltipTypes } from 'Components/Chart/LineChart/support/types';
import { Metrics, useMetrics } from 'Hooks/data/metrics/useMetrics';
import {
  getShowAsPercentage,
  isRankChart,
} from 'Pages/Keywords/Competitors/components/CompetitorChartContent/support/helpers';
import { useQueryDomainInfo } from '../../../Hooks/data/domain/useQueryDomainInfo';
import { selectFeatureAdvancedMetrics } from '../../../Selectors/DomainSelector';
import { LineChart } from '../LineChart/LineChart';
import { CompareChartDataContainer } from './components/CompareChartDataContainer';
import { useChartMetric, useFetchCompareChartData } from './support/hooks';
import { CompareChartDataProps } from './support/types';

export const CompareChartData = observer(<R, V>(props: CompareChartDataProps<R, V>) => {
  const { domainInfo } = useQueryDomainInfo();
  const featureAdvancedMetrics = useSelector(selectFeatureAdvancedMetrics);
  const metricProps = useChartMetric(props.defaultMetric, props.getMetrics);
  const showPercentage: boolean = getShowAsPercentage(metricProps.metric, domainInfo);
  const isRank = isRankChart(metricProps.metric);
  const { metricToYAxisLabel } = useMetrics();

  const { isLoading, notesData, series, colors, chartVersion } = useFetchCompareChartData<any, any>(
    {
      metric: metricProps.metric,
      ...pick(
        props,
        'tableId',
        'chartQuery',
        'notesQuery',
        'extractData',
        'getVariables',
        'extractNotes',
        'extractColorLineId',
        'getIsDefaultSelect',
      ),
      displayCurrency: props.displayCurrency,
      skip: props.skip,
    },
  );

  const showFake: boolean = useMemo(
    () => !!domainInfo && !isLoading && !featureAdvancedMetrics,
    [domainInfo, isLoading, featureAdvancedMetrics],
  );

  const chartKey = `${metricProps.metric}-${chartVersion}`;
  return (
    <CompareChartDataContainer {...metricProps} showFake={showFake}>
      <LineChart
        key={chartKey}
        series={series}
        notes={notesData}
        showFake={showFake}
        colors={colors}
        isLoading={isLoading}
        tooltip={{ type: chartTooltipTypes.MULTI, showPercentage }}
        metric={metricProps.metric}
        hasControl
        isRank={isRank}
        yAxisLabel={metricToYAxisLabel(metricProps.metric as Metrics)}
      />
    </CompareChartDataContainer>
  );
});
