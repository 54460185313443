import { NextKeywordsCompetitor } from 'Components/Modal/Content/KeywordsComparison/KeywordCompareChart';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import AccTitle from 'Components/Title/AccTitle';
import { useModal } from 'Hooks/base/useModal';
import { t } from 'Utilities/i18n';
import './keyword-comparison.scss';

const KeywordsComparison = ({ keywords }: { keywords?: string[] }) => {
  const { hideModal } = useModal();

  return (
    <ModalBorder
      className="keyword-comparison"
      onClose={hideModal}
      title={t('Keywords Comparison')}
    >
      <div className="keyword-comparison-chart">
        <AccTitle
          type="h4"
          boxProps={{ mb: 12 }}
          helper={t('The graph shows how the selected keywords have been ranking over time.')}
          popoverProps={{ width: 225 }}
        >
          {t('Keyword Ranks')}
        </AccTitle>
        <NextKeywordsCompetitor keywords={keywords} />
      </div>
    </ModalBorder>
  );
};

export default KeywordsComparison;
