import { useMemo } from 'react';
import cn from 'classnames';
import { ColumnsType } from 'Components/DataTable/table-core/interface';
import Icon from 'Components/Icon/new';
import { t } from 'Utilities/i18n';
import ClockIcon from 'icons/clock.svg?inline';
import { tableClassName } from '../../helpers';
import { TableStoreType } from '../../store/TableStore';
import { ColumnType, TableLabels } from '../../types';

export const useColumns = (
  columns: ColumnType[],
  tableStore: TableStoreType,
  labels?: TableLabels,
): ColumnsType<any> => {
  return useMemo(() => {
    return (columns as any[]).map(
      (
        { id, cellRenderer: CellRender, combineRow, cellRendererParams, ...columnConfig }: any,
        index,
      ) => {
        const hasCombinedRow = combineRow && columns.some((e) => e.id === combineRow);

        const isLast = columns?.length === index + 1;

        const configOverride: { fixed?: string } = {};

        if (isLast && columnConfig?.fixed === 'left') {
          // if all columns fixed left we have scroll that we don't need,
          // to avoid it we need to set last left column as center
          configOverride.fixed = undefined;
        }

        return {
          id,
          ...columnConfig,
          ...configOverride,
          className: cn(columnConfig?.className, {
            [tableClassName('cell--combined-right')]: hasCombinedRow,
          }),
          render: (value: any) => {
            const updateRowData = tableStore.updateRowData(value?.id);

            if (value?.updatingKeyword && cellRendererParams?.hasLoader) {
              if (cellRendererParams.emptyLoader) {
                return null;
              }
              const tooltip =
                cellRendererParams?.loaderTooltip?.(value) ??
                labels?.cellLoadingTooltip ??
                t('Loading data…');
              return (
                <Icon tooltip={tooltip} colorType="default">
                  <ClockIcon />
                </Icon>
              );
            }

            return CellRender ? (
              <CellRender
                value={{ data: value }}
                record={value}
                updateRowData={updateRowData}
                {...cellRendererParams}
              />
            ) : null;
          },
        };
      },
    );
  }, [columns, tableStore.updateRowData, labels]);
};
