import { useSelector } from 'react-redux';
import { hideModal } from 'Actions/ModalAction';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import { useActions } from 'Hooks/redux/useActions';
import { selectFeatureAdvancedMetrics } from 'Selectors/DomainSelector';
import { TableID } from 'Types/Table';
import { t } from 'Utilities/i18n';
import { devError } from 'Utilities/log';
import { NextTableSettingsForm } from './NextTableSettings/NextTableSettingsForm';
import { TableSettingsForm } from './components/TableSettingsForm/TableSettingsForm';
import { useTableSetting } from './support/config';
import {
  extractVisibleColumns,
  filterColumnsByDomainType,
  getAdvancedMetricsColumns,
  getDefaultVisibleColumns,
  getFormColumnIds,
} from './support/helpers';
import { useSaveTableSettings } from './support/hooks/useSaveTableSettings';
import { useStateColumns } from './support/hooks/useStateColumns';
import { TableSettingVersion } from './support/types';
import styles from './table-settings-modal.module.scss';

type Props = {
  tableName: TableID;
};

const TableSettingsModal: React.FC<React.PropsWithChildren<Props>> = ({ tableName }) => {
  const { hideModal: onModalClose } = useActions({
    hideModal,
  });

  const tableSetting = useTableSetting(tableName);
  const { columns, columnSettingsName, isFlat, groups } = tableSetting;
  const cols = columns ?? [];
  const saveTableSettingsIds = useStateColumns(tableSetting.selector);
  const groupConfigs = tableSetting.selectGroups
    ? useStateColumns(tableSetting.selectGroups)
    : null;
  const { domainInfo } = useQueryDomainInfo();
  const hasAnalytics = domainInfo?.hasAnalytics ?? false;
  const hasAdvancedMetrics = useSelector(selectFeatureAdvancedMetrics);

  const advancedMetricsColumns = getAdvancedMetricsColumns(cols);
  let visibleColumns = extractVisibleColumns(cols, hasAdvancedMetrics);
  visibleColumns = filterColumnsByDomainType(tableName, visibleColumns, domainInfo?.domainType);
  // Limit the columns to the ones that are visible in the table
  const defaultColumnIds = getDefaultVisibleColumns(visibleColumns);
  const formColumnIds = getFormColumnIds(saveTableSettingsIds, cols, hasAdvancedMetrics);
  const onSave = useSaveTableSettings({
    tableName,
    prevColumnIds: saveTableSettingsIds,
    columns: cols,
    columnSettingsName: columnSettingsName ?? 'defaultHomeColumns',
    onSuccess: onModalClose,
    isFlat,
    groupSettingsName: tableSetting.groupSettingsName,
  });

  if (tableSetting.version === TableSettingVersion.GROUPS) {
    return (
      <ModalBorder
        className={styles.tableSettingsModal}
        title={t('Configure Columns')}
        onClose={onModalClose}
      >
        <NextTableSettingsForm
          onSave={onSave}
          groupConfigs={groupConfigs}
          columnConfigs={saveTableSettingsIds}
          onClose={onModalClose}
          groups={groups}
        />
      </ModalBorder>
    );
  }

  if (!columns || !columnSettingsName) {
    devError(
      `Missing columns or columnSettingsName for table settings! Table name is ${tableName}`,
    );
    return null;
  }

  return (
    <ModalBorder
      className={styles.tableSettingsModal}
      title={t('Table Settings')}
      onClose={onModalClose}
    >
      <TableSettingsForm
        onSave={onSave}
        defaultColumnIds={defaultColumnIds}
        columns={visibleColumns}
        advancedMetricsColumns={advancedMetricsColumns}
        hasAdvancedMetrics={hasAdvancedMetrics}
        hasAnalytics={hasAnalytics}
        initialValues={{
          columns: formColumnIds,
        }}
      />
    </ModalBorder>
  );
};

export default TableSettingsModal;
