import cn from 'classnames';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import { AccFastIcon } from 'Components/AccFastIcon';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { CellRenderer } from 'Components/DataTable';
import { WithRowContext } from 'Components/DataTable/table-core';
import { RowContextProps } from 'Components/DataTable/table-core/context/RowContext';
import FormatNumber from 'Components/FormatNumber/new';
import type { TableKeywordNode } from 'Ghql/customTypes';
import { WithKeywordTableExpandChildren } from 'Pages/Keywords/Table/support/types';
import { t } from 'Utilities/i18n';
import ChevronDown from 'icons/chevronDown.svg';
import ChevronUp from 'icons/chevronUp.svg';
import Cell from './HelperComponents/AccuCell';
import NaCell from './helpers/NaCell';

type CellData = Partial<TableKeywordNode> &
  WithKeywordTableExpandChildren & { rank?: string; compact?: boolean };

export const getRankLoadingTooltip = (data) => {
  return [3, 4, 5].includes(data?.searchEngineId)
    ? t('Rankings for this search engine will come at a slower rate than Google and Bing.')
    : t('Refreshing rank…');
};

const NotInTop100 = ({ isLPR }) => {
  if (isLPR) {
    return (
      <AccTooltip tooltip={t('Not in Local Pack')}>
        <span className={'accu-table-cell__rank__value--num'}>{'-'}</span>
      </AccTooltip>
    );
  }
  return (
    <AccTooltip tooltip={t('Not in top 100')}>
      <span>{t('> 100')}</span>
    </AccTooltip>
  );
};

export type RankCellProps = {
  record: CellData;
  value: {
    data: CellData;
  };
  updateRowData: (patch: Partial<CellData>) => void;
  rowContext: RowContextProps;
  isBaseRank?: boolean;
  isLocalPackRank?: boolean;
  includeRankHistoryBtn?: boolean;
  includeExtraRankBtn?: boolean;
};

const RankCell: CellRenderer<CellData, WithRowContext> = (props: RankCellProps) => {
  const {
    value: { data },
    rowContext,
    isBaseRank,
    isLocalPackRank,
    includeRankHistoryBtn = false,
    includeExtraRankBtn = true,
  } = props;

  const { rankId, expandChildren } = data;

  const rankValue = isBaseRank
    ? data?.baseRank
    : isLocalPackRank
    ? data?.localPackRank
    : data?.rankValue || data?.rank;

  if (rankId === 0) {
    return <NaCell tooltip={t('No rank for date')} align="right" />;
  }

  const handleToggleExpandRows = () => rowContext.toggleExpandRows?.();
  const handleToggleCustomExpandRows = () =>
    rowContext?.toggleCustomExpand({ keyword: data?.keyword || '' });

  const extraRowsExpanded = rowContext?.expandExtraRows;
  const customExpand = rowContext?.customExpand;

  const showExtraRankBtn =
    !isBaseRank &&
    !isLocalPackRank &&
    ((rankValue && !!expandChildren?.length) || extraRowsExpanded);

  return (
    <Cell
      className="accu-table-cell__rank u-flex u-flex-row-reverse u-items-center u-justify-between"
      rightAligned
    >
      {rankValue ? <FormatNumber value={rankValue} /> : <NotInTop100 isLPR={isLocalPackRank} />}
      {includeExtraRankBtn && showExtraRankBtn && rowContext?.inView && (
        <AccTooltip
          tooltip={
            !extraRowsExpanded
              ? t('This rank has %s more positions, click to view.', expandChildren?.length ?? 0)
              : t('Hide extra ranks.')
          }
        >
          <AccActionIcon
            size="sm"
            onClick={handleToggleExpandRows}
            className={cn('rank-container', { 'rank-container-active': extraRowsExpanded })}
          >
            {extraRowsExpanded ? (
              <AccFastIcon src={ChevronUp} color={'#444444'} />
            ) : (
              <AccFastIcon src={ChevronDown} color={'#6b6c6c'} />
            )}
          </AccActionIcon>
        </AccTooltip>
      )}
      {includeRankHistoryBtn && rowContext?.inView && (
        <AccTooltip
          tooltip={
            !customExpand
              ? t('View rank history', expandChildren?.length ?? 0)
              : t('Hide rank history')
          }
        >
          <AccActionIcon
            size="sm"
            onClick={handleToggleCustomExpandRows}
            className={cn('rank-container', { 'rank-container-active': customExpand })}
          >
            {customExpand ? (
              <AccFastIcon src={ChevronUp} color={'#444444'} />
            ) : (
              <AccFastIcon src={ChevronDown} color={'#6b6c6c'} />
            )}
          </AccActionIcon>
        </AccTooltip>
      )}
    </Cell>
  );
};

export default RankCell;
