import { ErrorBoundary } from '@sentry/react';
import { GlobalTooltipEngine } from 'Components/AccTooltip/GlobalTooltipEngine';
import Footer from 'Pages/Layout/FooterEmpty';
import { ErrorFallback } from './ErrorFallback';
import PageContent from './pageContent';
import styles from './publicReportPage.module.scss';

const PublicReportPage = () => (
  <div className={styles.canvas}>
    <GlobalTooltipEngine />
    <ErrorBoundary fallback={<ErrorFallback />}>
      <PageContent />
    </ErrorBoundary>
    <Footer />
  </div>
);

export default PublicReportPage;
