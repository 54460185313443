import { ReactNode } from 'react';
import { Box, Divider, Group, Transition } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import AccText from 'Components/Text/AccText';
import { t, tn } from 'Utilities/i18n';
import toolbarStyles from './AccContextToolbar.module.scss';

export const AccContextToolbar = ({
  children,
  items,
  singularLabel = t('item selected'),
  pluralLabel = t('items selected'),
  enabled,
  onDisable,
}: {
  children: ReactNode;
  items: number;
  singularLabel?: string;
  pluralLabel?: string;
  enabled: boolean;
  onDisable?: () => void;
}) => {
  return (
    <Box className={toolbarStyles.container}>
      <Transition
        mounted={enabled}
        transition="pop"
        duration={200}
        exitDuration={100}
        timingFunction="ease"
      >
        {(styles) => (
          <div style={styles} className={toolbarStyles.root}>
            <Group gap="xs" pr="xs">
              <div className={toolbarStyles.number}>{items}</div>
              <AccText size="sm" c="white">
                {tn(singularLabel, pluralLabel, items)}
              </AccText>
            </Group>
            <Divider className={toolbarStyles.divider} h={24} my="auto" orientation="vertical" />
            {children}
            <AccActionIcon onClick={onDisable} variant="subtle" c="white">
              <IconX width={24} height={24} />
            </AccActionIcon>
          </div>
        )}
      </Transition>
    </Box>
  );
};
