import isNumber from 'lodash/isNumber';
import uniqueId from 'lodash/uniqueId';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import Checkmark from 'icons/check.svg?inline';
import './value-bar.scss';

export enum ValueBarColor {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
}

type Props = {
  value: number;
  color?: ValueBarColor;
  checkmarkOnComplete?: boolean;
};

const Bar = ({ width, color }) => {
  const id = uniqueId('valuebar_');
  return (
    <span id={id} className="value-bar-line">
      <span
        style={{
          width: `${width}%`,
        }}
        className={`value-indicator ${color}`}
      />
    </span>
  );
};

const ValueBar = ({ value, color = ValueBarColor.INFO, checkmarkOnComplete }: Props) => {
  const renderBarOrCheckmark = () => {
    const percentWidth = value * 100;
    if (checkmarkOnComplete && percentWidth === 100) {
      return <Checkmark className={`value-indicator-checkmark ${color}`} />;
    }
    return <Bar width={percentWidth} color={color} />;
  };

  const getPercentageDisplayValue = () => {
    const percentWidth = value * 100;
    return isNumber(percentWidth) ? `${percentWidth?.toFixed(0)}%` : undefined;
  };

  const percentageDisplayValue = getPercentageDisplayValue();

  return (
    <AccTooltip tooltip={percentageDisplayValue}>
      <div className={'value-bar-container'}>
        <div className={'value-bar'}>
          {renderBarOrCheckmark()}
          <div className={'value-bar-text'} />
        </div>
      </div>
    </AccTooltip>
  );
};

export default ValueBar;
