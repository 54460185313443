import { useMemo } from 'react';
import camelCase from 'lodash/camelCase';
import { useRowContext } from 'Components/DataTable';
import { useSerpFeatures } from 'Hooks';
import { SerpFeatures } from 'Types/Store';
import SerpIcon from './HelperComponents/SerpPopOver/serpIcon';
import { SerpFeaturesItem } from './HelperComponents/SerpPopOver/types';
import { SerpDropdown } from './SerpDropdown';
import { AllSerpTypes, getSerpFeatureData } from './helpers/serp-features';

export const convertToTitleCase = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export type SerpFeatureNode = {
  description?: string | null;
  id?: string | null;
  label?: string | null;
  owned?: string | null;
  url?: string | null;
  isOwned?: boolean | null;
  icon?: () => JSX.Element | null;
};

// eslint-disable-next-line import/no-unused-modules
export type SerpCellProps = {
  value: {
    data: {
      id: number;
      domainId: number;
      searchDate: string;
      rankId: number;
      updatingKeyword: boolean;
      serpAdsTop: boolean;
      serpAdsInBetween: boolean;
      serpAdsBottom: boolean;
      serpShopping: boolean;
      serpMapsLocalTeaser: boolean;
      serpMapsLocalTeaserOwned: boolean;
      serpMapsLocal: boolean;
      serpMapsLocalOwned: boolean;
      serpRelatedQuestions: boolean;
      serpRelatedQuestionsOwned: boolean;
      serpCarousel: boolean;
      serpImagePack: boolean;
      serpReviews: boolean;
      serpReviewsOwned: boolean;
      serpTweets: boolean;
      serpTweetsOwned: boolean;
      serpSiteLinks: boolean;
      serpSiteLinksOwned: boolean;
      serpFeatureSnippet: boolean;
      serpFeatureSnippetOwned: boolean;
      serpKnowledgePanel: boolean;
      serpKnowledgePanelOwned: boolean;
      serpKnowledgePanelPriceComparison: boolean;
      serpKnowledgePanelPriceComparisonOwned: boolean;
      serpKnowledgeCards: boolean;
      serpVideo: boolean;
      serpVideoOwned: boolean;
      serpYoutubeAds: boolean;
      serpYoutubePromotedVideo: boolean;
      serpYoutubePromotedVideoOwned: boolean;
      serpYoutubeRelatedSearches: boolean;
      serpYoutubeRelatedToYourSearch: boolean;
      serpYoutubeShelfRender: boolean;
      serpYoutubeRelatedMovies: boolean;
      serpYoutubeSecondarySearchContainer: boolean;
      serpYoutubeChannel: boolean;
      serpYoutubeVideo: boolean;
      serpYoutubeShow: boolean;
      serpYoutubePlaylist: boolean;
      serpYoutubeMovie: boolean;
      serpYoutubeLiveStream: boolean;
      serpYoutubeRadio: boolean;
      serpYoutubeChannelOwned: boolean;
      serpYoutubeVideoOwned: boolean;
      serpYoutubeShowOwned: boolean;
      serpYoutubePlaylistOwned: boolean;
      serpYoutubeMovieOwned: boolean;
      serpYoutubeLiveStreamOwned: boolean;
      serpYoutubeRadioOwned: boolean;
      serpThumbnails: boolean;
      serpThumbnailsOwned: boolean;
      serpDirectAnswer: boolean;
      serpJobPosting: boolean;
      serpSiteLinksSearchbox: boolean;
      serpSiteLinksSearchboxOwned: boolean;
      serpThingsTodo: boolean;
      serpFeaturedVideo: boolean;
      serpFeaturedVideoOwned: boolean;
      serpFaq: boolean;
      serpFaqOwned: boolean;
      serpFlights: boolean;
      serpEvents: boolean;
      serpResearchProductPosition: boolean;
      serpResearchProductPositionOwned: boolean;
      serpHotelsPack: boolean;
      serpTripsPopular: boolean;
      serpPodcasts: boolean;
      serpAiOverview: boolean;
      serpRelatedSearchesCarousel: boolean;
      serpMostPopularCarousel: boolean;
      serpRefineCarousel: boolean;
      serpResultsAbout: boolean;
      serpApps: boolean;
      serpDiscoverMorePlaces: boolean;
      serpSearchByPhotos: boolean;
      serpNewsPosition: boolean;
      serpNewsPositionOwned: boolean;
      serpRecipesPosition: boolean;
      serpRecipesPositionOwned: boolean;
      serpVideoCarouselPosition: boolean;
      serpVideoCarouselPositionOwned: boolean;
      serpRichProductInfo: boolean;
      serpRichProductInfoOwned: boolean;
      serpFilterMenu: boolean;
      serpOrganicStores: boolean;
      serpOrganicStoresOwned: boolean;
      serpPopularStores: boolean;
      serpPopularStoresOwned: boolean;
      serpHotelsPosition: boolean;
      serpHotelsPositionOwned: boolean;
      serpForumPostPosition: boolean;
      serpForumPostPositionOwned: boolean;
      serpImageProductPackPosition: boolean;
      serpImageProductPackPositionOwned: boolean;
      serpShopStyles: boolean;
      serpShopStylesOwned: boolean;
      searchEngineId: number;
      serpFeatures?: { [key in AllSerpTypes]: boolean };
    };
    loadingData: boolean;
  };
  serpFeatures: SerpFeatures;
  onClick: (...args: Array<any>) => any;
  isKeyDis?: boolean;
};

function useSerpFeaturesFromTableCellProps(props: SerpCellProps) {
  const {
    value: { data },
  } = props;

  const userSerpFeatures = useSerpFeatures();
  const {
    serpAdsTop,
    serpAdsInBetween,
    serpAdsBottom,
    serpShopping,
    serpMapsLocalTeaser,
    serpMapsLocalTeaserOwned,
    serpMapsLocal,
    serpMapsLocalOwned,
    serpRelatedQuestions,
    serpRelatedQuestionsOwned,
    serpCarousel,
    serpImagePack,
    serpReviews,
    serpReviewsOwned,
    serpTweets,
    serpTweetsOwned,
    serpSiteLinks,
    serpSiteLinksOwned,
    serpFeatureSnippet,
    serpFeatureSnippetOwned,
    serpKnowledgePanel,
    serpKnowledgePanelOwned,
    serpKnowledgePanelPriceComparison,
    serpKnowledgePanelPriceComparisonOwned,
    serpKnowledgeCards,
    serpVideo,
    serpVideoOwned,
    serpYoutubeAds,
    serpYoutubePromotedVideo,
    serpYoutubePromotedVideoOwned,
    serpYoutubeRelatedSearches,
    serpYoutubeRelatedToYourSearch,
    serpYoutubeShelfRender,
    serpYoutubeRelatedMovies,
    serpYoutubeSecondarySearchContainer,
    serpYoutubeChannel,
    serpYoutubeVideo,
    serpYoutubeShow,
    serpYoutubePlaylist,
    serpYoutubeMovie,
    serpYoutubeLiveStream,
    serpYoutubeRadio,
    serpYoutubeChannelOwned,
    serpYoutubeVideoOwned,
    serpYoutubeShowOwned,
    serpYoutubePlaylistOwned,
    serpYoutubeMovieOwned,
    serpYoutubeLiveStreamOwned,
    serpYoutubeRadioOwned,
    serpThumbnails,
    serpThumbnailsOwned,
    serpDirectAnswer,
    serpJobPosting,
    serpSiteLinksSearchbox,
    serpSiteLinksSearchboxOwned,
    serpThingsTodo,
    serpFeaturedVideo,
    serpFeaturedVideoOwned,
    serpFaq,
    serpFaqOwned,
    serpFlights,
    serpEvents,
    serpResearchProductPosition,
    serpResearchProductPositionOwned,
    serpHotelsPack,
    serpTripsPopular,
    serpPodcasts,
    serpAiOverview,
    serpRelatedSearchesCarousel,
    serpMostPopularCarousel,
    serpRefineCarousel,
    serpResultsAbout,
    serpApps,
    serpDiscoverMorePlaces,
    serpSearchByPhotos,
    serpNewsPosition,
    serpNewsPositionOwned,
    serpRecipesPosition,
    serpRecipesPositionOwned,
    serpVideoCarouselPosition,
    serpVideoCarouselPositionOwned,
    serpRichProductInfo,
    serpRichProductInfoOwned,
    serpFilterMenu,
    serpOrganicStores,
    serpOrganicStoresOwned,
    serpPopularStores,
    serpPopularStoresOwned,
    serpHotelsPosition,
    serpHotelsPositionOwned,
    serpForumPostPosition,
    serpForumPostPositionOwned,
    serpImageProductPackPosition,
    serpImageProductPackPositionOwned,
    serpShopStyles,
    serpShopStylesOwned,
    serpFeatures,
  } = data;
  const pageSerpFeatures = {
    serpAdsTop,
    serpAdsInBetween,
    serpAdsBottom,
    serpShopping,
    serpMapsLocalTeaser,
    serpMapsLocalTeaserOwned,
    serpMapsLocal,
    serpMapsLocalOwned,
    serpRelatedQuestions,
    serpRelatedQuestionsOwned,
    serpCarousel,
    serpImagePack,
    serpReviews,
    serpReviewsOwned,
    serpTweets,
    serpTweetsOwned,
    serpSiteLinks,
    serpSiteLinksOwned,
    serpFeatureSnippet,
    serpFeatureSnippetOwned,
    serpKnowledgePanel,
    serpKnowledgePanelOwned,
    serpKnowledgePanelPriceComparison,
    serpKnowledgePanelPriceComparisonOwned,
    serpKnowledgeCards,
    serpVideo,
    serpVideoOwned,
    serpYoutubeAds,
    serpYoutubePromotedVideo,
    serpYoutubePromotedVideoOwned,
    serpYoutubeRelatedSearches,
    serpYoutubeRelatedToYourSearch,
    serpYoutubeShelfRender,
    serpYoutubeRelatedMovies,
    serpYoutubeSecondarySearchContainer,
    serpYoutubeChannel,
    serpYoutubeVideo,
    serpYoutubeShow,
    serpYoutubePlaylist,
    serpYoutubeMovie,
    serpYoutubeLiveStream,
    serpYoutubeRadio,
    serpYoutubeChannelOwned,
    serpYoutubeVideoOwned,
    serpYoutubeShowOwned,
    serpYoutubePlaylistOwned,
    serpYoutubeMovieOwned,
    serpYoutubeLiveStreamOwned,
    serpYoutubeRadioOwned,
    serpThumbnails,
    serpThumbnailsOwned,
    serpDirectAnswer,
    serpJobPosting,
    serpSiteLinksSearchbox,
    serpSiteLinksSearchboxOwned,
    serpThingsTodo,
    serpFeaturedVideo,
    serpFeaturedVideoOwned,
    serpFaq,
    serpFaqOwned,
    serpFlights,
    serpEvents,
    serpResearchProductPosition,
    serpResearchProductPositionOwned,
    serpHotelsPack,
    serpTripsPopular,
    serpPodcasts,
    serpAiOverview,
    serpRelatedSearchesCarousel,
    serpMostPopularCarousel,
    serpRefineCarousel,
    serpResultsAbout,
    serpApps,
    serpDiscoverMorePlaces,
    serpSearchByPhotos,
    serpNewsPosition,
    serpNewsPositionOwned,
    serpRecipesPosition,
    serpRecipesPositionOwned,
    serpVideoCarouselPosition,
    serpVideoCarouselPositionOwned,
    serpRichProductInfo,
    serpRichProductInfoOwned,
    serpFilterMenu,
    serpOrganicStores,
    serpOrganicStoresOwned,
    serpPopularStores,
    serpPopularStoresOwned,
    serpHotelsPosition,
    serpHotelsPositionOwned,
    serpForumPostPosition,
    serpForumPostPositionOwned,
    serpImageProductPackPosition,
    serpImageProductPackPositionOwned,
    serpShopStyles,
    serpShopStylesOwned,
  };
  let pageSerpFeaturesKeyDis: SerpCellProps['value']['data']['serpFeatures'];

  if (props.isKeyDis && serpFeatures) {
    pageSerpFeaturesKeyDis = serpFeatures;
  }

  type ConvertToLowerCase<S extends string> = S extends `${infer FirstChar}${infer Rest}`
    ? `${Lowercase<FirstChar>}${Rest}`
    : S;

  const convertToLowerCase = <S extends string>(str: S): ConvertToLowerCase<S> => {
    return (str.charAt(0).toLowerCase() + str.slice(1)) as ConvertToLowerCase<S>;
  };

  const getRankSerpFeatures = () => {
    const { serpFeatures: serpFs } = props;
    return serpFs
      ?.filter((s) => {
        if (s?.owned) {
          if (props.isKeyDis) {
            return (
              (pageSerpFeaturesKeyDis?.[`${camelCase(s?.id ?? '')}`] as boolean) ||
              (pageSerpFeaturesKeyDis?.[`${camelCase(s.owned ?? '')}`] as boolean)
            );
          }

          return (
            (pageSerpFeatures[`serp${convertToTitleCase(camelCase(s?.id ?? ''))}`] as boolean) ||
            (pageSerpFeatures[`serp${convertToTitleCase(camelCase(s.owned))}`] as boolean)
          );
        }

        if (props.isKeyDis) {
          return pageSerpFeaturesKeyDis?.[`${camelCase(s?.id ?? '')}`];
        }

        return pageSerpFeatures[`serp${convertToTitleCase(camelCase(s?.id ?? ''))}`];
      })
      .map((s) => {
        return {
          ...getSerpFeatureData(
            convertToLowerCase((s?.id ?? '')?.replace('serp', '')),
            serpFs,
            userSerpFeatures,
          ),
          isOwned: s?.owned
            ? props.isKeyDis
              ? (pageSerpFeaturesKeyDis?.[`${camelCase(s?.owned || '')}`] as boolean)
              : (pageSerpFeatures[
                  `serp${convertToTitleCase(camelCase(s?.owned || ''))}`
                ] as boolean)
            : false,
          serpRank: props.isKeyDis
            ? (pageSerpFeaturesKeyDis?.[`${camelCase(s?.id || '')}`] as boolean)
            : (pageSerpFeatures[`serp${convertToTitleCase(camelCase(s?.id || ''))}`] as boolean),
        };
      });
  };

  //Do match up of page serpFeatures & metadata serp features
  const serpFeaturesData = useMemo(
    () => getRankSerpFeatures(),
    [pageSerpFeatures, pageSerpFeaturesKeyDis, props.serpFeatures],
  );
  return serpFeaturesData as SerpFeaturesItem[];
}

/**
 * Sort by owned first, then alphabetically
 * @param a
 * @param b
 */
const sortSerpFeatures = (a: SerpFeaturesItem, b: SerpFeaturesItem) => {
  if (a.isOwned && !b.isOwned) {
    return -1;
  }

  if (!a.isOwned && b.isOwned) {
    return 1;
  }

  return b.label > a.label ? -1 : 1;
};

const SerpCell = (props: SerpCellProps) => {
  const { inView } = useRowContext();
  const serpFeaturesData = useSerpFeaturesFromTableCellProps(props);
  const MaxVisibleItemsCount = 4;
  const visibleItems = serpFeaturesData.sort(sortSerpFeatures).slice(0, MaxVisibleItemsCount);
  const nonVisibleItemsCount = Math.max(0, serpFeaturesData.length - visibleItems.length);
  if (!inView) return null;
  return (
    <div className={'u-flex u-items-center table-serp-features'}>
      {visibleItems.map((serpFeature) => (
        <SerpIcon
          id={serpFeature.id}
          key={serpFeature.id}
          noTooltip
          owned={serpFeature.isOwned}
          serpRank={serpFeature.serpRank ? serpFeature.serpRank : undefined}
          serpFeatures={props.serpFeatures}
          searchEngineId={props?.value?.data?.searchEngineId}
          withinPortal
        />
      ))}
      {(nonVisibleItemsCount > 0 || visibleItems.length > 0) && (
        <SerpDropdown
          count={nonVisibleItemsCount}
          serpFeaturesData={serpFeaturesData}
          searchEngineId={props?.value?.data?.searchEngineId}
        />
      )}
    </div>
  );
};

export default SerpCell;
