/* eslint-disable radix */

/* eslint-disable react/display-name */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Progress, SimpleGrid, Skeleton } from '@mantine/core';
import arrayMutators from 'final-form-arrays';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import { v4 as uuidv4 } from 'uuid';
import { useTableStore } from 'Components/DataTable';
import { Form } from 'Components/Fields';
import { linkToKeywordsDomain } from 'Components/Filters/LinkToDomain';
import linkWithFilters from 'Components/Filters/linkWithFilters';
import { Gutter } from 'Components/Gutter';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import {
  EnableOveruseModal,
  RequestEnableOveruseModal,
  RequestUpgradePlanModal,
  UpgradePlanModal,
} from 'Components/OveruseConfirmation';
import {
  AddKeywordsInputNew,
  CountByCountryNode,
  DomainInfoAddKeywordsQuery,
  DomainsDomainDomainTypeChoices,
  EnableOveruseArea,
  KeywordOveruseCode,
  useAddKeywordsMutation,
  useImportKeydisAddKeywordsMutation,
} from 'Ghql';
import { useFilters } from 'Hooks';
import { useModal } from 'Hooks/base/useModal';
import toast, { MAX_NOTIFICATION_TIMEOUT } from 'Hooks/useToast';
import { FilterBase } from 'Types/Filter';
import { KEYWORDS_FILTER_SET } from 'Types/FilterSet';
import { TableIDs } from 'Types/Table';
import { EventName, trackEventMixpanel } from 'Utilities/Analytics/mixpanel';
import { appStorage } from 'Utilities/AppStorage';
import { useInvalidateFullCache } from 'Utilities/Graphql/invalidateCache';
import { useHistory } from 'Utilities/Router/withRouter';
import { ErrorsFromServer } from 'Utilities/errors';
import { t, tct } from 'Utilities/i18n';
import AdvancedSettingsInput from './AdvancedSettingsInput';
import Footer from './Footer';
import ImportFromCSVInput from './ImportFromCSVInput';
import ImportFromGSCInput from './ImportFromGSCInput';
import KeywordOveruseNotice, { useKeywordOveruseNoticeLoading } from './KeywordOveruseNotice';
import KeywordSuggestionsInput from './KeywordSuggestionsInput';
import KeywordsInput from './KeywordsInput';
import SearchEnginesInput from './SearchEnginesInput';
import TagsInput from './TagsInput';
import { useDomainInfo, useShowSuggestionsState } from './hooks';
import {
  AddKeywordsModalType,
  AddKeywordsMode,
  FieldName,
  FormState,
  KeydisImportConfig,
  MappedSearchEngine,
  ShowSuggestionsState,
} from './types';
import { transformImportKeydisKeywordsInput } from './utils';
import styles from './addKeywords.module.scss';

type GeneralProps = {
  // from showModal modalProps, see src/Components/Modal/index.tsx
  domainId: string;
  refresh?: () => void;
  getKeywords?: () => any; // TODO: only used by showDuplicateModal?
};

type AddDomainProps = {
  redirectOnSubmit: boolean;
};

type GoogleSearchConsoleProps = {
  gscKeywords?: { keyword: string }[];
};

type KeydisProps = {
  mode?: AddKeywordsModalType;
  placeholderKeywords?: { keyword: string }[];
  keydisKeywordsCounter?: number;
  selectedCountry?: CountByCountryNode; // Seems to be prop drilled in build domain form, but is there ever actually a value?
  importConfig?: KeydisImportConfig;
  overwriteFilters?: FilterBase[];
  getKeydisKeywords?: () => any;
};

type Props = GeneralProps & AddDomainProps & GoogleSearchConsoleProps & KeydisProps;

type FormContentProps = { mode: NonNullable<Props['mode']> } & Pick<
  Props,
  'domainId' | 'selectedCountry' | 'keydisKeywordsCounter'
> &
  ShowSuggestionsState & {
    responseCode: KeywordOveruseCode | null;
  };

const FormContent = ({
  mode,
  domainId,
  showSuggestions,
  setShowSuggestions,
  selectedCountry,
  keydisKeywordsCounter,
  responseCode,
}: FormContentProps) => {
  const keywordOveruseNoticeLoading = useKeywordOveruseNoticeLoading(
    mode ?? AddKeywordsMode.DEFAULT,
  );

  const SkeletonHOC = useCallback(
    <P extends object>(Component: React.ComponentType<P>) => {
      return (props: P) => {
        if (keywordOveruseNoticeLoading) {
          return (
            <Skeleton visible={keywordOveruseNoticeLoading}>
              <Component {...props} />
            </Skeleton>
          );
        }
        // Skeleton breaks layout of TagsInput when it's not visible, due to transform: translateZ(0) on Skeleton root, so we need to omit it
        return <Component {...props} />;
      };
    },
    [keywordOveruseNoticeLoading],
  );

  const WithSkeleton = useMemo(
    () => ({
      KeywordsInput: SkeletonHOC(KeywordsInput),
      KeywordSuggestionsInput: SkeletonHOC(KeywordSuggestionsInput),
      ImportFromGSCInput: SkeletonHOC(ImportFromGSCInput),
      ImportFromCSVInput: SkeletonHOC(ImportFromCSVInput),
      SearchEnginesInput: SkeletonHOC(SearchEnginesInput),
      TagsInput: SkeletonHOC(TagsInput),
      AdvancedSettingsInput: SkeletonHOC(AdvancedSettingsInput),
      Footer: SkeletonHOC(Footer),
    }),
    [SkeletonHOC],
  );

  return (
    <>
      <SimpleGrid cols={2} w="max-content">
        <div>
          <WithSkeleton.KeywordsInput mode={mode ?? AddKeywordsMode.DEFAULT} domainId={domainId} />
          {mode === AddKeywordsMode.DEFAULT && (
            <>
              <Gutter />
              <WithSkeleton.KeywordSuggestionsInput
                showSuggestions={showSuggestions}
                setShowSuggestions={setShowSuggestions}
              />
            </>
          )}

          {mode === AddKeywordsMode.DEFAULT && (
            <>
              <Gutter />
              <WithSkeleton.ImportFromGSCInput
                showSuggestions={showSuggestions}
                domainId={domainId}
              />
            </>
          )}

          {mode === AddKeywordsMode.DEFAULT && (
            <>
              <Gutter />
              <WithSkeleton.ImportFromCSVInput showSuggestions={showSuggestions} />
            </>
          )}
        </div>

        <div>
          <WithSkeleton.SearchEnginesInput domainId={domainId} selectedCountry={selectedCountry} />

          <Gutter />
          <WithSkeleton.TagsInput />

          <Gutter />
          <WithSkeleton.AdvancedSettingsInput />
        </div>
      </SimpleGrid>

      <Gutter />

      <KeywordOveruseNotice
        mode={mode ?? AddKeywordsMode.DEFAULT}
        keydisKeywordsCounter={keydisKeywordsCounter}
      />

      <WithSkeleton.Footer
        mode={mode ?? AddKeywordsMode.DEFAULT}
        responseCode={responseCode}
        keydisKeywordsCounter={keydisKeywordsCounter}
      />
    </>
  );
};

const useDomainIsNaver = (domainId: string) => {
  const domainInfo = useDomainInfo(domainId);
  return domainInfo?.domainType === DomainsDomainDomainTypeChoices.A_7;
};

const useDomainName = (domainId: string) => {
  const domainInfo = useDomainInfo(domainId);
  return domainInfo?.displayName || domainInfo?.domain;
};

const useDefaultEngines = (domainId: string): MappedSearchEngine[] | null => {
  const domainInfo = useDomainInfo(domainId);
  if (!domainInfo) {
    return null;
  }
  type DefaultSearchSetting = NonNullable<
    NonNullable<NonNullable<DomainInfoAddKeywordsQuery['domain']>['defaultSearchSettings']>[number]
  >; // Fixing overly nullable generated type
  type SearchEngine = NonNullable<NonNullable<DefaultSearchSetting['searchEngines']>[number]>; // Fixing overly nullable generated type

  return (domainInfo.defaultSearchSettings as DefaultSearchSetting[]).map((searchSetting) => ({
    countrylocale: searchSetting.countrylocale.id,
    locations: searchSetting.locations as string[],
    searchEngines: (searchSetting.searchEngines as SearchEngine[]).map(
      ({ searchEngine, searchTypes }) => ({
        id: searchEngine.id,
        name: searchEngine.name.toLowerCase(),
        searchTypes,
      }),
    ),
    settings: {
      ignoreLocalResults: searchSetting.ignoreLocalResults,
      ignoreFeaturedSnippet: searchSetting.ignoreFeaturedSnippet,
      enableAutocorrect: searchSetting.enableAutocorrect,
    },
  }));
};

export default ({
  domainId,
  refresh,
  getKeywords,
  redirectOnSubmit,
  gscKeywords,
  ...keydisProps
}: Props) => {
  const [selectedCountry, setSelectedCountry] = useState(keydisProps.selectedCountry);
  const [loadedKeywords, setLoadedKeywords] = useState(gscKeywords);
  const [responseCode, setResponseCode] = useState<KeywordOveruseCode | null>(null);
  const [retrySubmitData, setRetrySubmitData] = useState<FormState | null>(null);

  const domainName = useDomainName(domainId);
  const domainIsNaver = useDomainIsNaver(domainId);
  const defaultEngines = useDefaultEngines(domainId);

  const showSuggestionsState = useShowSuggestionsState();

  const [_importKeydisKeywords] = useImportKeydisAddKeywordsMutation();
  const [_addKeywords] = useAddKeywordsMutation();

  const { hideModal } = useModal();
  const filters = useFilters();
  const history = useHistory();
  const invalidateFullCache = useInvalidateFullCache();
  const keydisTableStore = useTableStore(TableIDs.KEYWORD_DISCOVERY);

  useEffect(() => {
    getKeywords?.().then((e) => {
      setLoadedKeywords(e?.data?.keywords?.keywords ?? null);
    });

    if (keydisProps.placeholderKeywords) {
      if (keydisProps.placeholderKeywords.length >= 100) {
        setLoadedKeywords([
          ...keydisProps.placeholderKeywords,
          {
            keyword: '...',
          },
        ]);
      } else {
        setLoadedKeywords(keydisProps.placeholderKeywords);
      }
      // eslint-disable-next-line brace-style
    } else if (keydisProps.getKeydisKeywords) {
      keydisProps.getKeydisKeywords?.().then((e) => {
        const placeholderKeywordsImmutable = e?.data?.keydisImportInfo?.keydisImportInfo?.keywords;
        const placeholderKeywords = placeholderKeywordsImmutable
          ? [...placeholderKeywordsImmutable]
          : undefined;
        if (placeholderKeywords && placeholderKeywords.length === 500) {
          if (keydisProps.keydisKeywordsCounter) {
            placeholderKeywords.push({
              keyword: `...and ${
                keydisProps.keydisKeywordsCounter - placeholderKeywords.length
              } more`,
            });
          }
        }
        setSelectedCountry({
          country: e?.data?.keydisImportInfo?.keydisImportInfo?.country,
          searchSettings: [e?.data?.keydisImportInfo?.keydisImportInfo?.searchSettings],
        });
        setLoadedKeywords(placeholderKeywords);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addKeywordJobId = uuidv4();

  const importKeydisKeywords = async (data: FormState) => {
    const input = transformImportKeydisKeywordsInput({
      data,
      selectedCountry,
      domainId,
      filters: keydisProps.overwriteFilters || filters,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      importConfig: keydisProps.importConfig!,
      addKeywordJobId,
    });

    try {
      const res = await _importKeydisKeywords({
        variables: {
          input,
        },
      });

      const code = (res.data?.importKeydisKeywords?.code ?? null) as KeywordOveruseCode | null;
      const errors = (res.data?.importKeydisKeywords?.errors ?? null) as ErrorsFromServer | null;

      if (errors !== null && !isEmpty(errors)) {
        return { code, error: errors[0].messages[0] };
      }

      return { code, error: null };
    } catch (e) {
      return { code: null, error: t('Something went wrong') };
    }
  };

  const mapInput = (data: FormState): AddKeywordsInputNew => {
    return {
      domain: domainId,
      keywords: data.keywords,
      starred: data.keywordSettings.starred,
      ignoreInShareOfVoice: data.keywordSettings.ignoreInShareOfVoice,
      tags: data.tags,
      locales: data.engines?.map(({ searchEngines, settings, countrylocale, ...rest }) => ({
        ...rest,
        ...settings,
        countrylocale: parseInt(countrylocale),
        searchEngines: searchEngines
          .filter((item) => item.searchTypes.length)
          .map((searchEngine) => ({
            id: parseInt(searchEngine.id),
            searchTypes: searchEngine.searchTypes,
          })),
      })),
      // country: selectedCountry?.country,
      addKeywordJobId,
    };
  };

  const addKeywords = async (data: FormState) => {
    const input = mapInput(data);

    try {
      const result = await _addKeywords({
        variables: {
          input,
        },
      });

      const code = (result.data?.addKeywordsNew?.code ?? null) as KeywordOveruseCode | null;
      const errors = (result.data?.addKeywordsNew?.errors ?? null) as ErrorsFromServer | null;

      if (errors !== null && !isEmpty(errors)) {
        return { code, error: errors[0].messages[0] };
      }

      return { code, error: null };
    } catch (e) {
      return { code: null, error: t('Something went wrong') };
    }
  };

  const onSuccess = (submitKeywords: FormState['keywords']) => {
    appStorage.clearKeywordModalContent();
    invalidateFullCache();
    hideModal();
    redirectOnSubmit && history.push(linkToKeywordsDomain(domainId));
    keydisTableStore?.getData(true);
    if (keydisProps.mode === AddKeywordsMode.IMPORT) {
      keydisTableStore?.resetSelection();
    }
    trackEventMixpanel(EventName.AddKeywordsSuccess, '', {
      Count: submitKeywords?.length,
      'Is Keydis': keydisProps.mode === AddKeywordsMode.IMPORT,
    });

    if (gscKeywords) {
      const link = linkWithFilters({ to: '/keywords/list', filterSet: KEYWORDS_FILTER_SET });
      history.replace(link);
    }

    // Show status update for all but naver
    if (!domainIsNaver) {
      toast.info(
        <div>
          {t('Keywords added to queue..')}
          <Progress.Root size="xl">
            <Progress.Section value={0}>
              <Progress.Label>0%</Progress.Label>
            </Progress.Section>
          </Progress.Root>
        </div>,
        {
          id: addKeywordJobId,
          autoClose: MAX_NOTIFICATION_TIMEOUT,
          loading: true,
          title: domainName
            ? tct('Adding keywords to [domainName]...', {
                domainName,
              })
            : t('Adding keywords...'),
        },
      );
    }

    refresh?.();
  };

  const handleSubmit = async (data: FormState) => {
    // clean line-feed character (inserted because the AceEditor component has newLineMode: 'windows')
    data[FieldName.KEYWORDS] = data[FieldName.KEYWORDS].map((keyword) =>
      keyword.replace(/\r/g, ''),
    );

    let code: KeywordOveruseCode | null = null;
    let error: string | null = null;
    if (keydisProps.mode === AddKeywordsMode.IMPORT) {
      ({ code, error } = await importKeydisKeywords(data));
    } else {
      ({ code, error } = await addKeywords(data));
    }
    if (error !== null) {
      toast.error(error);
    }
    if (code !== null) {
      setResponseCode(code);
      setRetrySubmitData(data);
      return;
    }

    onSuccess(data.keywords);
  };

  const loading = !(defaultEngines && (keydisProps.getKeydisKeywords ? loadedKeywords : true));

  if (loading) {
    // Loading
    return null;
  }

  return (
    <ModalBorder title={t('Add Keywords')} className={styles.modal} onClose={hideModal}>
      <Form<FormState>
        keepDirtyOnReinitialize // Necessary for SearchEnginesInput to work, otherwise it will reset the form
        mutators={{
          ...arrayMutators, // Necessary for SearchEnginesInput to work
        }}
        onSubmit={handleSubmit}
        initialValues={{
          [FieldName.KEYWORDS]: uniq(
            loadedKeywords?.map(({ keyword }) => keyword) ??
              appStorage.getKeywordModalContent(domainId),
          ),
          [FieldName.TAGS]: [],
          [FieldName.ENGINES]: defaultEngines,
          [FieldName.KEYWORD_SETTINGS]: {
            starred: false,
            ignoreInShareOfVoice: false,
          },
        }}
      >
        {responseCode === KeywordOveruseCode.UpgradePlanActiveLimit && (
          <UpgradePlanModal
            defaultOpened
            onClose={() => {
              setResponseCode(null);
              setRetrySubmitData(null);
            }}
          />
        )}
        {responseCode === KeywordOveruseCode.UpgradePlanTrackedLimit && (
          <UpgradePlanModal
            defaultOpened
            onClose={() => {
              setResponseCode(null);
              setRetrySubmitData(null);
            }}
            trackedLimit
          />
        )}

        {responseCode === KeywordOveruseCode.RequestUpgradePlanActiveLimit && (
          <RequestUpgradePlanModal
            defaultOpened
            onClose={() => {
              setResponseCode(null);
              setRetrySubmitData(null);
            }}
          />
        )}
        {responseCode === KeywordOveruseCode.RequestUpgradePlanTrackedLimit && (
          <RequestUpgradePlanModal
            defaultOpened
            onClose={() => {
              setResponseCode(null);
              setRetrySubmitData(null);
            }}
            trackedLimit
          />
        )}

        {responseCode === KeywordOveruseCode.EnableOveruseActiveLimit && (
          <EnableOveruseModal
            defaultOpened
            area={
              keydisProps.mode === AddKeywordsMode.IMPORT
                ? EnableOveruseArea.AddKeydisKeywords
                : EnableOveruseArea.AddKeywords
            }
            onEnableOveruseSuccess={() => {
              if (!retrySubmitData) {
                toast.error(t('Something went wrong'));
                return;
              }
              handleSubmit(retrySubmitData);
            }}
            onClose={() => {
              setResponseCode(null);
              setRetrySubmitData(null);
            }}
          />
        )}
        {responseCode === KeywordOveruseCode.EnableOveruseTrackedLimit && (
          <EnableOveruseModal
            defaultOpened
            area={
              keydisProps.mode === AddKeywordsMode.IMPORT
                ? EnableOveruseArea.AddKeydisKeywords
                : EnableOveruseArea.AddKeywords
            }
            onEnableOveruseSuccess={() => {
              if (!retrySubmitData) {
                toast.error(t('Something went wrong'));
                return;
              }
              handleSubmit(retrySubmitData);
            }}
            onClose={() => {
              setResponseCode(null);
              setRetrySubmitData(null);
            }}
            trackedLimit
          />
        )}

        {responseCode === KeywordOveruseCode.RequestEnableOveruseActiveLimit && (
          <RequestEnableOveruseModal
            defaultOpened
            onClose={() => {
              setResponseCode(null);
              setRetrySubmitData(null);
            }}
          />
        )}
        {responseCode === KeywordOveruseCode.RequestEnableOveruseTrackedLimit && (
          <RequestEnableOveruseModal
            defaultOpened
            onClose={() => {
              setResponseCode(null);
              setRetrySubmitData(null);
            }}
            trackedLimit
          />
        )}

        <FormContent
          mode={keydisProps.mode ?? AddKeywordsMode.DEFAULT}
          domainId={domainId}
          selectedCountry={selectedCountry}
          responseCode={responseCode}
          keydisKeywordsCounter={keydisProps.keydisKeywordsCounter}
          {...showSuggestionsState}
        />
      </Form>
    </ModalBorder>
  );
};
