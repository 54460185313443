import { useApolloClient } from '@apollo/client';
import { ColumnType, DataTable, QueryOrder, TableFetchDataCallBack } from 'Components/DataTable';
import { SORTING_TYPE } from 'Constants';
import {
  PendingMultiAccountOwnerFragment,
  PendingMultiAccountOwnersDocument,
  PendingMultiAccountOwnersQuery,
  PendingMultiAccountOwnersQueryVariables,
  useAcceptMultiAccountAccessMutation,
  useDenyMultiAccountAccessMutation,
} from 'Ghql';
import toast from 'Hooks/useToast';
import { TableIDs } from 'Types/Table';
import { t } from 'Utilities/i18n';
import { notEmpty } from 'Utilities/underdash';
import { ExternalAccessTablesProps } from '../components/ExternalAccessTables';
import { ColumnIDs } from './ColumnIDs';
import tableStyles from '../ownersTable.module.scss';

const useFetchData = (): TableFetchDataCallBack => {
  const client = useApolloClient();

  return async () => {
    return client
      .query<PendingMultiAccountOwnersQuery, PendingMultiAccountOwnersQueryVariables>({
        query: PendingMultiAccountOwnersDocument,
        fetchPolicy: 'network-only',
      })
      .then((response) => {
        const pendingOwners =
          response.data?.user?.organization?.pendingMultiAccountOwners?.filter(notEmpty) || [];

        return { data: pendingOwners, length: pendingOwners?.length ?? 0 };
      });
  };
};

const PendingOwnersTable = ({ dataKey, updateTables }: ExternalAccessTablesProps) => {
  const fetchData = useFetchData();

  const [removeMultiAccountRequest] = useDenyMultiAccountAccessMutation();

  const [acceptMultiAccountRequest] = useAcceptMultiAccountAccessMutation();

  const handleAcceptRequest = (id: string) => {
    acceptMultiAccountRequest({
      variables: {
        input: {
          id,
        },
      },
    }).then((res) => {
      const errors = res.data?.acceptMultiAccountRequest?.errors || [];
      if (!errors.length) {
        toast.success(t('Organization added'));
        updateTables();
      } else {
        toast.error(t('Unable to accept organization'));
      }
    });
  };

  const handleRemoveRequest = (id: string) => {
    removeMultiAccountRequest({
      variables: {
        input: {
          id,
          isOwner: false,
        },
      },
    }).then((res) => {
      const errors = res.data?.removeMultiAccountRequest?.errors || [];
      if (!errors.length) {
        toast.success(t('Organization denied'));
        updateTables();
      } else {
        toast.error(t('Unable to deny organization'));
      }
    });
  };

  const columns: ColumnType<PendingMultiAccountOwnerFragment, {}>[] = [
    {
      id: ColumnIDs.ORGANIZATION_NAME,
      title: t('Organization Name'),
      flex: true,
      width: 100,
      cellRenderer: (props) => <span>{props.record.fromOrganization?.name}</span>,
      onHeaderCell: () => ({
        ordering: {
          defaultOrder: QueryOrder.DESC,
          orderBy: 'fromOrganization.name',
          sortingKey: SORTING_TYPE.alphabetical,
        },
      }),
    },
    {
      id: ColumnIDs.USER_TYPE,
      title: t('Account access as'),
      flex: true,
      width: 50,
      cellRenderer: (props) => (
        <span>{props.record.isOrgAdmin ? t('Admin user') : t('Normal user')}</span>
      ),
      onHeaderCell: () => ({
        ordering: {
          defaultOrder: QueryOrder.DESC,
          orderBy: 'isOrgAdmin',
          sortingKey: SORTING_TYPE.alphabetical,
        },
      }),
    },
    {
      id: ColumnIDs.ACTIONS,
      title: t('Actions'),
      width: 150,
      cellRenderer: (props) => (
        <span>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleAcceptRequest(props.record.id);
            }}
          >
            {t('Accept')}
          </a>
          <span> / </span>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleRemoveRequest(props.record.id);
            }}
          >
            {t('Deny')}
          </a>
        </span>
      ),
    },
  ];

  return (
    <DataTable
      tableId={TableIDs.EXTERNAL_ACCESS_PENDING_OWNERS}
      dataKey={dataKey.toString()}
      fetchData={fetchData}
      columns={columns}
      emptyOptions={{
        title: t('No Data'),
        subTitle: t('There are currently no organizations with external access.'),
      }}
      offlineFilter={{
        tableName: TableIDs.EXTERNAL_ACCESS_PENDING_OWNERS,
        skipAll: true,
        mappings: {
          [ColumnIDs.ORGANIZATION_NAME]: 'fromOrganization.name',
          [ColumnIDs.USER_TYPE]: 'isOrgAdmin',
        },
      }}
      className={tableStyles.pendingOwnersTable}
    />
  );
};

export default PendingOwnersTable;
