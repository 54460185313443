import { useCallback } from 'react';
import noop from 'lodash/noop';
import { useLogout } from 'Hooks/actions/useLogout';
import { useModal } from 'Hooks/base/useModal';
import { useUser } from 'Hooks/data/user/useUser';
import {
  useSmartRouterSubscription,
  useStableNavigate,
} from '../../../../../../Utilities/Router/components/LocationPathnameContext';

export const useAccountInfoActions = () => {
  const user = useUser();
  const { showModal } = useModal();
  const isRequirePageRefresh = useSmartRouterSubscription((pathname) => {
    return !!pathname?.includes?.('/account/users');
  });
  const logout = useLogout();
  const navigate = useStableNavigate();

  const handleSignOut = useCallback(() => {
    logout() ?? false;
  }, [logout]);

  const handleAffiliate = useCallback(() => {
    if (user?.isAffiliate) {
      navigate('/affiliate');
    } else {
      window.open('https://www.accuranker.com/affiliate', '_blank');
    }
  }, [user, navigate]);

  const handleAddUser = useCallback(() => {
    showModal({
      modalType: 'AddUser',
      modalTheme: 'light',
      modalProps: {
        refreshPageAction: isRequirePageRefresh,
        refresh: noop,
      },
    });
  }, [showModal, isRequirePageRefresh]);

  const handleShowShortcuts = useCallback(() => {
    showModal({
      modalType: 'Shortcuts',
      modalTheme: 'light',
    });
  }, [showModal]);

  return { handleSignOut, handleAffiliate, handleAddUser, handleShowShortcuts };
};
