import { Box } from '@mantine/core';
import * as Sentry from '@sentry/react';
import Alert from 'Components/Alert';
import Skeleton from 'Components/Skeleton';
import { t, tct } from 'Utilities/i18n';

type Props = {
  loadingQuery: boolean;
  name: string | null;
  errors?: string;
  isOrgAdmin?: boolean;
  isExpiredLink: boolean;
  inviteSentFromOwnAccount: boolean | null;
  isExistingLink: boolean;
  existingMultiAccountName?: string | null;
};
export default function MultiAccountRequestTextBlock(props: Props) {
  if (props.loadingQuery) {
    return (
      <div className="base-card-body multi-account-card-body" data-testid="multi-acc-skeleton">
        <Skeleton
          linesConfig={[
            {
              type: 'text',
              options: {
                width: '120px',
                marginBottom: '20px',
                height: '20px',
              },
            },
            {
              type: 'text',
              options: {
                width: '400px',
              },
            },
            {
              type: 'text',
              options: {
                width: '300px',
              },
            },
          ]}
        />
      </div>
    );
  } else if (props.inviteSentFromOwnAccount) {
    return (
      <div className="base-card-body multi-account-card-body" data-testid="multi-acc-sent-from-own">
        <p className="alert alert-warning">{t('You cannot accept your own invitation.')}</p>
      </div>
    );
  } else if (props.isExpiredLink) {
    return (
      <div className="base-card-body multi-account-card-body" data-testid="multi-acc-expired">
        <p className="alert alert-warning">
          {t('Invitation has expired. Please request a new invitation.')}
        </p>
      </div>
    );
  } else if (props.isExistingLink) {
    return (
      <div className="base-card-body multi-account-card-body" data-testid="multi-acc-existing">
        <p className="alert alert-warning">
          {tct('You have already granted access to organization [name].', {
            name: props.existingMultiAccountName,
          })}
        </p>
      </div>
    );
  } else if (props.name === null) {
    Sentry.captureException(new Error('Invalid state'));
    return (
      <div className="base-card-body multi-account-card-body" data-testid="multi-acc-invalid-state">
        <p className="alert alert-warning">{t('Something went wrong. Please try again later.')}</p>
      </div>
    );
  }
  return (
    <div className="base-card-body multi-account-card-body">
      <h2>{t('Allow access')}</h2>
      <p>{t('Allow user from %s to access your account?', props.name)}</p>
      {props.isOrgAdmin && <p>{t('Note that admin access will be given to the users.')}</p>}
      {props.errors && (
        <Box mt="md">
          <Alert type="danger" className="error-with-action">
            {props.errors}
          </Alert>
        </Box>
      )}
    </div>
  );
}
