import { Switch, SwitchProps, useMantineTheme } from '@mantine/core';
import merge from 'lodash/merge';
import variables from 'css/base/variables.module.scss';

export type AccSwitchProps = {
  nowrap?: boolean;
} & SwitchProps;
const AccSwitch = (props: AccSwitchProps) => {
  const { nowrap, styles, disabled, ...rest } = props;

  const theme = useMantineTheme();

  const defaultStyles: typeof styles = {
    root: { display: 'flex', alignItems: 'center' },
    track: { cursor: 'pointer' },
    label: {
      cursor: 'pointer',
      margin: 0,
      fontSize: theme.fontSizes.xs,
      paddingLeft: theme.other.gutterSm,
      lineHeight: 1.55,
      height: '100%',
      userSelect: 'none',
      color: variables.textPrimary,
    },
    body: { marginBottom: 0, alignItems: 'center' },
    labelWrapper: { display: 'flex', alignItems: 'center', flexDirection: 'row' },
    trackLabel: { fontSize: theme.fontSizes.xs },
  };

  const nowrapStyles: typeof styles = {
    label: { whiteSpace: 'nowrap' },
  };

  const disabledStyles: typeof styles = {
    track: { cursor: 'not-allowed !important' },
    label: { cursor: 'not-allowed !important' },
  };

  return (
    <Switch
      color="snorlax"
      styles={merge(defaultStyles, nowrap && nowrapStyles, disabled && disabledStyles, styles)}
      disabled={disabled}
      {...rest}
    />
  );
};

export default AccSwitch;
