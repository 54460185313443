import AccTooltip from 'Components/AccTooltip/AccTooltip';
import Cell from 'Components/AccuTable/CellRenderer/HelperComponents/AccuCell';
import ValueIndicator from 'Components/ValueIndicator/value-indicator';
import { nullOrUndefined } from 'Utilities/format';
import { t } from 'Utilities/i18n';

interface Props {
  currentValue?: number | null;
  delta?: number | null;
}

export const ChangeCell = ({ currentValue, delta }: Props) => {
  if (nullOrUndefined(currentValue) || nullOrUndefined(delta)) {
    return (
      <Cell rightAligned>
        <AccTooltip tooltip={t('No value for this date')}>
          <span>?</span>
        </AccTooltip>
      </Cell>
    );
  }
  return (
    <Cell className="accu-table-cell__share-of-voice-change" rightAligned>
      <ValueIndicator
        beforeValue={currentValue || null}
        afterValue={delta || null}
        style="decimal"
      />
    </Cell>
  );
};
