import React from 'react';
import { ROW_SIZE } from '../../../../../Components/Table/VirtualizedTable/support/constants';
import { devError } from '../../../../../Utilities/log';
import { TreeState } from '../react-dnd-treeview/types';
import { DEEPNESS_INTEND, ROOT_ID, ROOT_WRAPPER_ID } from '../support/constants';

const TARGET_LINE_ID = 'target-line';

const getLine = (): HTMLElement | null => {
  return document.getElementById(TARGET_LINE_ID);
};

export const hideLine = (updatePosition?: boolean) => {
  const lineElement = getLine();

  if (lineElement?.style) {
    lineElement.style.opacity = '0';

    if (updatePosition) {
      lineElement.style.top = '0px';
    }
  }
};

const CONTAINER_TOP_OFFSET = 100;

const findPosition = (items, expandedFolders, targetId) => {
  let position = 0;

  let stack = [...items];

  let next = stack.shift();

  while (next) {
    if (next.id === targetId) {
      return position;
    }

    position++;
    if (next.isFolder && expandedFolders.includes(next.id)) {
      stack = [...(next.children || []), ...stack];
    }
    next = stack.shift();
  }

  return position;
};

const calculateItemTopPosition = (treeContext: TreeState, id: string | number) => {
  const isRootTarget = id === ROOT_ID;

  if (isRootTarget) {
    return CONTAINER_TOP_OFFSET;
  }

  let index = findPosition(treeContext.tree, treeContext.expandedNodes, id);
  index = index === -1 ? 0 : index;

  return CONTAINER_TOP_OFFSET + index * ROW_SIZE;
};

export const moveLine = (
  treeContext: TreeState,
  id?: string | number,
  direction?: string,
  indexParam?: number,
): void => {
  const isRootTarget = id === ROOT_ID;
  const rootWrapper = document.getElementById(ROOT_WRAPPER_ID)!;
  const elem = document.querySelector(`[data-drop-id="${id}"]`) as HTMLDivElement;

  const index = indexParam;

  const deepness = isRootTarget ? 0 : parseInt(elem?.getAttribute('data-deepness') ?? '0') || 0;
  const lineElement = getLine();

  if (!lineElement || (!id && !isRootTarget) || !rootWrapper) {
    devError('Missing one of: ', lineElement, id, rootWrapper);
    return;
  }

  let topOffset = calculateItemTopPosition(treeContext, id);

  let leftOffset = 0;
  let width = rootWrapper?.getBoundingClientRect().width;

  if (deepness) {
    leftOffset += deepness * DEEPNESS_INTEND;
    width -= deepness * DEEPNESS_INTEND;
  }

  if (direction === 'center') {
    hideLine();
    return;
  } else if (isRootTarget) {
    topOffset += ROW_SIZE * (index || 0);
  } else if (direction === 'down') {
    topOffset += ROW_SIZE;
  }

  lineElement.style.opacity = '1';
  lineElement.style.width = `${width}px`;
  lineElement.style.top = `${topOffset}px`;
  lineElement.style.left = `${leftOffset}px`;
};

export const TargetLine = () => {
  return (
    <div
      id={TARGET_LINE_ID}
      style={{
        background: '#4B4F69',
        minHeight: '2px',
        minWidth: '1px',
        position: 'absolute',
        transition: 'top .15s',
        pointerEvents: 'none',
        zIndex: 1000,
        opacity: 0,
        top: 0,
        left: '140px',
      }}
    />
  );
};
