/* eslint-disable react/no-did-update-set-state */
import { Component } from 'react';
import { connect } from 'react-redux';
import AccButton from 'Components/AccButton/AccButton';
import { withRouter } from 'Utilities/Router';
import { initLanguage, t } from 'Utilities/i18n';
import StopImpersonating from '../../../Components/ImpersonateDropdown/StopImpersonating/index';
import '../error-page.scss';

class BlockedError extends Component {
  constructor(props) {
    super(props);
    initLanguage();
  }

  renderLogoutButton = () => (
    <AccButton
      fullWidth
      variant="destructive"
      onClick={(this as any).logoutClick}
      href={'/org/multiaccount/logout/'}
      target="_self"
      h={40}
    >
      {t('Logout from account')}
    </AccButton>
  );

  render() {
    const {
      user: {
        organization: { accountBlockedReason },
      },
    }: any = this.props;
    return (
      <div className="error-page">
        <div
          style={{
            display: 'flex',
          }}
        >
          {document.cookie.includes('multiaccount') ? (
            <div className={'log-out-buttons'}> {this.renderLogoutButton()} </div>
          ) : (
            ''
          )}
          {document.cookie.includes('impersonate') ? (
            <div className={'log-out-buttons'}>
              {' '}
              <StopImpersonating />{' '}
            </div>
          ) : (
            ''
          )}
        </div>
        {accountBlockedReason?.map((reason, i) => (
          <div key={i}>
            <h1 className="title">{reason.title}</h1>
            <p className="description">{reason.message}</p>
          </div>
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

// TODO FixTSignore
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default withRouter(connect(mapStateToProps, null)(BlockedError));
