import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { useModal } from 'Hooks/base/useModal';
import toast from 'Hooks/useToast';
import { t } from 'Utilities/i18n';
import { useAddWalletItemMutation } from '../../../../Ghql';
import AddWalletItemForm from './AddWalletItemForm';

type Props = {
  organizationId: number;
};

const AddWalletItem = ({ organizationId }: Props) => {
  const { hideModal } = useModal();
  const [addWalletItemMutation, { loading }] = useAddWalletItemMutation();

  const handleSubmit = (form) => {
    const { description, customerOwesUs = false, amount, currency } = form;
    addWalletItemMutation({
      variables: {
        input: {
          organizationId: `${organizationId}`,
          amount: parseFloat(amount),
          currency,
          customerOwesUs,
          description,
        },
      },
    })
      .then((resp) => {
        const err1 = resp.errors ?? [];
        const err2 = resp.data?.createWallet?.errors ?? [];
        const errs = [...err1, ...err2];
        if (errs.length > 0) {
          throw 'Request failed';
        } else {
          toast.success(t('Wallet item added'));
        }
      })
      .catch((err) => {
        console.error('Add wallet item error:', err);
        toast.error(t('Request failed'));
      })
      .finally(hideModal);
  };

  return (
    <ModalBorder className="add-wallet-item" title={t('Add Wallet Item')} onClose={hideModal}>
      <AddWalletItemForm loading={loading} onSubmit={handleSubmit} />
    </ModalBorder>
  );
};

export default AddWalletItem;
