import { useCallback } from 'react';
import { useStore } from 'react-redux';
import { DocumentNode, FetchPolicy, OperationVariables, useApolloClient } from '@apollo/client';
import pick from 'lodash/pick';
import { TableFetchDataCallBack } from 'Components/DataTable';
import { TableStoreType } from 'Components/DataTable/store/TableStore';
import {
  TableFetchDataCallBackReturn,
  TableFetchDataCallbackParams,
} from 'Components/DataTable/types';
import {
  getEnabledColumnQueriesByTable,
  getIsValidOrdering,
} from 'Components/Modal/Content/TableSettingsModal/support/helpers';
import { OrderingInput } from 'Query';
import { selectCurrentFilters } from 'Selectors/FilterSelector';
import { FilterBase } from 'Types/Filter';
import { devError } from 'Utilities/log';

interface UseFetchTableDataParams<Response, Params, Data> {
  tableId: string;
  query: DocumentNode;
  getVariables?: (p: TableFetchDataCallbackParams & { filters: FilterBase[] }) => Params;
  fetchPolicy?: FetchPolicy;
  extractData: (res: Response) => TableFetchDataCallBackReturn<Data | null>;
  defaultOrdering?: OrderingInput;
  onSuccess?: (a: Response) => void;
  noTableSettings?: boolean;
  getAdditionalData?: (variables: Params, tableStore: TableStoreType) => void;
  displayCurrency?: string;
  skip?: boolean;
}

const defaultParams = {
  fetchPolicy: 'network-only' as FetchPolicy,
};

const formatData = <Data,>(
  res: TableFetchDataCallBackReturn<Data | null>,
): TableFetchDataCallBackReturn<Data> => ({
  data: res.data?.filter(Boolean)?.map((e) => e as Data),
  length: res.length,
});

export const useFetchTableData = <Response, Params, Data>({
  getVariables = (params) =>
    pick(params, 'filters', 'ordering', 'pagination', 'displayCurrency') as Params,
  ...params
}: UseFetchTableDataParams<Response, Params, Data>): TableFetchDataCallBack<Data> => {
  const client = useApolloClient();
  const store = useStore();
  return useCallback(
    async ({ ordering, pagination, tableStore }: any) => {
      try {
        const filters = selectCurrentFilters(store.getState());
        const queryOptions = getEnabledColumnQueriesByTable(params.tableId)(store.getState);
        const isValidOrdering = !params.noTableSettings
          ? getIsValidOrdering(params.tableId, store.getState, ordering?.orderBy)
          : true;
        const resultOrdering = isValidOrdering ? ordering : params.defaultOrdering;

        if (!isValidOrdering) {
          tableStore?.resetOrdering();
        }

        const variables = getVariables({
          filters,
          ordering: resultOrdering,
          pagination,
          ...queryOptions,
          displayCurrency: params.displayCurrency,
        } as any);

        if (params.skip) {
          return { data: [], length: 0 };
        }

        const result = await client.query<Response>({
          fetchPolicy: params.fetchPolicy || defaultParams.fetchPolicy,
          query: params.query,
          variables: variables as OperationVariables,
        });

        if (params.getAdditionalData) {
          setTimeout(() => {
            params?.getAdditionalData?.(variables, tableStore);
          }, 0);
        }
        params.onSuccess?.(result?.data);
        return formatData<Data>(params.extractData(result?.data));
      } catch (e) {
        devError(e);
        return { data: [], length: 0 };
      }
    },
    [client, store, params.displayCurrency, params.skip],
  );
};
