export const KeywordsQueryName = {
  TABLE_KEYWORDS: 'tableKeywords',
  KEYDIS_KEYWORDS: 'keydisKeywords',
  SITEMAPPINGS: 'sitemappings',
  SITEMAPPING_UNKNOWN_COMPETITORS: 'unknownCompetitorDomains',
  FOLDERS: 'folders',
  COMPETITOR_BUBBLE_CHART: 'CompetitorBubbleChart',
  HISTORY_CHARTS: 'HistoryCharts',
  KPI_BAR: 'KpiBar',
  KPIS: 'kpis',
  KEYWORDS: 'keywords',
  CHARTS: 'charts',
  GRAPHS: 'graphs',
  TABLE_DASHBOARD: 'tableDashboard',
};

export const KeywordSortOption = {
  BASE_RANK: 'base_rank',
  BASE_RANK_CHANGE: 'base_rank_change',
  LOCAL_PACK_RANK: 'local_pack_rank',
  LOCAL_PACK_RANK_CHANGE: 'local_pack_rank_change',
  MAX_RANK: 'max_rank',
  AI_TRAFFIC_VALUE: 'ai_traffic_value',
  AI_TRAFFIC_VALUE_CHANGE: 'ai_traffic_value_change',
  MAX_AI_TRAFFIC_VALUE: 'max_ai_traffic_value',
  MAX_AI_SOV: 'max_ai_sov',
  MAX_TRAFFIC_VALUE: 'max_traffic_value',
  MAX_SOV: 'max_sov',
  STARRED: 'starred',
  DOMAIN: 'domain',
  KEYWORD: 'keyword',
  SEARCH_TYPE: 'search_type',
  DESCRIPTION: 'description',
  LOCATION: 'location',
  RANK: 'rank',
  RANK_CHANGE: 'rank_change',
  URL: 'highest_ranking_page',
  PATH: 'path',
  SEARCH_VOLUME: 'search_volume',
  AI_SEARCH_VOLUME: 'ai_search_volume',
  CPC: 'cpc',
  COMPETITION: 'competition',
  VISITS: 'analytics_visitors',
  POTENTIAL: 'analytics_potential',
  SOV: 'share_of_voice',
  SOV_CHANGE: 'share_of_voice_change',
  SERP: 'page_serp_features',
  DATE: 'date_added',
  TIME_AGO: 'search_date',
  DYNAMIC_CTR: 'dynamic_ctr',
  DYNAMIC_CTR_CHANGE: 'dynamic_ctr_change',
  DYNAMIC_CTR_MAX: 'dynamic_ctr_max',
  DYNAMIC_CTR_POTENTIAL: 'dynamic_ctr_potential',
  ORGANIC_TRAFFIC: 'organic_traffic',
  ORGANIC_TRAFFIC_CHANGE: 'organic_traffic_change',
  SEARCH_INTENT: 'search_intent',
  TRAFFIC_VALUE: 'traffic_value',
  TRAFFIC_VALUE_CHANGE: 'traffic_value_change',
  PIXELS_FROM_TOP: 'pixels_from_top',
  PIXELS_FROM_TOP_CHANGE: 'pixels_from_top_change',
  ABOVE_THE_FOLD: 'above_the_fold',
} as const;

export enum KeywordColumnID {
  'PIXELS_FROM_TOP' = 'pixels_from_top',
  'PIXELS_FROM_TOP_CHANGE' = 'pixels_from_top_change',
  'ABOVE_THE_FOLD' = 'above_the_fold',
  'BASE_RANK' = 'base_rank',
  'BASE_RANK_CHANGE' = 'base_rank_change',
  'LOCAL_PACK_RANK' = 'local_pack_rank',
  'LOCAL_PACK_RANK_CHANGE' = 'local_pack_rank_change',
  'AI_TRAFFIC_VALUE' = 'ai_traffic_value',
  'AI_TRAFFIC_VALUE_CHANGE' = 'ai_traffic_value_change',
  'MAX_AI_TRAFFIC_VALUE' = 'max_ai_traffic_value',
  'MAX_AI_SOV' = 'max_ai_sov',
  'MAX_TRAFFIC_VALUE' = 'max_traffic_value',
  'MAX_SOV' = 'max_sov',
  'MAX_RANK' = 'max_rank',
  'TOP_DOMAIN' = 'top_domain',
  'CHECKBOX' = 'checkbox',
  'CHECKBOX_STARRED' = 'checkbox_starred',
  'DOMAIN' = 'domain',
  'KEYWORD' = 'keyword',
  'SEARCH_ENGINE' = 'search_engine',
  'SEARCH_TYPE' = 'search_type',
  'COUNTRY' = 'country',
  'LOCATION' = 'location',
  'LOCALE' = 'locale',
  'COUNTRY_LOCALE' = 'country_locale',
  'RANK' = 'rank',
  'RANK_CHANGE' = 'rank_change',
  'SERP' = 'serp',
  'SERP_RANK' = 'serp_rank',
  'URL' = 'url',
  'PATH' = 'path',
  'URL_STATUS' = 'url_status',
  'SEARCH_VOLUME' = 'search_volume',
  'AI_SEARCH_VOLUME' = 'ai_search_volume',
  'CPC' = 'cpc',
  'AD_COMPETITION' = 'ad_competition',
  'ANALYTICS_VISITORS' = 'analytics_visitors',
  'ANALYTICS_POTENTION' = 'analytics_potential',
  'SHARE_OF_VOICE' = 'share_of_voice',
  'SHARE_OF_VOICE_CHANGE' = 'share_of_voice_change',
  'SHARE_OF_VOICE_CPC' = 'share_of_voice_cpc',
  'SHARE_OF_VOICE_CPC_CHANGE' = 'share_of_voice_cpc_change',
  'SHARE_OF_VOICE_PERCENTAGE' = 'share_of_voice_percentage',
  'DATE_ADDED' = 'date_added',
  'KEYWORD_TIME_AGO' = 'time_ago',
  'ACTIONS' = 'actions',
  'DESCRIPTION' = 'description',
  'DYNAMIC_CTR' = 'dynamic_ctr',
  'DYNAMIC_CTR_CHANGE' = 'dynamic_ctr_change',
  'DYNAMIC_CTR_MAX' = 'dynamic_ctr_max',
  'DYNAMIC_CTR_POTENTIAL' = 'dynamic_ctr_potential',
  'ORGANIC_TRAFFIC' = 'organic_traffic',
  'ORGANIC_TRAFFIC_CHANGE' = 'organic_traffic_change',
  'SEARCH_INTENT' = 'search_intent',
}

export const keywordColumnOrdering = [
  KeywordColumnID.CHECKBOX_STARRED,
  KeywordColumnID.DOMAIN,
  KeywordColumnID.KEYWORD,
  KeywordColumnID.SEARCH_TYPE,
  KeywordColumnID.SEARCH_INTENT,
  KeywordColumnID.DESCRIPTION,
  KeywordColumnID.COUNTRY,
  KeywordColumnID.LOCATION,
  KeywordColumnID.LOCALE,
  KeywordColumnID.RANK,
  KeywordColumnID.RANK_CHANGE,
  KeywordColumnID.BASE_RANK,
  KeywordColumnID.BASE_RANK_CHANGE,
  KeywordColumnID.MAX_RANK,
  KeywordColumnID.LOCAL_PACK_RANK,
  KeywordColumnID.LOCAL_PACK_RANK_CHANGE,
  KeywordColumnID.URL,
  KeywordColumnID.SEARCH_VOLUME,
  KeywordColumnID.DYNAMIC_CTR,
  KeywordColumnID.DYNAMIC_CTR_CHANGE,
  KeywordColumnID.DYNAMIC_CTR_MAX,
  KeywordColumnID.ORGANIC_TRAFFIC,
  KeywordColumnID.ORGANIC_TRAFFIC_CHANGE,
  KeywordColumnID.MAX_AI_SOV,
  KeywordColumnID.SHARE_OF_VOICE,
  KeywordColumnID.SHARE_OF_VOICE_CHANGE,
  KeywordColumnID.MAX_SOV,
  KeywordColumnID.AI_TRAFFIC_VALUE,
  KeywordColumnID.AI_TRAFFIC_VALUE_CHANGE,
  KeywordColumnID.MAX_AI_TRAFFIC_VALUE,
  KeywordColumnID.SHARE_OF_VOICE_CPC,
  KeywordColumnID.SHARE_OF_VOICE_CPC_CHANGE,
  KeywordColumnID.MAX_TRAFFIC_VALUE,
  KeywordColumnID.TOP_DOMAIN,
  KeywordColumnID.PIXELS_FROM_TOP,
  KeywordColumnID.PIXELS_FROM_TOP_CHANGE,
  KeywordColumnID.CPC,
  KeywordColumnID.AD_COMPETITION,
  KeywordColumnID.SERP,
  KeywordColumnID.DATE_ADDED,
  KeywordColumnID.KEYWORD_TIME_AGO,
  KeywordColumnID.ACTIONS,
];

export const KeywordsTableSortType = {
  RANK: 'rank',
  SHARE_OF_VOICE_CHANGE_ASC: 'sov_change_asc',
  SHARE_OF_VOICE_CHANGE_DESC: 'sov_change_desc',
};

export enum WebsocketActionType {
  UPDATED = 'U',
  DELETED = 'D',
  CREATED = 'C',
}
