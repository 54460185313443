import AccTag, { AccTagProps } from 'Components/AccTag';
import styles from 'Components/AccTag/accTag.module.scss';

type Props = {
  keywordId: number;
  tooltipText: string;
  renderPopover: boolean;
  maxWidth?: string;
  className?: string;
  withinPopover?: boolean;
} & AccTagProps;

export const KeywordTag: React.FC<React.PropsWithChildren<Props>> = ({
  tooltipText,
  renderPopover,
  ...props
}) => {
  const { tag = '', fontSize = '11px', maxWidth = '100px' } = props;
  return (
    <>
      {renderPopover ? (
        <AccTag maxWidth={maxWidth} tooltipText={tooltipText} {...props} />
      ) : (
        <div
          className={styles.tag}
          data-dynamic={props.dynamic || null}
          style={{ maxWidth, fontSize }}
        >
          <span className={styles.label}>{tag}</span>
        </div>
      )}
    </>
  );
};
