import { useMemo, useState } from 'react';
import { t } from 'Utilities/i18n';
import * as helpers from '../helpers';
import { KeywordExtraTags } from './KeywordExtraTags';
import { KeywordTag } from './KeywordTag';
import styles from './keywordTag.module.scss';

interface KeywordCellTagsListProps {
  keywordId: number;
  domainId: string;
  tags?: string[];
  infoIconsLength?: number;
  dynamicTags?: string[];
  onTagRemoveClick?: (tag: string) => void;
  onTagClick?: (tag: string) => void;
}

export const KeywordCellTagsList: React.FC<React.PropsWithChildren<KeywordCellTagsListProps>> = (
  props,
) => {
  const combinedTags = helpers.concatTags(props.tags, props.dynamicTags);

  // ARR-4935 Postpone the rendering of `AccTag` as this is a heavy component
  const [renderPopover, setRenderPopover] = useState(false);

  const showNumberOfTags = props.infoIconsLength && props?.infoIconsLength > 2 ? 1 : 2; // only show one tag if more than two infoIcons are active
  const visibleTags = combinedTags?.slice(0, showNumberOfTags);

  const baseProps = {
    keywordId: props.keywordId,
    onTagClick: props.onTagClick,
    onTagRemoveClick: props.onTagRemoveClick,
  };

  const tagWidth = useMemo(() => {
    const spacingPx = 3 * 2 + 6; // 3px spacing * max-gaps + container margin
    if (combinedTags.length >= 2 && !(props.infoIconsLength && props?.infoIconsLength > 2)) {
      const collapseIconWidthPx = 35;
      return `calc((100% - ${spacingPx}px - ${collapseIconWidthPx}px) / 2)`;
    }
    return '90px';
  }, [combinedTags.length]);

  const showExtraTags = combinedTags?.length > 2;

  return (
    <div
      className="accu-table-cell__tags"
      data-tags={visibleTags?.length > 0}
      onMouseEnter={() => setRenderPopover(true)}
    >
      {visibleTags?.map((tagElement, i) => (
        <KeywordTag
          key={`${tagElement.tag}_${tagElement.dynamic}_${i}`}
          tag={tagElement.tag}
          renderPopover={renderPopover}
          maxWidth={tagWidth}
          dynamic={tagElement.dynamic}
          tooltipText={
            tagElement.dynamic
              ? t('Add as filter or edit tag: ') + tagElement.tag
              : t('Add as filter or remove tag: ') + tagElement.tag
          }
          {...baseProps}
          onTagRemoveClick={tagElement.dynamic ? undefined : props.onTagRemoveClick}
        />
      ))}
      {showExtraTags && renderPopover && <KeywordExtraTags tags={combinedTags} {...baseProps} />}
      {showExtraTags && !renderPopover && (
        <div className={styles.extraTagsContainer}>{`+${combinedTags?.slice(2).length}`}</div>
      )}
    </div>
  );
};
