import { useState } from 'react';
import {
  ActionIcon,
  Box,
  BoxProps,
  Popover,
  PopoverProps,
  Title,
  TitleOrder,
  TitleProps,
  useMantineTheme,
} from '@mantine/core';
import { IconHelp } from '@tabler/icons-react';
import cn from 'classnames';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import AccText from 'Components/Text/AccText';
import { t } from 'Utilities/i18n';
import styleVariables from 'css/base/variables.module.scss';
import styles from './accTitle.module.scss';

export type AccTitleProps = TitleProps & {
  type: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'label';
  helper?: React.ReactNode;
  helperSize?: number;
  /** For e.g. styling of the outer box */
  boxProps?: BoxProps;
  popoverProps?: Omit<PopoverProps, 'children'>;
  disabled?: boolean;
  inactive?: boolean;
  inline?: boolean;
  align?: 'center';
  iconProps?: any;
  nowrap?: boolean;
};
/**
 * Headline component to generate HTML Header tags
 * @example
 * <AccTitle
 *  fw={600}
 *  size={14}
 *  type="h3"
 *  boxProps={ styles: { root: { justifyContent: 'center', width: '100%' } } }
 * >
 * {t('Title')}
 * </AccTitle>
 */
const AccTitle = ({
  type,
  helper,
  helperSize,
  boxProps,
  popoverProps,
  disabled = false,
  inactive = false,
  inline,
  variant,
  c: colorProp,
  align,
  iconProps,
  nowrap = false,
  ...props
}: AccTitleProps) => {
  const headingLevels = new Map<typeof type, TitleOrder>([
    ['h1', 1],
    ['h2', 2],
    ['h3', 3],
    ['h4', 4],
    ['h5', 5],
    ['h6', 6],
    ['label', 6],
  ]);

  const [popoverOpen, setPopoverOpen] = useState(false);

  const order = headingLevels.get(type) ?? 1;

  const theme = useMantineTheme();

  const color = inactive
    ? theme.other.textInactive
    : disabled
    ? theme.other.textDisabled
    : colorProp ?? theme.other.textPrimary;

  return (
    <Box
      {...boxProps}
      className={cn(
        styles.defaultBox,
        {
          [styles.inlineBox]: inline,
          [styles.linkBox]: variant === 'link',
          [styles.alignCenterBox]: align === 'center',
        },
        boxProps?.className,
      )}
    >
      <Title
        size={type === 'label' ? 14 : undefined}
        fw={type === 'label' ? 600 : undefined}
        mr={helper ? theme.other.gutterSm : 0}
        order={order}
        c={color}
        {...props}
        className={cn(
          props.className,
          { helper: styles.helperTitle, [styles.nowrap]: nowrap },
          props.className,
        )}
      />
      {helper && (
        <Popover
          width={400}
          position="right"
          withArrow
          shadow="md"
          zIndex={styleVariables.zindexTooltip}
          {...popoverProps}
          onChange={setPopoverOpen}
        >
          <AccTooltip tooltip={t('More information')} disable={popoverOpen}>
            <Popover.Target>
              <ActionIcon
                radius={'sm'}
                color="gray.6"
                size="md"
                variant="subtle"
                className={cn(styles.toggleButtonSelected, styles.defaultActionIcon)}
                {...iconProps}
              >
                <IconHelp size={helperSize ?? 20} />
              </ActionIcon>
            </Popover.Target>
          </AccTooltip>
          <Popover.Dropdown>
            <AccText size="sm" style={{ whiteSpace: 'pre-line' }}>
              {helper}
            </AccText>
          </Popover.Dropdown>
        </Popover>
      )}
    </Box>
  );
};

export default AccTitle;
