import { Grid } from '@mantine/core';
import Skeleton from 'Components/Skeleton';

export const OveruseInfoPlaceholder = () => {
  return (
    <Grid className="overuse-info-panel base-container-padding">
      <Grid.Col span={{ lg: 8 }}>
        <Skeleton
          linesConfig={[
            {
              type: 'text',
              options: { width: '300px', height: '60px' },
            },
            {
              type: 'text',
              options: { width: '80%', height: '50px' },
            },
          ]}
        />
      </Grid.Col>
      <Grid.Col span={{ lg: 4 }}>
        <Skeleton
          linesConfig={[
            {
              type: 'text',
              options: { width: '300px', height: '60px', marginLeft: '27px' },
            },
          ]}
        />
      </Grid.Col>
    </Grid>
  );
};
