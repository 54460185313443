import { Component } from 'react';
import { Grid } from '@mantine/core';
import Skeleton from 'Components/Skeleton';

class ScheduledReportBuilderFormSkeleton extends Component {
  render() {
    return (
      <Grid>
        <Grid.Col span={{ lg: 12 }}>
          <Skeleton
            linesConfig={[
              {
                type: 'text',
                options: {
                  width: '30%',
                },
              },
              {
                type: 'input',
              },
              {
                type: 'text',
                options: {
                  width: '50%',
                },
              },
              {
                type: 'text',
                options: {
                  width: '30%',
                },
              },
              {
                type: 'input',
              },
              {
                type: 'text',
                options: {
                  width: '30%',
                },
              },
              {
                type: 'input',
              },
              {
                type: 'text',
                options: {
                  width: '30%',
                },
              },
              {
                type: 'input',
              },
              {
                type: 'text',
                options: {
                  width: '50%',
                  marginBottom: '10px',
                },
              },
            ]}
          />
          <Grid.Col span={{ lg: 6 }}>
            <Skeleton
              linesConfig={[
                {
                  type: 'text',
                  options: {
                    width: '30%',
                  },
                },
                {
                  type: 'input',
                },
                {
                  type: 'text',
                  options: {
                    width: '100%',
                  },
                },
              ]}
            />
          </Grid.Col>
          <Grid.Col span={{ lg: 6 }}>
            <Skeleton
              linesConfig={[
                {
                  type: 'text',
                  options: {
                    width: '30%',
                  },
                },
                {
                  type: 'input',
                },
              ]}
            />
          </Grid.Col>
          <Skeleton
            linesConfig={[
              {
                type: 'text',
                options: {
                  width: '30%',
                  marginTop: '10px',
                } as any,
              },
              {
                type: 'input',
              },
              {
                type: 'text',
                options: {
                  width: '50%',
                  marginBottom: '20px',
                },
              },
            ]}
          />
          <Grid.Col span={{ lg: 6 }}>
            <Skeleton
              linesConfig={[
                {
                  type: 'text',
                  options: {
                    width: '30%',
                  },
                },
              ]}
            />
          </Grid.Col>
          <Grid.Col span={{ lg: 6 }}>
            <Skeleton
              linesConfig={[
                {
                  type: 'text',
                  options: {
                    width: '50%',
                    alignment: 'right',
                  },
                },
              ]}
            />
          </Grid.Col>
          <Skeleton
            linesConfig={[
              {
                type: 'button',
                options: {
                  width: '15%',
                  alignment: 'right',
                  marginTop: '20px',
                } as any,
              },
            ]}
          />
        </Grid.Col>
      </Grid>
    );
  }
}

export default ScheduledReportBuilderFormSkeleton;
