import { IconTableOptions } from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import { ViewMode } from 'Components/ViewMode';
import { useModal } from 'Hooks/base/useModal';
import ActionbarContainer from 'Pages/Layout/ActionsMenu/components/ActionbarContainer';
import { TableID, TableIDs } from 'Types/Table';
import { TrackingKey } from 'Utilities/Analytics/mixpanel';
import { t } from 'Utilities/i18n';

const KeywordDiscoveryActionbar = ({ tableType }: { tableType: TableID }) => {
  const { showModal } = useModal();
  const showTableSettingsModal = () => {
    showModal({
      modalType: 'TableSettingsModal',
      modalTheme: 'light',
      modalProps: { tableName: TableIDs.KEYWORD_DISCOVERY }, // Always discovery, never research to make sure settings apply on both tables. In KeywordDiscoveryTable.tsx we always read setting for TableIDs.KEYWORD_DISCOVERY
    });
  };

  return (
    <ActionbarContainer>
      <ActionbarContainer.Sidebar>
        <AccButton
          onClick={showTableSettingsModal}
          variant="tertiary"
          leftSection={<IconTableOptions size={20} />}
          trackingKey={TrackingKey.TableSettings}
        >
          {t('Configure columns')}
        </AccButton>
        <ViewMode tableName={tableType} />
      </ActionbarContainer.Sidebar>
    </ActionbarContainer>
  );
};

export default KeywordDiscoveryActionbar;
