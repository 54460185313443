import { matchPath } from 'react-router-dom';
import { useAppDeclarations } from '../../../AppDeclaration';
import { useSmartRouterSubscription } from '../components/LocationPathnameContext';
import { AppRouteItem } from './useAppRouter';

function findActiveShellItem(routes: AppRouteItem[], pathname: string) {
  return routes.find((e) => {
    const paths = e.path ? [e.path] : e.paths;
    return paths?.some((path) => matchPath(path, pathname));
  });
}

/**
 * We define all config for routes in `AppDeclarations` - this hook subscribe to route change and current config item.
 * `extractValue` - parameter used to subscribe to specific properties instead of full config, since it re-render of value changed *(compare with isEqual)
 * @example:
 * ```tsx
 * const currentPageId = useShellConfig((shellConfig) => shellConfig.id);
 * ```
 */
export const useShellConfig = (extractValue?: Function): any => {
  const routes = useAppDeclarations();

  return useSmartRouterSubscription((pathname, params) => {
    const result = findActiveShellItem(routes, pathname);

    return extractValue ? extractValue(result, { pathname, params }) : result;
  });
};
