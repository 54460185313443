import { gql } from '@apollo/client';

export const MUTATION_ADD_DYNAMIC_TAG_RULESET = gql`
  mutation AddDynamicTagRuleset($input: AddDynamicTagRulesetInput!) {
    addDynamicTagRuleset(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const MUTATION_UPDATE_DYNAMIC_TAG_RULESET = gql`
  mutation UpdateDynamicTagRuleset($input: UpdateDynamicTagRulesetInput!) {
    updateDynamicTagRuleset(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const QUERY_DYNAMIC_TAG_RULESET_COUNT = gql`
  query DynamicTagRulesetCount($input: CountDynamicTagRulesetInput!) {
    dynamicTagRulesetCount(dynamicTagRuleset: $input)
  }
`;
