import Skeleton from 'Components/Skeleton';

export const SessionBlockPlaceholder = () => {
  return (
    <Skeleton
      className="indented-form-group form-group"
      linesConfig={[
        { type: 'text', options: { width: '50%' } },
        { type: 'text', options: { width: '30%' } },
        { type: 'input' },
        { type: 'input' },
        { type: 'input' },
      ]}
    />
  );
};
