import React from 'react';
import { Flex, Paper } from '@mantine/core';
import { IconCircleDot, IconMinus, IconPlus } from '@tabler/icons-react';
import { HierarchyPointLink, HierarchyPointNode, tree as d3tree, hierarchy } from 'd3-hierarchy';
import { event, select } from 'd3-selection';
import { zoom as d3zoom, zoomIdentity } from 'd3-zoom';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { v4 as uuidv4 } from 'uuid';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { SitemapNode } from 'Pages/SiteMapping/support/types';
import { t } from 'Utilities/i18n';
import Link from '../Link';
import Node from '../Node';
import globalCss from '../globalCss';
import {
  Point,
  RawNodeDatum,
  RenderCustomNodeElementFn,
  TreeNodeDatum,
  TreeNodeInfo,
} from '../types/common';
import TransitionGroupWrapper from './TransitionGroupWrapper';
import { TreeLinkEventCallback, TreeNodeEventCallback, TreeProps } from './types';

type TreeState = {
  dataRef: TreeProps['data'];
  data: SitemapNode | TreeNodeDatum[];
  d3: { translate: Point | undefined; scale: number };
  isTransitioning: boolean;
  isInitialRenderForDataset: boolean;
};

class Tree extends React.Component<TreeProps, TreeState> {
  static defaultProps: Partial<TreeProps> = {
    onNodeClick: undefined,
    onNodeMouseOver: undefined,
    onNodeMouseOut: undefined,
    onLinkClick: undefined,
    onLinkMouseOver: undefined,
    onLinkMouseOut: undefined,
    onUpdate: undefined,
    orientation: 'horizontal',
    translate: { x: 0, y: 0 },
    pathFunc: 'diagonal',
    pathClassFunc: undefined,
    transitionDuration: 500,
    depthFactor: undefined,
    collapsible: true,
    initialDepth: undefined,
    zoomable: true,
    zoom: 1,
    scaleExtent: { min: 0.1, max: 1 },
    nodeSize: { x: 140, y: 140 },
    separation: { siblings: 1, nonSiblings: 2 },
    shouldCollapseNeighborNodes: false,
    svgClassName: '',
    rootNodeClassName: '',
    branchNodeClassName: '',
    leafNodeClassName: '',
    renderCustomNodeElement: undefined,
    enableLegacyTransitions: false,
    hasInteractiveNodes: false,
    resetPositionButton: false,
    toolTipProps: undefined,
    NodeToolTip: undefined,
    renderCustomLink: undefined,
  };

  state: TreeState = {
    dataRef: this.props.data,
    data: Tree.assignInternalProperties(cloneDeep(this.props.data) as RawNodeDatum[], 0),
    d3: Tree.calculateD3Geometry(this.props),
    isTransitioning: false,
    isInitialRenderForDataset: true,
  };

  private internalState = {
    targetNode: null,
    isTransitioning: false,
    translate: null,
    zoom: null,
    ...Tree.calculateD3Geometry(this.props),
  };

  svgInstanceRef = `rd3t-svg-${uuidv4()}`;
  gInstanceRef = `rd3t-g-${uuidv4()}`;
  tooltipInstanceRef = `rd3t-tooltip-${uuidv4()}`;

  static getDerivedStateFromProps(
    nextProps: TreeProps,
    prevState: TreeState,
  ): Partial<TreeState> | null {
    let derivedState: Partial<TreeState> | null = null;
    // Clone new data & assign internal properties if `data` object reference changed.
    if (nextProps.data !== prevState.dataRef) {
      derivedState = {
        dataRef: nextProps.data,
        data: Tree.assignInternalProperties(cloneDeep(nextProps.data) as RawNodeDatum[]),
        isInitialRenderForDataset: true,
      };
    }
    const d3 = Tree.calculateD3Geometry(nextProps);
    if (!isEqual(d3, prevState.d3)) {
      derivedState = derivedState || {};
      derivedState.d3 = d3;
    }
    return derivedState;
  }

  componentDidMount(): void {
    this.bindZoomListener(this.props);
    this.setState({ isInitialRenderForDataset: false });
  }

  componentDidUpdate(prevProps: TreeProps): void {
    const { saveExpandedNodes, expandDataKey, expandedNodes } = this.props;

    if (saveExpandedNodes) {
      const { nodes } = this.getGroupedTreeData();

      if (prevProps.expandedNodes !== expandedNodes) {
        this.setInitialTreeDepth(nodes, 1);
      }

      const expandedIds = (nodes || [])
        .filter((node) => !((node.data || {}).__rd3t || {}).collapsed)
        .map((e) => e.data[expandDataKey]);
      if (!isEqual(expandedIds, expandedNodes)) {
        saveExpandedNodes(expandedIds || []);
      }
    }
    if (this.props.data !== prevProps.data) {
      // If last `render` was due to change in dataset -> mark the initial render as done.
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isInitialRenderForDataset: false });
      this.resetPosition();
    }

    if (
      !isEqual(this.props.translate, prevProps.translate) ||
      !isEqual(this.props.scaleExtent, prevProps.scaleExtent) ||
      this.props.zoomable !== prevProps.zoomable ||
      this.props.zoom !== prevProps.zoom ||
      this.props.enableLegacyTransitions !== prevProps.enableLegacyTransitions
    ) {
      // If zoom-specific props change -> rebind listener with new values.
      // Or: rebind zoom listeners to new DOM nodes in case legacy transitions were enabled/disabled.
      this.bindZoomListener(this.props);
    }

    if (typeof this.props.onUpdate === 'function') {
      this.props.onUpdate({
        node: this.internalState.targetNode ? this.internalState.targetNode : null,
        zoom: this.internalState.zoom ?? this.props.zoom ?? 1,
        translate: this.internalState.translate ?? this.props.translate ?? { x: 0, y: 0 },
      });
    }
    // Reset the last target node after we've flushed it to `onUpdate`.
    this.internalState.targetNode = null;
  }

  /**
   * Collapses all tree nodes with a `depth` larger than `initialDepth`.
   */
  setInitialTreeDepth(nodeSet: HierarchyPointNode<TreeNodeDatum>[], initialDepth: number): void {
    const { expandedNodes, expandDataKey } = this.props;
    nodeSet.forEach((n) => {
      if (expandedNodes) {
        n.data.__rd3t.collapsed = !(expandedNodes || []).includes((n.data || {})[expandDataKey]);
      } else {
        n.data.__rd3t.collapsed = n.data.__rd3t.depth >= initialDepth;
      }
    });
  }
  /**
   * bindZoomListener - If `props.zoomable`, binds a listener for
   * "zoom" events to the SVG and sets scaleExtent to min/max
   * specified in `props.scaleExtent`.
   */
  bindZoomListener(props: TreeProps): void {
    const { zoomable, scaleExtent, translate, zoom, hasInteractiveNodes } = props;
    const svg = select(`.${this.svgInstanceRef}`);
    const g = select(`.${this.gInstanceRef}`);

    let tooltip;
    if (this.props.NodeToolTip) {
      tooltip = select(`.${this.tooltipInstanceRef}`);
    }

    // Sets initial offset, so that first pan and zoom does not jump back to default [0,0] coords.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    svg.call(d3zoom().transform, zoomIdentity.translate(translate.x, translate.y).scale(zoom));
    svg.call(
      d3zoom()
        .scaleExtent(zoomable ? [scaleExtent?.min, scaleExtent?.max] : [zoom, zoom])
        // TODO: break this out into a separate zoom handler fn, rather than inlining it.
        .filter(() => {
          if (hasInteractiveNodes) {
            return (
              event.target.classList.contains(this.svgInstanceRef) ||
              event.target.classList.contains(this.gInstanceRef) ||
              event.shiftKey
            );
          }
          return props.customZoomFilter ? props.customZoomFilter(event) : true;
        })
        .on('zoom', () => {
          if (this.props.NodeToolTip) {
            // Slows down performance a lot!
            this.setState({ isTransitioning: true }); // To make sure tooltip is not shown during transitions
            // Translate tooltip but do not scale
            tooltip.attr('transform', `translate(${event.transform.x}, ${event.transform.y})`);
          }

          g.attr('transform', event.transform);

          this.setInternalZoomAndTranslate({
            x: event.transform.x,
            y: event.transform.y,
            zoom: event.transform.k,
          });

          if (this.props.NodeToolTip) {
            this.setState({ isTransitioning: false });
          }
        }),
    );
  }

  setInternalZoomAndTranslate({ x, y, zoom }): void {
    if (typeof this.props.onUpdate === 'function') {
      // This callback is magically called not only on "zoom", but on "drag", as well,
      // even though event.type == "zoom".
      // Taking advantage of this and not writing a "drag" handler.
      this.props.onUpdate({
        node: null,
        zoom,
        translate: { x, y },
      });
      // TODO: remove this? Shouldn't be mutating state keys directly.
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.d3.scale = zoom;
      this.internalState.zoom = zoom;
      this.internalState.translate = { x, y };
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.d3.translate = { x, y };
    }
  }
  /**
   * Assigns internal properties that are required for tree
   * manipulation to each node in the `data` set and returns a new `data` array.
   *
   * @static
   */
  static assignInternalProperties(data: RawNodeDatum[], currentDepth: number = 0): TreeNodeDatum[] {
    // Wrap the root node into an array for recursive transformations if it wasn't in one already.
    const d = Array.isArray(data) ? data : [data];

    d.forEach((n) => {
      const nodeDatum = n as TreeNodeDatum;
      nodeDatum.__rd3t = {
        collapsed: false,
        id: uuidv4(),
        depth: currentDepth,
      } as TreeNodeInfo;

      // If there are children, recursively assign properties to them too.
      if (nodeDatum.children && nodeDatum.children.length > 0) {
        nodeDatum.children = Tree.assignInternalProperties(nodeDatum.children, currentDepth + 1);
      }
    });
    return d as TreeNodeDatum[];
  }

  /**
   * Recursively walks the nested `nodeSet` until a node matching `nodeId` is found.
   */
  findNodesById(nodeId: string, nodeSet: TreeNodeDatum[], hits: TreeNodeDatum[]): TreeNodeDatum[] {
    if (hits.length > 0) {
      return hits;
    }
    hits = hits.concat(nodeSet.filter((node) => node.__rd3t.id === nodeId));
    nodeSet.forEach((node) => {
      if (node.children && node.children.length > 0) {
        hits = this.findNodesById(nodeId, node.children, hits);
      }
    });
    return hits;
  }

  /**
   * Recursively walks the nested `nodeSet` until all nodes at `depth` have been found.
   *
   * @param {number} depth Target depth for which nodes should be returned
   * @param {array} nodeSet Array of nested `node` objects
   * @param {array} accumulator Accumulator for matches, passed between recursive calls
   */
  findNodesAtDepth(
    depth: number,
    nodeSet: TreeNodeDatum[],
    accumulator: TreeNodeDatum[],
  ): TreeNodeDatum[] {
    accumulator = accumulator.concat(nodeSet.filter((node) => node.__rd3t.depth === depth));
    nodeSet.forEach((node) => {
      if (node.children && node.children.length > 0) {
        accumulator = this.findNodesAtDepth(depth, node.children, accumulator);
      }
    });
    return accumulator;
  }

  /**
   * Recursively sets the internal `collapsed` property of
   * the passed `TreeNodeDatum` and its children to `true`.
   *
   * @static
   */
  static collapseNode(nodeDatum: TreeNodeDatum): void {
    nodeDatum.__rd3t.collapsed = true;
    if (nodeDatum.children && nodeDatum.children.length > 0) {
      nodeDatum.children.forEach((child) => {
        Tree.collapseNode(child);
      });
    }
  }

  /**
   * Sets the internal `collapsed` property of
   * the passed `TreeNodeDatum` object to `false`.
   *
   * @static
   */
  static expandNode(nodeDatum: TreeNodeDatum): void {
    nodeDatum.__rd3t.collapsed = false;
  }

  /**
   * Collapses all nodes in `nodeSet` that are neighbors (same depth) of `targetNode`.
   */
  collapseNeighborNodes(targetNode: TreeNodeDatum, nodeSet: TreeNodeDatum[]): void {
    const neighbors = this.findNodesAtDepth(targetNode.__rd3t.depth, nodeSet, []).filter(
      (node) => node.__rd3t.id !== targetNode.__rd3t.id,
    );
    neighbors.forEach((neighbor) => Tree.collapseNode(neighbor));
  }

  /**
   * Finds the node matching `nodeId` and
   * expands/collapses it, depending on the current state of
   * its internal `collapsed` property.
   * `setState` callback receives targetNode and handles
   * `props.onClick` if defined.
   */
  handleNodeToggle = (nodeId: string) => {
    const data = this.state.data;
    const matches = this.findNodesById(nodeId, data as TreeNodeDatum[], []);
    const targetNodeDatum = matches[0];

    if (this.props.collapsible && !this.state.isTransitioning) {
      if (targetNodeDatum.__rd3t.collapsed) {
        Tree.expandNode(targetNodeDatum);
        this.props.shouldCollapseNeighborNodes &&
          this.collapseNeighborNodes(targetNodeDatum, data as TreeNodeDatum[]);
      } else {
        Tree.collapseNode(targetNodeDatum);
      }

      if (this.props.enableLegacyTransitions) {
        // Lock node toggling while transition takes place.
        this.setState({ data, isTransitioning: true });
        // Await transitionDuration + 10 ms before unlocking node toggling again.
        setTimeout(
          () => this.setState({ isTransitioning: false }),
          (this.props.transitionDuration ?? 0) + 10,
        );
      } else {
        this.setState({ data });
      }

      if (this.internalState) {
        this.internalState.targetNode = targetNodeDatum;
      }
    }
  };

  /**
   * Handles the user-defined `onNodeClick` function.
   */
  handleOnNodeClickCb: TreeNodeEventCallback = (hierarchyPointNode, evt) => {
    const { onNodeClick } = this.props;
    if (onNodeClick && typeof onNodeClick === 'function') {
      // Persist the SyntheticEvent for downstream handling by users.
      evt.persist();
      onNodeClick(cloneDeep(hierarchyPointNode), evt);
    }
  };

  /**
   * Handles the user-defined `onLinkClick` function.
   */
  handleOnLinkClickCb: TreeLinkEventCallback = (linkSource, linkTarget, evt) => {
    const { onLinkClick } = this.props;
    if (onLinkClick && typeof onLinkClick === 'function') {
      // Persist the SyntheticEvent for downstream handling by users.
      evt.persist();
      onLinkClick(cloneDeep(linkSource), cloneDeep(linkTarget), evt);
    }
  };

  /**
   * Handles the user-defined `onNodeMouseOver` function.
   */
  handleOnNodeMouseOverCb: TreeNodeEventCallback = (hierarchyPointNode, evt) => {
    const { onNodeMouseOver } = this.props;
    if (onNodeMouseOver && typeof onNodeMouseOver === 'function') {
      // Persist the SyntheticEvent for downstream handling by users.
      evt.persist();
      onNodeMouseOver(cloneDeep(hierarchyPointNode), evt);
    }
  };

  /**
   * Handles the user-defined `onLinkMouseOver` function.
   */
  handleOnLinkMouseOverCb: TreeLinkEventCallback = (linkSource, linkTarget, evt) => {
    const { onLinkMouseOver } = this.props;
    if (onLinkMouseOver && typeof onLinkMouseOver === 'function') {
      // Persist the SyntheticEvent for downstream handling by users.
      evt.persist();
      onLinkMouseOver(cloneDeep(linkSource), cloneDeep(linkTarget), evt);
    }
  };

  /**
   * Handles the user-defined `onNodeMouseOut` function.
   */
  handleOnNodeMouseOutCb: TreeNodeEventCallback = (hierarchyPointNode, evt) => {
    const { onNodeMouseOut } = this.props;
    if (onNodeMouseOut && typeof onNodeMouseOut === 'function') {
      // Persist the SyntheticEvent for downstream handling by users.
      evt.persist();
      onNodeMouseOut(cloneDeep(hierarchyPointNode), evt);
    }
  };

  /**
   * Handles the user-defined `onLinkMouseOut` function.
   */
  handleOnLinkMouseOutCb: TreeLinkEventCallback = (linkSource, linkTarget, evt) => {
    const { onLinkMouseOut } = this.props;
    if (onLinkMouseOut && typeof onLinkMouseOut === 'function') {
      // Persist the SyntheticEvent for downstream handling by users.
      evt.persist();
      onLinkMouseOut(cloneDeep(linkSource), cloneDeep(linkTarget), evt);
    }
  };

  getGroupedTreeData = (): {
    nodes: HierarchyPointNode<TreeNodeDatum>[];
    links: HierarchyPointLink<any>[];
  } => {
    const { separation, nodeSize, orientation } = this.props;
    const tree = d3tree<TreeNodeDatum>()
      .nodeSize(
        orientation === 'horizontal'
          ? [nodeSize?.y ?? 0, nodeSize?.x ?? 0]
          : [nodeSize?.x ?? 0, nodeSize?.y ?? 0],
      )
      .separation((a, b) =>
        (a.parent?.data?.__rd3t?.id === b.parent?.data.__rd3t.id
          ? separation?.siblings ?? 0
          : separation?.nonSiblings ?? 0),
      );

    const rootNode = tree(
      hierarchy(this.state.data[0], (d) => (d.__rd3t.collapsed ? null : d.children)),
    );
    return {
      nodes: rootNode.descendants(),
      links: rootNode.links(),
    };
  };

  /**
   * Generates tree elements (`nodes` and `links`) by
   * grabbing the rootNode from `this.state.data[0]`.
   * Restricts tree depth to `props.initialDepth` if defined and if this is
   * the initial render of the tree.
   */
  generateTree(): {
    nodes: HierarchyPointNode<TreeNodeDatum>[];
    links: HierarchyPointLink<any>[];
  } {
    const { initialDepth, depthFactor, expandDataKey } = this.props;
    const { isInitialRenderForDataset } = this.state;

    const { nodes, links } = this.getGroupedTreeData();

    // Configure nodes' `collapsed` property on first render if `initialDepth` is defined.
    if ((initialDepth !== undefined || expandDataKey) && isInitialRenderForDataset) {
      this.setInitialTreeDepth(nodes, initialDepth ?? 1);
    }

    if (depthFactor) {
      nodes.forEach((node) => {
        node.y = node.depth * depthFactor;
      });
    }

    return { nodes, links };
  }

  /**
   * Set initial zoom and position.
   * Also limit zoom level according to `scaleExtent` on initial display. This is necessary,
   * because the first time we are setting it as an SVG property, instead of going
   * through D3's scaling mechanism, which would have picked up both properties.
   *
   * @static
   */
  static calculateD3Geometry(nextProps: TreeProps): any {
    let scale;
    if ((nextProps?.zoom ?? 0) > (nextProps.scaleExtent?.max ?? 1)) {
      scale = nextProps.scaleExtent?.max ?? 1;
    } else if ((nextProps.zoom ?? 1) < (nextProps.scaleExtent?.min ?? 1)) {
      scale = nextProps.scaleExtent?.min;
    } else {
      scale = nextProps.zoom;
    }
    return {
      translate: nextProps.translate,
      scale,
    };
  }

  /**
   * Determines which additional `className` prop should be passed to the node & returns it.
   */
  getNodeClassName = (
    parent: HierarchyPointNode<TreeNodeDatum> | null,
    nodeDatum: TreeNodeDatum,
  ): string => {
    const { rootNodeClassName, branchNodeClassName, leafNodeClassName } = this.props;
    const hasParent = parent !== null && parent !== undefined;
    if (hasParent) {
      return (nodeDatum.children ? branchNodeClassName : leafNodeClassName) ?? '';
    }

    return rootNodeClassName ?? '';
  };

  setPosition = ({ x, y, zoom }) => {
    let duration = 500;
    const xOffset = 400;

    // If set to from the props we get unwanted behavior if switching between tabs + resetting
    if (!zoom) {
      // Used to center on a node, i.e. after toggling a node
      zoom = this.internalState.zoom ?? this.props.zoom;
      const new_x = -y + xOffset * (1 / zoom); // 400 out in the x direction is roughly centered
      y = -x * zoom + xOffset; // we need to switch between x and y as we have horizontal mode.. Insanely confusing ;)
      x = new_x * zoom;
    }

    if (!x && !y) {
      x = this.internalState.translate.x;
      y = this.internalState.translate.y;
      duration = 200;
    }

    const svg = select(`.${this.svgInstanceRef}`);
    const g = select(`.${this.gInstanceRef}`);

    let tooltip;
    if (this.props.NodeToolTip) {
      tooltip = select(`.${this.tooltipInstanceRef}`);
    }

    svg.call(d3zoom().transform, zoomIdentity.translate(x, y).scale(zoom));

    this.setState({ isTransitioning: true });
    g.transition().duration(duration).attr('transform', `translate(${x},${y})scale(${zoom})`);
    if (this.props.NodeToolTip) {
      tooltip.attr('transform', `translate(${x}, ${y})`);
    }
    this.setInternalZoomAndTranslate({ x, y, zoom });
    this.setState({ isTransitioning: false });
  };

  resetPosition = () => {
    this.setPosition({ x: 100, y: 400, zoom: this.props.zoom || 0.75 });
  };

  render(): JSX.Element {
    const { nodes, links } = this.generateTree();
    const {
      renderCustomNodeElement,
      orientation,
      pathFunc,
      transitionDuration,
      nodeSize,
      depthFactor,
      initialDepth,
      separation,
      enableLegacyTransitions,
      svgClassName,
      pathClassFunc,
      NodeToolTip,
      toolTipProps,
    } = this.props;
    const { translate, scale } = this.state.d3;
    const subscriptions = {
      ...nodeSize,
      ...separation,
      depthFactor,
      initialDepth,
    };

    const ZoomButtons = () => (
      <div className={'resetButton'}>
        <div className={'flexCol'}>
          <Paper h={40} w={40} shadow="xs" withBorder mb={8}>
            <Flex justify="center" align="center" h="100%">
              <AccTooltip tooltip={t('Reset position')}>
                <AccActionIcon onClick={this.resetPosition} m={2}>
                  <IconCircleDot />
                </AccActionIcon>
              </AccTooltip>
            </Flex>
          </Paper>
          <Paper w={40} shadow="xs" withBorder>
            <Flex direction="column" justify="center" align="center">
              <AccTooltip tooltip={t('Zoom in')}>
                <AccActionIcon
                  onClick={() =>
                    this.setPosition({
                      x: undefined,
                      y: undefined,
                      zoom: this.internalState.zoom * 1.2,
                    })
                  }
                  my={4}
                >
                  <IconPlus />
                </AccActionIcon>
              </AccTooltip>

              <AccTooltip tooltip={t('Zoom out')}>
                <AccActionIcon
                  onClick={() =>
                    this.setPosition({
                      x: undefined,
                      y: undefined,
                      zoom: this.internalState.zoom * 0.8,
                    })
                  }
                  my={4}
                >
                  <IconMinus />
                </AccActionIcon>
              </AccTooltip>
            </Flex>
          </Paper>
        </div>
      </div>
    );

    return (
      <div className={'rd3t-tree-container '}>
        <div className="rd3t-tree-container rd3t-grabbable">
          <style>{globalCss}</style>

          <svg
            className={`rd3t-svg ${this.svgInstanceRef} ${svgClassName}`}
            width="100%"
            height="100%"
          >
            <TransitionGroupWrapper
              enableLegacyTransitions={enableLegacyTransitions ?? false}
              component="g"
              className={`rd3t-g ${this.gInstanceRef}`}
              transform={`translate(${translate?.x},${translate?.y}) scale(${scale})`}
            >
              {links.map((linkData, i) => {
                if (linkData?.source?.data?.__rd3t?.collapsed) {
                  return null;
                }
                return (
                  <Link
                    key={`link-${i}`}
                    orientation={orientation!}
                    pathFunc={pathFunc!}
                    pathClassFunc={pathClassFunc!}
                    linkData={linkData}
                    onClick={this.handleOnLinkClickCb}
                    onMouseOver={this.handleOnLinkMouseOverCb}
                    onMouseOut={this.handleOnLinkMouseOutCb}
                    enableLegacyTransitions={enableLegacyTransitions ?? false}
                    transitionDuration={transitionDuration ?? 0}
                    renderCustomLink={this.props.renderCustomLink}
                  />
                );
              })}

              {nodes.map((hierarchyPointNode, i) => {
                const { data, x, y, parent } = hierarchyPointNode;
                if (parent?.data?.__rd3t.collapsed) {
                  return null;
                }
                return (
                  <Node
                    key={`node-${i}`}
                    data={data}
                    position={{ x, y }}
                    hierarchyPointNode={hierarchyPointNode}
                    parent={parent}
                    nodeClassName={this.getNodeClassName(parent, data)}
                    renderCustomNodeElement={renderCustomNodeElement as RenderCustomNodeElementFn}
                    nodeSize={nodeSize as any}
                    orientation={orientation as any}
                    enableLegacyTransitions={enableLegacyTransitions as any}
                    transitionDuration={transitionDuration as any}
                    onNodeToggle={this.handleNodeToggle}
                    setPosition={this.setPosition}
                    onNodeClick={this.handleOnNodeClickCb}
                    onNodeMouseOver={this.handleOnNodeMouseOverCb}
                    onNodeMouseOut={this.handleOnNodeMouseOutCb}
                    subscriptions={subscriptions}
                  />
                );
              })}
            </TransitionGroupWrapper>
            <g
              transform={`translate(${translate?.x},${translate?.y})`}
              className={this.tooltipInstanceRef}
            >
              {toolTipProps &&
                !this.state.isTransitioning &&
                NodeToolTip({
                  ...toolTipProps,
                  translate: this.internalState.translate,
                  scale: this.internalState.zoom ?? this.props.zoom,
                })}
            </g>
          </svg>
        </div>
        <ZoomButtons />
      </div>
    );
  }
}

export default Tree;
