import { Flex } from '@mantine/core';
import { Field } from 'Components/Fields';
import AccTitle, { AccTitleProps } from 'Components/Title/AccTitle';
import { t, tct } from 'Utilities/i18n';
import SocialMediaIcons, { SoMePlatform } from './SocialMediaIcons';

type Props = {
  activeSocials: SoMePlatform[];
  onIconToggle: (platform: SoMePlatform) => void;
  titleProps?: Partial<AccTitleProps>;
  isCompetitor?: boolean;
};

const SoMeFormFields = (props: Props) => {
  const { activeSocials, onIconToggle, titleProps } = props;

  return (
    <>
      <div>
        <AccTitle type="h3" {...titleProps}>
          {props?.isCompetitor ? t('Link competitor social media') : t('Link social media')}
        </AccTitle>
        <Flex columnGap="lg">
          <SocialMediaIcons onIconToggle={onIconToggle} activeSocials={activeSocials} />
        </Flex>
      </div>

      {activeSocials.includes(SoMePlatform.FACEBOOK) && (
        <Field.TextInput
          name={SoMePlatform.FACEBOOK}
          label={t('Facebook')}
          helpTextPopover={tct(
            'Please enter you Facebook information in one of the following formats:[break1] 1. [b1:FacebookProfile] [break2]2. [b2:facebook.com/FacebookProfile]',
            {
              break1: <br />,
              break2: <br />,
              b1: <b />,
              b2: <b />,
            },
          )}
          placeholder={t('Facebook profile')}
        />
      )}
      {activeSocials.includes(SoMePlatform.LINKEDIN) && (
        <Field.TextInput
          name={SoMePlatform.LINKEDIN}
          label={t('LinkedIn')}
          helpTextPopover={tct(
            'Please enter your LinkedIn information in one of the following formats:[break1] 1. [b1:linkedin.com/in/yourLinkedInProfile][break2]2. [b2:linkedin.com/company/yourCompanyLinkedInProfile] [break3]Note that a personal (/in/) and a company (/company/) LinkedIn have different URL path.',
            {
              break1: <br />,
              break2: <br />,
              break3: <br />,
              b1: <b />,
              b2: <b />,
            },
          )}
          placeholder={t('LinkedIn profile')}
        />
      )}
      {activeSocials.includes(SoMePlatform.X) && (
        <Field.TextInput
          name={SoMePlatform.X}
          label={t('X')}
          helpTextPopover={tct(
            'Please enter your X information in one of the following formats:[break1] 1. [b1:YourXHandle] [break2]2. [b2:x.com/YourXHandle] [break3]3. [b3:twitter.com/YourXHandle]',
            {
              break1: <br />,
              break2: <br />,
              break3: <br />,
              b1: <b />,
              b2: <b />,
              b3: <b />,
            },
          )}
          placeholder={t('@XHandle')}
        />
      )}
      {activeSocials.includes(SoMePlatform.TIKTOK) && (
        <Field.TextInput
          name={SoMePlatform.TIKTOK}
          label={t('TikTok')}
          helpTextPopoverProps={{ width: 425 }}
          helpTextPopover={tct(
            'Please enter you TikTok information in one of the following formats:[break1] 1. [b1:YourTikTokHandle] [break2]2. [b2:tiktok.com/YourTikTokHandle]',
            {
              break1: <br />,
              break2: <br />,
              b1: <b />,
              b2: <b />,
            },
          )}
          placeholder={t('@TikTokHandle')}
        />
      )}
      {activeSocials.includes(SoMePlatform.INSTAGRAM) && (
        <Field.TextInput
          name={SoMePlatform.INSTAGRAM}
          label={t('Instagram')}
          helpTextPopover={tct(
            'Please enter you Instagram information in one of the following formats:[break1] 1. [b1:InstagramProfile] [break2]2. [b2:instagram.com/InstagramProfile]',
            {
              break1: <br />,
              break2: <br />,
              b1: <b />,
              b2: <b />,
            },
          )}
          placeholder={t('@InstagramHandle')}
        />
      )}
    </>
  );
};

export default SoMeFormFields;
