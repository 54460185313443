import { TooltipFormatterContextObject } from 'highcharts';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { tct } from 'Utilities/i18n';
import { DataPoint, OverviewChartSeries } from '../constants';

const subtractDays = (fromTime: number, days: number) =>
  moment(fromTime).subtract(days, 'day').toDate().getTime();

// Adds placeholder data points, which oscillates up and down (+0, +1, +0, -1, +0, +1, +0, -1, +0, ..)
const addPlaceholderData = (first: DataPoint): DataPoint[] => {
  const count = 10;
  const fake = Array.from(Array(count)).map((_, i) => ({
    x: subtractDays(first.x, i + 1),
    y: first.y,
    totalKeywords: first.totalKeywords,
    isFake: true,
  }));
  return [first, ...fake];
};

// Adds placeholder data points
const addPlaceholderDataSimple = (first) => {
  const count = 1;
  const fake = Array.from(Array(count)).map((_, i) => [subtractDays(first[0], i + 1), first[1]]);

  return [first, ...fake];
};

// Used for styling the "fake data" part of the charts (gray, dashed, transparent).
// See e.g. ".highcharts-graph.lowDataZone"
const zones = (lowDataThreshold): Highcharts.SeriesZonesOptionsObject[] => [
  {
    value: lowDataThreshold,
    className: 'lowDataZone',
  },
  {
    className: 'regularZone',
  },
];

export const isPlaceholderPoint = (point: TooltipFormatterContextObject): boolean => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return !!point?.points?.[0]?.point?.isFake;
};

export const placeholderTooltip = () =>
  tct('[b:Placeholder data][br]Actual data will appear here in 1-2 days.', {
    b: <b />,
    br: <br />,
  });

// Low data mode (when only a single day of data exists): Add placeholder extra data + zones
export const addLowDataPlaceholderDays = (series: OverviewChartSeries, simple = false): void => {
  if (isEmpty(series) || isEmpty(series.data) || series.data.length !== 1) return;

  series.data = simple
    ? addPlaceholderDataSimple(series.data[0])
    : addPlaceholderData(series.data[0]);
  series.zoneAxis = 'x';
  series.zones = zones(series.data[1].x);
};
