import { memo, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Chart } from 'highcharts';
import isEqual from 'lodash/isEqual';
import { BUBBLE_CHART_HEIGHT } from 'Components/Chart/BubbleChart/support/constants';
import { BubbleChartData } from 'Components/Chart/BubbleChart/support/types';
import { ChartLoader } from 'Components/Chart/ChartLoader/ChartLoader';
import { ReactHighchartsChart } from 'Components/Chart/ReactHighcarts';
import { useBubbleChartConfig } from './support/hooks';

type Props = {
  loading?: boolean;
  data: BubbleChartData[];
  setDomain?: (
    event: Highcharts.SeriesLegendItemClickEventObject,
    domain: string | undefined,
  ) => void;
};

export const BubbleChart = memo((props: Props) => {
  const intl = useIntl();
  const chartRef = useRef<Chart>(null);
  const chartConfig = useBubbleChartConfig(props.data, intl, props.setDomain);

  return (
    <ChartLoader
      loaderType="bubble"
      loading={props.loading}
      containerBoxProps={{ h: BUBBLE_CHART_HEIGHT + 10 }}
    >
      <ReactHighchartsChart ref={chartRef} config={chartConfig} />
    </ChartLoader>
  );
}, isEqual);
BubbleChart.displayName = 'BubbleChart';
