import pick from 'lodash/pick';
import toast from 'Hooks/useToast';
import { DropDirection } from '../../react-dnd-treeview/types';
import { ROOT_ID } from '../constants';
import { convertListToTree } from '../helpers';
import { areIllegalSiblings } from './areIllegalSiblings';

// dragSource, direction, dropTargetId, direction
// eslint-disable-next-line import/no-unused-modules
export const extractDropParams = (dropParams, normalizedTree, selectedNodesIds) => {
  if (
    // dropParams?.isFolder &&
    [DropDirection.DOWN, DropDirection.UP].includes(dropParams.direction)
  ) {
    const parentId =
      normalizedTree.find((e) => e.id === dropParams.dropTargetId)?.parent ?? ROOT_ID;

    let index =
      normalizedTree
        .filter((e) => e.parent === parentId && !selectedNodesIds?.includes(e?.id))
        .findIndex((e) => e.id === dropParams.dropTargetId) +
      (dropParams.direction === DropDirection.DOWN ? 1 : 0);

    index = Math.max(index, 0);

    return {
      dropTargetId: parentId,
      index,
    };
  }
  return dropParams;
};

function addElementToTree(
  normalizedTreeFiltered: any[],
  selectedNodesIds: any[],
  dropTargetId,
  index,
) {
  const nextItems = normalizedTreeFiltered
    .filter((el) => selectedNodesIds?.includes(el.id))
    .map((e) => ({
      ...e,
      parent: dropTargetId,
      state: undefined,
    }));
  const list = normalizedTreeFiltered.filter((el) => !selectedNodesIds?.includes(el.id));

  const indexes: number[] = [];
  list.forEach((e, i) => {
    if (e.parent === dropTargetId) {
      indexes.push(i);
    }
  });

  if (!indexes?.length || index >= indexes.length) {
    list.push(...nextItems);
  } else {
    const resultIndex = indexes[index];
    list.splice(resultIndex, 0, ...nextItems);
  }

  return convertListToTree(list);
}

const getConflictNodeNames = (normalizedTree, dropTargetId, selectedNodesIds) => {
  const selectedNodes = normalizedTree.filter((node) => selectedNodesIds.includes(node.id));
  const newSiblings = normalizedTree.filter((node) => node.parent === dropTargetId);

  // find all siblings with the same name and type as one of the selected nodes
  const newSiblingsWithSameName = newSiblings.filter((sibling) =>
    selectedNodes.some((selectedNode) => areIllegalSiblings(selectedNode, sibling)),
  );

  // It is possible to drop in the same folder as the selected nodes already were in
  // In this case, we don't want to show a toast for "name conflict" and we also do not want to set isDirty
  // As such changes (which can at most be the ordering) will not be persisted when saving
  const changedWereMade = selectedNodes.some((node) => node.parent !== dropTargetId);

  let conflictingNodeNames;
  if (newSiblingsWithSameName.length > 0 && changedWereMade) {
    conflictingNodeNames = newSiblingsWithSameName.map((sibling) => `'${sibling.name}'`).join(', ');
  }
  return { conflictingNodeNames, changedWereMade };
};

export function useOnDrop(
  getSelectedNodes: () => any[],
  setTreeNested: (value: ((prevState: any[]) => any[]) | any[]) => void,
  setSelectedNodes: (value: any[]) => void,
  setIsDirty: (isDirty: boolean) => void,
  normalizedTree,
) {
  return (trees, dropParams: any, placeholderContext) => {
    const selectedNodesIds = getSelectedNodes().map((node) => node.id);
    const { dropTargetId, index } = extractDropParams(
      {
        ...pick(dropParams, 'direction', 'dropTargetId', 'direction'),
        isFolder: dropParams?.dragSource?.isFolder,
      },
      normalizedTree,
      selectedNodesIds,
    );

    const { conflictingNodeNames, changedWereMade } = getConflictNodeNames(
      normalizedTree,
      dropTargetId,
      selectedNodesIds,
    );

    if (conflictingNodeNames?.length) {
      toast.error(
        `Name conflict. The following name(s) already exist in the folder: ${conflictingNodeNames}`,
        {
          autoClose: 7000,
        },
      );
      return;
    }

    const resultList = addElementToTree(trees, selectedNodesIds, dropTargetId, index);
    if (changedWereMade) {
      setSelectedNodes([]);
      setIsDirty(true);
    }
    setTreeNested(resultList);
    placeholderContext?.hidePlaceholder();
  };
}
