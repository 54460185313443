const darkBlue = '#2f63d6';
const lightBlue = '#36bedf';
const green = '#12d15c';
const yellow = '#ffc451';
const pink = '#e84c85';
const purple = '#9a6cc8';
const black = '#313445';
const orange = '#f89537';
const rosa = '#e56be3';
const turquoise = '#1bdeac';
const ocean = '#77e8ef';
const brown = '#efc299';
const grey = '#d7d9e5';
const all = {
  darkBlue,
  lightBlue,
  green,
  yellow,
  pink,
  purple,
  black,
  orange,
  rosa,
  turquoise,
  ocean,
  brown,
  grey,
};

const lines = [
  purple,
  lightBlue,
  pink,
  yellow,
  green,
  rosa,
  darkBlue,
  turquoise,
  ocean,
  brown,
  orange,
];
const pies = [purple, pink, yellow, lightBlue, green];
const oceanColors = ['#d0e0fc', '#a0c2f9', '#72a4f7', '#4285F4', '#2f5ec4', '#06378BFF'].reverse();
const competitorColors = [pink, yellow, green, rosa, darkBlue, orange, black, turquoise, '#ade44e', ocean]; // prettier-ignore

const deviceTypeColors = {
  desktop: purple,
  mobile: lightBlue,
  all: black,
};
const winnersAndLosersColors = {
  winners: '#27ae60',
  // purple,
  losers: '#f75b5b',
  // lightBlue,
  unchanged: grey,
};
// Rank distribution "ocean"
// const rankDistributionColors = [purple, darkBlue, lightBlue, green, yellow, pink];
// Some pie charts
// const defaultAreasColors = pies;
// Used for keywords competitors, landing pages, tags cloud
// export const defaultLinesColors = lines;
const unknownCompetitorsColors = {
  competitors: [...pies, ...['#CD9FFB', '#FF7FB8', '#FFDE6B', '#50D8F9', '#2CEB76']],
  own: orange,
};
const rankIntervalsColors = {
  ranks1_3: '#7eb732',
  ranks4_10: '#1dace7',
  ranks11_20: '#626262',
  ranks21_50: '#ffac10',
  ranks51_100: '#e85b59',
  ranks101_rest: '#376c9f',
};
// from F89537 to 313445 http://www.perbang.dk/rgbgradient/
const googleAnalyticsColors = {
  direct: '#F89537',
  organic: '#DB8739',
  cpc: '#BF793B',
  referral: '#A26B3D',
  social: '#865D3F',
  email: '#694F41',
  display: '#4D4143',
  other: black,
};

const sovCompetitorsColors = {
  domain: purple,
  potential: '#d7d9e5',
  other: [...pies, ...['#CD9FFB', '#FF7FB8', '#FFDE6B', '#50D8F9', '#2CEB76']],
};

const mrrColors = {
  new: green,
  expansion: purple,
  // '#7d57f6',
  reactivation: lightBlue,
  contraction: yellow,
  churn: pink,
  net: black,
};

const graphsColors = {
  darkGreen: '#49A353',
  purple: '#b14fb1',
  darkBlue: '#4967BF',
  red: '#c44e52',
  brown: '#937860',
  pink: '#da8bc3',
  gray: '#8c8c8c',
  beige: '#ccb974',
  turqoise: '#64b5cd',
  green: '#AAAA00',
}; // Orange is added when own domain is present

const searchIntent = {
  I: graphsColors.darkBlue,
  T: graphsColors.darkGreen,
  N: graphsColors.purple,
  C: orange,
};

const graphBlueColors = [
  '#d4e2fa',
  '#D0E0FC',
  '#BBCFF5',
  '#A7BFEE',
  '#93AFE7',
  '#7F9FE0',
  '#6B8ED9',
  '#577ED2',
  '#436ECB',
  '#2F5EC4',
].reverse();
const graphs = {
  primaryDomain: orange,
  colors: graphsColors,
  graphBlueColors,
  searchIntent,
  stats: {
    other: graphsColors.gray,
    self: orange,
    shareOfVoice: orange,
    averageRank: darkBlue,
    averageBaseRank: graphsColors.red,
    averageCTR: graphsColors.darkGreen,
    aiTrafficValue: graphsColors.beige,
    estimatedVisits: graphsColors.purple,
    unknownCompetitorsDomain: orange,
    googleAnalytics: {
      direct: graphBlueColors[0],
      organic: graphBlueColors[1],
      cpc: graphBlueColors[2],
      referral: graphBlueColors[3],
      social: graphBlueColors[4],
      email: graphBlueColors[5],
      display: graphBlueColors[6],
      other: graphsColors.gray,
    },
  },
  getColors: () => {
    // no grey
    return Object.values(graphsColors);
  },
};

export const colorScheme = {
  orange,
  all,
  lines,
  graphs,
  increase: '#55AB68',
  decrease: '#F75B5B',
  rankDistribution: oceanColors,
  defaultAreas: pies,
  defaultLines: lines,
  competitors: competitorColors,
  winnersAndLosers: winnersAndLosersColors,
  averageRank: black,
  notes: black,
  deviceType: deviceTypeColors,
  unknownCompetitors: unknownCompetitorsColors,
  googleAnalytics: googleAnalyticsColors,
  searchVolume: '#4286f4',
  shareVoice: graphsColors.darkBlue,
  sovCompetitors: sovCompetitorsColors,
  rankIntervals: rankIntervalsColors,
  mrr: mrrColors,
};
export default colorScheme;

const getUnusedColors = (colorMap: Record<string, string>, domains: string[]) => {
  const allColors = colorScheme.graphs.getColors();
  const usedColors = colorMap
    ? Object.entries(colorMap)
        .filter(([domain, _color]) => domains.includes(domain))
        .map(([_, color]) => color)
    : [];
  return allColors.filter((color) => !usedColors.includes(color));
};
/** For any domain and a fixed colorMap mapping domain to color derived from useDomainInfo
 * this will assign a color to a domain such that no two domains have the same color and the same domain always
 * has the same color if it is present in the colorMap (which is the case for competitor domains and own domain).
 * **/
export const getCompetitorAndDomainColors = (colorMap, domains) => {
  const unusedColors = getUnusedColors(colorMap, domains);
  let unusedColorCount = 0;
  return domains.map((domain) => {
    let color = colorMap && colorMap[domain];
    if (!color) {
      color = unusedColors[unusedColorCount];
      unusedColorCount += 1;
    }
    return color;
  });
};

export const getColorsMap = (
  colorMap: Record<string, string> | undefined,
  domains: string[],
): Record<string, string> => {
  const colors = getCompetitorAndDomainColors(colorMap, domains);
  return domains.reduce(
    (acc, domain, i) => ({
      ...acc,
      [domain]: colors[i],
    }),
    { ...colorMap },
  );
};
