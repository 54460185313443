import { connect } from 'react-redux';
import { hideModal } from 'Actions/ModalAction';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import Alert from '../../../Alert';
import './loading-modal.scss';

type Props = {
  title: string;
  description: string;
  action: (...args: Array<any>) => any; // hideModal
  hideModal?: any;
};

const Loading = (props: Props) => {
  const { title, description, hideModal: _hideModal, action } = props;
  action(_hideModal);
  return (
    <ModalBorder className="loading-modal" title={title}>
      <Alert type={'info'}>{description}</Alert>
    </ModalBorder>
  );
};

export default connect(null, {
  hideModal,
})(Loading);
