import { useContext } from 'react';
import { AccMultiSelect, AccSelect, SelectItem } from 'Components/AccSelect';
import AccTag from 'Components/AccTag';
import FakeFiltersContext from 'Components/Filters/FakeFiltersContext';
import { useDomainTagsQuery } from 'Ghql';
import { useDomainIds } from 'Hooks/data/domain/useQueryDomainInfo';
import { t } from 'Utilities/i18n';
import { toArrayOrEmpty } from 'Utilities/underdash';
import { getTagsOptions } from './support/helpers';
import './form-tags-input.scss';

type Props = {
  onChange: (...args: Array<unknown>) => void;
  autoFocus?: boolean;
  placeholder: string;
  creatable?: boolean;
  showError: boolean;
  disabled?: boolean;
  // show only dynamic tags
  onlyDynamic?: boolean;
  excludeDynamic?: boolean;
};

const useTagOptions = (props: Props) => {
  const domainIds = useDomainIds();
  const { data, loading } = useDomainTagsQuery({
    variables: { ids: domainIds },
    fetchPolicy: 'network-only',
  });

  const filtersContext = useContext(FakeFiltersContext);
  const resultDomainIds = toArrayOrEmpty(filtersContext?.domainId ?? domainIds) as
    | string[]
    | undefined;

  const options: SelectItem<string>[] = !loading
    ? getTagsOptions(data, resultDomainIds, props.onlyDynamic, props.excludeDynamic)
    : [];
  return { loading, options };
};

export const SingleTagInput = (props: Props & { value: string | null }) => {
  const { loading, options } = useTagOptions(props);
  return (
    <AccSelect
      value={props.value}
      onChange={props.onChange}
      placeholder={props.placeholder}
      showError={props.showError}
      autoFocus={props.autoFocus}
      disabled={props.disabled}
      creatable={props.creatable}
      isLoading={loading}
      options={options ?? []}
      getCreateLabel={(label) => `${t('Create tag "%s"', label)}`}
    />
  );
};

export const TagsInput = (props: Props & { value: string[] | null }) => {
  const { loading, options } = useTagOptions(props);
  return (
    <AccMultiSelect
      value={props.value}
      onChange={props.onChange}
      placeholder={props.placeholder}
      showError={props.showError}
      autoFocus={props.autoFocus}
      disabled={props.disabled}
      creatable={props.creatable}
      hidePickedOptions={false}
      isLoading={loading}
      options={options ?? []}
      promptTextCreator={(label) => `${t('Create tag "%s"', label)}`}
      valueComponent={(valueProps) => (
        <AccTag
          tag={
            typeof valueProps.children === 'string' ? valueProps.children : valueProps.value || ''
          }
          dynamic={valueProps.isDynamic}
        />
      )}
    />
  );
};

TagsInput.defaultProps = {
  value: [],
  onChange: () => {},
  showError: false,
  options: [],
  disabled: false,
  creatable: true,
};

SingleTagInput.defaultProps = {
  value: undefined,
  onChange: () => {},
  showError: false,
  options: [],
  disabled: false,
  creatable: true,
};
