import { Component } from 'react';
import { AccFastIcon } from 'Components/AccFastIcon';
import {
  Baidu,
  Bing,
  Google,
  Mobile,
  Monitor,
  Naver,
  Yandex,
  YouTube,
} from 'Pages/Keywords/Table/Icon/Icons';
import { t } from 'Utilities/i18n/index';

type Props = {
  keywordData: Record<string, any>;
  showSearchType?: boolean;
};
let loadedIconData: any = null;

class RankOptions extends Component<Props> {
  static defaultProps = {
    showSearchType: true,
  };
  getIconData = () => {
    if (!loadedIconData) {
      loadedIconData = {
        A_1: {
          icon: Monitor,
          label: t('Desktop search'),
        },
        A_2: {
          icon: Mobile,
          label: t('Mobile search'),
        },
        Google: {
          icon: Google,
          label: t('Google'),
        },
        Bing: {
          icon: Bing,
          label: t('Bing'),
        },
        Yandex: {
          icon: Yandex,
          label: t('Yandex'),
        },
        Baidu: {
          icon: Baidu,
          label: t('Baidu'),
        },
        YouTube: {
          icon: YouTube,
          label: t('YouTube'),
        },
        Naver: {
          icon: Naver,
          label: t('Naver'),
        },
      };
    }

    return loadedIconData;
  };

  shouldComponentUpdate(nextProps: Props) {
    return nextProps.keywordData !== this.props.keywordData;
  }

  render() {
    const { keywordData, showSearchType } = this.props;
    const iconsData = this.getIconData();
    const searchEngineName = keywordData?.searchEngine?.name ?? '';
    const searchType = keywordData?.searchType ?? '';

    if (!searchEngineName) {
      return null;
    }

    return (
      <div className="flex-row-right">
        {showSearchType && (
          <AccFastIcon
            fit="contain"
            mr={8}
            key="searchType"
            src={iconsData[searchType].icon}
            tooltip={iconsData[searchType].label}
          />
        )}
        <AccFastIcon
          fit="contain"
          key="searchEngine"
          src={iconsData[searchEngineName].icon}
          tooltip={iconsData[searchEngineName].label}
        />
      </div>
    );
  }
}

export default RankOptions;
