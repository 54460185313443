import { SEARCH_TYPE_NAVER, SearchEngineIds } from 'Components/Modal/Content/AddSearchEngine/types';
import { t } from 'Utilities/i18n';
import FormatNumber from '../../FormatNumber/new';
import NaCell from './helpers/NaCell';

export type CTRCellProps = {
  value: {
    data: {
      dynamicCtrValue: number;
      rankId: number | string;
      searchEngineId: number;
    };
    loadingData?: boolean;
  };
};

const CTRCell = (props: CTRCellProps) => {
  const {
    value: { data },
  } = props;
  const { dynamicCtrValue, rankId } = data;

  if (rankId === 0 || dynamicCtrValue === null) {
    if (data?.searchEngineId === 1) {
      // Google
      return <NaCell tooltip={t('No CTR for this date.')} align="right" />;
    }
  }

  if (data?.searchEngineId === SearchEngineIds[SEARCH_TYPE_NAVER]) {
    return <NaCell tooltip={t('No CTR for this search engine.')} align={'right'} />;
  }

  return (
    <FormatNumber value={dynamicCtrValue} style={'percent'} className="text-right u-no-wrap" />
  );
};

export default CTRCell;
