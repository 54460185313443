import { ReportType } from 'Constants/report';
import { t } from 'Utilities/i18n';

export { getLanguageOptions } from '../../Constants/i18n';
export const PDF = ReportType.PDF;
export const EXCEL = ReportType.EXCEL;
const CSV = ReportType.CSV;
export const PUBLIC_REPORT = ReportType.PUBLIC_REPORT;
const GOOGLE_SHEETS = ReportType.GOOGLE_SHEETS;
export const getKeywordHistoryTypeOptions = () => [
  {
    value: EXCEL,
    label: t('Excel'),
  },
  {
    value: CSV,
    label: t('CSV'),
  },
  {
    value: GOOGLE_SHEETS,
    label: t('Google Sheets'),
  },
];

type ReportTypeOptionsReturn = { value: number; label: string }[];

export const getReportTypeOptions = (domains): ReportTypeOptionsReturn => {
  if (domains && domains.value.length > 1) {
    return [
      {
        value: PDF,
        label: t('PDF'),
      },
      {
        value: EXCEL,
        label: t('Excel'),
      },
      {
        value: CSV,
        label: t('CSV'),
      },
      {
        value: GOOGLE_SHEETS,
        label: t('Google Sheets'),
      },
    ];
  }

  return [
    {
      value: PDF,
      label: t('PDF'),
    },
    {
      value: EXCEL,
      label: t('Excel'),
    },
    {
      value: CSV,
      label: t('CSV'),
    },
    {
      value: GOOGLE_SHEETS,
      label: t('Google Sheets'),
    },
    {
      value: PUBLIC_REPORT,
      label: t('Public report'),
    },
  ];
};

type ScheduledReportTypeOptionsReturn = { value: number; label: string }[];

export const getScheduledReportTypeOptions = (): ScheduledReportTypeOptionsReturn => [
  {
    value: PDF,
    label: t('PDF'),
  },
  {
    value: EXCEL,
    label: t('Excel'),
  },
  {
    value: CSV,
    label: t('CSV'),
  },
  {
    value: GOOGLE_SHEETS,
    label: t('Google Sheets'),
  },
];
export const getDefaultEmailSubject = (): string => t('Scheduled AccuRanker report');
export const getDefaultEmailBody = (): string =>
  t('Hi,\n\nYour scheduled AccuRanker report is attached.');
