import { useMemo } from 'react';
import { withKeywordCellLoader } from 'Components/AccuTable/CellRenderer/HelperComponents/CellLoaders/withKeywordCellLoader';
import CountryCell from 'Components/AccuTable/CellRenderer/countryCell';
import CTRCell, { CTRCellProps } from 'Components/AccuTable/CellRenderer/ctr';
import CtrChangeCell from 'Components/AccuTable/CellRenderer/ctrChange';
import LocationCell from 'Components/AccuTable/CellRenderer/locationCell';
import RankCell, { RankCellProps } from 'Components/AccuTable/CellRenderer/rank';
import RankChangeCell, { RankChangeCellProps } from 'Components/AccuTable/CellRenderer/rankChange';
import SearchIntentCell from 'Components/AccuTable/CellRenderer/searchIntent';
import SearchTypeEngineCell from 'Components/AccuTable/CellRenderer/searchTypeEngine';
import SearchVolumeCell, {
  SearchVolumeCellProps,
} from 'Components/AccuTable/CellRenderer/searchVolume';
import { ColumnType, withRowContext } from 'Components/DataTable';
import { RowContextProps } from 'Components/DataTable/table-core/context/RowContext';
import { MetaDataFragment, useMetaDataQuery } from 'Ghql';
import { useLanguage } from 'Hooks/data/domain/useQueryDomainInfo';
import { keywordOrderConfig } from 'Pages/Keywords/Table/hooks/keyword/constants';
import { IconContainer } from 'Pages/Keywords/Table/support/IconContainer/IconContainer';
import { t } from 'Utilities/i18n';
import { notEmpty } from 'Utilities/underdash';
import DevicesIcon from 'icons/devices.svg?inline';
import { ColumnIDs } from '../support/ColumnIDs';
import type { PublicTableKeyword } from '../support/useFetchPublicKeywords';

type ColumnContext = {
  countryLocales: NonNullable<NonNullable<MetaDataFragment['countrylocales']>[number]>[];
  searchTypes: NonNullable<NonNullable<MetaDataFragment['searchTypes']>[number]>[];
  searchEngines: NonNullable<NonNullable<MetaDataFragment['searchEngines']>[number]>[];
  rowContext: RowContextProps;
};

export const usePublicTableColumns = ({
  includeAdvancedColumns,
}: {
  includeAdvancedColumns: boolean;
}) => {
  const { data } = useMetaDataQuery();

  const language = useLanguage();

  const cellRendererParams: ColumnContext = useMemo(
    () => ({
      countryLocales: data?.metaData?.countrylocales?.filter(notEmpty) || [],
      searchTypes: data?.metaData?.searchTypes?.filter(notEmpty) || [],
      searchEngines: data?.metaData?.searchEngines?.filter(notEmpty) || [],
      rowContext: {
        expandComponentProps: { useAI: false },
        id: '',
        expandExtraRows: false,
        customExpand: false,
        toggleExpandRows: () => {},
        toggleCustomExpand: () => {},
      },
    }),
    [data],
  );

  const columns: ColumnType<PublicTableKeyword, ColumnContext>[] = useMemo(
    () => [
      {
        id: ColumnIDs.KEYWORD,
        title: t('Keyword'),
        flex: true,
        width: 100,
        cellRenderer: ({ record }) => <span>{record.keyword}</span>,
        onHeaderCell: () => ({
          tooltip: t('Keyword'),
          ordering: keywordOrderConfig.keyword,
          menuTitle: t('Keyword'),
        }),
      },
      {
        id: ColumnIDs.SEARCH_TYPE,
        title: (
          <IconContainer size="md">
            <DevicesIcon />
          </IconContainer>
        ),

        width: 52,
        cellRenderer: SearchTypeEngineCell,
        cellRendererParams,
      },
      ...(includeAdvancedColumns
        ? [
            {
              id: ColumnIDs.SEARCH_INTENT,
              title: 'SI',
              width: 64,
              cellRenderer: SearchIntentCell,
              onHeaderCell: () => ({
                tooltip: t('Search Intent'),
                ordering: keywordOrderConfig.searchIntent,
                hideIconOnHover: true,
                menuTitle: t('Search Intent'),
              }),
            },
          ]
        : []),
      {
        id: ColumnIDs.COUNTRY,
        title: t('Country'),
        width: 72,
        cellRenderer: CountryCell,
        cellRendererParams,
      },
      {
        id: ColumnIDs.LOCATION,
        title: t('Location'),
        width: 120,
        cellRenderer: LocationCell,
        cellRendererParams,
        onHeaderCell: () => ({
          tooltip: t('Location'),
          ordering: keywordOrderConfig.countryLocale,
        }),
      },
      {
        id: ColumnIDs.RANK,
        combineRow: ColumnIDs.RANK_CHANGE,
        title: t('Rank'),
        width: 85,
        cellRenderer: withKeywordCellLoader(
          withRowContext(
            (props) =>
              RankCell({
                ...props,
                includeRankHistoryBtn: true,
                includeExtraRankBtn: false,
              } as RankCellProps) as any,
          ),
        ),
        onHeaderCell: () => ({
          tooltip: t('Rank'),
          ordering: keywordOrderConfig.rank,
          reverseDirection: true,
        }),
      },
      {
        id: ColumnIDs.RANK_CHANGE,
        title: t('+/-'),
        width: 60,
        cellRenderer: (props) => RankChangeCell(props as unknown as RankChangeCellProps),
        onHeaderCell: () => ({
          tooltip: t('Change in Rank between the two compared dates'),
          ordering: keywordOrderConfig.rankChange,
          reverseDirection: true,
          hideIconOnHover: true,
          menuTitle: t('Rank change'),
        }),
      },
      {
        id: ColumnIDs.SEARCH_VOLUME,
        title: t('Searches'),
        width: 80,
        cellRenderer: withKeywordCellLoader(
          withRowContext((props) =>
            SearchVolumeCell({
              ...props,
              expandable: false,
              useAi: false,
            } as SearchVolumeCellProps),
          ),
        ),
        cellRendererParams,
        onHeaderCell: () => ({
          tooltip: t('Average monthly Search Volume'),
          ordering: keywordOrderConfig.searchVolume,
        }),
      },
      ...(includeAdvancedColumns
        ? [
            {
              id: ColumnIDs.CTR,
              combineRow: ColumnIDs.DYNAMIC_CTR_CHANGE,
              title: t('CTR'),
              width: 50,
              cellRenderer: (props) => CTRCell(props as unknown as CTRCellProps),
              onHeaderCell: () => ({
                tooltip: t('Click-through rate'),
                ordering: keywordOrderConfig.dynamicCtr,
                reverseDirection: true,
                hideIconOnHover: true,
                menuTitle: t('Click-through Rate'),
              }),
            },
          ]
        : []),
      ...(includeAdvancedColumns
        ? [
            {
              id: ColumnIDs.DYNAMIC_CTR_CHANGE,
              title: t('+/-'),
              width: 60,
              cellRenderer: CtrChangeCell,
              onHeaderCell: () => ({
                tooltip: t('Change in ctr between the two compared dates'),
                ordering: keywordOrderConfig.dynamicCtrChange,
                reverseDirection: true,
                hideIconOnHover: true,
                menuTitle: t('Change in CTR'),
              }),
            },
          ]
        : []),
    ],
    [includeAdvancedColumns, language, cellRendererParams],
  );

  return columns;
};
