import { useEffect, useMemo, useState } from 'react';
import { calculateElementsPosition, calculateItemsPerScreen, getStoreKey } from '../helpers';
import { viewStore } from '../store';

export const useTableMetaInfo = (itemsCount: number, containerRef) => {
  const [version, setVersion] = useState(0);
  const itemsPerScreen = useMemo(() => {
    return calculateItemsPerScreen(itemsCount);
  }, [itemsCount, version]);

  const itemsPositions = useMemo(() => {
    return calculateElementsPosition(itemsPerScreen, containerRef, itemsCount);
  }, [itemsPerScreen, itemsCount, version]);

  const itemIndexes = useMemo(() => {
    return itemsPositions.map((e) => e.index);
  }, [itemsPositions]);

  const handleCountUpdate = (nextItemsCount, nextItemIndexes) => {
    viewStore?.updateAll(
      nextItemsCount,
      nextItemIndexes.map((e, i) => ({
        index: i,
        nextIndex: e,
      })),
      getStoreKey(itemsPerScreen, itemsCount),
    );
  };
  useEffect(() => {
    handleCountUpdate(itemsPerScreen, itemIndexes);
  }, [itemsPerScreen, itemsPositions]);

  useEffect(() => {
    const onResize = () => {
      const nextItemsCount = calculateItemsPerScreen(itemsCount);

      if (nextItemsCount && nextItemsCount !== itemsPerScreen) {
        setVersion((v) => v + 1);
      }
    };
    window.addEventListener('resize', onResize, true);
    return () => {
      window.removeEventListener('resize', onResize, true);
    };
  }, [itemsCount, itemsPerScreen]);

  return { itemsPerScreen, itemsPositions, itemIndexes };
};
