/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ApolloError } from '@apollo/client';
import { useDebouncedValue } from '@mantine/hooks';
import { SeriesItem } from 'Components/Chart/LineChart';
import { CompareInputNode, FolderMetric, useCompareChartsQuery } from 'Ghql';
import { useFilters } from 'Hooks';
import { DomainsFilter } from 'Types/Filter';
import { folderSeparator } from 'Utilities/generateSelectId';
import { useComparisonContext } from '../useComparisonContext';

type ChartData = {
  value: number;
  searchDate: number;
};

const mapToChartData = (data: ChartData[]) => {
  return data.map((entry) => {
    return {
      x: entry.searchDate,
      y: entry.value,
    };
  });
};

const convertToNestedJson = (input: string[]): CompareInputNode => {
  const result: CompareInputNode = { clients: [] };

  input.forEach((item: string) => {
    const [clientId, domainId, ...pathParts] = item.split(folderSeparator);

    let client = result.clients?.find((c) => c!.id === parseInt(clientId, 10));
    if (!client) {
      client = { id: parseInt(clientId, 10), includeSelf: false, domains: [] };
      result.clients!.push(client);
    }

    if (domainId) {
      let domain = client.domains?.find((d) => d!.id === parseInt(domainId, 10));
      if (!domain) {
        domain = { id: parseInt(domainId, 10), includeSelf: false, paths: [] };
        client.domains!.push(domain);
      }

      if (pathParts.length > 0) {
        const fullPath = pathParts.join('¦');
        if (!domain.paths?.includes(fullPath)) {
          domain.paths!.push(fullPath);
        }
      } else {
        domain.includeSelf = true;
      }
    } else {
      client.includeSelf = true;
    }
  });

  return result;
};

export const useFetchCharts = (
  currency: string | null,
): {
  series: SeriesItem[];
  displayCurrency: string | undefined;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const filters = useFilters();
  // If there are no domains filters, we need to add an empty one as this is not optional in the backend
  const emptyDomainsFilter: DomainsFilter = {
    attribute: 'domains',
    type: 'list',
    comparison: 'contains',
    value: [],
  };
  const filtersIncludesDomains = filters.some((filter) => filter.attribute === 'domains');
  const { selected, colorAssignments, compareBy } = useComparisonContext();
  const [debounceSelected] = useDebouncedValue(selected, 500);
  const compareInput = convertToNestedJson(debounceSelected);
  const { data, loading, error } = useCompareChartsQuery({
    variables: {
      filters: [...filters, ...(filtersIncludesDomains ? [] : [emptyDomainsFilter])],
      displayCurrency: currency,
      metric: compareBy as FolderMetric,
      compareInput,
    },
  });

  const series: SeriesItem[] =
    data?.graphs?.compareAcrossHistory?.map((node) => {
      return {
        name: node.name,
        primary: false,
        visible: true,
        color: colorAssignments[node.id],
        data: mapToChartData(node.data),
      };
    }) || [];
  return { series, displayCurrency: data?.graphs?.displayCurrency || undefined, loading, error };
};
