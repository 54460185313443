import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { StoreType } from 'Types/Store';
import { GroupSettingsItem } from '../types';

export const useStateColumns = <T extends string[] | GroupSettingsItem[] | null>(
  selector: (a: StoreType) => T,
): T => {
  return useSelector(selector, isEqual);
};
