import { useState } from 'react';
import { Group } from '@mantine/core';
import AccLazyPopover from 'Components/AccLazyPopover/AccLazyPopover';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import Top10DomainsLazy from 'Components/AccuTable/CellRenderer/HelperComponents/Top10DomainsPopover/top10Domains';
import { useRowContext } from 'Components/DataTable';
import { DomainFavicon } from 'Components/DomainFavicon';
import AccText from 'Components/Text/AccText';
import { t } from 'Utilities/i18n';

type Props = {
  value: {
    data: {
      topDomain: string;
      rankId: string;
      searchDate: string;
      id: string;
      updatingKeyword: boolean;
      baseRank: number;
      searchEngineId: number;
    };
    loadingData: boolean;
  };
};

const TopDomainCell = (props: Props) => {
  const { inView } = useRowContext();
  const {
    value: { data },
  } = props;
  const { topDomain, rankId, searchDate, id: keywordId, baseRank, searchEngineId } = data;

  const [popoverOpen, setPopoverOpen] = useState(false);

  if (parseInt(rankId) === 0) {
    return null;
  }

  const trigger = (
    <Group w={136} className="top-domain-container" gap={'xs'} wrap="nowrap">
      <DomainFavicon domain={topDomain || 'Local'} disabledTooltip />
      <AccText size="sm" variant="label" c="snorlax" nowrap overflowEllipsis noOverflowTooltip>
        {topDomain || t('Local result')}
      </AccText>
    </Group>
  );

  if (!inView) return trigger;

  return (
    <AccLazyPopover
      onToggle={() => setPopoverOpen(!popoverOpen)}
      target={
        <AccTooltip tooltip={t('Show top 10 competitors')} disable={popoverOpen}>
          {trigger}
        </AccTooltip>
      }
    >
      {() => (
        <Top10DomainsLazy
          rankId={rankId}
          searchDate={searchDate}
          keywordId={keywordId}
          ownRank={baseRank}
          searchEngineId={searchEngineId}
        />
      )}
    </AccLazyPopover>
  );
};

export default TopDomainCell;
