import { Box } from '@mantine/core';
import Accordion from 'Components/Accordion';
import { t } from 'Utilities/i18n';
import './select-plan.scss';

/**
 * PlanFeatures.
 *
 * Simple component that lists all features for our plans.
 * Does not accept any props (all plans share the same features).
 */

type PlanFeatureGroup = {
  name: string;
  features: (string | string[])[];
};

const getFeatures = (): PlanFeatureGroup[] => [
  {
    name: t('Search Engines'),
    features: [t('Google'), t('Bing'), t('Youtube')],
  },
  {
    name: t('Search Type'),
    features: [t('Desktop'), t('Mobile')],
  },
  {
    name: t('Local Search'),
    features: [t('Country'), t('State'), t('County'), t('City'), t('Zipcode'), t('Street')],
  },
  {
    name: t('Live Keyword Data'),
    features: [
      t('Automatic updated every 24 hour'),
      [t('Unlimited on-demand'), t('Individual keyword'), t('All keywords')],
    ],
  },
  {
    name: t('Advanced Filtering'),
    features: [t('25+ filter types'), t('Save as segments'), t('100% Dynamic')],
  },
  {
    name: t('Keyword Research'),
    features: [t('24B keywords globally'), t('Advanced filtering')],
  },
  {
    name: t('Organic Site Explorer'),
    features: [t('Easy organic overview'), t('Identify competitors keywords')],
  },
  {
    name: t('Dynamic Keyword Tagging'),
    features: [
      t('Custom rules'),
      t('Multiple OR, AND & Nested statements'),
      [t('Parameters'), t('Keyword'), t('URL'), t('Page title'), t('Rank')],
    ],
  },
  {
    name: t('SERP Feature Tracking'),
    features: [t('We track it all'), t('Competitor Tracking'), t('Dynamic competitors')],
  },
  {
    name: t('Share of Voice'),
    features: [t('Measure your own share of voice'), t('Measure your competitors\' share of voice')],
  },
  {
    name: t('Historic Search Volume'),
    features: [t('Google'), t('Youtube'), t('Bing')],
  },
  {
    name: t('Advanced Reporting'),
    features: [
      t('Custom templates'),
      [t('Report scheduling'), t('Daily'), t('Weekly'), t('Monthly')],
    ],
  },
  {
    name: t('Report type'),
    features: [
      t('PDF'),
      t('CSV'),
      t('Excel'),
      t('Google sheet'),
      t('Live'),
      t('Google Data Studio'),
    ],
  },
  {
    name: t('Integration'),
    features: [
      t('Google Data Studio'),
      t('Google Analytics 4'),
      t('Google Search Console'),
      t('Adobe Analytics'),
      t('Unlimited Restful API'),
    ],
  },
  {
    name: t('Data storage'),
    features: [t('We store your ranks forever')],
  },
  {
    name: t('Data migration'),
    features: [
      t('50+ Supported platforms'),
      t('All historical data, no matter the timeframe'),
      t('Support from the customer success team'),
    ],
  },
  {
    name: t('Users'),
    features: [t('Unlimited users'), t('Invite all your colleagues'), t('No extra cost')],
  },
  {
    name: t('Domains / Projects'),
    features: [
      t('Unlimited'),
      t('Create as many domains/projects as you need'),
      t('No extra cost'),
    ],
  },
];

const renderPlanFeature = (feature: string | string[]) => {
  if (typeof feature === 'string') {
    return <li key={`li-${feature}`}>{feature}</li>;
  }

  if (Array.isArray(feature) && feature.length >= 2) {
    const mainItem = feature[0];
    const subItems = feature.slice(1);
    return (
      <li key={`li-${mainItem}`}>
        {mainItem}
        <ul className={'select-plan-container__plan-group-ul'}>
          {subItems.map((sub) => renderPlanFeature(sub))}
        </ul>
      </li>
    );
  }

  // eslint-disable-next-line no-console
  console.error('Unexpected feature list item:', feature);
};

const renderPlanFeatureGroup = (pfg: PlanFeatureGroup, index: number) => {
  return (
    <Accordion
      key={`panel-${pfg.name}-${index}`}
      titleRow={<span className={'select-plan-container__plan-group'}> {pfg.name}</span>}
      initiallyCollapsed={index !== 0}
    >
      <ul className={'select-plan-container__plan-group-ul'}>
        {pfg.features.map(renderPlanFeature)}
      </ul>
    </Accordion>
  );
};

const PlanFeatures = (): JSX.Element => {
  const features = getFeatures();
  return (
    <div className={'select-plan-container__plan-description'}>
      {features.map(renderPlanFeatureGroup)}
      <Box mt={'md'}>{t('… and much much more')}</Box>
    </div>
  );
};

export default PlanFeatures;
