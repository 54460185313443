import React, { useCallback, useMemo, useState } from 'react';
import { FolderMetric } from 'Ghql';
import { MaxSelectableNodes } from 'Pages/Domains/support/const';
import { EventName, useMixpanel } from 'Utilities/Analytics/mixpanel';
import colorPalette from 'Utilities/colorPalette';
import { ItemType } from 'Utilities/generateSelectId';
import { assignColorsToKeys } from './support/assignColorsToKeys';
import { filterTree } from './support/filterTree';
import { getAllExpanded } from './support/getAllExpanded';
import { getAllIds } from './support/getAllIds';

export type TreeItem = {
  cid?: number;
  cn?: string;
  did?: number;
  dd?: string;
  dn?: string;
  ds?: TreeItem[];
  fs?: TreeItem[];
  cc?: string;
  fav?: string;
  ts?: TreeItem[];
  p?: string;
  dyn?: number;
  tn?: string;
  label?: string;
  type?: ItemType;
  selectId?: string;
  disabled?: boolean;
  demo?: boolean;
};

type ComparisonContextValue = {
  data: TreeItem[];
  selected: string[];
  expanded: string[];
  compareBy: string;
  search: string;
  colorAssignments: Record<string, string>;
  toggleSelect: (id: string) => void;
  toggleOpen: (id: string) => void;
  expandAll: () => void;
  collapseAll: () => void;
  setCompareBy: React.Dispatch<React.SetStateAction<string>>;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  getItemColor: (id: string) => string;
};

const ComparisonContext = React.createContext<ComparisonContextValue | undefined>(undefined);

type ComparisonProviderProps = {
  children: React.ReactNode;
  data: TreeItem[];
  initialSelect?: string[];
  initialCompareBy?: string;
};

/** Check if a string is a valid FolderMetric compareBy value  */
const isFolderMetricValue = (value: string): value is FolderMetric => {
  return Object.values(FolderMetric).includes(value as FolderMetric);
};

export const ComparisonProvider: React.FC<ComparisonProviderProps> = ({
  children,
  data,
  initialSelect,
  initialCompareBy,
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const allItems = useMemo(() => getAllIds(data), [JSON.stringify(data)]);
  const [selected, setSelected] = useState<string[]>(initialSelect || []);
  const [expanded, setExpanded] = useState<string[]>(getAllExpanded(initialSelect || []));
  const [compareBy, setCompareBy] = useState<string>(
    initialCompareBy && isFolderMetricValue(initialCompareBy)
      ? initialCompareBy
      : FolderMetric.EstimatedVisits,
  );
  const [search, setSearch] = useState<string>('');
  // Color assignment is used to prevent color shuffling when items are selected/deselected
  const [colorAssignments, setColorAssignments] = useState<Record<string, string>>(
    assignColorsToKeys(initialSelect || []),
  );
  const filteredData = filterTree(data, search.toLowerCase());

  const toggleSelect = useCallback((id: string) => {
    setSelected((current) => {
      if (current.includes(id)) {
        setColorAssignments((prev) => {
          // color assignment for the item being removed
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { [id]: _, ...rest } = prev;
          return rest;
        });
        return current.filter((item) => item !== id);
      }
      setColorAssignments((prev) => {
        const pickedColors = Object.values(prev);
        const fistAvaliableColor =
          colorPalette.find((col) => !pickedColors.includes(col)) || colorPalette[0];
        return {
          ...prev,
          [id]: fistAvaliableColor,
        };
      });
      return [...current, id];
    });
  }, []);

  const toggleOpen = useCallback((id: string) => {
    setExpanded((current) => {
      return current.includes(id) ? current.filter((item) => item !== id) : [...current, id];
    });
  }, []);

  const expandAll = () => {
    setExpanded(allItems);
  };

  const collapseAll = () => {
    setExpanded([]);
  };

  const getItemColor = useCallback(
    (id: string) => {
      return colorAssignments[id] || '';
    },
    [colorAssignments],
  );

  const value = {
    data: filteredData,
    selected,
    expanded,
    compareBy,
    search,
    colorAssignments,
    toggleSelect,
    toggleOpen,
    expandAll,
    collapseAll,
    setCompareBy,
    setSearch,
    getItemColor,
  };

  return <ComparisonContext.Provider value={value}>{children}</ComparisonContext.Provider>;
};

export const useComparisonContext = () => {
  const context = React.useContext(ComparisonContext);
  if (context === undefined) {
    throw new Error('useComparisonContext must be used within a ComparisonProvider');
  }
  return context;
};

export const useComparisonItemContext = (item: TreeItem) => {
  const trackEvent = useMixpanel();
  const context = useComparisonContext();
  const selectId = item.selectId || '';
  const selected = context.selected.includes(selectId);
  const disabled = context.selected.length >= MaxSelectableNodes && !selected;
  const toggleSelect = () => {
    context.toggleSelect(selectId);
    // if max selectable domains will be reached on select action - sent mixpanel event
    if (context.selected.length + 1 >= MaxSelectableNodes && !selected) {
      trackEvent(EventName.DomainsComparisonMaxCompareReached);
    }
  };
  return {
    selected,
    disabled,
    expanded: context.expanded.includes(selectId),
    toggleSelect,
    toggleOpen: () => context.toggleOpen(selectId),
    color: context.getItemColor(selectId),
  };
};
