import { useState } from 'react';
import { SelectItem } from 'Components/AccSelect';
import { SearchVolumeSettingInput, useDomainDisplaySettingsQuery } from 'Ghql';
import { t } from 'Utilities/i18n';

const getLocalSearchOptions = (): SelectItem<string>[] => [
  {
    value: SearchVolumeSettingInput.AccountDefault,
    label: t('Account Default'),
  },
  {
    value: SearchVolumeSettingInput.Country,
    label: t('Country'),
  },
  {
    value: SearchVolumeSettingInput.Local,
    label: t('Local'),
  },
];

export const useLocalSearch = (domainId: string) => {
  const [localSearchSetting, setLocalSearchSetting] = useState<string>('');

  useDomainDisplaySettingsQuery({
    variables: {
      id: domainId,
    },
    onCompleted: ({ domain }) => {
      const { showLocalSearchVolume } = domain || {};
      let localSearchSettingValue: SearchVolumeSettingInput;
      switch (showLocalSearchVolume) {
        case true:
          localSearchSettingValue = SearchVolumeSettingInput.Local;
          break;
        case false:
          localSearchSettingValue = SearchVolumeSettingInput.Country;
          break;
        default:
          localSearchSettingValue = SearchVolumeSettingInput.AccountDefault;
          break;
      }
      setLocalSearchSetting(localSearchSettingValue);
    },
  });

  const localSearchOptions = getLocalSearchOptions();

  return { localSearchOptions, localSearchSetting };
};
