import { AccFastIcon } from 'Components/AccFastIcon';
import { WithRowContext } from 'Components/DataTable/table-core';
import { t } from 'Utilities/i18n';
import AboveTheFoldIcon from 'icons/abovethefold.svg';

type Props = {
  value: {
    data: {
      id: number;
      [data: string]: any;
    };
    loadingData: boolean;
  };
} & WithRowContext;

export const AboveTheFold = ({ value }: { value: boolean }) => {
  return (
    <AccFastIcon
      fit="contain"
      src={AboveTheFoldIcon}
      color={value ? '#f89537' : '#6b6c6c'}
      tooltip={value ? t('Above the fold') : t('Not above the fold')}
      size={16}
      ml={4}
      style={value ? {} : { transform: 'scaleY(-1)' }}
    />
  );
};

const AboveTheFoldCell = (props: Props): JSX.Element | null => {
  const {
    value: {
      data: { aboveTheFold },
    },
  } = props;

  return <AboveTheFold value={aboveTheFold} />;
};

export default AboveTheFoldCell;
