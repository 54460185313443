import { KeyboardEvent } from 'react';
import * as React from 'react';
import { IconX } from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import { formatKeys } from 'Components/Modal/Content/Shortcuts';
import { t } from 'Utilities/i18n/index';
import { FilterDynamicProps } from '../../Filters/types';
import styles from './edit-form.module.scss';
import './edit-form.scss';

type EditFormProps = {
  title: string;
  icon?: React.ReactNode;
  keyboardCombo?: string;
  children: React.ReactNode;
  onCancel: (...args: Array<any>) => any;
  onSubmit: (...args: Array<any>) => any;
  submitLabel: string;
  cancelLabel?: string;
  cancelBtnStyle?: any;
  deleteLabel?: string;
  onDelete?: ((...args: Array<any>) => any) | null | undefined;
  error?: string;
  isNew?: boolean;
  /** Disables Analytics event tracking on the submit button. */
  disableTrackingOnSubmit?: boolean;
} & FilterDynamicProps;

const EditForm = (props: EditFormProps) => {
  const {
    title,
    icon = null,
    keyboardCombo,
    children,
    onDelete = null,
    onCancel,
    submitLabel = 'OK',
    cancelLabel = 'Cancel',
    deleteLabel,
    error = '',
    onSubmit,
    cancelBtnStyle = {},
    isNew,
    disableTrackingOnSubmit,
  } = props;

  const handleKeydown = (e: KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSubmit();
    }
  };

  let deleteButton: JSX.Element | null = null;

  if (onDelete && !isNew) {
    deleteButton = (
      <AccButton variant="tertiary" leftSection={<IconX />} onClick={onDelete}>
        {deleteLabel || t('Delete')}
      </AccButton>
    );
  }

  return (
    <form className="edit-form" onKeyDown={handleKeydown} onSubmit={(e) => e.preventDefault()}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>
          {icon}
          {title}
        </div>
        {keyboardCombo && <span>{formatKeys(keyboardCombo)}</span>}
      </div>
      <div>{children}</div>
      {error && <div className="error-message">{error}</div>}
      <div className="action-buttons">
        {deleteButton}
        <div className="spacer" />
        {onCancel && (
          <AccButton onClick={onCancel} variant="tertiary" style={cancelBtnStyle}>
            {cancelLabel}
          </AccButton>
        )}
        <AccButton
          ml={8}
          onClick={onSubmit}
          variant="primary"
          disabled={props.disableAdd}
          noTracking={disableTrackingOnSubmit}
        >
          {submitLabel}
        </AccButton>
      </div>
    </form>
  );
};

export default EditForm;
