const DATE = 'Date';
const KEYWORD = 'Keyword';
const ENGINE = 'engine';
const LOCALE = 'locale';
const GEO = 'geo';
const DEVICE = 'device';

export const ColumnIDs = {
  DATE,
  KEYWORD,
  ENGINE,
  LOCALE,
  GEO,
  DEVICE,
} as const;
