/* eslint-disable import/no-unused-modules */
import type { ElementType, ReactElement, RefObject } from 'react';
import React from 'react';
import type { DragSourceMonitor, XYCoord } from 'react-dnd';
import type { DragDropMonitor } from 'dnd-core';

export enum DropDirection {
  UP = 'up',
  DOWN = 'down',
  CENTER = 'center',
}

export type NodeModel = {
  id: number | string;
  parent: number | string;
  text: string;
  name?: string;
  droppable?: boolean;
  isFolder?: boolean;
  editMode?: boolean;
};

export type DragItem = NodeModel & {
  ref: RefObject<HTMLElement>;
};

export type RenderParams = {
  depth: number;
  isOpen: boolean;
  isDragging: boolean;
  isDropTarget: boolean;
  draggable: boolean;
  hasChild: boolean;
  containerRef: RefObject<HTMLElement>;
  handleRef: RefObject<any>;
  onToggle(): void;
};

export type NodeRender = (node: NodeModel, params: RenderParams) => ReactElement;

export type DropHandler = (
  dragSource: NodeModel | null,
  dropTargetId: NodeModel['id'],
  index: number,
  direction: DropDirection | undefined,
) => void;

export type CanDropHandler = (
  dragSourceId: NodeModel['id'],
  dropTargetId: NodeModel['id'],
) => boolean | void;

export type CanDragHandler = (id: NodeModel['id']) => boolean;

export type Classes = {
  root?: string;
  container?: string;
  listItem?: string;
  dropTarget?: string;
  draggingSource?: string;
  placeholder?: string;
};

export type SortCallback = (a: NodeModel, b: NodeModel) => number;

export type DragLayerMonitorProps = {
  item: DragItem;
  clientOffset: XYCoord | null;
  isDragging: boolean;
};

export type DragPreviewRender = (monitorProps: DragLayerMonitorProps) => ReactElement;

export type PlaceholderRenderParams = {
  depth: number;
};

export type PlaceholderRender = (node: NodeModel, params: PlaceholderRenderParams) => ReactElement;

export type ChangeOpenHandler = (newOpenIds: NodeModel['id'][]) => void;

export type InitialOpen = boolean | NodeModel['id'][];

export type DragSourceElement = EventTarget | null;

export type DragControlState = {
  isLock: boolean;
  lock: () => void;
  unlock: () => void;
};

export type PlaceholderState = {
  dropTargetId: NodeModel['id'] | undefined;
  direction: DropDirection | undefined;
  index: number | undefined;
  showPlaceholder: (
    parentId: NodeModel['id'],
    index: number,
    direction: DropDirection | undefined,
  ) => void;
  hidePlaceholder: () => void;
};

export type RootProps = Omit<React.HtmlHTMLAttributes<HTMLElement>, 'ref' | 'role'>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type TreeStateBase<T> = {
  tree: NodeModel[];
  rootId: NodeModel['id'];
  classes?: Classes;
  rootProps?: RootProps;
  render?: NodeRender;
  dragPreviewRender?: DragPreviewRender;
  placeholderRender?: PlaceholderRender;
  onDragStart?: (node: NodeModel, monitor: DragSourceMonitor) => void;
  onDragEnd?: (node: NodeModel, monitor: DragSourceMonitor) => void;
};

export type TreeState<T = unknown> = TreeStateBase<T> & {
  extraAcceptTypes: string[];
  listComponent: ElementType;
  listItemComponent: ElementType;
  placeholderComponent: ElementType;
  insertDroppableFirst: boolean;
  enableAnimateExpand: boolean;
  dropTargetOffset: number;
  expandedNodes?: (string | number)[];
  initialOpen: InitialOpen;
  onDrop: DropHandler;
  canDrop?: CanDropHandler;
  canDrag?: CanDragHandler;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type DropOptions<T = unknown> = {
  dragSourceId?: NodeModel['id'];
  dropTargetId: NodeModel['id'];
  dragSource?: NodeModel;
  dropTarget?: NodeModel;
  destinationIndex?: number;
  relativeIndex?: number;
  monitor: DragDropMonitor;
  index?: number;
  direction: DropDirection | undefined;
};

export type TreeProps<T = unknown> = TreeStateBase<T> & {
  extraAcceptTypes?: string[];
  listComponent?: ElementType;
  listItemComponent?: ElementType;
  placeholderComponent?: ElementType;
  insertDroppableFirst?: boolean;
  enableAnimateExpand?: boolean;
  dropTargetOffset?: number;
  initialOpen?: InitialOpen;
  expandedNodes?: (string | number)[];
  onChangeOpen?: ChangeOpenHandler;
  onDrop: (tree: NodeModel[], options: DropOptions<T>, placeholderContext?: any) => void;
  canDrop?: (
    tree: NodeModel[],
    options: DropOptions<T>,
    placeholderContext?: any,
  ) => boolean | void;
  canDrag?: (node: NodeModel | undefined) => boolean;
};
