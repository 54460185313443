import { PropsWithChildren, forwardRef } from 'react';
import {
  Paper as MantinePaper,
  MantineRadius,
  MantineShadow,
  MantineSpacing,
  PaperProps,
} from '@mantine/core';

// eslint-disable-next-line import/no-unused-modules
export type AccPaperProps = Omit<PaperProps, 'shadow'> & {
  shadow?: MantineShadow | 'none';
};

// eslint-disable-next-line import/no-unused-modules
export const DEFAULT_SHADOW: MantineShadow = 'md';
// eslint-disable-next-line import/no-unused-modules
export const DEFAULT_RADIUS: MantineRadius = 'md';
// eslint-disable-next-line import/no-unused-modules
const DEFAULT_PAPER_PADDING_X: MantineSpacing = 'lg';
const DEFAULT_PAPER_PADDING_Y: MantineSpacing = 'md';

/**
 * Paper component with defaults
 */
const AccPaper = forwardRef<HTMLDivElement, PropsWithChildren<AccPaperProps>>((props, ref) => {
  const {
    shadow = DEFAULT_SHADOW,
    radius = DEFAULT_RADIUS,
    py = DEFAULT_PAPER_PADDING_Y,
    px = DEFAULT_PAPER_PADDING_X,
    children,
    ...rest
  } = props;

  return (
    <MantinePaper ref={ref} {...rest} shadow={shadow} radius={radius} py={py} px={px}>
      {children}
    </MantinePaper>
  );
});
AccPaper.displayName = 'AccPaper';
export default AccPaper;
