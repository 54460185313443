import { useDispatch } from 'react-redux';
import { hideModal } from 'Actions/ModalAction';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { useCompetitorsReportForm_CreateReportMutationMutation } from 'Ghql';
import { t } from 'Utilities/i18n';
import StandardReportForm from './CompetitorsReportForm';

type Props = {
  domainId: string;
};

export default function CompetitorsReport(props: Props) {
  const { domainId } = props;
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(hideModal());
  };

  const [createReportMutation, { loading }] =
    useCompetitorsReportForm_CreateReportMutationMutation();

  const nextModalType = 'CompetitorsReport';

  return (
    <ModalBorder title={t('Download Report')} onClose={handleClose}>
      <StandardReportForm
        domainId={domainId}
        generatingReport={loading}
        createReportMutation={createReportMutation}
        nextModalType={nextModalType}
        dataField={'scheduleCompetitorsReport'}
      />
    </ModalBorder>
  );
}
