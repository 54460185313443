import { useEffect } from 'react';

export const useGlobalDragHandle = (isDragging?: boolean) => {
  const toggleGrabbing = (isGrabbing: boolean) => {
    if (!document) {
      return;
    }
    if (isGrabbing) {
      document.body.style.cursor = 'grabbing';
    } else if (document.body.style?.cursor) {
      document.body.style.cursor = '';
    }
  };

  useEffect(() => {
    toggleGrabbing(!!isDragging);
    return () => toggleGrabbing(false);
  }, [isDragging]);
};
