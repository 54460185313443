import { memo, useState } from 'react';
import { Box, BoxProps, Collapse } from '@mantine/core';
import cn from 'classnames';
import CollapseIcon from 'icons/chevron-forward.svg?inline';
import styles from './accordion.module.scss';

type AccordionProps = {
  children?: JSX.Element;
  titleRow?: JSX.Element | null;
  initiallyCollapsed?: boolean;
  targetProps?: BoxProps;
};

const Accordion = (props: AccordionProps): JSX.Element => {
  const { children, titleRow, initiallyCollapsed, targetProps } = props;
  const [collapsed, setCollapsed] = useState(!!initiallyCollapsed);

  return (
    <>
      <Box
        onClick={() => setCollapsed((prev) => !prev)}
        className={styles.collapseTarget}
        {...targetProps}
      >
        {titleRow}
        <CollapseIcon
          height={'12px'}
          className={cn(styles.iconColor, collapsed ? styles.rotateCollapsed : styles.rotateOpen)}
        />
      </Box>
      <Collapse in={!collapsed}>{children}</Collapse>
    </>
  );
};

export default memo(Accordion);
