import { useCallback, useMemo } from 'react';
import { useSelector, useStore } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import CountryLocaleCell from 'Components/AccuTable/CellRenderer/countryLocale';
import CTRCell from 'Components/AccuTable/CellRenderer/ctr';
import KeywordCell from 'Components/AccuTable/CellRenderer/keyword';
import RankCell from 'Components/AccuTable/CellRenderer/rank';
import RankChangeCell from 'Components/AccuTable/CellRenderer/rankChange';
import SearchIntentCell from 'Components/AccuTable/CellRenderer/searchIntent';
import ShareOfVoiceCell from 'Components/AccuTable/CellRenderer/shareOfVoice';
import ShareOfVoiceChangeCell from 'Components/AccuTable/CellRenderer/shareOfVoiceChange';
import TrafficCell from 'Components/AccuTable/CellRenderer/traffic';
import TrafficChangeCell from 'Components/AccuTable/CellRenderer/trafficChange';
import {
  ColumnType,
  TableFetchDataCallBack,
  withCellContext,
  withRowContext,
} from 'Components/DataTable';
import { DataTableProps } from 'Components/DataTable/DataTable';
import { DomainFavicon } from 'Components/DomainFavicon';
import { MenuItemIds } from 'Components/Modal/Content/KeywordInfo';
import {
  WinnerAndLosersTableDocument,
  WinnerAndLosersTableQuery,
  WinnerAndLosersTableQueryVariables,
} from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import { formatFiltersWithRankChange } from 'Hooks/data/filter/useFilters';
import useKeyword from 'Hooks/useKeyword';
import { keywordOrderConfig } from 'Pages/Keywords/Table/hooks/keyword/constants';
import { formatKeyword } from 'Pages/Keywords/Table/hooks/keyword/useTableKeyword';
import { KeywordColumnID } from 'Pages/Keywords/Table/support/constants';
import { selectCurrentFilters } from 'Selectors/FilterSelector';
import { StoreType } from 'Types/Store';
import { t } from 'Utilities/i18n';
import DevicesIcon from 'icons/devices.svg?inline';
import LocationIcon from 'icons/location.svg?inline';
import SearchTypeEngineCell from '../../../../../../Components/AccuTable/CellRenderer/searchTypeEngine';
import { IconContainer } from '../../../../Table/support/IconContainer/IconContainer';

type UseKeywordTableInfoProps = Pick<
  DataTableProps,
  'columns' | 'labels' | 'defaultOrdering' | 'viewMode'
>;

export const useFetchWinnerLosersTable = (winnersSelected?: boolean): TableFetchDataCallBack => {
  const client = useApolloClient();
  const store = useStore();

  return useCallback(
    (params) => {
      const resultFilters = formatFiltersWithRankChange(
        selectCurrentFilters(store.getState()),
        winnersSelected,
      );
      const variables: WinnerAndLosersTableQueryVariables = {
        filters: resultFilters,
        pagination: params.pagination,
        ordering: params.ordering,
        withId: true,
        withKeyword: true,
        withStarred: false,
        withOrganicTraffic: true,
        withOrganicTrafficCompare: true,
        withSearchIntent: true,
        withIgnoreInShareOfVoice: true,
        withIgnoreLocalResults: true,
        withIgnoreFeaturedSnippet: true,
        withEnableAutocorrect: true,
        withIgnoredDomains: true,
        withSearchTypeId: true,
        withUpdatingKeyword: false,
        withSearchEngineId: true,
        withDomain: true,
        withDomainId: true,
        withDisplayName: true,
        withKeywordDescription: false,
        withLocation: true,
        withCountrylocaleId: true,
        withRankId: true,
        withRankValue: true,
        withRankCompare: true,
        withHasExtraRanks: true,
        withExtraRanks: true,
        withAboveTheFold: true,
        withTitle: false,
        withDescription: false,
        withPreferredLandingPageId: false,
        withLandingPageId: false,
        withHighestRankingPage: false,
        withSearchVolumeValue: false,
        withValidForSearchVolume: true,
        withAvgCostPerClick: false,
        withCompetition: false,
        withAnalyticsPotential: false,
        withAnalyticsVisitors: false,
        withShareOfVoiceIsPercentage: true,
        withShareOfVoiceValue: true,
        withShareOfVoiceCompare: true,
        withShareOfVoicePercentageValue: true,
        withShareOfVoicePercentageValueCompare: true,
        withShareOfVoiceCpcValue: false,
        withShareOfVoiceCpcCompare: false,
        withSerp: false,
        withDateAdded: false,
        withSearchDate: false,
        withHistoryUrl: false,
        withTags: true,
        withDynamicTags: true,
        withDynamicCtr: true,
        allowPartialScrape: false,
      };
      return client
        .query<WinnerAndLosersTableQuery, WinnerAndLosersTableQueryVariables>({
          query: WinnerAndLosersTableDocument,
          variables,
          fetchPolicy: 'cache-first',
        })
        .then((e) => {
          const data = (e?.data?.tableKeywords?.keywords ?? []).map((_e) => formatKeyword(_e));
          const totalLength = e?.data?.tableKeywords?.pagination?.numResults ?? data.length;
          return { data, length: totalLength };
        });
    },
    [client, store, winnersSelected],
  );
};

export const useOverviewKeywordTableInfo = (
  isGroupDomains?: boolean,
  useAi?: boolean,
): UseKeywordTableInfoProps => {
  const { showModal } = useModal();
  const { showKeywordModal } = useKeyword({
    showModal,
  });
  const { metaData } = useSelector((state: StoreType) => ({
    metaData: state.metaData,
  }));

  const columns = useMemo((): ColumnType[] => {
    return [
      isGroupDomains
        ? {
            id: KeywordColumnID.DOMAIN,
            onHeaderCell: () => ({
              tooltip: t('Sort by domain'),
              ordering: keywordOrderConfig.domain,
            }),
            width: 14,
            fixed: 'left',
            cellRenderer: ({ record }: { record: any }) => (
              <DomainFavicon domain={record?.domain} />
            ),
          }
        : null,
      {
        id: KeywordColumnID.SEARCH_TYPE,
        title: (
          <IconContainer size="md">
            <DevicesIcon />
          </IconContainer>
        ),
        onHeaderCell: () => ({
          tooltip: t('Sort by search type'),
          ordering: keywordOrderConfig.searchType,
        }),
        width: 48,
        fixed: 'left',
        cellRenderer: SearchTypeEngineCell,
        cellRendererParams: {
          searchTypes: metaData?.searchTypes,
          searchEngines: metaData?.searchEngines,
          cellClassName: 'vertical-alignment-top',
        },
      },
      {
        id: KeywordColumnID.COUNTRY_LOCALE,
        title: (
          <IconContainer size="md">
            <LocationIcon />
          </IconContainer>
        ),
        onHeaderCell: () => ({
          tooltip: t('Sort by location'),
          ordering: keywordOrderConfig.countryLocale,
        }),
        width: 48,
        fixed: 'left',
        cellRenderer: CountryLocaleCell,
        cellRendererParams: {
          countryLocales: metaData?.countrylocales,
          compact: true,
        },
      },
      {
        id: KeywordColumnID.KEYWORD,
        title: t('Keyword'),
        width: 100,
        fixed: 'left',
        cellRenderer: withCellContext<any>(KeywordCell as any) as any,
        cellRendererParams: {
          disableTags: true,
          onKeywordClick: (id: any, rankId: any, _domainId: any) => {
            showKeywordModal({
              keywordId: id,
              domainId: _domainId,
              rankId,
              initialTab: MenuItemIds.TAB_RANKS_HISTORY,
            });
          },
          showOptions: true,
        },
        onHeaderCell: () => ({
          tooltip: t('Sort by keyword'),
          ordering: keywordOrderConfig.keyword,
        }),
      },

      {
        id: KeywordColumnID.RANK,
        combineRow: KeywordColumnID.RANK_CHANGE,
        title: t('Rank'),
        onHeaderCell: () => ({
          tooltip: t('Sort by rank'),
          ordering: keywordOrderConfig.rank,
          reverseDirection: true,
        }),
        width: 56,
        cellRenderer: withRowContext(RankCell as any),
        cellRendererParams: {
          compact: true,
        },
      },
      {
        id: KeywordColumnID.RANK_CHANGE,
        title: t('+/-'),
        onHeaderCell: () => ({
          reverseDirection: true,
          tooltip: t('Change in rank between the two compared dates'),
          ordering: keywordOrderConfig.rankChange,
        }),
        width: 40,
        cellRenderer: RankChangeCell,
      },
      {
        id: useAi ? KeywordColumnID.ORGANIC_TRAFFIC : KeywordColumnID.SHARE_OF_VOICE,
        title: useAi ? t('AI SoV') : t('SoV'),
        onHeaderCell: () => ({
          reverseDirection: true,
          tooltip: useAi ? t('Sort by AI Share of Voice') : t('Sort by Share of Voice'),
          ordering: useAi ? keywordOrderConfig.organicTraffic : keywordOrderConfig.sov,
        }),
        width: 48,
        cellRenderer: useAi ? TrafficCell : ShareOfVoiceCell,
      },
      {
        id: useAi ? KeywordColumnID.ORGANIC_TRAFFIC_CHANGE : KeywordColumnID.SHARE_OF_VOICE_CHANGE,
        title: t('+/-'),
        onHeaderCell: () => ({
          reverseDirection: true,
          tooltip: useAi ? t('Sort by change in AI SoV') : t('Sort by change in SoV'),
          ordering: useAi ? keywordOrderConfig.organicTrafficChange : keywordOrderConfig.sovChange,
        }),
        width: 54,
        cellRenderer: useAi ? TrafficChangeCell : ShareOfVoiceChangeCell,
      },
      {
        id: KeywordColumnID.DYNAMIC_CTR,
        title: t('CTR'),
        onHeaderCell: () => ({
          reverseDirection: true,
          tooltip: t('Sort by click-through rate'),
          ordering: keywordOrderConfig.dynamicCtr,
        }),
        width: 33,
        cellRenderer: CTRCell,
      },
      {
        id: KeywordColumnID.SEARCH_INTENT,
        title: t('Intent'),
        onHeaderCell: () => ({
          tooltip: t('Sort by search intent'),
          ordering: keywordOrderConfig.searchIntent,
        }),
        width: 54,
        cellRenderer: SearchIntentCell,
      },
    ]
      .filter(Boolean)
      .map((e) => e as ColumnType);
  }, []);

  return useMemo(
    () => ({
      columns,
      labels: {
        paginationLoading: t('Loading keywords…'),
      },
    }),
    [columns],
  );
};
