import moment from 'moment';

moment.relativeTimeThreshold('ss', 30);
moment.relativeTimeThreshold('s', 60);
moment.relativeTimeThreshold('m', 60);
moment.relativeTimeThreshold('h', 24);
moment.relativeTimeThreshold('d', 30);
moment.relativeTimeThreshold('M', 12);
// https://github.com/pmmmwh/react-refresh-webpack-plugin/issues/176
// TODO FixTSignore
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.$RefreshReg$ = () => undefined;
// TODO FixTSignore
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.$RefreshSig$ = () => () => undefined;

// see issue and solution here: https://github.com/facebook/react/issues/11538#issuecomment-417504600
export const tweakNodeOperations = () => {
  if (typeof Node === 'function' && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;

    // TODO FixTSignore
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Node.prototype.removeChild = function (child) {
      if (child.parentNode !== this) {
        if (console) {
          console.error('Cannot remove a child from a different parent', child, this);
        }

        return child;
      }

      // eslint-disable-next-line prefer-rest-params
      return originalRemoveChild.apply(this, arguments as any);
    };

    const originalInsertBefore = Node.prototype.insertBefore;
    // TODO FixTSignore
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Node.prototype.insertBefore = function (newNode, referenceNode) {
      if (referenceNode && referenceNode.parentNode !== this) {
        if (console) {
          console.error(
            'Cannot insert before a reference node from a different parent',
            referenceNode,
            this,
          );
        }

        return newNode;
      }

      // eslint-disable-next-line prefer-rest-params
      return originalInsertBefore.apply(this, arguments as any);
    };
  }
};
