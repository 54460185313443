import * as React from 'react';
import { useFormState } from 'react-final-form';
import cn from 'classnames';
import Alert from '../../Alert';

type FormErrorProps = {
  children?: React.ReactNode;
  className?: string;
};

/**
 * Display form error, subscribed to form state to display it.
 */
export const FormError = (props: FormErrorProps) => {
  const { submitError } = useFormState({
    subscription: {
      submitError: true,
    },
  });

  if (!submitError) {
    return null;
  }

  return (
    <Alert type="danger" className={cn('error-with-action', props.className)}>
      {submitError}
      {props.children}
    </Alert>
  );
};
