import { useState } from 'react';
import noop from 'lodash/noop';
import { observer } from 'mobx-react';
import { CompareChartDataContainer } from 'Components/Chart/CompareChartData/components/CompareChartDataContainer';
import { LineChart } from 'Components/Chart/LineChart';
import { TooltipConfiguration, chartTooltipTypes } from 'Components/Chart/LineChart/support/types';
import { useQueryNotes } from 'Components/Chart/Notes';
import { useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import { Metrics, useMetrics } from 'Hooks/data/metrics/useMetrics';
import { NaverBlacklist } from '../../hooks/useCompetitorsTableInfo';
import { CompetitorColumnID } from '../../support/constants';
import { getCompetitorMetricsLabels, getShowAsPercentage, isRankChart } from './support/helpers';
import { useCompetitorChartData } from './support/useCompetitorChartData';
import styles from './next-competitors-chart-container.module.scss';

export const NextCompetitorChartContainer = observer((): JSX.Element => {
  const { notes, isLoadingNotes } = useQueryNotes();
  const { metricToYAxisLabel } = useMetrics();

  const [metric, setMetric] = useState<string>(CompetitorColumnID.SHARE_OF_VOICE);
  const { domainInfo, isNaverDomain } = useQueryDomainInfo();
  const { series, loading } = useCompetitorChartData({ metric, skip: !domainInfo });

  const showPercentage: boolean = getShowAsPercentage(metric, domainInfo);
  const metricsOptions = getCompetitorMetricsLabels().filter(
    (label) => (isNaverDomain && NaverBlacklist.includes(label.value)) === false,
  );
  const isRank = isRankChart(metric);
  const metricPlaceholder = metricsOptions.find((option) => option.value === metric)?.label ?? '';
  const tooltipConfig: TooltipConfiguration = { type: chartTooltipTypes.MULTI, showPercentage };

  return (
    <div className={styles.container}>
      <CompareChartDataContainer
        metric={metric}
        metricsOptions={metricsOptions}
        metricPlaceholder={metricPlaceholder}
        setMetric={setMetric}
        superCellCollapsed={false}
        toggleCollapse={noop}
      >
        <LineChart
          series={series}
          notes={notes}
          showPercentage={showPercentage}
          isLoading={loading || isLoadingNotes}
          multiLineNavigator
          metric={metric}
          tooltip={tooltipConfig}
          hasControl
          isRank={isRank}
          yAxisLabel={metricToYAxisLabel(metric as Metrics)}
          fileName="Competitor Share of Voice"
        />
      </CompareChartDataContainer>
    </div>
  );
});
