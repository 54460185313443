import { useEffect } from 'react';
import { OptimizationStoreType } from '../../store/OptimizationStore';

// element is visible in case at least one pixel is in root component view port
function isElementInViewport(
  rect: DOMRectReadOnly,
  rootBound: DOMRectReadOnly | null,
  windowHeight: number,
) {
  return (
    (rect.top >= (rootBound?.top ?? 0) && rect.top <= (rootBound?.bottom ?? windowHeight)) ||
    (rect.bottom >= (rootBound?.top ?? 0) && rect.bottom <= (rootBound?.bottom ?? windowHeight))
  );
}

const OBSERVER_CONFIG = {
  root: null,
  rootMargin: '0px',
  threshold: 0,
  trackVisibility: true,
  delay: 100,
};

export const useOptimized = (data?: unknown[], store?: OptimizationStoreType) => {
  useEffect(() => {
    let observer: IntersectionObserver | null = null;
    if (data?.length && store) {
      if ('IntersectionObserver' in window) {
        const columnVisibility: { [key: string]: boolean } = {};

        const callback: IntersectionObserverCallback = (entries) => {
          const windowHeight = window.innerHeight;
          entries.forEach((entry) => {
            const elementKey = entry.target?.getAttribute('data-index');
            if (elementKey) {
              columnVisibility[elementKey] = isElementInViewport(
                entry.boundingClientRect,
                entry.rootBounds,
                windowHeight,
              );
            }
          });
        };
        observer = new IntersectionObserver(callback, OBSERVER_CONFIG);
        document.querySelectorAll('.rc-table-tbody tr[data-index]')?.forEach((item) => {
          observer?.observe(item);
        });
      } else {
        // We are setting all rows to be visible in case browser doesn't support IntersectionObserver
        store.setRange(0, Infinity);
      }
    }
    return () => observer?.disconnect();
  }, [data?.map((e) => (e as any)?.id).join('_'), store]);
};
