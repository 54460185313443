import * as React from 'react';
import { Field, reduxForm } from 'redux-form';
import AccButton from 'Components/AccButton/AccButton';
import { TagsField } from 'Components/Forms/Fields';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import { t } from 'Utilities/i18n';
import Validation from 'Utilities/validation';

type Props = {
  handleSubmit: (...args: Array<any>) => any;
  onCancel: (...args: Array<any>) => any;
  loading: boolean;
};

class AddTagsForm extends React.Component<Props> {
  render() {
    return (
      <form>
        <div className="form-label required">{t('Tags')}</div>
        <Field
          placeholder={t('Select existing tags or enter new')}
          component={TagsField}
          validate={Validation.array}
          name="tags"
          excludeDynamic
          autoFocus={true}
        />
        <ModalFooter
          primaryButtonSlot={
            <AccButton
              variant="primary"
              onClick={this.props.handleSubmit}
              disabled={this.props.loading}
            >
              {t('Add Tags')}
            </AccButton>
          }
          secondaryButtonSlot={
            <AccButton variant="tertiary" onClick={this.props.onCancel}>
              {t('Cancel')}
            </AccButton>
          }
        />
      </form>
    );
  }
}

export default reduxForm({
  form: 'AddTagsForm',
})(AddTagsForm);
