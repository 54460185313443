import type { SearchType } from 'Types/Filter';

export type SearchEngine = {
  name: string;
  searchTypes: SearchType[];
};

export const PLATFORM_DESKTOP = 1;
export const PLATFORM_MOBILE = 2;

export const SEARCH_TYPE_GOOGLE = 'google';
export const SEARCH_TYPE_BING = 'bing';
export const SEARCH_TYPE_BAIDU = 'baidu';
export const SEARCH_TYPE_YANDEX = 'yandex';
export const SEARCH_TYPE_YOUTUBE = 'youtube';
export const SEARCH_TYPE_NAVER = 'naver';

export const SearchEngineTypes = {
  [SEARCH_TYPE_GOOGLE]: '1',
  [SEARCH_TYPE_BING]: '2',
  [SEARCH_TYPE_BAIDU]: '3',
  [SEARCH_TYPE_YANDEX]: '4',
  [SEARCH_TYPE_YOUTUBE]: '5',
  [SEARCH_TYPE_NAVER]: '6',
};

export const SearchEngineIds = {
  [SEARCH_TYPE_GOOGLE]: 1,
  [SEARCH_TYPE_BING]: 2,
  [SEARCH_TYPE_BAIDU]: 3,
  [SEARCH_TYPE_YANDEX]: 4,
  [SEARCH_TYPE_YOUTUBE]: 5,
  [SEARCH_TYPE_NAVER]: 6,
};

export const TRANSACTIONAL = 'T';
export const NAVIGATIONAL = 'N';
export const INFORMATIONAL = 'I';
export const COMMERCIAL = 'C';
