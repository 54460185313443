import { AnyAction } from 'redux';
import { DomainsAction, UPDATE_DOMAIN } from 'Actions/DomainsAction';

type DomainStoreType = {
  displayCurrency: string;
};

type DomainsReducertype = Record<string, DomainStoreType>;
const initialState: DomainsReducertype = {};

export default function (
  state = initialState,
  action: AnyAction /*DomainsAction*/,
): DomainsReducertype {
  const a = action as DomainsAction;
  switch (a.type) {
    case UPDATE_DOMAIN:
      return {
        ...state,
        [a.payload.domainId]: {
          ...state[a.payload.domainId],
          ...a.payload.body,
        },
      };
    default:
      return state;
  }
}
