import FoldersMaxCtrCell from 'Pages/Keywords/Groupings/ViewMode/components/cells/FoldersMaxCtrCell';
import { getDomainId, getFolderTagPath } from 'Utilities/generateSelectId';

type Props = {
  maxCtr: number | undefined;
  path: string;
  groupOrDomainLevel: boolean;
};
const MaxCtrCell = (props: Props) => {
  const { maxCtr, path, groupOrDomainLevel } = props;
  const newPath = groupOrDomainLevel ? path : getFolderTagPath(path) || '';
  const domainId = getDomainId(path);
  return (
    <FoldersMaxCtrCell
      maxCtr={maxCtr}
      path={newPath}
      domainId={domainId}
      groupOrDomainLevel={groupOrDomainLevel}
    />
  );
};

export default MaxCtrCell;
