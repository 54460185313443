import { PointerEvent, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import styles from './stickyVerticalScroll.module.scss';

const StickyScrollBar = ({
  scrollBodyRef,
  width = 0,
}: {
  scrollBodyRef: RefObject<HTMLDivElement>;
  width: number | undefined;
}) => {
  const [scrollBarState, setScrollBarState] = useState({ visible: false, width: 0, scrollX: 0 });
  const initialPointerX = useRef<number | undefined>(undefined);
  const scrollbarRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);

  const handleScroll = useCallback(() => {
    if (scrollBodyRef.current && scrollbarRef.current) {
      const { scrollLeft, clientWidth } = scrollBodyRef.current;
      const scrollbarWidthRatio = clientWidth / width;
      const newScrollbarWidth = clientWidth * scrollbarWidthRatio;
      const newScrollPosition = (scrollLeft / width) * clientWidth;

      setScrollBarState((prevState) => ({
        ...prevState,
        width: newScrollbarWidth,
        scrollX: newScrollPosition,
      }));
    }
  }, [scrollBodyRef, width]);

  useEffect(() => {
    const handlePointerMove = (e: globalThis.MouseEvent) => {
      if (!e.buttons || !isDragging || !scrollBodyRef.current || !scrollbarRef.current) return;

      const { clientWidth, scrollWidth } = scrollBodyRef.current;
      const scrollbarTrackWidth = clientWidth - scrollBarState.width;

      // Calculate movement offset from the initial pointerdown position
      const offsetX = e.clientX - (initialPointerX.current || 0);
      const newScrollLeft = (offsetX / scrollbarTrackWidth) * (scrollWidth - clientWidth);
      scrollBodyRef.current.scrollLeft = newScrollLeft;

      const newScrollPosition =
        (scrollBodyRef.current.scrollLeft / width) * scrollBodyRef.current.clientWidth;
      setScrollBarState((prevState) => ({
        ...prevState,
        scrollX: newScrollPosition,
      }));
    };

    const handlePointerUp = () => {
      setIsDragging(false);
      initialPointerX.current = undefined;
    };

    const resizeObserver = new ResizeObserver(() => {
      if (scrollBodyRef.current) {
        const { clientWidth, scrollWidth } = scrollBodyRef.current;
        setScrollBarState((prevState) => ({
          ...prevState,
          visible: scrollWidth > clientWidth,
        }));
        handleScroll();
      }
    });

    const container = scrollBodyRef.current;
    if (container) {
      handleScroll();
      container.addEventListener('scroll', handleScroll, { passive: true });
      document.addEventListener('pointermove', handlePointerMove);
      document.addEventListener('pointerup', handlePointerUp);
      resizeObserver.observe(container);
    }

    return () => {
      container?.removeEventListener('scroll', handleScroll);
      document.removeEventListener('pointermove', handlePointerMove);
      document.removeEventListener('pointerup', handlePointerUp);
      container && resizeObserver.unobserve(container);
    };
  }, [
    isDragging,
    scrollBodyRef,
    width,
    scrollBarState.width,
    scrollBarState.visible,
    handleScroll,
  ]);

  if (!scrollBodyRef.current || !scrollBarState.visible) {
    return null;
  }

  const handlePointerDown = (e: PointerEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (scrollBodyRef.current && scrollbarRef.current) {
      setIsDragging(true);
      initialPointerX.current = e.clientX - scrollBarState.scrollX;
    }
  };

  return (
    <div className={styles.scrollTrack}>
      <div
        className={styles.scrollbar}
        ref={scrollbarRef}
        onPointerDown={handlePointerDown}
        data-active={isDragging || undefined}
        style={{
          width: `${scrollBarState.width}px`,
          left: `${scrollBarState.scrollX}px`,
        }}
      />
    </div>
  );
};

export default StickyScrollBar;
