import { Component } from 'react';
import { connect } from 'react-redux';
import { Flex } from '@mantine/core';
import { hideModal, showModal } from 'Actions/ModalAction';
import AccButton from 'Components/AccButton/AccButton';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { t } from 'Utilities/i18n/index';

type Props = {
  domainId?: string;
  refresh?: (...args: Array<any>) => any;
  groupId?: string;
  showModal: (...args: Array<any>) => any;
  hideModal: (...args: Array<any>) => any;
  isAddingConnection: boolean;
};
const GOOGLE_ANALYTICS = 'Google Analytics';
const ADOBE_ANALYTICS = 'Adobe Analytics';
const analyticsOptions = [GOOGLE_ANALYTICS, ADOBE_ANALYTICS];
const modals = {
  [GOOGLE_ANALYTICS]: 'ConnectToGA',
  [ADOBE_ANALYTICS]: 'ConnectToAdobe',
};

class ConnectToAnalytics extends Component<Props> {
  handleSelect = (option) => {
    const { isAddingConnection } = this.props;
    this.props.showModal({
      modalType: modals[option],
      modalTheme: 'light',
      modalProps: {
        domainId: this.props.domainId,
        refresh: this.props.refresh,
        isAdding: isAddingConnection,
        groupId: this.props.groupId,
      },
    });
  };

  renderAnalyticsTable() {
    return (
      <div>
        <Flex align="center" justify="center" columnGap={16}>
          {analyticsOptions.map((option) => (
            <>
              <AccButton variant="secondary" onClick={() => this.handleSelect(option)}>
                {option}
              </AccButton>
            </>
          ))}
        </Flex>
      </div>
    );
  }

  render() {
    return (
      <ModalBorder title={t('Select Analytics Provider')} onClose={this.props.hideModal}>
        {this.renderAnalyticsTable()}
      </ModalBorder>
    );
  }
}

export default connect(null, {
  showModal,
  hideModal,
})(ConnectToAnalytics);
