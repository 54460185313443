import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Flex } from '@mantine/core';
import Tabs from 'Components/AccTabs/Tabs';
import { linkToKeywordsDomain } from 'Components/Filters/LinkToDomain';
import { MissingDataOverlay } from 'Pages/Keywords/Overview/MissingDataOverlay';
import { useFakeData } from 'Pages/Keywords/Overview/hooks/useFakeData';
import { folderSeparator } from 'Utilities/generateSelectId';
import { t } from 'Utilities/i18n';
import useFetchChartsData from '../../../support/useFetchChartsData';
import Chart from './Chart';
import ChartTab from './ChartTab';
import { useDomainContext } from './DomainContext';
import styles from '../domainCard.module.scss';

const tab = {
  avgRank: 'avgRank',
  sov: 'sov',
  // traffic: 'aiTraffic',
  avgCtr: 'avgCtr',
};

const DomainCharts = () => {
  const defaultTab = tab.avgRank;
  const [tabSelected, selectTab] = useState<string | null>(tab.avgRank);
  const onChangeTab = (tabVal: string | null) => {
    selectTab(tabVal);
  };
  const {
    aiShareOfVoice,
    aiShareOfVoiceCompare,
    avgCtr,
    avgCtrCompare,
    avgRank,
    avgRankCompare,
    shareOfVoice,
    shareOfVoiceCompare,
    countKeywords,
    selectId,
  } = useDomainContext();
  const {
    sovSeries,
    avgCtrSeries,
    avgRankSeries,
    avgEstVisitsSeries,
    // trafficValueSeries,
    // aiTrafficValueSeries,
    loading: chartLoading,
    // error: chartError,
  } = useFetchChartsData();

  const { AvgRankChartFakeData: fakeData } = useFakeData();
  const navigate = useNavigate();

  const tabs = {
    [tab.avgRank]: {
      title: t('Average Rank'),
      value: avgRank,
      beforeValue: avgRankCompare,
      series: avgRankSeries,
      color: '#cad6f7',
      isRank: true,
    },
    [tab.sov]: {
      title: aiShareOfVoice ? t('AI Share of Voice') : t('Share of Voice'),
      value: aiShareOfVoice ? aiShareOfVoice : shareOfVoice,
      beforeValue: aiShareOfVoice ? aiShareOfVoiceCompare : shareOfVoiceCompare,
      series: aiShareOfVoice ? avgEstVisitsSeries : sovSeries,
      color: '#d19dd1',
      isRank: false,
    },
    // [tab.traffic]: {
    //   title: aiTrafficValue ? t('AI Traffic Value') : t('Traffic Value'),
    //   value: aiTrafficValue ? aiTrafficValue : trafficValue,
    //   beforeValue: aiTrafficValue ? aiTrafficValueBefore : trafficValueBefore,
    //   series: aiTrafficValue ? aiTrafficValueSeries : trafficValueSeries,
    //   color: '#ccb9744d',
    //   isRank: false,
    // },
    [tab.avgCtr]: {
      title: t('Average CTR'),
      value: avgCtr,
      beforeValue: avgCtrCompare,
      series: avgCtrSeries,
      color: '#a9dbae',
      isRank: false,
    },
  };

  const currentTab = tabs[tabSelected || defaultTab];

  const showFakeChartData = currentTab.series[0].data.length === 0 && !chartLoading;

  return (
    <Flex direction="column" pb="xxs" className={styles.domainCharts} mih={236}>
      <Tabs
        value={tabSelected}
        onChange={onChangeTab}
        className={styles.chartTabs}
        classNames={{
          tab: styles.tab,
          list: styles.tabsList,
          tabLabel: styles.tabLabel,
        }}
      >
        <Tabs.List>
          {Object.entries(tabs).map(([key, { title, value, beforeValue }]) => (
            <ChartTab
              key={key}
              panel={key}
              title={title}
              value={value || 0}
              beforeValue={beforeValue || 0}
            />
          ))}
        </Tabs.List>
      </Tabs>
      <Box px="lg" pb="xxs" pos="relative">
        {showFakeChartData && (
          <MissingDataOverlay
            type={countKeywords === 0 ? 'missingKeywords' : 'searchTrend'}
            isEmptyGroup={false}
            size="sm"
            addActionVariant="secondary"
            onClick={() => {
              const domainId = selectId.split(folderSeparator)[1];
              const domainKeywordsLink = linkToKeywordsDomain(domainId);
              navigate(domainKeywordsLink, { state: { addKeywordsModal: true } });
            }}
          />
        )}
        <Chart
          key={tabSelected}
          series={showFakeChartData ? fakeData : currentTab.series}
          title={currentTab.title}
          color={currentTab.color}
          isRank={currentTab.isRank}
          loading={chartLoading}
          sparklineMode
        />
      </Box>
    </Flex>
  );
};
export default DomainCharts;
