export const labelsFormatter = (data, noNegative?: boolean) => {
  if (!noNegative && data.value < 0) {
    return '';
  }
  // Round to one decimal so it looks like 1B, 1.5B, 2B, etc.
  // the multiplication by 10 and then division by 10 after make sure the number is rounded with one decimal.
  if (data.value >= 1000000000) {
    return `${Math.round((10 * data.value) / 1000000000) / 10}B`;
  }
  if (data.value >= 1000000) {
    return `${Math.round((10 * data.value) / 1000000) / 10}M`;
  }

  if (data.value >= 1000) {
    return `${Math.round((10 * data.value) / 1000) / 10}K`;
  }
  return `${Math.round(10 * data.value) / 10}`;
};
