import moment from 'moment';
import DateEditor from 'Components/Filters/Common/DateEditor';
import { FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import type { FilterBase } from 'Types/Filter';
import { t } from 'Utilities/i18n/index';
import CalendarIcon from 'icons/calendar.svg?inline';

const defaultValue = (attribute: 'date' | 'date_added') => ({
  attribute,
  type: FilterValueType.DATETIME,
  comparison: FilterComparison.EQ,
  value: moment().format('YYYY-MM-DD'),
});

const getComparisonLabels = () => ({
  [FilterComparison.EQ]: t('On'),
  [FilterComparison.BETWEEN]: t('Between'),
  [FilterComparison.GT]: t('After'),
  [FilterComparison.LT]: t('Before'),
});

const getDataFactory =
  ({
    title = () => t('Date'),
    attribute = FilterAttribute.DATE,
    withCount = false,
  }: { title?: () => string; attribute?: 'date' | 'date_added'; withCount?: boolean } = {}) =>
  () => ({
    defaultValue: defaultValue(attribute),
    title: title(),
    icon: CalendarIcon,
    editor: DateEditor,
    editorProps: {
      placeholder: t('Enter the country'),
      withCount,
    },
    labelFunc: (filter: FilterBase) =>
      `${getComparisonLabels()[filter.comparison]} ${filter.value}`,
  });

export default getDataFactory;
