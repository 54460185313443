import { FormSpy, useForm } from 'react-final-form';
import { Flex, Group, List } from '@mantine/core';
import { FormApi } from 'final-form';
import AccButton from 'Components/AccButton/AccButton';
import { DefaultChecked } from 'Components/Controls/Checkbox';
import { DomainFavicon } from 'Components/DomainFavicon';
import { Field, Form } from 'Components/Fields';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import AccText from 'Components/Text/AccText';
import AccTitle from 'Components/Title/AccTitle';
import { useTopCompetitorsForKeywordsQuery } from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import { useDomainId } from 'Hooks/data/domain/useQueryDomainInfo';
import { UpdateKeywordsSettingsValues } from 'Hooks/useKeyword';
import { t, tct } from 'Utilities/i18n';
import Validator from 'Utilities/validation';
import styles from '../edit-keywords.module.scss';

type Props = {
  initialValues: {
    ignoreInShareOfVoice: DefaultChecked;
    ignoreLocalResults: DefaultChecked;
    ignoreFeaturedSnippet: DefaultChecked;
    enableAutocorrect: DefaultChecked;
    ignoredDomain: string;
  };
  handleSubmit: (
    values: UpdateKeywordsSettingsValues,
    form: FormApi<UpdateKeywordsSettingsValues>,
  ) => void;
  showGoogleOptions?: boolean;
  youtubeKeywordsSelected?: boolean;
  mixedIgnoredDomains?: string[];
  loading?: boolean;
  keywordIds?: string[];
};

function IgnoredDomainsInput({
  mixedIgnoredDomains,
  keywordIds,
  handleSubmit,
}: Pick<Props, 'mixedIgnoredDomains' | 'keywordIds' | 'handleSubmit'>) {
  const form = useForm<UpdateKeywordsSettingsValues>();

  if (!keywordIds) return null;

  const domainId = useDomainId();
  const { data, loading } = useTopCompetitorsForKeywordsQuery({
    variables: {
      keywordIds: keywordIds.map((x) => x).map((x) => parseInt(x)),
      domainId: domainId ? parseInt(domainId) : undefined,
    },
  });

  const keywordsCount = keywordIds.length;

  const topDomains = data?.topCompetitorsForKeywords?.map((e) => {
    return { value: e.domain as string };
  });

  return (
    <div>
      <Field.Select
        name="ignoredDomain"
        label={t('Ignored Domain')}
        required={false}
        validate={Validator.isUrlDomainOrEmpty}
        options={topDomains}
        isLoading={loading}
        clearable
        itemComponent={(option) => (
          <Group gap={'xs'} wrap={'nowrap'}>
            <DomainFavicon domain={option.value as string} />
            <AccText size="sm" fw={'normal'} overflowEllipsis nowrap>
              {option.value}
            </AccText>
          </Group>
        )}
        placeholder={t('Select or enter a domain')}
        creatable
        helpTextPopover={t(
          'Exclude a specific domain from rank calculations for selected keywords. Useful for disregarding brands in branded searches (e.g., omitting "gucci.com" for "gucci belt") or ignoring social media results. This setting affects the rank but not the base rank, CTR, etc.',
        )}
      />
      {!!mixedIgnoredDomains?.length && (
        <Flex wrap="wrap" pl="md" mt="xxs" direction="column">
          <AccText fs="italic" fw={600} size="sm">
            {tct('The [count] selected keywords have mixed ignored domains:', {
              count: keywordsCount,
            })}
          </AccText>
          <List size="sm" pl="md">
            {mixedIgnoredDomains.map((domain) => (
              <List.Item key={domain}>
                <AccText size="sm" fs="italic" inactive>
                  {domain}
                </AccText>
              </List.Item>
            ))}
          </List>
          <AccButton
            size="xs"
            variant="tertiary"
            onClick={() => {
              form.change('ignoredDomain', '');
              const formValues = form.getState().values as UpdateKeywordsSettingsValues;
              handleSubmit(formValues, form);
            }}
          >
            {tct('Remove ignored domains for the [count] selected keywords', {
              count: keywordsCount,
            })}
          </AccButton>
        </Flex>
      )}
    </div>
  );
}

const EditKeywordsForm = (props: Props) => {
  const {
    showGoogleOptions,
    handleSubmit,
    loading,
    initialValues,
    mixedIgnoredDomains,
    youtubeKeywordsSelected,
    keywordIds,
  } = props;

  const { hideModal } = useModal();

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      formClassName={styles.formContainer}
      subscription={{
        //pass values to subscription - we need the values on the button
        values: true,
        submitting: true,
        submitSucceeded: true,
        submitError: true,
        hasSubmitErrors: true,
      }}
    >
      {showGoogleOptions && (
        <>
          <AccTitle type="h4">{t('Google Settings')}</AccTitle>
          <Flex direction="column" rowGap="sm">
            <Field.Checkbox
              name="ignoreLocalResults"
              defaultChecked={initialValues.ignoreLocalResults}
              label={t('Ignore local pack')}
              helpTextPopover={t(
                'If checked, any local pack found on the SERP will not be included in the rank position.',
              )}
            />
            <Field.Checkbox
              name="ignoreFeaturedSnippet"
              defaultChecked={initialValues.ignoreFeaturedSnippet}
              label={t('Ignore featured snippets')}
              helpTextPopover={t(
                'Ignore the featured snippet that is often shown on how/what searches.',
              )}
            />
            <Field.Checkbox
              name="enableAutocorrect"
              defaultChecked={initialValues.enableAutocorrect}
              label={t('Enable autocorrect')}
              helpTextPopover={t('Enable Google Autocorrect for this keyword')}
            />
          </Flex>
        </>
      )}
      <AccTitle type="h4">{t('Advanced Settings')}</AccTitle>
      <Flex direction="column" rowGap="sm">
        <Field.Checkbox
          name="ignoreInShareOfVoice"
          defaultChecked={initialValues.ignoreInShareOfVoice}
          label={t('Ignore in Share of Voice')}
          helpTextPopover={t('Do not include this keyword in the Share of Voice calculation.')}
        />
        {!youtubeKeywordsSelected && (
          <IgnoredDomainsInput
            mixedIgnoredDomains={mixedIgnoredDomains}
            keywordIds={keywordIds}
            handleSubmit={handleSubmit}
          />
        )}
        <Form.Errors />
      </Flex>
      <FormSpy subscription={{ pristine: true }}>
        {({ pristine }) => (
          <ModalFooter
            primaryButtonSlot={
              <AccButton variant="primary" type="submit" disabled={loading || pristine}>
                {t('Change settings')}
              </AccButton>
            }
            secondaryButtonSlot={
              <AccButton variant="tertiary" onClick={hideModal}>
                {t('Cancel')}
              </AccButton>
            }
          />
        )}
      </FormSpy>
    </Form>
  );
};

export default EditKeywordsForm;
