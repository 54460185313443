import Skeleton, { LineConfig } from 'Components/Skeleton';

const lines: LineConfig[] = [
  {
    type: 'text',
    options: {
      width: '60%',
    },
  },
  {
    type: 'input',
    options: {
      height: '200px',
    },
  },
  {
    type: 'button',
    options: {
      width: '15%',
      alignment: 'right',
    },
  },
];

const CopyToClipboardSkeleton = () => <Skeleton linesConfig={lines} />;

export default CopyToClipboardSkeleton;
