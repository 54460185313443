import { MouseEvent } from 'react';
import { useDeleteDomainsMutation } from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import toast from 'Hooks/useToast';
import { useTreeViewStore } from 'Pages/Domains/TreeView/support/treeViewStore';
import { TreeViewRootNode } from 'Pages/Domains/TreeView/useTreeViewTableConfig';
import { EventName, useMixpanel } from 'Utilities/Analytics/mixpanel';
import { getDomainId, getGroupId } from 'Utilities/generateSelectId';
import { domainDeleteConfirmation } from 'Utilities/getConfirmationDescription';
import { t } from 'Utilities/i18n';
import { devError } from 'Utilities/log';

export const useDomainActions = (rootNode: TreeViewRootNode) => {
  const clientId = getGroupId(rootNode.path);
  const domainId = getDomainId(rootNode.path);
  const treeViewStore = useTreeViewStore();

  const { showModal } = useModal();
  const trackEvent = useMixpanel();

  const [deleteDomain] = useDeleteDomainsMutation({
    onCompleted(data, clientOptions) {
      toast.success(t('Domain is being deleted. This might take a few seconds.'));
      trackEvent(EventName.DeleteDomainSuccess, {
        'Deleted Domain ID': clientOptions?.variables?.input?.id ?? '',
        Source: 'Domain card',
      });
      treeViewStore.refetch(false);
    },
    onError(error, clientOptions) {
      toast.error(t('Could not delete domain'));
      trackEvent(EventName.DeleteDomainFailed, {
        'Deleted Domain ID': clientOptions?.variables?.input?.id ?? '',
        Source: 'Domain card',
        Error: `${error.name}: ${error.message}`,
      });
    },
  });

  const handleEditDomain = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    showModal({
      modalType: 'BuildDomain',
      modalTheme: 'light',
      modalProps: {
        domainId,
        groupId: clientId,
        onClose: () => treeViewStore.refetch(false),
      },
    });
  };
  const deleteDomainAction = async () => {
    if (!domainId) {
      devError('Failed to delete: Missing domain ID');
      return;
    }

    deleteDomain({
      variables: {
        input: {
          id: domainId,
        },
      },
    });
  };

  const handleDeleteDomain = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const domain = 'display_name' in rootNode ? rootNode.display_name : null;
    if (!domain) return;
    showModal({
      modalType: 'Confirmation',
      modalProps: {
        title: t('Delete Domain?'),
        description: domainDeleteConfirmation(domain),
        confirmLabel: t('Delete domain'),
        action: deleteDomainAction,
      },
    });
  };

  return {
    handleEditDomain,
    handleDeleteDomain,
  };
};
