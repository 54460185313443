import { useState } from 'react';
import { SelectItem } from 'Components/AccSelect';
import { useKeywordsInputQuery } from 'Ghql';
import { DEFAULT_KEYWORDS_ORDERING } from './constants';
import { extractKeywordsOptionsFromNotes, getNotesStaticFilters } from './helpers';

/**
 * Load keywords options based on keywords list, or if all options exist, return them.
 */
export const useKeywordsValueOptions = (
  domainId: string | null,
  value?: string[] | null | string,
  valueOptions?: SelectItem<string>[],
): {
  valueOptions: SelectItem<string>[];
  loading?: boolean;
} => {
  const hasPredefinedValueOptions = (value as string[])?.every?.((id) =>
    valueOptions?.some((e) => e?.value.toString() === id.toString()),
  );
  const skip = hasPredefinedValueOptions || !value?.length || !domainId;

  const [isFirstRun, setIsFirstRun] = useState(skip);

  const { data: dataValues, loading } = useKeywordsInputQuery({
    skip: skip || !isFirstRun,
    variables: {
      ordering: DEFAULT_KEYWORDS_ORDERING,
      filters: [
        ...getNotesStaticFilters(domainId),
        {
          attribute: 'keywords',
          type: 'list',
          comparison: 'contains',
          value,
        },
      ],
    },
    onCompleted: () => {
      setIsFirstRun(false);
    },
  });
  const resultValueOptions = hasPredefinedValueOptions
    ? valueOptions
    : extractKeywordsOptionsFromNotes(dataValues);
  return { valueOptions: resultValueOptions ?? [], loading };
};
