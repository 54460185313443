import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import { IconCirclePlus } from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import { t } from 'Utilities/i18n';

type Props = {
  label?: string;
  icon?: any;
  className?: string;
  alignRight: boolean;
  featureAdvancedMetrics: boolean;
  disabled?: boolean;
};

const UpgradeAction = (props: Props) => {
  const navigate = useNavigate();

  if (props.featureAdvancedMetrics) {
    return null;
  }

  return (
    <AccButton
      className={props.className || ''}
      ml={props.alignRight ? 'auto !important' : ''}
      {...props}
      onClick={() => navigate('/billing/package/select', { replace: true })}
      variant="primary"
      leftSection={props.icon || <IconCirclePlus size={18} />}
    >
      {props.label || t('Upgrade plan')}
    </AccButton>
  );
};

const mapStateToProps = (state) => ({
  featureAdvancedMetrics: state.user.organization.activePlan.featureAdvancedMetrics,
});

export default connect(mapStateToProps)(UpgradeAction);
