import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { List } from 'react-virtualized';
import { Box, Checkbox, CloseButton, Flex, Textarea } from '@mantine/core';
import { IconChecks, IconCircleX } from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import AccText from 'Components/Text/AccText';
import { t } from 'Utilities/i18n';
import styles from './acc-list-select.module.scss';

/**
 * This function modifies the options based on the search.
 * We first look if the user entered multiple lines. If yes, then we look for exact matches in
 * the entered array.Else, we look to see if the user entered a comma-seperated list
 * if none of the above, we do a simple contains
 * Else, we look for simple contains
 * */
function useSearchForValueOrListOfValues(
  search: string,
  setCurrentOptions: Dispatch<SetStateAction<string[]>>,
  sortByValueIncluded: (_options: string[]) => string[],
  options: string[],
) {
  useEffect(() => {
    let searchArray;

    if (search.split('\n').length > 1) {
      searchArray = search.split('\n');
    } else if (search.split('/\\r?\\n/').length > 1) {
      searchArray = search.split('\n');
    } else if (search.split(',').length > 2) {
      searchArray = search.split(',');
    }

    let filteredOptions;
    if (searchArray) {
      filteredOptions = options.filter((option) =>
        searchArray.some((searchPhrase) => searchPhrase.toLowerCase() === option.toLowerCase()),
      );
    } else {
      filteredOptions = options.filter((option) =>
        option.toLowerCase().includes(search.toLowerCase()),
      );
    }

    setCurrentOptions(sortByValueIncluded(filteredOptions));
  }, [search]);
}

type AccListSelectProps = {
  options: string[];
  value: string[];
  setValue: Dispatch<SetStateAction<string[]>>;
  listHeight: number;
  rowHeight: number;
  rowWidth: number;
};

export const AccListSelect = ({
  options,
  value,
  setValue,
  listHeight,
  rowHeight,
  rowWidth,
}: AccListSelectProps) => {
  const [search, setSearch] = useState('');

  const [currentOptions, setCurrentOptions] = useState<string[]>(options);

  // Use Sets for faster lookups where needed.
  // But keep general data structure as array as that's easier used downstream.
  const valueAsSet = new Set(value);
  const currentOptionsAsSet = new Set(currentOptions);

  const sortByValueIncluded = (_options) => {
    return [..._options].sort((a, b) => Number(valueAsSet.has(b)) - Number(valueAsSet.has(a)));
  };

  useSearchForValueOrListOfValues(search, setCurrentOptions, sortByValueIncluded, options);

  // Version is needed as there were otherwise problems with the virtualized list..
  const [version, setVersion] = useState(0);

  const checkboxStyle: any = {
    label: { marginBottom: 0, cursor: 'pointer' },
    labelWrapper: { flexDirection: 'row', alignItems: 'center' },
    root: { cursor: 'pointer' },
    input: { cursor: 'pointer' },
  };

  function renderRow({ index, key, style }) {
    // Function with these arguments needed for react virtualized list
    return (
      <Checkbox
        key={key}
        checked={valueAsSet.has(currentOptions[index])}
        color={'snorlax'}
        styles={checkboxStyle}
        label={currentOptions[index]}
        style={style}
        onChange={() => {
          setValue((prev: string[]) => {
            const next = new Set(prev);

            if (valueAsSet.has(currentOptions[index])) {
              next.delete(currentOptions[index]);
            } else {
              next.add(currentOptions[index]);
            }

            return Array.from(next);
          });
        }}
      />
    );
  }

  const [selectAllChecked, setSelectAllChecked] = useState(false);

  // We check/uncheck based on the selectAllChecked state, but also need to remove all checked checkmark
  // if user unchecks some boxes or modifies search
  const selectAllShouldBeChecked =
    selectAllChecked &&
    value.length >= currentOptions.length &&
    currentOptions.every((x) => valueAsSet.has(x));

  return (
    <div style={{ width: `${rowWidth}px` }} key={version}>
      <Textarea
        value={search}
        onKeyDown={(e) => e.stopPropagation()} // Stop enter from propagating to filter form
        placeholder={t(
          'Search... (Tip: you can enter \',\' seperated values or multiple lines, e.g. copy/paste from Excel or Sheets)',
        )}
        onChange={(event) => setSearch(event.currentTarget.value)}
        rightSection={<CloseButton onClick={() => setSearch('')} />}
        maxRows={2}
      />
      <Flex align={'center'} justify={'space-between'}>
        <AccText fw={'normal'}>
          {value.length} {t('selected')}
        </AccText>
        <div>
          <AccButton
            variant={'tertiary'}
            leftSection={<IconChecks size={18} />}
            onClick={() => {
              if (value.length > 0) {
                setCurrentOptions(sortByValueIncluded(options));
              } else {
                setCurrentOptions([...options].sort());
              }
              setSearch('');
              setVersion(version + 1);
            }}
          >
            {t('Show selection')}
          </AccButton>
          <AccButton
            variant={'tertiary'}
            leftSection={<IconCircleX size={18} />}
            onClick={() => {
              setValue([]);
              setVersion(version + 1);
            }}
          >
            {t('Clear selection')}
          </AccButton>
        </div>
      </Flex>
      {search && currentOptions.length > 0 && (
        <Checkbox
          label={`Select all (${currentOptions.length})`}
          mb={'sm'}
          ml={'5px'} // To align with checkboxes below
          styles={checkboxStyle}
          color={'snorlax'}
          checked={selectAllShouldBeChecked}
          onChange={() => {
            if (selectAllShouldBeChecked) {
              setValue(value.filter((x) => !currentOptionsAsSet.has(x)));
            } else {
              // Add and deduplicate
              setValue(Array.from(new Set([...value, ...currentOptions])));
            }
            setSelectAllChecked(!selectAllShouldBeChecked);
            setVersion(version + 1);
          }}
        />
      )}
      <Box className={styles.checkboxListContainer}>
        <List
          width={rowWidth}
          height={listHeight}
          rowHeight={rowHeight}
          rowRenderer={renderRow}
          rowCount={currentOptions.length}
          overscanRowCount={20}
          style={{
            padding: '4px',
          }}
        />
      </Box>
    </div>
  );
};
