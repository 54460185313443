import { DropTargetMonitor } from 'react-dnd';
import last from 'lodash/last';
import { ROW_SIZE } from 'Components/Table/VirtualizedTable';
import {
  DropDirection,
  NodeModel,
  TreeState,
} from 'Pages/Keywords/Groupings/EditMode/react-dnd-treeview/types';
import { ROOT_ID } from '../../support/constants';

type DropTarget = {
  id: NodeModel['id'];
  index: number;
  direction?: DropDirection;
} | null;

const getHoverPosition = <T>(
  el: Element | null,
  pointerY: number,
  context: TreeState<T>,
): string => {
  if (!el) {
    return 'upper';
  }
  const bbox = el.getBoundingClientRect();
  const offsetY = context.dropTargetOffset;
  const upSideY = bbox.top + offsetY;
  const lowerSideY = bbox.bottom - offsetY;

  const middleY = lowerSideY + (upSideY - lowerSideY) / 2;

  if (pointerY > middleY) {
    return 'lower';
  }
  return 'upper';
};

const getParent = (node) => {
  return last((node as any)?.parents) || node?.parent;
};

const OFFSET_FROM_EDGE_PERCENTAGE = 0.3;

const getDirection = (targetY, nodeEl): DropDirection | undefined => {
  if (targetY === undefined || !nodeEl) {
    return undefined;
  }
  const { top, height } = nodeEl?.getBoundingClientRect() || ({} as any);

  let direction: DropDirection = DropDirection.CENTER;
  const topOffset = targetY - top;

  if (topOffset <= height * OFFSET_FROM_EDGE_PERCENTAGE) {
    direction = DropDirection.UP;
  } else if (topOffset >= height * (1 - OFFSET_FROM_EDGE_PERCENTAGE)) {
    direction = DropDirection.DOWN;
  }

  return direction;
};

/**
 * TODO doesn't handle:
 * - virtualized items that are not fully visible
 */
const getRootChildrenIndex = (context, scrollY) => {
  const rootChildren = context?.tree?.filter((e) => e.parent === ROOT_ID) ?? [];

  const resultIndex = rootChildren.reverse().findIndex((e) => {
    let itemRect;
    try {
      itemRect = document.querySelector(`[data-drop-id="${e.id}"]`)?.getBoundingClientRect();
    } catch (err) {
      return false;
    }
    return (itemRect?.top || 0) + ROW_SIZE / 2 <= scrollY;
  });

  return resultIndex === -1 ? 0 : rootChildren.length - 1 - resultIndex;
};

export const getDropTarget = <T>(
  node: NodeModel | null,
  nodeEl: HTMLElement | null,
  monitor: DropTargetMonitor,
  context: TreeState<T>,
): DropTarget => {
  const dragSource = monitor.getItem<any>();
  const isFolderSource = dragSource?.isFolder;
  const parentId = getParent(node) ?? context.rootId;
  const isFolderTarget = node?.isFolder;

  const isRoot = node === null;

  if (isRoot) {
    const index = getRootChildrenIndex(context, monitor.getClientOffset()?.y);

    if (!isFolderSource) {
      return null;
    }
    return {
      id: parentId,
      direction: DropDirection.CENTER,
      index,
    };
  } else if (isFolderTarget) {
    if (isFolderSource) {
      const direction = getDirection(monitor.getClientOffset()?.y, nodeEl);

      return {
        id: node.id,
        index: 0,
        direction,
      };
    }

    return {
      id: node.id,
      index: 0,
      direction: DropDirection.CENTER,
    };
  } else if (isFolderSource) {
    return {
      id: parentId,
      index: 0,
      direction: DropDirection.DOWN,
    };
  }

  const hoverPosition = getHoverPosition(nodeEl!, monitor.getClientOffset()?.y || 0, context);

  return {
    id: node.id,
    index: 0,
    direction: hoverPosition === 'lower' ? DropDirection.DOWN : DropDirection.UP,
  };
};
