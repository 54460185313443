import FieldUserRole from 'Components/Fields/Field/FieldUserRole';
import { UserRole, defaultRole } from 'Constants/workspaces';
import { useUser } from 'Hooks/data/user/useUser';

type Props = {
  record: Record<string, any>;
};

const UserRoleCell = ({ record: { id, userType } }: Props) => {
  const user = useUser();
  const prefix = `${id}_`;
  const disabled = user.id === id;
  const defaultValue: UserRole = userType ?? defaultRole;

  return (
    <FieldUserRole
      name={`${prefix}userRole`}
      disabled={disabled}
      defaultValue={defaultValue}
      withinPortal={true}
      size="sm"
    />
  );
};

export default UserRoleCell;
