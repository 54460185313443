const DESCRIPTION = 'description';
const TYPE = 'type';
const ADDED_BY = 'added_by';
const LINKED_AT = 'linked_at';
const ADOBE_MARKETING = 'adobe_marketing';
const GOOGLE_ANALYTICS = 'google_analytics';
const GOOGLE_SEARCH_CONSOLE = 'google_search_console';
const ACTIONS = 'actions';
export const ColumnIDs = {
  DESCRIPTION,
  TYPE,
  ADDED_BY,
  LINKED_AT,
  ADOBE_MARKETING,
  GOOGLE_ANALYTICS,
  GOOGLE_SEARCH_CONSOLE,
  ACTIONS,
} as const;
