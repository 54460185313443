export const combineValidators = (
  validator: ((...args: Array<any>) => any)[] | ((...args: Array<any>) => any),
): ((...args: Array<any>) => any) | void => {
  if (!validator) {
    return undefined;
  }

  // add async validation logic once there will be use case
  return Array.isArray(validator)
    ? (val) => validator.reduce((acc, validate) => acc || validate(val), undefined)
    : validator;
};

/**
 * Handle field level error display.
 */
export const extractErrorInfo = (
  meta: any,
  submitting: boolean = false,
): {
  showError?: boolean;
  error?: string;
} => {
  if (!meta || submitting) {
    return {};
  }

  const { error, submitError, touched, dirtySinceLastSubmit, submitFailed, active } = meta;
  const showSubmitError = !dirtySinceLastSubmit;
  const showSyncError = touched || submitFailed;
  const resultError = (showSubmitError && submitError) || (showSyncError && error) || '';
  return {
    showError: !!resultError && !active,
    error: resultError,
  };
};
