import { useFormState } from 'react-final-form';
import AccButton from 'Components/AccButton/AccButton';
import { t } from '../../../Utilities/i18n';

export const FormSubmitButton = ({ label }: { label?: string }) => {
  const { submitFailed, dirtySinceLastSubmit, submitting } = useFormState();

  // TODO confirm proper behavior
  // We allow user to press submit even though form is not valid - to see errors
  // Once user submitted and received errors, we disable submit button till he made changes
  // disabled during submit to avoid double submit
  const disabled = submitting || (submitFailed && !dirtySinceLastSubmit);

  return (
    <AccButton type="submit" variant="primary" disabled={disabled}>
      {label ? label : t('Save')}
    </AccButton>
  );
};
