import { IconInfoCircle } from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import { TrackingKey } from 'Utilities/Analytics/mixpanel';
import { t } from 'Utilities/i18n';

type HelpGuideButtonProps = {
  helpguideLink: string;
};

const HelpGuideButton = ({ helpguideLink }: HelpGuideButtonProps) => (
  <AccButton
    variant="tertiary"
    leftSection={<IconInfoCircle size={20} />}
    trackingKey={TrackingKey.HelpGuide}
    onClick={() => window.open(helpguideLink, '_blank', 'noopener,noreferrer')}
  >
    {t('Help guide')}
  </AccButton>
);

export default HelpGuideButton;
