import * as React from 'react';
import classnames from 'classnames';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import './ellipsis.scss';

type Props = {
  children: React.ReactNode;
  placement: string;
  tooltipText?: string;
  updateOnResize: boolean;
  className?: string;
  forceShowTooltip?: boolean;
  tooltipDelay?: any;
};
type State = {
  shouldShowTooltip: boolean;
};

class Ellipsis extends React.Component<Props, State> {
  static defaultProps = {
    placement: 'top',
    updateOnResize: true,
  };

  state = {
    shouldShowTooltip: false,
  };

  componentDidMount() {
    this.setShouldShowTooltip();

    if (this.props.updateOnResize) {
      window.addEventListener('resize', this.setShouldShowTooltip);
    }
  }

  componentWillUnmount() {
    if (this.props.updateOnResize) {
      window.removeEventListener('resize', this.setShouldShowTooltip);
    }
  }

  setShouldShowTooltip = () => {
    // TODO FixTSignore
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const shouldShowTooltip =
      this.elem?.current === null ||
      this.elem.current?.offsetWidth < this.elem.current?.scrollWidth;

    if (this.state.shouldShowTooltip !== shouldShowTooltip) {
      this.setState({
        shouldShowTooltip,
      });
    }
  };
  elem = React.createRef<HTMLSpanElement>();

  render() {
    const { tooltipText, children, className, forceShowTooltip } = this.props;
    const hideTooltip = !forceShowTooltip && !this.state.shouldShowTooltip;
    return (
      <AccTooltip tooltip={tooltipText || children} old disable={hideTooltip}>
        <span className={classnames('ellipsis-tooltip', className)} ref={this.elem}>
          {children}
        </span>
      </AccTooltip>
    );
  }
}

export default Ellipsis;
