import times from 'lodash/times';
import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { calculateItemsPerScreen } from './helpers';

class ViewStore {
  // Used to check where props and store calculations synchronized, to generate this key we use params for position calculations.
  storeKey: string = '';
  scrolling: boolean = false;
  indexMap: number[] = [];

  constructor(viewCount) {
    makeAutoObservable(this);
    this.indexMap = times(viewCount).map((e, i) => i);
  }

  updateAll = (viewCount, list, storeKey) => {
    runInAction(() => {
      this.indexMap = times(viewCount).map((e, i) => i);
      const next = toJS(this.indexMap);

      list.map((patch) => {
        if (patch) {
          const { index, nextIndex } = patch;
          next[index] = nextIndex;
        }
      });
      this.indexMap = next;
      this.storeKey = storeKey;
    });
  };

  setScrolling = (nextScrolling: boolean) => {
    runInAction(() => {
      this.scrolling = nextScrolling;
    });
  };

  setVisibilityBatch = (list) => {
    runInAction(() => {
      const next = toJS(this.indexMap);

      list.map((patch) => {
        if (patch) {
          const { index, nextIndex } = patch;
          next[index] = nextIndex;
        }
      });
      this.indexMap = next;
    });
  };
}

export const viewStore = new ViewStore(calculateItemsPerScreen(Infinity));
