import transform from 'lodash/transform';
import {
  ABOVE_THE_FOLD,
  AD_COMPETITION,
  AI_SEARCH_VOLUME,
  AI_TRAFFIC_VALUE,
  AI_TRAFFIC_VALUE_CHANGE,
  ALPHABET,
  BASE_RANK,
  BASE_RANK_CHANGE,
  CAMPAIGNS,
  CLICKS,
  CLIENTS,
  COMPARE_TO,
  COMPETITOR_DOMAIN,
  COMPETITOR_URL,
  CONNECTION,
  COUNTRY_LOCALE,
  COUNTRY_NAME,
  CPC,
  DATE,
  DATE_ADDED,
  DOMAINS,
  DYNAMIC_CTR,
  DYNAMIC_CTR_CHANGE,
  DYNAMIC_CTR_MAX,
  FOLDERS,
  FREE_TEXT_DOMAIN,
  GSC_CTR,
  GSC_EXISTS,
  GSC_POSITION,
  HAS_EXTRA_RANKS,
  HIGHEST_RANKING_PAGE,
  HIGHEST_RANKING_PAGE_MATCH,
  IMPRESSIONS,
  KEYWORD,
  KEYWORDS,
  KEYWORD_OPTIONS,
  LANDING_PAGES,
  LOCAL_PACK_RANK,
  LOCAL_PACK_RANK_CHANGE,
  LOCATION,
  MAX_AI_SOV,
  MAX_AI_TRAFFIC_VALUE,
  MAX_RANK,
  MAX_SOV,
  MAX_TRAFFIC_VALUE,
  MULTIPLE_KEYWORDS,
  NOTE,
  ORGANIC_TRAFFIC,
  ORGANIC_TRAFFIC_CHANGE,
  PAGE_SERP_FEATURES,
  PAGE_SERP_FEATURES_OWNED,
  PAGE_SERP_FEATURES_RANK,
  PERIOD,
  PIXELS_FROM_TOP,
  PIXELS_FROM_TOP_CHANGE,
  PLACEMENTS,
  RANK,
  RANK_CHANGE,
  SEARCH_ENGINE,
  SEARCH_INTENT,
  SEARCH_TYPE,
  SEARCH_VOLUME,
  SHARE_OF_VOICE,
  SHARE_OF_VOICE_CHANGE,
  STARRED,
  SUBDOMAIN,
  TAGS,
  TOP_COMPETITOR,
  TRAFFIC_VALUE,
  TRAFFIC_VALUE_CHANGE,
  USER_NAME,
} from 'Types/Filter';

export const EMPTY_FILTER_SET = 'empty';
export const DOMAINS_FILTER_SET = 'domains';
export const KEYWORDS_FILTER_SET = 'keywords';
export const KEYDIS_FILTER_SET = 'keywords_discovery';
export const SITE_MAPPING_FILTER_SET = 'site_mapping';
export const PUBLIC_REPORT_FILTER_SET = 'public_report_filter_set';
export const TAG_CLOUD_FILTER_SET = 'tag_cloud';
export const IMPORT_GSC_FILTER_SET = 'import_gsc';
export const IMPORT_UNIVERSAL_FILTER_SET = 'import_universal_connection';
export const NOTES_FILTER_SET = 'notes';
export const AFFILIATE_FILTER_SET = 'affiliate';
export const SALES_FILTER_SET = 'sales';
export type FilterSet =
  | typeof DOMAINS_FILTER_SET
  | typeof KEYWORDS_FILTER_SET
  | typeof EMPTY_FILTER_SET
  | typeof NOTES_FILTER_SET
  | typeof IMPORT_GSC_FILTER_SET
  | typeof IMPORT_UNIVERSAL_FILTER_SET
  | typeof AFFILIATE_FILTER_SET
  | typeof KEYDIS_FILTER_SET
  | typeof SALES_FILTER_SET
  | typeof TAG_CLOUD_FILTER_SET
  | typeof SITE_MAPPING_FILTER_SET
  | typeof PUBLIC_REPORT_FILTER_SET;
const filterSetTypes = {
  [EMPTY_FILTER_SET]: 0,
  [DOMAINS_FILTER_SET]: 1,
  [KEYWORDS_FILTER_SET]: 2,
  [KEYDIS_FILTER_SET]: 14,
  [IMPORT_GSC_FILTER_SET]: 3,
  [NOTES_FILTER_SET]: 5,
  [IMPORT_UNIVERSAL_FILTER_SET]: 7,
  [AFFILIATE_FILTER_SET]: 11,
  [SALES_FILTER_SET]: 13,
  [SITE_MAPPING_FILTER_SET]: 15,
  [TAG_CLOUD_FILTER_SET]: 16,
  [PUBLIC_REPORT_FILTER_SET]: 17,
};
export const getFilterSetType = (filterSet: FilterSet) => filterSetTypes[filterSet];
const REQUIRED = 2;
const SHOW_IN_FILTERBAR = 1;
const DEFAULT = 0;
const keywordsFilters = {
  [PERIOD]: REQUIRED,
  [COMPARE_TO]: REQUIRED,
  [DOMAINS]: REQUIRED,
  [STARRED]: SHOW_IN_FILTERBAR,
  [KEYWORD]: SHOW_IN_FILTERBAR,
  [MULTIPLE_KEYWORDS]: SHOW_IN_FILTERBAR,
  [DATE_ADDED]: SHOW_IN_FILTERBAR,
  [TAGS]: SHOW_IN_FILTERBAR,
  [FOLDERS]: SHOW_IN_FILTERBAR,
  [LOCATION]: SHOW_IN_FILTERBAR,
  [RANK]: SHOW_IN_FILTERBAR,
  [RANK_CHANGE]: SHOW_IN_FILTERBAR,
  [BASE_RANK]: SHOW_IN_FILTERBAR,
  [BASE_RANK_CHANGE]: SHOW_IN_FILTERBAR,
  [MAX_RANK]: SHOW_IN_FILTERBAR,
  [LOCAL_PACK_RANK]: SHOW_IN_FILTERBAR,
  [LOCAL_PACK_RANK_CHANGE]: SHOW_IN_FILTERBAR,
  [SHARE_OF_VOICE]: SHOW_IN_FILTERBAR,
  [SHARE_OF_VOICE_CHANGE]: SHOW_IN_FILTERBAR,
  [MAX_SOV]: SHOW_IN_FILTERBAR,
  [PIXELS_FROM_TOP]: SHOW_IN_FILTERBAR,
  [PIXELS_FROM_TOP_CHANGE]: SHOW_IN_FILTERBAR,
  [ORGANIC_TRAFFIC]: SHOW_IN_FILTERBAR,
  [ORGANIC_TRAFFIC_CHANGE]: SHOW_IN_FILTERBAR,
  [MAX_AI_SOV]: SHOW_IN_FILTERBAR,

  [SEARCH_VOLUME]: SHOW_IN_FILTERBAR,
  [AI_SEARCH_VOLUME]: SHOW_IN_FILTERBAR,
  [SEARCH_ENGINE]: SHOW_IN_FILTERBAR,
  [SEARCH_TYPE]: SHOW_IN_FILTERBAR,
  [PAGE_SERP_FEATURES]: SHOW_IN_FILTERBAR,
  [PAGE_SERP_FEATURES_OWNED]: SHOW_IN_FILTERBAR,
  [PAGE_SERP_FEATURES_RANK]: SHOW_IN_FILTERBAR,
  [HIGHEST_RANKING_PAGE]: SHOW_IN_FILTERBAR,
  [HIGHEST_RANKING_PAGE_MATCH]: SHOW_IN_FILTERBAR,

  [AI_TRAFFIC_VALUE]: SHOW_IN_FILTERBAR,
  [AI_TRAFFIC_VALUE_CHANGE]: SHOW_IN_FILTERBAR,
  [MAX_AI_TRAFFIC_VALUE]: SHOW_IN_FILTERBAR,
  [TRAFFIC_VALUE]: SHOW_IN_FILTERBAR,
  [TRAFFIC_VALUE_CHANGE]: SHOW_IN_FILTERBAR,
  [MAX_TRAFFIC_VALUE]: SHOW_IN_FILTERBAR,

  [CPC]: SHOW_IN_FILTERBAR,
  [AD_COMPETITION]: SHOW_IN_FILTERBAR,

  [COUNTRY_LOCALE]: SHOW_IN_FILTERBAR,
  [LANDING_PAGES]: SHOW_IN_FILTERBAR,
  [KEYWORD_OPTIONS]: SHOW_IN_FILTERBAR,
  [ABOVE_THE_FOLD]: SHOW_IN_FILTERBAR,
  [HAS_EXTRA_RANKS]: SHOW_IN_FILTERBAR,
  [DYNAMIC_CTR]: SHOW_IN_FILTERBAR,
  [DYNAMIC_CTR_MAX]: SHOW_IN_FILTERBAR,
  [DYNAMIC_CTR_CHANGE]: SHOW_IN_FILTERBAR,

  [SUBDOMAIN]: SHOW_IN_FILTERBAR,
  [COMPETITOR_URL]: SHOW_IN_FILTERBAR,
  [SEARCH_INTENT]: SHOW_IN_FILTERBAR,
  [ALPHABET]: SHOW_IN_FILTERBAR,
  [TOP_COMPETITOR]: SHOW_IN_FILTERBAR,
};

const tagCloudFilters = {
  [PERIOD]: REQUIRED,
  [COMPARE_TO]: REQUIRED,
  [DOMAINS]: REQUIRED,
  [STARRED]: SHOW_IN_FILTERBAR,
  [KEYWORD]: SHOW_IN_FILTERBAR,
  [MULTIPLE_KEYWORDS]: SHOW_IN_FILTERBAR,
  [DATE_ADDED]: SHOW_IN_FILTERBAR,
  [LOCATION]: SHOW_IN_FILTERBAR,
  [RANK]: SHOW_IN_FILTERBAR,
  [RANK_CHANGE]: SHOW_IN_FILTERBAR,
  [BASE_RANK]: SHOW_IN_FILTERBAR,
  [BASE_RANK_CHANGE]: SHOW_IN_FILTERBAR,
  [LOCAL_PACK_RANK]: SHOW_IN_FILTERBAR,
  [LOCAL_PACK_RANK_CHANGE]: SHOW_IN_FILTERBAR,
  [MAX_RANK]: SHOW_IN_FILTERBAR,
  [SHARE_OF_VOICE]: SHOW_IN_FILTERBAR,
  [SHARE_OF_VOICE_CHANGE]: SHOW_IN_FILTERBAR,
  [MAX_SOV]: SHOW_IN_FILTERBAR,
  [PIXELS_FROM_TOP]: SHOW_IN_FILTERBAR,
  [PIXELS_FROM_TOP_CHANGE]: SHOW_IN_FILTERBAR,
  [ORGANIC_TRAFFIC]: SHOW_IN_FILTERBAR,
  [ORGANIC_TRAFFIC_CHANGE]: SHOW_IN_FILTERBAR,
  [MAX_AI_SOV]: SHOW_IN_FILTERBAR,
  [SEARCH_VOLUME]: SHOW_IN_FILTERBAR,
  [AI_SEARCH_VOLUME]: SHOW_IN_FILTERBAR,
  [SEARCH_ENGINE]: SHOW_IN_FILTERBAR,
  [SEARCH_TYPE]: SHOW_IN_FILTERBAR,
  [PAGE_SERP_FEATURES]: SHOW_IN_FILTERBAR,
  [PAGE_SERP_FEATURES_OWNED]: SHOW_IN_FILTERBAR,
  [PAGE_SERP_FEATURES_RANK]: SHOW_IN_FILTERBAR,
  [HIGHEST_RANKING_PAGE]: SHOW_IN_FILTERBAR,
  [HIGHEST_RANKING_PAGE_MATCH]: SHOW_IN_FILTERBAR,
  [AI_TRAFFIC_VALUE]: SHOW_IN_FILTERBAR,
  [AI_TRAFFIC_VALUE_CHANGE]: SHOW_IN_FILTERBAR,
  [MAX_AI_TRAFFIC_VALUE]: SHOW_IN_FILTERBAR,
  [TRAFFIC_VALUE]: SHOW_IN_FILTERBAR,
  [TRAFFIC_VALUE_CHANGE]: SHOW_IN_FILTERBAR,
  [MAX_TRAFFIC_VALUE]: SHOW_IN_FILTERBAR,
  [CPC]: SHOW_IN_FILTERBAR,
  [AD_COMPETITION]: SHOW_IN_FILTERBAR,
  [COUNTRY_LOCALE]: SHOW_IN_FILTERBAR,
  [LANDING_PAGES]: SHOW_IN_FILTERBAR,
  [KEYWORD_OPTIONS]: SHOW_IN_FILTERBAR,
  [ABOVE_THE_FOLD]: SHOW_IN_FILTERBAR,
  [HAS_EXTRA_RANKS]: SHOW_IN_FILTERBAR,
  [DYNAMIC_CTR]: SHOW_IN_FILTERBAR,
  [DYNAMIC_CTR_MAX]: SHOW_IN_FILTERBAR,
  [DYNAMIC_CTR_CHANGE]: SHOW_IN_FILTERBAR,

  [SUBDOMAIN]: SHOW_IN_FILTERBAR,
  [COMPETITOR_URL]: SHOW_IN_FILTERBAR,
  [SEARCH_INTENT]: SHOW_IN_FILTERBAR,
  [ALPHABET]: SHOW_IN_FILTERBAR,
  [TOP_COMPETITOR]: SHOW_IN_FILTERBAR,
};

const sitemapFilters = {
  [PERIOD]: REQUIRED,
  [COMPARE_TO]: REQUIRED,
  [DOMAINS]: REQUIRED,
  [KEYWORD]: SHOW_IN_FILTERBAR,
  [MULTIPLE_KEYWORDS]: SHOW_IN_FILTERBAR,
  [RANK]: SHOW_IN_FILTERBAR,
  [LOCAL_PACK_RANK]: SHOW_IN_FILTERBAR,
  [SHARE_OF_VOICE]: SHOW_IN_FILTERBAR,
  [SEARCH_VOLUME]: SHOW_IN_FILTERBAR,
  [AI_SEARCH_VOLUME]: SHOW_IN_FILTERBAR,
  [PAGE_SERP_FEATURES]: SHOW_IN_FILTERBAR,
  [HIGHEST_RANKING_PAGE]: SHOW_IN_FILTERBAR,
  [ABOVE_THE_FOLD]: SHOW_IN_FILTERBAR,
  [DYNAMIC_CTR]: SHOW_IN_FILTERBAR,
  [DYNAMIC_CTR_MAX]: SHOW_IN_FILTERBAR,
  [ORGANIC_TRAFFIC]: SHOW_IN_FILTERBAR,
  [SUBDOMAIN]: SHOW_IN_FILTERBAR,
  [SEARCH_INTENT]: SHOW_IN_FILTERBAR,
  [ALPHABET]: SHOW_IN_FILTERBAR,
};

const keydisFilters = {
  [PERIOD]: REQUIRED,
  [COMPARE_TO]: REQUIRED,
  [DOMAINS]: REQUIRED,
  [FREE_TEXT_DOMAIN]: REQUIRED,
  [TAGS]: SHOW_IN_FILTERBAR,
  [FOLDERS]: SHOW_IN_FILTERBAR,
  [KEYWORD]: SHOW_IN_FILTERBAR,
  [RANK]: SHOW_IN_FILTERBAR,
  [SHARE_OF_VOICE]: SHOW_IN_FILTERBAR,
  [SEARCH_VOLUME]: SHOW_IN_FILTERBAR,
  [PAGE_SERP_FEATURES]: SHOW_IN_FILTERBAR,
  [PAGE_SERP_FEATURES_OWNED]: SHOW_IN_FILTERBAR,
  [HIGHEST_RANKING_PAGE]: SHOW_IN_FILTERBAR,
  [ABOVE_THE_FOLD]: SHOW_IN_FILTERBAR,
  [DYNAMIC_CTR]: SHOW_IN_FILTERBAR,
  [DYNAMIC_CTR_MAX]: SHOW_IN_FILTERBAR,
  [ORGANIC_TRAFFIC]: SHOW_IN_FILTERBAR,
  [SUBDOMAIN]: SHOW_IN_FILTERBAR,
  [COMPETITOR_URL]: SHOW_IN_FILTERBAR,
  [COMPETITOR_DOMAIN]: SHOW_IN_FILTERBAR,
  [SEARCH_INTENT]: SHOW_IN_FILTERBAR,
  [ALPHABET]: SHOW_IN_FILTERBAR,
};

const domainsFilters = {
  [PERIOD]: REQUIRED,
  [COMPARE_TO]: REQUIRED,
  [CLIENTS]: SHOW_IN_FILTERBAR,
  [DOMAINS]: SHOW_IN_FILTERBAR,
};
const importGSCFilters = {
  [DOMAINS]: REQUIRED,
  [KEYWORD]: SHOW_IN_FILTERBAR,
  [COUNTRY_NAME]: SHOW_IN_FILTERBAR,
  [IMPRESSIONS]: SHOW_IN_FILTERBAR,
  [CLICKS]: SHOW_IN_FILTERBAR,
  [GSC_EXISTS]: DEFAULT,
  [GSC_CTR]: SHOW_IN_FILTERBAR,
  [GSC_POSITION]: SHOW_IN_FILTERBAR,
};
const importUniversalFilters = {
  [CONNECTION]: REQUIRED,
  [KEYWORD]: DEFAULT,
  [COUNTRY_LOCALE]: DEFAULT,
};
const notesFilters = {
  [DOMAINS]: REQUIRED,
  [KEYWORDS]: DEFAULT,
  [NOTE]: DEFAULT,
  [USER_NAME]: DEFAULT,
  [DATE]: DEFAULT,
};
const affiliateFilters = {
  [PERIOD]: REQUIRED,
  [CAMPAIGNS]: SHOW_IN_FILTERBAR,
  [PLACEMENTS]: SHOW_IN_FILTERBAR, // [UNIQUE_IDS]: SHOW_IN_FILTERBAR,
};
const salesFilters = {
  [PERIOD]: REQUIRED,
};
const publicReportFilters = {
  [PERIOD]: DEFAULT,
  [COMPARE_TO]: DEFAULT,
};
export const filterSetViews = {
  [KEYWORDS_FILTER_SET]: keywordsFilters,
  [KEYDIS_FILTER_SET]: keydisFilters,
  [DOMAINS_FILTER_SET]: domainsFilters,
  [IMPORT_GSC_FILTER_SET]: importGSCFilters,
  [NOTES_FILTER_SET]: notesFilters,
  [IMPORT_UNIVERSAL_FILTER_SET]: importUniversalFilters,
  [AFFILIATE_FILTER_SET]: affiliateFilters,
  [SALES_FILTER_SET]: salesFilters,
  [SITE_MAPPING_FILTER_SET]: sitemapFilters,
  [TAG_CLOUD_FILTER_SET]: tagCloudFilters,
  [PUBLIC_REPORT_FILTER_SET]: publicReportFilters,
  [EMPTY_FILTER_SET]: {},
};
const relatedFilters = {
  [KEYDIS_FILTER_SET]: [KEYWORDS_FILTER_SET],
  [KEYWORDS_FILTER_SET]: [KEYDIS_FILTER_SET],
  [SITE_MAPPING_FILTER_SET]: [KEYWORDS_FILTER_SET],
  [TAG_CLOUD_FILTER_SET]: [KEYWORDS_FILTER_SET],
};

export const getCombinedFilterConfig = (filterSet: FilterSet) =>
  [...(relatedFilters[filterSet] ?? []), filterSet].reduce(
    (acc, k) => ({ ...acc, ...filterSetViews[k] }),
    {},
  );

export const getRelatedFiltersAttributes = (filterSet: FilterSet) =>
  Object.keys(getCombinedFilterConfig(filterSet) || {});

export function getFilterBarFilterAttributes(filterSet: FilterSet): string[] {
  const filterSetData = filterSetViews[filterSet];
  return Object.keys(filterSetData).filter(
    (filterAttribute) => (filterSetData[filterAttribute] & SHOW_IN_FILTERBAR) !== 0,
  );
}
export function isRequiredFilter(filterAttribute: string, filterSet: FilterSet): boolean {
  return (filterSetViews[filterSet][filterAttribute] & REQUIRED) !== 0;
}
export function isNonRequiredFilter(filterAttribute: string, filterSet: FilterSet): boolean {
  return !isRequiredFilter(filterAttribute, filterSet);
}
export function isShownInFilterBar(filterAttribute: string, filterSet: FilterSet): boolean {
  return (filterSetViews[filterSet][filterAttribute] & SHOW_IN_FILTERBAR) !== 0;
}
export const getGlobalFilterIsVisibleInBar = (filterAttribute: string, filterSet: FilterSet) => {
  const relatedAttributes = getCombinedFilterConfig(filterSet);
  return (relatedAttributes?.[filterAttribute] & SHOW_IN_FILTERBAR) !== 0;
};
export function isFilterInSet(filterAttribute: string, filterSet: FilterSet): boolean {
  return filterSetViews[filterSet]?.[filterAttribute] !== undefined;
}
export function getRequiredFilterAttributes(filterSet: FilterSet): string[] {
  return transform(
    filterSetViews[filterSet],
    (acc, value, attribute) => {
      if ((value & REQUIRED) !== 0) {
        acc.push(attribute);
      }

      return acc;
    },
    [] as any[],
  );
}
