import { memo } from 'react';
import cookie from 'react-cookies';
import { useApolloClient } from '@apollo/client';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import cn from 'classnames';
import AccButton from 'Components/AccButton/AccButton';
import Icon from 'Components/Icon/new';
import ImpersonateDropdown from 'Components/ImpersonateDropdown';
import IntercomButton from 'Components/Intercom/IntercomButton';
import LinkedAccountsDropdown from 'Components/LinkedAccountsDropdown';
import AccText from 'Components/Text/AccText';
import { PRESENT_MODE_COOKIE } from 'Pages/Profile/components/EditProfile';
import { t, tct } from 'Utilities/i18n';
import CautiousIcon from 'icons/grump/cautious.svg?inline';
import ChilledIcon from 'icons/grump/chilled.svg?inline';
import FuriousIcon from 'icons/grump/furious.svg?inline';
import GrumpyIcon from 'icons/grump/grumpy.svg?inline';
import { useGrumpMood } from '../NavbarHeader/useGrumpMood';
import styles from './navbar-footer.module.scss';

type Props = {
  navbarCollapsed: boolean;
  toggleNavbarCollapse: () => void;
};

const NavbarFooter = (props: Props) => {
  const { navbarCollapsed, toggleNavbarCollapse } = props;

  const isPresentMode = !!cookie.load(PRESENT_MODE_COOKIE);

  const grumpMood = useGrumpMood();

  const client = useApolloClient();
  const handleResetCache = () => {
    client
      ?.resetStore()
      .then(() => {
        console.info('Cache reset successfully');
      })
      .catch((error) => {
        console.error('Error resetting cache', error);
      });
  };

  const moodMapping = {
    chilled: <ChilledIcon />,
    cautious: <CautiousIcon />,
    furious: <FuriousIcon />,
    grumpy: <GrumpyIcon />,
  };

  return (
    <div className={cn(styles.navbarFooterContainer, { [styles.collapsed]: navbarCollapsed })}>
      {process?.env?.NODE_ENV === 'development' && (
        <AccButton onClick={handleResetCache} fullWidth px={0}>
          {!navbarCollapsed && 'Clear query cache'}
        </AccButton>
      )}
      {!isPresentMode && <ImpersonateDropdown navbarCollapsed={navbarCollapsed} />}

      <LinkedAccountsDropdown navbarCollapsed={navbarCollapsed} />

      <IntercomButton navbarCollapsed={navbarCollapsed} />
      <a
        href="https://www.accuranker.com/grump/"
        target="_blank"
        rel="noreferrer"
        className={styles.grumpItem}
      >
        <Icon className={styles.grumpy}>{moodMapping[grumpMood]}</Icon>
        {!navbarCollapsed && (
          <span>
            {tct('Grump is [grumpMood]', {
              grumpMood: <em>{grumpMood}</em>,
            })}
          </span>
        )}
      </a>

      <div
        onClick={toggleNavbarCollapse}
        className={styles.expandCollapseItem}
        title={navbarCollapsed ? t('Expand') : t('Collapse')}
      >
        <div className={styles.expandCollapseIcon}>
          {navbarCollapsed ? <IconChevronRight /> : <IconChevronLeft />}
        </div>
        {!navbarCollapsed && <AccText c="white">{t('Collapse')}</AccText>}
      </div>
    </div>
  );
};

export default memo(NavbarFooter);
