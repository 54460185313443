import React, { useMemo, useState } from 'react';
import { List } from 'react-virtualized';
import { Box, Divider, Flex, Popover } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { IconCaretDownFilled, IconCirclePlus } from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import TextInput from 'Components/Controls/TextInput';
import AccText from 'Components/Text/AccText';
import AccTitle from 'Components/Title/AccTitle';
import { TrackingKey } from 'Utilities/Analytics/mixpanel';
import DropdownRow from './DropdownRow';
import styles from './search-dropdown.module.scss';

export interface GroupsDomainItem {
  displayName?: string | null;
  domain?: string;
  id: string;
}

export interface GroupItem {
  id: number;
  domains?: GroupsDomainItem[];
  domainIds?: string[];
  displayName: string;
}

type DropdownItem = GroupItem | GroupsDomainItem;

type GroupsAndDomainsDropdownClassNames = { title: string; button: string };

type Props = {
  targetPlaceholder: string;

  item?: GroupsDomainItem | GroupItem;
  items: DropdownItem[];

  dropdownHeader: React.ReactNode;
  dropdownSearchPlaceholder: string;
  dropdownAddButtonLabel: string;
  trackingKey: TrackingKey;

  showAddButton?: boolean;

  onAdd?: (...args: Array<any>) => any;
  onDelete?: (...args: Array<any>) => any;
  onEdit?: (...args: Array<any>) => any;
  onSelect?: (...args: Array<any>) => any;
  clearAllSlot?: JSX.Element;

  dropdownWidth?: number;
  leftSection?: JSX.Element;
  rightSection?: JSX.Element;
  /** Render a checkbox on every option */
  showOptionCheckboxes?: boolean;
  /** List of selected domains.
   * Can be used to handle checkbox state on the individual domain rows
   */
  selectedDomains?: string[];
  classNames?: Partial<GroupsAndDomainsDropdownClassNames>;
  closeOnSelect?: boolean;
  loading?: boolean;
};

const GroupsAndDomainsDropdown = (props: Props) => {
  const {
    targetPlaceholder,
    classNames,
    item,
    showOptionCheckboxes,
    selectedDomains,
    clearAllSlot,
    trackingKey,
    loading,
  } = props;
  const domainURL = item && 'domain' in item ? item.domain : undefined;
  const {
    items,
    rightSection,
    leftSection = domainURL && (
      <img
        className="favicon"
        style={{ height: 14, width: 14 }}
        src={`https://app.accuranker.com/screenshots/favicon?url=${domainURL}&size=24`}
      />
    ),
  } = props;
  const { dropdownHeader, dropdownSearchPlaceholder, dropdownAddButtonLabel, closeOnSelect } =
    props;
  const { showAddButton = false } = props;
  const { onAdd, onDelete, onEdit, onSelect } = props;
  const { dropdownWidth } = props;

  const [opened, setOpened] = useState(false);

  const [searchValue, setSearchValue] = useState('');
  const [searchTerm] = useDebouncedValue(searchValue, 200);

  const filteredItems = useMemo(() => {
    return items?.length
      ? items.filter(
          (currentItem) =>
            currentItem.displayName?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
            ('domain' in currentItem &&
              currentItem.domain?.toLowerCase().includes(searchTerm?.toLowerCase())),
        )
      : [];
  }, [items, searchTerm]);

  const renderRow = ({ index, key, style }) => {
    const rowItem = filteredItems[index];
    return (
      <DropdownRow
        closeOnSelect={closeOnSelect}
        key={key}
        setOpened={setOpened}
        style={style}
        item={rowItem}
        onDelete={onDelete}
        onEdit={onEdit}
        onSelect={onSelect}
        renderCheckbox={showOptionCheckboxes}
        selected={selectedDomains?.includes(rowItem.id.toString())}
      />
    );
  };

  const Label = (): React.ReactElement => {
    if (item?.displayName && domainURL) {
      return (
        <>
          <span className={styles.ellipsis175}>{item.displayName}</span>
          {item.displayName !== domainURL && (
            <AccText fw={300} inline ml="xs" size="sm" overflowEllipsis maw={175}>
              {domainURL}
            </AccText>
          )}
        </>
      );
    }
    return (
      <span className={classNames?.title || styles.ellipsis250}>
        {item?.displayName || domainURL || targetPlaceholder}
      </span>
    );
  };

  return (
    <Popover opened={opened} onChange={setOpened} withinPortal position="bottom-start">
      <Popover.Target>
        <AccButton
          className={classNames?.button || styles.dropdownTarget}
          leftSection={leftSection}
          rightSection={rightSection || <IconCaretDownFilled size={12} />}
          variant="tertiary"
          loading={loading}
          trackingKey={trackingKey}
          onClick={() => {
            setOpened((prev) => !prev);
          }}
        >
          <Label />
        </AccButton>
      </Popover.Target>

      <Popover.Dropdown w="auto" p={0}>
        <Flex direction="column">
          <Box py="sm" px="md">
            <AccTitle mb="sm" type="h4">
              {dropdownHeader}
            </AccTitle>
            <TextInput
              placeholder={dropdownSearchPlaceholder}
              autoFocus
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              showError={false}
            />
          </Box>

          <Divider color="gray.1" />

          <List
            tabIndex={-1}
            width={dropdownWidth || 400}
            height={300}
            rowCount={filteredItems.length}
            rowHeight={48}
            rowRenderer={renderRow}
            overscanRowCount={20}
          />
          {showAddButton && (
            <>
              <Divider color="gray.1" />
              <Flex
                p="md"
                py="sm"
                align="center"
                justify={clearAllSlot ? 'space-between' : 'center'}
              >
                {clearAllSlot}
                <AccButton
                  variant="tertiary"
                  leftSection={<IconCirclePlus size={18} />}
                  onClick={onAdd}
                >
                  {dropdownAddButtonLabel}
                </AccButton>
              </Flex>
            </>
          )}
        </Flex>
      </Popover.Dropdown>
    </Popover>
  );
};

export default GroupsAndDomainsDropdown;
