/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useRef, useState } from 'react';
import { Flex, Menu, Popover } from '@mantine/core';
import { useClickOutside } from '@mantine/hooks';
import { IconCheck, IconDotsVertical, IconFilter, IconFilterFilled } from '@tabler/icons-react';
import cn from 'classnames';
import { observer } from 'mobx-react';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { QueryOrder } from 'Components/DataTable/constants';
import { tableClassName } from 'Components/DataTable/helpers';
import { useContextTableStore } from 'Components/DataTable/store/components/TableStoreContext';
import { ColumnFilter } from 'Components/DataTable/types';
import getFilterData from 'Components/Filters/getFilterData';
import AccText from 'Components/Text/AccText';
import { useFilters } from 'Hooks';
import { FilterBase } from 'Types/Filter';
import { Ordering } from 'Types/Sort';
import { EventName, useMixpanel } from 'Utilities/Analytics/mixpanel';
import { getSortingIcon, getSortingText } from 'Utilities/getSortingInfo';
import { t } from 'Utilities/i18n';
import { CheckboxHeaderCell } from './components/CheckboxHeaderCell';
import { TableFilter } from './components/TableFilter/TableFilter';
import FilterTabs from './components/TableFilter/components/FilterTabs/filterTabs';
import { getIsFilterElement } from './helpers';
import './header-cell.scss';

interface HeaderCellBuiltinProps {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

const defaultCell = {
  checkbox: CheckboxHeaderCell,
};

interface HeaderCellProps {
  render?: React.ReactNode;
  tooltip?: string;
  filter?: ColumnFilter;
  ordering?: Ordering;
  type?: keyof typeof defaultCell;
  reverseDirection?: boolean;
  disabledMenu?: boolean;
  menuTitle?: string;
  CustomMenuItem?: () => JSX.Element;
}

export const HeaderCell: React.FC<
  React.PropsWithChildren<HeaderCellProps & HeaderCellBuiltinProps>
> = observer(
  ({
    type,
    children,
    tooltip,
    className,
    style,
    render,
    filter,
    ordering,
    reverseDirection,
    disabledMenu,
    menuTitle,
    CustomMenuItem,
  }) => {
    const tableStore = useContextTableStore();
    const trackEvent = useMixpanel();
    const hasOrdering = !!ordering?.orderBy;
    const hasFilter = !!filter;
    const filters = useFilters();
    const defaultOrdering = tableStore?.defaultOrdering;
    const activeOrdering = tableStore?.ordering?.orderBy === ordering?.orderBy;
    const order = activeOrdering ? tableStore?.ordering?.order : ordering?.defaultOrder;
    const isDesc = order === QueryOrder.DESC;

    const [menuOpened, setMenuOpened] = useState(false);
    const [filterPopoverOpened, setFilterPopoverOpened] = useState(false);
    const [popoverFilterAttribute, setPopoverFilterAttribute] = useState<
      FilterBase['attribute'] | undefined
    >(filter?.filterAttributes[0]);
    // useClickOutside only works with useState, not useRef
    const [dropdown, setDropdown] = useState<HTMLDivElement | null>(null);
    const [filterButton, setFilterButton] = useState<HTMLDivElement | null>(null);
    const headerCellRef = useRef<HTMLDivElement | null>(null);
    const [activeFilters, setActiveFilters] = useState<FilterBase[]>([]);

    const getTabName = (filterAttribute: string | undefined) => {
      const { title } = getFilterData(filterAttribute) || {};
      return title;
    };

    useEffect(() => {
      if (filter?.filterAttributes && filters) {
        setActiveFilters(
          filters.filter(Boolean).filter((f) => filter?.filterAttributes.includes(f.attribute)),
        );
      }
    }, [filter?.filterAttributes, filters]);

    useClickOutside(() => setFilterPopoverOpened(false), null, [dropdown, filterButton]);

    const onOrderChange = (
      event: React.MouseEvent<HTMLButtonElement>,
      specificOrder?: QueryOrder,
      resetToDefault?: boolean,
    ) => {
      if (getIsFilterElement(event.currentTarget)) {
        return;
      }

      trackEvent(EventName.TableSorting, {
        'Table Name': tableStore?.tableName ?? '',
        'Sort Field': ordering?.orderBy ?? '',
        'Is Infinite Table': false,
      });

      if (specificOrder) {
        tableStore?.setOrdering({
          order: specificOrder,
          orderBy: ordering!.orderBy,
        });
        return;
      }

      if (resetToDefault) {
        defaultOrdering && tableStore?.setOrdering(defaultOrdering);
        return;
      }

      const sortingOrder = activeOrdering
        ? isDesc
          ? QueryOrder.ASC
          : QueryOrder.DESC
        : (ordering?.defaultOrder as QueryOrder) || QueryOrder.DESC;

      return tableStore?.setOrdering({
        order: sortingOrder,
        orderBy: ordering!.orderBy,
      });
    };

    let content = render ? render : <span className="label">{children}</span>;

    // TODO once we will have more default cells update logic
    let customClassName = '';
    if (type && defaultCell[type]) {
      const DefaultCell: any = defaultCell[type];
      customClassName = tableClassName('header-cell-container--checkbox');
      content = <DefaultCell tableStore={tableStore} />;
    }
    const resultClassName = cn(className, {
      [tableClassName('header-cell-ordering')]: hasOrdering || hasFilter,
      [tableClassName('header-cell-ordering--active')]: activeOrdering,
      [tableClassName(`header-cell-ordering--${isDesc ? 'desc' : 'asc'}`)]: hasOrdering,
    });

    const activeFilterAttributes = activeFilters.map((activeFilter) => activeFilter.attribute);

    return (
      <Popover
        opened={filterPopoverOpened}
        disabled={menuOpened || !hasFilter}
        withinPortal
        position={'bottom-start'}
        classNames={{ dropdown: 'header-popover' }}
      >
        <Popover.Target>
          <th className={cn(resultClassName)} style={style}>
            <AccTooltip tooltip={tooltip} disable={menuOpened}>
              <Flex
                align={'center'}
                direction={reverseDirection ? 'row-reverse' : 'row'}
                className={cn(tableClassName('header-cell-container'), customClassName)}
              >
                <Flex
                  align={'center'}
                  ref={headerCellRef}
                  className={'header-cell-content'}
                  // direction={reverseDirection ? 'row-reverse' : 'row'}
                  mr={reverseDirection ? -7 : undefined}
                  ml={!reverseDirection ? -7 : undefined}
                  onClick={() => {
                    if (!menuOpened) {
                      setMenuOpened((isOpened) => !isOpened);
                    }
                  }}
                >
                  <Flex
                    ml={reverseDirection ? 'auto' : undefined}
                    // mr={reverseDirection ? 'auto' : undefined}
                  >
                    <div className={'text'}>{content}</div>
                  </Flex>
                  {hasFilter && (
                    <div
                      ref={setFilterButton}
                      className="filter-icon"
                      onClick={(e) => {
                        e.stopPropagation();
                        setFilterPopoverOpened(!filterPopoverOpened);
                      }}
                    >
                      <TableFilter filter={filter} />
                    </div>
                  )}
                  {hasOrdering && getSortingIcon(isDesc, hasOrdering, ordering)}
                  {(hasOrdering || hasFilter) && !disabledMenu && (
                    <Menu
                      shadow="md"
                      width={208}
                      position={'bottom-start'}
                      opened={menuOpened}
                      transitionProps={{ duration: 0 }}
                      onChange={() => {
                        //timeout used to make sure this event is fired after the click event
                        setTimeout(() => setMenuOpened((isOpened) => !isOpened), 0);
                      }}
                      offset={4}
                      //pointerup event is used to make this event fire after the click event
                      clickOutsideEvents={['pointerup']}
                    >
                      <Menu.Target>
                        <Flex
                          className="context-icon"
                          ml={!reverseDirection ? 'auto' : undefined}
                          // mr={reverseDirection ? 'auto' : undefined}
                          onClick={(e) => {
                            // Prevents the event from bubbling up to the header cell onClick
                            e.stopPropagation();
                          }}
                        >
                          <IconDotsVertical size={16} />
                        </Flex>
                      </Menu.Target>
                      <Menu.Dropdown className="context-menu" onClick={(e) => e.stopPropagation()}>
                        {content && (
                          <>
                            <Menu.Label>
                              <AccText variant="label">{menuTitle ? menuTitle : content}</AccText>
                            </Menu.Label>
                            <Menu.Divider />
                          </>
                        )}

                        {hasFilter && (
                          <>
                            <Menu.Label>{t('Filter by')}</Menu.Label>
                            {filter.filterAttributes.map((filterAttribute) => (
                              <Menu.Item
                                key={filterAttribute}
                                leftSection={
                                  activeFilterAttributes.includes(filterAttribute) ? (
                                    <IconFilterFilled size={16} />
                                  ) : (
                                    <IconFilter size={16} />
                                  )
                                }
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setFilterPopoverOpened(true);
                                  setPopoverFilterAttribute(filterAttribute);
                                }}
                              >
                                {getTabName(filterAttribute)}
                              </Menu.Item>
                            ))}
                          </>
                        )}
                        {hasOrdering && (
                          <>
                            {hasFilter && <Menu.Divider />}
                            <Menu.Label>{t('Sort by')}</Menu.Label>
                            <Menu.Item
                              leftSection={getSortingIcon(false, hasOrdering, ordering)}
                              onClick={(event) => {
                                onOrderChange(event, QueryOrder.ASC);
                              }}
                            >
                              <Flex justify={'space-between'}>
                                {getSortingText(true, hasOrdering, ordering)}
                                {activeOrdering && !isDesc ? <IconCheck size={16} /> : null}
                              </Flex>
                            </Menu.Item>
                            <Menu.Item
                              leftSection={getSortingIcon(true, hasOrdering, ordering)}
                              onClick={(event) => {
                                onOrderChange(event, QueryOrder.DESC);
                              }}
                            >
                              <Flex justify={'space-between'}>
                                {getSortingText(false, hasOrdering, ordering)}
                                {activeOrdering && isDesc ? <IconCheck size={16} /> : null}
                              </Flex>
                            </Menu.Item>
                            {activeOrdering && (
                              <Menu.Item onClick={(event) => onOrderChange(event, undefined, true)}>
                                {t('Unsort')}
                              </Menu.Item>
                            )}
                          </>
                        )}
                        {CustomMenuItem && <CustomMenuItem />}
                      </Menu.Dropdown>
                    </Menu>
                  )}
                </Flex>
              </Flex>
            </AccTooltip>
          </th>
        </Popover.Target>
        <Popover.Dropdown ref={setDropdown} maw={640}>
          {hasFilter && popoverFilterAttribute && (
            <FilterTabs
              initialTab={filter.filterAttributes.indexOf(popoverFilterAttribute) + 1}
              filterAttributes={filter.filterAttributes}
              currentActiveFilters={activeFilters}
              onClose={() => setFilterPopoverOpened(false)}
              onSetFilter={() => setFilterPopoverOpened(false)}
            />
          )}
        </Popover.Dropdown>
      </Popover>
    );
  },
);
