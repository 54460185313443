import { memo, useCallback } from 'react';
import { Box, Button, Flex } from '@mantine/core';
import cn from 'classnames';
import Icon from 'Components/Icon/new';
import { getTreeTableData } from 'Pages/SiteMapping/components/TreeTable/getTreeTableData';
import { StackedValues } from 'Pages/SiteMapping/components/TreeTable/utils';
import { fixLeadingSlash } from 'Pages/SiteMapping/support/helpers';
import { SiteMapMode, SitemapNode } from 'Pages/SiteMapping/support/types';
import { t } from 'Utilities/i18n';
import CompetitorsIcon from 'icons/menu/competitors.svg?inline';
import MinusIcon from 'icons/ose-minus.svg?inline';
import PlusIcon from 'icons/ose-plus.svg?inline';
import RowIndentations from './RowIndentations';
import styles from './tree-table.module.scss';

type RowContentComponentFactoryProps = {
  hoveredThisPage: boolean;
  hoveredIncludingChildPages: boolean;
  includeThisPage: boolean;
  includeChildPages: boolean;
  viewModeId: SiteMapMode;
};

type RowContentProps = {
  isOpen: undefined | boolean;
  handleToggleExpand: () => void;
  rootNode: SitemapNode;
  className?: string;
  hideConnectors?: string[];
};

type FullRowContentProps = RowContentProps & RowContentComponentFactoryProps;

const RowContentIsEqual = (prevProps: FullRowContentProps, nextProps: FullRowContentProps) => {
  return (
    prevProps.rootNode.pathSegment === nextProps.rootNode.pathSegment &&
    prevProps.isOpen === nextProps.isOpen &&
    prevProps.hoveredThisPage === nextProps.hoveredThisPage &&
    prevProps.hoveredIncludingChildPages === nextProps.hoveredIncludingChildPages &&
    prevProps.includeThisPage === nextProps.includeThisPage &&
    prevProps.includeChildPages === nextProps.includeChildPages &&
    prevProps.viewModeId === nextProps.viewModeId
  );
};

const RowContent = memo(
  ({
    rootNode,
    isOpen,
    handleToggleExpand,
    hoveredThisPage,
    hoveredIncludingChildPages,
    includeThisPage,
    includeChildPages,
    viewModeId,
    className,
    hideConnectors,
  }: FullRowContentProps) => {
    const {
      estimatedVisits,
      estimatedVisitsIncludingChildren,
      trafficValue,
      trafficValueIncludingChildren,
      clickThroughValue,
      clickThroughValueIncludingChildren,
      trackedKeywords,
      trackedKeywordsIncludingChildren,
      untrackedKeywords,
      untrackedKeywordsIncludingChildren,
      searchVolume,
      searchVolumeIncludingChildren,
    } = getTreeTableData(rootNode, viewModeId);

    return (
      <>
        {/* URL tab */}
        <Flex className={styles.urlCell} onDoubleClick={handleToggleExpand}>
          <Box />
          <RowIndentations
            hideConnectors={hideConnectors}
            depth={rootNode.depth - 1}
            expandIconWidth="15px"
            className={className}
          />
          {rootNode.children?.length ? (
            <Button
              aria-pressed={isOpen}
              aria-label={isOpen ? t('toggle row closed') : t('toggle row open')}
              leftSection={isOpen ? <MinusIcon /> : <PlusIcon />}
              className={cn(styles.toggleButton, 'connectorElement')}
              onClick={handleToggleExpand}
            />
          ) : (
            <span className="btnPlaceholder" />
          )}
          <Box component="p" ml={6}>
            {fixLeadingSlash(rootNode.name)}
          </Box>
        </Flex>

        {/* SV tab */}

        <Flex align="center" justify="flex-end" style={{ position: 'relative' }}>
          <div
            className={trackedKeywords ? 'competitorIcons' : 'competitorIcons disabled'}
            style={{
              position: 'absolute',
              inset: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              paddingLeft: '4px',
            }}
          >
            <Icon
              tooltip={
                trackedKeywords
                  ? t('Dynamic competitor landing pages available')
                  : t('Dynamic competitor landing pages not available')
              }
              style={{ cursor: 'default' }}
            >
              <CompetitorsIcon />
            </Icon>
          </div>
          <StackedValues
            topValue={searchVolume}
            bottomValue={searchVolumeIncludingChildren}
            hoverTop={hoveredThisPage}
            hoverBottom={hoveredIncludingChildPages}
            includeTop={includeThisPage}
            includeBottom={includeChildPages}
          />
        </Flex>

        {/* EV tab */}
        <StackedValues
          topValue={estimatedVisits}
          bottomValue={estimatedVisitsIncludingChildren}
          hoverTop={hoveredThisPage}
          hoverBottom={hoveredIncludingChildPages}
          includeTop={includeThisPage}
          includeBottom={includeChildPages}
        />

        {/* $$$ tab */}
        <StackedValues
          topValue={trafficValue}
          bottomValue={trafficValueIncludingChildren}
          hoverTop={hoveredThisPage}
          hoverBottom={hoveredIncludingChildPages}
          includeTop={includeThisPage}
          includeBottom={includeChildPages}
        />

        {/* CTR tab */}
        <StackedValues
          topValue={clickThroughValue / 100}
          bottomValue={clickThroughValueIncludingChildren / 100}
          style={'percent'}
          maximumFractionDigits={2}
          hoverTop={hoveredThisPage}
          hoverBottom={hoveredIncludingChildPages}
          includeTop={includeThisPage}
          includeBottom={includeChildPages}
        />

        <StackedValues
          topValue={trackedKeywords}
          bottomValue={trackedKeywordsIncludingChildren}
          hoverTop={hoveredThisPage}
          hoverBottom={hoveredIncludingChildPages}
          includeTop={includeThisPage}
          includeBottom={includeChildPages}
        />
        <StackedValues
          topValue={untrackedKeywords}
          bottomValue={untrackedKeywordsIncludingChildren}
          hoverTop={hoveredThisPage}
          hoverBottom={hoveredIncludingChildPages}
          includeTop={includeThisPage}
          includeBottom={includeChildPages}
        />
      </>
    );
  },
  RowContentIsEqual,
);
RowContent.displayName = 'RowContent';

export const useRowContentComponent = ({
  hoveredThisPage,
  hoveredIncludingChildPages,
  includeThisPage,
  includeChildPages,
  viewModeId,
}: RowContentComponentFactoryProps) =>
  useCallback(
    ({ rootNode, isOpen, handleToggleExpand, className, hideConnectors }: RowContentProps) => (
      <RowContent
        className={className}
        rootNode={rootNode}
        isOpen={isOpen}
        handleToggleExpand={handleToggleExpand}
        hoveredThisPage={hoveredThisPage}
        hoveredIncludingChildPages={hoveredIncludingChildPages}
        includeThisPage={includeThisPage}
        includeChildPages={includeChildPages}
        viewModeId={viewModeId}
        hideConnectors={hideConnectors}
      />
    ),
    [hoveredThisPage, hoveredIncludingChildPages, includeThisPage, includeChildPages, viewModeId],
  );
