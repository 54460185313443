import React, { useContext } from 'react';
import { TableStoreType } from '../TableStore';

export const TableStoreContext = React.createContext<TableStoreType | null>(
  null,
) as React.Context<TableStoreType | null>;

// Return table store for elements that are inside table
export const useContextTableStore = (): TableStoreType | null => {
  return useContext(TableStoreContext);
};
