import { useEffect, useMemo } from 'react';
import type { FieldProps } from 'redux-form';
import DropdownList from 'Components/Controls/DropdownList';
import TextInput from 'Components/Controls/TextInput';
import FormField from 'Components/Forms/FormField';
import useFilterCount from 'Hooks/useFilterCount';
import { FilterComparison, NO_FILTER } from 'Types/Filter';
import { t } from 'Utilities/i18n/index';
import BetweenIcon from 'icons/arrow-both-sides.svg?inline';
import ArrowDown from 'icons/arrow-down.svg?inline';
import ArrowUp from 'icons/arrow-up.svg?inline';
import { getCompareLabels } from '../../Editors/helpers';
import FilterCount from '../FilterCount';
import '../common.scss';

type Props = {
  attribute: FieldProps;
  type: FieldProps;
  value: FieldProps;
  comparison: FieldProps;
  iconDropdown?: boolean;
  noFilterIcon?: Element;
  handleSelect?: (...args: Array<any>) => any;
  autoFocus: boolean;
  isEmpty?: boolean;
  isReversed?: boolean;
};

const InputField = (props: Props) => {
  const {
    attribute,
    type,
    iconDropdown,
    noFilterIcon,
    value,
    comparison,
    handleSelect,
    isEmpty,
    autoFocus,
    isReversed,
  } = props;

  useEffect(() => {
    if (value.input.value === '') {
      value.input.onChange(0);
    }
  }, [value.input.value]);

  const comparisonOptions = useMemo(() => {
    const getLabel = (key: string) => getCompareLabels(key, isReversed);
    const items: any[] = [
      {
        id: isReversed ? FilterComparison.LT : FilterComparison.GT,
        icon: ArrowUp,
      },
      {
        id: isReversed ? FilterComparison.GT : FilterComparison.LT,
        icon: ArrowDown,
      },
      {
        id: isReversed ? FilterComparison.LTE : FilterComparison.GTE,
        icon: ArrowDown,
      },
      {
        id: isReversed ? FilterComparison.GTE : FilterComparison.LTE,
        icon: ArrowDown,
      },
      {
        id: FilterComparison.EQ,
        icon: BetweenIcon,
      },
    ].map((e) => ({
      ...e,
      label: getLabel(e.id)?.selectLabel,
    }));

    if (iconDropdown) {
      items.push({
        id: NO_FILTER,
        label: t('No filter'),
        icon: noFilterIcon,
      });
    }

    return items;
  }, [iconDropdown, noFilterIcon, isReversed]);

  const count = useFilterCount({
    attribute: attribute.input.value,
    value: value.input.value,
    type: type.input.value,
    comparison: comparison.input.value,
  });

  const onComparisonChange = (newComparison: string) => {
    let actualValue = value.input.value;

    if (newComparison === comparison.input.value && !iconDropdown) {
      return;
    }

    comparison.input.onChange(newComparison);

    if (newComparison === FilterComparison.EQ) {
      actualValue = 0;
      value.input.onChange(actualValue);
    } else if (!isNaN(value)) {
      value.input.onChange(actualValue);
    }

    if (iconDropdown && newComparison !== FilterComparison.EQ) {
      actualValue = 1;
      value.input.onChange(actualValue);
    }

    // to submit proper filter after redux form state updated
    setTimeout(() => {
      if (handleSelect) {
        handleSelect({
          value: newComparison,
          reset: newComparison === NO_FILTER,
        });

        if (newComparison !== NO_FILTER) {
          handleSelect({
            value: actualValue,
          });
        }
      }
    });
  };
  const labelFunc = (item: any) => {
    if (iconDropdown) {
      const Icon = !isEmpty ? item.icon : noFilterIcon;
      return <Icon className={`icon ${isEmpty ? 'no-filter' : ''}`} />;
    }

    return item.label || item;
  };
  const onValueChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const val = event.currentTarget.value;
    if (val === '') {
      value.input.onChange('');
      return;
    }
    const numberValue = parseInt(val, 10);
    value.input.onChange(isNaN(numberValue) ? '' : numberValue);
  };

  if (comparisonOptions.filter((e) => e.id === comparison.input.value).length <= 0) {
    // eslint-disable-next-line
    console.error(
      '[NumberChangeEditor/InputField.js] Using a not allowed comparison',
      comparisonOptions,
      props,
    );
  }

  const hasError = value.meta.error;
  const operator = comparison.input.value;
  const rankChange = value.input.value;
  return (
    <FormField meta={value.meta}>
      <div className="number-filter-row">
        <DropdownList
          className="filter-button"
          items={comparisonOptions}
          value={comparison.input.value}
          onChange={onComparisonChange}
          dropDownLabelFunc={labelFunc}
        />
        {!iconDropdown && (
          <TextInput
            autoFocus={autoFocus}
            disabled={operator === FilterComparison.EQ}
            value={isNaN(rankChange) ? '' : rankChange}
            onChange={onValueChange}
            isNumber
            showError={hasError}
            isPositive
          />
        )}
        {!iconDropdown && <FilterCount count={count} />}
      </div>
    </FormField>
  );
};

InputField.defaultProps = {
  iconDropdown: false,
  isReversed: true,
  autoFocus: true,
  handleSelect: () => {},
};

export default InputField;
