import { IconAbc } from '@tabler/icons-react';
import OptionEditor from 'Components/Filters/Common/OptionEditor';
import { oneOfOptions } from 'Components/Filters/Common/labelFunc';
import { FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import type { AlphabetFilter } from 'Types/Filter';
import { t } from 'Utilities/i18n';
import getItems from './getItems';

const defaultValue: AlphabetFilter = {
  attribute: FilterAttribute.ALPHABET,
  type: FilterValueType.STRING,
  comparison: FilterComparison.EQ,
  value: 'Latin',
};

const getData = () => {
  const items = getItems();
  return {
    defaultValue,
    title: t('Alphabet'),
    icon: IconAbc,
    editor: OptionEditor,
    editorProps: {
      items,
      iconDropdown: true,
    },
    tableEditor: OptionEditor,
    tableEditorProps: {
      items,
      iconDropdown: true,
      showLabel: false,
    },
    labelFunc: oneOfOptions(items),
  };
};

export default getData;
