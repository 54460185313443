export const getAllExpanded = (selectedItems: string[]) => {
  if (selectedItems.length === 0) return [];
  const expand = new Set<string>();
  selectedItems.forEach((selected) => {
    const items = selected.split('¦');
    // do not include the last item from path
    items.slice(0, -1).forEach((item, idx) => {
      const path = items.slice(0, idx + 1).join('¦');
      expand.add(path);
    });
  });
  return Array.from(expand);
};
