import { Flex } from '@mantine/core';
import * as Sentry from '@sentry/react';
import AccButton from 'Components/AccButton/AccButton';
import { Field } from 'Components/Fields';
import { Form } from 'Components/Fields/Form/Form';
import FormErrors from 'Components/Forms/FormErrors';
import { useChangeUserPasswordMutation } from 'Ghql';
import toast from 'Hooks/useToast';
import { TrackingKey } from 'Utilities/Analytics/mixpanel';
import { t } from 'Utilities/i18n/index';
import Validator from 'Utilities/validation';

const ChangePasswordPage = () => {
  const [changeUserPasswordMutation] = useChangeUserPasswordMutation();

  const doHandleSubmit = async ({ oldPassword, password }) => {
    const updatePasswordInput = {
      oldPassword,
      password,
    };
    const input = {
      variables: {
        updatePasswordInput,
      },
    };

    try {
      const data = await changeUserPasswordMutation(input);
      const errors = data.data?.updateMyUserPassword?.errors ?? [];
      if (errors?.length) {
        if (errors?.[0]?.field === 'old_password') {
          toast.error(t('Old password is incorrect'));
        } else {
          Sentry.captureException(errors[0]);
          toast.error(t('Failed to change password, please contact support'));
        }
      } else {
        toast.success(t('Password changed'));
      }
    } catch (err) {
      Sentry.captureException(err);
      toast.error(t('Failed to change password, please contact support'));
    }
  };

  return (
    <Form
      onSubmit={doHandleSubmit}
      subscription={{ values: true, submitting: true, invalid: true, pristine: true }}
    >
      {({ values, submitting, invalid, pristine }) => (
        <Flex direction="column" gap="md">
          <FormErrors />
          <Field.Password
            name="oldPassword"
            label={t('Old password')}
            placeholder={t('Enter your old password')}
            required
          />
          <Field.Password
            name="password"
            label={t('New password')}
            placeholder={t('Enter your new password')}
            validate={[
              Validator.required,
              Validator.passwordNumber,
              Validator.passwordLetter,
              Validator.passwordMinLen,
              Validator.passwordMaxLen,
              Validator.passwordPwned,
            ]}
            required
          />
          <Field.Password
            label={t('Confirm new password')}
            name="passwordConfirmation"
            placeholder={t('Confirm your new password')}
            validate={[Validator.required, Validator.passwordsMatch(values.password)]}
            required
          />
          <div className="confirmation-button-wrapper text-right">
            <AccButton
              type="submit"
              variant="primary"
              loading={submitting}
              disabled={pristine || submitting || invalid}
              trackingKey={TrackingKey.ChangePassword}
            >
              {t('Update')}
            </AccButton>
          </div>
        </Flex>
      )}
    </Form>
  );
};

export default ChangePasswordPage;
