/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { hideModal } from 'Actions/ModalAction';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { EventName, TrackingKey, useMixpanel } from 'Utilities/Analytics/mixpanel';
import { t } from 'Utilities/i18n';
import AddEditUserForm from '../AddEditUserForm';
import { useCreateUser } from './useCreateUser';

type Props = {
  refresh: (...args: Array<any>) => any;
};

/**
 * Renders a modal content for adding a new user.
 *
 * @param refresh - A function to refresh the user list after adding a new user.
 * @returns The JSX element representing the modal content for adding a new user.
 */
const AddUser = ({ refresh }: Props) => {
  const trackEvent = useMixpanel();

  useEffect(() => {
    trackEvent(EventName.UsersPage, {
      'Tracking key': TrackingKey.OpenAddModal,
    });
  }, []);

  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(hideModal());
  };

  const handleSubmit = useCreateUser(refresh, onClose);

  return (
    <ModalBorder title={t('Invite New User')} onClose={onClose}>
      <AddEditUserForm onClose={onClose} handleSubmit={handleSubmit} type="add" />
    </ModalBorder>
  );
};

AddUser.defaultProps = {
  refresh: () => {},
};

export default AddUser;
