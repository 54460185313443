import { useRef, useState } from 'react';
import { gql, useApolloClient } from '@apollo/client';
import AccButton from 'Components/AccButton/AccButton';
import { SelectItem } from 'Components/AccSelect';
import { useTableStore } from 'Components/DataTable';
import { Field, Form } from 'Components/Fields';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import { useAutoFocus } from 'Hooks/useAutoFocus';
import useKeyword from 'Hooks/useKeyword';
import toast from 'Hooks/useToast';
import type { FilterBase } from 'Types/Filter';
import { TableIDs } from 'Types/Table';
import { t } from 'Utilities/i18n';
import Validator from 'Utilities/validation';

type Props = {
  keywords: any[];
  domainId: number;
  preferredLandingPageIsSet: boolean;
  isAllSelected: boolean;
  onClose: (...args: Array<any>) => any;
  filters: FilterBase[];
  initialValues: any;
};
const searchUrlsQuery = gql`
  query landingPageForm_searchLandingPages($domains: [ID]!, $query: String!) {
    urls(domains: $domains, query: $query) {
      id
      path
    }
  }
`;

const LandingPageForm = (props: Props) => {
  const tableStore = useTableStore(TableIDs.KEYWORDS);
  const client = useApolloClient();
  const [urls, setUrls] = useState<any[]>([]);
  const { updateKeywordsPreferredLandingPage } = useKeyword();
  const {
    onClose,
    keywords,
    domainId,
    isAllSelected,
    filters,
    preferredLandingPageIsSet,
    initialValues,
  } = props;

  const selectRef = useRef<HTMLInputElement>(null);
  // timeout used to bypass useFocusTrap
  useAutoFocus(selectRef, true, 10);

  const updatePreferredLandingIdInState = (preferredLandingPage) => {
    const preferredLandingPageId = urls?.find((e: any) => e.path === preferredLandingPage)?.id;

    if (!preferredLandingPageId) {
      return;
    }

    if (isAllSelected || keywords?.length > 1) {
      tableStore?.updateSelectedItems((data) => {
        data.preferredLandingPageId = preferredLandingPageId;
      });
    } else if (keywords?.[0]) {
      tableStore?.updateRowData(keywords[0])({
        preferredLandingPageId,
      });
    }
  };

  const handleSubmit = ({ preferredLandingPage }) => {
    // Sometimes preferredLandingPage is sent with preferredLandingPage : {value, label}, other times
    // just as the value, i.e. not an object
    // Fix for https://accuranker.myjetbrains.com/youtrack/agiles/124-5/current?issue=ARR-3001
    const landingPageValue = preferredLandingPage?.value || preferredLandingPage;

    updatePreferredLandingIdInState(landingPageValue);

    return updateKeywordsPreferredLandingPage({
      keywords,
      isAllSelected,
      filters,
      preferredLandingPage: landingPageValue,
    })
      .then(() => {
        onClose();
      })
      .catch(() => {
        onClose();
        toast.error(t('Unable to set landing page'));
      });
  };

  const handleClear = () => {
    return updateKeywordsPreferredLandingPage({
      keywords,
      isAllSelected,
      filters,
      resetPreferredLandingPage: true,
    })
      .then(() => {
        onClose();
      })
      .catch(() => {
        onClose();
        toast.error(t('Unable to set landing page'));
      });
  };

  const optionsLoader = (query?: string): Promise<SelectItem[]> => {
    return client
      .query({
        fetchPolicy: 'network-only',
        query: searchUrlsQuery,
        variables: {
          domains: [domainId],
          query,
        },
      })
      .then(({ data }) => {
        setUrls(data?.urls ?? []);
        const options = data.urls.map(({ path }) => ({
          label: path,
          value: path,
        }));

        return options;
      });
  };

  return (
    <Form
      initialValues={initialValues}
      keepDirtyOnReinitialize
      validateOnBlur={false}
      onSubmit={handleSubmit}
    >
      {({ invalid, submitting }) => (
        <>
          <Field.Select
            label={t('Select Preferred URL')}
            name="preferredLandingPage"
            placeholder={t('Select Preferred URL')}
            loadOptions={optionsLoader}
            validate={Validator.required}
            withinPortal={false}
            clearable
            creatable
            required
            skipAutoCompleteOnChange
            selectRef={selectRef}
          />
          <ModalFooter
            primaryButtonSlot={
              <AccButton
                type="submit"
                variant="primary"
                disabled={invalid || submitting}
                loading={submitting}
              >
                {t('Save')}
              </AccButton>
            }
            secondaryButtonSlot={
              preferredLandingPageIsSet ? (
                <AccButton variant="secondary" onClick={handleClear} disabled={submitting}>
                  {t('Remove preferred URL')}
                </AccButton>
              ) : (
                <AccButton variant="tertiary" onClick={onClose}>
                  {t('Cancel')}
                </AccButton>
              )
            }
          />
        </>
      )}
    </Form>
  );
};

export default LandingPageForm;
