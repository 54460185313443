// eslint-disable-next-line import/no-unresolved
import daTranslations from 'grank-locale/da/djangojs.po';

function translationsExist(language) {
  return language === 'da';
}

export function getTranslations(language) {
  if (translationsExist(language)) {
    return daTranslations;
  }

  return {};
}
