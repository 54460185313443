import { useSelector } from 'react-redux';
import { useCheckoutInfoWidgetGetPricingDetailsQueryQuery } from 'Ghql';
import { StoreType } from 'Types/Store';
import { t } from 'Utilities/i18n';
import Icon from './images/icon-info.png';
import './checkout-info-widget.scss';

type Props = {
  planId: string;
  billingCycleId: number;
};

const UpgradeWarning = () => (
  <div className="checkout-confirm-info">
    <img className="ico-info" src={Icon} alt="" />
    <div className="holder">
      <p>{t('You are upgrading your current plan. Plan upgrades are effective immediately.')}</p>
    </div>
  </div>
);

const DowngradeWarning = () => (
  <div className="checkout-confirm-info">
    <img className="ico-info" src={Icon} alt="" />
    <div className="holder">
      <p>
        {t(
          'You are downgrading your current plan. Plans downgrades are effective from your next payment.',
        )}
      </p>
      <h3>{t('Important')}</h3>
      <p>
        {t(
          'If you utilize more domains, keywords, competitors or users than is available on your new plan on the day of the downgrade, we will not be able to downgrade you and you will be billed for your old plan.',
        )}
      </p>
    </div>
  </div>
);

const useCustomInfoWidgetSelector = () =>
  useSelector((state: StoreType) => {
    return {
      validVat: state?.orderPlan?.vatValid,
      countryId: state?.form?.CompanyInfoForm?.values?.country?.countryCode,
    };
  });

const CheckoutInfoWidget = ({ planId, billingCycleId }: Props) => {
  const formInfo = useCustomInfoWidgetSelector();

  const { data } = useCheckoutInfoWidgetGetPricingDetailsQueryQuery({
    variables: {
      id: planId,
      billingCycle: billingCycleId,
      countryId: formInfo?.countryId,
      validVat: formInfo?.validVat,
    },
  });

  if (data?.pricingPlanCalculated?.upgrade) {
    return <UpgradeWarning />;
  }

  if (data?.pricingPlanCalculated?.downgrade) {
    return <DowngradeWarning />;
  }

  return null;
};

export default CheckoutInfoWidget;
