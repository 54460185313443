import { WithRowContext } from 'Components/DataTable/table-core';
import FormatNumber from 'Components/FormatNumber/new';
import { t } from 'Utilities/i18n';
import Cell from './HelperComponents/AccuCell';
import { AboveTheFold } from './aboveTheFold';
import NaCell from './helpers/NaCell';

type Props = {
  value: {
    data: {
      id: number;
      [data: string]: any;
    };
    loadingData: boolean;
  };
  nDecimals?: number;
} & WithRowContext;

const PixelsFromTopCell = (props: Props): JSX.Element | null => {
  const {
    value: { data },
    nDecimals,
  } = props;
  const { aboveTheFold, pixelsFromTop } = data;

  if (pixelsFromTop === null) {
    return <NaCell tooltip={t('No data for this date.')} align="right" />;
  }

  return (
    <Cell
      className="accu-table-cell__rank u-flex u-flex-row-reverse u-items-center u-justify-between"
      rightAligned={true}
    >
      <FormatNumber
        minimumFractionDigits={nDecimals ?? 1}
        maximumFractionDigits={nDecimals ?? 1}
        value={pixelsFromTop}
      />
      <AboveTheFold value={aboveTheFold} />
    </Cell>
  );
};

// eslint-disable-next-line import/no-unused-modules
export default PixelsFromTopCell;
