import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Flex, Group } from '@mantine/core';
import AccPaper from 'Components/AccPaper';
import AccTitle from 'Components/Title/AccTitle';
import { useGetPublicReportSettingsQuery, usePublicDomainQuery } from 'Ghql';
import { useFilters } from 'Hooks';
import { useSetOrOverwriteFilter } from 'Hooks/data/filter/setFilters';
import { useUser } from 'Hooks/data/user/useUser';
import { FilterAttribute, FilterComparison, FilterValueType, PeriodFilter } from 'Types/Filter';
import { t } from 'Utilities/i18n';
import { redirectToExternalUrl } from 'Utilities/underdash';
import { Header } from '../Header';
import { UserSwitch } from '../UserSwitch';
import { PublicCharts } from '../publicCharts';
import PublicKeywordsReportTable from '../publicKeywordsReportTable';
import { PublicKeywordsSearchField } from '../publicKeywordsReportTable/SearchField';
import { PublicKpiBar } from '../publicKpiBar';
import { PublicReportSettings } from '../publicReportSettings';
import { getCompare, useFiltersWithFallback } from '../support/useFiltersWithFallback';

const PageContent = () => {
  const { isAuthenticated } = useUser();
  const [isAdmin, setIsAdmin] = useState(!!isAuthenticated);

  useEffect(() => {
    setIsAdmin(!!isAuthenticated);
  }, [isAuthenticated]);

  const { domainSlug = '', viewkey = '' } = useParams();

  const setFilter = useSetOrOverwriteFilter();

  const filters = useFilters();

  const { data, loading, error } = useGetPublicReportSettingsQuery({
    variables: {
      viewkey,
      domainSlug,
    },
    skip: !viewkey || !domainSlug,
    onCompleted: (responseData) => {
      if (!filters) {
        return;
      }
      const compare = getCompare(responseData.publicReportSettings?.defaultDelta);

      const newPeriodFilter: PeriodFilter = {
        attribute: FilterAttribute.PERIOD,
        comparison: FilterComparison.BETWEEN,
        type: FilterValueType.DATETIME,
        value: JSON.stringify([compare.from, compare.to]),
      };

      setFilter(newPeriodFilter, 'no tracking');
    },
  });

  const { publicReportSettings } = data || {};

  const { includeAverageRankChart, includeRankingDistributionChart, includeSearchIntentChart } =
    publicReportSettings || {};

  const earliestStartDate = publicReportSettings?.defaultStartDate
    ? new Date(publicReportSettings?.defaultStartDate)
    : undefined;

  const {
    data: publicDomainData,
    loading: publicDomainLoading,
    error: publicDomainError,
  } = usePublicDomainQuery({
    variables: { domainSlug, viewkey },
    skip: !viewkey || !domainSlug,
  });

  const domainInfo = publicDomainData?.publicDomain || undefined;

  const organizationLogo = publicDomainData?.publicDomain?.client?.organization?.logo;

  const { displayName = '', domain = '' } = domainInfo || {};
  const firstRefreshAt = domainInfo?.firstRefreshAt
    ? new Date(domainInfo?.firstRefreshAt)
    : undefined;

  const filtersWithFallback = useFiltersWithFallback(filters, publicReportSettings?.defaultDelta);

  if (!viewkey || !domainSlug || publicDomainError?.message?.includes('Domain not found')) {
    redirectToExternalUrl(`/app/error/400?errors=${encodeURI('public report not found')}`);
  }

  //Show ErrorFallback handled by error boundary
  if (publicDomainError || error) {
    throw new Error(publicDomainError?.message || error?.message);
  }

  const includeCharts =
    includeAverageRankChart || includeRankingDistributionChart || includeSearchIntentChart || false;

  return (
    <>
      <Header
        loading={loading || publicDomainLoading}
        isAdmin={isAdmin}
        domain={domain}
        displayName={displayName}
        organizationLogo={organizationLogo || undefined}
        earliestStartDate={earliestStartDate}
        publicDomainData={publicDomainData || undefined}
        filters={filtersWithFallback}
      />
      {isAuthenticated && <UserSwitch isAdmin={isAdmin} setIsAdmin={setIsAdmin} />}
      <PublicReportSettings
        reportSettingsLoading={loading}
        publicReportSettings={publicReportSettings || undefined}
        isAdmin={isAdmin}
        domainFirstRefresh={firstRefreshAt}
      />
      <PublicKpiBar
        filters={filtersWithFallback}
        domainSlug={domainSlug}
        viewkey={viewkey}
        publicReportSettings={publicReportSettings || undefined}
      />
      {includeCharts && (
        <PublicCharts
          viewkey={viewkey}
          filters={filtersWithFallback}
          domainSlug={domainSlug}
          domainId={domainInfo?.id}
          includeAverageRankChart={includeAverageRankChart || false}
          includeRankingDistributionChart={includeRankingDistributionChart || false}
          includeSearchIntentChart={includeSearchIntentChart || false}
        />
      )}
      <AccPaper>
        <Flex direction="column" gap="sm">
          <Group w="100%" align="center" justify="space-between">
            <AccTitle type="h3">{t('Keywords')}</AccTitle>
            <PublicKeywordsSearchField />
          </Group>
          <PublicKeywordsReportTable
            domainId={publicReportSettings?.id || ''}
            defaultCompareTo={publicReportSettings?.defaultDelta}
            handleLoading={() => {}}
            viewkey={viewkey}
            domainSlug={domainSlug}
            filters={filtersWithFallback}
            includeAdvancedColumns={publicReportSettings?.includeAdvancedTableColumns || false}
            //useAiShareOfVoice={publicReportSettings?.useAiShareOfVoice || false}
          />
        </Flex>
      </AccPaper>
    </>
  );
};

export default PageContent;
