import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import AccButton from 'Components/AccButton/AccButton';
import Alert from 'Components/Alert';
import Hint from 'Components/Hint';
import { ImportColumnNodeError } from 'Ghql';
import { useUser } from 'Hooks/data/user/useUser';
import { t } from 'Utilities/i18n/index';
import { UploadImport, UploadImportColumn } from '../types';
import styles from './validationError.module.scss';

const toColumnErrorsByColumnName = (
  columns: UploadImportColumn[],
): Record<string, ImportColumnNodeError[]> => {
  return columns.reduce((acc, column) => {
    if (isEmpty(column.errors)) {
      return acc;
    }
    acc[column.name] = column.errors;
    return acc;
  }, {});
};

const toPrefix = (lines: string) =>
  (lines === '-1' ? t('All lines') : lines.includes('-') ? t('Lines') : t('Line'));

const allToPrefix = (lines: string[]): string =>
  (lines.length === 1 ? toPrefix(lines[0]) : t('Lines'));

export const ImportValidationStatusError = (props: { uploadImport: UploadImport }) => {
  const { uploadImport } = props;

  const user = useUser();

  const columnErrorsByColumnName = toColumnErrorsByColumnName(uploadImport.columns);

  return (
    <div className={styles.importPageValidationError}>
      {!isEmpty(columnErrorsByColumnName) && (
        <Alert type={'danger'}>
          <b>
            {t(
              'During validation, we found one or more errors in the following column(s). Please fix them and try again. If you are unable to resolve the error(s) please contact our support.',
            )}
          </b>
          <ul>
            {Object.entries(columnErrorsByColumnName).map(([columnName, columnErrors]) => (
              <li key={`column-${columnName}`}>
                <p>{columnName}</p>
                <ul>
                  {columnErrors.map((columnError) => (
                    <li key={`column-${columnName}-${columnError.message}`}>
                      <p>
                        {columnError.message}
                        {columnError.exception &&
                          (user.isAccurankerSuperuser ||
                            user.impersonateOriginUser?.isAccurankerSuperuser) && (
                            <Hint help={columnError.exception} />
                          )}
                      </p>
                      <p>
                        {allToPrefix(columnError.line)}{' '}
                        {columnError.line[0] !== '-1' && columnError.line.join(', ')}
                      </p>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </Alert>
      )}

      {!isEmpty(uploadImport.errors) && (
        <Alert type="danger">
          <b>
            {t(
              'During validation, we found one or more general errors. Please fix them and try again. If you are unable to resolve the error(s) please contact our support.',
            )}
          </b>
          <ul>
            {uploadImport.errors.map((error) => (
              <li key={`general-${error.message}`}>
                <p>
                  {error.message}
                  {error.exception &&
                    (user.isAccurankerSuperuser ||
                      user.impersonateOriginUser?.isAccurankerSuperuser) && (
                      <Hint help={error.exception} />
                    )}
                </p>
                {!isNil(error.line) && (
                  <p>
                    {allToPrefix(error.line)} {error.line && error.line.join(', ')}
                  </p>
                )}
              </li>
            ))}
          </ul>
        </Alert>
      )}

      <AccButton variant="primary" link="/import">
        {t('Back to imports')}
      </AccButton>
    </div>
  );
};
