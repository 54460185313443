import { useMemo } from 'react';
import {
  IconClipboardCopy,
  IconCopy,
  IconHandMove,
  IconPencil,
  IconTag,
} from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import { AccDropdownMenu, DropdownItem } from 'Components/AccDropdownMenu';
import { useCompareToIsLatest } from 'Hooks/data/filter/useFilters';
import { t } from 'Utilities/i18n';
import SettingsIcon from 'icons/menu/settings.svg?inline';
import NoteIcon from 'icons/notes.svg?inline';
import RefreshIcon from 'icons/refresh.svg?inline';
import StarIcon from 'icons/star.svg?inline';
import UrlIcon from 'icons/url.svg?inline';
import './more-actions.scss';

type Props = {
  selectedKeywords: any[];
  isAllSelected: boolean;
  domainId: any;
  totalResults: number;
  totalSelected: number;
  withCount?: boolean;
  // functions for actions
  updateKeywordsRefresh?: (...args: Array<any>) => any;
  showAddNoteModal: (...args: Array<any>) => any;
  showAddTagsModal: (...args: Array<any>) => any;
  updateKeywordsStarred: any;
  showEditKeywordsModal: (...args: Array<any>) => any;
  showLandingPageModal: (...args: Array<any>) => any;
  showDuplicateModal: (...args: Array<any>) => any;
  showMoveKeywordsModal: (...args: Array<any>) => any;
  showCopyToClipboardModal: (...args: Array<any>) => any;
  visible?: boolean;
  /*Render the actions and button with darkTheme colors*/
  darkTheme?: boolean;
};

const MoreActions = (props: Props) => {
  const {
    updateKeywordsRefresh,
    showAddTagsModal,
    showAddNoteModal,
    updateKeywordsStarred,
    showEditKeywordsModal,
    showLandingPageModal,
    showDuplicateModal,
    showMoveKeywordsModal,
    showCopyToClipboardModal,
    totalSelected,
    withCount = true,
    darkTheme = false,
  } = props;

  const isViewingLatestData = useCompareToIsLatest();

  const items: DropdownItem[] = useMemo(
    () => [
      ...(updateKeywordsRefresh
        ? [
            {
              label: t('Refresh'),
              icon: <RefreshIcon className="tabler-icon" />,
              onSelect: updateKeywordsRefresh,
              disabled: !isViewingLatestData,
              tooltip: !isViewingLatestData
                ? t('You can only refresh keywords when viewing the latest data')
                : undefined,
            },
          ]
        : []),
      {
        label: t('Add note'),
        onSelect: showAddNoteModal,
        icon: <NoteIcon />,
      },
      {
        label: t('Add tags'),
        onSelect: showAddTagsModal,
        icon: <IconTag />,
      },
      {
        label: t('Add stars'),
        onSelect: updateKeywordsStarred,
        icon: <StarIcon />,
      },
      {
        label: t('Change settings'),
        onSelect: showEditKeywordsModal,
        icon: <SettingsIcon />,
      },
      {
        label: t('Change preferred URL'),
        onSelect: showLandingPageModal,
        icon: <UrlIcon />,
      },
      {
        label: t('Duplicate'),
        onSelect: showDuplicateModal,
        icon: <IconCopy />,
      },
      {
        label: t('Move to other domain'),
        onSelect: showMoveKeywordsModal,
        icon: <IconHandMove />,
      },
      {
        label: t('Copy to clipboard'),
        onSelect: showCopyToClipboardModal,
        icon: <IconClipboardCopy />,
      },
    ],
    [
      updateKeywordsRefresh,
      isViewingLatestData,
      showAddNoteModal,
      showAddTagsModal,
      updateKeywordsStarred,
      showEditKeywordsModal,
      showLandingPageModal,
      showDuplicateModal,
      showMoveKeywordsModal,
      showCopyToClipboardModal,
    ],
  );

  return (
    <AccDropdownMenu items={items} width={220} darkTheme={darkTheme}>
      <AccButton
        c={`${darkTheme ? 'white' : 'snorlax'}`}
        variant="tertiary"
        leftSection={<IconPencil size={18} />}
      >
        {t('Edit')}
        {withCount && ` (${totalSelected})`}
      </AccButton>
    </AccDropdownMenu>
  );
};

export default MoreActions;
