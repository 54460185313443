import FieldUsers from 'Components/Fields/Field/FieldUsers';
import { allGroupsId } from 'Constants/workspaces';
import { useUpdateRoleOnChange } from './useUpdateRoleOnChange';

type Props = {
  record: Record<string, any>;
};

const UsersCell = ({ record }: Props) => {
  const prefix = `${record.id}_`;
  useUpdateRoleOnChange(record);
  return (
    <FieldUsers
      name={`${prefix}users`}
      size="sm"
      defaultValue={record.users?.map((user) => user.id)}
      searchMinWidth={50}
      isAllGroupsWorkspace={record.id === allGroupsId}
      withinPortal
    />
  );
};

export default UsersCell;
