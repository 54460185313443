import { showModal } from 'Actions/ModalAction';
import { store } from 'Store';

export const showMethodNotAllowedModal = () => {
  store.dispatch(
    showModal({
      modalType: 'NotAllowedModal',
    }),
  );
};
