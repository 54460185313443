import React from 'react';
import { Form as FinalForm, FormProps as FinalFormProps, FormRenderProps } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import isFunction from 'lodash/isFunction';
import { FormError } from './FormError';
import { OnChangeListener } from './OnChangeListener';

export type OnSubmitCallback<T = any> = FinalFormProps<T>['onSubmit'];

type FormProps<Values = any> = {
  onSubmit: OnSubmitCallback<Values>;
  initialValues?: Values;
  subscription: FinalFormProps<Values>['subscription'];
  children:
    | ((props: Omit<FormRenderProps<Values, Partial<Values>>, 'handleSubmit'>) => React.ReactNode)
    | React.ReactNode
    | undefined;
  formClassName?: string;
  keepDirtyOnReinitialize?: boolean;
  validateOnBlur?: boolean;
  // Executed on form values change, used to sync with other stores
  onChange?: (values: Values) => void;
} & Partial<Pick<FinalFormProps<Values>, 'validate'>>;

/**
 * A component that surrounds your entire form and manages the form state.
 * It can inject form state and functionality, e.g. a handleSubmit function
 * for you to pass to your <form> element, via render props.
 * @docs https://final-form.org/docs/react-final-form/api/Form
 */
export const Form = <FormValues extends object>({
  onSubmit,
  subscription,
  initialValues,
  children,
  formClassName,
  keepDirtyOnReinitialize,
  onChange,
  validateOnBlur,
  ...rest
}: FormProps<FormValues>) => {
  return (
    <FinalForm<FormValues>
      onSubmit={onSubmit}
      initialValues={initialValues}
      subscription={subscription}
      keepDirtyOnReinitialize={keepDirtyOnReinitialize}
      validateOnBlur={validateOnBlur}
      {...rest}
      render={({ handleSubmit, ...formProps }) => {
        return (
          <form onSubmit={(values) => handleSubmit(values)} className={formClassName}>
            <>
              {isFunction(children) ? children(formProps) : children}
              {onChange && <OnChangeListener onChange={onChange as (values: unknown) => void} />}
            </>
          </form>
        );
      }}
    />
  );
};

Form.Errors = FormError;

Form.defaultProps = {
  mutators: {
    ...arrayMutators,
  },
  // values: false - used to optimize render and do not re-render on each field update
  subscription: {
    values: false,
    submitting: true,
    submitSucceeded: true,
    submitError: true,
    submitErrors: true,
    submitFailed: true,
    hasSubmitErrors: true,
    pristine: true,
    error: true,
    errors: true,
  },
};
