import type { SwitchEl } from 'Components/Switch/types';
import type { KpiSectionType } from 'Reducers/OverviewPageReducer';

export const SET_STORED_SECTIONS = 'SET_STORED_SECTIONS';
export const HANDLE_SECTION_KPI_CLICK = 'HANDLE_SECTION_KPI_CLICK';
export const ADD_SECTION_KPI = 'ADD_SECTION_KPI';
export const REMOVE_SECTION_KPI = 'REMOVE_SECTION_KPI';
export const TOGGLE_SECTION_KPI_ACTION = 'TOGGLE_SECTION_KPI_ACTION';
export const TOGGLE_SECTION_KPI_COMPETITOR = 'TOGGLE_SECTION_KPI_COMPETITOR';
export const SET_SECTION_TITLE = 'SET_SECTION_TITLE';
export const ADD_EMPTY_SECTION = 'ADD_EMPTY_SECTION';
export const START_REMOVE_SECTION_ANIMATION = 'START_REMOVE_SECTION_ANIMATION';
export const REMOVE_SECTION = 'REMOVE_SECTION';
export const REGISTER_OVERVIEW_COMPONENT = 'registerOverviewComponent';
export const OVERVIEW_COMPONENT_LOADED = 'overviewComponentLoaded';

type KpiType = {
  id: string | number;
};
type KpiHandler = {
  type: string;
  sectionIndex: number;
  kpi: KpiType;
};
type SectionsHandler = {
  type: string;
  sections: Array<KpiSectionType>;
};
type KpiActionHandler = {
  type: string;
  sectionIndex: number;
  el: SwitchEl;
};
type KpiCompetitorHandler = {
  type: string;
  sectionIndex: number;
  id: string;
};

type SectionTitleHandler = {
  type: string;
  sectionIndex: number;
  value: string;
};
type OverviewRegistration = {
  type: string;
  payload: {
    name: string;
  };
};
export type Action =
  | KpiHandler
  | KpiActionHandler
  | OverviewRegistration
  | KpiCompetitorHandler
  | SectionTitleHandler
  | SectionsHandler
  | any;
