import AccessControl from 'Components/AccessControl';
import { useTableStore } from 'Components/DataTable';
import { getDisabledDemoText } from 'Constants/messages';
import * as Actions from 'Pages/Layout/ActionsMenu/Actions';
import ActionbarContainer from 'Pages/Layout/ActionsMenu/components/ActionbarContainer';
import { DomainInfo } from 'Query';
import { t } from 'Utilities/i18n';
import { useModal } from '../../../../Hooks/base/useModal';
import { TableIDs } from '../../../../Types/Table';

interface CompetitorActionMenuProps {
  domainInfo?: DomainInfo;
  domainId?: string | null;
}

const CompetitorActionMenu = (props: CompetitorActionMenuProps) => {
  const tableStore = useTableStore(TableIDs.COMPETITORS);
  const { isDemoDomain } = props.domainInfo || {};

  const { showModal } = useModal();

  const handleAddAction = () =>
    showModal({
      modalType: 'BuildCompetitor',
      modalTheme: 'light',
      modalProps: {
        domainId: props.domainId,
        refresh: () => {
          tableStore?.getData();
        },
      },
    });
  return (
    <ActionbarContainer>
      <AccessControl>
        <Actions.AddAction
          disabled={isDemoDomain}
          tooltip={isDemoDomain ? getDisabledDemoText() : undefined}
          key="add"
          label={t('Add competitor')}
          onClick={handleAddAction}
        />
        <Actions.UpgradeAction key="upgrade-plan" alignRight={true} />
      </AccessControl>
    </ActionbarContainer>
  );
};

export default CompetitorActionMenu;
