import OptionEditor from 'Components/Filters/Common/OptionEditor';
import { matchingPageComparisonLabelFunc } from 'Components/Filters/Common/labelFunc';
import { FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import type { HighestRankingPageMatchFilter } from 'Types/Filter';
import { t } from 'Utilities/i18n/index';
import CheckIcon from 'icons/check-1.svg?inline';
import DashedCircleIcon from 'icons/circle-dashed.svg?inline';
import CircleIcon from 'icons/circle.svg?inline';
import Icon from 'icons/link.svg?inline';
import XIcon from 'icons/x.svg?inline';

const defaultValue: HighestRankingPageMatchFilter = {
  attribute: FilterAttribute.HIGHEST_RANKING_PAGE_MATCH,
  type: FilterValueType.STRING,
  comparison: FilterComparison.EQ,
  value: '',
};

const getData = () => {
  const items = [
    {
      value: FilterComparison.EQ,
      label: t('Correct URL'),
      icon: () => <CheckIcon className="url-status status-up" />,
    },
    {
      value: FilterComparison.NE,
      label: t('Incorrect URL'),
      icon: () => <XIcon className="url-status status-down" />,
    },
    {
      value: FilterComparison.NOT_CONTAINS,
      label: t('Preferred URL not set'),
      icon: () => <DashedCircleIcon className="url-status status-unknown" />,
    },
  ];
  return {
    defaultValue,
    title: t('Preferred URL match'),
    icon: Icon,
    editor: OptionEditor,
    editorProps: {
      items,
      name: 'comparison',
      iconDropdown: true,
    },
    tableEditor: OptionEditor,
    tableEditorProps: {
      items,
      name: 'comparison',
      iconDropdown: true,
      noFilterIcon: CircleIcon,
    },
    labelFunc: matchingPageComparisonLabelFunc,
  };
};

export default getData;
