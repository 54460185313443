import { ROOT_ID } from '../constants';
import { FOLDER_SEPARATOR, getAddNodeItem } from '../helpers';
import { addTreeInfo } from './useGroupData';

export function useOnAdd(
  onCancelAdd: () => void,
  clearEditModes: () => void,
  setTreeNested: (value: ((prevState: any[]) => any[]) | any[]) => void,
  targetId: any,
  setIsDirty: (isDirty: boolean) => void,
) {
  return (folderName) => {
    clearEditModes();
    setIsDirty(true);

    const nextItem = {
      ...getAddNodeItem(folderName),
      parent: targetId,
      id: targetId === ROOT_ID ? folderName : targetId + FOLDER_SEPARATOR + folderName,
      isAdd: false,
      addTime: new Date().getTime(),
    };
    setTreeNested?.((tree) => addTreeInfo(tree, targetId, nextItem));
    setTimeout(() => onCancelAdd(), 0);
  };
}
