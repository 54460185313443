import React, { createContext, useContext, useState } from 'react';
import {
  NodeModel,
  PlaceholderState,
} from 'Pages/Keywords/Groupings/EditMode/react-dnd-treeview/types';
import { hideLine } from '../../components/TargetLine';

export const PlaceholderContext = createContext<PlaceholderState>({} as PlaceholderState);
export const usePlaceholderContext = () => useContext(PlaceholderContext);
const initialState = {
  dropTargetId: undefined,
  index: undefined,
  direction: undefined,
};

export const PlaceholderProvider: React.FC<{ children: React.ReactNode }> = (props) => {
  const [dropTargetId, setDropTargetId] = useState<PlaceholderState['dropTargetId']>(
    initialState.dropTargetId,
  );
  const [direction, setDirection] = useState<PlaceholderState['direction']>(initialState.direction);
  const [index, setIndex] = useState<PlaceholderState['index']>(initialState.index);

  const showPlaceholder = (
    dropId: NodeModel['id'],
    dropIndex: number,
    directionParam: PlaceholderState['direction'],
  ): void => {
    setDropTargetId(dropId);
    setIndex(dropIndex);
    setDirection(directionParam);
  };

  const hidePlaceholder = () => {
    hideLine();
    setDropTargetId(initialState.dropTargetId);
    setIndex(initialState.index);
  };

  return (
    <PlaceholderContext.Provider
      value={{
        dropTargetId,
        direction,
        index,
        showPlaceholder,
        hidePlaceholder,
      }}
    >
      {props.children}
    </PlaceholderContext.Provider>
  );
};
