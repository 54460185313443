import React from 'react';
import FilterEditorForm from 'Components/Filters/FilterEditorForm';
import getFilterData from 'Components/Filters/getFilterData';
import type { FiltersEditorProps } from 'Components/HOC/withFiltersEditor';
import withFiltersEditor from 'Components/HOC/withFiltersEditor';
import { FilterBase, getFilterByAttribute } from 'Types/Filter';
import { EventName, FilterTrackingKey, useMixpanel } from 'Utilities/Analytics/mixpanel';
import { checkForFilterRegexErrorReduxForm } from 'Utilities/validation';

type FilterCellProps = {
  filterAttribute: string;
  onSetFilter?: (arg: boolean) => void;
  onCancel?: () => void;
} & FiltersEditorProps;

const FilterCellComponent = React.memo((props: FilterCellProps) => {
  const { filtersEditor, filterAttribute, filterGroup, onCancel: _onCancel, onSetFilter } = props;
  const filter = getFilterByAttribute(filterAttribute, filterGroup);
  const trackEvent = useMixpanel();

  const onUpdate = (updatedFilter: FilterBase, isNew: boolean) => {
    checkForFilterRegexErrorReduxForm(updatedFilter);

    if (isNew) {
      filtersEditor.addFilter(updatedFilter);
      trackEvent(EventName.AddFilter, {
        Attribute: `${updatedFilter.attribute}`,
        Type: updatedFilter.type,
        Comparison: updatedFilter.comparison,
        Value: `${updatedFilter.value}`,
        'Tracking Key': FilterTrackingKey.TableHeader,
      });
    } else {
      filter && filtersEditor.updateFilters(updatedFilter, filter);
    }

    onSetFilter && onSetFilter(true);
  };

  const onCancel = () => {
    return _onCancel?.();
  };

  const onDelete = () => {
    filtersEditor.removeFilter(filterAttribute, filter);
    onCancel();
  };

  const renderEditForm = () => (
    <FilterEditorForm
      form={`filter-editor-form-${filterAttribute}`}
      isOpen={true}
      isNew={false}
      onSubmit={(updatedFilter: FilterBase) => {
        onUpdate(updatedFilter, false);
      }}
      filter={filter}
      initialValues={filter}
      onCancel={onCancel}
      onDelete={onDelete}
    />
  );

  const renderAddForm = () => {
    const _filter = getFilterData(filterAttribute)?.defaultValue;
    return (
      <FilterEditorForm
        form={`filter-editor-form-${filterAttribute}`}
        isOpen={true}
        isNew={true}
        onSubmit={(updatedFilter: FilterBase) => {
          onUpdate(updatedFilter, true);
        }}
        filter={_filter}
        initialValues={_filter}
        onCancel={onCancel}
        onDelete={onDelete}
      />
    );
  };

  return filter ? renderEditForm() : renderAddForm();
});
FilterCellComponent.displayName = 'FilterCell';

const FilterCell = withFiltersEditor(FilterCellComponent);

export default FilterCell;
