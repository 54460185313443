import { useEffect, useRef } from 'react';
import { DatePickerInput, DateTimePicker } from '@mantine/dates';
import moment from 'moment';
import styleVariables from 'css/base/variables.module.scss';

type Props = {
  value: Date | null;
  onChange: (...args: Array<any>) => any;
  showError?: boolean;
  disabled?: boolean;
  className?: string;
  showTimeSelect?: boolean;
  autoFocus?: boolean;
  withinPortal?: boolean;
};

const DatePicker = ({
  value = null,
  onChange = () => {},
  showError,
  disabled,
  showTimeSelect,
  autoFocus,
  withinPortal = false,
}: Props) => {
  const datePickerRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (autoFocus) {
      datePickerRef.current?.click();
    }
  }, []);

  const handleChange = (currentMoment: any) => {
    onChange(moment(currentMoment).toDate());
  };

  const format = showTimeSelect ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD';
  const pickerProps = {
    showError,
    valueFormat: format,
    //make sure form fields do not pass an empty string as value
    value: value || null,
    radius: 4,
    disabled,
    ref: datePickerRef,
    onChange: handleChange,
    size: 'md' as const,
    popoverProps: { zIndex: Number(styleVariables.zindexPopover), withinPortal },
  };
  return showTimeSelect ? (
    <DateTimePicker {...pickerProps} />
  ) : (
    <DatePickerInput {...pickerProps} />
  );
};

export default DatePicker;
