import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router';
import { Params, ScrollRestoration, useLocation } from 'react-router-dom';
import config from 'config';
import isEqual from 'lodash/isEqual';
import startsWith from 'lodash/startsWith';
import { v4 as uuidv4 } from 'uuid';
import { devError } from '../../log';

const AdvancedLocationProvider = React.createContext<{
  subscribe: any;
  initPathRef: any;
  initParamsRef: any;
  navigateRef: any;
}>({
  subscribe: null,
} as any);

const LocationSubscription = React.memo(({ onUpdate }: any) => {
  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    onUpdate(location.pathname, params);
  }, [location.pathname, params]);

  return null;
});

LocationSubscription.displayName = 'LocationSubscription';

const getPath = () => {
  let resultPath = window.location.pathname;

  if (config.basename) {
    resultPath = resultPath.replace(config.basename, '');
  }

  return startsWith(resultPath, '/') ? resultPath : '/'.concat(resultPath);
};

export const AdvancedRouterWrapper = ({ children }: PropsWithChildren<any>) => {
  const subscriptionsRef = useRef<Array<{ fn: Function; id: string }>>([]);
  const navigate = useNavigate();
  const navigateRef = useRef(navigate);

  const subscribe = (fn: Function) => {
    const id = uuidv4();
    subscriptionsRef.current.push({ fn, id });
    return () => {
      if (!subscriptionsRef.current.find((e) => e.id === id)) {
        devError('Failed to unsubscribe');
      }

      subscriptionsRef.current = subscriptionsRef.current.filter((e) => e.id !== id);
    };
  };

  const locationPath = useRef<string>(getPath());
  const params = useParams();
  const paramsRef = useRef<Params>(params);
  const context = useMemo(
    () => ({
      subscribe,
      initPathRef: locationPath,
      initParamsRef: paramsRef,
      navigateRef,
    }),
    [locationPath, paramsRef],
  );

  const onUpdate = useCallback((nextLocationPathname: string, nextParams: Params) => {
    if (
      !isEqual(locationPath.current, nextLocationPathname) ||
      !isEqual(paramsRef.current, nextParams)
    ) {
      locationPath.current = nextLocationPathname;
      paramsRef.current = nextParams;
      subscriptionsRef.current.forEach((el) => el?.fn?.(nextLocationPathname, nextParams));
    }
  }, []);

  return (
    <AdvancedLocationProvider.Provider value={context}>
      <LocationSubscription onUpdate={onUpdate} />
      <ScrollRestoration />
      {children}
    </AdvancedLocationProvider.Provider>
  );
};

export const useStableNavigate = (): NavigateFunction => {
  const { navigateRef } = useContext(AdvancedLocationProvider);
  if (navigateRef.current === null) {
    throw new Error('StableNavigate context is not initialized');
  }

  return navigateRef.current;
};

/**
 *
 * This hook listen to and `pathname`, `params` change and re-render only when returned value from callback changed.
 * It was implemented to avoid re-renders, since ReactRouter's `useLocation` re-render on every route change.
 *
 * Example: if your tab active for few pages, you provide function `path => ['v1', 'v2'].includes(path)` and our hook
 *          will re-render only if returned value changed, so that for v1 -> v2 page switch no re-renders.
 *
 * @example:
 * ```tsx
 * // get current pathname, won't re-render if params changed
 * const pathname = useSmartRouterSubscription((pathname) => pathname);
 * // get filter value from params
 * const pathname = useSmartRouterSubscription((,{filter}) => filter);
 * ```
 */
export const useSmartRouterSubscription = <T,>(
  _calc: (pathname: string, params: Params, init?: boolean) => T,
): T => {
  const calc = useMemo(() => _calc, []);
  const { subscribe } = useContext(AdvancedLocationProvider);

  const [initVal] = useState(calc(getPath(), useParams(), true));
  const valRef = useRef(initVal);
  const [, rerender] = useState(0);

  useEffect(() => {
    const unSubscribe = subscribe?.((pathName: string, params: Params) => {
      const next = calc(pathName, params);

      if (!isEqual(valRef.current, next)) {
        valRef.current = next;
        rerender((v) => v + 1);
      }
    });
    return () => {
      unSubscribe?.();
    };
  }, []);

  return valRef.current;
};
