import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import {
  selectMetaData,
  selectMetaDataCountrylocales,
  selectMetaDataSerpFeatures,
} from 'Selectors/Keywords';

export const useSerpFeatures = () => useSelector(selectMetaDataSerpFeatures, isEqual);
export const useMetaData = () => useSelector(selectMetaData, isEqual);

export const useMetaDataCountrylocales = () => useSelector(selectMetaDataCountrylocales, isEqual);
