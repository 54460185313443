import { useCallback, useMemo } from 'react';
import { TableFetchDataCallbackParams } from 'Components/DataTable/types';
import {
  ReportsPublicReportSettingsDefaultDeltaChoices,
  useGetPublicTableKeywordsLazyQuery,
} from 'Ghql';
import { useGetFilters } from 'Hooks/data/filter/useFilters';
import { DEFAULT_KEYWORD_ORDERING } from 'Pages/Keywords/Table/hooks/keyword/constants';
import { DomainsFilter, SearchIntent } from 'Types/Filter';
import { notEmpty } from 'Utilities/underdash';
import { BasicColumnIDs } from './ColumnIDs';
import { useFiltersWithFallback } from './useFiltersWithFallback';

export type PublicTableKeyword = {
  countrylocaleId: number;
  displayName: string;
  domain: string;
  domainId: number;
  id: number;
  keyword: string;
  location: string;
  noSearchVolumeForLocation: boolean;
  rankValue: number;
  rankCompare: number | null;
  searchDate: string;
  searchDatetime: string;
  searchEngineId: number;
  searchIntent: SearchIntent[];
  searchTypeId: number;
  updatingKeyword: boolean;
  searchVolumeValue: null;
};

export const useFetchPublicKeywords = ({
  viewkey,
  domainSlug,
  domainId,
  handleLoading,
  //useAiShareOfVoice,
  includeAdvancedColumns,
  defaultCompareTo,
}: {
  viewkey: string;
  domainSlug: string;
  domainId: string;
  handleLoading: (isLoading: boolean) => void;
  //useAiShareOfVoice: boolean;
  includeAdvancedColumns: boolean;
  defaultCompareTo: ReportsPublicReportSettingsDefaultDeltaChoices | undefined;
}) => {
  const domainsFilter: DomainsFilter = useMemo(
    () => ({
      attribute: 'domains',
      type: 'list',
      comparison: 'contains',
      value: [domainId],
    }),
    [domainId],
  );

  //TODO: filter withSearchVolumeValue && withAiSearchVolume depending on useAiShareOfVoice
  const [getKeywords] = useGetPublicTableKeywordsLazyQuery();
  const getFilters = useGetFilters();
  const filtersWithFallback = useFiltersWithFallback(getFilters(), defaultCompareTo);
  return useCallback(
    async ({ ordering, pagination, force }: TableFetchDataCallbackParams) => {
      /** Make sure we do not order using advanced columns when not including these in the table */
      const basicColumns = Object.values(BasicColumnIDs) as string[];
      const validatedOrdering =
        includeAdvancedColumns || basicColumns.includes(ordering.orderBy)
          ? ordering
          : DEFAULT_KEYWORD_ORDERING;

      /** Make sure we construct filters with fallback at request time */
      try {
        handleLoading(true);
        const response = await getKeywords({
          variables: {
            filters: [...filtersWithFallback, domainsFilter],
            pagination,
            ordering: validatedOrdering,
            viewkey,
            slug: domainSlug,
            withSearchEngineId: true,
            withSearchTypeId: true,
            withSearchIntent: includeAdvancedColumns,
            withCountrylocaleId: true,
            withLocation: true,
            withRankId: true,
            withRankValue: true,
            withRankCompare: true,
            withSearchVolumeValue: true,
            withValidForSearchVolume: true,
            //withAiSearchVolume: true,
            withDynamicCtr: includeAdvancedColumns,
          },
          fetchPolicy: force ? 'network-only' : 'cache-first',
        });
        const keywords: PublicTableKeyword[] =
          response?.data?.publicTableKeywords?.keywords?.filter(notEmpty) || [];
        const totalNumberOfKeywords = response?.data?.publicTableKeywords?.pagination?.numResults;
        return { data: keywords, length: totalNumberOfKeywords || 0 };
      } catch (error: unknown) {
        if (error instanceof Error) {
          console.error('Error fetching keywords for public report:', error.message);
        }
        return { data: [], length: 0 };
      } finally {
        handleLoading(false);
      }
    },
    [
      includeAdvancedColumns,
      handleLoading,
      getKeywords,
      domainsFilter,
      domainSlug,
      viewkey,
      getFilters,
      JSON.stringify(filtersWithFallback),
    ],
  );
};
