import { useSelector } from 'react-redux';
import { useEditGroupMutation } from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import toast from 'Hooks/useToast';
import { selectOrganizationId } from 'Selectors/OrganizationSelector';
import { t } from 'Utilities/i18n';
import GroupModalView, { GroupFormValues } from '../GroupModalView';

type Props = {
  groupId: string;
  refetch: (...args: Array<any>) => any;
  initialValues?: GroupFormValues;
};

const EditGroup = (props: Props) => {
  const { refetch, groupId, initialValues } = props;
  const organizationId = useSelector(selectOrganizationId);
  const { hideModal } = useModal();
  const [editGroup] = useEditGroupMutation();

  const handleSubmit = (values: GroupFormValues) => {
    const input = {
      id: groupId,
      name: values.groupName,
      organization: organizationId as string,
      delete: false,
    };
    if (!organizationId) {
      toast.error(t('Request failed'));
      return;
    }
    return editGroup({
      variables: {
        input,
      },
    })
      .then(() => {
        refetch();
      })
      .catch(() => {
        toast.error(t('Failed to edit group'));
      })
      .finally(hideModal);
  };

  return (
    <GroupModalView
      title={t('Edit Group')}
      hideModal={hideModal}
      handleSubmit={handleSubmit}
      initialValues={initialValues}
    />
  );
};

export default EditGroup;
