import { ApolloQueryResult } from '@apollo/client';
import { GroupsListQuery } from 'Ghql';
import { sleep } from 'Utilities/promise';

const MAX_TRIES = 5;
const WAIT_TIME = 500;

type Props = {
  id: string;
  result: ApolloQueryResult<GroupsListQuery>;
  tries: number;
  refetch: () => Promise<ApolloQueryResult<GroupsListQuery>>;
  handleSetGroup?: (id: number) => void;
};
export const onAddGroupCallback = async ({ id, result, tries, refetch, handleSetGroup }: Props) => {
  const clientId = parseInt(id, 10);
  const { clientsRaw } = result?.data ?? {};
  if (clientsRaw?.some((client) => client.clientId === clientId)) {
    handleSetGroup?.(clientId);
  } else if (tries < MAX_TRIES) {
    await sleep(WAIT_TIME);
    const refetchData = await refetch();
    await onAddGroupCallback({
      id,
      result: refetchData,
      tries: tries + 1,
      refetch,
      handleSetGroup,
    });
  }
};
