import { memo } from 'react';
import {
  RankingDistribution,
  RankingDistributionProps,
} from 'Pages/Keywords/Overview/components/HistoryCharts/RankingDistribution/RankingDistributionChart';
import { WithDragDetails } from 'Pages/Keywords/Overview/support/types';

// eslint-disable-next-line import/no-unused-modules
export const BaseRankingDistribution = memo(
  ({
    domainId,
    notes,
    onNotesSelect,
    notesLoading,
    enabledHistoryCharts,
    ...props
  }: RankingDistributionProps & WithDragDetails) => {
    return (
      <RankingDistribution
        domainId={domainId}
        notes={notes}
        onNotesSelect={onNotesSelect}
        notesLoading={notesLoading}
        enabledHistoryCharts={enabledHistoryCharts}
        isBaseRankingDistribution={true}
        {...props}
      />
    );
  },
);
BaseRankingDistribution.displayName = 'BaseRankingDistribution';
