import { useCallback } from 'react';
import useOfflineFilter from 'Components/DataTable/hooks/offline/useOfflineFilter';
import { TableFetchDataCallBack } from 'Components/DataTable/types';

export const useFetchWithOfflineFilter = (
  fetchData: TableFetchDataCallBack,
  prepareData: ReturnType<typeof useOfflineFilter>,
): TableFetchDataCallBack => {
  return useCallback(
    (options) => {
      if (prepareData) {
        return fetchData(options).then((e) =>
          prepareData(
            e.data,
            { sortField: options.ordering?.orderBy, sortOrder: options.ordering?.order },
            options.pagination,
          ),
        );
      }
      return fetchData(options);
    },
    [fetchData, prepareData],
  );
};
