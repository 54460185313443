import React from 'react';
import { shallowEqual } from 'react-redux';

/**
 * This component is used to prevent rerendering of the children component
 */
export const FreezeRerender = React.memo(
  ({ item }: any) => {
    return <>{item || null}</>;
  },
  (prevProps, nextProps) => {
    return nextProps.freeze || shallowEqual(prevProps, nextProps);
  },
);

FreezeRerender.displayName = 'FreezeRerender';
