import { Component } from 'react';
import { connect } from 'react-redux';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import compose from 'lodash/flowRight';
import { Field, reduxForm } from 'redux-form';
import { hideModal } from 'Actions/ModalAction';
import AccButton from 'Components/AccButton/AccButton';
import { TextField } from 'Components/Forms/Fields';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import toast from 'Hooks/useToast';
import { t } from 'Utilities/i18n/index';
import Validator from 'Utilities/validation';

type Props = {
  accountId: string;
  hideModal: () => void;
  updateGoogleConnection: (...args: Array<any>) => any;
  googleConnection: Record<string, any>;
  handleSubmit: (...args: Array<any>) => any;
  submitting: boolean;
  refresh?: () => void;
  accountName: string;
};

class EditAccount extends Component<Props> {
  onSubmit = ({ description }) => {
    const id = this.props.accountId;
    return this.props
      .updateGoogleConnection({
        variables: {
          input: {
            id,
            description,
            delete: false,
          },
        },
      })
      .then(
        ({
          data: {
            updateGoogleConnection: { errors },
          },
        }) => {
          if (errors && errors.length) {
            toast.error(t('Failed to update'));
            Validator.throwSubmissionError(errors);
          } else {
            this.props.hideModal();
            this.props.refresh && this.props.refresh();
            this.props.googleConnection.refetch();
          }
        },
        () => {
          toast.error(t('Failed to update'));
        },
      );
  };

  render() {
    const { handleSubmit, submitting, accountName } = this.props;
    return (
      <ModalBorder
        className="edit-account"
        onClose={submitting ? this.props.hideModal : undefined}
        title={t('Edit %s Account', accountName)}
      >
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <div className="form-label">{t('Description')}</div>
          <Field
            name="description"
            placeholder={t('Description')}
            component={TextField}
            disabled={submitting}
            validate={Validator.required}
            autoFocus={true}
          />
          <ModalFooter
            primaryButtonSlot={
              <AccButton variant="primary" disabled={submitting} type="submit">
                {t('Edit connection')}
              </AccButton>
            }
            secondaryButtonSlot={
              <AccButton variant="tertiary" disabled={submitting} onClick={this.props.hideModal}>
                {t('Cancel')}
              </AccButton>
            }
          />
        </form>
      </ModalBorder>
    );
  }
}

const googleConnectionQuery = gql`
  query editAccount_googleConnections($id: ID!) {
    googleConnection(id: $id) {
      description
    }
  }
`;
const updateGoogleConnectionQuery = gql`
  mutation editAccount_updateGoogleConnection($input: UpdateGoogleConnectionInput!) {
    updateGoogleConnection(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;
export default compose(
  graphql(googleConnectionQuery, {
    name: 'googleConnection',
    options: (props: Props) => {
      const id = props.accountId;
      return {
        variables: {
          id,
        },
        skip: !id,
      } as any;
    },
    props: ({ ownProps, googleConnection }: any) => {
      const description =
        googleConnection.loading || googleConnection.error
          ? ''
          : googleConnection.googleConnection.description;
      return {
        ...ownProps,
        initialValues: {
          description,
        },
        googleConnection,
      };
    },
  }),
  graphql(updateGoogleConnectionQuery, {
    name: 'updateGoogleConnection',
  }),
  connect(null, {
    hideModal,
  }),
)(
  reduxForm({
    form: 'EditAccountForm',
    enableReinitialize: true,
  })(EditAccount),
);
