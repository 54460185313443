import { Box } from '@mantine/core';
import { KeywordLandingChart } from './components/KeywordLandingChart/KeywordLandingChart';
import { KeywordLandingTable } from './components/KeywordLandingTable/KeywordLandingTable';

export const LandingPage = () => {
  return (
    <Box mt="xl">
      <KeywordLandingChart />
      <KeywordLandingTable />
    </Box>
  );
};
