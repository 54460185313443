import { ApolloError } from '@apollo/client';
import { useFilterOptionsQuery } from 'Ghql';
import { useFilters } from 'Hooks';
import { FilterAttribute } from 'Types/Filter';
import getIsKeyDis from 'Utilities/getKeyDis';
import { getItems } from './getItems';

type SearchItemType = {
  value: string;
  label: string;
  Icon: () => JSX.Element;
  count?: number;
};

export const useSearchIntentData = (): {
  data: SearchItemType[];
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const items = getItems();
  const filters = useFilters();
  const {
    data: optionsData,
    loading: optionsLoading,
    error: optionsError,
  } = useFilterOptionsQuery({
    fetchPolicy: 'network-only',
    variables: {
      filters: filters.filter((f) => f.attribute !== FilterAttribute.SEARCH_INTENT),
      isKeydis: getIsKeyDis(),
      filterType: FilterAttribute.SEARCH_INTENT,
    },
  });
  const filterOptions = optionsData?.filterData?.filterOptions;
  const options = optionsData?.filterData?.filterOptions === null ? null : filterOptions || [];
  let data = items;
  if (!optionsLoading && !optionsError && options !== null) {
    data = items.reduce((accumulator: SearchItemType[], item) => {
      const count = options.find((o) => o.option === item.value)?.count || 0;
      accumulator.push({
        ...item,
        count,
      });
      return accumulator;
    }, []);
  }

  return { data, loading: optionsLoading, error: optionsError };
};
