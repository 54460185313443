import AdvancedPlanUpsellChart from 'Components/AdvancedPlanUpsellChart';
import DropdownList from 'Components/Controls/DropdownList';
import Overlay from 'Components/Overlay';
import { ChartMetricProps } from '../support/types';
import styles from '../compare-chart.module.scss';

interface Props extends ChartMetricProps {
  showFake?: boolean;
}

export const CompareChartDataContainer: React.FC<React.PropsWithChildren<Props>> = (props) => {
  return (
    <Overlay
      collapsed={props.superCellCollapsed}
      onTop={
        <AdvancedPlanUpsellChart
          collapsed={props.superCellCollapsed}
          onCollapse={props.toggleCollapse}
        />
      }
      isEnabled={props.showFake}
    >
      <div key="select" className={styles.graphSelect}>
        <DropdownList
          items={props.metricsOptions}
          placeholder={props.metricPlaceholder}
          onChange={props.setMetric}
        />
      </div>
      {props.children}
    </Overlay>
  );
};
