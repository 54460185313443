import { makeAutoObservable, runInAction } from 'mobx';

export class OptimizationStore {
  constructor() {
    makeAutoObservable(this);
  }

  from: number = 0;
  to: number = 0;

  setRange = (from: number, to: number) => {
    if (from === this.from && to === this.to) {
      return false;
    }
    runInAction(() => {
      this.from = from;
      this.to = to;
    });
    return true;
  };
}

export type OptimizationStoreType = InstanceType<typeof OptimizationStore>;
