import { GetDomainInfoQuery } from 'Ghql';

export const getDefaultDomainSettings = (domainInfo?: GetDomainInfoQuery) => {
  if (!domainInfo) return undefined;

  const defaultDomainSettings = domainInfo?.domain?.defaultSearchSettings?.map((setting) => {
    const {
      countrylocale,
      locations,
      searchEngines,
      enableAutocorrect,
      ignoreFeaturedSnippet,
      ignoreLocalResults,
      primary,
    } = setting || {};
    return {
      countrylocale: countrylocale?.id || '',
      locations: locations || [],
      primary,
      searchEngines: searchEngines?.map((s) => ({
        id: s?.searchEngine?.id,
        searchTypes: s?.searchTypes,
        name: s?.searchEngine?.name?.toLocaleLowerCase(),
      })),
      settings: {
        enableAutocorrect,
        ignoreFeaturedSnippet,
        ignoreLocalResults,
      },
    };
  });

  return defaultDomainSettings?.length ? defaultDomainSettings : [];
};
