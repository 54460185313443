import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { LineChart } from 'Components/Chart/LineChart';
import { TooltipConfiguration, chartTooltipTypes } from 'Components/Chart/LineChart/support/types';
import { OverviewChartSeries } from 'Pages/Keywords/Overview/components/HistoryCharts/constants';

type Props = {
  series: OverviewChartSeries[];
  title: string;
  loading: boolean;
  color?: string;
  isRank?: boolean;
  /** SparklineMode will render the chart without x- and y-labels or gridlines  */
  sparklineMode?: boolean;
};
const Chart = ({
  series,
  loading,
  color = '#cad6f7',
  title,
  isRank,
  sparklineMode = false,
}: Props) => {
  const intl = useIntl();

  const tooltipConfig: TooltipConfiguration = {
    type: chartTooltipTypes.STOCK,
    title,
    showPercentage: false,
    customNumFormatter: (num: number) =>
      intl?.formatNumber(num, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        style: 'decimal',
      }),
  };

  const extraOptions = useMemo(
    () => ({
      plotOptions: {
        areaspline: {
          fillColor: color,
        },
      },
      navigator: {
        enabled: false,
      },
      scrollbar: {
        enabled: false,
      },
    }),
    [color],
  );

  return (
    <LineChart
      isRank={isRank}
      series={series}
      isLoading={loading}
      tooltip={tooltipConfig}
      extraOptions={extraOptions}
      chartType="area"
      loaderType="line"
      disableLegend
      exportButton={false}
      height={130}
      hideNotes
      sparklineMode={sparklineMode}
    />
  );
};

export default Chart;
