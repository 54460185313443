import React, { useEffect } from 'react';
import { Kbd } from '@mantine/core';
import upperFirst from 'lodash/upperFirst';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { useModal } from 'Hooks/base/useModal';
import { useIsAccurankerUser } from 'Hooks/data/organization/useOrganizationInfo';
import { t } from 'Utilities/i18n';
import Mousetrap from 'Utilities/mousetrap';
import { isMac, notEmpty } from 'Utilities/underdash';
import './shortcuts.scss';

type Shortcut = {
  keyCombo: string;
  description: string;
};

const getQuickNavigateCombo = (): string => 'Shift+/|d';
export const getAllFiltersCombo = (): string => (isMac() ? 'Shift+Cmd+F' : 'Shift+Ctrl+F');
const getCopyToClipboardCombo = (): string => (isMac() ? 'Shift+Cmd+O' : 'Shift+Ctrl+O');
export const getSearchCombo = (): string => (isMac() ? 'Cmd+F' : 'Ctrl+F');
export const getKeywordFiltersCombo = (): string => (isMac() ? 'Cmd+F' : 'Ctrl+F');

const getShortcuts = (isAccurankerUser: boolean): Shortcut[] =>
  [
    {
      keyCombo: 'Esc',
      description: t('Close opened menu'),
    },
    {
      keyCombo: getQuickNavigateCombo(),
      description: t('Open quick navigation'),
    },
    {
      keyCombo: getKeywordFiltersCombo(),
      description: t('Open keywords or domains filter'),
    },
    {
      keyCombo: getAllFiltersCombo(),
      description: t('Open all filters'),
    },
    ...(isAccurankerUser
      ? [
          {
            keyCombo: getCopyToClipboardCombo(),
            description: t('Open copy to clipboard modal'),
          },
        ]
      : []),
  ].filter(notEmpty);

const transformKey = (key: string): string => {
  switch (key) {
    case 'up':
      return '↑';

    case 'down':
      return '↓';

    case 'right':
      return '→';

    case 'left':
      return '←';

    case 'plus':
      return '+';

    default:
      if (key.length > 1) {
        return upperFirst(key);
      }

      return key;
  }
};

export const formatKeys = (keyCombo: string): JSX.Element[] => {
  const parts = keyCombo.split(/(\+| |\|)/);
  return parts.map((key, i) => {
    const uniq = `${key}-${i}`;
    if (key === ' ' || key === '+') {
      return <React.Fragment key={uniq}>{key}</React.Fragment>;
    }
    if (key === '|') {
      return <React.Fragment key={uniq}> {t('or')} </React.Fragment>;
    }
    return <Kbd key={uniq}>{transformKey(key)}</Kbd>;
  });
};

const Shortcuts = () => {
  const isAccurankerUser = useIsAccurankerUser();
  const { hideModal } = useModal();
  useEffect(() => {
    Mousetrap.pause();
    return () => {
      Mousetrap.unpause();
    };
  }, []);

  const ShortcutRow = ({ keyCombo, description }: Shortcut) => (
    <tr className="keybind">
      <td className="key">
        <span>{formatKeys(keyCombo)}</span>
      </td>
      <td>{description}</td>
    </tr>
  );

  return (
    <ModalBorder className="keybinds-modal" title={t('Keyboard Shortcuts')} onClose={hideModal}>
      <table className="data-table">
        <thead>
          <tr>
            <td>{t('Shortcut')}</td>
            <td>{t('Description')}</td>
          </tr>
        </thead>
        <tbody>
          {getShortcuts(isAccurankerUser).map((sc) => (
            <ShortcutRow key={sc.keyCombo} keyCombo={sc.keyCombo} description={sc.description} />
          ))}
        </tbody>
      </table>
    </ModalBorder>
  );
};

export default Shortcuts;
