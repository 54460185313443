import { useCallback, useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { IntercomContextValues } from 'react-use-intercom';
import { IconX } from '@tabler/icons-react';
import { t } from 'Utilities/i18n';
import styles from './intercom-button.module.scss';

type UseInsertIntercomCloseButtonProps = Pick<IntercomContextValues, 'hide' | 'isOpen'> & {
  isBooted: boolean;
};

/** Add closebutton in the upper right corner of the Intercom chat
 *
 * @example
 * useInsertIntercomCloseButton({ hide, isOpen, isBooted: bootRef.current });
 */
export const useInsertIntercomCloseButton = ({
  hide,
  isBooted,
  isOpen,
}: UseInsertIntercomCloseButtonProps) => {
  const [closeButtonRoot, setCloseButtonRoot] = useState<HTMLElement | null | undefined>(null);

  const insertCloseButton = useCallback(() => {
    const intercomframe: HTMLDivElement | null = document.querySelector(
      'div.intercom-messenger-frame',
    );
    if (intercomframe) {
      const closeButtonFrame = document.createElement('div');
      closeButtonFrame.id = 'intercom-close-button';
      closeButtonFrame.className = styles.closeButtonRoot;
      intercomframe.insertAdjacentElement('afterbegin', closeButtonFrame);
      setCloseButtonRoot(closeButtonFrame);
    }
  }, []);

  const root = closeButtonRoot ? createRoot(closeButtonRoot) : undefined;

  root?.render(
    <button className={styles.closeButton} onClick={hide} aria-label={t('close the chat')}>
      <IconX size={20} />
    </button>,
  );

  //insert custom close button in the upper right corner of the chat after Intercom is opened the first time
  useEffect(() => {
    if (isBooted && isOpen && !closeButtonRoot) {
      setTimeout(() => {
        insertCloseButton();
      }, 500);
    }
  }, [isOpen, closeButtonRoot, insertCloseButton, isBooted]);
};
