/* eslint-disable import/no-unused-modules */
// should be equal $table-prefix from styles.scss
export const TABLE_CLS_PREFIX = 'rc-table';

export const PAGE_BOTTOM_INDENT = 270;
export const MIN_TABLE_HEIGHT = 200;

// min number of not primary columns required to enable optimization
export const ENABLE_OPTIMIZATION_MIN_COLS = 3;

export enum TableSize {
  DEFAULT = 'default',
  CARD = 'card',
  TREE = 'tree',
  COMFORTABLE = 'comfortable',
  COMPACT = 'compact',
}

export { QueryOrder } from 'Constants';

// strategies of handling selecting rows in the table
export enum SelectRowType {
  // selection reset on page change, *used on keywords page
  ONE_PAGE_SELECT = 'one-page-select',
  // selection stored for all pages, *used on keydis page
  MULTI_PAGE_SELECT = 'multi-page-select',
}
