import Cell from 'Components/AccuTable/CellRenderer/HelperComponents/AccuCell';
import ValueIndicator from 'Components/ValueIndicator/value-indicator';
import { useIsPercentage } from 'Hooks/data/domain/useQueryDomainInfo';

interface Props {
  record: {
    currentValuePercentage?: number | null;
    comparePercentage?: number | null;
    currentValue?: number | null;
    compare?: number | null;
  };
}

export const CombinedChangeCell = ({ record }: Props) => {
  const isPercentage = useIsPercentage();

  return (
    <Cell>
      <ValueIndicator
        beforeValue={isPercentage ? (record.comparePercentage ?? 0) / 100 : record.compare || null}
        afterValue={
          isPercentage ? (record.currentValuePercentage ?? 0) / 100 : record.currentValue || null
        }
        style={isPercentage ? 'percent' : 'decimal'}
      />
    </Cell>
  );
};

export const PercentageChangeCell = ({ record }: Props) => (
  <Cell rightAligned>
    <ValueIndicator
      beforeValue={(record.comparePercentage ?? 0) / 100}
      afterValue={(record.currentValuePercentage ?? 0) / 100}
      style={'percent'}
    />
  </Cell>
);
