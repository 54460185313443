import React, { Fragment, useState } from 'react';
import IntersectionVisible from 'react-intersection-visible';
import { Box, Group } from '@mantine/core';
import { IconGripVertical } from '@tabler/icons-react';
import cn from 'classnames';
import AccPaper from 'Components/AccPaper';
import AccTitle from 'Components/Title/AccTitle';
import { useChartBoxContext } from 'Pages/Keywords/Overview/components/ChartBoxContext/index';
import { getHistoryChartCsvData } from 'Pages/Keywords/Overview/components/HistoryCharts/AverageRank/AverageRankChart';
import KebabMenu from 'Pages/Keywords/Overview/components/KebabMenu/index';
import { WithDragDetails } from 'Pages/Keywords/Overview/support/types';
import styles from './container-header.module.scss';

interface ChartContainerHeaderProps extends WithDragDetails {
  children: React.ReactNode;
  chartRef?: React.RefObject<unknown>;
  chartSeries?: unknown[];
  title: string;
  tooltip?: (JSX.Element | string)[];
  addCompetitor?: boolean;
  style?: React.CSSProperties;
  draggable?: boolean;
  refetch?: () => void;
  getCsvData?: any;
  kebabWrapper?: (_: any) => JSX.Element;
  minHeight?: number;
  includeKebabMenu?: boolean;
}

export const ChartContainer = ({
  addCompetitor,
  draggable = true,
  minHeight,
  includeKebabMenu = true,
  ...props
}: ChartContainerHeaderProps) => {
  const alwaysVisible = useChartBoxContext();
  const [visible, setVisible] = useState(alwaysVisible);
  const Wrapper = alwaysVisible ? React.Fragment : IntersectionVisible;

  const wrapperProps = alwaysVisible ? {} : { onShow: () => setVisible(true) };
  const KebabWrapper = props.kebabWrapper || React.Fragment;

  const helperText = (
    <>
      {props.tooltip?.map((e, i) => {
        const isLast = i === (props.tooltip?.length || 0) - 1;

        return (
          <Fragment key={typeof e === 'string' ? e : i}>
            {e}
            {!isLast && (
              <>
                <br /> <br />
              </>
            )}
          </Fragment>
        );
      })}
    </>
  );

  return (
    <Wrapper {...wrapperProps}>
      <Box pos="relative" mih={minHeight}>
        <AccPaper style={props.style} px="lg" py="md" className={styles.container}>
          <Group
            justify="space-between"
            className={cn(styles.headerContainer, {
              [styles.draggable]: !draggable ? false : !props.boardControls?.disabledDnd,
              [styles.dragActive]: !!props.boardControls?.isDragging,
            })}
            {...props.dragRef}
          >
            <div>
              <div className={styles.dragIcon}>
                <IconGripVertical width={20} height={20} color="#6b6c6c" />
              </div>
              <AccTitle type="h3" helper={helperText} iconProps={{ 'data-no-dnd': 'true' }}>
                {props.title}
              </AccTitle>
            </div>
            {includeKebabMenu && (
              <KebabWrapper>
                <KebabMenu
                  title={props.title}
                  pngContent={props.chartRef?.current ? () => props.chartRef?.current : null}
                  getCsvData={
                    props.getCsvData
                      ? props.getCsvData(props.chartSeries)
                      : props.chartSeries && getHistoryChartCsvData(props.chartSeries, props.title)
                  }
                  boardControls={props.boardControls}
                  addCompetitor={addCompetitor}
                  refetch={props.refetch}
                />
              </KebabWrapper>
            )}
          </Group>
          {visible ? props.children : true}
        </AccPaper>
      </Box>
    </Wrapper>
  );
};
