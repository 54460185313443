import React, { useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { Box } from '@mantine/core';
import { LineChart, NotesType } from 'Components/Chart/LineChart';
import { chartTooltipTypes } from 'Components/Chart/LineChart/support/types';
import linkToKeywordListFromChart from 'Components/Filters/LinkToKeywordListFromChart';
import { useHistoryChartsQuery } from 'Ghql';
import { useFilters } from 'Hooks';
import { MissingDataOverlay } from 'Pages/Keywords/Overview/MissingDataOverlay';
import { ChartContainer } from 'Pages/Keywords/Overview/components/ChartContainer/index';
import { getAverageBaseRankSeries } from 'Pages/Keywords/Overview/components/HistoryCharts/AverageBaseRank/helpers';
import { useFakeData } from 'Pages/Keywords/Overview/hooks/useFakeData';
import { useGroupViewInfo } from 'Pages/Keywords/Overview/hooks/useGroupViewInfo';
import { WithDragDetails } from 'Pages/Keywords/Overview/support/types';
import { FilterAttribute, FilterComparison } from 'Types/Filter';
import { colorScheme } from 'Utilities/colors';
import { t } from 'Utilities/i18n';
import { useAddKeywordsModal } from '../support/util';

type AvgRankChartProps = {
  domainId: string | undefined;
  notes?: NotesType[];
  notesLoading?: boolean;
  enabledHistoryCharts: string[];
} & WithDragDetails;

export const AverageBaseRankChart = (props: AvgRankChartProps) => {
  const intl = useIntl();
  const filters = useFilters();
  const { isEmptyGroup, isGroupView } = useGroupViewInfo(filters);
  const { AvgRankChartFakeData } = useFakeData();
  const chartRef = useRef<HTMLDivElement>(null);
  const { data, loading, refetch } = useHistoryChartsQuery({
    variables: { filters, name: 'historyCharts', charts: props.enabledHistoryCharts },
    skip: isEmptyGroup,
  });
  const addKeywordsModal = useAddKeywordsModal(props.domainId, refetch);
  const navigate = useNavigate();
  const chartSeries = getAverageBaseRankSeries(data?.graphs?.overviewHistoryGraphs);

  const extraOptions = useMemo(
    () => ({
      navigator: {
        series: {
          reversed: true,
          lineColor: colorScheme.graphs.stats.averageBaseRank,
        },
      },
      plotOptions: {
        areaspline: {
          fillColor: `${colorScheme.graphs.stats.averageBaseRank}4D`,
        },
        series: {
          point: {
            events: {
              click: (event) => {
                if (event.point.category) {
                  navigate(
                    linkToKeywordListFromChart(
                      event.point.category,
                      FilterAttribute.RANK,
                      FilterComparison.LTE,
                      100,
                    ),
                  );
                }
              },
            },
          },
        },
      },
    }),
    [],
  );

  const filtersAdded = filters.length > 3;
  const showFakeData = !filtersAdded && !data?.graphs?.overviewHistoryGraphs?.length;

  return (
    <ChartContainer
      title={t('Average Base Rank')}
      tooltip={[
        t(
          'The graph displays the average base rank over time for all keywords in the current filtering. Only keywords with ranks in the top 100 are included in the calculation.',
        ),
      ]}
      dragRef={props.dragRef}
      boardControls={props.boardControls}
      chartRef={chartRef}
      chartSeries={chartSeries}
    >
      <Box pos="relative" ref={chartRef}>
        {!loading && showFakeData && (
          <MissingDataOverlay
            type="missingKeywords"
            onClick={addKeywordsModal}
            isGroupView={isGroupView}
            isEmptyGroup={isEmptyGroup}
          />
        )}
        <LineChart
          isRank
          series={showFakeData ? AvgRankChartFakeData : chartSeries}
          tooltip={{
            type: chartTooltipTypes.STOCK,
            title: t('Average base rank'),
            showPercentage: false,
            bottomText: t('Click to see ranking keywords'),
            customNumFormatter: (num) =>
              intl?.formatNumber(num, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
                style: 'decimal',
              }),
          }}
          extraOptions={extraOptions}
          enableRangeSelector
          chartType="area"
          isLoading={props.dragging || loading || props.notesLoading}
          notes={props.notes}
          loaderType="line"
          disableLegend
          exportButton={false}
        />
      </Box>
    </ChartContainer>
  );
};
