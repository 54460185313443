import { Alert, Box } from '@mantine/core';
import { IconAlertTriangle } from '@tabler/icons-react';
import AccText from 'Components/Text/AccText';
import { t } from 'Utilities/i18n';
import { useOrganizationInfo } from '../../Selectors/OrganizationSelector';
import styles from './invoice-bar.module.scss';

const Late = 1;
const VeryLate = 2;

const UnpaidInvoiceBar = () => {
  const { isTrial, invoiceStatus } = useOrganizationInfo();
  const show = !isTrial && invoiceStatus === Late;

  if (!show) return null;

  return (
    <div className={styles.invoiceBar}>
      <AccText className={styles.invoiceBarText} variant="label">
        {t(
          'Action required! Your latest invoice is overdue. Please ensure payment is processed immediately.',
        )}
      </AccText>
    </div>
  );
};

export const UnpaidInvoiceAlert = () => {
  const { isTrial, invoiceStatus } = useOrganizationInfo();
  const show = !isTrial && invoiceStatus === VeryLate;
  if (!show) return null;

  return (
    <Alert
      icon={
        <Box my={-20}>
          <IconAlertTriangle size="1.5rem" />
        </Box>
      }
      title="Missing payment"
      color="orange"
      my={'lg'}
    >
      {t(
        'Action required! Your payment is overdue. Please ensure payment is made immediately to avoid losing access and data.',
      )}
    </Alert>
  );
};

export default UnpaidInvoiceBar;
