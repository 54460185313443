import { Group } from '@mantine/core';
import Cell from 'Components/AccuTable/CellRenderer/HelperComponents/AccuCell';
import ArrowDown from 'icons/arrow-down.svg?inline';
import ArrowUp from 'icons/arrow-up.svg?inline';

type Diff = {
  value: string;
  diff: number;
};
type JustifyProps = {
  justify?: 'flex-end';
};
type DatePickerCellRendererProps = {
  content: string | Diff;
} & JustifyProps;

const Arrow = ({ diff }: { diff: number }) => {
  if (diff > 0) {
    return <ArrowUp width={'10px'} height={'10px'} fill={'green'} />;
  }
  if (diff < 0) {
    return <ArrowDown width={'10px'} height={'10px'} fill={'red'} />;
  }
  return <span />;
};

const DiffCell = ({ value, diff, justify }: Diff & JustifyProps) => (
  <Group justify={justify ?? 'space-between'}>
    <Arrow diff={diff} />
    <span>{value}</span>
  </Group>
);

/**
 * For the "% Difference" and "# Difference" rows in the Neighborly tables
 */
const DiffCellRenderer = ({ content, justify }: DatePickerCellRendererProps) => {
  if (typeof content === 'object' && 'diff' in content) {
    return <DiffCell value={content.value} diff={content.diff} justify={justify} />;
  }
  return <Cell rightAligned>{content}</Cell>;
};

export default DiffCellRenderer;
