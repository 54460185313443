import { Field } from 'redux-form';
import { KeywordsListSelectInputField } from 'Components/Forms/Fields';
import {
  FilterAttribute,
  FilterComparison,
  FilterValueType,
  MultipleKeywordFilter,
} from 'Types/Filter';
import { t } from 'Utilities/i18n';
import IconListSearch from 'icons/multiple_keywords_filter.svg?inline';

const defaultValue: MultipleKeywordFilter = {
  attribute: FilterAttribute.MULTIPLE_KEYWORDS,
  type: FilterValueType.ARRAY,
  comparison: FilterComparison.ANY,
  value: [],
};

const KeywordListFieldEditor = () => {
  return <Field name="value" component={KeywordsListSelectInputField} />;
};

const labelFunc = (filter: MultipleKeywordFilter) => {
  let displayValue: string;
  if (filter.value.length > 3) {
    displayValue = `'${filter.value.slice(1, 3).join('\', \'')}',...`;
  } else {
    displayValue = `'${filter.value.join('\', \'')}'`;
  }

  return t('Keyword is one of ') + displayValue;
};

const getData = () => ({
  defaultValue,
  title: t('Multiple Keywords'),
  icon: IconListSearch,
  editor: KeywordListFieldEditor,
  editorProps: {
    placeholder: t('Enter the keyword'),
  },
  tableEditor: KeywordListFieldEditor,
  tableEditorProps: {
    placeholder: t('Multiple Keywords'),
  },
  labelFunc,
});

export default getData;
