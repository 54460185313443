import { MIN_TABLE_HEIGHT, PAGE_BOTTOM_INDENT, TABLE_CLS_PREFIX } from './constants';

export const tableClassName = (cls?: string): string => {
  return cls ? `${TABLE_CLS_PREFIX}-${cls}` : '';
};

/**
 * Set table min height full page size minus indent from bottom/top
 */
export const getDefaultTableHeight = (el?: HTMLElement) => {
  const top = el?.getBoundingClientRect()?.y ?? 0;
  const result = window.innerHeight - top - PAGE_BOTTOM_INDENT;
  return Math.max(result, MIN_TABLE_HEIGHT);
};
