import { DataTable } from 'Components/DataTable';
import { ReportsPublicReportSettingsDefaultDeltaChoices } from 'Ghql';
import { DEFAULT_KEYWORD_ORDERING } from 'Pages/Keywords/Table/hooks/keyword/constants';
import { useTableSubscription } from 'Pages/Keywords/Table/support/useTableSubscription';
import { FilterAttribute, FilterBase } from 'Types/Filter';
import { TableIDs } from 'Types/Table';
import { t } from 'Utilities/i18n';
import { ExpandedRankRow } from '../support/ExpandedRankRow';
import { useFetchPublicKeywords } from '../support/useFetchPublicKeywords';
import { usePublicTableColumns } from '../support/usePublicTableColumns';

const TABLE_NAME = TableIDs.PUBLIC_KEYWORDS_REPORT_TABLE;

const PublicKeywordsReportTable = ({
  viewkey,
  domainSlug,
  domainId,
  includeAdvancedColumns,
  //useAiShareOfVoice,
  handleLoading,
  filters,
  defaultCompareTo,
}: {
  viewkey: string;
  domainSlug: string;
  domainId: string;
  includeAdvancedColumns: boolean;
  //useAiShareOfVoice: boolean;
  handleLoading: (isLoading: boolean) => void;
  filters: FilterBase[];
  defaultCompareTo: ReportsPublicReportSettingsDefaultDeltaChoices | undefined;
}): JSX.Element => {
  useTableSubscription(TABLE_NAME, {
    subscribeTo: 'domain',
    subscribeToArg: domainId,
  });

  const hasPeriodFilter = filters.some((filter) => filter.attribute === FilterAttribute.PERIOD);

  const fetchData = useFetchPublicKeywords({
    viewkey,
    domainSlug,
    domainId,
    includeAdvancedColumns,
    //useAiShareOfVoice,
    handleLoading,
    defaultCompareTo,
  });

  const columns = usePublicTableColumns({ includeAdvancedColumns });

  return (
    <DataTable
      skip={!hasPeriodFilter}
      tableId={TABLE_NAME}
      fetchData={fetchData}
      columns={columns}
      emptyOptions={{
        title: t('No Keywords for this domain'),
        subTitle: t('There are currently no keywords added to this domain.'),
      }}
      defaultOrdering={DEFAULT_KEYWORD_ORDERING}
      customExpandComponent={ExpandedRankRow}
      pageSize={50}
      key={JSON.stringify(filters)}
    />
  );
};

export default PublicKeywordsReportTable;
