import { Group } from '@mantine/core';
import { Flag } from 'Components/Flag';
import Skeleton from 'Components/Skeleton';
import { useGetCountryLocaleQuery } from 'Ghql';

type Props = {
  localeId: number;
};

const CountryLocaleLabel = (props: Props) => {
  const { data, loading, error } = useGetCountryLocaleQuery({
    variables: {
      ids: [props.localeId.toString()],
    },
  });
  const countrylocale = data?.countrylocales?.[0];
  if (loading || error) {
    return (
      <Skeleton
        linesConfig={[
          {
            type: 'text',
            options: {
              width: '120px',
              marginBottom: '10px',
            },
          },
        ]}
      />
    );
  }
  if (!countrylocale) return null;
  return (
    <Group wrap="nowrap" gap={4}>
      <Flag size="sm" country={countrylocale.countryCode} />
      <span>
        {countrylocale.region} - {countrylocale.locale}
      </span>
    </Group>
  );
};

export default CountryLocaleLabel;
