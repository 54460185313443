import { gql } from '@apollo/client';

export const LIVE_CHANGES_DOMAIN_QUERY = gql`
  subscription subscribeLiveChangesDomain($id: ID!) {
    liveChangesDomain(id: $id) {
      id
      obj
      action
    }
  }
`;

export const UPDATE_KEYWORD_FRAGMENT = gql`
  fragment UpdateKeywordCache on TableKeywordNode {
    id
    aboveTheFold
    analyticsPotential
    analyticsVisitors
    avgCostPerClick
    competition
    countrylocaleId
    dateAdded
    description
    displayName
    domain
    domainId
    enableAutocorrect
    extraRanks
    hasExtraRanks
    highestRankingPage
    historyUrl
    ignoreFeaturedSnippet
    ignoreInShareOfVoice
    ignoreLocalResults
    keyword
    keywordDescription
    location
    preferredLandingPageId
    rankCompare
    rankId
    rankValue
    searchDate
    searchEngineId
    searchTypeId
    searchVolumeValue
    serpAdsTop
    serpAdsInBetween
    serpAdsBottom
    serpApps
    serpCarousel
    serpDirectAnswer
    serpDiscoverMorePlaces
    serpEvents
    serpFaq
    serpFaqOwned
    serpFeatureSnippet
    serpFeatureSnippetOwned
    serpFeaturedVideo
    serpFeaturedVideoOwned
    serpFlights
    serpHotelsPack
    serpImagePack
    serpJobPosting
    serpKnowledgeCards
    serpKnowledgePanel
    serpKnowledgePanelOwned
    serpKnowledgePanelPriceComparison
    serpKnowledgePanelPriceComparisonOwned
    serpMapsLocal
    serpMapsLocalOwned
    serpMapsLocalTeaser
    serpMapsLocalTeaserOwned
    serpMostPopularCarousel
    serpNewsPosition
    serpNewsPositionOwned
    serpPodcasts
    serpAiOverview
    serpRecipesPosition
    serpRecipesPositionOwned
    serpRefineCarousel
    serpRelatedQuestions
    serpRelatedQuestionsOwned
    serpRelatedSearchesCarousel
    serpResearchProductPosition
    serpResearchProductPositionOwned
    serpResultsAbout
    serpReviews
    serpReviewsOwned
    serpSearchByPhotos
    serpShopping
    serpSiteLinks
    serpSiteLinksOwned
    serpSiteLinksSearchbox
    serpSiteLinksSearchboxOwned
    serpThingsTodo
    serpThumbnails
    serpThumbnailsOwned
    serpTripsPopular
    serpTweets
    serpTweetsOwned
    serpVideo
    serpVideoCarouselPosition
    serpVideoCarouselPositionOwned
    serpVideoOwned
    serpYoutubeAds
    serpYoutubeChannel
    serpYoutubeChannelOwned
    serpYoutubeLiveStream
    serpYoutubeLiveStreamOwned
    serpYoutubeMovie
    serpYoutubeMovieOwned
    serpYoutubePlaylist
    serpYoutubePlaylistOwned
    serpYoutubePromotedVideo
    serpYoutubePromotedVideoOwned
    serpYoutubeRadio
    serpYoutubeRadioOwned
    serpYoutubeRelatedMovies
    serpYoutubeRelatedSearches
    serpYoutubeRelatedToYourSearch
    serpYoutubeSecondarySearchContainer
    serpYoutubeShelfRender
    serpYoutubeShow
    serpYoutubeShowOwned
    serpYoutubeVideo
    serpYoutubeVideoOwned
    serpRichProductInfo
    serpRichProductInfoOwned
    serpFilterMenu
    serpOrganicStores
    serpOrganicStoresOwned
    serpPopularStores
    serpPopularStoresOwned
    serpHotelsPosition
    serpHotelsPositionOwned
    serpForumPostPosition
    serpForumPostPositionOwned
    serpImageProductPackPosition
    serpImageProductPackPositionOwned
    serpShopStyles
    serpShopStylesOwned
    shareOfVoiceCompare
    shareOfVoiceCpcCompare
    shareOfVoiceCpcValue
    shareOfVoiceIsPercentage
    shareOfVoicePercentageValue
    shareOfVoicePercentageValueCompare
    shareOfVoiceValue
    starred
    tags
    title
    organicTraffic
    organicTrafficCompare
    updatingKeyword
  }
`;
