import { useEffect } from 'react';
import { subscribeToDomain } from 'Utilities/websocket';

export const useDomainSubscription = (refetchData: () => void) => {
  useEffect(() => {
    const subscription = subscribeToDomain(() => {
      refetchData();
      setTimeout(refetchData, 3000);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [refetchData]);
};
