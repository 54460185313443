import { useMemo } from 'react';
import { ApolloError } from '@apollo/client';
import { Flex } from '@mantine/core';
import cn from 'classnames';
import AccButton from 'Components/AccButton/AccButton';
import { Field, Form } from 'Components/Fields';
import FieldUserRole from 'Components/Fields/Field/FieldUserRole';
import FieldWorkspace from 'Components/Fields/Field/FieldWorkspace';
import { FormErrors } from 'Components/Forms/FormErrors';
import HelpTextPopover from 'Components/HelpTextPopover/HelpTextPopover';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import AddEditSkeleton from 'Components/Skeleton/Types/AddEdit';
import AccText from 'Components/Text/AccText';
import { UserRole, defaultRole } from 'Constants/workspaces';
import { t } from 'Utilities/i18n/index';
import Validator from 'Utilities/validation';
import formStyles from 'css/layout/form-layout.module.scss';

export type UserInitialValues = {
  fullName?: string | null;
  email?: string | null;
  userType?: UserRole;
  workspaces?: string[] | null;
};

type Props = {
  onClose: (...args: Array<any>) => any;
  initialValues?: UserInitialValues;
  handleSubmit: (...args: Array<any>) => any;
  loading?: boolean;
  error?: ApolloError;
  type?: 'add' | 'edit';
  disableUserRole?: boolean;
};

const AddEditUserForm = (props: Props) => {
  const { type, onClose, handleSubmit, loading, error, initialValues } = props;
  const workspaces = initialValues?.workspaces?.map(String);
  const values = useMemo(
    () => ({
      fullName: initialValues?.fullName,
      email: initialValues?.email,
      userType: initialValues?.userType || defaultRole,
      workspaces,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      initialValues?.email,
      initialValues?.fullName,
      initialValues?.userType,
      workspaces?.toString(),
    ],
  );

  if (loading) {
    return <AddEditSkeleton />;
  } else if (error) {
    return <AccText>{t('Error loading user data. Try again later')}</AccText>;
  }

  return (
    <>
      {type === 'add' && (
        <p>
          {t('When you add a new user an email will be sent to the new user.')}
          <br />
          {t(
            'To activate the account please make sure that the new user clicks the activate link in the email.',
          )}
        </p>
      )}
      <Form
        onSubmit={handleSubmit}
        initialValues={values}
        subscription={{ values: true, submitting: true, invalid: true }}
      >
        {({ invalid, submitting, submitErrors, values: formValues, form }) => {
          // validation fails on empty arrays.
          if (Array.isArray(formValues.workspaces) && !formValues.workspaces.length) {
            form.change('workspaces', undefined);
          }
          return (
            <>
              <Flex direction="column" rowGap="lg">
                <FormErrors errors={submitErrors} />
                <Field.TextInput
                  name="fullName"
                  label={t('Full Name')}
                  placeholder={t('Enter full name')}
                  validate={Validator.required}
                  autoFocus={true}
                  required
                />
                <Field.TextInput
                  name="email"
                  type="email"
                  label={t('Email')}
                  placeholder={t('Enter email address')}
                  validate={[Validator.required, Validator.email]}
                  required
                />
                <div className={cn(formStyles.formRow, formStyles.formRowNoMargin)}>
                  <div className="form-label">
                    {t('User role')}
                    <HelpTextPopover text={t('Select one of the user roles for the user')} />
                  </div>

                  <FieldUserRole
                    name="userType"
                    disabled={props.disableUserRole}
                    defaultValue={initialValues?.userType ?? defaultRole}
                  />
                </div>
                <div className={cn(formStyles.formRow, formStyles.formRowNoMargin)}>
                  <div className="form-label">
                    {t('Workspaces')} <div className="required-star">{'*'}</div>
                    <HelpTextPopover
                      text={t(
                        'Select one of the workspaces for the user. "All Groups" gives access to every group.',
                      )}
                    />
                  </div>
                  <FieldWorkspace
                    name="workspaces"
                    userRoleFieldName="userType"
                    defaultUserRole={initialValues?.userType ?? defaultRole}
                    defaultValue={workspaces}
                    maxDisplayedValues={4}
                    showError={!!form.getFieldState('workspaces')?.error}
                    required
                  />
                </div>
              </Flex>
              <ModalFooter
                primaryButtonSlot={
                  <AccButton disabled={invalid || submitting} type="submit" variant="primary">
                    {t('Save')}
                  </AccButton>
                }
                secondaryButtonSlot={
                  <AccButton variant="tertiary" onClick={onClose}>
                    {t('Cancel')}
                  </AccButton>
                }
              />
            </>
          );
        }}
      </Form>
    </>
  );
};

AddEditUserForm.defaultProps = {
  initialValues: {
    fullName: null,
    email: null,
    userType: defaultRole,
    workspaces: undefined,
  },
  type: 'add',
};

export default AddEditUserForm;
