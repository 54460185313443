import { ApolloError } from '@apollo/client';
import { Box, Flex } from '@mantine/core';
import isEmpty from 'lodash/isEmpty';
import { FilterBase } from 'Types/Filter';
import { t } from 'Utilities/i18n';
import { SiteMapMode, SitemapNode, TreeViewMode } from '../support/types';
import SiteMappingRightColumn from './Right';
import SitemapTree from './TreeChart';
import styles from './column-layout.module.scss';

interface ColumnLayoutProps {
  error: ApolloError | undefined;
  loading: boolean;
  rootNode: SitemapNode;
  nodeSelectionHandler: (node: SitemapNode | null) => void;
  selectedNode: SitemapNode;
  filters: FilterBase[];
  includeKeydis: boolean;
  setOrderBy: (orderBy: string) => void;
  treeViewMode: TreeViewMode;
  setTreeViewMode: (newNode: TreeViewMode) => void;
  viewModeId: SiteMapMode;
}

/**
 * Main content of the Site Mapping page.
 * This is where the two responsive columns are defined.
 */
export const ColumnLayout = ({
  error,
  loading,
  rootNode,
  nodeSelectionHandler,
  selectedNode,
  filters,
  includeKeydis,
  setOrderBy,
  treeViewMode,
  setTreeViewMode,
  viewModeId,
}: ColumnLayoutProps) => {
  if (!loading && isEmpty(rootNode)) {
    return (
      <Flex bg="white" p="md">
        <Box className="alert alert-warning" m="xs">
          {t('No data found for the selected filtering.')}
        </Box>
      </Flex>
    );
  }

  // // Websocket subscription to be used in the future to show banner when new data is added.
  // // Please note that a solution which avoids rerendering for every single rank coming in is needed!
  // // Test this for instance by importing a bulk of keywords from keydis
  // const domainListId = useDomainIds();
  //
  // const domainId = useDomainId();
  //
  // useTableSubscription(
  //   TableIDs.KEYWORDS,
  //   {
  //     subscribeTo: 'domain',
  //     subscribeToArg: domainId || domainListId || null,
  //   },
  //   true,
  // );

  return (
    <div className={styles.columnLayoutContainer}>
      <div className={styles.leftColumn}>
        <SitemapTree
          loading={loading}
          error={error}
          rootNode={rootNode}
          selectedNode={selectedNode}
          nodeSelectionHandler={nodeSelectionHandler}
          setOrderBy={setOrderBy}
          treeViewMode={treeViewMode}
          setTreeViewMode={setTreeViewMode}
          viewModeId={viewModeId}
        />
      </div>
      <div className={styles.rightColumn}>
        <SiteMappingRightColumn
          loading={loading}
          selectedNode={selectedNode}
          filters={filters}
          includeKeydis={includeKeydis}
        />
      </div>
    </div>
  );
};
