import { Component } from 'react';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import { Field } from 'redux-form';
import toFormField from 'Components/Forms/toFormField';
import { t } from 'Utilities/i18n';
import Validator from 'Utilities/validation';
import MultiOptionsInputFactory from '../../Common/MultiOptionsEditor/InputFactory';

const campaignsQuery = gql`
  query affiliateInput_campaigns {
    affiliate {
      uniqueIdChoices
    }
  }
`;
const UniqueIdsInput = MultiOptionsInputFactory(
  graphql(campaignsQuery, {
    props: ({ data: { error, loading, affiliate } }: any) => ({
      error,
      loading,
      items: affiliate ? affiliate.uniqueIdChoices : [],
    }),
  }),
);
const UniqueIdsField = toFormField(UniqueIdsInput);

class UniqueIdsEditor extends Component<{}> {
  render() {
    return (
      <div>
        <Field
          name="value"
          component={UniqueIdsField}
          validate={Validator.array}
          placeholder={t('Enter unique IDs')}
          noValueItem={t('No unique ID')}
        />
      </div>
    );
  }
}

export default UniqueIdsEditor;
