import { useEffect } from 'react';
import cookie from 'react-cookies';
import isEmpty from 'lodash/isEmpty';
import useToast from 'Hooks/useToast';
import type {
  SubscriptionActionType,
  SubscriptionCallback,
  SubscriptionDataType,
  SubscriptionObjectType,
} from 'Types/Subscription';
import {
  BLOCK,
  DOMAIN,
  GENERATED_REPORT,
  IMPORT,
  NO_TOPIC,
  RUNNING_TASKS,
  SCHEDULED_REPORT,
  SERVICE_MESSAGE,
  USER,
} from 'Types/Subscription';
import * as Types from 'Types/Subscription';
import { toArray } from 'Utilities/underdash';

type SubscribeToTopic = {
  topic?: SubscriptionObjectType | SubscriptionObjectType[];
  id?: string | string[];

  // TODO FixTSignore
  action?: SubscriptionActionType | SubscriptionActionType[] | any;
  toast?: string;
  cb: SubscriptionCallback;
};

type SubscriptionItem = {
  ids: string[];
  actions: SubscriptionActionType[];
  toast?: string;
  cb: SubscriptionCallback;
};

export type SubscriptionHandle = {
  unsubscribe: (...args: Array<any>) => any;
};

type SubscriptionsHolder = Partial<Record<SubscriptionObjectType, SubscriptionItem[]>>;

/*
 * Subscriptions holder to subscribe only once and just filter results
 * in apollo subscription callback
 */
const subscriptions: SubscriptionsHolder = {};

const transformSubProps = (topic, id, action) => ({
  topic: (Array.isArray(topic) ? topic[0] : topic) || NO_TOPIC,
  ids: toArray(id || []),
  actions: toArray(action || []),
});

const addSubToTopic = ({ topic, id, action, cb, toast }: SubscribeToTopic) => {
  const { topic: topicItem, ids, actions } = transformSubProps(topic, id, action);
  const newSubs = subscriptions[topicItem] || [];
  newSubs.push({
    ids,
    actions,
    cb,
    toast,
  });
  subscriptions[topicItem] = newSubs;
};

const removeSubFromTopic = ({ topic, id, action, cb, toast }: SubscribeToTopic) => {
  const { topic: topicItem, ids, actions } = transformSubProps(topic, id, action);
  subscriptions[topicItem] = (subscriptions[topicItem] || []).reduce((acc, item) => {
    // don't remove sub from topic as it has different callback
    if (item.cb !== cb) {
      acc.push(item);
      return acc;
    }

    // remove topic for any action and ids
    if (isEmpty(actions) && isEmpty(ids)) {
      return acc;
    }

    // remove action and ids from sub
    const newActions = item.actions.filter((act) => !actions.includes(act));
    const newIds = item.ids.filter((idItem) => !ids.includes(idItem));

    // if we have empty one, remove it
    if (isEmpty(newActions) && isEmpty(newIds)) {
      return acc;
    }

    // push the rest action and ids to sub
    acc.push({
      actions: newActions,
      ids: newIds,
      toast,
      cb,
    });
    return acc;
  }, []);
};

const unsubscribeTopic = (...items: SubscribeToTopic[]) => {
  items.forEach((item) => {
    toArray(item.topic).forEach((topic) => {
      removeSubFromTopic({ ...item, topic });
    });
  });
};

/**
 * Subscribe to topic to filter actions, ids + toast message definition
 * This is custom method so if you need to subscribe and do callback on every action and id
 * please use facade methods
 *
 * @returns array of handles to unsubscribe
 */
const subscribeToTopic = (...items: SubscribeToTopic[]): SubscriptionHandle[] => {
  items.forEach((item) => {
    toArray(item.topic).forEach((topic) => {
      addSubToTopic({ ...item, topic });
    });
  });
  return items.map((item) => ({
    unsubscribe: () => unsubscribeTopic(item),
  }));
};

export const useSubscribeToTopic = (items: SubscribeToTopic[]) => {
  return useEffect(() => {
    const subscriptionList = subscribeToTopic(...items);

    return () => {
      subscriptionList?.map((e) => e?.unsubscribe());
    };
  }, [items]);
};

export const onSubscriptionData = (
  { data }: SubscriptionDataType,
  options: {
    showModal: (...args: Array<any>) => any;
    disableMultipleSessions?: boolean;
  },
) => {
  console.info('[websocket.js] onSubscriptionData', data);

  if (
    data.action === BLOCK &&
    cookie.load('uifs') !== data.session_id &&
    cookie.load('impersonate') === undefined &&
    cookie.load('multiaccount') === undefined
  ) {
    if (options?.disableMultipleSessions) {
      options?.showModal?.({
        modalType: 'SessionBlockModal',
        modalTheme: 'light',
        modalProps: {},
      });
    }
    return;
  }

  const topic = data.obj || NO_TOPIC;

  if (subscriptions[topic]) {
    subscriptions[topic]?.forEach((sub: SubscriptionItem) => {
      if (
        (isEmpty(sub.ids) && isEmpty(sub.actions)) ||
        (!isEmpty(sub.ids) && sub.ids.includes(data.id)) ||
        (!isEmpty(sub.actions) && sub.actions.includes(data.action))
      ) {
        sub.cb({
          data,
        });
        sub.toast && useToast.success(sub.toast);
        console.info(`${data.obj} '${data.id}' ${data.action}`);
      }
    });
  }
};

const makeSubscribeTo = (topic) => (cb: SubscriptionCallback) =>
  subscribeToTopic({
    topic,
    cb,
  })[0];

// Facade methods
export const subscribeToUser = makeSubscribeTo(USER);
// const subscribeToGroup = makeSubscribeTo(GROUP);
export const subscribeToDomain = makeSubscribeTo(DOMAIN);
export const subscribeToScheduledReport = makeSubscribeTo(SCHEDULED_REPORT);
export const subscribeToGeneratedReport = makeSubscribeTo(GENERATED_REPORT);
export const subscribeToImport = makeSubscribeTo(IMPORT);

export const subscribeToRunningTasks = makeSubscribeTo(RUNNING_TASKS);

export const subscribeToServiceMessage = makeSubscribeTo(SERVICE_MESSAGE);

export * from 'Types/Subscription';

export default { ...Types };
