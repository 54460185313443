import moment from 'moment';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { ColumnType, DataTable, TableFetchDataCallBack } from 'Components/DataTable';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import AccText from 'Components/Text/AccText';
import { useMessagesReadCountLazyQuery, useMessagesReadLazyQuery } from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import { TableIDs } from 'Types/Table';
import { t, tct } from 'Utilities/i18n';
import InformationIcon from 'icons/binoculars.svg?inline';
import WarningIcon from 'icons/flash.svg?inline';
import ActionRequiredIcon from 'icons/wrench.svg?inline';

const Messages = () => {
  const [fetchRead] = useMessagesReadLazyQuery();
  const [fetchReadCount] = useMessagesReadCountLazyQuery();

  const { hideModal } = useModal();

  const fetchData: TableFetchDataCallBack = async ({ pagination }) => {
    const { data: countData } = await fetchReadCount({ fetchPolicy: 'network-only' });
    const totalMessagesCount = countData?.totalMessagesCount ?? 0;

    const { data } = await fetchRead({
      variables: {
        startIdx: pagination.startIndex,
        stopIdx: pagination.stopIndex,
      },
      fetchPolicy: 'network-only',
    });

    return {
      data: data?.messages ?? [],
      length: totalMessagesCount,
    };
  };

  const columns: ColumnType[] = [
    {
      id: 'type',
      title: t('Type'),
      cellRenderer: (row) => {
        const { levelId } = row.record;
        const icon =
          levelId === 1 ? (
            <AccTooltip tooltip={t('Information')}>
              <InformationIcon className="messages-icon grey" />
            </AccTooltip>
          ) : levelId === 2 ? (
            <WarningIcon className="messages-icon orange" />
          ) : (
            <ActionRequiredIcon className="messages-icon red" />
          );
        return icon;
      },
      width: 50,
    },
    {
      id: 'date',
      title: t('Date'),
      cellRenderer: (row) => {
        const { createdAt } = row.record;
        return <AccText size="sm">{moment(createdAt).format('ddd MMM DD, YYYY, hh:mm a')}</AccText>;
      },
    },
    {
      id: 'message',
      title: t('Message'),
      cellRenderer: (row) => {
        const { text } = row.record;
        return (
          <AccText nowrap overflowEllipsis size="sm">
            {text}
          </AccText>
        );
      },
    },
  ];

  const labels = {
    cellLoadingTooltip: 'Refreshing messages...',
    paginationLoading: 'Loading messages ...',
    paginationLoadingWithDetails: ({ from, to, total }) =>
      tct('Loading message [from] to [to] of [total] messages', {
        total,
        from,
        to,
      }),
    paginationDetails: ({ from, to, total }) =>
      tct('Message [from] to [to] of [total] messages', {
        total,
        from,
        to,
      }),
  };

  return (
    <ModalBorder title={t('Your Messages')} onClose={hideModal}>
      <DataTable
        tableId={TableIDs.MESSAGES}
        columns={columns}
        fetchData={fetchData}
        pagination={true}
        pageSize={10}
        labels={labels}
      />
    </ModalBorder>
  );
};

export default Messages;
