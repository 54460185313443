import colorScheme from 'Utilities/colors';
import { t } from 'Utilities/i18n';
import { OverviewChartSeries } from '../constants';
import { addLowDataPlaceholderDays } from '../support/lowData';

type AvgRankSeries = OverviewChartSeries;

export const getAverageBaseRankSeries = (data): AvgRankSeries[] => {
  const series: AvgRankSeries[] = [
    {
      name: t('Average base rank'),
      primary: true,
      color: `${colorScheme.graphs.stats.averageBaseRank}9D`,
      data:
        data?.filter(Boolean)?.map((e) => ({
          x: e?.date,
          y: e?.averageBaseRank ?? 0,
          totalKeywords: e?.keywords || 0,
        })) ?? [],
    },
  ];
  addLowDataPlaceholderDays(series[0]);
  return series;
};
