import AccTitle from 'Components/Title/AccTitle';
import dentsu from '../../../icons/customerLogos/dentsu.png';
import garmin from '../../../icons/customerLogos/garmin.png';
import groupm from '../../../icons/customerLogos/groupm.png';
import groupon from '../../../icons/customerLogos/groupon.png';
import ikea from '../../../icons/customerLogos/ikea.png';
import omnicomgroup from '../../../icons/customerLogos/omnicomgroup.png';
import CoreFeatureBullets from './components/CoreFeatureBullets';
import CustomerReviews from './components/CustomerReviews';
import styles from './expired-page.module.scss';

type Props = {
  title: string;
  mainContent: React.ReactNode;
  footerContent?: React.ReactNode;
};

const ExpiredPageComponent = (props: Props) => {
  const { title, mainContent, footerContent } = props;

  return (
    <div className={styles.exiredPageContainer}>
      <div className={styles.leftColumn}>
        <AccTitle type="h1" mb="xl">
          {title}
        </AccTitle>
        <div className={styles.mainContentContainer}>{mainContent}</div>
        <CustomerReviews />
        <div className={styles.logosContainer}>
          <img src={dentsu} alt="dentsu" className={styles.dentsu} />
          <img src={ikea} alt="ikea" className={styles.ikea} />
          <img src={groupm} alt="groupm" />
          <img src={groupon} alt="groupon" />
          <img src={omnicomgroup} alt="omnicomgroup" />
          <img src={garmin} alt="garmin" className={styles.garmin} />
        </div>
      </div>

      <div className={styles.rightColumn}>
        <CoreFeatureBullets />
        {footerContent}
      </div>
    </div>
  );
};

export default ExpiredPageComponent;
