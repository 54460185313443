import { FilterAttribute } from 'Types/Filter';
import { StoreType } from 'Types/Store';
import SpecificFilterSelector from './SpecificFilterSelector';

const domainsFilterSelector = SpecificFilterSelector(FilterAttribute.DOMAINS);

export const selectDomainId = (state: StoreType) => {
  const domainFilter = domainsFilterSelector(state);
  return (domainFilter?.value?.length === 1 ? domainFilter?.value?.[0] : null) ?? null;
};

export const selectDomainList = (state: StoreType) => {
  const domainFilter = domainsFilterSelector(state);
  return domainFilter?.value ?? null;
};

export const selectFeatureAdvancedMetrics = (state: StoreType) =>
  state?.user?.organization?.activePlan?.featureAdvancedMetrics ?? false;

export const selectLanguage = (state: StoreType): string | null => state?.user?.language ?? null;

export const selectHasKeydisAccess = (state: StoreType): boolean | null =>
  state?.user?.organization?.activePlan?.hasKeydisAccess ?? null;

export const selectDomainUnrankedWeight = (state: StoreType): number | null => {
  const domainFilter = domainsFilterSelector(state);
  return ((domainFilter?.value?.length === 1 ? domainFilter?.value?.[0] : undefined) as any)
    ?.unrankedWeight;
};
