import React, { isValidElement } from 'react';
import { connect } from 'react-redux';
import { hideModal } from 'Actions/ModalAction';
import AccButton from 'Components/AccButton/AccButton';
import Checkbox from 'Components/Controls/Checkbox';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import AccText from 'Components/Text/AccText';
import { EventName, trackEventMixpanel } from 'Utilities/Analytics/mixpanel';
import { t, tct } from 'Utilities/i18n';
import './confirmation-modal.scss';

type Props = {
  className: string;
  title: string;
  description: React.ReactNode;
  action: (...args: Array<any>) => any;
  cancelAction: (...args: Array<any>) => any;
  lockDuration?: number;
  cancelLabel?: string;
  showCancelLabel?: boolean;
  confirmLabel?: string;
  hideModal: (...args: Array<any>) => any;
  terms: boolean | string | undefined;
  showExclamationInTitle: boolean;
  enableOutsideClick?: boolean;
  modalFooterTextSlot?: string;
};
type State = {
  lockDuration?: number;
  agreed: boolean;
  submitting: boolean;
};

class Confirmation extends React.Component<Props, State> {
  static defaultProps = {
    lockDuration: 3,
    showCancelLabel: true,
    showExclamationInTitle: true,
  };
  state = {
    lockDuration: this.props.lockDuration ?? 3,
    agreed: false,
    submitting: false,
  };

  componentDidMount() {
    if (this.props.lockDuration) {
      this.timerId = setInterval(this.handleTimer, 1000);
    }
    trackEventMixpanel(EventName.ConfirmationModalOpen, '', {
      Classname: this.props.className,
      Title: this.props.title,
    });
  }

  componentWillUnmount() {
    if (this.timerId !== -1) {
      clearInterval(this.timerId);
      this.timerId = -1;
    }
  }

  timerId?: any = -1;
  handleTimer = () => {
    let newDuration = this.state.lockDuration - 1;

    if (newDuration <= 0) {
      newDuration = 0;
      clearInterval(this.timerId);
      this.timerId = -1;
    }

    this.setState({
      lockDuration: newDuration,
    });
  };
  handleConfirm = async () => {
    trackEventMixpanel(EventName.ConfirmationModalOK, '', {
      Classname: this.props.className,
      Title: this.props.title,
    });
    if (this.props.action) {
      this.setState({ submitting: true });
      await this.props.action();
      this.setState({ submitting: false });
    }
    this.props.hideModal();
  };
  handleCancel = () => {
    if (this.props.cancelAction) {
      this.props.cancelAction();
    } else {
      this.props.hideModal();
    }
    trackEventMixpanel(EventName.ConfirmationModalCancel, '', {
      Classname: this.props.className,
      Title: this.props.title,
    });
  };
  setAgreed = (e) => {
    e.persist();
    this.setState(() => ({
      agreed: !this.state.agreed,
    }));
  };

  renderTerms() {
    if (!this.props.terms) return null;

    if (typeof this.props.terms === 'string' || isValidElement(this.props.terms)) {
      return (
        <Checkbox onChange={(e) => this.setAgreed(e)} checked={this.state.agreed}>
          {this.props.terms}
          &nbsp;
          {tct('[link:Terms and Conditions]', {
            link: <a href="https://www.accuranker.com/terms" target="_blank" rel="noreferrer" />,
          })}
        </Checkbox>
      );
    }

    return (
      <Checkbox onChange={(e) => this.setAgreed(e)} checked={this.state.agreed}>
        {tct('I agree to the [link:Terms and Conditions]', {
          link: <a href="https://www.accuranker.com/terms" target="_blank" rel="noreferrer" />,
        })}
      </Checkbox>
    );
  }

  render() {
    const { title, description, showCancelLabel, showExclamationInTitle, modalFooterTextSlot } =
      this.props;
    const { lockDuration } = this.state;
    const cancelLabel = this.props.cancelLabel || t('Cancel');
    let confirmLabel = this.props.confirmLabel || t('Yes');
    let confirmEnabled = true;

    if (lockDuration > 0) {
      confirmLabel = t('Unlocking in %s sec', lockDuration);
      confirmEnabled = false;
    }

    if (this.props.terms && !this.state.agreed) confirmEnabled = false;
    return (
      <ModalBorder
        title={title}
        showExclamationInTitle={showExclamationInTitle}
        className={'confirmation-modal'}
        onClose={this.props.hideModal}
      >
        <AccText>{description}</AccText>
        {this.renderTerms()}
        <ModalFooter
          primaryButtonSlot={
            <AccButton
              variant={showExclamationInTitle ? 'destructive' : 'primary'}
              onClick={this.handleConfirm}
              disabled={!confirmEnabled}
              loading={this.state.submitting}
            >
              {confirmLabel}
            </AccButton>
          }
          secondaryButtonSlot={
            showCancelLabel ? (
              <AccButton variant="tertiary" onClick={this.handleCancel}>
                {cancelLabel}
              </AccButton>
            ) : (
              <></>
            )
          }
          textSlot={
            modalFooterTextSlot ? <AccText variant="label">{modalFooterTextSlot}</AccText> : <></>
          }
        />
      </ModalBorder>
    );
  }
}

export default connect(null, {
  hideModal,
})(Confirmation);
