import { useCallback, useRef } from 'react';
import { useIntercom } from 'react-use-intercom';
import { Space } from '@mantine/core';
// eslint-disable-next-line import/no-unresolved
import { FallbackRender } from '@sentry/react/types/errorboundary';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import AccButton from 'Components/AccButton/AccButton';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { useClipboard } from 'Hooks';
import { useRouteMatch } from 'Utilities/Router/hooks/useRouteMatch';
import { t, tct } from 'Utilities/i18n';
import { redirectToRoot } from 'Utilities/underdash';
import Icon from 'icons/500.svg';
import '../error-page.scss';

const SystemError = (errorData: Parameters<FallbackRender>[0]): React.ReactElement => {
  const { resetError, eventId } = errorData;
  const { path } = useRouteMatch();
  const pathRef = useRef<string | null>(null);

  const clipboard = useClipboard({ timeout: 4000 });

  const { isOpen, show } = useIntercom();

  const errorPageRef = useCallback(
    (node: HTMLDivElement) => {
      if (node !== null && !pathRef.current) {
        pathRef.current = path;
        return;
      }
      //reset the app error state the first time the user navigates to a different path
      if (pathRef.current && pathRef.current !== path) {
        resetError();
      }
    },
    [resetError, path],
  );

  return (
    <div className="error-page" ref={errorPageRef}>
      <img src={Icon} />
      <h1 className="title">{t('Internal Server Error')}</h1>
      <p className="description">
        {t(
          'Unfortunately, the requested page could not be reached. Please, try again later, or use the main menu to navigate to a different page.',
        )}
      </p>
      <p className="description">
        {tct('If the problem persists, please [support].', {
          support: <a onClick={() => !isOpen && show()}>{t('contact our support')}</a>,
        })}
      </p>
      {eventId && (
        <>
          <p>{t('Event id:')}</p>
          <AccTooltip
            delay={0}
            tooltip={clipboard.copied ? t('copied') : t('Copy id to clipboard')}
          >
            <AccActionIcon
              px={8}
              variant="subtle"
              miw="min-content"
              style={{ aspectRatio: 'unset' }}
              onClick={() => clipboard.copy(eventId)}
            >
              {eventId}
            </AccActionIcon>
          </AccTooltip>
        </>
      )}
      <Space h="xl" />
      <AccButton
        variant="primary"
        onClick={() => {
          redirectToRoot();
        }}
      >
        {t('Reload app')}
      </AccButton>
    </div>
  );
};

export default SystemError;
