import { ApolloError } from '@apollo/client';
import { GroupsListQuery, PaginationInput, useDomainsQuery } from 'Ghql';
import toast from 'Hooks/useToast';
import { FilterBase } from 'Types/Filter';
import { OrderingState } from 'Types/Sort';
import { useSmartQuery } from 'Utilities/Graphql/hooks/useSmartQuery';
import { defaultOrdering } from '../support/getSorting';

export const useDomainsQueryData = (
  filters: FilterBase[],
  ordering: OrderingState | null,
  pagination: PaginationInput | null,
  groupsLoading: boolean,
  groupDataError: ApolloError | undefined,
  groupData: GroupsListQuery | undefined,
  setPagination: (pagination: PaginationInput) => void,
) => {
  const { data, loading, error, refetch } = useSmartQuery(useDomainsQuery, {
    variables: {
      filters,
      ordering: ordering || defaultOrdering,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      pagination: pagination!,
    },
    skip: groupsLoading || !!groupDataError || !groupData || !pagination,
    onCompleted: () => {
      const resPagination = data?.tableDashboard?.pagination;
      if (
        resPagination &&
        resPagination.startIndex &&
        resPagination.stopIndex &&
        resPagination.numResults &&
        pagination?.page
      ) {
        setPagination({
          page: pagination.page,
          startIndex: resPagination.startIndex,
          stopIndex: resPagination.stopIndex,
          results: resPagination.numResults,
        });
      }
    },
    onError: (err) => {
      console.error(err);
      toast.error('An error occurred while getting domains data');
    },
  });

  return { data, loading, error, refetch };
};
