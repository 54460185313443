import moment from 'moment';
import { SeriesItem } from 'Components/Chart/LineChart';
import { useAccuApiSpendingQuery } from 'Ghql';
import { colorScheme } from 'Utilities/colors';
import { t } from 'Utilities/i18n';
import { notEmpty } from 'Utilities/underdash';

type SpendingHours = [timeStamp: number, totalRequests: number];

export const useSpendinChartData = (startDate: Date, endDate: Date) => {
  const startDateString = moment(startDate.toString()).format('YYYY-MM-DD');
  const endDateString = moment(endDate.toString()).format('YYYY-MM-DD');
  const { data, loading } = useAccuApiSpendingQuery({
    variables: { startDate: startDateString, endDate: endDateString },
  });

  const spendingHours: SpendingHours[] = data?.accuApiSpendingGraph?.hours?.filter(notEmpty) || [];

  const resultData: SeriesItem[] = loading
    ? []
    : [
        {
          name: t('Hourly requests'),
          primary: true,
          color: colorScheme.graphs.primaryDomain,
          data:
            spendingHours.map(([timeStamp, totalRequests]) => {
              return {
                y: totalRequests || 0,
                x: timeStamp,
              };
            }) ?? [],
        },
      ];

  return { data: resultData, loading };
};
