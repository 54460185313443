type Props = {
  domain: string;
  size?: string;
};

export const CompetitorFavicon: React.FC<React.PropsWithChildren<Props>> = ({
  size = '24',
  domain,
}) => {
  return (
    <img
      className="formatted-domain-cell-favicon"
      alt={domain}
      src={`https://app.accuranker.com/screenshots/favicon?url=${domain}&width=${size}&height=${size}`}
    />
  );
};
