import { useCallback } from 'react';
import { useStore } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import { TableFetchDataCallBack } from 'Components/DataTable';
import { GroupsTableDocument, GroupsTableQuery, GroupsTableQueryVariables } from 'Ghql';
import { selectCurrentFilters } from 'Selectors/FilterSelector';

export const useFetchGroupsData = (): TableFetchDataCallBack => {
  const client = useApolloClient();
  const store = useStore();

  return useCallback(
    async (params) => {
      const filters = selectCurrentFilters(store.getState());
      const variables: GroupsTableQueryVariables = {
        filters,
        pagination: params.pagination,
        ordering: params.ordering,
      };
      const e = await client.query<GroupsTableQuery, GroupsTableQueryVariables>({
        query: GroupsTableDocument,
        variables,
        fetchPolicy: 'network-only',
      });
      const data = e.data?.tableClients?.clients;
      const totalLength = e.data.tableClients?.pagination?.numResults;
      return { data: data || [], length: totalLength ?? 0 };
    },
    [client, store],
  );
};
