import { Center, Group } from '@mantine/core';
import cn from 'classnames';
import styles from 'Components/AccSelect/select.module.scss';

type Props = {
  label?: string;
  maw?: string | number;
  icon?: React.ReactNode;
  disabled?: boolean;
  isStack?: boolean;
} & React.HTMLAttributes<HTMLSpanElement>;

const MultiSelectValue = (props: Props) => {
  const { label, disabled, icon, maw, isStack, ...rest } = props;
  const maxWidth = maw as string | number | undefined;
  const renderLabel = (
    <span className={styles.pillLabel} style={{ maxWidth }}>
      {label}
    </span>
  );

  return (
    <span
      className={cn(styles.pillRoot, {
        [styles.pillNoBg]: isStack,
      })}
      data-disabled={disabled || null}
      {...rest}
    >
      {icon ? (
        <Group wrap="nowrap" align="center" gap="xxs" h="inherit">
          <Center w={15} h={15} ml={-3}>
            {icon}
          </Center>
          {renderLabel}
        </Group>
      ) : (
        renderLabel
      )}
    </span>
  );
};

export default MultiSelectValue;
