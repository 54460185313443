import { Component } from 'react';
import { connect } from 'react-redux';
import { hideModal } from 'Actions/ModalAction';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import OneTimeReportForm from 'Pages/ScheduledReportBuilder/OneTimeReportForm';
import { t } from 'Utilities/i18n';
import './one-time-report.scss';

type Props = {
  hideModal: (...args: Array<any>) => any;
  domainId: number | undefined;
  lastState: Record<string, any>;
  submitOnInit: boolean;
};

class OneTimeReport extends Component<Props> {
  handleClose = () => {
    this.props.hideModal();
  };

  render() {
    const { lastState, submitOnInit, domainId } = this.props;
    return (
      <ModalBorder
        className="add-scheduled-report"
        title={t('Download Report')}
        onClose={this.handleClose}
      >
        <OneTimeReportForm
          onSubmit={this.handleClose}
          domainId={domainId}
          initialState={lastState}
          submitOnInit={submitOnInit}
          onClose={this.handleClose}
        />
      </ModalBorder>
    );
  }
}

export default connect(null, {
  hideModal,
})(OneTimeReport);
