import { CompetitorColumnID } from 'Pages/Keywords/Competitors/support/constants';
import { DomainInfo } from 'Query/main';
import { t } from 'Utilities/i18n';

export const getShowAsPercentage = (metric: string, domainInfo?: DomainInfo) => {
  return (
    (metric === CompetitorColumnID.SHARE_OF_VOICE &&
      (domainInfo?.shareOfVoicePercentage ?? false)) ||
    metric === CompetitorColumnID.DYNAMIC_CTR
  );
};

export const isRankChart = (metric: string) => {
  return metric === CompetitorColumnID.KEYWORDS_AVG_RANK || metric === 'average_rank';
};

type CompetitorMetricLabel = { label: string; value: string };

export const getCompetitorMetricsLabels = (): CompetitorMetricLabel[] => [
  {
    label: t('Share of Voice'),
    value: 'share_of_voice',
  },
  {
    label: t('Average Rank'),
    value: 'average_rank',
  },
  {
    label: t('AI SoV'),
    value: 'organic_traffic',
  },
  {
    label: t('Average CTR'),
    value: 'dynamic_ctr',
  },
  {
    label: t('1-3'),
    value: 'keywords0To3',
  },
  {
    label: t('4-10'),
    value: 'keywords4To10',
  },
  {
    label: t('11-20'),
    value: 'keywords11To20',
  },
  {
    label: t('21-50'),
    value: 'keywords21To50',
  },
  {
    label: t('51-100'),
    value: 'keywordsAbove50',
  },
  {
    label: t('Unranked'),
    value: 'keywordsUnranked',
  },
];
