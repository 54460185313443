import type { Dispatch } from 'redux';
import { STATE_LOADING_DONE, STATE_LOADING_START } from 'redux-async-initial-state/lib/actionTypes';
import { loadStore } from 'Store';
import { ActionType, GetState } from 'Types/Store';

/**
 * Fetch user details and set it into redux store
 * should be executed after user get authorized `sessionid`
 */
export const initUser =
  (redirect: () => void, loadAppStore = loadStore) =>
  async (dispatch: Dispatch<ActionType>, getState: GetState) => {
    const nextState = await loadAppStore(getState);
    dispatch({ type: STATE_LOADING_START });
    dispatch({ type: STATE_LOADING_DONE, payload: { state: nextState } });

    setTimeout(() => {
      redirect();
    }, 0);
  };
