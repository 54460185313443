/**
 * Constructs array of size "length" with evenly spaced numbers from a logarithmic perspective.
 * @param minValue
 * @param maxValue
 * @param length
 * @returns Array of numbers
 */
export const logspace = (minValue: number, maxValue: number, length: number): number[] => {
  if (!length) {
    length = 10;
  }
  // Calculate the increment:
  const end = length - 1;
  const increment = (maxValue - minValue) / end;

  // Build the output array...
  const arr = new Array(length);
  let tmp = minValue;
  arr[0] = Math.pow(10, tmp);
  for (let i = 1; i < end; i++) {
    tmp += increment;
    arr[i] = Math.pow(10, tmp);
  }
  arr[end] = Math.pow(10, maxValue);
  return arr;
};

/**
 * Takes a string and removes all spaces
 * @param str
 * @returns {*}
 */
export const removeSpaces = (str: string): string => {
  return str.replace(/\s/g, '');
};
