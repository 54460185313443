import { useEffect, useRef } from 'react';
import { Group, Progress } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons-react';
import { formatDisplayNumber } from 'Components/FormatNumber/formatNumberHelper';
import { MAX_NOTIFICATION_TIMEOUT, toastStyles } from 'Hooks/useToast';
import { truncateString } from 'Pages/KeywordDiscovery/support/helpers';
import { useInvalidateKeywordCache } from 'Utilities/Graphql/invalidateCache';
import { t, tct } from 'Utilities/i18n';
import { sleep } from 'Utilities/promise';
import { SubscriptionHandle, subscribeToRunningTasks } from 'Utilities/websocket';

const updateScrapeTaskNotification = async (subscriptionData: any, invalidateKeywordCache) => {
  const {
    n_completed_subtasks: completedTasks,
    total_tasks: totalTasks,
    domain,
    keyword: lastUpdatedKeyword,
  } = subscriptionData.data;
  const autoCloseDelay = 5000;
  const isDone = subscriptionData.action === 'DONE' || completedTasks >= totalTasks;

  notifications.update({
    id: subscriptionData.id,
    autoClose: isDone ? autoCloseDelay : MAX_NOTIFICATION_TIMEOUT,
    color: 'snorlax',
    loading: !isDone,
    styles: toastStyles,
    icon: isDone ? <IconCheck size="1rem" /> : undefined,
    title: domain
      ? tct('Updating keywords for [domain]...', { domain })
      : t('Updating keywords...'),
    message: (
      <div>
        <Group justify="space-between">
          <div>
            {isDone && totalTasks === 0
              ? t('The added keyword(s) already exist, or plan size exceeded')
              : tct('[completedTasks] out of [totalTasks]', {
                  completedTasks: formatDisplayNumber(completedTasks),
                  totalTasks: formatDisplayNumber(totalTasks),
                })}
          </div>
          <div>
            {lastUpdatedKeyword &&
              tct('Updated: [lastUpdatedKeyword]', {
                lastUpdatedKeyword: <i>{truncateString(lastUpdatedKeyword, 20)} </i>,
              })}
          </div>
        </Group>
        <Progress.Root size="xl">
          <Progress.Section
            value={isDone ? 100 : (100 * completedTasks) / totalTasks}
            color={'snorlax'}
          >
            <Progress.Label>
              {isDone ? '100%' : `${Math.round((100 * completedTasks) / totalTasks)}%`}
            </Progress.Label>
          </Progress.Section>
        </Progress.Root>
      </div>
    ),
  });
  if (isDone) {
    await sleep(autoCloseDelay); // Wait for backend to completely be done.
    invalidateKeywordCache();
  }
};

const _handleRunningTaskUpdate = (invalidateKeywordCache) => (subscriptionReturnObject: any) => {
  const subscriptionData: any = subscriptionReturnObject.data;

  if (subscriptionData?.data?.name === 'SCRAPE_TASK') {
    updateScrapeTaskNotification(subscriptionData, invalidateKeywordCache);
  }
};

export const UserRunningTasksSubscription = () => {
  const _subHandler = useRef<SubscriptionHandle>();

  const invalidateKeywordCache = useInvalidateKeywordCache();

  const handleRunningTaskUpdate = _handleRunningTaskUpdate(invalidateKeywordCache);

  useEffect(() => {
    _subHandler.current = subscribeToRunningTasks(handleRunningTaskUpdate);
  }, []);

  return null;
};
