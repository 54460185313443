import { FilterData } from 'Components/Filters/getFilterData';
import { FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import type { FilterBase, SubdomainFilter } from 'Types/Filter';
import { t } from 'Utilities/i18n';
import SubdomainIcon from 'icons/subdomain.svg?inline';
import { FilterDynamicProps } from '../../types';
import SubdomainEditor from './index';
import { useSubDomainFilterData } from './support/hooks';

const defaultValue: SubdomainFilter = {
  attribute: FilterAttribute.SUBDOMAIN,
  type: FilterValueType.STRING,
  comparison: FilterComparison.EQ,
  value: '',
};

const getData = () => {
  return {
    defaultValue,
    title: t('Subdomain'),
    icon: SubdomainIcon,
    editor: SubdomainEditor,
    editorProps: {
      useDynamicEditorProps: (): FilterDynamicProps => {
        const { subDomainDisabled } = useSubDomainFilterData(true);
        return { disableAdd: subDomainDisabled };
      },
    },
    labelFunc: (filter: FilterBase) => filter.value,
  };
};

type SubdomainFilterData = ReturnType<typeof getData>;

export const isSubdomainFilterData = (
  filterViewData: FilterData,
): filterViewData is SubdomainFilterData => {
  return 'editorProps' in filterViewData && 'useDynamicEditorProps' in filterViewData.editorProps;
};

export default getData;
