const rankMinMaxConfig = {
  processMin: (dataMin: number) => {
    const minBreakPoint = 4; // point below which use "1" as min value.
    const minFixed = dataMin - 3; // fixed value to show at min extreme
    const minScaled = Math.floor(dataMin * 0.9); // calculated scaled value. Works for lower rank, i.e. 50+
    switch (true) {
      case minFixed < minBreakPoint:
      case minScaled < minBreakPoint:
        // if minFixed or minScaled are lower than breakpoint use 1 as minimum extreme
        return 1;
      case minFixed < minScaled:
        return minFixed;
      default:
        return minScaled;
    }
  },
  processMax: (dataMax: number) => {
    const maxBreakPoint = 97; // point above which use "100" as max value.
    const maxFixed = dataMax + 3; // fixed value to show at max extreme
    const maxScaled = Math.ceil(dataMax * 1.1); // calculated scaled value. Works best for lower rank. i.e. 50+
    switch (true) {
      case maxFixed > maxBreakPoint:
      case maxScaled > maxBreakPoint:
        // if maxFixed or maxScaled are higher than breakpoint use 100 as maximum extreme
        return 100;
      case maxFixed > maxScaled:
        return maxFixed;
      default:
        return maxScaled;
    }
  },
};

const chartMinMax = {
  rank: rankMinMaxConfig,
  baseRank: rankMinMaxConfig,
  // value: {
  //   processMin: (dataMin: number) => (dataMin * 0.97 < 1 ? 0 : Math.round(dataMin * 0.95)),
  //   processMax: (dataMax: number) => (dataMax * 1.1 < 1 ? 1 : Math.round(dataMax * 1.1)),
  // },
  default: {
    processMin: (dataMin: number) => Math.min(Math.floor(dataMin), 0),
    processMax: (dataMax: number) => Math.ceil(dataMax),
  },
};
const processExtremes = (
  dataMin: number,
  dataMax: number,
  type: 'rank' | 'default',
): { min: number; max: number } => {
  const { processMin, processMax } = chartMinMax[type] || chartMinMax.default;
  return { min: processMin(dataMin), max: processMax(dataMax) };
};

export default processExtremes;
