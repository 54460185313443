import { useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import { DomainInfo } from 'Query';
import { t } from 'Utilities/i18n';

export enum Metrics {
  SoV = 'share_of_voice',
  Rank = 'rank',
  AverageRank = 'average_rank',
  DynamicCTR = 'dynamic_ctr',
  OrganicTraffic = 'organic_traffic', // AI SoV
  Keywords0To3 = 'keywords0To3',
  Keywords4To10 = 'keywords4To10',
  Keywords11To20 = 'keywords11To20',
  Keywords21To50 = 'keywords21To50',
  KeywordsAbove50 = 'keywordsAbove50',
  KeywordsUnranked = 'keywordsUnranked',
  AverageRevenue = 'analytics_avg_revenue',
  SearchVolume = 'search_volume',
  TrafficValue = 'cost_value',
}

const metricToYAxisLabel =
  (domainInfo: DomainInfo | undefined) =>
  (metric: Metrics): string | undefined => {
    switch (metric) {
      case Metrics.SoV:
        return domainInfo?.shareOfVoicePercentage ? t('SoV percent') : t('SoV / Visits');
      case Metrics.Rank:
      case Metrics.AverageRank:
        return t('Rank');
      case Metrics.DynamicCTR:
        return t('Avg. CTR');
      case Metrics.OrganicTraffic:
        return t('AI SoV / Visits');
      case Metrics.Keywords0To3:
      case Metrics.Keywords4To10:
      case Metrics.Keywords11To20:
      case Metrics.Keywords21To50:
      case Metrics.KeywordsAbove50:
      case Metrics.KeywordsUnranked:
        return t('Count');
      case Metrics.AverageRevenue:
        return t('Avg Revenue');
      case Metrics.SearchVolume:
        return t('Searches');
      case Metrics.TrafficValue:
        return t('Traffic Value');
      default:
        return undefined;
    }
  };

export const useMetrics = () => {
  const { domainInfo } = useQueryDomainInfo();
  return { metricToYAxisLabel: metricToYAxisLabel(domainInfo) };
};
