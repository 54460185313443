import Skeleton from 'Components/Skeleton';

export const SkeletonTableCell = () => {
  return (
    <td>
      <Skeleton
        linesConfig={[
          {
            type: 'text',
            options: {
              width: '100%',
            },
          },
        ]}
      />
    </td>
  );
};
SkeletonTableCell.displayName = 'SkeletonTableCell';
