import { useFilterOptionsQuery } from 'Ghql';
import { useFilters } from 'Hooks';
import { FilterAttribute } from 'Types/Filter';
import getIsKeyDis from 'Utilities/getKeyDis';

export const useTopCompetitorData = () => {
  const filters = useFilters();
  const { loading, data } = useFilterOptionsQuery({
    fetchPolicy: 'cache-first',
    variables: {
      filters: filters.filter((f) => f.attribute !== FilterAttribute.TOP_COMPETITOR),
      isKeydis: getIsKeyDis(),
      filterType: FilterAttribute.TOP_COMPETITOR,
    },
  });
  return { data, loading };
};
