import AccButton from 'Components/AccButton/AccButton';
import HelpTextPopover from 'Components/HelpTextPopover/HelpTextPopover';
import LoadingSpinner from 'Components/LoadingSpinner';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import { useAffiliateGetMoneyOwedQuery, usePayAffiliateMutation } from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import toast from 'Hooks/useToast';
import { t, tct } from 'Utilities/i18n';

type Props = {
  organizationId: number;
};

const PayAffiliate = (props: Props) => {
  const { organizationId } = props;
  const { hideModal } = useModal();

  const {
    data: moneyOwedData,
    loading: moneyOwedLoading,
    error: moneyOwedError,
  } = useAffiliateGetMoneyOwedQuery({
    variables: {
      organizationId: organizationId.toString(),
    },
  });

  const [payAffiliateMutation] = usePayAffiliateMutation();

  const handleSubmit = async () => {
    const input = { variables: { input: { organizationId: organizationId.toString() } } };
    const { data } = await payAffiliateMutation(input);
    const errors = data?.payAffiliate?.errors;
    if (errors && errors.length) {
      console.error('Could not pay affiliate');
      console.error(errors);
      toast.error(t('Could not pay affiliate.'));
      // Validator.setResponseErrors(Validator.throwSubmissionError, errors);
    } else {
      toast.success(t('Affiliate paid.'));
      hideModal();
    }
  };

  const Content = () => {
    const disabled =
      moneyOwedLoading ||
      !!moneyOwedError ||
      (moneyOwedData?.affiliateMoneyOwed?.minimumPayout ?? 0) >
        (moneyOwedData?.affiliateMoneyOwed?.owedTotalUsd ?? 0);

    return (
      <>
        <table>
          <tbody>
            <tr>
              <td colSpan={2}>{t('Money owed to affiliate:')}</td>
            </tr>
            <tr>
              <td>
                <strong>{t('USD:')}</strong>
              </td>
              <td>{moneyOwedData?.affiliateMoneyOwed?.owedUsd}</td>
            </tr>
            <tr>
              <td>
                <strong>{t('EUR:')}</strong>
              </td>
              <td>{moneyOwedData?.affiliateMoneyOwed?.owedEur}</td>
            </tr>
            <tr>
              <td>
                <strong>{t('DKK:')}</strong>
              </td>
              <td>{moneyOwedData?.affiliateMoneyOwed?.owedDkk}</td>
            </tr>
            <tr>
              <td>
                <strong>{t('Minimum payout USD:')}</strong>
              </td>
              <td>{moneyOwedData?.affiliateMoneyOwed?.minimumPayout}</td>
            </tr>
            <tr>
              <td>
                <strong>{t('Total USD:')}</strong>
              </td>
              <td>{moneyOwedData?.affiliateMoneyOwed?.owedTotalUsd}</td>
            </tr>
          </tbody>
        </table>

        <ModalFooter
          primaryButtonSlot={
            <AccButton variant="primary" onClick={handleSubmit} disabled={disabled}>
              {t('Pay')}
            </AccButton>
          }
          textSlot={
            <HelpTextPopover
              position={'top'}
              text={tct(
                '[b:Note on Total USD:][br]This an approximate total in USD.[br]We do not payout this amount. We only use it to compare to "Minimum payout USD"',
                { b: <strong />, br: <br /> },
              )}
            />
          }
        />
      </>
    );
  };

  return (
    <ModalBorder title={t('Pay Affiliate')} onClose={hideModal}>
      {moneyOwedLoading ? <LoadingSpinner /> : <Content />}
    </ModalBorder>
  );
};

export default PayAffiliate;
