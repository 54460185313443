import * as React from 'react';
import { NotificationProps } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconInfoCircle, IconX } from '@tabler/icons-react';

type ToastOptions = Pick<NotificationProps, 'color' | 'onClose' | 'title' | 'loading' | 'icon'> & {
  autoClose?: number | boolean;
  id?: string | undefined;
  withIcon?: boolean;
};

export const MAX_NOTIFICATION_TIMEOUT = 90000; // 1½ minute

export const toastStyles = (theme) => ({
  root: {
    border: `1px solid ${theme.colors.gray[3]}`,
  },
});

export const showToast = (message: React.ReactNode, options?: ToastOptions) => {
  notifications.show({
    id: options?.id ? options.id.toString() : JSON.stringify(message).slice(0, 50), // Fallback ID if None is provided - makes sure we don't have duplicate notifications
    message,
    ...options,
    styles: toastStyles,
  });
};

const toast = {
  success: (message: React.ReactNode, options?: ToastOptions) => {
    showToast(message, {
      color: 'green',
      icon: options?.withIcon ? <IconCheck size="1rem" /> : undefined,
      ...options,
    });
  },
  error: (message: React.ReactNode, options?: ToastOptions) => {
    showToast(message, {
      color: 'red',
      icon: options?.withIcon ? <IconX size="1rem" /> : undefined,
      ...options,
    });
  },
  info: (message: React.ReactNode, options?: ToastOptions) => {
    showToast(message, {
      color: 'snorlax',
      icon: options?.withIcon ? <IconInfoCircle size={'1rem'} /> : undefined,
      ...options,
    });
  },
};

export default toast;
