import { Link } from 'react-router-dom';
import { Flex } from '@mantine/core';
import { Field } from 'Components/Fields';
import AccText from 'Components/Text/AccText';
import { tct } from 'Utilities/i18n';
import Validator from 'Utilities/validation';
import { FIELD_NAMES } from '..';
import styles from '../register.module.scss';

const TermsAndConditions = () => {
  return (
    <Flex my="sm" justify="center">
      <Field.Checkbox name={FIELD_NAMES.TERMS} validate={(val) => Validator.required(val)} />
      <AccText size={'sm'} style={{ whiteSpace: 'nowrap' }}>
        {tct('I agree to the [termsLink:Terms and Conditions] and [policyLink:Privacy Policy]', {
          termsLink: (
            <Link
              className={styles.termsAndConditionsLink}
              to="https://www.accuranker.com/terms"
              rel="noopener noreferrer"
              target="_blank"
            />
          ),
          policyLink: (
            <Link
              className={styles.termsAndConditionsLink}
              to="https://www.accuranker.com/privacy"
              rel="noopener noreferrer"
              target="_blank"
            />
          ),
        })}
      </AccText>
    </Flex>
  );
};

export default TermsAndConditions;
