import React from 'react';
import { connect } from 'react-redux';
import { Box, Flex, Group, MantineTheme } from '@mantine/core';
import { IconCirclePlus } from '@tabler/icons-react';
import { Field, FieldArray, change, formValueSelector, reduxForm } from 'redux-form';
import type { FormProps } from 'redux-form';
import { hideModal } from 'Actions/ModalAction';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import AccButton from 'Components/AccButton/AccButton';
import { OnSubmitCallback } from 'Components/Fields/Form/Form';
import { Flag } from 'Components/Flag';
import { LocationField } from 'Components/Forms/Fields';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import AccText from 'Components/Text/AccText';
import { withDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import { DomainInfo } from 'Query';
import { t } from 'Utilities/i18n';
import { withMantineTheme } from 'Utilities/withMantineTheme';
import CloseIcon from 'icons/close-2.svg?inline';
import SearchEngineSettings from './SearchEngineSettings';
import SearchSettingsField from './SearchSettingsField';
import { SEARCH_TYPE_BING, SEARCH_TYPE_GOOGLE, SEARCH_TYPE_YANDEX } from './types';
import './add-search-engine.scss';

type Props = {
  locale: Record<string, any>;
  hideModal: () => void;
  onSubmit: OnSubmitCallback;
  mantineTheme: MantineTheme;
  domainInfo?: DomainInfo;
} & FormProps;

const googleEngines = (engines) =>
  engines.filter((engine) => engine.name === SEARCH_TYPE_GOOGLE && engine.searchTypes.length);

const bingEngines = (engines) =>
  engines.filter((engine) => engine.name === SEARCH_TYPE_BING && engine.searchTypes.length);

const yandexEngines = (engines) =>
  engines.filter((engine) => engine.name === SEARCH_TYPE_YANDEX && engine.searchTypes.length);

class AddSearchEngine extends React.Component<Props> {
  componentDidUpdate(prevProps) {
    const { engines: prevEngines } = prevProps;
    const { engines } = this.props;
    const prevGoogleEngine = !!prevEngines && googleEngines(prevEngines);
    const curGoogleEngine = !!engines && googleEngines(engines);

    if (prevGoogleEngine.length > 0 && curGoogleEngine.length <= 0) {
      this.props?.initSettingField();
    }
  }

  handleClose = () => {
    this.props.hideModal();
  };
  handleSubmit = (values) => {
    const engine = {
      countrylocale: this.props.locale.id,
      ...values,
    };
    this.props.onSubmit(engine);
    this.props.hideModal();
  };

  validateSettings(searchEngines: any[]) {
    const hasSelectedOption =
      searchEngines?.reduce((acc, searchEngine) => {
        acc = acc || searchEngine.searchTypes.length > 0;
        return acc;
      }, false) ?? false;

    if (!hasSelectedOption) {
      return t('Please select at least one option');
    }
  }

  renderLocations(data) {
    let locationEnabled = false;
    // only allow for Google and Bing US
    const isGoogle = data.engines ? googleEngines(data.engines).length > 0 : false;
    const isBing = data.engines ? bingEngines(data.engines).length > 0 : false;
    const isYandex = data.engines ? yandexEngines(data.engines).length > 0 : false;
    // Naver does not support location

    if (isGoogle) {
      locationEnabled = true;
    }

    if (isYandex) {
      locationEnabled = true;
    }

    if (isBing && data.selectedCountry === 'US') {
      locationEnabled = true;
    }

    if (!locationEnabled) {
      return null;
    }

    return (
      <>
        <div className="form-label">{t('Location')}</div>
        <div className="location">
          <Box mb="md" className="locations">
            {data.fields.map((location, i: number) => (
              <Field
                key={location}
                component={LocationField}
                disabled={!locationEnabled}
                selectedCountry={data.selectedCountry}
                canDelete={data.fields.length > 1}
                onDelete={() => data.fields.remove(i)}
                name={location}
                placeholder={
                  locationEnabled
                    ? t('Enter location (e.g. New York, NY, USA)')
                    : t('Location not supported for selected search engine…')
                }
              />
            ))}

            {locationEnabled && (
              <Flex justify="center">
                <AccButton
                  variant="tertiary"
                  leftSection={<IconCirclePlus size={18} />}
                  onClick={() => data.fields.push('')}
                >
                  {t('Add another location')}
                </AccButton>
              </Flex>
            )}
          </Box>
        </div>
      </>
    );
  }

  render() {
    const {
      editMode,
      locale,
      handleSubmit,
      pristine,
      submitting,
      engines = [],
      featureYandex,
      featureBaidu,
      featureYoutube,
      featureNaver,
      youTubeDomain,
      mantineTheme,
      domainInfo,
    } = this.props;
    const showGoogleOptions = engines ? googleEngines(engines).length > 0 : false;

    return (
      <ModalBorder
        className="add-search-engine"
        title="test"
        header={
          <div className="modal-header">
            <Group wrap="nowrap" gap={8} className="modal-title">
              <Flag size="sm" country={locale.countryCode} fadedColors />
              {locale.region} - {locale.locale}
            </Group>
            <AccText size="sm" ml="auto" c={mantineTheme.colors.gray[5]}>
              ({t('ESC to close')})
            </AccText>
            <AccActionIcon ml={4} onClick={this.handleClose}>
              <CloseIcon
                fill={mantineTheme.colors.gray[5]}
                width={16}
                height={16}
                className="close-icon"
              />
            </AccActionIcon>
          </div>
        }
        onClose={this.handleClose}
      >
        <form onSubmit={handleSubmit(this.handleSubmit)}>
          <div className="form-label top-label">{t('Search Engines & Devices')}</div>
          <Field
            name="searchEngines"
            locale={locale}
            component={SearchSettingsField}
            validate={this.validateSettings}
            showYandex={featureYandex}
            showBaidu={featureBaidu}
            showYouTube={featureYoutube}
            showNaver={featureNaver}
            youTubeDomain={youTubeDomain}
            domainType={domainInfo?.domainType}
          />
          {engines?.filter((engine) => engine.searchTypes.length).length > 0 && ( // Only render location select if a search engine is selected
            <>
              <FieldArray
                name="locations"
                disabled={true}
                placeholder={t('Enter location (e.g. New York, NY, USA)')}
                selectedCountry={locale.countryCode}
                engines={engines}
                component={this.renderLocations}
              />
            </>
          )}
          {showGoogleOptions && (
            <>
              <div className="form-label">{t('Google Settings')}</div>
              <Field
                component={SearchEngineSettings}
                name="settings"
                showGoogleOptions={showGoogleOptions}
              />
            </>
          )}
          <ModalFooter
            primaryButtonSlot={
              <AccButton type="submit" variant="primary" disabled={pristine || submitting}>
                {editMode ? t('Edit locale') : t('Add locale')}
              </AccButton>
            }
            secondaryButtonSlot={
              <AccButton variant="tertiary" onClick={this.props.hideModal}>
                {t('Cancel')}
              </AccButton>
            }
          />
        </form>
      </ModalBorder>
    );
  }
}

const ReduxAddSearchEngine = reduxForm({
  form: 'AddSearchEngineForm',
})(AddSearchEngine);
const formSelector = formValueSelector('AddSearchEngineForm');

const mapStateToProps = (state) => {
  const engines = formSelector(state, 'searchEngines');
  return {
    ...formSelector(state, 'AddSearchEngineForm'),
    engines,
    featureYandex: state.user.organization.activePlan.featureYandex,
    featureBaidu: state.user.organization.activePlan.featureBaidu,
    featureYoutube: state.user.organization.activePlan.featureYoutube,
    featureNaver: state.user.organization.activePlan.featureNaver,
  };
};

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  initSettingField: () => {
    dispatch(
      change('AddSearchEngineForm', 'settings', {
        ignoreLocalResults: false,
        ignoreFeaturedSnippet: false,
        enableAutocorrect: false,
      }),
    );
    dispatch(change('AddSearchEngineForm', 'locations', ['']));
  },
});

export default withDomainInfo(
  withMantineTheme(connect(mapStateToProps, mapDispatchToProps)(ReduxAddSearchEngine)),
);
