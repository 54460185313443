import { useMemo } from 'react';
import { useField } from 'react-final-form';
import { Flex } from '@mantine/core';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { Field } from 'Components/Fields';
import MultiSelectValue from 'Components/MultiSelectValue';
import { allGroupsId, allGroupsUserRoles } from 'Constants/workspaces';
import { GetWorkspacesQueryHookResult, useGetWorkspacesQuery } from 'Ghql';
import { t, tct } from 'Utilities/i18n';
import Validator from 'Utilities/validation';

type Props = {
  defaultValue?: string[];
  defaultUserRole: number;
  name: string;
  userRoleFieldName: string;
  withinPortal?: boolean;
  required?: boolean;
  size?: 'default' | 'sm' | 'md' | 'lg';
  searchMinWidth?: number;
  maxDisplayedValues?: number;
  showError?: boolean;
};

type StackComponentProps = {
  moreValue: number;
};

type ValueComponentProps = {
  label: string;
  value: string;
  values: string[];
};

const StackComponent = ({ moreValue }: StackComponentProps) => {
  const moreItemsLabel = tct('+[count] more', {
    count: moreValue,
  });
  return <MultiSelectValue label={moreItemsLabel} isStack />;
};
const ValueComponent = ({ label, value, values }: ValueComponentProps) => {
  const isDisabled = values?.includes(allGroupsId) && value !== allGroupsId;
  return (
    <AccTooltip
      tooltip={
        isDisabled
          ? t('Workspace won\'t have any effect as the user has access to all groups')
          : label
      }
    >
      <MultiSelectValue label={label} disabled={isDisabled} maw={80} />
    </AccTooltip>
  );
};

const ContainerSizes = {
  sm: '36',
  md: '42',
  lg: '50',
  default: '42',
};

const WORKSPACE_ALIGNMENT_MARGIN = '13px';

const WorkspacesToSelectItems = (data: GetWorkspacesQueryHookResult['data']) => {
  const options = data?.workspacesByOrganization
    ?.map((role) => {
      if (!role?.id) return null;
      return {
        value: role.id,
        label: role.name,
      };
    })
    .filter(Boolean);
  return options || [];
};

const FieldWorkspace = (props: Props) => {
  const {
    size,
    userRoleFieldName,
    defaultValue,
    defaultUserRole,
    name,
    withinPortal,
    searchMinWidth,
    maxDisplayedValues,
    required,
    showError,
  } = props;
  const userRoleField = useField(userRoleFieldName);
  const { data, loading } = useGetWorkspacesQuery();
  const options = useMemo(() => WorkspacesToSelectItems(data), [data]);
  const userRole = userRoleField.input.value ?? defaultUserRole;
  if (allGroupsUserRoles.includes(userRole)) {
    return (
      <AccTooltip tooltip={t('Admin and Superusers have access to all groups')}>
        <Flex
          mih={ContainerSizes[size || 'default']}
          ml={WORKSPACE_ALIGNMENT_MARGIN}
          align="center"
        >
          <MultiSelectValue label={options.find((o) => o?.value === allGroupsId)?.label} />
        </Flex>
      </AccTooltip>
    );
  }

  return (
    <AccTooltip tooltip={t('Edit workspace access')}>
      <Field.MultiSelect
        aria-label={t('Edit workspace access')}
        value={defaultValue}
        options={options}
        name={name}
        showError={showError}
        validate={required ? Validator.required : undefined}
        required={required}
        placeholder={t('No workspace selected')}
        noResultsText={t('There are no Workspaces with that name')}
        withinPortal={withinPortal}
        size={size}
        clearable={false}
        creatable={false}
        isLoading={loading}
        hidePickedOptions={false}
        removeOnBackspace={false}
        searchMinWidth={searchMinWidth}
        maxDisplayedValues={maxDisplayedValues}
        stackComponent={StackComponent}
        valueComponent={ValueComponent}
      />
    </AccTooltip>
  );
};

FieldWorkspace.defaultProps = {
  size: 'default',
  maxDisplayedValues: 3,
  searchMinWidth: 100,
};

export default FieldWorkspace;
