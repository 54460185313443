import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DataTable, SelectConfig, SelectRowType } from 'Components/DataTable';
import { useFilteredTableColumns } from 'Components/Modal/Content/TableSettingsModal/useFilteredColumns';
import { CountByCountryNode } from 'Ghql';
import { useSpecificFilter } from 'Hooks';
import { useDomainId } from 'Hooks/data/domain/useQueryDomainInfo';
import { useMaxKeywordsCount } from 'Hooks/data/plan/useActivePlan';
import { useDisplayCurrency } from 'Hooks/displayCurrency';
import { useFetchKeywords } from 'Pages/Keywords/Table/hooks/keyword/useTableKeyword';
import { useTableSubscription } from 'Pages/Keywords/Table/support/useTableSubscription';
import { useKeywordTableInfo } from 'Pages/Keywords/Table/useKeywordTableInfo';
import { selectDomainList } from 'Selectors/DomainSelector';
import { FREE_TEXT_DOMAIN } from 'Types/Filter';
import { TableIDs } from 'Types/Table';
import { WithRouter, withRouter } from 'Utilities/Router';
import { t } from 'Utilities/i18n';
import { DEFAULT_KEYDIS_ORDERING } from '../../../Keywords/Table/hooks/keyword/constants';
import { getKeydisCountryFilterValue } from '../../support/helpers';
import styles from './KeywordDiscoveryTable.module.scss';

interface Props extends WithRouter {
  selectedCountry?: CountByCountryNode | null;
  tableType?: typeof TableIDs.KEYWORD_DISCOVERY | typeof TableIDs.RESEARCH;
  forceRenderSkeleton?: boolean;
}

const KeywordDiscoveryTable = ({
  history,
  selectedCountry,
  forceRenderSkeleton,
  tableType = TableIDs.KEYWORD_DISCOVERY,
}: Props) => {
  const domainId = useDomainId();
  const tableName = tableType;
  const freeTextDomainFilter = useSpecificFilter(FREE_TEXT_DOMAIN);
  const domainListId = useSelector(selectDomainList);
  const { displayCurrency } = useDisplayCurrency(true);

  const { columns, ...tableConfig } = useKeywordTableInfo({
    tableName,
    history,
    displayCurrency,
    isKeyDis: true,
  });

  const resultColumns = useFilteredTableColumns(columns, TableIDs.KEYWORD_DISCOVERY); // Don't take research here, as config is the same, but not implemented specifically

  const fetchKeywords = useFetchKeywords(tableName, displayCurrency, selectedCountry);

  useTableSubscription(tableName, {
    subscribeTo: 'domain',
    subscribeToArg: domainId || domainListId || [],
  });

  const maxKeywordsCount = useMaxKeywordsCount();
  const selectConfig: SelectConfig = useMemo(
    () => ({
      maxSelectCount: maxKeywordsCount,
      selectRowType: SelectRowType.MULTI_PAGE_SELECT,
    }),
    [maxKeywordsCount],
  );
  const emptyOptions = useMemo(
    () => ({
      title: t('No Data'),
      subTitle: t('There are currently no keywords to discover.'),
    }),
    [],
  );

  const dataKey = `${getKeydisCountryFilterValue(
    selectedCountry,
    Boolean(freeTextDomainFilter),
  )}-${displayCurrency}`;

  return (
    <div className={styles['overuse-table-container']}>
      <DataTable
        tableId={tableName}
        columns={resultColumns}
        fetchData={fetchKeywords}
        emptyOptions={emptyOptions}
        pageSize={100}
        {...tableConfig}
        dataKey={dataKey}
        defaultOrdering={DEFAULT_KEYDIS_ORDERING}
        selectConfig={selectConfig}
        skipSelectedCount
        unmountStore
        showCountInfo
        forceRenderSkeleton={forceRenderSkeleton}
      />
    </div>
  );
};
export default withRouter(KeywordDiscoveryTable);
