import { OverviewChartRowNode } from 'Ghql';
import colorScheme from 'Utilities/colors';
import { t } from 'Utilities/i18n';

const colors = colorScheme.graphs;

export const getSoVTagsColor = (x: any, index: number) => {
  let name = x.key;
  let color = colors.graphBlueColors[index % colors.graphBlueColors.length];

  if (x.otherTags) {
    name = t('Other tags');
    color = colors.stats.other;
  } else if (x.key === '') {
    name = t('No tags');
    color = colors.stats.other;
  }

  return { name, color };
};

export const getSoVLandingPagesColors = (x: OverviewChartRowNode | null, index: number) => {
  let name = x?.key || undefined;
  let color = colors.graphBlueColors[index % colors.graphBlueColors.length];

  if (x?.key === '') {
    name = t('Other');
    color = colors.stats.other;
  }
  return { name, color };
};
