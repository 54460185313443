import { useCallback, useEffect, useRef } from 'react';
import { useApolloClient } from '@apollo/client';
import AccButton from 'Components/AccButton/AccButton';
import { Field, Form } from 'Components/Fields';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import { useAddGscAccountMutation, useGscWebsitePage_GscWebsitesQuery } from 'Ghql';
import { useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import { useUser } from 'Hooks/data/user/useUser';
import toast from 'Hooks/useToast';
import {
  EventName,
  TrackingKey,
  trackEventMixpanel,
  useMixpanel,
} from 'Utilities/Analytics/mixpanel';
import { useUpdateStoredUserSettings } from 'Utilities/Graphql/hooks/useUpdateStoredUserSettings';
import { t } from 'Utilities/i18n/index';
import Validator from 'Utilities/validation';

type Props = {
  domainId: string;
  connectionId: string;
  onBack: (...args: Array<any>) => any;
  onSubmit: (...args: Array<any>) => any;
};

const GSCWebsitePage = (props: Props) => {
  const { onBack, domainId, connectionId, onSubmit } = props;

  const updateUserSettingsField = useUpdateStoredUserSettings();

  const trackEvent = useMixpanel();

  const [performCreateGSCConnection] = useAddGscAccountMutation();

  const trackClick = useCallback(
    (eventName: string) => {
      const extraProps = {
        Variant: eventName,
        TrackingKey: TrackingKey.GSCWebsite,
      };
      trackEvent(EventName.ConnectToGSC, extraProps);
    },
    [trackEvent],
  );

  const client = useApolloClient();

  const user = useUser();

  const isGSCConnected = user.organization?.hasValidGscConnection;

  const eventTracked = useRef(false);

  useEffect(() => {
    if (eventTracked.current) {
      return;
    }
    trackClick('Open');
    eventTracked.current = true;
  }, [trackClick]);

  const handleOnSubmit = async (value) => {
    const addGoogleSearchConsoleAccountInput = {
      domainId: `${domainId}`,
      connectionId,
      websiteUrl: value.gscWebsite,
    };

    try {
      const res = await performCreateGSCConnection({
        variables: {
          addGoogleSearchConsoleAccountInput,
        },
      });
      const domainOb = res.data?.addGoogleSearchConsoleAccount?.domain;
      if (domainOb) {
        onSubmit(domainOb);

        client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'user' });
        client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: `domain({"id":"${addGoogleSearchConsoleAccountInput.domainId}"})`,
        });
        client.cache.gc();
        updateUserSettingsField();

        trackEventMixpanel(EventName.ConnectToGSC, '', {
          domainId: addGoogleSearchConsoleAccountInput.domainId,
          connectionId: addGoogleSearchConsoleAccountInput.connectionId,
          websiteUrl: addGoogleSearchConsoleAccountInput.websiteUrl,
          Variant: isGSCConnected ? 'Success' : 'NewGSCConnectionSuccess',
          TrackingKey: TrackingKey.GSCWebsite,
        });
      } else if (res.errors) {
        toast.error(t('Failed to connect to Google Search Console'));
        console.error(res.errors);
      }
    } catch {
      toast.error(t('Failed to connect to Google Search Console'));
    }
  };

  const { data, loading } = useGscWebsitePage_GscWebsitesQuery({
    variables: {
      goaAccountID: connectionId,
    },
  });

  const domainInfo = useQueryDomainInfo();

  const domainName = domainInfo?.domainInfo?.domain;

  const websites = !loading && data?.googleConnection ? data?.googleConnection.gscSites : [];

  const sortedWebsites: any[] = [];

  if (domainName) {
    websites?.forEach((website) => {
      if (website?.siteUrl?.includes(domainName)) {
        sortedWebsites.push(website);
      }
    });
  }

  websites?.forEach((website) => {
    if (
      domainName
        ? website?.siteUrl?.includes('https://') && !website?.siteUrl?.includes(domainName)
        : website?.siteUrl?.includes('https://')
    ) {
      sortedWebsites.push(website);
    }
  });

  websites?.forEach((website) => {
    if (
      domainName
        ? !website?.siteUrl?.includes('https://') && !website?.siteUrl?.includes(domainName)
        : !website?.siteUrl?.includes('https://')
    ) {
      sortedWebsites.push(website);
    }
  });

  if (!loading && websites?.length === 0) {
    return (
      <>
        <div>
          {t('We could not find any properties. Please check your Google Search Console account.')}
        </div>
        <ModalFooter
          primaryButtonSlot={
            <AccButton
              variant="tertiary"
              onClick={() => {
                onBack();
                trackEventMixpanel(EventName.ConnectToGSC, '', {
                  Variant: 'Back',
                  TrackingKey: TrackingKey.GSCWebsite,
                });
              }}
            >
              {t('Back')}
            </AccButton>
          }
        />
      </>
    );
  }

  return (
    <Form onSubmit={handleOnSubmit}>
      <div className="form-label">{t('Select Property')}</div>
      <div>
        <Field.Select
          useFirstOptionAsDefault
          name="gscWebsite"
          id="gscWebsite"
          type="text"
          placeholder={''}
          disabled={loading}
          isLoading={loading}
          options={sortedWebsites?.map((website) => ({
            value: website?.siteUrl || '',
            label: website?.siteUrl || '',
          }))}
          validate={Validator.required}
          helpText={
            loading
              ? t('Loading…')
              : t('Please select the Google Search Console property that you wish to use.')
          }
        />
      </div>
      <ModalFooter
        primaryButtonSlot={
          <AccButton ml={8} variant="primary" type="submit">
            {t('Select website')}
          </AccButton>
        }
        secondaryButtonSlot={
          <AccButton
            variant="tertiary"
            onClick={() => {
              onBack();
              trackEventMixpanel(EventName.ConnectToGSC, '', {
                Variant: 'Back',
                TrackingKey: TrackingKey.GSCWebsite,
              });
            }}
          >
            {t('Back')}
          </AccButton>
        }
      />
    </Form>
  );
};

export default GSCWebsitePage;
