import { useEffect } from 'react';
import { useStore } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useModal } from 'Hooks/base/useModal';
import { selectModal } from '../../../Selectors/ModalSelector';

/**
 * Hide modals on route change, skip hiding if modelHide: false in location.state.
 */
export const ModalCloseListener = () => {
  const location = useLocation();
  const { hideModal } = useModal();
  const store = useStore();

  useEffect(() => {
    const modalHide = (location.state as { modalHide?: boolean })?.modalHide ?? true;

    if (modalHide) {
      const modals = selectModal(store.getState())?.list;

      if (modals?.length) {
        modals.map(() => hideModal());
      }
    }
  }, [location.pathname, location.state, store]);
  return null;
};
