import * as React from 'react';
import { useContext } from 'react';

// eslint-disable-next-line import/no-unused-modules
export interface RowContextProps {
  id: React.Key;
  expandExtraRows: boolean;
  customExpand: boolean;
  toggleExpandRows(): void;
  toggleCustomExpand(props?: { [key: string]: boolean | string }): void;
  inView?: boolean;
  userHasWriteAccess?: boolean;
  expandComponentProps: { [key: string]: boolean | string };
}

const RowContext = React.createContext<RowContextProps>({} as RowContextProps);

export interface WithRowContext {
  rowContext: RowContextProps;
}

export const useRowContext = () => useContext(RowContext);

export default RowContext;
