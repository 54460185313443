import { Flex } from '@mantine/core';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import AccText from 'Components/Text/AccText';

type Props = {
  item?: any | null | undefined;
  labelMaxWidth?: number;
  placeholder?: string;
  title: string;
  labelFunc?: (...args: Array<any>) => any;
  tooltip?: string;
};

const DropdownButton = (props: Props) => {
  const { item, placeholder, title, labelFunc, tooltip, labelMaxWidth } = props;

  const label = item && labelFunc ? labelFunc(item) : item || placeholder;

  const Contents = () => (
    <Flex className="dropdown-arrow" direction="column">
      <AccText size="sm" c="gray.7" ta="left" style={{ whiteSpace: 'nowrap' }}>
        {title}
      </AccText>
      <AccText size="sm" fw={400} maw={labelMaxWidth} overflowEllipsis ta="left">
        {label}
      </AccText>
    </Flex>
  );

  return tooltip ? (
    <AccTooltip tooltip={tooltip}>
      <Contents />
    </AccTooltip>
  ) : (
    <Contents />
  );
};

export default DropdownButton;
