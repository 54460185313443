import { Link } from 'react-router-dom';
import Cell from 'Components/AccuTable/CellRenderer/HelperComponents/AccuCell';
import FormatNumber from 'Components/FormatNumber/new';
import { useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import { FilterAttribute } from 'Types/Filter';
import { FilterTrackingKey, trackFilterEventWithNavigation } from 'Utilities/Analytics/mixpanel';
import { getKeywordsFilterLink } from '../support/helpers';
import { LandingTableItem } from '../support/types';

type Props = { record: LandingTableItem };

export const TableKeywordsCount = ({ record }: Props) => {
  const { domainInfo } = useQueryDomainInfo();

  if (!domainInfo?.domain) {
    return null;
  }
  const link = getKeywordsFilterLink('', record?.path ?? '');

  const handleTrackFilterEvent = () => {
    trackFilterEventWithNavigation(
      link,
      FilterTrackingKey.LandingPagesToKeywords,
      FilterAttribute.HIGHEST_RANKING_PAGE,
    );
  };

  return (
    <Cell rightAligned>
      <Link to={link} className="table-link" onClick={handleTrackFilterEvent}>
        <FormatNumber value={record?.keywords} />
      </Link>
    </Cell>
  );
};
