import { observer } from 'mobx-react';
import TableEmptyState from 'Components/TableEmptyState';
import { t } from 'Utilities/i18n';
import { useContextTableStore } from '../../store/components/TableStoreContext';

interface DataTablePlaceholderProps {
  title?: string;
  subTitle?: string | JSX.Element;
  noFilters?: boolean;
  noDataWithFiltersTitle?: string;
  noDataWithFiltersSubTitle?: string;
}

export const TablePlaceholder = observer(
  ({
    title = t('No Data'),
    subTitle = t('There are currently no keywords.'),
    noFilters = false,
    noDataWithFiltersTitle = '',
    noDataWithFiltersSubTitle = '',
  }: DataTablePlaceholderProps) => {
    const tableStore = useContextTableStore();

    if (!tableStore?.initialDataLoaded || tableStore?.loading) {
      return null;
    }

    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <TableEmptyState
        list={tableStore?.data}
        title={title}
        subTitle={subTitle}
        filtersPossible={!noFilters}
        noDataWithFiltersTitle={noDataWithFiltersTitle}
        noDataWithFiltersSubTitle={noDataWithFiltersSubTitle}
      />
    );
  },
);
