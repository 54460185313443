import cn from 'classnames';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import AddFilter from 'Components/Filters/AddFilter/component';
import FilterItem from 'Components/Filters/FilterItem/component';
import type { FilterBase } from 'Types/Filter';
import type { FilterSet } from 'Types/FilterSet';
import { checkForFilterRegexErrorReduxForm } from 'Utilities/validation';

type Props = {
  filterSet: FilterSet;
  value?: FilterBase[];
  onChange: (newFilters: Array<FilterBase>) => void;
  showError?: boolean;
  disabled?: boolean;
  tooltip?: string;
  id?: string;
};

const FiltersInput = (props: Props) => {
  const { value: filters = [], filterSet, disabled, tooltip, onChange, id } = props;

  const handleAdd = (newFilter: FilterBase) => {
    const newFilters = [...filters, newFilter];
    onChange(newFilters);
  };
  const handleDelete = (filterToRemove: FilterBase) => {
    const newFilters = filters.filter((filter) => filter.attribute !== filterToRemove.attribute);
    onChange(newFilters);
  };
  const handleUpdate = (newFilter: FilterBase) => {
    const newFilters = filters.map((filter) => {
      return filter.attribute === newFilter.attribute ? newFilter : filter;
    });

    // Validate regex filters, throw redux form submission error if invalid
    newFilters.forEach((filter) => {
      checkForFilterRegexErrorReduxForm(filter);
    });

    onChange(newFilters);
  };

  return (
    <AccTooltip tooltip={tooltip} placement="top" old>
      <div
        className={cn('filters filters-input', {
          disabled,
        })}
        id={id}
      >
        <div className="filter-item-list filter-item-list--narrow">
          {filters?.map((filter) => (
            <FilterItem
              key={filter.attribute}
              filter={filter}
              onDelete={handleDelete}
              onUpdate={handleUpdate}
            />
          ))}
          <AddFilter
            filters={filters}
            filterSet={filterSet}
            onAdd={handleAdd}
            disabled={disabled}
            flip
          />
        </div>
      </div>
    </AccTooltip>
  );
};

export default FiltersInput;
