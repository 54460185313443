const NAME = 'name';
const ELEMENTS = 'elements';
const SYSTEM_TEMPLATE = 'system_template';
const DEFAULT_TEMPLATE = 'default_template';
const ACTIONS = 'actions';

export const ColumnIDs = {
  NAME,
  ELEMENTS,
  SYSTEM_TEMPLATE,
  DEFAULT_TEMPLATE,
  ACTIONS,
} as const;
