import { Component } from 'react';
import { connect } from 'react-redux';
import { hideModal } from 'Actions/ModalAction';
import AccButton from 'Components/AccButton/AccButton';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import { t } from 'Utilities/i18n';
import { redirectToRoot } from 'Utilities/underdash';

type Props = {
  hideModal: (...args: Array<any>) => any;
};

class NotAllowedModal extends Component<Props> {
  addDomain = () => {
    redirectToRoot();
  };
  refreshPage = () => {
    window.location.reload();
  };

  render() {
    return (
      <ModalBorder title={t('Data Not Found')} onClose={this.props.hideModal}>
        <div className="serp-info-modal-content">
          <p>
            {t(
              'The data you are editing/viewing no longer exists or your are logged in to another account.',
            )}
          </p>
          <ModalFooter
            primaryButtonSlot={
              <AccButton onClick={this.addDomain} variant="primary">
                {t('Go to dashboard')}
              </AccButton>
            }
            secondaryButtonSlot={
              <AccButton onClick={this.refreshPage} variant="secondary">
                {t('Reload')}
              </AccButton>
            }
          />
        </div>
      </ModalBorder>
    );
  }
}

export default connect(null, {
  hideModal,
})(NotAllowedModal);
