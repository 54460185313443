import { Component } from 'react';
import { Flex } from '@mantine/core';
import AccButton from 'Components/AccButton/AccButton';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import { t, tct } from 'Utilities/i18n/index';
import './cancel-plan.scss';

class CancelPlanCall extends Component<any> {
  state = {
    step: 1,
  };
  nextStep = () => {
    this.setState({
      step: 2,
    });
  };

  render() {
    const { step } = this.state;

    if (step === 1) {
      return (
        <div>
          <p>{t('We’re sorry to hear that you want to cancel your AccuRanker subscription')}</p>
          <p>
            {t('Click next to follow the process on how to cancel your AccuRanker subscription.')}
          </p>

          <ModalFooter
            primaryButtonSlot={
              <AccButton onClick={this.nextStep} variant="destructive">
                {t('Next')}
              </AccButton>
            }
            secondaryButtonSlot={
              <AccButton variant="tertiary" onClick={this.props.onClose}>
                {t('Cancel')}
              </AccButton>
            }
          />
        </div>
      );
    }

    return (
      <div>
        <p>
          {tct(
            'In order to cancel your AccuRanker subscription, please call our customer service at [link:+45 89 87 39 44] or send an email to [email: hello@accuranker.com]. Customer service is available from 08:00 to 16:00, Mon-Fri, CET.',
            {
              link: <a href="tel:+4589873944" className="link" />,
              email: <a href="mailto:hello@accuranker.com" className="link" />,
            },
          )}
        </p>
        <p>
          {t(
            'Please note that a subscription needs to be cancelled 14 days before the subscription is set for renewal.',
          )}
        </p>
        <p>
          {t(
            'Your subscription has been cancelled when you receive a e-mail confirmation from our customer representative.',
          )}
        </p>

        <Flex justify="center" align="center" my={12} gap={24}>
          <AccButton variant="secondary" href="mailto:hello@accuranker.com" className="fixed-width">
            {t('hello@accuranker.com')}
          </AccButton>
          <AccButton variant="secondary" href="tel:+4589873944" className="fixed-width">
            {t('Call +45 89 87 39 44')}
          </AccButton>
        </Flex>
        <ModalFooter
          primaryButtonSlot={
            <AccButton variant="secondary" onClick={this.props.onClose}>
              {t('Close this window')}
            </AccButton>
          }
        />
      </div>
    );
  }
}

export default CancelPlanCall;
