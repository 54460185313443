import moment from 'moment';
import { PublicDomainFragment } from 'Ghql';
import { DomainInfo } from 'Query';
import { formatDateTime } from 'Utilities/format';

export function getNextScheduledRefresh(
  domainInfo: DomainInfo | PublicDomainFragment | undefined,
): string {
  const lastScrapedMoment = moment(domainInfo?.lastScraped);

  // Check if the last full scrape date is on the same day as the scheduled rescrape
  const isSameDayRescrape = moment(domainInfo?.lastFullScrape).isSame(
    lastScrapedMoment.clone().add(1, 'day'),
    'day',
  );

  // Adjust next scrape date by 2 days if rescrape was on the scheduled day, otherwise by 1 day
  const daysToAdd = isSameDayRescrape ? 2 : 1;

  return formatDateTime(lastScrapedMoment.add(daysToAdd, 'day').toString());
}
