import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { toJS } from 'mobx';
import { deleteFilters } from 'Actions/FilterAction';
import { useFilters } from 'Hooks';
import { useActions } from 'Hooks/redux/useActions';
import { sitemapping_nestedSitemapQuery as SiteMappingNestedQuery } from 'Query';
import { FilterAttribute } from 'Types/Filter';
import { nestedSitemapQuery } from '../sitemap.query';
import { shouldDeleteURLFilter, siteMappingTreeModes, siteMappingViewModes } from './helpers';
import { useSiteMappingFilters } from './hooks/useSiteMappingFilters';
import { useSitemapChartStore } from './store';
import { SiteMapViewMode, SitemapNode, TreeViewMode } from './types';

const useEffectButNotOnFirstRender = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      func();
    } else {
      didMount.current = true;
    }
  }, deps);
};

/** Takes a landing page and extracts the necessary expanded nodes for this landing page to be shown in the tree */
function getExpandedNodes(selectedLandingPage: string) {
  const expandedNodes = ['/'];
  for (let i = 1; i < selectedLandingPage.length; i++) {
    if (selectedLandingPage.charAt(i) === '/') {
      expandedNodes.push(selectedLandingPage.substring(0, i));
    }
  }
  return expandedNodes;
}
/** Recursively finds a specific node in the tree. Give it the root and a pathSegment to find any node anywhere */
const findNode = (node: SitemapNode, pathSegment: string) => {
  if (node.pathSegment === pathSegment) {
    return node;
  }
  if (node.children) {
    for (const child of node.children) {
      if (pathSegment.includes(child.pathSegment)) {
        return findNode(child, pathSegment);
      }
    }
  }
};

export const useSiteMappingData = () => {
  const filters = useFilters();
  const sitemapChartStore = useSitemapChartStore();
  const location = useLocation();
  const viewModes = siteMappingViewModes;
  const TreeViewModes = siteMappingTreeModes;

  const actions = useActions({ deleteFilters });
  const { loadingSubdomains, defaultSubdomainFilter } = useSiteMappingFilters();
  const [selectedNode, setSelectedNode] = useState<SitemapNode | null>(
    toJS(sitemapChartStore?.selectedNode) ?? null,
  );

  const [viewMode, setViewMode] = useState<SiteMapViewMode>(
    toJS(sitemapChartStore?.viewMode) || viewModes[0],
  );

  const [treeViewMode, setTreeViewMode] = useState<TreeViewMode>(
    toJS(sitemapChartStore?.treeViewMode) || TreeViewModes[1],
  );

  const [orderBy, setOrderBy] = useState(toJS(sitemapChartStore?.orderBy) || 'total_keywords');

  const [maxChildren, setMaxChildren] = useState(toJS(sitemapChartStore?.maxChildren) || 40);
  // Handling the transitioning back from keydis is a bit tricky.
  // We want to delete the URL filter if it was set from sitemap, which we check by verifying if the
  // selected node from sitemapping state matches the url filter.
  // In addition, we want to clear the selected node each time the filters are adjusted, but not if the adjustment
  // is the deletion of the url filter when returning from keydis. This is the reason for the effect used not on first render.
  const transitionedFromKeydis = useRef(false);

  // On first render we check whether there is a set which matches the selecting node,
  // which indicates that we navigated to keydis and back.
  useEffect(() => {
    if (shouldDeleteURLFilter(filters, sitemapChartStore?.selectedNode?.pathSegment)) {
      actions.deleteFilters([FilterAttribute.HIGHEST_RANKING_PAGE, FilterAttribute.COMPETITOR_URL]);
      transitionedFromKeydis.current = true;
    }
  }, []);

  // When resetting filters, we should also reset the selected node, as the data in the detail panel becomes invalid.
  // This effect must be called before the one setting the selectedNode from the sitemapChartStore!
  // However it should not be called when returning from keydis, which is why we dont call it on first render
  useEffectButNotOnFirstRender(() => {
    if (!transitionedFromKeydis.current) {
      setSelectedNode(null);
      sitemapChartStore?.setDefaultState();
    }
    transitionedFromKeydis.current = false;
  }, [filters]);

  useEffect(() => {
    if (sitemapChartStore && sitemapChartStore?.selectedNode) {
      setSelectedNode(toJS(sitemapChartStore?.selectedNode));
    }
  }, [sitemapChartStore]);

  const mode = viewMode.id;

  const { data, loading, error } = useQuery<SiteMappingNestedQuery>(nestedSitemapQuery, {
    fetchPolicy: 'cache-first',
    variables: {
      filters: defaultSubdomainFilter ? [...filters, defaultSubdomainFilter] : filters,
      mode,
      orderBy,
      maxChildren,
    },
    skip: !sitemapChartStore || loadingSubdomains,
  });

  const nodeSelectionHandler = (node: SitemapNode | null) => {
    if (node?.pathSegment !== selectedNode?.pathSegment) {
      sitemapChartStore?.setSelectedNode(node);
      setSelectedNode(node);
    }
  };

  const handleSetViewMode = (newMode: SiteMapViewMode) => {
    if (newMode.id !== viewMode.id) {
      sitemapChartStore?.setViewMode(newMode);
      setViewMode(newMode);
      nodeSelectionHandler(null);
    }
  };

  const handleSetMaxChildren = (value: number) => {
    // Note if you do check on value !== maxChildren here you run into this
    // https://accuranker.myjetbrains.com/youtrack/agiles/124-5/current?issue=ARR-2577
    sitemapChartStore?.setMaxChildren(value);
    setMaxChildren(value);
    nodeSelectionHandler(null);
  };

  const handleSetTreeViewMode = (newMode: TreeViewMode) => {
    if (newMode.id !== treeViewMode.id) {
      sitemapChartStore?.setTreeViewMode(newMode);
      setTreeViewMode(newMode);
    }
  };

  const handleSetOrderBy = (newOrderBy: string) => {
    if (newOrderBy !== orderBy) {
      sitemapChartStore?.setOrderBy(newOrderBy);
      setOrderBy(newOrderBy);

      nodeSelectionHandler(null);
    }
  };

  const rootNode = data?.sitemappings?.sitemap as SitemapNode;

  let chosenNode;

  // When coming from another page, it should be possible to set the expanded landing page
  // This code checks if a landing page is specified and does that
  const locationState = location?.state as { landingPage?: string };
  const selectedLandingPage = locationState?.landingPage;
  if (rootNode && selectedLandingPage) {
    if (selectedLandingPage) {
      const expandedNodes = getExpandedNodes(selectedLandingPage);
      sitemapChartStore?.saveExpandedNodes(expandedNodes);
      chosenNode = findNode(rootNode, selectedLandingPage);
      if (chosenNode) {
        nodeSelectionHandler(chosenNode);
      }
    }
    // Now reset the state so we do not keep running this on every render!
    if (location && location?.state) {
      locationState.landingPage = undefined;
    }
  }

  const resultSelectedNode =
    chosenNode || selectedNode || toJS(sitemapChartStore?.selectedNode) || rootNode;

  const countryCode = ''; // data?.sitemappings?.countrylocale?.countryCode
  return {
    rootNode,
    selectedNode: resultSelectedNode,
    nodeSelectionHandler,
    loading,
    countryCode,
    error,
    filters,
    loadingSubdomains,
    viewModeId: toJS(sitemapChartStore?.viewMode)?.id || viewMode.id,
    setViewMode: handleSetViewMode,
    treeViewMode,
    setTreeViewMode: handleSetTreeViewMode,
    setOrderBy: handleSetOrderBy,
    maxChildren,
    setMaxChildren: handleSetMaxChildren,
  };
};
