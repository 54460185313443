import { useApolloClient } from '@apollo/client';
import { Group, useMantineTheme } from '@mantine/core';
import {
  IconDeviceDesktop,
  IconDeviceMobile,
  IconDeviceTablet,
  TablerIconsProps,
} from '@tabler/icons-react';
import capitalize from 'lodash/capitalize';
import moment from 'moment';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { ColumnType, DataTable, QueryOrder, TableFetchDataCallBack } from 'Components/DataTable';
import Ellipsis from 'Components/Ellipsis';
import { Flag } from 'Components/Flag';
import {
  AccuApiSearchHistoryDocument,
  AccuApiSearchHistoryQuery,
  AccuApiSearchHistoryQueryVariables,
} from 'Ghql';
import * as Sort from 'Types/Sort';
import { TableIDs } from 'Types/Table';
import { t } from 'Utilities/i18n';
import { notEmpty } from 'Utilities/underdash';
import { ColumnIDs } from './ColumnIDs';

/**
 * Get country code from locale.
 *
 * Checking if the data is returned in one of two different formats. */
const getCountryCode = (record: AccuApiSearchHistory): string =>
  (record.locale.includes('_') ? record.locale.split('_')[0] : record.locale.split('-')[1]);

const getDevice = (
  record: AccuApiSearchHistory,
): { label: string; icon: (props: TablerIconsProps) => JSX.Element } => {
  return record.mobile
    ? { label: t('Mobile'), icon: IconDeviceMobile }
    : record.tablet
    ? { label: t('Tablet'), icon: IconDeviceTablet }
    : { label: t('Desktop'), icon: IconDeviceDesktop };
};

type AccuApiSearchHistory = {
  callback: null;
  created_at: string;
  engine: string;
  geo: string;
  id: string;
  keyword: string;
  locale: string;
  mobile: boolean;
  save_html: boolean;
  tablet: boolean;
};

const useFetchData = (): TableFetchDataCallBack => {
  const client = useApolloClient();

  return async () => {
    return client
      .query<AccuApiSearchHistoryQuery, AccuApiSearchHistoryQueryVariables>({
        query: AccuApiSearchHistoryDocument,
        fetchPolicy: 'network-only',
      })
      .then((response) => {
        const history: AccuApiSearchHistory[] =
          response.data?.accuApiSearchHistory?.history?.filter(notEmpty) || [];

        return { data: history, length: history?.length ?? 0 };
      });
  };
};

const SearchHistoryTable = () => {
  const fetchData = useFetchData();
  const theme = useMantineTheme();

  const columns: ColumnType<AccuApiSearchHistory>[] = [
    {
      id: ColumnIDs.DATE,
      title: t('Date'),
      flex: false,
      width: 160,
      cellRenderer: (props) => <Ellipsis>{moment(props.record.created_at).format('lll')}</Ellipsis>,
      onHeaderCell: () => ({
        ordering: {
          defaultOrder: QueryOrder.ASC,
          orderBy: 'created_at',
        },
      }),
    },
    {
      id: ColumnIDs.KEYWORD,
      title: t('Keyword'),
      flex: true,
      width: 80,
      cellRenderer: ({ record }) => <span>{record.keyword}</span>,
      onHeaderCell: () => ({
        ordering: {
          defaultOrder: QueryOrder.DESC,
          orderBy: 'keyword',
        },
      }),
    },
    {
      id: ColumnIDs.ENGINE,
      title: t('Engine'),
      flex: false,
      width: 80,
      cellRenderer: ({ record }) => <span>{capitalize(record.engine)}</span>,
    },
    {
      id: ColumnIDs.DEVICE,
      title: t('Device'),
      flex: false,
      width: 100,
      cellRenderer: ({ record }) => {
        const device = getDevice(record);
        const Icon = device.icon;
        return (
          <span>
            <Group wrap="nowrap" gap={6}>
              <span>
                <Icon size={20} color={theme.colors.gray[6]} />
              </span>
              <span>{device.label}</span>
            </Group>
          </span>
        );
      },
    },
    {
      id: ColumnIDs.LOCALE,
      title: t('Locale'),
      flex: false,
      width: 130,
      cellRenderer: ({ record }) => (
        <span>
          <AccTooltip tooltip={record.locale} placement="top" old>
            <Group wrap="nowrap" gap={6} id={record.locale}>
              <Flag size="sm" country={getCountryCode(record)} />
              <span>{record.locale}</span>
            </Group>
          </AccTooltip>
        </span>
      ),
      onHeaderCell: () => ({
        ordering: {
          defaultOrder: QueryOrder.DESC,
          orderBy: 'locale',
        },
      }),
    },
    {
      id: ColumnIDs.GEO,
      title: t('Geo'),
      flex: true,
      width: 180,
      cellRenderer: ({ record }) => <span>{record.geo}</span>,
      onHeaderCell: () => ({
        ordering: {
          defaultOrder: QueryOrder.DESC,
          orderBy: 'geo',
        },
      }),
    },
  ];

  const defaultOrdering = {
    order: QueryOrder.ASC,
    orderBy: ColumnIDs.DATE,
  };

  return (
    <DataTable
      tableId={TableIDs.ACCU_API_SEARCH_HISTORY}
      fetchData={fetchData}
      columns={columns}
      emptyOptions={{
        title: t('No History'),
        subTitle: t('There is currently no search history for this account.'),
      }}
      pageSize={25}
      defaultOrdering={defaultOrdering}
      offlineFilter={{
        tableName: TableIDs.ACCU_API_SEARCH_HISTORY,
        mappings: {
          [ColumnIDs.DATE]: 'created_at',
          [ColumnIDs.KEYWORD]: 'keyword',
          [ColumnIDs.ENGINE]: 'engine',
          [ColumnIDs.LOCALE]: 'locale',
          [ColumnIDs.GEO]: 'geo',
          [ColumnIDs.DEVICE]: 'desktop',
        },
        sortTypes: {
          created_at: Sort.DATE,
          keyword: Sort.NATURAL,
          engine: Sort.NATURAL,
          locale: Sort.NATURAL,
          geo: Sort.NATURAL,
          desktop: Sort.NATURAL,
        },
        skipAll: true,
      }}
    />
  );
};

export default SearchHistoryTable;
