import React from 'react';
import { devError } from '../../Utilities/log';
import { icons } from '../AIcon';

// eslint-disable-next-line import/no-unused-modules
export type IconType = keyof typeof icons;

type IconProps = { type: IconType } & React.SVGProps<SVGSVGElement>;

export const Icon = ({ type: iconType, ...props }: IconProps) => {
  const ResultIcon = icons[iconType];

  if (!ResultIcon) {
    devError(`No icon for iconType: ${iconType}`);
    return null;
  }
  return <ResultIcon {...props} />;
};
