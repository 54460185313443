import { IconFilter } from '@tabler/icons-react';
import cn from 'classnames';

type Props = {
  activeFilter: boolean;
  filterId?: string;
  filterContent?: React.ReactNode;
  filterClassName?: string;
};

export const FilterBtn = ({ activeFilter, filterId, filterContent, filterClassName }: Props) => {
  const className = cn(
    'column-filter-btn',
    {
      'column-filter-active': activeFilter,
    },
    filterClassName,
  );

  return (
    <div className={className} id={filterId}>
      {filterContent ?? <IconFilter size={16} className="column-filter-btn__icon" />}
    </div>
  );
};
