import React, { PureComponent } from 'react';
import debounce from 'lodash/debounce';
import { Field } from 'redux-form';
import { CheckBoxGroup, DropdownField } from 'Components/Forms/Fields';
import SearchInput from 'Components/SearchInput';
import { MODAL_SHOW_DELAY } from 'Constants';
import { FilterComparison } from 'Types/Filter';
import { t } from 'Utilities/i18n';
import { getComparisonLabels } from '../labelFunc';
import '../common.scss';

type ValueLabel = {
  value: any;
  label: string;
  children: Array<ValueLabel>;
};
type Props = {
  className?: string;
  items: ValueLabel[];
  showSearchBox: boolean;
};
type State = {
  items: ValueLabel[];
};

class ColumnChecklistEditor extends PureComponent<Props, State> {
  static defaultProps = {
    items: [],
    showSearchBox: false,
  };
  inputRef = React.createRef<any>();
  comparisonItems?: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      items: props.items,
    };
    const comparisons = [FilterComparison.ALL, FilterComparison.ANY, FilterComparison.NONE];
    const comparisonLabels = getComparisonLabels();
    this.comparisonItems = comparisons.map((comparison) => ({
      label: comparisonLabels[comparison],
      value: comparison,
    }));
  }

  componentDidMount(): any {
    setTimeout(() => {
      this.inputRef?.current?.focus?.();
    }, MODAL_SHOW_DELAY);
  }

  setDisplayItems = debounce((value) => {
    const searchValue = value.toLowerCase();
    this.setState({
      items: this.props.items
        .filter((v) => {
          if (v.children) {
            return v.children.some((c) => c.label.toLowerCase().includes(searchValue));
          }
          return v.label.toLowerCase().includes(searchValue);
        })
        .map((e) => {
          if (e.children) {
            return {
              ...e,
              children: e.children.filter((c) => c.label.toLowerCase().includes(searchValue)),
            };
          }

          return e;
        }),
    });
  }, 100);
  handleSearch = (e: any) => {
    this.setDisplayItems(e.target.value);
  };

  render() {
    return (
      <div className={this.props.className}>
        {this.props.showSearchBox && (
          <div className="form-field">
            <SearchInput
              placeholder={t('Search SERP features…')}
              onChange={this.handleSearch}
              ref={this.inputRef}
            />
          </div>
        )}
        <Field
          name="value"
          component={CheckBoxGroup}
          items={this.state.items}
          column
          maxLength={8}
        />
        <Field name="comparison" component={DropdownField} items={this.comparisonItems} />
      </div>
    );
  }
}

export default ColumnChecklistEditor;
