import { useEffect } from 'react';
import {
  OperationVariables,
  useSubscription as apolloUseSubscription,
  useApolloClient,
} from '@apollo/client';
import type { BaseSubscriptionOptions } from '@apollo/client/react/types/types';
import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { DocumentNode } from 'graphql';

type Props = {
  query: DocumentNode | TypedDocumentNode;
  variables?: undefined;
  onSubscriptionData: BaseSubscriptionOptions['onSubscriptionData'];
};

const useSubscription = (props: Props) => {
  const { query, variables, onSubscriptionData } = props;

  apolloUseSubscription(query, {
    variables,
    onSubscriptionData,
  });
};

type SubscriptionConfig<T = object> = {
  query: DocumentNode | TypedDocumentNode;
  variables?: OperationVariables;
  onSubscriptionData: BaseSubscriptionOptions<T>['onSubscriptionData'];
};

export const useMultipleSubscriptions = <T = unknown>(subscriptions?: SubscriptionConfig<T>[]) => {
  const client = useApolloClient();
  useEffect(() => {
    const resultSubscriptions = subscriptions?.map((sub) => {
      const observable = client.subscribe<T>({
        query: sub.query,
        variables: sub.variables,
      });
      return observable.subscribe((e) =>
        sub.onSubscriptionData?.({
          subscriptionData: { data: e.data ?? undefined, loading: false, error: undefined },
          client,
        }),
      );
    });
    return () => {
      resultSubscriptions?.map((e) => e?.unsubscribe?.());
    };
  }, [subscriptions, client]);
};

export default useSubscription;
