import { useState } from 'react';
import { IconChevronDown, IconSearch } from '@tabler/icons-react';
import cn from 'classnames';
import { AccSelect } from 'Components/AccSelect';
import type { AccSelectProps } from 'Components/AccSelect/AccSelect';
import { Flag } from 'Components/Flag';
import FormatNumber from 'Components/FormatNumber/new';
import { CountrySelectOption } from 'Pages/KeywordDiscovery/support/types';
import { t } from 'Utilities/i18n';
import styles from './keydis-country-select.module.scss';

const LocaleSelectItem = ({ value, region, keywords, ...others }: CountrySelectOption) => {
  return (
    <div {...others} className={cn('locale-select', others.className)}>
      <div className={styles.optionWrapper}>
        <Flag size="sm" mr={6} country={value as string} />
        <p className={styles.countryName}>{region}</p>{' '}
        <FormatNumber withTilde className={styles.keywordCount} value={keywords} />
      </div>
    </div>
  );
};

type Props = Pick<
  AccSelectProps<CountrySelectOption>,
  'value' | 'onChange' | 'options' | 'className' | 'isLoading' | 'disabled' | 'size'
>;

const CountrySelectRightSection: AccSelectProps<CountrySelectOption>['rightSectionComponent'] = ({
  search,
  valueOption,
}) => {
  const isSearchProgress = !search || search !== valueOption?.label;
  const keywordsCountVisible = !isSearchProgress && valueOption?.keywords;
  return (
    <div className={styles.rightSection}>
      <span style={{ opacity: keywordsCountVisible ? 1 : 0, marginRight: '3px' }}>
        <FormatNumber withTilde value={valueOption?.keywords ?? 0} />
      </span>
      <IconChevronDown size={14} />
    </div>
  );
};

export const KeydisCountrySelect = ({
  options,
  value,
  onChange,
  className,
  isLoading,
  disabled,
  size = 'md',
}: Props) => {
  const [search, setSearch] = useState('');
  const valueOption = options?.find((e) => e.value === value);

  const isSearchProgress = !search || search !== valueOption?.label;

  const optionsList = options
    ?.map((option) => option.value)
    ?.filter((option) => typeof option === 'string');

  const isValueNotInOptions = value && options && !optionsList?.includes(value);

  //Change to the first value in optionslist if the value is not in options
  isValueNotInOptions && optionsList?.[0] && onChange(optionsList?.[0], options[0]);

  return (
    <AccSelect
      disabled={disabled}
      value={isValueNotInOptions ? optionsList?.[0] : value}
      itemComponent={LocaleSelectItem}
      options={options || []}
      onChange={onChange}
      placeholder={t('Country')}
      className={className}
      isLoading={isLoading}
      leftSection={
        isSearchProgress ? <IconSearch size="16px" /> : value && <Flag size="sm" country={value} />
      }
      onSearchChange={setSearch}
      rightSectionComponent={CountrySelectRightSection}
      rightSectionWidth={60}
      size={size}
    />
  );
};
