import * as React from 'react';
import { LegacyRef } from 'react';
import { connect } from 'react-redux';
import { parse } from 'qs';
import { showModal } from 'Actions/ModalAction';
import { withRouter } from 'Utilities/Router';
import Logo from 'icons/logo.svg';
import './backdrop.scss';

type Props = {
  children: any;
  shown: boolean;
  showLogo: boolean;
  className: string;
  theme: 'light' | 'dark';
  location: Record<string, any>;
  showModal: (...args: Array<any>) => any;
};
type State = {
  scrollElement: any;
};

class Backdrop extends React.Component<Props, State> {
  static defaultProps = {
    showLogo: true,
    theme: 'light',
  };
  state = {
    scrollElement: null,
  };

  componentDidMount() {
    this.handleQueryAndModal();
    this.setState({ scrollElement: this.scrollRef.current });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search && this.props.location.search !== prevProps.location.search) {
      this.handleQueryAndModal();
    }
  }
  handleQueryAndModal() {
    const {
      location: { search },
    } = this.props;
    const qs: any = parse(search.substr(1));
    if (!qs.hasOwnProperty('m')) return;

    const modalProps = qs.hasOwnProperty('p') ? JSON.parse(qs.p) : {};
    this.props.showModal({
      modalType: qs.m,
      modalTheme: 'light',
      modalProps,
    });
  }

  scrollRef = React.createRef<HTMLDivElement>();

  renderContent() {
    if (!this.props.shown) return null;

    return (
      <div
        key="overlay-backdrop"
        ref={this.scrollRef as LegacyRef<HTMLDivElement>}
        className={`overlay-backdrop ${this.props.theme}`}
      >
        {this.props.showLogo && (
          <div className="logo">
            <a href="https://app.accuranker.com/" target="_blank" rel="noopener noreferrer">
              <img src={Logo} alt={'AccuRanker'} />
            </a>
          </div>
        )}
        {this.state.scrollElement && (
          <div className="overlay-content">
            {React.cloneElement(this.props.children, {
              scrollElement: this.state.scrollElement,
            })}
          </div>
        )}
      </div>
    );
  }

  render() {
    return <div className={this.props.className}>{this.renderContent()}</div>; //NOT IS USE
  }
}

export default withRouter(
  connect(null, {
    showModal,
  })(Backdrop as any),
) as any;
