import { Component } from 'react';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import { Flex } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import compose from 'lodash/flowRight';
import { Field, reduxForm } from 'redux-form';
import AccButton from 'Components/AccButton/AccButton';
import { Select } from 'Components/Forms/Fields';
import { t } from 'Utilities/i18n/index';
import underdash from 'Utilities/underdash';
import Validator from 'Utilities/validation';

type Props = {
  gaProfiles?: any;
  connectionId: string;
  propertyId: string;
  accountId: string;
  onSubmit: (...args: Array<any>) => any;
  onBack: (...args: Array<any>) => any;
  handleSubmit?: any;
  submitting?: boolean;
  invalid?: boolean;
};

class GAProfilePage extends Component<Props> {
  onSubmit = (val) => {
    this.props.onSubmit({
      profileId: val.gaProfile.value,
    });
  };

  render() {
    const { onBack, gaProfiles, handleSubmit, submitting, invalid } = this.props;
    const loading =
      underdash.graphqlError({ ...this.props }) || underdash.graphqlLoading({ ...this.props });
    const profiles =
      !loading && gaProfiles.googleConnection ? gaProfiles.googleConnection.gaProfiles : [];
    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className="form-label">{t('Profile')}</div>
        <div>
          <Field
            defaultBehaviour
            name="gaProfile"
            id="gaProfile"
            elementType="customSelect"
            type="text"
            placeholder={''}
            component={Select}
            loading={loading}
            options={profiles.map((profile) => ({
              value: profile.id,
              label: `${profile.name} (${profile.websiteUrl})`,
            }))}
            validate={Validator.required}
            helpText={
              loading
                ? t('Loading…')
                : t('Please select the Google Analytics profile that you wish to use.')
            }
          />
        </div>
        <div className="confirmation-button-wrapper text-right">
          <Flex columnGap="xxs" align="center">
            <AccButton variant="tertiary" onClick={onBack}>
              <IconArrowLeft />
              {t('Property')}
            </AccButton>
            <AccButton variant="primary" type="submit" disabled={loading || invalid || submitting}>
              {t('Select profile')}
            </AccButton>
          </Flex>
        </div>
      </form>
    );
  }
}

const gaProfilesQuery = gql`
  query gaProfilePage_gaProfiles(
    $goaConnectionID: ID!
    $gaAccountID: String!
    $gaPropertyID: String!
  ) {
    googleConnection(id: $goaConnectionID) {
      gaProfiles(gaAccountId: $gaAccountID, gaPropertyId: $gaPropertyID) {
        id
        name
        websiteUrl
      }
    }
  }
`;
export default compose(
  graphql(gaProfilesQuery, {
    name: 'gaProfiles',
    options: (props: Props) => {
      const { connectionId, accountId, propertyId } = props;
      return {
        fetchPolicy: 'network-only',
        variables: {
          goaConnectionID: connectionId,
          gaAccountID: accountId,
          gaPropertyID: propertyId,
        },
      };
    },
  }),
  reduxForm({
    form: 'SelectGAProfileForm',
  }),
)(GAProfilePage);
