import { ReactNode } from 'react';

type Props = {
  isSticky: boolean;
  style?: any;
  children: ReactNode;
};

const OrderPlanWrapper = (props: Props) => (
  <div className={props.isSticky ? 'sticky-wrapper' : ''} style={{ ...props.style }}>
    <div className={`order-plan-wrapper ${props.isSticky ? 'border-left' : ''}`}>
      {props.children}
    </div>
  </div>
);

export default OrderPlanWrapper;
