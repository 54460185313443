import * as React from 'react';
import { ComponentType } from 'react';
import { connect } from 'react-redux';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import { Grid, Group } from '@mantine/core';
import compose from 'lodash/flowRight';
import { Field, getFormValues, reduxForm } from 'redux-form';
import type { FieldProps, FormProps } from 'redux-form';
import { setVatStatus } from 'Actions/OrderPlanAction';
import { AccSelect } from 'Components/AccSelect';
import { Flag } from 'Components/Flag';
import LoadingSpinner from 'Components/LoadingSpinner';
import LocaleSelector from 'Selectors/LocaleSelector';
import { t } from 'Utilities/i18n';
import Validator from 'Utilities/validation';
import {
  WithPricingPlanAndCalculatedPlanProps,
  getDiscountedPrice,
  getGa4Item,
  withPricingPlanAndCalculatedPlan,
} from '../utils';
import CompanyInfoWidgetSkeleton from './CompanyInfoWidgetSkeleton';

type Props = WithPricingPlanAndCalculatedPlanProps & {
  dispatch: (...args: Array<any>) => any;
  companyInfoError: any;
  countriesError: any;
  initialCountryHasVat: boolean;
  initialVatEntered: boolean;
  formValues: any;
  companyInfoLoading: boolean;
  countriesLoading: boolean;
  vatOptions: Array<Record<string, any>>;
  countryOptions: Array<Record<string, any>>;
  setVatStatus: (...args: Array<any>) => any;
  change: (...args: Array<any>) => any;
  setFormValidStatus: (...args: Array<any>) => any;
  valid: boolean;
  registrationMode: boolean;
} & FormProps;

type State = {
  braintreeUniqueId: any;
  formSubmitting: boolean;
  braintreeInstance: any;
  registrationMode: boolean;
  ga4TrackingAddBillingInfoSent: boolean;
  initialCountryChange: boolean;
};

class CompanyInfoWidget extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      braintreeUniqueId: +new Date(),
      formSubmitting: false,
      braintreeInstance: false,
      registrationMode: props.registrationMode !== undefined ? props.registrationMode : false,
      ga4TrackingAddBillingInfoSent: false,
      initialCountryChange: false,
    };
  }

  trackGA4AddBillingInfo = () => {
    if (this.props.loading) return;
    if (this.props.pristine) return;
    if (this.props.invalid) return;
    if (this.state.ga4TrackingAddBillingInfoSent) return;

    const pricingPlan = this.props.pricingPlan;
    const calculatedPlan = this.props.calculatedPlan;

    const currency = calculatedPlan?.currency;
    const value = getDiscountedPrice(calculatedPlan);

    window.gtag?.('event', 'add_billing_info', {
      currency,
      value,
      items: [getGa4Item(calculatedPlan, pricingPlan)],
    });

    this.setState({ ga4TrackingAddBillingInfoSent: true });
  };

  componentDidUpdate(prevProps) {
    this.trackGA4AddBillingInfo();
    if (this.props.asyncValidating !== prevProps.asyncValidating) {
      this.props.setFormValidStatus(
        typeof this.props.asyncValidating !== 'boolean' ? false : this.props.valid,
      );
    }

    if (this.props.valid !== prevProps.valid) {
      this.props.setFormValidStatus(this.props.valid);
    }

    if (
      this.props.initialVatEntered &&
      this.props.initialVatEntered !== prevProps.initialVatEntered
    ) {
      this.props.setVatStatus(this.props.initialVatEntered);
    }
  }

  handleCountrySelectOnChange = (data) => {
    const countryCode = data.value;
    const {
      vatOptions,
      change,
      initialValues: {
        country: { countryCode: initialCountryCode },
      },
    } = this.props;
    // check if country change was changed from initial value
    if (countryCode !== initialCountryCode && !this.state.initialCountryChange) {
      this.setState({ initialCountryChange: true });
      change('vatNumber', '');
    } else if (this.state.initialCountryChange) {
      change('vatNumber', '');
    }
    if (!vatOptions) return;
    const option = vatOptions.filter((vatOption) => vatOption.countryCode === countryCode) || [];

    if (option.length) change('vatPrefix', option[0]);
    else change('vatPrefix', '');
  };

  handleSelectOnBlur = (field) => {
    const {
      input,
      input: { value },
    } = field;
    input.onBlur(value);
  };

  renderOption({ label, countryCode }) {
    return (
      <Group wrap="nowrap" gap={4}>
        <Flag size="sm" country={countryCode} />
        {label}
      </Group>
    );
  }

  renderCompanyName() {
    return (
      <Grid.Col span={{ sm: 12 }}>
        <Field
          label={t('Company name')}
          labelClassname="required"
          name="companyName"
          id="companyName"
          elementType="input"
          type="text"
          placeholder={t('Enter your company name')}
          component={this.renderField}
          validate={Validator.string}
          disabled={!!this.props.asyncValidating}
        />
      </Grid.Col>
    );
  }

  renderEmailInvoiceTo() {
    if (this.state.registrationMode) return;
    return (
      <>
        <Grid.Col span={{ sm: 12, lg: 6 }}>
          <Field
            label={t('Email address for invoices')}
            name="emailInvoiceTo"
            id="emailInvoiceTo"
            elementType="input"
            type="text"
            placeholder={t('Enter your email')}
            component={this.renderField}
            validate={Validator.email}
            disabled={!!this.props.asyncValidating}
          />
        </Grid.Col>
        <Grid.Col span={{ sm: 12, lg: 6 }}>
          <Field
            label={t('P.O. number')}
            name="poNumber"
            id="poNumber"
            elementType="input"
            type="text"
            placeholder={t('Enter P.O. number if required')}
            component={this.renderField}
            disabled={!!this.props.asyncValidating}
          />
        </Grid.Col>
      </>
    );
  }

  renderVatFields() {
    // if (!this.state.showVatFields) return;
    const { asyncValidating, formValues, vatOptions } = this.props;
    const country = (formValues.country && formValues.country.countryCode) || '';
    let helpInfo: string | null = null;

    if (country !== 'DK') {
      helpInfo = t('Enter your VAT number to avoid VAT charges');
    }
    const disabled = !!asyncValidating;
    return (
      <>
        <Grid.Col span={{ sm: 12, md: 4, lg: 3 }}>
          <Field
            label={t('EU vat number')}
            name="vatPrefix"
            id="vatPrefix"
            elementType="customSelect"
            type="text"
            placeholder={''}
            component={this.renderField}
            options={vatOptions}
            supressErrorMessage
            disabled={disabled}
          />
        </Grid.Col>
        <Grid.Col span={{ sm: 12, md: 8, lg: 9 }}>
          <Field
            label={helpInfo ?? <span>&nbsp;</span>}
            name="vatNumber"
            id="vatNumber"
            elementType="input"
            type="text"
            placeholder={t('Enter your vat number')}
            component={this.renderField}
            disabled={disabled}
          />
        </Grid.Col>
      </>
    );
  }

  renderField = (field: FieldProps): React.ReactElement<any> => {
    const {
      supressErrorMessage,
      meta: { asyncValidating, touched, error },
      disabled,
    } = field;
    const className = `${touched && error ? 'invalid' : ''}`;
    const asyncValidatingClassName = `${asyncValidating ? 'loading' : ''}`;
    const inputClassName = `custom-form-control ${asyncValidatingClassName}`;
    const loadingSpinner = asyncValidating ? <LoadingSpinner /> : '';
    const errorMessage = !supressErrorMessage ? error : '';
    let element;
    if (field.elementType === 'customSelect') {
      const onChange = (val, option) => {
        field.onCustomChange?.(option);
        field.input.onChange(option);
      };

      element = (
        <AccSelect
          key={field.input.value?.value}
          name={field.input.id}
          options={field.options}
          placeholder={field.placeholder}
          clearable={false}
          searchable={true}
          {...field.input}
          value={field.input.value?.value}
          onChange={onChange}
          onBlur={() => this.handleSelectOnBlur(field)}
          optionRenderer={field.optionRenderer}
          disabled={disabled}
          size="default"
        />
      );
    } else {
      const Component = field.elementType;
      element = (
        <Component
          className={inputClassName}
          type={field.type}
          placeholder={field.placeholder}
          {...field.input}
          disabled={disabled}
        />
      );
    }

    return (
      <div className={className}>
        <label htmlFor={field.input.id} className={field.labelClassname}>
          {field.label}
        </label>
        {element}
        {loadingSpinner}
        <span className="error-message">{touched ? errorMessage : ''}</span>
      </div>
    );
  };

  render() {
    const {
      asyncValidating,
      companyInfoLoading,
      countriesLoading,
      companyInfoError,
      countriesError,
    } = this.props;

    if (companyInfoLoading || countriesLoading) {
      return <CompanyInfoWidgetSkeleton />;
    }

    if (companyInfoError || countriesError) {
      return (
        <div>
          <p>{t('Something went wrong. Please try again, or contact support.')}</p>
        </div>
      );
    }

    const disabled = !!asyncValidating;
    return (
      <Grid mb={18}>
        {this.renderCompanyName()}
        <Grid.Col span={{ sm: 12, lg: 6 }}>
          <Field
            label={t('Street')}
            labelClassname="required"
            name="street"
            id="street"
            elementType="input"
            type="text"
            placeholder={t('Enter your street')}
            component={this.renderField}
            validate={Validator.string}
            disabled={disabled}
          />
        </Grid.Col>
        <Grid.Col span={{ sm: 12, lg: 6 }}>
          <Field
            label={t('Zip/postal code')}
            labelClassname="required"
            name="zipcode"
            id="zipcode"
            elementType="input"
            type="text"
            placeholder={t('Enter your ZIP')}
            component={this.renderField}
            validate={Validator.string}
            disabled={disabled}
          />
        </Grid.Col>
        <Grid.Col span={{ sm: 12, lg: 6 }}>
          <Field
            label={t('City')}
            labelClassname="required"
            name="city"
            id="city"
            elementType="input"
            type="text"
            placeholder={t('Enter your city')}
            component={this.renderField}
            validate={Validator.string}
            disabled={disabled}
          />
        </Grid.Col>
        <Grid.Col span={{ sm: 12, lg: 6 }}>
          <Field
            label={t('State / Province / Region')}
            name="state"
            id="state"
            elementType="input"
            type="text"
            placeholder={t('Enter your state/province/region')}
            component={this.renderField}
            disabled={disabled}
          />
        </Grid.Col>
        <Grid.Col span={{ sm: 12 }}>
          <Field
            label={t('Country')}
            labelClassname="required"
            name="country"
            id="country"
            elementType="customSelect"
            type="text"
            placeholder={t('Select your country')}
            component={this.renderField}
            validate={Validator.required}
            onCustomChange={this.handleCountrySelectOnChange}
            options={this.props.countryOptions}
            optionRenderer={this.renderOption}
            disabled={disabled}
          />
        </Grid.Col>
        {this.renderVatFields()}
        {this.renderEmailInvoiceTo()}

        {!this.props.registrationMode && (
          <Grid.Col span={{ sm: 12 }}>
            <span className="required-fields-info">* - {t('Required fields')}</span>
          </Grid.Col>
        )}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  fullLocale: LocaleSelector(state),
  formValues: getFormValues('CompanyInfoForm')(state) || {}, //This basically gives us the form values in the props and it gets updated on keydown.
});

const countriesQuery = gql`
  query companyInfoWidget_countries {
    countries {
      id
      name
      isEu
      vatCode
    }
  }
`;
const companyInfoQuery = gql`
  query companyInfoWidget_paymentContact {
    paymentContact {
      id
      companyName
      street
      zipcode
      city
      state
      country {
        id
        name
        isEu
        vatCode
      }
      vatPrefix
      vatNumber
      emailInvoiceTo
      poNumber
    }
  }
`;
const CompanyInfoWidgetForm = reduxForm({
  enableReinitialize: true,
  form: 'CompanyInfoForm',
  asyncValidate: Validator.validVatNumber,
  asyncBlurFields: ['vatNumber', 'vatPrefix'],
  shouldAsyncValidate: ({ trigger }) => {
    switch (trigger) {
      case 'blur':
        return true;

      default:
        return false;
    }
  },
})(CompanyInfoWidget);

export default compose(
  graphql(countriesQuery, {
    options: {
      fetchPolicy: 'network-only',
    },
    props: ({ data: { loading, countries, error } }: any) => {
      if (loading || error) {
        if (error) throw error;
        return {
          countriesLoading: loading,
          countriesError: error,
        };
      }

      return {
        countryOptions: countries.map(({ name, vatCode, id }) => ({
          label: name,
          value: id,
          vatCode,
          countryCode: id,
        })),
        vatOptions: countries
          .filter(({ vatCode }) => !!vatCode)
          .map(({ vatCode, id }) => ({
            label: vatCode,
            value: vatCode,
            vatCode,
            countryCode: id,
          })),
      };
    },
  }) as any,
  graphql(companyInfoQuery, {
    options: {
      fetchPolicy: 'network-only',
    },
    props: ({ data: { loading, paymentContact, error } }: any) => {
      if (loading || error) {
        if (error) throw error;
        return {
          companyInfoLoading: loading,
          companyInfoError: error,
        };
      }

      const companyInfo = {
        companyInfoError: error,
        initialValues: {
          ...paymentContact,
          companyInfoLoading: loading,
          country: {
            label: paymentContact.country.name,
            value: paymentContact.country.id,
            countryCode: paymentContact.country.id,
            vatCode: paymentContact.country.vatCode,
          },
        },
        initialCountryHasVat: !!paymentContact.country.vatCode,
        initialVatEntered: !!paymentContact.vatNumber,
      };

      if (paymentContact.country.vatCode) {
        companyInfo.initialValues.vatPrefix = {
          label: paymentContact.country.vatCode,
          value: paymentContact.country.vatCode,
          countryCode: paymentContact.country.id,
          vatCode: paymentContact.country.vatCode,
        };
      }
      return companyInfo;
    },
  }),
  connect(mapStateToProps, {
    setVatStatus,
  }),
  withPricingPlanAndCalculatedPlan,
)(CompanyInfoWidgetForm) as ComponentType<Pick<Props, 'onSubmit' | 'setFormValidStatus'>>;
