const DATE = 'date';
const INVOICE_NUMBER = 'invoice_number';
const AMOUNT = 'amount';
const VAT = 'vat';
const TOTAL = 'total';
const PAID = 'paid';
export const ColumnIDs = {
  DATE,
  INVOICE_NUMBER,
  AMOUNT,
  VAT,
  TOTAL,
  PAID,
};
