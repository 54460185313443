import * as React from 'react';
import { connect } from 'react-redux';
import { Alert } from '@mantine/core';
import { getFormSubmitErrors } from 'redux-form';
import { AddAction } from 'Pages/Layout/ActionsMenu/Actions';
import { ErrorKind, isFieldError } from 'Utilities/errors';
import withFormName from '../withFormName';
import './form-errors.scss';

type Props = {
  errors?: Record<string, string>;
};

const Errors = ({ errorKind, error }: { errorKind: string; error: string }) => {
  if (errorKind === ErrorKind.ORG_PLAN_ERROR) {
    return (
      <Alert key={errorKind} bg="red.0" className="error-with-action">
        {error}
        <AddAction label="Upgrade Plan" link="/billing/package/select" />
      </Alert>
    );
  }

  return (
    <Alert key={errorKind} bg="red.0">
      {error}
    </Alert>
  );
};

export const FormErrors = ({ errors }: Props) => {
  if (!errors) {
    return null;
  }

  const errorKinds = Object.keys(errors).filter((errorKind) => !isFieldError(errorKind));

  if (errorKinds.length === 0) {
    return null;
  }

  return (
    <div className="form-errors">
      {errorKinds.map((errorKind) => (
        <Errors key={errorKind} errorKind={errorKind} error={errors[errorKind]} />
      ))}
    </div>
  );
};

const mapStateToProps = (state: any, props: Props & { formName: string }) => ({
  errors: getFormSubmitErrors(props.formName)(state),
});

export default withFormName(connect(mapStateToProps)(FormErrors)) as React.FC<
  React.PropsWithChildren<unknown>
>;
