import compose from 'lodash/flowRight';
import noop from 'lodash/noop';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { useTableStore } from 'Components/DataTable';
import { useDomainsPageSelector } from 'Selectors/DomainsPageSelector';
import { TableIDs } from 'Types/Table';
import { folderSeparator } from 'Utilities/generateSelectId';
import Toolbar from '../../Toolbar';

const withDomainsTableData = (Component: any) => {
  const result = (props: any) => {
    const tableStore = useTableStore(TableIDs.DOMAINS);
    const { selectableDomains } = useDomainsPageSelector();
    const selectedRows: (string | number)[] = toJS(tableStore?.selectedRows) ?? [];
    const selectedDomains = selectedRows
      .map((id) => {
        const domainWithSeparator = `${folderSeparator}${id}`;
        const selectId = selectableDomains.find((item) => item.includes(domainWithSeparator));
        return selectId;
      })
      .filter((item) => item);

    return (
      <Component
        {...props}
        initialSelect={selectedDomains}
        deselectAll={tableStore?.resetSelection ?? noop}
      />
    );
  };
  result.displayName = 'withDomainsTableData';

  return result;
};

export default compose(observer, withDomainsTableData)(Toolbar);
