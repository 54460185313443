import { Flex } from '@mantine/core';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import { AccFastIcon } from 'Components/AccFastIcon';
import AccLazyPopover from 'Components/AccLazyPopover/AccLazyPopover';
import { UrlEllipsis } from 'Components/URLEllipsis/new';
import UrlSerpPreview from 'Components/UrlSerpPreview';
import { getUrlPath } from 'Utilities/parser';
import UrlIcon from 'icons/url.svg';
import './url-cell-content.scss';

type Props = {
  url?: string;
  title?: string | null;
  description?: string | null;
};

const URLCell = (props: Props) => {
  const { url, title, description } = props;
  const urlString = getUrlPath(url);
  return (
    <div className="url-cell-content">
      {url && (
        <>
          <Flex align="center" mr="xxs">
            <AccLazyPopover
              target={
                <AccActionIcon variant="subtle" size={'md'}>
                  <AccFastIcon src={UrlIcon} size={14} color={'var(--popover-color)'} />
                </AccActionIcon>
              }
            >
              <UrlSerpPreview title={title} url={url || '-'} text={description} />
            </AccLazyPopover>
          </Flex>
          <UrlEllipsis url={url} shortenedUrl={urlString} tooltip={url} />
        </>
      )}
    </div>
  );
};

export default URLCell;
