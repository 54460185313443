import isNumber from 'lodash/isNumber';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import { NOT_IN_TOP_100_VALUE } from 'Components/Chart/LineChart/support/constants';
import { SeriesDataItem } from 'Components/Chart/LineChart/support/types';
import { DEFAULT_BACKEND_DATE_FORMAT } from 'Constants';
import { devError } from 'Utilities/log';

export const getSeriesData = <
  T extends {
    value?: number | null;
    rank?: number | null;
    date?: number | null;
    searchDate?: number | string | null;
    [key: string]: unknown;
  } | null,
>(
  days: T[],
  config: {
    dateType?: 'iso' | 'backend';
    rank?: boolean;
  } = { dateType: 'backend' },
): SeriesDataItem[] => {
  const getValue = (el?: T) => (config.rank ? el?.rank ?? NOT_IN_TOP_100_VALUE : el?.value);
  return sortBy(
    days
      ?.filter((el) => isNumber(getValue(el)))
      ?.map((el) => {
        const valueDate = el?.date ?? el?.searchDate;

        if (!valueDate) {
          return;
        }
        let date;
        if (config?.dateType === 'iso') {
          date = moment(new Date(valueDate));
        } else {
          date = moment(valueDate, DEFAULT_BACKEND_DATE_FORMAT);
        }

        if (date?.isValid()) {
          return {
            x: date.toDate().getTime() ?? 0,
            y: config.rank ? el?.rank ?? NOT_IN_TOP_100_VALUE : el?.value,
          };
        }
        devError('Unknown format of the date: ', JSON.stringify(date));
        return undefined;
      })
      .filter(Boolean)
      .map((el) => el as SeriesDataItem) ?? [],
    'x',
  );
};
