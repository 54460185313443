import React, { PropsWithChildren, useRef } from 'react';
import { Box, Flex, Group } from '@mantine/core';
import moment from 'moment';
import styles from 'Components/Chart/BubbleChart/styles.module.scss';
import { ColumnChart } from 'Components/Chart/ColumnChart/ColumnChart';
import { ColumnChartItem } from 'Components/Chart/ColumnChart/support/types';
import { Flag } from 'Components/Flag';
import { DEFAULT_BACKEND_DATE_FORMAT } from 'Constants';
import { getDisabledDemoText } from 'Constants/messages';
import { useGscAndGaHistoryQuery } from 'Ghql';
import { GaAndGscHistory } from 'Ghql/customTypes';
import { useFilters } from 'Hooks';
import { useModal } from 'Hooks/base/useModal';
import { useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import { ChartContainer } from 'Pages/Keywords/Overview/components/ChartContainer/index';
import { WithDragDetails } from 'Pages/Keywords/Overview/support/types';
import { ConnectToAnalyticsAction, ConnectToGSCAction } from 'Pages/Layout/ActionsMenu/Actions';
import { t, tct } from 'Utilities/i18n';
import { MissingDataOverlay } from '../../MissingDataOverlay';
import { useGroupViewInfo } from '../../hooks/useGroupViewInfo';
import { FiltersNoEffectBadge } from '../FiltersNoEffectBadge';

interface SeriesNames {
  gscPrevYear: string;
  gscThisYear: string;
  gaPrevYear: string;
  gaThisYear: string;
}

const tooltipFormatter =
  (seriesNames: SeriesNames, hasGa: boolean | undefined, hasGsc: boolean | undefined) => (item) => {
    const thisYear = moment(new Date(item?.x)).format('MMM YYYY');
    const prevYear = moment(new Date(item?.x)).subtract(1, 'year').format('MMM YYYY');

    const getPointSeriesByName = (seriesName: string) =>
      item?.points?.find((point) => point?.series?.name === seriesName)?.y.toLocaleString();

    const gscPrevYear = getPointSeriesByName(seriesNames.gscPrevYear) ?? '-';
    const gscThisYear = getPointSeriesByName(seriesNames.gscThisYear) ?? '-';
    const gaPrevYear = getPointSeriesByName(seriesNames.gaPrevYear) ?? '-';
    const gaThisYear = getPointSeriesByName(seriesNames.gaThisYear) ?? '-';
    return `
<table class="${styles.tooltip}">
  <tr>
    <th></th>
    <th>${hasGsc && hasGa ? 'GSC' : ''}</th>
    <th>${hasGa && hasGsc ? 'GA' : ''}</th>
  </tr>
  <tr>
    <td>${thisYear}</td>
  <td>${hasGsc ? gscThisYear : ''}</td>
  <td>${hasGa ? gaThisYear : ''}</td>
  </tr>
  <tr>
    <td>${prevYear}</td>
  <td>${hasGsc ? gscPrevYear : ''}</td>
  <td>${hasGa ? gaPrevYear : ''}</td>
  </tr>
</table>`;
  };

const getFakeGscGaData = () => {
  return Array.from(Array(24)).map((_, i) => ({
    month: moment.utc().subtract(i, 'month').format(DEFAULT_BACKEND_DATE_FORMAT),
    gscTraffic: 200 + (24 - i) * 20 + Math.floor(Math.random() * 100),
    gaTraffic: 180 + (24 - i) * 20 + Math.floor(Math.random() * 100),
  }));
};

const getCsvData = (series) => () => {
  const header = `Date,${series.map((s) => s.title).join(',')}\n`;
  let i;
  let rows = '';
  for (i = 0; i < series[0].data.length; i++) {
    rows += `${moment.unix(series[0].data[i].date / 1000).format('YYYY-MM')}`;
    series.map((s) => (rows += `,${s.data[i].value}`));
    rows += '\n';
  }
  return header + rows;
};

export const GoogleAnalyticsTraffic = ({
  refresh,
  ...props
}: { refresh: () => void } & WithDragDetails) => {
  const {
    domainInfo,
    loadingDomainInfo,
    refetch: refetchDomainInfo,
    isDemoDomain,
  } = useQueryDomainInfo();
  const filters = useFilters();
  const { isEmptyGroup } = useGroupViewInfo(filters);
  const chartRef = useRef<HTMLDivElement>(null);
  const { showModal } = useModal();
  const {
    data,
    loading,
    refetch: refetchGaAndGsc,
  } = useGscAndGaHistoryQuery({
    variables: { filters, name: 'gaAndGscGraph' },
  });

  const isGroupView = !loadingDomainInfo && !domainInfo;
  const showFilterNotice = filters.length > 3;
  const hasGa = domainInfo?.isGAConnected;
  const hasGsc = domainInfo?.isGscConnected;
  const gscAndGaHistory = data?.graphs?.gscAndGaHistory?.data;
  const country = data?.graphs?.gscAndGaHistory?.country;
  const dataIsEmpty = !loading && gscAndGaHistory?.length === 0;

  const refetchAll = () => {
    refresh();
    refetchDomainInfo();
    refetchGaAndGsc();
  };

  const handleConnectToGSC = () => {
    showModal({
      modalType: 'ConnectToGSC',
      modalTheme: 'light',
      modalProps: {
        domainId: domainInfo?.id,
        refresh: refetchAll,
        skipImportKeywords: true,
      },
    });
  };

  const handleConnectToAnalytics = () => {
    showModal({
      modalType: 'ConnectToAnalytics',
      modalTheme: 'light',
      modalProps: {
        domainId: domainInfo?.id,
        refresh: refetchAll,
      },
    });
  };

  const noDataInGroupView = !loading && !gscAndGaHistory && isGroupView;
  const noConnection = !loadingDomainInfo && !isGroupView && !hasGa && !hasGsc;

  const chartData: GaAndGscHistory[] =
    noConnection || noDataInGroupView ? getFakeGscGaData() : gscAndGaHistory;

  const toMonth = (x) =>
    moment(x.month ?? '', DEFAULT_BACKEND_DATE_FORMAT)
      .toDate()
      .getTime();

  const addYearToDate = (x) =>
    moment(x.month ?? '', DEFAULT_BACKEND_DATE_FORMAT)
      .add(1, 'y')
      .toDate()
      .getTime();

  const seriesNames: SeriesNames = {
    gscPrevYear: t('GSC (year before)'),
    gscThisYear: t('GSC'),
    gaPrevYear: t('GA (year before)'),
    gaThisYear: t('GA'),
  };

  // The chart has data from the latest 12 months and the year before on top of each other.
  // The data coming from backend is ordered with the latest first. To make the data from last year
  // on top of this year, we need to add 1 year to the date.
  const gscData =
    chartData?.slice(0, 12).map((x) => ({
      date: toMonth(x),
      value: x.gscTraffic,
    })) ?? [];
  const gscDataPreviousYear =
    chartData?.slice(12, 24).map((x) => ({
      date: addYearToDate(x),
      value: x.gscTraffic,
    })) ?? [];
  const gaData =
    chartData?.slice(0, 12).map((x) => ({
      date: toMonth(x),
      value: x.gaTraffic,
    })) ?? [];
  const gaDataPreviousYear =
    chartData?.slice(12, 24).map((x) => ({
      date: addYearToDate(x),
      value: x.gaTraffic,
    })) ?? [];

  let series: ColumnChartItem[] = [];
  if (hasGsc || noConnection || isGroupView) {
    series = series.concat([
      {
        type: 'column',
        data: gscDataPreviousYear,
        title: t('GSC (year before)'),
        color: '#9eadda',
      },
      {
        type: 'column',
        data: gscData,
        title: 'GSC',
        color: '#3c59a9',
      },
    ]);
  }
  if (hasGa || noConnection || isGroupView) {
    series = series.concat([
      {
        type: 'column',
        data: gaDataPreviousYear,
        title: t('GA (year before)'),
        color: 'rgb(239, 194, 153)',
      },
      {
        type: 'column',
        data: gaData,
        title: 'GA',
        color: '#f89537',
      },
    ]);
  }

  const tooltip = [
    tct(
      'This chart shows the historical evolution of traffic from search engines registered by Google Analytics (GA) and Google Search Console (GSC).[br][br]' +
        'The data we fetch from GA/GSC is filtered to include only traffic from the primary country associated with the domain.[br][br]' +
        'The GA traffic displayed is the number of users visiting from a search engine, while the GSC traffic is the number of clicks registered through GSC.[br][br]' +
        'We pull this data directly from the GA/GSC APIs when you connect your account(s).[br][br]' +
        'The data is for ALL your users/keywords in GA/GSC, not just the ones you track. This also means the chart is unaffected by filters.[br][br]' +
        'Hint: Try to hover or click the different labels below the chart!',
      { br: <br /> },
    ),
  ];

  const customKebabWrapper = (prop: PropsWithChildren) => (
    <Group align="apart" gap="md" ml="auto">
      {showFilterNotice && <FiltersNoEffectBadge />}
      {!hasGsc && hasGa && domainInfo && (
        <ConnectToGSCAction key="addGSC" onClick={handleConnectToGSC} />
      )}
      {hasGsc && !hasGa && domainInfo && (
        <ConnectToAnalyticsAction key="addGa" onClick={handleConnectToAnalytics} />
      )}
      {country && (
        <Flex align="center">
          <Flag size="md" country={country} fadedColors />
        </Flex>
      )}
      {prop.children}
    </Group>
  );
  return (
    <ChartContainer
      title={`${t('Organic Traffic')} (GA/GSC)`}
      tooltip={tooltip}
      chartSeries={series}
      getCsvData={() => getCsvData(series)}
      dragRef={props.dragRef}
      boardControls={props.boardControls}
      chartRef={chartRef}
      kebabWrapper={customKebabWrapper}
    >
      <Box mih={385}>
        <Box pos="relative" ref={chartRef}>
          {noConnection && domainInfo && !loading && (
            <MissingDataOverlay
              type="organicTraffic"
              handleConnectToGSC={handleConnectToGSC}
              handleConnectToAnalytics={handleConnectToAnalytics}
              disable={isDemoDomain}
              disableTooltip={isDemoDomain ? getDisabledDemoText() : undefined}
              isEmptyGroup={isEmptyGroup}
            />
          )}
          {noDataInGroupView && !loading && (
            <MissingDataOverlay type="organicTrafficGroup" isEmptyGroup={isEmptyGroup} />
          )}
          {dataIsEmpty && !noConnection && (
            <MissingDataOverlay
              type="awaitAnalyticsData"
              onClick={() => refetchGaAndGsc()}
              isEmptyGroup={isEmptyGroup}
            />
          )}
          <ColumnChart
            tooltipFormatter={tooltipFormatter(
              seriesNames,
              hasGa || isGroupView,
              hasGsc || isGroupView,
            )}
            items={series}
            loading={loading}
            showLegend
            colWidth={10}
          />
        </Box>
      </Box>
    </ChartContainer>
  );
};
