import { useState } from 'react';
import { Box, Flex, FocusTrap, Popover, TextInput } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import AccText from 'Components/Text/AccText';
import { ROOT_ID } from 'Pages/Keywords/Groupings/EditMode/support/constants';
import AccButton from '../../../../../../Components/AccButton/AccButton';
import { t } from '../../../../../../Utilities/i18n';
import { useTreeContext } from '../../react-dnd-treeview/hooks';
import { FOLDER_SEPARATOR } from '../../support/helpers';
import { areIllegalSiblings } from '../../support/hooks/areIllegalSiblings';
import styles from './node-item.module.scss';

const siblingWithSameNameExists = (tree, node, newName) => {
  if (!node.parent) {
    node.parent = ROOT_ID;
  }

  const newNode = { ...node, name: newName };
  const siblings = tree.filter((e) => e.parent === newNode.parent && e.id !== newNode.id);

  // As we know it is a folder we are creating, we do not have to check for tags with same name
  return siblings.some((e) => areIllegalSiblings(e, newNode));
};

export const EditNodeInput = ({ onCancel, onSave, initValue, isFolder, node }) => {
  const treeContext = useTreeContext();
  const [textValue, setTextValue] = useState(initValue ?? '');
  const [{ error, valueWithError }, setError] = useState({ error: '', valueWithError: '' });
  const onAdd = () => {
    if (!textValue?.length) {
      setError({
        error: isFolder
          ? t('Can\'t add folder with empty name')
          : t('Can\'t update tag with empty name'),
        valueWithError: textValue,
      });
      return;
    } else if (initValue === textValue) {
      onCancel();
      return;
    }

    const siblingsWithSameName = siblingWithSameNameExists(treeContext.tree, node, textValue);
    if (siblingsWithSameName) {
      setError({
        error: t('Provided name is already used in this directory.'),
        valueWithError: textValue,
      });
      return;
    } else if (textValue.includes(FOLDER_SEPARATOR)) {
      setError({
        error: t(`${FOLDER_SEPARATOR} ${t('is not an allowed character')}`),
        valueWithError: textValue,
      });
      return;
    }
    onSave(textValue);
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      onAdd();
    }
    if (e.key === 'Escape') {
      onCancel();
    }
  };

  const showError = !!error && valueWithError === textValue;
  return (
    <FocusTrap active>
      <Flex onClick={(e) => e.stopPropagation()} align="center">
        <Popover
          opened={showError}
          position="bottom-start"
          width="200"
          withArrow
          shadow="md"
          withinPortal
        >
          <Popover.Target>
            <TextInput
              autoFocus
              variant="unstyled"
              data-autofocus
              p={0}
              m={0}
              onKeyDown={onKeyDown}
              value={textValue}
              onChange={(event) => setTextValue(event.currentTarget?.value)}
              placeholder="New folder"
              classNames={{ input: styles.newFolderInput }}
            />
          </Popover.Target>
          <Popover.Dropdown>
            <AccText c="red" size="sm">
              <Box mr={2} display="inline">
                <IconInfoCircle size={16} strokeWidth={2} color="red" />
              </Box>
              {error}
            </AccText>
          </Popover.Dropdown>
        </Popover>
        <Flex>
          <AccButton onClick={onCancel} variant="tertiary" mr={8}>
            {t('Cancel')}
          </AccButton>
          <AccButton onClick={onAdd} variant="primary">
            {initValue ? t('Update') : t('Add')}
          </AccButton>
        </Flex>
      </Flex>
    </FocusTrap>
  );
};
