import { useRef, useState } from 'react';
import { Grid } from '@mantine/core';
import { IconDeviceMobile, IconPhone } from '@tabler/icons-react';
import { Field } from 'Components/Fields';
import Hint from 'Components/Hint';
import { TrackingKey } from 'Utilities/Analytics/mixpanel';
import { t } from 'Utilities/i18n';
import Validator from 'Utilities/validation';
import Checkmark from 'icons/check.svg?inline';
import { Channel, FIELD_NAMES } from '..';
import AccButtonWithOptions from './AccButtonWithOptions';
import styles from '../register.module.scss';

type Props = {
  createdByCountry?: string;
  skipPhoneVerification: boolean; // only show phone validation fields if signup type 1
  isVerifyBtnDisabled: boolean;
  isPhoneVerified: boolean;
  handleVerify: (channel: Channel) => void;
  handleSubmitVerificationCode: (code: string) => Promise<number>;
};

const PhoneValidationLabel = ({ isPhoneVerified }: { isPhoneVerified: boolean }) => {
  if (isPhoneVerified) {
    return (
      <label>
        <Checkmark width={'16px'} height={'16px'} fill={'green'} /> {t('Validated')}
      </label>
    );
  }
  return <label>{t('Required for verification')}</label>;
};

const ContactInfo = ({
  createdByCountry,
  skipPhoneVerification,
  isVerifyBtnDisabled,
  isPhoneVerified,
  handleVerify,
  handleSubmitVerificationCode,
}: Props) => {
  const codeHasBeenSubmittedSuccessfully = useRef<boolean>(false);
  const [isCodeInputDisabled, setIsCodeInputDisabled] = useState<boolean>(false);

  // codeHasBeenSubmittedSuccessfully: Guards againts multiple submissions of the same code
  // isCodeInputDisabled: Disables the input field while verifying the code
  const handleCodeChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const code = e.target.value;
    if (code.length !== 4) {
      return; // only submit when 4 digits have been entered
    }
    if (codeHasBeenSubmittedSuccessfully.current) {
      return; // only submit once
    }
    setIsCodeInputDisabled(true); // disable the input field while verifying
    const rc = await handleSubmitVerificationCode(code);
    if (rc === 0) {
      // Verification successful; keep the field disabled
      codeHasBeenSubmittedSuccessfully.current = true;
    } else {
      // Verification failed; allow retry after 1 second
      setTimeout(() => {
        codeHasBeenSubmittedSuccessfully.current = false;
        setIsCodeInputDisabled(false);
      }, 1000);
    }
  };

  return (
    <>
      <Grid gutter={'xs'}>
        <Grid.Col span={{ base: 12, md: 6 }}>
          <Field.TextInput
            positionErrorBelow
            label={t('First name')}
            labelClassname="required"
            name={FIELD_NAMES.FIRST_NAME}
            id={FIELD_NAMES.FIRST_NAME}
            elementType="input"
            type="text"
            placeholder={t('First name')}
            autoComplete="name"
            required
            hideRequiredStar
            validate={[Validator.required, Validator.string]}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6 }}>
          <Field.TextInput
            positionErrorBelow
            label={t('Last name')}
            labelClassname="required"
            name={FIELD_NAMES.LAST_NAME}
            id={FIELD_NAMES.LAST_NAME}
            elementType="input"
            type="text"
            placeholder={t('Last name')}
            autoComplete="name"
            required
            hideRequiredStar
            validate={[Validator.required, Validator.string]}
          />
        </Grid.Col>

        <Grid.Col span={{ base: 12, md: skipPhoneVerification ? 12 : 6 }}>
          <Field.PhoneInput
            label={t('Phone')}
            labelClassname="required"
            name={FIELD_NAMES.PHONE}
            id={FIELD_NAMES.PHONE}
            elementType="input"
            type="tel-local"
            placeholder={t('Enter your phone number')}
            searchPlaceholder={t('Search')}
            defaultCountry={createdByCountry}
            className={styles.phoneInput}
            autoComplete="tel"
            disabled={isPhoneVerified}
            validate={skipPhoneVerification ? undefined : [Validator.required]}
          />
        </Grid.Col>

        {!skipPhoneVerification && (
          <>
            <Grid.Col span="content">
              <Hint
                help={t(
                  'Click here to receive a 4-digit verification code by either text message or an automated phone call',
                )}
              />
              <AccButtonWithOptions
                h={'40px'}
                disabled={isVerifyBtnDisabled}
                onClick={() => handleVerify('sms')}
                trackingKey={TrackingKey.RegisterPageVerifyPhone}
                label={t('Request code by')}
                options={[
                  {
                    label: t('SMS'),
                    icon: <IconDeviceMobile />,
                    onSelect: () => handleVerify('sms'),
                  },
                  {
                    label: t('Phone call'),
                    icon: <IconPhone />,
                    onSelect: () => handleVerify('call'),
                  },
                ]}
              >
                {t('Verify')}
              </AccButtonWithOptions>
            </Grid.Col>
            <Grid.Col span="auto">
              <Field.TextInput
                label={t('Code')}
                labelClassname="required"
                name={FIELD_NAMES.CODE}
                id={FIELD_NAMES.CODE}
                elementType="input"
                autoComplete="off"
                hideRequiredStar
                disabled={isPhoneVerified || isVerifyBtnDisabled || isCodeInputDisabled}
                customOnChange={handleCodeChange}
              />
            </Grid.Col>
          </>
        )}
      </Grid>

      {!skipPhoneVerification && <PhoneValidationLabel isPhoneVerified={isPhoneVerified} />}

      <Field.TextInput
        label={t('Work email')}
        labelClassname="required"
        name={FIELD_NAMES.EMAIL}
        id={FIELD_NAMES.EMAIL}
        elementType="input"
        readOnly
        disabled
        type="email"
        autoComplete="off"
        required
        hideRequiredStar
        validate={[Validator.required, Validator.emailHtml]}
      />
    </>
  );
};

export default ContactInfo;
