import React, { useEffect, useState } from 'react';
import { Group } from '@mantine/core';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import uniqueId from 'lodash/uniqueId';
import type { FieldArrayProps } from 'redux-form';
import { Flag } from 'Components/Flag';
import Icon from 'Components/Icon';
import {
  SEARCH_TYPE_BAIDU,
  SEARCH_TYPE_BING,
  SEARCH_TYPE_GOOGLE,
  SEARCH_TYPE_NAVER,
  SEARCH_TYPE_YANDEX,
  SEARCH_TYPE_YOUTUBE,
} from 'Components/Modal/Content/AddSearchEngine/types';
import AccText from 'Components/Text/AccText';
import { DESKTOP, MOBILE } from 'Types/Filter';
import { t } from 'Utilities/i18n/index';
import Baidu from 'icons/baidu.svg?inline';
import Bing from 'icons/bing.svg?inline';
import EditIcon from 'icons/edit.svg?inline';
import Google from 'icons/google.svg?inline';
import Ignore from 'icons/ignore.svg?inline';
import IgnoreInShareOfVoice from 'icons/ignoreInShareOfVoice.svg?inline';
import Naver from 'icons/naver.svg?inline';
import DeleteIcon from 'icons/remove.svg?inline';
import Snippet from 'icons/snippet.svg?inline';
import SpellCheck from 'icons/spellcheck.svg?inline';
import Yandex from 'icons/yandex.svg?inline';
import YouTube from 'icons/youtube.svg?inline';
import { useModal } from '../../../Hooks/base/useModal';
import './search-engine.scss';

const icons = {
  [SEARCH_TYPE_GOOGLE]: Google,
  [SEARCH_TYPE_BING]: Bing,
  [SEARCH_TYPE_BAIDU]: Baidu,
  [SEARCH_TYPE_YANDEX]: Yandex,
  [SEARCH_TYPE_YOUTUBE]: YouTube,
  [SEARCH_TYPE_NAVER]: Naver,
};

const searchTypeToString = (searchType: any) => {
  switch (searchType) {
    case MOBILE:
      return 'Mobile';

    case DESKTOP:
      return 'Desktop';

    default:
      return '';
  }
};

const getIconData = () => ({
  ignoreLocalResults: {
    icon: Ignore,
    label: t('Ignore local pack'),
  },
  ignoreFeaturedSnippet: {
    icon: Snippet,
    label: t('Ignore featured snippet'),
  },
  ignoreInShareOfVoice: {
    icon: IgnoreInShareOfVoice,
    label: t('Ignore in share of voice'),
  },
  enableAutocorrect: {
    icon: SpellCheck,
    label: t('Autocorrect enabled'),
  },
});

const renderGoogleSetting = (settings) => {
  const iconData = getIconData();
  return (
    <>
      {settings.map((setting) => (
        <Icon
          ignored
          className="icon"
          key={setting}
          icon={iconData[setting].icon}
          tooltip={iconData[setting].label}
        />
      ))}
    </>
  );
};

type IProps = {
  countryLocales: any[];
  showModal: (...args: Array<any>) => any;
  youTubeDomain: boolean;
  onPrimarySettChange?: () => void;
  getRadioGroupValue: () => number;
};

/**
 * Extracts value from field array
 * compatible with:
 * - redux-form - where we get value via get methods
 * - react-final-form - where we get value from value property
 * */
const getFieldValue = (fields, i: number) => {
  return fields?.get?.(i) ?? fields?.value?.[i];
};

const SearchEngine = (props: FieldArrayProps & IProps) => {
  const { showModal } = useModal();
  const [defaultEngineDeleted, setDefaultEngineDeleted] = useState(false);
  const { fields, countryLocales, youTubeDomain } = props;

  const handleEdit = (i, v) => {
    fields.remove(i);
    fields.insert(i, v);
  };

  useEffect(() => {
    if (fields.length === 1 && props.onPrimarySettChange) {
      props.onPrimarySettChange({
        val: getFieldValue(fields, 0)?.countrylocale,
      });
    }
  }, [getFieldValue(fields, 0)]);

  return (
    <div className={fields?.length ? 'searchEngines' : undefined}>
      {fields.map((_, i) => {
        const value = getFieldValue(fields, i);
        const locale = countryLocales.find((localeObj) => localeObj.id === value.countrylocale);

        if (!locale) {
          return null;
        }

        const { region, locale: localeName, countryCode } = locale;
        const settings = Object.keys(value.settings).filter((key) => value.settings[key]);
        const editFunction = () => {
          showModal({
            modalType: 'AddSearchEngine',
            modalTheme: 'light',
            modalProps: {
              locale,
              youTubeDomain,
              initialValues: {
                ...value,
                searchEngines: locale.searchEngines.map((se) => {
                  return (
                    value.searchEngines.find((s) => s.id.toString() === se.id.toString()) ?? se
                  );
                }),
              },
              editMode: true,
              onSubmit: (v) => handleEdit(i, v),
            },
            nested: true,
          });
        };

        let primaryRadioChecked;

        if (props.getRadioGroupValue) {
          primaryRadioChecked =
            (value.primary && !props.getRadioGroupValue()) ||
            (!value.primary && props.getRadioGroupValue() === value.countrylocale) ||
            (value.primary && props.getRadioGroupValue() === value.countrylocale);
        }

        return (
          <div className="searchEngine" key={i}>
            <div className="searchEngines__item">
              <div>
                <AccText fw="bold">{t('Country locale')}</AccText>
                <Group wrap="nowrap" gap={8}>
                  <Flag size="sm" id={`country-${value}`} country={countryCode} />

                  <span>
                    {region} - {localeName}
                  </span>
                </Group>
                <AccText fw="bold" mt="sm">
                  {t('Search engines')}
                </AccText>
                {value.searchEngines &&
                  value.searchEngines
                    .filter((item) => !!item.searchTypes.length)
                    .map((engine) => {
                      const EngineIcon = icons[engine.name.toLowerCase()];
                      return (
                        <p className="search-engine" key={engine.id}>
                          <span className="search-engine__name">
                            <Icon icon={EngineIcon} className="icon" />
                            <span className="text">
                              {engine.name.replace('youtube', 'youTube')}{' '}
                              {!!engine.searchTypes.length &&
                                `(${engine.searchTypes
                                  .map((type) => searchTypeToString(type))
                                  .join(', ')})`}
                            </span>
                            {engine.name === SEARCH_TYPE_GOOGLE && renderGoogleSetting(settings)}
                          </span>
                        </p>
                      );
                    })}
                <a
                  href={'#'}
                  onClick={(e) => {
                    e.preventDefault();
                    editFunction();
                  }}
                >
                  {t('Add search engine')}
                </a>
                <AccText fw="bold" mt="sm">
                  {t('Locations')}
                </AccText>
                {value.locations.length > 0 && !isEmpty(value.locations[0]) && (
                  <div>
                    {value.locations.map((location) => {
                      return (
                        <p key={uniqueId('locations')} className="search-engine">
                          <span className="search-engine__name">
                            <span className="text">{location}</span>
                          </span>
                        </p>
                      );
                    })}
                  </div>
                )}
                <a
                  href={'#'}
                  onClick={(e) => {
                    e.preventDefault();
                    editFunction();
                  }}
                >
                  {t('Add location')}
                </a>
                {fields.length > 1 && props.onPrimarySettChange && (
                  <label htmlFor="primaryCountryRadio" className="radio-btn-label">
                    <input
                      id="primaryCountryRadio"
                      type="radio"
                      name="primarySE"
                      value={value.countrylocale}
                      checked={primaryRadioChecked}
                    />
                    <div
                      onClick={() => {
                        props.onPrimarySettChange({
                          val: value.countrylocale,
                        });
                      }}
                      className={cn('radio-btn', {
                        'selected-radio': primaryRadioChecked,
                      })}
                    />
                    <span
                      onClick={() => {
                        props.onPrimarySettChange({
                          val: value.countrylocale,
                        });
                      }}
                    >
                      {t('Primary country')}
                    </span>
                  </label>
                )}
              </div>
              <div className="action_icons">
                <Icon icon={EditIcon} tooltip={t('Edit')} onClick={() => editFunction()} />

                <Icon
                  icon={DeleteIcon}
                  onClick={() => {
                    if (
                      props.getRadioGroupValue &&
                      getFieldValue(fields, i)?.countrylocale === props.getRadioGroupValue()
                    ) {
                      props.onPrimarySettChange({
                        val: getFieldValue(fields, 0)?.countrylocale,
                      });
                    }

                    if (i === 0 && !defaultEngineDeleted) {
                      setDefaultEngineDeleted(true);
                    }

                    fields.remove(i);
                  }}
                  tooltip={t('Delete')}
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SearchEngine;
