import { Component } from 'react';
import DomainDetails from 'Components/DomainDetails';

type Props = {
  domainData: Record<string, any>;
  small?: boolean;
  reset?: boolean;
};

class DomainDetailsCell extends Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    return nextProps.domainData !== this.props.domainData || nextProps.reset !== this.props.reset;
  }

  render() {
    const {
      domainData: { id, domain, displayName, faviconUrl, isDemoDomain },
      small,
    } = this.props;
    return (
      <DomainDetails
        domainId={id}
        title={displayName}
        domain={domain}
        logo={faviconUrl}
        isDemoDomain={isDemoDomain}
        reset={true}
        small={small ?? false}
      />
    );
  }
}

export default DomainDetailsCell;
