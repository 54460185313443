import { ComponentType, Suspense } from 'react';

export function withSuspense<T extends object = object>(
  Component: ComponentType<T>,
): ComponentType<T> {
  const ResultComponent = (props: T) => (
    <Suspense>
      <Component {...props} />
    </Suspense>
  );
  const displayName = Component.displayName || Component.name || 'Component';
  ResultComponent.displayName = `withSuspense(${displayName})`;
  return ResultComponent;
}
