export const SET_SERVICE_MESSAGE_READ = 'set_message_read';
export const RESET_SERVICE_MESSAGE_READ = 'reset_message_read';

export function setServiceMessageRead() {
  return {
    type: SET_SERVICE_MESSAGE_READ,
  };
}

export function resetServiceMessageRead() {
  return {
    type: RESET_SERVICE_MESSAGE_READ,
  };
}
