import colorScheme from 'Utilities/colors';
import { t } from 'Utilities/i18n';
import { OverviewChartSeries } from '../constants';
import { addLowDataPlaceholderDays } from '../support/lowData';

type HistoryChartSeries = OverviewChartSeries;

export const getPixelsFromTopSeries = (data: any): HistoryChartSeries[] => {
  const series: HistoryChartSeries[] = [
    {
      name: t('Average Pixels From Top'),
      primary: true,
      color: colorScheme.graphs.colors.turqoise,
      data:
        data?.filter(Boolean)?.map((e) => ({
          x: e?.date ?? 0,
          y: e?.pixelsFromTop ?? 0,
          totalKeywords: Number(e?.keywords ? e?.keywords : 0),
        })) ?? [],
    },
  ];
  addLowDataPlaceholderDays(series[0]);
  return series;
};
