import isNil from 'lodash/isNil';
import sortBy from 'lodash/sortBy';
import { labelsFormatter } from 'Components/Chart/support/helpers';
import { useIsPercentage } from 'Hooks/data/domain/useQueryDomainInfo';
import { useDisplayCurrency } from 'Hooks/displayCurrency';
import { LandingTableIDs } from 'Pages/Keywords/LandingPage/components/KeywordLandingTable/support/constants';
import { InjectedIntl } from 'Types/Intl';
import colorScheme from 'Utilities/colors';
import { t } from 'Utilities/i18n';
import { NOTES_SERIES_ID, NOT_IN_TOP_100_VALUE, lineChartColors } from './constants';
import { tooltipFormatter } from './tooltip';
import { LineChartType, LineChartTypes, LineColorsType, SeriesDataItem, SeriesItem } from './types';

const colors = colorScheme.defaultLines;

interface IndividualSeriesOptions {
  id: NamedCurve;
  name: string;
  type: string;
  data: {
    x: number;
    y: number | null;
  }[];
  color: string;
  visible: boolean;
  threshold?: number;
  zoneAxis?: string;
  zones?: Highcharts.SeriesZonesOptionsObject[];
  fillColor?: any;
}

const labelFormatter = (asPercentage: boolean, metric?: string, displayCurrency?: string) => {
  let unit = '';

  if (asPercentage) {
    unit = '%';
  } else if (metric === LandingTableIDs.COST_VALUE && displayCurrency) {
    unit = ` ${displayCurrency}`;
  }
  return (x) => `${labelsFormatter(x)}${unit}`;
};

export const rankLabelFormatter = (data) => {
  if (data?.value > 100) {
    return ' ';
  }
  return data?.value;
};

export const useLabelFormatter = (metric?: string, showPercentage?: boolean) => {
  const { displayCurrency } = useDisplayCurrency(true);
  const isPercentage = useIsPercentage();
  const asPercentage =
    showPercentage ||
    metric === LandingTableIDs.DYNAMIC_CTR ||
    (metric === LandingTableIDs.SHARE_OF_VOICE && isPercentage);
  return labelFormatter(asPercentage, metric, displayCurrency);
};

// for class components

export const getChartType = (chartType: LineChartTypes) => {
  return chartType === LineChartType.AREA ? 'areaspline' : 'spline';
};

// eslint-disable-next-line import/no-unused-modules
export const formatChartNumber = (
  intl: InjectedIntl,
  num: number,
  showPercentage: boolean = false,
) => {
  return intl?.formatNumber(num / (showPercentage ? 100 : 1), {
    minimumFractionDigits: showPercentage ? 2 : 0,
    maximumFractionDigits: showPercentage ? 2 : 0,
    style: showPercentage ? 'percent' : 'decimal',
  });
};

const getChartLineColor = (
  path: string,
  index?: number,
  customColors?: { [key: string]: string },
) => {
  if (customColors) {
    return customColors[path] ?? lineChartColors.line.DEFAULT_COLOR;
  }
  const resultIndex = (index ?? 0) % colors?.length;
  return colors?.[resultIndex] ?? lineChartColors.line.DEFAULT_COLOR;
};

export const getFakeSeriesData = (): SeriesItem[] => [
  {
    name: 'domain.com',
    primary: true,
    data: [...Array(14)].map((_, item) => ({
      x: new Date(`02-0${item + 1}-2018`).getTime(),
      y: Math.floor(Math.random() * 1000),
    })),
  },
];

const formatY = (item: SeriesDataItem, isRank: boolean) => {
  if (isRank) {
    if ((item?.y || 0) > 100) {
      return NOT_IN_TOP_100_VALUE;
    }
    return item?.y || NOT_IN_TOP_100_VALUE;
  }
  return item?.y;
};

const hexToRGBA = (hex, opacity) => {
  // Remove the "#" symbol if it's present
  hex = hex.replace(/^#/, '');

  // Parse the hex color components
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Ensure opacity is in the range [0, 1]
  opacity = Math.min(1, Math.max(0, opacity));

  // Create the RGBA color
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

const formatSingleSeries = (
  isRank: boolean,
  chartType: LineChartTypes,
  series: SeriesItem,
  chartColors?: LineColorsType,
  index?: number,
  visible?: boolean,
): IndividualSeriesOptions => {
  const color = series?.color ?? getChartLineColor(series.name, index, chartColors);
  const fillColor =
    chartType === LineChartType.AREA
      ? {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, color.includes('#') ? hexToRGBA(color, 0.5) : color],
            [1, '#FFFFFF'],
          ],
        }
      : undefined;

  return {
    id: series.name,
    name: series.name,
    type: getChartType(chartType),
    data:
      sortBy(series?.data, 'x')?.map((el) => ({ ...el, x: el.x, y: formatY(el, isRank) })) ?? [],
    color,
    fillColor,
    visible: series.visible ?? visible ?? true,
    zoneAxis: series.zoneAxis,
    zones: series.zones,
  };
};

/**
 * Format chart data and notes into series object required for chart
 */
export const formatChartSeries = (
  isRank: boolean,
  chartType: LineChartTypes,
  series?: SeriesItem[],
  chartColors?: LineColorsType,
  visibility?: Record<string, boolean>,
): IndividualSeriesOptions[] => {
  const getIsVisible = (name) => {
    return isNil(visibility) ? true : visibility?.[name];
  };
  const result =
    series?.map((e, i) =>
      formatSingleSeries(isRank, chartType, e, chartColors, i, !!getIsVisible(e.name)),
    ) ?? [];

  return result;
};

export const getLegendLabelFormat = (data: Highcharts.Point | Highcharts.Series) => {
  const value = data?.name.replaceAll('\'', '`'); // The first char breaks the legend if it appears.. Second looks just like it.

  return `<div class="chart-legend-item-container" title="${value}"><span id="${data?.options?.id}" class="chart-legend-item" >${value}</span></div>`;
};

export const chartPointFormatter = (
  intl: InjectedIntl,
  data?: Highcharts.TooltipFormatterContextObject,
  showPercentage?: boolean,
  metric?: string,
  isRankChart?: boolean,
): string => {
  const seriesName = data?.series?.name || data?.points?.[0]?.series?.name;

  if (seriesName === NOTES_SERIES_ID) {
    return '';
  }
  let value: string;
  if (isRankChart && data?.y === NOT_IN_TOP_100_VALUE) {
    value = t('Not in top 100');
  } else {
    value =
      data?.y === undefined || data?.y === null
        ? ''
        : intl?.formatNumber(data?.y / (showPercentage ? 100 : 1), {
            minimumFractionDigits: showPercentage ? 2 : 0,
            maximumFractionDigits: showPercentage ? 2 : 0,
            style:
              (showPercentage && metric === LandingTableIDs.SHARE_OF_VOICE) ||
              metric === LandingTableIDs.DYNAMIC_CTR
                ? 'percent'
                : 'decimal',
          });
  }
  const color = data?.color || (data?.points?.[0]?.series as any)?.color;
  return tooltipFormatter(value, color, seriesName);
};

export const getChartPositionerTooltip = (
  labelWidth: number,
  labelHeight: number,
  point: Highcharts.TooltipPositionerPointObject,
  tooltip: Highcharts.Tooltip,
) => {
  const chart = tooltip.chart;
  const plotLeft = chart.plotLeft;
  const plotTop = chart.plotTop;
  const plotWidth = chart.plotWidth;
  const plotHeight = chart.plotHeight;

  // Default tooltip position near the point
  let x = point.plotX + plotLeft + 20;
  let y = point.plotY + plotTop + 10;

  // Adjust horizontal position to keep tooltip within chart bounds
  //===============================================================
  if (x + labelWidth > plotLeft + plotWidth) {
    if (x > labelWidth + plotLeft) {
      // Case 1: If tooltip overflows right, show it to the left of the pointer
      x = point.plotX + plotLeft - labelWidth - 20;
    } else {
      // Case 2: If tooltip overflows right and not enough space to show it to the left: Overflow the pointer and adjust to the right of the chart
      x = plotLeft + plotWidth - labelWidth - 10;
    }
  }

  // Adjust vertical position to keep tooltip within chart bounds
  //===============================================================
  // Case 1: If tooltip overflows bottom, render the tooltip above the point
  if (y + labelHeight > plotTop + plotHeight) {
    y = plotTop + point.plotY - labelHeight - 10;
    // Case 2: If tooltip now overflows top, adjust to the top of the chart
    if (y < plotTop) {
      y = plotTop + 10;
    }
  }

  return { x, y };
};
