import { Flex } from '@mantine/core';
import AccButton from 'Components/AccButton/AccButton';
import { Field, Form } from 'Components/Fields';
import FormErrors from 'Components/Forms/FormErrors';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import { useRefundInvoiceMutation } from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import toast from 'Hooks/useToast';
import { throwNetworkError, throwSubmitErrors } from 'Utilities/errors';
import { t } from 'Utilities/i18n';
import Validator from 'Utilities/validation';

type Props = {
  organizationId: number;
  invoiceId: string;
};

const SalesRefundForm = ({ organizationId, invoiceId }: Props) => {
  const { hideModal } = useModal();
  const [refundInvoiceMutation] = useRefundInvoiceMutation();

  const handleSubmit = ({ reason, partialAmount, skipBraintreeRefund }) => {
    const input: any = {
      organizationId,
      reason,
      partialAmount: parseFloat(partialAmount),
    };
    if (invoiceId) {
      input.invoiceId = invoiceId;
    }
    if (skipBraintreeRefund) {
      input.skipBraintreeRefund = skipBraintreeRefund;
    }

    return refundInvoiceMutation({
      variables: {
        input,
      },
    }).then((data) => {
      if (data.errors?.length) {
        throwSubmitErrors(data.errors.map((e) => e as any));
      } else {
        toast.success(t('Organization refunded'));
        hideModal();
      }
    }, throwNetworkError);
  };

  return (
    <ModalBorder title={t('Refund')} onClose={hideModal}>
      <Form onSubmit={handleSubmit} subscription={{ submitting: true, invalid: true }}>
        {({ submitting, invalid }) => (
          <Flex direction="column" gap={'md'}>
            <FormErrors />
            <Field.TextInput
              name="reason"
              label={t('Reason for the refund')}
              placeholder={t('What is the reason for this refund?')}
              validate={[Validator.required]}
            />

            <Field.TextInput
              name="partialAmount"
              label={t('Partial refund')}
              placeholder={t('Amount to refund excl. VAT. Leave empty for full refund.')}
            />

            <Field.Checkbox
              name="skipBraintreeRefund"
              label={t('High Risk - Skip Braintree refund')}
              helpTextPopover={t(
                'Check this to bypass settlement in Braintree. You should only use this for customers paying by invoice and only if you are 100% sure of what you are doing!',
              )}
            />

            <ModalFooter
              primaryButtonSlot={
                <AccButton variant="primary" type="submit" disabled={submitting || invalid}>
                  {t('Refund')}
                </AccButton>
              }
            />
          </Flex>
        )}
      </Form>
    </ModalBorder>
  );
};

export default SalesRefundForm;
