import { PropsWithChildren } from 'react';
import { Flex } from '@mantine/core';
import { Icon } from 'icons/Icon';
import { useAutoScroll } from './support/hooks';
import styles from './autoScroll.module.scss';

export const AutoScrollWrapper = ({
  children,
  up,
  topStickyOffset,
}: PropsWithChildren<{ up: boolean; topStickyOffset?: number }>) => {
  const { isOver, drop, ref, showJump } = useAutoScroll(up, topStickyOffset || 0);

  return (
    <>
      <div ref={ref} className={styles.placeholder} />
      <Flex
        ref={drop}
        className={styles.container}
        data-is-over={isOver || null}
        data-up={up || null}
        gap={0}
        display={showJump ? 'flex' : 'none'}
      >
        <Icon
          type={up ? 'arrow-up' : 'arrow-down'}
          className={styles.icon}
          data-is-over={isOver || null}
        />
      </Flex>
      {showJump ? null : children}
    </>
  );
};
