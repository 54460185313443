import { useEffect, useRef, useState } from 'react';
import { FilterBase } from 'Types/Filter';
import FilterCell from './filter';
import FilterTabsContainer from './filterTabsContainer';
import FilterTabsItem from './filterTabsItem';
import './tabs-filter.scss';

type Props = {
  initialTab: number;
  filterAttributes?: string[];
  currentActiveFilters: FilterBase[];
  onClose: () => void;
  onSetFilter: () => void;
};

const FilterTabs = ({
  filterAttributes,
  currentActiveFilters,
  initialTab,
  onClose,
  onSetFilter,
}: Props): JSX.Element => {
  const [currentTab, setCurrentTab] = useState(initialTab);
  const tabFilterContainer = useRef<any>();
  useEffect(() => {
    const popoverContainer = tabFilterContainer.current;
    const inputField = popoverContainer?.querySelector('.text-input-control');
    let timeout;

    if (inputField) {
      setTimeout(() => {
        inputField.focus();
      }, 500);
    }

    return () => timeout && clearTimeout(timeout);
  }, [currentTab]);

  /**
   *
   * @param tab (number)
   */
  const onClickTab = (tab) => {
    setCurrentTab(tab);
  };

  // TODO FixTSignore
  const tempPropsFix: any = {};
  return (
    <div className="tabs-filter" ref={tabFilterContainer}>
      <FilterTabsContainer>
        {filterAttributes &&
          filterAttributes.length > 1 &&
          filterAttributes.map((filterAttribute, index) => (
            <FilterTabsItem
              currentActiveFilters={currentActiveFilters}
              key={index}
              currentTab={currentTab}
              filterAttribute={filterAttribute}
              index={index}
              onClick={() => onClickTab(index + 1)}
            />
          ))}
      </FilterTabsContainer>
      <div>
        {filterAttributes?.map((filterAttribute, index) => {
          if (index + 1 !== currentTab) return null;
          return (
            <FilterCell
              key={filterAttribute}
              filterAttribute={filterAttribute}
              onCancel={onClose}
              onSetFilter={onSetFilter}
              {...tempPropsFix}
            />
          );
        }) ?? null}
      </div>
    </div>
  );
};

export default FilterTabs;
