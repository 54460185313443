import { memo, useCallback, useEffect } from 'react';
import { useField } from 'react-final-form';
import { Select as MantineSelect, SelectProps } from '@mantine/core';
import { SelectItem } from 'Components/AccSelect';
import { t } from 'Utilities/i18n';
import styles from './simpleSelect.module.scss';

type SimpleSelectProps = {
  name: string;
  data: SelectItem<string>[];
  onChange?: (selectItem?: SelectItem<string>) => void;
  required?: boolean;
} & Omit<SelectProps, 'onChange'>;

const SimpleSelect = (props: SimpleSelectProps) => {
  const { name, data, onChange, defaultValue, required, ...rest } = props;

  const { input, meta } = useField(name, {
    type: 'select',
    validate: (value: any) => {
      return value ? undefined : required ? t('Field is required') : undefined;
    },
  });

  // set default value if provided
  useEffect(() => {
    if (!defaultValue) return;
    const defaultSelected = data.find((option) => option?.value === defaultValue);
    input.onChange(defaultSelected);
  }, [defaultValue]);

  const handleChange = useCallback(
    (value: string | null, _option) => {
      const selectedItem = data.find((item) => item?.value === value);

      onChange?.(selectedItem);
      input?.onChange(selectedItem);
    },
    [onChange, input.onChange, data],
  );

  return (
    <MantineSelect
      defaultValue={defaultValue}
      data={data}
      onChange={handleChange}
      value={input.value?.value || ''}
      error={meta.modified ? meta.error : ''}
      classNames={{ option: styles.option, input: styles.input }}
      {...rest}
    />
  );
};

export default memo(SimpleSelect);
