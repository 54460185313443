import { InjectedIntl } from 'Types/Intl';

const ONE_BILLION = 1000000000;
const ONE_MILLION = 1000000;
const TEN_THOUSAND = 10000;
const ONE_THOUSAND = 1000;
const ONE_HUNDRED = 100;

const defaultBillionsSettings = {
  billionsCutoff: ONE_BILLION,
  billionsLabel: 'B',
};
const defaultMillionsSettings = {
  millionsCutoff: ONE_MILLION,
  millionsLabel: 'M',
};
const defaultThousandsSettings = {
  thousandsCutoff: TEN_THOUSAND,
  thousandsLabel: 'K',
};

interface FormatNumberHelperParams {
  value: number;
  precision?: number;
  percentage?: boolean;
  thousandsSettings?: typeof defaultThousandsSettings;
  millionsSettings?: typeof defaultMillionsSettings;
  billionsSettings?: typeof defaultBillionsSettings;
  hasSpaceBetweenNumberAndLabel?: boolean;
  intlFormatNumber?: InjectedIntl['formatNumber'];
}

const formatNumberHelper = ({
  value,
  precision,
  percentage,
  thousandsSettings,
  millionsSettings,
  billionsSettings,
  hasSpaceBetweenNumberAndLabel,
  intlFormatNumber = (v) => v?.toString(),
}: FormatNumberHelperParams): string => {
  const spaceBetweenNumberAndLabel = hasSpaceBetweenNumberAndLabel ? ' ' : '';

  if (percentage) {
    return intlFormatNumber(value / 100, {
      style: 'percent',
      minimumFractionDigits: precision,
      maximumFractionDigits: precision,
    });
  }

  if (billionsSettings && value >= billionsSettings.billionsCutoff) {
    const billions = Math.round((value / ONE_BILLION) * ONE_HUNDRED) / ONE_HUNDRED;
    const val = billions.toFixed(precision);
    return `${val}${spaceBetweenNumberAndLabel}${billionsSettings.billionsLabel}`;
  }

  if (millionsSettings && value >= millionsSettings.millionsCutoff) {
    const millions = Math.round((value / ONE_MILLION) * ONE_HUNDRED) / ONE_HUNDRED;
    const val = millions.toFixed(precision);
    return `${val}${spaceBetweenNumberAndLabel}${millionsSettings.millionsLabel}`;
  }

  if (thousandsSettings && value >= thousandsSettings.thousandsCutoff) {
    const thousands = Math.round((value / ONE_THOUSAND) * ONE_HUNDRED) / ONE_HUNDRED;
    const val = thousands.toFixed(precision);
    return `${val}${spaceBetweenNumberAndLabel}${thousandsSettings.thousandsLabel}`;
  }

  return intlFormatNumber(value, {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });
};

export const formatDisplayNumber = (
  value: FormatNumberHelperParams['value'],
  {
    thousandsSettings = defaultThousandsSettings,
    millionsSettings = defaultMillionsSettings,
    billionsSettings = defaultBillionsSettings,
    ...rest
  }: Omit<FormatNumberHelperParams, 'value'> = {
    thousandsSettings: defaultThousandsSettings,
    millionsSettings: defaultMillionsSettings,
    billionsSettings: defaultBillionsSettings,
  },
) =>
  formatNumberHelper({
    value,
    thousandsSettings,
    millionsSettings,
    billionsSettings,
    ...rest,
  });

export {
  formatNumberHelper,
  defaultBillionsSettings,
  defaultMillionsSettings,
  defaultThousandsSettings,
};
