import { memo } from 'react';
import { t, tct } from 'Utilities/i18n';
import { DOMAIN_MODE } from '../BuildDomainForm';

const DomainFormAlerts = ({ mode }: { mode: DOMAIN_MODE }) => {
  return (
    <>
      {mode === DOMAIN_MODE.youtube && (
        <p className="alert alert-info">
          <strong>{t('You are about to add a YouTube channel.')}</strong>
          <br />
          {t('When using a channel, we will be tracking all the video urls within your channel.')}
          <br />
          {tct('You can read more about our YouTube integration [link:here].', {
            link: (
              <a
                href="https://www.accuranker.com/help/search-engines/setting-up-youtube-rank-tracking"
                target="_blank"
                rel="noreferrer"
              />
            ),
          })}
        </p>
      )}
      {mode === DOMAIN_MODE.youtubeVideo && (
        <p className="alert alert-info">
          <strong>{t('You are about to add a YouTube video URL.')}</strong>
          <br />
          {t(
            'If you add the channel URL, AccuRanker will automatically track all video URLs within the channel.',
          )}
          <br />
          {tct('Read more about the YouTube integration [link:here].', {
            link: (
              <a
                href="https://www.accuranker.com/help/search-engines/setting-up-youtube-rank-tracking"
                target="_blank"
                rel="noreferrer"
              />
            ),
          })}
        </p>
      )}
    </>
  );
};

export default memo(DomainFormAlerts);
