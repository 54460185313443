import { defaultRole, hasWriteAccess } from 'Constants/workspaces';
import { useUser } from './data/user/useUser';

/**
 * Custom hook that returns the user's permissions.
 * @returns An object containing the user's permissions.
 */
const useUserPermission = (): {
  isAdmin: boolean;
  isSuperuser: boolean;
  isWriteuser: boolean;
  userHasWriteAccess: boolean;
  hasAnyRole: boolean;
} => {
  const { userType, workspaces } = useUser();
  const userHasWriteAccess = hasWriteAccess.includes(userType || defaultRole);
  const hasAnyRole = Boolean(workspaces?.length);
  return {
    isAdmin: userType === 1,
    isSuperuser: userType === 2,
    isWriteuser: userType === 3,
    userHasWriteAccess,
    hasAnyRole,
  };
};

export default useUserPermission;
