import { KeydisKpisQuery, useKeydisKpisQuery } from 'Ghql';
import { useFilters, useSpecificFilter } from 'Hooks';
import { useDisplayCurrency } from 'Hooks/displayCurrency';
import toast from 'Hooks/useToast';
import { FilterAttribute } from 'Types/Filter';
import { t } from 'Utilities/i18n';

const useHasDomainOrFreeTextDomainFilter = () => {
  const domainsFilter = useSpecificFilter(FilterAttribute.DOMAINS);
  const freeTextDomainsFilter = useSpecificFilter(FilterAttribute.FREE_TEXT_DOMAIN);
  return Boolean(domainsFilter || freeTextDomainsFilter);
};

/**Get key performance metrics for Keyword Discovery dashboard
 * and set toast notification if no keywords was found.
 */
export const useKeydisKpis = (selectedCountry: string | undefined) => {
  const filters = useFilters();

  const hasDomainsFilter = useHasDomainOrFreeTextDomainFilter();

  const { displayCurrency = 'USD' } = useDisplayCurrency(true);

  const onSuccessFullyLoaded = (kpiData: KeydisKpisQuery) => {
    const numberOfKeywords = kpiData?.keydisKpis?.discoveredKeywords ?? 0;
    if (numberOfKeywords) {
      return;
    }
    if (filters.length > 3) {
      toast.info(t('No keywords found.\n Try removing one or more filters.'), { autoClose: 5000 });
    }
  };

  const { data, loading } = useKeydisKpisQuery({
    variables: { filters, country: selectedCountry, displayCurrency },
    skip: !selectedCountry || !hasDomainsFilter,
    onCompleted: (kpiData) => onSuccessFullyLoaded(kpiData),
  });

  return { data: data?.keydisKpis, loading };
};
