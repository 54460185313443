import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

type Props = {
  className?: string;
  inputClassName?: string;
  autoFocus?: boolean;
  value: any;
  onChange: (...args: Array<any>) => any;
  onBlur: (...args: Array<any>) => any;
  placeholder: string;
  searchPlaceholder: string;
  disabled?: boolean;
  readOnly?: boolean;
  defaultCountry?: string;
  autoComplete?: string;
};

const PhoneInput = ({
  value,
  onChange,
  placeholder,
  searchPlaceholder,
  inputClassName,
  disabled,
  readOnly,
  autoFocus,
  onBlur,
  defaultCountry,
  autoComplete,
}: Props) => {
  const handleChange = (_, __, ___, formattedValue: string) => onChange?.(formattedValue);

  const inputProps = {
    readOnly,
    autoFocus,
    className: inputClassName,
    autoComplete,
  };

  return (
    <ReactPhoneInput
      {...(defaultCountry && { country: defaultCountry.toLowerCase() })}
      value={value}
      placeholder={placeholder}
      searchPlaceholder={searchPlaceholder}
      onChange={handleChange}
      onBlur={onBlur}
      disabled={disabled}
      inputProps={inputProps}
      enableSearch
      disableSearchIcon
      dropdownStyle={{ minHeight: '330px' }}
      searchClass={'register-phone-country-search'}
    />
  );
};

export default PhoneInput;
