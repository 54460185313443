import { useIntl } from 'react-intl';
import { LineChart } from 'Components/Chart/LineChart';
import { TooltipConfiguration, chartTooltipTypes } from 'Components/Chart/LineChart/support/types';
import { useQueryNotesByKeywordId } from 'Components/Chart/Notes/support/hooks';
import { UnkowncompetitorHistoryGraphNode, useTopCompetitorsChartQuery } from 'Ghql';
import { useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import { useRequiredFilters } from 'Hooks/data/filter/useFilters';
import { Metrics, useMetrics } from 'Hooks/data/metrics/useMetrics';
import { getColorsMap } from 'Utilities/colors';
import { convertTopRankResponseToSeriesData } from './helpers';

export const TopCompetitorsChart = ({ keywordId }: { keywordId: string }) => {
  const intl = useIntl();
  const filters = useRequiredFilters();
  const { data, loading } = useTopCompetitorsChartQuery({
    variables: { filters, keywordId },
  });
  const { notes, isLoadingNotes } = useQueryNotesByKeywordId(keywordId);
  const historyData =
    (data?.graphs?.unknowncompetitorHistory as UnkowncompetitorHistoryGraphNode[]) ?? [];

  const series = convertTopRankResponseToSeriesData(historyData);
  const { colorMap } = useQueryDomainInfo();
  const dynamicCompetitors = series.filter((e) => !e.primary).map((e) => e.name);
  const colors = getColorsMap(colorMap, dynamicCompetitors);

  const tooltipConfig: TooltipConfiguration = {
    type: chartTooltipTypes.MULTI,
    showPercentage: false,
    customNumFormatter: (num) =>
      intl?.formatNumber(num, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        style: 'decimal',
      }),
  };
  const { metricToYAxisLabel } = useMetrics();

  return (
    <LineChart
      series={series}
      isLoading={loading || isLoadingNotes}
      colors={colors}
      notes={notes}
      loaderType="line"
      disableLegend={false}
      tooltip={tooltipConfig}
      stoked
      exportButton
      enableRangeSelector
      isRank
      yAxisLabel={metricToYAxisLabel(Metrics.Rank)}
    />
  );
};
