import * as Sentry from '@sentry/react';
// eslint-disable-next-line import/no-unresolved
import { FallbackRender } from '@sentry/react/types/errorboundary';
import noop from 'lodash/noop';
import SystemError from 'Pages/ErrorPages/500';
import PageError from 'Pages/ErrorPages/pageError';

type Props = {
  children: any;
  skipErrorShow?: boolean;
  page?: boolean;
  onError?: () => void;
  /**
   * Manually pass a fallback component to render when an error is thrown
   * @example <ErrorBoundary fallback={({ error, componentStack, resetError }) => (<CustomErrorComponent />} />
   */
  fallback?: FallbackRender;
};

const ErrorBoundary = (props: Props) => {
  const { page = true, children, skipErrorShow, onError, fallback } = props;
  const Fallback: FallbackRender = skipErrorShow
    ? (_errorData) => <></>
    : page
    ? (errorData) => <PageError {...errorData} />
    : (errorData) => <SystemError {...errorData} />;

  return (
    <Sentry.ErrorBoundary
      onError={onError || noop}
      fallback={(errorData) => (fallback ? fallback(errorData) : <Fallback {...errorData} />)}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
