import { ApolloError } from '@apollo/client';
import { useCountrylocaleEditorQuery, useFilterOptionsQuery } from 'Ghql';
import { useFilters } from 'Hooks';
import { FilterAttribute } from 'Types/Filter';
import getIsKeyDis from 'Utilities/getKeyDis';

type CountryLocaleType = {
  id: string;
  countryCode: string;
  region: string;
  locale: string;
  count?: number;
};

export const useCountryLocalesData = (): {
  data: CountryLocaleType[];
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const filters = useFilters();
  const {
    data: countryLocaleData,
    loading: localeLoading,
    error: localeError,
  } = useCountrylocaleEditorQuery();
  const {
    data: optionsData,
    loading: optionsLoading,
    error: optionsError,
  } = useFilterOptionsQuery({
    fetchPolicy: 'cache-first',
    variables: {
      filters: filters.filter((f) => f.attribute !== FilterAttribute.COUNTRY_LOCALE),
      isKeydis: getIsKeyDis(),
      filterType: FilterAttribute.COUNTRY_LOCALE,
    },
  });

  const countryLocales = countryLocaleData?.countrylocales || [];
  const filterOptions = optionsData?.filterData?.filterOptions;
  const options = optionsData?.filterData?.filterOptions === null ? null : filterOptions || [];
  const data = countryLocales.reduce((accumulator: CountryLocaleType[], countryLocale) => {
    if (countryLocale) {
      if (!optionsLoading && !optionsError && options !== null) {
        const count = options.find((o) => o.option === countryLocale?.id)?.count || 0;
        if (!count) return accumulator;
        accumulator.push({
          ...countryLocale,
          count,
        });
      } else {
        accumulator.push(countryLocale);
      }
    }
    return accumulator;
  }, []);

  const loading = localeLoading || optionsLoading;
  const error = localeError || optionsError;
  return { data, loading, error };
};
