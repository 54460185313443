import { useState } from 'react';
import { useField } from 'react-final-form';
import { FileWithPath, MIME_TYPES } from '@mantine/dropzone';
import { t } from 'Utilities/i18n';
import Dropzone from '../../../Dropzone';

type ImagePickerProps = {
  name: string;
  preview?: string;
};

const ImagePicker = (props: ImagePickerProps) => {
  const { input } = useField(props.name);
  const { preview: previewProp } = props;

  const [preview, setPreview] = useState<string | undefined>(previewProp);

  const handleDrop = (acceptedFiles: FileWithPath[]) => {
    const file = acceptedFiles?.[0];
    if (!file || !input) {
      return;
    }
    input.onChange(acceptedFiles[0]);
    setPreview(URL.createObjectURL(acceptedFiles[0]));
  };

  const handleDelete = () => {
    input.onChange(null);
    preview && URL.revokeObjectURL(preview);
    setPreview(undefined);
  };

  return (
    <Dropzone
      onDropFile={handleDrop}
      onDeleteFile={handleDelete}
      preview={preview}
      acceptFiles={[MIME_TYPES.jpeg, MIME_TYPES.png, MIME_TYPES.jpeg]}
      description={t('Drag and drop image to upload')}
      invalidFileMessage={t('Invalid file type. Please provide an image type.')}
      fileSize={1024 * 1024}
      multiple={false}
    />
  );
};

export default ImagePicker;
