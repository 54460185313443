import PageHeaderContainer from 'Components/PageHeaderContainer';
import Skeleton from 'Components/Skeleton';
import styles from './groups-placeholder.module.scss';

const SIZE = '18px';

export const GroupsPlaceholder = () => {
  return (
    <PageHeaderContainer className={styles.groupsPlaceholder}>
      <Skeleton
        linesConfig={[
          {
            type: 'text',
            options: {
              width: SIZE,
              height: SIZE,
            },
          },
        ]}
      />
      {new Array(3).fill(null).map((_, i) => (
        <Skeleton
          key={i}
          linesConfig={[
            {
              type: 'text',
              options: {
                width: '120px',
                height: SIZE,
              },
            },
          ]}
        />
      ))}
    </PageHeaderContainer>
  );
};
