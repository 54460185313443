import { useEffect, useRef, useState } from 'react';
import { Group } from '@mantine/core';
import { IconArrowNarrowRight, IconEdit } from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import {
  usePublicReportClientPublicUrlQuery,
  usePublicReportDomainPublicUrlQuery,
  usePublicReportRequestClientPublicUrlMutation,
  usePublicReportRequestDomainPublicUrlMutation,
} from 'Ghql';
import { useClipboard } from 'Hooks';
import { useModal } from 'Hooks/base/useModal';
import { TrackingKey } from 'Utilities/Analytics/mixpanel';
import { t } from 'Utilities/i18n';
import CopyIcon from 'icons/copy.svg?inline';
import './public-report-options.scss';

const createReportUrl = (url: string | undefined) => {
  return url;
};

const publicReportType = 4;

type Props = {
  reportType: Record<string, any>;
  domainId?: string;
  groupId?: string;
};

const PublicReportOptions = (props: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { domainId, reportType, groupId } = props;

  const [publicUrl, setPublicUrl] = useState('');

  const { showModal, hideModal } = useModal();

  const clipboard = useClipboard({ timeout: 1000 });

  const { data: domainPublicUrlData, loading: loadingDomainPublicUrlData } =
    usePublicReportDomainPublicUrlQuery({
      fetchPolicy: 'network-only',
      variables: {
        id: domainId as string,
      },
      skip: !domainId,
    });
  const { data: clientPublicUrlData, loading: loadingClientPublicUrlData } =
    usePublicReportClientPublicUrlQuery({
      fetchPolicy: 'network-only',
      variables: {
        id: groupId as string,
      },
      skip: !groupId,
    });
  const [requestDomainPublicUrl] = usePublicReportRequestDomainPublicUrlMutation();
  const [requestClientPublicUrl] = usePublicReportRequestClientPublicUrlMutation();

  useEffect(() => {
    if (publicUrl) return;

    if (loadingDomainPublicUrlData || loadingClientPublicUrlData) return;

    let tmpPublicUrl = '';
    if (domainId) tmpPublicUrl = domainPublicUrlData?.domain?.publicUrl ?? '';
    if (groupId) tmpPublicUrl = clientPublicUrlData?.client?.publicUrl ?? '';

    if (tmpPublicUrl) {
      const reportUrl = createReportUrl(tmpPublicUrl);
      reportUrl && setPublicUrl(reportUrl);
    }
  }, [
    loadingDomainPublicUrlData,
    loadingClientPublicUrlData,
    domainPublicUrlData,
    clientPublicUrlData,
    publicUrl,
  ]);

  const handleResetUrl = () => {
    if (domainId) {
      requestDomainPublicUrl({
        variables: {
          id: domainId,
        },
      }).then(({ data }) => {
        const url = data?.resetViewkeyDomain?.domain?.publicUrl || undefined;
        const reportUrl = createReportUrl(url);
        reportUrl && setPublicUrl(reportUrl);
      });
    } else if (groupId) {
      requestClientPublicUrl({
        variables: {
          id: groupId,
        },
      }).then(({ data }) => {
        const url = data?.resetViewkeyClient?.client?.publicUrl || undefined;
        const reportUrl = createReportUrl(url);
        reportUrl && setPublicUrl(reportUrl);
      });
    }
  };

  const handleGoToReport = () => {
    if (domainId) {
      window.open(publicUrl, '_blank', 'noopener,noreferrer');
    }
  };

  const renderLinkInput = () => {
    return <input value={publicUrl} disabled ref={inputRef} />;
  };

  const handleOpenConfirmationModal = () => {
    showModal({
      modalType: 'Confirmation',
      modalProps: {
        showExclamationInTitle: false,
        title: t('Reset public report link?'),
        lockDuration: 0,
        description: t(
          'Are you sure you want to reset the public link for this report? \n This will invalidate the current link.',
        ),
        cancelLabel: t('Cancel'),
        confirmLabel: t('Refresh'),
        action: () => handleResetUrl(),
        cancelAction: () => hideModal(),
      },
      nested: true,
    });
  };

  if (!reportType || reportType.value !== publicReportType) return <></>;

  return (
    <div className="public-report-options">
      <div className="form-label">{t('Public link')}</div>
      <div className="public-report-options__input-container">
        {renderLinkInput()}
        <AccTooltip
          delay={0}
          old
          tooltip={clipboard.copied ? t('Copied!') : t('Copy link to clipboard')}
        >
          <AccButton
            type="button"
            className="copy-button"
            aria-label={t('Copy link to clipboard')}
            variant="tertiary"
            trackingKey={TrackingKey.PublicReportCopyLink}
            onClick={() => clipboard.copy(inputRef?.current?.value)}
          >
            <CopyIcon />
          </AccButton>
        </AccTooltip>
      </div>
      <p>{t('Copy this link and give it to your client.')}</p>
      <Group justify="space-between">
        <AccButton
          mb={2}
          variant="secondary"
          onClick={handleOpenConfirmationModal}
          trackingKey={TrackingKey.PublicReportResetLink}
        >
          {t('Reset public link')}
        </AccButton>
        <AccButton
          mb={2}
          variant="secondary"
          trackingKey={TrackingKey.PublicReportCustomizeReport}
          onClick={handleGoToReport}
          leftSection={<IconEdit size={20} />}
          rightSection={<IconArrowNarrowRight size={20} />}
        >
          {t('Customize report')}
        </AccButton>
      </Group>
    </div>
  );
};

export default PublicReportOptions;
