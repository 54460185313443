import { normalizeTree } from '../../../../../../Utilities/Table/Tree/treeUtils';
import { convertListToTree } from '../helpers';

export function useOnUpdateNode(
  setTreeNested: (value: ((prevState: any[]) => any[]) | any[]) => void,
  onCancelAdd: () => void,
  setDirty: (val: boolean) => void,
) {
  return (id, patch) => {
    onCancelAdd();
    setDirty(true);
    setTreeNested((tree) => {
      let prevNode;
      let updatedNormalizedTree = normalizeTree(tree).map((node) => {
        if (node.id === id) {
          prevNode = node;
          return { ...node, ...patch };
        }
        return node;
      });

      // if parent id was change need to update children reference to new parent id
      if (prevNode?.isFolder && patch?.id) {
        updatedNormalizedTree = updatedNormalizedTree.map((e) =>
          (e.parent === prevNode.id ? { ...e, parent: patch.id } : e),
        );
      }
      return convertListToTree(updatedNormalizedTree);
    });
  };
}
