import { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { ROOT_ID } from '../constants';

/**
 * Handle edit/add modes for nodes
 */
export const useHandleUpdateState = (addExpandedNode?: Function) => {
  const [addTarget, setIsAddMode] = useState<string | number | null>(null);
  const onCancelAdd = () => setIsAddMode(null);
  const onStartAdd = (target: string | number | null = ROOT_ID) => {
    if (target) {
      addExpandedNode?.(target);
    }
    setIsAddMode(target);
  };
  const [editNodes, setEditModes] = useState<Record<string, boolean>>({});
  const cancelAllEdits = () => setEditModes({});
  const isEditingNode = Object.values(editNodes ?? {}).some((e) => e);
  const clearEditModes = () => setEditModes({});

  const getIsEditing = (nodeId?: string | number) => (nodeId && editNodes[nodeId]) ?? false;
  const createSetIsEdit = (id: string | number) => (value: boolean) =>
    setEditModes((acc) => ({ ...acc, [id]: value }));

  return {
    createSetIsEdit,
    getIsEditing,
    addTarget,
    onCancelAdd,
    onStartAdd,
    isEditingNode,
    clearEditModes,
    editNodes,
    isAddMode: !isEmpty(addTarget),
    cancelAllEdits,
  };
};
