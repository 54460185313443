import { useMemo } from 'react';
import { gql, useApolloClient } from '@apollo/client';
import type { MutationOptions } from '@apollo/client/core/watchQueryOptions';
import noop from 'lodash/noop';
import pickBy from 'lodash/pickBy';
import { DefaultChecked } from 'Components/Controls/Checkbox';
import { DEFAULT_PAGE_SIZE, TableStoreType } from 'Components/DataTable';
import { KeywordInfoProps } from 'Components/Modal/Content/KeywordInfo';
import { PaginatedTableKeywordsDocument } from 'Ghql';
import { invalidateCache } from 'Utilities/Graphql/invalidateCache';
import { t, tn } from 'Utilities/i18n';
import LocalStorage from 'Utilities/storage';
import { FilterAttribute, FilterComparison, FilterValueType } from '../Types/Filter';
import { useDisplayCurrency } from './displayCurrency';

type Props = {
  showModal: (a: any) => any;
  gridApi?: any;
};

type SetSelectedParams = {
  keywordId: number;
  selected: boolean;
  gridApi: any;
};

type SetAllSelectedParams = {
  selected: boolean;
  gridApi: any;
  colKey: string;
};

type ShowOneTimeReportModalParams = {
  domainId: number | undefined;
};

type ShowCompareModalParams = {
  keywords: number[];
};

type ShowAddKeywordsModalParams = {
  domainId: number;
};

// UpdateKeywords
type UpdateKeywordsParams = {
  keywords: number[];
  isAllSelected?: boolean;
  filters?: any[];
};

type UpdateKeywordsPreferredLandingPageParams = UpdateKeywordsParams & {
  preferredLandingPage?: string;
  resetPreferredLandingPage?: boolean;
};

type UpdateKeywordsDescriptionParams = UpdateKeywordsParams & {
  description: string;
};

type UpdateKeywordsStarredParams = UpdateKeywordsParams & {
  starred: boolean;
};

type UpdateKeywordsNotesParams = UpdateKeywordsParams & {
  note: string;
  createdAt: string;
  domain: number;
};

export type UpdateKeywordsSettingsValues = {
  ignoreInShareOfVoice: DefaultChecked;
  ignoreLocalResults: DefaultChecked;
  ignoreFeaturedSnippet: DefaultChecked;
  enableAutocorrect: DefaultChecked;
  ignoredDomain: string;
};

type UpdateKeywordsMoveToDomainParams = UpdateKeywordsParams & {
  moveToDomain: number;
};

export type UpdateKeywordsTagsParams = UpdateKeywordsParams & {
  tags: string[];
  remove: boolean;
  folderId?: string;
};

type UpdateKeywordsRefreshParams = UpdateKeywordsParams & {
  isDisabled: boolean;
  callback?: any;
};

type UpdateTableColumnSettingsParams = {
  columnsSettingsName:
    | 'defaultKeywordsColumns'
    | 'defaultCompetitorsColumns'
    | 'defaultLandingPagesColumns'
    | 'defaultTagCloudColumns'
    | 'defaultFoldersColumns'
    | 'defaultNotesColumns'
    | 'defaultKeywordsNotificationsColumns';
  column: string;
  width: number;
};

// modal types
type ShowRemoveTagsModalParams = UpdateKeywordsParams & {
  domainId: number;
};

type ShowDeleteKeywordsModalParams = { onAction: Function } & UpdateKeywordsParams;
type ShowAddNoteModalParams = UpdateKeywordsParams & {
  domainId: number;
  onClose?: Function;
};
type ShowAddTagsModalParams = UpdateKeywordsParams & {
  onClose?: Function;
};
type ShowEditKeywordsModalParams = UpdateKeywordsParams & {
  numResults: number;
  domainId?: string;
};
type ShowMoveKeywordsModalParams = UpdateKeywordsParams & {
  domainId: number;
  onClose?: Function;
};

type ShowLandingPageModalParams = UpdateKeywordsParams & {
  domainId: number;
  preferredLandingPageId?: number;
  highestRankingPage?: string;
};
type ShowDuplicateModalModalParams = UpdateKeywordsParams & {
  domainId: number;
  numResults: number;
  onClose?: Function;
};
type ShowCopyToClipboardModalParams = {
  tableStore: TableStoreType | null;
  filterAndOrderByWithVariables: Record<string, boolean>;
  filters?: any[];
  displayCurrency: string | undefined;
};

type GetKeywordsParams = {
  keywords?: number[];
  filters: any[];
  query?: any;
  stopIndex?: number;
};

const getKeywordsQuery = gql`
  query useKeyword_keywordsQuery(
    $filters: [FilterInput]!
    $pagination: PaginationInput!
    $ordering: OrderingInput!
  ) {
    keywords(filters: $filters, pagination: $pagination, ordering: $ordering) {
      keywords {
        id
        keyword
      }
    }
  }
`;

const updateKeywordsMutation = gql`
  mutation useKeyword_updateKeywordsMutation($input: UpdateKeywordsInput!) {
    updateKeywords(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

const addNoteMutation = gql`
  mutation useKeyword_addNoteMutation($input: AddNoteInput!) {
    addNote(input: $input) {
      note {
        id
      }
    }
  }
`;

const buildUpdateKeywordsInput = (params: UpdateKeywordsParams) => {
  const { keywords, isAllSelected, filters } = params;

  return isAllSelected
    ? {
        filters,
        keywordsToExclude: keywords,
      }
    : {
        keywords,
      };
};

type AdditionMutationOptions = Partial<Omit<MutationOptions, 'mutation'>>;

const useKeyword = ({ showModal }: Props = { showModal: noop }) => {
  const client = useApolloClient();
  const { displayCurrency: _displayCurrency } = useDisplayCurrency(true);

  const updateKeywords = (mutationOptions: AdditionMutationOptions) =>
    client.mutate({ mutation: updateKeywordsMutation, ...mutationOptions }).then((result) => {
      invalidateCache(client.cache);
      return result;
    });

  const addNote = (mutationOptions: AdditionMutationOptions) =>
    client.mutate({ mutation: addNoteMutation, ...mutationOptions });

  const getKeywords = async (params: GetKeywordsParams): Promise<any> => {
    // used to get keyword information, this uses the old so dont overuse this as it might be slow
    const { filters, keywords, query, stopIndex } = params;
    const newFilters = [...filters];
    if (keywords && keywords.length > 0) {
      newFilters.push({
        attribute: FilterAttribute.KEYWORDS,
        type: FilterValueType.LIST,
        comparison: FilterComparison.CONTAINS,
        value: keywords,
      });
    }

    return client.query({
      fetchPolicy: 'network-only',
      query: query || getKeywordsQuery,
      variables: {
        filters: newFilters,
        pagination: {
          page: 1,
          startIndex: 0,
          stopIndex: stopIndex ? stopIndex : DEFAULT_PAGE_SIZE,
          results: 0,
        },
        ordering: {
          order: 'ASC',
          orderBy: 'keyword',
        },
        displayCurrency: _displayCurrency,
        skip: !_displayCurrency,
      },
    });
  };

  // modals
  const showCompareModal = (params: ShowCompareModalParams) => {
    const { keywords } = params;
    showModal({
      modalType: 'KeywordsComparison',
      modalTheme: 'light',
      modalProps: {
        keywords,
      },
    });
  };

  const showOneTimeReportModal = (params: ShowOneTimeReportModalParams) => {
    const { domainId } = params;
    showModal({
      modalType: 'OneTimeReport',
      modalTheme: 'light',
      modalProps: {
        domainId,
      },
    });
  };

  const updateTableColumnWidth = (params: UpdateTableColumnSettingsParams) => {
    const { columnsSettingsName, column, width } = params;
    LocalStorage.save(`table-${columnsSettingsName}-column-${column}-width`, width);
  };

  const showAddKeywordsModal = (params: ShowAddKeywordsModalParams) => {
    const { domainId } = params;
    showModal({
      modalType: 'AddKeywords',
      modalTheme: 'light',
      modalProps: {
        domainId,
      },
    });
  };

  const showRemoveTagsModal = (params: ShowRemoveTagsModalParams) => {
    const { keywords, isAllSelected, filters, domainId } = params;
    showModal({
      modalType: 'RemoveTags',
      modalTheme: 'light',
      modalProps: {
        keywords,
        domainId,
        isAllSelected,
        filters,
      },
    });
  };

  const showDeleteKeywordsModal = (params: ShowDeleteKeywordsModalParams): void => {
    const { keywords } = params;
    showModal({
      modalType: 'Confirmation',
      modalTheme: 'light',
      modalProps: {
        title: tn('Delete Keyword?', 'Delete Keywords?', keywords.length),
        description: tn(
          'The keyword will be permanently deleted.',
          'The keywords will be permanently deleted.',
          keywords.length,
        ),
        confirmLabel: tn('Delete keyword', 'Delete keywords', keywords.length),
        cancelLabel: t('Cancel'),
        action: () => {
          const input = {
            ...buildUpdateKeywordsInput(params),
            delete: true,
          };

          params?.onAction();
          return updateKeywords({
            variables: {
              input,
            },
          });
        },
      },
    });
  };

  const showKeywordModal = (params: Omit<KeywordInfoProps, 'scrollElement'>) => {
    showModal({
      modalType: 'KeywordInfo',
      modalTheme: 'light',
      modalProps: {
        ...params,
      },
    });
  };

  const showLandingPageModal = (params: ShowLandingPageModalParams) => {
    const {
      keywords,
      domainId,
      isAllSelected,
      preferredLandingPageId,
      highestRankingPage,
      filters,
    } = params;
    showModal({
      modalType: 'LandingPage',
      modalTheme: 'light',
      modalProps: {
        keywords,
        domainId,
        isAllSelected,
        preferredLandingPageId,
        highestRankingPage,
        filters,
      },
    });
  };

  const showDuplicateModal = (params: ShowDuplicateModalModalParams) => {
    const { keywords, isAllSelected, filters, domainId, onClose, numResults } = params;

    showModal({
      modalType: 'AddKeywords',
      modalTheme: 'light',
      modalProps: {
        domainId,
        getKeywords: () =>
          getKeywords({
            filters: filters ?? [],
            keywords,
            stopIndex: numResults,
          }),
        numResults,
        shouldExclude: isAllSelected,
        filters,
        onClose,
      },
    });
  };

  const showAddTagsModal = (params: ShowAddTagsModalParams) => {
    const { keywords, isAllSelected, filters, onClose } = params;
    showModal({
      modalType: 'AddTags',
      modalTheme: 'light',
      modalProps: {
        keywords,
        isAllSelected,
        filters,
        onClose,
      },
    });
  };

  const showAddNoteModal = (params: ShowAddNoteModalParams) => {
    const { keywords, domainId, isAllSelected, filters, onClose } = params;
    showModal({
      modalType: 'AddNote',
      modalTheme: 'light',
      modalProps: {
        keywords,
        domainId,
        isAllSelected,
        filters,
        onClose,
      },
    });
  };

  const showEditKeywordsModal = (params: ShowEditKeywordsModalParams) => {
    const { keywords, isAllSelected, filters, domainId, numResults } = params;

    showModal({
      modalType: 'EditKeywords',
      modalTheme: 'light',
      modalProps: {
        keywords,
        isAllSelected,
        filters,
        domainId,
        numResults,
      },
    });
  };

  const showMoveKeywordsModal = (params: ShowMoveKeywordsModalParams) => {
    const { keywords, isAllSelected, filters, domainId, onClose } = params;
    showModal({
      modalType: 'MoveKeywords',
      modalTheme: 'light',
      modalProps: {
        keywords,
        isAllSelected,
        filters,
        domainId,
        onClose,
      },
    });
  };

  const showCopyToClipboardModal = (params: ShowCopyToClipboardModalParams) => {
    const { tableStore, filterAndOrderByWithVariables, filters, displayCurrency } = params;

    if (!tableStore) {
      return;
    }

    let newFilters = filters;
    if (filters && tableStore?.manualSelectedKeywords?.length > 0) {
      newFilters = [
        ...filters,
        {
          attribute: FilterAttribute.KEYWORDS,
          type: FilterValueType.LIST,
          comparison:
            tableStore?.isAllSelected || tableStore?.isAllPageSelected
              ? FilterComparison.NOT_CONTAINS
              : FilterComparison.CONTAINS,
          value: tableStore?.isAllPageSelected
            ? tableStore?.unSelectedRows
            : tableStore?.manualSelectedKeywords,
        },
      ];
    }

    showModal({
      modalType: 'CopyToClipboard',
      modalTheme: 'light',
      modalProps: {
        confirmButtonLabel: t('Copy keywords'),
        value: client
          .query({
            fetchPolicy: 'network-only',
            query: PaginatedTableKeywordsDocument,
            variables: {
              ...filterAndOrderByWithVariables,
              withKeyword: true,
              filters: newFilters,
              pagination: {
                page: 1,
                startIndex: 0,
                stopIndex: tableStore?.totalSelectedCount,
                results: 0,
              },
              ordering: tableStore?.ordering,
              displayCurrency,
            },
          })
          .then(
            ({
              data: {
                tableKeywords: { keywords: _keywords },
              },
            }) => {
              return _keywords.map(({ keyword }: any) => keyword).join('\r\n');
            },
          ),
      },
    });
  };

  const setSelected = async (params: SetSelectedParams): Promise<any> => {
    const { keywordId, selected, gridApi } = params;
    const rowNode = gridApi.getRowNode(keywordId.toString());
    rowNode.setSelected(selected);
  };

  const setAllSelected = async (params: SetAllSelectedParams): Promise<any> => {
    const { selected, gridApi } = params;
    // gridApi.forEachNode((rowNode) => {
    //   rowNode.setSelected(selected);
    //   rowNode.setDataValue(colKey, rowNode.data);
    // });
    const updatedItems: any[] = [];
    gridApi.forEachNode((rowNode: any) => {
      const updatedNode = (rowNode.selected = selected);
      updatedItems.push(updatedNode);
    });
    gridApi.applyTransaction({ update: updatedItems });
  };

  const setAllVisibleSelected = async (params: SetAllSelectedParams): Promise<any> => {
    const { selected, gridApi } = params;
    // gridApi.forEachNode((rowNode) => {
    //   if (rowNode.alreadyRendered) {
    //     rowNode.setSelected(selected);
    //     rowNode.setDataValue(colKey, rowNode.data);
    //   }
    // });
    const updatedItems: any[] = [];
    gridApi.forEachNode((rowNode: any) => {
      if (rowNode.alreadyRendered) {
        const updatedNode = (rowNode.selected = selected);
        updatedItems.push(updatedNode);
      }
    });
    gridApi.applyTransaction({ update: updatedItems });
  };

  // updateKeywords
  const updateKeywordsPreferredLandingPage = async (
    params: UpdateKeywordsPreferredLandingPageParams,
  ): Promise<any> => {
    const { preferredLandingPage, resetPreferredLandingPage } = params;

    const input = {
      ...buildUpdateKeywordsInput(params),
      preferredLandingPage,
      resetPreferredLandingPage,
    };

    return updateKeywords({
      variables: {
        input,
      },
    });
  };

  const updateKeywordsDescription = (params: UpdateKeywordsDescriptionParams): Promise<any> => {
    const { description } = params;

    const input = {
      ...buildUpdateKeywordsInput(params),
      description,
    };

    return updateKeywords({
      variables: {
        input,
      },
    });
  };

  const updateKeywordsStarred = async (params: UpdateKeywordsStarredParams): Promise<any> => {
    const { starred } = params;

    const input = {
      ...buildUpdateKeywordsInput(params),
      starred,
    };

    return updateKeywords({
      variables: {
        input,
      },
    });
  };

  const updateKeywordsSettings = async (
    params: UpdateKeywordsParams & Partial<UpdateKeywordsSettingsValues>,
  ): Promise<any> => {
    const {
      ignoreInShareOfVoice,
      ignoreLocalResults,
      ignoreFeaturedSnippet,
      enableAutocorrect,
      ignoredDomain,
    } = params;

    const input = {
      ...buildUpdateKeywordsInput(params),
      ignoreInShareOfVoice,
      ignoreLocalResults,
      ignoreFeaturedSnippet,
      enableAutocorrect,
      ignoredDomain,
    };

    const filteredInput = pickBy(input, (value) => value !== undefined);

    return updateKeywords({
      variables: {
        input: filteredInput,
      },
    });
  };

  const updateKeywordsNotes = (params: UpdateKeywordsNotesParams): Promise<any> => {
    const { note, createdAt, domain, isAllSelected, keywords, filters } = params;

    let input: any = {
      note,
      createdAt,
      domain,
    };

    if (isAllSelected) {
      input = {
        ...input,
        keywords: [],
        keywordsToExclude: keywords || [], // if all keywords selected, we need to send keywordsToExclude as either keywords or empty array
        filters,
      };
    } else {
      // https://accuranker.myjetbrains.com/youtrack/issue/ARR-1593
      // if we have selected keywords we need to send keywordsToExclude as an empty array
      input = {
        ...input,
        keywords: keywords ?? [],
        keywordsToExclude: keywords?.length > 0 ? [] : undefined,
        filters,
      };
    }

    return addNote({
      variables: {
        input,
      },
    });
  };

  const updateKeywordsMoveToDomain = (params: UpdateKeywordsMoveToDomainParams): Promise<any> => {
    const { moveToDomain } = params;

    const input = {
      ...buildUpdateKeywordsInput(params),
      moveToDomain,
    };

    return updateKeywords({
      variables: {
        input,
      },
    });
  };

  const updateKeywordsTags = (params: UpdateKeywordsTagsParams): Promise<any> => {
    const { tags, remove, folderId } = params;

    let input: any = {
      ...buildUpdateKeywordsInput(params),
    };

    if (remove) {
      input = {
        ...input,
        removeTags: tags,
      };
    } else {
      input = {
        ...input,
        addTags: tags,
        folderPath: folderId,
      };
    }

    return updateKeywords({
      variables: {
        input,
      },
    });
  };

  const updateKeywordsRefresh = async (params: UpdateKeywordsRefreshParams): Promise<any> => {
    const { isDisabled, callback } = params;
    if (isDisabled) {
      showModal({
        modalType: 'Confirmation',
        modalProps: {
          title: t('Degraded Performance'),
          lockDuration: 0,
          description: (
            <div style={{ textAlign: 'left' }}>
              <p>{t('Dear Customer')}</p>
              <p>
                {t(
                  'We are currently having some internal issues on our network, we are working on fixing them. Your keywords will be automatically be refreshed every 24-hours.',
                )}
              </p>
              <p>{t('We apologize for the inconvenience.')}</p>
            </div>
          ),
          showCancelLabel: false,
          confirmLabel: t('OK'),
        },
      });
    }

    callback && callback();

    const input = {
      ...buildUpdateKeywordsInput(params),
      scrape: true,
    };

    return updateKeywords({
      variables: {
        input,
      },
    });
  };

  const modals = {
    showRemoveTagsModal,
    showOneTimeReportModal,
    showAddKeywordsModal,
    showKeywordModal,
    showLandingPageModal,
    showDeleteKeywordsModal,
    showAddNoteModal,
    showAddTagsModal,
    showEditKeywordsModal,
    showMoveKeywordsModal,
    showCopyToClipboardModal,
    showDuplicateModal,
    showCompareModal,
  };

  const updateKeywordsActions = {
    updateKeywordsTags,
    updateKeywordsDescription,
    updateKeywordsStarred,
    updateKeywordsRefresh,
    updateKeywordsNotes,
    updateKeywordsSettings,
    updateKeywordsPreferredLandingPage,
    updateKeywordsMoveToDomain,
  };

  const agTableActions = {
    setSelected,
    setAllSelected,
    setAllVisibleSelected,
  };

  const other = {
    updateTableColumnWidth,
    getKeywords,
  };

  return useMemo(() => ({ ...modals, ...updateKeywordsActions, ...agTableActions, ...other }), []);
};

export default useKeyword;
