import { memo, useMemo } from 'react';
import AccDistribution, { DistributionItem } from 'Components/AccDistribution/AccDistribution';
import AccLazyPopover from 'Components/AccLazyPopover/AccLazyPopover';
import { BarChart } from 'Pages/Keywords/Overview/components/BarChart/BarChart';
import colorScheme from 'Utilities/colors';
import { t } from 'Utilities/i18n';
import { propsIsEqualComparison } from '../../../../../../Utilities/compare';
import { FolderCellProps } from '../../utils/useFoldersTableConfig';

const RankingDistributionCell = (props: FolderCellProps) => {
  const { classes, rootNode } = props;
  const tooltip = t('Show ranking distribution');
  const rankItems = useMemo((): DistributionItem[] => {
    const totalValue =
      rootNode.keywords0To3 +
      rootNode.keywords4To10 +
      rootNode.keywords11To20 +
      rootNode.keywords21To50 +
      rootNode.keywordsAbove50 +
      rootNode.keywordsUnranked;
    return [
      {
        id: 'keywords0To3',
        color: colorScheme.rankDistribution[0],
        value: rootNode.keywords0To3,
        label: rootNode.keywords0To3 > totalValue / 3 ? '1-3' : '',
        tooltip,
      },
      {
        id: 'keywords4To10',
        color: colorScheme.rankDistribution[1],
        value: rootNode.keywords4To10,
        label: rootNode.keywords4To10 > totalValue / 3 ? '4-10' : '',
        tooltip,
      },
      {
        id: 'keywords11To20',
        color: colorScheme.rankDistribution[2],
        value: rootNode.keywords11To20,
        label: rootNode.keywords11To20 > totalValue / 3 ? '11-20' : '',
        tooltip,
      },
      {
        id: 'keywords21To50',
        color: colorScheme.rankDistribution[3],
        value: rootNode.keywords21To50,
        label: rootNode.keywords21To50 > totalValue / 3 ? '21-50' : '',
        tooltip,
      },
      {
        id: 'keywordsAbove50',
        color: colorScheme.rankDistribution[4],
        value: rootNode.keywordsAbove50,
        label: rootNode.keywordsAbove50 > totalValue / 3 ? '51-100' : '',
        tooltip,
      },
      {
        id: 'keywordsUnranked',
        color: colorScheme.rankDistribution[5],
        value: rootNode.keywordsUnranked,
        label: rootNode.keywordsUnranked > totalValue / 3 ? '100+' : '',
        tooltip,
      },
    ];
  }, [rootNode, tooltip]);

  const RankingDistributionBar = () =>
    useMemo(() => {
      return (
        <span>
          {rootNode.keywords ? (
            <AccDistribution height={20} width={80} items={rankItems} backgroundColor="#e1e3e6" />
          ) : (
            <div className={classes.rightTextAlign}>-</div>
          )}
        </span>
      );
    }, [rootNode.search_intent, colorScheme]);

  return (
    <AccLazyPopover
      target={<RankingDistributionBar />}
      width={350}
      dropdownTitle={t('Ranking distribution')}
      outerContainerProps={{ justify: 'end' }}
    >
      <BarChart
        key={`SI-${rootNode.keywords0To3} ${rootNode.keywords4To10} ${rootNode.keywords11To20} ${rootNode.keywords21To50} ${rootNode.keywordsAbove50}`}
        items={[
          {
            value: rootNode.keywords0To3 ?? 0,
            label: 'Rank 1-3',
            background: colorScheme.rankDistribution[0],
            tooltip: 'Number of keywords that rank between 1-3',
          },
          {
            value: rootNode.keywords4To10 ?? 0,
            label: 'Rank 4-10',
            background: colorScheme.rankDistribution[1],
            tooltip: 'Number of keywords that rank between 4-10',
          },
          {
            value: rootNode.keywords11To20 ?? 0,
            label: 'Rank 11-20',
            background: colorScheme.rankDistribution[2],
            tooltip: 'Number of keywords that rank between 11-20',
          },
          {
            value: rootNode.keywords21To50 ?? 0,
            label: 'Rank 21-50',
            background: colorScheme.rankDistribution[3],
            tooltip: 'Number of keywords that rank between 21-50',
          },
          {
            value: rootNode.keywordsAbove50 ?? 0,
            label: 'Rank 51-100',
            background: colorScheme.rankDistribution[4],
            tooltip: 'Number of keywords that rank between 51-100',
          },
          {
            value: rootNode.keywordsUnranked ?? 0,
            label: 'Not in top 100',
            background: colorScheme.rankDistribution[5],
            tooltip: 'Number of keywords that are not in top 100',
          },
        ]}
      />
    </AccLazyPopover>
  );
};

export default memo(RankingDistributionCell, propsIsEqualComparison);
