import { useEffect, useState } from 'react';
import { FieldArray, useFieldArray } from 'react-final-form-arrays';
import { Stack } from '@mantine/core';
import { Field } from 'Components/Fields';
import { extractErrorInfo } from 'Components/Fields/hooks/WithFieldForm/helpers';
import SearchEngineField from 'Components/Forms/SearchEngine';
import { t } from 'Utilities/i18n';
import Validator from 'Utilities/validation';
import { DOMAIN_MODE } from '../BuildDomainForm';

export const LocationFieldSection = ({
  locales,
  handleChangeLocale,
  mode,
  primarySearchEngine,
  setPrimarySearchEngine,
}) => {
  const [dataKey, setDataKey] = useState<number>(0);
  const {
    fields: { value: selectedLocales },
    meta,
  } = useFieldArray('defaultSearchLocales');
  const { showError, error } = extractErrorInfo(meta);
  const selectFirst = t('Select engine, locale and location');
  const selectNext = t('Add another engine, locale and location');

  // Reset select field after value is selected from modal
  useEffect(() => {
    setDataKey(dataKey + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocales.length]);

  return (
    <Stack gap={0}>
      <Field.LocaleSelect
        key={dataKey}
        required
        name="localeAddDomainSelect"
        id="localeAddDomainSelect"
        label={t('Default Search Engine, Locale and Location')}
        placeholder={!selectedLocales.length ? selectFirst : selectNext}
        locales={locales}
        onChange={handleChangeLocale}
        size="md"
        externalError={error}
        externalShowError={showError}
        validateCheck
        clearable
      />
      <FieldArray
        name="defaultSearchLocales"
        countryLocales={locales}
        component={SearchEngineField}
        validate={Validator.array}
        youTubeDomain={mode === DOMAIN_MODE.youtube}
        getRadioGroupValue={() => primarySearchEngine}
        onPrimarySettChange={(localeValue: string | { val: string }) => {
          setPrimarySearchEngine(typeof localeValue === 'string' ? localeValue : localeValue.val);
        }}
      />
    </Stack>
  );
};
