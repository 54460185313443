import { PureComponent } from 'react';
import { withRouter } from 'Utilities/Router';
import FooterEmpty from '../FooterEmpty';
import NavTop from '../NavTop';
import './template-nav-top.scss';

type Props = {
  children: JSX.Element;
};

class TemplateNavTop extends PureComponent<Props> {
  render() {
    return (
      <div className="checkout-page-wrapper">
        <NavTop />
        {this.props.children}
        <FooterEmpty />
      </div>
    );
  }
}

export default withRouter(TemplateNavTop as any);
