import moment from 'moment-timezone';
import { SelectItem } from '../Components/AccSelect';

type Domain = {
  id: string;
  domain: string;
  displayName?: string | null;
};

export const formatDomain = ({ domain, displayName }: Domain) =>
  (displayName ? `${displayName} (${domain})` : domain);

export const formatDomainOption = (domain: Domain): SelectItem<string> => ({
  label: formatDomain(domain),
  value: domain.id,
});

/**
 * [roundNumber]
 * @param  {Number} n
 * @param  {Number?} decimals
 * @return {Number}
 *
 * @example 67.4568958649596 -> 67.5
 */
export const roundNumber = (
  n: number,
  decimals: number = 0,
  // TODO FixTSignore
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
) => (isNaN(n) ? n : Number(`${Math.round(`${n}e${decimals}`)}e-${decimals}`));

/**
 * [evolution]
 * @param  {Number} pre
 * @param  {Number} cur
 * @return {Number}
 */
export const evolution = (pre: number, cur: number) =>
  (pre !== 0 ? ((cur - pre) / pre) * 100 : cur === 0 ? 0 : 100);

/**
 * [renamePropKey]
 * @param  {String} oldKey
 * @param  {String} newKey
 * @param  {Object} obj
 * @return {Object}
 *
 * @example ('a', 'b', { a: 5 }) -> { b: 5 }
 */
// prettier-ignore
export const renamePropKey = (oldKey: string, newKey: string, {
  [oldKey]: old,
  ...obj
}: Record<string, any>) => ({
  [newKey]: old,
  ...obj
});

/**
 * [formatDate]
 * @param  {Date} date
 * @return {String}
 *
 * @example Tue Jun 12 2018 13:44:18 GMT+0200 -> '2018-06-12'
 */

type FormatDateTimeOptions = {
  format: string;
};

export const formatDate = (
  date?: Date | string | null,
  options?: FormatDateTimeOptions,
): string => {
  if (date == null) {
    return '';
  }

  if (options === undefined) {
    options = { format: 'YYYY-MM-DD' };
  }

  const momentDate = moment(date);

  if (!momentDate.isValid()) {
    if (date === 'latest' || date === 'earliest') {
      return date;
    }

    console.error(`The date (${date.toString()}) is not valid`);
    return '';
  }

  return momentDate.format(options.format);
};

export const formatComfortableDate = (date?: Date | string | null): string => {
  return formatDate(date, { format: 'MMM D, YYYY' });
};

/**
 * [formatDate]
 * @param  {Date} date
 * @return {String}
 *
 * @example Tue Jun 12 2018 13:44:18 GMT+0200 -> '2018-06-12'
 */

export const formatDateTime = (date?: Date | string | null): string => {
  if (date == null) {
    return '';
  }

  const momentDate = moment(date);

  if (!momentDate.isValid()) {
    if (date === 'latest' || date === 'earliest') {
      return date;
    }

    console.error(`The date (${date.toString()}) is not valid`);
    return '';
  }

  const tz = moment.tz.guess();
  return `${formatDate(date)} ${momentDate.tz(tz).format('HH:mm')}`;
};

export const percentageChange = (before, after) => {
  return (after - before) / before;
};

/**
 * @deprecated
 * use lodash.isNil instead
 */
export const nullOrUndefined = (value) => value === null || value === undefined;

export const toggleElInArr = (
  arr: Array<string>,
  val: string,
  keepIfExists: boolean = false,
): Array<string> =>
  (arr.includes(val) ? arr.filter((el) => (keepIfExists ? el : el !== val)) : [...arr, val]);
