import { formatComfortableDate } from 'Utilities/format';
import { t } from 'Utilities/i18n';
import { getSegmentedDateRange } from '../getRange';
import { DateRange, EARLIEST, LATEST } from '../model';

export const getTooltipDate = (value, min, max) => {
  const range = getSegmentedDateRange(value, max);
  let { to, from }: DateRange = range;

  if (to === LATEST) {
    to = max;
  }

  if (from === EARLIEST) {
    from = min;
  }

  const fromDate = formatComfortableDate(from);
  const toDate = formatComfortableDate(to);

  const tooltipDate = `${t('Compare data from')} ${fromDate} - ${toDate}`;

  return tooltipDate;
};
