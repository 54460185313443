import { ApolloQueryResult } from '@apollo/client';
import { Flex } from '@mantine/core';
import { captureException } from '@sentry/react';
import AccButton from 'Components/AccButton/AccButton';
import LoadingSpinner from 'Components/LoadingSpinner';
import AccText from 'Components/Text/AccText';
import {
  EditProfileUserSocialConnectionsQuery,
  useEditProfileDisconnectSocialAuthMutation,
} from 'Ghql';
import toast from 'Hooks/useToast';
import FacebookLogo from 'Pages/Auth/LoginPage/assets/facebook.svg';
import GoogleLogo from 'Pages/Auth/LoginPage/assets/google-svg.svg';
import LinkedInLogo from 'Pages/Auth/LoginPage/assets/linkedin.png';
import MicrosoftLogo from 'Pages/Auth/LoginPage/assets/microsoft.svg';
import { tct } from 'Utilities/i18n';

type SocialButtonProps = {
  provider: string;
  src: string;
  loginPath: string;
  socialConnectionsLoading: boolean;
  isConnected: boolean;
  refetchSocialConnections: () => Promise<ApolloQueryResult<EditProfileUserSocialConnectionsQuery>>;
};

const backendByProvider = new Map([
  ['google', 'google-oauth2'],
  ['linkedin', 'linkedin-oauth2'],
  ['microsoft', 'microsoft-graph'],
  ['facebook', 'facebook'],
]);

const SocialButton = ({
  provider,
  src,
  loginPath,
  socialConnectionsLoading,
  isConnected,
  refetchSocialConnections,
}: SocialButtonProps) => {
  const backend = backendByProvider.get(provider.toLocaleLowerCase());
  if (!backend) {
    throw new Error(`Missing Python-Social-Auth backend for provider ${provider}`);
  }
  const [disconnectSocialAuth, { loading: disconnectSocialAuthLoading }] =
    useEditProfileDisconnectSocialAuthMutation({
      variables: { input: { backend } },
    });

  return (
    <AccButton
      disabled={socialConnectionsLoading || disconnectSocialAuthLoading}
      variant="secondary"
      w="100%"
      href={isConnected ? undefined : `/suser/login/${loginPath}/?next=/app/profile`}
      target={isConnected ? undefined : '_self'}
      onClick={async () => {
        if (isConnected) {
          try {
            await disconnectSocialAuth();
          } catch (_) {
            const error = tct('Unable to disconnect [provider] account. Please try again later.', {
              provider,
            });
            toast.error(error);
            captureException(error);
          }
          await refetchSocialConnections();
        }
      }}
      leftSection={
        <img
          src={src}
          alt={
            isConnected
              ? tct('Disconnect with [provider]', { provider })
              : tct('Connect with [provider]', { provider })
          }
          height="24px"
          width="24px"
          data-auth-btn-icon
        />
      }
      styles={{
        inner: {
          display: 'grid',
          gridTemplateColumns: '1fr 24px 155px 1fr',
          alignItems: 'center',
          columnGap: '12px',
        },
        section: {
          gridColumn: '2 / 3',
          filter:
            socialConnectionsLoading || disconnectSocialAuthLoading ? 'grayscale(80%)' : undefined,
        },
        label: { gridColumn: '3 / 4', overflow: 'visible' },
      }}
    >
      {socialConnectionsLoading || disconnectSocialAuthLoading ? (
        <Flex justify="center" w="100%">
          <LoadingSpinner />
        </Flex>
      ) : (
        <AccText ta="center" variant="label">
          {isConnected
            ? tct('Disconnect from [provider]', { provider })
            : tct('Connect with [provider]', { provider })}
        </AccText>
      )}
    </AccButton>
  );
};

type SocialButtonsProps = {
  isConnectedGoogle: boolean;
  isConnectedLinkedIn: boolean;
  isConnectedMicrosoft: boolean;
  isConnectedFacebook: boolean;
  loading: boolean;
  refetch: () => Promise<ApolloQueryResult<EditProfileUserSocialConnectionsQuery>>;
};

export const SocialButtons = ({
  isConnectedGoogle,
  isConnectedLinkedIn,
  isConnectedMicrosoft,
  isConnectedFacebook,
  loading,
  refetch,
}: SocialButtonsProps) => (
  <Flex direction="column" rowGap={'sm'}>
    <SocialButton
      provider="Google"
      src={GoogleLogo}
      loginPath="google-oauth2"
      isConnected={isConnectedGoogle}
      socialConnectionsLoading={loading}
      refetchSocialConnections={refetch}
    />
    <SocialButton
      provider="LinkedIn"
      src={LinkedInLogo}
      loginPath="linkedin-oauth2"
      isConnected={isConnectedLinkedIn}
      socialConnectionsLoading={loading}
      refetchSocialConnections={refetch}
    />
    <SocialButton
      provider="Microsoft"
      src={MicrosoftLogo}
      loginPath="microsoft-graph"
      isConnected={isConnectedMicrosoft}
      socialConnectionsLoading={loading}
      refetchSocialConnections={refetch}
    />
    <SocialButton
      provider="Facebook"
      src={FacebookLogo}
      loginPath="facebook"
      isConnected={isConnectedFacebook}
      socialConnectionsLoading={loading}
      refetchSocialConnections={refetch}
    />
  </Flex>
);
