import { Component } from 'react';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import { Space } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import compose from 'lodash/flowRight';
import { Field, reduxForm } from 'redux-form';
import AccButton from 'Components/AccButton/AccButton';
import Checkbox from 'Components/Controls/Checkbox';
import { MultiSelectInputField } from 'Components/Forms/Fields';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import { t } from 'Utilities/i18n/index';
import underdash from 'Utilities/underdash';
import Validator from 'Utilities/validation';

type Props = {
  handleSubmit: (...args: Array<any>) => any;
  gaAccounts: Record<string, any>;
  connectionId: string;
  onSubmit: (...args: Array<any>) => any;
  onBack: (...args: Array<any>) => any;
  submitting?: any;
  invalid?: boolean;
};

class GAAccountPage extends Component<Props> {
  state = {
    selectAll: false,
  };

  onSubmit = (val) => {
    if (this.state.selectAll) {
      const { gaAccounts } = this.props;
      const loading =
        underdash.graphqlError({ ...this.props }) || underdash.graphqlLoading({ ...this.props });
      const accounts =
        !loading && gaAccounts.googleConnection ? gaAccounts.googleConnection.gaAccounts : [];
      this.props.onSubmit({ accountIds: accounts?.map((account) => account.id) });
    } else {
      this.props.onSubmit({
        accountIds: val.gaAccount,
      });
    }
  };

  render() {
    const { onBack, gaAccounts, handleSubmit, submitting, invalid } = this.props;
    const loading =
      underdash.graphqlError({ ...this.props }) || underdash.graphqlLoading({ ...this.props });
    const accounts =
      !loading && gaAccounts.googleConnection ? gaAccounts.googleConnection.gaAccounts : [];
    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className="form-label">{t('Account Name')}</div>
        <div>
          <Field
            defaultBehaviour
            name="gaAccount"
            id="gaAccount"
            elementType="customSelect"
            type="text"
            placeholder={''}
            component={MultiSelectInputField}
            loading={loading}
            options={accounts.map((account) => ({
              value: account.id,
              label: account.name,
            }))}
            validate={this.state.selectAll ? () => undefined : Validator.required}
            helpText={
              loading
                ? t('Loading…')
                : t('Please select the Google Analytics account that you wish to use.')
            }
          />
          <Space h="md" />
          <Checkbox
            value={this.state.selectAll}
            checked={this.state.selectAll}
            name={'selectAll'}
            onChange={(event) => this.setState({ selectAll: event.target.checked })}
          >
            {t('Select all')}
          </Checkbox>
        </div>
        <ModalFooter
          primaryButtonSlot={
            <AccButton
              variant="primary"
              type="submit"
              disabled={(!this.state.selectAll || loading) && (loading || invalid || submitting)}
            >
              {t('Select account')}
            </AccButton>
          }
          secondaryButtonSlot={
            <AccButton variant="tertiary" onClick={onBack}>
              <IconArrowLeft />
              {t('Connection')}
            </AccButton>
          }
        />
      </form>
    );
  }
}

const gaAccountsQuery = gql`
  query gaAccountPage_gaAccounts($goaConnectionID: ID!) {
    googleConnection(id: $goaConnectionID) {
      gaAccounts {
        id
        name
      }
    }
  }
`;
export default compose(
  graphql(gaAccountsQuery, {
    name: 'gaAccounts',
    options: (props: Props) => {
      const connectionId = props.connectionId;
      return {
        fetchPolicy: 'network-only',
        variables: {
          goaConnectionID: connectionId,
        },
      };
    },
  }),
  reduxForm({
    form: 'SelectGAAccountForm',
  }),
)(GAAccountPage) as any;
