import NumberChangeEditor from 'Components/Filters/Common/NumberChangeEditor';
import { changeFilterLabelFunc } from 'Components/Filters/Editors/helpers';
import { ChangeFilter, FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import { t } from 'Utilities/i18n/index';
import NoFilterIcon from 'icons/greater-than.svg?inline';
import RankIcon from 'icons/rank.svg?inline';

const defaultValue: ChangeFilter = {
  attribute: FilterAttribute.LOCAL_PACK_RANK_CHANGE,
  type: FilterValueType.NUMBER,
  comparison: FilterComparison.LT,
  value: '',
};

const getData = () => ({
  defaultValue,
  title: t('Local Pack Rank change'),
  icon: RankIcon,
  editor: NumberChangeEditor,
  editorProps: {
    isReversed: false,
  },
  tableEditor: NumberChangeEditor,
  tableEditorProps: {
    iconDropdown: true,
    isReversed: false,
    noFilterIcon: NoFilterIcon,
  },
  labelFunc: changeFilterLabelFunc,
});

export default getData;
