import { reduxForm } from 'redux-form';
import EditForm from 'Components/Forms/EditForm';
import type { FilterBase } from 'Types/Filter';
import { t } from 'Utilities/i18n/index';
import { isSubdomainFilterData } from '../Editors/Subdomain/data';
import getFilterData, { FilterData } from '../getFilterData';

/** narrow down the type to only get SubdomainFilterData */
const getDynamicProps = (filterViewData: FilterData | null) => {
  return filterViewData && isSubdomainFilterData(filterViewData)
    ? filterViewData?.editorProps?.useDynamicEditorProps()
    : {};
};

type Props = {
  filter: FilterBase;
  handleSubmit: (...args: Array<any>) => any;
  isNew: boolean;
  onCancel: (...args: Array<any>) => any;
  onDelete?: (...args: Array<any>) => any;
  isOpen: boolean;
};

const FilterEditorForm = (props: Props) => {
  const { filter, handleSubmit, onCancel, isNew, onDelete } = props;
  const filterViewData = getFilterData(filter?.attribute);
  const dynamicProps = getDynamicProps(filterViewData);

  const Editor = filterViewData && 'editor' in filterViewData ? filterViewData?.editor : undefined;
  const Icon = filterViewData?.icon;
  const editorProps =
    filterViewData && 'editorProps' in filterViewData ? filterViewData.editorProps : {};

  const keyboardCombo =
    filterViewData && 'keyboardCombo' in filterViewData ? filterViewData?.keyboardCombo : undefined;

  return (
    <EditForm
      title={filterViewData?.title}
      icon={Icon && <Icon />}
      keyboardCombo={keyboardCombo}
      submitLabel={isNew ? t('Add') : t('Update')}
      cancelLabel={t('Cancel')}
      deleteLabel={t('Clear')}
      onDelete={onDelete}
      onCancel={onCancel}
      onSubmit={handleSubmit}
      isNew={isNew}
      disableTrackingOnSubmit
      {...dynamicProps}
    >
      {Editor ? <Editor {...editorProps} /> : ''}
    </EditForm>
  );
};

export default reduxForm({
  form: 'filter-editor-form',
})(FilterEditorForm);
