import { useMemo } from 'react';
import cookie from 'react-cookies';
import { adminRole, defaultRole, hasWriteAccess, superuserRole } from 'Constants/workspaces';
import { useHasKeydisAccess } from 'Hooks/data/organization/useOrganizationInfo';
import { useUser } from 'Hooks/data/user/useUser';
import { PRESENT_MODE_COOKIE } from 'Pages/Profile/components/EditProfile';
import { StoreUserType } from 'Types/Store';
import { notEmpty } from 'Utilities/underdash';
import { VisibilityState, getAllNavigationItems } from './getAllNavigationItems';
import { NavItem, navItemSubPage } from './types';

const filterByVisibility = (
  item: navItemSubPage,
  _user: StoreUserType,
  _hasKeydisAccess: boolean | null,
) => {
  const isPresentMode = !!cookie.load(PRESENT_MODE_COOKIE);
  const visibleFor = (Array.isArray(item.visibleFor) ? item.visibleFor : [item.visibleFor]).filter(
    notEmpty,
  );

  if (!visibleFor.length) return true; // default visibility if no conditions are set for the route

  // Check if ALL specified conditions are met
  const allConditionsMet = visibleFor.every((condition) => {
    const isAdmin = _user.userType === adminRole;
    const isSuperuser = _user.userType === superuserRole;
    const userHasWriteAccess = hasWriteAccess.includes(_user.userType || defaultRole);
    switch (condition) {
      case VisibilityState.HasKeydisAccess:
        return _hasKeydisAccess;
      case VisibilityState.IsOrgAdmin:
        return isAdmin;
      case VisibilityState.SalesManagerNotMultiAccount:
        return _user.salesManager && !_user.isOnMultiaccount && !isPresentMode;
      case VisibilityState.IsTrial:
        return _user?.organization?.activePlan?.isTrial;
      case VisibilityState.IsNotTrial:
        return typeof _user?.organization?.activePlan?.isTrial === 'boolean'
          ? !_user?.organization?.activePlan?.isTrial
          : !_user?.organization?.previousPlan?.isTrial;
      case VisibilityState.PlanNotExpired: {
        return _user?.organization?.activePlan
          ? true
          : !_user?.organization?.errors?.trialExpired && !_user?.organization?.errors?.planExpired;
      }
      case VisibilityState.IsPartner:
        return _user?.organization?.isPartner;
      case VisibilityState.IsOrgAdminShowKeywordUsage:
        return isAdmin && _user?.organization?.showKeywordUsage;
      case VisibilityState.IsOrgAdminNotTrial:
        return isAdmin && !_user?.organization?.activePlan?.isTrial;
      case VisibilityState.HasAccuApiAccess:
        return _user?.organization?.activePlan?.featureAccuApi || false;
      case VisibilityState.NoAccuApiAccess:
        return !_user?.organization?.activePlan?.featureAccuApi;
      case VisibilityState.HasWriteAccess:
        return userHasWriteAccess;
      case VisibilityState.HasAccess:
        return Boolean(_user?.workspaces?.length);
      case VisibilityState.IsOrgAdminOrSuperuser:
        return isAdmin || isSuperuser;
      default:
        return true; // default visibility if no conditions are met
    }
  });
  return allConditionsMet;
};

export const useSidebarItems = (): NavItem[] => {
  const user = useUser();
  const hasKeydisAccess = useHasKeydisAccess();

  const items = useMemo(() => {
    const filteredItems = getAllNavigationItems()
      .filter((item) => filterByVisibility(item, user, hasKeydisAccess))
      .map((item) => {
        const filteredSubPages = item.subPages?.filter((subPage) =>
          filterByVisibility(subPage, user, hasKeydisAccess),
        );

        return {
          ...item,
          subPages: filteredSubPages,
        };
      });

    return filteredItems;
  }, [user, hasKeydisAccess]);

  return items;
};
