import React from 'react';
import { injectIntl } from 'react-intl';
import isNil from 'lodash/isNil';
import { InjectedIntl } from 'Types/Intl';
import { useIsPercentage } from '../../Hooks/data/domain/useQueryDomainInfo';
import { intlFormatNumber } from './helpers';

const ONE_BILLION = 1000000000;
const ONE_MILLION = 1000000;
const TEN_THOUSAND = 10000;
export const defaultBillionsSettings = {
  label: 'B',
  cutoff: ONE_BILLION,
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
};
export const defaultMillionsSettings = {
  label: 'M',
  cutoff: ONE_MILLION,
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
};
export const defaultThousandsSettings = {
  label: 'K',
  cutoff: TEN_THOUSAND,
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
};
export type FormatSettings = {
  // only used when style = 'decimal' | 'currency'
  spaceBetweenNumberAndLabel?: boolean;
  thousandsSettings?: {
    label?: string;
    cutoff?: number;
    minimumFractionDigits: number;
    maximumFractionDigits: number;
  };
  millionsSettings?: {
    label?: string;
    cutoff?: number;
    minimumFractionDigits: number;
    maximumFractionDigits: number;
  };
  billionsSettings?: {
    label?: string;
    cutoff?: number;
    minimumFractionDigits: number;
    maximumFractionDigits: number;
  };
};

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
type FormatNumberProps = {
  className?: string;
  value: number | null | undefined | string;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  style?: 'decimal' | 'currency' | 'percent';
  // if true - will use render props pattern to pass formatted value
  renderProps?: boolean;
  currency?: string | null;
  currencyDisplay?: 'symbol' | 'narrowSymbol' | 'code' | 'name';
  signDisplay?: 'auto' | 'never' | 'always' | 'exceptZero';
  withTilde?: boolean;
  formatSettings?: FormatSettings;
  children?: any;
};

const FormatNumberComponent = (props: FormatNumberProps & { intl: InjectedIntl }) => {
  const {
    className,
    value,
    minimumFractionDigits,
    maximumFractionDigits,
    style,
    currency,
    currencyDisplay,
    signDisplay,
    intl,
    children,
    renderProps,
    formatSettings: {
      // TODO FixTSignore
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      spaceBetweenNumberAndLabel,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      billionsSettings,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      millionsSettings,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      thousandsSettings,
    },
    withTilde,
  } = props;

  const resultValue =
    value === '-' || isNil(value)
      ? '-'
      : intlFormatNumber(intl)({
          value,
          className,
          minimumFractionDigits,
          maximumFractionDigits,
          style,
          currency,
          currencyDisplay,
          signDisplay,
          formatSettings: {
            spaceBetweenNumberAndLabel,
            billionsSettings,
            millionsSettings,
            thousandsSettings,
          },
        });

  if (renderProps) {
    return <>{children?.(resultValue)}</>;
  }

  // todo add tooltip that should display intl.formatNumber(value, numberFormatOptions)
  return (
    <span className={className}>
      {withTilde && '~'}
      {resultValue}
    </span>
  );
};

FormatNumberComponent.defaultProps = {
  value: undefined,
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
  style: 'decimal' as const,
  currency: 'USD' as const,
  currencyDisplay: 'symbol' as const,
  signDisplay: 'auto' as const,
  formatSettings: {
    spaceBetweenNumberAndLabel: false,
    billionsSettings: defaultBillionsSettings,
    millionsSettings: defaultMillionsSettings,
    thousandsSettings: defaultThousandsSettings,
  },
};

const FormatNumber = React.memo(
  injectIntl(FormatNumberComponent),
) as React.ComponentType<FormatNumberProps>;

export default FormatNumber;

/***
 * Display percentage or decimal based on account `shareOfVoicePercentage` field
 */
export const FormatNumberAuto = (props: Omit<FormatNumberProps, 'style'>) => {
  const sovAsPercentage = useIsPercentage();
  return <FormatNumber {...props} style={sovAsPercentage ? 'percent' : 'decimal'} />;
};
