import AccBadge from 'Components/AccBadge';
import { useModal } from 'Hooks/base/useModal';
import { EventName, useMixpanel } from 'Utilities/Analytics/mixpanel';
import { t } from 'Utilities/i18n';
import type { Integration } from '../types/Integration';

type Props = {
  integration: Integration;
};

const IntegrationCard = ({ integration }: Props) => {
  const { showModal } = useModal();
  const trackEvent = useMixpanel();
  const openIntegrationsModal = (): void => {
    showModal({
      modalType: 'Integration',
      modalTheme: 'light',
      modalProps: {
        content: integration.modalContent,
        title: integration.modalTitle,
      },
    });
    trackEvent(EventName.IntegrationCardClick, {
      id: integration.id,
      category: integration.category,
    });
  };
  return (
    <div className="integration-card">
      {integration.isPopular && (
        <span className="card-header-badge">
          <AccBadge type="success">{t('Popular')}</AccBadge>
        </span>
      )}
      {integration.comingSoon && (
        <span className="card-header-badge">
          <AccBadge type="warning">{t('Coming Soon')}</AccBadge>
        </span>
      )}
      {integration.isBeta && (
        <span className="card-header-badge">
          <AccBadge type="gray">{t('BETA')}</AccBadge>
        </span>
      )}
      <img className="card-img-top" src={integration.image} alt={integration.modalTitle ?? null} />

      <div className="title">{integration.modalTitle}</div>
      {integration.comingSoon ? (
        <button>{t('Coming Soon')}</button>
      ) : (
        <button onClick={openIntegrationsModal}>{t('Read more')}</button>
      )}
    </div>
  );
};

export default IntegrationCard;
