import colorScheme from 'Utilities/colors';
import { t } from 'Utilities/i18n';
import { OverviewChartSeries } from '../constants';
import { addLowDataPlaceholderDays } from '../support/lowData';

type HistoryChartSeries = OverviewChartSeries;

export const getAiTrafficValueSeries = (data: any): HistoryChartSeries[] => {
  const series: HistoryChartSeries[] = [
    {
      name: t('AI Traffic Value'),
      primary: true,
      color: colorScheme.graphs.stats.aiTrafficValue,
      data:
        data?.filter(Boolean)?.map((e) => ({
          x: e?.date ?? 0,
          y: e?.aiTrafficValue ?? 0,
          totalKeywords: Number(e?.keywords ? e?.keywords : 0),
        })) ?? [],
    },
  ];
  addLowDataPlaceholderDays(series[0]);
  return series;
};
