import { oneOfOptions } from 'Components/Filters/Common/labelFunc';
import { FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import type { AboveTheFoldFilter } from 'Types/Filter';
import { t } from 'Utilities/i18n/index';
import AboveTheFoldIcon from 'icons/abovethefold.svg?inline';
import AboveTheFoldEditor from '.';
import { getItems } from './hooks';

const defaultValue: AboveTheFoldFilter = {
  attribute: FilterAttribute.ABOVE_THE_FOLD,
  type: FilterValueType.BOOL,
  comparison: FilterComparison.EQ,
  value: true,
};

const getData = () => {
  const items = getItems();
  return {
    defaultValue,
    title: t('Above the Fold'),
    icon: AboveTheFoldIcon,
    editor: AboveTheFoldEditor,
    editorProps: {
      items,
    },
    labelFunc: oneOfOptions(items),
  };
};

export default getData;
