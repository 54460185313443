/* eslint-disable import/no-unused-modules */
import { AccFastIcon } from 'Components/AccFastIcon';
import { PLATFORM_DESKTOP, PLATFORM_MOBILE } from 'Components/Modal/Content/AddSearchEngine/types';
import { SearchEngineFragment, SearchTypeFragment } from 'Ghql';
import {
  Baidu,
  Bing,
  Google,
  Mobile,
  Monitor,
  Naver,
  Yandex,
  YouTube,
} from 'Pages/Keywords/Table/Icon/Icons';
import { TABLE_ICON_SIZE } from '../constants';
import Cell from './HelperComponents/AccuCell';

export const SEARCH_ENGINE_ICONS = {
  1: Google as string,
  2: Bing as string,
  3: Baidu as string,
  4: Yandex as string,
  5: YouTube as string,
  6: Naver as string,
};

export const SEARCH_TYPE_ICONS = {
  [PLATFORM_DESKTOP]: Monitor as string,
  [PLATFORM_MOBILE]: Mobile as string,
};

type SearchEngineProps = {
  searchEngineId: number;
  searchEngines: SearchEngine[];
};

const SearchEngineIcon = ({ searchEngineId, searchEngines }: SearchEngineProps) => {
  const searchEngine = searchEngines.find((e) => e.id === searchEngineId);
  const tooltip = searchEngine?.name;
  const iconImg = searchEngine ? SEARCH_ENGINE_ICONS[searchEngine?.id] : undefined;

  return (
    <>
      {iconImg && (
        <AccFastIcon src={iconImg} size={TABLE_ICON_SIZE} tooltip={tooltip} fit="contain" />
      )}
    </>
  );
};

type SearchTypeProps = {
  searchTypes: SearchType[];
  searchTypeId: number;
};

const SearchTypeIcon = ({ searchTypes, searchTypeId }: SearchTypeProps) => {
  const searchType = searchTypes.find((e) => e.id === searchTypeId);
  const iconImg = searchType ? SEARCH_TYPE_ICONS[searchType?.id] : undefined;
  const tooltip = searchType?.name;
  return (
    <>
      {iconImg && (
        <AccFastIcon size={TABLE_ICON_SIZE} tooltip={tooltip} src={iconImg} fit="contain" />
      )}
    </>
  );
};

export type SearchEngine = {
  id: keyof typeof SEARCH_ENGINE_ICONS;
  name: 'Bing' | 'Baidu' | 'Yandex' | 'Google' | 'YouTube';
};

export type SearchType = {
  id: keyof typeof SEARCH_TYPE_ICONS;
  name: 'Desktop' | 'Mobile';
};

export type SearchTypeEngineCellProps = {
  value: {
    data: {
      searchTypeId: number;
      searchEngineId: number;
    };
    loadingData?: boolean;
  };
  searchTypes: SearchTypeFragment[] | undefined;
  searchEngines: SearchEngineFragment[] | undefined;
};

const SearchTypeEngineCell = (props: SearchTypeEngineCellProps) => {
  const {
    value: { data, loadingData },
    searchTypes = [],
    searchEngines = [],
  } = props;
  // if (loadingData) return <LoadingSpinner />;
  if (loadingData) return <></>;
  const { searchTypeId, searchEngineId } = data;

  return (
    <Cell className={'accu-table-cell__search-engine-type'}>
      <SearchTypeIcon searchTypes={searchTypes as SearchType[]} searchTypeId={searchTypeId} />
      <SearchEngineIcon
        searchEngineId={searchEngineId as SearchEngine['id']}
        searchEngines={searchEngines as SearchEngine[]}
      />
    </Cell>
  );
};

export default SearchTypeEngineCell;
