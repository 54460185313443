import { useUser } from './data/user/useUser';

export const useShouldUseAiSoV = () => {
  const user = useUser();
  // List of users that requested old SoV
  if (['54531'].includes(user?.id ?? '')) {
    return false;
  }

  // List of organizations that requested old SoV
  if (['22738'].includes(user?.organization?.id ?? '')) {
    return false;
  }

  let showAISoV = false;

  if (user && user.dateJoined) {
    if (new Date(user.dateJoined) >= new Date('2023-06-01')) {
      showAISoV = true;
    }
  }

  // Accuranker users
  if (user.organization?.id === '1' || user.organization?.id === '28571') {
    return true;
  }
  return showAISoV;
};
