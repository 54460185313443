import { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { SelectItem } from 'Components/AccSelect';
import {
  ConnectToGaPropertyGa4PropertiesDocument,
  ConnectToGaPropertyGa4PropertiesQuery,
  ConnectToGaPropertyUaPropertiesDocument,
  ConnectToGaPropertyUaPropertiesQuery,
  DomainsInput_DomainsListQuery,
  GroupsAndDomainClientsQuery,
} from 'Ghql';
import { notEmpty } from 'Utilities/underdash';

type Ga4Property = {
  accountId: string;
  name?: string | null;
  displayName?: string | null;
};

type UaProperty = {
  id: string;
  name: string;
  websiteUrl: string;
};

const toGa4PropertyOptionsMultiple = (
  properties: Ga4Property[],
  skipUaPropertyOptions?: boolean,
): SelectItem<string>[] => {
  const groupLabel = skipUaPropertyOptions ? {} : { group: 'Google Analytics 4 (GA4)' };
  return (
    properties?.map((ga4Property) => ({
      label: ga4Property?.displayName || '-',
      value: ga4Property?.name?.split('/')[1] || '-',
      accountId: ga4Property?.accountId,
      ...groupLabel,
    })) || []
  );
};

const toUaPropertyOptionsMultiple = (properties: UaProperty[]): SelectItem<string>[] =>
  properties?.map((uaProperty) => ({
    label: `${uaProperty.name} (${uaProperty.websiteUrl})`,
    value: uaProperty.id,
    group: 'Universal Analytics (UA)',
  })) || [];

const useUaPropertyOptionsMultiple = (
  connectionId: string,
  accountIds: string[],
  skip?: boolean,
): [SelectItem<string>[], boolean] => {
  const client = useApolloClient();
  const [result, setResult] = useState<UaProperty[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const results = await Promise.all(
          accountIds.map(async (accountId) => {
            if (skip) {
              return [];
            }
            const { data }: { data: ConnectToGaPropertyUaPropertiesQuery } = await client.query({
              query: ConnectToGaPropertyUaPropertiesDocument,
              variables: {
                accountId,
                connectionId,
              },
              fetchPolicy: 'cache-first',
            });
            return data?.googleConnection?.gaProperties || []; // Extract the 'ga4Properties' array
          }),
        );
        setResult(results.flat()); // Flatten the arrays and set the result
        setIsLoading(false);
      } catch (error) {
        // Handle errors here if needed
        setIsLoading(false);
      }
    };

    fetchData();
  }, [(accountIds ?? []).join(','), connectionId, skip]);

  if (skip) return [[], false];
  return [toUaPropertyOptionsMultiple(result), isLoading];
};

const useGa4PropertyOptionsMultiple = (
  connectionId: string,
  accountIds: string[],
  skipUaPropertyOptions?: boolean,
): [SelectItem<string>[], boolean] => {
  const client = useApolloClient();
  const [result, setResult] = useState<Ga4Property[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const results = await Promise.all(
          accountIds.map(async (accountId) => {
            const { data }: { data: ConnectToGaPropertyGa4PropertiesQuery } = await client.query({
              query: ConnectToGaPropertyGa4PropertiesDocument,
              variables: {
                accountId,
                connectionId,
              },
              fetchPolicy: 'cache-first',
            });
            const ga4Properties =
              data?.googleConnection?.ga4Properties?.map((property) => ({
                ...property,
                accountId,
              })) || [];
            return ga4Properties; // Extract the 'ga4Properties' array
          }),
        );
        setResult(results.flat()); // Flatten the arrays and set the result
        setIsLoading(false);
      } catch (error) {
        // Handle errors here if needed
        setIsLoading(false);
      }
    };

    fetchData();
  }, [(accountIds ?? []).join(','), connectionId]);

  return [toGa4PropertyOptionsMultiple(result, skipUaPropertyOptions), isLoading];
};

export const usePropertyOptionsMultiple = (
  connectionId: string,
  accountIds: string[],
  skipUaPropertyOptions: boolean,
): [SelectItem<string>[], boolean] => {
  const [ga4PropertyOptions, ga4PropertiesLoading] = useGa4PropertyOptionsMultiple(
    connectionId,
    accountIds,
    skipUaPropertyOptions,
  );

  const [uaPropertyOptions, uaPropertiesLoading] = useUaPropertyOptionsMultiple(
    connectionId,
    accountIds,
    skipUaPropertyOptions,
  );

  const propertyOptions = skipUaPropertyOptions
    ? ga4PropertyOptions
    : [...uaPropertyOptions, ...ga4PropertyOptions];

  const loading = ga4PropertiesLoading || uaPropertiesLoading;

  return [propertyOptions, loading];
};

/** Create SelectItems for all domains in the current group */
export const useAvailableDomains = (
  domainsData: DomainsInput_DomainsListQuery | undefined,
): SelectItem<string>[] => {
  const domains = domainsData?.domainsList?.filter(notEmpty) || [];

  const availableDomains: SelectItem<string>[] = [];

  domains?.map((domain) => {
    const isYouTubeDomain = !!domain?.youtubeChannelName;

    if (!isYouTubeDomain) {
      availableDomains.push({
        label: domain.displayName || domain?.domain,
        value: domain.id,
        url: domain?.domain,
      });
    }
  });
  return availableDomains;
};

/** Create SelectItems for domain groups */
export const useAvailableGroups = (groupData: GroupsAndDomainClientsQuery | undefined) => {
  const groups = groupData?.clients?.filter(notEmpty) || [];

  const groupItems = groups.map((group) => ({
    label: group?.name,
    value: group.id,
  }));
  return groupItems;
};
