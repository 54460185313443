import { Dispatch, SetStateAction, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Combobox, Flex, InputBase, ScrollArea, SelectProps, useCombobox } from '@mantine/core';
import cn from 'classnames';
import { ComboboxOptions, RightSectionElement } from 'Components/AccSelect/AccSelect';
import { DelayedSpinner } from 'Components/AccSelect/components/DelayedSpinner';
import selectStyles from 'Components/AccSelect/select.module.scss';
import { MAX_SELECT_DROPDOWN_HEIGHT } from 'Components/AccSelect/support/constants';
import { useSelectNotFound } from 'Components/AccSelect/support/hooks';
import { DomainFavicon } from 'Components/DomainFavicon';
import { DomainItemComponent } from 'Components/DomainItemComponent';
import { useValidateDomainLazyQuery } from 'Ghql';
import { useSpecificFilter } from 'Hooks';
import { useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import { SelectedDomain } from 'Pages/KeywordDiscovery';
import { useHandleSetDomain } from 'Pages/KeywordDiscovery/hooks';
import { CompetitorDomainFilter, FilterAttribute } from 'Types/Filter';
import { PrettifyUnion } from 'Types/Prettify';
import { t } from 'Utilities/i18n';
import { trimTrailingSlash } from 'Utilities/underdash';
import validation from 'Utilities/validation';
import { subscribeToDomain } from 'Utilities/websocket';
import { useSearchHistory, useSelectItems } from './helpers';
import styles from './keydis-domain-select.module.scss';

export interface DomainItem {
  /**DisplayName of the domain  */
  label: string;
  value: string;
  /** Domain url*/
  description?: string;
  domain?: string;
  domainId?: string;
  group?: string;
  key?: string;
  searchBy?: string;
}

const KeydisDomainItemComponent =
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ label, description }: DomainItem) => {
    return <DomainItemComponent label={label} domain={description} />;
  };

type KeydisDomainSelectProps = PrettifyUnion<
  Pick<SelectProps, 'className' | 'searchable'> & {
    /** Sync the url filters when selecting a domain in the dropdown*/
    onDialogOverlay: boolean;
    selectedDomain: SelectedDomain;
    setSelectedDomain: Dispatch<SetStateAction<SelectedDomain>>;
    navigateToKeydis: (domainId: string) => void;
    // If valid URL is provided, the domain will be set on tab press or on blur.
    selectOnBlur?: boolean;
    invalidDomainError: string | undefined;
    setInvalidDomainError: Dispatch<SetStateAction<string | undefined>>;
    inKeyDis?: boolean;
    groupHidden?: boolean;
    fullWidth?: boolean;
  }
>;

export const KeydisDomainSelect = ({
  onDialogOverlay,
  selectedDomain,
  setSelectedDomain,
  navigateToKeydis,
  selectOnBlur = true,
  invalidDomainError,
  setInvalidDomainError,
  inKeyDis,
  fullWidth,
}: KeydisDomainSelectProps) => {
  useLayoutEffect(() => {
    //always initialize the component on dialog overlay with an empty selectedDomain state
    onDialogOverlay && setSelectedDomain({ domain: undefined, domainId: undefined });
  }, []);

  const inputRef = useRef<HTMLInputElement>(null);

  const [searchValue, setSearchValue] = useState<string>(
    onDialogOverlay ? '' : selectedDomain.domain || '',
  );

  const { searchHistory } = useSearchHistory();

  const competitorDomainFilter: CompetitorDomainFilter | undefined = useSpecificFilter(
    FilterAttribute.COMPETITOR_DOMAIN,
  );

  //domainInfo is returning info about already registered domains
  const { domainInfo, loadingDomainInfo, refetch: refetchDomainData } = useQueryDomainInfo();

  //set initial domain name in DomainSelect if a domain is selected initially
  const isFirstDomainLoad = useRef<number | null>(null);

  useEffect(() => {
    if (loadingDomainInfo) return;
    if (isFirstDomainLoad.current || !domainInfo?.domain) {
      isFirstDomainLoad.current = 1;
      return;
    }
    isFirstDomainLoad.current = 1;
    //if the dynamic competitor filter is set, we select the competitor domain - otherwise we use our own registered domain

    if (competitorDomainFilter?.value) {
      setSelectedDomain({ domain: competitorDomainFilter?.value, domainId: undefined });
      return;
    }
    domainInfo?.domain &&
      setSelectedDomain({ domain: domainInfo?.domain, domainId: domainInfo?.id });
    domainInfo.displayName && setSearchValue(domainInfo.displayName);
  }, [loadingDomainInfo]);

  const { domainItems, filteredDomainItems, filteredSearchHistory } = useSelectItems(
    searchValue,
    selectedDomain,
    searchHistory,
  );

  //Sync search when the selected domain is changed from the outside
  useEffect(() => {
    if (onDialogOverlay) return;
    const newSelectedItem = [...domainItems, ...searchHistory].find((selectItem) => {
      const searchBy = selectItem.searchBy?.split('+') || [selectItem.domain];
      return searchBy.includes(selectedDomain.domain);
    });
    if (newSelectedItem) {
      newSelectedItem.label !== searchValue && setSearchValue(newSelectedItem.label);
    } else {
      const searchLabel = selectedDomain.domain
        ? trimTrailingSlash(selectedDomain.domain)
        : undefined;
      //update when new entry added to searchHistory
      searchLabel && searchLabel !== searchValue && setSearchValue(searchLabel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDomain.domain]);

  useEffect(() => {
    const subscription = subscribeToDomain(() => {
      refetchDomainData();
    });
    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const urlValidation = validation.isUrl(searchValue.trim());
  const isValidUrl = !urlValidation;

  const [validateDomain] = useValidateDomainLazyQuery();
  const [validatingDomain, setValidatingDomain] = useState(false);

  const isLoading = !isFirstDomainLoad?.current || validatingDomain;

  const selectItems = [...filteredDomainItems, ...filteredSearchHistory];

  const handleSetDomain = useHandleSetDomain({
    invalidDomainError,
    setInvalidDomainError,
    setValidatingDomain,
    validateDomain,
    setSelectedDomain,
    selectItems,
    inKeyDis,
    onDialogOverlay,
    navigateToKeydis,
  });

  const handleSelectDomainOnBlur = () => {
    //check if the searched values is an existing domain
    const searchDomainHit = selectItems?.find(
      (selectItem) => selectItem.domain === searchValue || selectItem.label === searchValue,
    );
    //if the searched value is not identical to the selected domain
    if (
      (searchValue &&
        selectedDomain.domain !== searchDomainHit?.domain &&
        selectedDomain.domainId !== searchDomainHit?.domainId) ||
      (!searchDomainHit && searchValue)
    ) {
      selectOnBlur && searchDomainHit && handleSetDomain(searchDomainHit.label, searchDomainHit);
    }
  };

  // find the correct favicon to display in <InputBase />
  const faviconDomain = [...domainItems, ...searchHistory].find(
    (item) => item.label === searchValue || trimTrailingSlash(item.domain || '') === searchValue,
  )?.description;

  const combobox = useCombobox({
    onDropdownOpen: () => {
      combobox.selectActiveOption();
      combobox.focusTarget();
    },
  });

  const nothingFoundText: string = isValidUrl
    ? t('Press enter to search for this domain')
    : urlValidation;

  const nothingFound = useSelectNotFound({
    search: searchValue,
    isLoading,
    nothingFoundText,
  });

  const handleBlur = () => {
    combobox.dropdownOpened && combobox.closeDropdown();
    handleSelectDomainOnBlur();
  };

  const ComboboxOptionsList = () => {
    if (isLoading) {
      return (
        <Flex justify={'center'}>
          <DelayedSpinner />
        </Flex>
      );
    }

    return (
      <>
        <ComboboxOptions
          value={selectedDomain.domain || null}
          options={filteredSearchHistory}
          optionComponent={KeydisDomainItemComponent}
        />
        <ComboboxOptions
          value={selectedDomain.domain || null}
          options={filteredDomainItems}
          optionComponent={KeydisDomainItemComponent}
        />
        {!filteredDomainItems?.length && !filteredSearchHistory?.length && (
          <Combobox.Empty>{nothingFound}</Combobox.Empty>
        )}
      </>
    );
  };

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={(_value, optionProps) => {
        const option = selectItems.find((item) => item.value === optionProps.value);
        combobox.dropdownOpened && combobox.closeDropdown();
        handleSetDomain(optionProps.value);
        setSearchValue(option?.label || optionProps.value);
        inputRef.current?.focus();
      }}
      withinPortal
      classNames={{
        dropdown: selectStyles.comboboxDropdown,
        option: selectStyles.comboboxOption,
        options: selectStyles.comboboxOptions,
      }}
      position="bottom"
    >
      <Combobox.Target>
        <InputBase
          ref={inputRef}
          onBlur={handleBlur}
          value={searchValue}
          onChange={(event) => {
            setSearchValue(event.currentTarget.value);
          }}
          onKeyDown={(event) => {
            if (event.key.toLowerCase() === 'enter') {
              handleSetDomain(searchValue);
              combobox.closeDropdown();
            }
          }}
          onClick={() => !combobox.dropdownOpened && combobox.openDropdown()}
          leftSection={faviconDomain ? <DomainFavicon domain={faviconDomain} /> : undefined}
          rightSection={
            <RightSectionElement<string>
              clearable={true}
              value={selectedDomain.domain}
              isLoading={isLoading}
              onChange={() => {
                setSearchValue('');
                inputRef.current?.focus();
                combobox.openDropdown();
                setSelectedDomain({ domain: undefined, domainId: undefined });
              }}
            />
          }
          placeholder={
            loadingDomainInfo
              ? t('Loading domains...')
              : t('Select or enter a path to search. E.g. example.com/path')
          }
          classNames={{
            section: selectStyles.inputSection,
            input: selectStyles.input,
            root: cn(styles.container, selectStyles.inputRoot, { [styles.fullWidth]: fullWidth }),
            error: selectStyles.error,
          }}
          size={'md'}
          radius={'sm'}
        />
      </Combobox.Target>
      <Combobox.Dropdown classNames={{ dropdown: selectStyles.comboboxDropdown }}>
        <Combobox.Options>
          <ScrollArea.Autosize
            mah={MAX_SELECT_DROPDOWN_HEIGHT}
            type="auto"
            scrollbars="y"
            classNames={{ viewport: selectStyles.scrollAreaViewPort }}
          >
            <ComboboxOptionsList />
          </ScrollArea.Autosize>
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};
