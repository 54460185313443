import React, { HTMLAttributeAnchorTarget } from 'react';
import { Link } from 'react-router-dom';

type Props = {
  children: React.ReactNode;
  to?: string;
  target?: HTMLAttributeAnchorTarget;
  className?: string;
};

const ConditionalLink = (props: Props) => {
  const { children, to, target, className } = props;

  return to ? (
    <Link to={to} target={target} className={className}>
      {children}
    </Link>
  ) : (
    <>{children}</>
  );
};

export default ConditionalLink;
