import get from 'lodash/get';
import isString from 'lodash/isString';
import orderBy from 'lodash/orderBy';
import { TableOfflineFilter } from 'Components/DataTable/hooks/offline/useOfflineFilter';
import * as Sort from 'Types/Sort';
import { SortTypes } from 'Types/Sort';
import { DataLike } from './types';

const tableSortByDate = (data: DataLike, sortField, sortOrder): DataLike => {
  return orderBy(
    data,
    (o) => {
      return new Date(get(o, sortField));
    },
    sortOrder.toLowerCase(),
  );
};

/**
 * Sort by string value
 */
const tableSortByNatural = (data: DataLike, sortField, sortOrder): DataLike => {
  return orderBy(
    data,
    (o) => {
      const item = get(o, sortField);

      if (isString(item)) {
        return item != null ? item.toLowerCase() : '';
      }

      return item;
    },
    sortOrder.toLowerCase(),
  );
};

export const getAttributeMapping = (from: string, mappings: TableOfflineFilter['mappings']) => {
  if (mappings) {
    return mappings[from] || from;
  }

  return from;
};

export const getFilterDataValue = (
  option: DataLike[0],
  attr: string,
  options: TableOfflineFilter,
) => {
  const attribute = getAttributeMapping(attr, options?.mappings);
  const value = get(option, attribute);

  return options?.transformData?.[attribute]?.(value) ?? value;
};

export const sortMap: Record<SortTypes, (data: DataLike, sortField, sortOrder) => DataLike> = {
  [Sort.DATE]: tableSortByDate,
  [Sort.NATURAL]: tableSortByNatural,
};
