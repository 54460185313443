import isNil from 'lodash/isNil';
import { formatNumberHelper } from 'Components/FormatNumber/formatNumberHelper';
import ValueDelta from 'Components/Table/TableRow/ValueDelta';
import { DashboardNode } from 'Pages/Domains/support/types';
import { percentageChange } from 'Utilities/format';
import KPIChangeBadge from '../components/KpiChangeBadge';
import styles from '../domains-table.module.scss';

type Props = {
  domainNode: DashboardNode;
};

const AverageRankChangeCell = (props: Props) => {
  const { countKeywords, avgRank, avgRankCompare } = props.domainNode || {};

  if (!countKeywords || isNil(avgRank)) return null;

  const avgRankChangePercentage = percentageChange(avgRankCompare ?? 0, avgRank);
  const avgRankChange = avgRank - (avgRankCompare ?? 0);
  const avgRankChangeFormatted = formatNumberHelper({
    value: avgRankChange,
    precision: 1,
  });
  const avgRankChangePercentageFormatted = parseInt((avgRankChangePercentage * 100).toFixed(0), 10);

  if (!avgRankCompare || !avgRankChangeFormatted || avgRankChangeFormatted === '0') return null;

  return (
    <div className={styles.changeCell}>
      <ValueDelta reverseColor currentValue={avgRank} delta={avgRankChange} precision={1} />
      <KPIChangeBadge
        color={
          avgRankChangePercentageFormatted > 0
            ? 'red'
            : avgRankChangePercentageFormatted < 0
            ? 'green'
            : 'grey'
        }
        value={
          avgRankChangePercentageFormatted !== 0
            ? `${avgRankChangePercentage > 0 ? '+' : ''}${avgRankChangePercentageFormatted}%`
            : `${avgRankChangePercentageFormatted}%`
        }
      />
    </div>
  );
};

export default AverageRankChangeCell;
