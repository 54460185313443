import { useState } from 'react';
import { useForm } from 'react-final-form';
import cn from 'classnames';
import { FormApi } from 'final-form';
import noop from 'lodash/noop';
import { AccSelect, SelectItem } from 'Components/AccSelect';
import type { SelectStylesVariations } from 'Components/AccSelect';
import FilterCount from 'Components/Filters/Common/FilterCount';
import { Flag } from 'Components/Flag';
import { useCountryLocaleCountsQuery } from 'Ghql';
import { useOrganizationSelector } from 'Selectors/OrganizationSelector';
import { t } from 'Utilities/i18n';
import { devError } from 'Utilities/log';
import './locale-select.scss';

type Locale = {
  id: string;
  countryCode: string;
  region: string;
  locale: string;
  localeShort: string;
  searchEngines?: any[];
  count?: number;
  className?: string;
};

type LocaleOption = Locale & SelectItem<string>;

type Props = {
  autoFocus?: boolean;
  locales?: Locale[];
  value?: string;
  onChange: (value: string | null) => void;
  showError?: string;
  placeholder: string;
  disabled?: boolean;
  validateCheck?: boolean;
  size?: SelectStylesVariations['size'];
  id?: string;
  label?: string;
  required?: boolean;
};

const filterLocaleOptions = (filter: string, option: LocaleOption): boolean => {
  const localeLowercase = option?.locale?.toLowerCase();
  const regionLowercase = option?.region?.toLowerCase();

  if (!localeLowercase || !regionLowercase) {
    return false;
  }
  const searchEnginesLowercase =
    option.searchEngines &&
    option.searchEngines
      .map((engine) => engine.name?.toLowerCase().replace('youtube', 'youTube'))
      .join(', ');
  filter = filter?.toLowerCase();
  return (
    localeLowercase.includes(filter) ||
    regionLowercase.includes(filter) ||
    (!!searchEnginesLowercase && searchEnginesLowercase.includes(filter))
  );
};

const LocaleSelectItem = ({
  region,
  locale,
  countryCode: optionCountryCode,
  count,
  className,
  ...others
}: Locale) => {
  const countryCode = optionCountryCode?.toLowerCase();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { searchEngines, localeShort, ...divProps } = others;
  return (
    <div
      {...divProps}
      className={cn('locale-select option', className, {
        'with-count': count !== null && count !== undefined,
      })}
    >
      <Flag size="sm" mr={8} country={countryCode} />
      <span>
        {region} - {locale}
      </span>
      <FilterCount count={count} inOption />
    </div>
  );
};

LocaleSelectItem.displayName = 'LocaleSelectItem';

/**
 * Order locales by
 * 1) Most popular locales so far on this organization
 * 2) If there are no locales, the created by country for the organization
 * 3) Hardcoded list of most popular locales in database
 * @param locales
 */
function useOrderedLocales(locales, withCount) {
  const organization = useOrganizationSelector();

  const { data: countryLocaleCounts } = useCountryLocaleCountsQuery();

  // if any of the locales have a count, sort by count
  if (withCount) {
    return locales
      .sort((a, b) => (b.count ?? 0) - (a.count ?? 0))
      .map((e) => ({
        ...e,
        value: e.id,
        label: e.region,
      }));
  }

  // Ids corresponding to the locales that should be displayed first in the dropdown
  // Loosely based on the most popular locales in our database
  let orderedIdsByPopularity = ['314', '313', '16', '98', '76', '283', '93', '208', '279'];
  // Insert the most chosen countries so far on the organization at the top of the list
  if (countryLocaleCounts?.countrylocaleCounts) {
    const mostPopularCountrylocales = countryLocaleCounts?.countrylocaleCounts
      .slice()
      .sort((a, b) => b.count - a.count)
      .slice(0, 5) // Take top 5 most popular
      .map((x) => x.defaultCountrylocaleId.toString());

    orderedIdsByPopularity = mostPopularCountrylocales
      .concat(orderedIdsByPopularity.filter((x) => !mostPopularCountrylocales.includes(x)))
      .slice(0, 10);
  }

  const hasExistingDomains = Boolean(countryLocaleCounts?.countrylocaleCounts);

  const localeSort = (b, a) => {
    // If we do not have statistics about which locales they choose, suggest their organization country first
    if (!hasExistingDomains) {
      if (a.countryCode === organization?.createdByCountry) {
        return 1;
      }
      if (b.countryCode === organization?.createdByCountry) {
        return -1;
      }
    }

    // Otherwise sort by popularity

    // Find the indices of a and b in the groupOrder array
    const indexA = orderedIdsByPopularity.indexOf(a.id);
    const indexB = orderedIdsByPopularity.indexOf(b.id);

    if (indexA !== -1 && indexB !== -1) {
      // If both a and b are in the specified groups, sort them in reverse order
      return indexB - indexA;
    } else if (indexA !== -1) {
      // If only a is in the specified groups, it comes after b
      return 1;
    } else if (indexB !== -1) {
      // If only b is in the specified groups, it comes after a
      return -1;
    }
    // If neither a nor b are in the specified groups, sort them alphabetically by label
    return b.label.localeCompare(a.label);
  };

  return (
    locales
      ?.map((e) => ({
        ...e,
        value: e.id,
        label: e.region,
        group:
          orderedIdsByPopularity.includes(e.id) ||
          (!hasExistingDomains && e.countryCode === organization?.createdByCountry)
            ? t('Suggested countries and locales')
            : t('Other countries and locales'),
      }))
      .sort(localeSort) ?? []
  );
}

const LocaleSelect = (props: Props) => {
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const { placeholder, locales, value, showError, autoFocus, validateCheck, disabled } = props;
  const isError = (!isFirstLoading || validateCheck) && showError;

  const handleBlur = () => {
    setIsFirstLoading(false);
  };
  const withCount = locales?.some((locale) => locale?.count);
  const orderedLocales = useOrderedLocales(locales, withCount);

  return (
    <AccSelect<LocaleOption>
      options={orderedLocales}
      size={props.size}
      disabled={disabled}
      value={value}
      className="locale-select-main"
      autoFocus={autoFocus}
      placeholder={placeholder}
      showError={!!isError}
      onBlur={handleBlur}
      onChange={props.onChange ?? noop}
      itemComponent={LocaleSelectItem}
      filter={filterLocaleOptions}
      error={isError ? showError : null}
      clearSearchOnChange
      id={props.id}
      label={props.label}
      required={props.required}
      groupHidden={withCount}
    />
  );
};

LocaleSelect.defaultProps = {
  disabled: false,
};

interface LocaleSelectFieldProps extends Omit<Props, 'onChange'> {
  onChange: (value: string, form: FormApi) => void;
}

/**
 * LocaleSelectField implemented for use with
 * final form
 */
// eslint-disable-next-line import/no-unused-modules
export const LocaleSelectField = (props: LocaleSelectFieldProps) => {
  const form = useForm();
  const { onChange, ...rest } = props;

  const handleChange = (value: LocaleSelectFieldProps['value'] | null) => {
    if (typeof value === 'string') {
      onChange(value, form);
    } else {
      devError('Error: The value passed to the LocaleSelect FormField is not of type string');
    }
  };

  return <LocaleSelect {...rest} onChange={handleChange} />;
};

export default LocaleSelect;
