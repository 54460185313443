import { Box } from '@mantine/core';
import { Field } from 'redux-form';
import LoadingSpinner from 'Components/LoadingSpinner';
import Validator from 'Utilities/validation';

// Fake field to prevent submit while loading
const FieldLoader = () => (
  <Box style={{ textAlign: 'center' }} m="xs">
    <LoadingSpinner />
    <Field
      name="value"
      component="input"
      type="hidden"
      validate={[Validator.required, Validator.numeric]}
    />
  </Box>
);
export default FieldLoader;
