import { InjectedIntl } from 'Types/Intl';

const ONE_BILLION = 1000000000;
const ONE_MILLION = 1000000;
const TEN_THOUSAND = 10000;
const ONE_THOUSAND = 1000;
const ONE_HUNDRED = 100;

const defaultBillionsSettings = {
  label: 'B',
  cutoff: ONE_BILLION,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

const defaultMillionsSettings = {
  label: 'M',
  cutoff: ONE_MILLION,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

const defaultThousandsSettings = {
  label: 'K',
  cutoff: TEN_THOUSAND,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

const defaultFormatConfig = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
  style: 'decimal',
  currency: 'USD',
  currencyDisplay: 'symbol',
  signDisplay: 'auto',
  formatSettings: {
    spaceBetweenNumberAndLabel: false,
    billionsSettings: defaultBillionsSettings,
    millionsSettings: defaultMillionsSettings,
    thousandsSettings: defaultThousandsSettings,
  },
};

/**
 * Used to get string with formatted value
 * to use, need to wrap component with `injectIntl`
 * @note in react-intl v3 it will be possible to use hook useIntl *(current is v2)
 */
export const intlFormatNumber =
  (intl: InjectedIntl) =>
  ({
    value,
    minimumFractionDigits = defaultFormatConfig.minimumFractionDigits,
    maximumFractionDigits = defaultFormatConfig.maximumFractionDigits,
    style = defaultFormatConfig.style,
    currency = defaultFormatConfig.currency,
    currencyDisplay = defaultFormatConfig.currencyDisplay,
    signDisplay = defaultFormatConfig.signDisplay,
    formatSettings: {
      spaceBetweenNumberAndLabel,
      billionsSettings,
      millionsSettings,
      thousandsSettings,
    } = defaultFormatConfig.formatSettings,
  }: any) => {
    let postfix = '';
    let formattedValue = value;

    let numberFormatOptions = {};

    if (style === 'currency') {
      numberFormatOptions = {
        style,
        currency,
        currencyDisplay,
        signDisplay,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
    } else {
      numberFormatOptions = {
        style,
        minimumFractionDigits,
        maximumFractionDigits,
        signDisplay,
      };
    }

    if (style === 'currency' || style === 'decimal') {
      if (billionsSettings && billionsSettings.cutoff <= value) {
        formattedValue = Math.round((value / ONE_BILLION) * ONE_HUNDRED) / ONE_HUNDRED;

        if (style === 'currency') {
          // for currency we want the possible postfix to be infront of the currency like 10K USD
          numberFormatOptions = {
            ...numberFormatOptions,
            style: 'decimal',
            signDisplay,
          };

          postfix = `${billionsSettings.label} ${currency}`;
        } else {
          postfix = billionsSettings.label;
        }

        numberFormatOptions = {
          ...numberFormatOptions,
          minimumFractionDigits: billionsSettings.minimumFractionDigits,
          maximumFractionDigits: billionsSettings.maximumFractionDigits,
        };
      } else if (millionsSettings && millionsSettings.cutoff <= value) {
        formattedValue = Math.round((value / ONE_MILLION) * ONE_HUNDRED) / ONE_HUNDRED;

        if (style === 'currency') {
          // for currency we want the possible postfix to be infront of the currency like 10K USD
          numberFormatOptions = {
            ...numberFormatOptions,
            style: 'decimal',
            signDisplay,
          };

          postfix = `${millionsSettings.label} ${currency}`;
        } else {
          postfix = millionsSettings.label;
        }

        numberFormatOptions = {
          ...numberFormatOptions,
          minimumFractionDigits: millionsSettings.minimumFractionDigits,
          maximumFractionDigits: millionsSettings.maximumFractionDigits,
        };
      } else if (thousandsSettings && thousandsSettings.cutoff <= value) {
        formattedValue = Math.round((value / ONE_THOUSAND) * ONE_HUNDRED) / ONE_HUNDRED;

        if (style === 'currency') {
          // for currency we want the possible postfix to be infront of the currency like 10K USD
          numberFormatOptions = {
            ...numberFormatOptions,

            style: 'decimal',
            signDisplay,
          };

          postfix = `${thousandsSettings.label} ${currency}`;
        } else {
          postfix = thousandsSettings.label;
        }

        numberFormatOptions = {
          ...numberFormatOptions,
          minimumFractionDigits: thousandsSettings.minimumFractionDigits,
          maximumFractionDigits: thousandsSettings.maximumFractionDigits,
        };
      }
    }

    const result = intl.formatNumber(formattedValue, numberFormatOptions)?.replace('-', '−');

    return `${result}${spaceBetweenNumberAndLabel ? ' ' : ''}${postfix}`;
  };
