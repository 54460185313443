import * as React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { hideModal } from 'Actions/ModalAction';
import { linkToKeywordsDomain } from 'Components/Filters/LinkToDomain';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { LATEST } from 'Components/PeriodFilter/model';
import { withRouter } from 'Utilities/Router';
import { formatDate, formatDateTime } from 'Utilities/format';
import { t, tct } from 'Utilities/i18n';
import './partial-scrape.scss';

type Props = {
  domainInfo: any;
  dateRange: any;
  min: any;
  max: any;
  history?: any;
  hideModal?: any;
};
type State = {};

class PartialScrape extends React.Component<Props, State> {
  static defaultProps = {};
  state = {};
  handleClose = () => {
    this.props.hideModal();
  };
  handleRefresh = () => {
    const { domainInfo } = this.props;
    this.props.history.push(linkToKeywordsDomain(domainInfo.id));
    this.props.hideModal();
  };

  render() {
    const { domainInfo, dateRange, min, max } = this.props;
    let periodTo = dateRange.to;

    if (periodTo === LATEST) {
      periodTo = max;
    }

    if (periodTo < min) {
      periodTo = min;
    }

    const domain = domainInfo.domain;
    let lastFullScrape = domainInfo.lastFullScrapeCompleted;
    const nextScheduledRefresh = moment(domainInfo.lastScraped).add(1, 'day');

    if (lastFullScrape < min) {
      // domain has not been refreshed
      lastFullScrape = null;
    }

    return (
      <ModalBorder
        title={t('You have selected a date that is not fully refreshed')}
        closeOnClickOutside={true}
        showExclamationInTitle={true}
        onClose={this.handleClose}
      >
        <p>
          {lastFullScrape
            ? tct(
                'Your domain is currently undergoing a scraping process. You are trying to view ranks for [periodTo], however [domain] was last refreshed at [lastFullScrape].',
                {
                  periodTo: formatDate(periodTo),
                  domain,
                  lastFullScrape: formatDateTime(lastFullScrape),
                },
              )
            : tct(
                'You are trying to view ranks for [periodTo], however [domain] has not yet been fully refreshed.',
                {
                  periodTo: formatDate(periodTo),
                  domain,
                },
              )}
        </p>

        {lastFullScrape && (
          <p>{t('You will therefore only see a subset of ranks for the chosen date.')}</p>
        )}

        <p>
          {tct(
            `You can manually refresh on [link:${t(
              'the keywords list',
            )}] or wait until [nextScheduledRefresh] for the next automatic scheduled refresh.`,
            {
              link: <span className="refresh-button" onClick={this.handleRefresh} />,
              nextScheduledRefresh: formatDateTime(nextScheduledRefresh as any),
            },
          )}
        </p>
      </ModalBorder>
    );
  }
}

export default connect(null, {
  hideModal,
})(withRouter(PartialScrape as any)) as any;
