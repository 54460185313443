/* eslint-disable react/display-name */
import { PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { Box, Center, Modal, ModalProps, useMantineTheme } from '@mantine/core';
import AccTitle from 'Components/Title/AccTitle';
import styles from './simpleModal.module.scss';

type Props = PropsWithChildren<{
  title: string;
  opened?: boolean;
  setOpened?: (opened: boolean) => void;
  modalProps?: Omit<ModalProps, 'opened' | 'onClose' | 'title' | 'children'>;
  onClose?: () => void;
  zIndex?: number;
}>;

const SimpleModal = ({
  title,
  children,
  opened = false,
  setOpened,
  modalProps = {},
  onClose,
  zIndex,
}: Props) => {
  const [localOpened, setLocalOpened] = useState(opened);

  const handleClose = () => {
    setLocalOpened(false);
    setOpened?.(false);
    onClose?.();
  };

  useEffect(() => {
    setLocalOpened(opened);
  }, [opened]);

  return (
    <Modal
      opened={localOpened}
      onClose={() => handleClose()}
      title={
        <Center h="75x">
          <AccTitle type="h4" c="white" fw={600} nowrap>
            {title}
          </AccTitle>
        </Center>
      }
      overlayProps={{ color: 'black', opacity: 0.4 }}
      padding={0}
      classNames={{
        root: styles.modal,
        inner: styles.inner,
        content: styles.content,
        body: styles.body,
        header: styles.header,
        close: styles.close,
      }}
      {...modalProps}
      zIndex={zIndex}
      yOffset={100}
    >
      {children}
    </Modal>
  );
};

const getChildrenLength = (children: ReactNode): number => {
  if (Array.isArray(children)) {
    return children.length;
  }
  if (children == null) {
    return 0;
  }
  return 1;
};

const SimpleModalFooter = ({ children }: PropsWithChildren) => {
  const {
    other: { gutterSm },
  } = useMantineTheme();

  const childrenLength = getChildrenLength(children);

  return (
    <>
      <Box
        bg="gray.0"
        h="70px"
        m="0 -1rem -1rem -1rem"
        display="grid"
        px="1rem"
        style={{
          gridTemplateColumns:
            childrenLength <= 2
              ? '1fr auto auto'
              : `1fr ${Array(childrenLength - 1)
                  .fill('auto')
                  .join(' ')}`,
          alignItems: 'center',
          gap: gutterSm,
        }}
      >
        {childrenLength === 1 && (
          <>
            <div />
            <div />
          </>
        )}
        {childrenLength === 2 && <div />}
        {children}
      </Box>
    </>
  );
};

SimpleModal.Footer = SimpleModalFooter;

// eslint-disable-next-line import/no-unused-modules
export default SimpleModal;
