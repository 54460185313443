import { useCallback, useEffect, useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import { RowRenderType, VirtualizedTable } from 'Components/Table/VirtualizedTable';
import TableEmptyState from 'Components/TableEmptyState';
import { QueryOrder } from 'Constants';
import {
  getAllFolderNodeIds,
  getAllNodes,
  getExpandedNodeIds,
  updateTreeToToggleExpanded,
} from 'Utilities/Table/Tree/treeUtils';
import { t } from 'Utilities/i18n';
import { TableRowContainer } from './components/TableRowContainer';
import { applyExpandedToTree, flattenTreeAndAddMetaInfo } from './support/helpers';
import { useClientSideSearch } from './support/hooks';
import { GroupingsTreeTableProps } from './support/types';
import styles from './treetable.module.scss';

/**
 * Render large trees using virtualized-table
 * fork from SiteMappings, ideally should be separate and generic.
 */
export const TreeTable = <T extends object = object>({
  data,
  loading,
  expandDataKey = 'id',
  childrenLoadingKey = 'childrenLoading',
  rowComponent: RowContentComponent,
  headerComponent: Header,
  emptyContentComponent,
  saveExpandedNodes,
  expandedNodes: initExpandedNodeIds,
  disableRowSelect,
  onNodeSelect,
  gridTableRows,
  topOffset,
  headerProps,
  cellProps,
  onDragEnd,
  tableRowKey,
  bottomWrapper,
  disableBodyOnScroll = true,
  // disable expand/collapse all nodes
  disableExpandCollapse = false,
  searchKey,
  selectLimit,
}: GroupingsTreeTableProps<T>) => {
  const initData = useMemo(
    () => applyExpandedToTree(data, initExpandedNodeIds, expandDataKey),
    [data, initExpandedNodeIds],
  );

  const [treeData, setData] = useState(initData);

  useEffect(() => {
    setData(applyExpandedToTree(data, initExpandedNodeIds, expandDataKey, true));
  }, [data, initExpandedNodeIds]);

  const { nestedData, submitSearch, activeSearch } = useClientSideSearch(
    treeData,
    searchKey || expandDataKey,
    initData,
  );

  const handleTreeChange = useCallback(
    (resultData) => {
      saveExpandedNodes?.(getExpandedNodeIds(resultData));
      setData(applyExpandedToTree(resultData, [], expandDataKey));
    },
    [setData, saveExpandedNodes],
  );

  const currentExpandedNodeIds = getExpandedNodeIds(treeData);

  const expandOrCollapseAllNodes = useCallback(() => {
    if (currentExpandedNodeIds.length > 0) {
      saveExpandedNodes?.([]);
      setData(applyExpandedToTree(treeData, [], expandDataKey, true));
    } else {
      saveExpandedNodes?.(getAllFolderNodeIds(treeData));
      setData(applyExpandedToTree(treeData, getAllNodes(treeData), expandDataKey));
    }
  }, [currentExpandedNodeIds]);

  const TreeRowComponent: RowRenderType<T> = ({ node: rootNode, onToggle }) => {
    return (
      <TableRowContainer
        disableRowSelect={disableRowSelect}
        rootNode={rootNode}
        expandDataKey={expandDataKey}
        onNodeSelect={onNodeSelect}
        rowComponent={RowContentComponent}
        gridTableRows={gridTableRows}
        onDragEnd={onDragEnd}
        onToggle={onToggle}
        cellProps={cellProps}
        selectLimit={selectLimit}
      />
    );
  };

  const TreeRow = useMemo(() => TreeRowComponent, [onNodeSelect, tableRowKey]);

  const [resetHeaderKey, setResetHeaderKey] = useState(0);
  const headerRow = useMemo(
    () => (
      <Header
        submitSearch={submitSearch}
        className={styles.treeTableHeader}
        activeSearch={activeSearch}
        orderBy={headerProps?.orderBy || ''}
        order={headerProps?.order || QueryOrder.ASC}
        setOrderBy={headerProps?.setOrderBy || noop}
        key={resetHeaderKey?.toString()}
        handleExpandOrCollapseAllNodes={
          disableExpandCollapse ? undefined : expandOrCollapseAllNodes
        }
        expandNodes={currentExpandedNodeIds && currentExpandedNodeIds.length === 0}
      />
    ),
    [headerProps, activeSearch, resetHeaderKey, submitSearch, Header],
  );

  const resetSearch = () => {
    setResetHeaderKey(resetHeaderKey + 1);
    submitSearch('');
  };

  const emptyContent = useMemo(() => {
    if (emptyContentComponent) {
      const EmptyContentComponent = emptyContentComponent;
      return <EmptyContentComponent activeSearch={activeSearch} resetSearch={resetSearch} />;
    }
    return <TableEmptyState list={[]} title={t('No data found')} />;
  }, [activeSearch]);

  const onToggle = useCallback(
    (node) => {
      const nodeId = node[expandDataKey];
      const resultData = updateTreeToToggleExpanded(nestedData, nodeId, !node.state?.expanded);
      handleTreeChange(resultData);
      saveExpandedNodes?.(getExpandedNodeIds(resultData));
    },
    [nestedData],
  );

  const tableData = useMemo(() => {
    return flattenTreeAndAddMetaInfo({ nodes: nestedData, childrenLoadingKey });
  }, [nestedData]);

  const showPlaceholder = isEmpty(nestedData) && !loading;

  return (
    <VirtualizedTable
      data={tableData}
      rowRender={TreeRow}
      onToggle={onToggle}
      headerRow={headerRow}
      placeholderContent={showPlaceholder ? emptyContent : null}
      loading={loading}
      topOffset={topOffset}
      bottomWrapper={bottomWrapper}
      disableBodyOnScroll={disableBodyOnScroll}
    />
  );
};
