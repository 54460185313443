export const CLEAR_EVERYTHING = 'clear_everything';
export const SET_EVERYTHING = 'set_everything';
export function clearRedux() {
  return {
    type: CLEAR_EVERYTHING,
  };
}

export function clearEverything() {
  return () => {
    localStorage.clear(); //dispatch(clearRedux()); this is not needed
  };
}
