import * as React from 'react';
import isEqual from 'lodash/isEqual';
import type { ColumnType } from './interface';
import { getFlexColumnWidth } from './utils/cols';
import { INTERNAL_COL_DEFINE } from './utils/legacyUtil';

interface ColGroupProps<RecordType> {
  colWidths: readonly number[];
  columns?: readonly ColumnType<RecordType>[];
  columCount?: number;
  tableWidth?: number;
}

function ColGroup<RecordType>({ colWidths, columns, tableWidth }: ColGroupProps<RecordType>) {
  const cols: React.ReactElement[] = [];
  const len = columns?.length ?? 0;

  const flexColumnWidth = getFlexColumnWidth(colWidths, columns, tableWidth);
  // Only insert col with width & additional props
  // Skip if rest col do not have any useful info
  let mustInsert = false;

  for (let i = len - 1; i >= 0; i -= 1) {
    let width = colWidths[i];
    const column = columns && columns[i];
    const additionalProps = (column as any)?.[INTERNAL_COL_DEFINE];

    if (column?.flex && flexColumnWidth) {
      width = flexColumnWidth;
      // Tricky way to fix extra scroll bar when we have on flex column and few columns
      // issue can be related to wrong tableWidth with adding additional pixels 3h
      width -= 1;
    }
    if (width || additionalProps || mustInsert) {
      cols.unshift(<col key={i} style={{ width, maxWidth: width }} {...additionalProps} />);
      mustInsert = true;
    }
  }

  return <colgroup>{cols}</colgroup>;
}

export default React.memo(ColGroup, isEqual);
