import { useEffect, useState } from 'react';
import { useFilters } from 'Hooks';
import { FilterBase } from 'Types/Filter';
import { ColumnFilter } from '../../../../../types';
import { FilterBtn } from '../FilterButton/FilterButton';
import './table-filters.scss';

interface TableFilterProps {
  filter: ColumnFilter;
}

export const TableFilter = ({
  filter: { filterAttributes, filterId, filterContent, filterClassName },
}: TableFilterProps) => {
  const filters = useFilters();
  const [activeFilters, setActiveFilters] = useState<FilterBase[]>([]);

  useEffect(() => {
    if (filterAttributes && filters) {
      setActiveFilters(
        filters.filter(Boolean).filter((f) => filterAttributes.includes(f.attribute)),
      );
    }
  }, [filterAttributes, filters]);

  if (!filterAttributes?.length) return null;

  return (
    <FilterBtn
      activeFilter={activeFilters.length > 0}
      filterId={filterId}
      filterContent={filterContent}
      filterClassName={filterClassName}
    />
  );
};
