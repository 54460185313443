import { useCallback } from 'react';
import {
  useFilterData,
  useGetPagination,
  useGetSort,
} from 'Components/DataTable/hooks/offline/support/hooks';
import { TablePagination } from 'Components/DataTable/index';
import { SortTypes } from 'Types/Sort';

type DataLike = Record<string, any>[];

/**
 * Configuration for the table that are using backend queries that don't have sorting/pagination
 */
export type TableOfflineFilter = {
  tableName: string;
  /**
   * Filters that should be skipped in data filtering,
   * for example: `skip: ['domain']
   */
  skip?: string[];
  /**
   * Mapping between the filter attribute and the actual attribute in the data,
   * For example we send `order: commission, orderBy: 'desc', but in data we have value stored in 'affiliate.commission'
   * to work properly you should provide: commission: 'affiliate.commission',
   */
  mappings?: Record<string, string>;
  /**
   * Maps attribute with SortTypes (DATE - for date fields, NATURAL - for string fields),
   * @default NATURAL
   * @example: `sortTypes: { commission: SortType.DATE }`
   */
  sortTypes?: Record<string, SortTypes>;
  /**
   * transformation callback that transform result value before comparing with filter value
   */
  transformData?: Record<string, (value: unknown) => unknown>;
  /**
   * Skip all filters
   */
  skipAll?: boolean;
  /**
   * Disable pagination
   */
  withoutPagination?: boolean;
};

const useOfflineFilter = (options?: TableOfflineFilter) => {
  const filterData = useFilterData(options);
  const sortData = useGetSort(options);

  const paginateData = useGetPagination(options?.withoutPagination);
  if (!options) {
    return null;
  }

  return useCallback(
    (
      data: DataLike,
      externalTableProps?: { sortField: string; sortOrder: string },
      pagination?: TablePagination,
    ): { data: DataLike; length: number } => {
      const filteredData = filterData(data) ?? [];
      const sortedData = sortData(filteredData, externalTableProps) ?? [];
      return paginateData(sortedData, pagination);
    },
    [options, filterData, sortData, paginateData],
  );
};

export default useOfflineFilter;
