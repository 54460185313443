import { Dispatch, SetStateAction, useEffect } from 'react';
import { PublicChartTypes } from '../publicCharts';

export const useSyncCharts = ({
  includeAverageRankChart,
  includeRankingDistributionChart,
  includeSearchIntentChart,
  selectedChart,
  setSelectedChart,
}: {
  includeAverageRankChart: boolean;
  includeRankingDistributionChart: boolean;
  includeSearchIntentChart: boolean;
  selectedChart: PublicChartTypes;
  setSelectedChart: Dispatch<SetStateAction<PublicChartTypes>>;
}) => {
  useEffect(() => {
    if (!includeAverageRankChart && selectedChart === PublicChartTypes.AVERAGE_RANK) {
      setSelectedChart(
        includeRankingDistributionChart
          ? PublicChartTypes.RANKING_DISTRIBUTION
          : PublicChartTypes.SEARCH_INTENT,
      );
      return;
    }
    if (
      !includeRankingDistributionChart &&
      selectedChart === PublicChartTypes.RANKING_DISTRIBUTION
    ) {
      setSelectedChart(
        includeAverageRankChart ? PublicChartTypes.AVERAGE_RANK : PublicChartTypes.SEARCH_INTENT,
      );
      return;
    }
    if (!includeSearchIntentChart && selectedChart === PublicChartTypes.SEARCH_INTENT) {
      setSelectedChart(
        includeAverageRankChart
          ? PublicChartTypes.AVERAGE_RANK
          : PublicChartTypes.RANKING_DISTRIBUTION,
      );
      return;
    }
  }, [includeAverageRankChart, includeRankingDistributionChart, includeSearchIntentChart]);
};
