import { forwardRef, useCallback, useImperativeHandle, useMemo } from 'react';
import { Flex } from '@mantine/core';
import { AccuPagination } from 'Components/DataTable/components/footer/Pagination/Pagination';
import Sort from 'Components/Sort';
import { GroupStats } from '../DomainsTable/components/GroupStats';
import { CardViewGroupsAndDomains } from './components/CardViewGroupsAndDomains';
import CardViewToolbar from './components/CardViewToolbar';
import DomainsList from './components/DomainsList';
import { getSorting } from './support/getSorting';
import { useFetchDashboardCardsData } from './support/useFetchDashboardCardsData';
import { usePaginationSize } from './support/usePaginationSize';

const CardView = forwardRef((_, ref) => {
  const { paginationPageSize, ref: paginationRef } = usePaginationSize();

  const {
    data,
    loading,
    numberOfKeywordsInGroup,
    totalKeywordsForSelectedDomains,
    clearSelectedDomains,
    selectedGroup,
    handleSetGroup,
    selectedDomains,
    domainsInGroup,
    updateSelectedDomains,
    pagination,
    setPagination,
    ordering,
    handleUpdateOrdering,
    handleDeselectAllCompareDomains,
  } = useFetchDashboardCardsData({ paginationPageSize });

  useImperativeHandle(ref, () => ({
    handleSetGroup,
  }));

  const handleUpdatePagination = useCallback(
    (newPage: number) => {
      if (pagination?.page !== newPage && paginationPageSize) {
        setPagination((prev) => ({
          page: newPage,
          startIndex: (newPage - 1) * paginationPageSize,
          stopIndex: newPage * paginationPageSize,
          results: prev?.results || paginationPageSize,
        }));
        return true;
      }
      return false;
    },
    [pagination, paginationPageSize, setPagination],
  );

  const options = useMemo(getSorting, []);

  // reset selected domains for comparison when initially loading the view
  const initialLoadRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (!node) {
        return;
      }
      handleDeselectAllCompareDomains();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleDeselectAllCompareDomains],
  );

  return (
    <>
      <Flex ref={paginationRef} flex={1} gap={8} direction="column">
        <Flex ref={initialLoadRef} justify="space-between" align="center">
          <CardViewGroupsAndDomains
            handleSetGroup={handleSetGroup}
            selectedGroup={selectedGroup}
            selectedDomains={selectedDomains}
            updateSelectedDomains={updateSelectedDomains}
            clearSelectedDomains={clearSelectedDomains}
          />
          <Sort options={options} ordering={ordering} onOrderChange={handleUpdateOrdering} />
        </Flex>
        <GroupStats
          domains={data}
          selectedDomains={selectedDomains}
          loading={loading}
          numberOfKeywordsInGroup={numberOfKeywordsInGroup}
          domainsInGroup={domainsInGroup}
          totalKeywordsForSelectedDomains={totalKeywordsForSelectedDomains}
        />
        <DomainsList
          data={data}
          selectedGroup={selectedGroup}
          pageSize={paginationPageSize}
          loading={loading}
        />
        <AccuPagination
          onPagination={handleUpdatePagination}
          totalDataLength={selectedDomains.length || domainsInGroup.length}
          loading={loading}
          currentPage={pagination?.page || 1}
          setCurrentPage={(page) => {
            handleUpdatePagination(page);
          }}
          tableLabels={{
            cellLoadingTooltip: 'Refreshing domains...',
            paginationLoading: 'Loading domains ...',
          }}
          onPageSizeChange={() => {}}
          paginationPageSize={paginationPageSize}
        />
      </Flex>
      <CardViewToolbar />
    </>
  );
});
CardView.displayName = 'CardView';

export default CardView;
