import { Link } from 'react-router-dom';
import startsWith from 'lodash/startsWith';
import Tabs from 'Components/AccTabs/Tabs';
import { useSmartRouterSubscription } from 'Utilities/Router/components/LocationPathnameContext';
import { t } from 'Utilities/i18n';
import { useActiveNavKey } from '../SideNavbar/support/useActiveNavKey';
import { useKeywordNavItems } from '../SideNavbar/support/useKeywordNavItems';

export const keywordsPrefixes = ['/keywords', '/notes', '/settings'];

const DomainTabs = () => {
  const activeNavKey = useActiveNavKey();

  const { isDiscoverySelected, isDomainSelected } = useSmartRouterSubscription((pathname) => {
    let discoverySelected = false;
    let domainSelected = false;

    if (startsWith(pathname, '/research/')) {
      discoverySelected = true;
    } else if (keywordsPrefixes.some((prefix) => startsWith(pathname, prefix))) {
      domainSelected = true;
    }

    return { isDomainSelected: domainSelected, isDiscoverySelected: discoverySelected };
  });

  const navItems = useKeywordNavItems(isDiscoverySelected);

  if (!isDiscoverySelected && !isDomainSelected) return null;

  return (
    <Tabs
      mt="xl"
      value={
        isDiscoverySelected
          ? navItems.find((item) => item.link.includes('/keyword_discovery/'))?.link || null
          : navItems.find((item) => activeNavKey && item.link.includes(activeNavKey))?.link || null
      }
    >
      <Tabs.List>
        {navItems.map((item) => (
          <Link to={item.link} key={item.key} data-testid={item.testid}>
            <Tabs.Tab
              key={item.key}
              value={item.link}
              leftSection={item.icon && <item.icon />}
              disabled={isDiscoverySelected}
              title={
                isDiscoverySelected && !item.link.includes('/keyword_discovery/')
                  ? t('Add the domain to access this tab')
                  : ''
              }
            >
              {item.label}
            </Tabs.Tab>
          </Link>
        ))}
      </Tabs.List>
    </Tabs>
  );
};

export default DomainTabs;
