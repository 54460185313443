import { Box } from '@mantine/core';
import { WordCloudChart } from 'Pages/KeywordDiscovery/components/WordCloud/wordCloudChart';
import { WordCloudTable } from 'Pages/KeywordDiscovery/components/WordCloud/wordCloudTable';
import reusableStyles from 'css/reusable-styles.module.scss';

const WordCloud = ({ switchTab, country }) => {
  return (
    <div>
      <Box p="md" my="lg" className={reusableStyles.paper}>
        <WordCloudChart country={country} />
      </Box>
      <WordCloudTable switchTab={switchTab} country={country} />
    </div>
  );
};

export default WordCloud;
