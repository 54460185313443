import { Component } from 'react';
import { connect } from 'react-redux';
import { withApollo } from '@apollo/client/react/hoc';
import compose from 'lodash/flowRight';
import { Field } from 'redux-form';
import toFormField from 'Components/Forms/toFormField';
import SpecificFilterSelector from 'Selectors/SpecificFilterSelector';
import { FilterAttribute } from 'Types/Filter';
import type { DomainsFilter } from 'Types/Filter';
import Validator from 'Utilities/validation';
import LandingPagesInput from './LandingPagesInput';

type Props = {
  domains: string[];
};
const LandingPagesField = toFormField(LandingPagesInput);

class LandingPagesEditor extends Component<Props> {
  render() {
    return (
      <div>
        <Field
          name="value"
          domains={this.props.domains}
          component={LandingPagesField}
          validate={Validator.array}
        />
      </div>
    );
  }
}

const domainsSelector = SpecificFilterSelector(FilterAttribute.DOMAINS);

const mapStateToProps = (state) => {
  const domainsFilter: DomainsFilter = domainsSelector(state) as any;
  return {
    domains: domainsFilter.value,
  };
};

export default compose(connect(mapStateToProps), withApollo)(LandingPagesEditor);
