import { Component } from 'react';
import { connect } from 'react-redux';
import { hideModal } from 'Actions/ModalAction';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { t } from 'Utilities/i18n';
import CompetitorsRanksReportForm from './CompetitorsRanksReportForm';
import './competitors-ranks-report.scss';

type Props = {
  hideModal: (...args: Array<any>) => any;
  domainId: string;
  lastState: Record<string, any>;
  submitOnInit: boolean;
};

class CompetitorsRanksReport extends Component<Props> {
  handleClose = () => {
    this.props.hideModal();
  };

  render() {
    const { lastState, submitOnInit, domainId } = this.props;
    return (
      <ModalBorder
        className="competitors-ranks-report"
        title={t('Download Report')}
        onClose={this.handleClose}
      >
        <CompetitorsRanksReportForm
          onSubmit={this.handleClose}
          domainId={domainId}
          initialState={lastState}
          submitOnInit={submitOnInit}
          onClose={this.handleClose}
        />
      </ModalBorder>
    );
  }
}

export default connect(null, {
  hideModal,
})(CompetitorsRanksReport);
