import { useGroupsListQuery, useQuickNavigationDataQuery } from 'Ghql';

export const useRefetchGroupData = () => {
  const { refetch: refetchGroupIds } = useGroupsListQuery({ skip: true });
  const { refetch: refetchQuickNavigationData } = useQuickNavigationDataQuery({ skip: true });
  const refetchGroupData = async () => {
    await refetchQuickNavigationData();
    const data = await refetchGroupIds();
    return data;
  };
  return refetchGroupData;
};
