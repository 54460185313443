import { AccSelect, SelectItem } from 'Components/AccSelect';
import { useSubDomainFilterData } from '../support/hooks';

type Props = {
  input: {
    value: string;
    onChange: (val: string | null) => void;
  };
};

export const SubdomainFieldInput = (props: Props) => {
  const { loading, data } = useSubDomainFilterData();

  const options = data?.domainsList?.[0]?.subdomains?.map((e) => ({
    label: e,
    code: e,
    value: e,
  })) as SelectItem<string>[];

  const onChange = (val: string | null) => {
    props.input?.onChange(val);
  };

  return (
    <AccSelect
      value={props.input?.value}
      options={options}
      onChange={onChange}
      disabled={loading}
      clearable={false}
      inputMaxWidth={570}
      creatable={false}
      autoFocus
      mb="sm"
      limit={50}
    />
  );
};
