import NoScrapeOnCompareDateCell from 'Components/AccuTable/CellRenderer/helpers/NoScrapeOnCompareDateCell';
import { SEARCH_TYPE_NAVER, SearchEngineIds } from 'Components/Modal/Content/AddSearchEngine/types';
import ValueIndicator from 'Components/ValueIndicator/value-indicator';
import Cell from './HelperComponents/AccuCell';

type Props = {
  value: {
    data: {
      searchVolumeValue: number;
      shareOfVoiceCpcValue: number;
      shareOfVoiceCpcCompare: number;
      validForSearchVolume: boolean;
      updatingKeyword: boolean;
      avgCostPerClickCurrency: string;
      searchEngineId: number;
      noScrapeOnCompareDate?: boolean;
    };
    loadingData: boolean;
  };
};

const ShareOfVoiceCPCChangeCell = (props: Props) => {
  const {
    value: { data },
  } = props;
  const {
    updatingKeyword,
    searchVolumeValue,
    validForSearchVolume,
    avgCostPerClickCurrency,
    noScrapeOnCompareDate,
  } = data;
  let { shareOfVoiceCpcValue, shareOfVoiceCpcCompare } = data;

  if (!validForSearchVolume) {
    return null;
  }

  if (updatingKeyword || searchVolumeValue === null) {
    return null;
  }

  if (data?.searchEngineId === SearchEngineIds[SEARCH_TYPE_NAVER]) {
    return null;
  }

  if (noScrapeOnCompareDate) {
    return <NoScrapeOnCompareDateCell />;
  }

  if (shareOfVoiceCpcValue === null) {
    shareOfVoiceCpcValue = 0;
  }

  if (shareOfVoiceCpcCompare === null) {
    shareOfVoiceCpcCompare = 0;
  }

  return (
    <Cell className={'accu-table-cell__share-of-voice-cpc-change'} rightAligned={true}>
      <ValueIndicator
        beforeValue={shareOfVoiceCpcCompare}
        afterValue={shareOfVoiceCpcValue}
        currency={avgCostPerClickCurrency}
      />
    </Cell>
  );
};

export default ShareOfVoiceCPCChangeCell;
