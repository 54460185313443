import { Map as SerpMapIcon } from 'Pages/Keywords/Table/Icon/serpIcons';

export const getFaviconByDomain = (
  compDomain: string,
  size: string | number = '16',
  emptyOnNoFavicon: boolean = false,
): string => {
  return !compDomain || (typeof compDomain === 'string' && compDomain?.includes?.('.')) // somehow a number gets passed to this sometimes?!
    ? `https://app.accuranker.com/screenshots/favicon?url=${compDomain}&size=${size}&emptyOnNoFavicon=${emptyOnNoFavicon}`
    : SerpMapIcon; // This means we have a "domain" without a "." - typically this would mean it is a local result, thus we show map
};
