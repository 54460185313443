import { usePublicOverviewSearchIntentQuery } from 'Ghql';
import { SearchIntentChartContent } from 'Pages/Keywords/Overview/components/SearchIntent/SearchIntentChartContent';
import { DomainsFilter, FilterBase } from 'Types/Filter';

type PublicSearchIntentChartProps = {
  viewkey: string;
  domainSlug: string;
  filters: FilterBase[];
  domainId: string | undefined;
};

export const PublicSearchIntentChart = ({
  viewkey,
  domainSlug,
  filters,
  domainId,
}: PublicSearchIntentChartProps) => {
  const domainsFilter: DomainsFilter = {
    attribute: 'domains',
    type: 'list',
    comparison: 'contains',
    value: [domainId || ''],
  };
  const { data, loading } = usePublicOverviewSearchIntentQuery({
    variables: { filters: [...filters, domainsFilter], viewkey, domainSlug },
    skip: !domainId,
  });

  const searchIntentNode = data?.graphs?.publicOverviewSearchIntent;

  return (
    <SearchIntentChartContent
      minHeight={406}
      loading={loading}
      searchIntentNode={searchIntentNode}
      includeKebabMenu={false}
      setFilterFromChart={false}
      boardControls={{
        removeChart: null,
        disabledDnd: true,
      }}
    />
  );
};
