import { Flex, FlexProps } from '@mantine/core';
import AccTooltip from 'Components/AccTooltip/AccTooltip';

type Props = {
  children: JSX.Element;
  tooltip?: string | React.ReactNode;
  /** Apply mantine flexProps to the container immediately surrounding the button  */
  tooltipWrapperProps?: FlexProps;
};
const ConditionalTooltipWrapper = (props: Props) => {
  const { children, tooltip, tooltipWrapperProps } = props;

  return tooltip ? (
    <AccTooltip tooltip={tooltip}>
      <Flex align="center" justify="center" w="fit-content" {...tooltipWrapperProps}>
        {children}
      </Flex>
    </AccTooltip>
  ) : (
    children
  );
};

export default ConditionalTooltipWrapper;
