import { KeyboardEvent, useState } from 'react';
import { Button, ScrollArea, useMantineTheme } from '@mantine/core';
import { Spotlight, SpotlightActionGroupData, spotlight } from '@mantine/spotlight';
import { IconPlus, IconSearch } from '@tabler/icons-react';
import cn from 'classnames';
import startsWith from 'lodash/startsWith';
import AccButton from 'Components/AccButton/AccButton';
import AccessControl from 'Components/AccessControl';
import {
  DomainNode,
  useDomainInfoQuery,
  useLinkedAccountsOrganizationsQuery,
  useQuickNavigationDataQuery,
} from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import { useDomainId } from 'Hooks/data/domain/useQueryDomainInfo';
import { useIsAccurankerUser } from 'Hooks/data/organization/useOrganizationInfo';
import { useHasAccuApiAccess } from 'Hooks/data/user/useHasAccuApiAccess';
import { useUser } from 'Hooks/data/user/useUser';
import { keywordsPrefixes } from 'Pages/Layout/DashboardTemplate/DomainTabs/DomainTabs';
import { useActiveNavKey } from 'Pages/Layout/DashboardTemplate/SideNavbar/support/useActiveNavKey';
import { EventName, useMixpanel } from 'Utilities/Analytics/mixpanel';
import { getDomainName } from 'Utilities/Common/domain';
import { t } from 'Utilities/i18n';
import { useMousetrap } from 'Utilities/mousetrap';
import { notEmpty } from 'Utilities/underdash';
import { useMapDataToActions } from './support/helpers';
import styles from './quick-navigation.module.scss';

const MAX_GROUP_CATEGORY_COUNT = 5;

const getFilteredActions = (query: string, actions: SpotlightActionGroupData[]) => {
  const filteredActions = actions.map((actionGroup) => ({
    group: actionGroup.group,
    actions: actionGroup.actions
      .filter(
        (act) =>
          ('label' in act && act.label?.toLowerCase().includes(query.toLowerCase())) ||
          ('description' in act && act.description?.toLowerCase().includes(query.toLowerCase())) ||
          ('id' in act && act.id?.toString().replace(/^domain|^client/, '') === query),
      )
      .slice(0, MAX_GROUP_CATEGORY_COUNT),
  }));

  return filteredActions;
};

type Domain = Pick<DomainNode, 'displayName' | 'domain'> & { id: number };

export type QuickNavigationClient = {
  clientId: number;
  clientName: string;
  domains: Domain[];
  organizationId: number;
};

type Props = {
  collapsed?: boolean;
};

const useAccurankerUserShortcuts = () => {
  const domainId = useDomainId();
  const user = useUser();
  const isAccuRankerUser = useIsAccurankerUser();
  const { showModal } = useModal();
  const isAccuRankerOrImpersonating =
    isAccuRankerUser || user.isImpersonating || user?.organization?.id?.toString() === '1';

  const { data } = useDomainInfoQuery({
    variables: { id: domainId as string },
    skip: !domainId || !isAccuRankerOrImpersonating,
  });

  useMousetrap({
    combination: 'alt+shift+o',
    description: t('Ok'),
    disabled: !isAccuRankerOrImpersonating,
    callback: () => {
      if (isAccuRankerOrImpersonating) {
        const domainName = data?.domain ? getDomainName(data?.domain) : '';
        showModal({
          modalType: 'CopyToClipboard',
          modalTheme: 'light',
          modalProps: {
            confirmButtonLabel: t('Copy'),
            value: `Organization: ${user.organization?.name} #${user.organization?.id} \nDomain: ${domainName}  #${domainId}\nLink: ${window.location.href}`,
          },
        });
      }
    },
  });
};

const QuickNavigation = (props: Props) => {
  const { collapsed } = props;
  const [loadData, setLoadData] = useState(false);
  const [query, setQuery] = useState('');

  const { showModal } = useModal();

  const hasAccuApiAccess = useHasAccuApiAccess();

  const trackEvent = useMixpanel();

  useAccurankerUserShortcuts();

  const activeNavKey = useActiveNavKey();
  const domainPageTo = keywordsPrefixes.some((prefix) => startsWith(activeNavKey, prefix))
    ? activeNavKey
    : undefined;

  const {
    loading: loadingClients,
    data: clientsData,
    refetch,
  } = useQuickNavigationDataQuery({
    skip: !loadData,
  });
  const { loading: loadingAccounts, data: accountsData } = useLinkedAccountsOrganizationsQuery({
    variables: {
      searchQuery: '',
    },
    skip: !loadData,
  });

  const clients: QuickNavigationClient[] =
    clientsData?.user?.quickNavigationData?.filter(notEmpty) || [];

  const linkedOrganizations = accountsData?.linkedOrganizations?.filter(notEmpty);

  const actions = useMapDataToActions(
    loadingClients || loadingAccounts,
    clients,
    linkedOrganizations,
    domainPageTo,
    query,
  );

  const theme = useMantineTheme();

  // AccuApi users does not have access to quick navigation
  if (hasAccuApiAccess) return null;

  const Wrapper = ({ children }: { children: React.ReactNode }) => {
    return (
      <>
        {children}
        <div className={styles.spotlightFooter}>
          <AccessControl>
            <AccButton
              leftSection={<IconPlus size={14} />}
              h={28}
              ml="auto"
              variant="secondary"
              onClick={() => {
                trackEvent(EventName.AddDomainFromQuickNavigate);
                showModal({
                  modalType: 'BuildDomain',
                  modalTheme: 'light',
                });
                spotlight.close();
              }}
            >
              {t('Add Domain')}
            </AccButton>
          </AccessControl>
          <AccessControl withSuperuserPermission>
            <AccButton
              leftSection={<IconPlus size={14} />}
              h={28}
              variant="secondary"
              onClick={() => {
                trackEvent(EventName.AddGroupFromQuickNavigate);
                showModal({
                  modalType: 'AddGroup',
                  modalTheme: 'light',
                  modalProps: {
                    refetch,
                  },
                });
                spotlight.close();
              }}
            >
              {t('Add Group')}
            </AccButton>
          </AccessControl>
        </div>
      </>
    );
  };

  const filteredActions = getFilteredActions(query, actions);

  const onKeyDown = (e: KeyboardEvent<HTMLButtonElement>) => {
    // Open spotlight on letter or number key press
    const isLetterOrNumber = (/^[a-zA-Z0-9]$/).test(e.key);
    if (isLetterOrNumber) {
      spotlight.open();
    }
  };

  return (
    <>
      <Button
        classNames={{
          root: cn(styles.spotlightButton, { [styles.hidden]: collapsed }),
          inner: styles.spotlightButtonInner,
          label: styles.spotlightButtonLabel,
        }}
        onClick={() => spotlight.open()}
        onKeyDown={onKeyDown}
        fullWidth
        leftSection={<IconSearch size={18} />}
        rightSection={<div className={styles.spotLightButtonKbd}>Shift + /</div>}
        color="snorlax.5"
      >
        <div>{t('Search')}</div>
      </Button>
      <Spotlight.Root
        query={query}
        onQueryChange={setQuery}
        classNames={{
          search: styles.spotlightInput,
          action: styles.spotlightAction,
          content: styles.spotlightContent,
          actionsGroup: styles.actionsGroup,
          actionSection: styles.spotlightActionSection,
          actionBody: styles.spotlightActionBody,
          actionLabel: styles.spotlightActionLabel,
          actionDescription: styles.spotlightDescription,
        }}
        returnFocus={false}
        shortcut={['d', 'shift + /']}
        onSpotlightOpen={() => {
          trackEvent(EventName.OpenQuickNavigation);
          setLoadData(true);
        }}
        yOffset={'120px'}
        xOffset={'5vw'}
      >
        <Spotlight.Search
          placeholder={t('Search domains, groups and accounts...')}
          leftSection={<IconSearch size={16} />}
        />
        <Wrapper>
          <ScrollArea.Autosize
            //outher padding minus search and footer
            mah={'calc(100vh - (2 * 120px + 53px + 52px))'}
            type="auto"
            scrollbars="y"
            classNames={{ viewport: styles.scrollAreaViewPort }}
          >
            <Spotlight.ActionsList pb="xs">
              {filteredActions.map(
                (actionGroup) =>
                  !!actionGroup.actions.length && (
                    <Spotlight.ActionsGroup
                      fw={700}
                      tt={'uppercase'}
                      pt={'xs'}
                      pb={0}
                      pl={'sm'}
                      key={actionGroup.group}
                      label={actionGroup.group}
                      className={styles.spotlightActionGroup}
                    >
                      {actionGroup.actions.map((action) => (
                        <Spotlight.Action
                          key={action.id}
                          id={action.id}
                          label={action.label}
                          description={action.description}
                          leftSection={action.leftSection}
                          onClick={action.onClick}
                          className={styles.spotlightAction}
                          highlightQuery
                          highlightColor={theme.colors.gray[1]}
                          ml={'xxs'}
                        />
                      ))}
                    </Spotlight.ActionsGroup>
                  ),
              )}
            </Spotlight.ActionsList>
          </ScrollArea.Autosize>
        </Wrapper>
      </Spotlight.Root>
    </>
  );
};

export default QuickNavigation;
