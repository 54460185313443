import { TABLE_ICON_SIZE } from 'Components/AccuTable/constants';
import { Flag } from 'Components/Flag';
import Icon from 'Components/Icon/new';
import LocationIcon from 'icons/location-2.svg?inline';
import AccTooltip from '../../AccTooltip/AccTooltip';
import Cell from './HelperComponents/AccuCell';

type CountryLocale = {
  id: number;
  countryCode: string;
  locale: string;
  region: string;
};
type Props = {
  value: {
    data: {
      countrylocaleId: number;
      location: string;
    };
    loadingData: boolean;
  };
  countryLocales: CountryLocale[];
  compact?: boolean;
};

const CountryLocaleCell = (props: Props) => {
  const {
    value: { data, loadingData },
    countryLocales,
    compact,
  } = props;
  // if (loadingData) return <LoadingSpinner />;
  if (loadingData) return '';
  const { countrylocaleId, location } = data;
  let countryLocale;
  let countryLocaleTooltip;
  let locationToolTip;

  if (countrylocaleId) {
    countryLocale = countryLocales.find((e) => e.id === countrylocaleId);
    countryLocaleTooltip = `${countryLocale.region} (${countryLocale.locale})`;
    // https://accuranker.myjetbrains.com/youtrack/issue/ARR-1301
    locationToolTip = (location || '').concat(
      location?.includes(countryLocale.region) ? '' : `, ${countryLocale.region}`,
    );
  }

  return (
    <Cell className={'accu-table-cell__country-locale'}>
      {countryLocale && (
        <Icon tooltip={countryLocaleTooltip}>
          <Flag size="sm" mx={0} country={countryLocale.countryCode} fadedColors />
        </Icon>
      )}

      {compact && location && (
        <Icon tooltip={location} height={TABLE_ICON_SIZE} width={TABLE_ICON_SIZE}>
          <LocationIcon />
        </Icon>
      )}

      {location && !compact && (
        <AccTooltip tooltip={locationToolTip}>
          <span className="accu-table-cell__country-locale--text">{location}</span>
        </AccTooltip>
      )}
    </Cell>
  );
};
CountryLocaleCell.defaultProps = {
  countryLocales: [],
};
export default CountryLocaleCell;
