import {
  ApolloError,
  BaseMutationOptions,
  DocumentNode,
  useMutation as useApolloMutation,
} from '@apollo/client';
import isFunction from 'lodash/isFunction';
import * as helpers from './helpers';

type UseMutateOptions = {
  // mutation options to configure as in normal `useMutation`
  options?: BaseMutationOptions;
  // affect error extract logic
  isForm?: boolean;
  // function get custom {data, error} result,
  // used in case you have non standard query structure *(several queries for example)
  getResult?: (...args: Array<any>) => any;
  // set error message on network/form errors in case it didn't manage to find it
  defaultErrorMsg?: string;
  // by default all mutation has `input` key for variables,
  // in case it does work we have possibility to customize it
  variableKey?: string;
};

/**
 * Extension for `useMutation` (@apollo/client) Hook
 * Adding data/error extracting logic that are specific for normal request and form specific
 * @example:
 * ```js
 *    const [saveUser] = useMutate(MUTATION, {isForm: true})
 *    const onSave = (form: User) => saveUser(form)
 * ```
 * @docs https://www.apollographql.com/docs/react/data/mutations/
 */
export const useMutate = (
  mutation: DocumentNode | Function,
  { options, isForm, getResult, defaultErrorMsg, variableKey = 'input' }: UseMutateOptions = {},
) => {
  const [fetch, result] = isFunction(mutation)
    ? mutation(options)
    : useApolloMutation(mutation as any, options);
  const fetchData = async (variables: any, fetchOptions?: any, ...args: any[]) => {
    try {
      const res = await fetch(
        {
          ...fetchOptions,
          variables: {
            [variableKey]: variables,
          },
        },
        // TODO FixTSignore
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ...args,
      );
      return getResult ? getResult(result) : helpers.extractDataFromGraphqlResponse(res, isForm);
    } catch (e: unknown) {
      if (e instanceof ApolloError) {
        return helpers.extractServerErrorFromGhql(e, {
          defaultErrorMsg,
          isForm,
        });
      }
    }
  };

  return [
    fetchData,
    {
      ...result,
      errors:
        helpers.extractErrorFromGhql(result, isForm) ||
        (result.error && !isForm && defaultErrorMsg),
    },
    // TODO FixTSignore
  ] as any;
};
