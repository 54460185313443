import { useMemo } from 'react';
import cn from 'classnames';
import { AccFastIcon } from 'Components/AccFastIcon';
import { getFlagIcon } from './FlagTypes';
import classes from './flag.module.scss';

const getIconSize = (size?: IconSize) => {
  if (size === 'sm' || !size) {
    return 18;
  }
  if (size === 'md') {
    return 24;
  }
  return size;
};

type IconSize = 'sm' | 'md' | number;

// eslint-disable-next-line import/no-unused-modules
export type FlagComponentProps = {
  country: string | undefined;
  /** Prevent flags with white background color blend with the background */
  withOutline?: boolean;
  /** Make the flag colors less vivid and slightly grayscaled */
  fadedColors?: boolean;
  /** sm, md, lg or number  */
  size?: IconSize;
  /** number to set border-radius in px */
  radius?: number;
  className?: string;
  mr?: React.CSSProperties['marginRight'];
  ml?: React.CSSProperties['marginLeft'];
  mx?: React.CSSProperties['marginBlock'];
  id?: string;
  style?: React.CSSProperties;
};

/** Insert flag based on language code.
 *
 * Use Mantine props to set the size, margin, etc.
 * @example
 * <Flag country="DK" size="sm" radius={6} mr={8} />
 */
export const Flag = (props: FlagComponentProps) => {
  const {
    country,
    radius = 2,
    size = 'sm',
    withOutline = true,
    fadedColors = false,
    className,
    ...rest
  } = props;
  const backgroundImage = useMemo(() => {
    return getFlagIcon(country);
  }, [country]);
  const resultSize = getIconSize(size);

  const boxProps = {
    className: cn(className, {
      [classes.outlined]: withOutline,
      [classes.faded]: fadedColors,
    }),
    ...rest,
  };

  return (
    <AccFastIcon
      {...boxProps}
      radius={radius}
      size={resultSize}
      src={backgroundImage}
      fit={'cover'}
      aspectRatio="17 / 12"
    />
  );
};
