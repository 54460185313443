import { SelectItem } from 'Components/AccSelect';
import { ImportColumnInput } from 'Ghql';
import { rankType } from 'Pages/Import/importer_helpers';
import { t, tct } from 'Utilities/i18n/index';
import { ImportClients, UploadImportColumn, UploadImportMappableColumn } from '../types';

export const groupFieldKey = 'group';

const columnFieldPrefix = 'col';

export const toColumnFieldKey = (arg: UploadImportColumn) => {
  return `${columnFieldPrefix}-${arg.id}`;
};

export const getFieldValueIsNotUnique = (
  fieldValue: string,
  columnFields: Record<string, string | undefined>,
  thisColumn: UploadImportColumn,
  columns: UploadImportColumn[],
  mappableColumnItems: SelectItem<string>[],
): string | undefined => {
  const existingColumnField = Object.entries(columnFields).find(
    ([key, value]) =>
      value !== undefined && value === fieldValue && key !== toColumnFieldKey(thisColumn),
  );

  if (!existingColumnField) {
    return;
  }

  const existingId = parseInt(existingColumnField[0].split('-')[1], 10);
  const existingColumn = columns.find(
    (column) => column.id !== thisColumn.id && column.id === existingId,
  );

  const name = mappableColumnItems.find(
    (mappableColumnItem) => mappableColumnItem.value === fieldValue,
  )?.label;

  return tct('\'[name]\' is being used by column [id] ([column])', {
    name,
    id: existingColumn?.id,
    column: existingColumn?.name,
  });
};

export const filterColumnFields = (allFields: Record<string, string | undefined>) =>
  Object.fromEntries(
    Object.entries(allFields).filter(
      ([fieldKey, fieldValue]) => fieldValue && fieldKey.startsWith(columnFieldPrefix),
    ),
  );

export const getGroupValueMissing = (allFields: Record<string, string | undefined>) => {
  const groupValueExists = Object.entries(allFields).find(
    ([fieldKey, fieldValue]) => fieldKey === groupFieldKey && fieldValue !== undefined,
  );
  if (!groupValueExists) {
    return t('Please select a group for your import.');
  }
};

const toRequiredFieldNamesByValues = (
  mappableColumns: UploadImportMappableColumn[],
  type: string,
) =>
  mappableColumns
    .filter((column) => column.type === type && column.required === true)
    .reduce((acc: Record<string, string>, column) => {
      acc[column.id.toString()] = column.name;
      return acc;
    }, {});

const toRankValues = (mappableColumns: UploadImportMappableColumn[]) =>
  mappableColumns
    .filter((mappableColumn) => mappableColumn.type === rankType)
    .map((mappableColumn) => mappableColumn.id.toString());

const includesRankFieldValue = (
  columnFields: Record<string, string | undefined>,
  rankValues: string[],
) => Boolean(Object.values(columnFields).find((value) => value && rankValues.includes(value)));

export const getMissingRequiredFields = (
  mappableColumns: UploadImportMappableColumn[],
  columnFields: Record<string, string | undefined>,
  type: string,
): string | undefined => {
  if (type === rankType) {
    const rankValues = toRankValues(mappableColumns);
    if (!includesRankFieldValue(columnFields, rankValues)) {
      return;
    }
  }

  const requiredNamesByValues = toRequiredFieldNamesByValues(mappableColumns, type);

  const missingRequiredValues = Object.keys(requiredNamesByValues).filter(
    (requiredValue) =>
      !Object.values(columnFields).find((fieldValue) => fieldValue === requiredValue),
  );

  if (missingRequiredValues.length > 0) {
    const missingRequiredNames = missingRequiredValues
      .map((missingValue) => requiredNamesByValues[missingValue])
      .join(', ');

    return tct('Missing required fields for [type] import: [missingRequiredNames]', {
      type: type === rankType ? t('history') : t('keyword'),
      missingRequiredNames,
    });
  }
};

export const compareColumnFieldKeys = (a: string, b: string) =>
  parseInt(a.split('-')[1], 10) - parseInt(b.split('-')[1], 10);

export const getMappableColumnByFieldKey = (
  mappableColumns: UploadImportMappableColumn[],
  columnFields: Record<string, string | undefined>,
) =>
  mappableColumns.reduce((acc: Record<string, UploadImportMappableColumn>, mappableColumn) => {
    const fieldKey = Object.entries(columnFields).find(
      ([, fieldValue]) => fieldValue === mappableColumn.id.toString(),
    )?.[0];
    if (!fieldKey) {
      return acc;
    }
    acc[fieldKey] = mappableColumn;
    return acc;
  }, {});

export const toExamplesLength = (columns: UploadImportColumn[]) =>
  Math.max(...columns.map((x) => x.examples.length));

export const toDefaultColumnValues = (columns: UploadImportColumn[]) =>
  columns.reduce((acc: Record<string, string | undefined>, column) => {
    const key = toColumnFieldKey(column);
    const value = column.mappedColumnId?.toString();
    acc[key] = value;
    return acc;
  }, {});

export const toGroupSelectItems = (clients: ImportClients[]): SelectItem<string>[] =>
  clients
    .filter((client) => !client.isDemoClient)
    .map((client) => ({
      value: client.id,
      label: client.name,
    }));

export const toMappableColumnSelectItems = (
  mappableColumns: UploadImportMappableColumn[],
): SelectItem<string>[] =>
  mappableColumns.map((mappableColumn) => ({
    value: mappableColumn.id.toString(),
    label: mappableColumn.name,
  }));

export const getMappedColumns = (
  columns: UploadImportColumn[],
  allFields: Record<string, string | undefined>,
) =>
  columns.map((column) => {
    const fieldKey = toColumnFieldKey(column);
    const fieldValue = allFields[fieldKey];
    let mappedColumnId: number | null = null;
    if (fieldValue) {
      mappedColumnId = parseInt(fieldValue, 10);
    }
    return { ...column, mappedColumnId };
  });

export const toMappedInputColumns = (mappedColumns: UploadImportColumn[]): ImportColumnInput[] => {
  return mappedColumns.map((mappedColumn) => ({
    id: mappedColumn.id,
    mappedColumnId: mappedColumn.mappedColumnId,
    name: mappedColumn.name,
  }));
};
