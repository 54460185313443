import React from 'react';
import TableEmptyState from 'Components/TableEmptyState';
import useUserPermission from 'Hooks/useUserPermission';
import { t } from 'Utilities/i18n';
import { useModal } from '../../../../../Hooks/base/useModal';

export const EditModeEmptyContent = ({
  activeSearch,
  resetSearch,
}: {
  resetSearch?: Function;
  activeSearch?: string;
}) => {
  const { showModal } = useModal();
  const { userHasWriteAccess } = useUserPermission();
  const onCreateTagClick = () =>
    showModal({
      modalType: 'SelectTagType',
      modalTheme: 'light',
    });
  return (
    <TableEmptyState
      list={[]}
      title={t('No tags or folders found')}
      subTitle={
        activeSearch
          ? t('We found no tags or folders to show for specified search.')
          : t('We found no tags or folders to show for this domain.')
      }
      filtersPossible={true}
      noDataWithFiltersTitle={t('No tags or folders found')}
      noDataWithFiltersSubTitle={t(
        'We found no tags or folders to show, this could be due to your active filters. Clear your filters and try again.',
      )}
      onBtnClick={activeSearch ? resetSearch : userHasWriteAccess ? onCreateTagClick : null}
      btnTitle={activeSearch ? t('Clear Search') : t('Create Tag')}
      fullWidth
    />
  );
};
