import React, { MutableRefObject } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HCMore from 'highcharts/highcharts-more';
import HighchartsStoked from 'highcharts/highstock';
import HighchartsWordcloud from 'highcharts/modules/wordcloud';
import isFunction from 'lodash/isFunction';
import noop from 'lodash/noop';
import { t } from 'Utilities/i18n';

HCMore(Highcharts);
HighchartsWordcloud(Highcharts);
HighchartsStoked.AST.allowedAttributes.push(
  ...[
    'data-note-id',
    'data-note-control',
    'data-note-tooltip-container',
    'data-note-date',
    'data-note-text',
    'data-note-count',
    'data-note-list',
    'data-note-keywords-count',
  ],
);

HighchartsStoked.setOptions({
  chart: { style: { fontFamily: 'Open Sans' } },
  lang: {
    downloadCSV: t('Download CSV'),
    downloadJPEG: t('Download JPEG'),
    downloadPDF: t('Download PDF document'),
    downloadPNG: t('Download PNG image'),
    downloadSVG: t('Download SVG vector image'),
    downloadXLS: t('Download XLS'),
    drillUpText: t('Back to {series.name}'),
    noData: t('No data to display'),
    printChart: t('Print chart'),
    loading: t('Loading…'),
    rangeSelectorZoom: '',
    rangeSelectorFrom: '',
    rangeSelectorTo: '»',
  } as any,
});

interface BaseChartProps {
  config: Highcharts.Options;
  // If true, ref will be returned as an object with getChart() function, as it was before
  // for simple chart return chart
  legacy?: boolean;
}
interface ReactHighchartsChartProps
  extends Omit<HighchartsReact.Props, 'options'>,
    BaseChartProps {}

const getIsRef = (ref: unknown): ref is MutableRefObject<unknown> =>
  (ref as object)?.hasOwnProperty('current');

const buildRefCallback = (ref: unknown, legacy?: boolean) => {
  return ref
    ? (chart) => {
        const resultRef = legacy ? { getChart: () => chart } : chart;
        if (getIsRef(ref)) {
          ref.current = resultRef; // useRef based refs
        } else if (isFunction(ref)) {
          ref(resultRef); // class based refs
        }
      }
    : noop;
};

export const ReactHighchartsChart = React.forwardRef<
  Highcharts.Chart | null,
  ReactHighchartsChartProps
>(({ config, legacy, ...props }: ReactHighchartsChartProps, ref) => {
  return (
    <HighchartsReact
      highcharts={Highcharts}
      {...props}
      options={config}
      callback={buildRefCallback(ref, legacy)}
      allowChartUpdate={false}
    />
  );
});
ReactHighchartsChart.displayName = 'ReactHighchartsChart';

interface ReactStockedChartProps extends Omit<HighchartsReact.Props, 'options'>, BaseChartProps {}

export const ReactStockedChart = React.forwardRef<Highcharts.Chart | null, ReactStockedChartProps>(
  ({ config, legacy, ...props }: ReactStockedChartProps, ref) => {
    return (
      <HighchartsReact
        highcharts={HighchartsStoked}
        options={config}
        {...props}
        callback={buildRefCallback(ref, legacy)}
        constructorType="stockChart"
        allowChartUpdate={false}
      />
    );
  },
);
ReactStockedChart.displayName = 'ReactStockedChart';
