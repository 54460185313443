/* eslint-disable react/display-name */
import AccButton from 'Components/AccButton/AccButton';
import { t } from 'Utilities/i18n';
import { ShowSuggestionsState } from '../types';

export default ({ showSuggestions }: Pick<ShowSuggestionsState, 'showSuggestions'>) => {
  return (
    <AccButton link={'/import'} variant="secondary" fullWidth disabled={showSuggestions}>
      {t('Import from CSV')}
    </AccButton>
  );
};
