import { useEffect, useRef, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { Box, Flex, Pagination, Progress } from '@mantine/core';
import * as Sentry from '@sentry/react';
import { IconArrowLeft } from '@tabler/icons-react';
import isEmpty from 'lodash/isEmpty';
import AccButton from 'Components/AccButton/AccButton';
import Loader from 'Components/Loader';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import styles from 'Components/Modal/Wizard/ConnectToGA/PropertyBulkImport/styles.module.scss';
import AccText from 'Components/Text/AccText';
import AccTitle from 'Components/Title/AccTitle';
import {
  Add_Google_Search_Console_AccountDocument,
  Add_Google_Search_Console_AccountMutation,
  Add_Google_Search_Console_AccountMutationVariables,
  useGa_Connect_DomainsListQuery,
} from 'Ghql';
import toast from 'Hooks/useToast';
import { EventName, trackEventMixpanel } from 'Utilities/Analytics/mixpanel';
import { t } from 'Utilities/i18n/index';
import { useAvailableDomains } from '../../ConnectToGA/Property/utils';
import { formWidth } from '../../ConnectToGA/PropertyBulkImport';
import InputRow from '../../ConnectToGA/PropertyBulkImport/InputRow';
import { useGSCDomainOptions } from '../useGSCDomainOptions';

type DomainGSCPairs = {
  [accurankerDomain: string]: { gscSiteUrl: string };
};

type GSCBulkWebsitesProps = {
  connectionId: string;
  onSubmit: () => void;
  onBack: () => void;
  groupId?: string;
};

//maximum number of domains to query per page
const DOMAIN_LIMIT = 25;

// Height identical to InputRow and headerContainer
const LOADING_HEIGHT = 641;

const GSCBulkWebsites = ({ onBack, onSubmit, connectionId, groupId }: GSCBulkWebsitesProps) => {
  const [gscWebsiteOptions, gscWebsitesLoading] = useGSCDomainOptions(connectionId);

  //pagination state
  const [activePage, setPage] = useState(1);

  const offset = (activePage - 1) * DOMAIN_LIMIT;

  const [domainPairs, setDomainPairs] = useState<DomainGSCPairs>({});

  const [submitting, setSubmitting] = useState(false);

  const { data: domainsData, loading: domainsLoading } = useGa_Connect_DomainsListQuery({
    variables: {
      limit: DOMAIN_LIMIT,
      offset,
      onlyNotConnectedToGsc: true,
      clientId: Number(groupId),
    },
  });

  /** Total number of domains in this group */
  const totalDomains = domainsData?.domainsList?.[0]?.client.numberOfDomains || 0;

  const domains = useAvailableDomains(domainsData);

  //Try matching Accuranker domain URLs to the urls from GSC
  useEffect(() => {
    if (!domains?.length || domainsLoading || gscWebsitesLoading || !gscWebsiteOptions?.length) {
      return;
    }
    const newDomainMatches: DomainGSCPairs = {};
    for (let i = 0; i < domains.length; i++) {
      if ((domains[i].url as string) in domainPairs) {
        continue;
      }
      //const match = domains[i].url as string;
      const match = gscWebsiteOptions.find((option) => {
        return (option.value as string)?.includes(domains[i].url as string);
      });

      if (!match?.value) continue;
      newDomainMatches[domains[i].value as string] = { gscSiteUrl: match.value as string };
    }
    setDomainPairs((currentPairs) => ({ ...currentPairs, ...newDomainMatches }));
  }, [domains.length, gscWebsiteOptions.length]);

  //count how many domains failed to connect
  const failedDomainConnections = useRef<number>(0);

  const client = useApolloClient();

  const handleSubmit = async () => {
    const connectionPairs = Object.keys(domainPairs || {});
    if (connectionPairs.length === 0) {
      return;
    }
    setSubmitting(true);
    await Promise.all(
      connectionPairs.map(async (domainId) => {
        const { gscSiteUrl } = domainPairs[domainId];
        await client
          .mutate<
            Add_Google_Search_Console_AccountMutation,
            Add_Google_Search_Console_AccountMutationVariables
          >({
            mutation: Add_Google_Search_Console_AccountDocument,
            variables: {
              addGoogleSearchConsoleAccountInput: {
                connectionId,
                domainId,
                websiteUrl: gscSiteUrl,
              },
            },
          })
          .then((response) => {
            if (response.errors) {
              failedDomainConnections.current = failedDomainConnections.current + 1;
              console.error(response.errors);
              Sentry.captureException(response.errors);
            }
          });
      }),
    ).then(() => {
      const successfulConnects = connectionPairs.length - failedDomainConnections.current;
      if (failedDomainConnections.current > 0) {
        toast.error(
          t(
            `Failed to connect ${failedDomainConnections.current} domains to Google Search Console`,
          ),
        );
        if (successfulConnects > 0) {
          toast.success(
            t(`Successfully connected ${successfulConnects} domains to Google Search Console`),
          );
        }
      } else {
        toast.success(t('Connected all selected domains to Google Search Console'));
      }
      const trackingCtx = {
        bulk: true,
        connections: connectionPairs.join(','),
        total: connectionPairs.length,
        successfulConnects,
        failedConnects: failedDomainConnections.current,
      };
      trackEventMixpanel(EventName.ConnectToGSC, '', trackingCtx);
      //reset cache to show the correct connection status for domains in UI
      client.cache.reset();
      setSubmitting(false);
      onSubmit();
    });
  };

  const paginationPages =
    totalDomains % DOMAIN_LIMIT > 0 ? totalDomains / DOMAIN_LIMIT + 1 : totalDomains / DOMAIN_LIMIT;

  const loading = gscWebsitesLoading || domainsLoading;

  const handleUpdatePair = (gscDomain: string, domainId: string) => {
    if (gscDomain === '') {
      //remove the key-value pair of domainId
      setDomainPairs((currentPairs) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [domainId]: _, ...newPairs } = currentPairs;
        return newPairs;
      });
      return;
    }
    //add a value
    setDomainPairs((currentPairs) => ({
      ...currentPairs,
      [domainId]: { gscSiteUrl: gscDomain },
    }));
  };

  if (submitting) {
    return <Progress size="lg" animated value={100} />;
  }

  return (
    <>
      <Flex direction="column" gap={16} w={formWidth}>
        {domainsLoading || gscWebsitesLoading ? (
          <Loader
            style={{
              height: `${LOADING_HEIGHT}px`,
            }}
            noBackdrop={true}
            loadingText={t('Loading…')}
          />
        ) : domains?.length ? (
          <>
            <div className={styles.headerContainer}>
              <AccTitle type="h5">{t('Domain')}</AccTitle>
              <div />
              <AccTitle type="h5">{t('Property')}</AccTitle>
            </div>
            <Box className={styles.bodyContainer} w={formWidth} h={600} mr={-10} pr={10}>
              {domains.map((domain) => {
                return (
                  <InputRow
                    key={domain.value as string}
                    value={domainPairs[domain.value as string]?.gscSiteUrl || ''}
                    properties={gscWebsiteOptions}
                    onChange={(value) => handleUpdatePair(value, domain.value as string)}
                    domain={domain}
                    loading={domainsLoading || gscWebsitesLoading}
                    defaultValue={domainPairs[domain.value as string]?.gscSiteUrl || ''}
                  />
                );
              })}
            </Box>
          </>
        ) : (
          //Height identical to InputRow and headerContainer
          <Box h={LOADING_HEIGHT}>
            <AccText>{t('Could not find any domains')}</AccText>
          </Box>
        )}
      </Flex>
      <ModalFooter
        primaryButtonSlot={
          <AccButton
            variant="primary"
            onClick={handleSubmit}
            disabled={loading || isEmpty(domainPairs)}
          >
            {t('Connect domains')}
          </AccButton>
        }
        secondaryButtonSlot={
          <AccButton variant="tertiary" onClick={onBack} leftSection={<IconArrowLeft />}>
            {t('Group')}
          </AccButton>
        }
        textSlot={
          <>
            {totalDomains > DOMAIN_LIMIT && (
              <Pagination
                value={activePage}
                onChange={setPage}
                total={paginationPages}
                size="sm"
                disabled={gscWebsitesLoading}
              />
            )}
          </>
        }
      />
    </>
  );
};

export default GSCBulkWebsites;
