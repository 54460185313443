import { useEffect, useState } from 'react';
import AccButton from 'Components/AccButton/AccButton';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import AccText from 'Components/Text/AccText';
import { t } from 'Utilities/i18n';
import { redirectToRoot } from 'Utilities/underdash';

type Props = {
  autoRefreshAfter?: number;
};

const PermissionDeniedModal = ({ autoRefreshAfter = 30 }: Props) => {
  const [refreshAfter, setRefreshAfter] = useState(autoRefreshAfter);

  const handleReload = () => {
    redirectToRoot();
    window.location.reload();
  };

  useEffect(() => {
    const timerId = setInterval(() => {
      if (refreshAfter <= 0) {
        handleReload();
      }
      setRefreshAfter(refreshAfter - 1);
    }, 1000);
    //
    // clear interval on unmount
    return () => clearInterval(timerId);
  }, [refreshAfter]);

  return (
    <ModalBorder title={t('You no longer have access')}>
      <AccText>
        {t(
          'Your access to AccuRanker has been revoked by your organization. Please contact an organization admin to regain access.',
        )}
      </AccText>
      <AccText>{t('Click on "Refresh AccuRanker" button or wait for auto refresh.')}</AccText>
      <ModalFooter
        primaryButtonSlot={
          <AccButton type="submit" variant="primary" onClick={handleReload}>
            {t('Refresh AccuRanker')} ({refreshAfter})
          </AccButton>
        }
      />
    </ModalBorder>
  );
};

export default PermissionDeniedModal;
