import Icon from 'Components/Icon/new';
import { t } from 'Utilities/i18n';
import StarIcon from 'icons/star.svg?inline';

export const getItems = (): {
  id: string;
  value: boolean;
  label: JSX.Element;
  icon: (props: any) => JSX.Element;
  iconClassName?: string;
}[] => {
  return [
    {
      id: '1',
      value: true,
      label: (
        <div className="star-filter-option star-filter-option--active">
          <Icon>
            <StarIcon />
          </Icon>
          {t('Is starred')}
        </div>
      ),
      icon: StarIcon,
      iconClassName: 'star-filled',
    },
    {
      id: '0',
      value: false,
      label: (
        <div className="star-filter-option">
          <Icon>
            <StarIcon />
          </Icon>
          {t('Is not starred')}
        </div>
      ),
      icon: StarIcon,
    },
  ];
};
