/* eslint-disable import/no-unused-modules */
import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { RouteMatch, useRouteMatch } from 'Utilities/Router/hooks/useRouteMatch';

export type WithRouterHistory = {
  location: ReturnType<typeof useLocation>;
  push: ReturnType<typeof useNavigate>;
  goBack: () => void;
  goForward: () => void;
  replace: (string) => void;
};

export interface WithRouter {
  location: ReturnType<typeof useLocation>;
  match: RouteMatch;
  history: WithRouterHistory;
}

/**
 * For new functionality we should call useNavigate from react-router
 */
export const useHistory = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return {
    location,
    push: navigate,
    goBack: () => navigate(-1),
    goForward: () => navigate(1),
    replace: (url: string) => navigate(url, { replace: true }),
  };
};

// withRouter was deprecated and now we should use hooks
export const withRouter = <T extends object>(Component: React.ComponentType<T>) => {
  const Wrapper = (props) => {
    const location = useLocation();
    const match = useRouteMatch();
    const history = useHistory();
    const withHistoryProps: WithRouter = {
      location,
      match,
      history,
    };

    return <Component {...props} {...withHistoryProps} />;
  };
  const displayName = Component.displayName || Component.name || 'Component';
  Wrapper.displayName = `withRouterOverride(${displayName})`;
  return Wrapper;
};
