import { Progress } from '@mantine/core';
import Alert from 'Components/Alert';
import { t } from 'Utilities/i18n';
import { UploadImport } from '../types';
import styles from './validating.module.scss';

export const ImportValidationStatusValidating = (props: { uploadImport: UploadImport }) => {
  const { uploadImport } = props;
  const progress = uploadImport.progress / 100;
  return (
    <>
      <Alert>
        <p>{progress === 0 ? t('Initializing') : t('Validating')}</p>
      </Alert>
      <div className={styles.progress}>
        <Progress.Root size="xl">
          <Progress.Section color="blue" value={progress}>
            <Progress.Label>{`${progress.toString()}%`}</Progress.Label>
          </Progress.Section>
        </Progress.Root>
      </div>
    </>
  );
};
