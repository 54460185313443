import { useState } from 'react';
import { Box, Flex } from '@mantine/core';
import { DataTable } from 'Components/DataTable';
import AccText from 'Components/Text/AccText';
import AccTitle from 'Components/Title/AccTitle';
import { TableIDs } from 'Types/Table';
import { t } from 'Utilities/i18n';
import { DateRange } from './components/DateRange';
import { useFetchOveruse, useOveruseTableConfig } from './support/hooks';
import { OveruseRangeInput } from './support/types';
import './overuse-table.scss';

export const OveruseTable = () => {
  const [range, setRange] = useState<OveruseRangeInput>({ periodFrom: '', periodTo: '' });
  const [rangeList, setRangeList] = useState<OveruseRangeInput[]>([]);
  const { columns } = useOveruseTableConfig();
  const fetchDate = useFetchOveruse(range, setRange, rangeList, setRangeList);

  return (
    <>
      <div className="overuse-table-container">
        <AccTitle type="h3" mb="xs">
          {t('Daily keyword exceedings transactions')}
        </AccTitle>
        <AccText fw={400}>{t('Choose your billing period.')}</AccText>
        <Flex>
          <Box ml="auto">
            <DateRange range={range} rangeList={rangeList} setRange={setRange} />
          </Box>
        </Flex>
        <DataTable
          tableId={TableIDs.ACCOUNT_KEYWORD_OVERUSE}
          columns={columns}
          fetchData={fetchDate}
          pagination={false}
          emptyOptions={{ title: t('No Data'), subTitle: t('There are currently no keywords.') }}
          syncFilters={false}
        />
      </div>
    </>
  );
};
