import { Link } from 'react-router-dom';
import { Flex, Group } from '@mantine/core';
import linkWithFilters from 'Components/Filters/linkWithFilters';
import FormatNumber from 'Components/FormatNumber/new';
import Skeleton from 'Components/Skeleton';
import AccText from 'Components/Text/AccText';
import { DomainContextNode } from 'Pages/Domains/CardView/components/DomainCard/components/DomainContext';
import { DomainsFilter, FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import { KEYWORDS_FILTER_SET } from 'Types/FilterSet';
import { t } from 'Utilities/i18n';

const makeOverviewLink = (listOfDomains: string[]) => {
  if (!listOfDomains.length) {
    return '';
  }
  const domainsFilter: DomainsFilter = {
    attribute: FilterAttribute.DOMAINS,
    type: FilterValueType.LIST,
    comparison: FilterComparison.CONTAINS,
    value: listOfDomains,
  };
  return linkWithFilters({
    to: '/keywords/overview',
    overwriteFilters: [domainsFilter],
    filterSet: KEYWORDS_FILTER_SET,
    resetFilters: true,
  });
};

const TextSkeleton = () => (
  <Skeleton
    linesConfig={[
      {
        type: 'text',
        options: {
          width: '110px',
          height: '22px',
          borderRadius: '8px',
        },
      },
    ]}
  />
);

export const GroupStats = ({
  domains,
  numberOfKeywordsInGroup,
  selectedDomains,
  totalKeywordsForSelectedDomains,
  domainsInGroup,
  loading,
}: {
  /** Domains included on the current paginaged page */
  domains: DomainContextNode[];
  numberOfKeywordsInGroup: number;
  selectedDomains: string[];
  totalKeywordsForSelectedDomains: number;
  domainsInGroup: string[];
  loading: boolean;
}) => {
  const domainIds = domains.map((domain) => domain.id.toString());
  return (
    <Flex gap="lg" h={22} ml="sm">
      {loading ? (
        <>
          <TextSkeleton />
          <TextSkeleton />
        </>
      ) : (
        <>
          <Group gap={0} c="blue">
            {selectedDomains.length && (
              <Group gap={0}>
                <Link style={{ color: 'inherit' }} to={makeOverviewLink(domainIds)}>
                  <FormatNumber style="decimal" value={totalKeywordsForSelectedDomains} />
                </Link>
                <AccText c="gray.5">{'/'}</AccText>
              </Group>
            )}
            <Link style={{ color: 'inherit' }} to={makeOverviewLink(domainsInGroup)}>
              <FormatNumber style="decimal" value={numberOfKeywordsInGroup} />
            </Link>
            <AccText ml={4} c="gray.5">
              {t('keywords')}
            </AccText>
          </Group>
          <Group gap={0}>
            {selectedDomains.length && <AccText c="gray.5">{`${selectedDomains.length}/`}</AccText>}
            <AccText c="gray.5">{`${domainsInGroup.length || 0} ${t('domains')}`}</AccText>
          </Group>
        </>
      )}
    </Flex>
  );
};
