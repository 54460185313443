import { memo, useRef } from 'react';
import isEqual from 'lodash/isEqual';
import { ChartLoader } from 'Components/Chart/ChartLoader/ChartLoader';
import { ReactHighchartsChart } from 'Components/Chart/ReactHighcarts';
import Segment from 'Components/Segment';
import Watermark from 'Components/Watermark';
import { t } from 'Utilities/i18n';
import { FlippedChartConfigProps, useFlippedChartConfig } from './support/useFlippedChartConfig';
import './serp-flipped-chart.scss';

const FlippedChart = memo((props: FlippedChartConfigProps) => {
  const chartConfig = useFlippedChartConfig(props);
  const exportContainer = useRef<HTMLDivElement>(null);
  const noAvailableData = !props.loading && !chartConfig.series?.length;

  return (
    <div
      className="serp-flipped-chart chart-container chart-content"
      ref={exportContainer}
      style={{
        height: props.height,
      }}
    >
      {noAvailableData ? (
        <Segment hFull placeholder>
          {t('No SERP feature data for the selected filters')}
        </Segment>
      ) : (
        <>
          <Watermark big={props.watermarkBig || false} offset />
          <ChartLoader
            loaderType="flippedColumn"
            loading={props.loading}
            containerBoxProps={{ h: props.height }}
          >
            <ReactHighchartsChart config={chartConfig} />
          </ChartLoader>
        </>
      )}
    </div>
  );
}, isEqual);
FlippedChart.displayName = 'FlippedChart';

export default FlippedChart;
