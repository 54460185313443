import { useMantineTheme } from '@mantine/core';
import { CHART_HEIGHT } from 'Components/Chart/LineChart/support/constants';
import { ReactHighchartsChart } from 'Components/Chart/ReactHighcarts';
import { TOOLTIP_CHART_FORMATTING } from 'Components/Chart/support/constants';
import Loader from 'Components/Loader';
import { useKeydisWordCloudQuery } from 'Ghql';
import { useFilters } from 'Hooks';
import { useAddFilter } from 'Hooks/data/filter/setFilters';
import { FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import { FilterTrackingKey } from 'Utilities/Analytics/mixpanel';

export const WordCloudChart = ({ country }) => {
  const filters = useFilters();

  const addFilter = useAddFilter();

  const { data, loading } = useKeydisWordCloudQuery({
    variables: {
      country,
      filters,
    },
  });

  const wordCloudData = data?.keydisWordCloud?.words?.map((e) => ({
    name: e.word,
    weight: e.variations,
  }));

  const theme = useMantineTheme();

  if (loading) {
    return <Loader style={{ height: `${CHART_HEIGHT}px` }} noBackdrop={true} />;
  }

  return (
    <ReactHighchartsChart
      config={{
        series: [
          {
            colors: theme.colors.orange.slice(5, 10),
            animation: false,
            type: 'wordcloud',
            data: wordCloudData,
            name: 'hello',
            rotation: {
              from: 0,
              to: 90,
              orientations: 5,
            },
            minFontSize: 5,
            cursor: 'pointer',
            allowPointSelect: true,
            point: {
              events: {
                click(e) {
                  const name = e?.point?.name;
                  if (name) {
                    const newFilter: any = {
                      attribute: FilterAttribute.KEYWORD,
                      type: FilterValueType.STRING,
                      comparison: FilterComparison.CONTAINS,
                      value: name,
                    };
                    addFilter(newFilter, FilterTrackingKey.WordCloud);
                  }
                },
              },
            },
          },
        ],
        credits: {
          enabled: false,
        },
        tooltip: {
          ...TOOLTIP_CHART_FORMATTING,
          formatter() {
            const name = (this as any)?.point?.name;
            const weight = (this as any)?.point?.weight;

            return `<div> ${name}: <strong>${weight}</strong> </div>`;
          },
        },
        chart: {
          backgroundColor: 'transparent',
          animation: false,
          height: CHART_HEIGHT,
        },
        title: {
          text: '',
        },
      }}
    />
  );
};
