import { useCallback } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import { setSelectableDomains } from 'Actions/DomainsPageActions';
import { TableFetchDataCallBack } from 'Components/DataTable';
import { DomainsDocument, DomainsQuery, DomainsQueryVariables } from 'Ghql';
import { selectCurrentFilters } from 'Selectors/FilterSelector';
import { generateSelectId } from 'Utilities/generateSelectId';

// eslint-disable-next-line import/no-unused-modules
export const useFetchDomainsData = (): TableFetchDataCallBack => {
  const dispatch = useDispatch();
  const client = useApolloClient();
  const store = useStore();

  return useCallback(
    async (params) => {
      const filters = selectCurrentFilters(store.getState());
      const variables: DomainsQueryVariables = {
        filters,
        pagination: params.pagination,
        ordering: params.ordering,
      };
      const e = await client.query<DomainsQuery, DomainsQueryVariables>({
        query: DomainsDocument,
        variables,
        fetchPolicy: 'network-only',
      });
      const selectableIds: string[] = [];
      const data =
        e?.data?.tableDashboard?.domains?.map(({ id, clientId, ...rest }) => {
          const selectId = generateSelectId('domain', id, clientId);
          selectableIds.push(selectId);
          return {
            ...rest,
            clientId,
            id,
            selectId,
          };
        }) || [];
      const totalLength = e?.data?.tableDashboard?.pagination?.numResults ?? 0;
      dispatch(setSelectableDomains(selectableIds));
      return { data, length: totalLength };
    },
    [client, store],
  );
};
