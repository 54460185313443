const KEYWORD = 'keyword';
const SEARCH_TYPE = 'search_type';
const SEARCH_INTENT = 'search_intent';
const COUNTRY = 'country';
const LOCATION = 'location';
const RANK = 'rank';
const RANK_CHANGE = 'rank_change';
const CTR = 'ctr';
const DYNAMIC_CTR_CHANGE = 'dynamic_ctr_change';
const SEARCH_VOLUME = 'search_volume';

export const BasicColumnIDs = {
  KEYWORD,
  SEARCH_TYPE,
  COUNTRY,
  LOCATION,
  RANK,
  RANK_CHANGE,
  SEARCH_VOLUME,
} as const;

export const ColumnIDs = {
  ...BasicColumnIDs,
  SEARCH_INTENT,
  CTR,
  DYNAMIC_CTR_CHANGE,
} as const;
