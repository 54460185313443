import { Component } from 'react';
import { connect } from 'react-redux';
import { ApolloClient } from '@apollo/client';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import compose from 'lodash/flowRight';
import isEmpty from 'lodash/isEmpty';
import { showModal } from 'Actions/ModalAction';
import { SnakeCase } from 'Components/AccuTable/CellRenderer/helpers/serp-features';
import { COLUMN_CHART_HEIGHT } from 'Components/Chart/ColumnChart/support/constants';
import { SeriesDataItem } from 'Components/Chart/LineChart/support/types';
import { daysInPeriod } from 'Components/PeriodFilter/model';
import AccTitle from 'Components/Title/AccTitle';
import {
  ChartsFragment,
  KeywordsSerpHistoryDocument,
  KeywordsSerpHistoryQuery,
  SerpFeatureOverviewChartNodeNode,
  SerpsDataDocument,
} from 'Ghql';
import { RightRankChart } from 'Pages/Keywords/SERP/RightRankChart';
import * as Actions from 'Pages/Layout/ActionsMenu/Actions';
import ActionbarContainer from 'Pages/Layout/ActionsMenu/components/ActionbarContainer';
import queryDomainInfo from 'Pages/queryDomainInfo';
import SpecificFilterSelector from 'Selectors/SpecificFilterSelector';
import type { FilterBase } from 'Types/Filter';
import { FilterAttribute } from 'Types/Filter';
import { PrettifyUnion } from 'Types/Prettify';
import { t } from 'Utilities/i18n';
import { camelToSnakeCase } from 'Utilities/underdash';
import HelpGuideButton from '../../../Components/HelpGuideButton';
import Chart from './Chart';
import { ChartCategory } from './Chart/support/useChartConfig';
import ChartHistory from './ChartHistory';
import FlippedChart from './FlippedChart';
import SerpPieDistribution from './SerpPieDistribution';
import './keywords-serp.scss';

type Props = {
  client: ApolloClient<any>;
  filters: FilterBase[];
  domainId: string | null | undefined;
  period: number;
  data: {
    charts: ChartsFragment;
    loading: boolean;
    [key: string]: unknown;
  };
};

type State = {
  selectedSerp: SelectedSerp | 'all';
  loadingSerpHistory: boolean;
  serpHistoryData: SeriesDataItem[];
};

type SerpType = Exclude<PrettifyUnion<keyof SerpFeatureOverviewChartNodeNode>, '__typename'>;

type SelectedSerp = SnakeCase<SerpType>;

type OwnedStats = { owned: number; notOwned: number; total?: number };

type SortedFeatures = [SerpType, OwnedStats][];

type RemoveOwnedSuffix<T extends string> = T extends `${infer Prefix}Owned` ? Prefix : T;

export type SerpFeature = RemoveOwnedSuffix<Exclude<SerpType, 'keywordCount'>>;

export type SerpFeatureStats = Record<SerpFeature, OwnedStats>;

class KeywordsSerp extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedSerp: 'all',
      loadingSerpHistory: true,
      serpHistoryData: [],
    };
  }

  componentDidUpdate(prevProps: Readonly<any>) {
    const nextProps = this.props;
    const nextState = this.state;

    if (nextProps.filters !== prevProps.filters && !isEmpty(nextState.serpHistoryData)) {
      // TODO FixTSignore
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.querySerpHistory(nextState.selectedSerp, nextProps.filters);
    }

    if (nextProps?.data?.loading) return;
    if (!prevProps.data?.loading) return;
    if (!nextProps?.data?.charts?.serpFeatureOverview) return;
    if (nextProps?.data?.charts?.serpFeatureOverview?.length === 0) return;

    const countAll = (stats) => (stats.owned || 0) + (stats.notOwned || 0);

    const countOwned = (stats) => stats.owned || 0;

    const { serpFeatureStats } = this.processSerpFeatureData(
      nextProps.data?.charts?.serpFeatureOverview?.[0],
    );

    const sorted =
      serpFeatureStats &&
      (Object.entries(serpFeatureStats)
        .filter(([, stats]) => stats.owned)
        .sort(([, aStats], [, bStats]) => {
          const aOwned = countOwned(aStats) / countAll(aStats);
          const bOwned = countOwned(bStats) / countAll(bStats);
          return bOwned - aOwned;
        }) as SortedFeatures);

    if (sorted?.length && sorted?.length > 0 && sorted[0].length > 0) {
      this.handleSelectedSerpChange(sorted?.[0][0] ? camelToSnakeCase(sorted?.[0][0]) : undefined);
    }
  }

  handleSelectedSerpChange = (selectedSerp: SelectedSerp | undefined) => {
    if (!selectedSerp) return;
    this.querySerpHistory(selectedSerp, this.props.filters, true);
    this.setState({
      selectedSerp,
    });
  };
  querySerpHistory = (
    selectedSerp: SelectedSerp,
    filters: FilterBase[],
    chartShouldUpdate: boolean,
  ) => {
    const { client } = this.props;

    this.setState({
      loadingSerpHistory: true,
      serpHistoryData: !chartShouldUpdate ? this.state.serpHistoryData : [],
    });
    client
      .query<KeywordsSerpHistoryQuery>({
        query: KeywordsSerpHistoryDocument,
        fetchPolicy: 'network-only',
        variables: {
          filters,
          serp: selectedSerp,
        },
      })
      .then(({ data }) => {
        this.setState({
          loadingSerpHistory: false,
          serpHistoryData: data?.graphs?.serpHistory,
        });
      })
      .catch((error) => {
        this.setState({
          loadingSerpHistory: false,
        });
        throw error;
      });
  };
  renderActionButtons = () => {
    const actions: JSX.Element[] = [];

    actions.push(<Actions.UpgradeAction key="upgradePlan" alignRight={true} />);

    actions.push(
      <HelpGuideButton
        key="helpGuideLink"
        helpguideLink={'https://www.accuranker.com/help/serp-features/aggregated-serp-analysis'}
      />,
    );
    return actions;
  };

  processSerpFeatureData = (data: SerpFeatureOverviewChartNodeNode | null) => {
    if (!data) return {};
    const KEYWORD_COUNT = 'keywordCount';
    const TYPENAME = '__typename';
    const OWNED = 'Owned';
    let keywordCount: number | undefined;

    if (KEYWORD_COUNT in data) {
      keywordCount = data.keywordCount ?? 0;
    }

    const isNotSpecialFeature = (f: string) => f !== KEYWORD_COUNT && f !== TYPENAME;

    const reducePairwiseToObject = (accu: SerpFeatureStats, cur: [string, number]) => {
      const [feature, count]: [SelectedSerp, number] = cur as [SelectedSerp, number];
      let key: string = feature;

      const isOwned = feature.endsWith(OWNED);

      if (isOwned) {
        key = feature.substr(0, feature.length - OWNED.length);
      }

      const serpStats = key in accu ? accu[key] : {};
      serpStats[isOwned ? 'owned' : 'total'] = count;
      accu[key] = serpStats;

      return accu;
    };

    const serpFeatureStats: SerpFeatureStats = Object.entries(data)
      .filter(([feature]) => isNotSpecialFeature(feature))
      .filter((entry): entry is [string, number] => typeof entry[1] === 'number')
      .reduce(reducePairwiseToObject, {} as SerpFeatureStats);

    // Sets the "notOwned" and "owned" fields and removes "total" in the SERP list to match the OwnedStats type"
    (Object.keys(serpFeatureStats) as SerpFeature[]).forEach((key) => {
      if (serpFeatureStats[key].total) {
        serpFeatureStats[key].notOwned = serpFeatureStats[key]?.owned
          ? (serpFeatureStats[key].total as number) - serpFeatureStats[key]?.owned
          : serpFeatureStats[key].total || 0;

        serpFeatureStats[key].owned =
          (serpFeatureStats[key].total as number) - serpFeatureStats[key].notOwned;

        delete serpFeatureStats[key].total;
      }
    });

    return {
      keywordCount,
      serpFeatureStats,
    };
  };

  render() {
    const { data, domainId, period } = this.props;

    const { loadingSerpHistory, serpHistoryData, selectedSerp } = this.state;
    let serpFeatureOwnership: SerpFeatureOverviewChartNodeNode = {};

    if (
      Array.isArray(data?.charts?.serpFeatureOverview) &&
      data?.charts?.serpFeatureOverview?.length > 0 &&
      data?.charts?.serpFeatureOverview?.[0]
    ) {
      serpFeatureOwnership = data?.charts?.serpFeatureOverview?.[0];
    }

    const { keywordCount, serpFeatureStats } = this.processSerpFeatureData(serpFeatureOwnership);

    return (
      <>
        <ActionbarContainer>{this.renderActionButtons()}</ActionbarContainer>
        <div className="keywords-serp multi">
          <div className="keywords-serp-container wrap">
            <div className="keywords-serp-chart-container large">
              <AccTitle
                type="h3"
                helper={t('Total amount of keywords for each SERP feature vs how many you own.')}
              >
                {t('SERP Feature Ownership')}
              </AccTitle>
              <Chart
                height={COLUMN_CHART_HEIGHT - 20}
                data={data?.loading ? undefined : serpFeatureStats}
                keywordCount={keywordCount || 0}
                domainId={domainId || undefined}
                loading={data.loading}
                period={period}
                watermark
                watermarkBig
                onClick={(_, category: ChartCategory) => {
                  this.handleSelectedSerpChange(category.serp.id as SelectedSerp);
                }}
              />
            </div>
            <ChartHistory
              serpHistoryData={serpHistoryData}
              selectedSerp={selectedSerp}
              loadingSerpHistory={loadingSerpHistory}
            />
          </div>
          <div className="keywords-serp-container">
            <div style={{ width: '50%', flexGrow: 1 }}>
              <div className="keywords-serp-chart-container gap" style={{ width: '100%' }}>
                <AccTitle type="h3">{t('Owned SERP Feature Distribution')}</AccTitle>

                <SerpPieDistribution
                  onClick={(serp: SerpType) =>
                    serp && this.handleSelectedSerpChange(camelToSnakeCase(serp))
                  }
                  isLoading={!!data.loading}
                  serpFeatureData={data.loading ? undefined : serpFeatureStats}
                  keywordCount={keywordCount}
                />
              </div>
              <div className="keywords-serp-chart-container" style={{ width: '100%' }}>
                <AccTitle
                  type="h3"
                  helper={t(
                    'For some SERP features like video carousels or recipes, there will be multiple domains present. This chart shows your average "inside SERP feature" rank, for each SERP feature where this is possible.',
                  )}
                >
                  {t('Average rank inside SERP feature')}
                </AccTitle>
                <RightRankChart />
              </div>
            </div>
            <div className="keywords-serp-chart-container">
              <AccTitle type="h3">{t('Total SERP Feature Overview')}</AccTitle>
              <FlippedChart
                height={860}
                data={data.loading ? undefined : serpFeatureStats}
                keywordCount={keywordCount || 0}
                domainId={domainId || ''}
                loading={data.loading}
                period={period}
                watermark
                watermarkBig
                onClick={(_, category: ChartCategory) => {
                  this.handleSelectedSerpChange(category?.serp?.id as SelectedSerp);
                }}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const domainsFilterSelector = SpecificFilterSelector(FilterAttribute.DOMAINS);
const periodFilterSelector = SpecificFilterSelector(FilterAttribute.PERIOD);

const mapStateToProps = (state) => {
  const domainFilter = domainsFilterSelector(state);
  const periodFilter = periodFilterSelector(state);
  return {
    domainId: !!domainFilter && domainFilter.value.length === 1 ? domainFilter.value[0] : null,
    filters: state.filter.filterGroup.filters,
    period: periodFilter && daysInPeriod(periodFilter),
    featureAdvancedMetrics: state.user.organization.activePlan.featureAdvancedMetrics,
    isTrial: state.user.organization.activePlan.isTrial,
  };
};

export default compose(
  withApollo,
  connect(mapStateToProps, {
    showModal,
  }),
  graphql(SerpsDataDocument, {
    options: (props: Props) => {
      const { filters } = props;
      return {
        fetchPolicy: 'network-only',
        variables: {
          filters,
        },
      };
    },
  }),
  queryDomainInfo(),
)(KeywordsSerp);
