import { Component } from 'react';
import { connect } from 'react-redux';
import { gql } from '@apollo/client';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import FileSaver from 'file-saver';
import compose from 'lodash/flowRight';
import { Field, reduxForm } from 'redux-form';
import { showModal } from 'Actions/ModalAction';
import AccButton from 'Components/AccButton/AccButton';
import { DateField, Select } from 'Components/Forms/Fields';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import { throwNetworkError, throwSubmitErrors } from 'Utilities/errors';
import { t } from 'Utilities/i18n';
import { decodeBase64 } from 'Utilities/underdash';
import Validator from 'Utilities/validation';
import formStyles from 'css/layout/form-layout.module.scss';

type Props = {
  handleSubmit: (...args: Array<any>) => any;
  submitting: boolean;
  invalid: boolean;
  exportAdTracking: (...args: Array<any>) => any;
  hideModal: (...args: Array<any>) => any;
};
const channelOptions = [
  {
    value: 1,
    label: 'Google',
  },
  {
    value: 2,
    label: 'Facebook',
  },
];

class ExportAdTrackingForm extends Component<Props> {
  handleSubmit = (data) => {
    return this.props
      .exportAdTracking({
        variables: {
          input: data,
        },
      })
      .then(
        ({
          data: {
            exportAdTracking: { errors, file },
          },
        }) => {
          if (errors && errors.length) {
            throwSubmitErrors(errors);
          } else {
            const blob = new Blob([decodeBase64(file)], {
              type: 'data:text/csv;charset=utf-8',
            });
            FileSaver.saveAs(blob, 'accuranker_ad_trackking_export.csv');
          }
        },
        throwNetworkError,
      );
  };

  render() {
    const { handleSubmit, invalid, submitting } = this.props;
    return (
      <form className="move-domain-form" onSubmit={handleSubmit(this.handleSubmit)}>
        <div className={formStyles.formRow}>{t('Use this form to export ad tracking.')}</div>
        <div className={formStyles.formRow}>
          <div className="form-label required">{t('Start Date')}</div>
          <Field
            name="startDate"
            type="text"
            placeholder={t('Select Start Date')}
            component={DateField}
            validate={Validator.required}
          />
        </div>
        <div className={formStyles.formRow}>
          <div className="form-label required">{t('End Date')}</div>
          <Field
            name="endDate"
            type="text"
            placeholder={t('Select End Date')}
            component={DateField}
            validate={Validator.required}
          />
        </div>
        <div className={formStyles.formRow}>
          <div className="form-label required">{t('Channel')}</div>
          <Field
            name="channel"
            type="text"
            placeholder={t('Select Channel')}
            component={Select}
            options={channelOptions}
            validate={Validator.required}
            groupHidden
          />
        </div>
        <ModalFooter
          primaryButtonSlot={
            <AccButton
              disabled={invalid || submitting}
              type="submit"
              variant="primary"
              loading={submitting}
            >
              {t('Export')}
            </AccButton>
          }
          secondaryButtonSlot={
            <AccButton variant="tertiary" onClick={this.props.hideModal}>
              {t('Cancel')}
            </AccButton>
          }
        />
      </form>
    );
  }
}

const exportAdTrackingMutations = gql`
  mutation exportAdTracking($input: ExportAdTrackingInput!) {
    exportAdTracking(input: $input) {
      errors {
        messages
        field
      }
      file
    }
  }
`;
export default compose(
  withApollo,
  connect(null, {
    showModal,
  }),
  graphql(exportAdTrackingMutations, {
    name: 'exportAdTracking',
  }),
  reduxForm({
    form: 'MoveDomainForm',
  }),
)(ExportAdTrackingForm);
