import { t } from 'Utilities/i18n';
import { notEmpty } from 'Utilities/underdash';
import { PublicChartTypes } from '../publicCharts';

export const useSegmentedControlItems = ({
  includeAverageRankChart,
  includeRankingDistributionChart,
  includeSearchIntentChart,
}: {
  includeAverageRankChart: boolean;
  includeRankingDistributionChart: boolean;
  includeSearchIntentChart: boolean;
}): { label: JSX.Element; value: PublicChartTypes }[] =>
  [
    ...(includeAverageRankChart
      ? [
          {
            label: <>{t('Average Rank')}</>,
            value: PublicChartTypes.AVERAGE_RANK,
          },
        ]
      : []),
    ...(includeRankingDistributionChart
      ? [
          {
            label: <>{t('Ranking Distribution')}</>,
            value: PublicChartTypes.RANKING_DISTRIBUTION,
          },
        ]
      : []),
    ...(includeSearchIntentChart
      ? [
          {
            label: <>{t('Search Intent')}</>,
            value: PublicChartTypes.SEARCH_INTENT,
          },
        ]
      : []),
  ].filter(notEmpty);
