import { useState } from 'react';
import { ActionIcon, Divider, Group, Popover, Stack } from '@mantine/core';
import { IconHelp } from '@tabler/icons-react';
import moment from 'moment';
import { Field, Form } from 'Components/Fields';
import { FormSubmitButton } from 'Components/Fields/Form/FormSubmitButton';
import LoadingSpinner from 'Components/LoadingSpinner';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import AccText from 'Components/Text/AccText';
import AccTitle from 'Components/Title/AccTitle';
import {
  CreateInvoiceInput,
  SalesCreateInvoiceQuery,
  useCreateInvoiceMutationMutation,
  useSalesCreateInvoiceQuery,
} from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import toast from 'Hooks/useToast';
import { t, tct, tn } from 'Utilities/i18n';
import { notEmpty } from 'Utilities/underdash';
import Validator from 'Utilities/validation';
import styles from './createInvoice.module.scss';

type Props = {
  organizationId: string;
};

type CreateInvoiceFormValues = {
  maxKeywords: string;
  price: string;
  enableDiscount: boolean;
  message: string;
  plan: string | undefined;
  cycle: number | undefined;
  freePeriodBeforeStart: boolean;
  startDate: string;
};

type FoundPlan = NonNullable<SalesCreateInvoiceQuery['adminPublicPlans']>[number] | undefined;

const CreateInvoice = (props: Props) => {
  const { organizationId } = props;
  const { hideModal } = useModal();

  const [yearlyPayment, setYearlyPayment] = useState(false);

  const [isPaidPlan, setIsPaidPlan] = useState(true);

  const { loading, data } = useSalesCreateInvoiceQuery({
    variables: { organizationId },
    onCompleted: (returnData) => {
      const activePlan = returnData?.adminOrganization?.activePlan;
      const isPlanPaid =
        !!activePlan?.active &&
        !activePlan?.isFree &&
        !activePlan?.isTrial &&
        parseFloat(activePlan?.priceMonthlyUsd) > 0;
      setIsPaidPlan(isPlanPaid);
    },
  });

  const [createInvoiceMutation] = useCreateInvoiceMutationMutation();

  const { adminPublicPlans, billingCycles, adminOrganization } = data || {};

  const handleSubmit = ({
    maxKeywords,
    price,
    enableDiscount,
    message,
    plan,
    cycle,
    freePeriodBeforeStart,
    startDate,
  }: CreateInvoiceFormValues) => {
    //convert to isoFormat and strip out the time-stamp
    const freePeriodBeforePaymentDate = moment(startDate).format('YYYY-MM-DD');

    const billingCycle = billingCycles?.find((e) => e?.months === cycle);

    const billingCycleId = billingCycle?.id || '';

    const mutationInputVariables: Omit<CreateInvoiceInput, 'monthlyPrice' | 'yearlyPrice'> = {
      billingCycleId,
      enable10PercentDiscount: enableDiscount,
      freePeriodBeforePayment: freePeriodBeforeStart,
      freePeriodBeforePaymentDate,
      maxKeywords: parseFloat(maxKeywords),
      message,
      organizationId,
      planId: plan || '',
    };

    const pricingInput = yearlyPayment
      ? { yearlyPrice: parseFloat(price), monthlyPrice: 0 }
      : { monthlyPrice: parseFloat(price), yearlyPrice: 0 };

    createInvoiceMutation({
      variables: {
        input: { ...mutationInputVariables, ...pricingInput },
      },
    }).then(({ data: mutateData }) => {
      const errors = mutateData?.createInvoice?.errors?.filter(notEmpty);
      if (errors && errors.length) {
        toast.error(t('Could not create invoice.'));
        Validator.setResponseErrors(Validator.throwSubmissionError, errors);
      } else {
        toast.success(t('Invoice created.'));
        hideModal();
      }
    });
  };

  const planOptions =
    adminPublicPlans
      ?.map((e) => {
        if (e?.__typename) {
          return {
            value: e?.id,
            label: e?.name,
          };
        }
      })
      .filter(notEmpty) || [];

  const cycleOptions =
    billingCycles
      ?.map((e) => {
        if (e?.__typename) {
          return {
            value: e?.months,
            label: tn('%s month', '%s months', e?.months) as string,
          };
        }
      })
      .filter(notEmpty) || [];

  const getPrice = (
    foundPlan: FoundPlan,
    stringValue: string | undefined,
    enableDiscount: boolean,
  ) => {
    const value = Number(stringValue);
    if (!value || !foundPlan) return '';
    const monthlyPrice =
      adminOrganization?.paymentcontact?.currency === 'DKK'
        ? parseInt(foundPlan?.priceMonthlyDkk || '', 10)
        : parseInt(foundPlan?.priceMonthlyEur || '', 10);
    const yearlyPrice = enableDiscount
      ? adminOrganization?.paymentcontact?.currency === 'DKK'
        ? parseInt(foundPlan?.priceYearlyDkk || '', 10)
        : parseInt(foundPlan?.priceYearlyEur || '', 10)
      : monthlyPrice * 12;
    return value >= 12
      ? ((yearlyPrice * value) / 12).toFixed(2)
      : (monthlyPrice * value).toFixed(2);
  };

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  return (
    <ModalBorder
      title={`${t('Create Invoice')} - ${data?.adminOrganization?.name}`}
      onClose={hideModal}
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Form<CreateInvoiceFormValues>
          initialValues={{
            maxKeywords: '',
            enableDiscount: true,
            price: '',
            message: '',
            plan: undefined,
            cycle: undefined,
            freePeriodBeforeStart: false,
            startDate: '',
          }}
          keepDirtyOnReinitialize
          validateOnBlur={false}
          subscription={{ values: true }}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ form: { change: changeFieldValue, getFieldState } }) => {
            const freePeriodBeforeStart = getFieldState('freePeriodBeforeStart')?.value;
            return (
              <Stack justify="flex-start" gap="xl">
                <Group grow>
                  <Field.Select
                    required
                    name="plan"
                    label={t('Plan')}
                    options={planOptions}
                    validate={Validator.required}
                    customOnChange={(value, _option) => {
                      const cycleValue: number | undefined = getFieldState('cycle')?.value;
                      const discountEnabled: boolean =
                        getFieldState('enableDiscount')?.value || false;
                      const foundPlan = adminPublicPlans?.filter(notEmpty).find((plan) => {
                        return plan?.id === value;
                      });

                      changeFieldValue('maxKeywords', foundPlan?.maxKeywords?.toString());
                      changeFieldValue(
                        'price',
                        getPrice(foundPlan, cycleValue?.toString(), discountEnabled),
                      );
                    }}
                  />
                  <Field.Select
                    required
                    name="cycle"
                    label={t('Billing cycle')}
                    customOnChange={(value, _option) => {
                      const isPayingYearly = (value as number) >= 12;
                      if (isPayingYearly && !yearlyPayment) {
                        setYearlyPayment(true);
                      } else if (!isPayingYearly && yearlyPayment) {
                        setYearlyPayment(false);
                      }
                      const planValue: string | undefined = getFieldState('plan')?.value;
                      const discountEnabled: boolean =
                        getFieldState('enableDiscount')?.value || false;
                      const foundPlan = adminPublicPlans?.filter(notEmpty).find((plan) => {
                        return plan?.id === planValue;
                      });
                      changeFieldValue(
                        'price',
                        getPrice(foundPlan, value?.toString(), discountEnabled),
                      );
                    }}
                    options={cycleOptions}
                    validate={Validator.required}
                  />
                </Group>
                <Divider my="xs" color="gray.1" />
                <Stack justify="flex-start" gap="xl">
                  <AccTitle type="h5">
                    {t('Make plan changes')} (
                    {t('All prices are in %s', adminOrganization?.paymentcontact?.currency)})
                  </AccTitle>
                  <Group grow>
                    <Field.TextInput
                      required
                      name="maxKeywords"
                      label={t('Max keywords')}
                      validate={Validator.required}
                    />
                    <Field.TextInput
                      required={true}
                      name="price"
                      label={t('Price for the selected period')}
                      validate={Validator.required}
                    />
                  </Group>
                  <Group grow className={styles.discountGroup} gap="xs">
                    <Field.Checkbox
                      name="enableDiscount"
                      label={t('Enable 10% discount on yearly plans compared to monthly')}
                      customOnChange={(input) => {
                        const cycleValue: number | undefined = getFieldState('cycle')?.value;
                        const planValue: string | undefined = getFieldState('plan')?.value;
                        const discountEnabled: boolean = input.checked;

                        const foundPlan = adminPublicPlans?.filter(notEmpty).find((plan) => {
                          return plan?.id === planValue;
                        });

                        changeFieldValue('maxKeywords', foundPlan?.maxKeywords?.toString());
                        changeFieldValue(
                          'price',
                          getPrice(foundPlan, cycleValue?.toString(), discountEnabled),
                        );
                      }}
                    />

                    <Popover
                      width={400}
                      position="right"
                      withArrow
                      shadow="md"
                      withinPortal={false}
                    >
                      <Popover.Target>
                        <ActionIcon radius={'50%'} color="gray.6" size="sm" variant="transparent">
                          <IconHelp size={18} />
                        </ActionIcon>
                      </Popover.Target>
                      <Popover.Dropdown>
                        <AccText size="sm" className={styles.tooltipContent}>
                          {tct(
                            'The discount does not affect the price entered in the [boldLabel] field. However, it is used to calculate the plan\'s price if the billing cycle is changed from yearly to monthly payment or vice versa.\n\n[headline1]\nChecked: If the customer changes to a monthly plan, the price will be higher than the yearly price / 12 [DEFAULT].\nUnchecked: If the customer changes to a monthly plan, the price will be the yearly price / 12.\n\n[headline2]\nIf the customer switches to an yearly plan, the price will be less than the monthly price * 12.\nUnchecked: If the customer switches to an yearly plan, the price will be the monthly price * 12 [DEFAULT].',
                            {
                              boldLabel: <b>{t('Price for the selected period')}</b>,
                              headline1: (
                                <b>{t('Billing cycle = 12 months (called yearly plan)')}</b>
                              ),
                              headline2: (
                                <b>{t('Billing cycle < 12 months (called monthly plan)')}</b>
                              ),
                            },
                          )}
                        </AccText>
                      </Popover.Dropdown>
                    </Popover>
                  </Group>
                  <Field.TextInput
                    name="message"
                    label={t('Optional message to be displayed on the invoice')}
                  />
                  <Group grow>
                    <Field.Checkbox
                      name="freePeriodBeforeStart"
                      disabled={isPaidPlan}
                      label={t('Free start period before the payment starts')}
                    />
                  </Group>
                </Stack>
                <Field.DateInput
                  name="startDate"
                  label={t('Payment start date')}
                  className={styles.dateInput}
                  disabled={isPaidPlan || !freePeriodBeforeStart}
                  initialValue={tomorrow}
                  excludeDate={(date: Date) => {
                    const currentDate = new Date();
                    const givenDate = new Date(date);
                    return givenDate < currentDate;
                  }}
                />
                <Divider my="xs" color="gray.1" />
                <Group justify="flex-end">
                  <FormSubmitButton label={t('Create invoice')} />
                </Group>
              </Stack>
            );
          }}
        </Form>
      )}
    </ModalBorder>
  );
};

export default CreateInvoice;
