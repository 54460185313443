import { Link } from 'react-router-dom';
import { Flex } from '@mantine/core';
import AccButton from 'Components/AccButton/AccButton';
import { Field } from 'Components/Fields';
import { Form, OnSubmitCallback } from 'Components/Fields/Form/Form';
import IntercomButton from 'Components/Intercom/IntercomButton';
import AccText from 'Components/Text/AccText';
import AccTitle from 'Components/Title/AccTitle';
import { RequestPasswordResetInput } from 'Ghql';
import { t, tct } from 'Utilities/i18n';
import Validator from 'Utilities/validation';
import styles from './../../LoginPage/login-page.module.scss';

export type ResetPasswordFormValues = Omit<RequestPasswordResetInput, 'clientMutationId'>;

export const ResetPasswordForm = ({
  onSubmit,
}: {
  onSubmit: OnSubmitCallback<ResetPasswordFormValues>;
}) => {
  return (
    <Flex direction="column" gap="md">
      <AccTitle type="h2" ta="center">
        {t('Reset Password')}
      </AccTitle>
      <AccText ta="center">
        {t(
          'Enter the email you use for log in. We will send you an email with a link to reset your password.',
        )}
      </AccText>
      <Form<ResetPasswordFormValues> onSubmit={onSubmit}>
        {/* eslint-disable-next-line react/no-unused-prop-types */}
        {({ submitting }: { submitting: boolean }) => {
          return (
            <Flex gap="sm" direction="column">
              <Field.TextInput
                boxProps={{ mt: 'xxs' }}
                autoFocus
                name="email"
                placeholder={t('Email')}
                validate={[Validator.required, Validator.emailHtml]}
              />
              <AccText size="xs" fw={400} mt={-10} ml="auto">
                <Link to="/login" tabIndex={-1}>
                  {t('Back to Login')}
                </Link>
              </AccText>
              <Form.Errors />
              <div>
                <AccButton variant="primary" fullWidth loading={submitting} type="submit">
                  {t('Reset Password')}
                </AccButton>
              </div>
            </Flex>
          );
        }}
      </Form>
      <AccText ta="center" mt="lg">
        {tct(
          'If you can\'t remember your log in email, please contact our support by using the support icon in the corner - or send an email to [link:hello@accuranker.com].',
          {
            link: (
              <a href="mailto:hello@accuranker.com" aria-label={t('AccuRanker support Email')} />
            ),
          },
        )}
      </AccText>
      <div className={styles.intercomContainer}>
        <IntercomButton size="lg" />
      </div>
    </Flex>
  );
};
