import { Component } from 'react';
import { findDOMNode } from 'react-dom';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { gql } from '@apollo/client';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import compose from 'lodash/flowRight';
import sortBy from 'lodash/sortBy';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import type { FormProps } from 'redux-form';
import { createSelector } from 'reselect';
import { showModal } from 'Actions/ModalAction';
import AccButton from 'Components/AccButton/AccButton';
import { Select } from 'Components/Forms/Fields';
import toFormField from 'Components/Forms/toFormField';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import toast from 'Hooks/useToast';
import { DOMAINS, FilterAttribute, stringifyFilters } from 'Types/Filter';
import type { DomainsFilter, FilterBase } from 'Types/Filter';
import { t } from 'Utilities/i18n';
import { downloadFile, graphqlError, graphqlLoading } from 'Utilities/underdash';
import Validator from 'Utilities/validation';
import WS, { subscribeToGeneratedReport } from 'Utilities/websocket';
import PublicReportOptions from '../PublicReportOptions';
import EmailsInput from '../ScheduledReportBuilderForm/EmailsInput';
import {
  EXCEL,
  PDF,
  PUBLIC_REPORT,
  getDefaultEmailBody,
  getDefaultEmailSubject,
  getLanguageOptions,
  getReportTypeOptions,
} from '../data';

type Props = {
  client: any;
  reportTemplates: Record<string, any>;
  onClose: (...args: Array<any>) => any;
  onSubmit: () => void;
  emailFrom: string;
  filters: FilterBase[];
  reportType?: Record<string, any>;
  hasDriveAccount: boolean;
  showModal: (...args: Array<any>) => any;
  initialState: Record<string, any>;
  submitOnInit: boolean;
} & FormProps;
type State = {
  scheduledReportId: number | null;
  submittedOnInit: boolean;
  isLoading: boolean;
  link: string | null;
};
const EmailsField = toFormField(EmailsInput);

class OneTimeReportForm extends Component<Props, State> {
  _submit: any;
  generatedReportSub?: Record<string, any>;
  state: State = {
    isLoading: false,
    scheduledReportId: null,
    link: null,
    // to show Download button when report is created successfully
    submittedOnInit: false,
  };

  componentDidMount() {
    this.generatedReportSub = subscribeToGeneratedReport(this.handleDownload as any);
  }

  componentDidUpdate() {
    if (
      this._submit &&
      this.props.submitOnInit &&
      !this.state.submittedOnInit &&
      this.props.hasDriveAccount &&
      !(graphqlLoading(this.props) || graphqlError(this.props))
    ) {
      // eslint-disable-next-line react/no-will-update-set-state
      this.setState({
        submittedOnInit: true,
      });
      // Probably not the best way to submit programmatically,
      // so if there is a better way that runs handleSubmit and set submitting, fix me
      // eslint-disable-next-line react/no-find-dom-node
      // TODO FixTSignore
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line react/no-find-dom-node
      findDOMNode(this._submit)?.click?.();
    }
  }

  componentWillUnmount() {
    this.generatedReportSub?.unsubscribe();
  }

  handleDownload = ({
    data: {
      action,
      other: { scheduled_report, url, report_type },
    },
  }) => {
    // // ws message example
    // const message = {
    //   "data": {
    //     "id": "530365",
    //     "obj": "GENERATED_REPORT",
    //     "action": "UPDATED",
    //     "other": {
    //       "scheduled_report": 58224,
    //       "url": "/media/generated_reports/AccuRanker_accurankercom-8_2019-01-07_ab541b17b7ad4a04b6f7b12126aa2cf3_3lTBZlb.pdf",
    //       "report_type": 1
    //     }
    //   }
    // };
    const { selectedEmails } = this.props;

    if (
      !selectedEmails.length &&
      scheduled_report === this.state.scheduledReportId &&
      action === WS.UPDATED
    ) {
      if (url) {
        toast.success(t('Report created'));
        this.setState({
          link: url,
          isLoading: false,
        });
      }

      if (report_type !== 5 && url) {
        downloadFile(url);
      }
    }
  };
  languageOptions = getLanguageOptions();
  domainsFilter = this.props.filters.find(
    (filter) => filter.attribute === FilterAttribute.DOMAINS,
  ) as any;
  reportTypeOptions = getReportTypeOptions(this.domainsFilter ? this.domainsFilter : []);
  handleSubmit = (data: any) => {
    const domainsFilter = this.props.filters.find(
      (filter) => filter.attribute === FilterAttribute.DOMAINS,
    ) as any;

    if (!domainsFilter) {
      toast.error(t('Domains list is empty'));
      Validator.throwSubmissionError({
        _error: 'Domains list is empty',
      });
      return;
    }

    const domains = (domainsFilter as DomainsFilter).value;
    const { emails, reportType, template, language } = data;
    const isGroupReport = domains.length > 1;
    const noDomainsFilters = this.props.filters.filter((filter) => filter.attribute !== DOMAINS);
    const input: any = {
      isGroupReport,
      isOneTimeReport: true,
      reportType: reportType.value,
      reportFilter: {
        filters: stringifyFilters(noDomainsFilters),
      },
      reportTemplate: template ? template.value : null,
      schedule: 1,
      scheduledDay: null,
      recipients: emails ? emails : [],
      sender: this.props.emailFrom,
      emailSubject: getDefaultEmailSubject(),
      emailBody: getDefaultEmailBody(),
      language: language.value,
    };
    const { hasDriveAccount } = this.props;

    // pop the google auth flow if we dont have an account
    if (reportType.value === 5 && !hasDriveAccount) {
      this.handleMissingDriveAccount(data);
      return;
    }

    if (isGroupReport) {
      const client = this.props.clients.find((clientData) =>
        clientData.domains.find((domainData) => domainData.id === domains[0]),
      );

      if (client) {
        input.group = client.id;
      } else {
        toast.error(t('Can not find group for domain'));
        Validator.throwSubmissionError({
          _error: 'Can not find group for domain',
        });
        return;
      }
    } else {
      input.domain = domains[0];
    }

    this.setState({
      isLoading: true,
    });
    return this.props
      .addScheduledReport({
        variables: {
          input,
        },
      })
      .then(
        ({
          data: {
            addScheduledReport: { errors, scheduledReport },
          },
        }) => {
          if (errors && errors.length) {
            this.setState({
              isLoading: false,
            });
            toast.error(errors[0].messages[0]);
            Validator.throwSubmissionError(errors);
            return;
          }

          if (emails.length) {
            toast.success(
              t(
                'Report is being generated and will be sent to %s when completed.',
                emails.join(', '),
              ),
            );
            this.props.onSubmit();
            return;
          }

          this.setState({
            scheduledReportId: Number(scheduledReport.id),
          });
        },
        () => {
          this.setState({
            isLoading: false,
          });
          toast.error(t('Unable to create report'));
        },
      );
  };
  handleMissingDriveAccount = (data) => {
    const { domainId } = this.props;
    this.props.showModal({
      modalType: 'ConnectToDrive',
      modalTheme: 'light',
      modalProps: {
        message: t(
          'You do not have a Google Drive connection setup with AccuRanker. Please connect to your Google account to allow AccuRanker to create spreadsheet reports. AccuRanker will only have access to the files it creates, and cannot read other files.',
        ),
        nextModalType: 'OneTimeReport',
        lastState: data,
        domainId,
      },
    });
  };
  setSubmitRef = (ref) => {
    this._submit = ref;
  };

  renderGeneratingReport() {
    return (
      <div>
        <p>{t('Your report is being generated. Please wait…')}</p>
        <p>{t('Please note that reports are queued up and can take a few minutes to complete.')}</p>
        <p>
          {t(
            'If nothing happens, your browser may be blocking the download. You can probably find your report in the ',
          )}
          <Link to="/reports/generated">{t('generated reports tab.')}</Link>
        </p>
      </div>
    );
  }

  renderLinkToReport() {
    return (
      <div className="alert alert-info">
        {this.state.link?.startsWith('https://docs.google.com')
          ? t(
              'Your report is ready. To view it, please click the \'Open Google Sheets\' button below.',
            )
          : t(
              'Your report is ready. The download should have started automatically. If it hasn\'t, please click the download button below.',
            )}
      </div>
    );
  }

  downloadReport = () => {
    if (this.state.link) {
      window.open(this.state.link ?? '', '_blank');
    }
    this.props.onSubmit();
  };
  emailReportWhenDone = () => {
    const input = {
      id: this.state.scheduledReportId,
      recipients: [this.props.emailFrom],
    };
    this.props
      .updateScheduledReportRecipients({
        variables: {
          input,
        },
      })
      .then(
        ({
          data: {
            updateScheduledReportRecipients: { errors },
          },
        }) => {
          if (errors && errors.length) {
            toast.error(errors[0].messages[0]);
            return;
          }

          toast.success(
            t('The report will be emailed to %s when it is done.', this.props.emailFrom),
          );
          this.props.onSubmit();
        },
        () => {
          toast.error(t('Unable to update recipient'));
        },
      );
  };

  renderForm() {
    const { submitting, reportTemplates, reportType } = this.props;
    const templateOptions = sortBy(reportTemplates || [], 'name').map(({ id, name }) => ({
      value: id,
      label: name,
    }));
    const reportTypeOption = reportType || {};
    const isPublicReportModal = reportTypeOption.value === PUBLIC_REPORT;
    return (
      <div>
        <div className="form-label required">{t('Report Type')}</div>
        <Field
          name="reportType"
          defaultBehaviour
          component={Select}
          disabled={submitting}
          options={this.reportTypeOptions}
          validate={Validator.required}
          searchable={false}
        />
        {isPublicReportModal && (
          <PublicReportOptions reportType={reportTypeOption} domainId={this.props.domainId} />
        )}
        {reportTypeOption.value !== PUBLIC_REPORT && (
          <>
            {reportTypeOption.value === PDF && (
              <>
                <div key="template-label" className="form-label required">
                  {t('Template')}
                </div>
                <Field
                  key="template"
                  name="template"
                  placeholder={t('Template')}
                  component={Select}
                  disabled={submitting}
                  defaultBehaviour
                  validate={Validator.required}
                  options={templateOptions}
                />
              </>
            )}
            <div key="language-label" className="form-label required">
              {t('Language')}
            </div>
            <Field
              key="language"
              name="language"
              defaultBehaviour
              disabled={submitting}
              placeholder={t('Language')}
              component={Select}
              options={this.languageOptions}
              validate={Validator.required}
            />
            <div key="email-label" className="form-label">
              {t('Email to')}
            </div>
            <Field
              key="email"
              disabled={submitting}
              name="emails"
              //Do not include the word "email" in the placeholder - as this will trigger password managers to suggest email addresses
              placeholder={t('You can enter multiple addresses')}
              component={EmailsField}
            />
          </>
        )}
      </div>
    );
  }

  render() {
    const { handleSubmit, invalid, submitting, reportType } = this.props;
    const { isLoading, link } = this.state;
    const reportTypeOption = reportType || {};
    const isPublicReportModal = reportTypeOption.value === PUBLIC_REPORT;
    return (
      <form className="one-time-report-form" onSubmit={handleSubmit(this.handleSubmit)}>
        {(link && this.renderLinkToReport()) || (
          <div>{submitting || isLoading ? this.renderGeneratingReport() : this.renderForm()}</div>
        )}

        <div>
          <ModalFooter
            primaryButtonSlot={
              isPublicReportModal ? (
                <></>
              ) : (
                <>
                  {submitting ||
                    (isLoading && (
                      <AccButton variant="primary" onClick={this.emailReportWhenDone}>
                        {t('Email it to me when it\'s ready')}
                      </AccButton>
                    ))}

                  {(link && (
                    <AccButton onClick={this.downloadReport} variant="primary">
                      {this.state.link?.startsWith('https://docs.google.com')
                        ? t('Open Google Sheets')
                        : t('Download')}
                    </AccButton>
                  )) || (
                    <AccButton
                      ref={this.setSubmitRef}
                      disabled={invalid || submitting || isLoading}
                      type="submit"
                      variant="primary"
                      loading={submitting || isLoading}
                    >
                      {submitting || isLoading ? t('Generating') : t('Download')}
                    </AccButton>
                  )}
                </>
              )
            }
            secondaryButtonSlot={
              <AccButton variant="tertiary" onClick={this.props.onClose}>
                {t('Cancel')}
              </AccButton>
            }
          />
        </div>
      </form>
    );
  }
}

const dataQuery = gql`
  query oneTimeReportForm_reportTemplatesData {
    user {
      id
      email
      organization {
        id
        defaultReportTemplate {
          id
          name
        }
      }
    }
    reportTemplates {
      id
      name
    }
    clients {
      id
      name
      domains {
        id
        domain
        displayName
      }
    }
  }
`;
const addScheduledReportQuery = gql`
  mutation oneTimeReportForm_addScheduledReport($input: CreateScheduledReportInput!) {
    addScheduledReport(input: $input) {
      errors {
        field
        messages
      }
      scheduledReport {
        id
        url
        preferredUrl
      }
    }
  }
`;
const updateScheduledReportRecipientsMutation = gql`
  mutation oneTimeReportForm_updateScheduledReportRecipients(
    $input: UpdateScheduledReportRecipientsInput!
  ) {
    updateScheduledReportRecipients(input: $input) {
      errors {
        field
        messages
      }
      scheduledReport {
        id
      }
    }
  }
`;
const ReduxForm = reduxForm({
  form: 'OneTimeReportForm',
  enableReinitialize: true,
  // The next two options address the bug where the template field's validation error persists after fetching
  updateUnregisteredFields: true,
  keepDirtyOnReinitialize: true,
})(OneTimeReportForm);

const languageSelector = (state) => state.user.language;

const filtersSelector = (state) => state.filter.filterGroup.filters;

const initialValuesSelector = createSelector(
  [languageSelector, filtersSelector],
  (language: string) => {
    const languageOptions = getLanguageOptions();
    // TODO FixTSignore
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const reportTypes = getReportTypeOptions();
    return {
      language: languageOptions.find((languageObj) => languageObj.value === language),
      reportType: reportTypes.find((x) => x.value === EXCEL),
      emails: [],
    };
  },
);
const reportTypeSelector = formValueSelector('OneTimeReportForm');

const mapStateToProps = (state, { initialState }) => ({
  initialValues: initialState || initialValuesSelector(state),
  hasDriveAccount: state.user.googleConnections.length > 0,
  emailFrom: state.user.email,
  filters: state.filter.filterGroup.filters,
  reportType: reportTypeSelector(state, 'reportType'),
  selectedEmails: reportTypeSelector(state, 'emails'),
});

export default compose(
  withApollo,
  connect(mapStateToProps, {
    showModal,
  }), // queryDomainInfo(),
  graphql(addScheduledReportQuery, {
    name: 'addScheduledReport',
  }),
  graphql(updateScheduledReportRecipientsMutation, {
    name: 'updateScheduledReportRecipients',
  }),
  graphql(dataQuery, {
    props: ({ data, ownProps }: any) => {
      const { error, loading, user, reportTemplates, clients } = data;

      if (error || loading) {
        return {};
      }

      const {
        organization: { defaultReportTemplate },
      } = user;
      return {
        initialValues: {
          ...ownProps.initialValues,
          template: {
            value: defaultReportTemplate.id,
            label: defaultReportTemplate.name,
          },
        },
        reportTemplates,
        clients,
      };
    },
  }),
)(ReduxForm);
