import { memo } from 'react';
import { useNavigate } from 'react-router';
import * as Sentry from '@sentry/react';
import AccButton from 'Components/AccButton/AccButton';
import AccessControl from 'Components/AccessControl';
import AccText, { AccTextProps } from 'Components/Text/AccText';
import { useOrganizationInfo } from 'Selectors/OrganizationSelector';
import { t } from 'Utilities/i18n';
import CountDownToDate from './CountDownToDate';
import styles from '../top-navbar.module.scss';

const Text = (textProps: AccTextProps) => <AccText nowrap fw={400} size={'12px'} {...textProps} />;

const TrialWarnings = () => {
  const { isTrial, endDate: planEndDate } = useOrganizationInfo();

  const navigate = useNavigate();

  // Trial time left
  const planEndDateMs = planEndDate ? new Date(planEndDate).getTime() : undefined;
  const planExpired = planEndDateMs ? new Date().getTime() > planEndDateMs : undefined;

  if (!isTrial) return null;

  if (!planEndDateMs) {
    Sentry.captureException('planEndDateMs is undefined in TrialWarnings');
    console.error('No plan enddate defined for this trial.');
    throw new Error('planEndDateMs is undefined');
  }

  return (
    <div className={styles.trialWarning}>
      <Text fw={600}>{t('YOUR TRIAL EXPIRES IN')}</Text>
      {planExpired ? <Text>{t('0 days')}</Text> : <CountDownToDate endDate={planEndDateMs} />}
      <AccessControl withAdminPermission>
        <AccButton h={26} onClick={() => navigate('/billing/package/select')} variant="primary">
          {t('SELECT PLAN')}
        </AccButton>
      </AccessControl>
    </div>
  );
};

export default memo(TrialWarnings);
