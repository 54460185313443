import { gql } from '@apollo/client';

export const pricingPlansQuery = gql`
  query selectPlanForm_pricingPlansChoices {
    pricingPlansChoices {
      name
      originalPlan {
        id
        isPublicPlan
      }
      choices {
        id
        suggested
        name
        category
        currency
        priceMonthly
        priceYearly
        maxKeywords
        maxCompetitors
        maxDomains
        maxUsers
        unavailable
        featureApiAccess
        featureApiV4WriteAccess
        featureCompetitorMonitoring
        featureAnalyticsIntegration
        featureSearchVolume
        featureReporting
        featureKeywordRefresh
        featureAdvancedReporting
        featureCanPause
        featureSocial
        featureAdvancedMetrics
      }
    }
  }
`;
