import { MouseEvent } from 'react';
import { Flex, useMantineTheme } from '@mantine/core';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import { AccFastIcon } from 'Components/AccFastIcon';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import AccessControl from 'Components/AccessControl';
import { t } from 'Utilities/i18n';
import { IconFolderPlus } from 'icons/tag-cloud';
import IconTagPlus from 'icons/tag-plus.svg';

type FolderActionsProps = {
  onAddTag: (e: MouseEvent<HTMLButtonElement>) => void;
  onAddFolder: (e: MouseEvent<HTMLButtonElement>) => void;
  showAction: boolean;
};

export const FolderActions = ({ onAddTag, onAddFolder, showAction }: FolderActionsProps) => {
  const iconColor = useMantineTheme().colors.snorlax[4];

  if (!showAction) return null;

  return (
    <AccessControl>
      <Flex>
        <AccTooltip tooltip={t('Create tag')} old>
          <AccActionIcon variant="subtle" onClick={onAddTag}>
            <AccFastIcon src={IconTagPlus} size={18} color={iconColor} />
          </AccActionIcon>
        </AccTooltip>
        <AccTooltip tooltip={t('Create folder')} old>
          <AccActionIcon variant="subtle" onClick={onAddFolder}>
            <AccFastIcon src={IconFolderPlus} size={18} color={iconColor} />
          </AccActionIcon>
        </AccTooltip>
      </Flex>
    </AccessControl>
  );
};
