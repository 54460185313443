import { Component } from 'react';
import { connect } from 'react-redux';
import { hideModal } from 'Actions/ModalAction';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { t } from 'Utilities/i18n';
import CancelPlanCallForm from './CancelPlanCallForm';

type Props = {
  hideModal: (...args: Array<any>) => any;
};

class CancelPlan extends Component<Props> {
  render() {
    return (
      <ModalBorder
        className="cancel-plan"
        title={t('Cancel my AccuRanker Subscription')}
        onClose={this.props.hideModal}
      >
        <CancelPlanCallForm onClose={this.props.hideModal} />
      </ModalBorder>
    );
  }
}

export default connect(null, {
  hideModal,
})(CancelPlan);
