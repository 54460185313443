import { Component } from 'react';
import { connect } from 'react-redux';
import * as Apollo from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import compose from 'lodash/flowRight';
import { gaSetPassedState } from 'Actions/GoogleAccountsAction';
import { showModal } from 'Actions/ModalAction';
import {
  ScheduledReportDocument,
  ScheduledReportNode,
  ScheduledReportQuery,
  UpdateScheduledReportDocument,
  UpdateScheduledReportInput,
  UpdateScheduledReportMutation,
  UpdateScheduledReportMutationVariables,
} from 'Ghql';
import toast from 'Hooks/useToast';
import { apolloClient } from 'Store';
import { FilterBase, parseFilters, stringifyFilters } from 'Types/Filter';
import { withRouter } from 'Utilities/Router';
import { t } from 'Utilities/i18n';
import underdash from 'Utilities/underdash';
import Validator from 'Utilities/validation';
import ScheduledReportBuilderForm from './../';

export type EditReportFormSubmitData = Omit<
  ScheduledReportNode,
  'id' | 'schedule' | 'scheduledDay'
> & {
  filters: FilterBase[];
  emails: string[];
  template: { value: string; label: string } | string;
  schedule: { value: number; label: string } | number;
  scheduledDay?: number | string;
};

type Props = {
  id: string;
  history: Record<string, any>;
  user: Record<string, any>;
  addReportFilter: (...args: Array<any>) => any;
  updateScheduledReport: ({
    variables,
  }: {
    variables: UpdateScheduledReportMutationVariables;
  }) => Promise<Apollo.MutationResult<UpdateScheduledReportMutation>>;
  data: ScheduledReportQuery | null;
  hasDriveAccount: boolean;
  showModal: (...args: Array<any>) => any;
  initialState: UpdateScheduledReportInput;
  gaSetPassedState: (...args: Array<any>) => any;
};

class EditScheduledReport extends Component<Props> {
  componentWillUnmount() {
    this.props.gaSetPassedState(null);
  }

  handleSubmit = (data: EditReportFormSubmitData) => {
    const {
      domain,
      group,
      name,
      description,
      emailBody,
      sender,
      emailSubject,
      emails,
      language,
      reportType,
      schedule,
      filters,
      scheduledDay,
      isGroupReport,
      template: reportTemplate,
    } = data;
    const { hasDriveAccount, id } = this.props;
    const input: UpdateScheduledReportInput = {
      name,
      description,
      isGroupReport: !!isGroupReport,
      isOneTimeReport: false,
      reportType: (reportType as any).value || reportType,
      reportTemplate: typeof reportTemplate === 'object' ? reportTemplate.value : reportTemplate,
      reportFilter: {
        filters: stringifyFilters(filters),
      },
      schedule: typeof schedule === 'object' ? schedule.value : schedule,
      scheduledDay:
        Number(typeof schedule === 'object' ? schedule.value : schedule) === 1
          ? null
          : Number(scheduledDay),
      recipients: emails ? emails : [],
      sender,
      emailSubject,
      emailBody,
      language: (language as any).value || language,
      ...(isGroupReport
        ? {
            group: (group as any).value || group,
          }
        : {
            domain: (domain as any).value || domain,
          }),
      id,
    };

    // pop the google auth flow if we dont have an account
    if (input.reportType === 5 && !hasDriveAccount) {
      this.handleMissingDriveAccount(data);
      return;
    }

    return this.props
      .updateScheduledReport({
        variables: {
          input,
        },
      })
      .then((response) => {
        const errors = response.data?.updateScheduledReport?.errors;
        if (errors && errors.length) {
          toast.error(t('Unable to update scheduled report'));
          Validator.throwSubmissionError(errors);
        }

        toast.success(t('Report updated'));
        //empty the Apollo cache to make sure we refetch the reports so the Scheduled Reports table is updated
        apolloClient.cache.reset();
        this.props.history.push('/reports/scheduled');
      });
  };
  handleMissingDriveAccount = (data) => {
    this.props.showModal({
      modalType: 'ConnectToDrive',
      modalTheme: 'light',
      modalProps: {
        message: t(
          'You do not have a Google Drive connection setup with AccuRanker. Please connect to your Google account to allow AccuRanker to create spreadsheet reports. AccuRanker will only have access to the files it creates, and cannot read other files.',
        ),
        lastState: data,
      },
    });
  };

  render() {
    if (underdash.graphqlError({ ...this.props }) || underdash.graphqlLoading({ ...this.props })) {
      return null;
    }

    const { data, initialState, hasDriveAccount } = this.props;

    const { scheduledReport } = data || {};

    const {
      domain,
      group,
      recipients,
      reportType = '',
      schedule = '',
      reportFilter,
    } = scheduledReport || {};

    const initialValues: UpdateScheduledReportInput = initialState || {
      ...scheduledReport,
      reportType: parseInt(reportType.slice(2, 3), 10),
      schedule: parseInt(schedule.slice(2, 3), 10),
      filters: reportFilter ? parseFilters(reportFilter.filters) : [],
      emails: recipients,
      isGroupReport: scheduledReport?.isGroupReport,
      ...(scheduledReport?.isGroupReport
        ? {
            group: {
              label: group?.name,
              value: group?.id,
            },
          }
        : {
            domain: {
              label:
                (domain?.displayName && `${domain.displayName} (${domain.domain})`) ||
                domain?.domain,
              value: domain?.id,
            },
          }),
    };
    return (
      <ScheduledReportBuilderForm
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        submitOnInit={!!initialState}
        hasDriveAccount={hasDriveAccount}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  hasDriveAccount: state.user.googleConnections.length > 0,
  initialState: state.googleAccounts.passedState,
});

export default compose(
  connect(mapStateToProps, {
    showModal,
    gaSetPassedState,
  }),
  withRouter,
  graphql(ScheduledReportDocument, {
    options: () => ({
      fetchPolicy: 'network-only',
    }),
  }),
  graphql(UpdateScheduledReportDocument, {
    name: 'updateScheduledReport',
  }),
)(EditScheduledReport);
