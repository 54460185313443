import { useIntl } from 'react-intl';
import { Center, RingProgress, useMantineTheme } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { intlFormatNumber } from 'Components/FormatNumber/helpers';
import AccText from 'Components/Text/AccText';
import { t } from 'Utilities/i18n';

const PercentageOfMaxCircular = ({
  value,
  maxValue,
  isRank,
  isKeywords,
  isPercentages,
  large,
}: {
  value: number | undefined;
  maxValue: number;
  isRank?: boolean;
  isKeywords?: boolean;
  isPercentages?: boolean;
  large?: boolean;
}) => {
  const theme = useMantineTheme();
  const intl = useIntl();

  const numberValue = value || 0;

  let percentage = isRank
    ? Math.round(100 - (100 * (numberValue - maxValue)) / (100 - maxValue))
    : Math.round((100 * numberValue) / maxValue);

  // we do not want to show 100% if not exactly 100%
  if (percentage === 100 && value !== maxValue) {
    percentage = 99;
  }

  const color = theme.colors.snorlax[4];

  const valueInTooltip = isPercentages
    ? `${(numberValue * 100).toFixed(1)}%`
    : intlFormatNumber(intl)({ value, maximumFractionDigits: 1 });
  const maxValueInTooltip = isPercentages
    ? `${(maxValue * 100).toFixed(1)}%`
    : intlFormatNumber(intl)({ value: maxValue, maximumFractionDigits: 1 });

  return (
    <AccTooltip
      tooltip={
        value
          ? `${valueInTooltip} ${t('out of')} ${maxValueInTooltip}${
              isKeywords ? ` ${t('discovered keywords')}` : ''
            } (${percentage}%)`
          : undefined
      }
    >
      <RingProgress
        m={large ? -8 : 0}
        p={0}
        size={large ? 50 : 22}
        thickness={large ? 5 : 3}
        roundCaps
        label={
          value && value === maxValue ? (
            <Center>
              <IconCheck color={color} size={large ? 24 : 10} />
            </Center>
          ) : large ? (
            <Center>
              <AccText size={'xs'} fw={'bold'}>
                {value ? `${percentage}%` : '-'}
              </AccText>
            </Center>
          ) : null
        }
        sections={numberValue === 0 ? [] : [{ value: percentage, color }]}
      />
    </AccTooltip>
  );
};

export default PercentageOfMaxCircular;
