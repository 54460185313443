import { Component } from 'react';
import { connect } from 'react-redux';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import compose from 'lodash/flowRight';
import { Field, reduxForm } from 'redux-form';
import { hideModal } from 'Actions/ModalAction';
import AccButton from 'Components/AccButton/AccButton';
import { Select, TextField } from 'Components/Forms/Fields';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import toast from 'Hooks/useToast';
import { throwNetworkError } from 'Utilities/errors';
import { t } from 'Utilities/i18n';
import Validator from 'Utilities/validation';
import formStyle from 'css/layout/form-layout.module.scss';

type Props = {
  hideModal: (...args: Array<any>) => any;
  handleSubmit: (...args: Array<any>) => any;
  organizationId: number;
  users: Record<string, any>;
  invalid?: any;
  submitting?: any;
  usersQuery?: any;
  addAffiliateMutation?: any;
};

class MakeAffiliate extends Component<Props> {
  handleSubmit = ({ primaryContact, paypalEmail }) => {
    const input = {
      organizationId: this.props.organizationId,
      contactId: primaryContact.value,
      paypalEmail,
    };
    return this.props
      .addAffiliateMutation({
        variables: {
          input,
        },
      })
      .then(
        ({
          data: {
            addAffiliate: { errors },
          },
        }) => {
          if (errors && errors.length) {
            toast.error(t('Affiliate could not be created.'));
            Validator.setResponseErrors(Validator.throwSubmissionError, errors);
          } else {
            toast.success(t('Affiliate created.'));
            this.props.hideModal();
          }
        },
        throwNetworkError,
      );
  };

  render() {
    const {
      invalid,
      submitting,
      usersQuery: {
        loading,
        adminOrganization: { users },
      },
    } = this.props;
    const options = loading
      ? []
      : users.map(({ id, fullName, email }) => ({
          value: id,
          label: `${fullName} <${email}>`,
        }));
    return (
      <ModalBorder title={t('Make Organization Affiliate')} onClose={this.props.hideModal}>
        <form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
          <div className={formStyle.formRow}>
            <div className="form-label">{t('Select primary affiliate contact')}</div>
            <Field
              name="primaryContact"
              defaultBehaviour
              component={Select}
              options={options}
              loading={loading}
              validate={Validator.required}
            />
          </div>
          <div className={formStyle.formRow}>
            <div className="form-label">{t('PayPal email')}</div>
            <Field
              name="paypalEmail"
              defaultBehaviour
              label={t('PayPal email')}
              labelClassname="required"
              elementType="input"
              type="email"
              placeholder={t('Enter PayPal email')}
              component={TextField}
              validate={[Validator.required, Validator.email]}
            />
          </div>
          <div className="footer">
            <AccButton variant="primary" type="submit" disabled={invalid || submitting}>
              {t('Save')}
            </AccButton>
          </div>
        </form>
      </ModalBorder>
    );
  }
}

const addAffiliateMutation = gql`
  mutation ($input: AddAffiliateInput!) {
    addAffiliate(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;
const getUsersQuery = gql`
  query salesOrganization_getUsersForOrganization($id: ID!) {
    adminOrganization(id: $id) {
      id
      users {
        id
        email
        fullName
      }
    }
  }
`;
const SalesMakeAffiliateForm = reduxForm({
  form: 'SalesMakeAffiliateForm',
})(MakeAffiliate);
export default compose(
  graphql(getUsersQuery, {
    name: 'usersQuery',
    options: (props: any) => ({
      variables: {
        id: props.organizationId,
      },
    }),
  }),
  graphql(addAffiliateMutation, {
    name: 'addAffiliateMutation',
  }),
  connect(null, {
    hideModal,
  }),
)(SalesMakeAffiliateForm);
