import { Placement } from '@floating-ui/dom';
import cn from 'classnames';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { TABLE_ICON_SIZE } from 'Components/AccuTable/constants';
import styles from './fastIcon.module.scss';

// eslint-disable-next-line import/no-unused-modules
export type AccFastIconProps = {
  /**
   * URL to the *.svg icon file.  */
  src: string | undefined;
  /** Defaults to `1 / 1`  */
  aspectRatio?: React.CSSProperties['aspectRatio'];
  /** Flag width */
  size?: React.CSSProperties['width'];
  /** number or string to set border-radius*/
  radius?: React.CSSProperties['borderRadius'];
  tooltip?: string;
  tooltipPlacement?: Placement;
  /** Pass a color to the icon to recolor the svg */
  color?: React.CSSProperties['color'];
  tooltipKey?: React.Key;
  className?: string;
  /**
   * How the background icon should be placed in its frame.
   *
   * `cover` will stretch the icon to make sure it covers the full background, this can trim some of the image.
   *
   * `contain` will make sure the icon image maintains its proportions and is letterboxed in the box.
   *
   * Defaults to `cover`;
   * @documentation [mdn Web Docs](https://developer.mozilla.org/en-US/docs/Web/CSS/background-size)
   */
  fit?: 'cover' | 'contain';
  mr?: React.CSSProperties['marginRight'];
  ml?: React.CSSProperties['marginLeft'];
  id?: string;
  style?: React.CSSProperties;
  testId?: string;
  animate?: 'rotate' | 'scale';
  onClick?: (MouseEvent: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

// eslint-disable-next-line no-confusing-arrow
const getColorStyleProps = (
  color: string | undefined,
  fit?: 'cover' | 'contain',
  backgroundImage?: string,
) => {
  if (color) {
    const result: any = {
      maskImage: backgroundImage,
      WebkitMaskImage: backgroundImage,
      backgroundColor: color,
    };
    if (fit) {
      result.maskSize = fit;
      result.WebkitMaskSize = fit;
    }
    return result;
  }
  const resultWithBackgroundImage: any = { backgroundImage };
  if (fit) {
    resultWithBackgroundImage.backgroundSize = fit;
  }
  return resultWithBackgroundImage;
};

/**
 * Icon optimized for performance using background image.
 *
 * Use Mantine props to set the size, margin, etc.
 * @example
 * import IconSvg from "./icon.svg"
 *
 * <AccFastIcon src={IconSvg} size={24} radius={6} mr={8} color="#ffffff" fit="contain" />
 */

// eslint-disable-next-line import/no-unused-modules
export const AccFastIcon = (props: AccFastIconProps) => {
  const {
    radius,
    size,
    fit,
    className,
    aspectRatio,
    src,
    tooltip,
    tooltipPlacement,
    tooltipKey,
    color,
    mr,
    ml,
    style,
    testId,
    animate,
    onClick,
    ...rest
  } = props;

  const backgroundImage = src ? `url(${src})` : undefined;

  const stylesProps = getColorStyleProps(color, fit, backgroundImage);

  if (aspectRatio) {
    stylesProps.aspectRatio = aspectRatio;
  }
  // Only set size if it's not the default size
  if (size && size !== TABLE_ICON_SIZE) {
    stylesProps.width = `${size}px`;
    stylesProps.minWidth = `${size}px`;
  }
  if (radius) {
    stylesProps.borderRadius = `${radius}px`;
  }
  if (mr) {
    stylesProps.marginRight = mr;
  }
  if (ml) {
    stylesProps.marginLeft = ml;
  }

  const resultStyles = { ...stylesProps, ...style };

  const hasColor = !!color;

  const combinedClassName: string = cn(
    styles.root,
    {
      [styles.color]: hasColor,
      [styles.animateRotate]: animate === 'rotate',
      [styles.animateScale]: animate === 'scale',
      [styles.cursorPointer]: onClick,
    },
    className,
  );

  if (tooltip) {
    return (
      <AccTooltip tooltip={tooltip} placement={tooltipPlacement} key={tooltipKey || tooltip}>
        <div
          style={resultStyles}
          className={combinedClassName}
          onClick={onClick}
          {...rest}
          data-testid={testId}
        />
      </AccTooltip>
    );
  }
  return (
    <div
      style={resultStyles}
      className={combinedClassName}
      onClick={onClick}
      {...rest}
      data-testid={testId}
    />
  );
};
