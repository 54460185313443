import { SortEvent, SortEventWithTag } from 'react-sortable-hoc';
import uniq from 'lodash/uniq';
import { GroupSettingsItem } from '../../support/types';
import { filterSelectedOptions, normalizeOptions } from './common/helpers';
import { TableSettingGroup, TableSettingOption } from './types';

export function arrayMove<T = unknown>(array: T[], from: number, to: number): T[] {
  array = array.slice();
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  return array;
}

export const getGroupByOptionId = (
  groups: TableSettingGroup[],
  id: string,
): TableSettingGroup | undefined => groups?.find((e) => e.options.some((el) => el.id === id));

export const getDefaultItemsForGroupSettings = (configItems: TableSettingGroup[] | undefined) => {
  return configItems?.reduce(
    (acc, e) => {
      const options = normalizeOptions(e.options);

      const enabled = e.fixed || options?.some((el) => el?.defaultEnabled);
      const enabledOptions = uniq(
        options
          .filter((el) => el?.defaultEnabled)
          ?.map((el) => el?.id)
          .filter(Boolean),
      );
      return {
        groups: [...acc.groups, { id: e.id, enabled }],
        columns: [...acc.columns, ...(enabledOptions || [])].filter(Boolean).map((el) => el),
      };
    },
    { groups: [] as GroupSettingsItem[], columns: [] as string[] },
  );
};

const filterVisibleOptions = (
  options: TableSettingOption[],
  columns: string[] | null,
): string[] => {
  const result = options
    .filter(filterSelectedOptions(columns || []))
    .map((e) => (e.isParameter ? e.parentId! : e.id!));

  return uniq(result);
};

export const getActiveGroups = (
  configItems: TableSettingGroup[] | null,
  groups: GroupSettingsItem[] | null,
) => {
  return groups
    ?.map((e) => {
      const item = configItems?.find((el) => el.id === e.id);
      return {
        item,
        enabled: e.enabled,
        options: normalizeOptions(item?.options ?? []),
      };
    })
    .filter((e) => e.enabled || e.item?.fixed);
};

export const getVisibleColumnsByTableSettings = (
  configItems: TableSettingGroup[] | null,
  groups: GroupSettingsItem[] | null,
  columns: string[] | null,
): string[] => {
  return (
    getActiveGroups(configItems, groups)
      ?.map((e) => {
        return {
          ...e,
          options: filterVisibleOptions(e.options, columns),
        };
      })
      .filter((e) => e.enabled || e.item?.fixed)
      .reduce((acc, e) => [...acc, ...(e.options || [])], [] as string[]) || []
  );
};

const targetHasProp = (target: Element | null, hasProp: (target: Element) => boolean): boolean => {
  while (target) {
    if (hasProp(target)) {
      return true;
    }
    // eslint-disable-next-line no-param-reassign
    target = target.parentElement;
  }
  return false;
};

export const shouldCancelSortStart = (coach: SortEvent | SortEventWithTag): boolean => {
  // Cancel sort if a user is interacting with a given element
  return targetHasProp(coach.target as Element, (el) => {
    return el.hasAttribute('data-ignore-dnd');
  });
};
