import isArray from 'lodash/isArray';
import { replaceFolderSeparator } from 'Pages/Keywords/Groupings/EditMode/support/helpers';
import { FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import type { FoldersFilter } from 'Types/Filter';
import { t } from 'Utilities/i18n/index';
import Icon from 'icons/folder.svg?inline';
import FiltersEditor from '.';

const defaultValue: FoldersFilter = {
  attribute: FilterAttribute.FOLDERS,
  type: FilterValueType.FOLDER,
  comparison: FilterComparison.FOLDER_OR_SUBFOLDER,
  value: [],
};

const getData = () => {
  const comparisonLabels = {
    [FilterComparison.EXACT_FOLDER]: t('Exact folder'),
    [FilterComparison.FOLDER_OR_SUBFOLDER]: t('Folder or subfolder of'),
  };
  return {
    defaultValue,
    title: t('Folders'),
    icon: Icon,
    editor: FiltersEditor,
    labelFunc: (filter: FoldersFilter) =>
      `${comparisonLabels[filter.comparison]} ${
        isArray(filter.value) ? replaceFolderSeparator(filter.value.join(', ')) : ''
      }`,
  };
};

export default getData;
