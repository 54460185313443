import { Box, useMantineTheme } from '@mantine/core';

export const GutterSm = () => {
  const {
    other: { gutterSm },
  } = useMantineTheme();
  return <Box mb={gutterSm} />;
};

export const Gutter = () => {
  const {
    other: { gutter },
  } = useMantineTheme();
  return <Box mb={gutter} />;
};
