import { useState } from 'react';
import AccButton from 'Components/AccButton/AccButton';
import AccessControl from 'Components/AccessControl';
import { NOTE_TYPE_GOOGLE } from 'Components/Chart/Notes/support/constants';
import { ChartNote } from 'Components/Chart/Notes/support/types';
import { DomainFavicon } from 'Components/DomainFavicon';
import { useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import { t, tct } from 'Utilities/i18n';
import IconChevronBackward from 'icons/chevron-backward.svg?inline';
import IconChevronForward from 'icons/chevron-forward.svg?inline';
import { useNotesSelect } from '../support/hooks';
import styles from '../notes.module.scss';

type ChartNotePopoverContentProps = {
  note: ChartNote;
  relatedNotes?: ChartNote[];
  onClose?: () => void;
};

function NotePagination(
  goPrev: () => void,
  index: number,
  notesLastIndex: number,
  goNext: () => void,
) {
  return (
    <div className={styles.controlGroup}>
      <button className={styles.control} onClick={goPrev}>
        <IconChevronForward />
      </button>
      {tct('[from] of [to]', { from: index + 1, to: notesLastIndex })}
      <button className={styles.control} onClick={goNext}>
        <IconChevronBackward />
      </button>
    </div>
  );
}

export const ChartNotePopoverContent = ({
  note,
  relatedNotes,
  onClose,
}: ChartNotePopoverContentProps) => {
  const [index, setIndex] = useState(0);
  const showNoteEdit = useNotesSelect(onClose);
  const { domainInfo } = useQueryDomainInfo();

  const notesLastIndex = relatedNotes?.length ?? 1;
  const currentNote = relatedNotes?.[index] ?? note;
  const alignIndex = (i: number) => Math.min(Math.max(i, 0), notesLastIndex - 1);
  const goNext = () => setIndex((i) => alignIndex(i + 1));
  const goPrev = () => setIndex((i) => alignIndex(i - 1));

  if (!currentNote) {
    return null;
  }

  if (currentNote.type === NOTE_TYPE_GOOGLE) {
    return (
      <div>
        <div className={styles.title}>{currentNote.createdAt}</div>
        <div className={styles.note}>{currentNote.note}</div>
        {notesLastIndex > 1 && NotePagination(goPrev, index, notesLastIndex, goNext)}
      </div>
    );
  }

  return (
    <div>
      <div className={styles.title}>{currentNote.createdAt}</div>
      <div className={styles.note}>{currentNote.note}</div>
      <div className={styles.info}>
        {domainInfo?.domain && (
          <div className={styles.domain}>
            <DomainFavicon domain={domainInfo?.domain} /> {domainInfo?.domain}
          </div>
        )}
        <div>
          {t('Keywords')}:{' '}
          <span data-note-keywords-count="true">{currentNote.numberOfKeywords}</span>
        </div>
      </div>
      <div className={styles.controls}>
        <AccessControl>
          <AccButton variant="primary" onClick={() => showNoteEdit(currentNote?.id)}>
            {t('Edit note')}
          </AccButton>
        </AccessControl>
        {notesLastIndex > 1 && NotePagination(goPrev, index, notesLastIndex, goNext)}
      </div>
    </div>
  );
};
