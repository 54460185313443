import { Component } from 'react';
import { connect } from 'react-redux';
import { gql } from '@apollo/client';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import compose from 'lodash/flowRight';
import { Field, reduxForm } from 'redux-form';
import { showModal } from 'Actions/ModalAction';
import AccButton from 'Components/AccButton/AccButton';
import { Select } from 'Components/Forms/Fields';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import toast from 'Hooks/useToast';
import { throwNetworkError, throwSubmitErrors } from 'Utilities/errors';
import { t } from 'Utilities/i18n';
import Validator from 'Utilities/validation';
import formStyles from 'css/layout/form-layout.module.scss';

type Props = {
  handleSubmit: (...args: Array<any>) => any;
  submitting: boolean;
  invalid: boolean;
  moveDomain: (...args: Array<any>) => any;
  client?: any;
  showModal?: any;
  onClose?: any;
};

const groupAndDomainQuery = gql`
  query moveDomainForm_groupAndDomain($clientId: String!, $domainId: String!) {
    domainsSearch(query: $domainId) {
      id
      domain
      client {
        id
        organization {
          id
          name
        }
      }
    }
    clientsSearch(query: $clientId) {
      id
      name
      organization {
        id
        name
      }
    }
  }
`;
const domainsQuery = gql`
  query reportABugForm_domains($searchQuery: String!) {
    domainsSearch(query: $searchQuery) {
      id
      domain
      displayName
    }
  }
`;
const groupsQuery = gql`
  query reportABugForm_groups($searchQuery: String!) {
    clientsSearch(query: $searchQuery) {
      id
      name
    }
  }
`;

class MoveDomainForm extends Component<Props> {
  handleConfirm = (data) => {
    return this.props
      .moveDomain({
        variables: {
          input: {
            id: data.domain.id,
            client: data.client.id,
          },
        },
      })
      .then(
        ({
          data: {
            moveDomain: { errors },
          },
        }) => {
          if (errors && errors.length) {
            throwSubmitErrors(errors);
          }

          toast.success(t('Domain moved'));
        },
        throwNetworkError,
      );
  };
  handleSubmit = (data) => {
    return this.props.client
      .query({
        query: groupAndDomainQuery,
        variables: {
          domainId: data.domain,
          clientId: data.group,
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data: { domainsSearch: domains, clientsSearch: clients } }) => {
        const domain = domains[0];
        const client = clients[0];
        this.props.showModal({
          modalType: 'Confirmation',
          modalProps: {
            cancelLabel: t('Back'),
            confirmLabel: t('Move domain'),
            lockDuration: 0,
            title: t('Move domain?'),
            description: t(
              'Are you sure you wish to move: %s (%s) from organization %s (%s) to %s (%s) on organization %s (%s).',
              domain.domain,
              domain.id,
              domain.client.organization.name,
              domain.client.organization.id,
              client.name,
              client.id,
              client.organization.name,
              client.organization.id,
            ),
            action: () =>
              this.handleConfirm({
                domain,
                client,
              }),
            cancelAction: () =>
              this.props.showModal({
                modalType: 'MoveDomain',
                modalProps: {
                  initialValues: data,
                },
              }),
          },
        });
      });
  };
  domainOptionsLoader = (query: string) => {
    return this.props.client
      .query({
        query: domainsQuery,
        variables: {
          searchQuery: query,
        },
      })
      .then(
        ({ data: { domainsSearch } }) =>
          domainsSearch?.map((domain) => ({
            label: `(${domain.id}) ${domain.displayName ? `${domain.displayName} ` : ''}${
              domain.domain
            }`,
            value: domain.id,
          })) ?? [],
      );
  };
  groupOptionsLoader = (query: string) => {
    return this.props.client
      .query({
        query: groupsQuery,
        variables: {
          searchQuery: query,
        },
      })
      .then(
        ({ data: { clientsSearch } }) =>
          clientsSearch.map((client) => ({
            label: `(${client.id}) ${client.name}`,
            value: client.id,
          })) ?? [],
      );
  };

  render() {
    const { handleSubmit, invalid, submitting } = this.props;
    return (
      <form className="move-domain-form" onSubmit={handleSubmit(this.handleSubmit)}>
        <div>{t('Use this form to move domain to the group.')}</div>
        <div className={formStyles.formRow}>
          <div className="form-label required">{t('Domain')}</div>
          <Field
            name="domain"
            type="text"
            placeholder={t('Select domain')}
            loadOptions={this.domainOptionsLoader}
            component={Select}
            clientSideSearch
            searchPromptText={t('Type to search')}
            validate={[Validator.required]}
            autoFocus={true}
          />
        </div>
        <div className={formStyles.formRow}>
          <div className="form-label required">{t('Group')}</div>
          <Field
            name="group"
            type="text"
            placeholder={t('Select group')}
            loadOptions={this.groupOptionsLoader}
            component={Select}
            searchPromptText={t('Type to search')}
            validate={[Validator.required]}
          />
        </div>
        <ModalFooter
          primaryButtonSlot={
            <AccButton
              disabled={invalid || submitting}
              type="submit"
              variant="primary"
              loading={submitting}
            >
              {t('Move domain')}
            </AccButton>
          }
          secondaryButtonSlot={
            <AccButton variant="tertiary" onClick={this.props.onClose}>
              {t('Cancel')}
            </AccButton>
          }
        />
      </form>
    );
  }
}

const moveDomainMutations = gql`
  mutation moveDomainForm_moveDomain($input: MoveDomainInput!) {
    moveDomain(input: $input) {
      errors {
        messages
        field
      }
    }
  }
`;
export default compose(
  withApollo,
  connect(null, {
    showModal,
  }),
  graphql(moveDomainMutations, {
    name: 'moveDomain',
  }),
  reduxForm({
    form: 'MoveDomainForm',
  }),
)(MoveDomainForm);
