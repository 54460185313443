import { HIDE_CONNECTOR_PREFIX } from '../../../../Utilities/Table/Tree/constants';
import type { NodeLike } from './types';
import { WithTreeState } from './types';

export const isNodeExpanded = (node: NodeLike) => node.state?.expanded;
export const nodeHasChildren = (node: NodeLike) => node.children?.length;

type FlattenTreeAndAddMetaInfoParams = {
  nodes: NodeLike[] | undefined;
  parents?: (string | number)[] | undefined;
  hiddenConnectors?: string[] | undefined;
  childrenLoadingKey?: string;
};
export const flattenTreeAndAddMetaInfo = ({
  nodes,
  parents = [],
  hiddenConnectors = [],
  childrenLoadingKey = 'children_loading',
}: FlattenTreeAndAddMetaInfoParams) => {
  return (nodes || []).reduce((acc, node, i) => {
    const isLastChild = i === (nodes?.length || 0) - 1 && !!parents.length;
    const deepness = parents.length;
    const nodeWithHelpers = { ...node, deepness, parents, hiddenConnectors, isLastChild };
    // Add loading state for children if not loaded
    if (node[childrenLoadingKey] && isNodeExpanded(node)) {
      return [
        ...acc,
        nodeWithHelpers,
        {
          deepness: deepness + 1,
          parents: [...parents, node.id],
          hiddenConnectors: isLastChild
            ? [...hiddenConnectors, `${HIDE_CONNECTOR_PREFIX}${deepness - 1}`]
            : hiddenConnectors,
          isLastChild: true,
          state: { loading: true },
        },
      ];
    }
    if (!nodeHasChildren(node) || !isNodeExpanded(node)) {
      return [...acc, nodeWithHelpers];
    }

    return [
      ...acc,
      nodeWithHelpers,
      ...flattenTreeAndAddMetaInfo({
        nodes: node.children || [],
        parents: [...parents, node.id],
        hiddenConnectors: isLastChild
          ? [...hiddenConnectors, `${HIDE_CONNECTOR_PREFIX}${deepness - 1}`]
          : hiddenConnectors,
        childrenLoadingKey,
      }),
    ];
  }, [] as NodeLike[]);
};

export function applyExpandedToTree(
  tree: NodeLike[] | null | undefined,
  expandedNodeIds: (string | number)[] | undefined,
  expandDataKey: string,
  overwriteExpandedState?: boolean,
): NodeLike<WithTreeState>[] {
  function applyExpandedToItem(node: NodeLike) {
    const nodeId = node?.[expandDataKey];
    const updatedNode = {
      ...node,
      children: node.children ?? [],
      state: expandedNodeIds?.includes(nodeId)
        ? { expanded: true }
        : overwriteExpandedState
        ? { expanded: false }
        : node.state,
      id: nodeId,
    };

    if (updatedNode.children?.length) {
      updatedNode.children = updatedNode.children.map(applyExpandedToItem);
    }

    return updatedNode;
  }

  return tree?.map?.(applyExpandedToItem) ?? [];
}
