import { Group } from '@mantine/core';
import AccButton from 'Components/AccButton/AccButton';
import ConnectedLabel from 'Components/ConnectedLabel';
import AccText from 'Components/Text/AccText';
import { t } from 'Utilities/i18n';

type Props = {
  has2FA: boolean;
  loading: boolean;
};

const TwoFactor = ({ has2FA, loading }: Props) => {
  if (loading) {
    return <div>...</div>;
  }

  // const icon = has2FA ? <CheckIcon color={'green'} size={18} /> : <IconAlertTriangle size={18} />;
  const label = has2FA
    ? t('2FA is on for this account')
    : t('2FA is not configured for this account!');
  const btnLabel = has2FA ? t('Details') : t('Enable');
  const btnVariant = has2FA ? 'tertiary' : 'secondary';

  return (
    <>
      <AccText mb={0}>
        {t('We recommend to always enable 2FA authentication for increased security.')}
      </AccText>
      <Group justify="space-between">
        <ConnectedLabel isConnected={has2FA} description={label} />
        <AccButton href="/user/account/two_factor/" target="_self" variant={btnVariant}>
          {btnLabel}
        </AccButton>
      </Group>
    </>
  );
};

export default TwoFactor;
