import { useRef } from 'react';
import { Box } from '@mantine/core';
import {
  COMMERCIAL,
  INFORMATIONAL,
  NAVIGATIONAL,
  TRANSACTIONAL,
} from 'Components/Modal/Content/AddSearchEngine/types';
import { OverviewSearchIntentGraphNode } from 'Ghql';
import { useFilters } from 'Hooks';
import { getUpdatedArrayFilter, useSetOrOverwriteFilter } from 'Hooks/data/filter/setFilters';
import { BarChart } from 'Pages/Keywords/Overview/components/BarChart/BarChart';
import { ChartContainer } from 'Pages/Keywords/Overview/components/ChartContainer/index';
import { WithDragDetails } from 'Pages/Keywords/Overview/support/types';
import { SEARCH_INTENT, SearchIntent } from 'Types/Filter';
import { FilterTrackingKey } from 'Utilities/Analytics/mixpanel';
import { colorScheme } from 'Utilities/colors';
import { t } from 'Utilities/i18n';
import SearchIntentIcon from 'icons/search-intent/searchIntentIcon';

type ChartContentProps = {
  searchIntentNode: OverviewSearchIntentGraphNode | undefined;
  loading?: boolean;
  minHeight?: number;
  includeKebabMenu?: boolean;
  /** Filter by intent when clicking the bars in the chart. Defaults to `true` */
  setFilterFromChart?: boolean;
} & WithDragDetails;

export const SearchIntentChartContent = (props: ChartContentProps) => {
  const {
    searchIntentNode,
    loading,
    minHeight,
    includeKebabMenu = true,
    setFilterFromChart = true,
  } = props;
  const filters = useFilters();
  const chartRef = useRef<HTMLDivElement>(null);
  const setFilter = useSetOrOverwriteFilter();

  const getUpdatedFilter = (intent: SearchIntent) =>
    getUpdatedArrayFilter(filters, intent, SEARCH_INTENT);

  return (
    <ChartContainer
      includeKebabMenu={includeKebabMenu}
      minHeight={minHeight}
      chartRef={chartRef}
      title={t('Search Intent')}
      tooltip={[
        t(
          'Aggregated search intent for all keywords in the current filtering. The size of the bar corresponds to the percentage of keywords in the current filtering with the corresponding search intent.',
        ),
      ]}
      dragRef={props.dragRef}
      boardControls={props.boardControls}
    >
      <Box ref={chartRef} bg="white">
        {loading && <BarChart items={[]} loading={true} total={0} normalNumberOfItems={4} />}
        {!loading && (
          <BarChart
            total={searchIntentNode?.keywordCount}
            enableMultiValueOverlap
            loading={loading}
            key={`SI-${searchIntentNode?.I} ${searchIntentNode?.C} ${searchIntentNode?.N} ${searchIntentNode?.T}`} // Key to fix https://accuranker.myjetbrains.com/youtrack/agiles/124-2/current?issue=ARR-2551
            items={[
              {
                value: searchIntentNode?.N || 0,
                icon: <SearchIntentIcon searchIntent={'N'} size={22} withTooltip />,
                // label: 'Navigational',
                background: colorScheme.graphs.searchIntent.N,
                onClick: setFilterFromChart
                  ? () => {
                      setFilter(
                        getUpdatedFilter(NAVIGATIONAL),
                        FilterTrackingKey.SearchIntentChart,
                      );
                    }
                  : undefined,
                tooltip:
                  setFilterFromChart && searchIntentNode?.N
                    ? t('Add "Navigational" as filter')
                    : undefined,
              },
              {
                value: searchIntentNode?.I || 0,
                icon: <SearchIntentIcon searchIntent={'I'} size={22} withTooltip />,
                // label: 'Informational',
                background: colorScheme.graphs.searchIntent.I,
                onClick: setFilterFromChart
                  ? () => {
                      setFilter(
                        getUpdatedFilter(INFORMATIONAL),
                        FilterTrackingKey.SearchIntentChart,
                      );
                    }
                  : undefined,
                tooltip:
                  setFilterFromChart && searchIntentNode?.I
                    ? t('Add "Informational" as filter')
                    : undefined,
              },
              {
                value: searchIntentNode?.C || 0,
                icon: <SearchIntentIcon searchIntent={'C'} size={22} withTooltip />,
                // label: 'Commercial',
                background: colorScheme.graphs.searchIntent.C,
                onClick: setFilterFromChart
                  ? () => {
                      setFilter(getUpdatedFilter(COMMERCIAL), FilterTrackingKey.SearchIntentChart);
                    }
                  : undefined,
                tooltip:
                  setFilterFromChart && searchIntentNode?.C
                    ? t('Add "Commercial" as filter')
                    : undefined,
              },
              {
                value: searchIntentNode?.T || 0,
                icon: <SearchIntentIcon searchIntent={'T'} size={22} withTooltip />,
                // label: 'Transactional',
                background: colorScheme.graphs.searchIntent.T,
                onClick: setFilterFromChart
                  ? () => {
                      setFilter(
                        getUpdatedFilter(TRANSACTIONAL),
                        FilterTrackingKey.SearchIntentChart,
                      );
                    }
                  : undefined,
                tooltip:
                  setFilterFromChart && searchIntentNode?.T
                    ? t('Add "Transactional" as filter')
                    : undefined,
              },
            ]}
          />
        )}
      </Box>
    </ChartContainer>
  );
};
