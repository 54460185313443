import { useEffect, useMemo, useState } from 'react';
import { OperationVariables, useLazyQuery, useQuery } from '@apollo/client';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import { useTableStore } from 'Components/DataTable';
import { fakeTableDetails } from 'Constants/data';
import { useFilters } from 'Hooks';
import { useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import { useValueStore } from 'Hooks/mobx/useValueStore';
import { colorScheme } from 'Utilities/colors';
import { QUERY_COMPARE_CHART_NOTES } from './compareChart.query';
import {
  ChartLineChartConfig,
  ChartMetricProps,
  FetchCompareChartDataProps,
  GetMetricTypes,
} from './types';

export const useChartMetric = (
  defaultMetric: string,
  getMetrics: GetMetricTypes,
): ChartMetricProps => {
  const [superCellCollapsed, setSuperCellCollapsed] = useState(true);
  const [metric, setMetric] = useState<string>(defaultMetric);
  const toggleCollapse = () => setSuperCellCollapsed(!superCellCollapsed);

  const metricsOptions = getMetrics();

  const metricPlaceholder = metricsOptions.find((option) => option.value === metric)?.label ?? '';
  return {
    superCellCollapsed,
    metricPlaceholder,
    metricsOptions,
    toggleCollapse,
    metric,
    setMetric,
  };
};

interface UseFetchCompareChartDataProps<R, V>
  extends FetchCompareChartDataProps<R, V>,
    ChartLineChartConfig {
  metric: string;
  displayCurrency?: string;
  skip?: boolean;
}

// check https://accuranker.myjetbrains.com/youtrack/issue/ARR-1866
const useCompareChartColors = (
  selectedRows?: string[],
  tableData?: any[],
  config?: ChartLineChartConfig,
) => {
  const [defaultColor, ...otherColors] = colorScheme.graphs.getColors();
  const [resultColors, setColors] = useState({});
  const items: string[] =
    selectedRows
      ?.map((id) => tableData?.find((e) => id === e?.id))
      .map((e) => config?.extractColorLineId?.(e) as string)
      .filter(Boolean) ?? [];

  useEffect(() => {
    let index = Object.keys(resultColors).length;
    const result = { ...resultColors };
    items.forEach((e) => {
      if (!resultColors[e]) {
        result[e] = config?.getIsDefaultSelect?.(e)
          ? defaultColor
          : otherColors[index % otherColors.length];
        index++;
      }
    });
    if (!isEqual(result, resultColors)) {
      setColors(result);
    }
  }, [items.join('_')]);

  return resultColors;
};

export const useFetchCompareChartData = <R, V>(props: UseFetchCompareChartDataProps<R, V>) => {
  const { domainInfo, asPercentage } = useQueryDomainInfo();
  const [initialLoad, setInitLoad] = useState(true);
  const tableStore = useTableStore(props.tableId, false);
  const tableData = useValueStore(tableStore, 'data');
  const selectedRows = useValueStore(tableStore, 'selectedRows');
  const skip = !domainInfo || (initialLoad && !selectedRows?.length) || props.skip;

  const filters = useFilters();
  const [chartVersion, setVersion] = useState(0);
  const setNextVersion = () => setVersion((v) => v + 1);
  const { data: notesData, loading: isLoadingNotes } = useQuery(
    props.notesQuery || QUERY_COMPARE_CHART_NOTES,
    {
      variables: { filters, ...fakeTableDetails },
    },
  );

  const variables = {
    ...props.getVariables({
      filters,
      metric: props.metric,
      selectedRows,
      asPercentage,
    }),
    displayCurrency: props.displayCurrency,
  };

  const [fetchData, { loading: isLoading, data }] = useLazyQuery<R, OperationVariables>(
    props.chartQuery,
    {
      variables,
      onCompleted: () => {
        if (initialLoad) {
          setInitLoad(true);
        }
        setNextVersion();
      },
    },
  );

  const series = useMemo(() => props.extractData(data), [chartVersion, notesData]);

  useEffect(() => {
    if (!skip) {
      fetchData({ variables });
    }
  }, [JSON.stringify(variables), skip, tableStore?.pagination?.page]);
  const resultColors = useCompareChartColors(
    selectedRows,
    tableData,
    pick(props, 'extractColorLineId', 'getIsDefaultSelect'),
  );

  const getNotes = () => {
    if (props.extractNotes) {
      return props.extractNotes(notesData) ?? [];
    }

    return notesData?.keywords?.notes;
  };

  return {
    notesData: getNotes(),
    series,
    isLoading: isLoading || tableStore?.loading,
    isLoadingNotes,
    colors: resultColors,
    chartVersion,
  };
};
