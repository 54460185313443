import * as React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import RemoveIcon from 'icons/close-2.svg?inline';
import './location-field.scss';

type Props = {
  placeholder: string;
  value: string;
  onChange: (...args: Array<any>) => any;
  onDelete?: (...args: Array<any>) => any;
  canDelete?: boolean;
  selectedCountry?: Record<string, any>;
  disabled?: boolean;
};

const LocationInput: React.FC<Props> = ({
  placeholder,
  value,
  onChange,
  onDelete,
  canDelete = false,
  selectedCountry,
  disabled = false,
}) => {
  const renderDeleteButton = () => {
    if (!onDelete || !canDelete) return null;
    return <RemoveIcon className="remove-icon" onClick={onDelete} />;
  };

  const inputProps = {
    placeholder,
    value,
    onChange,
    disabled,
  };

  const cssClasses = {
    input: 'text-input-control',
    autocompleteContainer: 'autocomplete-container',
    autocompleteItem: 'autocomplete-item',
  };

  const options: Record<string, any> = {
    types: ['(cities)'],
  };

  if (selectedCountry) {
    options.componentRestrictions = {
      country: selectedCountry,
    };
  }

  return (
    <span>
      <PlacesAutocomplete
        options={options}
        inputProps={inputProps}
        classNames={cssClasses}
        googleLogo={false}
      />
      {renderDeleteButton()}
    </span>
  );
};

export default LocationInput;
