import React from 'react';
import cn from 'classnames';
import LoadingIcon from 'icons/loading.svg?inline';
import './loading-spinner.scss';

type Props = {
  white?: boolean;
  className?: string;
  tooltip?: string;
  containerClassName?: string;
  size?: 'large';
};

const LoadingSpinner = ({ white, size, className, tooltip, containerClassName }: Props) => {
  return (
    <div className={cn('loading-spinner-container', containerClassName)}>
      <LoadingIcon
        className={cn(
          'loading-spinner',
          { 'loading-spinner--white': white, [`loading-spinner--${size}`]: size },
          className,
        )}
      />
      {tooltip && (
        <div className={'loading-spinner-container__tooltip'}>
          <p>{tooltip}</p>
        </div>
      )}
    </div>
  );
};

export default React.memo<Props>(LoadingSpinner);
