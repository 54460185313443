import React, { useContext } from 'react';
import { DragElementWrapper, useDrop } from 'react-dnd';
import { hideLine, moveLine } from '../../components/TargetLine';
import { ItemTypes } from '../ItemTypes';
import { useTreeContext } from '../hooks';
import { PlaceholderContext } from '../providers';
import { NodeModel } from '../types';
import { getDropTarget, isDroppable, isNodeModel } from '../utils';

export const useDropNode = <T>(
  item: NodeModel,
  ref: React.RefObject<HTMLElement>,
): [boolean, NodeModel, DragElementWrapper<HTMLElement>] => {
  const treeContext = useTreeContext<T>();
  const placeholderContext = useContext(PlaceholderContext);
  const [{ isOver, dragSource }, drop] = useDrop({
    accept: [ItemTypes.TREE_ITEM, ...treeContext.extraAcceptTypes],
    drop: (dragItem: any, monitor) => {
      const { dropTargetId, index, direction } = placeholderContext;
      hideLine();

      if (monitor.isOver({ shallow: true }) && dropTargetId !== undefined && index !== undefined) {
        // If the drag source is outside the react-dnd,
        // a different object is passed than the NodeModel.
        treeContext.onDrop(isNodeModel(dragItem) ? dragItem : null, dropTargetId, index, direction);
      }

      placeholderContext.hidePlaceholder();
    },
    canDrop: (dragItem, monitor) => {
      if (monitor.isOver({ shallow: true })) {
        const dropTarget = getDropTarget<T>(item, ref.current, monitor, treeContext);

        if (dropTarget === null) {
          return false;
        }

        return isDroppable(
          // isNodeModel(dragItem) ? dragItem.id : undefined,
          dragItem.id,
          dropTarget.id,
          treeContext,
        );
      }

      return false;
    },
    hover: (dragItem, monitor) => {
      if (monitor.isOver({ shallow: true })) {
        const { dropTargetId, index, showPlaceholder, hidePlaceholder, direction } =
          placeholderContext;

        const dropTarget = getDropTarget<T>(item, ref.current, monitor, treeContext);

        const dropId: string = dropTarget?.id as string;

        if (!monitor.canDrop()) {
          hideLine();
          hidePlaceholder();
          return;
        }

        if (
          dropTarget === null ||
          !isDroppable(isNodeModel(dragItem) ? dragItem.id : undefined, dropId, treeContext)
        ) {
          hidePlaceholder();
          return;
        }

        if (
          dropId !== dropTargetId ||
          dropTarget.index !== index ||
          direction !== dropTarget?.direction
        ) {
          showPlaceholder(dropId, dropTarget.index, dropTarget?.direction);
          moveLine(treeContext, dropId, dropTarget?.direction, dropTarget?.index);
        }
      }
    },
    collect: (monitor) => {
      const source: NodeModel = monitor.getItem();
      const isOverElement = monitor.isOver({ shallow: true }) && monitor.canDrop();
      return {
        isOver: isOverElement,
        dragSource: source,
      };
    },
  });
  return [isOver, dragSource, drop];
};
