import { useSelector } from 'react-redux';
import { StoreType } from 'Types/Store';

export const selectOrganizationDisplayCurrency = (state: StoreType): string | undefined =>
  state.user.organization?.displayCurrency ?? undefined;

export const selectOrganizationUnrankedWeight = (state: StoreType): number | null =>
  state.user.organization?.unrankedWeight ?? null;

export const selectOrganizationId = (state: StoreType): string | null =>
  state?.user?.organization?.id ?? null;

export const useOrganizationSelector = () =>
  useSelector((state: StoreType) => state?.user?.organization);

export const useOrganizationInfo = () => {
  const organization = useOrganizationSelector();

  const isTrial = organization?.activePlan?.isTrial ?? false;
  const endDate = organization?.activePlan?.endDate ?? undefined;
  const startDate = organization?.activePlan?.startDate ?? undefined;
  const hasNextPlan = !!organization?.nextPlan;
  const invoiceStatus = organization?.errors?.invoiceStatus; // 0 is OK, 1 is overdue, 2 is very overdue
  const specialOfferId = organization?.dealType;
  return { organization, isTrial, endDate, hasNextPlan, invoiceStatus, startDate, specialOfferId };
};
