import { useCallback } from 'react';
import * as Sentry from '@sentry/react';
import {
  RelevantDomainSearchesDocument,
  useAddDomainSearchMutation,
  useQuickNavigationDataQuery,
  useRelevantDomainSearchesQuery,
} from 'Ghql';
import { truncateString } from 'Pages/KeywordDiscovery/support/helpers';
import { t } from 'Utilities/i18n';
import { notEmpty } from 'Utilities/underdash';
import { SelectedDomain } from '../../index';
import { DomainItem } from './index';

const SEARCH_HISTORY_LENGTH: number = 5;

type ClientDomain = {
  displayName: string;
  domain: string;
  id: number;
};

type Client = {
  clientId: number;
  clientName: string;
  domains: ClientDomain[];
  organizationId: number;
};

/**Get the hostname of an url */
const getLabel = (searchString: string | undefined) => {
  if (!searchString) return '';
  //validation isUrl will return undefined if this is a valid url
  return searchString.split('/')[0];
};

const DOMAIN_GROUP: string = t('Your Domains');

const getLoadingOption = (group: string) => ({
  label: 'Loading domains...',
  value: 'loading',
  disabled: true,
  description: t('Please wait'),
  group,
});

const DOMAIN_VALUE_SEPARATOR = ';separator&';

const createCombinedDomainValue = (domainInfo: SelectedDomain): string => {
  return `${domainInfo.domain}${domainInfo.domainId ? DOMAIN_VALUE_SEPARATOR : ''}${
    domainInfo.domainId ?? ''
  }`;
};

export const extractDomainInfoFromValue = (val: string): SelectedDomain => {
  const [domain, domainId] = val.split(DOMAIN_VALUE_SEPARATOR);
  return { domain, domainId };
};

/** Maximum number of displayed items in the dropdown */
const OPTIONS_LIMIT = 50;

/**
 * Get all- or filtered domainItems used for the `<KeydisDomainSelect>` component.
 * @example
 * const { domainItems, filteredDomainItems } = useSelectItems(searchValue, selectedDomain);
 */
export const useSelectItems = (
  searchValue: string,
  selectedDomain: SelectedDomain,
  searchHistory: DomainItem[],
) => {
  const { loading, data } = useQuickNavigationDataQuery({
    fetchPolicy: 'cache-first',
  });

  /** Filter the complete history according to textinput */
  const getFilteredSearchHistory = useCallback(() => {
    const filteredSearch = searchHistory.filter((historyEntry) =>
      historyEntry?.label?.toLowerCase().includes(searchValue.toLowerCase()),
    );
    /** Limit search history limited to last SEARCH_HISTORY_LENGTH searches */
    const latestSearchHistory =
      filteredSearch.length > SEARCH_HISTORY_LENGTH
        ? filteredSearch.slice(0, SEARCH_HISTORY_LENGTH)
        : filteredSearch;
    return latestSearchHistory;
  }, [searchValue, searchHistory]);

  const filteredSearchHistory = getFilteredSearchHistory();

  const createDomainItems: () => {
    allItems: DomainItem[];
    filteredItems: DomainItem[];
  } = useCallback(() => {
    const domainItemsArray: DomainItem[] = [];

    const clients: Client[] = data?.user?.quickNavigationData?.filter(notEmpty) ?? [];

    clients?.map((client) => {
      return client.domains?.map((item) => {
        if (item) {
          const label = item?.displayName || getLabel(item?.domain);
          domainItemsArray.push({
            key: `domain_${label}_${client.clientName}`,
            label,
            group:
              clients.length > 1
                ? `${t('Group')} - ${truncateString(client.clientName)}`
                : DOMAIN_GROUP,
            domain: item?.domain,
            domainId: item?.id.toString(),
            value: createCombinedDomainValue({
              domain: item.domain,
              domainId: item.id.toString(),
            }),
            description: item?.domain,
            searchBy: (item?.domain || '') + (item?.displayName || ''),
          });
        }
      });
    });

    const filteredItems = domainItemsArray
      .filter((client) =>
        (searchValue
          ? client.label?.toLowerCase().includes(searchValue?.toLowerCase()) ||
            client.value.toString().toLowerCase().includes(searchValue?.toLowerCase())
          : true),
      )
      .filter((client) => client.domainId !== selectedDomain?.domainId)
      .slice(0, OPTIONS_LIMIT);

    return loading
      ? {
          allItems: [getLoadingOption(DOMAIN_GROUP)],
          filteredItems: [getLoadingOption(DOMAIN_GROUP)],
        }
      : { allItems: domainItemsArray, filteredItems };
  }, [data, loading, searchValue, selectedDomain.domainId]);

  const { allItems, filteredItems } = createDomainItems();

  const domainItemIsSelected = !!allItems.find(
    (client) =>
      client.domain?.toLowerCase() === searchValue?.toLowerCase() ||
      client.label.toString().toLowerCase() === searchValue?.toLowerCase(),
  );

  const searchHistoryIsSelected = !!searchHistory.find(
    (historyEntry) => historyEntry?.label?.toLowerCase() === searchValue.toLowerCase(),
  );

  const selected = !!selectedDomain.domain && (domainItemIsSelected || searchHistoryIsSelected);

  const filteredDomainItems = selected ? allItems.slice(0, OPTIONS_LIMIT) : filteredItems;

  return {
    domainItems: allItems,
    filteredDomainItems,
    filteredSearchHistory: selected
      ? searchHistory.slice(0, SEARCH_HISTORY_LENGTH)
      : filteredSearchHistory,
  };
};

/**Get search history or add an entry to the history
 * @example
 * const { searchHistory, addRecentSearch } = useSearchHistory();
 *
 * addRecentSearch(search)
 *
 */
export const useSearchHistory = () => {
  const { data } = useRelevantDomainSearchesQuery();
  const [AddDomainSearchMutation] = useAddDomainSearchMutation();

  const relevantDomainSearchesHistory =
    data?.relevantDomainSearches?.domainTypes?.latestSearches?.filter(notEmpty) ?? [];

  const RECENT_SEARCES_GROUP: string = t('Recent Searches');

  const getSearchHistory: () => DomainItem[] = useCallback(() => {
    return relevantDomainSearchesHistory.map((search) => ({
      key: `history_${search}`,
      label: getLabel(search),
      value: search,
      domain: search,
      description: search,
      searchBy: search,
      group: RECENT_SEARCES_GROUP,
    }));
  }, [data]);

  const rawSearchHistory = getSearchHistory();

  const { data: quickNavigationData } = useQuickNavigationDataQuery({
    fetchPolicy: 'cache-first',
  });

  const clients = quickNavigationData?.user?.quickNavigationData?.filter(notEmpty) ?? [];

  //Create an array of all domainURLs and domain displaynames
  const domainList =
    clients
      ?.flatMap((domainlist) =>
        domainlist?.domains?.flatMap((domain) => {
          const domainInfo = [
            domain?.domain?.toLowerCase(),
            domain?.displayName?.toLowerCase(),
          ].filter(notEmpty);
          return domainInfo;
        }),
      )
      .filter(notEmpty) ?? [];

  //Filter out any dublicates in searchhistory and domainList
  const searchHistory = rawSearchHistory.filter(
    (historyEntry) =>
      typeof historyEntry?.value === 'string' &&
      !domainList.includes(historyEntry?.value.toLowerCase()),
  );

  /**Add current search to search history */
  const addRecentSearch = async (search: string) => {
    if (search === '') {
      return;
    }
    await AddDomainSearchMutation({
      variables: { searchTerm: search },
      refetchQueries: [
        {
          query: RelevantDomainSearchesDocument,
        },
      ],
      onError: (error) => {
        const errorMessage = `Failed to update searchHistory. [GraphQL error]: Message: ${error.message}`;
        Sentry.captureException(errorMessage);
      },
      optimisticResponse: {
        addKeywordResearchDomainSearch: {
          searchTerm: search,
          __typename: 'AddKeywordResearchDomainSearch',
          errors: null,
        },
      },
    });
  };
  return { searchHistory, addRecentSearch };
};
