import React from 'react';
import { Divider, Flex } from '@mantine/core';
import compose from 'lodash/flowRight';
import isEqual from 'lodash/isEqual';
import { observer } from 'mobx-react';
import AccessControl from 'Components/AccessControl';
import HelpGuideButton from 'Components/HelpGuideButton';
import { getDisabledDemoText } from 'Constants/messages';
import useKeyword from 'Hooks/useKeyword';
import useUserPermission from 'Hooks/useUserPermission';
import * as Actions from 'Pages/Layout/ActionsMenu/Actions';
import { DomainInfo } from 'Query';
import { FilterBase } from 'Types/Filter';
import { TableID } from 'Types/Table';
import { t } from 'Utilities/i18n';
import BulkActions from './BulkActions';
import {
  WithKeywordActionMenuDataProps,
  withKeywordActionMenuData,
} from './withKeywordActionMenuData';

interface KeywordActionMenuProps {
  tableName: TableID;
  domainInfo?: DomainInfo;
  filters: FilterBase[];
}

const ActionMenuList = (props: KeywordActionMenuProps & WithKeywordActionMenuDataProps) => {
  const { manualSelectedKeywords, isAllSelected, domainId, actions, showCompare, domainInfo } =
    props;
  const { showOneTimeReportModal, showAddKeywordsModal } = useKeyword({
    showModal: actions.showModal,
  });

  const { isDemoDomain } = domainInfo || {};
  const { userHasWriteAccess } = useUserPermission();
  const showActions =
    (manualSelectedKeywords.length > 0 || isAllSelected) && !isDemoDomain && userHasWriteAccess;

  return (
    <>
      {domainId ? (
        <>
          <AccessControl>
            <Actions.AddAction
              disabled={isDemoDomain}
              tooltip={isDemoDomain ? getDisabledDemoText() : undefined}
              key="add"
              label={t('Add keywords')}
              onClick={() => {
                showAddKeywordsModal({
                  domainId,
                });
              }}
            />
          </AccessControl>
          <Actions.ReportAction key="report" onClick={() => showOneTimeReportModal({ domainId })} />
          <HelpGuideButton
            helpguideLink={'https://www.accuranker.com/help/keywords/the-keyword-table/'}
          />
          <Flex columnGap="xs">
            {showActions || !!showCompare ? (
              <Divider h={26} m="auto" orientation="vertical" color="gray.2" />
            ) : null}
            <BulkActions {...props} showActions={showActions} withCount={true} />
          </Flex>
        </>
      ) : (
        <Actions.ReportAction key="report" onClick={() => showOneTimeReportModal({ domainId })} />
      )}
    </>
  );
};

export default compose(observer, withKeywordActionMenuData)(React.memo(ActionMenuList, isEqual));
