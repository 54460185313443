import { Box } from '@mantine/core';
import cn from 'classnames';
import { Field, Fields } from 'redux-form';
import FilterCountField from 'Components/Filters/FilterCountField';
import { DropdownField } from 'Components/Forms/Fields';
import { NO_FILTER } from 'Types/Filter';
import { withDefaultFormValue } from 'Utilities/Form/withDefaultFormValue';
import FilterCount from '../FilterCount';
import '../common.scss';

type Props = {
  items: any;
  name?: string;
  isEmpty?: boolean;
  iconDropdown?: boolean;
  noFilterIcon?: Element;
  handleSelect?: (...args: Array<any>) => any;
  showLabel?: boolean;
  defaultValue?: string | number | null;
  withCount?: boolean;
  countLoading?: boolean;
};

const OptionEditor = ({
  items,
  name,
  isEmpty,
  iconDropdown,
  noFilterIcon,
  handleSelect,
  showLabel = true,
  defaultValue,
  countLoading,
  withCount,
}: Props) => {
  const labelFunc = (item: any) => {
    const Count = <FilterCount count={item.count} inDropdown loading={countLoading} />;

    if (iconDropdown) {
      const className = cn('icon', item.iconClassName, { 'no-filter': isEmpty });
      const Icon = !isEmpty ? item.icon : noFilterIcon;
      return showLabel && item.label ? (
        <div className="icon-container">
          <span style={{ marginRight: '10px' }}>
            <Icon className={className} width={'16px'} height={'16px'} />
          </span>
          {item.label}
          {Count}
        </div>
      ) : (
        <Icon className={className} width={'16px'} height={'16px'} />
      );
    }

    return (
      <>
        {item.label || item}
        {Count}
      </>
    );
  };

  const iconFunc = (item: any) => {
    const Icon = item.icon;
    if (iconDropdown && Icon !== undefined) {
      return <Icon className={cn('icon', item.iconClassName)} />;
    }
  };

  const doHandleSelect = (value: any) => {
    handleSelect?.({
      value,
      reset: value === NO_FILTER,
    });
  };

  return (
    <Box pos="relative">
      {withCount && !items.some((item) => Number.isInteger(item.count)) && (
        <Fields
          names={['comparison', 'value', 'type', 'attribute']}
          component={FilterCountField}
          pos="absolute"
          right="28px"
          top="11px"
        />
      )}
      <Field
        name={name || 'value'}
        component={withDefaultFormValue(DropdownField)}
        className="filter-button"
        itemClassName="filter-button"
        handleSelect={doHandleSelect}
        items={items}
        isEmpty={isEmpty} // using this property to force update
        dropDownLabelFunc={labelFunc}
        iconFunc={iconFunc}
        defaultValue={defaultValue}
      />
    </Box>
  );
};

export default OptionEditor;
