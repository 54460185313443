import { useMemo } from 'react';
import { useField } from 'react-final-form';
import { Field } from 'Components/Fields';
import SelectOption from 'Components/SelectOption';
import { UserRole, getAllUserRoleOptions } from 'Constants/workspaces';
import styles from './field.module.scss';

type Props = {
  defaultValue: UserRole;
  name: string;
  disabled?: boolean;
  withinPortal?: boolean;
  size?: 'default' | 'sm' | 'md' | 'lg';
};

const FieldUserRole = (props: Props) => {
  const { name, size, defaultValue: initialValue, disabled, withinPortal } = props;
  const userRoleOptions = useMemo(getAllUserRoleOptions, []);
  const field = useField(name, { initialValue });
  const onChange = (val: number) => {
    if (val) field.input.onChange(val);
  };
  return (
    <Field.SelectValue
      itemComponent={SelectOption}
      name={name}
      searchable={false}
      clearable={false}
      classNames={{ options: styles.selectOptions }}
      options={userRoleOptions}
      size={size || 'default'}
      value={field.input.value}
      disabled={disabled}
      withinPortal={withinPortal}
      dropdownWidth={400}
      onChange={onChange}
      dropdownPosition="bottom-start"
    />
  );
};

export default FieldUserRole;
