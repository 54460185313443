import type { RouteProps } from 'react-router-dom';
import { RoutePath } from 'Constants/Routes';
import {
  useMultiAccountAcceptMultiAccountRequestMutation,
  useMultiAccountPendingRequestsQuery,
  useMultiAccountRemoveAccessRequestMutation,
} from 'Ghql';
import toast from 'Hooks/useToast';
import { withRouter } from 'Utilities/Router';
import { t } from 'Utilities/i18n';
import MultiAccountActionButtons from './components/MultiAccountActionButtons';
import MultiAccountRequestTextBlock from './components/MultiAccountRequestTextBlock';
import {
  combineMutationError,
  getDeletedPendingRequests,
  getExistingMultiAccountLinks,
  getExistingMultiAccountNameById,
  getMultiAccountRequestDetails,
} from './helpers';
import { useInviteSentFromOwnAccount } from './hooks';
import './multi-account-allow-access.scss';

// TODO FixTSignore
function MultiAccountAllowAccess(props: RouteProps & any) {
  const { data, loading: pendingRequestsLoading } = useMultiAccountPendingRequestsQuery();

  const deletedPendingRequests = getDeletedPendingRequests(data);

  const existingMultiAccountLinks = getExistingMultiAccountLinks(data);

  const code = props.match?.params?.code;

  const isExistingLink = existingMultiAccountLinks.includes(code);

  const existingMultiAccountNameById = getExistingMultiAccountNameById(data);
  const existingMultiAccountName = existingMultiAccountNameById[code] ?? null;

  const isExpiredLink = deletedPendingRequests.includes(code);

  const { isOrgAdmin, name } = getMultiAccountRequestDetails(data, code);

  const { inviteSentFromOwnAccount, loading: inviteSentFromOwnAccountLoading } =
    useInviteSentFromOwnAccount(code);

  const loadingQuery = pendingRequestsLoading || inviteSentFromOwnAccountLoading;

  const [fetchAcceptRequest, acceptResult] = useMultiAccountAcceptMultiAccountRequestMutation({
    variables: {
      input: { id: code },
    },
  });

  const [fetchDeclineRequest, declineResult] = useMultiAccountRemoveAccessRequestMutation({
    variables: {
      input: {
        id: code,
        isOwner: false,
      },
    },
  });

  const errors = combineMutationError(acceptResult, declineResult);

  const acceptRequest = async () => {
    try {
      const { data: submitData } = await fetchAcceptRequest();
      const submitError =
        submitData?.acceptMultiAccountRequest?.errors?.pop()?.messages?.pop() ?? null;
      if (submitError !== null) {
        toast.error(submitError);
      } else {
        toast.success(t('Multi account request was accepted successfully.'));
        props.history.push(RoutePath.ACCOUNT_EXTERNAL_ACCESS);
      }
    } catch (e) {
      toast.error(t('Something went wrong. Please try again later.'));
    }
  };

  const declineRequest = async () => {
    try {
      const { data: submitData } = await fetchDeclineRequest();
      const submitError =
        submitData?.removeMultiAccountRequest?.errors?.pop()?.messages?.pop() ?? null;
      if (submitError !== null) {
        toast.error(submitError);
      } else {
        toast.success(t('Multi account request was declined successfully.'));
        props.history.push(RoutePath.ACCOUNT_EXTERNAL_ACCESS);
      }
    } catch (e) {
      toast.error(t('Something went wrong. Please try again later.'));
    }
  };

  const onBack = () => {
    props.history.push(RoutePath.ACCOUNT_EXTERNAL_ACCESS);
  };

  return (
    <div className="base-card">
      <MultiAccountRequestTextBlock
        loadingQuery={loadingQuery}
        name={name}
        errors={errors}
        isOrgAdmin={isOrgAdmin ?? false}
        isExpiredLink={isExpiredLink}
        inviteSentFromOwnAccount={inviteSentFromOwnAccount}
        isExistingLink={isExistingLink}
        existingMultiAccountName={existingMultiAccountName}
      />
      <hr />
      <MultiAccountActionButtons
        onBack={onBack}
        acceptRequest={acceptRequest}
        declineRequest={declineRequest}
        loadingQuery={loadingQuery}
        isExpiredLink={isExpiredLink}
        loadingAccept={acceptResult.loading}
        loadingDecline={declineResult.loading}
        inviteSentFromOwnAccount={inviteSentFromOwnAccount}
        name={name}
      />
    </div>
  );
}

export default withRouter(MultiAccountAllowAccess);
