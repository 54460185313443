import { usePublicOverviewHistoryGraphsQuery } from 'Ghql';
import { RankingDistributionChartContent } from 'Pages/Keywords/Overview/components/HistoryCharts/RankingDistribution/RankingDistributionChartContent';
import {
  formatSeries,
  getRankingDistributionSeries,
} from 'Pages/Keywords/Overview/components/HistoryCharts/RankingDistribution/helpers';
import { WithDragDetails } from 'Pages/Keywords/Overview/support/types';
import { FilterBase, PeriodFilter } from 'Types/Filter';

type RankingDistributionChartProps = {
  viewkey: string;
  domainSlug: string;
  filters: FilterBase[];
  domainId: string | undefined;
} & WithDragDetails;

export const PublicRankingDistributionChart = (
  props: RankingDistributionChartProps & WithDragDetails,
) => {
  const { domainSlug, viewkey, filters, domainId } = props;
  const periodFilter = filters?.find((filter) => filter.attribute === 'period') as PeriodFilter;
  const { data, loading } = usePublicOverviewHistoryGraphsQuery({
    variables: { filters, viewkey, domainSlug, charts: ['average_rank', 'ranking_distribution'] },
    skip: !periodFilter || !filters.length || !domainId,
  });

  const chartSeries = getRankingDistributionSeries(
    data?.graphs?.publicOverviewHistoryGraphs,
    false,
  );
  const formatedSeries = formatSeries(chartSeries);

  return (
    <RankingDistributionChartContent
      chartSeries={formatedSeries}
      domainId={domainId}
      loading={loading}
      isGroupView={false}
      isEmptyGroup={false}
      showFakeData={false}
      includeKebabMenu={false}
      boardControls={{
        removeChart: null,
        disabledDnd: true,
      }}
    />
  );
};
