import React, { useRef } from 'react';
import { useIntercom } from 'react-use-intercom';
import { Flex, Space, useMantineTheme } from '@mantine/core';
import { useFocusTrap, useMergedRef } from '@mantine/hooks';
import { IconX } from '@tabler/icons-react';
import cn from 'classnames';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import AccButton from 'Components/AccButton/AccButton';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import ErrorBoundary from 'Components/ErrorBoundary';
import AccText from 'Components/Text/AccText';
import AccTitle from 'Components/Title/AccTitle';
import { useClipboard } from 'Hooks';
import { useModalClickOutside } from 'Hooks/useModalClickOutside';
import { t, tct } from 'Utilities/i18n';
import { useMousetrap } from 'Utilities/mousetrap';
import ExclamationIcon from 'icons/exclamation.svg?inline';
import { ModalFooter } from '../ModalFooter';
import './modal-border.scss';

interface ModalHeaderProps {
  showExclamationInTitle?: boolean;
  title?: string;
  onClose?: () => void;
}

const ModalHeader = ({ showExclamationInTitle, title, onClose }: ModalHeaderProps) => {
  const mantineTheme = useMantineTheme();

  return (
    <div className="modal-header">
      <div className="modal-title">
        {showExclamationInTitle && <ExclamationIcon className="confirmation-icon" />}
        {title}
      </div>
      {onClose && (
        <>
          <AccText size="sm" ml="auto" c={mantineTheme.colors.gray[5]}>
            ({t('ESC to close')})
          </AccText>
          <AccActionIcon ml={4} onClick={onClose} color={'gray'} size="md">
            <IconX stroke={2} className="close-icon" />
          </AccActionIcon>
        </>
      )}
    </div>
  );
};

interface ModalBorderProps {
  children: React.ReactNode;
  title?: string;
  className?: string;
  onClose?: () => void;
  header?: React.ReactElement | false;
  closeOnClickOutside?: boolean;
  showExclamationInTitle?: boolean;
  bodyClassname?: string;
}

const ModalBorder = ({
  title,
  className = '',
  onClose,
  children,
  header,
  closeOnClickOutside = true,
  showExclamationInTitle = false,
  bodyClassname,
}: ModalBorderProps) => {
  const handleEscape = () => (onClose ? onClose() : null);

  const clickOutsideRef = useRef<HTMLDivElement>(null);

  //Prevent users from navigating out of the modal
  const focusTrapRef = useFocusTrap();

  const mergedRef = useMergedRef(clickOutsideRef, focusTrapRef);

  const handleClickOutside = (event: MouseEvent) => {
    //Additional checks to make sure the clickOutside hook will only fire when clicking the modal backdrop/overlay.
    //Otherwise the onClose event might accidently fire when opening the modal.
    if (!closeOnClickOutside || !onClose) {
      return;
    }
    if (
      // do not fire callback if click inside a nested modal
      typeof (event?.target as HTMLDivElement)?.className !== 'string' ||
      (!(event?.target as HTMLDivElement).className.includes('overlay-content') &&
        !(event?.target as HTMLDivElement).className.includes('overlay-backdrop'))
    ) {
      return;
    }
    onClose();
  };

  const clipboard = useClipboard({ timeout: 4000 });

  const { isOpen, show } = useIntercom();

  useMousetrap({
    combination: 'esc',
    description: t('Close this modal'),
    callback: handleEscape,
  });
  // useClickOutside(modalBorderRef, handleClickOutside);
  useModalClickOutside(clickOutsideRef, handleClickOutside);
  const modalHeader =
    typeof header === 'boolean'
      ? undefined
      : header || (
          <ModalHeader
            showExclamationInTitle={showExclamationInTitle}
            title={title}
            onClose={onClose}
          />
        );
  return (
    <div className={cn('modal-border', className)} ref={mergedRef}>
      {modalHeader}
      <div
        data-no-header={header === false ? true : null}
        className={cn('modal-body', bodyClassname)}
      >
        <ErrorBoundary
          fallback={({ resetError, eventId }) => (
            <>
              <Flex align={'center'} justify="flex-start" direction="column" mih={240} role="alert">
                <AccTitle type="h2" my={16}>
                  {t('An error occurred. Please try again.')}
                </AccTitle>
                <AccText>
                  {tct('If the problem persists, please [support].', {
                    support: <a onClick={() => !isOpen && show()}>{t('contact support')}</a>,
                  })}
                </AccText>
                <Space h="xl" />
                {eventId && (
                  <>
                    <AccText>{t('Event id:')}</AccText>
                    <AccTooltip
                      delay={0}
                      tooltip={clipboard.copied ? t('copied') : t('Copy id to clipboard')}
                    >
                      <AccActionIcon
                        px={8}
                        variant="subtle"
                        miw="min-content"
                        style={{ aspectRatio: 'unset' }}
                        onClick={() => clipboard.copy(eventId)}
                      >
                        {eventId}
                      </AccActionIcon>
                    </AccTooltip>
                  </>
                )}
                <Space h="xl" />
                <AccButton
                  onClick={() => {
                    resetError();
                    onClose?.();
                  }}
                >
                  {t('Close modal')}
                </AccButton>
              </Flex>
              <ModalFooter primaryButtonSlot={<></>} />
            </>
          )}
        >
          {children}
        </ErrorBoundary>
      </div>
    </div>
  );
};
export default ModalBorder;
