import React, { Component } from 'react';
import cn from 'classnames';
import isNil from 'lodash/isNil';
import { AccFastIcon } from 'Components/AccFastIcon';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import FormatNumber from 'Components/FormatNumber';
import { formatNumberHelper } from 'Components/FormatNumber/formatNumberHelper';
import { ArrowDown, ArrowUp } from 'Pages/Keywords/Table/Icon/Icons';
import { tct } from 'Utilities/i18n';
import { mantineTheme } from 'css/mantine/theme';
import './value-delta.scss';

type Props = {
  delta: number;
  currentValue?: number | null;
  className?: string;
  showNumber?: boolean;
  currency?: string;
  reverseColor?: boolean;
  percentage?: boolean;
  precision?: number;
};

class ValueDelta extends Component<Props> {
  static defaultProps = {
    className: '',
    showNumber: true,
    currency: '',
    precision: 0,
    reverseColor: false,
    percentage: false,
  };
  shouldComponentUpdate(nextProps: Props) {
    return nextProps.delta !== this.props.delta;
  }

  renderFormattedValue(formattedValue) {
    const { percentage, precision, currency } = this.props;
    return (
      <FormatNumber
        currency={currency}
        percentage={percentage}
        precision={precision}
        isEmptyAllowed
        showTitle={false}
      >
        {isNaN(formattedValue) || formattedValue == null ? '' : Math.abs(formattedValue)}
      </FormatNumber>
    );
  }

  getFormattedValue = (value) => {
    const { precision } = this.props;
    return value != null
      ? formatNumberHelper({
          value,
          precision,
        })
      : value;
  };

  render() {
    const { reverseColor, delta } = this.props;
    const formattedValue = this.getFormattedValue(delta);
    return (
      <span
        className={cn('value-delta', this.props.className, {
          'decrease red':
            (formattedValue < 0 && !reverseColor) || (reverseColor && formattedValue > 0),
          'increase green':
            (formattedValue > 0 && !reverseColor) || (reverseColor && formattedValue < 0),
        })}
      >
        {((formattedValue < 0 && !reverseColor) || (reverseColor && formattedValue > 0)) && (
          <AccFastIcon
            size={10}
            src={ArrowDown}
            color={mantineTheme?.other?.decrease}
            style={{ display: 'inline-block' }}
            mr={5}
          />
        )}
        {((formattedValue > 0 && !reverseColor) || (reverseColor && formattedValue < 0)) && (
          <AccFastIcon
            size={10}
            src={ArrowUp}
            color={mantineTheme?.other?.increase}
            style={{ display: 'inline-block' }}
            mr={5}
          />
        )}
        {this.props.showNumber && (
          <React.Fragment>
            <AccTooltip
              tooltip={
                !isNil(this.props.currentValue)
                  ? tct('Compare value: [val]', {
                      val: this.renderFormattedValue(
                        this.getFormattedValue(this.props.currentValue - delta),
                      ),
                    })
                  : ''
              }
              old
            >
              <span className="value">{this.renderFormattedValue(formattedValue)}</span>
            </AccTooltip>
          </React.Fragment>
        )}
      </span>
    );
  }
}

export default ValueDelta;
