/* eslint-disable @typescript-eslint/no-use-before-define */
import { Link } from 'react-router-dom';
import { Group, Skeleton, Stack, Table } from '@mantine/core';
import cn from 'classnames';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { DomainFavicon } from 'Components/DomainFavicon';
import FormatNumber from 'Components/FormatNumber/new';
import Segment, { Images } from 'Components/Segment';
import AccText from 'Components/Text/AccText';
import { useDisplayCurrency } from 'Hooks/displayCurrency';
import { FilterAttribute, FilterComparison } from 'Types/Filter';
import { FilterTrackingKey, trackFilterEventWithNavigation } from 'Utilities/Analytics/mixpanel';
import { t, tct } from 'Utilities/i18n';
import {
  fixLeadingSlash,
  getParentPath,
  isOtherNode,
  linkToKeydisCompetitor,
  linkToKeywordListCompetitor,
  pathFromURL,
  removeProtocolFromUrl,
} from '../support/helpers';
import { CompetitorDomains, SitemapNode } from '../support/types';
import styles from '../siteMapping.module.scss';
import competitorStyles from './competitorTable.module.scss';

export const getFavicon = (compDomain: string): string =>
  `https://app.accuranker.com/screenshots/favicon?url=${compDomain}&size=16`;

export type CompetitorTableProps = {
  selectedNode: SitemapNode | null;
  allCompetitorsTracked?: Map<string, CompetitorDomains[]>;
  loading: boolean;
  handleShowKeywordsModal: (data: number | undefined, includingChildren: boolean) => () => void;
  linkToKeydis: string;
  isDemoDomain?: boolean;
};

export const CompetitorTable = ({
  allCompetitorsTracked,
  selectedNode,
  loading,
  handleShowKeywordsModal,
  linkToKeydis,
  isDemoDomain,
}: CompetitorTableProps) => {
  if (isOtherNode(selectedNode)) {
    return (
      <div className={competitorStyles.wrapper}>
        <OtherNodeTigerBanner selectedNode={selectedNode} />
      </div>
    );
  }

  const competitorsTrackedDataForSelectedPage = allCompetitorsTracked?.get(
    selectedNode?.pathSegment || '',
  );

  const TableComponent = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
    const { displayCurrency } = useDisplayCurrency(true);
    return (
      <Table className={competitorStyles.table} verticalSpacing="xs">
        <Table.Thead className={competitorStyles.header}>
          <Table.Tr>
            <Table.Th />
            <Table.Th>{t('URL')}</Table.Th>

            <Table.Th>
              <AccTooltip placement="left" tooltip={t('AI Share of Voice')}>
                <span className={competitorStyles.estVisits}>{t('AI SoV')}</span>
              </AccTooltip>
            </Table.Th>
            <Table.Th>
              <AccTooltip placement="left" tooltip={t('Estimated traffic value')}>
                <span className={competitorStyles.trafficValue}>
                  {`${t('AI Traf. val')} (${displayCurrency})`}
                </span>
              </AccTooltip>
            </Table.Th>
            <Table.Th>
              <AccTooltip placement="left" tooltip={t('Click-through rate')}>
                <span>{t('CTR')}</span>
              </AccTooltip>
            </Table.Th>
            <Table.Th>
              <AccTooltip placement="left" tooltip={t('Search volume')}>
                <span>{t('Searches')}</span>
              </AccTooltip>
            </Table.Th>
            <AccTooltip placement="left" tooltip={t('Number of shared keywords')}>
              <Table.Th>{t('Tracked')}</Table.Th>
            </AccTooltip>
            <AccTooltip placement="left" tooltip={t('Number of discovered keywords')}>
              <Table.Th>{t('Disc.')}</Table.Th>
            </AccTooltip>
          </Table.Tr>
        </Table.Thead>

        <Table.Tbody>{children}</Table.Tbody>
      </Table>
    );
  };

  if (loading) {
    return (
      <div className={competitorStyles.wrapper}>
        <TableComponent>
          <SkeletonRows />
        </TableComponent>
      </div>
    );
  }

  return (
    <div className={competitorStyles.wrapper}>
      {selectedNode?.kwECT ? (
        <TableComponent>
          {competitorsTrackedDataForSelectedPage === undefined ? (
            <SkeletonRows />
          ) : (
            <TableRows
              competitorsTrackedDataForSelectedPage={competitorsTrackedDataForSelectedPage}
              selectedNode={selectedNode}
            />
          )}
        </TableComponent>
      ) : (
        <NoKeywordsTigerBanner
          isDemoDomain={isDemoDomain}
          availableKeywords={selectedNode?.kwECU}
          handleShowKeywordsModal={handleShowKeywordsModal}
          linkToKeydis={linkToKeydis}
        />
      )}
    </div>
  );
};

const InformationSegment = ({
  children,
  image,
  testId,
}: {
  children: JSX.Element | JSX.Element[];
  image: Images;
  testId?: string;
}) => (
  <Segment className={competitorStyles.infoSegment} primary narrow image={image}>
    <div data-testid={testId}>{children}</div>
  </Segment>
);

function NoKeywordsTigerBanner({
  handleShowKeywordsModal,
  availableKeywords,
  linkToKeydis,
  isDemoDomain,
}: {
  handleShowKeywordsModal: (data: number | undefined, includingChildren: boolean) => () => void;
  availableKeywords: number | undefined;
  linkToKeydis: string;
  isDemoDomain?: boolean;
}) {
  const handleTrackFilterEvent = () => {
    trackFilterEventWithNavigation(
      linkToKeydis,
      FilterTrackingKey.SiteExplorerToDiscovery,
      FilterAttribute.HIGHEST_RANKING_PAGE,
    );
  };
  return (
    <InformationSegment image={Images.StandingCautiousTiger} testId="NoKeywordsTiger">
      {availableKeywords && !isDemoDomain
        ? tct(
            'You track 0 keywords on this page which means we cannot determine your competitors.\n\nSee [numberOfKeywords] available keywords in [goToKeydis] or [goToKeywordsModal] to see dynamic competitors for this landing page.',

            {
              numberOfKeywords: availableKeywords || '0',
              goToKeywordsModal: (
                <AccText
                  variant="link"
                  inline
                  tabIndex={0}
                  onClick={handleShowKeywordsModal(availableKeywords, false)}
                  onKeyDown={handleShowKeywordsModal(availableKeywords, false)}
                >
                  {tct('import [numberOfKeywords] available keywords ', {
                    numberOfKeywords: availableKeywords,
                  })}
                </AccText>
              ),
              goToKeydis: (
                <Link to={linkToKeydis} onClick={handleTrackFilterEvent}>
                  <AccText variant="link" inline>
                    {t('Keyword Discovery')}
                  </AccText>
                </Link>
              ),
            },
          )
        : t(
            'You are not indexed for any keywords on this page which means we cannot determine your competitors.',
          )}
    </InformationSegment>
  );
}

function OtherNodeTigerBanner({ selectedNode }: { selectedNode: SitemapNode | null }) {
  return (
    <>
      <InformationSegment testId="otherNodeTiger" image={Images.GrumpySmile}>
        {tct(
          'We only show the subpages with most keywords. Apply a filter to explore the remaining subpages of [parentPath] or increase the max subpages per page in the slider above the chart.',
          {
            parentPath: <span className={styles.wordBreak}>{getParentPath(selectedNode)}</span>,
          },
        )}
      </InformationSegment>
    </>
  );
}

function SkeletonRows() {
  return (
    <>
      {[...Array(10)].map((_slot, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <Table.Tr
          key={`${idx}-skeleton_loader_table_row`}
          className={competitorStyles.tableRow}
          data-testid={'skeletonWrapper'}
        >
          <Table.Td style={{ paddingRight: 0 }}>{`#${idx + 1}`}</Table.Td>
          <Table.Td style={{ padding: 0 }}>
            <Skeleton height={8} width={110} ml={10} pl={0} />
          </Table.Td>
          <Table.Td style={{ padding: 0 }}>
            <Skeleton height={8} width={80} ml={15} />
          </Table.Td>
          <Table.Td style={{ padding: 0 }}>
            <Skeleton height={8} width={65} ml={15} />
          </Table.Td>
          <Table.Td style={{ padding: 0 }}>
            <Skeleton height={8} width={30} ml={10} />
          </Table.Td>
          <Table.Td style={{ padding: 0 }}>
            <Skeleton height={8} width={45} ml={20} />
          </Table.Td>
          <Table.Td style={{ padding: 0 }}>
            <Skeleton height={8} width={45} ml={15} />
          </Table.Td>
          <Table.Td style={{ padding: 0 }}>
            <Skeleton height={8} width={35} ml={10} mr={10} />
          </Table.Td>
        </Table.Tr>
      ))}
    </>
  );
}

const LinkToOtherTab = ({ link, value, tooltip }) => {
  if (value === 0) return <span>0</span>;
  return (
    <AccTooltip placement="left" tooltip={tooltip}>
      <Link to={link} className={'table-link'}>
        {!!value && <FormatNumber value={value} />}
      </Link>
    </AccTooltip>
  );
};

const FormatLink = ({ domain, link }: { domain: string; link: string }) => {
  if (!link) {
    // Some results do not have links - so called "local results"
    return (
      <AccTooltip placement="left" tooltip={`${domain} (local result)`}>
        <a className={competitorStyles.domainEllipsis} style={{ cursor: 'default' }}>
          {domain}
        </a>
      </AccTooltip>
    );
  }
  return (
    <AccTooltip placement="left" tooltip={removeProtocolFromUrl(link)}>
      <a
        href={link || '#'}
        target="_blank"
        rel="noopener noreferrer"
        className={cn('table-link', competitorStyles.domainEllipsis)}
      >
        {domain}
      </a>
    </AccTooltip>
  );
};

interface TableRowsProps {
  competitorsTrackedDataForSelectedPage: CompetitorDomains[] | undefined;
  selectedNode: SitemapNode | null;
}

/**
 * Get domain + subdomain without www.
 * @param url
 */
const domainAndSubdomainFromUrl = (url: string) => {
  try {
    return new URL(url).host.replace(/^(www\.)/, '');
  } catch (e) {
    return undefined;
  }
};

function TableRows({
  competitorsTrackedDataForSelectedPage,
  selectedNode,
}: TableRowsProps): JSX.Element {
  if (!competitorsTrackedDataForSelectedPage) {
    return <Table.Tr />;
  }

  const rows = (competitorsTrackedDataForSelectedPage ?? [])
    //slice to prevent throwing errors in strict mode
    .slice()
    .sort((comp1, comp2) => comp2.score - comp1.score)
    .map((comp, index) => {
      const linkToKeydis = linkToKeydisCompetitor(
        comp.unknown_competitor_domain,
        comp.unknown_competitor_url,
      );

      const isOwnDomain = comp.is_own_domain;

      const linkToKeywordList = selectedNode?.url
        ? linkToKeywordListCompetitor(
            removeProtocolFromUrl(selectedNode?.url),
            removeProtocolFromUrl(comp.unknown_competitor_url),
            FilterComparison.ENDS_WITH,
            isOwnDomain,
          )
        : undefined;

      return (
        <Table.Tr
          // eslint-disable-next-line react/no-array-index-key
          key={`${index}-${comp.unknown_competitor_domain}`}
          className={`${competitorStyles.tableRow} ${isOwnDomain && competitorStyles.ownDomain}`}
        >
          <AccTooltip placement="left" tooltip={`Score: ${comp.score}`}>
            <Table.Td style={{ paddingRight: 0 }}> {index < 10 ? `#${index + 1}` : '>10'}</Table.Td>
          </AccTooltip>
          <Table.Td style={{ paddingTop: 2, paddingBottom: 2 }}>
            <Group wrap="nowrap" className={competitorStyles.urlCol}>
              <DomainFavicon
                domain={comp.unknown_competitor_url || comp.unknown_competitor_domain}
                size="md"
              />
              <Stack gap={0}>
                <FormatLink
                  domain={
                    (comp.unknown_competitor_url &&
                      domainAndSubdomainFromUrl(comp.unknown_competitor_url)) ||
                    comp.unknown_competitor_domain
                  }
                  link={comp.unknown_competitor_url}
                />
                <AccText nowrap className={competitorStyles.urlLTRsmall} c={'dimmed'}>
                  {fixLeadingSlash(pathFromURL(comp.unknown_competitor_url)) || '/'}
                </AccText>
              </Stack>
            </Group>
          </Table.Td>
          <Table.Td>
            <FormatNumber value={comp.organic_traffic} />
          </Table.Td>
          <Table.Td>
            <FormatNumber
              value={comp.organic_traffic_value}
              minimumFractionDigits={2}
              maximumFractionDigits={2}
            />
          </Table.Td>
          <Table.Td>
            {comp.dynamic_ctr_value < 0.1 ? (
              '<0.1%'
            ) : (
              <FormatNumber
                style="percent"
                value={comp.dynamic_ctr_value / 100}
                maximumFractionDigits={1}
              />
            )}
          </Table.Td>
          <Table.Td>
            <FormatNumber value={comp.search_volume_value} />
          </Table.Td>
          <Table.Td>
            <LinkToOtherTab
              link={linkToKeywordList}
              value={comp.n_matching_keywords}
              tooltip={t('See %s shared keywords', comp.n_matching_keywords)}
            />
          </Table.Td>
          <Table.Td>
            <Group wrap="nowrap" justify="flex-end">
              <LinkToOtherTab
                link={linkToKeydis}
                value={comp.discovered_keywords}
                tooltip={t(
                  'See %s available keywords in Keyword Discovery',
                  comp.discovered_keywords,
                )}
              />
            </Group>
          </Table.Td>
        </Table.Tr>
      );
    });

  return <>{rows}</>;
}
