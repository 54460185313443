import colorScheme from 'Utilities/colors';
import { t } from 'Utilities/i18n';
import { OverviewChartSeries } from '../constants';
import { addLowDataPlaceholderDays } from '../support/lowData';

export type SovChartSeries = OverviewChartSeries;

export const getSovChartSeries = (
  overviewShareOfVoice: any,
  showPercentage?: boolean,
): SovChartSeries[] => {
  const series: SovChartSeries[] = [
    {
      name: t('Share of Voice'),
      primary: true,
      color: colorScheme.graphs.stats.shareOfVoice,
      data:
        overviewShareOfVoice?.filter(Boolean)?.map((e: any) => ({
          x: e?.date ?? 0,
          y: (showPercentage ? e?.shareOfVoicePercentage : e?.shareOfVoiceValue) ?? 0,
          totalKeywords: Number(e?.keywords ? e?.keywords : 0),
        })) ?? [],
    },
  ];
  addLowDataPlaceholderDays(series[0]);
  return series;
};
