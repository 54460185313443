import { useGroupsTableUpdateClientMutation } from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import toast from 'Hooks/useToast';
import { useTreeViewStore } from 'Pages/Domains/TreeView/support/treeViewStore';
import { ProcessedFolderTreeNode } from 'Pages/Domains/TreeView/support/types';
import { useOrganizationInfo } from 'Selectors/OrganizationSelector';
import { getGroupId } from 'Utilities/generateSelectId';
import { groupDeleteConfirmation } from 'Utilities/getConfirmationDescription';
import { t } from 'Utilities/i18n';

export const useGroupActions = (rootNode: ProcessedFolderTreeNode) => {
  const groupId = getGroupId(rootNode.path);

  const { showModal } = useModal();
  const { organization } = useOrganizationInfo();
  const treeViewStore = useTreeViewStore();
  const [deleteClient] = useGroupsTableUpdateClientMutation({
    onCompleted(result) {
      const errors = result.updateClient?.errors;
      if (errors && errors.length) {
        toast.error(t('Could not delete group'));
      } else {
        toast.success(t('Group deleted'));
        treeViewStore.refetch(true);
      }
    },
  });

  const handleEditGroup = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    showModal({
      modalType: 'EditGroup',
      modalTheme: 'light',
      modalProps: {
        groupId: rootNode.id,
        refetch: () => {
          treeViewStore.refetch(true);
        },
        initialValues: {
          groupName: rootNode.name,
        },
      },
    });
  };

  const handleAddDomain = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    showModal({
      modalType: 'BuildDomain',
      modalTheme: 'light',
      modalProps: {
        groupId,
        onClose: () => {
          treeViewStore.refetch(true);
        },
      },
    });
  };

  const handleDeleteGroup = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    showModal({
      modalType: 'Confirmation',
      modalProps: {
        title: t('Delete Group?'),
        description: groupDeleteConfirmation(rootNode.name),
        confirmLabel: t('Delete group'),
        action: () => {
          deleteClient({
            variables: {
              input: {
                id: rootNode.id,
                name: rootNode.name,
                organization: organization?.id || '',
                delete: true,
              },
            },
          });
        },
      },
    });
  };

  return { handleAddDomain, handleEditGroup, handleDeleteGroup };
};
