import { useCallback, useMemo } from 'react';
import { useStore } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import {
  ColumnType,
  DataTable,
  QueryOrder,
  TableFetchDataCallBack,
  TableSize,
} from 'Components/DataTable';
import { TableStyleMode } from 'Components/DataTable/types';
import FormatNumber from 'Components/FormatNumber/new';
import { WordPhrasesDocument, WordPhrasesQuery, WordPhrasesQueryVariables } from 'Ghql';
import { useAddFilter } from 'Hooks/data/filter/setFilters';
import { useDisplayCurrency } from 'Hooks/displayCurrency';
import { selectCurrentFilters } from 'Selectors/FilterSelector';
import { FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import { TableIDs } from 'Types/Table';
import { FilterTrackingKey } from 'Utilities/Analytics/mixpanel';
import { t } from 'Utilities/i18n';

enum WordCloudColumnID {
  'PHRASE' = 'phrase',
  'VARIATIONS' = 'variations',
  'SEARCH_VOLUME' = 'searchVolume',
  'AI_SHARE_OF_VOICE' = 'aiShareOfVoice',
  'AVG_CTR' = 'avgCtr',
  'AVG_MAX_CTR' = 'avgMaxCtr',
  'AVG_RANK' = 'avgRank',
  'TRAFFIC_VALUE' = 'trafficValue',
  'ABOVE_THE_FOLD' = 'aboveTheFold',
}

const orderConfig = {
  avgRank: {
    defaultOrder: QueryOrder.DESC,
    orderBy: 'avg_rank',
  },
  avgCtr: {
    defaultOrder: QueryOrder.DESC,
    orderBy: 'avg_ctr',
  },
  avgMaxCtr: {
    defaultOrder: QueryOrder.DESC,
    orderBy: 'avg_max_ctr',
  },
  aiShareOfVoice: {
    defaultOrder: QueryOrder.DESC,
    orderBy: 'ai_share_of_voice',
  },
  trafficValue: {
    defaultOrder: QueryOrder.DESC,
    orderBy: 'traffic_value',
  },
  searchVolume: {
    defaultOrder: QueryOrder.DESC,
    orderBy: 'search_volume_value',
  },
  variations: {
    defaultOrder: QueryOrder.DESC,
    orderBy: 'variations',
  },
  aboveTheFold: {
    defaultOrder: QueryOrder.DESC,
    orderBy: 'above_the_fold',
  },
};

const useWordCloudKeywordTableInfo = (switchTab) => {
  const columns = useMemo((): ColumnType[] => {
    return [
      {
        id: WordCloudColumnID.PHRASE,
        title: t('Phrase'),
        width: 48,
        fixed: 'left',
        cellRenderer: (props) => {
          const { value } = props;
          const { data } = value;
          return data.phrase;
        },
      },
      {
        id: WordCloudColumnID.VARIATIONS,
        title: t('Variations'),
        onHeaderCell: () => ({
          tooltip: t('Sort by variations'),
          ordering: orderConfig.variations,
        }),
        width: 48,
        fixed: 'left',
        cellRenderer: (props) => {
          const { value } = props;
          const { data } = value;

          const startsWith = data.phrase.startsWith('_');
          const endsWith = data.phrase.endsWith('_');

          let comparison;

          if (startsWith && !endsWith) {
            comparison = FilterComparison.ENDS_WITH;
          } else if (!startsWith && endsWith) {
            comparison = FilterComparison.STARTS_WITH;
          } else {
            comparison = FilterComparison.CONTAINS;
          }

          const newFilter: any = {
            attribute: FilterAttribute.KEYWORD,
            type: FilterValueType.STRING,
            comparison,
            value: data.phrase.replace(/^_+|_+$/g, ''), // Remove _ from start and end of string
          };

          const addFilter = useAddFilter();

          return (
            <a
              onClick={() => {
                switchTab();
                addFilter(newFilter, FilterTrackingKey.WordCloud);
              }}
            >
              {data.variations}
            </a>
          );
        },
      },
      {
        id: WordCloudColumnID.AVG_RANK,
        title: t('Avg. rank'),
        width: 48,
        onHeaderCell: () => ({
          tooltip: t('Sort by average rank'),
          ordering: orderConfig.avgRank,
        }),
        cellRenderer: (props) => {
          const { value } = props;
          const { data } = value;

          return <FormatNumber value={data.avgRank} />;
        },
      },
      {
        id: WordCloudColumnID.AVG_CTR,
        title: t('Avg. CTR'),
        width: 48,
        onHeaderCell: () => ({
          tooltip: t('Sort by Avg. CTR'),
          ordering: orderConfig.avgCtr,
        }),
        cellRenderer: (props) => {
          const { value } = props;
          const { data } = value;

          return <FormatNumber value={data.avgCtr} style={'percent'} />;
        },
      },
      {
        id: WordCloudColumnID.AVG_MAX_CTR,
        title: t('Avg. Max CTR'),
        width: 48,
        onHeaderCell: () => ({
          tooltip: t('Sort by Avg. Max CTR'),
          ordering: orderConfig.avgMaxCtr,
        }),
        cellRenderer: (props) => {
          const { value } = props;
          const { data } = value;

          return <FormatNumber value={data.avgMaxCtr} style={'percent'} />;
        },
      },
      {
        id: WordCloudColumnID.SEARCH_VOLUME,
        title: t('Searches'),
        width: 48,
        onHeaderCell: () => ({
          tooltip: t('Sort by Searches'),
          ordering: orderConfig.searchVolume,
        }),
        cellRenderer: (props) => {
          const { value } = props;
          const { data } = value;

          return <FormatNumber value={data.searchVolumeValue} />;
        },
      },
      {
        id: WordCloudColumnID.AI_SHARE_OF_VOICE,
        title: t('AI SoV'),
        width: 48,
        onHeaderCell: () => ({
          tooltip: t('Sort by AI SoV'),
          ordering: orderConfig.aiShareOfVoice,
        }),
        cellRenderer: (props) => {
          const { value } = props;
          const { data } = value;

          return <FormatNumber value={data.aiShareOfVoice} />;
        },
      },
      {
        id: WordCloudColumnID.TRAFFIC_VALUE,
        title: t('Traf. val.'),
        width: 48,
        onHeaderCell: () => ({
          tooltip: t('Sort by Traffic value'),
          ordering: orderConfig.trafficValue,
        }),
        cellRenderer: (props) => {
          const { value } = props;
          const { data } = value;

          return <FormatNumber value={data.trafficValue} />;
        },
      },
      {
        id: WordCloudColumnID.ABOVE_THE_FOLD,
        title: t('Above the fold'),
        width: 48,
        onHeaderCell: () => ({
          tooltip: t('Sort by Above the fold'),
          ordering: orderConfig.aboveTheFold,
        }),
        cellRenderer: (props) => {
          const { value } = props;
          const { data } = value;

          return <FormatNumber value={data.aboveTheFold} />;
        },
      },
    ]
      .filter(Boolean)
      .map((e) => e as ColumnType);
  }, []);

  return useMemo(
    () => ({
      columns,
      labels: {
        paginationLoading: t('Loading Word Cloud…'),
      },
    }),
    [columns],
  );
};

const useFetchWordCloudPhrases = (country): TableFetchDataCallBack => {
  const client = useApolloClient();
  const store = useStore();
  const { displayCurrency } = useDisplayCurrency();

  return useCallback(
    (params) => {
      const filters = selectCurrentFilters(store.getState());
      const variables = {
        filters,
        pagination: params.pagination,
        ordering: params.ordering,
        country,
        displayCurrency,
      };
      return client
        .query<WordPhrasesQuery, WordPhrasesQueryVariables>({
          query: WordPhrasesDocument,
          variables,
        })
        .then((e) => {
          const data = e?.data?.keydisWordPhrases?.wordPhrases ?? [];
          const totalLength = e?.data?.keydisWordPhrases?.pagination?.numResults ?? data.length;
          return { data, length: totalLength };
        });
    },
    [client, store],
  );
};

export const WordCloudTable = ({ switchTab, country }) => {
  const tableName = TableIDs.WORD_CLOUD;
  const { columns } = useWordCloudKeywordTableInfo(switchTab);

  const fetchKeywords = useFetchWordCloudPhrases(country);

  const defaultOrdering = {
    order: QueryOrder.DESC,
    orderBy: 'variations',
  };

  return (
    <DataTable
      tableId={tableName}
      fetchData={fetchKeywords}
      styleMode={TableStyleMode.ROUNDED}
      columns={columns}
      defaultOrdering={defaultOrdering}
      pageSize={100}
      viewMode={TableSize.DEFAULT}
      tableMinHeight={200}
    />
  );
};
