import { Component } from 'react';
import { connect } from 'react-redux';
import { Flex } from '@mantine/core';
import { hideModal } from 'Actions/ModalAction';
import AccButtton from 'Components/AccButton/AccButton';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import AccText from 'Components/Text/AccText';
import AtoDjango from 'Utilities/django';
import { t } from 'Utilities/i18n';

type Props = {
  title?: string;
  errorType: string;
  hideModal: (...args: Array<any>) => any;
  link: string;
};

class GeneralError extends Component<Props> {
  static defaultProps = {
    title: 'An Error Occurred',
    errorType: '',
    link: '/',
  };

  render() {
    return (
      <ModalBorder title={this.props.title}>
        <AccText>
          {t('The error message states:')} "{this.props.errorType}".
        </AccText>
        <ModalFooter
          primaryButtonSlot={
            <AccButtton onClick={this.props.hideModal} variant="secondary">
              {t('Close')}
            </AccButtton>
          }
          secondaryButtonSlot={
            <Flex align="center">
              <AtoDjango href={this.props.link} className="custom-link">
                <>{t('Go back to the billing overview')}</>
              </AtoDjango>
            </Flex>
          }
        />
      </ModalBorder>
    );
  }
}

export default connect(null, {
  hideModal,
})(GeneralError);
