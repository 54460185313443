import { observer } from 'mobx-react';
import { colorScheme } from 'Utilities/colors';
import { t } from 'Utilities/i18n';
import { formatNumber, keywordsToNodeSize } from '../support/helpers';
import { useSitemapChartStore } from '../support/store';
import styles from '../siteMapping.module.scss';

const SizeLegend = ({ legendNodeSizes, sizeLegendValues, legendNodeCenters }) => {
  const sizeLegendWidth = (length: number) => {
    switch (length) {
      case 0:
      case 1:
      case 2:
        return '152px';
      case 3:
      case 4:
        return '204px';
      case 5:
        return '248px';
      case 6:
        return '312px';
      default:
        return '350px';
    }
  };

  return (
    <svg width={sizeLegendWidth(legendNodeSizes.length)} height={'100px'}>
      <g transform="translate(10,2)" fontSize="10" textAnchor="middle">
        <text
          x="1"
          y="8"
          fill="currentColor"
          textAnchor="start"
          fontWeight="bold"
          className="title"
        >
          {t('Total number of keywords')}
        </text>
        {
          legendNodeSizes.map((x, i) => (
            <circle
              strokeWidth={'2px'}
              r={x}
              fill={'none'}
              stroke={colorScheme.orange}
              cx={legendNodeCenters[i]}
              cy={35}
              key={i}
            />
          )) // Circles
        }
        {sizeLegendValues.map((x, i) => (
          <g className="tick" transform={`translate(${legendNodeCenters[i]},2)`} key={i}>
            <text fill="currentColor" y="70" dy="0.71em">
              {formatNumber(parseInt(x, 10))}
            </text>
          </g>
        ))}
      </g>
    </svg>
  );
};

const CircleFill = ({ i, x }) => {
  if (i === 0) {
    return (
      <pattern
        id={`leg${i}`}
        width="5"
        height="10"
        patternTransform="rotate(45 0 0)"
        patternUnits="userSpaceOnUse"
      >
        <rect x={0} y={0} width="10" height="10" style={{ fill: 'white', strokeWidth: 0 }} />
        <line x1="0" y1="0" x2="0" y2="10" style={{ stroke: colorScheme.orange, strokeWidth: 1 }} />
      </pattern>
    );
  }

  return (
    <linearGradient id={`leg${i}`} x1="0.5" y1="1" x2="0.5" y2="0">
      <stop offset="0%" stopOpacity="1" stopColor={colorScheme.orange} />
      <stop offset={`${x}%`} stopOpacity="1" stopColor={colorScheme.orange} />
      <stop offset={`${x}%`} stopOpacity="0" stopColor={colorScheme.orange} />
      <stop offset="100%" stopOpacity="0" stopColor={colorScheme.orange} />
    </linearGradient>
  );
};

const FillLegend = () => (
  <svg width="240px" height={'100px'}>
    <g transform="translate(10,0)" fontSize="10" textAnchor="middle">
      <text
        x="-5"
        y="10"
        fill="currentColor"
        textAnchor="start"
        fontWeight="bold"
        className="title"
      >
        {t('Percentage tracked')}
      </text>
      {[0, 1, 50, 100].map((x, i) => (
        <g key={i}>
          <CircleFill i={i} x={x} />
          <circle
            strokeWidth={'2px'}
            r={15}
            fill={`url(#leg${i})`}
            stroke={colorScheme.orange}
            cx={10 + 40 * i}
            cy={35}
          />
          <g className="tick" transform={`translate(${10 + 40 * i},0)`} fontWeight={'thin'}>
            <text fill="currentColor" y="62" dy="0.71em">
              {x}%
            </text>
          </g>
        </g>
      ))}
    </g>
  </svg>
);

const LeafLegend = () => (
  <svg width="110px" height={'100px'}>
    <g>
      <g fontSize="10">
        <text
          x="5"
          y="10"
          fill="currentColor"
          textAnchor="start"
          fontWeight="bold"
          className="title"
        >
          {t('Subpages')}
        </text>
        <circle
          strokeWidth={'2px'}
          r={15}
          stroke={colorScheme.orange}
          fill={'white'}
          cx={20}
          cy={35}
        />{' '}
        <text fill="currentColor" y="62" dy="0.71em" dx={11} fontWeight={'thin'}>
          {t('Has')}
        </text>
      </g>

      <g>
        <circle
          strokeWidth={'2px'}
          r={15}
          stroke={colorScheme.orange}
          fill={'white'}
          cx={10 + 60}
          cy={35}
          style={{ strokeDasharray: '3px' }}
        />
        <text fill="currentColor" y="62" dy="0.71em" dx={45} fontWeight={'thin'} fontSize="10">
          <></>
          {t("Doesn't have")}
        </text>
      </g>
    </g>
  </svg>
);

const TreeLegend = ({
  colorMap,
  colorLegendValues,
  sizeLegendValues,
  totalKeywords,
}): JSX.Element => {
  const siteMapStore = useSitemapChartStore();
  const legendNodeSizes = sizeLegendValues.map(
    (x) => keywordsToNodeSize(x, totalKeywords) * (siteMapStore?.pagesTreeState?.zoom ?? 0.75),
  );
  const legendNodeCenters = [10];
  for (let i = 0; i < legendNodeSizes.length - 1; i++) {
    let size = legendNodeCenters[i];
    size += 10;
    size += 2 * legendNodeSizes[i + 1];
    legendNodeCenters.push(size);
  }

  const ColorLegend = () => (
    <svg width={`${colorLegendValues.length * 28 + 12}px`} height={'100px'} fontSize="10">
      <g transform="translate(6,14)">
        <text
          x="0"
          y="-4"
          fill="currentColor"
          textAnchor="start"
          fontWeight="bold"
          className="title"
        >
          {t('Total search volume')}
        </text>
        {Array.from({ length: colorLegendValues.length }, (_, i) => i * 28).map((x, i) => (
          <rect x={x} y="18" width="28" height="10" fill={colorMap(colorLegendValues[i])} key={i} /> // Colored rectangles
        ))}
      </g>

      <g transform="translate(6,42)" fill="none" textAnchor="middle">
        {Array.from({ length: colorLegendValues.length - 1 }, (_, i) => 28.5 + i * 28).map(
          (x, i) => {
            return (
              <g className="tick" opacity="1" transform={`translate(${x},0)`} key={i}>
                <line stroke="currentColor" y2="6" y1="-10" />
                <text fill="currentColor" y="9" dy="0.71em">
                  {formatNumber(parseInt(colorLegendValues[i], 10))}
                </text>
              </g> // Numbers and lines
            );
          },
        )}
      </g>
    </svg>
  );

  return (
    <div className={styles.treeLegend}>
      <ColorLegend />

      <SizeLegend
        legendNodeSizes={legendNodeSizes}
        sizeLegendValues={sizeLegendValues}
        legendNodeCenters={legendNodeCenters}
      />

      {/*Toggle colors for table}*/}
      <FillLegend />
      <LeafLegend />
    </div>
  );
};

export default observer(TreeLegend);
