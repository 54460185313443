import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { deselectAllDomains } from 'Actions/DomainsPageActions';
import { useDomainsPageSelector } from 'Selectors/DomainsPageSelector';
import { EventName, useMixpanel } from 'Utilities/Analytics/mixpanel';
import Toolbar from '../../Toolbar';

const CardViewToolbar = () => {
  const trackEvent = useMixpanel();
  const dispatch = useDispatch();
  const { selectedDomains, maxReached } = useDomainsPageSelector();
  const deselectAll = () => dispatch(deselectAllDomains());
  useEffect(() => {
    if (maxReached) {
      trackEvent(EventName.HomeCardviewMaxCompareReached);
    }
  }, [maxReached]);
  return <Toolbar initialSelect={selectedDomains} deselectAll={deselectAll} />;
};
export default CardViewToolbar;
