import { notEmpty } from 'Utilities/underdash';
import { TreeItem } from '../useComparisonContext';

export const filterTree = (tree: TreeItem[], search: string): TreeItem[] => {
  if (!search) return tree;

  const newTree = tree
    .map((item) => {
      if (item.label?.toLocaleLowerCase().indexOf(search) !== -1) return item;
      if ('ds' in item) {
        const ds = filterTree(item.ds || [], search);
        if (ds.length) {
          return {
            ...item,
            ds,
          };
        }
      }
      if ('fs' in item || 'ts' in item) {
        const fs = filterTree(item.fs || [], search);
        const ts = filterTree(item.ts || [], search);
        if (fs.length || ts.length) {
          return {
            ...item,
            fs,
            ts,
          };
        }
      }
      return null;
    })
    .filter(notEmpty);
  return newTree;
};
