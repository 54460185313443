import { useMemo } from 'react';
import { getSeriesData } from 'Components/Chart/CompareChartData/support/helpers';
import { SeriesItem } from 'Components/Chart/LineChart';
import { useCompetitorsChartQuery } from 'Ghql';
import { useFilters } from 'Hooks';
import { useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import { getDomainName } from 'Utilities/Common/domain';
import { getCompetitorAndDomainColors } from 'Utilities/colors';

type Props = {
  metric: string;
  skip: boolean;
};

export const useCompetitorChartData = ({ metric, skip }: Props) => {
  const filters = useFilters();
  const { domainInfo, colorMap, asPercentage } = useQueryDomainInfo();

  const { loading, data } = useCompetitorsChartQuery({
    skip,
    variables: {
      filters,
      metric,
      asPercentage,
      allHistory: true,
    },
    fetchPolicy: 'cache-first',
  });

  const series: SeriesItem[] = useMemo(() => {
    const competitorHistory: any[] = data?.graphs?.competitorHistory || [];
    const colors = getCompetitorAndDomainColors(
      colorMap,
      competitorHistory.map((e) => e.domain),
    );
    return (
      competitorHistory?.map((e, i) => ({
        name: getDomainName(e),
        color: colors[i],
        primary: (domainInfo?.domain || e?.youtubeChannelName) === e.domain,
        data: getSeriesData(e?.days),
      })) ?? []
    );
  }, [data, colorMap]);

  return {
    series,
    loading,
  };
};
