import { memo, useMemo } from 'react';
import AccDistribution, { DistributionItem } from 'Components/AccDistribution/AccDistribution';
import AccLazyPopover from 'Components/AccLazyPopover/AccLazyPopover';
import { BarChart } from 'Pages/Keywords/Overview/components/BarChart/BarChart';
import colorScheme from 'Utilities/colors';
import { t } from 'Utilities/i18n';
import SearchIntentIcon from 'icons/search-intent/searchIntentIcon';
import { propsIsEqualComparison } from '../../../../../../Utilities/compare';
import { FolderCellProps } from '../../utils/useFoldersTableConfig';

const SearchIntentCell = (props: FolderCellProps) => {
  const { classes, rootNode } = props;

  const totalPercentage =
    rootNode.search_intent?.reduce((acc, searchIntentItem) => acc + searchIntentItem[1], 0) ?? 0;

  const tooltipMapper = useMemo(
    () => ({
      T: t('Transactional'),
      C: t('Commercial'),
      N: t('Navigational'),
      I: t('Informational'),
    }),
    [],
  );
  const mappedItems = useMemo((): DistributionItem[] => {
    return rootNode.search_intent?.map((item) => {
      return {
        id: item[0],
        color: colorScheme.graphs.searchIntent[item[0]],
        value: (item[1] * 100) / totalPercentage,
        label: item[1] > totalPercentage / 5 ? item[0] : '', // Don't show label if less than 20% as it's too cramped
        tooltip: t('Show Search Intent distribution'),
      };
    });
  }, [rootNode?.search_intent, totalPercentage, tooltipMapper]);

  const SearchIntentBar = () =>
    useMemo(() => {
      return (
        <span>
          {rootNode.search_intent ? (
            <AccDistribution height={20} width={80} items={mappedItems} />
          ) : (
            <div className={classes.rightTextAlign}>-</div>
          )}
        </span>
      );
    }, [rootNode.search_intent, colorScheme, totalPercentage]);

  return (
    <AccLazyPopover
      width={400}
      target={<SearchIntentBar />}
      dropdownTitle={t('Search intent count')}
      outerContainerProps={{ justify: 'end' }}
    >
      <BarChart
        items={rootNode.search_intent?.map((item) => {
          return {
            value: item[1],
            label: tooltipMapper[item[0]],
            id: item[0],
            background: colorScheme.graphs.searchIntent[item[0]],
            icon: <SearchIntentIcon searchIntent={item[0]} size={20} />,
          };
        })}
      />
    </AccLazyPopover>
  );
};

export default memo(SearchIntentCell, propsIsEqualComparison);
