import moment from 'moment';
import type { AvgRankSeries } from 'Pages/Keywords/Overview/components/HistoryCharts/AverageRank/helpers';
import { RankingDistributionSeries } from 'Pages/Keywords/Overview/components/HistoryCharts/RankingDistribution/helpers';
import { SovChartSeries } from 'Pages/Keywords/Overview/components/HistoryCharts/ShareOfVoice/helpers';
import { SearchTrendData } from '../components/SearchTrend/SearchTrend';

interface FakeDataReturn {
  SovChartFakeData: SovChartSeries[];
  AvgRankChartFakeData: AvgRankSeries[];
  RankingDistributionFakeData: RankingDistributionSeries[];
  SearchTrendFakeData: SearchTrendData[];
}

/** Create fake timestamps for SearchTrendFakeData */
const getTrendDate = (monthOffset: number) => {
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() - monthOffset);
  currentDate.setDate(1); // Set the date to the first day of the month

  // Get the timestamp (in milliseconds) for the first day of the adjusted month
  return currentDate.getTime();
};

export const useFakeData = (): FakeDataReturn => {
  const fakeDates = Array.from(Array(10)).map((_, i) =>
    moment.utc().subtract(i).toDate().getTime(),
  );

  const SovChartFakeData = [
    {
      color: '#f89537',
      data: [
        { x: fakeDates[5], y: 100, totalKeywords: 4 },
        { x: fakeDates[4], y: 200, totalKeywords: 4 },
        { x: fakeDates[3], y: 700, totalKeywords: 4 },
        { x: fakeDates[2], y: 800, totalKeywords: 3 },
        { x: fakeDates[1], y: 900, totalKeywords: 2 },
        { x: fakeDates[0], y: 1500, totalKeywords: 2 },
      ],
      name: 'Share of voice',
      primary: true,
    },
  ];

  const AvgRankChartFakeData: AvgRankSeries[] = [
    {
      color: '#2f63d6',
      data: [
        { x: fakeDates[5], y: 55, totalKeywords: 4 },
        { x: fakeDates[4], y: 35, totalKeywords: 4 },
        { x: fakeDates[3], y: 25, totalKeywords: 4 },
        { x: fakeDates[2], y: 20, totalKeywords: 4 },
        { x: fakeDates[1], y: 20, totalKeywords: 4 },
        { x: fakeDates[0], y: 5, totalKeywords: 4 },
      ],
      name: 'Average Rank',
      primary: true,
    },
  ];

  const RankingDistributionFakeData: RankingDistributionSeries[] = [
    {
      date: fakeDates[4],
      keywords0To3: 1,
      keywords4To10: 1,
      keywords11To20: 2,
      keywords21To50: 1,
      keywordsAbove50: 2,
      keywordsTotal: 9,
      keywordsUnranked: 2,
    },
    {
      date: fakeDates[3],
      keywords0To3: 1,
      keywords4To10: 2,
      keywords11To20: 2,
      keywords21To50: 1,
      keywordsAbove50: 1,
      keywordsTotal: 8,
      keywordsUnranked: 1,
    },
    {
      date: fakeDates[2],
      keywords0To3: 1,
      keywords4To10: 2,
      keywords11To20: 2,
      keywords21To50: 1,
      keywordsAbove50: 1,
      keywordsTotal: 8,
      keywordsUnranked: 1,
    },
    {
      date: fakeDates[1],
      keywords0To3: 2,
      keywords4To10: 3,
      keywords11To20: 1,
      keywords21To50: 1,
      keywordsAbove50: 1,
      keywordsTotal: 9,
      keywordsUnranked: 1,
    },
    {
      date: fakeDates[0],
      keywords0To3: 3,
      keywords4To10: 2,
      keywords11To20: 2,
      keywords21To50: 1,
      keywordsAbove50: 1,
      keywordsTotal: 10,
      keywordsUnranked: 1,
    },
  ];

  const SearchTrendFakeData: SearchTrendData[] = [
    {
      date: getTrendDate(27),
      value: 1671020,
    },
    {
      date: getTrendDate(26),
      value: 1787440,
    },
    {
      date: getTrendDate(25),
      value: 1707840,
    },
    {
      date: getTrendDate(24),
      value: 1783570,
    },
    {
      date: getTrendDate(23),
      value: 1765570,
    },
    {
      date: getTrendDate(22),
      value: 1916120,
    },
    {
      date: getTrendDate(21),
      value: 2390950,
    },
    {
      date: getTrendDate(20),
      value: 2281180,
    },
    {
      date: getTrendDate(19),
      value: 2390680,
    },
    {
      date: getTrendDate(18),
      value: 2827890,
    },
    {
      date: getTrendDate(17),
      value: 2968180,
    },
    {
      date: getTrendDate(16),
      value: 3491450,
    },
    {
      date: getTrendDate(15),
      value: 3334510,
    },
    {
      date: getTrendDate(14),
      value: 4040690,
    },
    {
      date: getTrendDate(13),
      value: 3623960,
    },
    {
      date: getTrendDate(12),
      value: 3712640,
    },
    {
      date: getTrendDate(11),
      value: 3649580,
    },
    {
      date: getTrendDate(10),
      value: 3666260,
    },
    {
      date: getTrendDate(9),
      value: 4479820,
    },
    {
      date: getTrendDate(8),
      value: 4343880,
    },
    {
      date: getTrendDate(7),
      value: 4020520,
    },
    {
      date: getTrendDate(6),
      value: 5012390,
    },
    {
      date: getTrendDate(5),
      value: 5118690,
    },
    {
      date: getTrendDate(4),
      value: 6224160,
    },
    {
      date: getTrendDate(3),
      value: 5084620,
    },
    {
      date: getTrendDate(2),
      value: 4823520,
    },
    {
      date: getTrendDate(1),
      value: 4349830,
    },
  ];

  return {
    SovChartFakeData,
    AvgRankChartFakeData,
    RankingDistributionFakeData,
    SearchTrendFakeData,
  };
};
