import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { useModal } from 'Hooks/base/useModal';
import { InvoicesTable } from 'Pages/Invoices/InvoicesTable';
import { t } from 'Utilities/i18n';

const Invoices = () => {
  const { hideModal } = useModal();
  return (
    <ModalBorder className="invoices-modal" title={t('Invoices')} onClose={hideModal}>
      <InvoicesTable />
    </ModalBorder>
  );
};

export default Invoices;
