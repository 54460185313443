import { Slider, Text } from '@mantine/core';
import HelpGuideButton from 'Components/HelpGuideButton';
import Switch from 'Components/Switch';
import ActionbarContainer from 'Pages/Layout/ActionsMenu/components/ActionbarContainer';
import { ColumnLayout } from 'Pages/SiteMapping/components/ColumnLayout';
import { t } from 'Utilities/i18n';
import { siteMappingViewModes } from './support/helpers';
import { useSiteMappingData } from './support/hooks';
import { SiteMapMode, SiteMapViewMode } from './support/types';

/**
 * Organic Site Explorer
 */
const SiteMappingPage = (): JSX.Element => {
  const {
    rootNode,
    nodeSelectionHandler,
    loading,
    error,
    viewModeId,
    setViewMode,
    filters,
    selectedNode,
    loadingSubdomains,
    setOrderBy,
    maxChildren,
    setMaxChildren,
    setTreeViewMode,
    treeViewMode,
  } = useSiteMappingData();

  const includeKeydis = viewModeId === SiteMapMode.TRACKED_AND_UNTRACKED;
  const viewModes = siteMappingViewModes;

  const ViewModeSwitch = () => (
    <Switch
      key="viewMode"
      activeId={viewModeId}
      width={150}
      disabled={false}
      els={viewModes}
      onClick={(el: SiteMapViewMode) => {
        setViewMode && setViewMode(el);
      }}
      activeById
      tooltips={[t('Tracked + keyword discovery keywords'), t('Tracked keywords only')]}
    />
  );

  return (
    <>
      <ActionbarContainer mb="xl" h={50}>
        <ViewModeSwitch />
        <div>
          <Text size={'xs'}>{t('Max subpages per page')}</Text>
          <Slider
            color={'snorlax'}
            w={200}
            defaultValue={maxChildren}
            min={10}
            max={150}
            thumbSize={14}
            step={10}
            marks={[
              { value: 40, label: 40 },
              { value: 100, label: 100 },
              { value: 150, label: 150 },
            ]}
            styles={{ markLabel: { marginTop: 2 } }}
            onChangeEnd={(value) => {
              setMaxChildren && setMaxChildren(value);
            }}
            mb={2}
          />
        </div>
        <HelpGuideButton
          helpguideLink={'https://www.accuranker.com/help/keywords/organic-site-explorer'}
        />
      </ActionbarContainer>
      <ColumnLayout
        error={error}
        loading={loading || loadingSubdomains}
        rootNode={rootNode}
        nodeSelectionHandler={nodeSelectionHandler}
        selectedNode={selectedNode}
        filters={filters}
        includeKeydis={includeKeydis}
        setOrderBy={setOrderBy}
        setTreeViewMode={setTreeViewMode}
        treeViewMode={treeViewMode}
        viewModeId={viewModeId}
      />
    </>
  );
};

export default SiteMappingPage;
