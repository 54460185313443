import * as React from 'react';
import FilterUrlSyncHandler from 'Components/Filters/FilterUrlSyncHandler';
import type { FilterSet } from 'Types/FilterSet';
import { useSmartRouterSubscription } from '../../Utilities/Router/components/LocationPathnameContext';

function withFilterset<InputProps extends {}>(
  Component: React.ComponentType<React.PropsWithChildren<InputProps>>,
  filterSet: FilterSet,
): React.ComponentType<React.PropsWithChildren<InputProps>> {
  const ResultComponent = (props: InputProps) => {
    const filterParam = useSmartRouterSubscription((_, params) => params?.filter);
    return (
      <FilterUrlSyncHandler filterSet={filterSet as FilterSet} filterParam={filterParam}>
        <Component {...props} />
      </FilterUrlSyncHandler>
    );
  };
  const displayName = Component.displayName || Component.name || 'Component';
  ResultComponent.displayName = `withFilterset(${displayName})`;
  return ResultComponent;
}

export default withFilterset;
