/* eslint-disable react/display-name */
import { Box } from '@mantine/core';
import AccText from 'Components/Text/AccText';
import { tct } from 'Utilities/i18n';
import { useKeywordOveruse, useKeywordRawCount } from '../hooks';
import { AddKeywordsModalType } from '../types';

export default ({
  mode,
  keydisKeywordsCounter,
}: {
  mode: AddKeywordsModalType;
  keydisKeywordsCounter?: number;
}) => {
  const keywordsRawCount = useKeywordRawCount(mode, keydisKeywordsCounter);
  const { accountCurrentKeywords, maxKeywords, difference } = useKeywordOveruse(keywordsRawCount);
  if (maxKeywords === null || accountCurrentKeywords === null || difference === null) {
    return null;
  }

  if (difference < 0) {
    return (
      <Box m="0 -1rem" display="grid" style={{ placeContent: 'center' }} bg="yellow.0">
        <AccText size="xs" p="xxs">
          {tct(
            'You are adding more keywords than your current [maxKeywords] keywords plan allow. You are exceeding your limit by [exceeding] keywords.',
            {
              maxKeywords,
              exceeding: Math.abs(difference),
            },
          )}
        </AccText>
      </Box>
    );
  }
  return null;
};

export const useKeywordOveruseNoticeLoading = (mode: AddKeywordsModalType) => {
  const keywordsRawCount = useKeywordRawCount(mode);
  const { difference } = useKeywordOveruse(keywordsRawCount);
  return difference === null;
};
