import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Group } from '@mantine/core';
import { IconPlugConnected } from '@tabler/icons-react';
import { gaSetFromCallback, gaToggleRefetch } from 'Actions/GoogleAccountsAction';
import { showModal } from 'Actions/ModalAction';
import { updateUserGoogleConnections } from 'Actions/UserAction';
import AccButton from 'Components/AccButton/AccButton';
import ConnectedLabel from 'Components/ConnectedLabel';
import { useEditProfileRemoveDriveConnectionMutation, useGenericGetUserLazyQuery } from 'Ghql';
import { StoreType } from 'Types/Store';
import { t } from 'Utilities/i18n/index';
import formStyles from 'css/layout/form-layout.module.scss';

const GoogleDriveAccount = () => {
  const dispatch = useDispatch();
  const [getUser] = useGenericGetUserLazyQuery();
  const [removeDriveConnection] = useEditProfileRemoveDriveConnectionMutation();

  const { user, shouldRefetch } = useSelector((state: StoreType) => ({
    user: state.user,
    shouldRefetch: state.googleAccounts.shouldRefetch,
  }));

  useEffect(() => {
    if (shouldRefetch) {
      dispatch(gaToggleRefetch());
      dispatch(gaSetFromCallback(false));
      getUser({ fetchPolicy: 'network-only' }).then(({ data }) => {
        const googleConnections = data?.user?.googleConnections;
        if (googleConnections) {
          dispatch(updateUserGoogleConnections(googleConnections));
        }
      });
    }
  }, [shouldRefetch, dispatch, getUser]);

  const handleRemoveDrive = () => {
    dispatch(
      showModal({
        modalType: 'Confirmation',
        modalProps: {
          title: t('Delete Google Drive Connection?'),
          description: t(
            'All scheduled reports that you have created and that use Google Drive will be deleted.',
          ),
          cancelLabel: t('Cancel'),
          confirmLabel: t('Delete connection'),
          action: () => {
            removeDriveConnection({}).then(({ data }) => {
              const googleConnections = data?.removeDriveConnection?.user?.googleConnections;
              if (googleConnections) {
                dispatch(updateUserGoogleConnections(googleConnections));
              }
            });
          },
        },
      }),
    );
  };

  const handleAddDrive = () => {
    dispatch(
      showModal({
        modalType: 'ConnectToDrive',
        modalTheme: 'light',
        modalProps: {
          message: t(
            'You do not have a Google Drive connection setup with AccuRanker. Please connect to your Google account to allow AccuRanker to create spreadsheet reports. AccuRanker will only have access to the files it creates, and cannot read other files.',
          ),
          modalType: 'ConnectToDrive',
        },
      }),
    );
  };

  const isConnected = !!user.googleConnections?.length;
  return (
    <div className={formStyles.formRow}>
      <div className="form-label">{t('Google Drive Connection')}</div>
      <Group justify="space-between">
        <ConnectedLabel
          isConnected={isConnected}
          description={isConnected ? t('Connected') : t('No connection')}
        />
        {!isConnected ? (
          <AccButton
            onClick={handleAddDrive}
            leftSection={<IconPlugConnected size={18} />}
            variant="secondary"
          >
            {t('Add connection')}
          </AccButton>
        ) : (
          <AccButton
            onClick={handleRemoveDrive}
            leftSection={<IconPlugConnected size={18} />}
            variant="secondary"
          >
            {t('Remove connection')}
          </AccButton>
        )}
      </Group>
    </div>
  );
};

export default GoogleDriveAccount;
