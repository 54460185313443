import { ComponentType, useEffect } from 'react';
import { FieldProps } from 'redux-form';

export const withDefaultFormValue = <T,>(
  Component: ComponentType<React.PropsWithChildren<unknown>>,
) => {
  const result = (props: T & FieldProps) => {
    useEffect(() => {
      if (!props.input?.value && props.defaultValue) {
        props.input?.onChange?.(props.defaultValue);
      }
    }, [props.defaultValue, props.input?.value]);
    return <Component {...props} />;
  };
  result.displayName = 'withDefaultFormValue';

  return result;
};
