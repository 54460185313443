import cn from 'classnames';
import styles from './kpi-change-badge.module.scss';

type Props = {
  color?: 'green' | 'red' | 'grey';
  value?: string;
};

const KPIChangeBadge = (props: Props) => {
  const { color, value } = props;

  return (
    <div className={styles.kpiChangeContainer}>
      {value && (
        <div
          className={cn(styles.kpiChangeBadge, {
            [styles.red]: color === 'red',
            [styles.green]: color === 'green',
            [styles.grey]: color === 'grey',
          })}
        >
          {value}
        </div>
      )}
    </div>
  );
};

export default KPIChangeBadge;
