import { useMemo } from 'react';
import { Box } from '@mantine/core';
import { Field, Fields } from 'redux-form';
import FilterCountField from 'Components/Filters/FilterCountField';
import { CheckBoxGroup, DropdownField } from 'Components/Forms/Fields';
import { FilterComparison } from 'Types/Filter';
import Validator from 'Utilities/validation';
import { getComparisonLabels } from '../labelFunc';

type ValueLabel = {
  value: any;
  label: string;
  Icon?: string | (() => JSX.Element);
};
type Props = {
  items: ValueLabel[];
  disallowEmptySelection?: boolean;
  withCount?: boolean;
};

const comparisons = [FilterComparison.ALL, FilterComparison.ANY, FilterComparison.NONE];

const ChecklistEditor = (props: Props) => {
  const { disallowEmptySelection, withCount } = props;
  const comparisonLabels = useMemo(() => getComparisonLabels(), []);
  const comparisonItems: ValueLabel[] = comparisons.map((comparison) => ({
    label: comparisonLabels[comparison],
    value: comparison,
  }));

  let otherProps = {};
  if (disallowEmptySelection) {
    otherProps = {
      validate: Validator.nonEmptyArrayOrObj,
    };
  }

  return (
    <div>
      <Field name="value" component={CheckBoxGroup} items={props.items} {...otherProps} />
      <Box pos="relative">
        {withCount && (
          <Fields
            names={['comparison', 'value', 'type', 'attribute']}
            component={FilterCountField}
            pos="absolute"
            right="28px"
            top="11px"
          />
        )}
        <Field name="comparison" component={DropdownField} items={comparisonItems} />
      </Box>
    </div>
  );
};

export default ChecklistEditor;
