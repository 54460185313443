import { useEffect, useState } from 'react';
import { useField } from 'react-final-form';
import { Box, Flex } from '@mantine/core';
import { Field } from 'Components/Fields';
import AccTitle from 'Components/Title/AccTitle';
import { t } from 'Utilities/i18n';
import Validator from 'Utilities/validation';
import { CountryOption, FIELD_NAMES } from '..';
import styles from '../register.module.scss';

type Props = {
  countryOptions: CountryOption[];
  vatOptions: CountryOption[];
};

const AddressInfo = ({ countryOptions, vatOptions }: Props) => {
  const [selectedCountry, setSelectedCountry] = useState<CountryOption>();

  const { input: countryInput } = useField(FIELD_NAMES.COUNTRY);
  const { input: VATPrefixInput } = useField(FIELD_NAMES.VAT_PREFIX);

  // set (1) country object and (2) VAT prefix, when selecting a new country
  useEffect(() => {
    if (countryInput.value) {
      const country = countryOptions.find((c) => c.countryCode === countryInput.value);
      setSelectedCountry(country);
      VATPrefixInput.onChange(country?.countryCode);
    }
  }, [countryInput.value, countryOptions, VATPrefixInput.onChange]);

  return (
    <>
      <AccTitle type="h3" className={styles.sectionTitle}>
        <span>{t('Address Information')}</span>
      </AccTitle>
      <Field.TextInput
        label={t('Address (street)')}
        labelClassname="required"
        name={FIELD_NAMES.STREET}
        id={FIELD_NAMES.STREET}
        elementType="input"
        type="text"
        placeholder={t('Enter your street')}
        autoComplete="off"
        required
        hideRequiredStar
        validate={Validator.required}
      />
      <Field.TextInput
        label={t('City')}
        labelClassname="required"
        name={FIELD_NAMES.CITY}
        id={FIELD_NAMES.CITY}
        elementType="input"
        type="text"
        placeholder={t('Enter your city')}
        autoComplete="off"
        required
        hideRequiredStar
        validate={Validator.required}
      />
      <Field.TextInput
        label={t('State')}
        name={FIELD_NAMES.STATE}
        id={FIELD_NAMES.STATE}
        elementType="input"
        type="text"
        placeholder={t('Enter your state/province/region')}
        autoComplete="off"
      />
      <Field.TextInput
        label={t('ZIP')}
        labelClassname="required"
        name={FIELD_NAMES.ZIPCODE}
        id={FIELD_NAMES.ZIPCODE}
        elementType="input"
        type="text"
        placeholder={t('Enter your ZIP')}
        autoComplete="off"
        required
        hideRequiredStar
        validate={Validator.required}
      />
      <Field.Select
        label={t('Country')}
        name={FIELD_NAMES.COUNTRY}
        id={FIELD_NAMES.COUNTRY}
        type="text"
        placeholder={t('Select your country')}
        disableLabel
        options={countryOptions}
        autoComplete="off"
        required
        hideRequiredStar
        validate={Validator.required}
      />

      {!!selectedCountry?.vatCode && (
        <Flex columnGap="sm">
          <Box w="33%">
            <Field.Select
              name={FIELD_NAMES.VAT_PREFIX}
              id={FIELD_NAMES.VAT_PREFIX}
              label={'VAT prefix'}
              type="text"
              placeholder={'VAT prefix'}
              options={vatOptions}
              autoComplete="off"
            />
          </Box>
          <Box w="66%">
            <Field.TextInput
              name={FIELD_NAMES.VAR_NUMBER}
              id={FIELD_NAMES.VAR_NUMBER}
              label={t('EU VAT number')}
              elementType="input"
              type="text"
              placeholder={t('Enter your VAT number')}
              autoComplete="off"
              validateOnBlur
              validate={Validator.vatNumber}
            />
          </Box>
        </Flex>
      )}
    </>
  );
};

export default AddressInfo;
