import AccText from 'Components/Text/AccText';

type Props = {
  label: string;
  value: string | number;
  caption?: string;
  [key: string]: any;
};

const SelectOption = (props: Props) => {
  return (
    <div {...props}>
      <AccText size="sm">{props.label}</AccText>
      {props.caption ? (
        <AccText size="xs" c="gray.5">
          {props.caption}
        </AccText>
      ) : null}
    </div>
  );
};

export default SelectOption;
