import { useEffect } from 'react';
import { useField } from 'react-final-form';
import { Box, Flex, useMantineTheme } from '@mantine/core';
import { Field } from 'Components/Fields';
import Title from 'Components/Title/AccTitle';
import { UnrankedWeightConstants } from 'Constants';
import { t } from 'Utilities/i18n/index';
import { FieldName } from '../helpers';
import {
  DisabledUnrankedWeight,
  EnabledUnrankedWeight,
  IncludeUnrankedHelperText,
  UnrankedWeightHelperText,
} from './helpers';

type AverageRankSettingsProps = {
  values: Record<FieldName, any>;
  organizationUnrankedWeight: number | null;
};

export const AverageRankSettings = ({
  values,
  organizationUnrankedWeight,
}: AverageRankSettingsProps) => {
  const theme = useMantineTheme();

  const fieldUnrankedWeight = useField(FieldName.unrankedWeight);

  useEffect(() => {
    if (!values[FieldName.unrankedWeightEnabled]) {
      // so form retains pristine state
      fieldUnrankedWeight.input.onChange(
        organizationUnrankedWeight
          ? organizationUnrankedWeight
          : UnrankedWeightConstants.DEFAULT_IF_DISABLED,
      );
    }
  }, [values[FieldName.unrankedWeightEnabled], organizationUnrankedWeight]);

  return (
    <Flex direction="column" w="100%">
      <Title type="h5">{t('Average Rank')}</Title>

      <Flex justify="space-between">
        <Title type="h6" helper={<IncludeUnrankedHelperText />}>
          {t('Include "Not in top 100" rank keywords')}
        </Title>

        <Field.SwitchInput name={FieldName.unrankedWeightEnabled} nowrap />
      </Flex>

      <Flex justify="space-between" mt={theme.other.gutter} align="center">
        <Title type="h6" helper={<UnrankedWeightHelperText />}>
          {t('Rank value of "Not in top 100" rank keywords')}
        </Title>

        <Box hidden={!values[FieldName.unrankedWeightEnabled]}>
          <EnabledUnrankedWeight />
        </Box>

        <Box hidden={values[FieldName.unrankedWeightEnabled]}>
          <DisabledUnrankedWeight />
        </Box>
      </Flex>
    </Flex>
  );
};
