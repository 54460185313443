import { SelectItem } from 'Components/AccSelect';

/**
 * Check if the domains url registered on AccuRanker is included
 * in the Google Analytics Property label
 */
export function matchDomainToProperty(domain: SelectItem, propertyOptions: SelectItem<string>[]) {
  // Test if the domainUrl is matching any of the GA Property.labels
  const urlMatches = propertyOptions.find((option) => {
    return option.label?.includes(domain.url as string);
  });
  return urlMatches;
}
