import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Flex } from '@mantine/core';
import { IconChevronsUp, IconPlugConnected } from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import ConnectedLabel from 'Components/ConnectedLabel';
import { STEPS } from 'Components/Modal/Wizard/Base/Account';
import { ACCOUNT, PROPERTY } from 'Components/Modal/Wizard/ConnectToGA';
import AccTitle from 'Components/Title/AccTitle';
import { universalAnalyticsIdPrefix } from 'Constants';
import { getDisabledDemoText } from 'Constants/messages';
import { useModal } from 'Hooks/base/useModal';
import { useDomainId, useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import toast from 'Hooks/useToast';
import {
  removeAdobeAccountQuery,
  removeGoogleAccountQuery,
} from 'Pages/DomainSettings/support/queries';
import { t } from 'Utilities/i18n';
import styles from './menu.module.scss';

const ConnectionStatusLabel = ({
  isConnected,
  isAdobeMarketingConnected,
  isGAConnected,
}): JSX.Element => {
  const connAdobeTxt = t('Connected to Adobe Analytics');
  const connGATxt = t('Connected to Google Analytics');
  const notConnTxt = t('This domain is not connected to an analytics service');

  return (
    <ConnectedLabel
      isConnected={isConnected}
      description={
        isAdobeMarketingConnected ? connAdobeTxt : isGAConnected ? connGATxt : notConnTxt
      }
    />
  );
};

const ConnectionMetaData = ({ descriptionType, description }) => (
  <div className={styles.meta}>
    <div key="description">
      <h6>{descriptionType}:</h6> <span>{description}</span>
    </div>
  </div>
);

export const ConnectToAnalytics = (): JSX.Element => {
  const domainId = useDomainId();
  const { domainInfo, refetch: refreshDomainInfo, isDemoDomain } = useQueryDomainInfo();
  const disabledDemoText = getDisabledDemoText();
  const { showModal, hideModal } = useModal();
  const [isConnected, setIsConnected] = useState<boolean>();

  const [removeAdobeAccount] = useMutation(removeAdobeAccountQuery);
  const [removeGAAccount] = useMutation(removeGoogleAccountQuery);

  useEffect(() => {
    if (domainInfo) {
      setIsConnected(Boolean(domainInfo?.isGAConnected || domainInfo?.isAdobeMarketingConnected));
    }
  }, [domainInfo]);

  let description = domainInfo?.googleOauthConnectionGa?.description ?? '';
  let descriptionType = t('Google OAuth Connection');
  if (domainInfo?.isAdobeMarketingConnected) {
    description = domainInfo?.adobeMarketingConnection?.description ?? '';
    descriptionType = t('Adobe Marketing Connection');
  }

  const handleConnectToAnalytics = () =>
    showModal({
      modalType: 'ConnectToAnalytics',
      modalTheme: 'light',
      modalProps: {
        domainId,
        refresh: refreshDomainInfo,
      },
    });

  const handleUpgradeToGA4 = () => {
    showModal({
      modalType: 'ConnectToGA',
      modalTheme: 'light',
      modalProps: {
        domainId,
        skipToStep: PROPERTY,
        skippedData: {
          [ACCOUNT]: { accountId: domainInfo?.gaAccountId },
          [STEPS.SELECT]: { connectionId: domainInfo?.googleOauthConnectionGa?.id },
        },
      },
    });
  };

  const handleDisconnectFromAnalytics = () => {
    let removeAccount;
    if (domainInfo?.isAdobeMarketingConnected) {
      removeAccount = removeAdobeAccount;
    } else if (domainInfo?.isGAConnected) {
      removeAccount = removeGAAccount;
    }

    removeAccount &&
      removeAccount({ variables: { input: { domainId } } }).then(
        () => {
          toast.success(t('Account removed'));
          refreshDomainInfo();
          hideModal();
        },
        () => {
          toast.error(t('Failed to remove account'));
        },
      );
  };

  return (
    <Flex direction="column" rowGap="md">
      <AccTitle type="h3">{t('Analytics')}</AccTitle>
      <ConnectionStatusLabel
        isConnected={isConnected}
        isAdobeMarketingConnected={domainInfo?.isAdobeMarketingConnected}
        isGAConnected={domainInfo?.isGAConnected}
      />
      {isConnected && (
        <ConnectionMetaData descriptionType={descriptionType} description={description} />
      )}
      <Flex columnGap="xs">
        {domainInfo?.gaPropertyId?.startsWith(universalAnalyticsIdPrefix) && (
          <AccButton
            onClick={handleUpgradeToGA4}
            leftSection={<IconChevronsUp size={18} />}
            variant="secondary"
            mr="xxs"
            disabled={isDemoDomain}
            tooltip={isDemoDomain ? disabledDemoText : undefined}
          >
            {t('Upgrade to GA4')}
          </AccButton>
        )}
        {!isConnected ? (
          <AccButton
            onClick={handleConnectToAnalytics}
            leftSection={<IconPlugConnected size={18} />}
            variant="secondary"
            disabled={isDemoDomain}
            tooltip={isDemoDomain ? disabledDemoText : undefined}
          >
            {t('Connect to Analytics')}
          </AccButton>
        ) : (
          <AccButton
            onClick={handleDisconnectFromAnalytics}
            leftSection={<IconPlugConnected size={18} />}
            variant="secondary"
            disabled={isDemoDomain}
            tooltip={isDemoDomain ? disabledDemoText : undefined}
          >
            {t('Disconnect from Analytics')}
          </AccButton>
        )}
      </Flex>
    </Flex>
  );
};
