import { ApolloError, FetchResult } from '@apollo/client';
import { FORM_ERROR } from 'final-form';
import isEmpty from 'lodash/isEmpty';
import { ErrorKind, isFieldError } from 'Utilities/errors';
import { t } from 'Utilities/i18n';

const extractDataByFirstKey = (res: FetchResult<any>) => {
  const key = Object.keys(res.data || {});
  return (res.data || {})?.[key as any];
};

const transformErrors = (errors: any, isForm?: boolean) => {
  if (!isForm || !Array.isArray(errors)) {
    return errors;
  }

  return errors.reduce((acc, e) => {
    let key = isFieldError((e || {}).field) ? e.field : FORM_ERROR;
    if (e.field === ErrorKind.ALL_ERRORS) {
      key = FORM_ERROR;
    }
    const error = e.message || (e.messages || []).join('\n');
    const resultError = acc[key] ? acc[key].concat(`\n${error}`) : error;
    return { ...acc, [key]: resultError };
  }, {});
};

const extractDataErrors = (errors: any[]) => {
  return errors.reduce(
    (acc, e) => acc.concat(`${e ? '\n' : ''}${(e.messages || []).join('. ')}`),
    '',
  );
};

export const extractErrorFromGhql = (res: any, isForm?: boolean) => {
  const { errors } = extractDataByFirstKey(res) || {
    errors: res?.errors,
  };
  const resultError =
    errors && (isForm ? transformErrors(errors, isForm) : extractDataErrors(errors));
  return isEmpty(resultError) ? undefined : resultError;
};

export const extractValidationError = (errorMsg: string) => {
  // If a validation error comes from the backend, it will start with ValidationError:
  // Take out the part of the string coming after ValidationError: if it exists, else return undefined
  if (!errorMsg) return undefined;
  if (!errorMsg.includes('ValidationError:')) return undefined;
  return errorMsg.split('ValidationError: ')[1];
};

export const extractFormErrors = (res: any) => extractErrorFromGhql(res, true);

// Used to get data/error from FetchResult if one mutation/query
export const extractDataFromGraphqlResponse = (res: FetchResult<any>, isForm?: boolean) => {
  const { ...data } = extractDataByFirstKey(res) || {};
  return {
    data,
    errors: extractErrorFromGhql(res, isForm),
  };
};

export const buildSubmitFormError = (error?: string, errorObj?: Record<string, string>) => {
  let result = {};

  if (error) {
    result[FORM_ERROR] = error;
  }
  if (errorObj) {
    result = { ...result, ...errorObj };
  }

  return result;
};

export const getDefaultSubmitError = () =>
  buildSubmitFormError(t('Failed to perform operation, please try again'));

export const extractQueryFormErrors = <T = any>(
  res: FetchResult<T>,
  options?: { hasError?: boolean; hasSubmitError?: boolean },
) => {
  let errors = extractErrorFromGhql(res, true);

  if (options?.hasSubmitError) {
    if (!errors) {
      errors = getDefaultSubmitError();
    }
    errors = { ...getDefaultSubmitError(), ...errors };
  }

  if (errors) {
    return errors;
  } else if (options?.hasError) {
    return getDefaultSubmitError();
  }
  return undefined;
};

export const extractServerErrorFromGhql = (
  _: ApolloError,
  {
    isForm,
    defaultErrorMsg,
  }: {
    isForm?: boolean;
    defaultErrorMsg?: string;
  },
) => {
  return {
    errors: isForm
      ? buildSubmitFormError(t('Something went wrong. Please try again, or contact support.'))
      : defaultErrorMsg,
    data: null,
  };
};
