import { memo, useMemo, useState } from 'react';
import { Flex, useMantineTheme } from '@mantine/core';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import { AccFastIcon } from 'Components/AccFastIcon';
import AccLazyPopover from 'Components/AccLazyPopover/AccLazyPopover';
import AccSegmentedControl from 'Components/AccSegmentedControl/AccSegmentedControl';
import SerpIcon from 'Components/AccuTable/CellRenderer/HelperComponents/SerpPopOver/serpIcon';
import { SerpFeaturesItem } from 'Components/AccuTable/CellRenderer/HelperComponents/SerpPopOver/types';
import { convertToTitleCase } from 'Components/AccuTable/CellRenderer/serp';
import Loader from 'Components/Loader';
import AccTitle from 'Components/Title/AccTitle';
import { useSerpFeaturesByPathLazyQuery } from 'Ghql';
import { useFilters } from 'Hooks';
import { useMetaData } from 'Hooks/data/user/useMetaData';
import { BarChart } from 'Pages/Keywords/Overview/components/BarChart/BarChart';
import { FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import { propsIsEqualComparison } from 'Utilities/compare';
import { t, tct } from 'Utilities/i18n';
import { IconSerpFeatures } from 'icons/tag-cloud';

interface SERPObject {
  [key: string]: number;
}

const getSERPFields = (node) => {
  const serpFields: { [key: string]: number } = {};
  for (const key in node) {
    if (key.startsWith('serp')) {
      if (node[key]) {
        const prefix = 'serp_';
        const suffix = '_value';
        const trimmedKey = key.substring(prefix.length, key.length - suffix.length);
        serpFields[trimmedKey] = node[key];
      }
    }
  }
  return serpFields;
};

type SERPCellProps = {
  path: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  domainId?: string;
};

const SERPCell = ({ path, isOpen, setIsOpen, domainId }: SERPCellProps) => {
  const [serpSegment, setSerpSegment] = useState<'owned' | 'available'>('available');

  const theme = useMantineTheme();

  const globalFilters = useFilters();
  const filters = useMemo(() => {
    let result = [...globalFilters];
    if (domainId) {
      result = result.filter((filter) => filter.attribute !== FilterAttribute.DOMAINS);
      result.push({
        attribute: FilterAttribute.DOMAINS,
        type: FilterValueType.LIST,
        comparison: FilterComparison.CONTAINS,
        value: [domainId],
      });
    }
    return result;
  }, [globalFilters, domainId]);

  const [fetchData, { data, loading }] = useSerpFeaturesByPathLazyQuery();

  const serpFeaturesForPath = data?.serpFeaturesByPath?.serpFeaturesByPath?.[path];
  const serpFeatures = serpFeaturesForPath ? getSERPFields(serpFeaturesForPath) : {};

  const metaData = useMetaData();
  const iconColor = theme.colors.snorlax[theme.primaryShade as number];

  const mapToBarChartItem = (serpFeaturesObj: SERPObject) => {
    return Object.keys(serpFeaturesObj)
      .sort((a, b) => serpFeaturesObj[b] - serpFeaturesObj[a])
      .slice(0, 10)
      .map((feature) => {
        return {
          value: serpFeaturesObj[feature],
          label: convertToTitleCase(feature.replace(/owned|_/g, ' ')),
          id: feature,
          background: theme.colors.snorlax[theme.primaryShade as number],
          icon: (
            <SerpIcon
              id={feature}
              size={20}
              disablePopover={true}
              serpFeatures={metaData?.serpFeatures as SerpFeaturesItem[] | undefined}
            />
          ),
        };
      });
  };

  const [ownedSerpsItems, availableSerpsItems] = useMemo(() => {
    const tmpOwned: SERPObject = {};
    const tmpAvailable: SERPObject = {};
    for (const key in serpFeatures) {
      if (key.includes('owned')) {
        tmpOwned[key] = serpFeatures[key];
      } else {
        tmpAvailable[key] = serpFeatures[key];
      }
    }
    return [mapToBarChartItem(tmpOwned), mapToBarChartItem(tmpAvailable)];
  }, [serpFeatures]);

  const serpItems = serpSegment === 'owned' ? ownedSerpsItems : availableSerpsItems;

  return (
    <AccLazyPopover
      target={
        <AccActionIcon
          styles={{
            root: { backgroundColor: isOpen ? theme.colors.snorlax[0] : undefined },
          }}
          variant="subtle"
          onClick={() => {
            fetchData({ variables: { filters } });
          }}
        >
          <AccFastIcon src={IconSerpFeatures} size={16} color={iconColor} />
        </AccActionIcon>
      }
      outerContainerProps={{ justify: 'end' }}
      width={400}
      position="bottom"
      onToggle={() => {
        setIsOpen(!isOpen);
      }}
    >
      <Flex direction="column">
        <Flex align="center" justify="space-between" mb={'md'}>
          <AccTitle type="h4">{t('SERP features')}</AccTitle>
          <AccSegmentedControl
            value={serpSegment}
            onChange={(value) => setSerpSegment(value as typeof serpSegment)}
            data={[
              { label: t('Available'), value: 'available' },
              { label: t('Owned'), value: 'owned' },
            ]}
          />
        </Flex>
        {loading ? (
          <Loader
            style={{
              height: 350,
            }}
          />
        ) : serpItems.length > 0 ? (
          <BarChart normalNumberOfItems={10} items={serpItems} />
        ) : (
          <div>
            {tct('No [owned]SERP features found', {
              owned: serpSegment === 'owned' ? t('owned ') : '',
            })}
          </div>
        )}
      </Flex>
    </AccLazyPopover>
  );
};

export default memo(SERPCell, propsIsEqualComparison);
