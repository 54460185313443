import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import { RankingDistributionFormatedSeries } from 'Pages/Keywords/Overview/components/HistoryCharts/RankingDistribution/helpers';
import { colorScheme } from 'Utilities/colors';
import { notEmpty } from 'Utilities/underdash';

interface RankingDistributionSeries {
  date: number;
  keywords0To3: number;
  keywords4To10: number;
  keywords11To20: number;
  keywords21To50: number;
  keywordsAbove50: number;
  keywordsUnranked: number;
  keywordsTotal: number;
}

const SERIA_ID = 'rankingDistribution';
const KEYWORDS1_SERIA = '1';
const KEYWORDS2_SERIA = '2';
const KEYWORDS3_SERIA = '3';
const KEYWORDSGT3_SERIA = '>3';
const KEYWORDSUNRANKED_SERIA = 'Not ranking';
const KEYWORDS1_RESPONSE = 'rank1';
const KEYWORDS2_RESPONSE = 'rank2';
const KEYWORDS3_RESPONSE = 'rank3';
const KEYWORDSGT3_RESPONSE = 'rankGt3';
const KEYWORDSUNRANKED_RESPONSE = 'nonRanking';
const colors = colorScheme.rankDistribution;

const seriesKeys = [
  KEYWORDS1_RESPONSE,
  KEYWORDS2_RESPONSE,
  KEYWORDS3_RESPONSE,
  KEYWORDSGT3_RESPONSE,
  KEYWORDSUNRANKED_RESPONSE,
];
const responseToSeriaMap = {
  [KEYWORDS1_RESPONSE]: () => KEYWORDS1_SERIA,
  [KEYWORDS2_RESPONSE]: () => KEYWORDS2_SERIA,
  [KEYWORDS3_RESPONSE]: () => KEYWORDS3_SERIA,
  [KEYWORDSGT3_RESPONSE]: () => KEYWORDSGT3_SERIA,
  [KEYWORDSUNRANKED_RESPONSE]: () => KEYWORDSUNRANKED_SERIA,
};

export const getRankingDistributionSeries = (
  overviewRankingDistribution?: (any | null)[] | null,
) => {
  // overviewRankingDistribution is on the format of {date: {rank1: 0, rank2-5: 0, ...}, ...}

  let series: any = [];

  if (!overviewRankingDistribution) {
    return [];
  }

  // iterate through object entries
  for (const [date, ranking] of Object.entries(overviewRankingDistribution)) {
    // iterate through object entries

    // skip if not date
    if (!date) {
      continue;
    }

    let totalKeywords = 0;
    const record = { date: moment(date).unix() * 1000, keywordsTotal: totalKeywords };
    for (const [rank, count] of Object.entries(ranking)) {
      record[rank] = count;
      totalKeywords += count as number;
      record.keywordsTotal = totalKeywords;
    }
    series.push(record);
  }

  if (series?.length === 1) {
    const fakeFirstDate = cloneDeep(series[0]);
    fakeFirstDate.date = moment(series[0].date).subtract(1, 'day').toDate().getTime();
    series = [fakeFirstDate, series[0]];
  }
  return series;
};

export const formatSeries = (
  data?: RankingDistributionSeries[],
): RankingDistributionFormatedSeries[] => {
  return seriesKeys.map((name, idx) => {
    return {
      name: responseToSeriaMap[name](),
      id: SERIA_ID + idx,
      color: colors[idx % colors.length],
      marker: {
        symbol: 'circle',
        radius: 3,
      },
      showInNavigator: true,
      turboThreshold: 500000,
      type: 'areaspline',
      pointPlacement: 'between',
      data: data?.filter(notEmpty)?.map((dataPoint) => ({
        x: dataPoint.date,
        y: dataPoint[name as keyof typeof dataPoint] || 0,
        totalKeywords: dataPoint.keywordsTotal,
      })),
    };
  });
};

export const getCsvData = (series) => () => {
  const header = `Date,${series.map((s) => s.name).join(',')},Total keywords` + '\n';
  let i;
  let rows = '';
  for (i = 0; i < series[0].data.length; i++) {
    const reverseIdx = series[0].data.length - 1 - i;
    rows += `${moment.unix(series[0].data[reverseIdx].x / 1000).format('YYYY-MM-DD')}`;
    series.map((s) => (rows += `,${s.data[reverseIdx].y}`));
    rows += `,${series[0].data[reverseIdx].totalKeywords}`;
    rows += '\n';
  }
  return header + rows;
};
