import { useSelector } from 'react-redux';
import { Flex } from '@mantine/core';
import isEmpty from 'lodash/isEmpty';
import xor from 'lodash/xor';
import FiltersEditorComponent from 'Components/Filters/FiltersEditor';
import type { FiltersEditorProps } from 'Components/HOC/withFiltersEditor';
import withFiltersEditor from 'Components/HOC/withFiltersEditor';
import { useLatestCompletedScrapeDateQuery } from 'Ghql';
import { useGetFilters } from 'Hooks/data/filter/useFilters';
import { DomainInfo } from 'Query';
import SpecificFilterSelector from 'Selectors/SpecificFilterSelector';
import type { FilterBase, PeriodFilter as PeriodFilterType } from 'Types/Filter';
import CompareToDropdown from './CompareToDropdown';
import './period-filter-selector.scss';

type Props = {
  periodFilter?: PeriodFilterType;
  min?: Date;
  max?: Date;
  lastFullScrape?: Date;
  domainInfo?: DomainInfo;
  message: string;
  onlyPeriodFilter: boolean;
} & FiltersEditorProps;

const checkToArraysHaveSameValues = (a, b) => isEmpty(xor(a, b));

/**
 * When in group view on the overview tab we need to cap the period filter at the latest completed group scrape date.
 * However, in the keyword list on the group view, it should not be capped.
 * @param domainInfo
 */
const useLatestCompletedScrapeDate = (domainInfo: DomainInfo | undefined) => {
  const domainsFilter = useSelector(SpecificFilterSelector('domains'));

  const onOverviewPage = window.location.href.includes('overview');

  const { data: clientsData } = useLatestCompletedScrapeDateQuery({
    skip: !!domainInfo || !onOverviewPage,
  });

  if (!onOverviewPage) {
    return undefined;
  }

  // Find the currently active group by searching for the group which matches active domain filters
  const currentlySelectedGroup = clientsData?.clients?.find((client) =>
    checkToArraysHaveSameValues(
      client?.domains?.map((domain) => domain?.id),
      domainsFilter?.value,
    ),
  );
  return currentlySelectedGroup?.latestCompletedScrapeDate;
};

const PeriodFilter = (props: Props) => {
  const { min, max, message, onlyPeriodFilter, lastFullScrape, domainInfo } = props;
  const periodFilter = useSelector(SpecificFilterSelector('period'));

  const getCurrentFilters = useGetFilters();

  const handleSubmit = (filters: FilterBase[]) => {
    const updatedFilterAttributes = filters.map((x) => x.attribute);
    const oldFilters = getCurrentFilters().filter((f) =>
      updatedFilterAttributes.includes(f.attribute),
    );
    props.filtersEditor.updateFilters(filters, oldFilters);
  };

  const groupLatestCompletedScrapeDate = useLatestCompletedScrapeDate(domainInfo);

  if (!periodFilter) {
    return null;
  }

  return (
    <FiltersEditorComponent skipPropsInject>
      <Flex align="center">
        <CompareToDropdown
          min={min || new Date(0)}
          max={groupLatestCompletedScrapeDate || max || new Date()}
          lastFullScrape={lastFullScrape}
          onSubmit={handleSubmit}
          periodFilter={periodFilter}
          onlyPeriodFilter={onlyPeriodFilter}
          domainInfo={domainInfo}
          message={message}
        />
      </Flex>
    </FiltersEditorComponent>
  );
};

export default withFiltersEditor(PeriodFilter) as any;
