import { Flex, FlexProps } from '@mantine/core';
import styles from './actionbar-container.module.scss';

type Props = {
  children: React.ReactNode;
} & FlexProps;

const ActionbarContainer = (props: Props) => {
  const { children, ...rest } = props;

  return (
    <Flex className={styles.container} {...rest} data-testid="actionsMenuContainer">
      {children}
    </Flex>
  );
};

// Sidebar container for "Configure columns", "View mode", etc.
interface ActionbarSidebarProps extends FlexProps {
  children: React.ReactNode;
}

const ActionbarSidebar: React.FC<ActionbarSidebarProps> = ({ children, ...rest }) => {
  return (
    <Flex className={styles.container} ml="auto" {...rest}>
      {children}
    </Flex>
  );
};

ActionbarContainer.defaultProps = {
  mt: 'lg',
  mb: 'lg',
  wrap: 'wrap',
};
ActionbarContainer.Sidebar = ActionbarSidebar;

export default ActionbarContainer;
