import uniqBy from 'lodash/uniqBy';
import { SelectItem, SelectItemValueType } from 'Components/AccSelect/support/types';
import { t } from 'Utilities/i18n';
import { InferSelectItemValueType } from '../AccSelect';

export const defaultSelectCreatePrompt = (label) =>
  label && label.trim().length > 0 ? `${t('Add: "%s"', label.trim())}` : '';

export const formatOptions = <Value extends SelectItemValueType = string>(
  stateOptions: SelectItem<Value>[],
  values?: Value[] | null,
  staticOptions?: SelectItem<Value>[],
  valueOptions?: SelectItem<Value>[],
  cachedValueOptions?: SelectItem<Value>[],
): SelectItem<Value>[] => {
  const resultValueOptions = uniqBy(
    [...(valueOptions || []), ...(cachedValueOptions || [])],
    'value',
  );
  const options = [...(stateOptions || []), ...(staticOptions || [])];
  const resultOptionsMap = new Map(options.map((e) => [e.value, true]));
  return uniqBy<SelectItem<Value>>(
    [
      ...options,
      ...(values
        ?.filter?.((e) => !resultOptionsMap.has(e))
        ?.map((e) => {
          const option = resultValueOptions?.find((o) => o?.value === e);
          return option ?? { value: e, label: e?.toString() };
        }) || []),
    ],
    'value',
  ).map((e) => ({
    ...e,
    label: e.label || e.value?.toString(),
  }));
};

export const getIsSearchPromptVisible = (props: {
  minQueryLength?: number;
  search?: string;
  searchPromptText?: string;
}): boolean => {
  return (
    !!props.minQueryLength &&
    (props.search?.length || 0) < props.minQueryLength &&
    !!props.searchPromptText
  );
};

type FilterData<Item extends SelectItem> = {
  data: Item[];
  limit: number;
  searchable: boolean;
  searchValue: string;
  filterDataOnExactSearchMatch: boolean;
  value: InferSelectItemValueType<Item> | null | undefined;
  filter: ((value: string, item: Item) => boolean) | undefined;
};

const defaultFilter = <Item extends SelectItem>(value: string, item: Item) => {
  return item.label?.toLowerCase().trim().includes(value.toLowerCase().trim()) || item.value?.toString()?.toLowerCase().trim().includes(value.toLowerCase().trim())  || false;
};

// eslint-disable-next-line import/no-unused-modules
export const filterData = <Item extends SelectItem = SelectItem<SelectItemValueType>>({
  data,
  searchable,
  limit,
  searchValue,
  filter = defaultFilter<Item>,
  value,
  filterDataOnExactSearchMatch,
}: FilterData<Item>) => {
  //make sure the array contains no empty values
  const cleanData = data.filter((item) => !!item.value);
  if (!searchable) {
    return cleanData;
  }

  const selected = value !== null ? cleanData.find((item) => item.value === value) || null : null;


  if (selected && !filterDataOnExactSearchMatch && (selected?.label === searchValue || selected?.value === searchValue)) {
    if (limit) {
      if (limit >= cleanData.length) {
        return cleanData;
      }
      const firstIndex = cleanData.indexOf(selected);
      const lastIndex = firstIndex + limit;
      const firstIndexOffset = lastIndex - cleanData.length;
      if (firstIndexOffset > 0) {
        return cleanData.slice(firstIndex - firstIndexOffset);
      }
      return cleanData.slice(firstIndex, lastIndex);
    }
    return cleanData;
  }

  const result: Item[] = [];

  for (let i = 0; i < cleanData.length; i += 1) {
    if (filter(searchValue, cleanData[i])) {
      result.push(cleanData[i]);
    }

    if (result.length >= limit) {
      break;
    }
  }


  return result;
};
