import { useMemo } from 'react';
import { useApolloClient } from '@apollo/client';
import get from 'lodash/get';
import moment from 'moment';
import toast from 'Hooks/useToast';
import { t } from 'Utilities/i18n';
import FormatNumber from '../../../../../Components/FormatNumber/new';
import { DEFAULT_CURRENCY } from '../../../../../Constants/currency';
import { QUERY_KEYWORD_OVERUSE_WALLET } from '../../../overuse.query';
import { OveruseTableItem } from '../../../types';
import { OveruseColumnID } from './constants';
import { OveruseRangeInput } from './types';

export const useFetchOveruse = (
  range: OveruseRangeInput,
  setRange: (a: OveruseRangeInput) => void,
  _: OveruseRangeInput[],
  setRangeList: (a: OveruseRangeInput[]) => void,
) => {
  const client = useApolloClient();

  return async () => {
    try {
      const res = await client.query({
        query: QUERY_KEYWORD_OVERUSE_WALLET,
        variables: range,
      });
      const keywordOveruseWallet = res?.data?.keywordOveruseWallet;
      const result = keywordOveruseWallet?.dates ?? [];
      const planPeriods = keywordOveruseWallet?.planPeriods;

      setRangeList(planPeriods);
      setRange({
        periodTo: (keywordOveruseWallet?.periodTo || planPeriods?.[0]?.periodTo) ?? '',
        periodFrom: (keywordOveruseWallet?.periodFrom || planPeriods?.[0]?.periodFrom) ?? '',
      });

      return { data: result, length: result.length };
    } catch (e) {
      toast.error(t('Failed to load data for overuse table.'));
      return { data: [], length: 0 };
    }
  };
};

const cellRender = (config: {
  path?: keyof OveruseTableItem;
  component?: (a: { record: OveruseTableItem; isTotal?: boolean }) => JSX.Element;
}) => {
  const result = (props: any) => {
    const isTotal = props?.record?.date === null;
    let content: JSX.Element | null = null;
    if (config?.path) {
      content = get(props.record, config?.path);
    } else if (config?.component) {
      const Component = config?.component;
      content = <Component record={props?.record} isTotal={isTotal} />;
    }
    return isTotal ? <b>{content}</b> : <>{content}</>;
  };
  result.displayName = 'cellRender';

  return result;
};

export const useOveruseTableConfig = () => {
  const columns = useMemo(
    () => [
      {
        id: OveruseColumnID.DATE,
        title: t('Date'),
        cellRenderer: cellRender({
          component: ({ record, isTotal }) => {
            const momentDate = moment(record?.date);
            return (
              <>
                {isTotal
                  ? t('Total')
                  : momentDate?.isValid()
                  ? momentDate?.format('MMM DD, YYYY')
                  : ''}
              </>
            );
          },
        }),
      },
      {
        id: OveruseColumnID.QUANTITY,
        title: t('Exceeded keyword quantity'),
        cellRenderer: cellRender({ path: 'overusedKeywords' }),
      },
      {
        id: OveruseColumnID.PRICE,
        title: t('Price'),
        cellRenderer: cellRender({
          component: ({ record }) => {
            return (
              <FormatNumber
                currency={record?.currency ?? DEFAULT_CURRENCY}
                value={record?.price ?? 0}
                maximumFractionDigits={2}
                style="currency"
              />
            );
          },
        }),
      },
    ],
    [],
  );

  return { columns };
};
