import { memo, useRef } from 'react';
import cn from 'classnames';
import isEqual from 'lodash/isEqual';
import { ChartLoader } from 'Components/Chart/ChartLoader/ChartLoader';
import { ReactHighchartsChart } from 'Components/Chart/ReactHighcarts';
import ExportButton from 'Components/ExportButton';
import Segment from 'Components/Segment';
import Watermark from 'Components/Watermark';
import { t } from 'Utilities/i18n';
import { PieChartConfigProps, usePieChartConfig } from './support/usePieChartConfig';
import './pie-chart.scss';

const PieChart = memo((props: PieChartConfigProps) => {
  const chartConfig = usePieChartConfig(props);
  const exportContainer = useRef<HTMLDivElement>(null);
  const noAvailableData = !props.loading && !(chartConfig.series?.[0] as any)?.data?.length;

  return (
    <div
      className={cn('chart-content', 'chart-container', {
        'chart-content-transparent': props.transparentBg,
      })}
      ref={exportContainer}
      style={{
        height: props.height,
      }}
    >
      {noAvailableData ? (
        <Segment placeholder>{t('No SERP feature data for the selected filters')}</Segment>
      ) : (
        <>
          <ExportButton content={() => exportContainer.current} />
          <Watermark big={props.watermarkBig || false} offset />
          <ChartLoader
            loaderType="pie"
            loading={props.loading}
            containerBoxProps={{ h: props.height }}
          >
            <ReactHighchartsChart config={chartConfig} />
          </ChartLoader>
        </>
      )}
    </div>
  );
}, isEqual);
PieChart.displayName = 'PieChart';

export default PieChart;
