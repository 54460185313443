import { Field } from 'redux-form';
import toFormField from 'Components/Forms/toFormField';
import Validator from 'Utilities/validation';
import DomainsInput from './DomainsInput';

const DomainsField = toFormField(DomainsInput as any);

const DomainsEditor = () => (
  <Field name="value" component={DomainsField} validate={Validator.array} />
);

export default DomainsEditor;
