import * as Actions from 'Pages/Layout/ActionsMenu/Actions';
import { useModal } from '../../../../../../Hooks/base/useModal';
import { useDomainId } from '../../../../../../Hooks/data/domain/useQueryDomainInfo';

export const CompetitorsRanksActions = () => {
  const domainId = useDomainId();
  const { showModal } = useModal();

  const handleReport = () => {
    showModal({
      modalType: 'CompetitorsRanksReport',
      modalTheme: 'light',
      modalProps: { domainId },
    });
  };
  return (
    <>
      <Actions.ReportAction m="0 0 0 auto" key="report" onClick={handleReport} />
      <Actions.UpgradeAction key="upgradePlan" alignRight={true} />
    </>
  );
};
