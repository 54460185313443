import React from 'react';
import { Placement } from '@floating-ui/dom';
import cn from 'classnames';
import { useTooltip } from 'Hooks/tooltip/useTooltip';
import Portal from '../Portal/portal';

/**
 * Tooltip based on popperjs, should be used only if we need:
 * - render html *(for example span)
 * - show tooltip for custom position instead
 * @note we should avoid using this tooltip, since it take a lot of resources
 */
export const AccComplexTooltip = (props: {
  children: any;
  disable?: boolean;
  tooltip?: string;
  transparent?: boolean;
  placement?: Placement;
}) => {
  const { childProps, tooltipProps, isMount, showTooltip, visible } = useTooltip({
    placement: props.placement ?? 'top',
    disable: props.disable,
  });
  return (
    <>
      {React.cloneElement(props.children, showTooltip ? childProps : {})}
      {isMount && showTooltip && props.tooltip && (
        <Portal>
          <div
            className={cn('acr-tooltip', {
              'acr-tooltip--visible': visible,
              'acr-tooltip--transparent': props.transparent,
            })}
            {...tooltipProps}
          >
            {props.tooltip}
          </div>
        </Portal>
      )}
    </>
  );
};
