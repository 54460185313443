import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { updateScrollTarget } from 'Actions/ScrollAction';
import linkWithFilters from 'Components/Filters/linkWithFilters';
import { store } from 'Store';
import type { DomainsFilter, FilterBase } from 'Types/Filter';
import { FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import type { FilterSet } from 'Types/FilterSet';
import { IMPORT_GSC_FILTER_SET, KEYWORDS_FILTER_SET, NOTES_FILTER_SET } from 'Types/FilterSet';

type Props = {
  domainId: string | string[];
  children: React.ReactNode;
  reset: boolean;
  className?: string;
  scrollTo?: string;
  navigateTo?: PageIdToLink;
};

export enum PageIdToLink {
  overview = '/keywords/overview',
  keywords = '/keywords/list',
  competitors = '/keywords/competitors',
  competitors_ranking = '/keywords/rankings',
  landing_pages = '/keywords/landing-pages',
  tags = '/keywords/tags',
  serp = '/keywords/serp',
  notes = '/notes',
  keywordDiscovery = '/keywords/keyword_discovery',
}

/** Navigates to the `Default keywords page` defined in the users profile.
 *
 *  If the user has not defined a default page, it will navigate to the page passed in the `page` prop.
 */
export function linkToPageWithDomains(
  page: string,
  domains: string | string[],
  filterSet?: FilterSet,
  newFilters?: FilterBase[],
  reset: boolean = false,
) {
  const domainsFilter: DomainsFilter = {
    attribute: FilterAttribute.DOMAINS,
    type: FilterValueType.LIST,
    comparison: FilterComparison.CONTAINS,
    value: Array.isArray(domains) ? domains.map((id) => id?.toString()) : [domains?.toString()],
  };

  if (reset && domainsFilter.value.length === 1) {
    // Update page to page from settings
    const defaultKeywordsPage = store.getState().user.defaultKeywordsPage;
    const newPage = defaultKeywordsPage ? PageIdToLink[defaultKeywordsPage] : undefined;

    if (newPage === PageIdToLink.notes) {
      filterSet = NOTES_FILTER_SET;
    }

    page = newPage || page;
  }

  const filters = [domainsFilter, ...(newFilters || [])];
  return linkWithFilters({
    to: page,
    overwriteFilters: filters,
    filterSet,
    resetFilters: reset,
  });
}
export function linkToImportGSCWithDomains(domains: string | string[]) {
  return linkToPageWithDomains('/keywords/import/gsc', domains, IMPORT_GSC_FILTER_SET, [
    {
      attribute: FilterAttribute.GSC_EXISTS,
      type: FilterValueType.BOOL,
      comparison: FilterComparison.EQ,
      value: false,
    },
  ]);
}
export function linkToKeywordsDomain(domainId: string | string[], resetFilters?: boolean) {
  return linkToPageWithDomains('/keywords/list', domainId, KEYWORDS_FILTER_SET, [], resetFilters);
}
export function linkToKeywordDiscoveryDomain(domainId: string | string[], resetFilters?: boolean) {
  return linkToPageWithDomains(
    PageIdToLink.keywordDiscovery,
    domainId,
    KEYWORDS_FILTER_SET,
    [],
    resetFilters,
  );
}
export function linkToGroupings(domainId: string | string[], resetFilters?: boolean) {
  return linkToPageWithDomains(PageIdToLink.tags, domainId, KEYWORDS_FILTER_SET, [], resetFilters);
}

const LinkToDomain: React.FC<Props> = ({
  domainId,
  className,
  children,
  reset = false,
  navigateTo,
  scrollTo,
}) => {
  const dispatch = useDispatch();
  const handleLink = () => {
    if (scrollTo) {
      dispatch(updateScrollTarget(scrollTo));
    }
  };

  return (
    <Link
      to={linkToPageWithDomains(
        navigateTo || PageIdToLink.overview,
        domainId,
        KEYWORDS_FILTER_SET,
        [],
        reset,
      )}
      tabIndex={0}
      onClick={handleLink}
      className={className}
    >
      {children}
    </Link>
  );
};

export default LinkToDomain;
