import { oneOfOptions } from 'Components/Filters/Common/labelFunc';
import { FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import type { SearchEngineFilter } from 'Types/Filter';
import { t } from 'Utilities/i18n/index';
import SearchEngineIcon from 'icons/window-search.svg?inline';
import SearchEngineEditor from '.';
import { getItems } from './hooks';

const defaultValue: SearchEngineFilter = {
  attribute: FilterAttribute.SEARCH_ENGINE,
  type: FilterValueType.NUMBER,
  comparison: FilterComparison.EQ,
  value: 1,
};

const getData = () => {
  const items = getItems();
  return {
    defaultValue,
    title: t('Search engine'),
    icon: SearchEngineIcon,
    editor: SearchEngineEditor,
    editorProps: {
      iconDropdown: true,
    },
    tableEditor: SearchEngineEditor,
    tableEditorProps: {
      iconDropdown: true,
      noFilterIcon: SearchEngineIcon,
      showLabel: false,
    },
    labelFunc: oneOfOptions(items),
  };
};

export default getData;
