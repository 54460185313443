import { toJS } from 'mobx';
import Sheetclip from 'sheetclip';
import { TableStoreType } from 'Components/DataTable/store/TableStore';
import toast from 'Hooks/useToast';
import { TableID } from 'Types/Table';
import { t } from 'Utilities/i18n';
import { copyToClipboard } from 'Utilities/underdash';
import { useTableStore } from '../../hooks/store/useTableStore';
import { ColumnType } from '../../types';

const sheetclip = new Sheetclip();

const getCopyData = (tableStore: TableStoreType | null, columns: ColumnType[]) => {
  if (!tableStore) {
    return [];
  }
  const dataCopyColumns = columns.filter((e) => e?.dataToCopy);
  const data = toJS(tableStore.data);

  return [dataCopyColumns.map((e) => e.title)].concat(
    data.reduce(
      (acc, item) => [...acc, dataCopyColumns.map((e) => String(e.dataToCopy?.(item) ?? '-'))],
      [],
    ),
  );
};

export const useDataCopyAction = (tableId: TableID, columns: ColumnType[]) => {
  const tableStore = useTableStore(tableId);
  return () => {
    if (!tableStore) {
      return;
    }

    copyToClipboard(sheetclip.stringify(getCopyData(tableStore, columns)));

    if (tableStore?.totalDataLength > tableStore?.data?.length) {
      toast.success(
        t(
          'Copied %s rows (maximum). Use download report for the full history.',
          tableStore?.data?.length,
        ),
      );
    } else {
      toast.success(t('Keywords copied to clipboard'));
    }
  };
};
