import { useShareOfVoiceLandingPagesQuery } from 'Ghql';
import { useFilters } from 'Hooks';
import { useSetOrOverwriteFilter } from 'Hooks/data/filter/setFilters';
import { LinkToSitemap } from 'Pages/Keywords/LandingPage/components/KeywordLandingTable/support/hooks';
import { BarChart, LabelActionButton } from 'Pages/Keywords/Overview/components/BarChart/BarChart';
import styles from 'Pages/Keywords/Overview/components/ShareOfVoiceByType/styles.module.scss';
import { getSoVLandingPagesColors } from 'Pages/Keywords/Overview/components/ShareOfVoiceByType/support/helpers';
import { FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import { FilterTrackingKey } from 'Utilities/Analytics/mixpanel';
import { t } from 'Utilities/i18n';

export const ShareOfVoiceLandingPages = ({ useAi }) => {
  const filters = useFilters();
  const { data, loading } = useShareOfVoiceLandingPagesQuery({
    variables: {
      filters,
      asPercentage: false,
      name: `SoVLandingPages${useAi ? 'AI' : ''}`,
      useAi,
    },
  });
  const setFilter = useSetOrOverwriteFilter();
  return (
    <BarChart
      normalNumberOfItems={11}
      enableLabelTooltips={true}
      loading={loading}
      items={
        data?.charts?.shareOfVoiceLandingPages?.rows?.map((e, i) => {
          const { name, color } = getSoVLandingPagesColors(e, i);

          return {
            value: e?.value ?? 0,
            label: name || '',
            labelActionButtons: [
              // <LabelActionButton
              //   component={
              //     <AccTooltip tooltip={t('Click to see')}>
              //       <ActionIcon
              //         variant={'light'}
              //         size={'sm'}
              //         component={'a'}
              //         href="https://mantine.dev/core/"
              //         target={'_blank'}
              //       >
              //         <IconExternalLink size={16} />
              //       </ActionIcon>
              //     </AccTooltip>
              //   }
              // />,
              e?.key ? (
                <LabelActionButton
                  key={`linkToSitemap${name}`}
                  component={<LinkToSitemap path={name} />}
                />
              ) : (
                <></>
              ),
            ],
            background: color,
            icon: <div className={styles.box} style={{ background: color }} />,
            id: name,
            onClick:
              name && name !== '/' // Disable click on root as this is not yet supported by backend, see https://accuranker.myjetbrains.com/youtrack/agiles/124-2/current?issue=ARR-2673
                ? () => {
                    setFilter(
                      {
                        attribute: FilterAttribute.HIGHEST_RANKING_PAGE,
                        type: FilterValueType.STRING,
                        comparison: FilterComparison.ENDS_WITH,
                        value: name,
                      },
                      FilterTrackingKey.ShareOfVoiceChart,
                    );
                  }
                : undefined,
            tooltip:
              (e?.value &&
                name !== '/' &&
                t('Click to see your keywords with highest ranking page: ') + name) ||
              '', // Note: tct for some reason does not work here
          };
        }) ?? []
      }
    />
  );
};
