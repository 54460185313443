import { ComponentType, StrictMode } from 'react';

export function withStrictMode<T extends object = object>(
  Component: ComponentType<T>,
): ComponentType<T> {
  const ResultComponent = (props: T) => (
    <StrictMode>{Component ? <Component {...props} /> : null}</StrictMode>
  );
  const displayName = Component.displayName || Component.name || 'Component';
  ResultComponent.displayName = `withStrictMode(${displayName})`;
  return ResultComponent;
}
