import { useState } from 'react';
import { Box, Flex, Grid, Space, useMantineTheme } from '@mantine/core';
import { IconLocationQuestion, IconPigMoney, IconPointerQuestion } from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import Breadcrumbs from 'Components/Breadcrumbs/Breadcrumbs';
import DatePicker from 'Components/Controls/DatePicker';
import AccText from 'Components/Text/AccText';
import AccTitle from 'Components/Title/AccTitle';
import { useAccuApiCreditsQuery, useAccuApiTokenQuery } from 'Ghql';
import { useClipboard } from 'Hooks';
import { t, tct } from 'Utilities/i18n';
import FormatNumber from '../../Components/FormatNumber';
import SearchHistoryTable from './SearchHistoryTable';
import { SpendingChart } from './SpendingChart';
import styles from './apiPage.module.scss';

const AccuApiPage = () => {
  const currentDate = new Date();
  const fourteenDaysAgo = new Date(currentDate.getTime() - 14 * 24 * 60 * 60 * 1000);
  const [startDate, setStartDate] = useState(fourteenDaysAgo);

  const [endDate, setEndDate] = useState(currentDate);

  const clipboard = useClipboard({ timeout: 1000 });
  const theme = useMantineTheme();

  const { data } = useAccuApiCreditsQuery();
  const { data: tokenData } = useAccuApiTokenQuery();

  const ApiToken: string = tokenData?.accuApiToken?.token || t('No token found');

  const iconColor = theme.colors.gray[7];

  return (
    <>
      <Breadcrumbs />
      <Flex gap="md" direction="column">
        <div className={styles.boxWrapper}>
          <Grid gutter={50} className={styles.boxGrid}>
            <Grid.Col span={{ xl: 6, md: 7, xs: 12 }}>
              <AccTitle type="h4">{t('Limit status overview')}</AccTitle>
              <Space h="md" />
              <Flex gap="md" direction="column" px={8}>
                <Flex gap="xxs" align="center">
                  <IconPigMoney color={iconColor} height={18} />
                  <div className={styles.credit}>
                    <AccText size={'14px'} inline fw={600} c={iconColor}>{`${t(
                      'Credits remaining',
                    )}: `}</AccText>
                    <AccText size={'16px'} inline fw={600}>
                      <FormatNumber>{data?.accuApiCredits?.creditsStats?.credits}</FormatNumber>
                    </AccText>
                    <AccText size={'14px'} inline fw={600} c={iconColor}>
                      {t('out of ')}
                    </AccText>
                    <AccText size={'16px'} inline fw={600}>
                      <FormatNumber>{data?.accuApiCredits?.creditsStats?.planCredits}</FormatNumber>
                    </AccText>
                    <AccText size={'14px'} inline fw={600} c={iconColor}>
                      {tct('per month. ([number] credits = 1 Google Search)', { number: '10' })}
                    </AccText>
                  </div>
                </Flex>
                <Flex gap="xxs" align="center">
                  <IconLocationQuestion color={iconColor} height={18} />
                  <AccTitle c={iconColor} type="h5">{`${t('GET requests remaining')}: `}</AccTitle>
                  <AccTitle type="h4">
                    {data?.accuApiCredits?.creditsStats?.getLimitRemaning?.toLocaleString()}
                  </AccTitle>
                  <AccTitle c={iconColor} type="h5">
                    {t('out of ')}
                  </AccTitle>
                  <AccTitle type="h4">
                    {data?.accuApiCredits?.creditsStats?.getLimit?.toLocaleString()}
                  </AccTitle>
                  <AccTitle type="h5" c={iconColor}>
                    {t(' per hour')}
                  </AccTitle>
                </Flex>
                <Flex gap="xxs" align="center">
                  <IconPointerQuestion color={iconColor} height={18} />
                  <AccTitle c={iconColor} type="h5">{`${t('POST requests remaining')}: `}</AccTitle>
                  <AccTitle type="h4">
                    {data?.accuApiCredits?.creditsStats?.postLimitRemaning?.toLocaleString()}
                  </AccTitle>
                  <AccTitle c={iconColor} type="h5">
                    {t('out of ')}
                  </AccTitle>
                  <AccTitle type="h4">
                    {data?.accuApiCredits?.creditsStats?.postLimit?.toLocaleString()}
                  </AccTitle>
                  <AccTitle type="h5" c={iconColor}>
                    {t(' per hour')}
                  </AccTitle>
                </Flex>
              </Flex>
            </Grid.Col>
            <Grid.Col span={{ xl: 6, md: 5, xs: 12 }}>
              <AccTitle type="h4">{t('API Token')}</AccTitle>
              <Space h="md" />
              <Flex gap="md" direction="column">
                <AccButton
                  variant="tertiary"
                  tooltip={clipboard.copied ? 'Copied!' : 'Copy to clipboard'}
                  onClick={() => ApiToken && clipboard.copy(ApiToken)}
                >
                  <AccTitle type="h5">{ApiToken}</AccTitle>
                </AccButton>
                <Box mx={8}>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.accuapi.com/doc/">
                    {t('Click here to read the documentation')}
                  </a>
                </Box>
              </Flex>
            </Grid.Col>
          </Grid>
        </div>
        <div className={styles.boxWrapper}>
          <Flex gap="md" direction="column">
            <AccTitle type="h4">{t('Requests per hour')}</AccTitle>
            <Flex gap="md" ml="auto">
              <Flex gap="sm" ml="auto">
                <AccTitle c={iconColor} type="h5">
                  {t('From:')}
                </AccTitle>
                <DatePicker value={startDate} onChange={setStartDate} />
              </Flex>
              <Flex gap="sm" ml="auto">
                <AccTitle c={iconColor} type="h5">
                  {t('To:')}
                </AccTitle>
                <DatePicker value={endDate} onChange={setEndDate} />
              </Flex>
            </Flex>
            <SpendingChart startDate={startDate} endDate={endDate} />
          </Flex>
        </div>
        <div className={styles.boxWrapper}>
          <Flex gap="md" direction="column">
            <AccTitle type="h4">{t('Search history')}</AccTitle>
            <SearchHistoryTable />
          </Flex>
        </div>
      </Flex>
    </>
  );
};

export default AccuApiPage;
