import { useUser } from 'Hooks/data/user/useUser';
import { ImpersonateSelect } from './ImpresonateSelect/ImpersonateSelect';
import StopImpersonating from './StopImpersonating';
import './impersonate-dropdown.scss';

type Props = {
  navbarCollapsed?: boolean;
};
const ImpersonateDropdown = (props: Props) => {
  const { navbarCollapsed } = props;

  const user = useUser();

  if (user?.isImpersonating) {
    return <StopImpersonating collapsed={navbarCollapsed} />;
  }

  if (!user?.salesManager || navbarCollapsed) {
    return null;
  }

  return <ImpersonateSelect />;
};

export default ImpersonateDropdown;
