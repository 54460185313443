import { Divider, Flex } from '@mantine/core';
import { No, Yes } from 'Components/AccBadge';
import Alert from 'Components/Alert';
import Breadcrumbs from 'Components/Breadcrumbs/Breadcrumbs';
import AccTitle from 'Components/Title/AccTitle';
import { useImportExamplePageMappableColumnsQuery } from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import { keywordType, rankType } from 'Pages/Import/importer_helpers';
import { t, tct } from 'Utilities/i18n';
import styles from './import-examples.module.scss';

const ImportExamples = () => {
  const { showModal } = useModal();

  const { data, loading } = useImportExamplePageMappableColumnsQuery();
  const mappableColumns = data?.mappableColumns;

  const handleClickCountriesList = (evt) => {
    evt.preventDefault();
    showModal({
      modalType: 'SupportedCountriesList',
      modalTheme: 'light',
      nested: true,
    });
  };

  if (loading) return null;

  return (
    <>
      <Breadcrumbs />
      <div className={styles.importExamplePage}>
        <div>
          <AccTitle type="h3" mb="xs">
            {t('Example files')}
          </AccTitle>

          <Alert>
            <ul>
              <li>
                {t('Keywords')}: <a href={'/import/example/?file_ext=csv'}>csv</a>{' '}
                <a href={'/import/example/?file_ext=xls'}>xls</a>{' '}
                <a href={'/import/example/?file_ext=xlt'}>xlt</a>{' '}
                <a href={'/import/example/?file_ext=xla'}>xla</a>{' '}
                <a href={'/import/example/?file_ext=xlsx'}>xlsx</a>
              </li>
              <li>
                {t('Keywords incl. rank history')}:{' '}
                <a href={'/import/example/?file_ext=csv&history=true'}>csv</a>{' '}
                <a href={'/import/example/?file_ext=xls&history=true'}>xls</a>{' '}
                <a href={'/import/example/?file_ext=xlt&history=true'}>xlt</a>{' '}
                <a href={'/import/example/?file_ext=xla&history=true'}>xla</a>{' '}
                <a href={'/import/example/?file_ext=xlsx&history=true'}>xlsx</a>
              </li>
            </ul>
          </Alert>
        </div>

        <Flex gap="lg" className={styles.tableWrapper}>
          <div>
            <AccTitle type="h3" mb="xs" mt="lg">
              {t('Keywords')}
            </AccTitle>

            <table className={'table'}>
              <thead>
                <tr>
                  <th>{t('Column name')}</th>
                  <th>{t('Required')}</th>
                  <th>{t('Description')}</th>
                  <th>{t('Example')}</th>
                </tr>
              </thead>
              <tbody>
                {mappableColumns
                  ?.slice()
                  .filter((e) => e?.type === keywordType)
                  .map((mappableColumn) => {
                    return (
                      <tr key={`td-row-${mappableColumn?.id}`}>
                        <td>{mappableColumn?.name}</td>
                        <td>{mappableColumn?.required ? <Yes /> : <No />}</td>
                        <td>
                          {mappableColumn?.id === 5 ? (
                            <i>
                              {tct('View available countries [link].', {
                                link: (
                                  <a onClick={handleClickCountriesList} href="#">
                                    {t('here')}
                                  </a>
                                ),
                              })}
                            </i>
                          ) : (
                            <i>{mappableColumn?.description}</i>
                          )}
                        </td>
                        <td>{mappableColumn?.examples[0]}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>

          <Divider orientation="vertical" color="gray.1" />

          <div>
            <AccTitle type="h3" mb="xs" mt="lg">
              {t('Keywords incl. rank history')}
            </AccTitle>

            <Alert>
              <p>{t('Fields from Keywords in addition to the ones below.')}</p>
            </Alert>

            <table className={'table'}>
              <thead>
                <tr>
                  <th>{t('Column name')}</th>
                  <th>{t('Required')}</th>
                  <th>{t('Description')}</th>
                  <th>{t('Example')}</th>
                </tr>
              </thead>
              <tbody>
                {mappableColumns
                  ?.slice()
                  .filter((e) => e?.type === rankType)
                  .map((mappableColumn) => {
                    return (
                      <tr key={`td-row-${mappableColumn?.id}`}>
                        <td>{mappableColumn?.name}</td>
                        <td>{mappableColumn?.required ? <Yes /> : <No />}</td>
                        <td>
                          <i>{mappableColumn?.description}</i>
                        </td>
                        <td>{mappableColumn?.examples[0]}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </Flex>
      </div>
    </>
  );
};

export default ImportExamples;
