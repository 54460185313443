import { Flex } from '@mantine/core';
import FormatNumber from 'Components/FormatNumber/new';
import AccText from 'Components/Text/AccText';
import { DEFAULT_CURRENCY } from 'Constants/currency';
import { useModal } from 'Hooks/base/useModal';
import * as Actions from 'Pages/Layout/ActionsMenu/Actions';
import { t, tn } from 'Utilities/i18n';
import { getIsShowPaymentInfo, getIsYearlyPeriod } from '../../../support/helpers';
import { OveruseActivePlan, OveruseNextPlan } from '../../../types';

interface OveruseInfoDetailsProps {
  isActive?: boolean;
  activePlan?: OveruseActivePlan | null;
  nextPlan?: OveruseNextPlan | null;
}

const AddAction: React.ComponentType<React.PropsWithChildren<any>> = Actions.AddAction;

export const OveruseInfoDetails = ({ activePlan, nextPlan, isActive }: OveruseInfoDetailsProps) => {
  const { showModal } = useModal();
  const isYearly = getIsYearlyPeriod(activePlan);
  const paymentInfoVisible = getIsShowPaymentInfo(activePlan);
  const showCancelPlanModal = () =>
    showModal({
      modalType: 'CancelPlan',
      modalTheme: 'light',
    });

  return (
    <Flex>
      <div>
        {nextPlan && isActive && (
          <p className="alert alert-warning next-plan">
            {t(
              'The plan will expire on %s. You will be switched to the "%s" on %s.',
              activePlan?.endDate,
              nextPlan.name,
              nextPlan.startDate,
            )}
          </p>
        )}
        <p className="overuse-info-panel__description">
          <b>{t('Plan')}</b>
          {activePlan?.isFree ? (
            <span className="info-value-cell">
              {t('%s with %s keywords', activePlan?.name, activePlan?.maxKeywords)}
            </span>
          ) : (
            <span className="info-value-cell">
              {t('%s with %s keywords', activePlan?.category, activePlan?.maxKeywords)}
            </span>
          )}
        </p>
        {paymentInfoVisible && (
          <>
            <p className="overuse-info-panel__description">
              {!isYearly ? (
                <>
                  <b>{t('Monthly price')}</b>{' '}
                  <span>
                    <FormatNumber
                      value={activePlan?.priceMonthly ?? 0}
                      style="currency"
                      currency={activePlan?.currency ?? DEFAULT_CURRENCY}
                    />
                  </span>
                </>
              ) : (
                <>
                  <b>{t('Annual price')}</b>{' '}
                  <span>
                    <FormatNumber
                      value={activePlan?.priceYearly ?? 0}
                      style="currency"
                      currency={activePlan?.currency ?? DEFAULT_CURRENCY}
                    />
                  </span>
                </>
              )}
            </p>
            <p className="overuse-info-panel__description">
              <b>{t('Billing cycle')}</b>{' '}
              <span>
                {tn('Paid monthly', 'Paid every %s months', activePlan?.billingCycleInMonths ?? 1)}
              </span>
            </p>
            {isActive && (
              <p className="overuse-info-panel__description">
                <b>{t('Next payment')}</b> <span>{activePlan?.endDate}</span>
              </p>
            )}
            {isActive && (
              <AccText variant="link" mt="sm" fw={400} onClick={showCancelPlanModal}>
                {t('Cancel my account')}
              </AccText>
            )}
          </>
        )}
      </div>
      <Flex ml="auto" pl="md">
        {isActive ? (
          <></>
        ) : (
          <AddAction
            link="/billing/package/select"
            label={paymentInfoVisible ? t('Reactivate') : t('Change plan')}
          />
        )}
      </Flex>
    </Flex>
  );
};
