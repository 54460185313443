import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import FormatNumber from 'Components/FormatNumber/new';
import { SEARCH_TYPE_NAVER, SearchEngineIds } from 'Components/Modal/Content/AddSearchEngine/types';
import { t } from 'Utilities/i18n';
import Cell from './HelperComponents/AccuCell';
import NaCell from './helpers/NaCell';

type Props = {
  value: {
    data: {
      avgCostPerClick: number;
      searchVolumeValue: number;
      validForSearchVolume: boolean;
      avgCostPerClickCurrency: string;
      searchEngineId: number;
    };
    loadingData: boolean;
  };
};

const CPCCell = (props: Props) => {
  const {
    value: { data },
  } = props;
  const { avgCostPerClick, validForSearchVolume, cpc, avgCostPerClickCurrency }: any = data;
  const value = avgCostPerClick ?? cpc;

  if (isNil(value) || (!validForSearchVolume && !isNumber(cpc))) {
    return null;
  }

  if (value === 0 && data?.searchEngineId === SearchEngineIds[SEARCH_TYPE_NAVER]) {
    return <NaCell tooltip={t('No CPC for this search engine')} />;
  }

  return (
    <Cell className={'accu-table-cell__cpc text-right'}>
      <FormatNumber value={value} minimumFractionDigits={2} maximumFractionDigits={2} renderProps>
        {(_value) => (
          <AccTooltip tooltip={`${_value} ${avgCostPerClickCurrency}`}>
            <div>
              {
                Number(value).toFixed(
                  2,
                ) /* toFixed because data from backend might not more than 1 decimal */
              }
            </div>
          </AccTooltip>
        )}
      </FormatNumber>
    </Cell>
  );
};

export default CPCCell;
