import React from 'react';
import './tooltip.scss';

interface AccTooltipSimpleProps {
  children: any;
  tooltip?: string;
  disable?: boolean;
  /** Tooltip delay in ms. */
  delay?: number;
}

/**
 * Simple tooltip created using react-tooltip library
 * @doc https://github.com/ReactTooltip/react-tooltip
 * @note this tooltip can't be used if you need to render html instead of a string
 **/
export const AccTooltipSimple = ({
  children,
  tooltip,
  disable,
  delay = 300,
}: AccTooltipSimpleProps) => {
  return React.cloneElement(children, {
    'data-tooltip-html': !disable ? tooltip : undefined,
    'data-tooltip-position-strategy': 'fixed',
    'data-tooltip-delay-show': delay,
    'data-tooltip-delay-hide': 0,
    'data-tooltip-variant': 'dark',
  });
};
