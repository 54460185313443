import NoScrapeOnCompareDateCell from 'Components/AccuTable/CellRenderer/helpers/NoScrapeOnCompareDateCell';
import ValueIndicator from 'Components/ValueIndicator/value-indicator';
import Cell from './HelperComponents/AccuCell';

type Props = {
  value: {
    data: {
      pixelsFromTop: number | null;
      pixelsFromTopCompare: number | null;
      updatingKeyword: boolean;
      noScrapeOnCompareDate?: boolean;
    };
    loadingData: boolean;
  };
};

const PixelsFromTopChangeCell = (props: Props) => {
  const {
    value: { data },
  } = props;
  const { pixelsFromTop, pixelsFromTopCompare, noScrapeOnCompareDate } = data;

  if (noScrapeOnCompareDate) {
    return <NoScrapeOnCompareDateCell />;
  }

  return (
    <Cell className="u-flex u-items-center" rightAligned={true}>
      <ValueIndicator negate={true} beforeValue={pixelsFromTopCompare} afterValue={pixelsFromTop} />
    </Cell>
  );
};

export default PixelsFromTopChangeCell;
