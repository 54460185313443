import { useMemo } from 'react';
import { Box } from '@mantine/core';
import { DataTable } from 'Components/DataTable';
import { SearchVolumeDetailsRow } from 'Components/DataTable/components/body/BodyRow/SearchVolumeDetailsRow';
import { useFilteredTableColumns } from 'Components/Modal/Content/TableSettingsModal/useFilteredColumns';
import { useDomainId, useDomainIds, useFilters } from 'Hooks';
import { useModal } from 'Hooks/base/useModal';
import { useQueryIsDemoClient } from 'Hooks/data/client/useQueryIsDemoClient';
import { useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import { useDisplayCurrency } from 'Hooks/displayCurrency';
import useUserPermission from 'Hooks/useUserPermission';
import { getIsLatestFilter } from 'Pages/Keywords/Table/support/helpers';
import { AddAction } from 'Pages/Layout/ActionsMenu/Actions';
import { TableIDs } from 'Types/Table';
import { WithRouter, withRouter } from 'Utilities/Router';
import { t } from 'Utilities/i18n';
import { useFetchKeywords } from './hooks/keyword/useTableKeyword';
import { useKeywordsTableDefaultOrdering } from './support/hooks';
import { useTableSubscription } from './support/useTableSubscription';
import { useKeywordTableInfo } from './useKeywordTableInfo';

const KeywordsTable = (props: WithRouter) => {
  const tableName = TableIDs.KEYWORDS;
  const domainId = useDomainId();
  const domainListId = useDomainIds();
  const filters = useFilters();

  const { showModal } = useModal();
  const { displayCurrency } = useDisplayCurrency(true);
  const isLatestFilter = useMemo(() => getIsLatestFilter(filters), [filters]);

  // Check if domain or client (group) is demo
  const { isDemoDomain, domainInfo } = useQueryDomainInfo();
  const { isDemoClient } = useQueryIsDemoClient(domainListId, isDemoDomain);

  const { columns, ...tableConfig } = useKeywordTableInfo({
    tableName,
    history: props.history,
    domainId: domainId || undefined,
    isLatestFilter,
    displayCurrency,
    isDemoDomain: isDemoClient || isDemoDomain,
    domainType: domainInfo?.domainType ?? undefined,
  });
  const { userHasWriteAccess } = useUserPermission();
  const resultColumns = useFilteredTableColumns(columns, TableIDs.KEYWORDS);
  const fetchKeywords = useFetchKeywords(tableName, displayCurrency);
  useTableSubscription(tableName, {
    subscribeTo: 'domain',
    subscribeToArg: domainId || domainListId || [],
  });
  const { ordering, skipSaveOrdering } = useKeywordsTableDefaultOrdering(
    props.history?.location,
    resultColumns,
  );
  const emptyOptions = {
    title: t('No keywords found on your domain'),
    subTitle:
      userHasWriteAccess && domainId ? (
        <Box p={'md'}>
          <AddAction
            key="add"
            label={t('Add keywords')}
            onClick={() => {
              showModal({
                modalType: 'AddKeywords',
                modalTheme: 'light',
                modalProps: {
                  domainId,
                },
              });
            }}
          />
        </Box>
      ) : (
        ''
      ),
    noDataWithFiltersTitle: t('No keywords found'),
    noDataWithFiltersSubTitle: t(
      'We found no keywords to show, this could be due to your active filters. Clear your filters and try again.',
    ),
  };
  return (
    <DataTable
      tableId={TableIDs.KEYWORDS}
      dataKey={displayCurrency || ''}
      fetchData={fetchKeywords}
      columns={resultColumns}
      {...tableConfig}
      customExpandComponent={SearchVolumeDetailsRow}
      optimizationConfig={false}
      defaultOrdering={ordering}
      skipSaveOrdering={skipSaveOrdering}
      emptyOptions={emptyOptions}
      pageSize={100}
      unmountStore
      showCountInfo
    />
  );
};

export default withRouter(KeywordsTable);
