import isNumber from 'lodash/isNumber';
import ValueBar, { ValueBarColor } from 'Components/Table/TableRow/ValueBar';
import Cell from './HelperComponents/AccuCell';

type Props = {
  value: {
    data: {
      searchVolumeValue: number;
      validForSearchVolume: boolean;
      updatingKeyword: boolean;
    };
    loadingData: boolean;
  };
  valueBarValue: number;
  colorMapper: (number) => ValueBarColor;
};

const ValueBarCell = (props: Props) => {
  const {
    value: { data },
  } = props;
  let { valueBarValue } = props;

  const { validForSearchVolume } = data;

  if (valueBarValue > 1) {
    // Happens in our test data for e.g. competition.
    valueBarValue = 1;
  }

  if ((!validForSearchVolume && !isNumber(valueBarValue)) || valueBarValue === null) {
    return null;
  }

  return (
    <Cell className={'accu-table-cell__competition'}>
      <ValueBar value={valueBarValue} color={props.colorMapper(valueBarValue)} />
    </Cell>
  );
};

export default ValueBarCell;
