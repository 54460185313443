import { Component } from 'react';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import compose from 'lodash/flowRight';
import { Field, reduxForm } from 'redux-form';
import AccButton from 'Components/AccButton/AccButton';
import { Select } from 'Components/Forms/Fields';
import type { IntegrationOAuthProvider } from 'Types/Integration';
import { t, tct } from 'Utilities/i18n/index';
import underdash from 'Utilities/underdash';
import Validator from 'Utilities/validation';

type Props = {
  handleSubmit: (...args: Array<any>) => any;
  goaAccounts: Record<string, any>;
  onAdd: (...args: Array<any>) => any;
  onCancel: (...args: Array<any>) => any;
  onSubmit: (...args: Array<any>) => any;
  integration: IntegrationOAuthProvider;
};
type State = {};

class SelectOAuthAccount extends Component<Props, State> {
  onSubmit = ({ goaAccount: { value } }) => {
    this.props.onSubmit({
      connectionId: value,
    });
  };

  render() {
    const loading =
      underdash.graphqlError({ ...this.props }) || underdash.graphqlLoading({ ...this.props });
    const {
      goaAccounts: { user },
      handleSubmit,
      integration,
    } = this.props;
    const { name: accountName, type } = integration;
    const options = loading
      ? []
      : user.organization.googleConnections
          .filter((item) => item.type === type)
          .map(({ id, description }) => ({
            value: id,
            label: description,
          }));

    if (options.length > 0) {
      return (
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <div className="form-label">
            {t('Select the %s connection you want to use for keywords import.', accountName)}
          </div>
          <div>
            <Field
              defaultBehaviour
              name="goaAccount"
              id="goaAccount"
              elementType="customSelect"
              type="text"
              placeholder={''}
              component={Select}
              loading={loading}
              options={options}
              validate={Validator.required}
            />
          </div>
          <div className="form-label">
            {tct(
              'Don\'t see the [accountName] account you are looking for? You can always add an additional account [link:here].',
              {
                accountName,
                link: <a onClick={this.props.onAdd} className="link" />,
              },
            )}
          </div>

          <div className="form-actions">
            <AccButton variant="tertiary" onClick={this.props.onCancel}>
              {t('Cancel')}
            </AccButton>
            <AccButton ml={8} variant="primary" type="submit">
              {t('Select account')}
            </AccButton>
          </div>
        </form>
      );
    }

    return (
      <div>
        <p>
          {t('We could not find a connected %s Account, please set one up first.', accountName)}
        </p>
        <div className="form-actions">
          <AccButton variant="tertiary" onClick={this.props.onCancel}>
            {t('Cancel')}
          </AccButton>
          <AccButton ml={8} variant="primary" onClick={this.props.onAdd}>
            {t('Connect an %s account', accountName)}
          </AccButton>
        </div>
      </div>
    );
  }
}

const goaAccountsQuery = gql`
  query selectOAuthAccount_goaAccounts {
    user {
      id
      organization {
        id
        googleConnections {
          id
          type
          description
        }
      }
    }
  }
`;
export default compose(
  graphql(goaAccountsQuery, {
    props: ({ ownProps, data, data: { error, loading, user } }: any) => {
      if (error || loading) {
        return {
          goaAccounts: data,
        };
      }

      const currentAccount = user.organization.googleConnections.find(
        (connection) => ownProps.accountId === connection.id,
      );
      let goaAccount;

      if (
        user.organization.googleConnections.find(
          (connection) => ownProps.accountId === connection.id,
        )
      ) {
        goaAccount = {
          label: currentAccount.description,
          value: currentAccount.id,
        };
      }

      return {
        goaAccounts: data,
        initialValues: {
          goaAccount,
        },
      };
    },
  }),
  reduxForm({
    form: 'SelectOAuthAccountForm',
  }),
)(SelectOAuthAccount) as any;
