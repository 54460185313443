import { FolderMetric } from 'Ghql';
import { t } from 'Utilities/i18n';

export const getMetricControlOptions = () => {
  return [
    {
      value: FolderMetric.EstimatedVisits,
      label: t('AI Share of Voice'),
    },
    {
      value: FolderMetric.ShareOfVoice,
      label: t('Share of Voice'),
    },
    {
      value: FolderMetric.AvgRank,
      label: t('Avg. rank'),
    },
    {
      value: FolderMetric.AvgBaseRank,
      label: t('Avg. base rank'),
    },
    {
      value: FolderMetric.AvgCtr,
      label: t('CTR'),
    },
    {
      value: FolderMetric.SearchVolume,
      label: t('Search volume'),
    },
    {
      value: FolderMetric.AiTrafficValue,
      label: t('AI Traffic value'),
    },
    {
      value: FolderMetric.TrafficValue,
      label: t('Traffic value'),
    },
    {
      value: FolderMetric.AboveTheFold,
      label: t('Above the fold'),
    },
  ];
};
