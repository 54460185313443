import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { gql } from '@apollo/client';
import { FormApi } from 'final-form';
import { toJS } from 'mobx';
import { hideModal } from 'Actions/ModalAction';
import { DefaultChecked } from 'Components/Controls/Checkbox';
import { TableStoreType, useTableStore } from 'Components/DataTable';
import LoadingSpinner from 'Components/LoadingSpinner';
import {
  SEARCH_TYPE_YOUTUBE,
  SearchEngineTypes,
} from 'Components/Modal/Content/AddSearchEngine/types';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import useKeyword, { UpdateKeywordsSettingsValues } from 'Hooks/useKeyword';
import { TableIDs } from 'Types/Table';
import { getDirtyValues } from 'Utilities/final-form';
import { t } from 'Utilities/i18n';
import EditKeywordsForm from './EditKeywordsForm';
import styles from './edit-keywords.module.scss';

type Props = {
  keywords: string[];
  isAllSelected?: boolean;
  filters?: any[];
  numResults: number;
  hideModal?: any;
};

const getKeywordsQuery = gql`
  query editKeywords_keywordsQuery(
    $filters: [FilterInput]!
    $pagination: PaginationInput!
    $ordering: OrderingInput!
  ) {
    keywords(filters: $filters, pagination: $pagination, ordering: $ordering) {
      keywords {
        id
        keyword
        ignoreInShareOfVoice
        ignoreLocalResults
        ignoreFeaturedSnippet
        enableAutocorrect
        searchEngine {
          id
        }
        ignoredDomain
      }
    }
  }
`;

/** for merging table data, since it can include optimistic changes */
function mergeTableData(tableStore: TableStoreType, keywords: any[]) {
  const tableStoreData = toJS(tableStore?.data);

  const tableStoreKeywordById = tableStoreData.reduce((acc, cur) => {
    acc[cur.id] = cur;
    return acc;
  }, {});

  const mergedKeywords = keywords.map((keyword) => {
    const dataItem = tableStoreKeywordById[keyword.id];
    if (dataItem) {
      return { ...keyword, ...dataItem };
    }
    return keyword;
  });

  return mergedKeywords;
}

const EditKeywords = (props: Props) => {
  const tableStore = useTableStore(TableIDs.KEYWORDS);
  const { keywords, isAllSelected, filters, hideModal: _hideModal, numResults } = props;
  const { updateKeywordsSettings, getKeywords } = useKeyword();
  const [loading, setLoading] = useState(true);
  const [formLoading, setFormLoading] = useState(false);
  const [keywordsData, setKeywordsData] = useState<any[]>([]);

  useEffect(() => {
    if (!tableStore || !filters) {
      return;
    }
    getKeywords({
      keywords: keywords.map((x) => parseInt(x)),
      filters,
      query: getKeywordsQuery,
      stopIndex: numResults,
    }).then(
      ({
        data: {
          keywords: { keywords: _keywords },
        },
      }) => {
        const mergedKeywords = mergeTableData(tableStore, _keywords);
        setKeywordsData(mergedKeywords);
        setLoading(false);
      },
    );
  }, [keywords, isAllSelected, filters, numResults, tableStore]);

  const handleSubmit = (
    values: UpdateKeywordsSettingsValues,
    form: FormApi<UpdateKeywordsSettingsValues>,
  ) => {
    setFormLoading(true);

    const dirtyValues = getDirtyValues<UpdateKeywordsSettingsValues>(values, form);

    tableStore?.updateSelectedItems((data) => {
      Object.assign(data, dirtyValues);
    });

    const params = {
      ...dirtyValues,
      keywords: keywords.map((x) => parseInt(x)),
      isAllSelected,
      filters,
    };

    updateKeywordsSettings(params).then(({ data }) => {
      setFormLoading(false);
      const errors = data?.updateKeywords?.errors;
      if (errors) {
        return errors;
      }
      _hideModal();
    });

    keywords.map((id) => {
      tableStore?.updateRowData?.(id.toString())({ updatingKeyword: true });
    });
  };

  const ignoredDomainsAllEqual = keywordsData.every(
    (kw) => kw.ignoredDomain === keywordsData[0].ignoredDomain,
  );

  const allIgnoredDomains = Array.from(
    new Set(
      keywordsData
        .filter((keyword) => keyword.ignoredDomain)
        .map((keyword) => keyword.ignoredDomain),
    ),
  );

  const shareOfVoiceAllEqual: boolean = keywordsData.every(
    (kw) => kw.ignoreInShareOfVoice === keywordsData[0].ignoreInShareOfVoice,
  );
  const shareOfVoiceAllTrue: boolean = keywordsData.every((kw) => kw.ignoreInShareOfVoice);

  const ignoreInShareOfVoice: DefaultChecked = shareOfVoiceAllEqual
    ? shareOfVoiceAllTrue
    : 'indeterminate';

  const ignoreLocalResultsAllEqual: boolean = keywordsData.every(
    (kw) => kw.ignoreLocalResults === keywordsData[0].ignoreLocalResults,
  );
  const ignoreLocalResultsAllTrue: boolean = keywordsData.every((kw) => kw.ignoreLocalResults);

  const ignoreLocalResults: DefaultChecked = ignoreLocalResultsAllEqual
    ? ignoreLocalResultsAllTrue
    : 'indeterminate';

  const featuredSnippetAllEqual: boolean = keywordsData.every(
    (kw) => kw.ignoreFeaturedSnippet === keywordsData[0].ignoreFeaturedSnippet,
  );
  const featuredSnippetAllTrue: boolean = keywordsData.every((kw) => kw.ignoreFeaturedSnippet);

  const ignoreFeaturedSnippet: DefaultChecked = featuredSnippetAllEqual
    ? featuredSnippetAllTrue
    : 'indeterminate';

  const autocorrectAllEqual: boolean = keywordsData.every(
    (kw) => kw.enableAutocorrect === keywordsData[0].enableAutocorrect,
  );
  const autocorrectAllTrue: boolean = keywordsData.every((kw) => kw.enableAutocorrect);

  const enableAutocorrect: DefaultChecked = autocorrectAllEqual
    ? autocorrectAllTrue
    : 'indeterminate';

  const showGoogleOptions =
    keywordsData.filter((kw) => parseInt(kw.searchEngine.id, 10) === 1).length > 0;

  const youtubeKeywordsSelected =
    keywordsData.filter(
      (kw) => parseInt(kw.searchEngine.id) === parseInt(SearchEngineTypes[SEARCH_TYPE_YOUTUBE]),
    ).length > 0;

  const initialIgnoredDomain = ignoredDomainsAllEqual ? allIgnoredDomains?.[0] ?? null : null; // no value should be null, because selecting and deselecting results in null

  return (
    <ModalBorder
      className={styles.editKeywords}
      title={t('Change Settings for Selected Keywords')}
      onClose={_hideModal}
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <EditKeywordsForm
          handleSubmit={handleSubmit}
          initialValues={{
            ignoreInShareOfVoice,
            ignoreLocalResults,
            ignoreFeaturedSnippet,
            enableAutocorrect,
            ignoredDomain: initialIgnoredDomain,
          }}
          mixedIgnoredDomains={!ignoredDomainsAllEqual ? allIgnoredDomains : []}
          showGoogleOptions={showGoogleOptions}
          youtubeKeywordsSelected={youtubeKeywordsSelected}
          loading={formLoading}
          keywordIds={keywords}
        />
      )}
    </ModalBorder>
  );
};

export default connect(null, {
  hideModal,
})(EditKeywords);
