import { useEffect, useRef } from 'react';
import { useIntercom } from 'react-use-intercom';
import { Flex, Group } from '@mantine/core';
import AccButton from 'Components/AccButton/AccButton';
import AccPaper from 'Components/AccPaper';
import { useInsertIntercomCloseButton } from 'Components/Intercom/useInsertIntercomCloseButton';
import { useIntercomConfig } from 'Components/Intercom/useIntercomConfig';
import AccText from 'Components/Text/AccText';
import AccTitle from 'Components/Title/AccTitle';
import { useUser } from 'Hooks/data/user/useUser';
import { t } from 'Utilities/i18n';
import AccurankerIcon from 'icons/logo-brand.svg';
import styles from './publicReportPage.module.scss';

function useIntercomControls() {
  const bootRef = useRef(false);
  const { hide, show, isOpen, boot } = useIntercom();
  useInsertIntercomCloseButton({ hide, isOpen, isBooted: bootRef.current });
  const bootIntercomWithConfiguration = useIntercomConfig({ boot });

  useEffect(() => {
    if (bootRef.current) {
      return;
    }
    if (bootIntercomWithConfiguration) {
      bootIntercomWithConfiguration();
      bootRef.current = true;
    }
  }, [bootIntercomWithConfiguration]);
  return { hide, show, isOpen };
}

export const ErrorFallback = () => {
  const { isAuthenticated } = useUser();
  const { hide, show, isOpen } = useIntercomControls();

  return (
    <>
      <Group align="center" wrap="nowrap" gap="xs">
        <img className={styles.logo} src={AccurankerIcon} alt={'Accuranker logo'} />

        <AccTitle type="h1" mb="0" ta="center">
          {t('Something Went Wrong...')}
        </AccTitle>
      </Group>
      <AccPaper>
        <Flex align="center" justify="center" wrap="wrap" gap="md" direction="column" h="100%">
          <AccTitle type="h2">{t('An error occurred while creating the report')}</AccTitle>
          <AccText fw={500} style={{ whiteSpace: 'pre' }}>
            {t('Please contact support if this issue persists')}
          </AccText>
          <AccButton
            id="intercom-button"
            variant="secondary"
            onClick={() => {
              isOpen ? hide : show;
            }}
          >
            {isOpen ? t('Close support chat') : t('Contact support')}
          </AccButton>
          <AccButton variant="primary" link="/">
            {isAuthenticated ? t('Go to dashboard') : t('Go to login')}
          </AccButton>
        </Flex>
      </AccPaper>
    </>
  );
};
