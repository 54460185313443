import React, { useState } from 'react';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import { AccFastIcon } from 'Components/AccFastIcon';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import Cell from 'Components/AccuTable/CellRenderer/HelperComponents/AccuCell';
import NaCell from 'Components/AccuTable/CellRenderer/helpers/NaCell';
import { useRowContext } from 'Components/DataTable';
import FormatNumber from 'Components/FormatNumber';
import { KeywordRankWithCompetitorRankNode } from 'Ghql';
import { t } from 'Utilities/i18n';
import ChevronDown from 'icons/chevronDown.svg';
import ChevronUp from 'icons/chevronUp.svg';

interface SearchVolumeCellProps {
  record: KeywordRankWithCompetitorRankNode;
}

export const SearchVolumeCell: React.FC<React.PropsWithChildren<SearchVolumeCellProps>> = ({
  record,
}) => {
  const [isActive, setIsActive] = useState(false);
  const rowContext = useRowContext();
  const openSearchVolume = () => {
    rowContext?.toggleCustomExpand({ useAi: false }); // TODO: Implement AI SV here
    setIsActive(!isActive);
  };

  if (record?.noSearchVolumeForLocation === true && record?.location) {
    return (
      <NaCell
        tooltip={t('Local search volume is not available for this location.')}
        align="right"
      />
    );
  }

  if (record?.validForSearchVolume === false) {
    return null;
  }

  return (
    <Cell rightAligned>
      <AccTooltip tooltip={t('Show historic search volume for this keyword')} disable={isActive}>
        <AccActionIcon
          mr="auto"
          variant="subtle"
          size="sm"
          onClick={openSearchVolume}
          styles={{
            root: { backgroundColor: isActive ? '#ced0dc' : undefined },
          }}
        >
          {isActive ? (
            <AccFastIcon src={ChevronUp} color={'#444444'} />
          ) : (
            <AccFastIcon src={ChevronDown} color={'#6b6c6c'} />
          )}
        </AccActionIcon>
      </AccTooltip>

      <FormatNumber>{record?.searchVolumeValue}</FormatNumber>
    </Cell>
  );
};
