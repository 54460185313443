import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import set from 'lodash/set';
import { UNCATEGORIZED_FOLDER_ID } from 'Pages/Keywords/Groupings/EditMode/components/DeleteFolderModal';
import { t } from 'Utilities/i18n';
import { NumberOperations } from '../../../support/constants';
import { getEmptyGroupToken, getIsGroupToken } from '../../../support/helpers';
import { DynamicTagFormValues, TokenGroup, TokenItem } from '../../../support/types';
import { combineFieldNames } from '../components/BuilderToken/support/helpers';
import { TAG_SOURCE_OPTIONS, TITLE_CONTAINS_KEYWORD } from './constants';
import { DynamicTagsCountVariables } from './types';

type NestedTokenItem = { indexes: number[]; item: TokenItem };

const normalizeTokens = (val?: TokenGroup, indexes: number[] = []): NestedTokenItem[] => {
  const result: NestedTokenItem[] = [];
  val?.tokens?.map((e, i) => {
    if (getIsGroupToken(e)) {
      result.push(...(normalizeTokens(e, [...indexes, i]) || []));
    } else {
      result.push({ indexes: [...indexes, i], item: e });
    }
  });
  return result ?? [];
};

export const validateRuleItem = (item: Partial<TokenItem>) => {
  const result: Partial<Record<keyof TokenItem, string>> = {};
  if (!item.source) {
    result.source = t('Field is required');
  } else if (!item.operator) {
    result.operator = t('Field is required');
  } else if (item.operator === NumberOperations.BETWEEN) {
    const empty = !item.value || isNil(item.value?.[0]) || isNil(item.value?.[1]);
    if (empty) {
      result.value = t('Provide both from and to');
    } else if (parseInt(item.value?.[0]) > parseInt(item.value?.[1])) {
      result.value = t('From should be less then To');
    }
  } else if ((isNil(item.value) && item.operator !== TITLE_CONTAINS_KEYWORD) || item.value === '') {
    result.value = t('Field is required');
  }

  return isEmpty(result) ? undefined : result;
};

const buildError = (errorObj: Record<string, string>, indexes: number[]) => {
  const basePath = 'rawRuleset.tokens['.concat(indexes.join(']tokens[').concat(']'));
  return Object.keys(errorObj || {}).reduce(
    (acc, k) => ({
      ...acc,
      [combineFieldNames(basePath, k)]: errorObj[k],
    }),
    {},
  );
};

export const validateRules = (val?: TokenGroup) => {
  const nestedTokens = normalizeTokens(val);

  const result = nestedTokens
    .map((e) => ({ indexes: e.indexes, error: validateRuleItem(e.item) }))
    .filter((e) => !!e.error)
    .reduce(
      (acc, e) => ({
        ...acc,
        ...buildError(e.error!, e.indexes),
      }),
      {},
    );

  return isEmpty(result) ? undefined : result;
};

const getBaseRulesError = (form: Partial<DynamicTagFormValues>) => {
  const nestedTokens = normalizeTokens(form?.rawRuleset);
  return nestedTokens.length ? undefined : { rawRuleset: t('Field is required.') };
};

export const validateRulesForm = (form: Partial<DynamicTagFormValues>) => {
  const rawRulesetErrors = validateRules(form?.rawRuleset ?? undefined);
  if (rawRulesetErrors) {
    const result = {};
    Object.keys(rawRulesetErrors).forEach((k) => {
      set(result, k, rawRulesetErrors[k]);
    });
    return result;
  }

  const baseError = getBaseRulesError(form);
  if (baseError) {
    return baseError;
  }
  return undefined;
};

export const getDefaultTagsFormValues = (): Pick<
  DynamicTagFormValues,
  'rawRuleset' | 'folder'
> => ({
  rawRuleset: getEmptyGroupToken() as TokenGroup,
  folder: UNCATEGORIZED_FOLDER_ID,
});

export const getMatchDynamicCount = (
  domainId: string,
  form: Partial<DynamicTagFormValues>,
): DynamicTagsCountVariables => {
  return {
    domainId,
    rawRuleset: JSON.stringify(form.rawRuleset),
  };
};

export const getOperationType = (source?: string) => {
  return source ? TAG_SOURCE_OPTIONS.find((e) => e.id === source)?.operationType : undefined;
};
