// string can be with single quote and double quotes
import isString from 'lodash/isString';
import { devError } from 'Utilities/log';
import { parseSingleQuoteArray } from 'Utilities/underdash';
import { GroupSettingsItem } from '../types';

export const selectFlatColumnIds = <ReturnT extends string[] | GroupSettingsItem[] | null>(
  data: string | string[] | undefined,
): ReturnT => {
  if (data) {
    if (isString(data)) {
      let customColumns: string[] | GroupSettingsItem[] | null = null;

      // means that it's not single quouted
      if (data?.startsWith?.('[')) {
        try {
          return JSON.parse(data);
        } catch (e) {
          devError('Failed to parse non-single quote string');
        }
      }

      try {
        customColumns = parseSingleQuoteArray(data);
      } catch (e) {
        try {
          customColumns = JSON.parse(data).filter((el: any) => isString(el));
        } catch (err) {
          console.error('Failed to parse', err);
          return null as ReturnT;
        }
      }
      return Array.isArray(customColumns) ? (customColumns as ReturnT) : (null as ReturnT);
    } else if (Array.isArray(data)) {
      return data.filter((e) => isString(e)) as ReturnT;
    }
  }

  return null as ReturnT;
};
