import colorScheme from 'Utilities/colors';
import { t } from 'Utilities/i18n';
import { OverviewChartSeries } from '../constants';
import { addLowDataPlaceholderDays } from '../support/lowData';

type HistoryChartSeries = OverviewChartSeries;

export const getAverageCtrSeries = (data: any): HistoryChartSeries[] => {
  const firstDateWithAvailableCtrData = 1622498000000; // June 1st, 2021
  const series: HistoryChartSeries[] = [
    {
      name: t('Average CTR'),
      primary: true,
      color: colorScheme.graphs.colors.darkGreen,
      data:
        data
          ?.filter(Boolean)
          ?.filter((e) => e.date > firstDateWithAvailableCtrData)
          .map((e) => ({
            x: e?.date ?? 0,
            y: e?.avgCtr ?? 0,
            totalKeywords: Number(e?.keywords ? e?.keywords : 0),
          })) ?? [],
    },
  ];
  addLowDataPlaceholderDays(series[0]);
  return series;
};
