import FormatNumber from 'Components/FormatNumber';
import LoadingSpinner from 'Components/LoadingSpinner';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { useAffiliateInfoQuery } from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import { t } from 'Utilities/i18n';

const AffiliateInfo = () => {
  const { hideModal } = useModal();
  const { data, loading } = useAffiliateInfoQuery();

  const Content = () => (
    <table className="data-table table">
      <tbody>
        <tr>
          <td>{t('AccuRanker affiliate ID (aaid)')}</td>
          <td>{data?.affiliate?.details?.affiliateId}</td>
        </tr>
        <tr>
          <td>{t('Paypal email')}</td>
          <td>{data?.affiliate?.details?.paypalEmail || 'n/a'}</td>
        </tr>
        <tr>
          <td>{t('Minimum payout')}</td>
          <td>
            <FormatNumber currency="USD">{data?.affiliate?.details?.minimumPayout}</FormatNumber>
          </td>
        </tr>
        <tr>
          <td>{t('Commission percentage')}</td>
          <td>{data?.affiliate?.details?.commission}%</td>
        </tr>
        <tr>
          <td>{t('Total commission')}</td>
          <td>
            <FormatNumber currency="USD">{data?.affiliate?.details?.totalCommission}</FormatNumber>
          </td>
        </tr>
        <tr>
          <td>{t('Total unpaid commission (and older than 30 days)')}</td>
          <td>
            <FormatNumber currency="USD">
              {data?.affiliate?.details?.totalUnpaidCommission}
            </FormatNumber>
          </td>
        </tr>
        <tr>
          <td>{t('Total paid commission')}</td>
          <td>
            <FormatNumber currency="USD">
              {data?.affiliate?.details?.totalPaidCommission}
            </FormatNumber>
          </td>
        </tr>
      </tbody>
    </table>
  );

  return (
    <ModalBorder title={t('Affiliate Information')} onClose={hideModal}>
      {loading || !data?.affiliate?.details ? <LoadingSpinner /> : <Content />}
    </ModalBorder>
  );
};

export default AffiliateInfo;
