import { Flex } from '@mantine/core';
import { IconCalendarEvent, IconPlayerPlay, IconShoppingCart } from '@tabler/icons-react';
import AccessControl from 'Components/AccessControl';
import { t } from 'Utilities/i18n';
import Action from './action';

type Props = {
  playVideo: () => void;
  scheduleMeeting: () => void;
  selectPlan: () => void;
};

const WelcomeActions = (props: Props) => {
  const { playVideo, scheduleMeeting, selectPlan } = props;

  return (
    <Flex gap={24} wrap="wrap" my={42}>
      <Action
        title={t('Explore')}
        body={t('I want to explore the platform on my own and watch the videos')}
        action={playVideo}
        actionText={t('Play video')}
        icon={<IconPlayerPlay size={18} />}
      />
      <Action
        title={t('Guided tour')}
        body={t(
          'Schedule a free meeting with a specialist, and get the full onboarding experience',
        )}
        action={scheduleMeeting}
        actionText={t('Schedule meeting')}
        icon={<IconCalendarEvent size={18} />}
        primary
      />
      <AccessControl>
        <Action
          title={t('Select plan')}
          body={t('I am familiar with the platform and would just like to upgrade my account')}
          action={selectPlan}
          actionText={t('Select my plan')}
          icon={<IconShoppingCart size={18} />}
        />
      </AccessControl>
    </Flex>
  );
};

export default WelcomeActions;
