import { RefObject, useLayoutEffect } from 'react';
import noop from 'lodash/noop';
import { SCROLL_FINISH_RE_RENDER_DELAY } from '../constants';
import { calculateElementsPosition, updateBodyScrolling } from '../helpers';
import { viewStore } from '../store';

export const useTableElementsPositionScrollSync = (
  itemsPerScreen: number,
  containerRef: RefObject<HTMLDivElement>,
  itemsCount: number,
  listRef: RefObject<HTMLDivElement>,
  disableBodyOnScroll: boolean,
) => {
  const updateScrolling = disableBodyOnScroll ? updateBodyScrolling : noop;
  const updateRowPositions = () => {
    const updatedItemsPositions = calculateElementsPosition(
      itemsPerScreen,
      containerRef,
      itemsCount,
    );

    const itemsDom = Array.from(listRef?.current?.children ?? []);

    viewStore.setVisibilityBatch(
      updatedItemsPositions.map((e, i) => ({
        index: i,
        nextIndex: e.index,
      })),
    );

    requestAnimationFrame(() => {
      itemsDom.map((e: any, i) => {
        if (e?.style) {
          e.style.top = `${updatedItemsPositions[i]?.top}px`;
          const index = updatedItemsPositions[i]?.index;
          e.style.display = index >= itemsCount ? 'none' : '';
        }
      });
    });
  };

  useLayoutEffect(() => {
    let scrollingStop;
    const onScroll = () => {
      clearTimeout(scrollingStop);

      updateScrolling(true);
      viewStore.setScrolling(true);
      updateRowPositions();

      scrollingStop = setTimeout(() => {
        viewStore.setScrolling(false);
        updateScrolling(false);
      }, SCROLL_FINISH_RE_RENDER_DELAY);
    };

    if (itemsCount > itemsPerScreen) {
      document.addEventListener('scroll', onScroll, false);
    }

    return () => {
      viewStore.setScrolling(false);
      updateScrolling(false);
      clearTimeout(scrollingStop);
      document.removeEventListener('scroll', onScroll);
    };
  }, [itemsPerScreen, itemsCount, updateScrolling]);
};
