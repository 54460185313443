import { Component } from 'react';
import AccButton from 'Components/AccButton/AccButton';
import { t } from 'Utilities/i18n';

type Props = {
  message: string;
  onSubmit: (...args: Array<any>) => any;
};
export default class ConnectedAccount extends Component<Props> {
  render() {
    const { message, onSubmit } = this.props;
    return (
      <div>
        <p>{message}</p>
        <div className="form-actions">
          <AccButton variant="primary" onClick={onSubmit}>
            {t('Close')}
          </AccButton>
        </div>
      </div>
    );
  }
}
