import { QueryOrder } from 'Components/DataTable';
import { SORTING_TYPE } from 'Constants';
import { TableIDs } from 'Types/Table';

export const CompetitorsRanksTableIDs = {
  KEYWORD: 'keyword',
  LOCATION: 'location',
  RANK: 'rank',
  RANK_CHANGE: 'rank_change',
  ABOVE_THE_FOLD: 'above_the_fold',
  SEARCH_ENGINE: 'search_type',
  SEARCH_VOLUME: 'search_volume_value',
  COMPETITOR_RANK: 'competitor_rank:',
  COMPETITOR_RANK_CHANGE: 'competitor_rank_change:',
};

export const DEFAULT_COMPETITORS_RANKS_ORDERING = {
  order: QueryOrder.ASC,
  orderBy: CompetitorsRanksTableIDs.KEYWORD,
};

export const CompetitorRanksOrderConfig = {
  KEYWORD: {
    defaultOrder: QueryOrder.ASC,
    orderBy: CompetitorsRanksTableIDs.KEYWORD,
    sortingKey: SORTING_TYPE.alphabetical,
  },
  SEARCH_ENGINE: {
    defaultOrder: QueryOrder.ASC,
    orderBy: CompetitorsRanksTableIDs.SEARCH_ENGINE,
    sortingKey: SORTING_TYPE.alphabetical,
  },
  RANK: {
    defaultOrder: QueryOrder.ASC,
    orderBy: CompetitorsRanksTableIDs.RANK,
    sortingKey: SORTING_TYPE.rank,
  },
  RANK_CHANGE: {
    defaultOrder: QueryOrder.ASC,
    orderBy: CompetitorsRanksTableIDs.RANK_CHANGE,
    sortingKey: SORTING_TYPE.numerical,
  },
  ABOVE_THE_FOLD: {
    defaultOrder: QueryOrder.ASC,
    orderBy: CompetitorsRanksTableIDs.ABOVE_THE_FOLD,
    sortingKey: SORTING_TYPE.numerical,
  },
  SEARCH_VOLUME: {
    defaultOrder: QueryOrder.ASC,
    orderBy: CompetitorsRanksTableIDs.SEARCH_VOLUME,
    sortingKey: SORTING_TYPE.numerical,
  },
};

export const COMPETITORS_RANKS_TABLE_ID = TableIDs.COMPETITOR_RANKINGS;
