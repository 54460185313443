import { useMemo } from 'react';
import cn from 'classnames';
import { DataTable, QueryOrder, TableSize } from 'Components/DataTable';
import { FilterAttribute } from 'Types/Filter';
import * as Sort from 'Types/Sort';
import { TableIDs } from 'Types/Table';
import { t } from 'Utilities/i18n';
import { NotesTableIDs } from './support/types';
import { useFetchNotes } from './support/useFetchNotes';
import { useNotesTableInfo } from './support/useNotesTableInfo';
import reusableStyles from '../../../css/reusable-styles.module.scss';
import styles from '../keywords-notes.module.scss';

const NotesTable = () => {
  const fetchNotes = useFetchNotes();
  const { columns } = useNotesTableInfo();

  const tableConfig = useMemo(
    () => ({
      offlineFilter: {
        skip: ['domains'],
        mappings: {
          [FilterAttribute.DATE]: 'createdAt',
          [FilterAttribute.USER_NAME]: 'user.fullName',
        },
        sortTypes: {
          createdAt: Sort.DATE,
        },
        transformData: {
          keywords: (kws) => {
            return kws?.filter(Boolean).map(({ id }) => id);
          },
          note: (note) => note.toLowerCase(),
        },
        tableName: TableIDs.NOTES,
      },
      emptyOptions: {
        title: t('No Data'),
        subTitle: t('Did not find any notes.'),
      },
      defaultOrdering: {
        order: QueryOrder.DESC,
        orderBy: NotesTableIDs.CREATED_BY,
      },
    }),
    [],
  );
  return (
    <DataTable
      className={cn(reusableStyles.paper, styles.removeMinHeight)}
      tableId={TableIDs.NOTES}
      columns={columns}
      fetchData={fetchNotes}
      {...tableConfig}
      viewMode={TableSize.DEFAULT}
      pagination
    />
  );
};

export default NotesTable;
