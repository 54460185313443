export const getOrderOfMagnitude = (value: number) =>
  Math.floor(Math.log(value) / Math.LN10 + 0.000000001);

/** Gets the height of the grayscale bars in the background on the charts
 * If the largest y value is 15273, this is set to 20000
 * If it is 220000 it is set to 300000
 * I.e. it is the nearest number in the same order of magnitude with the first digit increased by 1.
 * */
export const getYMax = (item) => {
  if (!item.data) return 0;

  const maxValue = Math.max(...item.data.map((x) => x.value));

  const orderOfMagnitude = getOrderOfMagnitude(maxValue);

  const firstDigit = Number(String(maxValue)[0]);

  const result = (firstDigit + 1) * Math.pow(10, orderOfMagnitude);
  return isNaN(result) ? 0 : result;
};
