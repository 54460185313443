import ChecklistEditor from 'Components/Filters/Common/ChecklistEditor';
import { someOfOptions } from 'Components/Filters/Common/labelFunc';
import { FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import type { KeywordOptionsFilter } from 'Types/Filter';
import { t } from 'Utilities/i18n/index';
import Icon from 'icons/progress-checked.svg?inline';

const defaultValue: KeywordOptionsFilter = {
  attribute: FilterAttribute.KEYWORD_OPTIONS,
  type: FilterValueType.ARRAY,
  comparison: FilterComparison.ALL,
  value: [],
};

const getData = () => {
  const items = [
    {
      value: 'ignore_local_results',
      label: t('Ignore local results'),
    },
    {
      value: 'ignore_featured_snippet',
      label: t('Ignore featured snippet'),
    },
    {
      value: 'ignore_in_share_of_voice',
      label: t('Ignore in share of voice'),
    },
    {
      value: 'ignored_domain',
      label: t('Ignored domain'),
    },
    {
      value: 'enable_autocorrect',
      label: t('Enable autocorrect'),
    },
  ];
  return {
    defaultValue,
    title: t('Keyword Options'),
    icon: Icon,
    editor: ChecklistEditor,
    editorProps: {
      items,
      disallowEmptySelection: true,
    },
    labelFunc: someOfOptions(items),
  };
};

export default getData;
