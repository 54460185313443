import ErrorBoundary from 'Components/ErrorBoundary';
import KpiBox, { KpiBoxSizeProps } from 'Pages/Keywords/Overview/components/KpiBar/KpiBox';
import { KPI_NAMES } from 'Pages/Keywords/Overview/components/KpiBar/index';
import { t } from 'Utilities/i18n';
import { KpiBarQueryProps } from './useKpiBarQueryWithDisplayCurrency';

const PixelsFromTopKpiBox = ({
  size,
  kpiBarQueryProps,
  draggable,
}: KpiBoxSizeProps & { kpiBarQueryProps: KpiBarQueryProps }) => {
  const { displayCurrency, data, loading } = kpiBarQueryProps;

  return (
    <ErrorBoundary>
      <KpiBox
        id={KPI_NAMES.PIXELS_FROM_TOP}
        size={size}
        title={t('Avg. Pixels From Top')}
        loading={loading || !displayCurrency}
        value={loading ? 0 : data?.kpis?.allKpis?.pixelsFromTop ?? undefined}
        beforeValue={loading ? 0 : data?.kpis?.allKpis?.pixelsFromTopBefore ?? undefined}
        precision={0}
        changeNegation
        draggable={draggable}
      />
    </ErrorBoundary>
  );
};

export default PixelsFromTopKpiBox;
