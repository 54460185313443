import { createSelector } from 'reselect';
import { FilterAttributetype, FilterBase } from 'Types/Filter';
import { StoreType } from 'Types/Store';

const filterSelector = (state: StoreType) => state.filter.filterGroup.filters;

export const specificFilterSelectorValue = <T extends FilterAttributetype>(filterAttribute: T) =>
  createSelector(
    filterSelector,
    (filters) =>
      (
        filters.find((f) => f.attribute === filterAttribute) as Extract<
          FilterBase,
          { attribute: T }
        >
      )?.value,
  );

const specificFilterSelector = <T extends FilterAttributetype>(filterAttribute: T) =>
  createSelector(filterSelector, (filters) => {
    const specificFilter = filters.find((f) => f.attribute === filterAttribute);
    //Make sure typeScript returns the type of the specific filter
    return specificFilter as Extract<FilterBase, { attribute: T }> | undefined;
  });

export default specificFilterSelector;
