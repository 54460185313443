import { Box, Checkbox, Collapse, Flex, Group, useMantineTheme } from '@mantine/core';
import { IconChevronRight, IconFolder, IconSitemap } from '@tabler/icons-react';
import cn from 'classnames';
import AccTag from 'Components/AccTag';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import DemoContentBadge from 'Components/DemoContent/DemoContentBadge';
import { DomainFavicon } from 'Components/DomainFavicon';
import { Flag } from 'Components/Flag';
import { RowIndentations } from 'Components/Table/TreeTable';
import AccText from 'Components/Text/AccText';
import { useDeepMemo } from 'Hooks/useDeep';
import { MaxSelectableNodes } from 'Pages/Domains/support/const';
import { ItemType } from 'Utilities/generateSelectId';
import { t } from 'Utilities/i18n';
import { TreeItem, useComparisonItemContext } from '../useComparisonContext';
import ItemContainer from './ItemContainer';
import style from '../domainsComparison.module.scss';

const EXPAND_ICON_WIDTH = '20px';

export type ItemProps = {
  data: TreeItem;
  depth: number;
  lastChild: boolean;
  hideConnectors: string[];
  groupIsDemo?: boolean;
};

const CollapseLabel = ({
  name,
  type,
  domain,
  country,
  dynamic,
}: {
  name: string;
  type: ItemType;
  domain?: string;
  country?: string;
  dynamic?: boolean;
}) => {
  const theme = useMantineTheme();
  const Label = (
    <AccText truncate fw={600} flex="1" lh="xs">
      {name}
    </AccText>
  );
  switch (type) {
    case 'group':
      return (
        <>
          <IconSitemap size={17} color={theme.colors.gray[7]} />
          {Label}
        </>
      );
    case 'domain':
      return (
        <>
          <DomainFavicon domain={domain} />
          {Label}
          {country && <Flag size="sm" mr={2} country={country} />}
        </>
      );
    case 'folder':
      return (
        <>
          <IconFolder size={20} color={theme.colors.gray[7]} />
          {Label}
        </>
      );
    case 'tag':
      return (
        <Box ml="xxs">
          <AccTag tag={name} dynamic={dynamic} maxWidth="100%" />
        </Box>
      );
    default:
      return Label;
  }
};

export const CollapseItem = ({
  data,
  depth,
  lastChild,
  hideConnectors,
  groupIsDemo,
}: ItemProps) => {
  const { selected, disabled, expanded, toggleOpen, toggleSelect, color } =
    useComparisonItemContext(data);
  const { type = 'group', dyn, label, dd, cc, ds, fs, ts, disabled: itemDisabled, demo } = data;
  const isDemoContent = demo || groupIsDemo;
  // Combine all child items into a single array
  const childItems = useDeepMemo(() => [...(ds || []), ...(fs || []), ...(ts || [])], [ds, fs, ts]);
  const hasCollapse = childItems.length > 0;
  const checkboxDisabled = disabled || itemDisabled;
  let tooltip = '';
  if (disabled) {
    tooltip = t('Max number (%s) of selected items reached.', MaxSelectableNodes);
  } else if (itemDisabled) {
    tooltip = t('No sub-folders or tags found.');
  }

  return (
    <>
      <Group gap="xs" wrap="nowrap" h="50px" style={{ overflow: 'hidden' }}>
        <AccTooltip tooltip={tooltip} disable={!checkboxDisabled}>
          <Checkbox
            checked={selected}
            onChange={toggleSelect}
            color={color}
            disabled={checkboxDisabled}
            classNames={{ input: style.checkbox }}
          />
        </AccTooltip>
        <RowIndentations
          isDemoContent={isDemoContent}
          expandIconWidth={EXPAND_ICON_WIDTH}
          depth={depth}
          className={cn({ lastChild })}
          hideConnectors={lastChild ? hideConnectors.slice(0, -1) : hideConnectors}
          addEmptyConnector={!hasCollapse}
        />
        <Group
          onClick={hasCollapse ? toggleOpen : undefined}
          data-has-collapse={hasCollapse || undefined}
          className={style.trigger}
          gap={6}
          wrap="nowrap"
          flex="1"
        >
          {hasCollapse && (
            <Flex
              className={cn(style.triggerIcon, {
                [style.triggerIconRotate]: expanded,
              })}
            >
              <IconChevronRight size={16} />
            </Flex>
          )}
          <CollapseLabel name={label || ''} type={type} domain={dd} country={cc} dynamic={!!dyn} />
          {isDemoContent && type === 'group' && (
            <DemoContentBadge
              title={t('demo group')}
              tooltipTitle={t(
                'This is a group with demo domains and cannot be edited.<br>The data is updated once a day.',
              )}
            />
          )}
        </Group>
      </Group>
      {hasCollapse && (
        <Collapse in={expanded} transitionDuration={120}>
          {childItems.map((item, idx) => (
            <ItemContainer
              key={item.selectId}
              data={item}
              groupIsDemo={isDemoContent}
              depth={depth + 1}
              lastChild={idx === childItems.length - 1}
              hideConnectors={
                idx === childItems.length - 1 ? [...hideConnectors, `hide${depth}`] : hideConnectors
              }
            />
          ))}
        </Collapse>
      )}
    </>
  );
};
