import { useState } from 'react';
import { Menu, ScrollArea } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import cn from 'classnames';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import AccText from 'Components/Text/AccText';
import style from 'Pages/Keywords/Overview/NextChartsDashboard/DraggableChartListV2/dashboard.module.scss';
import {
  CHART_MENU,
  GET_CHART_DESCRIPTION,
  GET_CHART_DISPLAY_NAME,
} from 'Pages/Keywords/Overview/components/ChartBox';
import { EventName, useMixpanel } from 'Utilities/Analytics/mixpanel';
import { t } from 'Utilities/i18n';

type AddChartBoxProps = {
  addColumnOptions?: string[];
  index: number;
  addItem: (id: string, index: number, columnId: string | undefined) => void;
  columnId: string | undefined;
  position: string;
};

export const AddChartBox = ({
  addColumnOptions,
  index,
  addItem,
  columnId,
  position,
}: AddChartBoxProps) => {
  const [opened, setOpened] = useState(false);

  const trackEvent = useMixpanel();

  const trackClick = () => {
    const text = 'Add Chart';
    const extraProps = {
      ...(text ? { Text: text } : {}),
    };
    trackEvent(EventName.ButtonClick, extraProps);
  };

  return (
    <Menu
      shadow="md"
      width={320}
      opened={opened}
      onChange={setOpened}
      position="bottom-start"
      withinPortal
      offset={0}
    >
      <Menu.Target>
        <div className={style.addChart} data-add-chart-container={position}>
          <div className={cn(style.addChartButton, opened ? style.addChartActive : '')}>
            <AccTooltip tooltip={t('Add widgets to dashboard')} disable={opened}>
              <div className={style.addChartButtonInner} onClick={() => trackClick()}>
                <IconPlus size={10} />
                <AccText variant="label" size="xs" className={style.addChartButtonText}>
                  {t('Add Widget')}
                </AccText>
              </div>
            </AccTooltip>
          </div>
        </div>
      </Menu.Target>
      <Menu.Dropdown>
        <ScrollArea.Autosize
          mah={512}
          scrollbars={'y'}
          offsetScrollbars={(addColumnOptions?.length || 0) > 5}
          type="hover"
        >
          {addColumnOptions?.map((e) => {
            return (
              <Menu.Item key={e} onClick={() => addItem(e, index, columnId)}>
                <div className={style.menuItem}>
                  <div>{CHART_MENU[e]}</div>
                  <div>
                    <AccText variant="label">{GET_CHART_DISPLAY_NAME()[e]}</AccText>
                    <AccText inactive size={'xs'}>
                      {GET_CHART_DESCRIPTION()[e]}
                    </AccText>
                  </div>
                </div>
              </Menu.Item>
            );
          })}
        </ScrollArea.Autosize>
      </Menu.Dropdown>
    </Menu>
  );
};
