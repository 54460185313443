import { memo } from 'react';
import { MantineSize } from '@mantine/core';
import cn from 'classnames';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import AccText from 'Components/Text/AccText';
import colorScheme from 'Utilities/colors';
import { t } from 'Utilities/i18n';
import style from './search-intent-icon.module.scss';

type SearchIntentIconProps = {
  searchIntent: 'T' | 'C' | 'N' | 'I';
  withTooltip?: boolean;
  withFilterTooltip?: boolean;
  size: number;
  fontSize?: (string & {}) | MantineSize | undefined;
  faded?: boolean;
  opacity?: number;
  inverted?: boolean;
};

const SearchIntentIcon = (props: SearchIntentIconProps) => {
  const {
    searchIntent,
    withTooltip,
    withFilterTooltip,
    size = 18,
    fontSize = 'sm',
    faded,
    opacity = 1,
    inverted = false,
  } = props;
  const tooltipMapper = {
    T: t('Transactional'),
    C: t('Commercial'),
    N: t('Navigational'),
    I: t('Informational'),
  };
  const filterTooltipMapper = {
    T: t('Add "Transactional" as filter'),
    C: t('Add "Commercial" as filter'),
    N: t('Add "Navigational" as filter'),
    I: t('Add "Informational" as filter'),
  };

  return (
    <AccTooltip
      delay={0}
      tooltip={
        withTooltip
          ? tooltipMapper[searchIntent]
          : withFilterTooltip
          ? filterTooltipMapper[searchIntent]
          : ''
      }
    >
      <div
        className={cn(style.square, { [style.faded]: faded })}
        style={{
          background: inverted ? undefined : colorScheme.graphs.searchIntent[searchIntent],
          width: size,
          height: size,
          opacity,
          borderColor: inverted ? colorScheme.graphs.searchIntent[searchIntent] : undefined,
          borderStyle: 'solid',
          borderWidth: inverted ? 1 : 0,
        }}
      >
        <AccText
          c={inverted ? colorScheme.graphs.searchIntent[searchIntent] : 'white'}
          size={fontSize}
          fw={inverted ? 900 : 600}
        >
          {searchIntent}
        </AccText>
      </div>
    </AccTooltip>
  );
};

export default memo(SearchIntentIcon);
