import { Tabs as MantineTabs, TabsProps } from '@mantine/core';
import cn from 'classnames';
import styles from './tabs.module.scss';

type Props = {
  className?: string;
} & TabsProps;

const Tabs = (props: Props) => {
  const { className, classNames, ...rest } = props;
  return (
    <MantineTabs
      {...rest}
      className={className}
      classNames={{
        tab: cn(styles.tab),
        list: cn(styles.tabsList),
        tabLabel: cn(styles.tabLabel),
        ...classNames,
      }}
    />
  );
};

Tabs.Tab = MantineTabs.Tab;
Tabs.Panel = MantineTabs.Panel;
Tabs.List = MantineTabs.List;

export default Tabs;
