/**
 * Splits a full name into first name and last name parts.
 * If the input is null or undefined, returns empty strings for both.
 * If only a last name is provided, the first name will be an empty string.
 *
 * @param fullName - The full name as a string, which may be null or undefined.
 * @returns An object containing the first name and last name.
 */
export const splitFullName = (
  fullName: string | null | undefined,
): { firstName: string; lastName: string } => {
  const name = fullName ?? '';
  const nameParts = name.trim().split(' ');
  // If there's only one part, treat it as the last name
  const lastName = nameParts.pop() || '';
  const firstName = nameParts.join(' ') || '';

  return { firstName, lastName };
};
