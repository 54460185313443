import { Component } from 'react';
import { connect } from 'react-redux';
import { ApolloCache, useApolloClient } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import compose from 'lodash/flowRight';
// actions
import { hideModal } from 'Actions/ModalAction';
import AccountWizard, { STEPS, withProps } from 'Components/Modal/Wizard/Base/Account';
import { universalAnalyticsIdPrefix } from 'Constants';
import { ConnectToGa_DomainDocument, RemoveGoogleAnalyticsAccountDocument } from 'Ghql';
import toast from 'Hooks/useToast';
import { IntegrationOAuthProviders } from 'Types/Integration';
import { t } from 'Utilities/i18n';
import { graphqlOK } from 'Utilities/underdash';
import { StepTo } from '..';
import ConnectOAuthAccount from '../Common/ConnectOAuthAccount';
import SaveGoogleAccount from '../Common/GoogleAccounts/SaveGoogleAccount';
// steps
import SelectGoogleAccount from '../Common/GoogleAccounts/SelectGoogleAccount';
import Account from './Account';
import AddGAAccount from './AddGAAccount';
import { Group } from './Group';
import Profile from './Profile';
import { Property } from './Property';
import { DomainPropertyPairs, PropertyBulkImport } from './PropertyBulkImport';

type OptionalGAProps =
  | {
      domainId: string;
      bulkConnect?: never;
    }
  | {
      domainId?: never;
      bulkConnect: true;
    };

type ConnectToGAProps = {
  refresh?: (...args: Array<any>) => any;
  oAuthResult?: Record<string, any>;
  isAdding?: boolean;
  // automatic
  domainData: Record<string, any>;
  hideModal: (...args: Array<any>) => any;
  removeAccount: (...args: Array<any>) => any;
  apolloCache: ApolloCache<any>;
  skipToStep?: string;
  skippedData?: Record<string, any>;
} & OptionalGAProps;

export const ACCOUNT = 'selectAccountStep' as const;
export const PROPERTY = 'selectPropertyStep' as const;
const GROUP = 'selectGroupStep' as const;
const PROFILE = 'selectProfileStep' as const;
const ADD_GA = 'addGAAccount' as const;

type AddGaComponentProps = {
  data: {
    selectStep: {
      connectionId: string;
    };
    [ACCOUNT]: {
      accountIds: string[];
    };
    [GROUP]: { groupId: string };
    // Setting profileId to null when we are not importing the old UA Properies
    [PROFILE]: { profileId: string | null };
    [PROPERTY]:
      | { domainPropertyPairs: DomainPropertyPairs }
      | { propertyId?: string; accountId: string };
  };
  accountIds: string[];
  propertyId: string | undefined;
  accountId: string | undefined;
  domainPropertyPairs: DomainPropertyPairs | undefined;
  profileId: string | null;
};

const WithApolloCache = (Wrapped: React.ComponentType<ConnectToGAProps>) => {
  const apolloCache = (props: ConnectToGAProps) => {
    const cache = useApolloClient().cache;
    return <Wrapped {...props} apolloCache={cache} />;
  };
  apolloCache.displayName = 'apolloCache';

  return apolloCache;
};

/**
 * Return a function that conditionally navigates to the next step based on the input propertyId.
 *
 * If the propertyId starts with 'UA' (Universal Analytics properties), the function will navigate to the PROFILE step.
 *
 * Otherwise (Google Analytics 4 properties), the function will add placeholder data for the skipped PROFILE step, and navigate to the ADD_GA step.
 */
function fromPropertyStepToConditionally(
  stepTo: StepTo,
): (arg: { propertyId: string } | { domainPropertyPairs: DomainPropertyPairs }) => void {
  return (props) => {
    const propertyId = 'propertyId' in props ? props?.propertyId : undefined;
    if (propertyId?.startsWith(universalAnalyticsIdPrefix)) {
      stepTo(PROFILE, props);
      return;
    }

    stepTo(ADD_GA, {
      ...props,
      skippedStep: { [PROFILE]: { profileId: null } },
    });
  };
}

class ConnectToGA extends Component<ConnectToGAProps> {
  handleAddGAAccount = () => {
    this.props.hideModal();
    this.props.apolloCache.reset();
  };
  handleRemoveAccount = () => {
    const { domainId } = this.props;
    this.props
      .removeAccount({
        variables: {
          input: {
            domainId,
          },
        },
      })
      .then(
        () => {
          toast.success(t('Account removed'));
          this.props.hideModal();
          this.props.apolloCache.reset();
        },
        () => {
          toast.error(t('Failed to remove account'));
        },
      );
  };
  getConnectionId = (data: { [key: string]: any }) => {
    const source = data[STEPS.SELECT] || data[STEPS.SAVE];
    return source ? source.connectionId : null;
  };
  getGASteps = () => [
    {
      name: ACCOUNT,
      title: t('Select Analytics Account'),
      component: ({
        stepTo,
        data,
      }: {
        stepTo: StepTo;
        data: { selectSteps: { connectionId } };
      }) => {
        return (
          <Account
            connectionId={this.getConnectionId(data)}
            onSubmit={
              this.props.bulkConnect ? withProps(stepTo, GROUP) : withProps(stepTo, PROPERTY)
            }
            onBack={() => stepTo(STEPS.SELECT)}
          />
        );
      },
    },
    // Bulk connect requires an additional step to select a domainGroup.
    {
      name: GROUP,
      title: t('Select Group'),
      component: ({ stepTo }: { stepTo: StepTo; data: { selectSteps: { connectionId } } }) => {
        return <Group onSubmit={withProps(stepTo, PROPERTY)} onBack={() => stepTo(ACCOUNT)} />;
      },
    },
    {
      name: PROPERTY,
      title: this.props.bulkConnect
        ? t('Select Analytics Property for Each Domain')
        : t('Select Analytics Property'),
      component: ({
        stepTo,
        data,
        accountIds = data?.[ACCOUNT]?.accountIds,
        groupId = data?.[GROUP]?.groupId,
      }) => (
        <>
          {this.props.bulkConnect ? (
            <PropertyBulkImport
              connectionId={this.getConnectionId(data) || ''}
              accountIds={accountIds}
              onSubmit={fromPropertyStepToConditionally(stepTo)}
              onBack={() => stepTo(GROUP)}
              groupId={groupId as string}
            />
          ) : (
            <Property
              connectionId={this.getConnectionId(data) || ''}
              accountIds={accountIds}
              onSubmit={fromPropertyStepToConditionally(stepTo)}
              onBack={() => stepTo(ACCOUNT)}
            />
          )}
        </>
      ),
    },
    {
      name: PROFILE,
      title: t('Select Analytics Profile'),
      component: ({
        stepTo,
        data,
        data: {
          [ACCOUNT]: { accountId },
          [PROPERTY]: { propertyId },
        },
      }) => (
        <Profile
          connectionId={this.getConnectionId(data) || ''}
          accountId={accountId}
          propertyId={propertyId}
          onSubmit={withProps(stepTo, ADD_GA)}
          onBack={() => stepTo(PROPERTY)}
        />
      ),
    },
    {
      name: ADD_GA,
      title: t('Add Google Analytics Account'),
      component: ({
        data,
        propertyId = 'propertyId' in data[PROPERTY] ? data[PROPERTY].propertyId : undefined,
        accountId = 'accountId' in data[PROPERTY] ? data[PROPERTY].accountId : undefined,
        domainPropertyPairs = 'domainPropertyPairs' in data[PROPERTY]
          ? data[PROPERTY].domainPropertyPairs
          : undefined,
        profileId = data[PROFILE].profileId,
      }: AddGaComponentProps) => {
        return (
          <AddGAAccount
            domainId={this.props.domainId}
            connectionId={this.getConnectionId(data) || ''}
            accountId={accountId}
            propertyId={propertyId}
            domainPropertyPairs={domainPropertyPairs}
            profileId={profileId ?? undefined}
            onSubmit={this.handleAddGAAccount}
            onCancel={this.props.hideModal}
          />
        );
      },
    },
  ];

  render() {
    if (!graphqlOK(this.props, ['oAuthResult'], ['oAuthResult'])) {
      return null;
    }

    const { domainId, oAuthResult, isAdding, domainData } = this.props;
    const account =
      domainData && domainData.domain ? domainData.domain.googleOauthConnectionGa : null;
    const noOAuthStep = !isAdding ? STEPS.SELECT : STEPS.CONNECT;
    return (
      <AccountWizard
        className="connect-to-ga"
        step={this.props.skipToStep ?? (!oAuthResult ? noOAuthStep : STEPS.SAVE)}
        skippedData={this.props.skippedData}
        selectStep={{
          title: t('Select Google Connection'),
          component: ({ stepTo }) => (
            <SelectGoogleAccount
              accountId={account && account.id}
              onAdd={() => stepTo(STEPS.CONNECT)}
              onRemove={this.handleRemoveAccount}
              handleSubmit={withProps(stepTo, ACCOUNT)}
              onCancel={this.props.hideModal}
            />
          ),
        }}
        connectStep={{
          title: t('Add Google Connection'),
          component: () => (
            <ConnectOAuthAccount
              modalParams={{
                modalType: 'ConnectToGA',
                modalTheme: 'light',
                modalProps: {
                  domainId,
                  integration: IntegrationOAuthProviders.GOOGLE_ACCOUNT,
                  isAdding,
                },
              }}
              onCancel={this.props.hideModal}
            />
          ),
        }}
        saveStep={{
          title: t('Add Google Connection'),
          component: ({ stepTo }) => (
            <SaveGoogleAccount
              onSubmit={!isAdding ? withProps(stepTo, ACCOUNT) : this.handleAddGAAccount}
              onCancel={withProps(stepTo, STEPS.CONNECT)}
              oAuthResult={oAuthResult}
            />
          ),
        }}
        postSteps={this.getGASteps() as any[]}
      />
    );
  }
}

export default compose(
  connect(null, {
    hideModal,
  }),
  graphql(RemoveGoogleAnalyticsAccountDocument, {
    name: 'removeAccount',
  }),
  graphql(ConnectToGa_DomainDocument, {
    name: 'domainData',
    skip: (props) => !props.domainId,
    options: (props: any) => ({
      variables: {
        id: props.domainId,
      },
    }),
  }),
)(WithApolloCache(ConnectToGA));
