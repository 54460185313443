import { Flex } from '@mantine/core';
import { DataTable } from 'Components/DataTable';
import { SearchVolumeDetailsRow } from 'Components/DataTable/components/body/BodyRow/SearchVolumeDetailsRow';
import AccTitle from 'Components/Title/AccTitle';
import { t } from 'Utilities/i18n';
import { CompetitorsRanksActions } from './components/CompetitorsRanksActions';
import {
  COMPETITORS_RANKS_TABLE_ID,
  DEFAULT_COMPETITORS_RANKS_ORDERING,
} from './support/constants';
import { useCompetitorsRanks, useCompetitorsRanksTableInfo } from './support/hooks';
import reusableStyles from '../../../../../css/reusable-styles.module.scss';

export const CompetitorsRanksTable = () => {
  const { fetchData, competitors } = useCompetitorsRanks();
  const { columns } = useCompetitorsRanksTableInfo(competitors);

  return (
    <div>
      <Flex mb="xs" justify={'space-between'}>
        <AccTitle type="h3">{t('Competitor Ranks')}</AccTitle>
        <CompetitorsRanksActions />
      </Flex>
      <DataTable
        className={reusableStyles.paper}
        tableId={COMPETITORS_RANKS_TABLE_ID}
        columns={columns}
        fetchData={fetchData}
        defaultOrdering={DEFAULT_COMPETITORS_RANKS_ORDERING}
        customExpandComponent={SearchVolumeDetailsRow}
        unmountStore
      />
    </div>
  );
};
