import { Box, Group } from '@mantine/core';
import { useTimeout } from '@mantine/hooks';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { SEARCH_ENGINE_GOOGLE } from 'Components/AccuTable/CellRenderer/HelperComponents/CellLoaders/withKeywordCellLoader';
import { DomainFavicon } from 'Components/DomainFavicon';
import FormatNumber from 'Components/FormatNumber/new';
import Skeleton from 'Components/Skeleton';
import AccText from 'Components/Text/AccText';
import { useTop10DomainsLazyQuery } from 'Ghql';
import { useDomainId } from 'Hooks/data/domain/useQueryDomainInfo';
import { t } from 'Utilities/i18n';
import styles from './top10Domains.module.scss';

const Top10DomainsLazy = ({
  rankId,
  searchDate,
  keywordId,
  ownRank,
  searchEngineId,
}: {
  rankId: string;
  searchDate: string;
  keywordId: string;
  ownRank?: number;
  searchEngineId: number;
}) => {
  const domainId = useDomainId();

  const showCtr = searchEngineId === SEARCH_ENGINE_GOOGLE;

  const variables = {
    keywordrankId: parseInt(rankId, 10),
    searchDate,
    keywordId: parseInt(keywordId, 10),
    domainId: parseInt(domainId || '', 10) || undefined,
  };

  const [fetchData, { loading, data: top10DomainsData }] = useTop10DomainsLazyQuery({
    variables,
  });

  useTimeout(
    // Note this way of fetching with lazy query and use timeout is a workaround to avoid error of "Rendered more hooks than during the previous render"
    // Should be refactored once AccLazyPopver works better..
    () => {
      if (!top10DomainsData && searchDate) {
        // Fetch title or description if we do not already have it
        fetchData();
      }
    },
    0,
    { autoInvoke: true },
  );

  return (
    <Box w={240}>
      {loading || !top10DomainsData ? (
        <Box p={8}>
          <Skeleton
            linesConfig={Array(11).fill({
              type: 'text',
              options: {
                width: '100%',
                height: '13.5px',
              },
            })}
          />
        </Box>
      ) : (
        <table className={styles.fullWidth}>
          <thead className={styles.tableHead}>
            <tr>
              <th className={styles.rankColumn}>
                <AccText size={'12px'} fw={600} c="black">
                  #
                </AccText>
              </th>
              <th>
                <AccText size="12px" fw={600} c="black">
                  {t('Domain')}
                </AccText>
              </th>
              {showCtr ? (
                <th>
                  <AccText size="12px" fw={600} c="black">
                    {t('CTR')}
                  </AccText>
                </th>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {top10DomainsData?.top10Domains?.map((unknowncompetitor, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <tr key={`${searchDate}-${index}-ctr`}>
                <td className={styles.rankColumn}>
                  <AccText size="sm" fw={'normal'} c={index + 1 === ownRank ? 'orange' : 'black'}>
                    {index + 1}
                  </AccText>
                </td>
                <td className={styles.domainColumn}>
                  <AccTooltip tooltip={unknowncompetitor.url || undefined}>
                    <Group gap={'xs'} maw={200} wrap={'nowrap'}>
                      <DomainFavicon domain={unknowncompetitor.domain} />
                      <AccText
                        size="sm"
                        fw={'normal'}
                        c={index + 1 === ownRank ? 'orange' : 'blue'}
                        ta="start"
                        href={unknowncompetitor.url || undefined}
                        target="_blank"
                        overflowEllipsis
                        noOverflowTooltip
                        nowrap
                        truncate={true}
                        w={135}
                        className={styles.linkStyle}
                      >
                        {unknowncompetitor.domain}
                      </AccText>
                    </Group>
                  </AccTooltip>
                </td>
                {showCtr ? (
                  <td className={styles.ctrColumn}>
                    <AccText
                      size="sm"
                      fw={'normal'}
                      c={index + 1 === ownRank ? 'orange' : 'black'}
                      ta="end"
                    >
                      <FormatNumber
                        value={unknowncompetitor.ctr}
                        style={'percent'}
                        minimumFractionDigits={1}
                        maximumFractionDigits={1}
                      />
                    </AccText>
                  </td>
                ) : null}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </Box>
  );
};

export default Top10DomainsLazy;
