import { Box, Group, Stack } from '@mantine/core';
import { CountByCountryNode } from 'Ghql';
import { CompetitorsBubbleChart } from 'Pages/Keywords/Overview/components/CompetitorsBubbleChart';
import { TableIDs } from 'Types/Table';
import KeyDisKpiBar from '../../components/KpiBar';
import { ImportKeywordsCard } from './ImportKeywordsCard';
import { QuickActionsCard } from './QuickActionsCard';

interface ResearchPanelProps {
  disabledKeywordImport: boolean;
  numberOfSelectedKeywords: number;
  numberOfavailableKeywords: number;
  selectedCountry: CountByCountryNode | undefined;
  /**Selected country value with fallback */
  countryChoice: string | undefined;
  domainIsSelected: boolean;
  tableType: typeof TableIDs.KEYWORD_DISCOVERY | typeof TableIDs.RESEARCH;
  keywordsCardDomainSlot: JSX.Element | undefined;
  setDomain?: (
    event: Highcharts.SeriesLegendItemClickEventObject,
    domain: string | undefined,
  ) => void;
}

export const ResearchPanel = ({
  disabledKeywordImport,
  numberOfSelectedKeywords,
  numberOfavailableKeywords,
  countryChoice,
  selectedCountry,
  domainIsSelected,
  tableType,
  keywordsCardDomainSlot,
  setDomain,
}: ResearchPanelProps) => (
  <Stack gap="lg" py={24}>
    <KeyDisKpiBar countryChoice={countryChoice} usePlaceholderData={!domainIsSelected} />
    <QuickActionsCard countryChoice={countryChoice} domainIsSelected={domainIsSelected} />
    <Group gap="lg" wrap="nowrap">
      <ImportKeywordsCard
        availableKeywords={numberOfavailableKeywords}
        selectedKeywords={numberOfSelectedKeywords}
        disabled={disabledKeywordImport}
        selectedCountry={selectedCountry}
        usePlaceholderData={!domainIsSelected}
        tableType={tableType}
        domainSlot={keywordsCardDomainSlot}
      />
      <Box mih={320} miw={0} style={{ flexGrow: 3 }}>
        <CompetitorsBubbleChart
          isKeydis={true}
          country={countryChoice}
          setDomain={setDomain}
          changeDomainFromChart={true}
        />
      </Box>
    </Group>
  </Stack>
);
