import { encodeFilters } from 'Components/Filters/serialization';
import { FilterBase } from 'Types/Filter';
import { filterSetViews } from 'Types/FilterSet';
import { RouteMatch } from 'Utilities/Router/hooks/useRouteMatch';

const filterablePath = [
  '/',
  '/domains',
  '/groups',
  '/research',
  '/domains/paused',
  '/keywords/list',
  '/keywords/keyword_discovery',
  '/affiliate/overview',
  '/affiliate',
  '/sales/customers',
];

const getFilterGroupConfig = (filterSet: string) => {
  return filterSetViews[filterSet];
};

export const getIsFilterActive = (filterSet: string, filterAttribute: string) => {
  const filter = getFilterGroupConfig(filterSet);

  return Object.keys(filter || {})?.includes(filterAttribute) ?? false;
};

// Substitute path '/domains/:filter' for '/domains'.
// (To allow adding a filter to a path with no existing filter)
const substituteRoute = (path: string): string => {
  if (filterablePath.includes(path)) {
    return `${path}/:filter`;
  }
  return path;
};

export const setFilteredPath = (
  filters: Array<FilterBase>,
  filterGroupId: string,
  match: RouteMatch,
  history: any,
  scrollToTop?: boolean,
) => {
  const filterHash = encodeFilters(filters, filterGroupId);
  const { path, params } = match;

  const newParams = {
    ...(params as object),
    ...{
      filter: filterHash,
    },
  };

  const newPath = substituteRoute(path)
    .replace(
      /(:([\w\d]+)\??)(?:$|\/)/g,
      (_, matchToReplace, propertyMatch) => `${newParams[propertyMatch] || matchToReplace}/`,
    )
    .replace(':filter?/', '');
  history.push(newPath, { preventScrollReset: !scrollToTop });
};
