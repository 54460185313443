import { Group } from '@mantine/core';
import { DomainFavicon } from 'Components/DomainFavicon';
import AccText from 'Components/Text/AccText';

type Props = {
  label: string;
  domain?: string;
};

export const DomainItemComponent = ({ label, domain }: Props) => {
  return (
    <Group wrap="nowrap" gap={6}>
      {domain && <DomainFavicon domain={domain} />}
      <AccText size="12px" fw={600} overflowEllipsis>
        {label}
      </AccText>
      {domain && domain !== label && (
        <AccText size="12px" fw={600} overflowEllipsis dir="rtl" c="gray.5">
          {domain}
        </AccText>
      )}
    </Group>
  );
};
