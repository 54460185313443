import { useMemo } from 'react';
import { ApolloError } from '@apollo/client';
import { Flex } from '@mantine/core';
import cn from 'classnames';
import AccButton from 'Components/AccButton/AccButton';
import { Field, Form } from 'Components/Fields';
import FieldGroupAccess from 'Components/Fields/Field/FieldGroupAccess';
import FieldUsers from 'Components/Fields/Field/FieldUsers';
import { FormErrors } from 'Components/Forms/FormErrors';
import HelpTextPopover from 'Components/HelpTextPopover/HelpTextPopover';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import AddEditSkeleton from 'Components/Skeleton/Types/AddEdit';
import AccText from 'Components/Text/AccText';
import { t } from 'Utilities/i18n/index';
import Validator from 'Utilities/validation';
import formStyles from 'css/layout/form-layout.module.scss';

export type WorkspaceInitialValues = {
  name?: string | null;
  description?: string | null;
  clients?: string[] | null;
  users?: string[] | null;
};

type Props = {
  onClose: (...args: Array<any>) => any;
  initialValues?: WorkspaceInitialValues;
  handleSubmit: (...args: Array<any>) => any;
  type?: 'add' | 'edit';
  loading?: boolean;
  error?: ApolloError;
  hasAllGroups?: boolean;
};

const AddEditWorkspaceForm = (props: Props) => {
  const { type, onClose, handleSubmit, loading, error, hasAllGroups, initialValues } = props;
  const clients = initialValues?.clients?.map(String);
  const users = initialValues?.users?.map(String);

  const values = useMemo(
    () => ({
      name: initialValues?.name,
      description: initialValues?.description,
      clients,
      users,
    }),
    [initialValues?.description, initialValues?.name, clients?.toString(), users?.toString()],
  );

  if (loading) {
    return <AddEditSkeleton />;
  } else if (error) {
    return <AccText>{t('Error loading workspace data. Try again later')}</AccText>;
  }

  return (
    <>
      {type === 'add' && <p>{t('Instruction how to add new workspace')}</p>}
      <Form onSubmit={handleSubmit} initialValues={values}>
        {({ invalid, submitting, submitErrors }) => {
          return (
            <>
              <Flex direction="column" rowGap="lg">
                <FormErrors errors={submitErrors} />
                <Field.TextInput
                  name="name"
                  label={t('Name')}
                  placeholder={t('Enter workspace name')}
                  validate={Validator.required}
                  autoFocus={true}
                  disabled={hasAllGroups}
                  required
                />
                <Field.TextInput
                  name="description"
                  label={t('Description')}
                  placeholder={t('Workspace description')}
                  disabled={hasAllGroups}
                />
                <div className={cn(formStyles.formRow, formStyles.formRowNoMargin)}>
                  <div className="form-label">
                    {t('Group Access')}
                    <HelpTextPopover
                      text={t('Select one or more Groups to grant access to this workspace.')}
                    />
                  </div>
                  <FieldGroupAccess
                    name="clients"
                    hasAllGroups={hasAllGroups}
                    defaultValue={clients}
                    maxDisplayedValues={3}
                  />
                </div>
                <div className={cn(formStyles.formRow, formStyles.formRowNoMargin)}>
                  <div className="form-label">{t('Users')}</div>
                  <FieldUsers
                    name="users"
                    defaultValue={users}
                    maxDisplayedValues={20}
                    isAllGroupsWorkspace={!!hasAllGroups}
                  />
                </div>
              </Flex>
              <ModalFooter
                primaryButtonSlot={
                  <AccButton disabled={invalid || submitting} type="submit" variant="primary">
                    {type === 'add' ? t('Add Workspace') : t('Save')}
                  </AccButton>
                }
                secondaryButtonSlot={
                  <AccButton variant="tertiary" onClick={onClose}>
                    {t('Cancel')}
                  </AccButton>
                }
              />
            </>
          );
        }}
      </Form>
    </>
  );
};

AddEditWorkspaceForm.defaultProps = {
  initialValues: {
    name: null,
    description: null,
    clients: [],
    users: [],
  },
  type: 'add',
};

export default AddEditWorkspaceForm;
