import { useMemo } from 'react';
import { useElementSize } from '@mantine/hooks';

const CARD_BREAKPOINT = 492;
const DASHBOARD_GAP_SIZE = 24;
const GRID_ROWS = 3;

const calculatePaginationPageSize = (totalWidth: number): number => {
  if (!totalWidth) {
    return 0;
  }
  return Math.max(
    2 * GRID_ROWS,
    Math.floor((totalWidth + DASHBOARD_GAP_SIZE) / (CARD_BREAKPOINT + DASHBOARD_GAP_SIZE)) *
      GRID_ROWS,
  );
};

export const usePaginationSize = () => {
  const { ref, width: dashboardWidth } = useElementSize<HTMLDivElement>();

  const paginationPageSize = useMemo(
    () => calculatePaginationPageSize(dashboardWidth),
    [dashboardWidth],
  );

  return { paginationPageSize, ref };
};
