import { useMemo } from 'react';
import { renderToString } from 'react-dom/server';
import { useIntl } from 'react-intl';
import { MantineProvider } from '@mantine/core';
import { AxisLabelsFormatterContextObject } from 'highcharts';
import SerpIcon from 'Components/AccuTable/CellRenderer/HelperComponents/SerpPopOver/serpIcon';
import { ChartButtonTheme } from 'Components/Chart/support/buttonStyleConfig';
import { DEFAULT_CHART_CONFIG } from 'Components/Chart/support/constants';
import { updateLoader } from 'Components/HighchartsLoader';
import { getSerpFeatureData } from 'Components/Table/TableRow/SERPOptions/data';
import { MetaDataSerpFeatureNode } from 'Ghql';
import { t, tn } from 'Utilities/i18n';
import { mantineTheme } from 'css/mantine/theme';

export type SerpStats = { owned?: number; notOwned?: number };
export type SerpData = Record<string, SerpStats>;

export type ChartCategory = { label: string; serp: MetaDataSerpFeatureNode; stats: SerpStats };

export type ChartConfigProps = {
  data: Record<string, any> | undefined;
  keywordCount: number;
  domainId: string | undefined;
  height: number;
  onClick: (domainId: string, category: ChartCategory, name: string) => void;
  history?: Record<string, any>;
  animate?: boolean;
  language?: string;
  loading?: boolean;
  period?: number;
  watermark?: boolean;
  watermarkBig?: boolean;
  watermarkSmall?: boolean;
};

export const getSerpIconLabel = (context: AxisLabelsFormatterContextObject): string => {
  const htmlLabel = renderToString(
    <MantineProvider theme={mantineTheme} defaultColorScheme="light">
      <SerpIcon
        id={(context as any).value.serp.id}
        owned={false}
        disableInteraction={true}
        size={16}
        className="chart-icon"
      />
    </MantineProvider>,
  );
  return htmlLabel;
};

const renderLabel = (count: number, serpLabel: string, keywordCount: number) => {
  return (
    <>
      <strong>{serpLabel}</strong>
      <br />
      {tn(
        '%s keyword with %s (%s total keywords)',
        '%s keywords with %s (%s total keywords)',
        count,
        serpLabel,
        keywordCount,
      )}
      <br />
      <i>{t('Click to view historic data.')}</i>
    </>
  );
};

const countAll = (stats: SerpStats) => (stats.owned || 0) + (stats.notOwned || 0);
const countOwned = (stats: SerpStats) => stats.owned || 0;
const countNotOwned = (stats: SerpStats) => stats.notOwned || 0;

const generateData = (data: SerpData | undefined, keywordCount: number) => {
  if (!data) return [];
  return (
    Object.entries(data)
      .map(([feature, stats]) => ({ feature, stats, count: countAll(stats) }))
      // filter those who we do not have values for
      .filter(({ count }) => typeof count === 'number' && count > 0)
      .filter(({ feature }) => getSerpFeatureData(feature).owned)
      // map to output format
      .map(({ feature, stats, count }) => {
        const serpFeatureData = getSerpFeatureData(feature);
        const htmlLabel = renderToString(
          <>{renderLabel(count, serpFeatureData.label || '', keywordCount)}</>,
        );
        return {
          serp: serpFeatureData,
          label: htmlLabel,
          stats,
        };
      })
      // sort by percentage of keywords owned
      .sort((a, b) => {
        const aOwned = countOwned(a.stats) / countAll(a.stats);
        const bOwned = countOwned(b.stats) / countAll(b.stats);
        return bOwned - aOwned;
      })
  );
};

type RawData = ReturnType<typeof generateData>;

const formatData = (data: RawData) => {
  const owned = data.map((d) => countOwned(d.stats));
  const other = data.map((d) => countNotOwned(d.stats));
  return [
    {
      name: t('Not owned'),
      color: 'rgb(243, 244, 247)',
      marker: {
        symbol: 'circle',
        radius: 3,
      },
      type: 'column',
      data: other,
    },
    {
      name: t('Owned'),
      color: '#f89537',
      marker: {
        symbol: 'circle',
        radius: 3,
      },
      type: 'column',
      data: owned,
    },
  ];
};

export const useChartConfig = (props: ChartConfigProps): Highcharts.Options => {
  const intl = useIntl();
  const { data, period, loading, keywordCount, domainId, onClick, animate, height } = props;

  const chart: Highcharts.ChartOptions = useMemo(
    () => ({
      height,
      marginTop: 5,
      marginRight: 5,
      //make sure there is enough margin at the bottom for icons turned 45deg
      marginBottom: 40,
      marginLeft: 75,
      colorCount: 6,
      zoomType: 'x',
      zooming: {
        resetButton: {
          position: {
            // align: 'right', // by default
            // verticalAlign: 'top', // by default
            x: 0,
            y: -5,
          },
          //style the button similar to AccButton secondary
          theme: ChartButtonTheme,
        },
      },
      events: {
        load() {
          updateLoader(this, period, loading);
        },
      },
    }),
    [period, loading, height],
  );

  const generatedData = generateData(data, keywordCount);
  const series = formatData(generatedData) as Highcharts.SeriesOptionsType[];

  const categories = generatedData
    ? (generatedData as unknown as Highcharts.XAxisOptions['categories'])
    : [];

  const plotOptions: Highcharts.PlotOptions = {
    line: {
      pointPlacement: 'between',
    },
    area: {
      stacking: 'percent',
    },
    column: {
      stacking: 'percent',
    },
    series: {
      animation: animate,
      cursor: 'pointer',
      point: {
        events: {
          click: (evt) => {
            const {
              series: { name },
              category,
            } = evt.point;

            if (domainId == null) {
              return;
            }

            onClick(domainId, category as unknown as ChartCategory, name);
          },
        },
      },
    },
  };

  const yAxis: Highcharts.YAxisOptions | Highcharts.YAxisOptions[] = [
    {
      title: {
        text: t('% owned'),
      },
      min: 0,
      tickInterval: 5,
      top: '0%',
      labels: {
        style: {
          whiteSpace: 'nowrap',
          textOverflow: 'none',
        },
      },
    },
  ];

  const xAxis: Highcharts.XAxisOptions = {
    categories,
    labels: {
      formatter: getSerpIconLabel,
      useHTML: true,
    },
  };

  const tooltip: Highcharts.TooltipOptions = {
    shared: true,
    shadow: false,
    backgroundColor: '#FFFFFF',
    borderColor: '#E0E0E7',
    borderRadius: 0,
    borderWidth: 1,
    padding: 0,
    useHTML: true,
    headerFormat:
      '<div class="chart-tooltip-table"><div class="chart-tooltip-table-tr"><div class="chart-tooltip-table-th">{point.key.label}</div></div>',

    pointFormatter() {
      return `<div class="chart-tooltip-table-tr">
                <div class="chart-tooltip-table-td">
                    <span class="chart-tooltip-bullet" style="color: ${this.color};">●</span> ${
        this.series.name
      }
                </div>
                <div class="chart-tooltip-table-td chart-tooltip-table-right" style="text-align: right;">
                    ${intl.formatNumber((this.percentage || 0) / 100, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                      style: 'percent',
                    })} (${intl.formatNumber((this as any).y)})
                </div>
            </div>`;
    },

    footerFormat: '</div>',
    xDateFormat: '%b %e, %Y',
    hideDelay: 5,
  };

  return {
    chart,
    plotOptions,
    series,
    xAxis,
    yAxis,
    tooltip,
    legend: {
      enabled: false,
    },
    lang: {
      noData: t('No SERP feature data for the selected filters'),
    },
    ...DEFAULT_CHART_CONFIG,
  };
};
