import { useNavigate, useParams } from 'react-router';
import { Box } from '@mantine/core';
import AccButton from 'Components/AccButton/AccButton';
import Skeleton from 'Components/Skeleton';
import AccTitle from 'Components/Title/AccTitle';
import { useInvoicesTableQuery } from 'Ghql';
import { t } from 'Utilities/i18n';
import { InvoicesTable } from './InvoicesTable';
import styles from './invoices.module.scss';

const Invoices = () => {
  const params = useParams<{ id: string }>();

  const navigate = useNavigate();

  const { data, error, loading } = useInvoicesTableQuery({
    variables: { id: params.id || '' },
  });

  const { name } = data?.organization || {};

  return (
    <Box className={styles.container}>
      <AccTitle mb="xl" type="h2">{`${t('Invoices for')} ${name}`}</AccTitle>

      {error || loading ? (
        <Skeleton
          linesConfig={[
            {
              type: 'title',
              options: {
                width: '30%',
                marginBottom: '10px',
              },
            },
          ]}
        />
      ) : (
        <InvoicesTable organizationId={params.id} />
      )}

      <AccButton variant="secondary" mt="xl" onClick={() => navigate(-1)}>
        {t('Back')}
      </AccButton>
    </Box>
  );
};

export default Invoices;
