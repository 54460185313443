import moment from 'moment';
import Logo from 'icons/logo-brand.svg';
import styles from './footerEmpty.module.scss';

const FooterEmpty = () => (
  <div className={styles.footerWrapper}>
    <footer>
      <div className={styles.logoSection}>
        <img className={styles.logo} src={Logo} />
        <small>{`2013 - ${moment().year()} © AccuRanker. All Rights Reserved. `}</small>
      </div>
    </footer>
  </div>
);

export default FooterEmpty;
