import moment from 'moment';
import { NotesType } from 'Components/Chart/LineChart';
import { useHistoryChartsQuery } from 'Ghql';
import { useFilters } from 'Hooks';
import { getAverageRankSeries } from 'Pages/Keywords/Overview/components/HistoryCharts/AverageRank/helpers';
import { useFakeData } from 'Pages/Keywords/Overview/hooks/useFakeData';
import { useGroupViewInfo } from 'Pages/Keywords/Overview/hooks/useGroupViewInfo';
import { useSmartQuery } from 'Utilities/Graphql/hooks/useSmartQuery';
import { WithDragDetails } from '../../../support/types';
import { useAddKeywordsModal } from '../support/util';
import { AverageRankChartContent } from './AverageRankChartContent';

type AvgRankChartProps = {
  domainId: string | undefined;
  notes?: NotesType[];
  notesLoading?: boolean;
  enabledHistoryCharts: string[];
} & WithDragDetails;

export const getHistoryChartCsvData = (chartSeries, name) => () =>
  `Date,${name},Total keywords\n${chartSeries[0].data
    .slice()
    .reverse()
    .map(
      (e) =>
        `${moment.unix(e.x / 1000).format('YYYY-MM-DD')},${
          Number.isInteger(e.y) ? e.y : e.y.toFixed(2)
        },${e.totalKeywords}`,
    )
    .join('\n')}`;

export const AverageRankChart = (props: AvgRankChartProps & WithDragDetails) => {
  const filters = useFilters();
  const { isEmptyGroup, isGroupView } = useGroupViewInfo(filters);
  const { AvgRankChartFakeData } = useFakeData();
  const { data, loading, refetch } = useSmartQuery(useHistoryChartsQuery, {
    variables: { filters, name: 'historyCharts', charts: props.enabledHistoryCharts },
    skip: isEmptyGroup,
  });
  const addKeywordsModal = useAddKeywordsModal(props.domainId, refetch);
  const chartSeries = getAverageRankSeries(data?.graphs?.overviewHistoryGraphs);

  const showFakeData = !chartSeries.length;
  return (
    <AverageRankChartContent
      chartSeries={showFakeData ? AvgRankChartFakeData : chartSeries}
      notes={props.notes}
      notesLoading={props.notesLoading}
      loading={loading}
      isGroupView={isGroupView}
      isEmptyGroup={isEmptyGroup}
      onMissingDataOverlayClick={addKeywordsModal}
      navigateToKeywordslistFromChart={true}
      showFakeData={showFakeData}
      dragRef={props.dragRef}
      dragging={props.dragging}
      boardControls={props.boardControls}
    />
  );
};
