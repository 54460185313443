import { Dispatch, SetStateAction } from 'react';
import { Box, Group, Switch, rem, useMantineTheme } from '@mantine/core';
import { IconUser, IconUserShield } from '@tabler/icons-react';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import AccText from 'Components/Text/AccText';
import { t } from 'Utilities/i18n';

export const UserSwitch = ({
  isAdmin,
  setIsAdmin,
}: {
  isAdmin: boolean;
  setIsAdmin: Dispatch<SetStateAction<boolean>>;
}) => {
  const theme = useMantineTheme();

  const clientIcon = (
    <Group align="center" gap={2} pr={8}>
      <IconUser
        style={{ width: rem(16), height: rem(16) }}
        stroke={2.5}
        color={theme.colors.gray[7]}
      />
      <AccText c={theme.colors.gray[7]} fw={600}>
        {t('Client')}
      </AccText>
    </Group>
  );

  const adminIcon = (
    <Group align="center" gap="xxs" px={4}>
      <IconUserShield style={{ width: rem(16), height: rem(16) }} stroke={2.5} color="white" />
      <AccText c="white" fw={600}>
        {t('Admin')}
      </AccText>
    </Group>
  );

  return (
    <div>
      <AccTooltip
        tooltip={
          isAdmin
            ? t('Click to view the report as a client')
            : t('Click to view and customize the report as admin')
        }
      >
        <Box h={30} ml="auto" mr={0} w="fit-content">
          <Switch
            size="lg"
            color={theme.colors.snorlax[4]}
            styles={{
              body: { width: 'fit-content' },
              track: { cursor: 'pointer' },
              root: { width: 'fit-content' },
            }}
            onLabel={adminIcon}
            offLabel={clientIcon}
            checked={isAdmin}
            onChange={(e) => setIsAdmin(e.currentTarget.checked)}
          />
        </Box>
      </AccTooltip>
    </div>
  );
};
