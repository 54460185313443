import { Component } from 'react';
import { Field } from 'redux-form';
import { DropdownField, KeywordsInputField } from 'Components/Forms/Fields';
import { FilterComparison } from 'Types/Filter';
import { t } from 'Utilities/i18n/index';
import Validation from 'Utilities/validation';

const comparisonOptions = [
  {
    id: FilterComparison.ANY,
    value: FilterComparison.ANY,
    label: 'Any',
  },
  {
    id: FilterComparison.ALL,
    value: FilterComparison.ALL,
    label: 'All',
  },
  {
    id: FilterComparison.NONE,
    value: FilterComparison.NONE,
    label: 'None',
  },
];

class KeywordsEditor extends Component<{}> {
  render() {
    return (
      <div>
        <Field
          name="value"
          component={KeywordsInputField}
          placeholder={t('Enter keywords')}
          validate={[Validation.array]}
        />
        <Field name="comparison" component={DropdownField} items={comparisonOptions} />
      </div>
    );
  }
}

export default KeywordsEditor;
