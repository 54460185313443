import { Component } from 'react';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import compose from 'lodash/flowRight';
import { Field, reduxForm } from 'redux-form';
import AccButton from 'Components/AccButton/AccButton';
import { Select } from 'Components/Forms/Fields';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import { t, tct } from 'Utilities/i18n/index';
import underdash from 'Utilities/underdash';
import Validator from 'Utilities/validation';

type Props = {
  handleSubmit: (...args: Array<any>) => any;
  adobeAccounts: Record<string, any>;
  onAdd: (...args: Array<any>) => any;
  onRemove: (...args: Array<any>) => any;
  initialValues: Record<string, any>;
  onCancel: (...args: Array<any>) => any;
  onSubmit: (...args: Array<any>) => any;
  accountId?: string;
};
type State = {};

class SelectAdobeAccount extends Component<Props, State> {
  onSubmit = ({ adobeAccount: { value } }) => {
    this.props.onSubmit({
      connectionId: value,
    });
  };

  render() {
    const loading =
      underdash.graphqlError({ ...this.props }) || underdash.graphqlLoading({ ...this.props });
    const {
      adobeAccounts: { user },
      handleSubmit,
      initialValues,
    } = this.props;
    const options = loading
      ? []
      : user.organization.adobeMarketingConnections.map(({ id, description }) => ({
          value: id,
          label: description,
        }));

    if (options.length > 0) {
      return (
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <div className="form-label">{t('Select the Adobe connection you want to use.')}</div>
          <div>
            <Field
              defaultBehaviour
              name="adobeAccount"
              id="adobeAccount"
              elementType="customSelect"
              type="text"
              placeholder={''}
              component={Select}
              loading={loading}
              options={options}
              validate={Validator.required}
              helpText={
                loading
                  ? t('Loading…')
                  : tct(
                      'Don\'t see the Adobe Account you are looking for? You can always add an additional account [link:here].',
                      {
                        link: (
                          <a
                            style={{ color: '#F89537' }}
                            onClick={this.props.onAdd}
                            className="link"
                          />
                        ),
                      },
                    )
              }
            />
          </div>

          {this.props.accountId != null && (
            <div className="alert alert-warning">
              <div className="form-label">
                {tct(
                  'This domain is already connected to [account] Adobe Account, you can connect to another account above or disconnect [link:here].',
                  {
                    account: (
                      <strong>
                        {initialValues.adobeAccount ? initialValues.adobeAccount.label : null}
                      </strong>
                    ),
                    link: <a onClick={this.props.onRemove} className="link" />,
                  },
                )}
              </div>
            </div>
          )}
          <ModalFooter
            primaryButtonSlot={
              <AccButton variant="primary" type="submit">
                {t('Select account')}
              </AccButton>
            }
            secondaryButtonSlot={
              <AccButton variant="tertiary" onClick={this.props.onCancel}>
                {t('Cancel')}
              </AccButton>
            }
          />
        </form>
      );
    }

    return (
      <div>
        <p>{t('We could not find a connected Adobe Account, please set one up first.')}</p>
        <ModalFooter
          primaryButtonSlot={
            <AccButton variant="primary" onClick={this.props.onAdd}>
              {t('Connect an Adobe account')}
            </AccButton>
          }
          secondaryButtonSlot={
            <AccButton variant="tertiary" onClick={this.props.onCancel}>
              {t('Cancel')}
            </AccButton>
          }
        />
      </div>
    );
  }
}

const adobeAccountsQuery = gql`
  query selectAdobeAccount_adobeAccounts {
    user {
      id
      organization {
        id
        adobeMarketingConnections {
          id
          description
        }
      }
    }
  }
`;
export default compose(
  graphql(adobeAccountsQuery, {
    props: ({ ownProps, data, data: { error, loading, user } }: any) => {
      if (error || loading) {
        return {
          adobeAccounts: data,
        };
      }

      const currentAccount = user.organization.adobeMarketingConnections.find(
        (connection) => ownProps.accountId === connection.id,
      );
      let adobeAccount;

      if (
        user.organization.adobeMarketingConnections.find(
          (connection) => ownProps.accountId === connection.id,
        )
      ) {
        adobeAccount = {
          label: currentAccount.description,
          value: currentAccount.id,
        };
      }

      return {
        adobeAccounts: data,
        initialValues: {
          adobeAccount,
        },
      };
    },
  }),
  reduxForm({
    form: 'SelectAdobeAccountForm',
  }),
)(SelectAdobeAccount) as any;
