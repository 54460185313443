/* eslint-disable @typescript-eslint/no-non-null-assertion */
import moment from 'moment';
import AccAvatar from 'Components/AccAvatar';
import { ColumnType, QueryOrder } from 'Components/DataTable';
import { FixedType } from 'Components/DataTable/table-core/interface';
import ActionsCell, { ActionsCellItem } from 'Components/Table/TableRow/ActionsCell';
import AccText from 'Components/Text/AccText';
import { SORTING_TYPE } from 'Constants';
import { UserFragment } from 'Ghql';
import UserRoleCell from 'Pages/Users/UsersTable/userRoleCell';
import { t } from 'Utilities/i18n';
import { ColumnIDs } from './ColumnIDs';
import WorkspaceCell from './workspacesCell';
import styles from '../usersTable.module.scss';

export const getColumns = (getActions: (record: UserFragment) => ActionsCellItem[]) => {
  const columns: ColumnType<UserFragment, {}>[] = [
    {
      id: ColumnIDs.GRAVATAR,
      title: undefined,
      width: 60,
      fixed: 'left' as FixedType,
      cellRenderer: ({ record }) => (
        <AccAvatar
          name={record.fullName || record.email || ''}
          email={record.email}
          className={styles.icoWrapper}
          size={36}
        />
      ),
    },
    {
      id: ColumnIDs.FULL_NAME,
      title: t('Name'),
      width: 220,
      cellRenderer: ({ record }) => (
        <AccText size="sm" overflowEllipsis>
          {record.fullName}
        </AccText>
      ),
      onHeaderCell: () => ({
        ordering: {
          orderBy: ColumnIDs.FULL_NAME,
          defaultOrder: QueryOrder.ASC,
          sortingKey: SORTING_TYPE.alphabetical,
        },
      }),
    },
    {
      id: ColumnIDs.EMAIL,
      title: t('Email'),
      width: 220,
      cellRenderer: ({ record }) => (
        <AccText size="sm" overflowEllipsis>
          {record.email}
        </AccText>
      ),
      onHeaderCell: () => ({
        ordering: {
          orderBy: ColumnIDs.EMAIL,
          defaultOrder: QueryOrder.ASC,
          sortingKey: SORTING_TYPE.alphabetical,
        },
      }),
    },
    {
      id: ColumnIDs.USER_ROLE,
      title: t('User role'),
      width: 200,
      cellRenderer: UserRoleCell,
      onHeaderCell: () => ({
        tooltip: t('Select one of the user roles for the user'),
        ordering: {
          orderBy: ColumnIDs.USER_ROLE,
          defaultOrder: QueryOrder.ASC,
        },
      }),
    },
    {
      id: ColumnIDs.WORKSPACE,
      title: t('Workspace'),
      flex: true,
      width: 380,
      cellRenderer: WorkspaceCell,
      onHeaderCell: () => ({
        tooltip: t('Domains are organized in groups, which are organized into Workspaces'),
        ordering: {
          orderBy: ColumnIDs.WORKSPACE,
          defaultOrder: QueryOrder.ASC,
        },
      }),
    },
    {
      id: ColumnIDs.LAST_EDITED,
      title: t('Time since edit'),
      width: 100,
      cellRenderer: ({ record }) => {
        return record?.permissionsEditedAt
          ? moment(new Date(record.permissionsEditedAt)).fromNow(true)
          : '-';
      },
      onHeaderCell: () => ({
        tooltip: t('Time since last permission change'),
        ordering: {
          orderBy: ColumnIDs.LAST_EDITED,
          defaultOrder: QueryOrder.ASC,
          sortingKey: SORTING_TYPE.recurency,
        },
      }),
    },
    {
      id: ColumnIDs.DATE_JOINED,
      title: t('Member for'),
      width: 90,
      cellRenderer: ({ record }) => {
        return record?.dateJoined ? moment(new Date(record.dateJoined)).fromNow(true) : '-';
      },
      onHeaderCell: () => ({
        ordering: {
          orderBy: ColumnIDs.DATE_JOINED,
          defaultOrder: QueryOrder.ASC,
          sortingKey: SORTING_TYPE.recurency,
        },
      }),
    },
    {
      id: ColumnIDs.ACTIONS,
      title: t('Actions'),
      width: 76,
      fixed: 'right' as FixedType,
      cellRenderer: ({ record }) => (
        <ActionsCell shouldUpdateIndicator={record} actions={getActions(record)} />
      ),
    },
  ];
  return columns;
};
