import { Link } from 'react-router-dom';
import { Box, Group, Modal, SimpleGrid } from '@mantine/core';
import AccButton from 'Components/AccButton/AccButton';
import Icon from 'Components/Icon/new';
import { t, tct } from 'Utilities/i18n';
import ExclamationIcon from 'icons/exclamation.svg?inline';

type Props = {
  condition: boolean;
};

//
// A blocking Modal to inform the user that the deal has expired (or has not started yet)
// Blocks access to the Checkout page
const GenericMessage = (type: 'dealExpired' | 'notStartedYet') => {
  const result = ({ condition }: Props) => (
    <Modal
      opened={condition}
      onClose={() => {}}
      withCloseButton={false}
      centered
      closeOnEscape={false}
      closeOnClickOutside={false}
    >
      <SimpleGrid cols={1} py={'lg'} px={'md'}>
        <Group>
          <Icon width={28} height={28} style={{ color: 'orange' }}>
            <ExclamationIcon />
          </Icon>
          <Box>
            {type === 'dealExpired' &&
              tct(
                '[header: Deal Expired][br]Click here to return to the [link: Select Plan] page',
                {
                  header: <b />,
                  br: <br />,
                  link: <Link to={'/billing/package/select'} />,
                },
              )}
            {type === 'notStartedYet' &&
              tct(
                '[header: Deal Not Yet Open][br]Click here to return to the [link: Select Plan] page',
                {
                  header: <b />,
                  br: <br />,
                  link: <Link to={'/billing/package/select'} />,
                },
              )}
          </Box>
        </Group>
        <Box ta={'right'} mt={'lg'}>
          <AccButton variant="primary" link={'/billing/package/select'} data-autofocus>
            {t('Go Back')}
          </AccButton>
        </Box>
      </SimpleGrid>
    </Modal>
  );
  result.displayName = 'GenericMessage';

  return result;
};

export const DealExpired = GenericMessage('dealExpired');
export const DealNotStartedYet = GenericMessage('notStartedYet');
