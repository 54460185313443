import { createSelector } from 'reselect';
import { FilterBase } from 'Types/Filter';
import {
  getGlobalFilterIsVisibleInBar,
  getRelatedFiltersAttributes,
  isShownInFilterBar,
} from 'Types/FilterSet';
import { StoreType } from 'Types/Store';

const selectFilterSet = (state: StoreType) => state.filter.filterSet;

export const selectAllFilters = (state: StoreType): FilterBase[] => {
  return state?.filter?.filters;
};

export const selectFilterBarFilters = createSelector(
  selectAllFilters,
  selectFilterSet,
  (data, filterSet) => {
    const attributes = getRelatedFiltersAttributes(filterSet);
    return (
      data?.filter(
        (e) =>
          attributes?.includes(e?.attribute) &&
          getGlobalFilterIsVisibleInBar(e?.attribute, filterSet),
      ) ?? []
    );
  },
);

export const selectHasActiveVisibleFilters = createSelector(
  selectAllFilters,
  selectFilterSet,
  (data, filterSet) => data?.filter((e) => isShownInFilterBar(e?.attribute, filterSet))?.length > 0,
);

export const selectCurrentFilters = (state: StoreType): FilterBase[] =>
  state?.filter?.filterGroup?.filters ?? [];
