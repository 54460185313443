const SLIM_LOADING_START = 'slim_loading_start';
const SLIM_LOADING_FINISH = 'slim_loading_finish';

export function startSlimLoading() {
  return {
    type: SLIM_LOADING_START,
  };
}

export function finishSlimLoading() {
  return {
    type: SLIM_LOADING_FINISH,
  };
}
