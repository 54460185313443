// If you get the urge to get rid of this dependency and use something built in for converting to base64, be sure to test a lot of old links. Note that this library uses some compression as well, so just converting to base64 is not enough..
import { compress, decompress } from 'lzutf8';
import { FilterValueType, parseValue } from 'Types/Filter';
import type { FilterBase } from 'Types/Filter';
import { decodeBase64, isValidJSON } from 'Utilities/underdash';

const compressIntListOffset = (uncompressedList: number[]): number[] => {
  const uncompressedListSorted = uncompressedList.slice().sort((a, b) => a - b);
  return uncompressedListSorted.map((el, i, arr) => el - (arr[i - 1] || 0));
};

const decompressIntListOffset = (compressedList: number[]): string[] => {
  const decompressedList = [compressedList[0]];
  // eslint-disable-next-line no-return-assign
  compressedList.forEach((el, i) => (decompressedList[i] = el + (decompressedList[i - 1] || 0)));
  return decompressedList.map((el) => el.toString());
};
function compressDomainFilter(filtersWithStringValues) {
  const filterIndexDomains: number = filtersWithStringValues?.filters.findIndex(
    (obj) => obj.attribute === 'domains',
  );
  if (filtersWithStringValues?.filters[filterIndexDomains]) {
    filtersWithStringValues.filters[filterIndexDomains].value = JSON.stringify(
      compressIntListOffset(
        JSON.parse(filtersWithStringValues?.filters[filterIndexDomains].value).map(Number),
      ),
    );
  }
}
const compressEncodeFiltersForUrl = (uncompressedFilters: string): string => {
  let filters: object;

  try {
    filters = JSON.parse(uncompressedFilters);
  } catch (error) {
    console.error(`Error parsing uncompressedFilters: ${error}`);
    return '';
  }

  // Compress the list of Domain Ids using offset compression
  compressDomainFilter(filters);

  const filtersString = JSON.stringify(filters);
  let compressedFilters = compress(filtersString, { outputEncoding: 'Base64' });
  // Replace characters from Base64 that conflict with generic URL handling
  compressedFilters = compressedFilters.replace(/\+/g, '-').replace(/\//g, '_');

  return compressedFilters;
};

function decompressDomainFilter(decompressedUrl) {
  const filterIndexDomains: number = decompressedUrl?.filters.findIndex(
    (obj) => obj.attribute === 'domains',
  );
  if (
    decompressedUrl?.filters[filterIndexDomains] &&
    JSON.parse(decompressedUrl?.filters[filterIndexDomains].value).length > 0
  ) {
    decompressedUrl.filters[filterIndexDomains].value = JSON.stringify(
      decompressIntListOffset(
        JSON.parse(decompressedUrl?.filters[filterIndexDomains].value).map(Number),
      ),
    );
  }
}

export const decompressDecodeFiltersForUrl = (compressedUrl: string): string => {
  //Replace characters from Base64 that conflict with generic URL handling
  const compressedUrlReplaced = compressedUrl.replace(/-/g, '+').replace(/_/g, '/');
  let decompressedUrl = decompress(compressedUrlReplaced, { inputEncoding: 'Base64' });
  //Ensures backwards compatibility
  if (isValidJSON(decompressedUrl)) {
    //Decompress the list of Domains Ids using offset decompression
    decompressedUrl = JSON.parse(decompressedUrl);
    decompressDomainFilter(decompressedUrl);
    return JSON.stringify(decompressedUrl);
  }
  return decodeBase64(compressedUrl);
};

export const encodeFilters = (filters: Array<FilterBase>, segmentId: string = '') => {
  const filtersWithStringValues = filters.map((filter) => ({
    ...filter,
    value: filter.type === FilterValueType.STRING ? filter.value : JSON.stringify(filter.value),
  }));
  const filtersString = JSON.stringify({
    segmentId,
    filters: filtersWithStringValues,
  });
  return compressEncodeFiltersForUrl(filtersString);
};
export const decodeFilters = (filtersData: string) => {
  const dataObject: Record<string, any> = JSON.parse(decompressDecodeFiltersForUrl(filtersData));

  const result: { segmentId: string; filters: FilterBase[] } = {
    segmentId: '',
    filters: [],
  };
  let filtersWithStringValues: any[] = [];

  if (Array.isArray(dataObject)) {
    filtersWithStringValues = dataObject;
  } else {
    filtersWithStringValues = dataObject.filters;
    result.segmentId = dataObject.segmentId;
  }

  result.filters = filtersWithStringValues?.map((filter) => ({
    ...filter,
    value: parseValue(filter),
  }));
  return result;
};
