import { oneOfOptions } from 'Components/Filters/Common/labelFunc';
import { FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import type { ExtraRanksFilter } from 'Types/Filter';
import { t } from 'Utilities/i18n/index';
import ChevronDown from 'icons/chevronDown.svg?inline';
import ExtraRanksEditor from '.';
import { getItems } from './hooks';

const defaultValue: ExtraRanksFilter = {
  attribute: FilterAttribute.HAS_EXTRA_RANKS,
  type: FilterValueType.BOOL,
  comparison: FilterComparison.EQ,
  value: true,
};

const getData = () => {
  const items = getItems();
  return {
    defaultValue,
    title: t('Has extra ranks'),
    icon: ChevronDown,
    editor: ExtraRanksEditor,
    editorProps: {
      items,
    },
    labelFunc: oneOfOptions(items),
  };
};

export default getData;
