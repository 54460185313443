import { Flex, SimpleGrid } from '@mantine/core';
import isNumber from 'lodash/isNumber';
import AccText from 'Components/Text/AccText';
import AccTitle from 'Components/Title/AccTitle';
import { t, tct } from 'Utilities/i18n';
import SerpIcon, { SerpFeatureInfo } from './serpIcon';
import { SerpFeaturesItem } from './types';
import styles from './serp-popover.module.scss';

type Props = {
  serpFeatures: SerpFeaturesItem[];
  searchEngineId: number;
};

const SerpPopover = ({ serpFeatures, searchEngineId }: Props) => (
  <div>
    <AccTitle type="h4" mb="xs">
      {t('All SERP Features')}
    </AccTitle>

    <SimpleGrid cols={serpFeatures.length > 7 ? 2 : 1} spacing={8} verticalSpacing={0}>
      {serpFeatures?.map((serpFeature) => {
        return (
          <SerpFeatureInfo
            id={serpFeature.id}
            owned={serpFeature.isOwned}
            serpRank={serpFeature.serpRank}
            serpFeatures={serpFeatures}
            searchEngineId={searchEngineId}
            key={`popover-${serpFeature.id}`}
            withinPortal={false}
          >
            <Flex
              gap={8}
              className={styles.serpPopoverRow}
              align={isNumber(serpFeature.serpRank) ? 'top' : 'center'}
            >
              <SerpIcon
                id={serpFeature.id}
                owned={serpFeature.isOwned}
                serpRank={serpFeature.serpRank}
                serpFeatures={serpFeatures}
                disablePopover={true}
                noTooltip
              />
              <Flex direction={'column'} gap={0}>
                <AccText size="sm" fw="bold">{`${serpFeature.label} ${
                  serpFeature.isOwned ? ` ${t('(owned)')} ` : ''
                }`}</AccText>
                {isNumber(serpFeature.serpRank) && (
                  <AccText size="sm" mt={-4}>
                    {tct('Position on SERP: Before rank [serpRank]', {
                      serpRank: serpFeature.serpRank,
                    })}
                  </AccText>
                )}
                {isNumber(serpFeature.isOwned) && (
                  <AccText size="sm" mt={-4}>
                    {tct('Position in Feature: [numberInFeature]', {
                      numberInFeature: serpFeature.isOwned,
                    })}
                  </AccText>
                )}
              </Flex>
            </Flex>
          </SerpFeatureInfo>
        );
      })}
    </SimpleGrid>
  </div>
);

export default SerpPopover;
