import React, { useRef, useState } from 'react';
import { Tabs } from '@mantine/core';
import { ChartContainer } from 'Pages/Keywords/Overview/components/ChartContainer/index';
import { ShareOfVoiceCompetitors } from 'Pages/Keywords/Overview/components/ShareOfVoiceByType/components/ShareOfVoiceCompetitors';
import { ShareOfVoiceLandingPages } from 'Pages/Keywords/Overview/components/ShareOfVoiceByType/components/ShareOfVoiceLandingPages';
import { ShareOfVoiceTags } from 'Pages/Keywords/Overview/components/ShareOfVoiceByType/components/ShareOfVoiceTags';
import { t, tct } from 'Utilities/i18n';
import styles from './styles.module.scss';

const GeneralShareOfVoiceContainer = ({ isGroupView, useAi, ...props }) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const tabRefTags = useRef<HTMLDivElement>(null);
  const tabRefComps = useRef<HTMLDivElement>(null);
  const tabRefPages = useRef<HTMLDivElement>(null);
  const [activeTab, setActiveTab] = useState<string | null>('tags');

  const activeTabRef = (() => {
    switch (activeTab) {
      case 'tags':
        return tabRefTags;
      case 'competitors':
        return tabRefComps;
      case 'page':
        return tabRefPages;
      default:
        return chartRef;
    }
  })();

  return (
    <ChartContainer
      title={useAi ? t('AI Share of Voice, by') : t('Share of Voice, by')}
      tooltip={[
        tct(
          'See your [shareOfVoiceText] aggregated by: tags, competitors, ' +
            'or landing pages by clicking the corresponding tab. [br][br]' +
            'You can click the labels to toggle them on/off to get a better view of the relative size of the other tags/competitors/landing pages.[br][br]' +
            'In both the Tags and the Page tab, you can click the bars to filter the data.',
          { br: <br />, shareOfVoiceText: useAi ? t('AI Share of Voice') : t('Share of Voice') },
        ),
      ]}
      chartRef={activeTabRef}
      dragRef={props.dragRef}
      boardControls={props.boardControls}
    >
      <Tabs
        value={activeTab}
        onChange={setActiveTab}
        className={styles.tab}
        keepMounted={false}
        ref={chartRef}
      >
        <Tabs.List>
          <Tabs.Tab value="tags">{t('Tags')}</Tabs.Tab>
          {!isGroupView && (
            <>
              <Tabs.Tab value="competitors" disabled={isGroupView}>
                {t('Competitors')}
              </Tabs.Tab>
              <Tabs.Tab value="page" disabled={isGroupView}>
                {t('Page')}
              </Tabs.Tab>{' '}
            </>
          )}
        </Tabs.List>
        <Tabs.Panel value="tags" pt="xs" ref={tabRefTags} bg="white">
          <ShareOfVoiceTags useAi={useAi} />
        </Tabs.Panel>
        {!isGroupView && (
          <>
            <Tabs.Panel value="competitors" pt="xs" ref={tabRefComps} bg="white">
              <ShareOfVoiceCompetitors useAi={useAi} />
            </Tabs.Panel>
            <Tabs.Panel value="page" pt="xs" ref={tabRefPages} bg="white">
              <ShareOfVoiceLandingPages useAi={useAi} />
            </Tabs.Panel>
          </>
        )}
      </Tabs>
    </ChartContainer>
  );
};

export const AIShareOfVoiceContainer = ({ isGroupView, ...props }) => {
  return GeneralShareOfVoiceContainer({ isGroupView, useAi: true, ...props });
};

export const ShareOfVoiceContainer = ({ isGroupView, ...props }) => {
  return GeneralShareOfVoiceContainer({ isGroupView, useAi: false, ...props });
};
