import { Divider, Flex, Group } from '@mantine/core';
import { IconDeviceDesktopAnalytics, IconListSearch } from '@tabler/icons-react';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import AccessControl from 'Components/AccessControl';
import { DomainFavicon } from 'Components/DomainFavicon';
import LinkToDomain, { PageIdToLink } from 'Components/Filters/LinkToDomain';
import { Flag } from 'Components/Flag';
import AccText from 'Components/Text/AccText';
import { t } from 'Utilities/i18n';
import KeywordsDistribution from '../KeywordsDistribution';
import { useDomainContext } from './DomainContext';
import DomainMenu from './DomainMenu';
import domainCardStyles from '../domainCard.module.scss';

const DetailsActions = () => {
  const { id, displayName, domain, country } = useDomainContext();
  const domainId = id.toString();
  return (
    <Flex wrap="nowrap" align="center" px="lg" py={8} gap="sm" justify="space-between" mih={58}>
      {id && (
        <>
          <Group gap={8} wrap="nowrap" mih={42} miw={0} styles={{ root: { alignItems: 'center' } }}>
            <DomainFavicon domain={domain} size="lg" />
            <Flex wrap="nowrap" gap="xxs" direction="column" miw={0}>
              <LinkToDomain domainId={domainId} reset className={domainCardStyles.linkToDomain}>
                {displayName || domain}
              </LinkToDomain>
              {displayName && (
                <AccText className={domainCardStyles.domainText} size="sm">
                  {domain}&lrm;
                </AccText>
              )}
            </Flex>
          </Group>
          <Flex wrap="nowrap" align="center" gap="xs" lh="1">
            <Group wrap="nowrap" gap="0" c="blue.4" className={domainCardStyles.actions}>
              <LinkToDomain domainId={domainId} reset={false} navigateTo={PageIdToLink.overview}>
                <AccTooltip tooltip={t('Go to dashboard')}>
                  <AccActionIcon variant="subtle" tabIndex={-1} c="blue">
                    <IconDeviceDesktopAnalytics size={21} />
                  </AccActionIcon>
                </AccTooltip>
              </LinkToDomain>
              <LinkToDomain domainId={domainId} reset={false} navigateTo={PageIdToLink.keywords}>
                <AccTooltip tooltip={t('Go to keywords list')}>
                  <AccActionIcon variant="subtle" tabIndex={-1} c="blue">
                    {/* 21 to line with tabler icons */}
                    <IconListSearch size={21} />
                  </AccActionIcon>
                </AccTooltip>
              </LinkToDomain>
            </Group>
            {country && <Flag size="md" mx={0} country={country} fadedColors />}
            <KeywordsDistribution />
            <AccessControl>
              <Divider orientation="vertical" my="xxs" />
              <DomainMenu />
            </AccessControl>
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default DetailsActions;
