import * as React from "react";
const SvgCtr = (props) => /* @__PURE__ */ React.createElement("svg", { xmlSpace: "preserve", width: 18.918, height: 21.58, viewBox: "0 0 18.918 21.58", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", { id: "ctr_svg__a", clipPathUnits: "userSpaceOnUse" }, /* @__PURE__ */ React.createElement("path", { d: "M1 2h22v25H1Zm12.97 13.358v7.795h8.726v-7.795Z", className: "ctr_svg__powerclip", style: {
  strokeWidth: 1.75748
} }))), /* @__PURE__ */ React.createElement("g", { style: {
  fontSize: "10.6667px",
  lineHeight: 1.25
} }, /* @__PURE__ */ React.createElement("path", { d: "M17.769 17.733q0 .97-.502 1.493-.49.523-1.29.523-.395 0-.726-.128-.33-.139-.565-.395-.235-.256-.373-.629-.128-.384-.128-.864 0-.981.49-1.493.502-.523 1.302-.523t1.29.523q.502.512.502 1.493zm-1.014 0q0-.565-.213-.843-.203-.288-.565-.288-.363 0-.566.288-.202.278-.202.843t.202.853q.203.278.566.278.362 0 .565-.278.213-.288.213-.853zm3.179-1.856h1.227l-4.171 7.392h-1.227zm2.816 5.547q0 .97-.501 1.493-.491.523-1.291.523-.395 0-.725-.139-.331-.128-.566-.384-.234-.256-.373-.63-.128-.383-.128-.863 0-.982.49-1.494.502-.522 1.302-.522t1.29.522q.502.512.502 1.494zm-1.013 0q0-.566-.214-.843-.202-.288-.565-.288-.363 0-.565.288-.203.277-.203.843 0 .565.203.853.202.277.565.277.363 0 .565-.277.214-.288.214-.853z", "aria-label": "%", style: {
  fontWeight: 500,
  fontFamily: "Ubuntu",
  InkscapeFontSpecification: "'Ubuntu Medium'"
}, transform: "translate(-4 -2)" })), /* @__PURE__ */ React.createElement("g", { clipPath: "url(#ctr_svg__a)", transform: "translate(-4 -2)" }, /* @__PURE__ */ React.createElement("path", { d: "M17 22h-7c-.379 0-.725-.214-.895-.553l-3-6A1 1 0 0 1 7 14h2V8a1 1 0 0 1 1.316-.949l3 1c.409.137.684.519.684.949v4.219l3.242.811A1 1 0 0 1 18 15v6a1 1 0 0 1-1 1zm-6.382-2H16v-4.219l-3.242-.81A1.002 1.002 0 0 1 12 14V9.721l-1-.333V15a1 1 0 0 1-1 1H8.618Z" })), /* @__PURE__ */ React.createElement("path", { d: "M0 6h3v2H0zm2.757-4.656L4.88 3.465 3.465 4.88 1.343 2.758zM6 0h2v3H6zm6.656 2.758L10.535 4.88 9.12 3.465l2.121-2.12zM11 6h3v2h-3z" }));
export default SvgCtr;
