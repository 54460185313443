import { Flex } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import { Field, Form } from 'Components/Fields';
import 'Components/Fields/hooks/WithFieldForm/form-field.scss';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import { useGroupsAndDomainClientsQuery } from 'Ghql';
import { useSpecificFilter } from 'Hooks';
import { FilterAttribute } from 'Types/Filter';
import { t } from 'Utilities/i18n';
import { useAvailableGroups } from '../Property/utils';

type Props = {
  onSubmit: (arg: { groupId: string }) => any;
  onBack: (...args: Array<any>) => any;
};

export const Group = ({ onSubmit, onBack }: Props) => {
  const { data, loading } = useGroupsAndDomainClientsQuery();
  const groupIds = useSpecificFilter(FilterAttribute.CLIENTS)?.value;

  // get pre-selected groupId if the user has filtered on a single group
  const preselectedGroupId = groupIds?.length === 1 ? groupIds[0] : '';

  const groupItems = useAvailableGroups(data);

  // if the user has only one group we preselect this
  const groupId = groupItems.length === 1 ? groupItems[0].value : preselectedGroupId;

  return (
    <Form
      onSubmit={(values: Record<string, string>) => {
        onSubmit({ groupId: values.group });
      }}
      subscription={{ values: true }}
      initialValues={{ group: groupId }}
    >
      {(form) => {
        return (
          <Flex direction="column" gap={16}>
            <Field.Select
              name="group"
              options={groupItems}
              label={t('Group')}
              isLoading={loading}
              disabled={loading}
              fieldFormOnlyLabel
            />
            <ModalFooter
              primaryButtonSlot={
                <AccButton
                  variant="primary"
                  type="submit"
                  disabled={loading || form.values.group === ''}
                >
                  {t('Select group')}
                </AccButton>
              }
              secondaryButtonSlot={
                <AccButton variant="tertiary" onClick={onBack} leftSection={<IconArrowLeft />}>
                  {t('Account')}
                </AccButton>
              }
            />
          </Flex>
        );
      }}
    </Form>
  );
};
