import { QueryOrder, SORTING_TYPE } from 'Constants';

export const SortType = {
  DATE: 'date',
  NATURAL: 'natural',
} as const;

export type SortTypes = typeof SortType[keyof typeof SortType];

export const DATE = SortType.DATE;
export const NATURAL = SortType.NATURAL;

export interface OrderingState {
  order: QueryOrder;
  orderBy: string;
}

export interface Ordering {
  defaultOrder: QueryOrder;
  orderBy: string;
  sortingKey?: SORTING_TYPE;
}
