import { useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { observe, toJS } from 'mobx';

/**
 * Observe and return the value of a specific property in a MobX store.
 * Used for hooks, to get the latest value, since hooks can't be wrapped into `observer`
 */
export const useValueStore = <T extends object = object>(
  store: T | null,
  property: keyof T,
  extractValue?: Function,
) => {
  const transformValue = (val) => (extractValue ? extractValue(toJS(val)) : val);
  const [value, setValue] = useState(toJS(transformValue(store?.[property])));

  useEffect(() => {
    const nextVal = transformValue(toJS(store?.[property]));
    setValue((prevValue) => {
      if (!isEqual(prevValue, nextVal)) {
        return nextVal;
      }
      return prevValue;
    });
  }, [store, property, extractValue]);

  useEffect(() => {
    const unsubscribe =
      store && property
        ? observe(store, property as never, (change: any) => {
            let nextValue = toJS(change.newValue);
            let oldValue = toJS(change.oldValue);

            if (extractValue) {
              nextValue = extractValue(nextValue);
              oldValue = extractValue(oldValue);
            }

            if (!isEqual(nextValue, oldValue)) {
              setValue(nextValue);
            }
          })
        : undefined;

    return () => {
      unsubscribe?.();
    };
  }, [store, property, extractValue]);
  return value;
};
