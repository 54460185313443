import {
  ADD_SELECTED_DOMAIN,
  Action,
  CLEAR_ALL_SELECTED_DOMAINS,
  REMOVE_SELECTED_DOMAIN,
  SET_SELECTABLE_DOMAINS,
} from 'Actions/DomainsPageActions';
import { MaxSelectableNodes } from 'Pages/Domains/support/const';

// Interface for the domain page state
export type DomainPageState = {
  selectedDomains: string[];
  selectableDomains: string[];
  maxReached: boolean;
};

// Initial state for the domains page
const initialState: DomainPageState = {
  selectedDomains: [],
  selectableDomains: [],
  maxReached: false,
};

export default function DomainsPageReducer(
  state: DomainPageState = initialState,
  action: Action,
): DomainPageState {
  switch (action.type) {
    case ADD_SELECTED_DOMAIN: {
      if (state.selectedDomains.length + 1 > MaxSelectableNodes) {
        return state;
      }
      const selectedDomains = [...state.selectedDomains, action.id];
      return {
        ...state,
        selectedDomains,
        maxReached: selectedDomains.length >= MaxSelectableNodes,
      };
    }
    case REMOVE_SELECTED_DOMAIN: {
      const selectedDomains = state.selectedDomains.filter((id) => id !== action.id);
      return {
        ...state,
        selectedDomains,
        maxReached: selectedDomains.length >= MaxSelectableNodes,
      };
    }
    case SET_SELECTABLE_DOMAINS:
      return {
        ...state,
        selectableDomains: action.selectableDomains,
      };
    case CLEAR_ALL_SELECTED_DOMAINS:
      return {
        ...state,
        selectedDomains: [],
        maxReached: false,
      };
    default:
      return state;
  }
}
