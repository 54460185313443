import * as React from 'react';
import Cell from '../Cell';
import TableContext from '../context/TableContext';
import {
  CellType,
  ColumnType,
  CustomizeComponent,
  GetComponentProps,
  StickyOffsets,
} from '../interface';
import { getCellFixedInfo } from '../utils/fixUtil';
import { getColumnsKey } from '../utils/valueUtil';

interface RowProps<RecordType> {
  cells: readonly CellType<RecordType>[];
  stickyOffsets: StickyOffsets;
  flattenColumns: readonly ColumnType<RecordType>[];
  rowComponent: CustomizeComponent;
  cellComponent: CustomizeComponent;
  onHeaderRow: GetComponentProps<readonly ColumnType<RecordType>[]>;
  index: number;
}

function HeaderRow<RecordType>({
  cells,
  stickyOffsets,
  flattenColumns,
  rowComponent: RowComponent,
  cellComponent: CellComponent,
  onHeaderRow,
  index,
}: RowProps<RecordType>) {
  const { prefixCls, direction } = React.useContext(TableContext);

  let rowProps: React.HTMLAttributes<HTMLElement> | undefined;
  if (onHeaderRow) {
    rowProps = onHeaderRow(cells.map((cell) => cell.column) as any, index);
  }

  const columnsKey = getColumnsKey(cells.map((cell) => cell.column) as any);

  return (
    <RowComponent {...rowProps}>
      {cells.map((cell: CellType<RecordType>, cellIndex) => {
        const { column } = cell;
        const fixedInfo = getCellFixedInfo(
          cell.colStart!,
          cell.colEnd!,
          flattenColumns,
          stickyOffsets,
          direction,
        );

        let additionalProps: React.HTMLAttributes<HTMLElement> | undefined;
        if (column?.onHeaderCell) {
          additionalProps = column.onHeaderCell(column);
        }

        return (
          <Cell
            {...cell}
            ellipsis={column?.ellipsis}
            align={column?.align}
            component={CellComponent}
            prefixCls={prefixCls}
            key={columnsKey[cellIndex]}
            {...fixedInfo}
            additionalProps={additionalProps}
            rowType="header"
          />
        );
      })}
    </RowComponent>
  );
}

HeaderRow.displayName = 'HeaderRow';

export default HeaderRow;
