import { t } from 'Utilities/i18n';

export enum ReportType {
  PDF = 1,
  EXCEL = 2,
  CSV = 3,
  PUBLIC_REPORT = 4,
  GOOGLE_SHEETS = 5,
}

export const getReportTypeOptions = () => [
  { value: ReportType.PDF, id: ReportType.PDF, label: t('PDF') },
  { value: ReportType.EXCEL, id: ReportType.EXCEL, label: t('Excel') },
  { value: ReportType.CSV, id: ReportType.CSV, label: t('CSV') },
  { value: ReportType.PUBLIC_REPORT, id: ReportType.PUBLIC_REPORT, label: t('Public Report') },
  { value: ReportType.GOOGLE_SHEETS, id: ReportType.GOOGLE_SHEETS, label: t('Google Sheets') },
];
