import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { deselectDomain, selectDomain } from 'Actions/DomainsPageActions';
import toast from 'Hooks/useToast';
import { store } from 'Store';
import { StoreType } from 'Types/Store';
import { t } from 'Utilities/i18n';

export const useToggleCheckbox = (selectId?: string) => {
  const { checked, disabled } = useSelector(({ domainsPage }: StoreType) => {
    const isChecked = !!(selectId && domainsPage.selectedDomains.includes(selectId));
    return {
      checked: isChecked,
      disabled: !selectId || (domainsPage.maxReached && !isChecked),
    };
  });
  const toggle = useCallback(() => {
    if (!selectId) return;
    if (!checked) {
      if (disabled) {
        toast.info(t('Max number of selected domains reached.'));
      } else {
        store.dispatch(selectDomain(selectId));
      }
    } else {
      store.dispatch(deselectDomain(selectId));
    }
  }, [checked, disabled, selectId]);
  return toggle;
};

export const useCheckboxSelection = (selectId?: string) => {
  const result = useSelector(({ domainsPage }: StoreType) => {
    const isChecked = !!(selectId && domainsPage.selectedDomains.includes(selectId));
    const disabled = !selectId || (domainsPage.maxReached && !isChecked);
    return {
      checked: isChecked,
      disabled,
    };
  });
  return result;
};
