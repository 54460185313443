import { makeAutoObservable } from 'mobx';

class RefreshKeywordStore {
  constructor() {
    makeAutoObservable(this);
  }

  refreshStatus: Record<string, boolean> = {};

  getIsRefreshing = (domainId: string) => {
    return this.refreshStatus[domainId] ?? false;
  };

  startLoading = (domainId: string) => {
    this.refreshStatus[domainId] = true;
  };

  stopLoading = (domainId: string) => {
    this.refreshStatus[domainId] = false;
  };
}

export const refreshKeywordStore = new RefreshKeywordStore();
