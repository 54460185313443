import sortBy from 'lodash/sortBy';
import { SeriesItem } from 'Components/Chart/LineChart';
import { NOT_IN_TOP_100_VALUE } from 'Components/Chart/LineChart/support/constants';
import { SeriesDataItem } from 'Components/Chart/LineChart/support/types';
import { UnkowncompetitorHistoryGraphNode } from 'Ghql';
import { getDomainName } from 'Utilities/Common/domain';

export const convertTopRankResponseToSeriesData = (
  data?: Partial<UnkowncompetitorHistoryGraphNode>[],
): SeriesItem[] => {
  const result = data
    ?.filter((e) => e.searchDate)
    ?.reduce((acc, e) => {
      const name = getDomainName(e);

      return {
        ...acc,
        [name]: [
          ...(acc[name] || []),
          {
            y: e.rank ?? NOT_IN_TOP_100_VALUE,
            x: new Date(e.searchDate!).getTime(),
          },
        ],
      };
    }, {} as Record<string, SeriesDataItem[]>);

  const ownDomainName = getDomainName(data?.find((e) => e.ownDomain) ?? {});
  return Object.entries(result || {}).map(([name, items]) => {
    const primary = ownDomainName === name;
    return {
      name,
      primary,
      data: sortBy(items ?? [], 'x'),
    };
  });
};
