import { gql } from '@apollo/client';

export const QUERY_LANDING_CHART = gql`
  query LandingChartQuery(
    $filters: [FilterInput]!
    $landingPages: [ID]!
    $metric: String!
    $displayCurrency: String!
  ) {
    graphs(filters: $filters, displayCurrency: $displayCurrency) {
      landingPageHistory(allHistory: true, landingPages: $landingPages, metric: $metric) {
        days {
          searchDate
          value
        }
        path
      }
    }
  }
`;
