import { DatePickerInput, DateValue } from '@mantine/dates';
import styleVariables from 'css/base/variables.module.scss';
import styles from './datePickerAdapter.module.scss';

type DateInputProps = {
  disabled: boolean;
  value: Date | undefined;
  onChange: (...args: any[]) => void;
  /**
   * Callback function to determine whether the day should be disabled.
   *
   * This can eg. be used to filter out any past dates
   */
  excludeDate?: (date: Date) => boolean;
  minDate?: Date;
  maxDate?: Date;
};

export const DatePickerAdapter = ({
  disabled,
  value: selectedValue,
  onChange,
  excludeDate,
  minDate,
  maxDate,
}: DateInputProps) => {
  return (
    <DatePickerInput
      disabled={disabled}
      value={selectedValue || undefined}
      excludeDate={excludeDate}
      size="xs"
      radius={0}
      valueFormat="YYYY-MM-DD"
      onChange={(value: DateValue) => onChange(value)}
      className={styles.dateInput}
      popoverProps={{ zIndex: Number(styleVariables.zindexPopover) }}
      minDate={minDate}
      maxDate={maxDate}
    />
  );
};
