import { devError } from './log';

export const extractHostname = (url: string) => {
  let hostname;
  //find & remove protocol (http, ftp, etc.) and get hostname

  if (url.indexOf('//') > -1) {
    hostname = url.split('/')[2];
  } else {
    hostname = url.split('/')[0];
  }

  //find & remove port number
  hostname = hostname.split(':')[0];
  //find & remove "?"
  hostname = hostname.split('?')[0];

  return hostname;
};

export const getUrlPath = (rankUrl?: string | null) => {
  let urlString;
  if (rankUrl) {
    let el;
    try {
      el = new URL(rankUrl);
    } catch (e) {
      devError('Url is not valid, please handle it: ', rankUrl);
      return rankUrl;
    }
    try {
      urlString = decodeURI(`${el.pathname}${el.search}${el.hash}`).toString();
    } catch (e) {
      urlString = `${el.pathname}${el.search}${el.hash}`.toString();
    }
  }

  return urlString;
};
