import { CSSProperties, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Point, PointOptionsObject } from 'highcharts';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import uniqueId from 'lodash/uniqueId';
import {
  defaultBillionsSettings,
  defaultMillionsSettings,
  defaultThousandsSettings,
  formatNumberHelper,
} from 'Components/FormatNumber/formatNumberHelper';
import { updateLoader } from 'Components/HighchartsLoader';
import { SerpFeature } from 'Pages/Keywords/SERP';
import { mantineTheme } from 'css/mantine/theme';

type DataType = Array<{
  name: string;
  y: number;
}>;

export type PieChartConfigProps = {
  data: DataType;
  noDataMessage: string;
  showLegend?: boolean;
  totalLabel: string;
  seriesLabel: string;
  transparentBg?: boolean;
  linkTo?: string | ((...args: Array<any>) => any);
  onClick?: (arg1: unknown, arg2: unknown) => void;
  history?: Record<string, any>;
  height?: number;
  width?: number;
  sum?: number;
  legendWidth?: number;
  currency?: boolean;
  percentage?: boolean;
  showZeros?: boolean;
  sortByValue?: boolean;
  period?: number;
  loading: boolean;
  watermark: boolean;
  watermarkBig: boolean;
  watermarkCutNumber?: number;
  showSubtitle?: boolean;
  showTitle?: boolean;
  disableTooltip?: boolean;
  dataLabels: Highcharts.PlotPieOptions['dataLabels'];
};

// get sum of all "y"-values in input
const getTotalValue = (data: DataType): number =>
  data.reduce((currentTotal, dataItem) => currentTotal + dataItem.y, 0);

export const usePieChartConfig = (props: PieChartConfigProps): Highcharts.Options => {
  const {
    showLegend = false,
    height,
    width: chartWidth = 490,
    currency = false,
    percentage,
    sortByValue = true,
    showSubtitle = true,
    showTitle = true,
    disableTooltip = false,
    dataLabels,
    loading,
    seriesLabel,
    totalLabel,
    data: originalData,
    sum,
    showZeros,
    legendWidth,
    period,
    onClick,
    linkTo,
    history,
    noDataMessage,
  } = props;

  const intl = useIntl();
  const totalValue = sum ? sum : getTotalValue(originalData);
  const total = intl.formatNumber(
    totalValue,
    currency
      ? {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      : {},
  );

  const data = useMemo(() => {
    let returnData = showZeros ? originalData : originalData.filter((dataItem) => dataItem.y !== 0);
    returnData = !sortByValue ? returnData : orderBy(returnData, ['y'], ['desc']);
    returnData = returnData.map((item) => ({ ...item, id: uniqueId('legend-tooltip') }));
    return returnData;
  }, [originalData, showZeros, sortByValue]);

  const subtitleNoData = !isEmpty(data) ? noDataMessage : '';
  const subtitle = totalValue > 0 ? total : subtitleNoData;

  const subTitleFontSize = totalValue > 0 ? '1rem' : '14px !important';
  const marginRight = showLegend ? legendWidth || 210 : 0;

  const width = chartWidth || 0 + marginRight;

  const chart: Highcharts.ChartOptions = useMemo(
    () => ({
      type: 'pie',
      height,
      width,
      marginRight,
      plotShadow: false,
      colorCount: 7,
      className: 'pie-chart',
      style: {
        fontFamily: mantineTheme.fontFamily as CSSProperties['fontFamily'],
      },
      events: {
        load() {
          updateLoader(this, period, loading);
        },
      },
    }),
    [period, loading, height, marginRight, width],
  );

  const legend: Highcharts.LegendOptions = {
    align: 'right',
    verticalAlign: 'middle',
    layout: 'vertical',
    x: 10,
    width: legendWidth || 190,
    useHTML: true,
    enabled: showLegend,

    labelFormatter() {
      let value;
      if (this instanceof Point) {
        value = this.y;
      }

      const name = this.name;

      if (currency) {
        value = intl.formatNumber(value, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      } else if (percentage) {
        value = intl.formatNumber(value / 100, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          style: 'percent',
        });
      } else {
        value = formatNumberHelper({
          value,
          billionsSettings: defaultBillionsSettings,
          millionsSettings: defaultMillionsSettings,
          thousandsSettings: defaultThousandsSettings,
          intlFormatNumber: intl.formatNumber,
        });
      }

      return `
        <div class="chart-pie-legend-container">
          <div class="legend-item-name-wrapper">
            <div
              class="legend-item-pointer-circle"
              style="background-color: ${(this as Point).color}"
            ></div>
            <span id="${(this as any).id}" class="chart-pie-legend">${name}</span>
          </div>
          <span class="chart-pie-legend-value">${value}</span>
        </div>
      `;
    },

    navigation: {
      enabled: false,
    },
  };

  const tooltip: Highcharts.TooltipOptions = {
    enabled: !disableTooltip,
    shared: false,
    shadow: false,
    backgroundColor: '#FFFFFF',
    borderRadius: 0,
    borderWidth: 0,
    padding: 0,
    useHTML: true,
    formatter() {
      return `<div class="chart-tooltip-box">
          <div class="chart-tooltip-table">
            <div class="chart-tooltip-table-tr">
              <div class="chart-tooltip-table-th">${this.point.name}</div>
            </div>
            <div class="chart-tooltip-table-tr">
              <div class="chart-tooltip-table-td">
                <span class="chart-tooltip-bullet" style="color: ${this.color};">●</span> ${
        this.series.name
      }
              </div>
              <div class="chart-tooltip-table-td chart-tooltip-table-right" style="text-align: right;">
                ${intl.formatNumber(this.percentage / 100, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                  style: 'percent',
                })} (${intl.formatNumber((this as any).y)})
              </div>
            </div>
          </div>
        </div>`;
    },
    valueDecimals: 0,
    xDateFormat: '%b %e, %Y',
    hideDelay: 5,
  };

  const plotOptions: Highcharts.PlotOptions = {
    pie: {
      shadow: false,
      cursor: linkTo || onClick ? 'pointer' : undefined,
      dataLabels,
      states: { hover: { halo: null } },
      events: {
        click: (evt) => {
          if (onClick) {
            onClick(
              (evt.point.options as PointOptionsObject & { serp: SerpFeature }).serp,
              evt.point.options,
            );
          }

          if (linkTo) {
            let url = linkTo;

            if (linkTo instanceof Function) {
              url = linkTo(evt);
            }

            history && history.push(url);
          }
        },
      },
    },
    series: {
      animation: false,
    },
  };

  return {
    chart,
    plotOptions,
    tooltip,
    legend: {
      ...legend,
      // need to hide symbols from box
      // MUST be a string without 'px', because number 0 doesn't have effect, and '0px' calls errors
      symbolWidth: '0',
      symbolHeight: '0',
    } as unknown as Highcharts.LegendOptions,
    title: showTitle
      ? {
          verticalAlign: 'middle',
          align: 'center',
          text: totalLabel,
          x: isEmpty(data) ? 0 : -marginRight / 2 + 5,
          y: -10,
        }
      : undefined,
    subtitle: showSubtitle
      ? {
          text: subtitle,
          verticalAlign: 'middle',
          x: -marginRight / 2 + 5,
          y: 10,
          style: {
            fontSize: subTitleFontSize,
          },
        }
      : undefined,
    series: [
      {
        name: seriesLabel,
        data,
        size: '100%',
        innerSize: '60%',
        showInLegend: true,
        type: 'pie',
      },
    ],
    credits: {
      enabled: false,
    },
    noData: {
      style: {
        fontSize: subTitleFontSize,
        fontWeight: 'normal',
      },
      position: {
        y: 5,
        x: 100,
      },
    },
    lang: {
      noData: noDataMessage,
    },
    exporting: {
      enabled: false,
    },
  };
};
