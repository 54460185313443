/* eslint-disable react-hooks/rules-of-hooks */
import { ComponentType } from 'react';
import Alert from 'Components/Alert';
import { useUser } from 'Hooks/data/user/useUser';
import useUserPermission from 'Hooks/useUserPermission';
import { t } from 'Utilities/i18n';
import reusableStyles from 'css/reusable-styles.module.scss';

export const withOnlyOrgPermission = (
  Component: ComponentType<React.PropsWithChildren<unknown>>,
) => {
  const result = (props: object) => {
    const user = useUser();
    const { isAdmin } = useUserPermission();

    const showError = user?.organization && !isAdmin;

    if (showError) {
      return (
        <div className={reusableStyles.paperContainer}>
          <Alert>{t('You need to contact your organization admin to upgrade the account.')}</Alert>
        </div>
      );
    }
    return <Component {...props} />;
  };
  result.displayName = 'withOnlyOrgPermission';

  return result;
};
