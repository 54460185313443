import { useState } from 'react';
import { useForm } from 'react-final-form';
import { Group } from '@mantine/core';
import { FormApi } from 'final-form';
import noop from 'lodash/noop';
import { AccSelect, SelectItem } from 'Components/AccSelect';
import type { SelectItemValueType, SelectStylesVariations } from 'Components/AccSelect';
import { Flag } from 'Components/Flag';
import AccText from 'Components/Text/AccText';
import { t } from 'Utilities/i18n';
import { devError } from 'Utilities/log';

type Country = {
  label: string;
  value: string;
};

type CountryOption = Country & SelectItem;

type CountryOptionValueType = SelectItemValueType | null;

type Props = {
  autoFocus?: boolean;
  countries?: Country[];
  value?: string;
  onChange: (value: CountryOptionValueType) => void;
  showError?: string;
  placeholder: string;
  disabled?: boolean;
  validateCheck?: boolean;
  size?: SelectStylesVariations['size'];
  id?: string;
  label?: string;
  required?: boolean;
};

const filterCountryOptions = (filter: string, option: CountryOption): boolean => {
  const countryLowercase = option?.label?.toLowerCase();
  const countryCodeLowercase = option?.value?.toLowerCase();
  if (!countryLowercase) return false;
  filter = filter?.toLowerCase();
  return countryLowercase.includes(filter) || countryCodeLowercase.includes(filter);
};

const CountrySelectItem = ({ label, countryCode: optionCountryCode }: any) => {
  const countryCode = optionCountryCode?.toLowerCase();
  return (
    <Group wrap="nowrap" gap="xs">
      <Flag size="sm" mr={8} country={countryCode} />
      <AccText size="sm">{label}</AccText>
    </Group>
  );
};

CountrySelectItem.displayName = 'CountrySelectItem';

/**
 * Order locales by
 * 1) Most popular locales so far on this organization
 * 2) If there are no locales, the created by country for the organization
 * 3) Hardcoded list of most popular locales in database
 * @param locales
 */
function useOrderedCountries(locales) {
  // Ids corresponding to the locales that should be displayed first in the dropdown
  // Loosely based on the most popular locales in our database
  const orderedCCByPopularity = ['us', 'gb', 'au', 'de', 'da', 'se', 'fr', 'nl', 'es'];

  const localeSort = (b, a) => {
    // Find the indices of a and b in the groupOrder array
    const indexA = orderedCCByPopularity.indexOf(a.countryCode.toLowerCase());
    const indexB = orderedCCByPopularity.indexOf(b.countryCode.toLowerCase());

    if (indexA !== -1 && indexB !== -1) {
      // If both a and b are in the specified groups, sort them in reverse order
      return indexB - indexA;
    } else if (indexA !== -1) {
      // If only a is in the specified groups, it comes after b
      return 1;
    } else if (indexB !== -1) {
      // If only b is in the specified groups, it comes after a
      return -1;
    }
    // If neither a nor b are in the specified groups, sort them alphabetically by label
    return 0;
  };

  return (
    locales
      ?.map((e) => ({
        ...e,
        value: e.value,
        label: e.label,
        group: orderedCCByPopularity.includes(e.countryCode.toLowerCase())
          ? t('Suggested countries and locales')
          : t('Other countries and locales'),
      }))
      .sort(localeSort) ?? []
  );
}

const CountrySelect = (props: Props) => {
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const { placeholder, countries, value, showError, autoFocus, validateCheck, disabled } = props;
  const isError = (!isFirstLoading || validateCheck) && showError;

  const handleBlur = () => {
    setIsFirstLoading(false);
  };
  const orderedCountries = useOrderedCountries(countries);
  return (
    <AccSelect<CountryOption>
      options={orderedCountries}
      size={props.size}
      disabled={disabled}
      value={value}
      className="locale-select-main"
      autoFocus={autoFocus}
      placeholder={placeholder}
      showError={!!isError}
      onBlur={handleBlur}
      onChange={props.onChange ?? noop}
      itemComponent={CountrySelectItem}
      filter={filterCountryOptions}
      error={isError ? showError : null}
      clearSearchOnChange
      id={props.id}
      label={props.label}
      required={props.required}
    />
  );
};

CountrySelect.defaultProps = {
  disabled: false,
};

interface CountrySelectFieldProps extends Omit<Props, 'onChange'> {
  onChange: (value: string, form: FormApi) => void;
}

/**
 * CountrySelectField implemented for use with final form
 */
// eslint-disable-next-line import/no-unused-modules
export const CountrySelectField = (props: CountrySelectFieldProps) => {
  const form = useForm();
  const { onChange, ...rest } = props;

  const handleChange = (value: CountryOptionValueType) => {
    if (typeof value === 'string') {
      onChange(value, form);
    } else {
      devError('Error: The value passed to the CountrySelect FormField is not of type string');
    }
  };

  return <CountrySelect {...rest} onChange={handleChange} />;
};

// eslint-disable-next-line import/no-unused-modules
export default CountrySelect;
