import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Menu } from '@mantine/core';
import compose from 'lodash/flowRight';
import {
  deleteFilterGroup,
  renameFilterGroup,
  toggleDefaultFilterGroup,
} from 'Actions/FilterAction';
import SimpleDropdownButton from 'Components/Controls/Dropdowns/SimpleDropdownButton';
import withFiltersEditor from 'Components/HOC/withFiltersEditor';
import type { FiltersEditorProps } from 'Components/HOC/withFiltersEditor';
import AccText from 'Components/Text/AccText';
import FilterGroupsSelector from 'Selectors/FilterGroupsSelector';
import type { FilterGroup } from 'Types/Filter';
import { StoreType } from 'Types/Store';
import { t } from 'Utilities/i18n/index';
import SavedFilterGroup from './SavedFilterGroup';
import styles from './saved-filters.module.scss';

const SavedFilterGroups = (props: FiltersEditorProps) => {
  const [menuOpened, setMenuOpened] = useState(false);
  const dispatch = useDispatch();

  const filterGroups = useSelector((state: StoreType) => FilterGroupsSelector(state));

  const { filterSet, filtersEditor, filterGroup } = props;

  const handleSelect = (group: FilterGroup) => {
    filtersEditor.selectFilterGroup(group);
  };
  const handleDelete = (id: string) => {
    dispatch(deleteFilterGroup(id));
  };
  const handleEdit = (id: string, name: string, isAPIFilter) => {
    if (isAPIFilter) {
      dispatch(deleteFilterGroup(id));
    } else {
      dispatch(renameFilterGroup(id, name));
    }
  };
  const handleToggleDefault = (id: string, isDefault: boolean) => {
    dispatch(toggleDefaultFilterGroup(id, isDefault));
  };

  const filterGroupName = filterGroup.name;
  const currentFilterGroup = filterGroups.find((group) => group.name === filterGroupName);

  return (
    <Box ml={8}>
      <Menu
        withinPortal
        onChange={setMenuOpened}
        opened={menuOpened}
        shadow="md"
        trigger="click"
        width={250}
        position={'bottom-end'}
        closeOnItemClick={false}
        classNames={{ item: styles.menuItem }}
        transitionProps={{ duration: 0 }}
        closeOnEscape
        closeOnClickOutside
        id="saved-segments-menu"
      >
        <Menu.Target>
          <button
            className={styles.savedSegmentsButton}
            onClick={() => {
              setMenuOpened((isOpen) => !isOpen);
            }}
          >
            <SimpleDropdownButton
              title={t('Saved segments')}
              labelFunc={(group: FilterGroup) => group.name}
              item={currentFilterGroup}
              labelMaxWidth={200}
            />
          </button>
        </Menu.Target>
        <Menu.Dropdown miw={200}>
          <Menu.Label>
            <AccText overflowEllipsis variant="label">
              {t('Saved segments')}
            </AccText>
          </Menu.Label>
          <Menu.Divider />
          {filterGroups?.map((group, index) => {
            const withClose = (fn) => compose(() => setMenuOpened(false), fn);
            return (
              <Menu.Item
                component={SavedFilterGroup}
                key={group.name}
                index={index}
                filterGroup={group}
                filterSet={filterSet}
                onEdit={withClose(handleEdit)}
                onSelect={withClose(handleSelect)}
                onDelete={withClose(handleDelete)}
                onToggleDefault={handleToggleDefault}
                closeDropdown={() => {
                  setMenuOpened(false);
                }}
              />
            );
          })}
        </Menu.Dropdown>
      </Menu>
    </Box>
  );
};

export default withFiltersEditor(SavedFilterGroups) as React.ComponentType<{}>;
