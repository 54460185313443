import { FilterSet } from 'Types/FilterSet';

export type navItemSubPage = {
  link: string;
  label: string;
  filterSet?: FilterSet;
  external?: boolean;
  /** Specify who the route should be visible for.
   * If an array, all conditions have to be met for the route to be visible */
  visibleFor?: string | string[];
  extraActiveRoutes?: string[];
};

export type NavItem = {
  key: string;
  label: string;
  link: string;
  icon: () => JSX.Element;
  subPages?: navItemSubPage[];
  /** Specify who the route should be visible for.
   * If an array, all conditions have to be met for the route to be visible */
  visibleFor?: string | string[];
  extraActiveRoutes?: string[];
};

export enum NavItemKeys {
  HOME = 'home',
  RESEARCH = 'research',
  REPORTING = 'reporting',
  ACCOUNT = 'account',
  INTEGRATIONS = 'integrations',
  IMPORTER = 'importer',
  SALES = 'sales',
  WELCOME = 'welcome',
}
