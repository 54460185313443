import { useCallback, useMemo } from 'react';
import { useFilters } from 'Hooks';
import { useRemoveFilter, useSetOrOverwriteFilter } from 'Hooks/data/filter/setFilters';
import { DomainsFilter, FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';

export const useSelectedDomains = (): [string[], (domainIdList: string[]) => void] => {
  const filters = useFilters();
  const setFilter = useSetOrOverwriteFilter();
  const removeFilter = useRemoveFilter();
  const domainsFilter = filters.find((filter) => filter.attribute === FilterAttribute.DOMAINS) as
    | DomainsFilter
    | undefined;
  const selectedDomains = useMemo(() => domainsFilter?.value || [], [domainsFilter]);
  const setSelectedDomains = useCallback(
    (domainIdList: string[]) => {
      if (!domainIdList?.length) {
        removeFilter(FilterAttribute.DOMAINS);
        return;
      }
      const newDomainsFilter: DomainsFilter = {
        attribute: FilterAttribute.DOMAINS,
        type: FilterValueType.LIST,
        comparison: FilterComparison.CONTAINS,
        value: domainIdList,
      };
      setFilter(newDomainsFilter, 'no tracking');
    },
    [removeFilter, setFilter],
  );
  return [selectedDomains, setSelectedDomains];
};
