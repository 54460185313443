import { FilterBase } from 'Types/Filter';

/** Get info relevant to groupview of the overview page.
 *
 *  @example
 *  const { isGroupView, isEmptyGroup } = useGroupViewInfo();
 */
export const useGroupViewInfo = (filters: FilterBase[]) => {
  const isGroupView = filters?.some(
    (filter) => filter.attribute === 'domains' && filter.value?.length > 1,
  );
  const isEmptyGroup = filters?.some(
    (filter) => filter.attribute === 'domains' && !filter.value?.length,
  );
  return { isGroupView, isEmptyGroup };
};
