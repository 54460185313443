import { memo } from 'react';
import { Box } from '@mantine/core';
import isEqual from 'lodash/isEqual';
import { useInView } from 'Hooks/intersectionObserver/useInView';
import { useComparisonItemContext } from '../useComparisonContext';
import { CollapseItem, ItemProps } from './CollapseItem';

const ItemContainer = (props: ItemProps) => {
  const { ref, inView } = useInView({
    rootMargin: '50px',
    triggerOnce: true,
  });
  const { selected, expanded } = useComparisonItemContext(props.data);
  // show expanded item to allow scroll to sub-items
  return (
    <Box maw="full" ref={ref} mih="50px" data-checked={selected || undefined}>
      {(inView || expanded) && <CollapseItem {...props} />}
    </Box>
  );
};

export default memo(ItemContainer, isEqual);
