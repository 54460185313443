import { Flex, Group, Stack, useMantineTheme } from '@mantine/core';
import { IconFilterOff } from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import AccTitle from 'Components/Title/AccTitle';
import { useResetFilters } from 'Hooks/data/filter/setFilters';
import { t, tct } from 'Utilities/i18n';
import reusableStyles from 'css/reusable-styles.module.scss';
import { KeywordSearch } from './QuickActions/KeywordSearch';
import { SERPFeatures } from './QuickActions/SERPFeatures';
import { SearchIntent } from './QuickActions/SearchIntent';
import styles from './research-panel.module.scss';

interface QuickActionsCardProps {
  countryChoice: string | undefined;
  domainIsSelected: boolean;
}

export const QuickActionsCard = ({ countryChoice, domainIsSelected }: QuickActionsCardProps) => {
  const resetFilters = useResetFilters();
  const theme = useMantineTheme();

  const ResetActions = () => {
    resetFilters();
  };

  const primarySnorlax = theme.colors.snorlax[theme.primaryShade as number];

  return (
    <Stack gap={'xs'} p="xl" className={reusableStyles.paperContainer}>
      <Flex align={'center'} wrap={'nowrap'} justify={'space-between'}>
        <AccTitle
          type="h2"
          helper={tct(
            'This panel is used to maximize your keyword research speed and accuracy with handy filter shortcuts.[br]Mix and match actions to quickly zero in on the most relevant keywords.[br][br][tip] Make your search even more targeted by combining the actions with filters from the top-level filter bar.',
            {
              br: <br />,
              tip: <b>{t('Tip')}:</b>,
            },
          )}
        >
          {t('Quick Actions')}
        </AccTitle>
        <AccButton
          variant="tertiary"
          aria-label={t('Reset all filters')}
          onClick={ResetActions}
          leftSection={<IconFilterOff size={18} color={primarySnorlax} />}
        >
          {t('Reset all filters')}
        </AccButton>
      </Flex>
      <Group
        className={styles.quickActionsContentContainer}
        wrap="nowrap"
        justify="space-between"
        align="stretch"
        gap={0}
      >
        <KeywordSearch domainIsSelected={domainIsSelected} />
        <SearchIntent countryChoice={countryChoice} />
        <SERPFeatures countryChoice={countryChoice} usePlaceholderData={!domainIsSelected} />
      </Group>
    </Stack>
  );
};
