import {
  hideModal as hideModalAction,
  showModal as showModalAction,
} from '../../Actions/ModalAction';
import { useActions } from '../redux/useActions';

export const useModal = () => {
  const { showModal, hideModal } = useActions({
    showModal: showModalAction,
    hideModal: hideModalAction,
  });
  return { showModal, hideModal };
};
