import { useEffect, useState } from 'react';
import { Box } from '@mantine/core';
import cn from 'classnames';
import Ellipsis from 'Components/Ellipsis';
import LoadingSpinner from 'Components/LoadingSpinner';
import AccText from 'Components/Text/AccText';
import useUserPermission from 'Hooks/useUserPermission';
import { t } from 'Utilities/i18n';
import SaveIcon from 'icons/save.svg?inline';
import Icon from '../../Icon/new';
import Cell from './HelperComponents/AccuCell';

type Props = {
  value: {
    data: {
      keywordDescription: string;
      id: number | string;
    };
    loadingData: boolean;
  };
  onChange: any;
  className: any;
};

const DescriptionCell = (props: Props) => {
  const {
    value: { data, loadingData },
    onChange,
    className,
  } = props;
  const { keywordDescription, id } = data;
  const [isEditing, setIsEditing] = useState(false);
  const [descriptionValue, setDescriptionValue] = useState(keywordDescription);
  const [isLoading, setIsLoading] = useState(false);
  const { userHasWriteAccess } = useUserPermission();
  const changed = descriptionValue !== keywordDescription;
  // eslint-disable-next-line prefer-const
  let textInput: any = null;
  useEffect(() => {
    setDescriptionValue(keywordDescription);
  }, [keywordDescription]);

  if (loadingData) return <span />;

  const onInputChange = (event) => {
    setDescriptionValue(event.target.value);
  };

  const focusInput = () => {
    textInput && textInput.focus();
  };

  const onInputKeyDown = async (event) => {
    if (event.keyCode === 13 && !isLoading) {
      setIsLoading(true);
      focusInput();

      try {
        // TODO FixTSignore
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        await onChange(id, descriptionValue);
        setIsLoading(false);
        setIsEditing(false);
      } catch (err: unknown) {
        console.error('error', err);
        if (typeof err === 'string') {
          throw new Error(err);
        }
      }
    }
  };

  const onBlur = () => {
    changed && setDescriptionValue(keywordDescription);
    setIsEditing(false);
  };

  const onEdit = () => {
    setIsEditing(true);
  };
  const Wrapper = ({ children, onClick }: { children: React.ReactNode; onClick?: () => void }) => (
    <Cell className={cn('accu-table-cell__description', className)} onClick={onClick}>
      {children}
    </Cell>
  );

  if (isEditing) {
    return (
      <Wrapper>
        <form className={'keyword-description-edit-container'} onBlur={onBlur}>
          {!isLoading && (
            <input
              ref={textInput}
              type="text"
              className="text-input-control keyword-description-cell"
              onChange={onInputChange}
              value={descriptionValue}
              disabled={isLoading}
              onKeyDown={onInputKeyDown}
              autoFocus
            />
          )}

          {isLoading && <LoadingSpinner />}
          {changed && !isLoading && (
            <Box ml="xs">
              <Icon width={16} height={16} tooltip={t('Save')}>
                <SaveIcon
                  onMouseDown={() =>
                    onInputKeyDown({
                      keyCode: 13,
                    })
                  }
                />
              </Icon>
            </Box>
          )}
        </form>
      </Wrapper>
    );
  }
  if (!userHasWriteAccess) {
    return (
      <Wrapper>
        <Ellipsis>{descriptionValue || '-'}</Ellipsis>
      </Wrapper>
    );
  }
  return (
    <Wrapper onClick={onEdit}>
      {!descriptionValue ? (
        <AccText size="12px" inactive>
          {t('Click to edit')}
        </AccText>
      ) : (
        <Ellipsis>{descriptionValue}</Ellipsis>
      )}
    </Wrapper>
  );
};

export default DescriptionCell;
