/** Style the chart zoom button similar to AccButton secondary */
export const ChartButtonTheme = {
  fill: 'white',
  stroke: 'var(--mantine-color-gray-2)',
  style: {
    color: 'var(--mantine-color-snorlax-light-color)',
    height: 32,
    fontWeight: '600',
  },
  r: 8,
  states: {
    hover: {
      //mantine-color-snorlax-light-hover layered with white
      fill: 'rgb(234, 234, 238)',
    },
  },
};
