import { useField } from 'react-final-form';
import { Box } from '@mantine/core';
import cn from 'classnames';
import TextInput from 'Components/Controls/TextInput';
import { extractErrorInfo } from 'Components/Fields/hooks/WithFieldForm/helpers';
import { TITLE_CONTAINS_KEYWORD } from 'Pages/DomainSettings/components/DynamicTagsBuilder/support/constants';
import { t } from 'Utilities/i18n';
import formStyles from 'css/layout/form-layout.module.scss';
import { NumberOperations, OperationType } from '../../../../../support/constants';
import { combineFieldNames } from '../support/helpers';
import styles from '../../../dynamic-tags.module.scss';

interface Props {
  name: string[] | string;
  operationGroup?: string;
  operator?: string;
}

const BaseFieldInput = (props: Props) => {
  const { input, meta } = useField(combineFieldNames(props.name));
  const { error, showError } = extractErrorInfo(meta);
  const className = cn('field-select-input', { 'has-error': error });
  return (
    <div>
      <TextInput
        className={className}
        isNumber={props.operationGroup === OperationType.NUMBER}
        placeholder={t('Enter value')}
        {...input}
      />
      <div className={styles.fieldValueError}>{showError ? error : ''}</div>
    </div>
  );
};

const EMPTY_ARRAY = [];

const BaseBetweenFieldInput = (props: Props) => {
  const { meta } = useField(combineFieldNames(props.name), { defaultValue: EMPTY_ARRAY });
  const { error, showError } = extractErrorInfo(meta);
  const { input: beforeInput } = useField(combineFieldNames(props.name).concat('[0]'));
  const { input: afterInput } = useField(combineFieldNames(props.name).concat('[1]'));

  const className = cn('field-select-input', { 'has-error': error });
  return (
    <div className={formStyles.formGroup}>
      <div className={formStyles.formCol}>
        <TextInput isNumber className={className} placeholder={t('From')} {...beforeInput} />
      </div>
      <div className={formStyles.formCol}>
        <TextInput isNumber className={className} placeholder={t('To')} {...afterInput} />
      </div>
      <div className={styles.fieldValueError}>{showError ? error : ''}</div>
    </div>
  );
};

export const FieldDynamicInput = (props: Props) => {
  if (props.operationGroup === OperationType.BASE || props.operator === TITLE_CONTAINS_KEYWORD) {
    //Returns a Box component with a minHeight of 60 to avoid jumping when changing between operators
    return <Box mih={60} />;
  } else if (props.operator === NumberOperations.BETWEEN) {
    return <BaseBetweenFieldInput {...props} />;
  }
  return <BaseFieldInput {...props} />;
};
