import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AuthRoutePath, StandAlonePath } from 'Constants/Routes';
import { selectModal } from 'Selectors/ModalSelector';

// eslint-disable-next-line import/no-unused-modules
export enum AppLanguage {
  DANISH = 'da',
  ENGLISH = 'en',
}

const DEFAULT_LANGUAGE_KEY = 'AccuRanker_default-language';

export const setDefaultLanguage = (lang: string): void => {
  try {
    localStorage?.setItem(DEFAULT_LANGUAGE_KEY, lang);
  } catch (e) {
    console.error('Failed to set lang', lang);
  }
};

// we need to get language before initialization to avoid rendering page twice,
// since to change lang we re-render full page *(changing root key)
export const getDefaultLanguage = (): AppLanguage => {
  try {
    let lang = localStorage?.getItem(DEFAULT_LANGUAGE_KEY);
    if (lang && !Object.values(AppLanguage).includes(lang as AppLanguage)) {
      lang = AppLanguage.ENGLISH;
    }
    return (lang as AppLanguage) || AppLanguage.ENGLISH;
  } catch (e) {
    return AppLanguage.ENGLISH;
  }
};

export const getIsResponsivePage = (path?: string) => {
  if (!path) {
    return false;
  }
  return [...(Object.values(AuthRoutePath) || []), ...(Object.values(StandAlonePath) || [])].some(
    (page) => path?.includes(page),
  );
};
/** Prevent scroll on the body when a modal is open */
export const usePreventScrollWhenModalOpen = () => {
  const modals = useSelector(selectModal);

  const modalOpen = !!modals.list.length;

  const body = document.querySelector('body');

  useEffect(() => {
    if (body && modalOpen && !body.dataset.noScroll) {
      body.dataset.noScroll = 'true';
    }
    if (body && !modalOpen && body.dataset.noScroll) {
      delete body.dataset.noScroll;
    }
  }, [modalOpen, body]);
};
