import { makeAutoObservable } from 'mobx';

const GROUPING_SUBMIT_DELAY = 3000; // Backend processes submits asynchronously, so we need to wait a bit before we can fetch the updated data

class GroupingStore {
  refetchListener: number;

  constructor() {
    this.refetchListener = 1;
    makeAutoObservable(this);
  }
  refetch = (immediate) => {
    return new Promise((resolve) =>
      setTimeout(resolve, immediate ? 0 : GROUPING_SUBMIT_DELAY),
    ).then(() => this.refetchListener++);
  };
}

let groupingStore: GroupingStore;

export const useGroupingStore = () => {
  if (!groupingStore) {
    groupingStore = new GroupingStore();
  }
  return groupingStore;
};
