import { useShellConfig } from 'Utilities/Router/hooks/useShellConfig';
import { NextGroupsAndDomains } from './GroupsAndDomains/NextGroupsAndDomains';

const Menu = () => {
  const config = useShellConfig((callback) => callback?.shell);
  if (!config?.showBreadcrumbs) return null;
  return <NextGroupsAndDomains />;
};

export default Menu;
