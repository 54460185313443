import { useEffect, useState } from 'react';

const useCountdown = (targetDateTime: number) => {
  const [countDown, setCountDown] = useState(targetDateTime - new Date().getTime());

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(targetDateTime - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDateTime]);

  const getReturnValues = (cd: number) => {
    const days = Math.floor(cd / (1000 * 60 * 60 * 24));
    const hours = Math.floor((cd % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((cd % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((cd % (1000 * 60)) / 1000);

    return [days, hours, minutes, seconds];
  };

  return getReturnValues(countDown);
};

export { useCountdown };
