import { Group } from '@mantine/core';
import cn from 'classnames';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { SitemapNode } from 'Pages/SiteMapping/support/types';
import { t } from 'Utilities/i18n';
import { getParentPath } from '../../support/helpers';
import RowIndentations from './RowIndentations';
import { TableRowChildProps } from './TableRowChild';
import styles from './tree-table.module.scss';

/** Renders when the node isOtherNode with a message about not being able to show more than 40 subpages */
export const OtherRow = (
  props: Pick<
    TableRowChildProps,
    | 'rootNode'
    | 'updateSelectedNode'
    | 'selectedNodes'
    | 'className'
    | 'gridTableRows'
    | 'hideConnectors'
  > & { isSelected: boolean },
) => {
  const { rootNode, updateSelectedNode, className, hideConnectors, isSelected } = props;
  const parentPath = `${getParentPath(rootNode as SitemapNode)}`;

  return (
    <Group
      onClick={(event) => updateSelectedNode(rootNode, event)}
      wrap="nowrap"
      className={cn(styles.fullRow, className, { [styles.selected]: isSelected })}
      key={rootNode.name}
      style={{
        gridTemplateColumns: '100%',
      }}
      ml={-6}
    >
      <span className={styles.otherNodeWrapper}>
        <RowIndentations
          hideConnectors={hideConnectors}
          depth={rootNode.depth - 1}
          expandIconWidth="15px"
          className={className}
        />
        <AccTooltip
          placement="left"
          tooltip={
            t(
              'We only show the subpages with most keywords. Apply a filter or adjust the max subpages per path setting above the chart to explore the remaining subpages of ',
            ) + parentPath
          }
        >
          <span>{t('Other subpages')}</span>
        </AccTooltip>
      </span>
    </Group>
  );
};
