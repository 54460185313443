import { TreeItem } from '../useComparisonContext';

const getIds = (item: TreeItem, ids: string[]) => {
  ids.push(item.selectId || '');
  if ('ds' in item) {
    item.ds?.forEach((subItem) => getIds(subItem, ids));
  }
  if ('fs' in item) {
    item.fs?.forEach((subItem) => getIds(subItem, ids));
  }
  if ('ts' in item) {
    item.ts?.forEach((subItem) => getIds(subItem, ids));
  }
};

export const getAllIds = (data: TreeItem[]) => {
  const ids: string[] = [];
  data.forEach((subItem) => getIds(subItem, ids));
  return ids;
};
