import { useRef } from 'react';
import { ChartLoader } from 'Components/Chart/ChartLoader/ChartLoader';
import { COLUMN_CHART_HEIGHT } from 'Components/Chart/ColumnChart/support/constants';
import { ReactHighchartsChart } from 'Components/Chart/ReactHighcarts';
import AccTitle from 'Components/Title/AccTitle';
import { t } from 'Utilities/i18n';
import { OveruseChartDescription } from './components/OveruseChartDescription';
import { useChartConfig } from './support/useChartConfig';

export const OveruseChart = () => {
  const chartref = useRef<Highcharts.Chart>(null);

  const { chartConfig, loading } = useChartConfig({ chartref });

  return (
    <>
      <AccTitle type="h3" mb="md">
        {t('Last 14 days keyword usage history')}
      </AccTitle>
      <ChartLoader
        loaderType="keywordOveruse"
        loading={loading}
        containerBoxProps={{ mb: 10, h: COLUMN_CHART_HEIGHT }}
      >
        <ReactHighchartsChart ref={chartref} config={chartConfig} />
      </ChartLoader>
      <OveruseChartDescription />
    </>
  );
};
