import ErrorBoundary from 'Components/ErrorBoundary';
import KpiBox, { KpiBoxSizeProps } from 'Pages/Keywords/Overview/components/KpiBar/KpiBox';

interface DiscKpiBoxProps extends KpiBoxSizeProps {
  loading: boolean;
  value: number | undefined;
  title: string;
  precision?: number;
  showPercentage?: boolean;
}

const DiscKpiBox = ({
  size,
  loading,
  value,
  title,
  precision = 0,
  showPercentage = false,
}: DiscKpiBoxProps) => {
  return (
    <ErrorBoundary>
      <KpiBox
        size={size}
        title={title}
        loading={loading}
        value={value}
        beforeValue={undefined}
        precision={precision}
        showPercentage={showPercentage}
        shadow="sm"
      />
    </ErrorBoundary>
  );
};

export default DiscKpiBox;
