import { stringLabelFunc } from 'Components/Filters/Common/labelFunc';
import { keywordFilterDefaultValue } from 'Components/Filters/Editors/Keywords/constants';
import KeywordsEditor from 'Components/Filters/Editors/Keywords/keywordsEditor';
import { getKeywordFiltersCombo } from 'Components/Modal/Content/Shortcuts';
import { t } from 'Utilities/i18n';
import KeywordsIcon from 'icons/keywords.svg?inline';
import NoFilterIcon from 'icons/no-filter.svg?inline';

const getData = () => ({
  defaultValue: keywordFilterDefaultValue,
  title: t('Keywords'),
  icon: KeywordsIcon,
  keyboardCombo: getKeywordFiltersCombo(),
  editor: KeywordsEditor,
  editorProps: {
    placeholder: t('Enter the keyword'),
  },
  tableEditor: KeywordsEditor,
  tableEditorProps: {
    placeholder: t('Keyword'),
    noFilterIcon: NoFilterIcon,
  },
  labelFunc: stringLabelFunc,
});

export default getData;
