import { useIntercom } from 'react-use-intercom';
import { Flex } from '@mantine/core';
import Text from 'Components/Text/AccText';
import Title from 'Components/Title/AccTitle';
import { t, tct } from 'Utilities/i18n';

const SupportText = () => {
  const { isOpen, show } = useIntercom();
  return (
    <Flex direction="column" rowGap={18} maw={650}>
      <Title type="h3">{t('Support')}</Title>
      <Text inline={false}>
        {tct(
          'Our [support] is ready if you have any questions or need help. Click on the orange bubble in the lower left corner, and we will get back to you as soon as possible.',
          {
            support: <a onClick={() => !isOpen && show()}>{t('customer support')}</a>,
          },
        )}
      </Text>
    </Flex>
  );
};

export default SupportText;
