import { useEffect, useRef } from 'react';
import { Box, Center, Flex, ScrollArea, Stack } from '@mantine/core';
import { useDebouncedState } from '@mantine/hooks';
import cn from 'classnames';
import AccAlert from 'Components/AccAlert/AccAlert';
import Spinner from 'Components/Spinner';
import AccText from 'Components/Text/AccText';
import { t } from 'Utilities/i18n';
import { useComparisonContext } from '../useComparisonContext';
import CollapseButtons from './CollapseButtons';
import CollapseSearch from './CollapseSearch';
import ItemContainer from './ItemContainer';
import styles from '../domainsComparison.module.scss';

const SCROLL_DELAY = 500;
const CollapseGroups = ({ loading }: { loading: boolean }) => {
  const { data } = useComparisonContext();
  const [pingedTop, setPingedTop] = useDebouncedState(false, 50);
  const [pingedBottom, setPingedBottom] = useDebouncedState(false, 50);
  const ref = useRef<HTMLDivElement>(null);
  const refTimeout = useRef<any>(null);

  const onScroll = ({ y }: { y: number }) => {
    setPingedTop(y !== 0);
    setPingedBottom(!(ref.current && y === ref.current.scrollHeight - ref.current.clientHeight));
  };

  // Scroll to first checked item after render
  useEffect(() => {
    if (refTimeout.current) {
      // clear previous timeout
      clearTimeout(refTimeout.current);
    }
    refTimeout.current = setTimeout(() => {
      if (!loading && data?.length && ref.current) {
        const firstCheckedEl = ref.current.querySelector('[data-checked]');
        if (firstCheckedEl) {
          const refPosition = ref.current.getBoundingClientRect().top;
          const firstCheckedElPosition = firstCheckedEl.getBoundingClientRect().top;
          ref.current.scrollTo({
            top: firstCheckedElPosition - refPosition,
            behavior: 'smooth',
          });
        }
      }
    }, SCROLL_DELAY);
  }, [loading, data]);

  return (
    <Flex direction="column" className={styles.comparisonColumn}>
      <Stack gap="xs" px="xl" pt="xl" mb="xs">
        <CollapseSearch />
        <CollapseButtons />
      </Stack>
      {loading ? (
        <Center p="lg">
          <Spinner />
        </Center>
      ) : data?.length ? (
        <Flex flex="1" align="stretch">
          <ScrollArea.Autosize
            viewportRef={ref}
            scrollbars="y"
            flex="1"
            offsetScrollbars="y"
            onScrollPositionChange={onScroll}
            className={styles.viewportContainer}
            classNames={{
              root: cn(styles.viewport, {
                [styles.viewportPingTop]: pingedTop,
                [styles.viewportPingBottom]: pingedBottom,
              }),
            }}
          >
            <Box className={styles.tree} pl="xl" pb="xl">
              {data.map((item, idx) => (
                <ItemContainer
                  key={item.cid}
                  data={item}
                  depth={0}
                  lastChild={idx === data.length - 1}
                  hideConnectors={[]}
                />
              ))}
            </Box>
          </ScrollArea.Autosize>
        </Flex>
      ) : (
        <AccAlert mt="xs" mx="xl" severity="info" singleLine title={t('No results found')}>
          <AccText size="sm" fw={400}>
            {t('Try different search terms.')}
          </AccText>
        </AccAlert>
      )}
    </Flex>
  );
};

export default CollapseGroups;
