import { useCallback } from 'react';
import isFunction from 'lodash/isFunction';
import { makeAutoObservable, toJS } from 'mobx';
import { useEffectOnce } from 'Components/DataTable/hooks/useEffectOnce';
import { useValueStore } from 'Hooks/mobx/useValueStore';

class SelectedNodes<T = any> {
  constructor() {
    makeAutoObservable(this);
  }

  selectedNodes: T[] = [];

  setSelectedNodes = (nodeUpdates: T[] | ((a: T[]) => T[])) => {
    if (isFunction(nodeUpdates)) {
      this.selectedNodes = nodeUpdates(toJS(this.selectedNodes)).map((e) => ({
        ...e,
        // removing children required to prevent expensive mobx wrapper for all children
        children: null,
      }));
    } else {
      this.selectedNodes =
        (nodeUpdates as T[])?.map?.((e) => ({
          ...e,
          // removing children required to prevent expensive mobx wrapper for all children
          children: null,
        })) ?? [];
    }
  };
}

const selectedStore = new SelectedNodes();

export const useSelectedNodes = () => {
  return {
    setSelectedNodes: selectedStore.setSelectedNodes,
    selectedNodes: toJS(selectedStore.selectedNodes),
    getSelectedNodes: () => toJS(selectedStore.selectedNodes),
  };
};

export const useClearSelectedOnUnmount = () => {
  const { setSelectedNodes } = useSelectedNodes();
  return useEffectOnce(() => () => setSelectedNodes([]));
};
/**
 * Used to re-render only if selected changed
 */
export const useIsSelected = (id: string, expandDataKey: string = 'id') => {
  const getIsSelected = useCallback(
    (items): boolean => {
      return items?.some((e) => e[expandDataKey] === id);
    },
    [id, expandDataKey],
  );
  return useValueStore(selectedStore, 'selectedNodes', getIsSelected);
};

export const useIsSelectLimitReached = (selectLimit?: number) => {
  const getIsSelectLimitReached = useCallback(
    (items): boolean => {
      if (selectLimit === undefined) return false;
      return items?.length >= selectLimit;
    },
    [selectLimit],
  );

  return useValueStore(selectedStore, 'selectedNodes', getIsSelectLimitReached);
};
