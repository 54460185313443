import { DomainNode } from 'Ghql';

export const UPDATE_DOMAIN = 'update_domain';
export const updateDomain = (domainId: string, body: Partial<Record<keyof DomainNode, any>>) => ({
  type: UPDATE_DOMAIN,
  payload: {
    domainId,
    body,
  },
});

export type DomainsAction = ReturnType<typeof updateDomain>;
