import { Center, HoverCard, Indicator, Menu } from '@mantine/core';
import { IconPlugConnected } from '@tabler/icons-react';
import AccBadge from 'Components/AccBadge';
import AccButton from 'Components/AccButton/AccButton';
import AccText from 'Components/Text/AccText';
import { useModal } from 'Hooks/base/useModal';
import { useDomainId, useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import { DomainTypeChoices } from 'Query';
import { t, tct } from 'Utilities/i18n';

export const useShowConnectToGscButton = () => {
  const { domainInfo } = useQueryDomainInfo();

  const isGscConnected = domainInfo?.isGscConnected;

  const isDemoDomain = domainInfo?.isDemoDomain;

  const isYoutubeChannel = domainInfo?.youtubeChannelName;

  const isNaverDomain = domainInfo?.domainType === DomainTypeChoices.A_7;

  if (!domainInfo || isGscConnected || isDemoDomain || isYoutubeChannel || isNaverDomain) {
    return false;
  }
  return true;
};

export const ConnectToGSCHovercard = () => {
  const domainId = useDomainId();
  const showConnectToGscButton = useShowConnectToGscButton();
  const { showModal } = useModal();

  if (!showConnectToGscButton) {
    return null;
  }

  const handleConnectToGSC = () => {
    console.log('calling show modal');
    showModal({
      modalType: 'ConnectToGSC',
      modalTheme: 'light',
      modalProps: {
        domainId,
      },
    });
  };

  return (
    <HoverCard width={280}>
      <HoverCard.Target>
        <Menu.Item onClick={handleConnectToGSC}>
          <Indicator processing position={'middle-start'}>
            <AccBadge type={'warning'} variant={'gradient'} size={'md'} ml={12}>
              {t('Improve AI Search Volume')}
            </AccBadge>
          </Indicator>
        </Menu.Item>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <AccText mb={12}>
          {tct(
            'Connect to to Google Search console to improve the accuracy of the AI Search Volume estimates next time they are updated (~15th of each month). Learn more [link:here].',
            {
              link: (
                <a
                  href="https://www.accuranker.com/help/keywords/ai-search-volume"
                  target="_blank"
                  rel="noreferrer"
                />
              ),
            },
          )}
        </AccText>
        {}{' '}
        <Center>
          <AccButton
            onClick={handleConnectToGSC}
            leftSection={<IconPlugConnected size={18} />}
            variant="secondary"
          >
            {t('Connect to Search Console')}
          </AccButton>
        </Center>
      </HoverCard.Dropdown>
    </HoverCard>
  );
};
