import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { useModal } from 'Hooks/base/useModal';
import { t, tct } from 'Utilities/i18n';
import OrgNoteForm from './OrgNoteForm/OrgNoteForm';

type Props = {
  organizationId: string;
  noteId?: string;
  message?: string;
  onUpdate: () => void;
};

const OrganizationNote = (props: Props) => {
  const { organizationId, noteId, message, onUpdate } = props;
  const { hideModal } = useModal();

  const mode = noteId ? 'Edit' : 'Add';

  return (
    <ModalBorder title={tct('[mode] Note', { mode: t(mode) })} onClose={hideModal}>
      <OrgNoteForm
        organizationId={organizationId}
        noteId={noteId}
        message={message}
        mode={noteId ? 'Edit' : 'Add'}
        onUpdate={() => {
          hideModal();
          onUpdate();
        }}
      />
    </ModalBorder>
  );
};

export default OrganizationNote;
