import ColumnChecklistEditor from 'Components/Filters/Common/ColumnChecklistEditor';
import { someOfOptionsWithChildren } from 'Components/Filters/Common/labelFunc';
import { FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import type { PageSerpFeaturesOwnedFilter } from 'Types/Filter';
import { t } from 'Utilities/i18n/index';
import Icon from 'icons/window-checked.svg?inline';
import {
  GOOGLE_SEARCH_ENGINE_ID,
  YOUTUBE_SEARCH_ENGINE_ID,
  getSerpFeatureData,
  getSerpFeaturesGroups,
} from '../../../Table/TableRow/SERPOptions/data';
import './serp_features.scss';

const defaultValue: PageSerpFeaturesOwnedFilter = {
  attribute: FilterAttribute.PAGE_SERP_FEATURES_OWNED,
  type: FilterValueType.ARRAY,
  comparison: FilterComparison.ANY,
  value: [],
};

const getData = () => {
  const items = [
    {
      value: '1',
      label: t('Google'),
      children: getSerpFeaturesGroups(GOOGLE_SEARCH_ENGINE_ID)
        ?.filter((x) => x?.owned)
        .map((s) => {
          const serp = getSerpFeatureData(s?.id || '');
          return {
            label: serp.label,
            value: serp.id,
            Icon: serp.icon,
          };
        }),
    },
    {
      value: '2',
      label: t('YouTube'),
      children: getSerpFeaturesGroups(YOUTUBE_SEARCH_ENGINE_ID)
        ?.filter((x) => x?.owned)
        .map((s) => {
          const serp = getSerpFeatureData(s?.id || '');
          return {
            label: serp.label,
            value: serp.id,
            Icon: serp.icon,
          };
        }),
    },
  ];
  return {
    defaultValue,
    title: t('SERP Features owned'),
    icon: Icon,
    isLargeFilter: true,
    editor: ColumnChecklistEditor,
    editorProps: {
      items,
      showSearchBox: true,
      className: 'serp_features',
    },
    labelFunc: someOfOptionsWithChildren(items),
  };
};

export default getData;
