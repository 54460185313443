import { useCallback } from 'react';
import { useStore } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import { TableFetchDataCallBack } from 'Components/DataTable';
import {
  OverviewNotificationsDocument,
  OverviewNotificationsQuery,
  OverviewNotificationsQueryVariables,
} from 'Ghql';
import { selectCurrentFilters } from 'Selectors/FilterSelector';

export const useFetchOverviewNotifications = (): TableFetchDataCallBack => {
  const client = useApolloClient();
  const store = useStore();
  return useCallback(
    ({ ordering, pagination }: any) => {
      const filters = selectCurrentFilters(store.getState());

      return client
        .query<OverviewNotificationsQuery, OverviewNotificationsQueryVariables>({
          query: OverviewNotificationsDocument,
          variables: {
            filters,
            pagination,
            ordering,
          },
        })
        .then((e) => ({
          data: e.data.notifications?.notifications ?? [],
          length: e.data?.notifications?.pagination?.numResults ?? 0,
        }));
    },
    [client, store],
  );
};
