import { memo } from 'react';
import { NavigateFunction } from 'react-router';
import { Link } from 'react-router-dom';
import { Collapse, Menu } from '@mantine/core';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import cn from 'classnames';
import config from 'config';
import ConditionalLink from 'Components/ConditionalLink/ConditionalLink';
import linkWithFilters from 'Components/Filters/linkWithFilters';
import AccText from 'Components/Text/AccText';
import { NavItem } from '../../../support/types';
import styles from './nav-item.module.scss';

type Props = {
  item: NavItem;
  navbarExpanded: boolean;
  activeKey?: string;
  itemExpanded: boolean;
  onExpand: (key?: string) => void;
  onNavigate: NavigateFunction;
};

const NavigationItem = (props: Props) => {
  const { item, navbarExpanded, activeKey, itemExpanded, onExpand, onNavigate } = props;

  const showSubpages = navbarExpanded && !!item?.subPages;

  const handleToggleExpand = () => {
    itemExpanded ? onExpand(undefined) : onExpand(item.key);
  };

  const handleChildClick = (link: string, external?: boolean) => {
    if (external) {
      window.open(`${config.baseUrl}${link}`, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <div className={styles.navigationItemContainer}>
      <Menu
        offset={4}
        trigger="hover"
        openDelay={50}
        closeDelay={150}
        position="right-start"
        withinPortal
      >
        <Menu.Target>
          <Link
            to={item.link}
            onClick={handleToggleExpand}
            tabIndex={0}
            className={cn(styles.parentNavigationItem, {
              [styles.active]:
                (item.link === activeKey && !showSubpages) || // active item without children
                (item.subPages?.some((page) => page.link === activeKey) &&
                  (!itemExpanded || !navbarExpanded)), // collapsed item with active child
            })}
          >
            <div className={styles.iconContainer}>
              <item.icon />
            </div>

            {navbarExpanded && (
              <AccText fw={500} c="white">
                {item.label}
              </AccText>
            )}

            {showSubpages && (
              <span
                style={{ marginLeft: 'auto' }}
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  handleToggleExpand();
                }}
              >
                {itemExpanded ? <IconChevronUp color="white" /> : <IconChevronDown color="white" />}
              </span>
            )}
          </Link>
        </Menu.Target>

        {!navbarExpanded && (
          <Menu.Dropdown className={styles.dropdownMenu}>
            {item?.subPages ? (
              <Menu.Label>{item.label}</Menu.Label>
            ) : (
              <Menu.Label>{item.label}</Menu.Label>
            )}
            {item?.subPages?.map((page) => {
              return (
                <Menu.Item
                  className={cn(styles.dropdownSubNavigationItem, {
                    [styles.active]: page.link === activeKey,
                  })}
                  onClick={() => onNavigate(page.link)}
                  key={page.link}
                >
                  {page.label}
                </Menu.Item>
              );
            })}
          </Menu.Dropdown>
        )}
      </Menu>

      {showSubpages && (
        <Collapse in={itemExpanded}>
          <div className={styles.expandContainer}>
            {item.subPages?.map((page) => {
              const link = page.filterSet
                ? linkWithFilters({ to: page.link, filterSet: page.filterSet })
                : page.link;
              return (
                <ConditionalLink
                  to={!page?.external ? link : undefined}
                  key={page.label + page.link}
                >
                  <AccText
                    c="white"
                    fw={500}
                    onClick={() => handleChildClick(link, page?.external)}
                    onKeyDown={(event) =>
                      event.key === 'Enter' && handleChildClick(link, page?.external)
                    }
                    className={cn(styles.subNavigationItem, {
                      [styles.active]:
                        page.link === activeKey ||
                        page.extraActiveRoutes?.some((route) => route === activeKey),
                    })}
                  >
                    {page.label}
                  </AccText>
                </ConditionalLink>
              );
            })}
          </div>
        </Collapse>
      )}
    </div>
  );
};

export default memo(NavigationItem, (prevProps, nextProps) => {
  return (
    prevProps.activeKey === nextProps.activeKey &&
    prevProps.navbarExpanded === nextProps.navbarExpanded &&
    prevProps.itemExpanded === nextProps.itemExpanded
  );
});
