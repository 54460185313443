import { t } from 'Utilities/i18n';

const getItems = () => {
  return [
    {
      id: '1',
      value: 'Latin',
      label: t('Latin'), //  (a, b, c, ...)
      icon: () => null,
    },
    {
      id: '2',
      value: 'Arabic',
      label: t('Arabic'), // (أ, ب, ج, ...)
      icon: () => null,
    },
    {
      id: '3',
      value: 'Cyrillic',
      label: t('Cyrillic'), //  (а, б, в, ...)
      icon: () => null,
    },
    {
      id: '4',
      value: 'Devanagari', // Hindi
      label: t('Devanagari'), // (अ, आ, इ, ...)
      icon: () => null,
    },
    {
      id: '5',
      value: 'Hangul', // Korean
      label: t('Hangul'), //  (한글)
      icon: () => null,
    },
    {
      id: '6',
      value: 'Han', // Chinese
      label: t('Han'), //  (漢字)
      icon: () => null,
    },
    {
      id: '7',
      value: 'Hiragana', // Japanese
      label: t('Hiragana'), //  (ひらがな)
      icon: () => null,
    },
    {
      id: '8',
      value: 'Katakana', // Japanese
      label: t('Katakana'), //  (カタカナ)
      icon: () => null,
    },
  ];
};
export default getItems;
