import isNumber from 'lodash/isNumber';
import { CompareChartInputVariables } from 'Components/Chart/CompareChartData/support/types';
import { SeriesItem } from 'Components/Chart/LineChart';
import { LandingChartQuery, LandingChartQueryVariables } from 'Query';
import { t } from 'Utilities/i18n';
import { LandingTableIDs } from '../../KeywordLandingTable/support/constants';
import { NaverBlacklist } from '../../KeywordLandingTable/support/hooks';

type LandingPagesMetricLabel = { label: string; value: string };

export const getLandingMetrics =
  (enabledRevenue?: boolean, isNaverDomain?: boolean) => (): LandingPagesMetricLabel[] => {
    return [
      {
        label: t('Share of Voice'),
        value: LandingTableIDs.SHARE_OF_VOICE,
      },
      ...(enabledRevenue
        ? [
            {
              label: t('Revenue'),
              value: 'analytics_avg_revenue',
            },
          ]
        : []),
      {
        label: t('Search volume'),
        value: LandingTableIDs.SEARCH_VOLUME,
      },
      {
        label: t('Traffic value'),
        value: LandingTableIDs.COST_VALUE,
      },

      {
        label: t('AI SoV'),
        value: LandingTableIDs.ORGANIC_TRAFFIC,
      },

      {
        label: t('Average CTR'),
        value: LandingTableIDs.DYNAMIC_CTR,
      },
    ].filter((metric) => !isNaverDomain || !NaverBlacklist.includes(metric.value));
  };

export const extractLandingChartData = (res?: LandingChartQuery): SeriesItem[] =>
  res?.graphs?.landingPageHistory?.filter(Boolean)?.map((e) => {
    const item: SeriesItem = {
      name: e?.path ?? '',
      data:
        e?.days
          ?.filter((el) => isNumber(el?.value))
          ?.map((el) => ({
            x: new Date(el?.searchDate).getTime() ?? 0,
            y: el?.value ?? 0,
          })) ?? [],
    };

    return item;
  }) ?? [];

export const extractLandingChartVariables = ({
  selectedRows,
  ...variables
}: CompareChartInputVariables): LandingChartQueryVariables => ({
  ...variables,
  landingPages: selectedRows,
});
