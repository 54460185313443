import { memo, useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { Menu } from '@mantine/core';
import { IconMenu2 } from '@tabler/icons-react';
import cn from 'classnames';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import QuickNavigation from 'Components/QuickNavigation/QuickNavigation';
import useUserPermission from 'Hooks/useUserPermission';
import ServiceMessageStatus from 'Pages/Layout/DashboardTemplate/SideNavbar/components/NavbarHeader/ServiceMessageStatus';
import { t } from 'Utilities/i18n';
import AccurankerLogo from 'icons/logo-brand.svg?inline';
import AccurankerLogoAndText from 'icons/logo.svg?inline';
import { useMenuItems } from './useMenuItems';
import styles from './navbar-header.module.scss';

type Props = {
  navbarCollapsed: boolean;
};

const NavbarHeader = (props: Props) => {
  const { navbarCollapsed } = props;
  const { hasAnyRole } = useUserPermission();
  const navigate = useNavigate();

  const [hideTooltip, setHideTooltip] = useState(false);

  const { menuItems } = useMenuItems();

  return (
    <div className={cn(styles.navbarHeaderContainer, { [styles.collapsed]: navbarCollapsed })}>
      <div className={styles.navbarHeaderIcons}>
        <Link to={'/domains'} className={styles.logo}>
          {navbarCollapsed ? <AccurankerLogo /> : <AccurankerLogoAndText />}
        </Link>

        <ServiceMessageStatus />

        <Menu
          position="right-start"
          offset={18}
          keepMounted={false}
          withinPortal
          data-testid="main-sidenavbar-menu"
          onChange={setHideTooltip}
          classNames={{
            dropdown: styles.dropdownMenu,
            item: styles.dropdownItem,
            itemSection: styles.dropdownItemIcon,
          }}
        >
          <Menu.Target>
            <div className={styles.hamburgerMenu}>
              <AccTooltip tooltip={t('Profile, Messages and more...')} disable={hideTooltip}>
                <IconMenu2 />
              </AccTooltip>
            </div>
          </Menu.Target>

          <Menu.Dropdown className={styles.dropdownMenu}>
            {menuItems.map((item, idx) => {
              return (
                <Menu.Item
                  leftSection={item.icon}
                  onClick={() => (item.link ? navigate(item.link) : item?.onSelect?.())}
                  // eslint-disable-next-line react/no-array-index-key
                  key={idx}
                >
                  {item.label}
                </Menu.Item>
              );
            })}
          </Menu.Dropdown>
        </Menu>
      </div>

      {hasAnyRole && <QuickNavigation collapsed={navbarCollapsed} />}
    </div>
  );
};

export default memo(NavbarHeader);
