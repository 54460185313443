import * as React from 'react';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import compose from 'lodash/flowRight';
import { Field, reduxForm } from 'redux-form';
import AccButton from 'Components/AccButton/AccButton';
import { TextField } from 'Components/Forms/Fields';
import FormErrors from 'Components/Forms/FormErrors';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import toast from 'Hooks/useToast';
import { throwNetworkError, throwSubmitErrors } from 'Utilities/errors';
import { t, tct } from 'Utilities/i18n';
import Validator from 'Utilities/validation';
import formStyles from 'css/layout/form-layout.module.scss';

type FormValues = {
  username: string;
  secret: string;
  description: string;
};
type Props = {
  handleSubmit: (...args: Array<any>) => any;
  onSubmit?: (...args: Array<any>) => any;
  addAdobeMarketingConnection: (...args: Array<any>) => any;
  onCancel: (...args: Array<any>) => any;
  submitting: boolean;
  refresh?: (...args: Array<any>) => any;
};

class ConnectAdobeAccount extends React.Component<Props> {
  onSubmit = (values: FormValues) => {
    const { refresh } = this.props;
    const { username, secret, description } = values;
    return this.props
      .addAdobeMarketingConnection({
        variables: {
          input: {
            username,
            secret,
            description,
          },
        },
      })
      .then(
        ({
          data: {
            addAdobeMarketingConnection: { errors, connection },
          },
        }) => {
          if (errors && errors.length) {
            throwSubmitErrors(errors);
          }

          refresh && refresh();
          this.props.onSubmit &&
            this.props.onSubmit({
              connectionId: connection.id,
            });
        },
        throwNetworkError,
      )
      .catch((error) => {
        toast.error(t('Failed to add connection'));
        throw error;
      });
  };

  render() {
    const { handleSubmit, submitting } = this.props;
    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <p className="alert alert-info">
          {tct(
            'If you are having trouble setting up your Adobe Analytics account, then we have a guide on how to get started which you can read [link:here].',
            {
              link: (
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.accuranker.com/help/integrations/adobe-analytics"
                />
              ),
            },
          )}
        </p>

        <FormErrors />
        <div className={formStyles.formRow}>
          <div className="form-label required">{t('User Name')}</div>
          <Field
            name="username"
            placeholder={t('Name')}
            component={TextField}
            disabled={submitting}
            validate={Validator.required}
            autoFocus={true}
          />
        </div>
        <div className={formStyles.formRow}>
          <div className="form-label required">{t('Shared Secret')}</div>
          <Field
            name="secret"
            placeholder={t('Secret')}
            component={TextField}
            disabled={submitting}
            validate={Validator.required}
          />
        </div>
        <div className={formStyles.formRow}>
          <div className="form-label required">{t('Description')}</div>
          <Field
            name="description"
            placeholder={t('Description')}
            component={TextField}
            disabled={submitting}
            validate={Validator.required}
          />
        </div>
        <ModalFooter
          primaryButtonSlot={
            <AccButton variant="primary" disabled={submitting} type="submit">
              {t('Add connection')}
            </AccButton>
          }
          secondaryButtonSlot={
            <AccButton variant="tertiary" disabled={submitting} onClick={this.props.onCancel}>
              {t('Cancel')}
            </AccButton>
          }
        />
      </form>
    );
  }
}

const addAdobeMarketingConnectionQuery = gql`
  mutation connectAdobeAccount_addAdobeMarketingConnection(
    $input: AddAdobeMarketingConnectionInput!
  ) {
    addAdobeMarketingConnection(input: $input) {
      connection {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`;
export default compose(
  graphql(addAdobeMarketingConnectionQuery, {
    name: 'addAdobeMarketingConnection',
  }),
  reduxForm({
    form: 'ConnectAdobeAccount',
  }),
)(ConnectAdobeAccount) as any;
