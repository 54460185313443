import { createSelector } from 'reselect';
import type { FilterBase } from 'Types/Filter';
import type { FilterSet } from 'Types/FilterSet';
import { isNonRequiredFilter, isRequiredFilter, isShownInFilterBar } from 'Types/FilterSet';
import { StoreType } from 'Types/Store';

export const filtersSelector = (state: StoreType) => state.filter.filterGroup.filters;

const filterSetSelector = (state: StoreType) => state.filter.filterSet;

type FilterFunction = (filterAttribute: string, filterSet: FilterSet) => boolean;
type TransformFunction = (filter: FilterBase) => FilterBase;
type FilterSelectorFactoryArgs = {
  filterFunction?: FilterFunction;
  transformFunction?: TransformFunction;
  preformatFilters?: any;
};

const FilterSelectorFactory = ({
  filterFunction,
  transformFunction,
  preformatFilters,
}: FilterSelectorFactoryArgs) =>
  createSelector([filtersSelector, filterSetSelector], (filters, filterSet) => {
    let result = preformatFilters ? preformatFilters(filters) : filters;
    result = filterFunction
      ? result.filter((filter) => filterFunction(filter.attribute, filterSet))
      : result;
    result = transformFunction
      ? result.reduce((acc, filter) => {
          acc.push(transformFunction(filter));
          return acc;
        }, [])
      : result;
    return result;
  });

export const NonRequiredFiltersSelector = FilterSelectorFactory({
  filterFunction: isNonRequiredFilter,
});
export const RequiredFiltersSelector = FilterSelectorFactory({
  filterFunction: isRequiredFilter,
});
export const VisibleFiltersSelector = FilterSelectorFactory({
  filterFunction: isShownInFilterBar,
});
