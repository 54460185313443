import AccTooltip from 'Components/AccTooltip/AccTooltip';
import SERPCell from 'Pages/Keywords/Groupings/ViewMode/components/cells/SERPCell';
import { getDomainId, getFolderTagPath } from 'Utilities/generateSelectId';
import { t } from 'Utilities/i18n';

type Props = {
  path: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export const SERPAction = ({ path, isOpen, setIsOpen }: Props) => {
  const processedPath = getFolderTagPath(path);
  const domainId = getDomainId(path);
  if (!processedPath || !domainId) return null;
  return (
    <AccTooltip tooltip={t('Show SERP features')} disable={isOpen}>
      <div onClick={(e) => e.stopPropagation()}>
        <SERPCell path={processedPath} isOpen={isOpen} setIsOpen={setIsOpen} domainId={domainId} />
      </div>
    </AccTooltip>
  );
};
