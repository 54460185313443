import { memo, useMemo } from 'react';
import { Flex, useMantineTheme } from '@mantine/core';
import AccLazyPopover from 'Components/AccLazyPopover/AccLazyPopover';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import FormatNumber from 'Components/FormatNumber/new';
import Loader from 'Components/Loader';
import { useAvgTopTenCtrByPathLazyQuery, useAvgTopTenCtrTreeLazyQuery } from 'Ghql';
import { useFilters } from 'Hooks';
import { FolderColumnID } from 'Pages/Keywords/Groupings/support/constants';
import { BarChart } from 'Pages/Keywords/Overview/components/BarChart/BarChart';
import { FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import { propsIsEqualComparison } from 'Utilities/compare';
import { t } from 'Utilities/i18n';

type Props = {
  maxCtr: number | undefined;
  path: string;
  domainId?: string;
  groupOrDomainLevel?: boolean;
};
const FoldersMaxCtrCell = ({ maxCtr, path, domainId, groupOrDomainLevel }: Props) => {
  const globalFilters = useFilters();
  const filters = useMemo(() => {
    let result = [...globalFilters];
    if (domainId && !groupOrDomainLevel) {
      result = result.filter((filter) => filter.attribute !== FilterAttribute.DOMAINS);
      result.push({
        attribute: FilterAttribute.DOMAINS,
        type: FilterValueType.LIST,
        comparison: FilterComparison.CONTAINS,
        value: [domainId],
      });
    }
    return result;
  }, [globalFilters, domainId]);
  const [fetchData, { data, loading }] = useAvgTopTenCtrByPathLazyQuery();
  const [fetchDomainLevelData, { data: domainData, loading: domainDataLoading }] =
    useAvgTopTenCtrTreeLazyQuery();

  let items: {
    value: number;
    label: string;
    id: number;
    background: string;
  }[] = [];

  const theme = useMantineTheme();

  if (!maxCtr) {
    return (
      <Flex align="center" justify="end">
        -
      </Flex>
    );
  }

  if (!groupOrDomainLevel && data?.avgTopTenCtrByPath) {
    items = data.avgTopTenCtrByPath?.avgTopTenCtrByPath?.[path]?.map((item) => {
      return {
        value: item.avg_ctr,
        label: t('Rank ') + item.rank,
        id: item.rank,
        background: theme.colors.snorlax[theme.primaryShade as number],
      };
    });
  }

  const groupOrDomainLevelRanks:
    | { [key: string]: { avg_ctr: number; rank: number }[] }[]
    | undefined = domainData?.charts?.avgTopTenCtrTree;

  if (groupOrDomainLevelRanks?.length) {
    const top10 = groupOrDomainLevelRanks.find((tree) => {
      if (tree) {
        const treeKeys = Object.keys(tree);
        return treeKeys.some((key) => key === path);
      }
      return false;
    });
    if (top10?.[path]?.length) {
      items = top10[path].map((item) => {
        return {
          value: item.avg_ctr,
          label: t('Rank ') + item.rank,
          id: item.rank,
          background: theme.colors.snorlax[theme.primaryShade as number],
        };
      });
    }
  }

  return (
    <AccLazyPopover
      dropdownTitle={t('Average CTR by rank')}
      width={350}
      outerContainerProps={{ justify: 'end' }}
      target={
        <button
          className={'table-link-like'}
          key={FolderColumnID.DYNAMIC_CTR_MAX}
          onClick={() => {
            if (groupOrDomainLevel) {
              fetchDomainLevelData({ variables: { filters } });
              return;
            }
            fetchData({ variables: { filters } });
          }}
        >
          <AccTooltip tooltip={t('Show average CTR by rank')}>
            <Flex align="center" justify="end">
              <FormatNumber
                value={maxCtr}
                style="percent"
                maximumFractionDigits={1}
                minimumFractionDigits={1}
              />
            </Flex>
          </AccTooltip>
        </button>
      }
    >
      {loading || domainDataLoading ? (
        <Loader
          style={{
            height: 350,
          }}
        />
      ) : (
        <BarChart normalNumberOfItems={10} items={items} formatNumberStyle={'percent'} />
      )}
    </AccLazyPopover>
  );
};

export default memo(FoldersMaxCtrCell, propsIsEqualComparison);
