import * as React from "react";
const SvgZoomIn = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", x: "0px", y: "0px", width: "24px", height: "24px", viewBox: "0 0 24 24", style: {
  enableBackground: "new 0 0 24 24"
}, xmlSpace: "preserve", ...props }, /* @__PURE__ */ React.createElement("g", { id: "Frame_-_24px" }, /* @__PURE__ */ React.createElement("rect", { y: 0, style: {
  fill: "none"
}, width: 24, height: 24 })), /* @__PURE__ */ React.createElement("g", { id: "Line_Icons" }, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#a4a6b3"
}, points: "11,6 9,6 9,9 6,9 6,11 9,11 9,14 11,14 11,11 14,11 14,9 11,9  " }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#a4a6b3"
}, d: "M21.707,20.293L16.314,14.9C17.403,13.504,18,11.799,18,10c0-2.137-0.833-4.146-2.344-5.656 C14.146,2.832,12.137,2,10,2S5.854,2.832,4.344,4.344C2.833,5.854,2,7.863,2,10s0.833,4.146,2.344,5.656 C5.854,17.168,7.863,18,10,18c1.799,0,3.504-0.596,4.9-1.686l5.393,5.392L21.707,20.293z M10,16c-1.603,0-3.109-0.624-4.242-1.757 C4.624,13.11,4,11.603,4,10c0-1.602,0.624-3.109,1.758-4.242C6.891,4.624,8.397,4,10,4s3.109,0.624,4.242,1.758 C15.376,6.891,16,8.398,16,10c0,1.603-0.624,3.11-1.758,4.243C13.109,15.376,11.603,16,10,16z" }))));
export default SvgZoomIn;
