import { useLayoutEffect, useRef, useState } from 'react';
import {
  SegmentedControl,
  SegmentedControlProps,
  SegmentedControlStylesNames,
} from '@mantine/core';
import cn from 'classnames';
import { EventName, TrackingKey, useMixpanel } from 'Utilities/Analytics/mixpanel';
import styles from './accSegmentedControl.module.scss';

export type AccSegmentedControlProps = Omit<SegmentedControlProps, 'classNames'> & {
  classNames?: Partial<Record<SegmentedControlStylesNames, string>>;
  trackingKey?: TrackingKey;
  /** Animated state transitions on the component. Defaults to `false` */
  animated?: boolean;
  /** Run this method when the component is initialized */
  onInitialized?: (args?: any[]) => void;
};

const AccSegmentedControl = (props: AccSegmentedControlProps) => {
  const {
    children,
    classNames,
    onChange,
    trackingKey,
    animated = false,
    onInitialized,
    ...rest
  } = props;

  const trackEvent = useMixpanel();

  const elementRef = useRef<HTMLDivElement>(null);

  // SegmentedControl does some logic internally and is not "ready" before data-initialized is true
  // thus, we force a rerender once the component was initialized
  const [isFirstRender, setIsFirstRender] = useState(true);

  useLayoutEffect(() => {
    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'data-initialized' &&
          mutation.target instanceof HTMLDivElement
        ) {
          const initialized = mutation.target.getAttribute('data-initialized') === 'true';
          if (initialized && isFirstRender) {
            setIsFirstRender(false);
            onInitialized?.();
          }
        }
      });
    });

    if (elementRef.current) {
      observer.observe(elementRef.current, { attributes: true });
    }

    return () => {
      observer.disconnect();
    };
  }, [onInitialized, isFirstRender]);

  const trackChange = (v) => {
    const text = v;
    const extraProps = {
      ...(text ? { Text: text } : {}),
      ...(trackingKey ? { 'Tracking Key': trackingKey } : {}),
    };
    trackEvent(EventName.ButtonClick, extraProps);
  };

  return (
    <SegmentedControl
      color="snorlax"
      radius={8}
      ref={elementRef}
      transitionDuration={animated ? 200 : 0}
      onChange={(value) => {
        trackChange(value);
        onChange?.(value);
      }}
      {...rest}
      classNames={{
        ...classNames,
        root: cn(styles.root, classNames?.root),
        label: cn(styles.label, classNames?.label),
        indicator: cn(styles.indicator, classNames?.indicator),
        control: cn(styles.control, classNames?.control),
        input: cn(styles.input, classNames?.input),
      }}
    >
      {children}
    </SegmentedControl>
  );
};
export default AccSegmentedControl;
