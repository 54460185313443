import * as React from 'react';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import { Progress } from '@mantine/core';
import compose from 'lodash/flowRight';
import AccButton from 'Components/AccButton/AccButton';
import toast from 'Hooks/useToast';
import { t } from 'Utilities/i18n';

type Props = {
  addAdobeAccount: (...args: Array<any>) => any;
  onSubmit: (...args: Array<any>) => any;
  onCancel: (...args: Array<any>) => any;
  connectionId: string;
  suiteId: string;
  domainId: string;
};
type State = {
  loading: boolean;
  error: string;
};

class AddAdobeAccount extends React.Component<Props, State> {
  state = {
    error: '',
    loading: true,
  };

  componentDidMount() {
    const { domainId, connectionId, suiteId } = this.props;
    this.props
      .addAdobeAccount({
        variables: {
          input: {
            domainId,
            connectionId,
            suiteId,
          },
        },
      })
      .then(
        ({
          data: {
            addAdobeMarketingAccount: { domain: domainObj },
          },
        }) => {
          toast.success(t('Account connected'));
          this.props.onSubmit(domainObj);
        },
        () => {
          this.setState({
            error: t('Failed to connect to Adobe Analytics'),
          });
        },
      )
      .then(() => {
        this.setState({
          loading: false,
        });
      });
  }

  render() {
    const { loading, error } = this.state;
    let content: any = null;

    if (loading) {
      content = <Progress size="lg" animated value={100} />;
    }

    if (error) {
      content = (
        <div>
          <p>{error}</p>
          <AccButton variant="primary" onClick={this.props.onCancel}>
            {t('Cancel')}
          </AccButton>
        </div>
      );
    }

    return <div className="add-adobe-account">{content}</div>;
  }
}

const addAdobeAccount = gql`
  mutation addAdobeAccount_addAdobeAccount($input: AddAdobeMarketingAccountInput!) {
    addAdobeMarketingAccount(input: $input) {
      domain {
        id
      }
    }
  }
`;
export default compose(
  graphql(addAdobeAccount, {
    name: 'addAdobeAccount',
  }),
)(AddAdobeAccount);
