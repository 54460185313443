import { useEffect, useState } from 'react';
import { useInterval } from '@mantine/hooks';

const fetchGrumpMood = async () =>
  fetch('https://webservices.servers.ac/webservices/grump/tiger_mood').then((response) =>
    response.text(),
  );

/** Ping AccuRanker webservices API every 5 minutes to get grump mood **/
export const useGrumpMood = () => {
  const [grumpyMood, setGrumpyMood] = useState('chilled');

  const setMood = async () => {
    const mood = await fetchGrumpMood();
    if (['furious', 'cautious', 'chilled', 'grumpy'].includes(mood)) {
      setGrumpyMood(mood);
    }
  };

  const interval = useInterval(() => {
    setMood();
  }, 1000 * 60 * 5); // 5 minutes

  useEffect(() => {
    setMood();
    interval.start();
    return interval.stop;
  }, []);
  return grumpyMood;
};
