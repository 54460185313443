import cn from 'classnames';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { t } from 'Utilities/i18n';
import FacebookIcon from 'icons/social-media/facebook_circle.svg?inline';
import InstagramIcon from 'icons/social-media/instagram_circle.svg?inline';
import LinkedInIcon from 'icons/social-media/linkedin_circle.svg?inline';
import TikTokIcon from 'icons/social-media/tiktok_circle.svg?inline';
import XIcon from 'icons/social-media/x_circle.svg?inline';
import styles from './some-styles.module.scss';

// names set to match backend object
export enum SoMePlatform {
  X = 'x_handle',
  FACEBOOK = 'facebook_profile',
  LINKEDIN = 'linkedin_profile',
  TIKTOK = 'tiktok_profile',
  INSTAGRAM = 'instagram_profile',
}

type Props = {
  onIconToggle: (platform: SoMePlatform) => void;
  activeSocials: SoMePlatform[];
};

const SocialMediaIcons = (props: Props) => {
  const { onIconToggle, activeSocials } = props;

  const soMeConfig = [
    {
      platform: SoMePlatform.FACEBOOK,
      icon: () => <FacebookIcon width="36px" height="36px" />,
      toolTip: t('Facebook'),
    },
    {
      platform: SoMePlatform.LINKEDIN,
      icon: () => <LinkedInIcon width="36px" height="36px" />,
      toolTip: t('LinkedIn'),
    },
    {
      platform: SoMePlatform.X,
      icon: () => <XIcon width="36px" height="36px" />,
      toolTip: t('X'),
    },
    {
      platform: SoMePlatform.TIKTOK,
      icon: () => <TikTokIcon width="36px" height="36px" />,
      toolTip: t('TikTok'),
    },
    {
      platform: SoMePlatform.INSTAGRAM,
      icon: () => <InstagramIcon width="36px" height="36px" />,
      toolTip: t('Instagram'),
    },
  ];

  return (
    <>
      {soMeConfig.map((item) => {
        return (
          <AccTooltip tooltip={item.toolTip} key={item.toolTip}>
            <AccActionIcon
              size={42}
              className={cn(styles.iconBox, {
                [styles.active]: activeSocials.includes(item.platform),
              })}
              onClick={() => onIconToggle(item.platform)}
            >
              {item.icon()}
            </AccActionIcon>
          </AccTooltip>
        );
      })}
    </>
  );
};

export default SocialMediaIcons;
