import { useState } from 'react';
import Breadcrumbs from 'Components/Breadcrumbs/Breadcrumbs';
import { useModal } from 'Hooks/base/useModal';
import useUserPermission from 'Hooks/useUserPermission';
import * as Actions from 'Pages/Layout/ActionsMenu/Actions';
import ActionbarContainer from 'Pages/Layout/ActionsMenu/components/ActionbarContainer';
import { t } from 'Utilities/i18n';
import reusableStyles from 'css/reusable-styles.module.scss';
import AccountsRequestsTable from './AccountsRequestsTable';

const PendingRequests = () => {
  const [dataKey, setDataKey] = useState<number>(0);

  const handleUpdateTable = () => {
    setDataKey((prevKey) => prevKey + 1);
  };

  const { showModal } = useModal();
  const { isAdmin } = useUserPermission();

  const handleAdd = () => {
    showModal({
      modalType: 'RequestAccess',
      modalTheme: 'light',
      modalProps: {
        refresh: () => handleUpdateTable(),
      },
    });
  };

  return (
    <>
      <Breadcrumbs />
      <ActionbarContainer mt="md">
        <Actions.AddAction
          key="add"
          label={t('Request access to another account')}
          onClick={handleAdd}
          disabled={!isAdmin}
          showTooltip={!isAdmin}
          tooltip={t('Only admin users can request access to another account')}
        />
      </ActionbarContainer>
      <div className={reusableStyles.paper}>
        <AccountsRequestsTable dataKey={dataKey} handleUpdateTable={handleUpdateTable} />
      </div>
    </>
  );
};

export default PendingRequests;
