import { useState } from 'react';
import { connect } from 'react-redux';
import { Flex, Indicator } from '@mantine/core';
import compose from 'lodash/flowRight';
import moment from 'moment';
import { showModal } from 'Actions/ModalAction';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import {
  LATEST,
  findButtonConfig,
  getDateRange,
  parsePeriodFilterValue,
} from 'Components/PeriodFilter/model';
import { EditUserProfileMutationVariables, useEditUserProfileMutation } from 'Ghql';
import { useUser } from 'Hooks/data/user/useUser';
import { DomainInfo } from 'Query';
import type { PeriodFilter } from 'Types/Filter';
import { useUpdateUserSettingsField } from 'Utilities/Graphql/hooks/useUpdateStoredUserSettings';
import { formatComfortableDate } from 'Utilities/format';
import { t } from 'Utilities/i18n';
import WarningIcon from 'icons/exclamation.svg?inline';
import { DateRangeType, SegmentedCompareToContent } from '../CompareToContentSegmented';
import { getButtonsConfigs } from '../buttons';
import type { ButtonConfig, DateFilters, DateRange } from '../model';

type Props = {
  periodFilter: PeriodFilter;
  max: Date;
  min: Date;
  lastFullScrape?: Date;
  onSubmit: (filters: DateFilters) => void;
  message?: string;
  onlyPeriodFilter: boolean;
  domainInfo?: DomainInfo | undefined;
  showModal?: any;
};

export const getRangeToShow = (dateRange: DateRange, minDate: Date, maxDate: Date): DateRange => {
  let { from, to }: any = dateRange;
  // TODO: check if getDateRange is enough and following logic is redundant
  from = moment(from).isBefore(minDate) ? minDate : moment(from);
  from = moment(from).isAfter(maxDate) ? maxDate : moment(from);

  if (to !== LATEST) {
    to = moment(to).isAfter(maxDate) ? maxDate : moment(to);
    to = moment(to).isBefore(minDate) ? minDate : moment(to);
  }

  return getDateRange(
    {
      from,
      to,
    },
    minDate,
    maxDate,
  );
};

function useChangeUserSettingsMutation() {
  const updateUserSettingsField = useUpdateUserSettingsField();

  const user = useUser();

  const [performEditProfileQuery] = useEditUserProfileMutation();

  const changeUserSettings = (value: DateRangeType) => {
    if (value !== 'custom' && value !== 'initial') {
      const updateMyUserInput: EditUserProfileMutationVariables['updateMyUserInput'] = {
        email: user.email || '',
        fullName: user.fullName || '',
        language: user.language || '',
        resetApiToken: false,
        defaultCompareTo: value,
      };

      // Change in backend
      performEditProfileQuery({
        variables: {
          updateMyUserInput,
        },
      }).then((res) => {
        const errors = res.data?.updateMyUser?.errors || [];
        if (errors && errors.length) {
          console.error(errors);
        } else {
          // If request to backend successful, also change in redux store
          updateUserSettingsField('defaultCompareTo', value);
        }
      });
    }
  };
  return changeUserSettings;
}

const CompareToDropdown = (props: Props) => {
  const {
    periodFilter,
    max,
    min,
    lastFullScrape,
    domainInfo,
    onlyPeriodFilter,
    message,
    onSubmit,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSubmit = (filters: any) => {
    onSubmit(filters);
    toggle();
  };

  const buttonsConfigs = getButtonsConfigs();

  const labelFunc = (labelPeriodFilter: PeriodFilter, minDate: Date, maxDate: Date) => {
    const dateRange = parsePeriodFilterValue(labelPeriodFilter);
    const buttonConfig = findButtonConfig(
      buttonsConfigs as ButtonConfig[],
      dateRange,
      minDate,
      maxDate,
    );

    if (onlyPeriodFilter) {
      const { from, to } = getRangeToShow(dateRange, minDate, maxDate);
      return {
        value: `${formatComfortableDate(from)} - ${formatComfortableDate(to)}`,
        label: t('Period'),
      };
    }

    if (buttonConfig && dateRange.to === LATEST) {
      return {
        value: buttonConfig.label,
        label: t('Comparing data to'),
      };
    }

    const { from, to } = getRangeToShow(dateRange, minDate, maxDate);
    return {
      value: `${formatComfortableDate(from)} - ${formatComfortableDate(to)}`,
      label: t('Comparing'),
    };
  };

  const openPartialScrapeModal = () => {
    const dateRange = parsePeriodFilterValue(periodFilter);

    props.showModal({
      modalType: 'PartialScrape',
      modalTheme: 'light',
      modalProps: {
        domainInfo,
        dateRange,
        min,
        max,
      },
    });
  };

  const labelValue = labelFunc(periodFilter, min, max);
  const dateRange = parsePeriodFilterValue(periodFilter);
  let partialScrapeWarningShouldShow = false;
  const notViewingLatestData =
    dateRange.to !== LATEST && dateRange.to.toDateString() !== new Date(max).toDateString();

  if (lastFullScrape && domainInfo) {
    // remember to only compare dates
    // domainInfo.lastScrapedCompleted > domainInfo.firstRefreshAt as if lastScrapedCompleted is null it will be set to 1970
    if (
      (dateRange.to === LATEST &&
        moment(max).isAfter(lastFullScrape, 'day') &&
        moment(domainInfo.lastScrapedCompleted).isAfter(domainInfo.firstRefreshAt, 'day')) ||
      (notViewingLatestData &&
        moment(dateRange.to).isAfter(lastFullScrape, 'day') &&
        moment(domainInfo.lastScrapedCompleted).isAfter(domainInfo.firstRefreshAt, 'day'))
    ) {
      partialScrapeWarningShouldShow = true;
    }
  }
  const changeUserSettings = useChangeUserSettingsMutation();

  return (
    <>
      {partialScrapeWarningShouldShow && (
        <div className={'partial-scrape-warning'} onClick={openPartialScrapeModal}>
          <WarningIcon />
        </div>
      )}
      <Flex>
        <SegmentedCompareToContent
          onChange={changeUserSettings}
          partialScrapeWarningShouldShow={partialScrapeWarningShouldShow}
          min={min}
          max={max}
          periodFilter={periodFilter}
          onSubmit={handleSubmit}
          domainInfo={domainInfo}
          onlyPeriodFilter={onlyPeriodFilter}
          message={message}
          label={labelValue.value}
          notViewingLatestData={notViewingLatestData}
        />
        <AccTooltip tooltip={t('You are not viewing the latest data')}>
          <Indicator color={'yellow'} size={12} disabled={!notViewingLatestData}>
            {/* Empty fragment added to make AccTooltip work with Indicator */}
            <></>
          </Indicator>
        </AccTooltip>
      </Flex>
    </>
  );
};

export default compose(
  connect(null, {
    showModal,
  }),
)(CompareToDropdown);
