const DATE = 'date';
const DESCRIPTION = 'description';
const TYPE = 'type';
const AMOUNT = 'amount';
export const ColumnIDs = {
  DATE,
  DESCRIPTION,
  TYPE,
  AMOUNT,
};
