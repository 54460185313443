export const ADD_SELECTED_DOMAIN = 'ADD_SELECTED_DOMAIN';
export const REMOVE_SELECTED_DOMAIN = 'REMOVE_SELECTED_DOMAIN';
export const SET_SELECTABLE_DOMAINS = 'SET_SELECTABLE_DOMAINS';
export const CLEAR_ALL_SELECTED_DOMAINS = 'CLEAR_ALL_SELECTED_DOMAINS';

type AddSelectedDomain = {
  type: typeof ADD_SELECTED_DOMAIN;
  id: string;
};

type RemoveSelectedDomain = {
  type: typeof REMOVE_SELECTED_DOMAIN;
  id: string;
};
type SetSelectableDomains = {
  type: typeof SET_SELECTABLE_DOMAINS;
  selectableDomains: string[];
};

type ClearAllSelectedDomains = {
  type: typeof CLEAR_ALL_SELECTED_DOMAINS;
};

export type Action =
  | RemoveSelectedDomain
  | AddSelectedDomain
  | SetSelectableDomains
  | ClearAllSelectedDomains;

export function selectDomain(selectId: string): AddSelectedDomain {
  return {
    type: ADD_SELECTED_DOMAIN,
    id: selectId,
  };
}

export function deselectDomain(selectId: string): RemoveSelectedDomain {
  return {
    type: REMOVE_SELECTED_DOMAIN,
    id: selectId,
  };
}

export function setSelectableDomains(domains: string[]): SetSelectableDomains {
  return {
    type: SET_SELECTABLE_DOMAINS,
    selectableDomains: domains,
  };
}

export function deselectAllDomains(): ClearAllSelectedDomains {
  return {
    type: CLEAR_ALL_SELECTED_DOMAINS,
  };
}
