import { useUser } from './useUser';

/** Check if the user has AccuApi access
 * @example
 * const hasAccuApiAccess = userHasAccuApiAccess();
 */
export const useHasAccuApiAccess = (): boolean => {
  const user = useUser();

  return user.organization?.activePlan?.featureAccuApi || false;
};
