import { useApolloClient } from '@apollo/client';
import { UpdateWorkspaceInput, useEditWorkspaceMutation } from 'Ghql';
import toast from 'Hooks/useToast';
import { EventName, TrackingKey, useMixpanel } from 'Utilities/Analytics/mixpanel';
import { invalidateCache } from 'Utilities/Graphql/invalidateCache';
import { t } from 'Utilities/i18n';
import { mapFinalFormErrors } from 'Utilities/validation';

// eslint-disable-next-line import/no-unused-modules
export const useUpdateWorkspace = ({
  id,
  inlineForm,
  refresh,
  onClose,
}: {
  id: string;
  inlineForm?: boolean;
  refresh?: (...args: Array<any>) => any;
  onClose?: (...args: Array<any>) => void;
}) => {
  const trackEvent = useMixpanel();
  const client = useApolloClient();
  const [updateWorkspace] = useEditWorkspaceMutation();

  const handleSubmit = ({
    name,
    description,
    clients,
    users,
  }: Pick<UpdateWorkspaceInput, 'name' | 'description' | 'clients' | 'users'>) => {
    const roleInput = {
      workspaceId: id,
      clients: clients?.map(Number) || [],
      users: users?.map(Number) || [],
      name,
      description: description || '',
    };

    return updateWorkspace({
      variables: {
        roleInput,
      },
    }).then((result) => {
      const errors = result.data?.updateWorkspace?.errors;
      if (errors && errors.length) {
        toast.error(t('Something went wrong'));
        const errorObjects = errors
          .filter((err) => err)
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          .map((err) => ({ field: err!.field, messages: err!.messages }));
        trackEvent(EventName.WorkspacePage, {
          'Tracking key': inlineForm ? TrackingKey.InlineEditFail : TrackingKey.EditFail,
          Message: errors[0]?.messages[0] ?? '',
        });

        return mapFinalFormErrors(errorObjects);
      }
      trackEvent(EventName.WorkspacePage, {
        'Tracking key': inlineForm ? TrackingKey.InlineEditSuccess : TrackingKey.EditSuccess,
      });
      toast.success(t('Workspace updated'));
      invalidateCache(client.cache, ['workspace']);
      refresh?.();
      onClose?.();
    });
  };
  return handleSubmit;
};
