import { Instance, OptionsGeneric, createPopper } from '@popperjs/core';
import { makeAutoObservable } from 'mobx';
import notesStyle from 'Components/Chart/Notes/notes.module.scss';
import chartPopoverStyles from 'Components/Chart/support/components/ChartPopover/popover.module.scss';

export class ChartPopoverStore {
  visible: boolean = false;
  contentProps: object | null = null;
  content: HTMLElement | null = null;
  target: HTMLElement | null = null;
  popover: Instance | null = null;

  setContent = (element: HTMLElement) => {
    this.content = element;
  };

  constructor() {
    makeAutoObservable(this, {
      setContent: false,
      content: false,
      target: false,
      popover: false,
    });
  }

  hidePopper = () => {
    this.popover?.destroy();
    this.visible = false;
  };

  showPopover = (target: any | null, contentProps?: any, options?: Partial<OptionsGeneric<{}>>) => {
    if (!target) {
      return false;
    }

    if (this.visible && contentProps?.note?.id === (this.contentProps as any)?.note?.id) {
      return;
    }

    this.target = target;
    this.visible = true;
    this.contentProps = contentProps;
    this.popover?.destroy();

    const { noteWidth } = notesStyle;
    const { popoverPaddingHorizontal } = chartPopoverStyles;
    const popperWidth = parseInt(noteWidth) + parseInt(popoverPaddingHorizontal) * 2;
    const noteIconWidth = target.getBoundingClientRect().width;

    this.popover = createPopper(
      target,
      this.content!,
      options || {
        placement: 'top',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [-(popperWidth + noteIconWidth / 2), 0],
            },
          },
        ],
      },
    );
  };

  togglePopover = (
    target: HTMLElement | SVGElement | null,
    contentProps?: any,
    options?: Partial<OptionsGeneric<{}>>,
  ) => {
    if (!target) {
      return false;
    }
    if (this.visible) {
      this.visible = false;
      return;
    }

    this.showPopover(target, contentProps, options);
  };
}
