import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import { Box } from '@mantine/core';
import * as Sentry from '@sentry/react';
import cn from 'classnames';
import { FormApi, ValidationErrors } from 'final-form';
import omit from 'lodash/omit';
import { updateDomain } from 'Actions/DomainsAction';
import { updateUserOrganization } from 'Actions/UserAction';
import AccButton from 'Components/AccButton/AccButton';
import Breadcrumbs from 'Components/Breadcrumbs/Breadcrumbs';
import { Field, Form } from 'Components/Fields';
import { FormError } from 'Components/Fields/Form/FormError';
import Title from 'Components/Title/AccTitle';
import { UnrankedWeightConstants } from 'Constants';
import {
  UpdateOrganizationInput,
  useGetOrganizationLogoLazyQuery,
  useUpdateOrganizationMutation,
} from 'Ghql';
import { useHasAccuApiAccess } from 'Hooks/data/user/useHasAccuApiAccess';
import { DefaultValue, useDisplayCurrencies, useTopCurrencies } from 'Hooks/displayCurrency';
import { useActions } from 'Hooks/redux/useActions';
import toast from 'Hooks/useToast';
import { useOrganizationInfo } from 'Selectors/OrganizationSelector';
import { StoreType } from 'Types/Store';
import { t } from 'Utilities/i18n/index';
import Validator from 'Utilities/validation';
import { AverageRankSettings } from './AverageRank';
import {
  AccountSkeleton,
  DisplayCurrencyHelp,
  EnableLocalSearchHelp,
  FieldName,
  IncludeLocalSearchHelperText,
  KeywordExceedingsHelp,
  SubAccountHelp,
  removeUpload,
  upload,
  validateUnrankedWeight,
} from './helpers';
import styles from './account-settings.module.scss';

function useLogoExpirationHandling() {
  const { organization } = useOrganizationInfo();
  const [refetchOrganizationLogo] = useGetOrganizationLogoLazyQuery({});
  const actions = useActions({ updateUserOrganization });

  const logoExpired = useMemo(() => {
    if (!organization?.logo) return false;
    const logoExpires = new URL(organization.logo).searchParams.get('Expires');
    return logoExpires
      ? parseInt(logoExpires) < // logo timestamp in seconds
          Date.now() / 1000 // current time in seconds
      : false;
  }, [organization?.logo]);

  useEffect(() => {
    if (logoExpired) {
      refetchOrganizationLogo({ fetchPolicy: 'network-only' }).then((res) => {
        res.data?.user?.organization?.logo &&
          actions.updateUserOrganization({ logo: res.data.user.organization.logo });
      });
    }
  }, [logoExpired]);

  return logoExpired;
}

function AccountSettings() {
  const logoExpired = useLogoExpirationHandling();
  const isSubAccount = useSelector((state: StoreType) => state?.user?.isOnMultiaccount);
  const { organization, isTrial } = useOrganizationInfo();

  const isFree = organization?.activePlan?.isFree;

  const hasAccuApiAccess = useHasAccuApiAccess();

  const topCurrencies = useTopCurrencies(false);

  const displayCurrencies = useDisplayCurrencies(topCurrencies, 'USD', DefaultValue.organization);

  const actions = useActions({ updateUserOrganization, updateDomainAction: updateDomain });

  const [updateAccount] = useUpdateOrganizationMutation();

  const organizationUnrankedWeight = organization?.unrankedWeight;

  const initialValues: Record<FieldName, any> = {
    name: organization?.name,
    logo: organization?.logo ?? null,
    isPartner: !!organization?.isPartner,
    keywordOveruseEnabled: !!organization?.keywordOveruseEnabled,
    displayCurrency: organization?.displayCurrency ?? 'default',
    showLocalSearchVolume: organization?.showLocalSearchVolume,
    unrankedWeight: organizationUnrankedWeight
      ? organizationUnrankedWeight
      : UnrankedWeightConstants.DEFAULT_IF_DISABLED,
    unrankedWeightEnabled: organizationUnrankedWeight !== null,
  };

  const submitLogoField = async (dirtyFields: string[], values: Record<FieldName, any>) => {
    const isDirty = dirtyFields.includes(FieldName.logo);
    if (!isDirty) {
      return {};
    }

    const logoValue = values[FieldName.logo];

    if (!logoValue) {
      await removeUpload();
      actions.updateUserOrganization({ logo: null });
      return {};
    }

    const result = await upload(logoValue);
    const newLogoURL = await result?.text();
    actions.updateUserOrganization({ logo: newLogoURL });
  };

  const submitOtherFields = async (dirtyFields: string[], values: Record<FieldName, any>) => {
    const otherFields = Object.values(FieldName).filter((field) => field !== FieldName.logo);

    const isDirty = otherFields.some((field) => dirtyFields.includes(field));
    if (!isDirty) {
      return {};
    }

    const newUnrankedWeight = values.unrankedWeightEnabled
      ? parseInt(values.unrankedWeight, 10)
      : null;

    const input: UpdateOrganizationInput = {
      id: organization?.id ?? '',
      ...omit(values, [FieldName.logo, FieldName.unrankedWeight, FieldName.unrankedWeightEnabled]),
      unrankedWeight: newUnrankedWeight,
    };

    await updateAccount({
      variables: {
        input,
      },
    });

    actions.updateUserOrganization(input);
  };

  const cache = useApolloClient().cache;

  const handleSubmit = async (values: Record<FieldName, any>, form: FormApi) => {
    try {
      const isDirtyByField = form.getState().dirtyFields;

      const dirtyFields = Object.keys(isDirtyByField).filter(
        (fieldName) => isDirtyByField[fieldName],
      );

      await Promise.all([
        submitLogoField(dirtyFields, values),
        submitOtherFields(dirtyFields, values),
      ]);

      toast.info(t('Account settings updated successfully.'));
      cache.reset();
    } catch (e) {
      Sentry.captureException(e);
      toast.error(t('Something went wrong. Please try again later.'));
    }
  };

  return (
    <>
      <Breadcrumbs />
      <div className={cn(styles['account-settings'])}>
        {!organization || logoExpired ? (
          <Box w={800} h={1000}>
            <AccountSkeleton />
          </Box>
        ) : (
          <Form
            subscription={{ values: true, submitting: true, invalid: true, pristine: true }}
            initialValues={initialValues}
            validate={(values) => {
              const errors: ValidationErrors = {};
              validateUnrankedWeight(values, errors);
              return errors;
            }}
            onSubmit={handleSubmit}
          >
            {({ submitting, invalid, pristine, values }) => {
              return (
                <>
                  <FormError />

                  <div className={styles.accountPreferencesContainer}>
                    <Title type="h3">{t('Account Information')}</Title>
                    <Field.TextInput
                      name={FieldName.name}
                      label={t('Account Name')}
                      placeholder={t('Enter account name')}
                      validate={Validator.required}
                      required
                      hideRequiredStar
                    />
                    <Field.ImagePicker name={FieldName.logo} preview={organization?.logo ?? ''} />
                    {!hasAccuApiAccess && (
                      <>
                        <Title type="h3" fw={600}>
                          {t('Account Preferences')}
                        </Title>
                        <div className={styles.accountPreferenceSection}>
                          <div className={styles.accountPreferencesRow}>
                            <Title type="h5">{t('Enable the sub-account feature')}</Title>
                            <Field.SwitchInput
                              name={FieldName.isPartner}
                              disabled={!!organization.isPartner || !!isSubAccount}
                              defaultChecked={!!organization.isPartner}
                            />
                          </div>
                          <SubAccountHelp
                            isPartner={!!organization.isPartner}
                            isSubAccount={!!isSubAccount}
                          />
                        </div>

                        <div className={styles.accountPreferenceSection}>
                          <div className={styles.accountPreferencesRow}>
                            <Title type="h5">
                              {t('Enable keyword exceeding for this account?')}
                            </Title>
                            <Field.SwitchInput
                              name={FieldName.keywordOveruseEnabled}
                              defaultChecked={organization.keywordOveruseEnabled}
                              disabled={organization.keywordOveruseEnabled || isTrial || isFree}
                            />
                          </div>
                          <KeywordExceedingsHelp
                            keywordOveruseEnabled={!!organization.keywordOveruseEnabled}
                            isTrial={isTrial}
                          />
                        </div>

                        <Title type="h3" fw={600}>
                          {t('Account Display Settings')}
                        </Title>

                        <div className={styles.accountPreferenceSection}>
                          <div className={styles.accountPreferencesRow}>
                            <Title type="h5" helper={<IncludeLocalSearchHelperText />}>
                              {t('Local search volume')}
                            </Title>
                            <Field.SwitchInput
                              name={FieldName.showLocalSearchVolume}
                              // defaultChecked={organization.keywordOveruseEnabled}
                              // disabled={organization.keywordOveruseEnabled}
                            />
                          </div>
                          <EnableLocalSearchHelp />
                        </div>

                        <div className={styles.accountPreferenceSection}>
                          <div className={styles.accountPreferencesRow}>
                            <Title type="h5">{t('CPC Currency')}</Title>
                            <div style={{ width: '125px' }}>
                              <Field.Select
                                name={FieldName.displayCurrency}
                                options={displayCurrencies}
                                searchable
                                groupHidden
                              />
                            </div>
                          </div>
                          <DisplayCurrencyHelp />
                        </div>

                        <div className={styles.accountPreferenceSection}>
                          <div className={styles.accountPreferencesRow}>
                            <AverageRankSettings
                              values={values}
                              organizationUnrankedWeight={organizationUnrankedWeight || 0}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    <AccButton
                      type="submit"
                      disabled={invalid || submitting || pristine}
                      variant="primary"
                    >
                      {t('Save')}
                    </AccButton>
                  </div>
                </>
              );
            }}
          </Form>
        )}
      </div>
    </>
  );
}

export default AccountSettings;
