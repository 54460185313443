import { useCallback } from 'react';
import AccButton from 'Components/AccButton/AccButton';
import { Field, Form } from 'Components/Fields';
import { useAddOrganizationNoteMutation, useEditOrganizationNoteMutation } from 'Ghql';
import { useUser } from 'Hooks/data/user/useUser';
import toast from 'Hooks/useToast';
import { t } from 'Utilities/i18n';
import styles from './orgNoteForm.module.scss';

type Props = {
  organizationId: string;
  noteId?: string;
  message?: string;
  mode: 'Add' | 'Edit';
  onUpdate: () => void;
};

const OrgNoteForm = ({ organizationId, noteId, message, mode, onUpdate }: Props) => {
  const [addOrganizationNoteMutation] = useAddOrganizationNoteMutation();
  const [editOrganizationNoteMutation] = useEditOrganizationNoteMutation();
  const user = useUser();

  const handleSubmit = useCallback((values) => {
    if (!user?.id) {
      toast.error('Missing user ID');
      return;
    }

    if (mode === 'Add') {
      addOrganizationNoteMutation({
        variables: {
          input: {
            organizationId,
            userId: user?.id,
            message: values.message,
          },
        },
      }).then(() => {
        onUpdate();
      });
    } else if (mode === 'Edit') {
      editOrganizationNoteMutation({
        variables: {
          input: {
            noteId: noteId ?? '',
            message: values.message,
          },
        },
      }).then(() => {
        onUpdate();
      });
    }
  }, []);

  return (
    <Form onSubmit={(values) => handleSubmit(values)} initialValues={{ message }}>
      {(formRenderProps) => {
        const { submitting, invalid, pristine } = formRenderProps;

        return (
          <div className={styles.inputContainer}>
            <Field.TextAreaInput
              name="message"
              label={t('Note')}
              placeholder={t('Enter your note here...')}
              required
            />
            <AccButton type="submit" disabled={invalid || submitting || pristine} variant="primary">
              {mode === 'Add' ? t('Add') : t('Edit')}
            </AccButton>
          </div>
        );
      }}
    </Form>
  );
};

export default OrgNoteForm;
