import { Flex } from '@mantine/core';
import { hideModal } from 'Actions/ModalAction';
import AccButton from 'Components/AccButton/AccButton';
import { Field, Form } from 'Components/Fields';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import { t, tct } from 'Utilities/i18n';
import Validation from 'Utilities/validation';
import { BillingWalletCurrencyChoices } from '../../../../../Ghql';

type Props = {
  loading: boolean;
  onSubmit: (...args) => void;
};

const CurrencyOptions = [
  {
    label: 'USD',
    value: BillingWalletCurrencyChoices.Usd,
  },
  {
    label: 'EUR',
    value: BillingWalletCurrencyChoices.Eur,
  },
  {
    label: 'DKK',
    value: BillingWalletCurrencyChoices.Dkk,
  },
];

export default function AddWalletItemForm({ loading, onSubmit }: Props) {
  return (
    <Form onSubmit={onSubmit}>
      {() => {
        return (
          <>
            <Flex direction="column" rowGap="xl">
              <Field.TextInput
                key="description"
                name="description"
                label={t('Description (shown on invoice)')}
                placeholder={t('Enter description')}
                required
                validate={Validation.required}
                autoFocus={true}
              />
              <Field.Checkbox
                name="customerOwesUs"
                label={t('Customer owes us')}
                hint={tct(
                  'On: Money the customer owes AccuRanker.[br]Off: Money AccuRanker owes the customer.',
                  { br: <br /> },
                )}
              />
              <Field.TextInput
                name="amount"
                label={t('Amount')}
                placeholder={t('Enter positive non-zero amount')}
                required
                validate={Validation.numericPositive}
              />
              <Field.Select
                name="currency"
                label={t('Currency')}
                options={CurrencyOptions}
                searchable={false}
                validate={[Validation.required]}
                required
              />
            </Flex>

            <ModalFooter
              primaryButtonSlot={
                <AccButton type="submit" variant="primary" disabled={loading} loading={loading}>
                  {t('Add')}
                </AccButton>
              }
              secondaryButtonSlot={
                <AccButton variant="tertiary" onClick={hideModal}>
                  {t('Cancel')}
                </AccButton>
              }
            />
          </>
        );
      }}
    </Form>
  );
}
