import { useEffect, useState } from 'react';
import { Navigate } from 'react-router';
import { Flex } from '@mantine/core';
import * as Sentry from '@sentry/react';
import isEmpty from 'lodash/isEmpty';
import { clearEverything } from 'Actions/ResetAction';
import AccButton from 'Components/AccButton/AccButton';
import { Form } from 'Components/Fields';
import {
  PricingPlanNode,
  RegisterPhoneVerificationInput,
  UnconfirmedUserNode,
  useRegisterPageCountriesQuery,
  useRegisterPagePayNowPlanQuery,
  useRegisterPagePricingPlanQuery,
  useRegisterPageUnconfirmedUserQuery,
  useRegisterPageUserPhoneVerificationMutation,
} from 'Ghql';
import { useUser } from 'Hooks/data/user/useUser';
import { useActions } from 'Hooks/redux/useActions';
import { useTrialConversionTracking } from 'Hooks/useConversionTracking';
import toast from 'Hooks/useToast';
import AuthPageContainer from 'Pages/Auth/Shared/AuthPageContainer/AuthPageContainer';
import PaymentWidget from 'Pages/Billing/PaymentWidget';
import { EventName, TrackingKey, useMixpanel } from 'Utilities/Analytics/mixpanel';
import { useRouteMatch } from 'Utilities/Router/hooks/useRouteMatch';
import { t } from 'Utilities/i18n';
import { redirectToExternalUrl } from 'Utilities/underdash';
import AddressInfo from './components/AddressInfo';
import CompanyInfo from './components/CompanyInfo';
import ContactInfo from './components/ContactInfo';
import PlanInfo from './components/PlanInfo';
import TermsAndConditions from './components/TermsAndConditions';
import { splitFullName } from './utils/names';
import { useCountryAndVATFields } from './utils/useCountryAndVATFields';
import { useRegistrationSubmit } from './utils/useRegistrationSubmit';

export type CountryOption = {
  label: string;
  value: string;
  vatCode: string;
  countryCode: string;
};

const TRIAL = 'A_1'; // standard trial (don't show payment)
export const TRIAL_CC_AND_PAY = 'A_2'; // abusive country (show payment)
export const PAY = 'A_3'; // may be unused

export enum FIELD_NAMES {
  FULL_NAME = 'fullName',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  JOB_TITLE = 'jobTitle',
  PHONE = 'phone',
  CODE = 'code', // phone verification code
  EMAIL = 'email',
  PASSWORD = 'password',
  ORG_NAME = 'organizationName',
  ORG_URL = 'organizationUrl',
  KEYWORD_COUNT = 'keywordCount',
  STREET = 'street',
  CITY = 'city',
  STATE = 'state',
  ZIPCODE = 'zipcode',
  COUNTRY = 'country',
  VAT_PREFIX = 'vatPrefix',
  VAR_NUMBER = 'varNumber',
  TERMS = 'termsAccepted',
}

export type Channel = 'sms' | 'call';

const hubspotTrackPageView = () => {
  try {
    if (!window?.location?.pathname?.startsWith('/app/register/')) return; // only track on the register page
    if (window?.location?.hostname !== 'app.accuranker.com') return; // only prod

    if (!(window as any)?._hsq) {
      (window as any)._hsq = [];
    }

    const _hsq = (window as any)._hsq;

    _hsq.push(['setPath', window.location.pathname]);
    _hsq.push(['trackPageView']);
  } catch (e) {
    // ignore error
  }
};

const RegisterPage = () => {
  useTrialConversionTracking();
  const user = useUser();
  const trackEvent = useMixpanel();

  useEffect(() => {
    (window as any)?.registerHotJar?.();
    hubspotTrackPageView();
    return () => {
      (window as any)?.unregisterHotJar?.();
      (window as any)._hsq = [];
    };
  }, []);

  const match = useRouteMatch();
  const unconfirmedUserId = match.params?.id;

  // Unconfirmed user
  const {
    data: uuData,
    error: uuError,
    refetch: uuRefetch,
  } = useRegisterPageUnconfirmedUserQuery({
    variables: { id: unconfirmedUserId ?? '' },
  });
  const signupType = uuData?.unconfirmedUser?.signupType;
  const mustPayNow = signupType === TRIAL_CC_AND_PAY || signupType === PAY;

  // Standard pricing plan
  const { data: ppData, error: ppError } = useRegisterPagePricingPlanQuery({
    variables: { id: unconfirmedUserId ?? '' },
    skip: signupType === PAY,
  });
  // Abusive country pricing plan
  const { data: pnpData, error: pnpError } = useRegisterPagePayNowPlanQuery({
    skip: signupType === TRIAL,
  });
  const pricingPlan: PricingPlanNode | undefined =
    (ppData?.pricingPlan as PricingPlanNode) || (pnpData?.payNowPlan as PricingPlanNode);

  // Countries
  const { data: cData, error: cError } = useRegisterPageCountriesQuery();
  const { countryOptions, vatOptions } = useCountryAndVATFields({
    countriesData: cData,
  });

  const [registerPageUserPhoneVerificationMutation, { loading: verifyIsLoading }] =
    useRegisterPageUserPhoneVerificationMutation();

  const [initialValues, setInitialValues] = useState<any>();
  const [braintreeInstance, setBraintreeInstance] = useState<any | false>(false);
  const [braintreeUniqueId, setBraintreeUniqueId] = useState<number>(+new Date());
  const [verifyBtnClicked, setVerifyBtnClicked] = useState<boolean>(false);

  const actions = useActions({ clearEverything });

  const { handleSubmit } = useRegistrationSubmit({
    match,
    setBraintreeUniqueId,
    signupType,
    braintreeInstance,
    pricingPlan,
    unconfirmedUserData: uuData,
  });

  useEffect(() => {
    if (verifyBtnClicked) return; // don't overwrite user input
    if (!uuData) return;

    const emailParts = uuData?.unconfirmedUser?.email?.split('@');
    const orgUrlDefaultValue = emailParts?.length === 2 ? emailParts[1] : '';
    const { firstName, lastName } = splitFullName(uuData?.unconfirmedUser?.prefillFullName);

    setInitialValues({
      [FIELD_NAMES.FIRST_NAME]: firstName,
      [FIELD_NAMES.LAST_NAME]: lastName,
      [FIELD_NAMES.EMAIL]: uuData?.unconfirmedUser?.email || '',
      [FIELD_NAMES.PHONE]: uuData?.unconfirmedUser?.phoneNumber || '',
      [FIELD_NAMES.ORG_URL]: orgUrlDefaultValue,
      [FIELD_NAMES.TERMS]: false,
    });
  }, [uuData]);

  const error = !!uuError || !!ppError || !!pnpError || !!cError;
  if (error) {
    Sentry.captureException(error);
  }

  if (uuError) {
    return <Navigate replace to={'/error/404'} />;
  }

  const handleStopImpersonate = () => {
    actions.clearEverything();
    redirectToExternalUrl('/accuranker_admin/impersonate/stop/');
  };

  // Phone number verification,
  // requires three separate calls to the userPhoneVerification mutation:
  // 1. handleVerify1: set phone number
  // 2. handleVerify2: choose channel (sms or call), which will send a verification code
  // 3. handleVerify3: submit verification code
  const doHandleVerify = async (prop: object) => {
    const input: RegisterPhoneVerificationInput = {
      ...prop,
      unconfirmedUserId: unconfirmedUserId ?? '',
    };
    return registerPageUserPhoneVerificationMutation({ variables: { input } });
  };

  const handleVerify1 = async (phoneNumber: string) => {
    setVerifyBtnClicked(true);
    const res = await doHandleVerify({ phone: phoneNumber });
    if (res?.data?.userPhoneVerification?.errors?.length) {
      toast.error('Phone verification failed');
      trackEvent(EventName.RegisterPagePhoneVerificationFailed, { step: 'phone number' });
      throw new Error('Phone verification error (phone number)');
    }
  };

  const handleVerify2 = async (channel: Channel) => {
    // gets channel from user input ("sms" or "call")
    const res = await doHandleVerify({ channel: channel ?? 'sms' });
    if (res?.data?.userPhoneVerification?.errors?.length) {
      toast.error('Phone verification failed');
      trackEvent(EventName.RegisterPagePhoneVerificationFailed, { step: 'channel', channel });
      throw new Error('Phone verification error (channel)');
    }
  };

  const handleVerify3 = async (code: string): Promise<number> => {
    const res = await doHandleVerify({ code });
    let status = 0;
    if (res?.data?.userPhoneVerification?.errors?.length) {
      toast.error('Phone verification failed');
      trackEvent(EventName.RegisterPagePhoneVerificationFailed, { step: 'code' });
      status = 1;
    } else {
      trackEvent(EventName.RegisterPagePhoneVerificationSuccess);
    }
    uuRefetch();
    return status;
  };

  return (
    <AuthPageContainer
      heroText={t('The World\'s Fastest & Most Accurate Rank Tracker')}
      formHeader={
        <PlanInfo
          pricingPlan={pricingPlan}
          unconfirmedUser={uuData?.unconfirmedUser as UnconfirmedUserNode | undefined}
        />
      }
    >
      <Flex direction="column">
        <Form
          onSubmit={handleSubmit}
          initialValues={initialValues}
          subscription={{ values: true, submitting: true, errors: true }}
        >
          {({ submitting, errors, values }) => {
            return (
              <Flex direction="column" rowGap="md">
                <ContactInfo
                  skipPhoneVerification={mustPayNow}
                  isVerifyBtnDisabled={
                    verifyIsLoading ||
                    !!errors?.hasOwnProperty('phone') ||
                    !!uuData?.unconfirmedUser?.phoneNumberVerified
                  }
                  isPhoneVerified={!!uuData?.unconfirmedUser?.phoneNumberVerified}
                  handleVerify={async (channel) => {
                    await handleVerify1(values[FIELD_NAMES.PHONE]);
                    handleVerify2(channel);
                  }}
                  handleSubmitVerificationCode={handleVerify3}
                  createdByCountry={uuData?.unconfirmedUser?.createdByCountry ?? undefined}
                />

                <CompanyInfo isSocialSignup={!!uuData?.unconfirmedUser?.isSocialSignup} />

                {mustPayNow && (
                  <AddressInfo countryOptions={countryOptions ?? []} vatOptions={vatOptions} />
                )}

                {mustPayNow && (
                  <PaymentWidget
                    onCreate={(instance) => setBraintreeInstance(instance)}
                    uniqueid={braintreeUniqueId}
                    braintreeThreeDSecureAmount={pricingPlan?.priceMonthly ?? 1}
                    hideTitle
                  />
                )}

                <TermsAndConditions />

                <AccButton
                  my="xxs"
                  mx="auto"
                  type="submit"
                  variant="primary"
                  fullWidth
                  disabled={
                    submitting ||
                    !isEmpty(errors) ||
                    (!mustPayNow && !uuData?.unconfirmedUser?.phoneNumberVerified)
                  }
                  trackingKey={TrackingKey.RegisterPageActivate}
                >
                  {signupType === TRIAL_CC_AND_PAY || signupType === TRIAL
                    ? t('Activate trial')
                    : t('Pay')}
                </AccButton>
                {user?.isImpersonating && (
                  <AccButton
                    my="xxs"
                    mx="auto"
                    type="button"
                    variant="destructive"
                    onClick={handleStopImpersonate}
                  >
                    {t('Stop impersonating')}
                  </AccButton>
                )}
              </Flex>
            );
          }}
        </Form>
      </Flex>
    </AuthPageContainer>
  );
};

export default RegisterPage;
