import { IconFilterOff } from '@tabler/icons-react';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import AccButton from 'Components/AccButton/AccButton';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import withFiltersEditor from 'Components/HOC/withFiltersEditor';
import type { FiltersEditorProps } from 'Components/HOC/withFiltersEditor';
import { TrackingKey } from 'Utilities/Analytics/mixpanel';
import { t } from 'Utilities/i18n/index';
import styleVariables from 'css/base/variables.module.scss';

interface ResetFilterProps {
  compact?: boolean;
}
const ResetFilter = (props: ResetFilterProps & FiltersEditorProps) => {
  const { filtersEditor, compact } = props;
  const handleReset = () => {
    filtersEditor.resetFilters();
  };

  return !compact ? (
    <AccButton
      variant="tertiary"
      trackingKey={TrackingKey.ClearFilters}
      onClick={handleReset}
      leftSection={<IconFilterOff size={20} />}
    >
      {!compact && t('Clear filters')}
    </AccButton>
  ) : (
    <AccTooltip tooltip={t('Clear filters')}>
      <AccActionIcon variant="subtle" onClick={handleReset} c={styleVariables.snorlax4}>
        <IconFilterOff size={20} />
      </AccActionIcon>
    </AccTooltip>
  );
};

export default withFiltersEditor(ResetFilter) as React.ComponentType<ResetFilterProps>;
