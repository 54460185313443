import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { Flex } from '@mantine/core';
import { useTrialConversionTracking } from '../../Hooks/useConversionTracking';
import SupportText from './components/SupportText';
import WelcomeActions from './components/welcomeActions';
import WelcomeText from './components/welcomeText';
import styles from './welcome.module.scss';

const VIDEO_WIDTH = 400;
const VIDEO_HEIGHT = 225;

const Welcome = () => {
  useTrialConversionTracking();

  const [autoPlay, setAutoPlay] = useState(false);

  const navigate = useNavigate();

  const firstVideoUrl = useMemo(() => {
    return autoPlay
      ? 'https://www.youtube.com/embed/PGZ53yOjnMk?autoplay=1'
      : 'https://www.youtube.com/embed/PGZ53yOjnMk';
  }, [autoPlay]);

  return (
    <Flex
      className={styles.container}
      direction={{ base: 'column', xl: 'row' }}
      gap="xl"
      justify="stretch"
    >
      <Flex direction="column">
        <WelcomeText />
        <WelcomeActions
          playVideo={() => setAutoPlay((prev) => !prev)}
          scheduleMeeting={() =>
            window.open(
              'https://meeting.accuranker.com/meetings/hsj/website-bookings',
              '_blank',
              'noopener,noreferrer',
            )
          }
          selectPlan={() => navigate('/billing/package/select')}
        />
        <SupportText />
      </Flex>
      <Flex
        wrap="wrap"
        direction={{ base: 'row', xl: 'column' }}
        gap={18}
        justify="center"
        align="center"
        miw={VIDEO_WIDTH}
        style={{ flexGrow: 1 }}
      >
        <iframe
          className={styles.video}
          src={firstVideoUrl}
          width={VIDEO_WIDTH}
          height={VIDEO_HEIGHT}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
        <iframe
          className={styles.video}
          src="https://www.youtube.com/embed/iAvLhubfTTg"
          width={VIDEO_WIDTH}
          height={VIDEO_HEIGHT}
          frameBorder="0"
          allowFullScreen
        />
        <iframe
          className={styles.video}
          src="https://www.youtube.com/embed/yUiqCgwtTrA"
          width={VIDEO_WIDTH}
          height={VIDEO_HEIGHT}
          frameBorder="0"
          allowFullScreen
        />
      </Flex>
    </Flex>
  );
};

export default Welcome;
