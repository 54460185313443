import { KeyboardEvent, MouseEvent, useEffect, useState } from 'react';
import { Button, Flex, Stack, TextInput, useMantineTheme } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import { AccSelect, SelectItem } from 'Components/AccSelect';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import FilterCount from 'Components/Filters/Common/FilterCount';
import AccText from 'Components/Text/AccText';
import AccTitle from 'Components/Title/AccTitle';
import { useSpecificFilter } from 'Hooks';
import { useRemoveFilter, useSetOrOverwriteFilter } from 'Hooks/data/filter/setFilters';
import useFilterCount from 'Hooks/useFilterCount';
import {
  CONTAINS,
  ENDS_WITH,
  EQ,
  FilterAttribute,
  FilterComparison,
  FilterValueType,
  NE,
  NOT_CONTAINS,
  NOT_REGEX,
  REGEX,
  STARTS_WITH,
  StringComparison,
} from 'Types/Filter';
import { FilterTrackingKey } from 'Utilities/Analytics/mixpanel';
import { t } from 'Utilities/i18n';
import { getRegexError } from 'Utilities/validation';
import styles from './keywordSearch.module.scss';

interface KeywordSearchProps {
  domainIsSelected: boolean;
}

interface StringComparisonOption extends SelectItem<StringComparison> {
  value: StringComparison;
  label: string;
}

const ComparisonOption = ({ label, ...rest }: StringComparisonOption) => {
  return (
    <div {...rest}>
      <AccText size="sm" c="#000">
        {label}
      </AccText>
    </div>
  );
};

export const KeywordSearch = ({ domainIsSelected }: KeywordSearchProps) => {
  const keywordFilter = useSpecificFilter(FilterAttribute.KEYWORD);
  const setFilter = useSetOrOverwriteFilter();
  const removeFilter = useRemoveFilter();
  const [error, setError] = useState<string | null>(null);

  const stringComparisonOptions: StringComparisonOption[] = [
    { value: CONTAINS, label: t('Contains') },
    { value: EQ, label: t('Equals') },
    { value: NE, label: t('Not equals') },
    { value: NOT_CONTAINS, label: t('Not contains') },
    { value: STARTS_WITH, label: t('Starts with') },
    { value: ENDS_WITH, label: t('Ends with') },
    { value: REGEX, label: t('RegEx') },
    { value: NOT_REGEX, label: t('Not RegEx') },
  ] as StringComparisonOption[];

  const [inputValue, setInputValue] = useState(keywordFilter?.value || '');
  const [filterComp, setFilterComp] = useState(keywordFilter?.comparison || 'contains');

  const filterCount = useFilterCount({
    attribute: FilterAttribute.KEYWORD,
    comparison: filterComp,
    value: inputValue,
    type: FilterValueType.STRING,
  });

  const baseFilter = {
    attribute: FilterAttribute.KEYWORD,
    type: FilterValueType.STRING,
    comparison: filterComp,
  };

  const onSubmit = (event: KeyboardEvent<HTMLInputElement> | MouseEvent<HTMLButtonElement>) => {
    //Only change filter when pressing enter
    if ('key' in event && event.key !== 'Enter') return;

    if (inputValue === '') {
      removeFilter(FilterAttribute.KEYWORD);
      return;
    }

    if (baseFilter?.comparison === FilterComparison.REGEX || FilterComparison.NOT_REGEX) {
      const errorMessage = getRegexError(inputValue);
      if (errorMessage) {
        setError(errorMessage);
        return;
      }
    }
    setError(null);

    setFilter(
      {
        ...baseFilter,
        value: inputValue,
      },
      FilterTrackingKey.DiscoveryQuickAction,
    );
  };

  const handleReset = () => {
    removeFilter(FilterAttribute.KEYWORD);
    setInputValue('');
  };

  //watch filterchanges on the page
  useEffect(() => {
    //only sync if a domain is selected
    if (!domainIsSelected) return;
    //reset filter if the filter is reset from filterbar
    if (!keywordFilter) {
      handleReset();
      return;
    }
    if (keywordFilter?.comparison && filterComp !== keywordFilter?.comparison) {
      setFilterComp(keywordFilter.comparison || 'contains');
    }
    if (keywordFilter?.value) {
      setInputValue(keywordFilter?.value || '');
    }
  }, [domainIsSelected, keywordFilter]);

  const updateFilterComparison = (
    _comparison: StringComparison | null,
    option: StringComparisonOption,
  ) => {
    if (option.value === filterComp) {
      return;
    }
    setFilterComp(option.value);
    if (inputValue === '') {
      return;
    }
    keywordFilter &&
      setFilter(
        {
          ...keywordFilter,
          comparison: option.value,
        },
        FilterTrackingKey.DiscoveryQuickAction,
      );
  };

  const theme = useMantineTheme();

  return (
    <Stack gap={'xl'}>
      <AccTitle type="h3">{t('Keyword Search')}</AccTitle>
      <Stack gap={0}>
        <Flex gap={0} justify="flex-start" align="center" direction="row" wrap="nowrap">
          <AccSelect
            itemComponent={ComparisonOption}
            searchable={false}
            clearable={false}
            classNames={{ input: styles.selectInput, root: styles.selectRoot }}
            size="md"
            bg="gray"
            options={stringComparisonOptions}
            value={filterComp}
            onChange={updateFilterComparison}
          />
          <TextInput
            radius={0}
            styles={{
              root: {
                flexGrow: 1,
                minWidth: 0,
              },
              input: {
                height: 40,
                borderColor: theme.colors.gray[2],
              },
              section: {
                marginRight: 6,
                width: 'unset',
              },
              wrapper: {
                margin: 0,
              },
              error: {
                height: 0,
                transform: 'translateY(4px)',
              },
            }}
            placeholder={t('Press enter to search')}
            aria-label={t('Keyword search')}
            onKeyDown={onSubmit}
            value={inputValue}
            rightSection={inputValue !== '' ? <FilterCount count={filterCount} /> : <div />}
            onChange={(event) => setInputValue(event.currentTarget.value)}
            error={error}
          />
          <Button
            color="gray"
            variant="transparent"
            onClick={onSubmit}
            disabled={inputValue === ''}
            radius={0}
            px={10}
            className={styles.buttonRoot}
          >
            {t('Search')}
          </Button>
          <AccTooltip delay={0} tooltip={t('Clear search')}>
            <AccActionIcon disabled={inputValue === ''} ml={6} onClick={handleReset}>
              <IconTrash size={20} />
            </AccActionIcon>
          </AccTooltip>
        </Flex>
      </Stack>
    </Stack>
  );
};
