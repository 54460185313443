import { Outlet } from 'react-router-dom';
import { useLocalStorage } from '@mantine/hooks';
import cn from 'classnames';
import { GlobalTooltipEngine } from '../../../Components/AccTooltip/GlobalTooltipEngine';
import DevBar from '../../../Components/DevBar';
import ErrorBoundary from '../../../Components/ErrorBoundary';
import Filters from '../../../Components/Filters';
import UnpaidInvoiceBar, { UnpaidInvoiceAlert } from '../../../Components/UnpaidInvoiceBar';
import { WithShellConfigFilters } from '../../../Utilities/Router/components/WithShellConfigFilters';
import Footer from '../FooterEmpty';
import DemoDomainBanner from './DemoDomainBanner/DemoDomainBanner';
import DomainTabs from './DomainTabs/DomainTabs';
import Menu from './Menu';
import SideNavbar from './SideNavbar';
import TrialWarnings from './TopNavbar/TrialWarnings/TrialWarnings';
import styles from './dashboard-template.module.scss';

export const NextDashboardTemplate = () => {
  const [navbarCollapsed, setNavbarCollapsed] = useLocalStorage({
    key: 'navbarCollapsed',
    defaultValue: false,
  });

  return (
    <>
      <GlobalTooltipEngine />
      <div
        className={cn(styles.container, {
          [styles.collapsed]: navbarCollapsed,
        })}
      >
        <SideNavbar
          navbarCollapsed={navbarCollapsed}
          toggleNavbarCollapse={() => setNavbarCollapsed((prev) => !prev)}
        />

        <div className={cn(styles.contents)}>
          <div className={cn(styles.warningsContainer)}>
            <TrialWarnings />
            <UnpaidInvoiceBar />
            <DevBar />
          </div>

          <Menu />

          <WithShellConfigFilters>
            <Filters />
          </WithShellConfigFilters>

          <DomainTabs />

          <UnpaidInvoiceAlert />
          <DemoDomainBanner />

          <ErrorBoundary page={false}>
            <Outlet />
          </ErrorBoundary>

          <Footer />
        </div>
      </div>
    </>
  );
};
