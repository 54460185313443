import { ChangeEvent } from 'react';
import { useApolloClient } from '@apollo/client';
import { ActionIcon, Box, Flex, Grid, Group, Popover } from '@mantine/core';
import { IconHelp } from '@tabler/icons-react';
import moment from 'moment';
import AccBadge from 'Components/AccBadge';
import AccButton from 'Components/AccButton/AccButton';
import AccPaper from 'Components/AccPaper';
import { Field, Form } from 'Components/Fields';
import { OnSubmitCallback } from 'Components/Fields/Form/Form';
import AccText from 'Components/Text/AccText';
import AccTitle from 'Components/Title/AccTitle';
import {
  PublicReportSettingsFragment,
  PublicReportSettingsInput,
  ReportsPublicReportSettingsDefaultDeltaChoices,
  useUpdatePublicReportSettingsMutation,
} from 'Ghql';
import toast from 'Hooks/useToast';
import { GET_KPI_DISPLAY_NAME, KPI_NAMES } from 'Pages/Keywords/Overview/components/KpiBar';
import { t, tct } from 'Utilities/i18n';
import { notEmpty } from 'Utilities/underdash';
import Validator from 'Utilities/validation';
import styleVariables from 'css/base/variables.module.scss';
import { GET_PUBLIC_KPI_DISPLAY_NAME, PUBLIC_KPI_NAMES } from '../publicKpiBar/Boxes';
import styles from './publicReportSettings.module.scss';

type BasicKpiValues = Extract<
  keyof Pick<
    PublicReportSettingsFragment,
    | 'includeKpiTop1'
    | 'includeKpiTop3'
    | 'includeKpiFirstPage'
    | 'includeKpiMovedUp'
    | 'includeKpiMovedDown'
  >,
  string
>;

type AdvancedKpiValues = Extract<
  keyof Pick<
    PublicReportSettingsFragment,
    | 'includeKpiKeywords'
    | 'includeKpiAvgRank'
    | 'includeKpiAvgCtr'
    | 'includeKpiSov'
    | 'includeKpiTrafficValue'
    | 'includeKpiAboveTheFold'
  >,
  string
>;

const BASIC_KPI_VALUES: BasicKpiValues[] = [
  'includeKpiTop1',
  'includeKpiTop3',
  'includeKpiFirstPage',
  'includeKpiMovedUp',
  'includeKpiMovedDown',
];

const ADVANCED_KPI_VALUES: AdvancedKpiValues[] = [
  'includeKpiKeywords',
  'includeKpiAvgRank',
  'includeKpiAvgCtr',
  'includeKpiSov',
  'includeKpiTrafficValue',
  'includeKpiAboveTheFold',
];

type SettingsValues = {
  description: string;
  includeAverageRankChart: boolean;
  includeRankingDistributionChart: boolean;
  includeSearchIntentChart: boolean;
  defaultStartDate: Date | undefined;
  sovType: 'AISoV' | 'SoV';
  basicKpis: boolean;
  includeKpiTop1: boolean;
  includeKpiTop3: boolean;
  includeKpiFirstPage: boolean;
  includeKpiMovedUp: boolean;
  includeKpiMovedDown: boolean;
  advancedKpis: boolean;
  includeKpiKeywords: boolean;
  includeKpiAvgRank: boolean;
  includeKpiAvgCtr: boolean;
  includeKpiSov: boolean;
  includeKpiTrafficValue: boolean;
  includeKpiAboveTheFold: boolean;
  includeAdvancedTableColumns: boolean;
  defaultCompareTo: ReportsPublicReportSettingsDefaultDeltaChoices | '_1' | undefined;
};

const SettingsLabel = ({
  labelText,
  labelTooltip,
}: {
  labelText: string;
  labelTooltip?: string;
}) => (
  <Group wrap="nowrap" gap="xxs">
    <AccText fw={600} size="sm">
      {labelText}
    </AccText>
    {labelTooltip && (
      <Popover
        width={400}
        position="right"
        withArrow
        shadow="md"
        zIndex={styleVariables.zindexTooltip}
      >
        <Popover.Target>
          <ActionIcon radius={'sm'} color="gray.6" size="md" variant="subtle">
            <IconHelp size={20} />
          </ActionIcon>
        </Popover.Target>
        <Popover.Dropdown>
          <AccText size="sm" style={{ whiteSpace: 'pre-line' }}>
            {labelTooltip}
          </AccText>
        </Popover.Dropdown>
      </Popover>
    )}
  </Group>
);

export const PublicReportSettings = ({
  publicReportSettings,
  reportSettingsLoading,
  isAdmin,
  domainFirstRefresh,
}: {
  publicReportSettings: PublicReportSettingsFragment | undefined;
  reportSettingsLoading: boolean;
  isAdmin: boolean;
  domainFirstRefresh: Date | undefined;
}) => {
  const { id: domainId } = publicReportSettings || {};

  const today = new Date();

  const initialDefaultStartDate = publicReportSettings?.defaultStartDate
    ? new Date(publicReportSettings?.defaultStartDate)
    : undefined;

  const client = useApolloClient();

  // Check if any of the basic KPIs are enabled
  const basicKpis = BASIC_KPI_VALUES.map((key) => publicReportSettings?.[key]).some(
    (value) => value,
  );

  // Check if any of the advanced KPIs are enabled
  const advancedKpis = ADVANCED_KPI_VALUES.map((key) => publicReportSettings?.[key]).some(
    (value) => value,
  );

  const defaultDelta = publicReportSettings?.defaultDelta as
    | ReportsPublicReportSettingsDefaultDeltaChoices
    | '_1'
    | undefined;

  const initialValues: SettingsValues = {
    description: publicReportSettings?.description || '',
    includeAverageRankChart: publicReportSettings?.includeAverageRankChart || false,
    includeRankingDistributionChart: publicReportSettings?.includeRankingDistributionChart || false,
    includeSearchIntentChart: publicReportSettings?.includeSearchIntentChart || false,
    defaultStartDate: initialDefaultStartDate || domainFirstRefresh,
    sovType: publicReportSettings?.useAiShareOfVoice ? 'AISoV' : 'SoV',
    basicKpis,
    includeKpiTop1: publicReportSettings?.includeKpiTop1 || false,
    includeKpiTop3: publicReportSettings?.includeKpiTop3 || false,
    includeKpiFirstPage: publicReportSettings?.includeKpiFirstPage || false,
    includeKpiMovedUp: publicReportSettings?.includeKpiMovedUp || false,
    includeKpiMovedDown: publicReportSettings?.includeKpiMovedDown || false,
    advancedKpis,
    includeKpiKeywords: publicReportSettings?.includeKpiKeywords || false,
    includeKpiAvgRank: publicReportSettings?.includeKpiAvgRank || false,
    includeKpiAvgCtr: publicReportSettings?.includeKpiAvgCtr || false,
    includeKpiSov: publicReportSettings?.includeKpiSov || false,
    includeKpiTrafficValue: publicReportSettings?.includeKpiTrafficValue || false,
    includeKpiAboveTheFold: publicReportSettings?.includeKpiAboveTheFold || false,
    includeAdvancedTableColumns: publicReportSettings?.includeAdvancedTableColumns || false,
    defaultCompareTo: defaultDelta,
  };

  const [
    updateSettings,
    //{ loading: updateSettingsLoading }
  ] = useUpdatePublicReportSettingsMutation();

  const handleSaveSettings: OnSubmitCallback<SettingsValues> = ({
    description,
    includeAverageRankChart,
    includeRankingDistributionChart,
    includeSearchIntentChart,
    defaultStartDate,
    sovType,
    includeKpiTop1,
    includeKpiTop3,
    includeKpiFirstPage,
    includeKpiMovedUp,
    includeKpiMovedDown,
    includeKpiKeywords,
    includeKpiAvgRank,
    includeKpiAvgCtr,
    includeKpiSov,
    includeKpiTrafficValue,
    includeKpiAboveTheFold,
    includeAdvancedTableColumns,
    defaultCompareTo,
  }) => {
    try {
      if (!domainId) {
        throw new Error(
          'No domainId found when trying to send updatePublicReportSettings mutation',
          { cause: 'no domainId found' },
        );
      }

      // make sure an empty description is truly empty
      if (description === '<p></p>') {
        description = '';
      }

      const input: PublicReportSettingsInput = {
        description,
        includeAverageRankChart,
        includeRankingDistributionChart,
        includeSearchIntentChart,
        defaultStartDate: defaultStartDate
          ? moment(defaultStartDate.toString()).format('YYYY-MM-DD')
          : undefined,
        id: domainId,
        useAiShareOfVoice: sovType === 'AISoV',
        includeAdvancedTableColumns,
        includeKpiAboveTheFold,
        includeKpiAvgCtr,
        includeKpiAvgRank,
        includeKpiFirstPage,
        includeKpiKeywords,
        includeKpiMovedDown,
        includeKpiMovedUp,
        includeKpiSov,
        includeKpiTop1,
        includeKpiTop3,
        includeKpiTrafficValue,
        defaultDelta:
          defaultCompareTo === '_1'
            ? -1
            : defaultCompareTo
            ? Number(defaultCompareTo?.replace('A_', ''))
            : -1,
      };

      updateSettings({
        variables: {
          input,
        },
      }).then((res) => {
        const errors = res.data?.updatePublicReportSettings?.errors?.filter(notEmpty) || [];
        if (errors && errors.length) {
          toast.error(t('Failed to save report settings'));
          Validator.setResponseErrors(Validator.throwSubmissionError, errors);
        } else {
          client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'publicReportSettings' });
          client.cache.gc();
          toast.success(t('Settings saved!'));
        }
      });
    } catch (err) {
      if (err instanceof Error) {
        console.error('Error occurred while saving report settings:', err);
      }
    }
  };

  if (!isAdmin) {
    return (
      <>
        {initialValues.description && (
          <div>
            <AccPaper p="0">
              <div
                // Add HTML content from the rich text editor
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: initialValues.description }}
              />
            </AccPaper>
          </div>
        )}
      </>
    );
  }

  const compareToOptions: { label: string; value: string }[] = [
    { label: t('Initial'), value: '_1' },
    { label: t('Yesterday'), value: 'A_1' },
    { label: t('One Week Ago'), value: 'A_7' },
    { label: t('One Month Ago'), value: 'A_30' },
    { label: t('One Year Ago'), value: 'A_365' },
  ];

  return (
    <div>
      <AccPaper p="0">
        <Form<SettingsValues>
          onSubmit={handleSaveSettings}
          initialValues={initialValues}
          subscription={{
            values: true,
            submitting: true,
            submitSucceeded: true,
            submitError: true,
            submitErrors: true,
            submitFailed: true,
            hasSubmitErrors: true,
            pristine: true,
            error: true,
            errors: true,
          }}
        >
          {({ form, submitting }) => {
            const formValues = form.getState().values;

            const handleUpdateAllBasic = (e: ChangeEvent<HTMLInputElement>) => {
              if (e.currentTarget.checked) {
                BASIC_KPI_VALUES.map((kpiKey) => {
                  form.change(kpiKey, true);
                });
                return;
              }
              BASIC_KPI_VALUES.map((kpiKey) => {
                form.change(kpiKey, false);
              });
            };

            const handleUpdateAllAdvanced = (e: ChangeEvent<HTMLInputElement>) => {
              if (e.currentTarget.checked) {
                ADVANCED_KPI_VALUES.map((kpiKey) => {
                  form.change(kpiKey, true);
                });
                return;
              }
              ADVANCED_KPI_VALUES.map((kpiKey) => {
                form.change(kpiKey, false);
              });
            };

            // Check if all basic KPIs are disabled and disable the basicKpis switch
            const handleCheckBasicKpis = (eventTarget: HTMLInputElement) => {
              if (eventTarget.checked) {
                return;
              }
              const currentFormValues = form.getState().values;
              const isAllDisabled = !BASIC_KPI_VALUES.some(
                (kpiKey) => currentFormValues[kpiKey] === true,
              );
              if (isAllDisabled) {
                form.change('basicKpis', false);
              }
            };

            // Check if all advanced KPIs are disabled and disable the advancedKpis switch
            const handleCheckAdvancedKpis = (eventTarget: HTMLInputElement) => {
              if (eventTarget.checked) {
                return;
              }
              const currentFormValues = form.getState().values;
              const isAllDisabled = !ADVANCED_KPI_VALUES.some(
                (kpiKey) => currentFormValues[kpiKey] === true,
              );
              if (isAllDisabled) {
                form.change('advancedKpis', false);
              }
            };

            return (
              <Flex align="flex-start" gap="sm" direction="column" w="100%">
                <AccTitle type="h2">{t('Report settings')}</AccTitle>
                <Flex w="100%" gap="xs" wrap="nowrap" direction="column">
                  <AccTitle type="h3">{t('Presentation')}</AccTitle>
                  <Group align="center" gap="xxs">
                    <AccBadge type="brand" variant="filled">
                      {t('Note:')}
                    </AccBadge>
                    <AccText variant="body">
                      {t(
                        'This editor is not visible to your clients. Clients will only see the final presentation.',
                      )}
                    </AccText>
                  </Group>
                  <Field.RichTextEditor
                    name="description"
                    disabled={submitting}
                    loading={reportSettingsLoading}
                    className={styles.richTextEditorWrapper}
                  />
                </Flex>
                <Flex gap="sm" wrap="wrap" align="flex-start" direction="column" w="100%">
                  <AccTitle type="h3">{t('Settings')}</AccTitle>
                  <Group align="center" gap="xxs">
                    <AccBadge type="brand" variant="filled">
                      {t('Note:')}
                    </AccBadge>
                    <AccText variant="body">
                      {t(
                        'These settings are not visible to your clients. Clients will see the report based on your settings.',
                      )}
                    </AccText>
                  </Group>
                  <Grid w="100%" gutter={{ base: 'xl' }} classNames={{ col: styles.gridCol }}>
                    <Grid.Col span={{ base: 12, xs: 6, md: 4 }}>
                      <Flex gap="sm" direction="column">
                        <Field.DateInput
                          name="defaultStartDate"
                          minDate={domainFirstRefresh}
                          maxDate={today}
                          label={
                            <SettingsLabel
                              labelText={t('Earliest possible start date')}
                              labelTooltip={t(
                                'Sets a limit on the earliest selectable date for data filtering.\nData prior to this date will not be accessible.',
                              )}
                            />
                          }
                        />
                        <SettingsLabel labelText={t('Include charts in report')} />
                        <Field.Checkbox
                          name="includeAverageRankChart"
                          disabled={submitting}
                          label={t('Average Rank chart')}
                        />
                        <Field.Checkbox
                          name="includeRankingDistributionChart"
                          disabled={submitting}
                          label={t('Ranking Distribution chart')}
                        />
                        <Field.Checkbox
                          name="includeSearchIntentChart"
                          disabled={submitting}
                          label={t('Search Intent chart')}
                        />
                      </Flex>
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, xs: 6, md: 4 }}>
                      <Flex gap="sm" direction="column">
                        <Group align="center" justify="space-between">
                          <SettingsLabel
                            labelText={t('Show basic KPI boxes')}
                            labelTooltip={tct(
                              'Show boxes with basic [KPIs] on the domains keyword rankings.',
                              {
                                KPIs: <b>{t('Key Performance Indicators')}</b>,
                              },
                            )}
                          />
                          <Field.SwitchInput
                            name="basicKpis"
                            customOnChange={handleUpdateAllBasic}
                          />
                        </Group>
                        <Field.Checkbox
                          name="includeKpiTop1"
                          disabled={formValues.basicKpis === false || submitting}
                          label={GET_PUBLIC_KPI_DISPLAY_NAME()[PUBLIC_KPI_NAMES.TOP_ONE_KEYWORDS]}
                          customOnChange={handleCheckBasicKpis}
                        />
                        <Field.Checkbox
                          name="includeKpiTop3"
                          disabled={formValues.basicKpis === false || submitting}
                          label={GET_PUBLIC_KPI_DISPLAY_NAME()[PUBLIC_KPI_NAMES.TOP_THREE_KEYWORDS]}
                          customOnChange={handleCheckBasicKpis}
                        />
                        <Field.Checkbox
                          name="includeKpiFirstPage"
                          disabled={formValues.basicKpis === false || submitting}
                          label={t(GET_PUBLIC_KPI_DISPLAY_NAME()[PUBLIC_KPI_NAMES.ON_PAGE_ONE])}
                          customOnChange={handleCheckBasicKpis}
                        />
                        <Field.Checkbox
                          name="includeKpiMovedUp"
                          disabled={formValues.basicKpis === false || submitting}
                          label={GET_PUBLIC_KPI_DISPLAY_NAME()[PUBLIC_KPI_NAMES.KEYWORDS_MOVED_UP]}
                          customOnChange={handleCheckBasicKpis}
                        />
                        <Field.Checkbox
                          name="includeKpiMovedDown"
                          disabled={formValues.basicKpis === false || submitting}
                          label={
                            GET_PUBLIC_KPI_DISPLAY_NAME()[PUBLIC_KPI_NAMES.KEYWORDS_MOVED_DOWN]
                          }
                          customOnChange={handleCheckBasicKpis}
                        />
                      </Flex>
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, xs: 6, md: 4 }}>
                      <Flex gap="sm" direction="column">
                        <Group align="center" justify="space-between">
                          <SettingsLabel
                            labelText={t('Show advanced KPI boxes')}
                            labelTooltip={tct(
                              'Show boxes with advanced [KPIs] on the domains keyword rankings.',
                              {
                                KPIs: <b>{t('Key Performance Indicators')}</b>,
                              },
                            )}
                          />
                          <Field.SwitchInput
                            name="advancedKpis"
                            customOnChange={handleUpdateAllAdvanced}
                          />
                        </Group>
                        <Field.Checkbox
                          name="includeKpiKeywords"
                          disabled={formValues.advancedKpis === false || submitting}
                          label={GET_KPI_DISPLAY_NAME()[KPI_NAMES.KEYWORDS]}
                          customOnChange={handleCheckAdvancedKpis}
                        />
                        <Field.Checkbox
                          name="includeKpiAvgRank"
                          disabled={formValues.advancedKpis === false || submitting}
                          label={GET_KPI_DISPLAY_NAME()[KPI_NAMES.AVERAGE_RANK]}
                          customOnChange={handleCheckAdvancedKpis}
                        />
                        <Field.Checkbox
                          name="includeKpiAvgCtr"
                          disabled={formValues.advancedKpis === false || submitting}
                          label={GET_KPI_DISPLAY_NAME()[KPI_NAMES.DYNAMIC_CTR]}
                          customOnChange={handleCheckAdvancedKpis}
                        />
                        <Field.Checkbox
                          name="includeKpiSov"
                          disabled={formValues.advancedKpis === false || submitting}
                          label={GET_KPI_DISPLAY_NAME()[KPI_NAMES.SHARE_OF_VOICE]}
                          customOnChange={handleCheckAdvancedKpis}
                        />
                        <Field.Checkbox
                          name="includeKpiTrafficValue"
                          disabled={formValues.advancedKpis === false || submitting}
                          label={GET_KPI_DISPLAY_NAME()[KPI_NAMES.TRAFFIC_VALUE]}
                          customOnChange={handleCheckAdvancedKpis}
                        />
                        <Field.Checkbox
                          name="includeKpiAboveTheFold"
                          disabled={formValues.advancedKpis === false || submitting}
                          label={GET_KPI_DISPLAY_NAME()[KPI_NAMES.ABOVE_THE_FOLD]}
                          customOnChange={handleCheckAdvancedKpis}
                        />
                      </Flex>
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, xs: 6, md: 4 }}>
                      <Flex gap="sm" direction="column">
                        <Field.SegmentedControl
                          animated
                          label={
                            <SettingsLabel
                              labelText={t('Share of Voice calculation')}
                              labelTooltip={tct(
                                'We recommend that users use the latest [AISOV] algorithm, which uses a machine learning model.\n However, it is still possible to view [SOV] using the older implementation if needed.',
                                {
                                  AISOV: <b>{t('AI Share of Voice')}</b>,
                                  SOV: <b>{t('Share of Voice')}</b>,
                                },
                              )}
                            />
                          }
                          name="sovType"
                          disabled={submitting}
                          data={[
                            { label: t('AI Share of Voice'), value: 'AISoV' },
                            { label: t('Share of Voice'), value: 'SoV' },
                          ]}
                        />
                        <SettingsLabel
                          labelText={t('Included keywords table columns')}
                          labelTooltip={tct(
                            'Show additional table columns with advanced keyword metrics.\n\nThis will add the [searchIntent] and [CTR] columns to the keywords chart.',
                            {
                              searchIntent: <b>{t('Search Intent-')}</b>,
                              CTR: <b>{t('Click-Through Rate')}</b>,
                            },
                          )}
                        />
                        <Field.Checkbox
                          name="includeAdvancedTableColumns"
                          disabled={submitting}
                          label={t('Advanced table columns')}
                        />
                        <Field.SelectValue
                          name="defaultCompareTo"
                          disabled={submitting}
                          label={t('Default compare to')}
                          options={compareToOptions}
                          withinPortal={false}
                          clearable={false}
                          size="md"
                        />
                      </Flex>
                    </Grid.Col>
                  </Grid>
                </Flex>
                <Box ml="auto" w="fit-content">
                  <AccButton type="submit" disabled={submitting}>
                    {submitting ? t('Saving') : t('Save')}
                  </AccButton>
                </Box>
              </Flex>
            );
          }}
        </Form>
      </AccPaper>
    </div>
  );
};
