import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import compose from 'lodash/flowRight';
import isEmpty from 'lodash/isEmpty';
import uniqueId from 'lodash/uniqueId';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import AccButton from 'Components/AccButton/AccButton';
import { SelectItem } from 'Components/AccSelect';
import { Checkbox, DateField, KeywordsInputField, TextAreaField } from 'Components/Forms/Fields';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import Skeleton from 'Components/Skeleton';
import { QueryOrder } from 'Constants';
import toast from 'Hooks/useToast';
import { formatDate } from 'Utilities/format';
import { t } from 'Utilities/i18n';
import { graphqlError, graphqlLoading } from 'Utilities/underdash';
import Validation from 'Utilities/validation';
import './edit-note-form.scss';

type Props = {
  handleSubmit: (...args: Array<any>) => any;
  noteId: string;
  updateNote: (...args: Array<any>) => any;
  getNote: any;
  hideModal: (...args: Array<any>) => any;
  onBack?: (...args: Array<any>) => any;
  refresh: (...args: Array<any>) => any;
  forAllKeywords: boolean;
  enableEditingKeywords: boolean;
  defaultEditMode?: boolean;
  keywordOptions?: SelectItem<string>[];
};
type State = {
  inProgress: boolean;
  editMode: boolean;
};

export const NOTE_KEYWORD_ORDER = {
  orderBy: 'keyword_length',
  order: QueryOrder.ASC,
};

class EditNoteForm extends Component<Props, State> {
  static defaultProps = {
    defaultEditMode: true,
  };
  state = {
    inProgress: false,
    editMode: this.props.defaultEditMode ?? false,
  };
  handleEdit = (evt) => {
    evt.preventDefault();
    this.setState({
      editMode: true,
    });
  };
  getKeywordsInput = (ids, shouldExclude) => {
    if (isEmpty(ids) && !shouldExclude) {
      return {};
    }

    return {
      keywordsToExclude: shouldExclude ? ids : [],
      keywords: !shouldExclude ? ids : [],
    };
  };
  handleSubmit = (values: any) => {
    const {
      getNote: { note },
      onBack,
    } = this.props;
    this.setState({
      inProgress: true,
      editMode: false,
    });
    this.props
      .updateNote({
        variables: {
          input: {
            ...this.getKeywordsInput(values.keywords, values.forAllKeywords),
            id: note.id,
            note: values.note,
            createdAt: formatDate(values.createdAt),
            delete: false,
          },
        },
      })
      .then(
        () => {},
        () => {
          toast.error(t('Failed to update note'));
        },
      )
      .then(() => {
        this.setState({
          inProgress: false,
        });
        this.props.refresh();
        this.props.getNote.refetch();
        onBack ? onBack(note) : this.props.hideModal();
      });
  };

  renderSkeleton() {
    return (
      <div className="edit-note-form">
        <Skeleton
          className="indented-form-group form-group"
          linesConfig={[
            {
              type: 'text',
              options: {
                width: '20%',
              },
            },
            {
              type: 'input',
            },
            {
              type: 'text',
              options: {
                width: '20%',
              },
            },
            {
              type: 'input',
              options: {
                height: '80px',
              },
            },
            {
              type: 'text',
              options: {
                width: '70%',
              },
            },
            {
              type: 'text',
              options: {
                width: '50%',
              },
            },
          ]}
        />
        <Skeleton
          className="footer"
          linesConfig={[
            {
              type: 'button',
              options: {
                display: 'inline-block',
                width: '15%',
              },
            },
            {
              type: 'button',
              options: {
                display: 'inline-block',
                width: '15%',
                marginLeft: '0.5rem',
              },
            },
          ]}
        />
      </div>
    );
  }

  render() {
    if (graphqlError({ ...this.props }) || graphqlLoading({ ...this.props })) {
      return this.renderSkeleton();
    }

    const { inProgress, editMode } = this.state;
    const {
      getNote: { note },
      handleSubmit,
      onBack,
      enableEditingKeywords,
      forAllKeywords,
    } = this.props;
    const button = editMode ? (
      <AccButton variant="primary" type="submit" disabled={inProgress} loading={inProgress}>
        {t('Save')}
      </AccButton>
    ) : (
      <AccButton variant="primary" onClick={this.handleEdit}>
        {t('Edit')}
      </AccButton>
    );
    return (
      <form className="edit-note-form" onSubmit={handleSubmit(this.handleSubmit)}>
        <div className="form-label required">{t('Note')}</div>
        <Field
          disabled={!editMode}
          placeholder={t('Enter note')}
          component={TextAreaField}
          validate={Validation.required}
          name="note"
          autoFocus={true}
        />
        <div className="form-label required">{t('Date')}</div>
        <Field disabled={!editMode} component={DateField} name="createdAt" />
        {editMode && enableEditingKeywords ? (
          <Fragment>
            <Field name="forAllKeywords" component={Checkbox} defaultChecked={forAllKeywords}>
              {t('Add for all keywords')}
            </Field>
            <div className="form-label">
              {!forAllKeywords ? t('Keywords') : t('Keywords to exclude')}
            </div>
            <Field
              name="keywords"
              component={KeywordsInputField}
              ordering={NOTE_KEYWORD_ORDER}
              valueOptions={this.props.keywordOptions}
              fullWidth
              size="default"
            />
          </Fragment>
        ) : (
          !!note.keywords.length && (
            <Fragment>
              <div className="form-label">{t('Related Keywords')}</div>
              <div className="keywords">
                {note.keywords
                  .filter((obj) => obj !== null)
                  .map((keyword) => (
                    <span key={uniqueId('related-keyword')}>{keyword.keyword}</span>
                  ))}
              </div>
            </Fragment>
          )
        )}
        <ModalFooter
          primaryButtonSlot={button as JSX.Element}
          secondaryButtonSlot={
            <AccButton
              variant="tertiary"
              disabled={inProgress}
              onClick={onBack ? () => onBack(note) : this.props.hideModal}
            >
              {onBack ? t('Back') : t('Close')}
            </AccButton>
          }
        />
      </form>
    );
  }
}

const updateNoteQuery = gql`
  mutation editNoteForm_updateNote($input: UpdateNoteInput!) {
    updateNote(input: $input) {
      note {
        id
      }
    }
  }
`;
const getNoteQuery = gql`
  query editNoteForm_getNote($id: ID!) {
    note(id: $id) {
      id
      createdAt
      note
      keywords {
        id
        keyword
      }
    }
  }
`;
const formName = 'EditNoteForm';
const formSelector = formValueSelector(formName);

const mapStateToProps = (state) => ({
  forAllKeywords: formSelector(state, 'forAllKeywords'),
});

export default compose(
  connect(mapStateToProps),
  graphql(updateNoteQuery, {
    name: 'updateNote',
  }),
  graphql(getNoteQuery, {
    name: 'getNote',
    options: (props: Props) => ({
      enableReinitialize: true,
      variables: {
        id: props.noteId,
      },
    }),
    props: ({ getNote, getNote: { loading, error } }: any) => {
      if (loading || error) {
        return {
          getNote,
        };
      }

      const { note } = getNote;
      const keywordOptions = note.keywords.filter(Boolean).map(({ id, keyword }) => ({
        value: id,
        label: keyword,
      }));
      return {
        getNote,
        keywordOptions,
        initialValues: {
          note: note.note,
          keywords: keywordOptions?.map((e) => e.value),
          createdAt: new Date(note.createdAt),
        },
      };
    },
  }),
)(
  reduxForm({
    form: formName,
    enableReinitialize: true,
  })(EditNoteForm),
);
