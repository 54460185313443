import { useEffect, useState } from 'react';
import { useField } from 'react-final-form';
import { useDebouncedValue } from '@mantine/hooks';
import { useUpdateUser } from 'Components/Modal/Content/EditUser/useUpdateUser';
import { defaultRole } from 'Constants/workspaces';

const DEBOUNCE = 800;

/**
 * Custom hook that handles updating a user's data when a change occurs.
 * @param record - The user record containing the data to be updated.
 */
export const useUpdateUserOnChange = ({
  id,
  email,
  fullName,
  userType,
  workspaces,
}: Record<string, any>) => {
  const prefix = `${id}_`;
  const { input: userRole } = useField(`${prefix}userRole`);
  const { input: workspacesInput } = useField(`${prefix}workspaces`);
  const updateUser = useUpdateUser({ id, inlineForm: true });
  const inputs = {
    email,
    fullName,
    workspaces: workspacesInput.value || workspaces?.map((role) => role.id),
    delete: false,
    userType: userRole.value || userType || defaultRole,
  };

  // Stringify inputs to compare with initial inputs, use debounced value to prevent multiple updates
  const [stringifiedInputs] = useDebouncedValue(JSON.stringify(inputs), DEBOUNCE);
  const [currentInput, setCurrentInput] = useState(stringifiedInputs);
  useEffect(() => {
    if (currentInput !== stringifiedInputs) {
      setCurrentInput(stringifiedInputs);
      updateUser(inputs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringifiedInputs]);
};
