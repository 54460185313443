import { useState } from 'react';
import { BuilderTokenGroup } from './BuilderTokenGroup';

export const BuilderTokenGroupField = ({ name }: { name: string }) => {
  const [activeGroup, setActiveGroup] = useState(undefined);
  return (
    <BuilderTokenGroup
      name={name}
      activeGroup={activeGroup}
      setActiveGroup={setActiveGroup}
      nestedLevel={0}
    />
  );
};
