import { DataTable } from 'Components/DataTable';
import { TableIDs } from 'Types/Table';
import { ColumnIDs } from './support/constants';
import { useFetchSubAccounts, useSubAccountsColumnsConfig } from './support/hooks';

const SUB_ACCOUNTS_TABLE = TableIDs.SUB_ACCOUNTS;

const offlineFilterConfig = {
  tableName: SUB_ACCOUNTS_TABLE,
  skipAll: true,
  mappings: {
    [ColumnIDs.ACCOUNT_NAME]: 'toOrganization.name',
    [ColumnIDs.USER_TYPE]: 'isOrgAdmin',
    [ColumnIDs.PAID_BY_YOU]: 'fromOrganizationPays',
    [ColumnIDs.INVOICES]: 'fromOrganizationPays',
    [ColumnIDs.NEXT_PAYMENT]: 'toOrganization.activePlan.endDate',
  },
};
export const SubAccountsTable = ({ updateTable, pageKey }) => {
  const columns = useSubAccountsColumnsConfig(updateTable);
  const fetchData = useFetchSubAccounts();

  return (
    <DataTable
      tableId={SUB_ACCOUNTS_TABLE}
      fetchData={fetchData}
      columns={columns}
      dataKey={pageKey}
      key={pageKey}
      offlineFilter={offlineFilterConfig}
    />
  );
};
