import { Badge } from '@mantine/core';
import cn from 'classnames';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import AccText from 'Components/Text/AccText';
import { t } from 'Utilities/i18n';
import styles from './demo-content.module.scss';

type Props = {
  className?: string;
  title?: string;
  tooltipTitle?: string;
  variant?: 'normal' | 'treeview';
  type?: 'group' | 'domain' | 'folder' | 'tag';
};

const DemoContentBadge = (props: Props) => {
  const { className, tooltipTitle, title, variant = 'normal', type = 'domain' } = props;

  const textSizeByVariant: Record<'normal' | 'treeview', string> = {
    normal: 'xs',
    treeview: '10px',
  };

  return ['group', 'domain'].includes(type) ? (
    <AccTooltip
      tooltip={
        tooltipTitle ||
        (type === 'group'
          ? t('This is a demo group and cannot be edited.<br>The data is updated once a day.')
          : t('This is a demo domain and cannot be edited.<br>The data is updated once a day.'))
      }
    >
      <Badge
        miw={variant === 'normal' ? 'fit-content' : '100px'}
        color="blue"
        className={cn(styles.demoContentBadge, className)}
        variant="filled"
      >
        <AccText size={textSizeByVariant[variant]} fw={600} c="white" lh={1}>
          {title || type === 'group' ? t('Demo Group') : t('Demo Domain')}
        </AccText>
      </Badge>
    </AccTooltip>
  ) : null;
};

export default DemoContentBadge;
