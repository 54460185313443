import React, { useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Tabs } from '@mantine/core';
import { useUnknownCompetitorsBarChartQuery } from 'Ghql';
import { useFilters } from 'Hooks';
import { useModal } from 'Hooks/base/useModal';
import { useDomainId, useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import useUserPermission from 'Hooks/useUserPermission';
import { MissingDataOverlay } from 'Pages/Keywords/Overview/MissingDataOverlay';
import { BarChart, LabelActionButton } from 'Pages/Keywords/Overview/components/BarChart/BarChart';
import { ChartContainer } from 'Pages/Keywords/Overview/components/ChartContainer/index';
import styles from 'Pages/Keywords/Overview/components/ShareOfVoiceByType/styles.module.scss';
import { UnknownCompetitorRankDistributionChart } from 'Pages/Keywords/Overview/components/UnknownCompetitorTabs/RankDistribution';
import { UnknownCompetitorScoreOverTime } from 'Pages/Keywords/Overview/components/UnknownCompetitorTabs/ScoreOverTime';
import { WithDragDetails } from 'Pages/Keywords/Overview/support/types';
import { selectFeatureAdvancedMetrics } from 'Selectors/DomainSelector';
import { colorScheme } from 'Utilities/colors';
import { getFaviconByDomain } from 'Utilities/favicon';
import { t, tct } from 'Utilities/i18n';
import { useGroupViewInfo } from '../../hooks/useGroupViewInfo';

const UnknownCompetitorTabs = (props: WithDragDetails) => {
  const { showModal } = useModal();
  const domainId = useDomainId();
  const filters = useFilters();
  const { isEmptyGroup } = useGroupViewInfo(filters);
  const chartRef = useRef<HTMLDivElement>(null);
  const scoreRef = useRef<HTMLDivElement>(null);
  const ranksRef = useRef<HTMLDivElement>(null);
  const scoreOverTimeRef = useRef<HTMLDivElement>(null);
  const [activeTab, setActiveTab] = useState<string | null>('score');
  const { displayNameMap, isDemoDomain } = useQueryDomainInfo();
  const { userHasWriteAccess } = useUserPermission();
  const featureAdvancedMetrics = useSelector(selectFeatureAdvancedMetrics);
  const {
    data: barChartData,
    loading,
    refetch: refetchUnknownComps,
  } = useUnknownCompetitorsBarChartQuery({
    variables: { filters, name: 'unknownCompetitorBar' },
    skip: !domainId,
  });

  const { domainInfo, loadingDomainInfo } = useQueryDomainInfo();
  const isGroupView = !loadingDomainInfo && !domainInfo;
  const isYoutubeDomain = Boolean(domainInfo?.youtubeChannelName);

  const competitors = (barChartData?.charts?.unknownCompetitors?.map((x) => x?.domain) ||
    []) as string[];

  const showAddCompetitorModal = (domainToAdd: string | undefined | null) =>
    showModal({
      modalType: 'BuildCompetitor',
      modalTheme: 'light',
      modalProps: {
        domainId,
        domainToAdd: domainToAdd ?? '',
        refresh: refetchUnknownComps,
      },
    });

  const labelActionTooltip = t('Pin this domain as a static competitor');

  const dataItems = useMemo(() => {
    return (
      barChartData?.charts?.unknownCompetitors?.map((e, idx) => {
        return {
          value: e?.amount ?? 0,
          label: (displayNameMap && displayNameMap[e?.domain || '']) || e?.domain || '',
          labelActionButtons:
            !e?.competitor &&
            !e?.ownDomain &&
            !isDemoDomain &&
            userHasWriteAccess &&
            e?.domain?.includes('.') // filter out most local results this way
              ? [
                  <LabelActionButton
                    text={'+'}
                    tooltip={labelActionTooltip}
                    callback={
                      !e?.competitor && !e?.ownDomain
                        ? () => showAddCompetitorModal(e?.domain)
                        : undefined
                    }
                    key={`addCompetitor${e?.domain || ''}`}
                  />,
                ]
              : undefined,
          background: e?.ownDomain ? colorScheme.orange : colorScheme.shareVoice,
          isOwnDomain: e?.ownDomain,
          icon: (
            <>
              <Box w={30} display="inline-block">
                {' '}
                #{idx + 1}{' '}
              </Box>
              <img
                src={
                  // If your own domain is YouTube domain, we assume other domains are YouTube channels
                  e?.domain ? getFaviconByDomain(isYoutubeDomain ? 'youtube.com' : e.domain) : ''
                }
                alt=""
              />
            </>
          ),
          id: e?.domain,
        };
      }) ?? []
    );
  }, [barChartData]);

  const tabs = { SCORE: 'score', RANKS: 'ranks', SCORE_OVER_TIME: 'scoreOverTime' };

  const tooltip = [
    tct(
      'See who your biggest competitors are based on their ranking ' +
        'performance on your keywords in the current filtering. Apply any filter or segment to see your competitors for the corresponding set of keywords![br][br]' +
        '[tab1][br]' +
        'For each keyword, each competitor gets a score between 0 and 10 based on their rank. Rank 1 gets 10 points, rank 2 gets 9 points, and so on. ' +
        'The total score is the sum of these scores for all keywords in the current filtering.[br][br]' +
        '[tab2][br]' +
        'See which ranks each competitor has.[br][br]' +
        '[tab3][br]' +
        'See your competitors\' relative dominance of the top SERP over time.',
      {
        br: <br />,
        tab1: <b>{t('Score')}</b>,
        tab2: <b>{t('Ranks')}</b>,
        tab3: <b>{t('Score over time')}</b>,
      },
    ),
  ];

  const resultRef = (() => {
    switch (activeTab) {
      case tabs.SCORE:
        return scoreRef;
      case tabs.RANKS:
        return ranksRef;
      case tabs.SCORE_OVER_TIME:
        return scoreOverTimeRef;
      default:
        return chartRef;
    }
  })();

  return (
    <ChartContainer
      title={t('Dynamic Top 10 Competitors')}
      tooltip={tooltip}
      chartRef={resultRef}
      dragRef={props.dragRef}
      boardControls={props.boardControls}
    >
      <Box>
        <Tabs value={activeTab} onChange={setActiveTab} className={styles.tab} keepMounted={false}>
          <Tabs.List>
            <Tabs.Tab value={tabs.SCORE}>{t('Score')}</Tabs.Tab>
            <Tabs.Tab value={tabs.SCORE_OVER_TIME}>{t('Score over time')}</Tabs.Tab>
            <Tabs.Tab value={tabs.RANKS}>{t('Ranks')}</Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value={tabs.SCORE} pt="xs">
            <Box ref={scoreRef}>
              {!featureAdvancedMetrics && (
                <MissingDataOverlay
                  type="featureAdvancedMetrics"
                  extraBlurry={true}
                  isGroupView={isGroupView}
                  isEmptyGroup={isEmptyGroup}
                />
              )}
              <BarChart normalNumberOfItems={10} loading={loading} items={dataItems} />
            </Box>
          </Tabs.Panel>
          <Tabs.Panel value={tabs.SCORE_OVER_TIME} pt="xs">
            {activeTab === tabs.SCORE_OVER_TIME && (
              <Box ref={scoreOverTimeRef}>
                {/*{alert(1)}*/}
                {!featureAdvancedMetrics && (
                  <MissingDataOverlay
                    type="featureAdvancedMetrics"
                    extraBlurry={true}
                    isGroupView={isGroupView}
                    isEmptyGroup={isEmptyGroup}
                  />
                )}
                <UnknownCompetitorScoreOverTime competitors={competitors} />
              </Box>
            )}
          </Tabs.Panel>
          <Tabs.Panel value={tabs.RANKS} pt="xs">
            <Box ref={ranksRef}>
              {!featureAdvancedMetrics && activeTab === tabs.RANKS && (
                <MissingDataOverlay
                  type="featureAdvancedMetrics"
                  extraBlurry={true}
                  isGroupView={isGroupView}
                  isEmptyGroup={isEmptyGroup}
                />
              )}
              {activeTab === tabs.RANKS && (
                <UnknownCompetitorRankDistributionChart competitors={competitors} />
              )}
            </Box>
          </Tabs.Panel>
        </Tabs>
      </Box>
    </ChartContainer>
  );
};

export default UnknownCompetitorTabs;
