import { Flex, Stack } from '@mantine/core';
import AccButton from 'Components/AccButton/AccButton';
import { Field, Form } from 'Components/Fields';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import AccText from 'Components/Text/AccText';
import { useSalesOrganizationPlanQuery, useUpdateSalesOrganizationPlanMutation } from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import toast from 'Hooks/useToast';
import { t } from 'Utilities/i18n';
import Validator from 'Utilities/validation';

type FormValues = {
  maxKeywords: string;
  maxCompetitors: string;
  maxNumbersOfWorkspaces: string;
  featureAdvancedMetrics: boolean;
  featureApiAccess: boolean;
  featureApiV4WriteAccess: boolean;
};

type Props = {
  planId: number;
};

const SalesOrganizationPlan = ({ planId }: Props) => {
  const { hideModal } = useModal();

  const { data, loading } = useSalesOrganizationPlanQuery({
    variables: {
      id: planId.toString(),
    },
    fetchPolicy: 'network-only',
  });

  const initialValues = data?.adminOrganizationPlan;

  const [updateSalesOrganizationPlanMutation] = useUpdateSalesOrganizationPlanMutation();

  const handleSubmit = (values: FormValues) => {
    updateSalesOrganizationPlanMutation({
      variables: {
        input: {
          planId: planId.toString(),
          maxKeywords: parseInt(values.maxKeywords, 10),
          maxCompetitors: parseInt(values.maxCompetitors, 10),
          maxNumbersOfWorkspaces: parseInt(values.maxNumbersOfWorkspaces, 10),
          featureAdvancedMetrics: values.featureAdvancedMetrics,
          featureApiAccess: values.featureApiAccess,
          featureApiV4WriteAccess: values.featureApiV4WriteAccess,
        },
      },
    }).then((result) => {
      if (result.errors && result.errors.length > 0) {
        toast.error(t('Plan update failed'));
      }
      toast.success(t('Plan updated'));
      hideModal();
    });
  };

  if (loading) {
    return (
      <ModalBorder title={t('Edit Organization Plan')} onClose={hideModal}>
        <Flex>
          <AccText>{t('Loading')}</AccText>
        </Flex>
      </ModalBorder>
    );
  }

  return (
    <ModalBorder title={t('Edit Organization Plan')} onClose={hideModal}>
      <Form
        initialValues={{
          maxKeywords: initialValues?.maxKeywords.toString() || '',
          maxCompetitors: initialValues?.maxCompetitors.toString() || '',
          maxNumbersOfWorkspaces: initialValues?.maxNumbersOfWorkspaces.toString() || '',
          featureAdvancedMetrics: true,
          featureApiAccess: initialValues?.featureApiAccess || false,
          featureApiV4WriteAccess: initialValues?.featureApiV4WriteAccess || false,
        }}
        subscription={{ values: true, submitting: true, invalid: true }}
        onSubmit={(values: FormValues) => handleSubmit(values)}
      >
        {({ submitting, invalid }) => (
          <>
            <Stack gap="xl">
              <div>
                <div className="form-label">{t('Max keywords')}</div>
                <Field.TextInput
                  name="maxKeywords"
                  helpText={t(
                    `Amount of keywords available on this plan. Current amount: ${
                      initialValues?.maxKeywords.toString() || ''
                    }.`,
                  )}
                  disabled={submitting}
                  validate={[Validator.numericPositive, Validator.required]}
                  autoFocus={true}
                />
              </div>
              <div>
                <div className="form-label">{t('Max competitors')}</div>
                <Field.TextInput
                  name="maxCompetitors"
                  helpText={t(
                    `Amount of competitors available on this plan. Current amount: ${
                      initialValues?.maxCompetitors.toString() || ''
                    }.`,
                  )}
                  disabled={submitting}
                  validate={[Validator.numericPositive, Validator.required]}
                />
              </div>
              <div>
                <div className="form-label">{t('Max Workspaces')}</div>
                <Field.TextInput
                  name="maxNumbersOfWorkspaces"
                  helpText={t(
                    `Amount of Workspaces available on this plan. Current amount: ${
                      initialValues?.maxNumbersOfWorkspaces.toString() || ''
                    }.`,
                  )}
                  disabled={submitting}
                  validate={[Validator.numericPositive, Validator.required]}
                />
              </div>
              <div>
                <div className="form-label">{t('Advanced metrics')}</div>
                <Field.Checkbox
                  name="featureAdvancedMetrics"
                  label={t('Enable advanced metrics on this plan.')}
                  disabled={submitting}
                />
              </div>
              <div>
                <div className="form-label">{t('API')}</div>
                <Stack gap="sm">
                  <Field.Checkbox
                    name="featureApiAccess"
                    label={t('Enable API on this plan.')}
                    disabled={submitting}
                  />
                  <Field.Checkbox
                    name="featureApiV4WriteAccess"
                    label={t('Enable write API on this plan.')}
                    disabled={submitting}
                  />
                </Stack>
              </div>
            </Stack>
            <ModalFooter
              primaryButtonSlot={
                <AccButton
                  variant="primary"
                  type="submit"
                  loading={submitting}
                  disabled={invalid || submitting}
                >
                  {t('Save')}
                </AccButton>
              }
            />
          </>
        )}
      </Form>
    </ModalBorder>
  );
};

export default SalesOrganizationPlan;
