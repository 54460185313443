import Countdown, { zeroPad } from 'react-countdown-now';
import { t, tn } from 'Utilities/i18n';
import './special-offer-timer.scss';

type Props = {
  showCountdown: boolean;
  dealEndDate: any;
};

const SpecialOfferTimer = ({ showCountdown, dealEndDate }: Props) => {
  const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
    if (completed) return null;
    return (
      <div className="special-offer-timer-wrapper">
        <div className="holder">
          <h3>{t('This offer expires in')}</h3>
          <div className="special-offer-timer">
            <span className="time-segment">
              {days}
              <span className="time-segment-description">{tn('Day', 'Days', days)}</span>
            </span>
            <span className="time-segment">
              {zeroPad(hours)}
              <span className="time-segment-description">{tn('Hour', 'Hours', hours)}</span>
            </span>
            <span className="time-segment">
              {zeroPad(minutes)}
              <span className="time-segment-description">{tn('Minute', 'Minutes', minutes)}</span>
            </span>
            <span className="time-segment">
              {zeroPad(seconds)}
              <span className="time-segment-description">{tn('Second', 'Seconds', seconds)}</span>
            </span>
          </div>
        </div>
      </div>
    );
  };

  if (!showCountdown) {
    return null;
  }

  return <Countdown date={dealEndDate} renderer={renderer} />;
};

export default SpecialOfferTimer;
