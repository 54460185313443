import { Box, Divider, Flex } from '@mantine/core';
import cn from 'classnames';
import noop from 'lodash/noop';
import AccButton from 'Components/AccButton/AccButton';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { adminRole } from 'Constants/workspaces';
import { useTake_ControlLazyQuery, useUsers_AdminUsersQuery } from 'Ghql';
import { useLogout } from 'Hooks/actions/useLogout';
import { useModal } from 'Hooks/base/useModal';
import { appStorage } from 'Utilities/AppStorage';
import { t, tct } from 'Utilities/i18n';
import { SessionBlockPlaceholder } from './components/SessionBlockPlaceholder';
import { useSessionSync } from './hooks/useSessionSync';
import BlockedSessionPlaceholder from './images/blocked-session-placeholder.png';
import styles from './session-modal.module.scss';

/**
 * @docs: https://accuranker.myjetbrains.com/youtrack/issue/ARR-1483
 */
const SessionBlockModal = (): JSX.Element => {
  const { showModal, hideModal } = useModal();
  const { data, loading } = useUsers_AdminUsersQuery();
  const [takeControl] = useTake_ControlLazyQuery({ fetchPolicy: 'network-only' });

  const closeModal = (notify?: boolean) => {
    hideModal();
    if (notify) {
      appStorage.setSessionModalClosed(true);
    }
  };

  useSessionSync(closeModal);

  const onLogout = useLogout();

  const onTakeControl = async () => {
    try {
      await takeControl();
    } catch (e) {
      console.error('Failed to take control.');
    }
    closeModal(true);
  };

  const openAddItem = () => {
    showModal({
      modalType: 'AddUser',
      modalTheme: 'light',
      nested: true,
      modalProps: {
        refresh: noop,
      },
    });
  };

  const isAdmin = data?.user?.userType === adminRole;
  // "free" accounts (such as AppSumo accounts) can not create more than one user
  const isFreeAccount = data?.user?.organization?.activePlan?.maxUsers === 1;

  const renderMainContent = () => {
    if (loading) {
      return <SessionBlockPlaceholder />;
    }

    const accountInfo: JSX.Element = (
      <p className={styles.text}>
        Account: {data?.user?.fullName} {data?.user?.email ? `- ${data?.user?.email}` : ''}
      </p>
    );

    const infoText: JSX.Element = (
      <p className={styles.text}>
        {tct(
          'Someone else is using the same user account as you to access AccuRanker.' +
            ' For security reasons we do not allow account sharing.' +
            ' If you have just been using your account on another computer,' +
            ' you can click the [assumeCtrl:"Assume control"] button to resume you work on this computer.' +
            ' Note that doing so will show this prompt on anyone else who might be using this' +
            ' user account right now. If you want to log out and sign in as another user,' +
            ' please click the [logOut:"Log out"] button',
          {
            assumeCtrl: <b />,
            logOut: <b />,
          },
        )}
      </p>
    );

    let createUserPrompt: JSX.Element | null = null;
    if (isAdmin && !isFreeAccount) {
      createUserPrompt = (
        <p className={styles.text}>
          {t(
            'Please note that we allow unlimited users on your account, so to avoid this in' +
              ' future please create an AccuRanker user just for you. ',
          )}
          {tct('Click the [createUser:"Create user"] button to create a new user account.', {
            createUser: <b />,
          })}
        </p>
      );
    }
    let adminList: JSX.Element | null = null;
    if (!isAdmin && !isFreeAccount) {
      adminList = (
        <div>
          <p className={styles.text}>
            {t('Create your own user free of charge, contact one of your admins:')}
          </p>
          <Box mt="sm">
            <ul className={cn(styles.text)}>
              {data?.users?.map((e) => (
                <li key={e?.id}>
                  {e?.fullName} {e?.email && <a href={`mailto:${e?.email}`}>{e?.email}</a>}{' '}
                </li>
              ))}
            </ul>
          </Box>
        </div>
      );
    }

    return (
      <>
        {accountInfo}
        {infoText}
        {createUserPrompt}
        {adminList}
      </>
    );
  };

  return (
    <ModalBorder title={t('Your account is in use on another computer')} className={styles.text}>
      <div>
        <img
          src={BlockedSessionPlaceholder}
          alt={t('Session Block Modal')}
          className={styles.placeholder}
        />
      </div>

      {renderMainContent()}

      <Divider color="gray.2" my="md" />

      <Flex justify="space-between">
        {!isFreeAccount && (
          <AccButton variant="primary" onClick={openAddItem}>
            {t('Create user')}
          </AccButton>
        )}
        <AccButton
          variant={isFreeAccount ? 'primary' : 'secondary'}
          onClick={onTakeControl}
          ml="auto"
          mr={12}
        >
          {t('Assume Control')}
        </AccButton>
        <AccButton variant="tertiary" onClick={onLogout}>
          {t('Log out')}
        </AccButton>
      </Flex>
    </ModalBorder>
  );
};

export default SessionBlockModal;
