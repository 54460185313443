import { Flex } from '@mantine/core';
import { IconSettings } from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import TextInput from 'Components/Controls/TextInput';
import AccTitle from 'Components/Title/AccTitle';
import { getDisabledDemoText } from 'Constants/messages';
import { useModal } from 'Hooks/base/useModal';
import { useDomainId, useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import { t } from 'Utilities/i18n';
import formStyles from 'css/layout/form-layout.module.scss';

const BasicDomainInfoForm = ({ domainName, displayName }) => (
  <form>
    <div className={formStyles.formRow}>
      <label>{t('Domain name or YouTube channel url')}</label>
      <TextInput id="domainName" disabled={true} showError={false} value={domainName || ''} />
    </div>
    <div className={formStyles.formRow}>
      <label>{t('Display name')}</label>
      <TextInput id="displayName" disabled={true} showError={false} value={displayName || ''} />
    </div>
  </form>
);

export const GeneralMenuDetails = (): JSX.Element => {
  const domainId = useDomainId();
  const { domainInfo, isDemoDomain } = useQueryDomainInfo();
  const { showModal } = useModal();

  const handleEditDomain = () =>
    showModal({
      modalType: 'BuildDomain',
      modalTheme: 'light',
      modalProps: {
        domainId,
      },
    });

  return (
    <Flex direction="column" rowGap="md">
      <AccTitle type="h3">{t('General Settings')}</AccTitle>

      <BasicDomainInfoForm
        domainName={domainInfo?.domain || domainInfo?.youtubeChannelName}
        displayName={domainInfo?.displayName}
      />

      <AccButton
        onClick={handleEditDomain}
        leftSection={<IconSettings size={18} />}
        variant="secondary"
        disabled={isDemoDomain}
        tooltip={isDemoDomain ? getDisabledDemoText() : undefined}
      >
        {t('Edit domain')}
      </AccButton>
    </Flex>
  );
};
