import { memo } from 'react';
import { Flex } from '@mantine/core';
import cn from 'classnames';
import times from 'lodash/times';
import styles from './rowIndentations.module.scss';

type Props = {
  /**
   * A list of strings used to calculate which indentations should not be connected.
   *
   * The string number will determine the depth of the indentation to be hidden.
   * @example ["hide1", "hide2", "hide5"]
   */
  hideConnectors?: string[];
  /**
   * The width of the icon/button that toggles rows to expand.
   *
   * The value is needed to calculate the position of the connectors.
   * @example "24px"
   */
  expandIconWidth: string;
  className?: string;
  depth: number;
  /** Add a blue color to the tree for demo content */
  isDemoContent?: boolean;
  addEmptyConnector?: boolean;
};

export const RowIndentations = memo((props: Props) => {
  const { expandIconWidth, hideConnectors, depth, isDemoContent, addEmptyConnector } = props;

  const hideIndexes = hideConnectors?.map((string) => parseInt(string.replace('hide', '')));
  const results = times(depth ?? 0);

  return (
    <>
      {results.map((_, i) => {
        const intent = (
          <div
            key={i}
            style={{
              width: expandIconWidth,
              opacity: hideIndexes?.some((index) => index === i) ? 0 : 1,
            }}
            data-is-demo-content={isDemoContent || undefined}
            className={cn(styles.container, props.className, {
              [styles.connector]: i + 1 === depth,
            })}
          />
        );
        if (i === results.length - 1 && addEmptyConnector) {
          return (
            <Flex wrap="nowrap" key={i} align="center">
              {intent}
              <div
                className={styles.emptyConnector}
                style={{
                  width: expandIconWidth,
                }}
              />
            </Flex>
          );
        }
        return intent;
      })}
    </>
  );
});
RowIndentations.displayName = 'RowIndentations';
