/* eslint-disable */
import { gql } from '@apollo/client';
import * as Sentry from '@sentry/react';
import CryptoJS from 'crypto-js';
import isValidNumber from 'libphonenumber-js/build/validate';
import isNil from 'lodash/isNil';
import isPlainObject from 'lodash/isPlainObject';
import transform from 'lodash/transform';
import { SubmissionError } from 'redux-form';
import { Error } from 'Ghql';
import { FilterBase, FilterComparison } from 'Types/Filter';
import { setVatStatus } from '../Actions/OrderPlanAction';
import { apolloClient, store } from '../Store';
import { EventName, trackEventMixpanel } from './Analytics/mixpanel';
import { t } from './i18n';

export const RE_HAS_NUMBER = /[0-9]/;
export const RE_HAS_LETTER_OR_SPECIAL = /[$&+,:;=?@#|'<>.^*()%!-\D]/;
export const RE_HAS_ATLEAST_8_CHARACTERS = /^.{8,}$/;

export const COMPROMISED_ERROR = () => t('Password may be compromised. Please enter another.');

export const mapFinalFormErrors = (errors: Record<string, any>[]) => {
  return errors.reduce(
    (errorObj, error) => ({ ...errorObj, [error.field]: error.messages.join(', ') }),
    {},
  );
};

const initialDataQuery = gql`
  mutation generic_checkVat($vatPrefix: String!, $vatNumber: String!) {
    checkVat(prefix: $vatPrefix, number: $vatNumber) {
      success
      fixedVatNumber
    }
  }
`;
export type Errors = Error[];

type RestErrors = Record<string, Array<string>>;

const validateEmail = (value: string | undefined) => {
  const regex =
    /^((([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#$%&'*+-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;
  return !value || regex.test(value);
};

const isValidUrl = (value: string) => {
  if (!value) return false;
  if (value.startsWith('/')) return false;
  let url = value;
  if (!url?.startsWith('http://') && !url?.startsWith('https://')) {
    url = 'https://' + url;
  }
  return /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/.test(url);
};

function validateFullName(value: string | null | undefined) {
  // Implemented on request from HJ
  // We know that a few people might have names not fitting the rules
  // But implemented as 33% of people only gave first name
  // Check if the value is not empty, has a length greater than 1, and does not contain '@'
  if (!value || value.length <= 1 || value.includes('@')) {
    return t('Please enter your full name');
  }

  // Split the value by spaces
  const words = value.split(' ');

  // Check if there are at least two words
  if (words.length < 2) {
    return t('Please enter both first and last name');
  }

  // Check if each word has at least two letters
  for (let word of words) {
    if (word.length < 2) {
      return t('Each name must have at least two letters');
    }
  }

  return undefined;
}

export default {
  required: (value: string): string | null | undefined => {
    return value ? undefined : t('This field is required');
  },
  // compared to empty, won't show error for 0
  notEmpty: (value: string): string | null | undefined => {
    return !isNil(value) ? undefined : t('This field is required');
  },
  requiredNamed:
    (label: string) =>
    (value: unknown): string | null | undefined =>
      value ? undefined : label,
  isUrlDomainOrEmpty: (value: string): string | null | undefined => {
    // Used for domains
    if (!value) {
      return undefined;
    }
    return isValidUrl(value) ? undefined : t('Please enter a valid domain, e.g. amazon.com');
  },
  isUrl: (value: string) => {
    const isValid = isValidUrl(value);
    return isValid ? undefined : t('Please enter a valid URL');
  },
  isListOfUrls: (value: string[]) => {
    const invalidUrl = value?.find((x) => !isValidUrl(x));
    return invalidUrl ? t('%s is not a valid URL', invalidUrl) : undefined;
  },
  isDomain: (value: string): string | undefined => {
    const isValid =
      /^(((?!\-))(xn\-\-)?[a-z0-9\-_]{0,61}[a-z0-9]{1,1}\.)*(xn\-\-)?([a-z0-9\-]{1,61}|[a-z0-9\-]{1,30})\.[a-z]{2,}$/.test(
        value,
      );
    return isValid ? undefined : t('Please enter a valid domain name');
  },
  isNotObviouslyFakeCompany: (value: string): string | undefined => {
    if (
      value == 'example.com' ||
      value == 'gmail.com' ||
      value == 'gmail' ||
      value?.toLowerCase() == 'acme inc' ||
      value?.includes('accuranker') ||
      value?.includes('google.com') ||
      value?.includes('facebook.com')
    ) {
      return t('Are you sure this is the name of your company?');
    }
    return undefined;
  },

  array: (value: Array<any>): string | null | undefined =>
    value && value.length ? undefined : t('This field is required'),
  nonEmptyArrayOrObj: (value: Array<any> | Record<string, any>): string | null | undefined =>
    (Array.isArray(value) && value.length) || isPlainObject(value)
      ? undefined
      : t('This field is required'),
  string: (value: string): string | null | undefined =>
    value && /\S/.test(value) ? undefined : t('This field is required'),
  fullName: (value: string): string | null | undefined => validateFullName(value),
  numeric: (value: string | number): string | null | undefined =>
    !isNaN(Number(value)) && isFinite(Number(value)) ? undefined : t('This field is required'),
  numericPositive: (value: string): string | null | undefined =>
    (typeof value === 'number' || value) && Number(value) > 0
      ? undefined
      : t('A positive number is required'),
  phone: (value: string): string | null | undefined =>
    isValidNumber(value) ? undefined : t('Phone number is invalid'),
  email: (value: string | undefined) =>
    validateEmail(value) ? undefined : t('This is not a valid email'),
  emails: (values: string[] | undefined) =>
    values
      ? values.every(validateEmail)
        ? undefined
        : values.length > 1
        ? t('One or more invalid emails')
        : t('This is not a valid email')
      : undefined,
  // we have duplicate of email validation since initially html validation was used
  // as the result if we will introduce more strict regexp for email we can potentially broke user login
  // to migrate to one regexp we need to make sure that all emails match it
  // @docs https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#basic_validation
  emailHtml: (value?: string) => {
    const regex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i;
    return !value || regex.test(value) ? undefined : t('This is not a valid email');
  },
  passwordMinLen: (value: string): string | null | undefined =>
    value && RE_HAS_ATLEAST_8_CHARACTERS.test(value)
      ? undefined
      : t('Password must be at least 8 characters'),
  passwordLetter: (value: string): string | null | undefined =>
    value && RE_HAS_LETTER_OR_SPECIAL.test(value)
      ? undefined
      : t('Must contain a letter or special character'),
  passwordNumber: (value: string): string | null | undefined =>
    value && RE_HAS_NUMBER.test(value) ? undefined : t('Must contain a number'),
  passwordMaxLen: (value: string): string | null | undefined =>
    value && value.length <= 200 ? undefined : t('Password can be no more than 200 characters'),
  passwordConfirmation: (value: string, fields: Record<string, any>) =>
    fields.password && fields.password && fields.password.length >= 8 && value === fields.password
      ? undefined
      : t('Your passwords do not match'),
  passwordsMatch: (password?: string) => (confirmPassword?: string) => {
    return password && password.length && confirmPassword !== password
      ? t('Your passwords do not match')
      : undefined;
  },
  passwordPwned: async (value: string) => {
    const hashedPassword = CryptoJS.SHA1(value).toString().toUpperCase();

    const prefix = hashedPassword.slice(0, 5);
    const suffix = hashedPassword.slice(5);

    try {
      const response = await fetch(`https://api.pwnedpasswords.com/range/${prefix}`);

      if (response.ok) {
        const data = await response.text();
        const hashes = data.split('\n');
        for (const hashSuffix of hashes) {
          if (hashSuffix.includes(suffix)) {
            trackEventMixpanel(EventName.PwnedPasswordFlagged);
            return COMPROMISED_ERROR();
          }
        }
        return undefined;
      } else {
        // no validation error if request fails (service down? network error? ..)
        trackEventMixpanel(EventName.PwnedPasswordError, '', { status: response.status });
        return undefined;
      }
    } catch (error) {
      trackEventMixpanel(EventName.PwnedPasswordError, '', { error: JSON.stringify(error) });
      return undefined;
    }
  },
  //Async validation gets (values, dispatch, props, blurredField)
  throwSubmissionError: (errors: Record<string, any>) => {
    throw new SubmissionError(errors);
  },
  setRestResponseErrors: (setErrors: (arg0: {}) => any, errors: RestErrors = {}) =>
    setErrors(
      transform(
        errors,
        (acc, messages, field) => {
          acc[field] = messages.join(', ');
          return acc;
        },
        {},
      ),
    ),
  setResponseErrors: (setErrors: (arg0: {}) => any, errors: Errors = []) =>
    setErrors(mapFinalFormErrors(errors)),
  validVatNumber: (
    values: Record<string, any>,
    _: (...args: Array<any>) => any,
    props: Record<string, any>,
  ): Promise<void | Record<string, any>> => {
    const {
      vatPrefix: { vatCode },
      vatNumber,
    } = values;

    if (!vatNumber || !vatCode) {
      props.clearAsyncError('vatPrefix');
      props.clearAsyncError('vatNumber');
      store.dispatch(setVatStatus(false));
      return Promise.resolve();
    }

    return apolloClient
      .mutate({
        mutation: initialDataQuery,
        variables: {
          vatPrefix: vatCode,
          vatNumber,
        },
      })
      .then((res) => {
        const success = res?.data?.checkVat?.success;
        const fixedVatNumber = res?.data?.checkVat?.fixedVatNumber;
        props.touch('vatPrefix');
        props.touch('vatNumber');
        store.dispatch(setVatStatus(success));
        if (!success)
          return Promise.reject({
            vatNumber: t('Invalid VAT'),
            vatPrefix: t('Invalid VAT'),
          });

        if (fixedVatNumber) {
          props.dispatch(props.change('vatNumber', fixedVatNumber));
        }

        props.clearAsyncError('vatPrefix');
        props.clearAsyncError('vatNumber');
      });
  },
  vatNumber: (
    value: string,
    fields: Record<string, any>,
  ): Promise<string | null | undefined | void> => {
    const vatNumber = value;
    const vatPrefix = fields.vatPrefix;

    if (!vatPrefix) {
      return Promise.resolve();
    }
    if (!vatNumber) {
      return Promise.resolve(t('Field is required'));
    }

    return apolloClient
      .mutate({
        mutation: initialDataQuery,
        variables: {
          vatPrefix,
          vatNumber,
        },
      })
      .then((res) => {
        const success = res?.data?.checkVat?.success;
        if (!success) {
          return Promise.resolve(t('Invalid VAT number'));
        } else {
          return Promise.resolve(undefined);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  },
};

export const getRegexError = (input: string) => {
  try {
    new RegExp(input);
    return undefined;
  } catch (e) {
    let errorMessage: string;
    // Catch the SyntaxError thrown by invalid regex patterns
    if (e instanceof SyntaxError) {
      // Count occurrences of certain characters
      const openParenthesesCount = (input.match(/\(/g) || []).length;
      const closeParenthesesCount = (input.match(/\)/g) || []).length;
      const openBracketsCount = (input.match(/\[/g) || []).length;
      const closeBracketsCount = (input.match(/\]/g) || []).length;
      const openBracesCount = (input.match(/\{/g) || []).length;
      const closeBracesCount = (input.match(/\}/g) || []).length;

      // Common regex errors and possible explanations
      if (openParenthesesCount !== closeParenthesesCount) {
        errorMessage = t('Unmatched parenthesis');
      } else if (openBracketsCount !== closeBracketsCount) {
        errorMessage = t('Unmatched square brackets');
      } else if (openBracesCount !== closeBracesCount) {
        errorMessage = t('Unmatched curly braces');
      } else if (input.endsWith('\\')) {
        errorMessage = t('Invalid escape character');
      } else {
        errorMessage = e.message;
      }
    } else {
      // If the error is not a SyntaxError, log a generic message
      return t('Invalid regex');
    }
    // fallback to the message javascript provides - better than nothing!
    return t('Invalid regex: ') + errorMessage;
  }
};

export const checkForFilterRegexErrorReduxForm = (filter: FilterBase) => {
  if (
    filter?.comparison === FilterComparison.REGEX ||
    filter?.comparison === FilterComparison.NOT_REGEX
  ) {
    const errorMessage = getRegexError(filter?.value);
    if (errorMessage) {
      throw new SubmissionError({ value: errorMessage });
    }
  }
};
