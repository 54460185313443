import { useEffect, useState } from 'react';
import { useField } from 'react-final-form';
import { useDebouncedValue } from '@mantine/hooks';
import { useUpdateWorkspace } from 'Components/Modal/Content/EditWorkspace/useUpdateWorkspace';

const DEBOUNCE = 600;

/**
 * Custom hook that handles updating a role when the input values change.
 * @param record - The record object containing the role information.
 */
export const useUpdateRoleOnChange = (record: Record<string, any>) => {
  const prefix = `${record.id}_`;
  const { input: groupAccessInput } = useField(`${prefix}group_access`);
  const { input: usersInput } = useField(`${prefix}users`);
  const updateRole = useUpdateWorkspace({ id: record.id, inlineForm: true });

  const inputs = {
    name: record.name,
    description: record.description,
    clients: groupAccessInput.value || record.clients?.map((client) => client.id),
    users: usersInput.value || record.users?.map((user) => user.id),
  };

  // Stringify inputs to compare with initial inputs
  const [stringifiedInputs] = useDebouncedValue(JSON.stringify(inputs), DEBOUNCE);
  const [currentInput, setCurrentInput] = useState(stringifiedInputs);

  useEffect(() => {
    if (currentInput !== stringifiedInputs) {
      setCurrentInput(stringifiedInputs);
      updateRole(inputs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringifiedInputs]);
};
