import { useEffect, useRef } from 'react';
import { DragSourceMonitor } from 'react-dnd';
import { useSelectedNodes } from 'Hooks/useSelectedNodes';

/**
 * Inspired by https://minop1205.github.io/react-dnd-treeview/?path=/docs/advanced-examples-multiple-drag--multiple-drag-story
 */
const useMultiSelectDragAndDropControls = (treeData: any[], clearEditModes?: Function) => {
  const { setSelectedNodes, getSelectedNodes } = useSelectedNodes();
  const monitorRef = useRef<DragSourceMonitor | null>(null);
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key.toLowerCase() === 'escape') {
        clearEditModes?.();
        setSelectedNodes([]);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleMultiSelect = (clickedNode) => {
    const selectedNodes = getSelectedNodes();
    const selectedIds = selectedNodes.map((n) => n.id);

    // ignore if the clicked node is already selected
    if (selectedIds.includes(clickedNode.id)) {
      setSelectedNodes(selectedNodes.filter((n) => n.id !== clickedNode.id));
      return;
    }

    const updateNodes = [...selectedNodes, clickedNode];
    setSelectedNodes(updateNodes);
  };

  const handleClick = (e, node) => {
    handleMultiSelect(node);
  };
  const handleDragStart = (node, monitor) => {
    const selectedNodes = getSelectedNodes();
    monitorRef.current = monitor;
    const isSelectedNode = selectedNodes.some((n) => n.id === node.id);
    if (isSelectedNode) {
      return;
    }

    if (!selectedNodes.some((n) => n.id === node.id)) {
      setSelectedNodes([...selectedNodes, node]);
    }
  };

  return {
    getSelectedNodes,
    handleClick,
    setSelectedNodes,
    onSelect: handleClick,
    handleDragStart,
    monitorRef,
  };
};

export default useMultiSelectDragAndDropControls;
