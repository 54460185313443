import { Dispatch, SetStateAction, useEffect } from 'react';
import { Box, Group } from '@mantine/core';
import { IconArrowBigDownLines } from '@tabler/icons-react';
import { observer } from 'mobx-react';
import AccButton from 'Components/AccButton/AccButton';
import { useTableStore } from 'Components/DataTable';
import { TableStoreType } from 'Components/DataTable/store/TableStore';
import HelpGuideButton from 'Components/HelpGuideButton';
import AccText from 'Components/Text/AccText';
import { CountByCountryNode } from 'Ghql';
import { useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import { SelectedDomain } from 'Pages/KeywordDiscovery';
import { CountrySelectOption } from 'Pages/KeywordDiscovery/support/types';
import { KEYWORD_DISCOVERY, RESEARCH } from 'Pages/Layout/ActionsMenu/support/constants';
import { CompetitorDomainFilter } from 'Types/Filter';
import { TableIDs } from 'Types/Table';
import { useHistory } from 'Utilities/Router/withRouter';
import { t } from 'Utilities/i18n';
import { getStickyTop } from '../../../../Utilities/sticky';
import { DomainWithFavicon } from '../DomainWithFavicon';
import { KeydisCountrySelect } from '../KeydisCountrySelect/KeydisCountrySelect';
import { KeydisDomainSelect } from '../KeydisDomainSelect';
import { ImportButton } from '../ResearchPanel/importButton';
import styles from '../../KeywordDiscovery.module.scss';

export const keydisHelpGuideURL = 'https://www.accuranker.com/help/keywords/keyword-discovery';
const researchHelpGuideURL =
  'https://www.accuranker.com/help/keywords/researching-keywords-on-keyword-discovery/';

interface KeyDisActionsProps {
  selectedDomain: SelectedDomain;
  setSelectedDomain: Dispatch<SetStateAction<SelectedDomain>>;
  selectedCountry: CountByCountryNode | undefined;
  tableType: typeof TableIDs.KEYWORD_DISCOVERY | typeof TableIDs.RESEARCH;
  tableStore: TableStoreType | null;
  onCountryChange: (value: any) => void;
  menuFor: typeof KEYWORD_DISCOVERY | typeof RESEARCH;
  domainIsSelected: boolean;
  countryChoice: string | undefined;
  openedResearchPanel: boolean;
  invalidDomainError: string | undefined;
  setInvalidDomainError: Dispatch<SetStateAction<string | undefined>>;
  navigateToKeydis: (domainId: string) => void;
  competitorDomainFilter: CompetitorDomainFilter | undefined;
  addRecentSearch: (search: string) => Promise<void>;
  countrySelectData?: CountrySelectOption[] | undefined;
}

export const KeyDisActions = observer(
  ({
    selectedDomain,
    setSelectedDomain,
    selectedCountry,
    tableType,
    onCountryChange,
    countryChoice,
    openedResearchPanel,
    domainIsSelected,
    invalidDomainError,
    setInvalidDomainError,
    navigateToKeydis,
    competitorDomainFilter,
    addRecentSearch,
    countrySelectData,
  }: KeyDisActionsProps) => {
    const tableStore = useTableStore(tableType);
    const tableStoreLoading = tableStore?.loading;
    const selectedCount = tableStore?.totalSelectedCount ?? 0;
    const disableButton = tableStoreLoading || !selectedCount;

    const {
      location: { pathname },
    } = useHistory();

    const isFreeTextSearch = pathname.includes('research');

    const isDisabled = isFreeTextSearch ? false : disableButton;

    const { domainInfo } = useQueryDomainInfo();

    //update SelectedDomain if the competitorDomainFilter is removed or updated from the filterbar
    useEffect(() => {
      if (
        !isFreeTextSearch &&
        !competitorDomainFilter &&
        domainInfo?.domain &&
        domainInfo?.domain !== selectedDomain.domain
      ) {
        setSelectedDomain({ domain: domainInfo.domain, domainId: domainInfo.id });
      }
      if (competitorDomainFilter && competitorDomainFilter.value !== selectedDomain.domain) {
        addRecentSearch(competitorDomainFilter.value);
        setSelectedDomain({ domain: competitorDomainFilter.value, domainId: undefined });
      }
    }, [competitorDomainFilter]);

    const handleScrollToKeywords = () => {
      const tableReference = document
        .querySelector('#table-scroll-reference')
        ?.getBoundingClientRect();

      if (!tableReference) return;

      const newScrollTop = tableReference.top - getStickyTop();

      //make sure the top of the table is set just below the sticky bars
      window.scrollBy({
        top: newScrollTop,
        behavior: 'smooth',
      });
    };

    return (
      <div className={styles.actionGroupContainer} id="actionsMenuContainer" data-sticky={true}>
        <KeydisDomainSelect
          onDialogOverlay={false}
          selectedDomain={selectedDomain}
          setSelectedDomain={setSelectedDomain}
          invalidDomainError={invalidDomainError}
          setInvalidDomainError={setInvalidDomainError}
          inKeyDis={tableType === TableIDs.KEYWORD_DISCOVERY}
          navigateToKeydis={navigateToKeydis}
        />
        <HelpGuideButton
          helpguideLink={
            tableType === TableIDs.RESEARCH ? researchHelpGuideURL : keydisHelpGuideURL
          }
        />
        {openedResearchPanel && (
          <AccButton
            variant="tertiary"
            onClick={handleScrollToKeywords}
            leftSection={<IconArrowBigDownLines size={20} />}
          >
            {t('Go to keywords')}
          </AccButton>
        )}
        <Box pos="relative">
          <ImportButton
            selectedKeywords={selectedCount}
            isDisabled={isDisabled}
            selectedCountry={selectedCountry}
            tableType={tableType}
          />
          {!isFreeTextSearch && domainInfo && competitorDomainFilter && (
            <Group justify="center" gap={8} pos="absolute" w="100%" pt={2}>
              <AccText ta="center" size="10px">
                {t('to')}
              </AccText>
              <DomainWithFavicon ownDomain={domainInfo} size="xs" />
            </Group>
          )}
        </Box>
        <div className={styles.actionGroup}>
          {domainIsSelected && (
            <KeydisCountrySelect
              options={countrySelectData}
              onChange={onCountryChange}
              value={countryChoice}
            />
          )}
        </div>
      </div>
    );
  },
);
