import { useRef } from 'react';
import { Box } from '@mantine/core';
import { ChartLoader } from 'Components/Chart/ChartLoader/ChartLoader';
import { NotesType } from 'Components/Chart/LineChart';
import { daysInPeriod } from 'Components/PeriodFilter/model';
import { useFilters, useSpecificFilter } from 'Hooks';
import { MissingDataOverlay } from 'Pages/Keywords/Overview/MissingDataOverlay';
import { ChartContainer } from 'Pages/Keywords/Overview/components/ChartContainer/index';
import { RankingDistributionNeighborlyChart } from 'Pages/Keywords/Overview/components/HistoryCharts/RankingDistributionNeighborly/Chart';
import { getCsvData } from 'Pages/Keywords/Overview/components/HistoryCharts/RankingDistributionNeighborly/Chart/helpers';
import { RankingDistributionNeighborlyTable } from 'Pages/Keywords/Overview/components/HistoryCharts/RankingDistributionNeighborly/table';
import { FilterAttribute } from 'Types/Filter';
import { t, tct } from 'Utilities/i18n/index';
import { WithDragDetails } from '../../../support/types';

type ChartContentProps = {
  chartSeries: any[];
  domainId: string | undefined;
  loading: boolean;
  isGroupView: boolean;
  isEmptyGroup: boolean;
  showFakeData: boolean;
  onMissingDataOverlayClick?: (() => any) | undefined;
  isBaseRankingDistribution?: boolean;
  notes?: NotesType[];
  notesLoading?: boolean;
  onNotesSelect?: (...args: Array<any>) => any;
  includeKebabMenu?: boolean;
} & WithDragDetails;

// eslint-disable-next-line import/no-unused-modules
export const RankingDistributionNeighborlyChartContent = ({
  chartSeries,
  domainId,
  loading,
  isGroupView,
  isEmptyGroup,
  dragRef,
  boardControls,
  showFakeData,
  onMissingDataOverlayClick = () => {},
  dragging,
  notes = [],
  notesLoading,
  onNotesSelect = () => {},
  includeKebabMenu = true,
}: ChartContentProps) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const periodFilter = useSpecificFilter(FilterAttribute.PERIOD);
  const period: number = periodFilter ? daysInPeriod(periodFilter) : 0;

  const filters = useFilters();
  const filtersAdded = filters.length > 3;
  const showFakeDataOnChart = !filtersAdded && showFakeData;

  return (
    <ChartContainer
      includeKebabMenu={includeKebabMenu}
      title={t('Ranking Distribution')}
      dragRef={dragRef}
      boardControls={boardControls}
      chartSeries={chartSeries}
      chartRef={chartRef}
      getCsvData={() => getCsvData(chartSeries)}
      tooltip={[
        tct(
          'This graph shows the distribution of ranks grouped into six different rank ranges: 1, ' +
            '2-5, 6-10, 11-20, 21-100, and not ranking. [br]The graph shows how ALL your ranks have developed over time.',
          { br: <br /> },
        ),
      ]}
    >
      <ChartLoader
        loading={dragging || loading || notesLoading}
        containerBoxProps={{
          h: 308,
          mt: 12,
          mb: 10,
        }}
        loaderType="stack"
      >
        <Box pos="relative" bg="white" ref={chartRef}>
          {!loading && showFakeDataOnChart && (
            <MissingDataOverlay
              type="missingKeywords"
              onClick={onMissingDataOverlayClick}
              isGroupView={isGroupView}
              isEmptyGroup={isEmptyGroup}
            />
          )}
          <RankingDistributionNeighborlyChart
            animate={false}
            series={chartSeries}
            domainId={domainId}
            period={period}
            notes={notes}
            onNotesSelect={onNotesSelect}
            watermark
            watermarkBig
          />
          <RankingDistributionNeighborlyTable />
        </Box>
      </ChartLoader>
    </ChartContainer>
  );
};
