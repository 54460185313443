import React, { ComponentType, useMemo } from 'react';
import { RouteObject } from 'react-router';
import { createBrowserRouter } from 'react-router-dom';
import routeConfig from 'config';
import BaseContainer from 'Components/BaseContainer/next';
import withAnalytics from 'Components/HOC/withAnalytics';
import toPageWithFilterSet from 'Components/HOC/withFilterset';
import { NextDashboardTemplate } from 'Pages/Layout/DashboardTemplate/NextDashboardTemplate';
import { FilterSet } from 'Types/FilterSet';
import NoStoreRoute from 'Utilities/NoStoreRoute';
import PrivateRoute, { PrivateRouterConfig } from 'Utilities/PrivateRoute';
import PublicRoute from 'Utilities/PublicRoute';
import { withStrictMode } from 'Utilities/Router/hooks/withStrictMode';
import { t } from 'Utilities/i18n';
import { getIsReactLazy } from '../helpers';
import { withSuspense } from '../hocs/withSuspense';
import type { RouteE2ESpecInfo } from '../types/route-e2e';

export enum RouteType {
  DEFAULT = 'default',
  PUBLIC = 'public',
  PRIVATE = 'private',
  NO_STORE = 'no-store',
}

interface ShellConfig {
  hideShell?: true;
  showBreadcrumbs?: boolean;
  showFilters?: boolean;
  showFiltersShell?: boolean;
  showSegments?: boolean;
}

interface WrapperConfig {
  test?: boolean;
  type?: RouteType | undefined;
  component: ComponentType<React.PropsWithChildren<unknown>> | any;
  path?: string;
  privateRouteConfig?: PrivateRouterConfig;
  title: () => string;
  filterSet?: FilterSet;
  disableAnalytics?: boolean;

  shell?: ShellConfig;

  // used for tests to allow duplicates
  redirect?: boolean;
  /**
   * wraps component with React.StrictMode if true
   * @default true
   */
  strict?: boolean;
}

export interface AppRouteItem extends Omit<RouteObject, 'element' | 'children'>, WrapperConfig {
  paths?: string[];
  e2e?: RouteE2ESpecInfo;
}

const withRouteWrapper = (config: WrapperConfig): JSX.Element | null => {
  let WrappedComponent = config.component;

  if (!config.disableAnalytics) {
    WrappedComponent = withAnalytics(config.component, config.title || (() => t('')));
  }

  if (config.filterSet) {
    WrappedComponent = toPageWithFilterSet(WrappedComponent, config.filterSet);
  }
  // default to strict mode
  if (config?.strict ?? true) {
    WrappedComponent = withStrictMode(WrappedComponent);
  }

  if (getIsReactLazy(config.component)) {
    WrappedComponent = withSuspense(WrappedComponent);
  }
  const routeType = config.type || RouteType.DEFAULT;

  if (routeType === RouteType.DEFAULT) {
    return <WrappedComponent />;
  } else if (routeType === RouteType.PRIVATE) {
    return (
      <PrivateRoute
        {...config.privateRouteConfig}
        component={WrappedComponent}
        path={config.path}
      />
    );
  } else if (routeType === RouteType.NO_STORE) {
    return <NoStoreRoute component={WrappedComponent} />;
  } else if (routeType === RouteType.PUBLIC) {
    return <PublicRoute component={WrappedComponent} />;
  }

  return null as never;
};

export const useAppRouter = (routes: AppRouteItem[]) => {
  const [routesConfig, privateRoutesConfig] = useMemo(() => {
    const allRoutes: RouteObject[] = [];
    const privateRoutes: RouteObject[] = [];
    routes.map(({ paths, ...e }) => {
      const item = {
        ...e,
        element: withRouteWrapper(e),
      };
      let nextItems: RouteObject[] = [];
      if (Array.isArray(paths)) {
        nextItems = paths.map((pathItem) => ({
          ...item,
          path: pathItem,
        }));
      } else {
        nextItems = [item];
      }

      if (e.type === RouteType.PRIVATE && !e.shell?.hideShell) {
        privateRoutes.push(...nextItems);
      } else {
        allRoutes.push(...nextItems);
      }
    });

    return [allRoutes, privateRoutes];
  }, []);

  return useMemo(() => {
    return createBrowserRouter(
      [
        {
          path: '/',
          element: <BaseContainer />,
          children: [
            {
              path: '/',
              element: <NextDashboardTemplate />,
              children: privateRoutesConfig,
            },
            ...(routesConfig || []),
          ],
        },
      ],
      {
        basename: routeConfig.basename,
      },
    );
  }, [routesConfig, privateRoutesConfig]);
};
