import { forwardRef, useState } from 'react';
import { Flex } from '@mantine/core';
import cn from 'classnames';
import uniqueId from 'lodash/uniqueId';
import moment from 'moment';
import AccAlert from 'Components/AccAlert/AccAlert';
import AccButton from 'Components/AccButton/AccButton';
import { InjectedPopoverBodyProps } from 'Components/Popover/AccPopover';
import AccText from 'Components/Text/AccText';
import type { PeriodFilter } from 'Types/Filter';
import { formatDateTime } from 'Utilities/format';
import { t } from 'Utilities/i18n';
import styleVariables from 'css/base/variables.module.scss';
import type { DateFilters } from '../model';
import { EARLIEST, LATEST, getDateRange, parsePeriodFilterValue, rangeToFilters } from '../model';
import DatesPicker from './DatesPicker';
import { getNextScheduledRefresh } from './utils';

type Props = {
  onSubmit: (filters: DateFilters) => void;
  onCancel: () => void;
  periodFilter: PeriodFilter;
  devMode?: boolean;
  min: Date;
  max: Date;
  message?: string;
  onlyPeriodFilter: boolean;
  domainInfo?: any;
} & InjectedPopoverBodyProps;

export const CompareToContent = forwardRef((props: Props, ref: any) => {
  const {
    onSubmit,
    onCancel,
    periodFilter,
    onlyPeriodFilter,
    devMode,
    min,
    max,
    message,
    domainInfo,
  } = props;

  const initialRange = parsePeriodFilterValue(periodFilter);

  const [range, setRange] = useState(initialRange);

  const [showWarning, setShowWarning] = useState(moment(initialRange.to).isBefore(max, 'day'));

  const handleSubmit = () => {
    const filters = rangeToFilters(range);
    props.closePopover?.();
    onSubmit(filters);
  };

  const handleCancel = () => {
    onCancel?.();
    props.closePopover?.();
  };

  const handleChangeRange = (dateRange: { from: Date; to: Date }) => {
    const lastScrapedDate = moment(domainInfo?.lastScraped); // only assigned when auto refresh
    const lastScrapedCompletedDate = moment(domainInfo?.lastFullScrapeCompleted); // also assigned when users do manual refresh
    const latestAutoOrManualScrapedDate = moment.max([lastScrapedDate, lastScrapedCompletedDate]);

    const beforeMaxDate = moment(dateRange?.to).isBefore(max, 'day');
    const beforeLastScrape = moment(dateRange?.to).isBefore(lastScrapedDate, 'day');

    if (beforeMaxDate && beforeLastScrape) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }

    // Today: "2022-08-30" and we sent: 1. `2022-08-23 - 2022-08-30`, 2. `2022-08-23 - latest`: will get different results
    // we transform today date to "latest" to have the same filter params
    const to = moment(dateRange?.to).isSameOrAfter(
      latestAutoOrManualScrapedDate?.isValid() ? latestAutoOrManualScrapedDate : moment(),
      'day',
    )
      ? LATEST
      : dateRange?.to;
    // we should only be able to pick shortcuts by pressing the button not selected in date picker.
    setRange({ ...dateRange, to });
  };

  const id = uniqueId('left-message-');

  const domainFullyScraped = moment(domainInfo?.lastFullScrapeCompleted).isSameOrAfter(min, 'day');

  return (
    <div className="period-filter-editor" ref={ref}>
      {onlyPeriodFilter ? (
        <div className={'title'}>{t('Date')}</div>
      ) : (
        <div className={'title'}>{t('Compare Data')}</div>
      )}
      <div
        className={cn({
          'period-filter-range-select': !!message,
        })}
      >
        <DatesPicker
          value={getDateRange(range, min, max)}
          min={min}
          max={max}
          onChange={handleChangeRange}
        />
        {message && <div className="alert alert-warning period-filter-message">{message}</div>}
      </div>
      {showWarning && (
        <AccAlert mt={'sm'} severity="warning">
          {t('Please note that you will not be viewing the latest data.')}
        </AccAlert>
      )}
      <div className={'footer'}>
        <div className={'left-message'}>
          {domainInfo && (
            <span id={id} className={'text'}>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'auto 1fr',
                  columnGap: styleVariables.spacingXxs,
                  alignItems: 'baseline',
                }}
              >
                <AccText variant="label">{t('Latest complete refresh')}</AccText>
                <AccText tooltip={domainFullyScraped ? t('Local time') : undefined}>
                  {domainFullyScraped
                    ? formatDateTime(domainInfo.lastFullScrapeCompleted)
                    : t('Domain not fully scraped')}
                </AccText>

                <AccText variant="label">{t('Next scheduled refresh')}</AccText>
                <AccText tooltip={t('Local time')}>{getNextScheduledRefresh(domainInfo)}</AccText>
              </div>

              {devMode && (
                <p className={'alert alert-info'}>
                  DEBUG
                  <br />
                  min: {moment(min).format()}
                  <br />
                  max: {moment(max).format()}
                  <br />
                  <br />
                  domainInfo.lastFullScrapeCompleted:{' '}
                  {moment(domainInfo.lastFullScrapeCompleted).format()}
                  <br />
                  domainInfo.lastScraped: {moment(domainInfo.lastScraped).format()}
                  <br />
                  domainInfo.lastScrapedCompleted:{' '}
                  {moment(domainInfo.lastScrapedCompleted).format()}
                  <br />
                  domainInfo.firstRefreshAt: {moment(domainInfo.firstRefreshAt).format()}
                  <br />
                  <br />
                  periodFilter.from:{' '}
                  {range.from === EARLIEST ? EARLIEST : moment(range.from).format()}
                  <br />
                  periodFilter.to: {range.to === LATEST ? LATEST : moment(range.to).format()}
                  <br />
                </p>
              )}
            </span>
          )}
        </div>
        <Flex ml={24} mt={24}>
          <AccButton onClick={handleCancel} variant="tertiary">
            {t('Cancel')}
          </AccButton>
          <AccButton onClick={handleSubmit} variant="primary" ml={8}>
            {t('Update')}
          </AccButton>
        </Flex>
      </div>
    </div>
  );
});

CompareToContent.displayName = 'CompareToContent';
