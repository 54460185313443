import { useMemo } from 'react';
import { Location } from 'react-router';
import { ColumnType, QueryOrder } from 'Components/DataTable';
import { DEFAULT_KEYWORD_ORDERING, DEFAULT_RANK_ORDERING } from '../hooks/keyword/constants';
import { KeywordColumnID, KeywordsTableSortType } from './constants';

export const useKeywordsTableDefaultOrdering = (location?: Location, columns?: ColumnType[]) => {
  const sortType = useMemo(() => {
    try {
      return new URLSearchParams(location?.search).get('sort') || '';
    } catch (e) {
      return null;
    }
  }, [location?.search]);

  let ordering = DEFAULT_KEYWORD_ORDERING;

  if (sortType === KeywordsTableSortType.RANK) {
    const hasRankColumn = columns?.some((e) => e?.id === KeywordColumnID.RANK);
    ordering = hasRankColumn ? DEFAULT_RANK_ORDERING : DEFAULT_KEYWORD_ORDERING;
  } else if (sortType === KeywordsTableSortType.SHARE_OF_VOICE_CHANGE_ASC) {
    ordering = { order: QueryOrder.ASC, orderBy: KeywordColumnID.SHARE_OF_VOICE_CHANGE };
  } else if (sortType === KeywordsTableSortType.SHARE_OF_VOICE_CHANGE_DESC) {
    ordering = { order: QueryOrder.DESC, orderBy: KeywordColumnID.SHARE_OF_VOICE_CHANGE };
  }

  // by skipping save ordering we give higher priority to ordering from url search params
  const skipSaveOrdering: boolean = sortType
    ? Object.values(KeywordsTableSortType).includes(sortType)
    : false;

  return {
    ordering,
    skipSaveOrdering,
  };
};
