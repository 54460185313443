import { useMemo } from 'react';
import { SeriesItem } from 'Components/Chart/LineChart';
import { NOT_IN_TOP_100_VALUE } from 'Components/Chart/LineChart/support/constants';
import { fakePeriodFilter } from 'Constants/data';
import {
  useKeywordHistoryChartRanksQuery,
  useKeywordHistoryCompetitorsRanksChartQuery,
} from 'Ghql';
import 'Ghql/customTypes';
import { useStandardFilters } from 'Hooks';
import { useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import { FilterAttribute } from 'Types/Filter';
import { colorScheme, getCompetitorAndDomainColors } from 'Utilities/colors';

type KeywordHistoryNode = {
  rank?: number | undefined;
  searchDatetime: number;
};

type CompetitorItem = {
  competitorId?: number;
  days?: KeywordHistoryNode[];
  domain?: string;
  youtubeChannelName?: string;
  name?: string;
  id: string;
};

const getChartName = <T extends { domain?: string | null; youtubeChannelName?: string | null }>(
  item?: Partial<T> | null,
): string | undefined | null => {
  return item?.youtubeChannelName || item?.domain;
};

export const useHistoryChartData = (keywordId: string, domain?: string) => {
  const filters = useStandardFilters();

  const filtersButWithFakePeriod = useMemo(
    () => filters.map((f) => (f?.attribute === FilterAttribute.PERIOD ? fakePeriodFilter : f)),
    [filters],
  );
  const { colorMap } = useQueryDomainInfo();
  const { data, loading } = useKeywordHistoryChartRanksQuery({
    variables: { filters: filtersButWithFakePeriod, keywordId },
  });
  const { data: competitorsData, loading: competitorsLoading } =
    useKeywordHistoryCompetitorsRanksChartQuery({
      variables: { filters: filtersButWithFakePeriod, keywordId },
    });

  const competitors: CompetitorItem[] = (competitorsData?.graphs?.keywordCompetitorHistory ?? [])
    .filter(Boolean)
    .map((e) => ({ id: e?.domain ?? '', ...e }));

  const colors = getCompetitorAndDomainColors(colorMap, competitors?.map((e) => e.id) ?? []);
  const resultData: SeriesItem[] =
    loading || competitorsLoading
      ? []
      : [
          {
            name: domain || '',
            primary: true,
            color: colorScheme.graphs.primaryDomain,
            data:
              data?.graphs?.keywordHistory?.map((e: KeywordHistoryNode) => {
                return {
                  y: e?.rank || NOT_IN_TOP_100_VALUE,
                  x: e.searchDatetime,
                };
              }) ?? [],
          },
          ...(competitors?.map((el, i) => {
            return {
              name: getChartName(el) || '-',
              color: colors[i],
              data:
                el?.days?.map((e) => ({
                  y: e?.rank || NOT_IN_TOP_100_VALUE,
                  x: e.searchDatetime,
                })) ?? [],
            };
          }) ?? []),
        ];

  return {
    data: resultData,
    isLoading: loading || competitorsLoading,
    competitors,
  };
};
