import { useState } from 'react';
import { Flex } from '@mantine/core';
import AccSegmentedControl from 'Components/AccSegmentedControl/AccSegmentedControl';
import { FilterBase } from 'Types/Filter';
import { useSegmentedControlItems } from '../support/useSegmentedControlItems';
import { useSyncCharts } from '../support/useSyncCharts';
import { PublicAvgRankChart } from './PublicAvgRankChart';
import { PublicRankingDistributionChart } from './PublicRankingDistributionChart';
import { PublicSearchIntentChart } from './PublicSearchIntentChart';

export enum PublicChartTypes {
  AVERAGE_RANK = 'average_rank',
  RANKING_DISTRIBUTION = 'ranking_distribution',
  SEARCH_INTENT = 'search_intent',
}

type PublicChartsProps = {
  viewkey: string;
  domainSlug: string;
  filters: FilterBase[];
  domainId: string | undefined;
  includeAverageRankChart: boolean;
  includeRankingDistributionChart: boolean;
  includeSearchIntentChart: boolean;
};

export const PublicCharts = ({
  domainSlug,
  viewkey,
  filters,
  domainId,
  includeAverageRankChart,
  includeRankingDistributionChart,
  includeSearchIntentChart,
}: PublicChartsProps) => {
  const [selectedChart, setSelectedChart] = useState<PublicChartTypes>(
    PublicChartTypes.AVERAGE_RANK,
  );

  useSyncCharts({
    includeAverageRankChart,
    includeRankingDistributionChart,
    includeSearchIntentChart,
    selectedChart,
    setSelectedChart,
  });

  const segmentedControlItems = useSegmentedControlItems({
    includeAverageRankChart,
    includeRankingDistributionChart,
    includeSearchIntentChart,
  });

  const showSegmentedControl =
    [includeAverageRankChart, includeRankingDistributionChart, includeSearchIntentChart].filter(
      (chart) => chart,
    ).length > 1;

  return (
    <Flex direction="column" gap="md">
      {showSegmentedControl && (
        <AccSegmentedControl
          animated
          value={selectedChart}
          defaultValue={
            includeAverageRankChart
              ? PublicChartTypes.AVERAGE_RANK
              : includeRankingDistributionChart
              ? PublicChartTypes.RANKING_DISTRIBUTION
              : PublicChartTypes.SEARCH_INTENT
          }
          onChange={(value) => setSelectedChart(value as PublicChartTypes)}
          size="sm"
          ml="auto"
          data={segmentedControlItems}
        />
      )}
      {selectedChart === PublicChartTypes.AVERAGE_RANK && includeAverageRankChart && (
        <PublicAvgRankChart domainSlug={domainSlug} viewkey={viewkey} filters={filters} />
      )}
      {selectedChart === PublicChartTypes.RANKING_DISTRIBUTION &&
        includeRankingDistributionChart && (
          <PublicRankingDistributionChart
            domainSlug={domainSlug}
            viewkey={viewkey}
            filters={filters}
            domainId={domainId}
          />
        )}
      {selectedChart === PublicChartTypes.SEARCH_INTENT && includeSearchIntentChart && (
        <PublicSearchIntentChart
          domainSlug={domainSlug}
          viewkey={viewkey}
          filters={filters}
          domainId={domainId}
        />
      )}
    </Flex>
  );
};
