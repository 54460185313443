import { useContext } from 'react';
import { Flex } from '@mantine/core';
import { observer } from 'mobx-react';
import AccButton from 'Components/AccButton/AccButton';
import { Field, Form } from 'Components/Fields';
import SimpleModal from 'Components/SimpleModal';
import { t } from 'Utilities/i18n';
import Validator from 'Utilities/validation';
import { useSelectedNodes } from '../../../../../../../Hooks/useSelectedNodes';
import { useTreeContext } from '../../../react-dnd-treeview/hooks';
import { PlaceholderContext } from '../../../react-dnd-treeview/providers';
import { ROOT_ID } from '../../../support/constants';
import { getIsTreeHasChildren, replaceFolderSeparator } from '../../../support/helpers';

export const MoveGroupModal = observer(({ opened, setOpened, onDrop }: any) => {
  const placeholderContext = useContext(PlaceholderContext);
  const { selectedNodes } = useSelectedNodes();
  const treeContext = useTreeContext();

  const onMove = ({ moveTo }) => {
    onDrop(treeContext.tree, { dropTargetId: moveTo, index: 0 }, placeholderContext);
    setOpened(false);
  };

  const getOptions = () => {
    const items = selectedNodes;

    const hasTag = items?.some((e) => !e.isFolder);

    return treeContext.tree
      .filter(
        (e) =>
          e.isFolder &&
          items?.every((selected) => selected.id !== e.id && !getIsTreeHasChildren(selected, e.id)),
      )
      .map((e) => ({
        value: e.id,
        label: e.id === ROOT_ID ? t('Root level') : replaceFolderSeparator(e.id as string),
      }))
      .concat(!hasTag ? [{ value: ROOT_ID, label: t('Root level') }] : []);
  };
  const options = getOptions();
  return (
    <SimpleModal opened={opened} setOpened={setOpened} title={t('Move selected to')}>
      <Form onSubmit={onMove}>
        <Field.Select
          name="moveTo"
          options={options}
          label={t('Select folder', selectedNodes.length)}
          required
          validate={Validator.notEmpty}
          withinPortal
        />
        <Flex mt="sm" justify="right" gap="sm">
          <AccButton variant={'tertiary'} onClick={() => setOpened(false)}>
            {t('Cancel')}
          </AccButton>
          <AccButton variant="primary" type="submit">
            {t('Move')}
          </AccButton>
        </Flex>
      </Form>
    </SimpleModal>
  );
});
