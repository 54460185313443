import { useMemo } from 'react';
import { useFilters } from 'Hooks';
import { FilterAttribute, FilterBase, FilterComparison, FilterValueType } from 'Types/Filter';

export const useFiltersMemo = (currentGroupId: string, selectedDomains: string[]) => {
  const filtersFromURL = useFilters();
  return useMemo(() => {
    const clientsFilter = filtersFromURL.find(
      (filter) => filter.attribute === FilterAttribute.CLIENTS,
    );
    const baseFilters: FilterBase[] = filtersFromURL.filter(
      (filter) => filter.attribute !== FilterAttribute.DOMAINS,
    );

    if (currentGroupId && !clientsFilter) {
      baseFilters.push({
        attribute: FilterAttribute.CLIENTS,
        type: FilterValueType.LIST,
        comparison: FilterComparison.CONTAINS,
        value: [currentGroupId],
      });
    }

    if (selectedDomains.length) {
      baseFilters.push({
        attribute: FilterAttribute.DOMAINS,
        type: FilterValueType.LIST,
        comparison: FilterComparison.CONTAINS,
        value: selectedDomains,
      });
    }

    return baseFilters;
  }, [filtersFromURL, currentGroupId, selectedDomains]);
};
