import { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router';
import {
  ActionIcon,
  Button,
  FocusTrap,
  Group,
  Overlay,
  Stack,
  useMantineTheme,
} from '@mantine/core';
import { SelectItem, SelectItemValueType } from 'Components/AccSelect/support/types';
import Icon from 'Components/Icon/new';
import AccText from 'Components/Text/AccText';
import AccTitle from 'Components/Title/AccTitle';
import { CountByCountryNode } from 'Ghql';
import { useSetOrOverwriteFilter } from 'Hooks/data/filter/setFilters';
import { useDisableBodyScroll } from 'Hooks/useDisableBodyScroll';
import { SelectedDomain } from 'Pages/KeywordDiscovery';
import { FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import { t } from 'Utilities/i18n';
import validation from 'Utilities/validation';
import CloseIcon from 'icons/close-2.svg?inline';
import { CountrySelectOption } from '../../support/types';
import { KeydisCountrySelect } from '../KeydisCountrySelect/KeydisCountrySelect';
import { KeydisDomainSelect } from '../KeydisDomainSelect';
import styles from '../../KeywordDiscovery.module.scss';

type DialogOverlayProps = {
  selectedDomain: SelectedDomain;
  setSelectedDomain: Dispatch<SetStateAction<SelectedDomain>>;
  countrySelectData: CountrySelectOption[] | undefined;
  selectedCountry: CountByCountryNode | undefined;
  handleSetSelectedCountry: (value: SelectItemValueType | null, _option?: SelectItem) => void;
  countryChoice: string | undefined;
  domainsLoading: boolean;
  invalidDomainError: string | undefined;
  setInvalidDomainError: Dispatch<SetStateAction<string | undefined>>;
  navigateToKeydis: (domainId: string) => void;
  countryLoading?: boolean;
};

export const DialogOverlay = ({
  selectedDomain,
  setSelectedDomain,
  countrySelectData,
  selectedCountry,
  handleSetSelectedCountry,
  countryChoice,
  domainsLoading,
  invalidDomainError,
  setInvalidDomainError,
  navigateToKeydis,
  countryLoading,
}: DialogOverlayProps) => {
  const theme = useMantineTheme();
  const setFilter = useSetOrOverwriteFilter();

  const navigate = useNavigate();

  useDisableBodyScroll();

  const searchForDomain = () => {
    //set the domainfilter if an exitsting domain is selected
    if (selectedDomain.domainId) {
      setFilter(
        {
          attribute: FilterAttribute.DOMAINS,
          type: FilterValueType.LIST,
          comparison: FilterComparison.CONTAINS,
          value: [selectedDomain.domainId],
        },
        'no tracking',
      );
      navigateToKeydis(selectedDomain.domainId);
    } else {
      // set a free text domainfilter
      selectedDomain.domain &&
        setFilter(
          {
            attribute: FilterAttribute.FREE_TEXT_DOMAIN,
            type: FilterValueType.STRING,
            comparison: FilterComparison.CONTAINS,
            value: selectedDomain.domain,
          },
          'no tracking',
        );
    }
  };

  const isValidUrl = !!selectedDomain.domain && !validation.isUrl(selectedDomain.domain);

  return (
    <>
      <Overlay blur={5} zIndex={101} color={theme.colors.gray[6]} className={styles.overLay} />
      <FocusTrap active>
        <div className={styles.overLayWrapper}>
          <Group justify="center" className={styles.overlayContentWrapper}>
            <Stack align="flex-start" className={styles.domainSelectDialog}>
              <ActionIcon
                //remove tabindex - otherwise autofocus on KeydisDomainSelect will not work
                tabIndex={-1}
                onClick={() => history.length > 1 && navigate(-1)}
                radius={'0.25rem'}
                color="gray.5"
                size="sm"
                variant="transparent"
                className={styles.domainDialogClose}
              >
                <Icon width={13} height={13}>
                  <CloseIcon />
                </Icon>
              </ActionIcon>
              <AccTitle
                type="h3"
                ta="center"
                c={theme.colors.gray[8]}
                fw={'bold'}
                boxProps={{
                  className: styles.overLayTitleBox,
                }}
              >
                {t('Keyword Discovery')}
              </AccTitle>
              <AccText c="gray.7" className={styles.dialogDescription}>
                {t(
                  'In-depth analysis of your own and your competitors\' domains. By importing keywords,\nyou can continuously monitor performance and gain real-time insights for even deeper analysis.',
                )}
              </AccText>
              <Group wrap="nowrap" align="center" w="100%" miw={800}>
                <KeydisDomainSelect
                  onDialogOverlay
                  selectedDomain={selectedDomain}
                  setSelectedDomain={setSelectedDomain}
                  invalidDomainError={invalidDomainError}
                  setInvalidDomainError={setInvalidDomainError}
                  navigateToKeydis={navigateToKeydis}
                  fullWidth
                />
                <KeydisCountrySelect
                  disabled={!selectedDomain.domain}
                  options={countrySelectData ?? []}
                  onChange={handleSetSelectedCountry}
                  value={countryChoice}
                  isLoading={countryLoading}
                />
                <Button
                  disabled={domainsLoading || !selectedCountry || !isValidUrl}
                  onClick={searchForDomain}
                  variant="filled"
                >
                  {t('Search')}
                </Button>
              </Group>
            </Stack>
          </Group>
        </div>
      </FocusTrap>
    </>
  );
};
