import { Component } from 'react';
import { connect } from 'react-redux';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import compose from 'lodash/flowRight';
import moment from 'moment';
import { hideModal } from 'Actions/ModalAction';
import Loader from 'Components/Loader';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { t } from 'Utilities/i18n';

type Props = {
  hideModal: (...args: Array<any>) => any;
  organizationId: string;
  lastState: Record<string, any>;
  submitOnInit: boolean;
  organizationQuery?: any;
};

class SalesOrganizationAbuseLog extends Component<Props> {
  handleClose = () => {
    this.props.hideModal();
  };

  render() {
    const { adminOrganization: organization } = this.props.organizationQuery;
    return (
      <ModalBorder title={t('Abuse Log')} onClose={this.handleClose}>
        {this.props.organizationQuery.loading || this.props.organizationQuery.error ? (
          <Loader
            style={{
              height: '500px',
            }}
          />
        ) : (
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">{t('Date')}</th>
                <th scope="col">{t('Description')}</th>
                <th scope="col">{t('Log')}</th>
              </tr>
            </thead>
            <tbody>
              {organization.trialAbuseLog.map((log) => (
                <tr key={log.id}>
                  <td>{moment(log.createdAt).format('L')}</td>
                  <td>{log.description}</td>
                  <td>{log.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </ModalBorder>
    );
  }
}

const organizationQuery = gql`
  query salesOrganization_adminOrganization($id: ID!) {
    adminOrganization(id: $id) {
      id
      trialAbuseLog {
        id
        createdAt
        description
        value
      }
    }
  }
`;
export default compose(
  graphql(organizationQuery, {
    name: 'organizationQuery',
    options: (props: any) => ({
      variables: {
        id: props.organizationId,
      },
    }),
  }),
  connect(null, {
    hideModal,
  }),
)(SalesOrganizationAbuseLog);
