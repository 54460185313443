import { useEffect, useRef } from 'react';

/**
 * Identical to React.useEffect, except that it never runs on mount. This is
 * the equivalent of the componentDidUpdate lifecycle function.
 *
 * optimized to not run when the component mounts initially 2x in dev mode.
 *
 * @param {function:function} effect - A useEffect effect.
 * @param {array} [dependencies] - useEffect dependency list.
 */
export const useUpdateEffect: typeof useEffect = (effect, deps) => {
  const isMounted = useRef(false);
  useEffect(() => {
    if (isMounted.current) {
      const unmount = effect();
      return () => unmount && unmount();
    }
    isMounted.current = true;
  }, deps);

  // Reset on unmount for the next mount.
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);
};
