import { Field, Fields } from 'redux-form';
import { t } from 'Utilities/i18n/index';
import InputField, { InputFieldProps } from './InputField';
import './number-filter-editor.scss';

const validate = (value: any) => {
  if (value === '') {
    return t('This field is required');
  }

  if (Array.isArray(value)) {
    const from = value[0];
    const to = value[1];

    if (from === '' || to === '') {
      return t('This field is required');
    }

    if (from > to) {
      return t('Right should be >= left');
    }
  }
};

export type NumberEditorProps = Pick<
  InputFieldProps,
  | 'iconDropdown'
  | 'handleSelect'
  | 'autoFocus'
  | 'withinPortal'
  | 'includeNoValueFilter'
  | 'isDecimal'
>;

const NumberEditor = (props: NumberEditorProps) => {
  return (
    <div className="number-filter-editor">
      <Fields
        names={['comparison', 'value', 'type', 'attribute']}
        component={InputField}
        {...props}
      />
      <Field name="value" component="div" validate={[validate]} />
    </div>
  );
};

NumberEditor.defaultProps = {
  iconDropdown: false,
  handleSelect: () => {},
  isDecimal: false,
};

export default NumberEditor;
