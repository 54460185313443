import { ActionIcon, Popover, PopoverProps } from '@mantine/core';
import { IconHelp } from '@tabler/icons-react';
import AccText from 'Components/Text/AccText';
import styles from './helpTextPopover.module.scss';

type Props = {
  text: React.ReactNode;
} & Omit<PopoverProps, 'children'>;

const HelpTextPopover = (props: Props) => {
  const { text, ...rest } = props;

  return (
    <Popover
      width="fit-content"
      position="right"
      withArrow
      shadow="md"
      {...rest}
      withinPortal={false}
    >
      <Popover.Target>
        <ActionIcon
          radius={'4px'}
          color="gray.6"
          size="md"
          variant="subtle"
          className={styles.toggleButtonSelected}
        >
          <IconHelp size={20} />
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown>
        <AccText size="sm">{text}</AccText>
      </Popover.Dropdown>
    </Popover>
  );
};

export default HelpTextPopover;
