import { useApolloClient } from '@apollo/client';
import { useTableStore } from 'Components/DataTable';
import {
  DeleteCompetitorDocument,
  DeleteCompetitorMutation,
  DeleteCompetitorMutationVariables,
} from 'Ghql';
import toast from 'Hooks/useToast';
import { t } from 'Utilities/i18n';
import { useModal } from '../../../../Hooks/base/useModal';
import { TableIDs } from '../../../../Types/Table';

export const useCompetitorActions = () => {
  const client = useApolloClient();
  const { showModal } = useModal();
  const tableStore = useTableStore(TableIDs.COMPETITORS);
  const onFail = () => toast.error(t('Could not delete competitor'));

  const deleteCompetitor = async (competitor: DeleteCompetitorMutationVariables['input']) => {
    try {
      tableStore?.deleteItem(competitor.id?.toString());
      const result = await client.mutate<
        DeleteCompetitorMutation,
        DeleteCompetitorMutationVariables
      >({
        mutation: DeleteCompetitorDocument,
        variables: {
          input: {
            id: competitor.id,
            delete: true,
            domain: competitor.domain,
            displayName: competitor.displayName || '',
            googleBusinessNameList: competitor.googleBusinessNameList || [],
            twitterHandle: competitor.twitterHandle || '',
            facebookProfile: competitor.facebookProfile || '',
            instagramProfile: competitor.instagramProfile || '',
            linkedinProfile: competitor.linkedinProfile || '',
            tiktokProfile: competitor.tiktokProfile || '',
          },
        },
      });

      if (result?.data?.updateCompetitor?.errors?.length) {
        onFail();
        return;
      }
      toast.success(t('Competitor deleted'));
    } catch (e) {
      tableStore?.getData();
      onFail();
    }
  };

  const handleEditCompetitor = (competitorId: number | null | undefined, domainId?: number) => {
    showModal({
      modalType: 'BuildCompetitor',
      modalProps: {
        competitorId,
        domainId,
        refresh: ({ id, ...attrs }: any) => {
          tableStore?.updateRowData(id)(attrs);
        },
      },
    });
  };

  const handleDeleteCompetitor = (record: any) =>
    showModal({
      modalType: 'Confirmation',
      modalProps: {
        title: t('Delete Competitor?'),
        description: t('The competitor and its historic data will be permanently deleted.'),
        cancelLabel: t('Cancel'),
        confirmLabel: t('Delete competitor'),
        action: () => deleteCompetitor(record),
      },
    });

  return { handleEditCompetitor, handleDeleteCompetitor };
};
