import {
  IconArrowNarrowDown,
  IconArrowNarrowUp,
  IconSortAscendingLetters,
  IconSortAscendingNumbers,
  IconSortDescendingLetters,
  IconSortDescendingNumbers,
} from '@tabler/icons-react';
import { SORTING_TYPE } from 'Constants';
import { Ordering } from 'Types/Sort';
import { t } from 'Utilities/i18n';

const MENU_ICON_SIZE = 16;

export const getSortingIcon = (isDesc: boolean, hasOrdering: boolean, ordering: Ordering) => {
  if (!hasOrdering) return null;
  const icons = isDesc
    ? {
        [SORTING_TYPE.alphabetical]: IconSortDescendingLetters,
        [SORTING_TYPE.numerical]: IconSortDescendingNumbers,
        [SORTING_TYPE.rank]: IconSortDescendingNumbers,
        [SORTING_TYPE.recurency]: IconArrowNarrowDown,
        default: IconArrowNarrowUp,
      }
    : {
        [SORTING_TYPE.alphabetical]: IconSortAscendingLetters,
        [SORTING_TYPE.numerical]: IconSortAscendingNumbers,
        [SORTING_TYPE.rank]: IconSortAscendingNumbers,
        [SORTING_TYPE.recurency]: IconArrowNarrowUp,
        default: IconArrowNarrowDown,
      };

  const SortingIcon = icons[ordering.sortingKey || 'default'] || icons.default;

  return <SortingIcon className="sort-icon" size={MENU_ICON_SIZE} />;
};

export const getSortingText = (isASC: boolean, hasOrdering: boolean, ordering: Ordering) => {
  if (!hasOrdering) return null;
  const translations = isASC
    ? {
        [SORTING_TYPE.alphabetical]: t('A to Z'),
        [SORTING_TYPE.numerical]: t('Lowest to highest'),
        [SORTING_TYPE.recurency]: t('Oldest to newest'),
        [SORTING_TYPE.amount]: t('Fewest to most'),
        [SORTING_TYPE.rank]: t('Highest to lowest'),
        default: t('Ascending'),
      }
    : {
        [SORTING_TYPE.alphabetical]: t('Z to A'),
        [SORTING_TYPE.numerical]: t('Highest to lowest'),
        [SORTING_TYPE.recurency]: t('Newest to oldest'),
        [SORTING_TYPE.amount]: t('Most to fewest'),
        [SORTING_TYPE.rank]: t('Lowest to highest'),
        default: t('Descending'),
      };

  return translations[ordering.sortingKey || 'default'] || translations.default;
};
