import { memo } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import { ChartLoader } from 'Components/Chart/ChartLoader/ChartLoader';
import { lineChartColors } from 'Components/Chart/LineChart/support/constants';
import {
  getNavigatorXAxis,
  getRangeSelectorButtonConfig,
} from 'Components/Chart/LineChart/support/hooks/useChartConfig';
import { ReactStockedChart } from 'Components/Chart/ReactHighcarts';
import {
  DEFAULT_STOCK_CHART_CONFIG,
  ENABLED_RANGE_SELECTOR,
  TOOLTIP_CHART_FORMATTING,
} from 'Components/Chart/support/constants';
import { useUnknownCompetitorRankDistributionOverTimeQuery } from 'Ghql';
import { useFilters } from 'Hooks';
import { useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import { addLowDataPlaceholderDays } from 'Pages/Keywords/Overview/components/HistoryCharts/support/lowData';
import colorScheme, { getCompetitorAndDomainColors } from 'Utilities/colors';
import { getFaviconByDomain } from 'Utilities/favicon';

export const UnknownCompetitorScoreOverTime = memo(({ competitors }: { competitors: string[] }) => {
  const filters = useFilters();
  const { domainInfo, colorMap, displayNameMap } = useQueryDomainInfo();
  const chartHeight = 380;
  const isYoutubeDomain = Boolean(domainInfo?.youtubeChannelName);

  const { data, loading } = useUnknownCompetitorRankDistributionOverTimeQuery({
    skip: !competitors,
    variables: {
      filters,
      competitors,
      name: 'unknownCompetitorRankDistributionOverTime',
    },
  });

  let scoreDistributionOverTime = data?.graphs?.unknownCompetitorRankDistributionNew
    ? [...data.graphs.unknownCompetitorRankDistributionNew]
    : [];

  // If only 1 date, add an extra fake date so graph is not completely empty
  if (scoreDistributionOverTime?.every((x) => x?.data?.length === 1)) {
    scoreDistributionOverTime = cloneDeep(scoreDistributionOverTime); // data is read-only
    for (let i = 0; i < scoreDistributionOverTime.length; i++) {
      addLowDataPlaceholderDays(scoreDistributionOverTime[i], true);
    }
  }

  if (loading) {
    return (
      <ChartLoader
        loaderType="stack"
        loading={loading}
        containerBoxProps={{
          h: chartHeight,
          mb: 10,
        }}
      />
    );
  }

  if (!competitors || !scoreDistributionOverTime || !colorMap || !displayNameMap) {
    return null;
  }
  const dynamicCompetitors = scoreDistributionOverTime.map((x) => x.name);

  const colors = getCompetitorAndDomainColors(colorMap, dynamicCompetitors);

  const series = scoreDistributionOverTime.map((x, i) => {
    return {
      marker: {
        enabled: false,
      },
      type: 'areaspline',
      animation: false,
      pointPlacement: 'between',
      color: colors[i],
      name: displayNameMap[x.name] || x.name,
      domain: x.name,
      isOwnDomain: x.name === domainInfo?.domain,
      data: x.data,
      showInNavigator: true,
    };
  });

  const yAxis: any = [
    {
      title: null,
      min: 0,
      max: 103,
      showLastLabel: true,
      tickInterval: 50,
      endOnTick: false,
      top: '0%',
      height: '100%',
      opposite: false,
      labels: {
        align: 'right',
        y: 4,
        style: {
          whiteSpace: 'nowrap',
          textOverflow: 'none',
        },
        formatter() {
          return `${(this as any)?.value}%`;
        },
      },
    },
  ];

  const config = {
    chart: {
      type: 'areaspline',
      height: chartHeight,
      marginTop: 5,
      marginBottom: 20,
      marginLeft: 45,
      zooming: { type: 'x' },
    },
    navigator: {
      enabled: true,
      handles: {
        height: 20,
        borderColor: lineChartColors.navigator.BORDER_COLOR,
        width: 7,
      },
      xAxis: getNavigatorXAxis(series),
      height: 50,
      margin: 50,
      fillOpacity: 0.8,
      lineWidth: 0,
      borderWidth: 0,
      maskFill: lineChartColors.navigator.MASK_FILL,
      series: {
        type: 'areaspline',
        stacking: 'percent',
        pointPlacement: 'between',
        marker: {
          enabled: false,
        },
        connectNulls: false, // Layout is broken when nulls are connected for large data sets, see e.g. Accuranker UK
        fillOpacity: 0.8,
        lineWidth: 0,
        borderWidth: 0,
        boostThreshold: 100,
        turboThreshold: 500000,
        dataGrouping: true,
      },
    },
    tooltip: {
      ...TOOLTIP_CHART_FORMATTING,
      shared: true,
      split: false,
      padding: 15,
      valueDecimals: 0,
      xDateFormat: '%b %e, %Y',
      //hideDelay: 5,
    },
    plotOptions: {
      areaspline: {
        stacking: 'percent',
        states: {
          inactive: {
            enabled: true,
            animation: { duration: 0 },
          },
          hover: {
            enabled: false,
          },
        },
      },
      series: {
        animation: false,
        connectNulls: false,
        cursor: 'pointer',
        lineWidth: 2,
        boostThreshold: 100,
        turboThreshold: 500000,
        dataGrouping: true,
      },
    },
    boost: {
      useGPUTranslations: true,
      seriesThreshold: 1,
    },
    xAxis: {
      type: 'datetime',
      allowDecimals: false,
      crosshair: false,
      minTickInterval: 24 * 3600 * 1000,
      tickWidth: 0,
      labels: {
        //overflow: 'justify',
        y: 20,
      },
    },
    yAxis,
    legend: {
      enabled: true,
      layout: 'vertical',
      itemMarginBottom: 6,
      y: 10,
      verticalAlign: 'top',
      align: 'right',
      useHTML: true,
      padding: 0,
      labelFormatter() {
        const faviconSrc = getFaviconByDomain(
          isYoutubeDomain ? 'youtube.com' : (this as any)?.userOptions?.domain,
          16,
          true,
        );
        const idx = (this as any)?._i + 1;
        const fontColor = (this as any)?.userOptions?.isOwnDomain ? colorScheme.orange : '#337ab7';

        return `<div style='${
          (this as any)?.visible ? '' : 'opacity: 0.5;'
        }; color: ${fontColor};  padding-bottom: 2px; padding-top: 0; margin-top:0'>
          <span style='width:20px; display: inline-block'>#${idx}</span>
<img width="16" height="16" src="${faviconSrc}" alt="" style="border-radius: 100%; margin-right: 4px; "/>${(
          this as any
        )?.name.slice(0, 25)}</div>`;
      },
    },
    series,
    ...DEFAULT_STOCK_CHART_CONFIG,
  };
  const finalConfig = merge(cloneDeep(config), {
    rangeSelector: { ...ENABLED_RANGE_SELECTOR, ...getRangeSelectorButtonConfig() },
  });

  return <ReactStockedChart config={finalConfig} />;
}, isEqual);
UnknownCompetitorScoreOverTime.displayName = 'UnknownCompetitorScoreOverTime';
