import type { Options } from 'highcharts';
import { CHART_HEIGHT, lineChartColors } from 'Components/Chart/LineChart/support/constants';

/* eslint-disable import/no-unused-modules */
export const NO_CREDITS_CHART_CONFIG = {
  credits: { enabled: false },
};

export const NO_RANGE_SELECTORS_CHART_CONFIG = {
  rangeSelector: {
    enabled: false,
  },
};

const DEFAULT_RESPONSIVE_CHART_CONFIG: Options['responsive'] = {
  rules: [
    {
      condition: {
        maxWidth: 600,
      },
      chartOptions: {
        chart: {
          height: CHART_HEIGHT,
        },
        subtitle: {
          text: undefined,
        },
      },
    },
  ],
};

export const NO_TITLE_CHART_CONFIG = {
  title: { text: '', style: { display: 'none' } },
};

/** Used for highcharts charts to disable the use a hidden range selector
 * The reason it is still enabled is because we want to default to the latest 3 months corresponding to selected: 1
 * */
export const DEFAULT_RANGE_SELECTOR: Options['rangeSelector'] = {
  enabled: true,
  floating: true,
  selected: 1,
  allButtonsEnabled: true,
  buttonTheme: {
    style: { display: 'none' },
  },
  inputEnabled: false,
  labelStyle: {
    visibility: 'hidden',
  },
};

export const DEFAULT_NAVIGATOR: Options['navigator'] = {
  enabled: true,
  handles: {
    height: 20,
    borderColor: lineChartColors.navigator.BORDER_COLOR,
    width: 7,
    symbols: ['square', 'square'],
    r: 4,
  } as Highcharts.NavigatorHandlesOptions,
  outlineWidth: 0,
  height: 50,
  maskFill: lineChartColors.navigator.MASK_FILL,
};

export const ENABLED_RANGE_SELECTOR: Options['rangeSelector'] = {
  enabled: true,
  selected: 2,
  allButtonsEnabled: true,
  inputStyle: {
    color: 'rgb(102, 102, 102)', //#f89734
  },
  inputPosition: {
    align: 'right',
  },
  floating: true,
  y: -68,
  verticalAlign: 'bottom',
  labelStyle: {
    visibility: 'visible',
  },
  buttonTheme: {
    style: { display: 'inline-block', color: '#444', fontWeight: '600' },
    r: 4,
    fill: 'transparent',
    states: {
      select: {
        fill: '#ced0dc91',
        style: {
          fontWeight: 600,
        },
      },
    },
  },
};

export const WITHOUT_LIVE_REDRAW_IN_NAVIGATOR = {
  scrollbar: {
    liveRedraw: false,
    barBackgroundColor: lineChartColors.scrollbar.THUMB,
    barBorderRadius: 4,
    barBorderWidth: 0,
    rifleColor: lineChartColors.scrollbar.THUMB_RIFLE,
    buttonArrowColor: lineChartColors.scrollbar.BUTTON_ARROW,
    buttonBackgroundColor: lineChartColors.scrollbar.BUTTON_ARROW_BACKGROUND,
    buttonBorderRadius: 2,
    buttonBorderWidth: 0,
    trackBackgroundColor: lineChartColors.scrollbar.TRACK,
    trackBorderWidth: 0,
  },
};

export const DEFAULT_CHART_CONFIG = {
  ...NO_TITLE_CHART_CONFIG,
  ...NO_CREDITS_CHART_CONFIG,
  rangeSelector: DEFAULT_RANGE_SELECTOR,
  ...WITHOUT_LIVE_REDRAW_IN_NAVIGATOR,
};

export const DEFAULT_STOCK_CHART_CONFIG = {
  rangeSelector: DEFAULT_RANGE_SELECTOR,
  responsive: DEFAULT_RESPONSIVE_CHART_CONFIG,
  ...NO_TITLE_CHART_CONFIG,
  ...NO_CREDITS_CHART_CONFIG,
  ...WITHOUT_LIVE_REDRAW_IN_NAVIGATOR,
};

export const WITH_DISABLED_LEGEND = {
  legend: { enabled: false },
};

export const TOOLTIP_CHART_FORMATTING = {
  useHTML: true,
  backgroundColor: 'white',
  borderColor: '#D7D9E5',
  borderRadius: 10,
  boxShadow: 'none',
  shadow: false,
  padding: 24,
  borderWidth: 1,
  shared: false,
  stickOnContact: false,
};
