import { useApolloClient } from '@apollo/client';
import { UserRole, allGroupsId, allGroupsUserRoles } from 'Constants/workspaces';
import { UpdateUserInput, useEditUserForm_EditUserMutation } from 'Ghql';
import toast from 'Hooks/useToast';
import { EventName, TrackingKey, useMixpanel } from 'Utilities/Analytics/mixpanel';
import { invalidateCache } from 'Utilities/Graphql/invalidateCache';
import { t } from 'Utilities/i18n';
import { mapFinalFormErrors } from 'Utilities/validation';

export const useUpdateUser = ({
  id,
  inlineForm,
  refresh,
  onClose,
}: {
  id: string;
  inlineForm?: boolean;
  refresh?: (...args: Array<any>) => any;
  onClose?: (...args: Array<any>) => void;
}) => {
  const trackEvent = useMixpanel();
  const client = useApolloClient();
  const [updateUser] = useEditUserForm_EditUserMutation();

  const handleSubmit = ({
    fullName,
    email,
    userType,
    workspaces,
  }: {
    fullName: string;
    email: string;
    userType: UserRole;
    workspaces?: number[];
  }) => {
    const updateUserInput: UpdateUserInput = {
      id,
      fullName,
      email,
      userType,
      workspaces: (allGroupsUserRoles.includes(userType) ? [allGroupsId] : workspaces || []).map(
        Number,
      ),
      delete: false,
    };

    return updateUser({
      variables: {
        updateUserInput,
      },
    }).then((result) => {
      const errors = result.data?.updateUser?.errors;
      if (errors && errors.length) {
        toast.error(t('Something went wrong'));
        const errorObjects = errors
          .filter((err) => err)
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          .map((err) => ({ field: err!.field, messages: err!.messages }));

        trackEvent(EventName.UsersPage, {
          'Tracking key': inlineForm ? TrackingKey.InlineEditFail : TrackingKey.EditFail,
          Message: errors[0]?.messages[0] ?? '',
        });

        return mapFinalFormErrors(errorObjects);
      }
      trackEvent(EventName.UsersPage, {
        'Tracking key': inlineForm ? TrackingKey.InlineEditSuccess : TrackingKey.EditSuccess,
      });
      toast.success(t('User updated'));
      invalidateCache(client.cache, ['user']);
      refresh?.();
      onClose?.();
    });
  };
  return handleSubmit;
};
