export enum StringOperation {
  EQ = 'eq',
  NE = 'neq',
  CONTAINS = 'contains',
  STARTS_WITH = 'starts_with',
  ENDS_WITH = 'ends_with',
  NOT_CONTAINS = 'not_contains',
  REGEX = 'regex',
  NOT_REGEX = 'not_regex',
}

export enum NumberOperations {
  EQ = 'eq',
  NE = 'neq',
  LT = 'lt',
  GT = 'gt',
  BETWEEN = 'between',
}

export enum BaseOperations {
  ANY = 'any',
  ALL = 'all',
  NONE = 'none',
  EMPTY = 'empty',
}

export enum OperationType {
  STRING = 'string',
  NUMBER = 'number',
  BASE = 'base',
  STRING_AND_TITLE_CONTAINS_KEYWORD = 'title_contains_keyword',
}
