import { useCallback, useEffect, useState } from 'react';
import { Flex, Group } from '@mantine/core';
import { AccFastIcon } from 'Components/AccFastIcon';
import { SeriesDataItem } from 'Components/Chart/LineChart/support/types';
import { getSerpFeatureData } from 'Components/Table/TableRow/SERPOptions/data';
import AccTitle from 'Components/Title/AccTitle';
import { store } from 'Store';
import { t } from 'Utilities/i18n';
import ChartHistoryChart from './ChartHistoryChart';
import { SerpOwnedSegmentedControl } from './SerpOwnedSegmentedControl';

const ChartHistory = ({
  selectedSerp,
  serpHistoryData,
  loadingSerpHistory,
}: {
  selectedSerp: string;
  serpHistoryData: SeriesDataItem[];
  loadingSerpHistory: boolean;
}) => {
  const state = store.getState();

  const serpFeatureCanBeOwned = state?.metaData?.serpFeatures?.some(
    (feature) => feature?.owned && feature.id === selectedSerp,
  );

  const [ownedChart, _setOwnedChart] = useState(true);

  const [key, setKey] = useState(0);

  const setOwnedChart = useCallback((value: boolean) => {
    setKey((currentKey) => currentKey + 1);
    _setOwnedChart(value);
  }, []);

  useEffect(() => {
    if (!serpFeatureCanBeOwned) {
      setOwnedChart(false);
    }
  }, [serpFeatureCanBeOwned, setOwnedChart]);

  return (
    <div className="keywords-serp-chart-container large">
      <Group>
        <AccTitle type="h3" helper={t('See the changes over time for an individual SERP feature.')}>
          {selectedSerp === 'all' ? (
            t('All SERP Historical')
          ) : (
            <Flex gap={4}>
              <AccFastIcon
                src={getSerpFeatureData(selectedSerp).icon}
                size={16}
                color={'#6b6c6c'}
                fit={'contain'}
              />
              {getSerpFeatureData(selectedSerp).label} {t('Historical')}
            </Flex>
          )}
        </AccTitle>
        <SerpOwnedSegmentedControl
          onChange={() => setOwnedChart(!ownedChart)}
          serpFeatureCanBeOwned={serpFeatureCanBeOwned}
          ownedChart={ownedChart}
        />
      </Group>
      <ChartHistoryChart
        data={serpHistoryData}
        selectedSerp={selectedSerp}
        isLoading={loadingSerpHistory}
        ownedChart={ownedChart}
        updateKey={key}
      />
    </div>
  );
};

export default ChartHistory;
