import { Tooltip } from '@mantine/core';
import uniqueId from 'lodash/uniqueId';
import HelpIcon from 'icons/question-rounded-filled.svg?inline';
import './hint.scss';

type Props = {
  help: string;
};

const Hint = ({ help }: Props) => {
  const helpIconId = uniqueId('hint');

  return (
    <Tooltip label={help} withinPortal={false} px={10} py={5} lh={1.5}>
      <span className="hint">
        <HelpIcon id={helpIconId} />
      </span>
    </Tooltip>
  );
};

export default Hint;
