import { useNavigate } from 'react-router';
import { IconChevronRight, IconHome } from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import PageHeaderContainer from 'Components/PageHeaderContainer';
import AccText from 'Components/Text/AccText';
import { useHasAccuApiAccess } from 'Hooks/data/user/useHasAccuApiAccess';
import { t } from 'Utilities/i18n';
import { useBreadcrumTitles } from './useBreadcrumbTitles';
import styles from './breadcrumbs.module.scss';

export const HomeButton = ({ navigate }: { navigate?: (url: string) => void }) => {
  const hasAccuApiAccess = useHasAccuApiAccess();

  const homeLink = hasAccuApiAccess ? '/account' : '/domains';

  const handleOnClick = () => navigate?.(homeLink);

  return (
    <AccTooltip tooltip={t('Go to home page')}>
      <div className={styles.homeLinkButton} onClick={handleOnClick}>
        <IconHome size={18} />
      </div>
    </AccTooltip>
  );
};

export const LastBreadcrumbChild = ({
  children,
  testid,
}: {
  children: React.ReactNode;
  testid?: string;
}) => (
  <AccText data-testid={testid} size="sm" fw={600} c="gray.6">
    {children}
  </AccText>
);

type Props = {
  customTitle?: string;
  customParentTitle?: string | null;
  customParentLink?: string;
};
const Breadcrumbs = (props: Props) => {
  const { customTitle, customParentTitle, customParentLink } = props;

  const { title, parentTitle, parentLink } = useBreadcrumTitles();

  const navigate = useNavigate();

  if (!title && !parentTitle && !customTitle && !customParentTitle) return null;

  const finalTitle = customTitle ?? title;
  const finalParentTitle = customParentTitle ?? parentTitle;

  return (
    <PageHeaderContainer breadcrumbs>
      <HomeButton navigate={navigate} />
      {finalParentTitle && finalTitle ? (
        <>
          <IconChevronRight />
          <AccButton
            data-testid={'breadcrumbs-parent-title'}
            onClick={() => {
              if (customParentLink) {
                navigate(customParentLink);
              } else if (parentLink) {
                navigate(parentLink);
              }
            }}
            variant="tertiary"
          >
            {finalParentTitle}
          </AccButton>
        </>
      ) : (
        <>
          <IconChevronRight />
          <LastBreadcrumbChild testid="breadcrumbs-title">{finalParentTitle}</LastBreadcrumbChild>
        </>
      )}
      {finalTitle && (
        <>
          <IconChevronRight />
          <LastBreadcrumbChild testid="breadcrumbs-title">{finalTitle}</LastBreadcrumbChild>
        </>
      )}
    </PageHeaderContainer>
  );
};

export default Breadcrumbs;
