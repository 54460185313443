import { useRef, useState } from 'react';
import { useFormState } from 'react-final-form';
import { useQuery } from '@apollo/client';
import { Flex } from '@mantine/core';
import cn from 'classnames';
import throttle from 'lodash/throttle';
import AccBadge from 'Components/AccBadge';
import FormatNumber from 'Components/FormatNumber/new';
import Icon from 'Components/Icon/new';
import AccText from 'Components/Text/AccText';
import { t } from 'Utilities/i18n';
import KeyDisIcon from 'icons/menu/compass-keydis.svg?inline';
import KeywordsIcon from 'icons/menu/keywords.svg?inline';
import { QUERY_DYNAMIC_TAG_RULESET_COUNT } from '../support/dynamig-tags.query';
import { getMatchDynamicCount, validateRules } from '../support/helpers';
import styles from '../dynamic-tags.module.scss';

const DEBOUNCE_DELAY = 3 * 1000;

export const DynamicTagsRulesCount = ({ domainId }: { domainId: string }) => {
  const [input, setInputVariables] = useState();

  const { data, loading } = useQuery(QUERY_DYNAMIC_TAG_RULESET_COUNT, {
    variables: { input },
    skip: !input,
  });
  const prevGrankCountRef = useRef(0);
  const prevKeydisCountRef = useRef(0);

  const { values } = useFormState();

  // Set zero count if invalid rules (e.g. incomplete, for instance if comparator not yet chosen).
  const counts = !validateRules(values.rawRuleset) ? data?.dynamicTagRulesetCount : [0, 0];

  useFormState({
    onChange: throttle((form) => {
      prevGrankCountRef.current = counts?.[0] ?? '...';
      prevKeydisCountRef.current = counts?.[1] ?? '...';
      // Set input variables from form and thus fire query if the rules are valid
      const rulesAreValid = !validateRules(form.values.rawRuleset);
      // eslint-disable-next-line no-console
      if (rulesAreValid) {
        setInputVariables(getMatchDynamicCount(domainId!, form.values));
      }
    }, DEBOUNCE_DELAY),
    subscription: { values: true, valid: true },
  });

  return (
    <Flex gap={'lg'} mr="auto">
      <Flex justify={'space-between'} gap={2} align={'center'}>
        <Icon height={16} width={16}>
          <KeywordsIcon stroke={'gray'} />
        </Icon>
        <AccText variant="label">{t('Tracked keywords: ')}</AccText>
        <AccBadge type="brand" ml={4} className={cn({ [styles.loadingTag]: loading })}>
          {loading ? '...' : <FormatNumber value={counts?.[0]} />}
        </AccBadge>
      </Flex>
      <Flex justify={'space-between'} gap={2} align={'center'}>
        <Icon height={16} width={16} color={'gray'}>
          <KeyDisIcon />
        </Icon>
        <AccText variant="label">{t('Discovered keywords: ')}</AccText>
        <AccBadge type="brand" ml={4} className={cn({ [styles.loadingTag]: loading })}>
          {loading ? '...' : <FormatNumber value={counts?.[1]} />}
        </AccBadge>
      </Flex>
    </Flex>
  );
};
