import { Flex, Progress } from '@mantine/core';
import { useAccountUsageUserQuery } from 'Ghql';
import { t } from 'Utilities/i18n';
import { notEmpty } from 'Utilities/underdash';
import './account-usage.scss';

const AccountUsage = () => {
  const { data, error, loading } = useAccountUsageUserQuery();

  if (loading || error) {
    return null;
  }

  const numberOfKeywords = data?.user?.organization?.numberOfKeywords ?? 0;
  const numberOfUsers = data?.user?.organization?.numberOfUsers ?? 0;
  const numberOfDomains = data?.user?.organization?.numberOfDomains ?? 0;

  const { activePlan, previousPlan } = data?.user?.organization || {};

  const workspaces = data?.workspacesByOrganization?.filter(notEmpty) || [];

  const {
    maxUsers = 0,
    maxKeywords = 0,
    maxDomains = 0,
    maxNumbersOfWorkspaces = 0,
  } = activePlan || previousPlan || {};

  return (
    <div className="account-usage">
      <Flex justify="space-between">
        <small>{t('Keywords')}</small>
        <small>{t('%s of %s', numberOfKeywords, maxKeywords)}</small>
      </Flex>
      <Progress
        mb={6}
        size="xl"
        color="blue"
        value={(Math.min(numberOfKeywords, maxKeywords) / maxKeywords) * 100}
      />

      <Flex justify="space-between">
        <small>{t('Domains')}</small>
        <small>
          {t('%s of %s', numberOfDomains, maxDomains === -1 ? t('Unlimited') : maxDomains)}
        </small>
      </Flex>
      <Progress
        mb={6}
        size="xl"
        color="blue"
        value={(numberOfDomains / (maxDomains === -1 ? 9999 : maxDomains)) * 100}
      />

      <Flex justify="space-between">
        <small>{t('Users')}</small>
        <small>{t('%s of %s', numberOfUsers, maxUsers === -1 ? t('Unlimited') : maxUsers)}</small>
      </Flex>
      <Progress
        mb={6}
        size="xl"
        color="blue"
        value={(numberOfUsers / (maxUsers === -1 ? 9999 : maxUsers)) * 100}
      />

      <Flex justify="space-between">
        <small>{t('Max number of Workspaces')}</small>
        <small>
          {t(
            '%s of %s',
            workspaces?.length ?? 0,
            maxNumbersOfWorkspaces === -1 ? t('Unlimited') : maxNumbersOfWorkspaces,
          )}
        </small>
      </Flex>
      <Progress
        mb={0}
        size="xl"
        color="blue"
        value={
          ((workspaces?.length ?? 0) /
            (maxNumbersOfWorkspaces === -1 ? 9999 : maxNumbersOfWorkspaces)) *
          100
        }
      />
    </div>
  );
};

export default AccountUsage;
