import { useRef } from 'react';
import { findDOMNode } from 'react-dom';
import { IconDownload } from '@tabler/icons-react';
import domtoimage from 'dom-to-image';
import FileSaver from 'file-saver';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import toast from 'Hooks/useToast';
import { t } from 'Utilities/i18n';
import './export-button.scss';

// const TRANSPARENT_PIXEL =
//   'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
type Props = {
  content: () => any;
  fileName?: string;
  onClick?: (...args: Array<any>) => any;
  small?: boolean;
};

export const elmToImage = (content, domNodeToExclude, fileName) => {
  const domContent = content();
  // let devSettings = {};
  // if (process.env.NODE_ENV !== 'production') {
  //   // replace images with placeholders in dev mode because of CORS issues
  //   devSettings = {
  //     imagePlaceholder: TRANSPARENT_PIXEL,
  //   };
  // }
  domContent.classList.add('exported-chart');
  domtoimage
    .toBlob(domContent, {
      // eslint-disable-next-line react/no-find-dom-node
      filter: (node) => node !== findDOMNode(domNodeToExclude) && node.type !== 'image',
      // ...devSettings,
    })
    .then((blob) => {
      FileSaver.saveAs(blob, fileName || 'chart.png');
      toast.success(t('Image downloaded'));
    })
    .catch(() => {
      toast.error(t('Export as image failed'));
    })
    .finally(() => {
      domContent.classList.remove('exported-chart');
    });
};

const ExportButton = (props: Props) => {
  const exportButtonRef = useRef(null);

  return (
    <AccTooltip tooltip={t('Download chart')}>
      <AccActionIcon
        size={'lg'}
        variant="subtle"
        ref={exportButtonRef}
        className="export-chart-button"
        onClick={() => {
          if (props.onClick) {
            props.onClick(exportButtonRef);
            return;
          }
          elmToImage(props.content, exportButtonRef.current, props.fileName);
        }}
      >
        <IconDownload />
      </AccActionIcon>
    </AccTooltip>
  );
};

export default ExportButton;
