import { useLayoutEffect, useRef } from 'react';
import { ScrollSyncPane } from 'react-scroll-sync';
import StickyScrollBar from './stickyScrollBar';
import styles from './stickyVerticalScroll.module.scss';

export const StickyVerticalScroll = ({ width }: { width?: number }) => {
  const hasWidth = !!width;

  const stickyRef = useRef<{ setScrollLeft:(left: number) => void }>();
  const containerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    stickyRef.current?.setScrollLeft(containerRef.current?.scrollLeft || 0);
  }, [containerRef.current?.scrollLeft]);

  return (
    <ScrollSyncPane>
      <div className={styles.container} ref={containerRef} data-has-width={hasWidth || null}>
        <div className={styles.innerBlock}>
          <div className={styles.extendBlock} style={{ width: width ? `${width}px` : '100%' }} />
        </div>
      </div>
      <StickyScrollBar scrollBodyRef={containerRef} width={width} />
    </ScrollSyncPane>
  );
};
