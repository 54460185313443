import { SeriesItem } from 'Components/Chart/LineChart';
import { FolderPathHistoryQuery } from 'Ghql';
import colorScheme from 'Utilities/colors';

type ChartData = {
  value: number;
  searchDate: number;
};

type groupingsChartData = {
  name: string;
  isDynamic: boolean;
  isFolder: boolean;
  data: ChartData[];
};

const mapToChartData = (data: ChartData[]) => {
  return data.map((entry) => {
    return {
      x: entry.searchDate,
      y: entry.value,
    };
  });
};

export const useGroupingsChartData = (rawGroupingsData?: FolderPathHistoryQuery) => {
  if (!rawGroupingsData?.graphs?.folderPathHistory) {
    return { series: undefined };
  }

  const folderPathHistory = rawGroupingsData?.graphs.folderPathHistory as groupingsChartData[];
  const colors = colorScheme.graphs.getColors();

  const series: SeriesItem[] = folderPathHistory?.map((node, i) => {
    return {
      name: node.name,
      primary: false,
      color: colors[i % colors.length],
      visible: true,
      data: mapToChartData(node.data),
    };
  });

  return { series };
};
