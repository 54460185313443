import { useEffect, useRef } from 'react';
import { useIntercom } from 'react-use-intercom';
import { IconChevronDown } from '@tabler/icons-react';
import cn from 'classnames';
import AccText from 'Components/Text/AccText';
import { t } from 'Utilities/i18n';
import IntercomIcon from 'icons/intercom-icon.svg?inline';
import { useInsertIntercomCloseButton } from './useInsertIntercomCloseButton';
import { useIntercomConfig } from './useIntercomConfig';
import styles from './intercom-button.module.scss';

type Props = {
  navbarCollapsed?: boolean;
  size?: 'sm' | 'lg';
};
const IntercomButton = (props: Props) => {
  const { navbarCollapsed = true } = props;

  const bootRef = useRef(false);

  const { hide, show, isOpen, boot } = useIntercom();
  useInsertIntercomCloseButton({ hide, isOpen, isBooted: bootRef.current });
  const bootIntercomWithConfiguration = useIntercomConfig({ boot });

  useEffect(() => {
    if (bootRef.current) {
      return;
    }
    if (bootIntercomWithConfiguration) {
      bootIntercomWithConfiguration();
      bootRef.current = true;
    }
  }, [bootIntercomWithConfiguration]);

  return (
    <button
      id="intercom-button"
      className={styles.intercomButton}
      onClick={() => {
        isOpen ? hide : show;
      }}
    >
      <div
        className={cn(styles.container, { [styles.collapsed]: navbarCollapsed })}
        data-large={props.size === 'lg' || null}
      >
        {isOpen ? <IconChevronDown fill="none" /> : <IntercomIcon />}
      </div>
      {!navbarCollapsed && (
        <AccText className={styles.intercomText} c="white">
          {isOpen ? t('Close support') : t('Contact support')}
        </AccText>
      )}
    </button>
  );
};

export default IntercomButton;
