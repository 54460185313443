import { useCallback, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import isNil from 'lodash/isNil';
import moment from 'moment/moment';
import { DataTable, TableSize } from 'Components/DataTable';
import { formatDisplayNumber } from 'Components/FormatNumber/formatNumberHelper';
import { EARLIEST, LATEST } from 'Components/PeriodFilter/model';
import { DEFAULT_BACKEND_DATE_FORMAT } from 'Constants';
import { AiSearchTrendDocument, SearchTrendDocument } from 'Ghql';
import { useFiltersWithHash, useSpecificFilter } from 'Hooks';
import { percentageDifference } from 'Pages/Keywords/Overview/components/HistoryCharts/LocalPackRankNeighborly/table';
import { useGroupViewInfo } from 'Pages/Keywords/Overview/hooks/useGroupViewInfo';
import { FilterAttribute } from 'Types/Filter';
import { TableIDs } from 'Types/Table';
import { t } from 'Utilities/i18n/index';
import DatePickerCellRenderer from '../HistoryCharts/support/DatePickerCellRenderer';
import DiffCellRenderer from '../HistoryCharts/support/DiffCellRenderer';

const dateToStartOfMonth = (date: string) => {
  return moment(date, DEFAULT_BACKEND_DATE_FORMAT)
    .startOf('month')
    .format(DEFAULT_BACKEND_DATE_FORMAT);
};

const formatAsMonth = (date: string) => {
  return moment(date).format('MMMM YYYY');
};

const getSearchTrendNeighborlyColumns = (useAi) => {
  return [
    {
      id: 'date',
      title: t('Date'),
      width: 150,
      cellRenderer: ({ record }) => (
        <DatePickerCellRenderer
          onDateChange={record.onDateChange}
          date={record.firstColumn}
          minDate={record.minDate}
          maxDate={record.maxDate}
          monthly
        />
      ),
    },
    {
      id: 'searchVolume',
      title: useAi ? t('AI Search Volume') : t('Search Volume'),
      width: 5 * 90, // to match LocalPackRankNeighborly/table.tsx and RankingDistributionNeighborly/table.tsx
      cellRenderer: ({ record }) => (
        <DiffCellRenderer content={record.searchVolume} justify={'flex-end'} />
      ),
      onHeaderCell: () => ({ reverseDirection: true }),
    },
  ];
};

export const SearchTrendNeighborlyTable = ({ useAi }: { useAi: boolean }) => {
  const columns = getSearchTrendNeighborlyColumns(useAi);
  const [filters, filtersHash] = useFiltersWithHash();
  const client = useApolloClient();
  const { isEmptyGroup } = useGroupViewInfo(filters);
  const periodFilter = useSpecificFilter(FilterAttribute.PERIOD);
  const periodFilterValue = JSON.parse((periodFilter?.value as string) ?? '[]');
  const isLatest = periodFilterValue[1] === LATEST;
  const isInitial = periodFilterValue[0] === EARLIEST;
  const document = useAi ? AiSearchTrendDocument : SearchTrendDocument;
  const name = useAi ? 'aiSearchTrendNeighborlyGraph' : 'searchTrendNeighborlyGraph';
  const [customCompareDate, setCustomCompareDate] = useState<string | null>(null);
  const [customLatestDate, setCustomLatestDate] = useState<string | null>(null);

  // WARNING: For some reason, pulling this logic out in a custom hook causes the table not to respond
  // to filter changes (only responds on every second change then).. So please keep this inline
  const fetchData = useCallback(async () => {
    if (isEmptyGroup) {
      return {
        data: [],
        length: 0,
      };
    }
    return client
      .query({
        query: document,
        variables: {
          filters,
          name,
        },
      })
      .then((e) => {
        const dataKey = useAi ? 'overviewAiSearchTrend' : 'overviewSearchTrend';
        const searchTrendData = e.data.graphs[dataKey];

        const monthToValue = {};
        searchTrendData.forEach((x) => {
          monthToValue[x.month] = x.searchVolumeTotal ?? 0;
        });

        const absoluteMaxDate = Object.keys(monthToValue)[Object.keys(monthToValue).length - 1];
        let latestDate = dateToStartOfMonth(
          customLatestDate ? customLatestDate : isLatest ? absoluteMaxDate : periodFilterValue[1],
        );
        let value = monthToValue[latestDate];
        if (!value) {
          // handle invalid defaults from period filter
          latestDate = absoluteMaxDate;
          value = monthToValue[latestDate];
        }

        const absMinDate = Object.keys(monthToValue)[0];
        let compareDate = dateToStartOfMonth(
          customCompareDate ? customCompareDate : isInitial ? absMinDate : periodFilterValue[0],
        );
        let compareValue = monthToValue[compareDate];
        if (!compareValue) {
          // handle invalid defaults from period filter
          compareDate = absMinDate;
          compareValue = monthToValue[compareDate];
        }

        return {
          data: [
            {
              firstColumn: formatAsMonth(compareDate),
              searchVolume: isNil(compareValue) ? 'N/A' : formatDisplayNumber(compareValue),
              minDate: new Date(absMinDate),
              maxDate: moment(latestDate).subtract(1, 'm').toDate(),
              onDateChange: setCustomCompareDate,
            },
            {
              firstColumn: formatAsMonth(latestDate),
              searchVolume: isNil(value) ? 'N/A' : formatDisplayNumber(value),
              minDate: moment(compareDate).add(1, 'm').toDate(),
              maxDate: new Date(absoluteMaxDate),
              onDateChange: setCustomLatestDate,
            },
            {
              firstColumn: t('% Difference'),
              searchVolume: {
                value:
                  isNil(value) || isNil(compareValue)
                    ? 'N/A'
                    : `${percentageDifference(value, compareValue)}%`,
                diff: value - compareValue,
              },
            },
            {
              firstColumn: t('# Difference'),
              searchVolume: {
                value:
                  isNil(value) || isNil(compareValue)
                    ? 'N/A'
                    : formatDisplayNumber(value - compareValue),
                diff: value - compareValue,
              },
            },
          ],
          length: 4,
        };
      });
  }, [
    customCompareDate,
    customLatestDate,
    filtersHash,
    client,
    useAi,
    isEmptyGroup,
    isLatest,
    isInitial,
  ]);

  return (
    <DataTable
      tableId={useAi ? TableIDs.AI_SEARCH_TREND_NEIGHBORLY : TableIDs.SEARCH_TREND_NEIGHBORLY}
      columns={columns}
      fetchData={fetchData}
      pageSize={4}
      viewMode={TableSize.DEFAULT}
      defaultOrdering={undefined}
      pagination={false}
      dataKey={`${customCompareDate}-${customLatestDate}-${filtersHash}`} // trigger table re-render when dates change
      skipClearStoreOnUnmount
    />
  );
};
