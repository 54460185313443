import { Flex } from '@mantine/core';
import FacebookLogo from 'icons/social-media/facebook_circle.svg?inline';
import GoogleLogo from 'icons/social-media/google_circle.svg?inline';
import LinkedInLogo from 'icons/social-media/linkedin_circle.svg?inline';
import MicrosoftLogo from 'icons/social-media/microsoft.svg?inline';
import styles from './LoginForm.module.scss';

const FACEBOOK_AUTH_URL = '/suser/login/facebook/';
const GOOGLE_AUTH_URL = '/suser/login/google-oauth2/';
const MICROSOFT_AUTH_URL = '/suser/login/microsoft-graph/';
const LINKEDIN_AUTH_URL = '/suser/login/linkedin-oauth2/';

const handleSocialSignin = (link: string) => {
  window.open(link, '_self', 'noopener,noreferrer');
};

export default function AuthButtons() {
  const height = 50;
  const width = 50;
  const size = {
    height,
    width,
  };
  return (
    <Flex justify="space-evenly" my="xs" className={styles.authButtonsContainer}>
      <div title="Google" onClick={() => handleSocialSignin(GOOGLE_AUTH_URL)}>
        <GoogleLogo {...size} />
      </div>
      <div title="LinkedIn" onClick={() => handleSocialSignin(LINKEDIN_AUTH_URL)}>
        <LinkedInLogo {...size} />
      </div>
      <div title="Microsoft" onClick={() => handleSocialSignin(MICROSOFT_AUTH_URL)}>
        <MicrosoftLogo {...size} />
      </div>
      <div title="Facebook" onClick={() => handleSocialSignin(FACEBOOK_AUTH_URL)}>
        <FacebookLogo {...size} />
      </div>
    </Flex>
  );
}
