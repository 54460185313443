import React, { Component, Suspense, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import * as Sentry from '@sentry/react';
import Braintree from 'braintree-web-drop-in';
import compose from 'lodash/flowRight';
import AccTitle from 'Components/Title/AccTitle';
import LocaleSelector from 'Selectors/LocaleSelector';
import { useRouteMatch } from 'Utilities/Router/hooks/useRouteMatch';
import { t } from 'Utilities/i18n';
import {
  CalculatedPlan,
  PricingPlan,
  WithPricingPlanAndCalculatedPlanProps,
  getDiscountedPrice,
  getGa4Item,
  withPricingPlanAndCalculatedPlan,
} from '../utils';
import './payment-widget.scss';

type WidgetProps = {
  authorizationToken: string | undefined;
  onCreate: (arg0: Record<string, any> | boolean) => void;
  onError: (arg0: Error) => void;
  on3DCancel: (arg0: boolean) => void;
  fullLocale: string;
  uniqueid: string;
  companyInfoError: any;
  standalone: boolean;
  braintreeVaultManager: boolean;
  braintreeThreeDSecureAmount: number;
  country?: string;
  billingCycle?: string;
  pricingPlan?: PricingPlan;
  calculatedPlan: CalculatedPlan;
};
type State = {
  braintreeInstance: any;
  waitingText: string;
  ga4TrackingAddPaymentInfoSent: boolean;
};

class Widget extends Component<WidgetProps, State> {
  braintreeContainer: HTMLDivElement | undefined;
  static defaultProps = {
    standalone: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      braintreeInstance: null,
      waitingText: t('Connecting to payment provider…'),
      ga4TrackingAddPaymentInfoSent: false,
    };
  }

  componentDidMount() {
    if (this.state.braintreeInstance || !this.props.authorizationToken) return;
    this.setup();
  }

  componentDidUpdate(prevProps: WidgetProps, prevState: State) {
    this.onUpdate(prevProps);

    if (
      this.props.braintreeThreeDSecureAmount !== prevProps.braintreeThreeDSecureAmount &&
      prevState.braintreeInstance
    ) {
      prevState.braintreeInstance?.updateConfiguration(
        'threeDSecure',
        'amount',
        this.props.braintreeThreeDSecureAmount,
      );
    }
  }

  componentWillUnmount() {
    this.onUnmount();
  }

  async onUpdate(prevProps) {
    if (
      this.props.authorizationToken &&
      (prevProps.authorizationToken !== this.props.authorizationToken ||
        this.props.fullLocale !== prevProps.fullLocale ||
        this.props.uniqueid !== prevProps.uniqueid ||
        this.props.country !== prevProps.country)
    ) {
      try {
        await this.tearDown();
        // arr-4859 fix console error: options.selector or options.container must reference an empty DOM node
        this.braintreeContainer?.innerHTML && (this.braintreeContainer.innerHTML = '');
        this.setup();
      } catch (e: unknown) {
        console.error(e);

        if (this.props.onError && e instanceof Error) {
          this.props.onError(e);
        }
      }
    }
  }

  async onUnmount() {
    if (!this.state.braintreeInstance) return;

    try {
      await this.tearDown();
      // arr-4859 fix console error: options.selector or options.container must reference an empty DOM node
      this.braintreeContainer?.innerHTML && (this.braintreeContainer.innerHTML = '');
    } catch (e: unknown) {
      if (this.props.onError && e instanceof Error) {
        this.props.onError(e);
      }
    }
  }

  trackGA4AddPaymentInfo(event: { paymentMethodIsSelected: boolean; type: string }) {
    if (this.state.ga4TrackingAddPaymentInfoSent) return;

    const calculatedPlan = this.props.calculatedPlan;
    const pricingPlan = this.props.pricingPlan;

    const currency = calculatedPlan?.currency;
    const value = getDiscountedPrice(calculatedPlan);
    const payment_type = event.type;

    window.gtag?.('event', 'add_payment_info', {
      currency,
      value,
      payment_type,
      items: [getGa4Item(calculatedPlan, pricingPlan)],
    });

    this.setState({ ga4TrackingAddPaymentInfoSent: true });
  }

  setup() {
    if (this.props.companyInfoError) return;
    this.setState(
      {
        braintreeInstance: null,
      },
      () => {
        Braintree.create(
          {
            authorization: this.props.authorizationToken,
            container: this.braintreeContainer,
            threeDSecure: true,
            vaultManager: this.props.braintreeVaultManager || false,
            paypal: {
              flow: 'vault',
              buttonStyle: {
                size: 'responsive',
                color: 'blue',
              },
            },
            locale: this.props.fullLocale,
          },
          (e, instance) => {
            if (e) {
              console.error(e);
              Sentry.captureException(e);

              if (this.props.onError) {
                this.props.onError(e);
                return;
              }
            }

            if (this.props.onCreate && instance !== undefined) {
              if (instance.isPaymentMethodRequestable()) {
                this.props.onCreate(instance);
              } else {
                this.props.onCreate(false);
              }

              instance.on('paymentMethodRequestable', (event) => {
                this.trackGA4AddPaymentInfo(event);
                this.props.onCreate(this.state.braintreeInstance);
              });
              instance.on('noPaymentMethodRequestable', () => {
                this.props.onCreate(false);
              });

              instance._threeDSecure._instance.on('customer-canceled', () => {
                console.info('Customer canceled 3D Secure verification');
                this.props.on3DCancel(true); // currently not used by any implementation
              });
            }

            this.setState({
              braintreeInstance: instance,
            });
          },
        );
      },
    );
  }

  tearDown() {
    return new Promise((resolve, reject) => {
      // TODO FixTSignore
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (!this.state.braintreeInstance) return resolve();
      return this.state.braintreeInstance.teardown((err) => {
        if (err) {
          reject(err);
        }

        // TODO FixTSignore
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        resolve();
      });
    });
  }

  braintreeRef = React.createRef();

  render() {
    const className = this.props.standalone ? 'standalone' : '';
    return (
      <>
        <div
          id="payment-form"
          data-unique-id={this.props.uniqueid}
          className={className}
          ref={(ref) => {
            // TODO FixTSignore
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.braintreeContainer = ref;
          }}
          data-text={this.state.waitingText}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  fullLocale: LocaleSelector(state),
});

const companyInfoQuery = gql`
  query paymentWidget_braintreeToken($country: String) {
    paymentContact {
      id
      braintreeToken(country: $country)
    }
  }
`;
const WidgetWrapper = compose(
  graphql(companyInfoQuery, {
    options: (props: Record<string, any>) => {
      const { country } = props;
      return {
        fetchPolicy: 'network-only',
        variables: {
          country,
        },
      };
    },
    props: ({ data: { loading, paymentContact, error } }: Record<string, any>) => {
      if (loading) {
        return;
      }
      if (error) {
        console.error(error);
        return {
          companyInfoError: error,
        };
      }

      return {
        companyInfoError: error,
        authorizationToken: paymentContact.braintreeToken,
      } as any;
    },
  }),
  connect(mapStateToProps, {}),
)(Widget);

type PaymentWidgetProps = {
  onCreate: (instance: any) => void;
  uniqueid: any;
  standalone?: boolean;
  braintreeVaultManager?: boolean;
  hideTitle?: boolean;
} & Pick<WidgetProps, 'braintreeThreeDSecureAmount' | 'country'> &
  WithPricingPlanAndCalculatedPlanProps;

const PaymentWidget = ({
  braintreeThreeDSecureAmount,
  onCreate,
  uniqueid,
  country,
  standalone,
  braintreeVaultManager,
  hideTitle,
  loading,
  pricingPlan,
  calculatedPlan,
}: PaymentWidgetProps) => {
  const { params } = useRouteMatch();

  const [load, setLoad] = useState(false);

  useEffect(() => {
    setLoad(!loading);
  }, [loading]);

  return (
    <>
      {!hideTitle && (
        <AccTitle type="h4" mb={20}>
          {t('Payment Information')}
        </AccTitle>
      )}
      <div>
        <Suspense fallback={<div />}>
          {load && (
            <WidgetWrapper
              braintreeThreeDSecureAmount={braintreeThreeDSecureAmount}
              onCreate={onCreate}
              uniqueid={uniqueid}
              country={country}
              standalone={standalone}
              braintreeVaultManager={braintreeVaultManager}
              billingCycle={params?.billingCycle}
              pricingPlan={pricingPlan}
              calculatedPlan={calculatedPlan}
            />
          )}
        </Suspense>
      </div>
    </>
  );
};

export default withPricingPlanAndCalculatedPlan(PaymentWidget);
