import { TableSettingOption } from '../types';

export const filterSelectedOptions = (columns: string[]) => (option: TableSettingOption) => {
  return option?.hidden || option?.disabled || columns?.some((id) => id === option?.id);
};

export const normalizeOptions = (options: TableSettingOption[]): TableSettingOption[] => {
  const stack = [...options];
  let item: TableSettingOption | undefined = stack.shift();
  const items: TableSettingOption[] = item ? [item] : [];
  while (item) {
    items.push({ ...item, children: undefined });
    if (item.children?.length) {
      stack.unshift(...item.children);
    }
    item = stack.shift();
  }
  return items;
};
