import { useLocation } from 'react-router-dom';
import { Grid } from '@mantine/core';
import { RoutePath } from 'Constants/Routes';
import reusableStyles from 'css/reusable-styles.module.scss';
import ExternalAccessTables from './components/ExternalAccessTables';
import MultiAccountAllowAccess from './components/MultiAccountAllowAccess/MultiAccountAllowAccess';
import './users-table.scss';

const ExternalAccess = () => {
  const location = useLocation();
  const tableView = location?.pathname?.startsWith(RoutePath.ACCOUNT_EXTERNAL_ACCESS);
  return (
    <>
      {tableView ? (
        <ExternalAccessTables />
      ) : (
        <div className={reusableStyles.paperContainer}>
          <Grid>
            <Grid.Col span={{ lg: 4, md: 6, sm: 12 }}>
              <MultiAccountAllowAccess />
            </Grid.Col>
          </Grid>
        </div>
      )}
    </>
  );
};

export default ExternalAccess;
