import { useApolloClient } from '@apollo/client';
import moment from 'moment';
import { ColumnType, DataTable, QueryOrder } from 'Components/DataTable';
import FormatNumber from 'Components/FormatNumber';
import { SORTING_TYPE } from 'Constants';
import { WalletFragment, WalletsDocument, WalletsQuery } from 'Ghql';
import * as Sort from 'Types/Sort';
import { TableIDs } from 'Types/Table';
import { t } from 'Utilities/i18n';
import { ColumnIDs } from './support/ColumnIDs';

const useWalletColumns = () => {
  const walletTypes = {
    A_1: 'Payment',
    A_2: 'Commission',
    A_3: 'Signup Bonus',
  };
  const columns: ColumnType<WalletFragment, {}>[] = [
    {
      id: ColumnIDs.DATE,
      title: t('Date'),
      flex: true,
      width: 50,
      cellRenderer: ({ record }) => <span>{moment(record.createdAt).format('LLL')}</span>,
      onHeaderCell: () => ({
        ordering: {
          defaultOrder: QueryOrder.ASC,
          orderBy: 'createdAt',
          sortingKey: SORTING_TYPE.recurency,
        },
      }),
    },
    {
      id: ColumnIDs.DESCRIPTION,
      title: t('Description'),
      flex: true,
      width: 150,
      cellRenderer: ({ record }) => <span>{record.description}</span>,
      onHeaderCell: () => ({
        ordering: {
          defaultOrder: QueryOrder.ASC,
          orderBy: ColumnIDs.DESCRIPTION,
          sortingKey: SORTING_TYPE.alphabetical,
        },
      }),
    },
    {
      id: ColumnIDs.TYPE,
      title: t('Type'),
      flex: true,
      width: 100,
      cellRenderer: ({ record }) => (
        <span>{walletTypes[record.walletType as keyof typeof walletTypes] || 'Unknown'}</span>
      ),
      onHeaderCell: () => ({
        ordering: {
          defaultOrder: QueryOrder.ASC,
          orderBy: 'walletType',
          sortingKey: SORTING_TYPE.alphabetical,
        },
      }),
    },
    {
      id: ColumnIDs.AMOUNT,
      title: t('Amount'),
      flex: true,
      width: 100,
      cellRenderer: ({ record }) => (
        <span className="text-right">
          <FormatNumber currency={record.currency || 'USD'}>{record.amount}</FormatNumber>
        </span>
      ),
      onHeaderCell: () => ({
        ordering: {
          defaultOrder: QueryOrder.ASC,
          orderBy: 'currency',
          sortingKey: SORTING_TYPE.numerical,
        },
      }),
    },
  ];
  return columns;
};

const useFetchWalletsData = () => {
  const client = useApolloClient();
  return async () => {
    return client
      .query<WalletsQuery>({
        query: WalletsDocument,
      })
      .then((e) => {
        const data = e.data.user?.organization?.affiliate?.wallet ?? [];
        return { data, length: data.length };
      });
  };
};

const WalletsTable = (): JSX.Element => {
  const columns = useWalletColumns();
  const fetchWalletsData = useFetchWalletsData();

  return (
    <DataTable
      tableId={TableIDs.WALLET}
      columns={columns}
      fetchData={fetchWalletsData}
      defaultOrdering={{
        order: QueryOrder.DESC,
        orderBy: 'createdAt',
      }}
      offlineFilter={{
        tableName: TableIDs.WALLET,
        mappings: {
          [ColumnIDs.DATE]: 'createdAt',
          [ColumnIDs.DESCRIPTION]: 'description',
        },
        sortTypes: {
          [ColumnIDs.DATE]: Sort.DATE,
          [ColumnIDs.DESCRIPTION]: Sort.NATURAL,
          [ColumnIDs.TYPE]: Sort.NATURAL,
          [ColumnIDs.AMOUNT]: Sort.NATURAL,
        },
        skipAll: true,
      }}
      borderless
      pagination={false}
    />
  );
};

export default WalletsTable;
