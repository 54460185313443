import { Box, Tabs, Tooltip } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import { SerpFeatureNode } from 'Components/AccuTable/CellRenderer/serp';
import { Flag } from 'Components/Flag';
import Loader from 'Components/Loader';
import { KeywordHistoryChart } from 'Components/Modal/Content/KeywordHistory/ChartContainer';
import { HistoryDataTable } from 'Components/Modal/Content/KeywordHistory/Table/HistoryDataTable';
import { GoogleTrendsChart } from 'Components/Modal/Content/KeywordInfo/components/GoogleTrendsChart';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import RankOptions from 'Components/Table/TableRow/RankOptions';
import { KeywordInfoQuery } from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import { t } from 'Utilities/i18n';
import { TopCompetitorsChart } from './components/TopCompetitorsChart';
import { useKeywordDetail } from './useKeywordDetail';
import './keyword-info.scss';

interface RanksHistoryTabProps {
  keyword: NonNullable<KeywordInfoQuery['keywords']>['keyword'];
  keywordId: string;
  scrollElement: HTMLElement;
}

const RanksHistoryTab = ({ keyword, keywordId }: RanksHistoryTabProps) => {
  return (
    <>
      <div className="keyword-info-chart">
        <Tabs keepMounted={false} defaultValue="static">
          <Tabs.List>
            <Tabs.Tab value="static">{t('Competitor Ranks')}</Tabs.Tab>
            <Tabs.Tab value="dynamic">{t('Dynamic Top 10 Competitors Ranks')}</Tabs.Tab>
            <Tabs.Tab value="trends">{t('Google Trends')}</Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="static" pt="xs">
            <KeywordHistoryChart keywordId={keywordId} domain={keyword?.domain?.domain} />
          </Tabs.Panel>
          <Tabs.Panel value="dynamic" pt="xs">
            <TopCompetitorsChart keywordId={keywordId} />
          </Tabs.Panel>

          <Tabs.Panel value="trends" pt="xs">
            <GoogleTrendsChart keywordId={keywordId} />
          </Tabs.Panel>
        </Tabs>
      </div>
      <div className="keyword-info-table">
        <HistoryDataTable keywordId={keywordId} />
      </div>
    </>
  );
};

export enum MenuItemIds {
  TAB_RANKS_HISTORY = 'ranks_history',
  TAB_SERP_FEATURES = 'serp_features',
}

type MenuItemId = `${MenuItemIds}`;

export interface KeywordInfoProps {
  keywordId: string;
  domainId: string;
  initialTab?: MenuItemId;
  selectedSerp?: string;
  serpFeaturesData?: SerpFeatureNode[];
  scrollElement: HTMLElement;
  searchDate?: string;
  isKeyDis?: boolean;
  rankId?: string;
}

const KeywordInfo = (props: KeywordInfoProps) => {
  const { hideModal } = useModal();

  const { keywordId, scrollElement, isKeyDis = false } = props;

  const { data: keywordData, loading: keywordDataLoading } = useKeywordDetail(keywordId, isKeyDis);

  const Content = () => {
    if (keywordDataLoading) {
      return (
        <Loader
          style={{
            height: '250px',
          }}
          noBackdrop={true}
          loadingText={t('Loading…')}
        />
      );
    }
    const keyword = keywordData?.keywords?.keyword;

    return (
      <RanksHistoryTab keyword={keyword} keywordId={keywordId} scrollElement={scrollElement} />
    );
  };

  const renderModalHeader = () => {
    if (keywordDataLoading) {
      return (
        <div className="modal-header">
          <div className="nav-header">
            <div className="keyword-title">
              <span className="keyword-title-text">{t('Loading…')}</span>
            </div>
          </div>
        </div>
      );
    }

    const keyword = keywordData?.keywords?.keyword;
    const flagIconTooltip = `${keyword?.location ? `${keyword?.location}, ` : ''}${
      keyword?.countrylocale?.region
    }`;

    return (
      <div className="modal-header">
        <div className="nav-header">
          <div className="keyword-title">
            <span className="keyword-title-text">{keyword?.keyword}</span>
            <Box mr="sm">
              <RankOptions keywordData={keyword || {}} />
            </Box>
            <Tooltip label={flagIconTooltip} withArrow arrowSize={6}>
              <Flag
                size="sm"
                id="country"
                ml={4}
                country={keyword?.countrylocale?.countryCode}
                fadedColors
              />
            </Tooltip>
          </div>
        </div>
        <div className={'close-modal-btn'}>
          <AccActionIcon ml={4} onClick={hideModal} color={'gray'} size="md">
            <IconX stroke={2} className="close-icon" />
          </AccActionIcon>
        </div>
      </div>
    );
  };

  return (
    <ModalBorder className={'keyword-info'} onClose={hideModal} header={renderModalHeader()}>
      <Content />
    </ModalBorder>
  );
};

export default KeywordInfo;
