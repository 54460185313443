import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useMantineTheme } from '@mantine/core';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import { AccFastIcon } from 'Components/AccFastIcon';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import linkWithFilters from 'Components/Filters/linkWithFilters';
import { DomainsFilter, FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import { KEYWORDS_FILTER_SET } from 'Types/FilterSet';
import { getFolderPathByDepth } from 'Utilities/generateSelectId';
import KeydisIcon from 'icons/menu/compass-keydis.svg';
import CompetitorsIcon from 'icons/menu/competitors.svg';
import KeywordsIcon from 'icons/menu/keywords.svg';
import OverviewIcon from 'icons/menu/overview.svg';
import styles from '../filters.module.scss';

export const KEYWORDS_PAGE = 'keywords';
export const OVERVIEW_PAGE = 'overview';

// eslint-disable-next-line import/no-unused-modules
export const COMPETITORS_PAGE = 'competitors';

// eslint-disable-next-line import/no-unused-modules
export const KEYWORD_DISCOVERY_PAGE = 'keyword_discovery';

type Pages = {
  path: string;
  icon: string;
};

type PageTypes =
  | typeof KEYWORDS_PAGE
  | typeof OVERVIEW_PAGE
  | typeof COMPETITORS_PAGE
  | typeof KEYWORD_DISCOVERY_PAGE;

const PAGES_WITH_PATH_AND_ICON: Record<PageTypes, Pages> = {
  [KEYWORDS_PAGE]: { path: '/keywords/list', icon: KeywordsIcon },
  [OVERVIEW_PAGE]: { path: '/keywords/overview', icon: OverviewIcon },
  [COMPETITORS_PAGE]: { path: '/keywords/competitors', icon: CompetitorsIcon },
  [KEYWORD_DISCOVERY_PAGE]: { path: '/keywords/keyword_discovery', icon: KeydisIcon },
};

const getFolderOrTagFilter = ({ isFolder, folderOrTag }) => {
  if (isFolder) {
    return {
      attribute: FilterAttribute.FOLDERS,
      type: FilterValueType.FOLDER,
      comparison: FilterComparison.FOLDER_OR_SUBFOLDER,
      value: [folderOrTag],
    };
  }
  return {
    attribute: FilterAttribute.TAGS,
    type: FilterValueType.ARRAY,
    comparison: FilterComparison.ANY,
    value: [folderOrTag],
  };
};

const linkToPath = (
  isFolder: boolean,
  folderOrTag: string,
  path: string,
  domainsFilter?: DomainsFilter,
) => {
  const filter = getFolderOrTagFilter({ isFolder, folderOrTag });
  return linkWithFilters({
    to: path,
    newFilters: [filter, ...(domainsFilter ? [domainsFilter] : [])],
    filterSet: KEYWORDS_FILTER_SET,
    removeFilters: [FilterAttribute.FOLDERS, FilterAttribute.TAGS], // remove folder and tag filter to prevent filter duplication
  });
};

export const useFolderOrTagFilter = ({ isFolder, folderOrTag }) => {
  return useMemo(() => getFolderOrTagFilter({ isFolder, folderOrTag }), [isFolder, folderOrTag]);
};

export const useFolderLink = ({
  isFolder,
  path = '',
  pageToGoTo,
  name,
  depth,
  domainId,
}: {
  isFolder: boolean;
  path: string;
  pageToGoTo: string;
  name: string;
  depth?: number;
  domainId?: string;
}) => {
  const folderPath = getFolderPathByDepth(path, depth);
  const domainsFilter: DomainsFilter | undefined = domainId
    ? {
        attribute: FilterAttribute.DOMAINS,
        type: FilterValueType.LIST,
        comparison: FilterComparison.CONTAINS,
        value: [domainId],
      }
    : undefined;

  return useMemo(
    () =>
      linkToPath(
        isFolder,
        isFolder ? folderPath : name,
        PAGES_WITH_PATH_AND_ICON[pageToGoTo].path,
        domainsFilter,
      ),
    [isFolder, pageToGoTo, name, path],
  );
};

export const LinkIconWithFolderFilter = React.memo(
  ({
    path,
    pageToGoTo,
    isFolder,
    name,
    tooltipText,
    depth,
    domainId,
  }: {
    path: string;
    pageToGoTo: keyof typeof PAGES_WITH_PATH_AND_ICON;
    isFolder: boolean;
    name: string;
    tooltipText?: string;
    depth?: number;
    domainId?: string;
  }) => {
    const link = useFolderLink({
      isFolder,
      path,
      pageToGoTo,
      name,
      depth,
      domainId,
    });

    const theme = useMantineTheme();

    return (
      <Link to={link} className={styles.link}>
        <AccTooltip tooltip={tooltipText}>
          <AccActionIcon variant="subtle" tabIndex={-1}>
            <AccFastIcon
              src={PAGES_WITH_PATH_AND_ICON[pageToGoTo].icon}
              color={theme.colors.blue[4]}
            />
          </AccActionIcon>
        </AccTooltip>
      </Link>
    );
  },
);
LinkIconWithFolderFilter.displayName = 'LinkIconWithFolderFilter';
