import { Grid } from '@mantine/core';
import { PublicReportSettingsFragment, useGetAllPublicKpisQuery } from 'Ghql';
import AboveTheFoldKpiBox from 'Pages/Keywords/Overview/components/KpiBar/Boxes/AboveTheFold';
import AiShareOfVoiceKpiBox from 'Pages/Keywords/Overview/components/KpiBar/Boxes/AiShareOfVoiceBox';
import AiTrafficValueKpiBox from 'Pages/Keywords/Overview/components/KpiBar/Boxes/AiTrafficValue';
import AverageRankKpiBox from 'Pages/Keywords/Overview/components/KpiBar/Boxes/AverageRank';
import DynamicCtrKpiBox from 'Pages/Keywords/Overview/components/KpiBar/Boxes/DynamicCtrKpiBox';
import KeywordsCountKpiBox from 'Pages/Keywords/Overview/components/KpiBar/Boxes/KeywordsCount';
import ShareOfVoiceKpiBox from 'Pages/Keywords/Overview/components/KpiBar/Boxes/ShareOfVoiceKpiBox';
import TrafficValueKpiBox from 'Pages/Keywords/Overview/components/KpiBar/Boxes/TrafficValue';
import { FilterAttribute, FilterBase } from 'Types/Filter';
import {
  FirstPageKeywordsKpiBox,
  MovedDownInRankingsKpiBox,
  MovedUpInRankingsKpiBox,
  TopOneKeywordsKpiBox,
  TopThreeKeywordsKpiBox,
} from './Boxes';

const COLUMN_SPAN = { base: 30, xs: 20, md: 15, lg: 12 };

export const PublicKpiBar = ({
  filters,
  domainSlug = '',
  viewkey = '',
  publicReportSettings,
}: {
  filters: FilterBase[];
  domainSlug: string | undefined;
  viewkey: string | undefined;
  publicReportSettings: PublicReportSettingsFragment | undefined;
}) => {
  const hasPeriodFilter = filters.some((filter) => filter.attribute === FilterAttribute.PERIOD);

  const {
    useAiShareOfVoice,
    includeKpiAboveTheFold,
    includeKpiAvgCtr,
    includeKpiAvgRank,
    includeKpiFirstPage,
    includeKpiKeywords,
    includeKpiMovedDown,
    includeKpiMovedUp,
    includeKpiSov,
    includeKpiTop1,
    includeKpiTop3,
    includeKpiTrafficValue,
  } = publicReportSettings || {};

  const showSOVasAI = useAiShareOfVoice ?? true;

  const { data, loading } = useGetAllPublicKpisQuery({
    variables: { filters, viewkey, domainSlug },
    skip: !domainSlug || !viewkey || !hasPeriodFilter,
  });

  const kpiBarQueryProps = { displayCurrency: data?.kpis?.displayCurrency || 'USD', data, loading };

  return (
    <Grid columns={60}>
      {includeKpiTop1 && (
        <Grid.Col span={COLUMN_SPAN}>
          <TopOneKeywordsKpiBox
            kpiBarQueryProps={kpiBarQueryProps}
            draggable={false}
            size="large"
          />
        </Grid.Col>
      )}
      {includeKpiTop3 && (
        <Grid.Col span={COLUMN_SPAN}>
          <TopThreeKeywordsKpiBox
            kpiBarQueryProps={kpiBarQueryProps}
            draggable={false}
            size="large"
          />
        </Grid.Col>
      )}
      {includeKpiFirstPage && (
        <Grid.Col span={COLUMN_SPAN}>
          <FirstPageKeywordsKpiBox
            kpiBarQueryProps={kpiBarQueryProps}
            draggable={false}
            size="large"
          />
        </Grid.Col>
      )}
      {includeKpiMovedUp && (
        <Grid.Col span={COLUMN_SPAN}>
          <MovedUpInRankingsKpiBox
            kpiBarQueryProps={kpiBarQueryProps}
            draggable={false}
            size="large"
          />
        </Grid.Col>
      )}
      {includeKpiMovedDown && (
        <Grid.Col span={COLUMN_SPAN}>
          <MovedDownInRankingsKpiBox
            kpiBarQueryProps={kpiBarQueryProps}
            draggable={false}
            size="large"
          />
        </Grid.Col>
      )}
      {includeKpiKeywords && (
        <Grid.Col span={COLUMN_SPAN}>
          <KeywordsCountKpiBox
            kpiBarQueryProps={kpiBarQueryProps}
            draggable={false}
            size="large"
            hideProgressbar
          />
        </Grid.Col>
      )}
      {includeKpiAvgRank && (
        <Grid.Col span={COLUMN_SPAN}>
          <AverageRankKpiBox kpiBarQueryProps={kpiBarQueryProps} draggable={false} size="large" />
        </Grid.Col>
      )}
      {includeKpiAvgCtr && (
        <Grid.Col span={COLUMN_SPAN}>
          <DynamicCtrKpiBox kpiBarQueryProps={kpiBarQueryProps} draggable={false} size="large" />
        </Grid.Col>
      )}
      {includeKpiSov && (
        <Grid.Col span={COLUMN_SPAN}>
          {showSOVasAI ? (
            <AiShareOfVoiceKpiBox
              kpiBarQueryProps={kpiBarQueryProps}
              draggable={false}
              size="large"
            />
          ) : (
            <ShareOfVoiceKpiBox
              kpiBarQueryProps={kpiBarQueryProps}
              draggable={false}
              size="large"
            />
          )}
        </Grid.Col>
      )}
      {includeKpiTrafficValue && (
        <Grid.Col span={COLUMN_SPAN}>
          {showSOVasAI ? (
            <AiTrafficValueKpiBox
              kpiBarQueryProps={kpiBarQueryProps}
              draggable={false}
              size="large"
            />
          ) : (
            <TrafficValueKpiBox
              kpiBarQueryProps={kpiBarQueryProps}
              draggable={false}
              size="large"
            />
          )}
        </Grid.Col>
      )}
      {includeKpiAboveTheFold && (
        <Grid.Col span={COLUMN_SPAN}>
          <AboveTheFoldKpiBox kpiBarQueryProps={kpiBarQueryProps} draggable={false} size="large" />
        </Grid.Col>
      )}
    </Grid>
  );
};
