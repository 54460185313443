import { useMemo } from 'react';
import { useField } from 'react-final-form';
import { Box } from '@mantine/core';
import { IconChecks, IconRotateClockwise } from '@tabler/icons-react';
import uniq from 'lodash/uniq';
import AccButton from 'Components/AccButton/AccButton';
import { Form } from 'Components/Fields';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import AccText from 'Components/Text/AccText';
import { t } from 'Utilities/i18n';
import { GroupSettingsItem, TableSettingForm } from '../support/types';
import { TableSettingsList } from './components/TableSettingsList';
import { getDefaultItemsForGroupSettings } from './support/helpers';
import { TableSettingGroup } from './support/types';
import styles from './components/tableSettings.module.scss';

type NextTableSettingsFormProps = {
  groupConfigs: GroupSettingsItem[] | null;
  columnConfigs: string[] | null;
  groups: TableSettingGroup[] | undefined;
  onSave: (form: TableSettingForm) => Promise<void>;
  onClose: () => void;
};

const ResetDefaultSettings = ({
  groups: groupConfigs,
}: {
  groups: TableSettingGroup[] | undefined;
}) => {
  const groupsField = useField('groups');
  const columnsField = useField('columns');

  const resetDefault = () => {
    const { columns, groups } = getDefaultItemsForGroupSettings(groupConfigs) || {};
    groupsField.input.onChange(groups);
    columnsField.input.onChange(columns);
  };

  return (
    <AccButton
      variant="tertiary"
      leftSection={<IconRotateClockwise size={20} />}
      onClick={resetDefault}
    >
      <div className="keywords-count">{t('Reset to default')}</div>
    </AccButton>
  );
};

const IncludeAllSettings = ({ items }: { items: TableSettingGroup[] }) => {
  const groupsField = useField<GroupSettingsItem[]>('groups');
  const columnsField = useField<string[]>('columns');

  const includeAll = () => {
    groupsField.input.onChange(
      groupsField.input.value.map((field) => ({ ...field, enabled: true })),
    );

    // Map over all items and their children to get a complete list of all item ids
    const allItems = items.flatMap((item) =>
      item.options
        .filter((option) => !option.hidden)
        .flatMap((option) => {
          const childIds = option.children?.map((child) => child.id) || [];
          return [option.id, ...childIds];
        }),
    );
    columnsField.input.onChange(allItems);
  };

  return (
    <AccButton variant="tertiary" leftSection={<IconChecks size={20} />} onClick={includeAll}>
      <div className="keywords-count">{t('Include all')}</div>
    </AccButton>
  );
};

export const NextTableSettingsForm = (props: NextTableSettingsFormProps) => {
  const initialValues: TableSettingForm = useMemo(
    () => ({
      groups: props.groupConfigs ?? [],
      columns: uniq(props.columnConfigs ?? []),
    }),
    [props.groupConfigs, props.columnConfigs],
  );

  return (
    <Form onSubmit={props.onSave} initialValues={initialValues}>
      {({ form }) => {
        const items = (form
          .getState()
          .values.groups?.map((e) => props.groups?.find((el) => el.id === e.id)) ||
          []) as TableSettingGroup[];
        return (
          <>
            <div className={styles.outerContainer}>
              <AccText className={styles.description} variant="label">
                {t('(Drag to reorder column groups)')}
              </AccText>
              <TableSettingsList items={items} />
            </div>
            <ModalFooter
              primaryButtonSlot={
                <AccButton variant="primary" type="submit">
                  {t('Update')}
                </AccButton>
              }
              secondaryButtonSlot={
                <AccButton variant="tertiary" onClick={props.onClose}>
                  {t('Cancel')}
                </AccButton>
              }
              textSlot={
                <Box>
                  <ResetDefaultSettings groups={props.groups} />
                  <IncludeAllSettings items={items} />
                </Box>
              }
            />
          </>
        );
      }}
    </Form>
  );
};
