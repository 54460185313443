import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { FolderMetric } from 'Ghql';
import { t } from 'Utilities/i18n';
import styles from '../domainsComparison.module.scss';

const ControlOption = ({ name, shortName }) => {
  return (
    <AccTooltip tooltip={name} placement="top">
      <span>
        <span className={styles.shortName}>{shortName}</span>
        <span className={styles.fullName}>{name}</span>
      </span>
    </AccTooltip>
  );
};

export const getMetricControlOptions = () => {
  return [
    {
      value: FolderMetric.EstimatedVisits,
      label: <ControlOption name={t('AI Share of Voice')} shortName={t('AI SoV')} />,
      chartName: t('AI Share of Voice'),
    },
    {
      value: FolderMetric.ShareOfVoice,
      label: <ControlOption name={t('Share of Voice')} shortName={t('SoV')} />,
    },
    {
      value: FolderMetric.AvgRank,
      label: <ControlOption name={t('Avg. rank')} shortName={t('AR')} />,
      chartName: t('Avg. rank'),
    },
    {
      value: FolderMetric.AvgBaseRank,
      label: <ControlOption name={t('Avg. base rank')} shortName={t('ABR')} />,
      chartName: t('Avg. base rank'),
    },
    {
      value: FolderMetric.AvgCtr,
      label: <ControlOption name={t('CTR')} shortName={t('CTR')} />,
      chartName: t('CTR'),
    },
    {
      value: FolderMetric.SearchVolume,
      label: <ControlOption name={t('Search volume')} shortName={t('SV')} />,
      chartName: t('Search volume'),
    },
    {
      value: FolderMetric.AiTrafficValue,
      label: <ControlOption name={t('AI Traffic value')} shortName={t('AI TV')} />,
      chartName: t('AI Traffic value'),
    },
    {
      value: FolderMetric.TrafficValue,
      label: <ControlOption name={t('Traffic value')} shortName={t('TV')} />,
      chartName: t('Traffic value'),
    },
    {
      value: FolderMetric.AboveTheFold,
      label: <ControlOption name={t('Above the fold')} shortName={t('AtF')} />,
      chartName: t('Above the fold'),
    },
    {
      value: FolderMetric.AvgPixelsFromTop,
      label: <ControlOption name={t('Avg. pixels from top')} shortName={t('PFT')} />,
      chartName: t('Avg. pixels from top'),
    },
  ];
};
