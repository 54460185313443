import { Link } from 'react-router-dom';
import cn from 'classnames';
import Cell from 'Components/AccuTable/CellRenderer/HelperComponents/AccuCell';
import { Flag } from 'Components/Flag';
import FormatNumber from 'Components/FormatNumber/new';
import { useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import { FilterAttribute } from 'Types/Filter';
import { FilterTrackingKey, trackFilterEventWithNavigation } from 'Utilities/Analytics/mixpanel';
import { getKeywordsFilterLink } from '../support/helpers';
import styles from '../landing-table.module.scss';

type Props = {
  record?: any;
};

export const UntrackedKeywordsCell: React.FC<React.PropsWithChildren<Props>> = ({
  record,
}: Props) => {
  const { domainInfo } = useQueryDomainInfo();

  if (!domainInfo?.domain) {
    return null;
  }
  const link = getKeywordsFilterLink('', record?.path ?? '', true); // Domain is in the path here

  const handleTrackFilterEvent = () => {
    trackFilterEventWithNavigation(
      link,
      FilterTrackingKey.LandingPagesToDiscovery,
      FilterAttribute.HIGHEST_RANKING_PAGE,
    );
  };

  return (
    <Cell rightAligned={true}>
      <Link
        to={link}
        className={cn('table-link', styles.untrackedContainer)}
        onClick={handleTrackFilterEvent}
      >
        <div>{record?.country && <Flag size="sm" country={record.country} fadedColors />}</div>

        <FormatNumber value={record?.untrackedKeywords} className={styles.untrackedKeywords} />
      </Link>
    </Cell>
  );
};
