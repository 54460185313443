import React from 'react';
import cn from 'classnames';
import { useRowContext } from 'Components/DataTable/table-core';
import SearchVolumeContent from 'Pages/Keywords/Table/Chart/SearchVolume';
import { tableClassName } from '../../../helpers';

export const SearchVolumeDetailsRow = React.memo(({ id, useAi }: { id: any; useAi?: boolean }) => {
  const rowContext = useRowContext();
  return (
    <tr
      className={cn(
        tableClassName('expanded-row'),
        tableClassName('expanded-row-level-1'),
        'row-detail',
      )}
      style={{ height: '335px' }}
    >
      <td className="row-detail-container">
        <SearchVolumeContent
          keywordId={id}
          height={250}
          useAi={useAi}
          toggleExpand={rowContext.toggleExpandRows}
        />
      </td>
    </tr>
  );
});
SearchVolumeDetailsRow.displayName = 'SearchVolumeDetailsRow';
