import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Flex } from '@mantine/core';
import { IconPlugConnected } from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import ConnectedLabel from 'Components/ConnectedLabel';
import AccTitle from 'Components/Title/AccTitle';
import { getDisabledDemoText } from 'Constants/messages';
import { useModal } from 'Hooks/base/useModal';
import { useDomainId, useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import toast from 'Hooks/useToast';
import { removeGSCAccountQuery } from 'Pages/DomainSettings/support/queries';
import { t } from 'Utilities/i18n';
import styles from './menu.module.scss';

const ConnectionStatusLabel = ({ isConnected }) => {
  const connTxt = t('Connected to Google Search Console');
  const notConnTxt = t('This domain is not connected to Google Search Console');
  return (
    <ConnectedLabel isConnected={isConnected} description={isConnected ? connTxt : notConnTxt} />
  );
};

const ConnectionMetaData = ({ gscSiteId, description }) => (
  <div className={styles.meta}>
    <div key="description">
      <h6>{t('Google OAuth Connection')}:</h6> <span>{description}</span>
    </div>
    {gscSiteId && (
      <div key="siteID">
        <h6>{t('Website')}:</h6>
        <span>{gscSiteId}</span>
      </div>
    )}
  </div>
);

export const ConnectToGSC = (): JSX.Element | null => {
  const domainId = useDomainId();
  const { domainInfo, refetch: refreshDomainInfo, isDemoDomain } = useQueryDomainInfo();
  const disabledDemoText = getDisabledDemoText();
  const { showModal, hideModal } = useModal();
  const [isConnected, setIsConnected] = useState<boolean>();
  const [removeGSCAccount] = useMutation(removeGSCAccountQuery);

  useEffect(() => {
    if (domainInfo) {
      setIsConnected(Boolean(domainInfo?.isGscConnected));
    }
  }, [domainInfo]);

  const handleConnectToGSC = () =>
    showModal({
      modalType: 'ConnectToGSC',
      modalTheme: 'light',
      modalProps: {
        domainId,
        refresh: refreshDomainInfo,
      },
    });

  const handleDisconnectFromGSC = () => {
    removeGSCAccount({ variables: { input: { domainId } } }).then(
      () => {
        toast.success(t('Account removed'));
        refreshDomainInfo();
        hideModal();
      },
      () => {
        toast.error(t('Failed to remove account'));
      },
    );
  };

  return (
    <Flex direction="column" rowGap="md">
      <AccTitle type="h3">{t('Search Console')}</AccTitle>
      {<ConnectionStatusLabel isConnected={isConnected} />}
      {isConnected && (
        <ConnectionMetaData
          description={domainInfo?.googleOauthConnectionGsc?.description}
          gscSiteId={domainInfo?.gscSiteId}
        />
      )}
      {!isConnected ? (
        <AccButton
          onClick={handleConnectToGSC}
          leftSection={<IconPlugConnected size={18} />}
          variant="secondary"
          disabled={isDemoDomain}
          tooltip={isDemoDomain ? disabledDemoText : undefined}
        >
          {t('Connect to Search Console')}
        </AccButton>
      ) : (
        <AccButton
          onClick={handleDisconnectFromGSC}
          leftSection={<IconPlugConnected size={18} />}
          variant="secondary"
          disabled={isDemoDomain}
          tooltip={isDemoDomain ? disabledDemoText : undefined}
        >
          {t('Disconnect from GSC')}
        </AccButton>
      )}
    </Flex>
  );
};
