import { useState } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { submit } from 'redux-form';
import AccButton from 'Components/AccButton/AccButton';
import LoadingSpinner from 'Components/LoadingSpinner';
import AccTitle from 'Components/Title/AccTitle';
import { useUpdateCompanyInfoMutation } from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import toast from 'Hooks/useToast';
import { t } from 'Utilities/i18n';
import reusableStyles from 'css/reusable-styles.module.scss';
import CompanyInfoWidget from '../CompanyInfoWidget/index';
import styles from './update-payment-info.module.scss';

const UpdatePaymentInfo = () => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formValid, setFormValid] = useState(true);
  const { showModal } = useModal();
  const dispatch = useDispatch();
  const [updateCompanyInfo] = useUpdateCompanyInfoMutation();

  const getFormValidStatus = (valid: boolean) => {
    setFormValid(valid);
  };

  const handleSubmit = async ({
    companyName,
    street,
    zipcode,
    city,
    state,
    country: { countryCode },
    vatPrefix,
    vatNumber,
    emailInvoiceTo,
    poNumber,
  }) => {
    setFormSubmitting(true);
    updateCompanyInfo({
      variables: {
        companyName,
        street,
        zipcode,
        city,
        state,
        countryIso: countryCode,
        vatPrefix: vatPrefix ? vatPrefix.vatCode : '',
        vatNumber: vatNumber ? vatNumber.trim() : '',
        emailInvoiceTo,
        poNumber,
      },
    })
      .then(({ data, errors }) => {
        const unexpectedErrorMessage = t(
          'We were unable to save your company information. Please contact support.',
        );
        if (!isEmpty(errors)) {
          toast.error(unexpectedErrorMessage);
          return;
        }
        if (!data?.setPaymentContact?.success) {
          showModal({
            modalType: 'GeneralError',
            modalProps: {
              title: t('We could not update your company information'),
              errorType: data?.setPaymentContact?.error || unexpectedErrorMessage,
              link: '/billing/',
            },
          });
          return;
        }
        toast.success(t('Company details updated successfully'));
      })
      .finally(() => {
        setFormSubmitting(false);
      });
  };

  return (
    <div className={cn(styles.container, reusableStyles.paperContainer)}>
      <AccTitle type="h4" mb={18}>
        {t('Company Details')}
      </AccTitle>
      <form>
        <CompanyInfoWidget onSubmit={handleSubmit} setFormValidStatus={getFormValidStatus} />
        <div className="text-right confirmation-button-wrapper">
          {formSubmitting && <LoadingSpinner />}
          <AccButton
            disabled={formSubmitting || !formValid}
            variant="primary"
            onClick={() => dispatch(submit('CompanyInfoForm'))}
          >
            {t('Update')}
          </AccButton>
        </div>
      </form>
    </div>
  );
};

export default UpdatePaymentInfo;
