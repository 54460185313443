import type { FiltersEditorProps } from 'Components/HOC/withFiltersEditor';
import withFiltersEditor from 'Components/HOC/withFiltersEditor';
import type { FilterBase } from 'Types/Filter';
import { EventName, FilterTrackingKey, useMixpanel } from 'Utilities/Analytics/mixpanel';
import AddFilterComponent from './component';

type Props = FiltersEditorProps & { hasPrimaryFilterAdded?: boolean };

const AddFilter = ({ filterGroup, filterSet, filtersEditor, hasPrimaryFilterAdded }: Props) => {
  const trackEvent = useMixpanel();
  const onAdd = (filter: FilterBase) => {
    filtersEditor.addFilter(filter);
    if (filter) {
      trackEvent(EventName.AddFilter, {
        Attribute: `${filter.attribute}`,
        Type: filter.type,
        Comparison: filter.comparison,
        Value: `${filter.value}`,
        'Tracking Key': FilterTrackingKey.FilterBar,
      });
    }
  };

  return (
    <AddFilterComponent
      onAdd={onAdd}
      filters={filterGroup.filters}
      filterSet={filterSet}
      hasPrimaryFilterAdded={hasPrimaryFilterAdded}
    />
  );
};

export default withFiltersEditor(AddFilter);
