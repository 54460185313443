import { AccFastIcon } from 'Components/AccFastIcon';
import ActionsCell, { ActionsCellItem } from 'Components/Table/TableRow/ActionsCell';
import { GeneratedReportFragment, ScheduledReportFragment } from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import useUserPermission from 'Hooks/useUserPermission';
import { useHistory } from 'Utilities/Router/withRouter';
import { t } from 'Utilities/i18n';
import styleVariables from 'css/base/variables.module.scss';
import IconDownload from 'icons/IconDownload.svg';
import IconEdit from 'icons/IconEdit.svg';
import { IconTrash } from 'icons/tag-cloud';

export type Report = ScheduledReportFragment | GeneratedReportFragment;

const ReportActionCell = <R extends Report = Report>({
  report,
  handleDelete,
}: {
  report: R;
  handleDelete: (report: R) => void;
}) => {
  const { showModal } = useModal();
  const { userHasWriteAccess } = useUserPermission();
  const history = useHistory();

  const deleteReport = (action: (...args: Array<any>) => any) => {
    showModal({
      modalType: 'Confirmation',
      modalProps: {
        cancelLabel: t('Cancel'),
        confirmLabel: t('Delete'),
        lockDuration: 0,
        title: t('Delete Scheduled Report?'),
        description: t('This action is irreversible'),
        action,
      },
    });
  };

  const actions: ActionsCellItem[] = [];

  const { snorlax4 } = styleVariables;

  if ('url' in report) {
    actions.push({
      className: 'dropdown-item-no-icon',
      label: t('Download'),
      fastIcon: <AccFastIcon src={IconDownload} size={20} color={snorlax4} />,
      onSelect: () => {
        // TODO FixTSignore
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.open(report.url, '_blank').focus();
      },
    });
  }
  if (userHasWriteAccess) {
    if ('schedule' in report) {
      actions.push({
        label: t('Edit'),
        fastIcon: <AccFastIcon src={IconEdit} size={20} color={snorlax4} />,
        onSelect: () => history.push(`/reports/schedule/edit/${report.id}`),
      });
    }

    actions.push({
      label: t('Delete'),
      onSelect: () => deleteReport(() => handleDelete(report)),
      fastIcon: <AccFastIcon src={IconTrash} size={20} color={snorlax4} />,
    });
  }
  return <ActionsCell shouldUpdateIndicator={report} actions={actions} />;
};

export default ReportActionCell;
