import { useMemo } from 'react';
import cn from 'classnames';
import Highcharts from 'highcharts';
import flatMap from 'lodash/flatMap';
import styles from 'Components/Chart/BubbleChart/styles.module.scss';
import { BubbleChartData } from 'Components/Chart/BubbleChart/support/types';
import { ChartButtonTheme } from 'Components/Chart/support/buttonStyleConfig';
import { DEFAULT_CHART_CONFIG, TOOLTIP_CHART_FORMATTING } from 'Components/Chart/support/constants';
import { labelsFormatter } from 'Components/Chart/support/helpers';
import { InjectedIntl } from 'Types/Intl';
import { getFaviconByDomain } from 'Utilities/favicon';
import { t } from 'Utilities/i18n';
import { BUBBLE_CHART_HEIGHT } from './constants';

export const useBubbleChartConfig = (
  items?: BubbleChartData[],
  intl?: InjectedIntl,
  setDomain?: (
    event: Highcharts.SeriesLegendItemClickEventObject,
    domain: string | undefined,
  ) => void,
): Highcharts.Options => {
  const series = flatMap(
    items?.map((e) => {
      return [
        {
          data: [e],
          linkedTo: e?.name,
          name: e?.name,
          displayName: e?.displayName,
          color: e.color,
          opacity: 0.9,
          visible: true,
          type: 'bubble' as const,
          highlighted: e.highlighted,
        },
        {
          data: [],
          id: e?.name,
          name: e?.name,
          displayName: e?.displayName,
          color: e.color,
          showInLegend: true,
          type: 'bubble' as const,
          highlighted: e.highlighted,
        },
      ];
    }),
  );

  return useMemo(
    () => ({
      chart: {
        type: 'bubble',
        height: BUBBLE_CHART_HEIGHT,
        spacingTop: 30,
        spacingBottom: 5,
        className: styles.chart,
        animation: false,
        zoomType: 'xy',
        zooming: {
          resetButton: {
            position: {
              // align: 'right', // by default
              // verticalAlign: 'top', // by default
              x: 0,
              y: -26,
            },
            //style the button similar to AccButton secondary
            theme: ChartButtonTheme,
          },
        },
      },
      tooltip: {
        ...TOOLTIP_CHART_FORMATTING,
        outside: true,
        formatter() {
          const self: any = this;
          const domain = self.key;
          const faviconSrc = getFaviconByDomain(domain, 16, true);
          return `
          <div class="${styles.tooltip}">
            <div class="${styles.tooltipTitle}">
              <img src="${faviconSrc}" alt="" />
              ${domain}
            </div>
            <table class="${styles.lastColRight}">
              <tr>
                  <td>${t('Share of Voice')}:</td>
                  <td>${intl?.formatNumber(self.point?.z)}</td>
              </tr>
              <tr>
                  <td>${t('Keywords')}:</td>
                  <td>${intl?.formatNumber(self.x)}</td>
              </tr>
              <tr>
                  <td>${t('Search volume')}:</td>
                  <td>${intl?.formatNumber(self.y)}</td>
              </tr>
            </table>
          </div>
      `;
        },
      },
      yAxis: {
        title: { text: t('Search volume') },
        labels: {
          align: 'left',
          x: 0,
          y: -3,
          formatter() {
            if (Number(this.value) < 0) {
              return '';
            }
            return labelsFormatter(this, true);
          },
        },
      },
      xAxis: {
        title: { text: t('Keywords Count'), align: 'low', margin: 10 },
        labels: { enabled: false },
        tickLength: 0,
      },
      plotOptions: {
        bubble: {
          minSize: 30,
          maxSize: 100,
          events: {
            legendItemClick(event) {
              setDomain && setDomain(event, this.userOptions.id);
            },
          },
        },
        series: {
          clip: false,
          animation: false,
          states: { inactive: { opacity: 0.9 } },
          jitter: 0,
          stacking: 'normal',
          dataLabels: {
            enabled: true,
            formatter() {
              const domain = (this as any).key;
              const faviconSrc = getFaviconByDomain(domain, 16, true);
              return `<div class="${styles.box}" style="background: ${this.color}; background-image: url('${faviconSrc}');"/>`;
            },
            useHTML: true,
          },
        },
      },
      legend: {
        useHTML: true,
        align: 'right',
        verticalAlign: 'top',
        layout: 'vertical',
        padding: 0,
        y: -10,
        labelFormatter() {
          const faviconSrc = getFaviconByDomain(this.name, 16, true);
          const color = (this as any)?.userOptions?.highlighted ? '#f89537' : '#337ab7';
          return `<div style='${
            this.visible ? '' : 'opacity: 0.5;'
          }; color: ${color};' class="bubble-chart-legend-item ${styles.item}${
            (this as any)?.userOptions?.highlighted ? ' highlighted' : ''
          }" title="${
            setDomain
              ? `${t('Change domain to ')}${
                  (this as any)?.userOptions?.displayName || this.name.slice(0, 25)
                }`
              : ''
          }">
            <img width="16" height="16" src="${faviconSrc}" alt=""/>
            ${`<span className=${styles.text}>`}${
            (this as any)?.userOptions?.displayName || this.name.slice(0, 25)
          }</span>
            ${
              setDomain
                ? `<button class="${cn(
                    styles.searchDomainButton,
                    'researchDomain',
                  )}" id="researchDomain" ><svg width="18" height="18" fill="currentColor"><path d="m9 1.5c-4.14 0-7.5 3.35-7.5 7.5 0 4.14 3.35 7.5 7.5 7.5 4.14 0 7.5-3.35 7.5-7.5 7.5e-4 -4.14-3.35-7.5-7.5-7.5zm-6 7.5c0-1.6 0.624-3.11 1.76-4.24 1.13-1.13 2.64-1.76 4.24-1.76 1.57 0 3.05 0.604 4.18 1.7l-6.36 2.12-2.12 6.36c-1.09-1.13-1.7-2.6-1.7-4.18zm5-0.998 2 2-2.99 0.998zm0.998 7c-1.57 0-3.05-0.604-4.17-1.7l6.36-2.12 2.12-6.36c1.09 1.13 1.7 2.6 1.7 4.18 0 3.32-2.68 6-6 6z" stroke-width=".75"/></svg></button>`
                : ''
            }</div>`;
        },
      },
      series,
      ...DEFAULT_CHART_CONFIG,
    }),
    [series],
  );
};
