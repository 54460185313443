export const extractNextPath = (
  location: Pick<Location, 'search'> & object = window.location,
): string | undefined => {
  let savedPath;

  if (!location?.search?.includes('next')) {
    return undefined;
  }
  try {
    savedPath = new URLSearchParams(location?.search)?.get('next')?.replace(/^\/?app/, '');
  } catch (e) {
    console.error('failed to parse location.search', location?.search);
  }

  if (savedPath?.includes('/login')) {
    return undefined;
  }
  return savedPath;
};
