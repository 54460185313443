/**
 * Get sticky offset for sticky items placed below the filterbar.
 *
 * Used for cases with a sticky actions menu: Only keidis and overview supported for now.
 *
 * If the filterbar is hidden the sticky actions menu will always be placed at top.
 */
export const getStickyTop = (
  { filtersOnly, hiddenFilterbar }: { filtersOnly: boolean; hiddenFilterbar?: boolean } = {
    filtersOnly: false,
    hiddenFilterbar: false,
  },
) => {
  //offset used to stack header underneath domain filters, which are also sticky
  const filtersPanel = document.querySelector('#filtersPanel') as HTMLDivElement | undefined;
  const filterHeight = filtersPanel?.getBoundingClientRect()?.height || 0;

  if (hiddenFilterbar) {
    return 0;
  }
  if (filtersOnly) {
    return filterHeight;
  }
  const actionsMenu = document.querySelector('#actionsMenuContainer[data-sticky="true"]') as
    | HTMLDivElement
    | undefined;
  const actionsMenuHeight = actionsMenu?.getBoundingClientRect()?.height || 0;

  return filterHeight + actionsMenuHeight;
};
