import { Group, Title, useMantineTheme } from '@mantine/core';
import AccTitle from 'Components/Title/AccTitle';
import { t, tct } from 'Utilities/i18n';
import SpeedGreenIcon from 'icons/speedometer-green.svg?inline';
import SpeedOrangeIcon from 'icons/speedometer-orange.svg?inline';
import SpeedRedIcon from 'icons/speedometer-red.svg?inline';
import { getPercentageTracked } from '../support/helpers';
import { SitemapNode } from '../support/types';

interface SectionHeaderProps {
  title: string;
  contentSlot?: React.ReactNode;
  titleTooltipSlot?: React.ReactNode;
}

export const SectionHeader = ({ contentSlot, title, titleTooltipSlot }: SectionHeaderProps) => {
  return (
    <Group justify="space-between">
      <Title order={5}>
        {title}
        {titleTooltipSlot}
      </Title>
      {contentSlot}
    </Group>
  );
};

interface CompetitorCertaintyProps {
  selectedNode: SitemapNode | null;
}

enum Certainty {
  NONE = 'none',
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
}

interface IndicatorOptions {
  [Key: string]: {
    label: string;
    icon: JSX.Element | null;
    color: string;
  };
}

export const CompetitorCertaintyIndicator = ({ selectedNode }: CompetitorCertaintyProps) => {
  const theme = useMantineTheme();
  const percentageTracked = selectedNode ? getPercentageTracked(selectedNode) * 100 : 0;
  let certainty: Certainty;
  if (selectedNode) {
    if (percentageTracked === 0) {
      certainty = Certainty.NONE;
    } else if (percentageTracked < 20) {
      certainty = Certainty.LOW;
    } else if (percentageTracked < 50) {
      certainty = Certainty.MEDIUM;
    } else {
      certainty = Certainty.HIGH;
    }
  }

  const indicatorOptions: IndicatorOptions = {
    none: {
      label: t('Zero competitor certainty'),
      icon: null,
      color: theme.colors.gray[5],
    },
    low: {
      label: t('Low competitor certainty'),
      icon: <SpeedRedIcon />,
      color: theme.colors.red[4],
    },
    medium: {
      label: t('Medium competitor certainty'),
      icon: <SpeedOrangeIcon />,
      color: theme.colors.orange[4],
    },
    high: {
      label: t('High competitor certainty'),
      icon: <SpeedGreenIcon />,
      color: theme.colors.green[4],
    },
  };

  const Speedometer = () => indicatorOptions[certainty].icon;

  const helpTooltip = tct(
    'The more keywords you track, the higher competitor certainty.[br][br]You currently track [amount] of your indexed keywords on this landing page.',
    { br: <br />, amount: percentageTracked ? `${Math.round(percentageTracked)}%` : 'none' },
  );

  const SpeedometerLabel = () => {
    return (
      <AccTitle c={indicatorOptions[certainty].color} helper={helpTooltip} type={'h6'}>
        {indicatorOptions[certainty].label}
      </AccTitle>
    );
  };

  return (
    <>
      <Group style={{ gap: 0 }}>
        <Group gap="sm">
          <Speedometer />
          <SpeedometerLabel />
        </Group>
      </Group>
    </>
  );
};
