import { Component } from 'react';
import { connect } from 'react-redux';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import compose from 'lodash/flowRight';
import { gaSetPassedState } from 'Actions/GoogleAccountsAction';
import { showModal } from 'Actions/ModalAction';
import toast from 'Hooks/useToast';
import { apolloClient } from 'Store';
import { stringifyFilters } from 'Types/Filter';
import { withRouter } from 'Utilities/Router';
import { t } from 'Utilities/i18n';
import Validator from 'Utilities/validation';
import Form from './../../ScheduledReportBuilderForm';

type Props = {
  history: Record<string, any>;
  user: Record<string, any>;
  addScheduledReport: (...args: Array<any>) => any;
  hasDriveAccount: boolean;
  showModal: (...args: Array<any>) => any;
  initialState: Record<string, any>;
  gaSetPassedState: (...args: Array<any>) => any;
};

class AddScheduledReport extends Component<Props> {
  componentWillUnmount() {
    this.props.gaSetPassedState(null);
  }

  handleSubmit = (data: any) => {
    const {
      group,
      domain,
      name,
      description,
      emailBody,
      sender,
      emailSubject,
      emails,
      filters,
      language: { value: language },
      reportType,
      schedule,
      scheduledDay,
      isGroupReport,
      template: { value: reportTemplate },
    } = data;
    const { hasDriveAccount } = this.props;
    const input = {
      isGroupReport: !!isGroupReport,
      name,
      description,
      isOneTimeReport: false,
      reportType: reportType.value || reportType,
      reportFilter: {
        filters: stringifyFilters(filters),
      },
      reportTemplate,
      schedule: schedule.value || schedule,
      scheduledDay: (schedule.value || schedule) === 1 ? null : Number(scheduledDay),
      recipients: emails ? emails : [],
      sender,
      emailSubject,
      emailBody,
      language,
      ...(isGroupReport
        ? {
            group: group.value,
          }
        : {
            domain: domain.value,
          }),
    };

    // pop the google auth flow if we dont have an account
    if (reportType === 5 && !hasDriveAccount) {
      this.handleMissingDriveAccount(data);
      return;
    }

    return this.props
      .addScheduledReport({
        variables: {
          input,
        },
      })
      .then(
        ({
          data: {
            addScheduledReport: { errors },
          },
        }) => {
          if (errors && errors.length) {
            toast.error(t('Unable to create scheduled report'));
            Validator.throwSubmissionError(errors);
          }
          toast.success(t('Report scheduled'));
          //empty the Apollo cache to make sure we refetch the reports so the Scheduled Reports table is updated
          apolloClient.cache.reset();
          //call the redirect async to make sure we have evicted the cache first.
          this.props.history.push('/reports/scheduled');
        },
      );
  };
  handleMissingDriveAccount = (data) => {
    this.props.showModal({
      modalType: 'ConnectToDrive',
      modalTheme: 'light',
      modalProps: {
        message: t(
          'You do not have a Google Drive connection setup with AccuRanker. Please connect to your Google account to allow AccuRanker to create spreadsheet reports. AccuRanker will only have access to the files it creates, and cannot read other files.',
        ),
        lastState: data,
      },
    });
  };

  render() {
    const { initialState, hasDriveAccount } = this.props;
    return (
      <Form
        onSubmit={this.handleSubmit}
        initialValues={initialState || {}}
        submitOnInit={!!initialState}
        hasDriveAccount={hasDriveAccount}
      />
    );
  }
}

const addScheduledReportQuery = gql`
  mutation addScheduledReport_addScheduledReport($input: CreateScheduledReportInput!) {
    addScheduledReport(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

const mapStateToProps = (state) => ({
  user: state.user,
  hasDriveAccount: state.user.googleConnections.length > 0,
  initialState: state.googleAccounts.passedState,
});

export default compose(
  connect(mapStateToProps, {
    showModal,
    gaSetPassedState,
  }),
  withRouter,
  graphql(addScheduledReportQuery, {
    name: 'addScheduledReport',
  }),
)(AddScheduledReport);
