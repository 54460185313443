import Skeleton from 'Components/Skeleton';

const PlanSkeleton = () => (
  <Skeleton
    linesConfig={[
      {
        type: 'chart',
        options: {
          flex: '1',
          height: '250px',
        },
      },
    ]}
  />
);

export default PlanSkeleton;
