import { changeIsPartner } from 'Actions/UserAction';
import AccButton from 'Components/AccButton/AccButton';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { Form } from 'Components/Fields';
import AccText from 'Components/Text/AccText';
import { useSubAccountsInformationForm_UpdateOrganizationMutation } from 'Ghql';
import { useUser } from 'Hooks/data/user/useUser';
import { useActions } from 'Hooks/redux/useActions';
import useUserPermission from 'Hooks/useUserPermission';
import { t } from 'Utilities/i18n/index';
import Validator from 'Utilities/validation';

const InformationForm = () => {
  const user = useUser();
  const { isAdmin } = useUserPermission();
  const { isOnMultiaccount } = user;
  const isSubAccount = isOnMultiaccount ?? false;

  const [performUpdateAccount] = useSubAccountsInformationForm_UpdateOrganizationMutation();

  const actions = useActions({ changeIsPartner });

  const onSubmit = () => {
    const { organization } = user;

    const { id, name, displayCurrency, keywordOveruseEnabled, showLocalSearchVolume } =
      organization || {};
    performUpdateAccount({
      variables: {
        input: {
          id: id ?? '',
          name: name ?? '',
          isPartner: true,
          displayCurrency: displayCurrency ?? '',
          keywordOveruseEnabled: keywordOveruseEnabled ?? false,
          showLocalSearchVolume: showLocalSearchVolume ?? false,
        },
      },
    }).then(({ data }) => {
      const errors = data?.updateOrganization?.errors;
      if (errors && errors.length) {
        Validator.setResponseErrors(Validator.throwSubmissionError, errors as any);
      } else {
        actions.changeIsPartner(true);
      }
    });
  };

  return (
    <Form onSubmit={onSubmit}>
      {({ invalid, submitting }) => (
        <>
          <AccText>
            {t(
              'Sub-accounts enables you to manage additional AccuRanker accounts from your main account. You can control whether this account or the sub-account should handle billing.',
            )}
          </AccText>
          <div>
            <hr />
            {!isAdmin && (
              <AccText>{t('You must be an administrator to enable sub-accounts.')}</AccText>
            )}

            <div className="confirmation-button-wrapper text-right">
              <AccTooltip
                tooltip={
                  isSubAccount ? t('Enabling sub-accounts is not possible from a sub-account') : ''
                }
              >
                <div>
                  <AccButton
                    disabled={invalid || submitting || !isAdmin || isSubAccount}
                    type="submit"
                    variant="primary"
                  >
                    {t('Enable sub-accounts')}
                  </AccButton>
                </div>
              </AccTooltip>
            </div>
          </div>
        </>
      )}
    </Form>
  );
};
InformationForm.displayName = 'InformationForm';

export default InformationForm;
