import {
  RESET_SERVICE_MESSAGE_READ,
  SET_SERVICE_MESSAGE_READ,
} from '../Actions/ServiceMessageActions';

type InitialState = {
  serviceMessageRead: boolean;
};
const initialState: InitialState = {
  serviceMessageRead: false,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_SERVICE_MESSAGE_READ:
      return { ...state, serviceMessageRead: true };

    case RESET_SERVICE_MESSAGE_READ:
      return { ...state, ...initialState };

    default:
      return state;
  }
}
