import { OverviewSearchIntentGraphNode, useSearchIntentOverviewQuery } from 'Ghql';
import { useFilters } from 'Hooks';
import { WithDragDetails } from 'Pages/Keywords/Overview/support/types';
import { SearchIntentChartContent } from './SearchIntentChartContent';

const SearchIntentChart = (props: WithDragDetails) => {
  const filters = useFilters();
  const { data, loading } = useSearchIntentOverviewQuery({
    variables: { filters, name: 'searchIntentChart' },
  });

  const searchIntentNode = data?.graphs?.overviewSearchIntent as OverviewSearchIntentGraphNode;

  if (!searchIntentNode) {
    return null;
  }

  return (
    <SearchIntentChartContent {...props} searchIntentNode={searchIntentNode} loading={loading} />
  );
};

export default SearchIntentChart;
