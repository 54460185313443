/* eslint-disable import/no-unused-modules */
import clone from 'lodash/clone';
import isEqual from 'lodash/isEqual';
import {
  COMMERCIAL,
  INFORMATIONAL,
  NAVIGATIONAL,
  PLATFORM_DESKTOP,
  PLATFORM_MOBILE,
  TRANSACTIONAL,
} from 'Components/Modal/Content/AddSearchEngine/types';

export const DOMAIN = 'domain';
export const ID = 'id';
export const DISPLAY_NAME = 'display_name';
export const HAS_GOOGLE_ANALYTICS = 'has_ga';
export const HAS_GOOGLE_SEARCH_CONSOLE = 'has_gsc';
export const SHARE_OF_VOICE = 'share_of_voice';
export const SHARE_OF_VOICE_CHANGE = 'share_of_voice_change';
export const KEYWORD = 'keyword';
export const MULTIPLE_KEYWORDS = 'multiple_keywords';
export const TAGS = 'tags';
export const NAME = 'name';
export const FOLDERS = 'folders';
export const LOCATION = 'location';
export const RANK = 'rank';
export const RANK_CHANGE = 'rank_change';
export const SEARCH_TYPE = 'search_type';
export const SEARCH_ENGINE = 'search_engine';
export const DOMAINS = 'domains';
export const SALES_MANAGER = 'sales_manager';
export const STARRED = 'starred';
export const SEARCH_VOLUME = 'search_volume';
export const AI_SEARCH_VOLUME = 'ai_search_volume';
export const VISITORS = 'visitors';
export const SERP_FEATURES = 'serp_features';
export const ABOVE_THE_FOLD = 'above_the_fold';
export const HAS_EXTRA_RANKS = 'has_extra_ranks';
export const KEYWORD_OPTIONS = 'keyword_options';
export const PAGE_SERP_FEATURES = 'page_serp_features';
export const PAGE_SERP_FEATURES_OWNED = 'page_serp_features_owned';
export const PAGE_SERP_FEATURES_RANK = 'page_serp_features_rank';
export const COUNTRY_LOCALE = 'countrylocale';
export const LANDING_PAGES = 'landingpages';
export const HIGHEST_RANKING_PAGE = 'highest_ranking_page';
export const HIGHEST_RANKING_PAGE_MATCH = 'highest_ranking_page_match';
export const PERIOD = 'period';
export const COMPARE_TO = 'compare_to';
export const DATE_ADDED = 'date_added';
export const COUNTRY_NAME = 'countryName';
export const IMPRESSIONS = 'impressions';
export const CLICKS = 'clicks';
export const GSC_EXISTS = 'gscExists';
export const USER_NAME = 'fullName';
export const DATE = 'date';
export const SIGNED_UP = 'signed_up';
export const NOTE = 'note';
export const KEYWORDS = 'keywords';
export const CLIENTS = 'clients';
export const CONNECTION = 'connection';
export const CAMPAIGNS = 'campaigns';
export const PLACEMENTS = 'placements';
export const UNIQUE_IDS = 'unique_ids';
export const COMPETITOR_URL = 'unknown_competitor_url';
export const COMPETITOR_DOMAIN = 'unknown_competitor_domain';
export const TOP_COMPETITOR = 'top_domain';
export const SUBDOMAIN = 'subdomain';
export const DYNAMIC_CTR = 'dynamic_ctr';
export const DYNAMIC_CTR_CHANGE = 'dynamic_ctr_change';
export const DYNAMIC_CTR_MAX = 'dynamic_ctr_max';
export const DYNAMIC_CTR_POTENTIAL = 'dynamic_ctr_potential';
export const ORGANIC_TRAFFIC = 'organic_traffic';
export const ORGANIC_TRAFFIC_CHANGE = 'organic_traffic_change';
export const SEARCH_INTENT = 'search_intent';
export const FREE_TEXT_DOMAIN = 'free_text_domain';

export const BASE_RANK = 'base_rank';
export const BASE_RANK_CHANGE = 'base_rank_change';

export const LOCAL_PACK_RANK = 'local_pack_rank';
export const LOCAL_PACK_RANK_CHANGE = 'local_pack_rank_change';

export const TRAFFIC_VALUE = 'traffic_value';

export const AI_TRAFFIC_VALUE = 'ai_traffic_value';

export const AI_TRAFFIC_VALUE_CHANGE = 'ai_traffic_value_change';

export const MAX_AI_TRAFFIC_VALUE = 'max_ai_traffic_value';
export const MAX_TRAFFIC_VALUE = 'max_traffic_value';

export const MAX_AI_SOV = 'max_ai_sov';

export const MAX_RANK = 'max_rank';

export const MAX_SOV = 'max_sov';

export const PIXELS_FROM_TOP = 'pixels_from_top';

export const PIXELS_FROM_TOP_CHANGE = 'pixels_from_top_change';

export const AD_COMPETITION = 'competition';

export const CPC = 'avg_cost_per_click';

export const TRAFFIC_VALUE_CHANGE = 'traffic_value_change';

export const ALPHABET = 'alphabet';

export const GSC_CTR = 'gscCtr';

export const GSC_POSITION = 'gscPosition';

const FIRST_DATE = 'first_date';
const LAST_DATE = 'last_date';
export const compareToOptions = {
  FIRST_DATE,
  LAST_DATE,
};

export const FilterAttribute = {
  NAME,
  GSC_CTR,
  GSC_POSITION,
  MAX_RANK,
  MAX_AI_SOV,
  MAX_SOV,
  PIXELS_FROM_TOP,
  AI_TRAFFIC_VALUE,
  AI_TRAFFIC_VALUE_CHANGE,
  MAX_AI_TRAFFIC_VALUE,
  MAX_TRAFFIC_VALUE,
  TRAFFIC_VALUE,
  TRAFFIC_VALUE_CHANGE,
  PIXELS_FROM_TOP_CHANGE,
  AD_COMPETITION,
  CPC,
  BASE_RANK,
  BASE_RANK_CHANGE,
  LOCAL_PACK_RANK,
  LOCAL_PACK_RANK_CHANGE,
  DOMAIN,
  DOMAINS,
  FREE_TEXT_DOMAIN,
  DISPLAY_NAME,
  HAS_GOOGLE_ANALYTICS,
  HAS_GOOGLE_SEARCH_CONSOLE,
  ID,
  SHARE_OF_VOICE,
  SHARE_OF_VOICE_CHANGE,
  KEYWORD,
  MULTIPLE_KEYWORDS,
  TAGS,
  FOLDERS,
  LOCATION,
  RANK,
  ABOVE_THE_FOLD,
  HAS_EXTRA_RANKS,
  RANK_CHANGE,
  SALES_MANAGER,
  SEARCH_TYPE,
  SEARCH_ENGINE,
  STARRED,
  SEARCH_VOLUME,
  AI_SEARCH_VOLUME,
  VISITORS,
  KEYWORD_OPTIONS,
  PAGE_SERP_FEATURES,
  PAGE_SERP_FEATURES_OWNED,
  PAGE_SERP_FEATURES_RANK,
  HIGHEST_RANKING_PAGE,
  HIGHEST_RANKING_PAGE_MATCH,
  COUNTRY_LOCALE,
  LANDING_PAGES,
  DATE_ADDED,
  PERIOD,
  COMPARE_TO,
  COUNTRY_NAME,
  IMPRESSIONS,
  CLICKS,
  GSC_EXISTS,
  USER_NAME,
  DATE,
  NOTE,
  KEYWORDS,
  CLIENTS,
  CONNECTION,
  CAMPAIGNS,
  PLACEMENTS,
  UNIQUE_IDS,
  COMPETITOR_URL,
  COMPETITOR_DOMAIN,
  DYNAMIC_CTR,
  DYNAMIC_CTR_CHANGE,
  DYNAMIC_CTR_MAX,
  DYNAMIC_CTR_POTENTIAL,
  ORGANIC_TRAFFIC,
  ORGANIC_TRAFFIC_CHANGE,
  SUBDOMAIN,
  SEARCH_INTENT,
  SIGNED_UP,
  ALPHABET,
  TOP_COMPETITOR,
} as const;

export const EQ = 'eq';
export const NE = 'ne';
const LT = 'lt';
const GT = 'gt';
const LTE = 'lte';
const GTE = 'gte';
const BETWEEN = 'between';
const IS_NULL = 'is_null';
const ANY = 'any';
const ALL = 'all';
const NONE = 'none';
const EMPTY = 'empty';
export const REGEX = 'regex';

export const NOT_REGEX = 'not_regex';
export const CONTAINS = 'contains' as const;
export const NOT_CONTAINS = 'not_contains' as const;
export const STARTS_WITH = 'starts_with' as const;
export const ENDS_WITH = 'ends_with' as const;
const EXACT_FOLDER = 'exact_folder';
const FOLDER_OR_SUBFOLDER = 'folder_or_subfolder';
export const NO_FILTER = 'No filter';
type ArrayComparison = typeof ANY | typeof ALL | typeof NONE | typeof EMPTY;
type FolderComparison = typeof EXACT_FOLDER | typeof FOLDER_OR_SUBFOLDER;
export type StringComparison =
  | typeof CONTAINS
  | typeof EQ
  | typeof NE
  | typeof NOT_CONTAINS
  | typeof STARTS_WITH
  | typeof ENDS_WITH
  | typeof REGEX
  | typeof NOT_REGEX;
export const FilterComparison = {
  EQ,
  NE,
  LT,
  GT,
  LTE,
  GTE,
  BETWEEN,
  IS_NULL,
  ANY,
  ALL,
  NONE,
  EMPTY,
  CONTAINS,
  NOT_CONTAINS,
  STARTS_WITH,
  ENDS_WITH,
  NO_FILTER,
  EXACT_FOLDER,
  FOLDER_OR_SUBFOLDER,
  REGEX,
  NOT_REGEX,
} as const;
const STRING = 'string';
const NUMBER = 'num';
const BOOL = 'bool';
const ARRAY = 'array';
const LIST = 'list';
const FOLDER = 'folder';
const DATETIME = 'datetime';
export const FilterValueType = {
  STRING,
  NUMBER,
  BOOL,
  ARRAY,
  LIST,
  DATETIME,
  FOLDER,
} as const;
export type DomainFilter = {
  readonly attribute: typeof DOMAIN;
  readonly type: typeof STRING;
  readonly comparison: typeof CONTAINS;
  readonly value: string;
};

export type FreeTextDomainFilter = {
  readonly attribute: typeof FREE_TEXT_DOMAIN;
  readonly type: typeof STRING;
  readonly comparison: typeof CONTAINS;
  readonly value: string;
};

export type DisplayNameFilter = {
  readonly attribute: typeof DISPLAY_NAME;
  readonly type: typeof STRING;
  readonly comparison: typeof CONTAINS;
  readonly value: string;
};
export type HasGoogleAnalyticsFilter = {
  readonly attribute: typeof HAS_GOOGLE_ANALYTICS;
  readonly type: typeof BOOL;
  readonly comparison: typeof EQ;
  readonly value: boolean;
};
export type HasGoogleSearchConsoleFilter = {
  readonly attribute: typeof HAS_GOOGLE_SEARCH_CONSOLE;
  readonly type: typeof BOOL;
  readonly comparison: typeof EQ;
  readonly value: boolean;
};
export type IDFilter = {
  readonly attribute: typeof ID;
  readonly type: typeof NUMBER;
  readonly comparison: typeof EQ | typeof GT | typeof LT | typeof LTE | typeof GTE;
  readonly value: number;
};

export type ShareOfVoiceFilter = {
  readonly attribute: typeof SHARE_OF_VOICE;
  readonly type: typeof NUMBER;
  readonly comparison: typeof EQ | typeof GT | typeof LT | typeof LTE | typeof GTE;
  readonly value: number;
};
export type KeywordFilter = {
  readonly attribute: typeof KEYWORD;
  readonly type: typeof STRING;
  readonly comparison: StringComparison;
  readonly value: string;
};

export type MultipleKeywordFilter = {
  readonly attribute: typeof MULTIPLE_KEYWORDS;
  readonly type: typeof ARRAY;
  readonly comparison: typeof ANY;
  readonly value: Array<string>;
};

export type SearchEngineFilter = {
  readonly attribute: typeof SEARCH_ENGINE;
  readonly type: typeof NUMBER;
  readonly comparison: typeof EQ;
  readonly value: number;
};
export type CountryLocaleFilter = {
  readonly attribute: typeof COUNTRY_LOCALE;
  readonly type: typeof NUMBER;
  readonly comparison: typeof EQ;
  readonly value: number;
};
export const TOTAL = 0;
export const DESKTOP = PLATFORM_DESKTOP;
export const MOBILE = PLATFORM_MOBILE;
export type SearchType = typeof TOTAL | typeof MOBILE | typeof DESKTOP;
export type SearchTypeFilter = {
  readonly attribute: typeof SEARCH_TYPE;
  readonly type: typeof NUMBER;
  readonly comparison: typeof EQ;
  readonly value: SearchType;
};

export type SearchIntent =
  | typeof TRANSACTIONAL
  | typeof NAVIGATIONAL
  | typeof COMMERCIAL
  | typeof INFORMATIONAL;

export type SearchIntentFilter = {
  readonly attribute: typeof SEARCH_INTENT;
  readonly type: typeof ARRAY;
  readonly comparison: typeof ALL;
  readonly value: SearchIntent[];
};

export type AlphabetFilter = {
  readonly attribute: typeof ALPHABET;
  readonly type: typeof STRING;
  readonly comparison: typeof EQ;
  readonly value: string;
};

export type TopCompetitorFilter = {
  readonly attribute: typeof TOP_COMPETITOR;
  readonly type: typeof ARRAY;
  readonly comparison: typeof ANY;
  readonly value: string[];
};

export type LocationFilter = {
  readonly attribute: typeof LOCATION;
  readonly type: typeof STRING;
  readonly comparison: typeof CONTAINS;
  readonly value: string;
};
export type TagsFilter = {
  readonly attribute: typeof TAGS;
  readonly type: typeof ARRAY;
  readonly comparison: ArrayComparison;
  readonly value: Array<string>;
};

export type FoldersFilter = {
  readonly attribute: typeof FOLDERS;
  readonly type: typeof FOLDER;
  readonly comparison: FolderComparison;
  readonly value: Array<string>;
};

export type SubdomainFilter = {
  readonly attribute: typeof SUBDOMAIN;
  readonly type: typeof STRING;
  readonly comparison: typeof EQ;
  readonly value: string;
};

export type RankFilter =
  | {
      readonly attribute: typeof RANK;
      readonly type: typeof NUMBER;
      readonly comparison: typeof EQ | typeof GT | typeof LT | typeof LTE | typeof GTE;
      readonly value: number;
    }
  | {
      readonly attribute: typeof RANK;
      readonly type: typeof NUMBER;
      readonly comparison: typeof BETWEEN;
      readonly value: [number, number];
    };

export type BaseRankFilter =
  | {
      readonly attribute: typeof BASE_RANK;
      readonly type: typeof NUMBER;
      readonly comparison: typeof EQ | typeof GT | typeof LT | typeof LTE | typeof GTE;
      readonly value: number;
    }
  | {
      readonly attribute: typeof BASE_RANK;
      readonly type: typeof NUMBER;
      readonly comparison: typeof BETWEEN;
      readonly value: [number, number];
    };

export type LocalPackRankFilter =
  | {
      readonly attribute: typeof LOCAL_PACK_RANK;
      readonly type: typeof NUMBER;
      readonly comparison:
        | typeof EQ
        | typeof GT
        | typeof LT
        | typeof LTE
        | typeof GTE
        | typeof IS_NULL;
      readonly value: number;
    }
  | {
      readonly attribute: typeof LOCAL_PACK_RANK;
      readonly type: typeof NUMBER;
      readonly comparison: typeof BETWEEN;
      readonly value: [number, number];
    };

export type MaxRankFilter =
  | {
      readonly attribute: typeof MAX_RANK;
      readonly type: typeof NUMBER;
      readonly comparison: typeof EQ | typeof GT | typeof LT | typeof LTE | typeof GTE;
      readonly value: number;
    }
  | {
      readonly attribute: typeof MAX_RANK;
      readonly type: typeof NUMBER;
      readonly comparison: typeof BETWEEN;
      readonly value: [number, number];
    };

export type MaxAiSovFilter =
  | {
      readonly attribute: typeof MAX_AI_SOV;
      readonly type: typeof NUMBER;
      readonly comparison: typeof EQ | typeof GT | typeof LT | typeof LTE | typeof GTE;
      readonly value: number;
    }
  | {
      readonly attribute: typeof MAX_AI_SOV;
      readonly type: typeof NUMBER;
      readonly comparison: typeof BETWEEN;
      readonly value: [number, number];
    };

export type MaxSovFilter =
  | {
      readonly attribute: typeof MAX_SOV;
      readonly type: typeof NUMBER;
      readonly comparison: typeof EQ | typeof GT | typeof LT | typeof LTE | typeof GTE;
      readonly value: number;
    }
  | {
      readonly attribute: typeof MAX_SOV;
      readonly type: typeof NUMBER;
      readonly comparison: typeof BETWEEN;
      readonly value: [number, number];
    };

export type PixelsFromTopFilter =
  | {
      readonly attribute: typeof PIXELS_FROM_TOP;
      readonly type: typeof NUMBER;
      readonly comparison: typeof EQ | typeof GT | typeof LT | typeof LTE | typeof GTE;
      readonly value: number;
    }
  | {
      readonly attribute: typeof PIXELS_FROM_TOP;
      readonly type: typeof NUMBER;
      readonly comparison: typeof BETWEEN;
      readonly value: [number, number];
    };

export type PixelsFromTopChangeFilter =
  | {
      readonly attribute: typeof PIXELS_FROM_TOP_CHANGE;
      readonly type: typeof NUMBER;
      readonly comparison: typeof EQ | typeof GT | typeof LT | typeof LTE | typeof GTE;
      readonly value: number;
    }
  | {
      readonly attribute: typeof PIXELS_FROM_TOP_CHANGE;
      readonly type: typeof NUMBER;
      readonly comparison: typeof BETWEEN;
      readonly value: [number, number];
    };

export type AdCompetitionFilter =
  | {
      readonly attribute: typeof AD_COMPETITION;
      readonly type: typeof NUMBER;
      readonly comparison: typeof EQ | typeof GT | typeof LT | typeof LTE | typeof GTE;
      readonly value: number;
    }
  | {
      readonly attribute: typeof AD_COMPETITION;
      readonly type: typeof NUMBER;
      readonly comparison: typeof BETWEEN;
      readonly value: [number, number];
    };

export type CpcFilter =
  | {
      readonly attribute: typeof CPC;
      readonly type: typeof NUMBER;
      readonly comparison: typeof EQ | typeof GT | typeof LT | typeof LTE | typeof GTE;
      readonly value: number;
    }
  | {
      readonly attribute: typeof CPC;
      readonly type: typeof NUMBER;
      readonly comparison: typeof BETWEEN;
      readonly value: [number, number];
    };

export type MaxAiTrafficValueFilter =
  | {
      readonly attribute: typeof MAX_AI_TRAFFIC_VALUE;
      readonly type: typeof NUMBER;
      readonly comparison: typeof EQ | typeof GT | typeof LT | typeof LTE | typeof GTE;
      readonly value: number;
    }
  | {
      readonly attribute: typeof MAX_AI_TRAFFIC_VALUE;
      readonly type: typeof NUMBER;
      readonly comparison: typeof BETWEEN;
      readonly value: [number, number];
    };

export type MaxTrafficValueFilter =
  | {
      readonly attribute: typeof MAX_TRAFFIC_VALUE;
      readonly type: typeof NUMBER;
      readonly comparison: typeof EQ | typeof GT | typeof LT | typeof LTE | typeof GTE;
      readonly value: number;
    }
  | {
      readonly attribute: typeof MAX_TRAFFIC_VALUE;
      readonly type: typeof NUMBER;
      readonly comparison: typeof BETWEEN;
      readonly value: [number, number];
    };

export type TrafficValueFilter =
  | {
      readonly attribute: typeof TRAFFIC_VALUE;
      readonly type: typeof NUMBER;
      readonly comparison: typeof EQ | typeof GT | typeof LT | typeof LTE | typeof GTE;
      readonly value: number;
    }
  | {
      readonly attribute: typeof TRAFFIC_VALUE;
      readonly type: typeof NUMBER;
      readonly comparison: typeof BETWEEN;
      readonly value: [number, number];
    };

export type AiTrafficValueFilter =
  | {
      readonly attribute: typeof AI_TRAFFIC_VALUE;
      readonly type: typeof NUMBER;
      readonly comparison: typeof EQ | typeof GT | typeof LT | typeof LTE | typeof GTE;
      readonly value: number;
    }
  | {
      readonly attribute: typeof AI_TRAFFIC_VALUE;
      readonly type: typeof NUMBER;
      readonly comparison: typeof BETWEEN;
      readonly value: [number, number];
    };

export type DomainsFilter = {
  readonly attribute: typeof DOMAINS;
  readonly type: typeof LIST;
  readonly comparison: typeof CONTAINS;
  readonly value: string[];
};
export type PeriodFilter = {
  readonly attribute: typeof PERIOD;
  readonly type: typeof DATETIME;
  readonly comparison: typeof BETWEEN;
  readonly value: string;
};
export type CompareToFilter = {
  readonly attribute: typeof COMPARE_TO;
  readonly type: typeof DATETIME;
  readonly comparison: typeof EQ;
  readonly value: string;
};
export type StarredFilter = {
  readonly attribute: typeof STARRED;
  readonly type: typeof BOOL;
  readonly comparison: typeof EQ;
  readonly value: boolean;
};

export type SalesManagerFilter = {
  readonly attribute: typeof SALES_MANAGER;
  readonly type: typeof STRING;
  readonly comparison: StringComparison;
  readonly value: string;
};

export type SearchVolumeFilter =
  | {
      readonly attribute: typeof SEARCH_VOLUME;
      readonly type: typeof NUMBER;
      readonly comparison: typeof EQ | typeof GT | typeof LT | typeof LTE | typeof GTE;
      readonly value: number;
    }
  | {
      readonly attribute: typeof SEARCH_VOLUME;
      readonly type: typeof NUMBER;
      readonly comparison: typeof BETWEEN;
      readonly value: [number, number];
    };

export type AiSearchVolumeFilter =
  | {
      readonly attribute: typeof AI_SEARCH_VOLUME;
      readonly type: typeof NUMBER;
      readonly comparison: typeof EQ | typeof GT | typeof LT | typeof LTE | typeof GTE;
      readonly value: number;
    }
  | {
      readonly attribute: typeof AI_SEARCH_VOLUME;
      readonly type: typeof NUMBER;
      readonly comparison: typeof BETWEEN;
      readonly value: [number, number];
    };

export type OrganicTrafficFilter =
  | {
      readonly attribute: typeof ORGANIC_TRAFFIC;
      readonly type: typeof NUMBER;
      readonly comparison: typeof EQ | typeof GT | typeof LT | typeof LTE | typeof GTE;
      readonly value: number;
    }
  | {
      readonly attribute: typeof ORGANIC_TRAFFIC;
      readonly type: typeof NUMBER;
      readonly comparison: typeof BETWEEN;
      readonly value: [number, number];
    };

export type DynamicCtrFilter =
  | {
      readonly attribute: typeof DYNAMIC_CTR;
      readonly type: typeof NUMBER;
      readonly comparison: typeof EQ | typeof GT | typeof LT | typeof LTE | typeof GTE;
      readonly value: number;
    }
  | {
      readonly attribute: typeof DYNAMIC_CTR;
      readonly type: typeof NUMBER;
      readonly comparison: typeof BETWEEN;
      readonly value: [number, number];
    };

export type DynamicCtrMaxFilter =
  | {
      readonly attribute: typeof DYNAMIC_CTR_MAX;
      readonly type: typeof NUMBER;
      readonly comparison: typeof EQ | typeof GT | typeof LT | typeof LTE | typeof GTE;
      readonly value: number;
    }
  | {
      readonly attribute: typeof DYNAMIC_CTR_MAX;
      readonly type: typeof NUMBER;
      readonly comparison: typeof BETWEEN;
      readonly value: [number, number];
    };

export type ChangeFilter = {
  readonly attribute:
    | typeof SHARE_OF_VOICE_CHANGE
    | typeof ORGANIC_TRAFFIC_CHANGE
    | typeof RANK_CHANGE
    | typeof DYNAMIC_CTR_CHANGE
    | typeof BASE_RANK_CHANGE
    | typeof LOCAL_PACK_RANK_CHANGE
    | typeof TRAFFIC_VALUE_CHANGE
    | typeof AI_TRAFFIC_VALUE_CHANGE;
  readonly type: typeof NUMBER;
  readonly comparison: typeof EQ | typeof GT | typeof LT | typeof LTE | typeof GTE;
  readonly value: number | string;
};

export type VisitorsFilter =
  | {
      readonly attribute: typeof VISITORS;
      readonly type: typeof NUMBER;
      readonly comparison: typeof EQ | typeof GT | typeof LT | typeof LTE | typeof GTE;
      readonly value: number;
    }
  | {
      readonly attribute: typeof VISITORS;
      readonly type: typeof NUMBER;
      readonly comparison: typeof BETWEEN;
      readonly value: [number, number];
    };
export type AboveTheFoldFilter = {
  readonly attribute: typeof ABOVE_THE_FOLD;
  readonly type: typeof BOOL;
  readonly comparison: typeof EQ;
  readonly value: boolean;
};
export type ExtraRanksFilter = {
  readonly attribute: typeof HAS_EXTRA_RANKS;
  readonly type: typeof BOOL;
  readonly comparison: typeof EQ;
  readonly value: boolean;
};
export type KeywordOptionsFilter = {
  readonly attribute: typeof KEYWORD_OPTIONS;
  readonly type: typeof ARRAY;
  readonly comparison: typeof ALL;
  readonly value: string[];
};
export type PageSerpFeaturesOwnedFilter = {
  readonly attribute: typeof PAGE_SERP_FEATURES_OWNED;
  readonly type: typeof ARRAY;
  readonly comparison: typeof ANY | typeof ALL;
  readonly value: string[];
};
export type PageSerpFeaturesFilter = {
  readonly attribute: typeof PAGE_SERP_FEATURES;
  readonly type: typeof ARRAY;
  readonly comparison: typeof ANY | typeof ALL;
  readonly value: string[];
};
export type PageSerpFeaturesRankFilter =
  | {
      readonly attribute: typeof PAGE_SERP_FEATURES_RANK;
      readonly type: typeof NUMBER;
      readonly comparison: typeof EQ | typeof GT | typeof LT | typeof LTE | typeof GTE;
      readonly value: number;
    }
  | {
      readonly attribute: typeof PAGE_SERP_FEATURES_RANK;
      readonly type: typeof NUMBER;
      readonly comparison: typeof BETWEEN;
      readonly value: [number, number];
    };
export type HighestRankingPageFilter = {
  readonly attribute: typeof HIGHEST_RANKING_PAGE;
  readonly type: typeof STRING;
  readonly comparison: StringComparison;
  readonly value: string;
};
export type CompetitorUrlFilter = {
  readonly attribute: typeof COMPETITOR_URL;
  readonly type: typeof STRING;
  readonly comparison: StringComparison;
  readonly value: string;
};
export type CompetitorDomainFilter = {
  readonly attribute: typeof COMPETITOR_DOMAIN;
  readonly type: typeof STRING;
  readonly comparison: StringComparison;
  readonly value: string;
};
export type HighestRankingPageMatchFilter = {
  readonly attribute: typeof HIGHEST_RANKING_PAGE_MATCH;
  readonly type: typeof STRING;
  readonly comparison: typeof NOT_CONTAINS | typeof EQ | typeof NE;
  readonly value: string;
};
export type LandingPagesFilter = {
  readonly attribute: typeof LANDING_PAGES;
  readonly type: typeof LIST;
  readonly comparison: typeof CONTAINS;
  readonly value: string[];
};
export type ImpressionsFilter =
  | {
      readonly attribute: typeof IMPRESSIONS;
      readonly type: typeof NUMBER;
      readonly comparison: typeof EQ | typeof GT | typeof LT | typeof LTE | typeof GTE;
      readonly value: number;
    }
  | {
      readonly attribute: typeof IMPRESSIONS;
      readonly type: typeof NUMBER;
      readonly comparison: typeof BETWEEN;
      readonly value: [number, number];
    };
export type CountryNameFilter = {
  readonly attribute: typeof COUNTRY_NAME;
  readonly type: typeof STRING;
  readonly comparison: typeof CONTAINS;
  readonly value: string;
};
export type GSCExistFilter = {
  readonly attribute: typeof GSC_EXISTS;
  readonly type: typeof BOOL;
  readonly comparison: typeof EQ;
  readonly value: boolean;
};
export type GSCCtrFilter =
  | {
      readonly attribute: typeof GSC_CTR;
      readonly type: typeof NUMBER;
      readonly comparison: typeof EQ | typeof GT | typeof LT | typeof LTE | typeof GTE;
      readonly value: number;
    }
  | {
      readonly attribute: typeof GSC_CTR;
      readonly type: typeof NUMBER;
      readonly comparison: typeof BETWEEN;
      readonly value: [number, number];
    };

export type GSCPositionFilter =
  | {
      readonly attribute: typeof GSC_POSITION;
      readonly type: typeof NUMBER;
      readonly comparison: typeof EQ | typeof GT | typeof LT | typeof LTE | typeof GTE;
      readonly value: number;
    }
  | {
      readonly attribute: typeof GSC_POSITION;
      readonly type: typeof NUMBER;
      readonly comparison: typeof BETWEEN;
      readonly value: [number, number];
    };

export type ClicksFilter =
  | {
      readonly attribute: typeof CLICKS;
      readonly type: typeof NUMBER;
      readonly comparison: typeof EQ | typeof GT | typeof LT | typeof LTE | typeof GTE;
      readonly value: number;
    }
  | {
      readonly attribute: typeof CLICKS;
      readonly type: typeof NUMBER;
      readonly comparison: typeof BETWEEN;
      readonly value: [number, number];
    };
export type UserNameFilter = {
  readonly attribute: typeof USER_NAME;
  readonly type: typeof STRING;
  readonly comparison: StringComparison;
  readonly value: string;
};
export type NameFilter = {
  readonly attribute: typeof NAME;
  readonly type: typeof STRING;
  readonly comparison: StringComparison;
  readonly value: string;
};
export type DateFilter = {
  readonly attribute: typeof DATE;
  readonly type: typeof DATETIME;
  readonly comparison: typeof EQ | typeof BETWEEN;
  readonly value: string;
};
export type SignedUpFilter = {
  readonly attribute: typeof SIGNED_UP;
  readonly type: typeof DATETIME;
  readonly comparison: typeof EQ | typeof BETWEEN;
  readonly value: string;
};
export type NoteFilter = {
  readonly attribute: typeof NOTE;
  readonly type: typeof STRING;
  readonly comparison: StringComparison;
  readonly value: string;
};
export type KeywordsFilter = {
  readonly attribute: typeof KEYWORDS;
  readonly type: typeof ARRAY;
  readonly comparison: ArrayComparison;
  readonly value: Array<string>;
};
export type ClientsFilter = {
  readonly attribute: typeof CLIENTS;
  readonly type: typeof LIST;
  readonly comparison: typeof CONTAINS;
  readonly value: string[];
};
export type ConnectionFilter = {
  readonly attribute: typeof CONNECTION;
  readonly type: typeof STRING;
  readonly comparison: typeof CONTAINS;
  readonly value: string;
};
export type CampaignsFilter = {
  readonly attribute: typeof CAMPAIGNS;
  readonly type: typeof LIST;
  readonly comparison: typeof CONTAINS;
  readonly value: string[];
};
export type PlacementsFilter = {
  readonly attribute: typeof PLACEMENTS;
  readonly type: typeof LIST;
  readonly comparison: typeof CONTAINS;
  readonly value: string[];
};
export type UniqueIdsFilter = {
  readonly attribute: typeof UNIQUE_IDS;
  readonly type: typeof LIST;
  readonly comparison: typeof CONTAINS;
  readonly value: string[];
};

export type FilterBase =
  | DomainFilter
  | DomainsFilter
  | DisplayNameFilter
  | ShareOfVoiceFilter
  | KeywordFilter
  | RankFilter
  | BaseRankFilter
  | LocalPackRankFilter
  | ChangeFilter
  | TagsFilter
  | FoldersFilter
  | SubdomainFilter
  | LocationFilter
  | SearchTypeFilter
  | SearchIntentFilter
  | AlphabetFilter
  | TopCompetitorFilter
  | SearchEngineFilter
  | PeriodFilter
  | CompareToFilter
  | StarredFilter
  | CountryLocaleFilter
  | VisitorsFilter
  | AboveTheFoldFilter
  | KeywordOptionsFilter
  | HighestRankingPageFilter
  | LandingPagesFilter
  | CountryNameFilter
  | ImpressionsFilter
  | ClicksFilter
  | GSCExistFilter
  | GSCCtrFilter
  | GSCPositionFilter
  | UserNameFilter
  | DateFilter
  | NoteFilter
  | KeywordsFilter
  | ClientsFilter
  | ConnectionFilter
  | CampaignsFilter
  | PlacementsFilter
  | UniqueIdsFilter
  | CompetitorDomainFilter
  | FreeTextDomainFilter
  | PageSerpFeaturesOwnedFilter
  | PageSerpFeaturesFilter
  | CompetitorUrlFilter
  | MultipleKeywordFilter
  | SalesManagerFilter
  | SignedUpFilter
  | NameFilter
  | IDFilter
  | MaxRankFilter
  | MaxAiSovFilter
  | MaxSovFilter
  | MaxAiTrafficValueFilter
  | MaxTrafficValueFilter
  | TrafficValueFilter
  | AiTrafficValueFilter
  | PixelsFromTopFilter
  | ExtraRanksFilter;

export type FilterGroup = {
  readonly name: string;
  readonly type: string;
  readonly id: string;
  readonly filters: FilterBase[];
  readonly defaultForDomains?: boolean;
  readonly defaultForKeywords?: string[];
};

export type FilterAttributetype = FilterBase['attribute'];

export const getFilterByAttribute = (filterAttribute: string, filterGroup: FilterGroup) => {
  const filter = clone(
    filterGroup?.filters?.find((currentFilter) => currentFilter.attribute === filterAttribute),
  );

  return filter;
};

export const parseValue = (filter: any) => {
  switch (filter.type) {
    case FilterValueType.STRING:
      return filter.value;

    case FilterValueType.BOOL:
      return filter.value === true || filter.value === 'true';

    case FilterValueType.NUMBER:
      if (filter.comparison !== FilterComparison.BETWEEN) {
        return parseFloat(filter.value);
      }

      return JSON.parse(filter.value);

    default:
      try {
        return JSON.parse(filter.value);
      } catch (err) {
        return filter.value;
      }
  }
};
export const stringifyFilterValue = (filter: FilterBase) => {
  if (
    filter.type === FilterValueType.ARRAY ||
    filter.type === FilterValueType.LIST ||
    filter.type === FilterValueType.FOLDER ||
    (filter.comparison === FilterComparison.BETWEEN && filter.type === FilterValueType.NUMBER) ||
    (filter.comparison === FilterComparison.BETWEEN &&
      filter.type === FilterValueType.DATETIME &&
      Array.isArray(filter.value))
  ) {
    return `${JSON.stringify(filter.value)}`;
  }

  if (filter.type === FilterValueType.BOOL) {
    return filter.value.toString();
  }

  return filter.value;
};

export const parseFilters = (filtersData: string) =>
  JSON.parse(filtersData).map((filter) => ({ ...filter, value: parseValue(filter) }));

export const stringifyFilters = (filters: Array<FilterBase>) =>
  JSON.stringify(filters.map((filter) => ({ ...filter, value: stringifyFilterValue(filter) })));

export const subfilterDiff = (a: Array<FilterBase>, b: Array<FilterBase>) => {
  const common = Array.from(
    new Set(
      a
        .concat(b)
        .map((item) => item?.attribute)
        .filter(Boolean),
    ),
  );
  return common.reduce((acc, attribute) => {
    const valueA = a.find((item) => item.attribute === attribute);
    const valueB = b.find((item) => item.attribute === attribute);
    // TODO FixTSignore
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return isEqual(valueA, valueB) ? acc : acc.concat(attribute);
  }, []);
};

// Temp
export type BetweenRankFilter = any;
