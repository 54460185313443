import TableEmptyState from 'Components/TableEmptyState';
import { getDisabledDemoText } from 'Constants/messages';
import { useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import { t } from 'Utilities/i18n';

export const TreeViewEmptyContent = ({
  activeSearch,
  resetSearch,
}: {
  activeSearch?: string;
  resetSearch?: () => void;
}) => {
  const { isDemoDomain } = useQueryDomainInfo();

  return (
    <TableEmptyState
      list={[]}
      title={t('No Groups or Domains Found')}
      subTitle={
        activeSearch
          ? t('We found no groups, domains, or tags to show for the specified search.')
          : t('We found no groups or domains to show for this domain.')
      }
      filtersPossible={true}
      noDataWithFiltersTitle={t('No groups, domains, or tags found')}
      noDataWithFiltersSubTitle={t(
        'We found no groups, domains, or tags. This could be due to your active filters. Clear your filters and try again.',
      )}
      onBtnClick={activeSearch ? resetSearch : null}
      btnTitle={activeSearch ? t('Clear Search') : undefined}
      fullWidth
      btnDisable={!activeSearch && isDemoDomain}
      btnTooltip={!activeSearch && isDemoDomain && getDisabledDemoText()}
    />
  );
};
