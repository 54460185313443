import { useState } from 'react';
import { useField } from 'react-final-form';
import { arrayMove, shouldCancelSortStart } from '../support/helpers';
import { useOptionsField } from '../support/hooks/useOptionsField';
import { TableSettingGroup, TableSettingOptionProps } from '../support/types';
import { SortableTableSettingsList } from './SortableTableSettingsList';
import styles from './tableSettings.module.scss';

export const TableSettingsList = ({ items }: { items: TableSettingGroup[] }) => {
  const { input: inputGroups } = useField('groups');

  const [grabbingCursorRef, setGrabbingCursorRef] = useState<boolean>(false);
  const onSortEnd = ({ oldIndex, newIndex }) => {
    inputGroups.onChange(arrayMove(inputGroups.value, oldIndex, newIndex));
    setGrabbingCursorRef(false);
  };

  const onGroupStatusChange = (id: string, value?: boolean) => {
    inputGroups.onChange(
      [...inputGroups.value]
        .map((e) => (e.id === id ? { ...e, enabled: value } : e))
        .filter(Boolean),
    );
  };

  const [columnsValue, onColumnsChange] = useOptionsField(items, onGroupStatusChange);

  const itemProps: TableSettingOptionProps = {
    groupStatuses: inputGroups.value,
    optionStatuses: columnsValue,
    onGroupStatusChange,
    onOptionsStatusChange: onColumnsChange,
  };

  return (
    <SortableTableSettingsList
      items={items}
      onSortEnd={onSortEnd}
      itemProps={itemProps}
      useDragHandle
      shouldCancelStart={shouldCancelSortStart}
      helperClass={styles.activeDraggableItem}
      onSortStart={(_, event) => {
        event?.preventDefault?.();
        setGrabbingCursorRef(true);
      }}
      grabbingCursor={grabbingCursorRef}
    />
  );
};
