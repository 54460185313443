import { Group } from '@mantine/core';
import { DomainFavicon } from 'Components/DomainFavicon';
import AccText from 'Components/Text/AccText';
import { DomainNode } from 'Ghql';

type OwnDomain = Pick<DomainNode, 'domain' | 'id' | 'displayName' | 'faviconUrl'>;

// eslint-disable-next-line import/no-unused-modules
export const DomainWithFavicon = ({
  ownDomain,
  size = 'md',
}: {
  ownDomain: OwnDomain;
  size?: 'md' | 'xs';
}) => (
  <Group wrap="nowrap" gap={size === 'md' ? 4 : 2} align="center">
    <DomainFavicon size={size} domain={ownDomain.domain} />
    <AccText
      size={size === 'md' ? 'md' : '10px'}
      fw={700}
      overflowEllipsis
      maw={size === 'md' ? 320 : 180}
    >
      {ownDomain.displayName || ownDomain.domain || ''}
    </AccText>
  </Group>
);
