import React from 'react';
import cn from 'classnames';
import isEqual from 'lodash/isEqual';
import './accuCell.scss';

const AccuCell = React.memo(
  (props: { className?: string; rightAligned?: boolean; children: JSX.Element } & any) => {
    const { rightAligned, ...propsWithoutRightAligned } = props;
    return (
      <div
        {...propsWithoutRightAligned}
        className={cn('accu-table-cell', props.className, rightAligned ? 'text-right' : '')}
      >
        {props.children}
      </div>
    );
  },
  isEqual,
);
AccuCell.displayName = 'AccuCell';
export default AccuCell;
