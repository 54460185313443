import { Flex } from '@mantine/core';
import Text from 'Components/Text/AccText';
import Title from 'Components/Title/AccTitle';
import { useUser } from 'Hooks/data/user/useUser';
import { t } from 'Utilities/i18n';

const WelcomeText = () => {
  const user = useUser();

  return (
    <Flex direction="column" rowGap={18} maw={650}>
      <Title type="h2">{t('Welcome to AccuRanker')}</Title>

      {user?.fullName && (
        <Text inline={false}>
          {t('Dear')} {user?.fullName}
        </Text>
      )}

      <Text inline={false}>
        {t(
          'We are excited for you to experience the power of our platform during the 14-day trial period, which includes access to all features and the ability to track up to 100 keywords. Discover how AccuRanker can help you stay ahead of competitors and increase website traffic.',
        )}
      </Text>

      <Text inline={false}>
        {t(
          'We offer three convenient ways to get started to help you make the most of your trial.',
        )}
      </Text>
    </Flex>
  );
};

export default WelcomeText;
