import { useSelector } from 'react-redux';
import { filtersSelector } from 'Selectors/FiltersSelector';
import { FilterAttribute } from 'Types/Filter';

export const useKeydisFilterControl = () => {
  const filters = useSelector(filtersSelector);

  const competitor = filters?.find((e) => e.attribute === FilterAttribute.COMPETITOR_DOMAIN)?.value;
  return { filters, competitor };
};
