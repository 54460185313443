import { Component } from 'react';
import { findDOMNode } from 'react-dom';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { gql } from '@apollo/client';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import compose from 'lodash/flowRight';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import type { FormProps } from 'redux-form';
import { createSelector } from 'reselect';
import { showModal } from 'Actions/ModalAction';
import AccButton from 'Components/AccButton/AccButton';
import { Select } from 'Components/Forms/Fields';
import toFormField from 'Components/Forms/toFormField';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import toast from 'Hooks/useToast';
import queryDomainInfo from 'Pages/queryDomainInfo';
import { DOMAINS, FilterAttribute, stringifyFilters } from 'Types/Filter';
import type { DomainsFilter, FilterBase } from 'Types/Filter';
import { t } from 'Utilities/i18n';
import { downloadFile, graphqlError, graphqlLoading } from 'Utilities/underdash';
import Validator from 'Utilities/validation';
import WS, { subscribeToGeneratedReport } from 'Utilities/websocket';
import EmailsInput from '../ScheduledReportBuilderForm/EmailsInput';
import {
  getDefaultEmailBody,
  getDefaultEmailSubject,
  getKeywordHistoryTypeOptions,
  getLanguageOptions,
} from '../data';
import './keyword-history-report-form.scss';

type Props = {
  client: any;
  reportTemplates: Record<string, any>;
  onSubmit: () => void;
  emailFrom: string;
  keywordId: string;
  filters: FilterBase[];
  reportType?: Record<string, any>;
  hasDriveAccount: boolean;
  showModal: (...args: Array<any>) => any;
  initialState: Record<string, any>;
  submitOnInit: boolean;
} & FormProps;
type State = {
  scheduledReportId: number | null;
  submittedOnInit: boolean;
  isLoading: boolean;
  link: string | null;
};
const EmailsField = toFormField(EmailsInput);

class KeywordHistoryReportForm extends Component<Props, State> {
  _submit: any;
  generatedReportSub: any;
  state: State = {
    isLoading: false,
    scheduledReportId: null,
    link: null,
    // to show Download button when report is created successfully
    submittedOnInit: false,
  };

  componentDidMount() {
    this.generatedReportSub = subscribeToGeneratedReport(this.handleDownload as any);
  }

  componentDidUpdate() {
    if (
      this._submit &&
      this.props.submitOnInit &&
      !this.state.submittedOnInit &&
      this.props.hasDriveAccount &&
      !(graphqlLoading(this.props) || graphqlError(this.props))
    ) {
      // eslint-disable-next-line react/no-will-update-set-state
      this.setState({
        submittedOnInit: true,
      });
      // Probably not the best way to submit programmatically,
      // so if there is a better way that runs handleSubmit and set submitting, fix me
      // eslint-disable-next-line react/no-find-dom-node
      // TODO FixTSignore
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line react/no-find-dom-node
      findDOMNode(this._submit)?.click?.();
    }
  }

  componentWillUnmount() {
    this.generatedReportSub.unsubscribe();
  }

  handleDownload = ({
    data: {
      action,
      other: { scheduled_report, url, report_type },
    },
  }) => {
    const { selectedEmails } = this.props;

    if (
      !selectedEmails.length &&
      scheduled_report === this.state.scheduledReportId &&
      action === WS.UPDATED
    ) {
      if (url) {
        toast.success(t('Report created'));
        this.setState({
          link: url,
          isLoading: false,
        });
      }

      if (report_type !== 5 && url) {
        downloadFile(url);
      }
    }
  };
  languageOptions = getLanguageOptions();
  reportTypeOptions = getKeywordHistoryTypeOptions();
  handleSubmit = (data: any) => {
    const domainsFilter = this.props.filters.find(
      (filter) => filter.attribute === FilterAttribute.DOMAINS,
    ) as any;

    if (!domainsFilter) {
      toast.error(t('Domains list is empty'));
      Validator.throwSubmissionError({
        _error: 'Domains list is empty',
      });
      return;
    }

    const domains = (domainsFilter as DomainsFilter).value;
    const { emails, reportType, template, language } = data;
    const isGroupReport = domains.length > 1;
    const noDomainsFilters = this.props.filters.filter((filter) => filter.attribute !== DOMAINS);
    noDomainsFilters.push({
      attribute: 'keywords',
      type: 'list',
      comparison: 'contains',
      value: [this.props.keywordId],
    });
    const input: any = {
      isGroupReport,
      isOneTimeReport: true,
      reportType: reportType.value,
      withHistory: true,
      reportFilter: {
        filters: stringifyFilters(noDomainsFilters),
      },
      reportTemplate: template ? template.value : null,
      schedule: 1,
      scheduledDay: null,
      recipients: emails ? emails : [],
      sender: this.props.emailFrom,
      emailSubject: getDefaultEmailSubject(),
      emailBody: getDefaultEmailBody(),
      language: language.value,
    };
    const { hasDriveAccount } = this.props;

    // pop the google auth flow if we dont have an account
    if (reportType.value === 5 && !hasDriveAccount) {
      this.handleMissingDriveAccount(data);
      return;
    }

    if (!isGroupReport) {
      input.domain = domains[0];
    }

    this.setState({
      isLoading: true,
    });
    return this.props
      .addScheduledReport({
        variables: {
          input,
        },
      })
      .then(
        ({
          data: {
            addScheduledReport: { errors, scheduledReport },
          },
        }) => {
          if (errors && errors.length) {
            this.setState({
              isLoading: false,
            });
            toast.error(errors[0].messages[0]);
            Validator.throwSubmissionError(errors);
            return;
          }

          if (emails.length) {
            toast.success(
              t(
                'Report is being generated and will be sent to %s when completed.',
                emails.join(', '),
              ),
            );
            this.props.onSubmit();
            return;
          }

          this.setState({
            scheduledReportId: Number(scheduledReport.id),
          });
        },
        () => {
          this.setState({
            isLoading: false,
          });
          toast.error(t('Unable to create report'));
        },
      );
  };
  handleMissingDriveAccount = (data) => {
    const { domainId } = this.props;
    this.props.showModal({
      modalType: 'ConnectToDrive',
      modalTheme: 'light',
      modalProps: {
        message: t(
          'You do not have a Google Drive connection setup with AccuRanker. Please connect to your Google account to allow AccuRanker to create spreadsheet reports. AccuRanker will only have access to the files it creates, and cannot read other files.',
        ),
        nextModalType: 'OneTimeReport',
        lastState: data,
        domainId,
      },
    });
  };
  setSubmitRef = (ref) => {
    this._submit = ref;
  };

  renderGeneratingReport() {
    return (
      <div>
        <p>{t('Your report is being generated. Please wait…')}</p>
        <p>{t('Please note that reports are queued up and can take a few minutes to complete.')}</p>
        <p>
          {t(
            'If nothing happens, your browser may be blocking the download. You can probably find your report in the ',
          )}
          <Link to="/reports/generated">{t('generated reports tab.')}</Link>
        </p>
        <div className="align-center">
          <AccButton variant="primary" onClick={this.emailReportWhenDone}>
            {t('Email it to me when it\'s ready')}
          </AccButton>
        </div>
      </div>
    );
  }

  renderLinkToReport() {
    return (
      <div className="alert alert-info">
        {this.state.link?.startsWith('https://docs.google.com')
          ? t(
              'Your report is ready. To view it, please click the \'Open Google Sheets\' button below.',
            )
          : t(
              'Your report is ready. The download should have started automatically. If it hasn\'t, please click the download button below.',
            )}
      </div>
    );
  }

  downloadReport = () => {
    if (this.state.link) {
      // TODO FixTSignore
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.open(this.state.link, '_blank');
    }
    this.props.onSubmit();
  };
  emailReportWhenDone = () => {
    const input = {
      id: this.state.scheduledReportId,
      recipients: [this.props.emailFrom],
    };
    this.props
      .updateScheduledReportRecipients({
        variables: {
          input,
        },
      })
      .then(
        ({
          data: {
            updateScheduledReportRecipients: { errors },
          },
        }) => {
          if (errors && errors.length) {
            toast.error(errors[0].messages[0]);
            return;
          }

          toast.success(
            t('The report will be emailed to %s when it is done.', this.props.emailFrom),
          );
          this.props.onSubmit();
        },
        () => {
          toast.error(t('Unable to update recipient'));
        },
      );
  };

  renderForm() {
    const { submitting } = this.props;
    return (
      <div>
        <div className="form-label required">{t('Report Type')}</div>
        <Field
          name="reportType"
          defaultBehaviour
          component={Select}
          disabled={submitting}
          options={this.reportTypeOptions}
          validate={Validator.required}
          searchable={false}
        />
        <div key="language-label" className="form-label required">
          {t('Language')}
        </div>
        <Field
          key="language"
          name="language"
          defaultBehaviour
          disabled={submitting}
          placeholder={t('Language')}
          component={Select}
          options={this.languageOptions}
          validate={Validator.required}
          searchable={false}
        />
        <div key="email-label" className="form-label">
          {t('Email to')}
        </div>
        <Field
          key="email"
          disabled={submitting}
          name="emails"
          //Do not include the word "email" in the placeholder - as this will trigger password managers to suggest email addresses
          placeholder={t('You can enter multiple addresses')}
          component={EmailsField}
        />
      </div>
    );
  }

  render() {
    const { handleSubmit, invalid, submitting } = this.props;
    const { isLoading, link } = this.state;
    return (
      <form className="one-time-report-form" onSubmit={handleSubmit(this.handleSubmit)}>
        {(link && this.renderLinkToReport()) || (
          <div>{submitting || isLoading ? this.renderGeneratingReport() : this.renderForm()}</div>
        )}
        <ModalFooter
          primaryButtonSlot={
            <>
              {(link && (
                <AccButton onClick={this.downloadReport} variant="primary">
                  {this.state.link?.startsWith('https://docs.google.com')
                    ? t('Open Google Sheets')
                    : t('Download')}
                </AccButton>
              )) || (
                <AccButton
                  ref={this.setSubmitRef}
                  disabled={invalid || submitting || isLoading}
                  type="submit"
                  variant="primary"
                  loading={submitting || isLoading}
                >
                  {submitting || isLoading ? t('Generating') : t('Download')}
                </AccButton>
              )}
            </>
          }
          secondaryButtonSlot={
            <AccButton variant="tertiary" onClick={this.props.onClose}>
              {t('Cancel')}
            </AccButton>
          }
        />
      </form>
    );
  }
}

const dataQuery = gql`
  query oneTimeReportForm_reportTemplatesData {
    user {
      id
      email
      organization {
        id
        defaultReportTemplate {
          id
          name
        }
      }
    }
    reportTemplates {
      id
      name
    }
    clients {
      id
      name
      domains {
        id
        domain
        displayName
      }
    }
  }
`;
const addScheduledReportQuery = gql`
  mutation oneTimeReportForm_addScheduledReport($input: CreateScheduledReportInput!) {
    addScheduledReport(input: $input) {
      errors {
        field
        messages
      }
      scheduledReport {
        id
        url
        preferredUrl
      }
    }
  }
`;
const updateScheduledReportRecipientsMutation = gql`
  mutation oneTimeReportForm_updateScheduledReportRecipients(
    $input: UpdateScheduledReportRecipientsInput!
  ) {
    updateScheduledReportRecipients(input: $input) {
      errors {
        field
        messages
      }
      scheduledReport {
        id
      }
    }
  }
`;
const ReduxForm = reduxForm({
  form: 'KeywordHistoryReportForm',
  enableReinitialize: true,
})(KeywordHistoryReportForm);

const languageSelector = (state) => state.user.language;

const filtersSelector = (state) => state.filter.filterGroup.filters;

const initialValuesSelector = createSelector(
  [languageSelector, filtersSelector],
  (language: string) => {
    const languageOptions = getLanguageOptions();
    const reportTypes = getKeywordHistoryTypeOptions();
    return {
      language: languageOptions.find((languageObj) => languageObj.value === language),
      reportType: reportTypes[0],
      emails: [],
    };
  },
);
const reportTypeSelector = formValueSelector('KeywordHistoryReportForm');

const mapStateToProps = (state, { initialState }) => ({
  initialValues: initialState || initialValuesSelector(state),
  hasDriveAccount: state.user.googleConnections.length > 0,
  emailFrom: state.user.email,
  filters: state.filter.filterGroup.filters,
  reportType: reportTypeSelector(state, 'reportType'),
  selectedEmails: reportTypeSelector(state, 'emails'),
});

export default compose(
  withApollo,
  connect(mapStateToProps, {
    showModal,
  }),
  queryDomainInfo(),
  graphql(addScheduledReportQuery, {
    name: 'addScheduledReport',
  }),
  graphql(updateScheduledReportRecipientsMutation, {
    name: 'updateScheduledReportRecipients',
  }),
  graphql<any>(dataQuery, {
    // TODO FixTSignore
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    props: ({ data, ownProps }: any) => {
      const { error, loading, user, reportTemplates, clients } = data;

      if (error || loading) {
        return {};
      }

      const {
        organization: { defaultReportTemplate },
      } = user;
      return {
        initialValues: {
          ...ownProps.initialValues,
          template: {
            value: defaultReportTemplate.id,
            label: defaultReportTemplate.name,
          },
        },
        reportTemplates,
        clients,
      };
    },
  }),
)(ReduxForm);
