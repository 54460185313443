import * as React from 'react';
import cn from 'classnames';
import './overlay.scss';

type Props = {
  className?: string;
  contentClassName?: string;
  tag?: string | ((...args: Array<any>) => any);
  children: any;
  onTop?: React.ReactNode;
  onBottom?: React.ReactNode;
  isEnabled?: boolean;
  collapsed?: boolean;
};

class Overlay extends React.Component<Props> {
  wrapChildren(Tag: string | ((...args: Array<any>) => any), props: Record<string, any>) {
    const { children, className, contentClassName } = this.props;
    return (
      <Tag {...props} className={className}>
        <div className={contentClassName}>{children}</div>
      </Tag>
    );
  }

  render() {
    const {
      children,
      onTop,
      onBottom,
      isEnabled,
      className,
      contentClassName,
      tag,
      collapsed,
      ...otherProps
    } = this.props;
    const Tag = tag || 'div';
    return isEnabled ? (
      <Tag
        {...otherProps}
        className={cn(
          'blurry-overlay',
          {
            'blurry-overlay-collapsed': collapsed,
          },
          className,
        )}
      >
        <div className={cn('blurry-overlay-on-bottom', contentClassName)}>
          {!collapsed ? onBottom || children : null}
        </div>
        <div className="blurry-overlay-backdrop" />
        {onTop && <div className="blurry-overlay-on-top">{onTop}</div>}
      </Tag>
    ) : (
      this.wrapChildren(Tag, otherProps)
    );
  }
}

export default Overlay;
