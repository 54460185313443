interface CheckmarkIconProps {
  className: string;
  id: string;
  indeterminate: boolean | undefined;
  checked: boolean | undefined;
}

export const CheckmarkIcon = ({ className, id, indeterminate, checked }: CheckmarkIconProps) => (
  <svg
    version="1.1"
    id={id}
    x="0px"
    y="0px"
    viewBox="0 0 22 22"
    width="20"
    height="20"
    className={className && className}
  >
    {checked && <path className="check" fill="none" strokeWidth="2" d="M7,10l3,3l4-4l2-2" />}
    <path
      fill="none"
      strokeWidth="2"
      d="M6,1h10c2.8,0,5,2.2,5,5v10c0,2.8-2.2,5-5,5H6c-2.8,0-5-2.2-5-5V6C1,3.2,3.2,1,6,1z"
    />
    {indeterminate && (
      <path
        style={{ fill: 'none' }}
        d="m 6.29315,10.353553 h 10.4137"
        strokeWidth="2"
        className="indeterminate"
      />
    )}
  </svg>
);
