import { Flex } from '@mantine/core';
import Skeleton from 'Components/Skeleton';
import AccText from 'Components/Text/AccText';

type Params = {
  label: string;
  used: number;
  total: number;
  loading?: boolean;
};

const TotalUsed = ({ label, used, total, loading }: Params) => {
  return (
    <Flex direction="column" align="end">
      <AccText fw="bold" size="md">
        {label}
      </AccText>
      {loading ? (
        <Skeleton
          linesConfig={[
            {
              type: 'text',
              options: {
                width: '60px',
                height: '20px',
              },
            },
          ]}
        />
      ) : (
        <AccText fw="bold" size="sm" h={20}>{`${used}/${total >= 0 ? total : 'Ꝏ'}`}</AccText>
      )}
    </Flex>
  );
};

export default TotalUsed;
