import { ReportType } from '../Constants/report';

export const NO_TOPIC = 'NO_TOPIC';
export const BLOCK = 'BLOCK';
export const KEYWORD = 'KEYWORD';
export const KEYWORDS = 'KEYWORDS';
export const USER = 'USER';

export const RUNNING_TASKS = 'RUNNING_TASKS';
export const GROUP = 'GROUP';
export const DOMAIN = 'DOMAIN';
export const MESSAGE = 'MESSAGE';
export const ORGANIZATION = 'ORGANIZATION';
export const SCHEDULED_REPORT = 'SCHEDULED_REPORT';
export const GENERATED_REPORT = 'GENERATED_REPORT';
export const IMPORT = 'IMPORT';

export const SERVICE_MESSAGE = 'SERVICE_MESSAGE';

export const IMPORTS = 'IMPORTS';
export type SubscriptionObjectType =
  | typeof KEYWORD
  | typeof KEYWORDS
  | typeof USER
  | typeof GROUP
  | typeof DOMAIN
  | typeof MESSAGE
  | typeof ORGANIZATION
  | typeof SCHEDULED_REPORT
  | typeof GENERATED_REPORT
  | typeof IMPORTS
  | typeof IMPORT
  | typeof SERVICE_MESSAGE;
export const CREATED = 'CREATED';
export const UPDATED = 'UPDATED';
export const DELETED = 'DELETED';
export const NOCHANGE = 'NOCHANGE';
export const CONNECTED = 'CONNECTED';
export const FORCE_RELOAD = 'FORCE_RELOAD';
export type SubscriptionActionType =
  | typeof CREATED
  | typeof UPDATED
  | typeof DELETED
  | typeof NOCHANGE
  | typeof BLOCK
  | typeof CONNECTED;
export type SubscriptionDataGeneratedReportOtherType = {
  scheduled_report: number;
  url: string;
  report_type: ReportType;
};
export type SubscriptionDataImportOtherType = {
  progress: number;
  status: number;
  time: string;
  rankProcessingStartedAt: string;
  rankProcessingLastRowIdx: number;
  rankProcessingResumedAt: string;
  rankProcessingResumedRankIdx: string;
  rankProcessingTotalRows: number;
};
export type SubscriptionDataOtherEventType = { event?: string; sessionid?: string };

export type SubscriptionDataOtherType =
  | SubscriptionDataGeneratedReportOtherType
  | SubscriptionDataImportOtherType;
export type SubscriptionDataType = {
  data: {
    obj: SubscriptionObjectType;
    session_id?: string;
    action: SubscriptionActionType;
    id: string;
    other?: SubscriptionDataOtherType & SubscriptionDataOtherEventType;
  };
};
export type SubscriptionCallback = (arg0: SubscriptionDataType) => void;
