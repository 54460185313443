import { gql, useMutation } from '@apollo/client';
import type { Dispatch } from 'redux';
import { STATE_LOADING_START } from 'redux-async-initial-state/lib/actionTypes';
import { useActions } from 'Hooks/redux/useActions';
import toast from 'Hooks/useToast';
import { t } from 'Utilities/i18n';
import { clearRedux } from '../../Actions/ResetAction';
import { apolloClient } from '../../Store';
import { useStableNavigate } from '../../Utilities/Router/components/LocationPathnameContext';

const onLogout = (redirect?: () => void) => async (dispatch: Dispatch<any>) => {
  await apolloClient.clearStore();
  localStorage.clear();
  dispatch({ type: STATE_LOADING_START });
  redirect?.();
  dispatch(clearRedux());
};

// Don't rename query. The name is used to whitelist query from blocking other sessions.
const MUTATION_SIGN_OUT = gql`
  mutation accountInfo_userLogout {
    userLogout {
      success
    }
  }
`;

export const useLogout = () => {
  const [logout] = useMutation(MUTATION_SIGN_OUT);
  const actions = useActions({ onLogout });
  const navigate = useStableNavigate();

  return async () => {
    return logout()
      .then(async () => {
        await actions.onLogout(() => navigate('/login'));
      })
      .catch(async () => {
        toast.error(t('Failed to perform logout'));
      });
  };
};
