import React, { useCallback, useEffect, useRef } from 'react';
import { IconPencil, IconTrash } from '@tabler/icons-react';
import classnames from 'classnames';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import { t } from 'Utilities/i18n/index';

type Item = {
  disabled?: boolean;
  label?: any;
  link?: any;
  icon?: any;
  onSelect?: (...args: Array<any>) => any;
  header?: boolean;
  className?: string;
  isDemoClient?: boolean;
};

type DropdownItemProps = {
  ref?: (...args: Array<any>) => any;
  tag: any;
  to?: string;
  onClick: (...args: Array<any>) => any;
  onSelect?: (...args: Array<any>) => any;
  header?: any;
};

type Props = {
  items: Array<Item>;
  item?: any | null | undefined;
  valueFunc?: (...args: Array<any>) => any;
  labelFunc?: (...args: Array<any>) => any;
  iconFunc?: ((...args: Array<any>) => any) | null | undefined;
  onSelect?: (...args: Array<any>) => any;
  onEdit?: ((...args: Array<any>) => any) | null | undefined;
  onDelete?: ((...args: Array<any>) => any) | null | undefined;
  selectedItem: number;
  onMouseOver: (...args: Array<any>) => any;
  onMouseOut?: (...args: Array<any>) => any;
  emptyText?: string;
};

const SimpleItemsList: React.FC<Props> = ({
  items,
  labelFunc = (item: any) => item,
  iconFunc = null,
  onMouseOver = () => {},
  selectedItem = -1,
  onSelect,
  onEdit,
  onDelete,
  emptyText,
  valueFunc,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const selectedItemRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (selectedItemRef.current && containerRef.current) {
      const container = containerRef.current;
      const elem = selectedItemRef.current;

      const elemTop = elem.offsetTop;
      const elemBottom = elemTop + elem.scrollHeight;
      const containerTop = container.scrollTop + container.offsetTop;
      const containerBottom = containerTop + container.clientHeight;
      const isVisible = elemTop > containerTop && elemBottom < containerBottom;
      const scrollToTop = elemTop < containerTop;

      if (!isVisible && typeof container.scroll === 'function') {
        if (scrollToTop) {
          container.scroll(0, elem.offsetTop - container.offsetTop);
        } else {
          container.scroll(
            0,
            elem.offsetTop - container.offsetTop - container.clientHeight + elem.scrollHeight,
          );
        }
      }
    }
  });

  const Actions = useCallback(
    ({ item, visible }: { item: Item; visible: boolean }) => {
      if (!visible) return null;

      return (
        <span className="action-icon-wrapper">
          {onEdit && (
            <AccActionIcon
              size={22}
              className="icn-button"
              onClick={(event: React.SyntheticEvent<any>) => {
                event.preventDefault();
                onEdit(event, item);
              }}
            >
              <IconPencil style={{ height: '20px', width: '20px' }} />
            </AccActionIcon>
          )}
          {onDelete && (
            <AccActionIcon
              size={22}
              className="icn-button"
              onClick={(event: React.SyntheticEvent<any>) => {
                event.preventDefault();
                onDelete(event, item);
              }}
            >
              <IconTrash style={{ height: '20px', width: '20px' }} />
            </AccActionIcon>
          )}
        </span>
      );
    },
    [onDelete, onEdit],
  );

  const renderItems = () => {
    if (!items.length) {
      return <span className="empty">{emptyText || t('No items to display')}</span>;
    }

    return items.map((item, index) => {
      const itemLabel = labelFunc ? labelFunc(item) : item.label;
      const icon = iconFunc?.(item);
      const key = valueFunc?.(item) ?? itemLabel;
      const disableActions = item?.isDemoClient || false;

      // Handle the case when item has it's own select handler
      // to avoid the situation when we need to identify
      // what item was passed to the common onSelect
      const handleItemSelect = () => {
        let result;

        if (item.onSelect) {
          result = item.onSelect();
        }

        if (result !== false) {
          onSelect && onSelect(item);
        }
      };

      const itemProps: DropdownItemProps = {
        tag: 'div',
        onClick: handleItemSelect,
      };

      const { tag: WrapperComponent = 'div', ...resultItemProps } = itemProps;

      return (
        <WrapperComponent
          key={key}
          tabIndex={item.disabled ? -1 : 0}
          {...resultItemProps}
          className={classnames('dropdown-item', item.className, {
            selected: selectedItem === index,
            header: item.header,
            disabled: item.disabled,
          })}
          onMouseOver={() => onMouseOver(index)}
          ref={selectedItem === index ? selectedItemRef : null}
        >
          {icon && <div className="icon-container">{icon}</div>}
          {itemLabel}
          <Actions item={item} visible={!disableActions} />
        </WrapperComponent>
      );
    });
  };

  return (
    <div className="items" ref={containerRef}>
      {renderItems()}
    </div>
  );
};

export default SimpleItemsList;
