import { oneOfOptions } from 'Components/Filters/Common/labelFunc';
import { FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import type { StarredFilter } from 'Types/Filter';
import { t } from 'Utilities/i18n';
import StarIcon from 'icons/star.svg?inline';
import StarredEditor from '.';
import { getItems } from './getItems';
import './star-option.scss';

const defaultValue: StarredFilter = {
  attribute: FilterAttribute.STARRED,
  type: FilterValueType.BOOL,
  comparison: FilterComparison.EQ,
  value: true,
};

const getData = () => {
  const items = getItems();
  return {
    defaultValue,
    title: t('Starred'),
    icon: StarIcon,

    editor: StarredEditor,
    editorProps: {},
    tableEditor: StarredEditor,
    tableEditorProps: {
      iconDropdown: true,
      noFilterIcon: StarIcon,
    },
    labelFunc: oneOfOptions(items as any),
  };
};

export default getData;
