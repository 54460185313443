import { useApolloClient } from '@apollo/client';
import uniqBy from 'lodash/uniqBy';
import { AccMultiSelect, SelectItem } from 'Components/AccSelect';
import {
  extractKeywordsOptionsFromNotes,
  getNotesStaticFilters,
} from 'Components/Filters/Editors/KeywordsList/KeywordsInput/support/helpers';
import { useKeywordsValueOptions } from 'Components/Filters/Editors/KeywordsList/KeywordsInput/support/hooks';
import { KeywordsInputDocument, KeywordsInputQuery, KeywordsInputQueryVariables } from 'Ghql';
import { useDomainId } from 'Hooks/data/domain/useQueryDomainInfo';
import { t } from 'Utilities/i18n';
import OptionComponent from './OptionComponent';
import { DEFAULT_KEYWORDS_ORDERING } from './support/constants';
import './form-keywords-input.scss';

type Props = {
  value: string[] | null;
  fullWidth?: boolean;
  onChange: (val: string[] | null) => void;
  ordering?: KeywordsInputQueryVariables['ordering'];
  valueOptions?: SelectItem<string>[];
  disabled?: boolean;
  domainId?: string;
};

const KeywordsInput = (props: Props) => {
  const domainIdFromHook = useDomainId();
  const domainId = props.domainId || domainIdFromHook;
  const client = useApolloClient();

  const { valueOptions, loading } = useKeywordsValueOptions(
    domainId,
    props.value,
    props.valueOptions,
  );

  const getOptions = (query: string | undefined) => {
    const filters = [
      ...getNotesStaticFilters(domainId),
      {
        attribute: 'keyword',
        type: 'string',
        comparison: 'contains',
        value: query,
      },
    ];
    return client
      .query<KeywordsInputQuery>({
        query: KeywordsInputDocument,
        variables: {
          filters,
          ordering: props.ordering || DEFAULT_KEYWORDS_ORDERING,
        },
      })
      .then(({ data }) => {
        const result = extractKeywordsOptionsFromNotes(data);
        return uniqBy([...result, ...valueOptions], 'value');
      });
  };

  return (
    <AccMultiSelect
      value={props.value}
      optionComponent={OptionComponent}
      loadOptions={getOptions}
      onChange={props.onChange}
      loadingPlaceholderText={t('Fetching keywords')}
      placeholder={t('Type to search for keywords')}
      inputMaxWidth={!props.fullWidth ? 570 : undefined}
      creatable={false}
      isLoading={loading}
      disabled={props.disabled}
      hidePickedOptions={false}
      searchable
      showResultsForEmptySearch
      size="md"
    />
  );
};

export default KeywordsInput;
