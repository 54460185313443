import { useEffect, useRef } from 'react';
import * as Sentry from '@sentry/react';
import AccButton from 'Components/AccButton/AccButton';
import { useUser } from 'Hooks/data/user/useUser';
import { t, tct } from 'Utilities/i18n';
import Icon from 'icons/404.svg';
import '../error-page.scss';

const NotFound = () => {
  const { isAuthenticated } = useUser();
  const messageCaptured = useRef(false);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development' && !messageCaptured.current) {
      Sentry?.captureMessage(`404 Page: "${window.location.pathname}"`);
    }
    messageCaptured.current = true;
  }, []);

  return (
    <div className="error-page">
      <img src={Icon} />
      <h1 className="title">{t('Error')}</h1>
      <p className="description">
        {tct(
          'Unfortunately, the requested page could not be found. Please, try another URL, or [goTo].',
          { goTo: isAuthenticated ? t('return to dashboard') : t('go to login') },
        )}
      </p>
      <AccButton variant="primary" link="/">
        {isAuthenticated ? t('Go to dashboard') : t('Go to login')}
      </AccButton>
    </div>
  );
};

export default NotFound;
