import { useSelector } from 'react-redux';
import { setFilteredPath } from 'Components/Filters/helpers';
import { useSetOrOverwriteFilter } from 'Hooks/data/filter/setFilters';
import { filterGroupSelector } from 'Selectors/FilterGroupsSelector';
import { FilterBase } from 'Types/Filter';
import { FilterTrackingKey } from 'Utilities/Analytics/mixpanel';
import { useRouteMatch } from 'Utilities/Router/hooks/useRouteMatch';
import { useHistory } from 'Utilities/Router/withRouter';

/**
 * Update URL with new filter or add a filter to an unfiltered URL
 * @example
 * const updateUrl = useUpdateUrlFilter();
 * updateUrl(newFilter);
 */
export const useUpdateUrlFilter = () => {
  const match = useRouteMatch();
  const filterGroup = useSelector(filterGroupSelector);
  const pathHasFilter = match.path?.includes(':filter');
  const setOrOverwriteFilter = useSetOrOverwriteFilter();
  const history = useHistory();

  const updateUrl = (newFilters: FilterBase[], trackingKey?: FilterTrackingKey) => {
    if (!pathHasFilter) {
      setFilteredPath(
        newFilters,
        filterGroup.id,
        { ...match, path: `${match.path}/:filter` },
        history,
      );
    } else {
      newFilters.map((newFilter) => setOrOverwriteFilter(newFilter, trackingKey || 'no tracking'));
    }
  };
  return updateUrl;
};
