import { scrollToFirstRow } from 'Components/DataTable/components/footer/Pagination/helpers';
import { TableStoreType } from 'Components/DataTable/store/TableStore';
import { useFilters } from 'Hooks';
import { useUpdateEffect } from 'Hooks/common/useUpdateEffect';

export const useTableFilterSync = (config: {
  tableStore: TableStoreType;
  applyDefaultOrder?: boolean;
}) => {
  const { tableStore, applyDefaultOrder } = config;
  const filters = useFilters();

  useUpdateEffect(() => {
    tableStore?.resetSelection();
    tableStore?.resetPagination();
    if (tableStore?.scrollOnPageChange) {
      scrollToFirstRow(tableStore.tableName);
    }
    if (applyDefaultOrder && tableStore.defaultOrdering) {
      tableStore?.setOrdering(tableStore.defaultOrdering);
    }
    tableStore?.getData();
  }, [JSON.stringify(filters ?? {})]);
};
