import moment from 'moment';
import linkWithFilters from 'Components/Filters/linkWithFilters';
import type { CompareToFilter, PeriodFilter, ShareOfVoiceFilter } from 'Types/Filter';
import { FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import { KEYWORDS_FILTER_SET } from 'Types/FilterSet';

export default function linkToKeywordListFromChart(
  category: any,
  filterAttribute,
  comparison?,
  value?,
) {
  const date = moment(category);
  const today = date.format('YYYY-MM-DD');
  const yesterday = date.subtract(1, 'day').format('YYYY-MM-DD');
  const periodFilter: PeriodFilter = {
    attribute: FilterAttribute.PERIOD,
    type: FilterValueType.DATETIME,
    comparison: FilterComparison.BETWEEN,
    value: JSON.stringify([yesterday, today]),
  };
  const compareToFilter: CompareToFilter = {
    attribute: FilterAttribute.COMPARE_TO,
    type: FilterValueType.DATETIME,
    comparison: FilterComparison.EQ,
    value: yesterday,
  };
  const shareOfVoiceFilter: ShareOfVoiceFilter = {
    attribute: filterAttribute,
    type: FilterValueType.NUMBER,
    comparison: comparison ?? FilterComparison.GT,
    value: value ?? 0,
  };
  return linkWithFilters({
    to: '/keywords/list',
    newFilters: [shareOfVoiceFilter],
    overwriteFilters: [periodFilter, compareToFilter],
    filterSet: KEYWORDS_FILTER_SET,
  });
}
