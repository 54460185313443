import React from 'react';
import { WithRowContext } from 'Components/DataTable';
import FormatNumber from 'Components/FormatNumber/new';
import Cell from './HelperComponents/AccuCell';
import './styles.scss';

type Props = {
  value: {
    data: {
      id: number;
      maxAiTrafficValue: number;
      aiTrafficValue: number;
      rankValue: number;
      validForSearchVolume?: boolean;
    };
    loadingData: boolean;
  };
  colName: string;
  nDecimals?: number;

  updatingKeyword?: any;
} & WithRowContext;

const MaxAiTrafficValueCell = (props: Props): JSX.Element | null => {
  const {
    value: { data },
    nDecimals,
  } = props;
  const { maxAiTrafficValue, validForSearchVolume } = data;

  if (!validForSearchVolume) {
    return null;
  }

  return (
    <Cell rightAligned>
      <FormatNumber
        minimumFractionDigits={nDecimals ?? 0}
        maximumFractionDigits={nDecimals ?? 0}
        value={maxAiTrafficValue}
      />
    </Cell>
  );
};

export default MaxAiTrafficValueCell;
