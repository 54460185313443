import { IconChess } from '@tabler/icons-react';
import { HierarchyPointNode } from 'd3-hierarchy';
import { TreeNodeDatum } from 'Components/Chart/TreeChart';
import { nodeName } from '../support/helpers';
import { removeSpaces } from '../support/legendHelpers';
import styles from '../siteMapping.module.scss';

export const RenderSVGNode = ({
  node,
  selectedNode,
  totalSearchVolume,
  nodeSelectionHandler,
  hoverNodeHandler,
  keywordsToNodeSize,
  colorMap,
  //zoom,
}) => {
  const onSelectNode = (n: HierarchyPointNode<TreeNodeDatum>): void => {
    const s = n.data;
    if (s) nodeSelectionHandler(s);
  };
  const totalKeywords = node.nodeDatum?.kwICT + node.nodeDatum?.kwICU;

  const nodeSize = keywordsToNodeSize(totalKeywords);
  const nodeSearchVolume = Math.max(node?.nodeDatum?.svICT + node?.nodeDatum?.svICU, 10); // Minimum 10 to avoid colors not on colormap
  const color = colorMap(nodeSearchVolume, totalSearchVolume);
  const cpID = `clip${removeSpaces(node.nodeDatum?.pathSegment)}`;
  const fillID = `fill${removeSpaces(node.nodeDatum?.pathSegment)}`;

  const isSelected = node.nodeDatum?.pathSegment === selectedNode?.pathSegment;
  const strokeColor = color; //isSelected ? 'orange' : color;
  const strokeWidth = isSelected ? '4px' : '2px';
  const fontWeight = isSelected ? 700 : 400;
  const fillPercentage = (100 * node.nodeDatum?.kwICT) / totalKeywords;

  const onClickHandler = (e) => {
    if (e.detail === 2) {
      // Double click
      node.toggleNode();
    } else {
      onSelectNode(node.hierarchyPointNode);
    }
  };

  const Fill = () => {
    if (node.nodeDatum?.kwICT > 0) {
      return (
        <linearGradient id={fillID} x1="0.5" y1="1" x2="0.5" y2="0">
          <stop offset="0%" stopColor={color} />
          <stop offset={`${fillPercentage}%`} stopColor={color} />
          <stop offset={`${fillPercentage}%`} stopColor={'white'} />
          <stop offset="100%" stopColor={'white'} color={'white'} />
        </linearGradient>
      );
    }
    return (
      <pattern
        id={fillID}
        width="5"
        height="10"
        patternTransform="rotate(45 0 0)"
        patternUnits="userSpaceOnUse"
      >
        <rect x={0} y={0} width="10" height="10" style={{ fill: 'white', strokeWidth: 0 }} />
        <line x1="0" y1="0" x2="0" y2="10" style={{ stroke: color, strokeWidth: 1 }} />
      </pattern>
    );
  };

  return (
    <g
      id={'sitemapNode'}
      onMouseOver={() => hoverNodeHandler && hoverNodeHandler(node)}
      onMouseOut={() => hoverNodeHandler && hoverNodeHandler(undefined)}
    >
      <clipPath id={cpID}>
        <rect x="0" y="-20" width="405" height="35px" />
      </clipPath>
      <Fill />
      <circle
        strokeWidth={strokeWidth}
        r={nodeSize}
        onClick={onClickHandler}
        fill={`url(#${fillID})`}
        style={{ backgroundColor: 'white' }}
        stroke={strokeColor}
      />
      <g>
        <text
          strokeWidth="0.1"
          x={nodeSize + 25}
          y="8"
          className={styles.treeBranchNode}
          onClick={onClickHandler}
          clipPath={`url(#${cpID})`}
          fontSize={'30px'}
          fontWeight={fontWeight}
          //style={{ fontSize: zoom ? `${12 / zoom}px` : '20px' }}
        >
          {nodeName(node)}
        </text>
        <IconChess
          className={node.nodeDatum?.kwECT === 0 ? styles.iconHidden : styles.iconVisible}
          x={nodeSize}
          y={-13}
          height={24}
        />
      </g>
    </g>
  );
};
