import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import { DomainTypeChoices } from 'Query';

type DomainInfo = {
  id: string;
  domain: string;
  firstRefreshAt: Date;
  lastManualRefreshAt: Date;
  lastScrapedCompleted: Date;
  lastFullScrapeCompleted: Date;
  lastScraped: Date;
  canRefresh: boolean;
  canUpdate: boolean;
  isGAConnected: boolean;
  isGCConnected: boolean;
  isAdobeMarketingConnected: boolean;
  shareOfVoicePercentage: any;
  youtubeChannelName?: string;
  isDemoDomain: boolean;
  domainType: DomainTypeChoices;
};
const domainQuery = gql`
  query generic_domainInfo($id: ID!) {
    domain(id: $id) {
      id
      domain
      youtubeChannelName
      canRefresh
      canUpdate
      lastManualRefreshAt
      firstRefreshAt
      lastScrapedCompleted
      lastFullScrapeCompleted
      lastScraped
      shareOfVoicePercentage
      isDemoDomain
      domainType
      competitors {
        id
        displayName
        domain
        youtubeChannelName
      }
      googleOauthConnectionGa {
        id
      }
      googleOauthConnectionGsc {
        id
      }
      adobeMarketingConnection {
        id
      }
    }
  }
`;

const queryDomainInfo = () => {
  const optionsParser = (props) => {
    return {
      variables: {
        id: props.domainId,
      },
    };
  };

  return graphql(domainQuery, {
    options: optionsParser,
    props: ({ ownProps, data }: any) => {
      const { domain, refetch } = data;
      // TODO CheckLogic
      let domainInfo: DomainInfo;

      if (domain) {
        const firstRefreshAt = new Date(domain.firstRefreshAt);
        const lastScrapedCompleted = new Date(domain.lastScrapedCompleted);
        const lastFullScrapeCompleted = new Date(domain.lastFullScrapeCompleted);
        const lastManualRefreshAt = new Date(domain.lastManualRefreshAt);
        const lastScraped = new Date(domain.lastScraped);
        domainInfo = {
          id: domain.id,
          domain: domain.domain,
          youtubeChannelName: domain.youtubeChannelName,
          firstRefreshAt,
          lastFullScrapeCompleted,
          lastScrapedCompleted,
          lastManualRefreshAt,
          lastScraped,
          canRefresh: domain.canRefresh,
          canUpdate: domain.canUpdate,
          isAdobeMarketingConnected:
            domain.adobeMarketingConnection && domain.adobeMarketingConnection.id,
          isGAConnected: domain.googleOauthConnectionGa && domain.googleOauthConnectionGa.id,
          isGCConnected: domain.googleOauthConnectionGsc && domain.googleOauthConnectionGsc.id,
          shareOfVoicePercentage: domain.shareOfVoicePercentage,
          isDemoDomain: domain.isDemoDomain,
          domainType: domain.domainType,
        };
      }

      // TODO FixTSignore
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return { ...ownProps, domainInfo, refetchDomainInfo: refetch, domainData: data };
    },
    skip: (props) => !props.domainId,
  });
};

export default queryDomainInfo;
