import { useCallback, useState } from 'react';
import { Box, Tabs } from '@mantine/core';
import { IconCloud, IconHome } from '@tabler/icons-react';
import cn from 'classnames';
import { SeriesLegendItemClickEventObject } from 'highcharts';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { SelectItemValueType } from 'Components/AccSelect/support/types';
import { useTableStore } from 'Components/DataTable';
import { linkToKeywordDiscoveryDomain } from 'Components/Filters/LinkToDomain';
import { CountByCountryNode, useCountByCountryQuery, useQuickNavigationDataQuery } from 'Ghql';
import { useSpecificFilter } from 'Hooks';
import { useRemoveFilter, useSetOrOverwriteFilter } from 'Hooks/data/filter/setFilters';
import {
  useHasKeydisAccess,
  useHasWordCloudAccess,
} from 'Hooks/data/organization/useOrganizationInfo';
import { useMetaDataCountrylocales } from 'Hooks/data/user/useMetaData';
import WordCloud from 'Pages/KeywordDiscovery/components/WordCloud';
import { KEYWORD_DISCOVERY, RESEARCH } from 'Pages/Layout/ActionsMenu/support/constants';
import {
  DomainsFilter,
  FilterAttribute,
  FilterComparison,
  FilterValueType,
  FreeTextDomainFilter,
} from 'Types/Filter';
import { TableID, TableIDs } from 'Types/Table';
import { EventName, TrackingKey, useMixpanel } from 'Utilities/Analytics/mixpanel';
import { useRouteMatch } from 'Utilities/Router/hooks/useRouteMatch';
import { useHistory } from 'Utilities/Router/withRouter';
import { t } from 'Utilities/i18n';
import { notEmpty } from 'Utilities/underdash';
import KeywordDiscoveryActionbar from './components/Actionbar';
import { KeyDisActions } from './components/Actions/Actions';
import { DialogOverlay } from './components/DialogOverlay';
import { DomainWithFavicon } from './components/DomainWithFavicon';
import { useSearchHistory } from './components/KeydisDomainSelect/helpers';
import KeywordDiscoveryTable from './components/KeywordDiscoveryTable/KeywordDiscoveryTable';
import { KeywordDiscoveryPlaceholderPage } from './components/Placeholders';
import { ResearchDashboard } from './components/ResearchDashboard';
import { getIsCountByCountryNode, truncateString } from './support/helpers';
import { useKeydisFilterControl } from './support/hooks/useKeydisFilterControl';
import { CountrySelectOption } from './support/types';
import reusableStyles from '../../css/reusable-styles.module.scss';
import styles from './KeywordDiscovery.module.scss';

type PageProps = {
  pageType: typeof KEYWORD_DISCOVERY | typeof RESEARCH;
};

export type SelectedDomain = { domain: string | undefined; domainId: string | undefined };

const KeywordDiscoveryPage = observer(({ pageType }: PageProps) => {
  const trackEvent = useMixpanel();
  const freeTextDomainFilter = useSpecificFilter(FilterAttribute.FREE_TEXT_DOMAIN);
  const competitorDomainFilter = useSpecificFilter(FilterAttribute.COMPETITOR_DOMAIN);
  const domainsFilter = useSpecificFilter(FilterAttribute.DOMAINS);
  const setFilter = useSetOrOverwriteFilter();
  const { addRecentSearch } = useSearchHistory();

  const tableType: TableID =
    pageType === KEYWORD_DISCOVERY ? TableIDs.KEYWORD_DISCOVERY : TableIDs.RESEARCH;

  const tableStore = useTableStore(tableType);
  const match = useRouteMatch();

  const {
    location: { pathname: currentPathname },
    push: navigate,
    replace,
  } = useHistory();

  //make sure we never set the domainsFilter & freeTextDomainFilter at the same time
  const removeFilter = useRemoveFilter();
  if (freeTextDomainFilter && domainsFilter) {
    removeFilter(FilterAttribute.FREE_TEXT_DOMAIN);
  }

  const { filters, competitor } = useKeydisFilterControl();

  //only render the table when in viewport

  const [selectedDomain, setSelectedDomain] = useState<SelectedDomain>({
    domain: competitorDomainFilter?.value || freeTextDomainFilter?.value,
    domainId: undefined,
  });

  const [invalidDomainError, setInvalidDomainError] = useState<string | undefined>(undefined);

  const [openedResearchPanel, setOpenedResearchPanel] = useState(true);

  //Filterplaceholders used when we the dialogOverlay is visible and we do not add filters to the path
  const freeTextDomainFilterPlaceholder: FreeTextDomainFilter = {
    attribute: FilterAttribute.FREE_TEXT_DOMAIN,
    type: FilterValueType.STRING,
    comparison: FilterComparison.CONTAINS,
    value: selectedDomain.domain || '',
  };

  const domainsFilterPlaceholder: DomainsFilter = {
    attribute: FilterAttribute.DOMAINS,
    type: FilterValueType.LIST,
    comparison: FilterComparison.CONTAINS,
    value: [selectedDomain.domainId || ''],
  };

  const filterPlaceholder = selectedDomain.domainId
    ? domainsFilterPlaceholder
    : freeTextDomainFilterPlaceholder;

  const pathHasFilter = match.path?.includes(':filter');

  const activeFilters = pathHasFilter ? filters : [filterPlaceholder];

  const skip = !pathHasFilter && !selectedDomain.domain;

  const countrylocales = useMetaDataCountrylocales();

  const [selectedCountry, setSelectedCountry] = useState<CountByCountryNode | undefined>(undefined);
  const [countrySelectData, setCountrySelectData] =
    useState<CountrySelectOption[] | undefined>(undefined);

  const { data, loading } = useCountByCountryQuery({
    variables: {
      filters: activeFilters,
    },
    skip,
    onCompleted: (res) => {
      //If we get no countries back the domain search didn't return any results
      if (
        (!res.countByCountry?.countries?.length && tableType === 'research') ||
        (tableType === 'keywordDiscovery' && res.countByCountry?.countries?.[0]?.keywords === 0)
      ) {
        const activeFreeTextDomainFilter = activeFilters.find(
          (activeFilter) => activeFilter?.attribute === FilterAttribute.FREE_TEXT_DOMAIN,
        ) as FreeTextDomainFilter | undefined;
        //trunkate long urls
        const searchedDomain = activeFreeTextDomainFilter?.value;
        //set selectedDomain to undefined if the overlay is visible to make sure you cannot go forward from the dialog without a valid domain
        !pathHasFilter && setSelectedDomain({ domain: undefined, domainId: undefined });
        setInvalidDomainError(
          searchedDomain
            ? t('No results found for %s!', truncateString(searchedDomain, 55))
            : t('No results found for this domain!'),
        );
      } else {
        setInvalidDomainError(undefined);

        const _countrySelectData = res?.countByCountry?.countries?.filter(notEmpty).map((el) => {
          const region = countrylocales?.find(
            (c) => c?.countryCode?.toLowerCase() === el.country,
          )?.region;
          return {
            value: el.country!,
            label: region ?? undefined,
            region,
            keywords: el?.keywords,
          };
        });

        setCountrySelectData(_countrySelectData);
        if (!selectedCountry) {
          //get country from query parameter:
          const urlParams = new URLSearchParams(window.location.search);
          const countryParam = urlParams.get('country');

          const preSelectedCountry: CountByCountryNode | undefined = countryParam
            ? res?.countByCountry?.countries
                ?.filter(getIsCountByCountryNode)
                .find((el) => el?.country === countryParam)
            : undefined;

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setSelectedCountry(
            preSelectedCountry ||
              (res?.countByCountry?.countries?.find((el) => el?.default) as CountByCountryNode) ||
              undefined,
          );

          preSelectedCountry && replace(currentPathname);
        }
      }
    },
  });

  const { loading: domainsLoading, data: quickNavigationData } = useQuickNavigationDataQuery({
    fetchPolicy: 'cache-first',
  });

  const clients = quickNavigationData?.user?.quickNavigationData?.filter(notEmpty) ?? [];

  const countryChoice = selectedCountry?.country || undefined;

  const handleSetSelectedCountry = (value: SelectItemValueType | null) => {
    const newCountry = data?.countByCountry?.countries
      ?.filter(getIsCountByCountryNode)
      .find((el) => el?.country === value);
    newCountry && setSelectedCountry(newCountry);
  };

  const hasWordCloudAccess = useHasWordCloudAccess();

  const handleNavigateToKeydis = (domainNavigationId: string) => {
    const country = selectedCountry?.country;
    //add queryparam for selected country when navigating
    const countryParam = country ? { search: `?country=${country}` } : {};

    navigate(
      {
        pathname: linkToKeywordDiscoveryDomain(domainNavigationId),
        ...countryParam,
      },
      { replace: true },
    );
  };

  const handleSetDomainFromChart = useCallback(
    (event: SeriesLegendItemClickEventObject, clickedDomain: string | undefined) => {
      //check if the domain is one of the users own domains
      const ownDomainId = clients.find((client) =>
        client?.domains?.find((clientDomain) => clientDomain?.domain === clickedDomain),
      )?.id;

      const isOwnDomain = !!ownDomainId;

      if (!clickedDomain) {
        return;
      } else if (pageType === KEYWORD_DISCOVERY) {
        if (isOwnDomain) {
          setFilter(
            {
              attribute: FilterAttribute.DOMAINS,
              type: FilterValueType.LIST,
              comparison: FilterComparison.CONTAINS,
              value: [clickedDomain],
            },
            'no tracking',
          );
          trackEvent(EventName.ButtonClick, {
            Domain: clickedDomain,
            'Tracking Key': TrackingKey.NavigateToDomainFromBubbleChart,
          });
          setSelectedDomain({ domain: clickedDomain, domainId: ownDomainId });
        } else {
          addRecentSearch(clickedDomain);
          setFilter(
            {
              attribute: FilterAttribute.COMPETITOR_DOMAIN,
              type: FilterValueType.STRING,
              comparison: FilterComparison.EQ,
              value: clickedDomain,
            },
            'no tracking',
          );
          trackEvent(EventName.ButtonClick, {
            Domain: clickedDomain,
            'Tracking Key': TrackingKey.NavigateToDomainFromBubbleChart,
          });
          setSelectedDomain({ domain: clickedDomain, domainId: undefined });
        }
      } else if (
        (event?.browserEvent?.target as HTMLButtonElement | null)?.classList.contains(
          'researchDomain',
        )
      ) {
        event.preventDefault();
        if (isOwnDomain) {
          setFilter(
            {
              attribute: FilterAttribute.DOMAINS,
              type: FilterValueType.LIST,
              comparison: FilterComparison.CONTAINS,
              value: [clickedDomain],
            },
            'no tracking',
          );
          setSelectedDomain({ domain: clickedDomain, domainId: ownDomainId });
          removeFilter(FilterAttribute.FREE_TEXT_DOMAIN);
          handleNavigateToKeydis(clickedDomain);
        } else {
          addRecentSearch(clickedDomain);
          setFilter(
            {
              attribute: FilterAttribute.FREE_TEXT_DOMAIN,
              type: FilterValueType.STRING,
              comparison: FilterComparison.CONTAINS,
              value: clickedDomain,
            },
            'no tracking',
          );
          removeFilter(FilterAttribute.DOMAINS);
          setSelectedDomain({ domain: clickedDomain, domainId: undefined });
        }
      }
    },
    [],
  );

  const ownDomains = clients?.flatMap((client) =>
    client?.domains
      ?.filter((clientDomain) => clientDomain?.id === domainsFilter?.value?.[0])
      ?.filter(notEmpty),
  );

  const ownDomain = ownDomains?.[0];
  const [activeTab, setActiveTab] = useState<string | null>('Dashboard');

  return (
    <>
      {!pathHasFilter && (
        <DialogOverlay
          countryLoading={loading}
          selectedDomain={selectedDomain}
          setSelectedDomain={setSelectedDomain}
          countrySelectData={countrySelectData}
          selectedCountry={selectedCountry}
          handleSetSelectedCountry={handleSetSelectedCountry}
          countryChoice={countryChoice}
          domainsLoading={domainsLoading}
          invalidDomainError={invalidDomainError}
          setInvalidDomainError={setInvalidDomainError}
          navigateToKeydis={handleNavigateToKeydis}
        />
      )}
      <div>
        <KeyDisActions
          selectedDomain={selectedDomain}
          setSelectedDomain={setSelectedDomain}
          selectedCountry={selectedCountry}
          domainIsSelected={pathHasFilter}
          tableType={tableType}
          menuFor={pageType}
          tableStore={tableStore}
          countrySelectData={countrySelectData}
          countryChoice={countryChoice}
          onCountryChange={handleSetSelectedCountry}
          openedResearchPanel={openedResearchPanel}
          invalidDomainError={invalidDomainError}
          setInvalidDomainError={setInvalidDomainError}
          navigateToKeydis={handleNavigateToKeydis}
          competitorDomainFilter={competitorDomainFilter}
          addRecentSearch={addRecentSearch}
        />
        {hasWordCloudAccess ? (
          <Tabs mt="md" value={activeTab} onChange={setActiveTab} keepMounted={false}>
            <Tabs.List>
              <Tabs.Tab value="Dashboard" leftSection={<IconHome size="16px" />}>
                {t('Dashboard')}
              </Tabs.Tab>
              <Tabs.Tab value="Word Cloud" leftSection={<IconCloud size="16px" />}>
                {t('Word Cloud')}
              </Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="Dashboard" className={styles.tabPanel}>
              <>
                <ResearchDashboard
                  keywordsCardDomainSlot={
                    ownDomain && competitorDomainFilter ? (
                      <DomainWithFavicon ownDomain={ownDomain} />
                    ) : undefined
                  }
                  countryChoice={countryChoice}
                  selectedCountry={selectedCountry}
                  tableType={tableType}
                  domainIsSelected={pathHasFilter}
                  selectedCount={toJS(tableStore?.totalSelectedCount) || 0}
                  openedResearchPanel={openedResearchPanel}
                  setOpenedResearchPanel={setOpenedResearchPanel}
                  setDomain={handleSetDomainFromChart}
                />
                <KeywordDiscoveryActionbar tableType={tableType} />
                <Box className={styles.contentContainer}>
                  <div
                    id="table-scroll-reference"
                    className={cn(styles.tableContainer, reusableStyles.paper)}
                  >
                    <KeywordDiscoveryTable
                      selectedCountry={selectedCountry}
                      competitor={competitor}
                      tableType={tableType}
                    />
                  </div>
                </Box>
              </>
            </Tabs.Panel>
            <Tabs.Panel value="Word Cloud" pt={0}>
              <WordCloud
                switchTab={() => {
                  setActiveTab('Dashboard');
                }}
                country={countryChoice}
              />
            </Tabs.Panel>
          </Tabs>
        ) : (
          <>
            <ResearchDashboard
              keywordsCardDomainSlot={
                ownDomain && competitorDomainFilter ? (
                  <DomainWithFavicon ownDomain={ownDomain} />
                ) : undefined
              }
              countryChoice={countryChoice}
              selectedCountry={selectedCountry}
              tableType={tableType}
              domainIsSelected={pathHasFilter}
              selectedCount={toJS(tableStore?.totalSelectedCount) || 0}
              openedResearchPanel={openedResearchPanel}
              setOpenedResearchPanel={setOpenedResearchPanel}
              setDomain={handleSetDomainFromChart}
            />
            <KeywordDiscoveryActionbar tableType={tableType} />
            <Box
              id="table-scroll-reference"
              className={cn(styles.tableContainer, reusableStyles.paper)}
            >
              <KeywordDiscoveryTable
                selectedCountry={selectedCountry}
                competitor={competitor}
                tableType={tableType}
              />
            </Box>
          </>
        )}
      </div>
    </>
  );
});

/**
 * Show a placeholder for trials or flagged plans (OrganizationPlanNode.hasKeydisAccess).
 * Show the regular Keyword Discovery page for all others.
 */
const KeywordDiscoveryComponent = ({ pageType }: Partial<PageProps>) => {
  const hasKeydisAccess = useHasKeydisAccess();

  if (hasKeydisAccess) {
    return <KeywordDiscoveryPage pageType={pageType || KEYWORD_DISCOVERY} />;
  }
  return <KeywordDiscoveryPlaceholderPage />;
};

export default KeywordDiscoveryComponent;
