import {
  GroupItem,
  GroupsDomainItem,
} from 'Components/Controls/Dropdowns/GroupsAndDomainsDropdown';
import { linkToPageWithDomains } from 'Components/Filters/LinkToDomain';
import { KEYWORDS_FILTER_SET } from 'Types/FilterSet';
import { notEmpty } from 'Utilities/underdash';

const makeDomainsLink = (domainIds: string[], page?: string) => {
  return linkToPageWithDomains(
    page || '/keywords/overview',
    domainIds,
    KEYWORDS_FILTER_SET,
    [],
    !page,
  );
};

export const getDomainLink = (domainObj: GroupsDomainItem, page?: string) =>
  makeDomainsLink([domainObj.id], page);

export const getGroupLink = (group: GroupItem) => makeDomainsLink(group?.domainIds || []);

type Client = {
  clientId: number;
  clientName: string;
  domains?: {
    id: number;
    domain: string;
    displayName?: string | null;
  }[];
};

export const buildGroups = (clients: Client[] = []): GroupItem[] => {
  return clients.map((client) => {
    const domainIds = client.domains?.map((domain) => domain?.id).filter(notEmpty) || [];
    return {
      id: client?.clientId,
      displayName: client?.clientName,
      domainIds: domainIds.map((x) => x.toString()),
      domains:
        client?.domains?.map((domain) => ({
          id: domain?.id.toString(),
          domain: domain?.domain,
          displayName: domain?.displayName || '',
        })) || [],
    };
  });
};
