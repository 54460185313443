import SettingsMenu from './components/Menu';
import styles from './domain-settings.module.scss';

const DomainSettings = (): JSX.Element => {
  return (
    <div className={styles.container}>
      <SettingsMenu />
    </div>
  );
};

export default DomainSettings;
