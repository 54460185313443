import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import uniqueId from 'lodash/uniqueId';
import Ellipsis from 'Components/Ellipsis';
import StarIcon from 'icons/star.svg?inline';
import AccTooltip from '../../AccTooltip/AccTooltip';
import { CheckmarkIcon } from './checkmarkIcon';
import './checkbox.scss';

export type CheckboxKind = 'default' | 'star' | 'toggle' | 'custom';

export type DefaultChecked = boolean | 'indeterminate';

interface CheckedState {
  checked: boolean | undefined;
  previous: boolean | undefined;
}

export type CheckboxProps = {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  children?: React.ReactNode;
  /**
   * "Indeterminate" is a partly selected state.
   *
   * Read more
   * {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/checkbox#indeterminate_state_checkboxes|mdn web docs}
   */
  defaultChecked?: DefaultChecked | undefined;
  kind?: CheckboxKind;
  onBlur?: (...args: unknown[]) => void;
  disabled?: boolean;
  className?: string;
  ellipsis?: boolean;
  tooltip?: string;
  name?: string;
  value?: string | boolean;
  customIconRenderer?: React.FC<{ checked?: boolean; [key: string]: unknown }>;
};
const Checkbox = (props: PropsWithChildren<CheckboxProps>) => {
  const {
    onChange,
    checked,
    defaultChecked,
    children = null,
    kind = 'default',
    onBlur,
    disabled = false,
    className,
    ellipsis,
    tooltip,
    name,
    value,
    customIconRenderer,
    ...rest
  } = props;

  const id = uniqueId('checkbox');
  const tooltipId = uniqueId('tooltip');

  //redux form will pass a boolean value
  const checkboxChecked = typeof value === 'boolean' ? value : checked;

  const [checkedState, setCheckedState] = useState<CheckedState>({
    checked: defaultChecked === true ? true : checkboxChecked,
    //checkedState.previous is always undefined on first checkbox state
    previous: undefined,
  });

  //defaultChecked can only be true on first state
  const isDefaultChecked =
    checkedState.previous === undefined && defaultChecked === true ? true : undefined;

  //state can only be indeterminate on first checked state
  const indeterminate = checkedState.previous === undefined && defaultChecked === 'indeterminate';

  //check if this is the first render of the component
  const isFirst = useRef(true);

  useEffect(() => {
    //return on first render
    if (isFirst.current) {
      isFirst.current = false;
      return;
    }
    setCheckedState((previousState) => ({
      checked: previousState.previous ? checked : previousState.checked,
      previous: previousState.checked,
    }));
  }, [checked, value, disabled]);

  const wrapEllipsis = (node?: React.ReactNode) => {
    return ellipsis ? <Ellipsis>{node}</Ellipsis> : node;
  };

  const handleBlur = () => {
    onBlur && onBlur();
  };

  let iconContent: JSX.Element | null = null;
  const CustomIconRenderer = customIconRenderer;

  switch (kind) {
    case 'star':
      iconContent = <StarIcon id={tooltipId} data-testid="star" />;
      break;

    case 'toggle':
      iconContent = (
        <div className="toggle-button" id={tooltipId}>
          <span className="slider" />
        </div>
      );
      break;
    case 'custom':
      if (CustomIconRenderer) {
        iconContent = <CustomIconRenderer checked={isDefaultChecked || checkboxChecked} />;
      }
      break;

    default:
      iconContent = (
        <CheckmarkIcon
          className="checkbox-icon"
          id={tooltipId}
          indeterminate={indeterminate}
          checked={indeterminate ? false : isDefaultChecked || checkboxChecked}
        />
      );
      break;
  }

  return (
    <label
      htmlFor={id}
      className={cn(`checkbox checkbox-kind-${kind || 'default'}`, className, {
        'has-label': children,
        disabled,
        ellipsis,
      })}
    >
      <AccTooltip tooltip={tooltip} placement="top-start">
        <input
          {...rest}
          id={id}
          name={name}
          value={typeof value === 'string' ? value : undefined}
          type="checkbox"
          disabled={disabled}
          className={className}
          checked={isDefaultChecked || checkboxChecked}
          onBlur={handleBlur}
          onChange={onChange}
        />
      </AccTooltip>
      {iconContent}
      {wrapEllipsis(children)}
    </label>
  );
};

export default Checkbox;
