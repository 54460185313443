import { Component } from 'react';
import { Box } from '@mantine/core';
import { Field } from 'redux-form';
import { DropdownField, FoldersField } from 'Components/Forms/Fields';
import { FilterComparison } from 'Types/Filter';
import { t } from 'Utilities/i18n/index';
import Validation from 'Utilities/validation';

const comparisonOptions = [
  {
    id: FilterComparison.EXACT_FOLDER,
    label: 'Exact folder',
  },
  {
    id: FilterComparison.FOLDER_OR_SUBFOLDER,
    label: 'Folder or subfolder',
  },
];

class FoldersEditor extends Component {
  render() {
    return (
      <div>
        <Box w={270}>
          <Field
            autoFocus
            name="value"
            component={FoldersField}
            placeholder={t('Select folder(s)')}
            creatable={false}
            validate={[Validation.array]}
          />
        </Box>
        <Field name="comparison" component={DropdownField} items={comparisonOptions} />
      </div>
    );
  }
}

export default FoldersEditor;
