import { stringLabelFunc } from 'Components/Filters/Common/labelFunc';
import { FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import type { LocationFilter } from 'Types/Filter';
import { t } from 'Utilities/i18n/index';
import LocationIcon from 'icons/location.svg?inline';
import NoFilterIcon from 'icons/no-filter.svg?inline';
import LocationEditor from '.';

const defaultValue: LocationFilter = {
  attribute: FilterAttribute.LOCATION,
  type: FilterValueType.STRING,
  comparison: FilterComparison.CONTAINS,
  value: '',
};

const getData = () => ({
  defaultValue,
  title: t('Location'),
  icon: LocationIcon,
  editor: LocationEditor,
  editorProps: {
    placeholder: t('Select location'),
    withFilterCount: true,
  },
  tableEditor: LocationEditor,
  tableEditorProps: {
    placeholder: t('Location'),
    noFilterIcon: NoFilterIcon,
  },
  labelFunc: stringLabelFunc,
});

export default getData;
