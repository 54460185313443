/* eslint-disable import/no-unused-modules */
export type ItemType = 'group' | 'domain' | 'folder' | 'tag';
export const folderSeparator = '¦';
export const generateSelectId = (
  type: ItemType,
  id: string | number,
  path?: string,
  isDynamicTag?: boolean,
): string => {
  switch (type) {
    case 'domain':
    case 'folder':
      return `${path}${folderSeparator}${id}`;
    case 'tag':
      return `${path}${folderSeparator}${id}${
        isDynamicTag && id.toString().slice(-1) !== 'Ỽ' ? 'Ỽ' : ''
      }`;
    default:
      return `${id}`;
  }
};

/**
 * Checks if a selectId is a domain selectId.
 * @param selectId - The full selectId string.
 * @returns True if the selectId is a domain selectId, false otherwise.
 */
export const selectIdIsDomain = (selectId: string) => {
  const structure = selectId.split(folderSeparator);
  // domain id is the second element in the array
  return structure.length === 2;
};

/**
 * Extracts the domain ID from a selectId.
 * @param selectId - The full selectId string.
 * @returns The domain ID, or null if the selectId doesn't contain a domain ID.
 */
export const getDomainId = (selectId: string) => {
  const structure = selectId.split(folderSeparator);
  return structure.length > 1 ? structure[1] : '';
};

/**
 * Extracts the group ID from a selectId.
 * @param selectId - The full selectId string.
 * @returns The group ID, or null if the selectId doesn't contain a group ID.
 */
export const getGroupId = (selectId: string) => {
  return selectId.split(folderSeparator)?.[0] || null;
};

/**
 * Extracts the folder or tag path from a selectId.
 * @param selectId - The full selectId string.
 * @returns The folder or tag path, or null if the selectId doesn't contain a path.
 */
export const getFolderTagPath = (selectId: string) => {
  const structure = selectId.split(folderSeparator);
  // remove the first two elements: group id and domain id
  return structure.length > 2 ? structure.slice(2).join(folderSeparator) : null;
};

/**
 * Gets a portion of a folder path based on depth from the end.
 * @param path - The full folder path string.
 * @param depth - Optional. The number of path segments to include from the end.
 * @returns The folder path truncated to the specified depth, or the full path if no depth specified.
 */
export const getFolderPathByDepth = (path: string, depth?: number) => {
  return depth
    ? path
        ?.split(folderSeparator)
        .slice(-1 * depth)
        .join(folderSeparator)
    : path;
};

/**
 * Checks if a selectId represents a group.
 * @param selectId - The full selectId string to check.
 * @returns True if the selectId represents a group (has only one segment), false otherwise.
 */
export const isGroup = (selectId: string) => {
  return selectId.split(folderSeparator).length === 1;
};
