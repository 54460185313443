import { memo } from 'react';
import { Checkbox } from '@mantine/core';
import { IconPencil, IconTrash } from '@tabler/icons-react';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import AccessControl from 'Components/AccessControl';
import AccText from 'Components/Text/AccText';
import { GroupItem, GroupsDomainItem } from '.';
import styles from './search-dropdown.module.scss';

type Props = {
  setOpened: (val: boolean) => void;
  onDelete?: (...args: Array<any>) => any;
  onEdit?: (...args: Array<any>) => any;
  onSelect?: (...args: Array<any>) => any;
  style?: React.CSSProperties;
  item?: GroupsDomainItem | GroupItem;
  /** Render a checkbox on the row */
  renderCheckbox?: boolean;
  /** Selected state used for checkboxes */
  selected?: boolean;
  closeOnSelect?: boolean;
};

const DropdownRow = (props: Props) => {
  const {
    item,
    setOpened,
    onDelete,
    onEdit,
    onSelect,
    style,
    renderCheckbox = false,
    closeOnSelect = true,
  } = props;

  const domainURL = item && 'domain' in item ? item.domain : undefined;

  return (
    <div
      tabIndex={renderCheckbox ? -1 : 0}
      className={styles.row}
      style={style}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onSelect?.(item);
          closeOnSelect && setOpened(false);
        }
      }}
      onClick={() => {
        onSelect?.(item);
        closeOnSelect && setOpened(false);
      }}
    >
      {renderCheckbox && (
        <Checkbox
          color="gray.9"
          checked={props.selected}
          // silence Mantine errors
          onChange={() => {}}
          classNames={{ root: styles.checkbox, input: styles.checkboxInput }}
        />
      )}
      {domainURL && (
        <img
          className="favicon"
          style={{ height: 16, width: 16 }}
          src={`https://app.accuranker.com/screenshots/favicon?url=${domainURL}&size=24`}
        />
      )}
      <AccText overflowEllipsis nowrap size="sm" fw={600}>
        {item?.displayName || domainURL}
      </AccText>
      {domainURL && (
        <AccText overflowEllipsis nowrap size="sm" fw={300}>
          {domainURL}
        </AccText>
      )}
      {item && (
        <div className={styles.groupData}>
          {'domains' in item && (
            <div className={styles.groupStats}>{item.domains?.length} Domains</div>
          )}
        </div>
      )}
      <AccessControl>
        <div className={styles.actionIcons}>
          <AccActionIcon
            tabIndex={0}
            size={22}
            onClick={(event) => {
              event.preventDefault();
              onEdit?.(event, item);
            }}
          >
            <IconPencil size={20} />
          </AccActionIcon>
          <AccActionIcon
            tabIndex={0}
            size={22}
            onClick={(event) => {
              event.preventDefault();
              onDelete?.(event, item);
            }}
          >
            <IconTrash size={20} />
          </AccActionIcon>
        </div>
      </AccessControl>
    </div>
  );
};

export default memo(DropdownRow);
