import { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'lodash/flowRight';
import { hideModal } from 'Actions/ModalAction';
import { t } from '../../../../Utilities/i18n';
import ModalBorder from '../../Layout/ModalBorder';
import PhoneVerifyForm from './form';

type Props = {
  hideModal: (...args: Array<any>) => any;
  unconfirmedUserId: string;
  phoneNumberVerificationSid: string;
  phone: string;
  callback: (...args: Array<any>) => any;
};

class RegisterPhoneVerify extends Component<Props> {
  render() {
    const { unconfirmedUserId, phoneNumberVerificationSid, phone, callback } = this.props;
    return (
      <ModalBorder
        className="verify-phone"
        title={t('Please verify your phone number')}
        onClose={this.props.hideModal}
      >
        <PhoneVerifyForm
          key={unconfirmedUserId}
          unconfirmedUserId={unconfirmedUserId}
          phoneNumberVerificationSid={phoneNumberVerificationSid}
          initialValues={{
            channel: 'sms',
            phoneNumber: phone,
          }}
          callback={callback}
        />
      </ModalBorder>
    );
  }
}

export default compose(
  connect(null, {
    hideModal,
  }),
)(RegisterPhoneVerify);
