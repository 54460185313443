import React, { PropsWithChildren, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSmartRouterSubscription } from 'Utilities/Router/components/LocationPathnameContext';
import { getIsResponsivePage } from './helpers';

/**
 * We have some pages that are responsive (for example Login) and other are not.
 */
const AppContainer = React.memo(({ children }: PropsWithChildren) => {
  const isResponsivePage = useSmartRouterSubscription((pathname) => getIsResponsivePage(pathname));

  useEffect(() => {
    if (document.body.style) {
      // always show scrollbar placeholder to avoid jumps
      document.body.style.overflowY = isResponsivePage ? '' : 'scroll';
      document.body.style.overflowX = isResponsivePage ? '' : 'hidden';
    }
  }, [isResponsivePage]);

  const mainStyle: React.CSSProperties = {
    minWidth: isResponsivePage ? undefined : '1200px',
    height: '100%',
  };

  return (
    <div style={mainStyle}>
      <Helmet>
        <meta
          name="viewport"
          content={
            isResponsivePage
              ? 'width=device-width, initial-scale=1.0'
              : 'width=1200, maximum-scale=1.0'
          }
        />
      </Helmet>
      {children}
    </div>
  );
});

AppContainer.displayName = 'AppContainer';

export default React.memo(AppContainer);
