import isFunction from 'lodash/isFunction';
import isNil from 'lodash/isNil';
import uniq from 'lodash/uniq';
import { configure, makeAutoObservable, runInAction, toJS } from 'mobx';
import { TableID } from 'Types/Table';
import { appStorage } from 'Utilities/AppStorage';
import { devError } from 'Utilities/log';
import { SelectRowType, TableSize } from '../constants';
import { ColumnOrderingState, PaginationState, TableFetchDataCallBack } from '../types';

configure({ enforceActions: 'observed' });

type InitTableStore = {
  defaultOrdering?: ColumnOrderingState;
  fetchData?: TableFetchDataCallBack;
  defaultSelected?: string[];
  onSelectToggle?: (value: string, checked: boolean) => void;
  initialRowSelect?: (data: any[]) => string[];
  selectRowType?: SelectRowType;
  maxSelectCount?: number;
  disableOnMaxSelect?: boolean;
  enableSelectAll?: boolean;
  pageSize?: number;
  scrollOnPageChange?: boolean;
  viewMode?: TableSize;
  skipSaveOrdering?: boolean;
  syncSelected?: boolean;
};

const expandRowConfig = {
  columnIds: ['rank', 'url'],
  rowKeys: [],
};

export const DEFAULT_PAGE_SIZE = 100;

const stores: { [key: string]: InstanceType<typeof TableStore> } = {};

class TableStore {
  constructor(init: InitTableStore, tableName: TableID) {
    this.tableName = tableName;
    this.defaultOrdering = init.defaultOrdering;
    this.requireInvalidateCache = false;
    this.ordering = init.defaultOrdering;
    this.scrollOnPageChange = init.scrollOnPageChange ?? true;
    this.selectRowType = init.selectRowType ?? SelectRowType.ONE_PAGE_SELECT;
    this.maxSelectCount = init.maxSelectCount ?? undefined;
    this.disableOnMaxSelect = init.disableOnMaxSelect ?? false;
    this.enableSelectAll = init.enableSelectAll ?? true;
    this.fetchData = init.fetchData || this.fetchData;
    this.initialRowSelect = init.initialRowSelect;
    this.syncSelected = init.syncSelected;

    const localConfig = appStorage.getTablesStore(tableName);
    this.viewMode = localConfig?.viewMode ?? init?.viewMode ?? TableSize.DEFAULT;
    if (init?.pageSize) {
      this.pagination = {
        startIndex: 0,
        stopIndex: init?.pageSize,
        page: 1,
        results: 0,
      };
      this.paginationSize = init.pageSize;
    }

    if (init?.onSelectToggle && isFunction(init?.onSelectToggle)) {
      this.onSelectToggle = init.onSelectToggle;
    }
    if (!init.skipSaveOrdering && localConfig?.ordering?.order && localConfig?.ordering?.orderBy) {
      this.ordering = localConfig.ordering;
    }
    if (init.defaultSelected) {
      this.selectedRows = init.defaultSelected;
      this.defaultSelectedRows = init.defaultSelected;
    }
    if (init.skipSaveOrdering) {
      this.skipSaveOrdering = init.skipSaveOrdering;
    }
    makeAutoObservable(this, { onSelectToggle: false, syncSelected: false });
  }

  saveInStorage = () => {
    if (!this.skipSaveOrdering) {
      setTimeout(() => {
        appStorage.setTableStore(this.tableName, {
          viewMode: this.viewMode,
          ordering: this.ordering,
        });
      }, 0);
    }
  };
  paginationSize: number = DEFAULT_PAGE_SIZE;
  pagination: PaginationState = {
    startIndex: 0,
    stopIndex: DEFAULT_PAGE_SIZE,
    page: 1,
    results: 0,
  };
  tableName: TableID;
  requireInvalidateCache?: boolean = false;
  selectRowType: SelectRowType;
  maxSelectCount?: number;
  disableOnMaxSelect?: boolean;
  enableSelectAll?: boolean;
  initialDataLoaded: boolean = false;
  data: any[] = [];
  initialRowSelect?: (data: any[]) => string[];
  // stored items that where added or remove through websockets
  updateInfo = {
    added: 0,
    removed: 0,
  };
  scrollOnPageChange?: boolean;
  totalDataLength: number = 0;
  loading: boolean = true;
  viewMode: TableSize = TableSize.DEFAULT;
  ordering?: ColumnOrderingState = undefined;
  defaultOrdering?: ColumnOrderingState = undefined;
  expandedRows: string[] = [];
  // Used to show additional rows (separated by columns) for example different ranks in rank table
  expandRowConfig: { columnIds: string[]; rowKeys: string[] } = expandRowConfig;
  fetchData: TableFetchDataCallBack<any> = () => {
    throw new Error('Provide fetchData for table to make it working');
  };
  getParams: any;

  isAllPageSelected: boolean = false;
  isAllSelected: boolean = false;
  selectedRows: string[] = [];
  defaultSelectedRows: string[] = [];
  unSelectedRows: string[] = [];
  onSelectToggle?: (value: string, checked: boolean) => void | undefined;

  skipSaveOrdering?: boolean = false;
  syncSelected?: boolean = false;

  get hasMaxSelect() {
    // return true if maxSelectCount is set and maxSelectCount is less than data length
    return !!this.maxSelectCount && this.maxSelectCount < this.totalDataLength;
  }

  get singleRowSelect() {
    return this.selectRowType === SelectRowType.ONE_PAGE_SELECT;
  }

  get isPageSelected(): boolean {
    if (this.singleRowSelect) {
      return this.isAllPageSelected;
    }
    return this.data?.length
      ? this.data?.every((e) => this.selectedRows?.includes(e?.id?.toString()))
      : false;
  }

  get isGroupSelected() {
    return (
      this.isAllSelected ||
      this.isPageSelected ||
      (this.maxSelectCount && this.totalSelectedCount >= this.maxSelectCount)
    );
  }

  get pageSelected() {
    return this.isAllPageSelected
      ? (this.data?.length ?? 0) - (this.unSelectedRows?.length ?? 0)
      : 0;
  }

  get manualSelectedKeywords() {
    return this.isAllPageSelected
      ? this.data
          ?.map((e) => e.id)
          .filter((id) => !toJS(this.unSelectedRows)?.includes(id.toString()))
      : this.isAllSelected
      ? this.unSelectedRows
      : this.selectedRows;
  }

  selectAll = () => {
    runInAction(() => {
      this.isAllSelected = true;
      this.isAllPageSelected = false;
      this.selectedRows = [];
      this.unSelectedRows = [];
    });
  };

  selectAllPage = () => {
    runInAction(() => {
      this.isAllPageSelected = true;
      this.isAllSelected = false;

      if (this.selectRowType === SelectRowType.ONE_PAGE_SELECT) {
        this.selectedRows = [];
        this.unSelectedRows = [];
      } else if (this.selectRowType === SelectRowType.MULTI_PAGE_SELECT) {
        this.selectedRows = uniq([
          ...(this.selectedRows || []),
          ...(this.data.map((e) => e.id) || []),
        ]);
        this.unSelectedRows = [];
      }
    });
  };

  getIsInMaxCountRange = (rowId: string, index?: number) => {
    const maxCount = this.maxSelectCount ?? 0;
    if (!isNil(index)) {
      return index < maxCount;
    }
    const itemIndex = this.data.findIndex((e) => this.getIsEqualId(e?.id, rowId));
    const pageMax = (this.pagination.page - 1) * this.paginationSize;
    let position = pageMax + itemIndex;
    if (itemIndex !== -1) {
      position = this.data?.[itemIndex]?.index ?? position;
    }

    return position < maxCount;
  };

  getIsRowSelected = (rowId: string | number, index?: number) => {
    if (this.isAllSelected || (this.isAllPageSelected && this.singleRowSelect)) {
      const isUnselected = this.unSelectedRows
        ?.map((unselectedId) => unselectedId?.toString())
        .includes(rowId?.toString());
      if (isUnselected) {
        return false;
      } else if (this.maxSelectCount) {
        return (
          this.getIsInMaxCountRange(rowId?.toString(), index) ||
          this.selectedRows?.includes(rowId?.toString())
        );
      } else if (this.singleRowSelect) {
        return true;
      }
    }
    return this.selectedRows?.includes(rowId?.toString());
  };

  get currentMaxSelectCount(): number | undefined {
    if (!this.maxSelectCount) {
      return this.maxSelectCount;
    }
    return Math.min(this.maxSelectCount ?? 0, this.totalDataLength);
  }

  get totalSelectedCount(): number {
    let result;
    if (this.isAllPageSelected && this.singleRowSelect) {
      result = (this.data?.length ?? 0) - (this.unSelectedRows?.length ?? 0);
    } else if (this.isAllSelected) {
      result =
        (this.currentMaxSelectCount ?? this.totalDataLength) -
        (this.unSelectedRows?.length ?? 0) +
        (this.selectedRows?.length ?? 0);
    } else {
      result = this.selectedRows?.length;
    }

    return result;
  }

  resetSelection = (force?: boolean) => {
    runInAction(() => {
      this.isAllPageSelected = false;
      this.isAllSelected = false;

      if (!this.defaultSelectedRows || !this.singleRowSelect || force) {
        this.selectedRows = [];
      }
      this.unSelectedRows = [];
    });
  };

  toggleRowSelected = (_rowId: unknown) => {
    const rowId = (_rowId as any)?.toString() ?? null;
    if (!rowId) {
      devError('Row is empty, please provide rowId');
      return;
    }

    if (this.isAllSelected || (this.isAllPageSelected && this.singleRowSelect)) {
      runInAction(() => {
        if (this.singleRowSelect || (this.maxSelectCount && this.getIsInMaxCountRange(rowId))) {
          this.unSelectedRows = this.unSelectedRows.includes(rowId)
            ? this.unSelectedRows.filter((e) => e !== rowId)
            : [...(this.unSelectedRows || []), rowId];
        } else {
          this.selectedRows = this.selectedRows.includes(rowId)
            ? this.selectedRows.filter((e) => e !== rowId)
            : [...(this.selectedRows || []), rowId];
        }

        if (this.singleRowSelect) {
          if (this.isAllSelected) {
            this.isAllSelected = false;
            this.isAllPageSelected = true;
          }
        }
      });
    } else {
      runInAction(() => {
        const selected = this.selectedRows.includes(rowId);
        this.selectedRows = selected
          ? this.selectedRows.filter((e) => e !== rowId)
          : [...(this.selectedRows || []), rowId];
        this.onSelectToggle?.(rowId, !selected);
      });
    }
  };

  changePaginationSize = (paginationSize: number) => {
    if (this.paginationSize !== paginationSize) {
      this.paginationSize = paginationSize;
      this.pagination = { startIndex: 0, stopIndex: paginationSize, page: 1, results: 0 };
      this.getData();
    }
  };

  updateChangeInfo = ({ added, removed }: { added?: number; removed?: number }) => {
    runInAction(() => {
      if (added) {
        this.updateInfo.added += added;
      }
      if (removed) {
        this.updateInfo.removed += removed;
      }
    });
  };

  resetChangeInfo = () => {
    if (this.updateInfo.added || this.updateInfo.removed) {
      this.updateInfo = { added: 0, removed: 0 };
    }
  };

  changePage = (page: number): boolean => {
    if (this.pagination.page !== page) {
      runInAction(() => {
        if (this.selectRowType === SelectRowType.ONE_PAGE_SELECT) {
          this.resetSelection();
        }
        this.pagination.page = page;
        this.pagination.startIndex = (page - 1) * this.paginationSize;
        this.pagination.stopIndex = page * this.paginationSize;
      });
      return true;
    }
    return false;
  };

  toggleExpandRowConfig = (id: string) => {
    this.expandRowConfig.rowKeys = this.expandRowConfig.rowKeys?.includes(id)
      ? this.expandRowConfig.rowKeys.filter((e) => e !== id)
      : [...this.expandRowConfig.rowKeys, id];
  };

  toggleExpandRow = (id: string) => {
    this.expandedRows = this.expandedRows?.includes(id)
      ? this.expandedRows.filter((e) => e !== id)
      : [...this.expandedRows, id];
  };

  setTableViewMode = (viewMode: TableSize) => {
    if (this.viewMode !== viewMode) {
      this.viewMode = viewMode;
      this.saveInStorage();
    }
  };

  setData = (setData: (a: any[]) => any[]) => {
    const prevLength = this.data.length;
    const nextData = setData(toJS(this.data));

    if (prevLength !== nextData?.length) {
      const removedItemsCount = prevLength - nextData?.length;
      this.totalDataLength -= removedItemsCount;
    }
    this.data = nextData;
  };

  resetOrdering = (defaultOrdering?: ColumnOrderingState) => {
    this.ordering = defaultOrdering ?? toJS(this.defaultOrdering);
    this.saveInStorage();
  };

  resetPagination = () => {
    this.pagination = { startIndex: 0, stopIndex: this.paginationSize, page: 1, results: 0 };
  };

  resetState = () => {
    this.resetPagination();
    this.resetChangeInfo();
    this.resetSelection();
    runInAction(() => {
      this.loading = true;
      this.data = [];
      this.totalDataLength = 0;
      if (this.defaultSelectedRows || !this.singleRowSelect) {
        this.selectedRows = [];
      }
      this.expandedRows = [];
      this.expandRowConfig = expandRowConfig;
    });
  };

  startLoading = () => {
    runInAction(() => {
      this.loading = true;
    });
  };

  setFetchData = (fetchMethod: TableFetchDataCallBack) => {
    runInAction(() => {
      this.fetchData = fetchMethod;
    });
  };

  applyInitialSelectedRows = (data = this.data) => {
    if (this.initialRowSelect) {
      this.selectedRows = this.initialRowSelect(data) ?? [];

      if (this.selectedRows?.length) {
        this.isAllPageSelected = this.selectedRows?.length === data?.length;
      }
    }
  };

  getData = async (force?: boolean, silently?: boolean) => {
    try {
      if (!silently) {
        runInAction(() => {
          this.loading = true;
          this.resetChangeInfo();
        });
      }
      const { data, length, skip } = await this.fetchData({
        ordering: toJS(this.ordering)!,
        pagination: toJS(this.pagination),
        force,
        tableStore: this,
      });

      if (skip) {
        this.loading = false;
        return;
      }
      const startTime = new Date().getTime();
      runInAction(() => {
        if (!this.initialDataLoaded && this.initialRowSelect) {
          this.applyInitialSelectedRows(data);
        }
        this.initialDataLoaded = true;
        this.data = this.formatData(data);
        this.totalDataLength = length ?? 0;
        this.loading = false;
        this.resetChangeInfo();
        if (this.syncSelected) {
          this.selectedRows = this.filterSelectedByCurrentData(this.data);
        }
      });
      console.info(`Time took: ${new Date().getTime() - startTime}`);
    } finally {
      if (this.loading) {
        this.loading = false;
      }
    }
  };

  filterSelectedByCurrentData = (data) => {
    return this.selectedRows.filter((id) => data?.some((item) => this.getIsEqualId(item?.id, id)));
  };

  setOrderingSilently = (ordering: ColumnOrderingState) => {
    runInAction(() => {
      if (this.ordering) {
        this.ordering.order = ordering.order;
        this.ordering.orderBy = ordering.orderBy;
      } else {
        this.ordering = ordering;
      }
    });
  };

  setOrdering = (ordering: ColumnOrderingState) => {
    runInAction(() => {
      this.resetSelection();
      if (this.ordering) {
        this.ordering.order = ordering.order;
        this.ordering.orderBy = ordering.orderBy;
      } else {
        this.ordering = ordering;
      }
      this.pagination = { startIndex: 0, stopIndex: this.paginationSize, page: 1, results: 0 };
    });
    this.getData().then(() => this.applyInitialSelectedRows());
    this.saveInStorage();
  };

  updateRowData = (rowId: string) => (patch: any) => {
    this.data = this.data.map((e) =>
      (this.getIsEqualId(e.id, rowId) ? { ...toJS(e), ...patch } : e),
    );
  };

  updateItems = (patches) => {
    runInAction(() => {
      this.requireInvalidateCache = true;
      this.data = this.data.map((e) => {
        const itemPatch = patches?.find((patch) => this.getIsEqualId(e?.id, patch?.id));
        if (itemPatch) {
          return { ...toJS(e), ...itemPatch };
        }
        return e;
      });
    });
  };

  updateSelectedItems = (updateFn: (data: any) => any) => {
    this.requireInvalidateCache = true;
    this.data?.forEach((e) => {
      if (this.getIsRowSelected(e?.id.toString())) {
        return updateFn(e);
      }
    });
  };

  private formatData = (data) => {
    return data.map((e, i) => {
      const index = i + (this.pagination.page - 1) * this.paginationSize;
      return {
        ...e,
        index,
      };
    });
  };

  getIsEqualId = (id?: string | number, secondId?: string | number) => {
    return id?.toString() === secondId?.toString();
  };

  deleteItem = (id: string) => {
    const lengthBefore = this.data?.length;
    runInAction(() => {
      this.data = this.data?.filter((e) => !this.getIsEqualId(e?.id, id));
      this.selectedRows = this.selectedRows?.filter((e) => !this.getIsEqualId(e, id));
      this.totalDataLength = this.totalDataLength - (lengthBefore - this.data?.length || 0);
      this.requireInvalidateCache = true;
    });
  };

  deleteSelectedItems = () => {
    runInAction(() => {
      this.requireInvalidateCache = true;
      const lengthBefore = this.data?.length;
      this.data = this.data?.filter((e) => !this.getIsRowSelected(e?.id));
      this.resetSelection(true);
      this.totalDataLength = this.totalDataLength - (lengthBefore - this.data?.length || 0);
    });
  };
}

export type TableStoreType = InstanceType<typeof TableStore>;

export const deleteTableStore = (id: string) => {
  delete stores[id];
};

export const clearStore = (id: string) => {
  deleteTableStore(id);
  stores[id]?.resetState?.();
};

/**
 * Used to store subscriptions, in case we delay register of tableStore
 */
const subscriptions: Record<string, Function[] | undefined> = {};

/**
 * Subscribe to add tableStore event, and called with it value, also return unsubscribe function
 */
export const subscribeToTableStore = (tableId: string, callback: Function) => {
  subscriptions[tableId] = [...(subscriptions[tableId] || []), callback];
  return function unsubscribe() {
    if (subscriptions[tableId]?.length) {
      subscriptions[tableId] = subscriptions[tableId]?.filter((e) => e !== callback);
    }
  };
};

const notifySubscriptions = (tableId, tableStore) => {
  subscriptions[tableId]?.forEach((callback) => callback(tableStore));
};

export const getTableStore = (id: TableID, init?: InitTableStore, onlyIfExist?: boolean) => {
  let store = stores[id];

  if (!store) {
    if (onlyIfExist || !init) {
      return null; // Please don't set init to {} if its undefined!
    }
    store = new TableStore(init, id);
    stores[id] = store;
    notifySubscriptions(id, store);
  }

  return store;
};

export const resetAllTablesOrdering = () => {
  Object.keys(stores).map((key) => {
    stores[key].resetOrdering();
  });
};
