import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Flex } from '@mantine/core';
import { IconClipboardCopy, IconRefresh } from '@tabler/icons-react';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import {
  UpdateMyUserInput,
  useEditProfileApiTokenQuery,
  useEditProfileResetApiTokenMutation,
} from 'Ghql';
import { useClipboard } from 'Hooks';
import { useModal } from 'Hooks/base/useModal';
import { StoreType } from 'Types/Store';
import { t } from 'Utilities/i18n/index';
import formStyles from 'css/layout/form-layout.module.scss';

const IntegrationsApiToken = () => {
  const user = useSelector((state: StoreType) => state.user);
  const { fullName, email, language, defaultKeywordsPage, defaultCompareTo } = user;
  const APIToken = useEditProfileApiTokenQuery();
  const [resetAPIToken] = useEditProfileResetApiTokenMutation();

  const { showModal } = useModal();

  const clipboard = useClipboard({ timeout: 1000 });

  const handleResetAPIToken = async () => {
    const updateMyUserInput: UpdateMyUserInput = {
      fullName: fullName as string,
      email: email as string,
      language: language as string,
      defaultKeywordsPage,
      defaultCompareTo,
      resetApiToken: true,
    };
    resetAPIToken({
      variables: {
        updateMyUserInput,
      },
    }).then(() => APIToken.refetch());
  };

  if (APIToken.loading || APIToken.error) {
    return null;
  }

  const openConfirmationModal = () => {
    showModal({
      modalType: 'Confirmation',
      modalProps: {
        showExclamationInTitle: false,
        title: t('Refresh API Token?'),
        lockDuration: 0,
        description: t(
          'Are you sure you want to refresh the token? \n This will invalidate the current token.',
        ),
        cancelLabel: t('Cancel'),
        confirmLabel: t('Refresh'),
        action: () => handleResetAPIToken(),
      },
    });
  };
  const copyToken = () => {
    if (APIToken?.data?.user?.apiToken) {
      clipboard.copy(APIToken.data.user.apiToken);
    }
  };
  return (
    <div className={formStyles.formRow}>
      <div className="form-label">{t('API Token')}</div>
      <Flex direction="row" gap="md" align="center">
        <AccTooltip
          delay={0}
          tooltip={clipboard.copied ? t('copied') : t('Copy token to clipboard')}
        >
          <AccActionIcon
            px={8}
            variant="subtle"
            miw="min-content"
            style={{ aspectRatio: 'unset' }}
            onClick={copyToken}
          >
            {APIToken?.data?.user?.apiToken}
          </AccActionIcon>
        </AccTooltip>
        <AccTooltip
          delay={0}
          tooltip={clipboard.copied ? t('copied') : t('Copy token to clipboard')}
        >
          <AccActionIcon variant="subtle" color="gray.7" onClick={copyToken}>
            <IconClipboardCopy size={18} />
          </AccActionIcon>
        </AccTooltip>
        <AccTooltip delay={0} tooltip={t('Refresh API token')}>
          <AccActionIcon variant="subtle" onClick={openConfirmationModal} color="gray.7">
            <IconRefresh size={18} />
          </AccActionIcon>
        </AccTooltip>
      </Flex>
      <Link to="/api" className="small">
        {t('Click here to find more information about our API')}
      </Link>
    </div>
  );
};

export default IntegrationsApiToken;
