import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import { MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import { Notifications } from '@mantine/notifications';
import '@mantine/notifications/styles.css';
import config from 'config';
import { App } from 'AppDeclaration';
import ErrorBoundary from 'Components/ErrorBoundary';
import { apolloClient, store } from 'Store';
import { tweakNodeOperations } from 'Utilities/tweaks';
import 'Utilities/websocket';
import { mantineTheme } from 'css/mantine/theme';
import './css/app.scss';

tweakNodeOperations();

// in react-router v5 it was redirecting from url / to /app(basename) automatically in 6 version - they dropped this functionality,
// so we need to do it manually also can be handled by server https://github.com/remix-run/react-router/issues/8427
if (config.basename && !window.location?.pathname?.includes(config.basename)) {
  window.history?.replaceState('', '', config.basename + window.location.pathname);
}

const root = createRoot(document.getElementById('root')!);

root.render(
  <ErrorBoundary page={true}>
    <HelmetProvider>
      <Provider store={store}>
        <MantineProvider theme={mantineTheme} defaultColorScheme="light">
          <Notifications
            position="bottom-right"
            zIndex={2077}
            autoClose={4000}
            notificationMaxHeight={300}
          />
          <ApolloProvider client={apolloClient as any}>
            <App />
          </ApolloProvider>
        </MantineProvider>
      </Provider>
    </HelmetProvider>
  </ErrorBoundary>,
);
