import { CreateWorkspaceInput, useCreateWorkspaceMutation } from 'Ghql';
import toast from 'Hooks/useToast';
import { EventName, TrackingKey, useMixpanel } from 'Utilities/Analytics/mixpanel';
import { t } from 'Utilities/i18n';
import { mapFinalFormErrors } from 'Utilities/validation';

export const useAddWorkspace = (
  refresh?: (...args: Array<any>) => any,
  onClose?: (...args: Array<any>) => void,
) => {
  const trackEvent = useMixpanel();
  const [createWorkspace] = useCreateWorkspaceMutation();

  const handleSubmit = ({ name, description, clients, users }: CreateWorkspaceInput) => {
    const roleInput = {
      name,
      description: description || '',
      clients: clients?.map(Number) || [],
      users: users?.map(Number) || [],
    };
    return createWorkspace({
      variables: {
        roleInput,
      },
    }).then((result) => {
      const errors = result.data?.createWorkspace?.errors;
      if (errors && errors.length) {
        toast.error('Something went wrong');
        const errorObjects = errors
          .filter((err) => err)
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          .map((err) => ({ field: err!.field, messages: err!.messages }));
        trackEvent(EventName.WorkspacePage, {
          'Tracking key': TrackingKey.AddFail,
          Message: errors[0]?.messages[0] ?? '',
        });
        return mapFinalFormErrors(errorObjects);
      }
      trackEvent(EventName.WorkspacePage, { 'Tracking key': TrackingKey.AddSuccess });
      toast.success(`${t('Added Workspace:')} ${name}`);

      refresh?.();
      onClose?.();
    });
  };
  return handleSubmit;
};
