import * as Sentry from '@sentry/react';
import ADFlag from './FlagIcons/AD.svg';
import AEFlag from './FlagIcons/AE.svg';
import AFFlag from './FlagIcons/AF.svg';
import AGFlag from './FlagIcons/AG.svg';
import AIFlag from './FlagIcons/AI.svg';
import ALFlag from './FlagIcons/AL.svg';
import AMFlag from './FlagIcons/AM.svg';
import AOFlag from './FlagIcons/AO.svg';
import AQFlag from './FlagIcons/AQ.svg';
import ARFlag from './FlagIcons/AR.svg';
import ASFlag from './FlagIcons/AS.svg';
import ATFlag from './FlagIcons/AT.svg';
import AUFlag from './FlagIcons/AU.svg';
import AWFlag from './FlagIcons/AW.svg';
import AXFlag from './FlagIcons/AX.svg';
import AZFlag from './FlagIcons/AZ.svg';
import BAFlag from './FlagIcons/BA.svg';
import BBFlag from './FlagIcons/BB.svg';
import BDFlag from './FlagIcons/BD.svg';
import BEFlag from './FlagIcons/BE.svg';
import BFFlag from './FlagIcons/BF.svg';
import BGFlag from './FlagIcons/BG.svg';
import BHFlag from './FlagIcons/BH.svg';
import BIFlag from './FlagIcons/BI.svg';
import BJFlag from './FlagIcons/BJ.svg';
import BLFlag from './FlagIcons/BL.svg';
import BMFlag from './FlagIcons/BM.svg';
import BNFlag from './FlagIcons/BN.svg';
import BOFlag from './FlagIcons/BO.svg';
import BRFlag from './FlagIcons/BR.svg';
import BSFlag from './FlagIcons/BS.svg';
import BTFlag from './FlagIcons/BT.svg';
import BVFlag from './FlagIcons/BV.svg';
import BWFlag from './FlagIcons/BW.svg';
import BYFlag from './FlagIcons/BY.svg';
import BZFlag from './FlagIcons/BZ.svg';
import CAFlag from './FlagIcons/CA.svg';
import CCFlag from './FlagIcons/CC.svg';
import CDFlag from './FlagIcons/CD.svg';
import CFFlag from './FlagIcons/CF.svg';
import CGFlag from './FlagIcons/CG.svg';
import CHFlag from './FlagIcons/CH.svg';
import CIFlag from './FlagIcons/CI.svg';
import CKFlag from './FlagIcons/CK.svg';
import CLFlag from './FlagIcons/CL.svg';
import CMFlag from './FlagIcons/CM.svg';
import CNFlag from './FlagIcons/CN.svg';
import COFlag from './FlagIcons/CO.svg';
import CRFlag from './FlagIcons/CR.svg';
import CUFlag from './FlagIcons/CU.svg';
import CVFlag from './FlagIcons/CV.svg';
import CWFlag from './FlagIcons/CW.svg';
import CXFlag from './FlagIcons/CX.svg';
import CYFlag from './FlagIcons/CY.svg';
import CZFlag from './FlagIcons/CZ.svg';
import DEFlag from './FlagIcons/DE.svg';
import DJFlag from './FlagIcons/DJ.svg';
import DKFlag from './FlagIcons/DK.svg';
import DMFlag from './FlagIcons/DM.svg';
import DOFlag from './FlagIcons/DO.svg';
import DZFlag from './FlagIcons/DZ.svg';
import ECFlag from './FlagIcons/EC.svg';
import EEFlag from './FlagIcons/EE.svg';
import EGFlag from './FlagIcons/EG.svg';
import EHFlag from './FlagIcons/EH.svg';
import ERFlag from './FlagIcons/ER.svg';
import ESFlag from './FlagIcons/ES.svg';
import ETFlag from './FlagIcons/ET.svg';
import FIFlag from './FlagIcons/FI.svg';
import FJFlag from './FlagIcons/FJ.svg';
import FKFlag from './FlagIcons/FK.svg';
import FMFlag from './FlagIcons/FM.svg';
import FOFlag from './FlagIcons/FO.svg';
import FRFlag from './FlagIcons/FR.svg';
import GAFlag from './FlagIcons/GA.svg';
import GBFlag from './FlagIcons/GB.svg';
import GDFlag from './FlagIcons/GD.svg';
import GEFlag from './FlagIcons/GE.svg';
import GFFlag from './FlagIcons/GF.svg';
import GGFlag from './FlagIcons/GG.svg';
import GHFlag from './FlagIcons/GH.svg';
import GIFlag from './FlagIcons/GI.svg';
import GLFlag from './FlagIcons/GL.svg';
import GMFlag from './FlagIcons/GM.svg';
import GNFlag from './FlagIcons/GN.svg';
import GPFlag from './FlagIcons/GP.svg';
import GQFlag from './FlagIcons/GQ.svg';
import GRFlag from './FlagIcons/GR.svg';
import GSFlag from './FlagIcons/GS.svg';
import GTFlag from './FlagIcons/GT.svg';
import GUFlag from './FlagIcons/GU.svg';
import GWFlag from './FlagIcons/GW.svg';
import GYFlag from './FlagIcons/GY.svg';
import HKFlag from './FlagIcons/HK.svg';
import HMFlag from './FlagIcons/HM.svg';
import HNFlag from './FlagIcons/HN.svg';
import HRFlag from './FlagIcons/HR.svg';
import HTFlag from './FlagIcons/HT.svg';
import HUFlag from './FlagIcons/HU.svg';
import IDFlag from './FlagIcons/ID.svg';
import IEFlag from './FlagIcons/IE.svg';
import ILFlag from './FlagIcons/IL.svg';
import IMFlag from './FlagIcons/IM.svg';
import INFlag from './FlagIcons/IN.svg';
import IOFlag from './FlagIcons/IO.svg';
import IQFlag from './FlagIcons/IQ.svg';
import IRFlag from './FlagIcons/IR.svg';
import ISFlag from './FlagIcons/IS.svg';
import ITFlag from './FlagIcons/IT.svg';
import JEFlag from './FlagIcons/JE.svg';
import JMFlag from './FlagIcons/JM.svg';
import JOFlag from './FlagIcons/JO.svg';
import JPFlag from './FlagIcons/JP.svg';
import KEFlag from './FlagIcons/KEFlag.svg';
import KGFlag from './FlagIcons/KG.svg';
import KHFlag from './FlagIcons/KH.svg';
import KIFlag from './FlagIcons/KI.svg';
import KMFlag from './FlagIcons/KM.svg';
import KNFlag from './FlagIcons/KN.svg';
import KPFlag from './FlagIcons/KP.svg';
import KRFlag from './FlagIcons/KR.svg';
import KWFlag from './FlagIcons/KW.svg';
import KYFlag from './FlagIcons/KY.svg';
import KZFlag from './FlagIcons/KZ.svg';
import LAFlag from './FlagIcons/LA.svg';
import LBFlag from './FlagIcons/LB.svg';
import LCFlag from './FlagIcons/LC.svg';
import LIFlag from './FlagIcons/LI.svg';
import LKFlag from './FlagIcons/LK.svg';
import LRFlag from './FlagIcons/LR.svg';
import LSFlag from './FlagIcons/LS.svg';
import LTFlag from './FlagIcons/LT.svg';
import LUFlag from './FlagIcons/LU.svg';
import LVFlag from './FlagIcons/LV.svg';
import LYFlag from './FlagIcons/LY.svg';
import MAFlag from './FlagIcons/MA.svg';
import MCFlag from './FlagIcons/MC.svg';
import MDFlag from './FlagIcons/MD.svg';
import MEFlag from './FlagIcons/ME.svg';
import MFFlag from './FlagIcons/MF.svg';
import MGFlag from './FlagIcons/MG.svg';
import MHFlag from './FlagIcons/MH.svg';
import MKFlag from './FlagIcons/MK.svg';
import MLFlag from './FlagIcons/ML.svg';
import MMFlag from './FlagIcons/MM.svg';
import MNFlag from './FlagIcons/MN.svg';
import MOFlag from './FlagIcons/MO.svg';
import MPFlag from './FlagIcons/MP.svg';
import MQFlag from './FlagIcons/MQ.svg';
import MRFlag from './FlagIcons/MR.svg';
import MSFlag from './FlagIcons/MS.svg';
import MTFlag from './FlagIcons/MTFlag.svg';
import MUFlag from './FlagIcons/MU.svg';
import MVFlag from './FlagIcons/MV.svg';
import MWFlag from './FlagIcons/MW.svg';
import MXFlag from './FlagIcons/MX.svg';
import MYFlag from './FlagIcons/MY.svg';
import MZFlag from './FlagIcons/MZ.svg';
import NAFlag from './FlagIcons/NA.svg';
import NCFlag from './FlagIcons/NC.svg';
import NEFlag from './FlagIcons/NE.svg';
import NFFlag from './FlagIcons/NF.svg';
import NGFlag from './FlagIcons/NG.svg';
import NIFlag from './FlagIcons/NI.svg';
import NLFlag from './FlagIcons/NL.svg';
import NOFlag from './FlagIcons/NO.svg';
import NPFlag from './FlagIcons/NP.svg';
import NRFlag from './FlagIcons/NR.svg';
import NUFlag from './FlagIcons/NU.svg';
import NZFlag from './FlagIcons/NZ.svg';
import OMFlag from './FlagIcons/OM.svg';
import PAFlag from './FlagIcons/PA.svg';
import PEFlag from './FlagIcons/PE.svg';
import PFFlag from './FlagIcons/PF.svg';
import PGFlag from './FlagIcons/PG.svg';
import PHFlag from './FlagIcons/PH.svg';
import PKFlag from './FlagIcons/PK.svg';
import PLFlag from './FlagIcons/PL.svg';
import PMFlag from './FlagIcons/PM.svg';
import PNFlag from './FlagIcons/PN.svg';
import PRFlag from './FlagIcons/PR.svg';
import PSFlag from './FlagIcons/PS.svg';
import PTFlag from './FlagIcons/PT.svg';
import PWFlag from './FlagIcons/PW.svg';
import PYFlag from './FlagIcons/PY.svg';
import QAFlag from './FlagIcons/QA.svg';
import REFlag from './FlagIcons/RE.svg';
import ROFlag from './FlagIcons/RO.svg';
import RSFlag from './FlagIcons/RS.svg';
import RUFlag from './FlagIcons/RU.svg';
import RWFlag from './FlagIcons/RW.svg';
import SAFlag from './FlagIcons/SA.svg';
import SBFlag from './FlagIcons/SB.svg';
import SCFlag from './FlagIcons/SC.svg';
import SDFlag from './FlagIcons/SD.svg';
import SEFlag from './FlagIcons/SE.svg';
import SGFlag from './FlagIcons/SG.svg';
import SHFlag from './FlagIcons/SH.svg';
import SIFlag from './FlagIcons/SI.svg';
import SJFlag from './FlagIcons/SJ.svg';
import SKFlag from './FlagIcons/SK.svg';
import SLFlag from './FlagIcons/SL.svg';
import SMFlag from './FlagIcons/SM.svg';
import SNFlag from './FlagIcons/SN.svg';
import SOFlag from './FlagIcons/SO.svg';
import SRFlag from './FlagIcons/SR.svg';
import SSFlag from './FlagIcons/SS.svg';
import STFlag from './FlagIcons/ST.svg';
import SVFlag from './FlagIcons/SV.svg';
import SXFlag from './FlagIcons/SX.svg';
import SYFlag from './FlagIcons/SY.svg';
import SZFlag from './FlagIcons/SZ.svg';
import TCFlag from './FlagIcons/TC.svg';
import TDFlag from './FlagIcons/TD.svg';
import TFFlag from './FlagIcons/TF.svg';
import TGFlag from './FlagIcons/TG.svg';
import THFlag from './FlagIcons/TH.svg';
import TJFlag from './FlagIcons/TJ.svg';
import TKFlag from './FlagIcons/TK.svg';
import TLFlag from './FlagIcons/TL.svg';
import TMFlag from './FlagIcons/TM.svg';
import TNFlag from './FlagIcons/TN.svg';
import TOFlag from './FlagIcons/TO.svg';
import TRFlag from './FlagIcons/TR.svg';
import TTFlag from './FlagIcons/TT.svg';
import TVFlag from './FlagIcons/TV.svg';
import TWFlag from './FlagIcons/TW.svg';
import TZFlag from './FlagIcons/TZ.svg';
import UAFlag from './FlagIcons/UA.svg';
import UGFlag from './FlagIcons/UG.svg';
import UMFlag from './FlagIcons/UM.svg';
import USFlag from './FlagIcons/US.svg';
import UYFlag from './FlagIcons/UY.svg';
import UZFlag from './FlagIcons/UZ.svg';
import VAFlag from './FlagIcons/VA.svg';
import VCFlag from './FlagIcons/VC.svg';
import VEFlag from './FlagIcons/VE.svg';
import VGFlag from './FlagIcons/VG.svg';
import VIFlag from './FlagIcons/VI.svg';
import VNFlag from './FlagIcons/VN.svg';
import VUFlag from './FlagIcons/VU.svg';
import WFFlag from './FlagIcons/WF.svg';
import WSFlag from './FlagIcons/WS.svg';
import YEFlag from './FlagIcons/YE.svg';
import YTFlag from './FlagIcons/YTFlag.svg';
import ZAFlag from './FlagIcons/ZA.svg';
import ZMFlag from './FlagIcons/ZM.svg';
import ZWFlag from './FlagIcons/ZW.svg';

const flagIconMap: Record<string, string> = {
  /**Afghanistan*/
  AF: AFFlag,
  /**Åland Islands*/
  AX: AXFlag,
  /**Albania*/
  AL: ALFlag,
  /**Algeria*/
  DZ: DZFlag,
  /**American Samoa*/
  AS: ASFlag,
  /**Andorra*/
  AD: ADFlag,
  /**Angola*/
  AO: AOFlag,
  /**Anguilla*/
  AI: AIFlag,
  /**Antarctica*/
  AQ: AQFlag,
  /**Antigua and Barbuda*/
  AG: AGFlag,
  /**Argentina*/
  AR: ARFlag,
  /**Armenia*/
  AM: AMFlag,
  /**Aruba*/
  AW: AWFlag,
  /**Australia*/
  AU: AUFlag,
  /**Austria*/
  AT: ATFlag,
  /**Azerbaijan*/
  AZ: AZFlag,
  /**Bahamas*/
  BS: BSFlag,
  /**Bahrain*/
  BH: BHFlag,
  /**Bangladesh*/
  BD: BDFlag,
  /**Barbados*/
  BB: BBFlag,
  /**Belarus*/
  BY: BYFlag,
  /**Belgium*/
  BE: BEFlag,
  /**Belize*/
  BZ: BZFlag,
  /**Benin*/
  BJ: BJFlag,
  /**Bermuda*/
  BM: BMFlag,
  /**Bhutan*/
  BT: BTFlag,
  /**Bolivia (Plurinational State of)*/
  BO: BOFlag,
  /**Bonaire, Sint Eustatius and Saba*/
  BQ: '', // <------------------------------TODO ADD MISSING FLAG
  /**Bosnia and Herzegovina*/
  BA: BAFlag,
  /**Botswana*/
  BW: BWFlag,
  /**Bouvet Island*/
  BV: BVFlag,
  /**Brazil*/
  BR: BRFlag,
  /**British Indian Ocean Territory*/
  IO: IOFlag,
  /**Brunei Darussalam*/
  BN: BNFlag,
  /**Bulgaria*/
  BG: BGFlag,
  /**Burkina Faso*/
  BF: BFFlag,
  /**Burundi*/
  BI: BIFlag,
  /**Cabo Verde*/
  CV: CVFlag,
  /**Cambodia*/
  KH: KHFlag,
  /**Cameroon*/
  CM: CMFlag,
  /**Canada*/
  CA: CAFlag,
  /**Cayman Islands*/
  KY: KYFlag,
  /**Central African Republic*/
  CF: CFFlag,
  /**Chad*/
  TD: TDFlag,
  /**Chile*/
  CL: CLFlag,
  /**China*/
  CN: CNFlag,
  /**Christmas Island*/
  CX: CXFlag,
  /**Cocos (Keeling) Islands*/
  CC: CCFlag,
  /**Colombia*/
  CO: COFlag,
  /**Comoros*/
  KM: KMFlag,
  /**Congo*/
  CG: CGFlag,
  /**Congo (the Democratic Republic of the)*/
  CD: CDFlag,
  /**Cook Islands*/
  CK: CKFlag,
  /**Costa Rica*/
  CR: CRFlag,
  /**Côte d'Ivoire*/
  CI: CIFlag,
  /**Croatia*/
  HR: HRFlag,
  /**Cuba*/
  CU: CUFlag,
  /**Curaçao*/
  CW: CWFlag,
  /**Cyprus*/
  CY: CYFlag,
  /**Czechia*/
  CZ: CZFlag,
  /**Denmark*/
  DK: DKFlag,
  /**Djibouti*/
  DJ: DJFlag,
  /**Dominica*/
  DM: DMFlag,
  /**Dominican Republic*/
  DO: DOFlag,
  /**Ecuador*/
  EC: ECFlag,
  /**Egypt*/
  EG: EGFlag,
  /**El Salvador*/
  SV: SVFlag,
  /**Equatorial Guinea*/
  GQ: GQFlag,
  /**Eritrea*/
  ER: ERFlag,
  /**Estonia*/
  EE: EEFlag,
  /**Eswatini*/
  SZ: SZFlag,
  /**Ethiopia*/
  ET: ETFlag,
  /**Falkland Islands (Malvinas)*/
  FK: FKFlag,
  /**Faroe Islands*/
  FO: FOFlag,
  /**Fiji*/
  FJ: FJFlag,
  /**Finland*/
  FI: FIFlag,
  /**France*/
  FR: FRFlag,
  /**French Guiana*/
  GF: GFFlag,
  /**French Polynesia*/
  PF: PFFlag,
  /**French Southern Territories*/
  TF: TFFlag,
  /**Gabon*/
  GA: GAFlag,
  /**Gambia*/
  GM: GMFlag,
  /**Georgia*/
  GE: GEFlag,
  /**Germany*/
  DE: DEFlag,
  /**Ghana*/
  GH: GHFlag,
  /**Gibraltar*/
  GI: GIFlag,
  /**Greece*/
  GR: GRFlag,
  /**Greenland*/
  GL: GLFlag,
  /**Grenada*/
  GD: GDFlag,
  /**Guadeloupe*/
  GP: GPFlag,
  /**Guam*/
  GU: GUFlag,
  /**Guatemala*/
  GT: GTFlag,
  /**Guernsey*/
  GG: GGFlag,
  /**Guinea*/
  GN: GNFlag,
  /**Guinea-Bissau*/
  GW: GWFlag,
  /**Guyana*/
  GY: GYFlag,
  /**Haiti*/
  HT: HTFlag,
  /**Heard Island and McDonald Islands*/
  HM: HMFlag,
  /**Holy See*/
  VA: VAFlag,
  /**Honduras*/
  HN: HNFlag,
  /**Hong Kong*/
  HK: HKFlag,
  /**Hungary*/
  HU: HUFlag,
  /**Iceland*/
  IS: ISFlag,
  /**India*/
  IN: INFlag,
  /**Indonesia*/
  ID: IDFlag,
  /**Iran (Islamic Republic of)*/
  IR: IRFlag,
  /**Iraq*/
  IQ: IQFlag,
  /**Ireland*/
  IE: IEFlag,
  /**Isle of Man*/
  IM: IMFlag,
  /**Israel*/
  IL: ILFlag,
  /**Italy*/
  IT: ITFlag,
  /**Jamaica*/
  JM: JMFlag,
  /**Japan*/
  JP: JPFlag,
  /**Jersey*/
  JE: JEFlag,
  /**Jordan*/
  JO: JOFlag,
  /**Kazakhstan*/
  KZ: KZFlag,
  /**Kenya*/
  KE: KEFlag,
  /**Kiribati*/
  KI: KIFlag,
  /**Korea (the Democratic People's Republic of)*/
  KP: KPFlag,
  /**Korea (the Republic of)*/
  KR: KRFlag,
  /**Kuwait*/
  KW: KWFlag,
  /**Kyrgyzstan*/
  KG: KGFlag,
  /**Lao People's Democratic Republic*/
  LA: LAFlag,
  /**Latvia*/
  LV: LVFlag,
  /**Lebanon*/
  LB: LBFlag,
  /**Lesotho*/
  LS: LSFlag,
  /**Liberia*/
  LR: LRFlag,
  /**Libya*/
  LY: LYFlag,
  /**Liechtenstein*/
  LI: LIFlag,
  /**Lithuania*/
  LT: LTFlag,
  /**Luxembourg*/
  LU: LUFlag,
  /**Macao*/
  MO: MOFlag,
  /**Madagascar*/
  MG: MGFlag,
  /**Malawi*/
  MW: MWFlag,
  /**Malaysia*/
  MY: MYFlag,
  /**Maldives*/
  MV: MVFlag,
  /**Mali*/
  ML: MLFlag,
  /**Malta*/
  MT: MTFlag,
  /**Marshall Islands*/
  MH: MHFlag,
  /**Martinique*/
  MQ: MQFlag,
  /**Mauritania*/
  MR: MRFlag,
  /**Mauritius*/
  MU: MUFlag,
  /**Mayotte*/
  YT: YTFlag,
  /**Mexico*/
  MX: MXFlag,
  /**Micronesia (Federated States of)*/
  FM: FMFlag,
  /**Moldova (the Republic of)*/
  MD: MDFlag,
  /**Monaco*/
  MC: MCFlag,
  /**Mongolia*/
  MN: MNFlag,
  /**Montenegro*/
  ME: MEFlag,
  /**Montserrat*/
  MS: MSFlag,
  /**Morocco*/
  MA: MAFlag,
  /**Mozambique*/
  MZ: MZFlag,
  /**Myanmar*/
  MM: MMFlag,
  /**Namibia*/
  NA: NAFlag,
  /**Nauru*/
  NR: NRFlag,
  /**Nepal*/
  NP: NPFlag,
  /**Netherlands*/
  NL: NLFlag,
  /**New Caledonia*/
  NC: NCFlag,
  /**New Zealand*/
  NZ: NZFlag,
  /**Nicaragua*/
  NI: NIFlag,
  /**Niger*/
  NE: NEFlag,
  /**Nigeria*/
  NG: NGFlag,
  /**Niue*/
  NU: NUFlag,
  /**Norfolk Island*/
  NF: NFFlag,
  /**North Macedonia*/
  MK: MKFlag,
  /**Northern Mariana Islands*/
  MP: MPFlag,
  /**Norway*/
  NO: NOFlag,
  /**Oman*/
  OM: OMFlag,
  /**Pakistan*/
  PK: PKFlag,
  /**Palau*/
  PW: PWFlag,
  /**Palestine, State of*/
  PS: PSFlag,
  /**Panama*/
  PA: PAFlag,
  /**Papua New Guinea*/
  PG: PGFlag,
  /**Paraguay*/
  PY: PYFlag,
  /**Peru*/
  PE: PEFlag,
  /**Philippines*/
  PH: PHFlag,
  /**Pitcairn*/
  PN: PNFlag,
  /**Poland*/
  PL: PLFlag,
  /**Portugal*/
  PT: PTFlag,
  /**Puerto Rico*/
  PR: PRFlag,
  /**Qatar*/
  QA: QAFlag,
  /**Réunion*/
  RE: REFlag,
  /**Romania*/
  RO: ROFlag,
  /**Russian Federation*/
  RU: RUFlag,
  /**Rwanda*/
  RW: RWFlag,
  /**Saint Barthélemy*/
  BL: BLFlag,
  /**Saint Helena, Ascension and Tristan da Cunha*/
  SH: SHFlag,
  /**Saint Kitts and Nevis*/
  KN: KNFlag,
  /**Saint Lucia*/
  LC: LCFlag,
  /**Saint Martin (French part)*/
  MF: MFFlag,
  /**Saint Pierre and Miquelon*/
  PM: PMFlag,
  /**Saint Vincent and the Grenadines*/
  VC: VCFlag,
  /**Samoa*/
  WS: WSFlag,
  /**San Marino*/
  SM: SMFlag,
  /**Sao Tome and Principe*/
  ST: STFlag,
  /**Saudi Arabia*/
  SA: SAFlag,
  /**Senegal*/
  SN: SNFlag,
  /**Serbia*/
  RS: RSFlag,
  /**Seychelles*/
  SC: SCFlag,
  /**Sierra Leone*/
  SL: SLFlag,
  /**Singapore*/
  SG: SGFlag,
  /**Sint Maarten (Dutch part)*/
  SX: SXFlag,
  /**Slovakia*/
  SK: SKFlag,
  /**Slovenia*/
  SI: SIFlag,
  /**Solomon Islands*/
  SB: SBFlag,
  /**Somalia*/
  SO: SOFlag,
  /**South Africa*/
  ZA: ZAFlag,
  /**South Georgia and the South Sandwich Islands*/
  GS: GSFlag,
  /**South Sudan*/
  SS: SSFlag,
  /**Spain*/
  ES: ESFlag,
  /**Sri Lanka*/
  LK: LKFlag,
  /**Sudan*/
  SD: SDFlag,
  /**Suriname*/
  SR: SRFlag,
  /**Svalbard and Jan Mayen*/
  SJ: SJFlag,
  /**Sweden*/
  SE: SEFlag,
  /**Switzerland*/
  CH: CHFlag,
  /**Syrian Arab Republic*/
  SY: SYFlag,
  /**Taiwan (Province of China)*/
  TW: TWFlag,
  /**Tajikistan*/
  TJ: TJFlag,
  /**Tanzania, the United Republic of*/
  TZ: TZFlag,
  /**Thailand*/
  TH: THFlag,
  /**Timor-Leste*/
  TL: TLFlag,
  /**Togo*/
  TG: TGFlag,
  /**Tokelau*/
  TK: TKFlag,
  /**Tonga*/
  TO: TOFlag,
  /**Trinidad and Tobago*/
  TT: TTFlag,
  /**Tunisia*/
  TN: TNFlag,
  /**Turkey*/
  TR: TRFlag,
  /**Turkmenistan*/
  TM: TMFlag,
  /**Turks and Caicos Islands*/
  TC: TCFlag,
  /**Tuvalu*/
  TV: TVFlag,
  /**Uganda*/
  UG: UGFlag,
  /**Ukraine*/
  UA: UAFlag,
  /**United Arab Emirates*/
  AE: AEFlag,
  /**United Kingdom of Great Britain and Northern Ireland*/
  GB: GBFlag,
  /**United States Minor Outlying Islands*/
  UM: UMFlag,
  /**United States of America*/
  US: USFlag,
  /**Uruguay*/
  UY: UYFlag,
  /**Uzbekistan*/
  UZ: UZFlag,
  /**Vanuatu*/
  VU: VUFlag,
  /**Venezuela (Bolivarian Republic of)*/
  VE: VEFlag,
  /**Viet Nam*/
  VN: VNFlag,
  /**Virgin Islands (British)*/
  VG: VGFlag,
  /**Virgin Islands (U.S.)*/
  VI: VIFlag,
  /**Wallis and Futuna*/
  WF: WFFlag,
  /**Western Sahara*/
  EH: EHFlag,
  /**Yemen*/
  YE: YEFlag,
  /**Zambia*/
  ZM: ZMFlag,
  /**Zimbabwe*/
  ZW: ZWFlag,
};

/**
 * Return flagIconComponent from localeID. Note, these flags are stolen from mantine-flagpack
 * @example
 * const Icon = getFlagIcon('DK')
 * <Icon w={100} border='sm' />
 */
export const getFlagIcon = (iconId: string | undefined): string => {
  const formattedIconId = iconId?.trim().toUpperCase();
  if (formattedIconId && formattedIconId in flagIconMap) {
    const result = flagIconMap[formattedIconId];
    return result;
  }
  // eslint-disable-next-line no-console
  console.error(`No flag icon found for ${iconId}`);
  Sentry.captureException(`No flag icon found for ${iconId}`);
  return '';
};
