import { ApolloError } from '@apollo/client';
import { useFilterOptionsQuery } from 'Ghql';
import { useFilters } from 'Hooks';
import { FilterAttribute } from 'Types/Filter';
import getIsKeyDis from 'Utilities/getKeyDis';

type CountryLocaleType = {
  value: string;
  label: string;
  count: number;
};

export const useLocationData = (): {
  data: CountryLocaleType[];
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const filters = useFilters();
  const {
    data: optionsData,
    loading,
    error,
  } = useFilterOptionsQuery({
    fetchPolicy: 'cache-first',
    variables: {
      filters: filters.filter((f) => f.attribute !== FilterAttribute.LOCATION),
      isKeydis: getIsKeyDis(),
      filterType: FilterAttribute.LOCATION,
    },
  });

  const options = optionsData?.filterData?.filterOptions || [];
  const data = options.map((item) => ({
    value: item.option,
    label: item.option,
    count: item.count,
  }));
  return { data, loading, error };
};
