import ErrorBoundary from 'Components/ErrorBoundary';
import KpiBox, { KpiBoxSizeProps } from 'Pages/Keywords/Overview/components/KpiBar/KpiBox';
import { KPI_NAMES } from 'Pages/Keywords/Overview/components/KpiBar/index';
import { t } from 'Utilities/i18n';
import { KpiBarQueryProps } from './useKpiBarQueryWithDisplayCurrency';

const AverageBaseRankKpiBox = ({
  size,
  kpiBarQueryProps,
  draggable,
}: KpiBoxSizeProps & { kpiBarQueryProps: KpiBarQueryProps }) => {
  const { displayCurrency, data, loading } = kpiBarQueryProps;

  return (
    <ErrorBoundary>
      <KpiBox
        id={KPI_NAMES.BASE_RANK}
        size={size}
        title={t('Average Base Rank')}
        loading={loading || !displayCurrency}
        value={loading ? '-' : data?.kpis?.allKpis?.avgBaseRank ?? '-'}
        beforeValue={
          !loading && data?.kpis?.allKpis?.avgBaseRankBefore
            ? data?.kpis?.allKpis?.avgBaseRankBefore
            : '-'
        }
        maxValue={data?.kpis?.allKpis?.avgMaxBaseRank}
        isRank={true}
        precision={2}
        changeNegation={true}
        draggable={draggable}
      />
    </ErrorBoundary>
  );
};

export default AverageBaseRankKpiBox;
