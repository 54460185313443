import { memo } from 'react';
import cn from 'classnames';
import { tableClassName } from 'Components/DataTable/helpers';
import { useRowContext } from 'Components/DataTable/table-core';
import PublicTableRankChart from './TableRankChart';

export const ExpandedRankRow = memo(({ id, keyword }: { id: number; keyword: string }) => {
  const rowContext = useRowContext();
  return (
    <tr
      className={cn(
        tableClassName('expanded-row'),
        tableClassName('expanded-row-level-1'),
        'row-detail',
      )}
      style={{ height: '400px', position: 'relative' }}
    >
      <td className="row-detail-container">
        <PublicTableRankChart
          keywordId={id.toString()}
          keyword={keyword}
          toggleExpand={rowContext.toggleCustomExpand}
        />
      </td>
    </tr>
  );
});

ExpandedRankRow.displayName = 'ExpandedRankRow';
