import { useState } from 'react';
import { IconCirclePlus } from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import Breadcrumbs from 'Components/Breadcrumbs/Breadcrumbs';
import CrossSellBanner from 'Components/CrossSellBanner';
import { Form } from 'Components/Fields';
import HelpGuideButton from 'Components/HelpGuideButton';
import AccTitle from 'Components/Title/AccTitle';
import TotalUsed from 'Components/TotalUsed';
import { useTotalWorkspacesQuery } from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import ActionbarContainer from 'Pages/Layout/ActionsMenu/components/ActionbarContainer';
import { t } from 'Utilities/i18n/index';
import WorkspacesTable from './WorkspacesTable';
import styles from './workspacesTable.module.scss';

const WorkspacesPage = () => {
  const [dataKey, setDataKey] = useState<number>(0);
  const { showModal } = useModal();
  const {
    data: workspaces,
    loading,
    refetch,
  } = useTotalWorkspacesQuery({
    fetchPolicy: 'network-only',
  });
  const permRolesQty = workspaces?.workspacesByOrganization?.length ?? 0;
  const maxQty = workspaces?.user?.organization?.activePlan?.maxNumbersOfWorkspaces ?? 0;
  // maximum workspaces reached
  const isMaxQtyReached = maxQty >= 0 && permRolesQty >= maxQty;
  const handleUpdateTable = () => {
    refetch();
    setDataKey((prev) => prev + 1);
  };

  const handleAdd = () => {
    showModal({
      modalType: 'AddWorkspace',
      modalTheme: 'light',
      modalProps: {
        refresh: handleUpdateTable,
      },
    });
  };

  return (
    <>
      <Breadcrumbs />
      <div className={styles.workspacesTable} key={`${dataKey}-container`}>
        <AccTitle type="h3">{t('Workspaces')}</AccTitle>
        <ActionbarContainer mt="md" key={`${dataKey}-actionbar`}>
          <AccButton
            key="new"
            variant="primary"
            onClick={handleAdd}
            leftSection={<IconCirclePlus size={18} />}
            disabled={isMaxQtyReached}
            tooltip={isMaxQtyReached ? t('Maximum Workspaces reached') : undefined}
          >
            {t('Add workspace')}
          </AccButton>
          <HelpGuideButton
            helpguideLink={
              'https://www.accuranker.com/help/account/accuranker-workspaces-organise-user-access-to-keyword-groups/'
            }
          />
          <ActionbarContainer.Sidebar>
            <TotalUsed
              loading={loading}
              label={t('Workspaces')}
              used={permRolesQty}
              total={maxQty}
            />
          </ActionbarContainer.Sidebar>
        </ActionbarContainer>
        {!loading && isMaxQtyReached && (
          <CrossSellBanner crossSellId="permission-roles" title={t('Need more Workspaces?')}>
            {t('Contact Customer Support to request more Workspaces.')}
          </CrossSellBanner>
        )}
        <Form onSubmit={() => {}} key={`${dataKey}-form`}>
          <WorkspacesTable
            handleUpdateTable={handleUpdateTable}
            refetchQty={refetch}
            dataKey={dataKey}
          />
        </Form>
      </div>
    </>
  );
};

export default WorkspacesPage;
