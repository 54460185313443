import moment from 'moment';
import { ColumnType, QueryOrder } from 'Components/DataTable';
import { FixedType } from 'Components/DataTable/table-core/interface';
import ActionsCell, { ActionsCellItem } from 'Components/Table/TableRow/ActionsCell';
import AccText from 'Components/Text/AccText';
import { WorkspaceFragment } from 'Ghql';
import { t } from 'Utilities/i18n';
import { ColumnIDs } from './ColumnIDs';
import GroupAccessCell from './groupAccessCell';
import UsersCell from './usersCell';

export const getColumns = (getActions: (record: WorkspaceFragment) => ActionsCellItem[]) => {
  const columns: ColumnType<WorkspaceFragment, {}>[] = [
    {
      id: ColumnIDs.NAME,
      title: t('Name'),
      width: 220,
      cellRenderer: ({ record }) => (
        <AccText size="sm" lineClamp={2} tooltip={record.name || ''}>
          {record.name}
        </AccText>
      ),
      onHeaderCell: () => ({
        ordering: {
          orderBy: ColumnIDs.NAME,
          defaultOrder: QueryOrder.ASC,
        },
      }),
    },
    {
      id: ColumnIDs.DESCRIPTION,
      title: t('Description'),
      flex: true,
      width: 250,
      cellRenderer: ({ record }) => (
        <AccText size="sm" lineClamp={3} tooltip={record.description || ''}>
          {record.description}
        </AccText>
      ),
      onHeaderCell: () => ({
        ordering: {
          orderBy: ColumnIDs.DESCRIPTION,
          defaultOrder: QueryOrder.ASC,
        },
      }),
    },
    {
      id: ColumnIDs.CLIENTS,
      title: t('Group Access'),
      width: 350,
      cellRenderer: GroupAccessCell,
      onHeaderCell: () => ({
        ordering: {
          orderBy: ColumnIDs.CLIENTS,
          defaultOrder: QueryOrder.ASC,
        },
      }),
    },
    {
      id: ColumnIDs.USERS,
      title: t('Users'),
      width: 260,
      cellRenderer: UsersCell,
      onHeaderCell: () => ({
        ordering: {
          orderBy: ColumnIDs.USERS,
          defaultOrder: QueryOrder.ASC,
        },
      }),
    },
    {
      id: ColumnIDs.LAST_EDITED,
      title: t('Time since edit'),
      width: 100,
      cellRenderer: ({ record }) => {
        return record?.lastEdited ? moment(new Date(record.lastEdited)).fromNow(true) : null;
      },
      onHeaderCell: () => ({
        ordering: {
          orderBy: ColumnIDs.LAST_EDITED,
          defaultOrder: QueryOrder.ASC,
        },
      }),
    },
    {
      id: ColumnIDs.ACTIONS,
      title: t('Actions'),
      width: 76,
      fixed: 'right' as FixedType,
      cellRenderer: ({ record }) => (
        <ActionsCell shouldUpdateIndicator={record} actions={getActions(record)} />
      ),
    },
  ];

  return columns;
};
