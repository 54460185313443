import { TableStoreType } from 'Components/DataTable';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { CountByCountryNode } from 'Ghql';
import { t } from 'Utilities/i18n';
import { useModal } from '../../../../Hooks/base/useModal';
import BuildDomainForm from './BuildDomainForm/BuildDomainForm';
import './add-domain.scss';

export type DomainFormProps = {
  /** Used to determine whether edit or add form is displayed */
  domainId?: number;
  /** Pass a groupId manually to make sure the correct group is preselected */
  groupId?: number | string;
  onClose?: (...args: Array<any>) => void;
  domain?: string;
  tableStore?: TableStoreType | null;
  selectedCountry?: CountByCountryNode; // Used from keyword research to specify the country to send along to the add keywords modal
};

const BuildDomain = ({
  domain,
  tableStore,
  selectedCountry,
  domainId,
  groupId,
  onClose,
}: DomainFormProps) => {
  const { hideModal } = useModal();

  return (
    <ModalBorder
      className="add-domain"
      title={domainId ? t('Edit Domain') : t('Add Domain')}
      onClose={hideModal}
    >
      <BuildDomainForm
        domain={domain}
        groupId={groupId}
        tableStore={tableStore}
        selectedCountry={selectedCountry}
        domainId={domainId}
        onClose={onClose}
      />
    </ModalBorder>
  );
};

export default BuildDomain;
