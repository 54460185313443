import { useField } from 'react-final-form';
import { Flex } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import { NumberOperations } from 'Pages/DomainSettings/support/constants';
import { t } from 'Utilities/i18n';
import { getOperationType } from '../../support/helpers';
import { useBuilderTokenInfo } from '../../support/hooks';
import { RulesActiveGroupEvents } from '../../support/types';
import { FieldDynamicInput } from './components/FieldDynamicInput';
import { FieldSelect } from './components/FieldSelect';
import { combineFieldNames } from './support/helpers';
import styles from '../../dynamic-tags.module.scss';

interface BuilderTokenProps {
  name: string;
  deleteItem?: () => void;
  activeEvents?: RulesActiveGroupEvents;
}

export const BuilderToken: React.FC<React.PropsWithChildren<BuilderTokenProps>> = ({
  name,
  deleteItem,
}) => {
  const {
    input: { value, onChange },
  } = useField(name);
  const showOperation = !!value?.source;
  const { sourceOptions, operationOptions, operationSelected } = useBuilderTokenInfo(value?.source);

  const clearOperation = (next?: string) =>
    onChange({ source: next, operator: undefined, value: undefined });

  return (
    <Flex className={styles.token}>
      <FieldSelect
        name={combineFieldNames(name, 'source')}
        options={sourceOptions}
        placeholder={t('Select Field')}
        className={styles.tokenSource}
        searchable={false}
        customChange={({ prev, next }) => {
          if (prev && getOperationType(prev) !== getOperationType(next)) {
            clearOperation(next);
          }
        }}
      />
      {showOperation && (
        <FieldSelect
          name={combineFieldNames(name, 'operator')}
          options={operationOptions}
          placeholder={t('Operation')}
          className={styles.tokenOperator}
          searchable={false}
          useFirstOptionAsDefault
          customChange={({ prev, next }) => {
            if (
              prev !== next &&
              (prev === NumberOperations.BETWEEN || next === NumberOperations.BETWEEN)
            ) {
              onChange({ source: value.source, operator: next, value: undefined });
            }
          }}
        />
      )}
      <div className={styles.tokenValue}>
        {showOperation && (
          <FieldDynamicInput
            name={[name, 'value']}
            operator={value?.operator}
            operationGroup={operationSelected}
          />
        )}
      </div>
      <div className={styles.tokenDelete}>
        <AccActionIcon className={styles.deleteItem} onClick={deleteItem} variant="subtle">
          <IconTrash />
        </AccActionIcon>
      </div>
    </Flex>
  );
};
