import AccButton from 'Components/AccButton/AccButton';
import AccessControl from 'Components/AccessControl';
import AccText from 'Components/Text/AccText';
import AccTitle from 'Components/Title/AccTitle';
import useUserPermission from 'Hooks/useUserPermission';
import { t } from 'Utilities/i18n';
import ExpiredPageComponent from '../expiredPageComponent/ExpiredPageComponent';

const MainContent = ({ accessRestricted }: { accessRestricted: boolean }) => (
  <>
    <AccText>
      {t(
        'We hope you enjoyed your journey with AccuRanker and discovered the powerful insights and tools that can help elevate your SEO strategy.',
      )}
    </AccText>
    <AccTitle type="h4">{t('Don\'t let your SEO efforts pause here!')}</AccTitle>
    <AccText>
      {t(
        'Join the thousands of clients worldwide who are benefiting from the full suite of features that AccuRanker offers.',
      )}
    </AccText>
    <AccessControl withAdminPermission>
      <AccButton
        variant="primary"
        mt="md"
        mx="auto"
        link="/billing/package/select"
        disabled={accessRestricted}
      >
        {t('Upgrade your account')}
      </AccButton>
    </AccessControl>
  </>
);

const FooterContent = ({ accessRestricted }: { accessRestricted: boolean }) => (
  <>
    <AccTitle type="h4" fw={600} mt="xl">
      {t('Still uncertain?')}
    </AccTitle>
    <AccText fw={300}>
      {t(
        'We understand that every decision needs careful consideration. If you have any questions or need further information, feel free to book a meeting with our team of experts.',
      )}
    </AccText>
    <AccButton
      variant="primary"
      ml="auto"
      mt="xs"
      onClick={() =>
        window.open(
          'https://meeting.accuranker.com/meetings/hsj/website-bookings',
          '_blank',
          'noopener,noreferrer',
        )
      }
      disabled={accessRestricted}
    >
      {t('Book a 1:1 meeting')}
    </AccButton>
  </>
);

const TrialExpired = () => {
  const { userHasWriteAccess } = useUserPermission();
  return (
    <ExpiredPageComponent
      title={t('Your trial has come to an end')}
      mainContent={<MainContent accessRestricted={!userHasWriteAccess} />}
      footerContent={<FooterContent accessRestricted={!userHasWriteAccess} />}
    />
  );
};

export default TrialExpired;
