import Cell from 'Components/AccuTable/CellRenderer/HelperComponents/AccuCell';
import ValueIndicator from 'Components/ValueIndicator/value-indicator';

const NoScrapeOnCompareDateCell = () => {
  return (
    <Cell rightAligned={true}>
      <ValueIndicator
        beforeValue={null}
        afterValue={null}
        noCompareString={'This keyword was not tracked on the date you are comparing to'}
        nullishHyphen={true}
      />
    </Cell>
  );
};

export default NoScrapeOnCompareDateCell;
