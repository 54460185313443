import { NextCompetitorChartContainer } from 'Pages/Keywords/Competitors/components/CompetitorChartContent/NextCompetitorsChartContainer';
import { useQueryDomainInfo } from '../../../Hooks/data/domain/useQueryDomainInfo';
import CompetitorActionMenu from './components/CompetitorActionMenu';
import { CompetitorsRanksTable } from './components/CompetitorsRanksTable/CompetitorsRanksTable';
import CompetitorsTable from './components/CompetitorsTable';
import './keywords-competitors.scss';

const CompetitorsPage = () => {
  const { domainInfo, domainId } = useQueryDomainInfo();
  return (
    <>
      <CompetitorActionMenu domainInfo={domainInfo} domainId={domainId} />
      <div className="keywords-competitors">
        <NextCompetitorChartContainer />
        <CompetitorsTable domainInfo={domainInfo} />
        <CompetitorsRanksTable />
      </div>
    </>
  );
};

export default CompetitorsPage;
