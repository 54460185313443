import React from 'react';
import { Box } from '@mantine/core';

export const ChartPlaceholder = () => {
  return (
    <div>
      <Box mih="380px" opacity={0}>
        <h1>placeholder</h1>
      </Box>
    </div>
  );
};
