import Breadcrumbs from 'Components/Breadcrumbs/Breadcrumbs';
import { OveruseChart } from './components/OveruseChart/OveruseChart';
import { OveruseInfoPanel } from './components/OveruseInfoPanel/OveruseInfoPanel';
import { OveruseTable } from './components/OveruseTable/OveruseTable';
import styles from './overuse-page.module.scss';

/**
 * This page contains: plan info and keyword overuse info
 * - overuse info - show keyword over usage and how match user can save
 * if he will extend his plan.
 * @docs https://accuranker.myjetbrains.com/youtrack/issue/ARR-1450
 */
const KeywordOverusePage = () => {
  return (
    <div>
      <Breadcrumbs />
      <div className={styles.overuseContainer}>
        <OveruseInfoPanel />
      </div>
      <div className={styles.overuseContainer}>
        <OveruseChart />
      </div>
      <div className={styles.overuseContainer}>
        <OveruseTable />
      </div>
    </div>
  );
};

export default KeywordOverusePage;
