import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { useModal } from 'Hooks/base/useModal';
import { t } from 'Utilities/i18n';
import RequestAccessForm from './RequestAccessForm';

type Props = {
  refresh: (...args: Array<any>) => any;
};

const RequestAccess = (props: Props) => {
  const { hideModal } = useModal();

  const requestAccessInitialValues = {
    sendACopy: true,
    subject: t('Request for access to your AccuRanker account'),
  };
  return (
    <ModalBorder className="request-access" title={t('Request Access')} onClose={hideModal}>
      <RequestAccessForm
        initialValues={requestAccessInitialValues}
        onClose={hideModal}
        refresh={props.refresh}
      />
    </ModalBorder>
  );
};

RequestAccess.defaultProps = {
  refresh: () => {},
};

export default RequestAccess;
