const ACCOUNT_NAME = 'name';
const USER_TYPE = 'type';
const PAID_BY_YOU = 'paid_by_you';
const NEXT_PAYMENT = 'next_payment';
const INVOICES = 'invoices';
const ACTIONS = 'actions';

export const ColumnIDs = {
  ACCOUNT_NAME,
  USER_TYPE,
  PAID_BY_YOU,
  NEXT_PAYMENT,
  INVOICES,
  ACTIONS,
};
