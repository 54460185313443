import cn from 'classnames';
import noop from 'lodash/noop';
import { TableSettingOption } from '../support/types';
import { TableSettingOptionCheckbox } from './TableCheckboxes';
import styles from './tableSettings.module.scss';

type Props = {
  item: TableSettingOption;
  indent: number;
  optionStatuses: string[];
  onOptionsStatusChange: (id: string, value: boolean) => void;
  active?: boolean;
};

const ITEM_INDENT_SIZE = 28;

export const TableSettingSubOption = ({
  item,
  optionStatuses,
  indent = 0,
  onOptionsStatusChange,
  active,
}: Props) => {
  const checked = optionStatuses?.includes(item.id);
  const onChange = () => onOptionsStatusChange(item.id, !checked);
  const hasChildren = !!item?.children?.length;

  return (
    <div style={{ marginLeft: `${indent * ITEM_INDENT_SIZE}px` }}>
      <div className={styles.optionContainer}>
        <TableSettingOptionCheckbox
          onChange={item.disabled ? noop : onChange}
          checked={item.disabled || checked}
          disabledCheck={item.disabled}
        >
          <span
            className={cn(
              styles.optionLabel,
              active ? (item.disabled ? styles.optionLabelDisabled : styles.optionLabelActive) : '',
            )}
          >
            {item.label?.()}
          </span>
        </TableSettingOptionCheckbox>
      </div>
      {hasChildren && checked && (
        <div className={styles.optionsList}>
          {item?.children?.map((e: any) => (
            <TableSettingSubOption
              item={e}
              key={e.id}
              indent={indent + 1}
              optionStatuses={optionStatuses}
              onOptionsStatusChange={onOptionsStatusChange}
              active={active}
            />
          ))}
        </div>
      )}
    </div>
  );
};
