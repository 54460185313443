import { Component, FormEventHandler } from 'react';
import { findDOMNode } from 'react-dom';
import { connect } from 'react-redux';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import { Box, Flex, Grid } from '@mantine/core';
import flatMap from 'lodash/flatMap';
import compose from 'lodash/flowRight';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';
import sortBy from 'lodash/sortBy';
import { Dispatch } from 'redux';
import { Field, change as changeFormValue, formValueSelector, reduxForm } from 'redux-form';
import AccButton from 'Components/AccButton/AccButton';
import FakeFiltersContext from 'Components/Filters/FakeFiltersContext';
import FiltersInput from 'Components/Filters/FiltersInput';
import { Checkbox, Select, TextAreaField, TextField } from 'Components/Forms/Fields';
import toFormField from 'Components/Forms/toFormField';
import { ReportType } from 'Constants/report';
import PublicReportOptions from 'Pages/ScheduledReportBuilder/PublicReportOptions';
import { KEYWORDS_FILTER_SET } from 'Types/FilterSet';
import { withRouter } from 'Utilities/Router';
import { t } from 'Utilities/i18n';
import underdash, { graphqlError, graphqlLoading } from 'Utilities/underdash';
import Validator from 'Utilities/validation';
import {
  getDefaultEmailBody,
  getDefaultEmailSubject,
  getLanguageOptions,
  getScheduledReportTypeOptions,
} from '../data';
import { EditReportFormSubmitData } from './Edit';
import EmailsInput from './EmailsInput';
import Skeleton from './Skeleton';

type ReportTemplate = {
  id: string;
  name: string;
};
type ScheduledReportBuilderFormProps = {
  formValues: {
    isGroupReport: boolean;
    schedule: number;
    domain: Record<string, any>;
    group: Record<string, any>;
    reportTemplate: ReportTemplate;
  };
  initialValues: EditReportFormSubmitData;
  clients: Record<string, any>;
  data: Record<string, any>;
  reportTemplates: Array<Record<string, any>>;
  reportType: Record<string, any>;
  resetFormValue: (formName: string, fieldName: string) => any;
  changeFieldValue: (formName: string, fieldName: string, newValue: unknown) => any;
  history: Record<string, any>;
  backLink: string;
  submitOnInit: boolean;
  handleSubmit: (data: EditReportFormSubmitData) => Promise<void> | undefined;
  submitting: boolean;
  invalid: boolean;
  loading?: boolean;
  hasDriveAccount: boolean;
  change: (...args: Array<any>) => any;
};

type State = {
  submittedOnInit: boolean;
};
const EmailsField = toFormField(EmailsInput);
const FiltersField = toFormField(FiltersInput);
const SCHEDULE_WEEKLY = 2;
const SCHEDULE_MONTHLY = 3;

const validateScheduleDay = (
  value,
  props: {
    schedule: number;
  },
) => {
  if (props.schedule === SCHEDULE_WEEKLY || props.schedule === SCHEDULE_MONTHLY) {
    return Validator.numeric(value);
  }
};

const SCHEDULE_REPORT_FORM = 'ScheduleReport';

class ScheduledReportBuilderForm extends Component<ScheduledReportBuilderFormProps, State> {
  _submit: any;
  static defaultProps = {
    backLink: '/reports/scheduled',
  };
  state = {
    submittedOnInit: false,
  };
  resetValue = (name: string) => {
    this.props.resetFormValue(SCHEDULE_REPORT_FORM, name);
  };
  setValue = (fieldName: string, newValue: unknown) => {
    this.props.changeFieldValue(SCHEDULE_REPORT_FORM, fieldName, newValue);
  };

  getIsDomainUpdated = (prevProps: ScheduledReportBuilderFormProps) => {
    const domainId = this.getDomainId(prevProps);
    const nextDomainId = this.getDomainId();
    return domainId && Array.isArray(nextDomainId)
      ? !isEqual(domainId, nextDomainId)
      : domainId !== nextDomainId;
  };

  toggleReportType = () => {
    const isGroupReport = !!this.props.formValues?.isGroupReport;
    if (isGroupReport) {
      this.setValue('isGroupReport', false);
      return;
    }
    const domainGroup = this.props.formValues?.domain?.group;
    const group = this.props.clients?.find((client) => client.name === domainGroup);
    this.setValue('isGroupReport', true);
    this.resetValue('reportType');
    if (domainGroup && group?.id) {
      setTimeout(() => {
        this.setValue('group', {
          label: domainGroup,
          value: group.id,
        });
      }, 0);
    }
  };

  componentDidUpdate(prevProps) {
    const {
      formValues: { schedule, isGroupReport },
    } = prevProps;
    const {
      formValues: { schedule: nextSchedule, isGroupReport: nextIsGroupReport },
    } = this.props;

    if (schedule && schedule !== nextSchedule) {
      this.resetValue('scheduledDay');
    }

    if (!prevProps.loading) {
      if (isGroupReport !== nextIsGroupReport) {
        this.resetValue('domain');
        this.resetValue('group');
        this.props.change('filters', []);
      } else if (this.getIsDomainUpdated(this.props)) {
        this.props.change('filters', []);
      }
    }

    if (
      this._submit &&
      this.props.submitOnInit &&
      !this.state.submittedOnInit &&
      this.props.hasDriveAccount &&
      !(graphqlLoading(this.props) || graphqlError(this.props))
    ) {
      // eslint-disable-next-line react/no-will-update-set-state
      this.setState({
        submittedOnInit: true,
      });
      // Probably not the best way to submit programmatically,
      // so if there is a better way that runs handleSubmit and set submitting, fix me
      // eslint-disable-next-line react/no-find-dom-node,@typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line react/no-find-dom-node
      findDOMNode(this._submit)?.click?.();
    }
  }

  handleBack = () => {
    const { history, backLink } = this.props;
    history.push(backLink);
  };
  reportTypeOptions = getScheduledReportTypeOptions();
  scheduleOptions: { value: number; label: string }[] = [
    {
      value: 1,
      label: t('Daily'),
    },
    {
      value: SCHEDULE_WEEKLY,
      label: t('Weekly'),
    },
    {
      value: SCHEDULE_MONTHLY,
      label: t('Monthly'),
    },
  ];
  scheduleWeeklyOptions: { value: string; label: string }[] = [
    {
      value: '0',
      label: t('Monday'),
    },
    {
      value: '1',
      label: t('Tuesday'),
    },
    {
      value: '2',
      label: t('Wednesday'),
    },
    {
      value: '3',
      label: t('Thursday'),
    },
    {
      value: '4',
      label: t('Friday'),
    },
    {
      value: '5',
      label: t('Saturday'),
    },
    {
      value: '6',
      label: t('Sunday'),
    },
  ];
  languageOptions = getLanguageOptions();
  scheduleMonthlyOptions = range(1, 32).map((value) => ({
    value,
    label: value >= 29 ? `${value === 31 ? t('Last day of month') : value} *` : `${value}`,
  }));
  setSubmitRef = (ref) => {
    this._submit = ref;
  };

  /*
   * Get domain id selected by group and domain selections
   */
  getDomainId = (props = this.props): string[] | string | null => {
    const {
      formValues: { isGroupReport, domain, group },
      clients,
    } = props;
    let domainId;

    if (isGroupReport) {
      const client =
        group && clients ? clients.find((clientItem) => clientItem.id === group.value) : null;
      domainId = client ? client.domains.map(({ id }) => id) : null;
    } else {
      domainId = domain ? domain.value : null;
    }

    return domainId;
  };
  renderDomainSelect = () => {
    const { clients } = this.props;

    return (
      <Grid.Col span={{ lg: 12 }}>
        <div className="form-label required">{t('Domain')}</div>
        <Field
          name="domain"
          placeholder={t('Select a domain')}
          component={Select}
          defaultBehaviour
          useFirstOptionAsDefault={true}
          options={flatMap(
            clients.map((client) =>
              client.domains.map(({ id, domain, displayName }) => ({
                value: id,
                group: client.name,
                label: (displayName && `${displayName} (${domain})`) || domain,
              })),
            ),
          )}
        />
      </Grid.Col>
    );
  };
  renderGroupSelect = () => {
    const { clients } = this.props;
    return (
      <Grid.Col span={{ lg: 12 }}>
        <div className="form-label required">{t('Group')}</div>
        <Field
          name="group"
          placeholder={t('Select a group')}
          component={Select}
          defaultBehaviour
          useFirstOptionAsDefault={false}
          options={clients.map(({ id, name }) => ({
            value: id,
            label: name,
          }))}
        />
      </Grid.Col>
    );
  };

  renderScheduleSelect = () => {
    const {
      formValues: { schedule },
    } = this.props;
    let secondDropdownOptions: { value: string | number; label: string }[] | null = null;
    let isRequired = false;

    if (schedule === SCHEDULE_WEEKLY) {
      secondDropdownOptions = this.scheduleWeeklyOptions;
      isRequired = true;
    }

    if (schedule === SCHEDULE_MONTHLY) {
      secondDropdownOptions = this.scheduleMonthlyOptions;
      isRequired = true;
    }

    const secondDropdown = (
      <Grid.Col span={{ lg: 6 }}>
        <div className={`form-label ${isRequired ? 'required' : ''}`}>{t('Day')}</div>
        <Field
          disabled={!secondDropdownOptions}
          name="scheduledDay"
          defaultBehaviour
          placeholder={t('Day')}
          component={Select}
          parse={(scheduleItem) => scheduleItem.value}
          options={secondDropdownOptions}
          validate={validateScheduleDay}
          useFirstOptionAsDefault
          searchable={false}
        />
        {schedule && schedule === SCHEDULE_MONTHLY && (
          <p className="alert alert-info">
            {t(
              '* If the month doesn\'t have that many days it\'s treated as the last day of the month. If you choose \'Last day of month\' we will create a report from the 1st to the last day of the month.',
            )}
          </p>
        )}
      </Grid.Col>
    );
    return (
      <>
        <Grid.Col span={{ lg: 6 }}>
          <div className="form-label required">{t('Email frequency')}</div>
          <Field
            name="schedule"
            defaultBehaviour
            placeholder={t('Schedule')}
            parse={(scheduleItem) => scheduleItem.value}
            component={Select}
            options={this.scheduleOptions}
            validate={Validator.required}
            searchable={false}
            customOnChange={() => this.resetValue('scheduledDay')}
          />
        </Grid.Col>
        {secondDropdown}
        <Box px={8}>
          <p className="alert alert-info">
            {t('Reports are generated and sent after the daily ranking check on the chosen day')}
          </p>
        </Box>
      </>
    );
  };
  renderEmailFields = () => (
    <>
      <Grid.Col span={{ lg: 12 }}>
        <div className="form-label required">{t('Send email from')}</div>
        <Field name="sender" component={TextField} validate={Validator.required} />
        <p className="alert alert-info">
          {t(
            'The report will be sent in an email from noreply@accuranker.com. Clicking "Reply" will send an email back to the above address.',
          )}
        </p>
      </Grid.Col>
      <Grid.Col span={{ lg: 12 }}>
        <div className="form-label required">{t('Email subject')}</div>
        <Field name="emailSubject" component={TextField} validate={Validator.required} />
      </Grid.Col>
      <Grid.Col span={{ lg: 12 }}>
        <div className="form-label required">{t('Email body')}</div>
        <Field name="emailBody" component={TextAreaField} validate={Validator.required} />
      </Grid.Col>
    </>
  );
  renderPublicReportOptions = () => {
    const {
      reportType,
      formValues: { isGroupReport, domain, group },
    } = this.props;
    const props = {
      ...(group && isGroupReport
        ? {
            groupId: group.value,
          }
        : {}),
      ...(domain
        ? {
            domainId: domain.value,
          }
        : {}),
    };
    // TODO FixTSignore
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <PublicReportOptions {...props} reportType={reportType} />;
  };
  renderReportTypeField = () => (
    <Grid.Col span={{ lg: 12 }}>
      <div className="form-label required">{t('Report Type')}</div>
      <Field
        name="reportType"
        // defaultBehaviour
        component={Select}
        useFirstOptionAsDefault
        options={
          this.props.formValues.isGroupReport
            ? this.reportTypeOptions.slice(0, 1)
            : this.reportTypeOptions
        }
        validate={Validator.required}
        searchable={false}
      />
    </Grid.Col>
  );

  renderForm() {
    const {
      handleSubmit,
      invalid,
      submitting,
      reportTemplates,
      initialValues,
      formValues: { isGroupReport },
    } = this.props;
    const domainId = this.getDomainId();
    const templateOptions = sortBy(reportTemplates, 'name').map(({ id, name }) => ({
      value: id,
      label: name,
    }));
    const isPDFReport =
      this.props.reportType.value === ReportType.PDF ||
      (this.props.reportType as unknown as number) === ReportType.PDF;
    return (
      <div className="scheduled-report-builder">
        <Grid onSubmit={handleSubmit as unknown as FormEventHandler<HTMLDivElement>}>
          <Grid.Col span={{ lg: 12 }}>
            <div className="form-label">{t('Name')}</div>
            <Field name="name" component={TextField} />
          </Grid.Col>

          <Grid.Col span={{ lg: 12 }}>
            <div className="form-label">{t('Description')}</div>
            <Field name="description" component={TextAreaField} />
          </Grid.Col>

          {isGroupReport ? this.renderGroupSelect() : this.renderDomainSelect()}
          <Grid.Col span={{ lg: 12 }}>
            <Field
              name="isGroupReport"
              component={Checkbox}
              defaultChecked={initialValues.isGroupReport}
              onChange={() => this.toggleReportType()}
            >
              {t('Create for all domains in a group')}
            </Field>
          </Grid.Col>
          {this.renderReportTypeField()}
          {this.renderPublicReportOptions()}
          <Grid.Col span={{ lg: 12 }}>
            <div className="form-label required">{t('Language')}</div>
            <Field
              name="language"
              defaultBehaviour
              placeholder={t('Language')}
              component={Select}
              options={this.languageOptions}
              validate={Validator.required}
              searchable={false}
            />
          </Grid.Col>
          {isPDFReport && (
            <Grid.Col span={{ lg: 12 }}>
              <div className="form-label required">{t('Template')}</div>
              <Field
                name="template"
                placeholder={t('Template')}
                component={Select}
                defaultBehaviour
                validate={Validator.required}
                options={templateOptions}
              />
            </Grid.Col>
          )}
          {this.renderScheduleSelect()}
          <Grid.Col span={{ lg: 12 }}>
            <div className="form-label">{t('Email Report To')}</div>
            <Field
              name="emails"
              //Do not include the word "email" in the placeholder - as this will trigger password managers to suggest email addresses
              placeholder={t('You can enter multiple addresses')}
              component={EmailsField}
            />
          </Grid.Col>
          {this.renderEmailFields()}
          <Grid.Col span={{ lg: 12 }}>
            <FakeFiltersContext.Provider
              value={{
                domainId,
              }}
            >
              <Field
                name="filters"
                filterSet={KEYWORDS_FILTER_SET}
                component={FiltersField}
                disabled={!domainId}
                tooltip={!domainId && t('Pick a domain/group before adding filters')}
                id="schedule-report-builder-filter-input"
              />
            </FakeFiltersContext.Provider>
          </Grid.Col>

          <Grid.Col span={{ lg: 12 }}>
            <hr />
            <Flex justify="end">
              <AccButton variant="tertiary" onClick={this.handleBack}>
                {t('Back')}
              </AccButton>
              <AccButton
                ml={8}
                ref={this.setSubmitRef}
                disabled={invalid || submitting}
                onClick={handleSubmit as unknown as FormEventHandler<HTMLFormElement>}
                variant="primary"
              >
                {t('Save')}
              </AccButton>
            </Flex>
          </Grid.Col>
        </Grid>
      </div>
    );
  }

  render() {
    if (underdash.graphqlLoading({ ...this.props }) || underdash.graphqlError({ ...this.props })) {
      return <Skeleton />;
    }

    return this.renderForm();
  }
}

const dataQuery = gql`
  query scheduledReportBuilderForm_reportTemplatesData {
    user {
      id
      email
      organization {
        id
        defaultReportTemplate {
          id
          name
        }
      }
    }
    reportTemplates {
      id
      name
    }
    clients {
      id
      name
      domains {
        id
        domain
        displayName
      }
    }
  }
`;
const formValuesSelector = formValueSelector(SCHEDULE_REPORT_FORM);

const mapStateToProps = (state, ownProps) => ({
  formValues: formValuesSelector(state, 'isGroupReport', 'schedule', 'domain', 'group'),
  reportType: formValuesSelector(state, 'reportType'),
  initialValues: {
    language: {
      value: state.user.language,
      label: state.user.language === 'da' ? t('Danish') : t('English'),
    },
    schedule: {
      value: 1,
      label: t('Daily'),
    },
    reportType: {
      value: 1,
      label: t('PDF'),
    },
    sender: state.user.email,
    emailSubject: getDefaultEmailSubject(),
    emailBody: getDefaultEmailBody(),
    filters: [],
    ...{
      ...ownProps.initialValues,
      scheduledDay: ownProps.initialValues.scheduledDay
        ? ownProps.initialValues.scheduledDay.toString()
        : null,
    },
  },
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  resetFormValue: (formName: string, fieldName: string) =>
    dispatch(changeFormValue(formName, fieldName, '')),
  changeFieldValue: (formName: string, fieldName: string, newValue: unknown) =>
    dispatch(changeFormValue(formName, fieldName, newValue)),
});

const validateForm = (values) => {
  if (values.isGroupReport && !values.group) {
    return { group: t('Field is required') };
  } else if (!values.isGroupReport && !values.domain) {
    return { domain: t('Field is required') };
  }

  return undefined;
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  graphql(dataQuery, {
    options: () => ({
      fetchPolicy: 'network-only',
    }),
    props: ({
      data,
      data: { error, loading, user, reportTemplates, clients },
      ownProps: { initialValues, submitOnInit },
    }: any) => {
      if (error || loading) {
        return {
          data,
          initialValues,
          loading,
        };
      }

      const {
        organization: {
          defaultReportTemplate: { id, name },
        },
      } = user;
      const template = initialValues.reportTemplate;
      return {
        data,
        initialValues: submitOnInit
          ? initialValues
          : {
              ...initialValues,
              template: template
                ? {
                    value: template.id,
                    label: template.name,
                  }
                : {
                    value: id,
                    label: name,
                  },
            },
        clients,
        reportTemplates,
        loading,
      };
    },
  }),
)(
  reduxForm({
    form: SCHEDULE_REPORT_FORM,
    enableReinitialize: true,
    validate: validateForm,
  })(ScheduledReportBuilderForm),
);
