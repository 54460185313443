import { GetAllPublicKpisQuery, KpiBarQuery, useKpiBarQuery } from 'Ghql';
import { useFilters } from 'Hooks';
import { useSmartQuery } from '../../../../../../Utilities/Graphql/hooks/useSmartQuery';

/**  displayCurrency required for all kpiBarQueries, to enable cache invalidation */
export const useKpiBarQueryWithCurrency = (displayCurrency: string | undefined) => {
  const filters = useFilters();

  return useSmartQuery(useKpiBarQuery, {
    variables: { filters, name: 'kpiBar', displayCurrency: displayCurrency! },
    skip: !displayCurrency,
    fetchPolicy: 'cache-and-network',
  });
};

export interface KpiBarQueryProps {
  displayCurrency: string | undefined;
  data: KpiBarQuery | GetAllPublicKpisQuery | null | undefined;
  loading: boolean;
}
