import AccSegmentedControl from 'Components/AccSegmentedControl/AccSegmentedControl';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import styles from 'Pages/Keywords/Overview/components/SearchTypeSwitch/search-type-switch.module.scss';
import { t } from 'Utilities/i18n';

// eslint-disable-next-line import/no-unused-modules
export const SerpOwnedSegmentedControl = ({
  onChange,
  serpFeatureCanBeOwned,
  ownedChart,
}: {
  onChange: (value: string) => void;
  serpFeatureCanBeOwned: boolean | undefined;
  ownedChart: boolean;
}) => (
  <AccSegmentedControl
    value={ownedChart ? 'owned' : 'all'}
    defaultValue={ownedChart ? 'owned' : 'all'}
    onChange={onChange}
    size="sm"
    ml="auto"
    mb={2}
    data={[
      {
        label: (
          <AccTooltip tooltip={serpFeatureCanBeOwned ? '' : t('You cannot own this SERP feature.')}>
            <span>{t('Owned')}</span>
          </AccTooltip>
        ),
        value: 'owned',
        disabled: !serpFeatureCanBeOwned,
      },
      { label: t('All'), value: 'all' },
    ]}
    classNames={{
      label: styles.searchTypeSwitchLabel,
    }}
  />
);
