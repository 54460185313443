import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loadTemplate } from 'Actions/ReportTemplateAction';
import {
  useAddReportTemplateMutation,
  useReportTemplateByIdQuery,
  useReportTemplateUserOrgQuery,
} from 'Ghql';
import toast from 'Hooks/useToast';
import { useRouteMatch } from 'Utilities/Router/hooks/useRouteMatch';
import { useHistory } from 'Utilities/Router/withRouter';
import { t } from 'Utilities/i18n';
import TemplateBuilder from '..';

const CloneTemplate = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [saveTemplate, _rest] = useAddReportTemplateMutation();

  const { data: userOrg } = useReportTemplateUserOrgQuery();

  const history = useHistory();

  const match = useRouteMatch();

  const id = match.params?.id;

  const { data, loading, error } = useReportTemplateByIdQuery({
    variables: {
      id: id || '',
    },
    skip: !id,
  });

  const { name, brandColor, template } = data?.reportTemplate || {};

  const dispatch = useDispatch();

  // Dispatch loadTemplate action to the ReportTemplateReducer in Redux Store
  useEffect(() => {
    if (!error && data && !loading) {
      dispatch(
        loadTemplate(
          t('Copy of %s', name),
          brandColor || '',
          template ? JSON.parse(template) : undefined,
        ),
      );
    }
  }, [data, error, loading, brandColor, dispatch, name, template]);

  const handleSave = async (templateName: string, color: string, elements: any) => {
    const organizationId = userOrg?.user?.organization?.id || '';

    const input = {
      organization: organizationId,
      brandColor: color,
      name: templateName,
      template: elements,
    };

    await saveTemplate({
      variables: {
        input,
      },
    }).then((res) => {
      const errors = res.data?.addReportTemplate?.errors;
      if (errors?.length) {
        toast.error(t('Unable to save template: %s', errors));
      } else {
        toast.success(t('Template saved'));
        history.push('/reports/templates');
      }
    });
  };

  return <TemplateBuilder handleSave={handleSave} />;
};

export default CloneTemplate;
