import linkWithFilters from 'Components/Filters/linkWithFilters';
import type { HighestRankingPageFilter } from 'Types/Filter';
import { FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import { KEYWORDS_FILTER_SET } from 'Types/FilterSet';
import { extractHostname, getUrlPath } from 'Utilities/parser';
import { LANDING_ROOT_PATH } from './constants';
import { LandingTableItem } from './types';

const linkToKeywordList = (path: string, isKeydis?: boolean) => {
  const highestRankingPageFilter: HighestRankingPageFilter = {
    attribute: FilterAttribute.HIGHEST_RANKING_PAGE,
    type: FilterValueType.STRING,
    comparison: FilterComparison.ENDS_WITH,
    value: path,
  };
  return linkWithFilters({
    to: isKeydis ? '/keywords/keyword_discovery' : '/keywords/list',
    newFilters: [highestRankingPageFilter],
    filterSet: KEYWORDS_FILTER_SET,
  });
};

const getLandingPagePath = (e: LandingTableItem) => {
  return e?.path ?? '';
};

export const getLandingTablePath = (e: LandingTableItem) => {
  return e?.path?.startsWith('/') ? e?.path : getUrlPath(e?.path ?? '');
};

export const getIsDefaultLanding = (id?: string) => {
  return id === LANDING_ROOT_PATH;
};

export const selectFirstLandingElement = (data?: LandingTableItem[]) => {
  const id = data?.find((e) => getIsDefaultLanding(getLandingPagePath(e)))?.id ?? data?.[0]?.id;
  return id?.toString();
};

export const getKeywordsFilterLink = (domain: string, path: string, isKeydis?: boolean) => {
  return linkToKeywordList(extractHostname(domain ?? '')?.concat(path ?? ''), isKeydis);
};
