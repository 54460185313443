import cn from 'classnames';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import './Switch.scss';

type Props = {
  onClick: (...args: Array<any>) => void;
  width: number;
  // 300
  els: any[];
  className?: string;
  size?: 'small' | 'large';
  style?: any;
  switchToggle?: boolean;
  activeById?: boolean;
  activeId?: string | number | boolean;
  disabled?: boolean;
  tooltips?: string[];
};

const Switch = (props: Props) => {
  const { onClick, width, els, className, style, size, activeById, activeId, disabled, tooltips } =
    props;
  const activeEl =
    (activeById
      ? els.map((el, i) => (el.id === activeId ? { ...el, i } : undefined))
      : els.map((el, i) => (el.active ? { ...el, i } : undefined))
    ).filter((el) => el !== undefined)[0] || 0;
  return (
    <div
      className={cn(
        'Switch',
        {
          disabled,
          [`Switch--${size}`]: size,
        },
        className,
      )}
      style={Object.assign(
        {
          width,
        },
        style,
      )}
    >
      <div
        className="Switch-bar"
        style={{
          width: `calc(${100 / els.length}% - 2px)`,
          transform: `translateX(${activeEl.i * 100}%)`,
        }}
      />

      {els.map((el, i) => {
        const { icon: Icon } = el;
        const tooltip = tooltips && tooltips[i];
        const disable = !tooltip;
        return (
          <AccTooltip tooltip={tooltip} disable={disable} placement={'left'} key={`switch${i}`}>
            <div
              tabIndex={0}
              onClick={() => !disabled && onClick(el)}
              onKeyPress={() => !disabled && onClick(el)}
              className={cn('Switch-text', {
                active: activeById ? el.id === activeId : el.active,
              })}
              style={{
                width: `calc(${100 / els.length}%)`,
              }}
            >
              {el.icon ? <Icon className="Switch-icon" /> : el.name}
            </div>
          </AccTooltip>
        );
      })}
    </div>
  );
};

Switch.defaultProps = {
  width: 300,
};
export default Switch;
