import { useModal } from 'Hooks/base/useModal';

export const useAddKeywordsModal = (domainId, refetch): (() => any) => {
  const { showModal } = useModal();
  return () => {
    domainId &&
      showModal({
        modalType: 'AddKeywords',
        modalTheme: 'light',
        modalProps: {
          domainId: domainId,
          refresh: () => {
            refetch?.();
            setTimeout(() => refetch?.(), 10000); // ugly hack: wait for keywords to be scraped. no websock msg. sigh.
          },
        },
      });
  };
};
