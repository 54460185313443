import { useEffect, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';
import {
  getIsBottomOfVirtualizedTableSticky,
  getIsTopOfVirtualizedTableSticky,
  getTopOffsetForVirtualizedTable,
} from 'Components/Table/VirtualizedTable';
import 'Components/Table/VirtualizedTable/support/helpers';
import { ItemTypes } from '../../../react-dnd-treeview/ItemTypes';
import { useTreeDragLayer } from '../../../react-dnd-treeview/hooks';
import { smoothScrollBy } from '../../../support/helpers';

const SCROLL_SPEED = 1.2;

/**
 * We show auto-scroll only when its needed
 */
const useIsVisibleAutoScroll = ({ isDragging, isUp, topStickyOffset }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const listener = () => {
      const isSticky = isUp
        ? getIsTopOfVirtualizedTableSticky(topStickyOffset)
        : getIsBottomOfVirtualizedTableSticky();

      if (isSticky) {
        setShow(true);
      } else {
        setShow(false);
      }
    };

    if (isDragging) {
      listener();
      document.addEventListener('scroll', listener, false);
    }

    return () => {
      document.removeEventListener('scroll', listener);
    };
  }, [isDragging, show]);

  return show;
};

function useUpdateDragHandle(isOver: boolean, isUp: boolean, topStickyOffset: number) {
  useEffect(() => {
    let unsubscribe;
    if (isOver) {
      if (document.body.style) {
        document.body.style.cursor = isUp ? 'n-resize' : 's-resize';
      }

      const topOffset = getTopOffsetForVirtualizedTable(topStickyOffset);
      if (isUp) {
        const speed = Math.abs((window.scrollY ?? 0) * (1 / SCROLL_SPEED));

        unsubscribe = smoothScrollBy(topOffset - window.scrollY, speed);
      } else {
        const distance = Math.round(
          document.body.scrollHeight - (window.innerHeight + window.scrollY),
        );
        const speed = Math.abs((distance ?? 0) * (1 / SCROLL_SPEED));

        unsubscribe = smoothScrollBy(distance, speed, () => {
          return !getIsBottomOfVirtualizedTableSticky();
        });
      }
    }
    return () => {
      if (document.body.style) {
        document.body.style.cursor = '';
      }

      unsubscribe?.();
    };
  }, [isOver]);
}

export const useAutoScroll = (isUp: boolean, topStickyOffset: number = 0) => {
  const { isDragging } = useTreeDragLayer();

  const ref = useRef<HTMLDivElement | null>(null);
  const [{ isOver }, drop] = useDrop({
    accept: [ItemTypes.TREE_ITEM],
    collect: (monitor) => ({
      isOver: monitor.isOver({ shallow: true }) && monitor.canDrop(),
    }),
  });

  const show = useIsVisibleAutoScroll({ isDragging, isUp, topStickyOffset });
  useUpdateDragHandle(isOver, isUp, topStickyOffset);

  const showJump = isDragging && show;
  return { isDragging, show, isOver, drop, ref, showJump };
};
