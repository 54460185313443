import { memo } from 'react';
import { IconClipboardCopy } from '@tabler/icons-react';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { useClipboard } from 'Hooks';
import { t } from 'Utilities/i18n';
import styles from './copy-text-cell.module.scss';

interface Props {
  text: string | string[];
}

const CopytextCell = (props: Props) => {
  const { text } = props;

  const clipboard = useClipboard({ timeout: 1000 });

  return (
    <div className={styles['recipient-cell']}>
      {text}
      <AccTooltip old tooltip={clipboard.copied && t('Copied!')}>
        <AccActionIcon onClick={() => clipboard.copy(text)} radius="sm" variant="subtle">
          <IconClipboardCopy size={18} />
        </AccActionIcon>
      </AccTooltip>
    </div>
  );
};

export default memo(CopytextCell);
