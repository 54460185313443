import { memo } from 'react';
import { useHover } from '@mantine/hooks';
import isNil from 'lodash/isNil';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import ConditionalLink from 'Components/ConditionalLink/ConditionalLink';
import {
  KEYWORD_DISCOVERY_PAGE,
  useFolderLink,
} from 'Components/Filters/LinkWithFolders/linkWithFolders';
import linkWithFilters from 'Components/Filters/linkWithFilters';
import FormatNumber from 'Components/FormatNumber/new';
import Icon from 'Components/Icon/new';
import { FolderTypeWithTreeInfo } from 'Pages/Keywords/Groupings/groupingTypes';
import { FolderColumnID } from 'Pages/Keywords/Groupings/support/constants';
import { FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import { KEYWORDS_FILTER_SET } from 'Types/FilterSet';
import { propsIsEqualComparison } from 'Utilities/compare';
import { folderSeparator } from 'Utilities/generateSelectId';
import { t } from 'Utilities/i18n';
import KeyDisIcon from 'icons/menu/compass-keydis.svg?inline';
import { BaseCellProps } from '../../utils/useFoldersTableConfig';

/** Check if a folder only includes static tags */
const checkIfStaticFolder = (rootNode: FolderTypeWithTreeInfo) => {
  if (!rootNode.is_folder || rootNode.keydis_count !== 0) {
    return false;
  }
  if (rootNode.children.some((child) => child.is_dynamic)) {
    return false;
  }
  return true;
};

const useDomainLink = (domainId: string) =>
  linkWithFilters({
    to: '/keywords/keyword_discovery',
    overwriteFilters: [
      {
        attribute: FilterAttribute.DOMAINS,
        type: FilterValueType.LIST,
        comparison: FilterComparison.CONTAINS,
        value: [domainId],
      },
    ],
    filterSet: KEYWORDS_FILTER_SET,
    resetFilters: true,
  });

export const DiscoveredKeywordsCell = memo(<T extends BaseCellProps>(props: T) => {
  const isDomainsComparison = props.rootNode.isDomainsComparison;
  const domainId = isDomainsComparison ? props.rootNode.path.split(folderSeparator)[1] : undefined;

  const folderOrTagLink = useFolderLink({
    name: props.rootNode.name,
    isFolder: props.rootNode.is_folder,
    path: props.rootNode.path,
    pageToGoTo: KEYWORD_DISCOVERY_PAGE,
    depth: props.rootNode.depth,
    domainId,
  });

  const domainLink = useDomainLink(domainId || '');

  const isDomain = 'is_domain' in props.rootNode && props.rootNode.is_domain;
  const isGroup = 'is_group' in props.rootNode && props.rootNode.is_group;
  const isFolder = props.rootNode.is_folder;
  const isTag = !props.rootNode.is_folder && !isGroup && !isDomain;

  const link = isFolder || isTag ? folderOrTagLink : isDomain ? domainLink : '';

  const { hovered, ref } = useHover();

  // We should not show a link when a folder only contains static tags
  const folderIncludeStaticTagsOnly = checkIfStaticFolder(props.rootNode);
  if ((isTag && !!props.rootNode.is_dynamic !== true) || isGroup || folderIncludeStaticTagsOnly) {
    return (
      <AccTooltip
        tooltip={isGroup ? t('Not available for groups') : t('Only available for dynamic tags')}
      >
        <div className={props.classes.rightTextAlign}>{t('N/A')}</div>
      </AccTooltip>
    );
  }

  const disableLink = isNil(props.rootNode.keydis_count);

  return (
    <div
      className={props.classes.rightTextAlign}
      key={FolderColumnID.DISCOVERED_KEYWORDS}
      ref={ref}
    >
      <AccTooltip tooltip={disableLink ? t('N/A') : t('Go to discovery')}>
        <ConditionalLink to={disableLink ? undefined : link} className="table-link">
          {hovered && !disableLink && (
            <Icon width={22} height={14}>
              <KeyDisIcon />
            </Icon>
          )}
          <FormatNumber value={props.rootNode.keydis_count} />
        </ConditionalLink>
      </AccTooltip>
    </div>
  );
}, propsIsEqualComparison);
DiscoveredKeywordsCell.displayName = 'DiscoveredKeywordsCell';
