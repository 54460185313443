import { Group, Menu } from '@mantine/core';
import { useClickOutside, useDisclosure } from '@mantine/hooks';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import { QueryOrder, SORTING_TYPE } from 'Constants';
import { Ordering, OrderingState } from 'Types/Sort';
import { getSortingIcon } from 'Utilities/getSortingInfo';
import { t } from 'Utilities/i18n';
import SortItem from './SortItem';
import styles from './sort.module.scss';

export type SortingMenuItem = {
  orderBy: string;
  defaultOrder: QueryOrder;
  sortingKey: SORTING_TYPE;
  label: string;
};

type Props = {
  options: SortingMenuItem[];
  ordering: OrderingState | null;
  onOrderChange: (ordering: OrderingState) => void;
};
const Sort = ({ options, ordering, onOrderChange }: Props) => {
  const [isOpen, handlers] = useDisclosure(false);
  const onSortClick = (order: Ordering) => {
    const onClick = () => {
      onOrderChange({
        orderBy: order.orderBy,
        order: order.defaultOrder,
      });
      handlers.close();
    };
    return onClick;
  };

  const menuRef = useClickOutside(() => {
    return handlers.close();
  });

  const Chevron = isOpen ? IconChevronUp : IconChevronDown;
  const selectedOption =
    (ordering && options.find((item) => item.orderBy === ordering?.orderBy)) || null;
  const label = selectedOption?.label || t('Sort');
  return (
    <Menu
      width={200}
      shadow="md"
      position="bottom-start"
      opened={isOpen}
      closeOnItemClick={false}
      classNames={{
        dropdown: styles.sortDropdown,
        item: styles.sortItem,
      }}
      closeOnEscape={true}
      menuItemTabIndex={0}
      returnFocus={true}
    >
      <Menu.Target>
        <AccButton
          variant="secondary"
          radius="sm"
          size="xs"
          miw={190}
          data-selected={Boolean(ordering)}
          data-opened={isOpen}
          classNames={{
            root: styles.sortButton,
            label: styles.sortLabel,
          }}
          onClick={handlers.toggle}
        >
          <Group gap="md">
            {!!selectedOption &&
              getSortingIcon(ordering?.order === QueryOrder.DESC, true, selectedOption)}
            <span>{label}</span>
          </Group>
          <div className={styles.sortButtonChevron}>
            <Chevron size={16} />
          </div>
        </AccButton>
      </Menu.Target>

      <Menu.Dropdown ref={menuRef}>
        {options.map((option) => (
          <SortItem option={option} onSortClick={onSortClick} key={option.orderBy} />
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};

export default Sort;
