import { Grid } from '@mantine/core';
import { t } from 'Utilities/i18n';
import { Icon } from '../../../../../icons/Icon';

const OveruseInfoPanelBlock = ({ title, icon, primaryCount, secondaryCount }: any) => {
  return (
    <div className="overuse-info-panel__block">
      <div className="overuse-info-panel__block__icon">
        <Icon type={icon} />
      </div>
      <div className="overuse-info-panel__block__info">
        <div className="overuse-info-panel__block__title control-font-family">{title}</div>
        <div className="overuse-info-panel__block__price">
          <b>{primaryCount}</b> /{' '}
          {!secondaryCount || secondaryCount === -1 ? <>&#8734;</> : secondaryCount}
        </div>
      </div>
    </div>
  );
};

export const OveruseInfoPanelBlockList = (props: {
  keywordsCount?: number;
  domainsCount?: number;
  usersCount?: number;
  permRoles?: number;
  maxKeywords?: number;
  maxDomains?: number;
  maxUsers?: number;
  maxPermRoles?: number;
}) => {
  const configs = [
    {
      title: t('Keywords'),
      icon: 'menu-keywords',
      primaryCount: props.keywordsCount,
      secondaryCount: props.maxKeywords,
    },
    {
      title: t('Domains'),
      icon: 'menu-domains',
      primaryCount: props.domainsCount,
      secondaryCount: props.maxDomains,
    },
    {
      title: t('Users'),
      icon: 'menu-users',
      primaryCount: props.usersCount,
      secondaryCount: props.maxUsers,
    },
    {
      title: t('Perm. roles'),
      icon: 'menu-groups',
      primaryCount: props.permRoles,
      secondaryCount: props.maxPermRoles,
    },
  ];
  return (
    <Grid>
      {configs.map((e) => (
        <Grid.Col span={{ lg: 3 }} key={e.title}>
          <OveruseInfoPanelBlock {...e} />
        </Grid.Col>
      ))}
    </Grid>
  );
};
