import { useEffect, useRef, useState } from 'react';
import { Box, Tabs } from '@mantine/core';
import { IconTrendingDown, IconTrendingUp } from '@tabler/icons-react';
import AccText from 'Components/Text/AccText';
import { WinnerAndLosersQuery, useWinnerAndLosersQuery } from 'Ghql';
import { useFilters } from 'Hooks';
import { ChartContainer } from 'Pages/Keywords/Overview/components/ChartContainer/index';
import { WinnerLosersTable } from 'Pages/Keywords/Overview/components/WinnersLosers/WinnerLosersTable';
import { WithDragDetails } from 'Pages/Keywords/Overview/support/types';
import { colorScheme } from 'Utilities/colors';
import { t, tct } from 'Utilities/i18n/index';
import { WinnerLosersBar } from './WinnerLosersBar';
import { WinnerLoserTabLabel } from './components/WinnerLoserTabLabel';
import styles from './winners-losers.module.scss';

export type ResultData = {
  winner: number;
  losers: number;
  winnerSov: number;
  loserSov: number;
  periodFrom?: string;
  periodTo?: string;
};

const getResultWinnerLosersData = (data?: WinnerAndLosersQuery) => {
  const chartData = data?.charts?.winnersAndLosers?.[0];
  return {
    winner: chartData?.rankWinners ?? 0,
    losers: chartData?.rankLosers ?? 0,
    winnerSov: chartData?.winnersSovGain ?? 0,
    loserSov: chartData?.losersSovLoss ?? 0,
    periodFrom: chartData?.periodFrom,
    periodTo: chartData?.periodTo,
  };
};
const tabs = { WINNERS: 'winners', LOSERS: 'losers' };

const Tooltip = () => {
  return (
    <>
      <AccText size="sm">
        {tct(
          '[tab1][br]' +
            'Keywords with an increase in rank since the compare date.[br][br]' +
            '[tab2][br]' +
            'Keywords with a decrease in rank since the compare date.[br][br]' +
            '[tab3][br]' +
            'The date selected in the date picker in the top right of the page.[br][br]' +
            '[tab4][br]' +
            'Click the Winners/Losers tab to see the corresponding keywords in the table below, or click the icon next to the Winners or Losers title to see the keywords in the keyword list.',
          {
            br: <br />,
            tab1: <b>{t('Winners')}</b>,
            tab2: <b>{t('Losers')}</b>,
            tab3: <b>{t('Compare date:')}</b>,
            tab4: <b>{t('Hint:')}</b>,
          },
        )}
      </AccText>
    </>
  );
};
const WinnersAndLosersComponent = ({ useAi, ...props }: { useAi: boolean } & WithDragDetails) => {
  const filters = useFilters();
  const { data, loading } = useWinnerAndLosersQuery({
    variables: { filters, name: 'winnersAndLosersChart', useAi },
    fetchPolicy: 'cache-first',
  });
  const chartRef = useRef<HTMLDivElement>(null);
  const resultData = getResultWinnerLosersData(data);
  const [activeTab, setActiveTab] = useState<string | null>(tabs.WINNERS);

  useEffect(() => {
    if (!resultData.winner && resultData.losers > 0) {
      setActiveTab(tabs.LOSERS);
    }
  }, [resultData.winner, resultData.losers]);

  return (
    <ChartContainer
      title={useAi ? t('Top AI Winners/Losers') : t('Top Winners/Losers')}
      tooltip={[<Tooltip key="0" />]}
      dragRef={props.dragRef}
      boardControls={props.boardControls}
      chartRef={chartRef}
    >
      <Box bg="white" ref={chartRef}>
        <WinnerLosersBar useAi={useAi} loading={loading} resultData={resultData} />
        <Tabs value={activeTab} onChange={setActiveTab} className={styles.tab} keepMounted={false}>
          <Tabs.List>
            <Tabs.Tab value={tabs.WINNERS} disabled={!resultData.winner}>
              <WinnerLoserTabLabel
                label={t('Winners')}
                color={colorScheme.increase}
                icon={IconTrendingUp}
                keywords={resultData.winner}
              />
            </Tabs.Tab>
            <Tabs.Tab value={tabs.LOSERS} disabled={!resultData.losers}>
              <WinnerLoserTabLabel
                label={t('Losers')}
                color={colorScheme.decrease}
                icon={IconTrendingDown}
                keywords={resultData.losers}
              />
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value={tabs.WINNERS} pt="xs">
            {activeTab === tabs.WINNERS && (
              <WinnerLosersTable winnersSelected={true} useAi={useAi} />
            )}
          </Tabs.Panel>
          <Tabs.Panel value={tabs.LOSERS} pt="xs">
            {activeTab === tabs.LOSERS && (
              <WinnerLosersTable winnersSelected={false} useAi={useAi} />
            )}
          </Tabs.Panel>
        </Tabs>
      </Box>
    </ChartContainer>
  );
};

export const WinnersAndLosers = (props) => {
  return <WinnersAndLosersComponent useAi={false} {...props} />;
};

export const AiWinnersAndLosers = (props) => {
  return <WinnersAndLosersComponent useAi={true} {...props} />;
};
