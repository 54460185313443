import { IconCirclePlus, IconUpload } from '@tabler/icons-react';
import { toJS } from 'mobx';
import AccButton from 'Components/AccButton/AccButton';
import { useTableStore } from 'Components/DataTable';
import FormatNumber from 'Components/FormatNumber/new';
import { AddKeywordsMode, KeydisImportConfig } from 'Components/Modal/Content/AddKeywords/types';
import { getDisabledDemoText, getReadOnlyFeatureText } from 'Constants/messages';
import { CountByCountryNode } from 'Ghql';
import { useSpecificFilter } from 'Hooks';
import { useModal } from 'Hooks/base/useModal';
import { useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import useUserPermission from 'Hooks/useUserPermission';
import { getPlaceholderKeywords } from 'Pages/KeywordDiscovery/support/helpers';
import { removeProtocolFromUrl } from 'Pages/SiteMapping/support/helpers';
import { DomainsFilter, FilterAttribute } from 'Types/Filter';
import { TableIDs } from 'Types/Table';
import { TrackingKey } from 'Utilities/Analytics/mixpanel';
import { t, tct } from 'Utilities/i18n';

interface ImportButtonProps {
  selectedKeywords: number;
  isDisabled: boolean;
  selectedCountry: CountByCountryNode | undefined;
  tableType: typeof TableIDs.KEYWORD_DISCOVERY | typeof TableIDs.RESEARCH;
}

// eslint-disable-next-line import/no-unused-modules
export const ImportButton = ({
  selectedKeywords,
  isDisabled,
  selectedCountry,
  tableType,
}: ImportButtonProps) => {
  const { showModal } = useModal();
  const freetextFilter = useSpecificFilter(FilterAttribute.FREE_TEXT_DOMAIN);
  const { userHasWriteAccess } = useUserPermission();

  const domain: DomainsFilter['value'][0] | undefined = useSpecificFilter(FilterAttribute.DOMAINS)
    ?.value[0];

  const isFreeTextSearch = !!freetextFilter?.value;

  const tableStore = useTableStore(tableType);

  const { isDemoDomain } = useQueryDomainInfo();

  const handleImportKeywords = () => {
    const nKeywordsToAdd =
      (tableStore?.isAllSelected
        ? Math.min(
            tableStore?.totalDataLength,
            tableStore?.maxSelectCount ?? tableStore?.totalDataLength,
          )
        : 0) ?? 0;
    console.log({ nKeywordsToAdd });
    console.log({
      totalDataLength: tableStore?.totalDataLength,
      maxSelectCount: tableStore?.maxSelectCount,
    });
    const importConfig: KeydisImportConfig = {
      excludeKeywords: toJS(tableStore?.unSelectedRows),
      includeKeywords: toJS(tableStore?.selectedRows),
      nKeywordsToAdd,
      ordering: toJS(tableStore?.ordering),
    };

    showModal({
      modalType: 'AddKeywords',
      modalTheme: 'light',
      modalProps: {
        domainId: domain,
        domain,
        mode: AddKeywordsMode.IMPORT,
        placeholderKeywords: getPlaceholderKeywords(tableStore!),
        keydisKeywordsCounter: tableStore?.totalSelectedCount,
        selectedCountry,
        importConfig,
      },
    });
  };

  const handleAddDomain = () => {
    showModal({
      modalType: 'BuildDomain',
      modalTheme: 'light',
      modalProps: {
        domain: removeProtocolFromUrl(freetextFilter?.value || ''),
        tableStore,
        selectedCountry,
      },
    });
  };

  return (
    <AccButton
      variant="primary"
      onClick={isFreeTextSearch ? handleAddDomain : handleImportKeywords}
      leftSection={isFreeTextSearch ? <IconCirclePlus size={20} /> : <IconUpload size={20} />}
      mx="auto"
      miw={225}
      disabled={isDisabled || isDemoDomain || !userHasWriteAccess}
      tooltip={
        isDemoDomain
          ? getDisabledDemoText()
          : !userHasWriteAccess
          ? getReadOnlyFeatureText()
          : undefined
      }
      trackingKey={
        isFreeTextSearch
          ? TrackingKey.AddDomainFromKeywordDiscovery
          : TrackingKey.AddKeywordsFromKeywordDiscovery
      }
    >
      {isFreeTextSearch
        ? selectedKeywords > 0
          ? t('Add domain with keywords')
          : t('Add domain')
        : selectedKeywords > 0
        ? tct('Import [keywords] keywords', {
            keywords: (
              <strong>
                <FormatNumber value={selectedKeywords || 0} />
              </strong>
            ),
          })
        : t('Select keywords to import')}
    </AccButton>
  );
};
