import { loaderHTML } from 'Components/Loader';
import './highcharts-loader.scss';

export const updateLoader = (chart?: Record<string, any>, period?: number, isLoading?: boolean) => {
  return isLoading
    ? chart?.showLoading?.(
        loaderHTML({
          period,
          className: 'chart-loader-inner-container',
        }),
      )
    : chart?.hideLoading?.();
};
