import { UnrankedWeightConstants } from 'Constants';
import { FieldName, UnrankedWeightEnabled } from '.';

/**
 * If the user has selected "Yes" then we want to send the unranked weight value to the backend.
 *
 * If the user has selected "Account Default" then we want to send the unranked weight value of 0 to the backend.
 *
 * If the user has selected "No" then we want to send the unranked weight value of null to the backend.
 */
export const getUnrankedWeightInput = (values: Record<FieldName, any>) => {
  const isAccountDefault =
    values[FieldName.unrankedWeightEnabled] === UnrankedWeightEnabled.accountDefault;

  const isNo = values[FieldName.unrankedWeightEnabled] === UnrankedWeightEnabled.no;

  let unrankedWeightInput: number | null = null;
  if (isAccountDefault) {
    unrankedWeightInput = UnrankedWeightConstants.ACCOUNT_DEFAULT;
  } else if (isNo) {
    unrankedWeightInput = null;
  } else {
    unrankedWeightInput = parseInt(values[FieldName.unrankedWeight], 10);
  }

  return unrankedWeightInput;
};

export const validateUnrankedWeight = (values: Record<FieldName, any>, errors) => {
  const isYes = values[FieldName.unrankedWeightEnabled] === UnrankedWeightEnabled.yes;

  if (isYes) {
    const unrankedWeight = parseInt(values[FieldName.unrankedWeight], 10);
    if (unrankedWeight < UnrankedWeightConstants.MIN_UNRANKED_WEIGHT) {
      errors[FieldName.unrankedWeight] = ' ';
    } else if (unrankedWeight > UnrankedWeightConstants.MAX_UNRANKED_WEIGHT) {
      errors[FieldName.unrankedWeight] = ' ';
    }
  }
};
