import { Component } from 'react';
import AccButton from 'Components/AccButton/AccButton';
import { withRouter } from 'Utilities/Router';
import { t } from 'Utilities/i18n';
import './advanced-plan-upsell-chart.scss';

type Props = {
  history: Record<string, any>;
  title?: string;
  subTitle?: string;
  subSubTitle?: string;
  onCollapse: (...args: Array<any>) => any;
  collapsed: boolean;
};

class AdvancedPlanUpsellChart extends Component<Props & any> {
  handleUpgrade = () => {
    this.props.history.push('/billing/package/select');
  };
  handleToggleDetails = (evt) => {
    evt.preventDefault();
    const { onCollapse } = this.props;
    onCollapse();
  };

  render() {
    const { collapsed, title, subTitle, subSubTitle } = this.props;
    return (
      <div className="advanced-plan-upsell-chart">
        <h2>{title || t('Want More Metrics?')}</h2>
        {!collapsed ? (
          [
            <p key="title">
              {subTitle ||
                t('Upgrade your plan to enable advanced metrics such as SoV Pro and more.')}
              <br />
              <i>{subSubTitle}</i>
            </p>,
            <AccButton key="upgrade" onClick={this.handleUpgrade} variant="primary">
              {t('Upgrade plan')}
            </AccButton>,
            <p key="link">
              <a
                className="advanced-plan-upsell-show-link"
                tabIndex={0}
                onClick={this.handleToggleDetails}
              >
                {t('Cancel')}
              </a>
            </p>,
          ]
        ) : (
          <a
            className="advanced-plan-upsell-show-link"
            tabIndex={0}
            onClick={this.handleToggleDetails}
          >
            {t('Click to read more')}
          </a>
        )}
      </div>
    );
  }
}

export default withRouter(AdvancedPlanUpsellChart) as any;
