import { useMemo } from 'react';
import { Field, Fields, formValues } from 'redux-form';
import FilterCountField from 'Components/Filters/FilterCountField';
import { DropdownField, TagsField } from 'Components/Forms/Fields';
import { FilterComparison } from 'Types/Filter';
import { t } from 'Utilities/i18n/index';
import Validation from 'Utilities/validation';
import styles from './tags-editor.module.scss';

type Props = {
  comparison: string;
  tagsProps?: Record<string, any>;
};

const TagsEditor = (props: Props) => {
  const { comparison } = props;
  const comparisonOptions = useMemo(
    () => [
      {
        id: FilterComparison.ANY,
        label: t('Any of the selected tags'),
      },
      {
        id: FilterComparison.ALL,
        label: t('All of the selected tags'),
      },
      {
        id: FilterComparison.NONE,
        label: t('None of the selected tags'),
      },
      {
        id: FilterComparison.EMPTY,
        label: t('No tags'),
      },
    ],
    [],
  );

  return (
    <div>
      {comparison !== FilterComparison.EMPTY && (
        <div className={styles.tags}>
          <Field
            autoFocus
            name="value"
            component={TagsField}
            placeholder={t('Enter the tags')}
            creatable={false}
            validate={[Validation.array]}
            {...props.tagsProps}
          />
        </div>
      )}
      <div className={styles.comparisonContainer}>
        <Fields
          names={['comparison', 'value', 'type', 'attribute']}
          component={FilterCountField}
          pos="absolute"
          right="30px"
          top="12px"
        />
        <Field name="comparison" component={DropdownField} items={comparisonOptions} />
      </div>
    </div>
  );
};

export default formValues('comparison')(TagsEditor);
