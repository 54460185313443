import moment from 'moment';
import DateEditor from 'Components/Filters/Common/DateEditor';
import { FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import type { DateFilter } from 'Types/Filter';
import { t } from 'Utilities/i18n/index';
import CalendarIcon from 'icons/calendar.svg?inline';

const defaultValue = {
  attribute: FilterAttribute.SIGNED_UP,
  type: FilterValueType.DATETIME,
  comparison: FilterComparison.EQ,
  value: moment().format('YYYY-MM-DD'),
};

const getComparisonLabels = () => ({
  [FilterComparison.EQ]: t('On'),
  [FilterComparison.BETWEEN]: t('Between'),
  [FilterComparison.GT]: t('After'),
  [FilterComparison.LT]: t('Before'),
});

const getData = () => ({
  defaultValue,
  title: t('SignedUp'),
  icon: CalendarIcon,
  editor: DateEditor,
  labelFunc: (filter: DateFilter) => `${getComparisonLabels()[filter.comparison]} ${filter.value}`,
});

export default getData;
