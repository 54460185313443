import { memo } from 'react';
import isEqual from 'lodash/isEqual';
import { NotesType } from 'Components/Chart/LineChart';
import { useHistoryChartsQuery } from 'Ghql';
import { useFilters } from 'Hooks';
import { useModal } from 'Hooks/base/useModal';
import { useFakeData } from 'Pages/Keywords/Overview/hooks/useFakeData';
import { useGroupViewInfo } from 'Pages/Keywords/Overview/hooks/useGroupViewInfo';
import { WithDragDetails } from '../../../support/types';
import { RankingDistributionChartContent } from './RankingDistributionChartContent';
import { RankingDistributionSeries, formatSeries, getRankingDistributionSeries } from './helpers';

export type RankingDistributionProps = {
  domainId: string | undefined;
  notesLoading: boolean;
  notes: NotesType[];
  onNotesSelect: (...args: Array<any>) => any;
  enabledHistoryCharts: string[];
  isBaseRankingDistribution?: boolean;
} & WithDragDetails;

// eslint-disable-next-line import/no-unused-modules
export const RankingDistribution = memo(
  ({
    domainId,
    notes,
    onNotesSelect,
    notesLoading,
    enabledHistoryCharts,
    isBaseRankingDistribution,
    dragging,
    ...props
  }: RankingDistributionProps) => {
    const filters = useFilters();
    const { isEmptyGroup, isGroupView } = useGroupViewInfo(filters);
    const { showModal } = useModal();
    const { RankingDistributionFakeData } = useFakeData();
    const { data, loading, refetch } = useHistoryChartsQuery({
      variables: { filters, name: 'historyCharts', charts: enabledHistoryCharts },
      skip: isEmptyGroup,
    }); // use all history chart names to reuse query

    const chartSeries: RankingDistributionSeries[] | undefined = getRankingDistributionSeries(
      data?.graphs?.overviewHistoryGraphs,
      isBaseRankingDistribution,
    );

    const showFakeData = !data?.graphs?.overviewHistoryGraphs?.length;
    const series = formatSeries(showFakeData ? RankingDistributionFakeData : chartSeries);

    const addKeywordsModal = () => {
      domainId &&
        showModal({
          modalType: 'AddKeywords',
          modalTheme: 'light',
          modalProps: {
            domainId,
            refresh: () => refetch?.(),
          },
        });
    };

    return (
      <RankingDistributionChartContent
        chartSeries={series}
        domainId={domainId}
        loading={dragging || loading || notesLoading}
        isGroupView={isGroupView}
        isEmptyGroup={isEmptyGroup}
        dragRef={props.dragRef}
        boardControls={props.boardControls}
        onMissingDataOverlayClick={addKeywordsModal}
        showFakeData={showFakeData}
        isBaseRankingDistribution={isBaseRankingDistribution}
        dragging={dragging}
        notes={notes}
        notesLoading={notesLoading}
        onNotesSelect={onNotesSelect}
      />
    );
  },
  isEqual,
);
RankingDistribution.displayName = 'RankingDistribution';
