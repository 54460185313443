import { Center } from '@mantine/core';
import AccButton from 'Components/AccButton/AccButton';
import { Field, Form } from 'Components/Fields';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import Spinner from 'Components/Spinner';
import { useLockOrganizationMutation, useOrganizationQuery } from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import toast from 'Hooks/useToast';
import { throwNetworkError } from 'Utilities/errors';
import { t } from 'Utilities/i18n';
import { notEmpty } from 'Utilities/underdash';
import Validator from 'Utilities/validation';
import formStyle from 'css/layout/form-layout.module.scss';

type LockOrganizationFormValues = {
  type: 1 | 2 | 3 | undefined;
  blockedDomainIds: string[] | undefined;
  accountBlockedCustomMessage: string | undefined;
};

const INITIAL_FORM_VALUES: LockOrganizationFormValues = {
  type: undefined,
  blockedDomainIds: undefined,
  accountBlockedCustomMessage: undefined,
};

const SalesLockOrganization = ({ organizationId }: { organizationId: string | undefined }) => {
  const { hideModal } = useModal();

  const { data, loading } = useOrganizationQuery({
    fetchPolicy: 'network-only',
    variables: { id: organizationId || '' },
    skip: !organizationId,
  });
  const [lockOrganizationMutation] = useLockOrganizationMutation();
  const handleSubmit = ({
    type,
    blockedDomainIds,
    accountBlockedCustomMessage,
  }: LockOrganizationFormValues) => {
    const blockedDomainIdsAsInt =
      blockedDomainIds?.map((domainId) => {
        return parseInt(domainId, 10);
      }) || [];

    organizationId &&
      type &&
      lockOrganizationMutation({
        variables: {
          input: {
            organizationId: organizationId?.toString() || '',
            type,
            blockedDomainIds: blockedDomainIdsAsInt,
            accountBlockedCustomMessage,
          },
        },
      }).then((response) => {
        const errors = response.data?.lockOrganization?.errors?.filter(notEmpty) || [];
        if (errors.length > 0) {
          toast.error(errors[0]?.messages[0]);
          Validator.setResponseErrors(Validator.throwSubmissionError, errors);
        } else {
          toast.success(t('Organization locked'));
          window.location.reload();
        }
      }, throwNetworkError);
  };

  const FormContent = () => {
    if (loading) {
      return (
        <Center p="xl">
          <Spinner />
        </Center>
      );
    }

    const domains =
      data?.adminOrganization?.organizationClients
        ?.filter(notEmpty)
        .flatMap((client) =>
          client?.domains?.filter(notEmpty).map((domain) => ({
            value: domain.id,
            label: `[${client.name}] (${domain?.displayName || ''}) ${domain?.domain}`,
          })),
        )
        .filter(notEmpty) || [];

    return (
      <Form
        onSubmit={handleSubmit}
        initialValues={INITIAL_FORM_VALUES}
        subscription={{
          ...Form.defaultProps.subscription,
          invalid: true,
        }}
      >
        {({ submitting, invalid }) => (
          <>
            <div className={formStyle.formRow}>
              <div className="form-label">{t('Type')}</div>
              <Field.Select
                name="type"
                required
                options={[
                  {
                    value: 1,
                    label: t('Abuse'),
                  },
                  {
                    value: 2,
                    label: t('No contact'),
                  },
                  {
                    value: 3,
                    label: t('Pause'),
                  },
                ]}
                validate={Validator.required}
              />
            </div>
            <div className={formStyle.formRow}>
              <div className="form-label">{t('Blacklist domains')}</div>
              <Field.MultiSelect name="blockedDomainIds" options={domains} />
            </div>
            <div className={formStyle.formRow}>
              <div className="form-label">{t('Custom message')}</div>
              <Field.TextInput
                name="accountBlockedCustomMessage"
                placeholder={t('Enter custom message to be shown on blocked page')}
              />
            </div>
            <ModalFooter
              primaryButtonSlot={
                <AccButton variant="primary" type="submit" disabled={invalid || submitting}>
                  {t('Lock Account')}
                </AccButton>
              }
            />
          </>
        )}
      </Form>
    );
  };

  return (
    <ModalBorder title={t('Lock Organization')} onClose={hideModal}>
      <FormContent />
    </ModalBorder>
  );
};

export default SalesLockOrganization;
