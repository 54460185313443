import { Alert, AlertProps, useMantineTheme } from '@mantine/core';
import { IconAlertCircle, IconDiscountCheck, IconInfoCircle } from '@tabler/icons-react';
import styles from './accAlert.module.scss';

export type AccAlertProps = {
  severity: 'error' | 'warning' | 'info' | 'success';
  variant?: 'standard' | 'block';
  singleLine?: boolean;
  hideIcon?: boolean;
  withCloseButton?: boolean;
} & AlertProps;

const AccAlert = (props: AccAlertProps) => {
  const mantineTheme = useMantineTheme();
  const {
    severity,
    variant = 'standard',
    singleLine,
    hideIcon,
    withCloseButton,
    ...otherProps
  } = props;

  const getAlertIcon = () => {
    switch (severity) {
      case 'info':
        return <IconInfoCircle color={mantineTheme.colors.blue[4]} />;
      case 'warning':
        return <IconAlertCircle color={mantineTheme.colors.yellow[9]} />;
      case 'error':
        return <IconAlertCircle color={mantineTheme.colors.red[4]} />;
      case 'success':
        return <IconDiscountCheck color={mantineTheme.colors.green[4]} />;
      default:
        return <IconInfoCircle color={mantineTheme.colors.yellow[9]} />;
    }
  };

  return (
    <Alert
      classNames={{
        root: variant === 'standard' ? styles.rootStandard : styles.rootBlock,
        body: styles.body,
        wrapper: styles.wrapper,
        label: styles.label,
        message: styles.message,
        title: styles.title,
        closeButton: styles.closeButton,
      }}
      data-single-line={singleLine || null}
      data-error={severity === 'error' || null}
      data-warning={severity === 'warning' || null}
      data-info={severity === 'info' || null}
      data-success={severity === 'success' || null}
      icon={!hideIcon && getAlertIcon()}
      variant={variant}
      withCloseButton={withCloseButton}
      {...otherProps}
    />
  );
};

export default AccAlert;
