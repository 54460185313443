import { ActionIcon, Group } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { IconCalendar } from '@tabler/icons-react';
import moment from 'moment';
import AccLazyPopover from 'Components/AccLazyPopover/AccLazyPopover';
import AccuCell from 'Components/AccuTable/CellRenderer/HelperComponents/AccuCell';
import { DEFAULT_BACKEND_DATE_FORMAT } from 'Constants';

type DatePickerCellRendererProps = {
  date: string;
  minDate?: Date;
  maxDate?: Date;
  monthly?: boolean;
  onDateChange: (date: string) => void;
};

const CalendarActionIcon = () => (
  <ActionIcon variant="subtle" color="gray.6" size="xs">
    <IconCalendar size={16} />
  </ActionIcon>
);

const DatePickerCell = ({
  date,
  monthly,
  minDate,
  maxDate,
  onDateChange,
}: DatePickerCellRendererProps) => {
  let monthlyProps = {};
  if (monthly) {
    monthlyProps = {
      level: 'year',
      onLevelChange: () => {
        return false;
      },
      onMonthSelect: (month) => onDateChange(moment(month).format(DEFAULT_BACKEND_DATE_FORMAT)),
    };
  }

  return (
    <Group justify="space-between">
      <span>{date}</span>
      <AccLazyPopover target={<CalendarActionIcon />}>
        <DatePicker
          {...monthlyProps}
          size="sm"
          value={new Date(date)}
          minDate={minDate}
          maxDate={maxDate}
          onChange={(dv) => {
            if (!dv) return;
            if (monthly) return;
            onDateChange(moment(dv).format(DEFAULT_BACKEND_DATE_FORMAT));
          }}
        />
      </AccLazyPopover>
    </Group>
  );
};

/**
 * For the "date" cells in the Neighborly tables (upper left corner).
 * Shows a date and a date picker.
 *
 * If no `onDateChange` is provided, it will just show the raw data (in "date"). As a fallback for the other cells
 */
const DatePickerCellRenderer = ({ onDateChange, date, ...rest }: DatePickerCellRendererProps) => {
  if (onDateChange) {
    return <DatePickerCell onDateChange={onDateChange} date={date} {...rest} />;
  }
  return <AccuCell>{date}</AccuCell>;
};

export default DatePickerCellRenderer;
