import { SortingMenuItem } from 'Components/Sort';
import { QueryOrder, SORTING_TYPE } from 'Constants';
import { DomainColumnIDs } from 'Pages/Domains/support/types';
import { t } from 'Utilities/i18n';

export const defaultOrdering = {
  order: QueryOrder.ASC,
  orderBy: DomainColumnIDs.DOMAIN_DISPLAY_NAME,
};

export const getSorting = (): SortingMenuItem[] => {
  return [
    {
      defaultOrder: QueryOrder.ASC,
      orderBy: DomainColumnIDs.DOMAIN_DISPLAY_NAME,
      sortingKey: SORTING_TYPE.alphabetical,
      label: t('Domain Name'),
    },
    {
      defaultOrder: QueryOrder.ASC,
      orderBy: DomainColumnIDs.KEYWORDS,
      sortingKey: SORTING_TYPE.numerical,
      label: t('Tracked Keywords'),
    },
    {
      defaultOrder: QueryOrder.ASC,
      orderBy: DomainColumnIDs.DISCOVERED_KEYWORDS,
      sortingKey: SORTING_TYPE.numerical,
      label: t('Discovered Keywords'),
    },
    {
      defaultOrder: QueryOrder.DESC,
      orderBy: DomainColumnIDs.ABOVE_THE_FOLD,
      sortingKey: SORTING_TYPE.numerical,
      label: t('Above the Fold'),
    },
    {
      defaultOrder: QueryOrder.ASC,
      orderBy: DomainColumnIDs.AVERAGE_RANK,
      sortingKey: SORTING_TYPE.rank,
      label: t('Average Rank'),
    },
    {
      defaultOrder: QueryOrder.DESC,
      orderBy: DomainColumnIDs.AI_SHARE_OF_VOICE,
      sortingKey: SORTING_TYPE.numerical,
      label: t('AI Share of Voice'),
    },
    {
      defaultOrder: QueryOrder.DESC,
      orderBy: DomainColumnIDs.SHARE_OF_VOICE,
      sortingKey: SORTING_TYPE.numerical,
      label: t('Share of Voice'),
    },
    {
      defaultOrder: QueryOrder.DESC,
      orderBy: DomainColumnIDs.AVERAGE_CTR,
      sortingKey: SORTING_TYPE.numerical,
      label: t('Average CTR'),
    },
  ];
};
