import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { updateUserSettings } from 'Actions/UserAction';
import { useGenericGetUserLazyQuery } from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import { useActions } from 'Hooks/redux/useActions';
import { StoreType } from 'Types/Store';
import { subscribeToUser } from 'Utilities/websocket';

/**
 * Custom hook that listens for changes in the user's settings and updates them accordingly.
 * It subscribes to user changes, fetches the updated user data, and compares it with the current user settings.
 * If there are any changes, it updates the user settings and shows a modal indicating the permission update.
 */
const useOnUserChange = () => {
  const actions = useActions({ updateUserSettings });
  const { showModal } = useModal();
  const [getUser] = useGenericGetUserLazyQuery({ fetchPolicy: 'network-only' });
  const { userType, workspaces } = useSelector((store: StoreType) => store.user);

  useEffect(() => {
    const subscription = subscribeToUser(({ data }) => {
      getUser().then((response) => {
        if (response.data?.user) {
          const user = response.data.user;
          const shouldUpdate = !isEqual(
            { userType, workspaces },
            {
              userType: user.userType,
              workspaces: user.workspaces,
            },
          );
          if (shouldUpdate && !data.other?.event?.includes('LOGGEDOUT')) {
            actions.updateUserSettings(user);

            setTimeout(() => {
              showModal({
                modalType: 'PermissionUpdatedModal',
              });
            }, 100);
          }
        }
      });
    });
    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userType, workspaces?.toString()]);
};

export default useOnUserChange;
