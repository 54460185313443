import { observer } from 'mobx-react';
import { useContextTableStore } from 'Components/DataTable/store/components/TableStoreContext';
import useSticky from 'Components/DataTable/table-core/hooks/useSticky';
import AccText from 'Components/Text/AccText';
import { t, tn } from 'Utilities/i18n';
import { tableClassName } from '../../../helpers';
import { TableLabels } from '../../../types';
import './style.scss';

// Temporary solution until new sticky select actions are implemented
const HEADER_HEIGHT = 40;

type Props = { itemsUpdateLabel?: TableLabels['itemsUpdate'] };

export const DynamicItemsIndicator = observer(({ itemsUpdateLabel }: Props) => {
  const { offsetHeader } = useSticky(false, '');
  const tableStore = useContextTableStore();

  const added = tableStore?.updateInfo.added || 0;
  const removed = tableStore?.updateInfo.removed || 0;
  const hasAdded = added > 0;
  const hasRemoved = removed > 0;

  const showInfoRow = added || hasRemoved;

  const refreshTable = () => {
    tableStore?.resetChangeInfo();
    tableStore?.getData(true);
  };
  if (!showInfoRow) {
    return null;
  }

  return (
    <div className={tableClassName('info-row')} style={{ top: offsetHeader + HEADER_HEIGHT }}>
      <div data-info-row-content>
        {itemsUpdateLabel ? (
          <span>{itemsUpdateLabel(added, removed)}</span>
        ) : (
          <span>
            {hasAdded && `${added} ${tn('Keyword added', 'Keywords added', added)}`}{' '}
            {hasAdded && hasRemoved ? t('and') : ''}{' '}
            {hasRemoved && `${removed} ${tn('Keyword removed', 'Keywords removed', removed)}`}{' '}
            {hasRemoved ? t('from domain') : t('to domain')}
          </span>
        )}
        <AccText variant="link" fw={'bold'} onClick={refreshTable} ml={8}>
          {t('Refresh')}
        </AccText>
      </div>
    </div>
  );
});
