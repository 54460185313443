import { connect } from 'react-redux';
import { gql, useMutation } from '@apollo/client';
import { hideModal } from 'Actions/ModalAction';
import AccButton from 'Components/AccButton/AccButton';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import toast from 'Hooks/useToast';
import { t, tct } from 'Utilities/i18n';
import Validator from 'Utilities/validation';
import formStyle from 'css/layout/form-layout.module.scss';

type Props = {
  hideModal: (...args: Array<any>) => any;
  invoiceId: number;
  users: Record<string, any>;
  invalid?: any;
  submitting?: any;
  updateInvoiceContactMutation?: any;
};

const UPDATE_INVOICE_CONTACT_MUTATION = gql`
  mutation ($input: UpdateInvoiceContactInput!) {
    updateInvoiceContact(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

const UpdateInvoiceContactInfo = (props: Props) => {
  const { invoiceId, invalid, submitting, hideModal: _hideModal } = props;

  const [updateInvoiceContactMutation] = useMutation(UPDATE_INVOICE_CONTACT_MUTATION);

  const handleSubmit = () => {
    const input = {
      invoiceId,
    };

    updateInvoiceContactMutation({ variables: { input } }).then(({ data }) => {
      const {
        updateInvoiceContact: { errors },
      } = data;
      if (errors && errors.length) {
        toast.error(
          tct('Could not update invoice:[br][msgs]', {
            br: <br />,
            msgs: errors.map((error: any) => error.messages.join('<br />')),
          }),
        );
        Validator.setResponseErrors(Validator.throwSubmissionError, errors);
      } else {
        toast.success(t('Invoice updated.'));
        _hideModal();
      }
    });
  };

  const renderContent = () => {
    return (
      <form>
        <div className={formStyle.formRow}>
          <p>{t('Update the contact details on the issued invoice.')}</p>
          <p>{t('It will NOT change the details if country or VAT number has changed.')}</p>
        </div>
        <div className="footer">
          <AccButton variant="primary" onClick={handleSubmit} disabled={invalid || submitting}>
            {t('Update invoice')}
          </AccButton>
        </div>
      </form>
    );
  };

  return (
    <ModalBorder title={t('Update payment contact on invoice')} onClose={_hideModal}>
      {renderContent()}
    </ModalBorder>
  );
};

export default connect(null, { hideModal })(UpdateInvoiceContactInfo);
