import { useSelector } from 'react-redux';
import { QueryOrder } from 'Components/DataTable';
import { useKeywordInfoQuery } from 'Ghql';
import { RequiredFiltersSelector } from 'Selectors/FiltersSelector';

/**
 * Hook used to get details about a keyword by keywordId
 *
 * @example
 * const {data: keywordData, loading: keywordDataLoading}  = useKewordDetail(keywordId)
 *
 */
export const useKeywordDetail = (keywordId: string, skip: boolean = false) => {
  const filters = useSelector(RequiredFiltersSelector);
  const { data, loading } = useKeywordInfoQuery({
    variables: {
      filters,
      pagination: {
        page: 1,
        results: 1,
      },
      ordering: {
        order: QueryOrder.ASC,
        orderBy: 'keyword',
      },
      keywordId,
    },
    fetchPolicy: 'network-only',
    skip,
  });
  return { data, loading };
};
