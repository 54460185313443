import { SelectItem } from 'Components/AccSelect/support/types';
import { useGsc_WebsitesQuery } from 'Ghql';
import { notEmpty } from 'Utilities/underdash';

export const useGSCDomainOptions = (connectionId: string): [SelectItem<string>[], boolean] => {
  const { data, loading } = useGsc_WebsitesQuery({
    variables: {
      goaAccountID: connectionId,
    },
  });

  const gscSites = data?.googleConnection?.gscSites?.filter(notEmpty) || [];

  const domainOptions = gscSites.map((site) => ({
    value: site.siteUrl || '',
    label: site.siteUrl || '',
  }));

  return [domainOptions, loading];
};
