import { getAllNavigationItems } from 'Pages/Layout/DashboardTemplate/SideNavbar/support/getAllNavigationItems';
import { useActiveNavKey } from 'Pages/Layout/DashboardTemplate/SideNavbar/support/useActiveNavKey';

export const useBreadcrumTitles = () => {
  const activeNavKey = useActiveNavKey();

  const navigationItem = getAllNavigationItems().find(
    (item) =>
      item.subPages?.some((subPage) => subPage.link === activeNavKey) || item.link === activeNavKey,
  );
  const subPage = navigationItem?.subPages?.find((page) => page.link === activeNavKey);

  const { label: parentTitle, link: parentLink } = navigationItem || {};

  return { parentTitle, parentLink, title: subPage?.label };
};
