import Loader from 'Components/Loader';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { useSalesTrialsDomainsQuery } from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import { t } from 'Utilities/i18n';

type Props = {
  organizationId: string;
};

const SalesOrganizationDomains = ({ organizationId }: Props) => {
  const { hideModal } = useModal();
  const { data, loading, error } = useSalesTrialsDomainsQuery({
    variables: {
      id: organizationId,
    },
  });

  if (loading || error) {
    return (
      <ModalBorder title={t('Organization domains')} onClose={hideModal}>
        <Loader style={{ height: '500px' }} />
      </ModalBorder>
    );
  }

  return (
    <ModalBorder title={t('Organization domains')} onClose={hideModal}>
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">{t('Group')}</th>
            <th scope="col">{t('Domain')}</th>
            <th scope="col">{t('Keywords')}</th>
          </tr>
        </thead>
        <tbody>
          {data?.adminOrganization?.organizationClients?.map((client) => [
            <tr key={`client-${client?.id}`}>
              <td>{client?.name}</td>
              <td />
              <td />
            </tr>,
            client?.domains?.map((domain) => (
              <tr key={`domain-${domain?.id}`}>
                <td />
                <td>
                  {domain?.displayName} <i>{domain?.domain}</i>
                </td>
                <td>{domain?.totalKeywords}</td>
              </tr>
            )),
          ])}
        </tbody>
      </table>
    </ModalBorder>
  );
};

export default SalesOrganizationDomains;
