import moment from 'moment';
import { DateRangeType } from './CompareToContentSegmented';
import { DateRange, EARLIEST, LATEST } from './model';

export function getSegmentedDateRange(value: DateRangeType, max: Date): DateRange {
  switch (value) {
    case 'yesterday': {
      const yesterday = moment(max).subtract(1, 'days').toDate();
      return {
        from: yesterday,
        to: LATEST,
      };
    }

    case 'last-week': {
      const weekAgo = moment(max).subtract(1, 'week').toDate();
      return {
        from: weekAgo,
        to: LATEST,
      };
    }
    case 'last-two-weeks': {
      const twoWeeksAgo = moment(max).subtract(2, 'week').toDate();
      return {
        from: twoWeeksAgo,
        to: LATEST,
      };
    }

    case 'last-month': {
      const lastMonth = moment(max).subtract(1, 'month').toDate();
      return {
        from: lastMonth,
        to: LATEST,
      };
    }

    case 'last-year': {
      const lastYear = moment(new Date()).subtract(1, 'year').toDate();
      return {
        from: lastYear,
        to: LATEST,
      };
    }

    case 'initial': {
      return {
        from: EARLIEST,
        to: LATEST,
      };
    }

    default: {
      const yesterday = moment(new Date()).subtract(1, 'days').toDate();
      return {
        from: yesterday,
        to: LATEST,
      };
    }
  }
}
