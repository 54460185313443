import { Link } from 'react-router-dom';
import { Group, Skeleton, Stack, Text } from '@mantine/core';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import FormatNumber from 'Components/FormatNumber/new';
import { FilterAttribute } from 'Types/Filter';
import { FilterTrackingKey, trackFilterEventWithNavigation } from 'Utilities/Analytics/mixpanel';

export interface PanelDataProps {
  description: string;
  data: number | undefined;
  link: string | undefined;
  loading: boolean;
  filterTrackingKey: FilterTrackingKey;
  iconSlot?: JSX.Element;
  tooltipText?: string;
}

export const PanelData = ({
  description,
  data,
  iconSlot,
  link,
  filterTrackingKey,
  tooltipText,
  loading,
}: PanelDataProps) => {
  const handleTrackFilterEvent = () => {
    link &&
      trackFilterEventWithNavigation(link, filterTrackingKey, FilterAttribute.HIGHEST_RANKING_PAGE);
  };
  return (
    <Stack style={{ gap: 0, flex: '1 1 auto' }}>
      <Group style={{ gap: 12 }}>
        {loading && <Skeleton data-testid="skeletonWrapper" width={50} height={25} />}
        {!loading &&
          (data && link ? (
            <>
              <AccTooltip tooltip={tooltipText}>
                <Link to={link} onClick={handleTrackFilterEvent}>
                  <Text fw={700} size="xl" c="blue">
                    {<FormatNumber value={data} />}
                  </Text>
                </Link>
              </AccTooltip>
              {iconSlot}
            </>
          ) : (
            <Text fw={700} size="xl" c="gray">
              {'-'}
            </Text>
          ))}
      </Group>
      <Text fw={600} size="xs" c="gray">
        {description}
      </Text>
    </Stack>
  );
};
