/* eslint-disable react/display-name */
import { useState } from 'react';
import { useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import AccButton from 'Components/AccButton/AccButton';
import LocaleSelect from 'Components/Controls/Dropdowns/LocaleSelect';
import SearchEngineField from 'Components/Forms/SearchEngine';
import { GutterSm } from 'Components/Gutter';
import AccText from 'Components/Text/AccText';
import { CountByCountryNode, DomainsDomainDomainTypeChoices } from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import { useUser } from 'Hooks/data/user/useUser';
import toast from 'Hooks/useToast';
import { transformLocalesData } from 'Utilities/Common/search-engines';
import { t } from 'Utilities/i18n';
import Validation from 'Utilities/validation';
import { useAddKeywordsFields, useCountryLocales, useDomainInfo } from '../hooks';
import { FieldName, MappedSearchEngine } from '../types';

const useAccountFeatures = () => {
  const user = useUser();
  return {
    featureBaidu: !!user.organization?.activePlan?.featureBaidu,
    featureYoutube: !!user.organization?.activePlan?.featureYoutube,
  };
};

const useDomainIsYoutube = (domainId: string) => {
  const domainInfo = useDomainInfo(domainId);
  return domainInfo?.domainType === DomainsDomainDomainTypeChoices.A_3;
};

const useLocaleData = (domainId: string) => {
  const countrylocales = useCountryLocales();
  const { featureBaidu, featureYoutube } = useAccountFeatures();
  const domainIsYouTube = useDomainIsYoutube(domainId);

  if (!countrylocales) {
    return null;
  }

  return transformLocalesData(countrylocales, featureBaidu, featureYoutube, domainIsYouTube);
};

function usePushFieldArray<T>(name: string) {
  const form = useForm();
  return (value: T) => form.mutators.push(name, value);
}

export default ({
  domainId,
  selectedCountry,
}: {
  domainId: string;
  selectedCountry?: CountByCountryNode;
}) => {
  const [showCountryLocaleSelect, setShowCountryLocaleSelect] = useState(false);
  const locales = useLocaleData(domainId);
  const { showModal } = useModal();
  const domainIsYoutube = useDomainIsYoutube(domainId);
  const fields = useAddKeywordsFields();
  const pushEngines = usePushFieldArray<MappedSearchEngine>(FieldName.ENGINES);

  const handleChangeLocale = (localeId: string | null) => {
    if (!locales) throw new Error('Locales should be loaded before changing locale');
    const locale = locales.find((localeObj) => localeObj.id === localeId);

    if (fields.engines && locale) {
      const alreadyExists =
        fields.engines.fields.value.filter((e) => e.countrylocale === locale.id).length > 0;
      if (alreadyExists) {
        toast.error(t('You cannot add the same locale multiple times.'));
        return;
      }
    }

    showModal({
      modalType: 'AddSearchEngine',
      modalTheme: 'light',
      modalProps: {
        locale,
        youTubeDomain: domainIsYoutube,
        initialValues: {
          searchEngines: locale?.searchEngines,
          settings: {
            ignoreLocalResults: false,
            ignoreFeaturedSnippet: false,
            enableAutocorrect: false,
          },
          locations: [''],
        },
        onSubmit: (v) => {
          pushEngines(v);
        },
      },
      nested: true,
    });
    setShowCountryLocaleSelect(false);
  };

  if (!locales) {
    // Loading
    return null;
  }
  return (
    <>
      <AccText variant="label">{t('Search Engine, Locale and Location')}</AccText>
      <FieldArray
        name={FieldName.ENGINES}
        countryLocales={locales}
        showModal={showModal}
        component={SearchEngineField}
        youTubeDomain={domainIsYoutube}
        validate={Validation.array}
        selectedCountry={selectedCountry}
      />
      <GutterSm />
      {showCountryLocaleSelect ? (
        <LocaleSelect
          placeholder={t('Please add at least one locale')}
          locales={locales}
          onChange={handleChangeLocale}
          autoFocus={showCountryLocaleSelect}
        />
      ) : (
        <AccButton variant="secondary" onClick={() => setShowCountryLocaleSelect(true)}>
          {' '}
          {t('Add Search Engine')}
        </AccButton>
      )}
    </>
  );
};
