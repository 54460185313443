import { useState } from 'react';
import { DragSourceMonitor } from 'react-dnd';

export const useAnimatedMove = (monitorRef: { current?: DragSourceMonitor | null }) => {
  const [animateMoveSelected, setAnimateMoveSelected] = useState(false);

  const handleDragOver = () => {
    if (monitorRef.current) {
      const offsetY = monitorRef?.current?.getDifferenceFromInitialOffset()?.y || 0;
      const spaceFromBottom = window.innerHeight - (monitorRef?.current?.getClientOffset()?.y || 0);

      if (offsetY === 0) {
        return;
      }

      if (offsetY > 100 && spaceFromBottom < 50) {
        setAnimateMoveSelected(true);
      }

      if (spaceFromBottom < 10) {
        window.scrollTo(window.scrollX, window.scrollY + 10);
      }
    }
  };

  return {
    animateMoveSelected,
    handleDragOver,
  };
};
