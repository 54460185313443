import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { useModal } from 'Hooks/base/useModal';
import { t } from 'Utilities/i18n';
import CollapseGroups from './components/CollapseGroups';
import CompareCharts from './components/CompareCharts';
import { useFetchStructure } from './support/useFetchStructure';
import { ComparisonProvider } from './useComparisonContext';
import styles from './domainsComparison.module.scss';

const DomainsComparison = ({
  initialSelect,
  defaultTab,
}: {
  initialSelect: string[];
  defaultTab: string;
}) => {
  const { hideModal } = useModal();
  const { data, loading } = useFetchStructure();
  return (
    <ModalBorder
      header={false}
      onClose={hideModal}
      title={t('Keywords Comparison')}
      className={styles.modalBorder}
      bodyClassname={styles.modalBody}
    >
      <ComparisonProvider initialSelect={initialSelect} data={data} initialCompareBy={defaultTab}>
        <div className={styles.modalGrid}>
          <CollapseGroups loading={loading} />
          <CompareCharts />
        </div>
      </ComparisonProvider>
    </ModalBorder>
  );
};

export default DomainsComparison;
