import { useEffect } from 'react';
import {
  ConnectDragPreview,
  ConnectDragSource,
  DragElementWrapper,
  DragPreviewOptions,
  DragSourceOptions,
  useDrag,
} from 'react-dnd';
import { ItemTypes } from 'Pages/Keywords/Groupings/EditMode/react-dnd-treeview/ItemTypes';
import { useTreeContext } from 'Pages/Keywords/Groupings/EditMode/react-dnd-treeview/hooks/index';
import {
  DragItem,
  DragSourceElement,
  NodeModel,
} from 'Pages/Keywords/Groupings/EditMode/react-dnd-treeview/types';

let dragSourceElement: DragSourceElement = null;

const register = (e: DragEvent | TouchEvent): void => {
  const { target } = e;

  if (target instanceof HTMLElement) {
    const source = target.closest('[role="listitem"]');

    if (e.currentTarget === source) {
      dragSourceElement = source;
    }
  }
};

const handleDragStart = (e: DragEvent) => register(e);
const handleTouchStart = (e: TouchEvent) => register(e);

export const useDragNode = <T>(
  item: NodeModel,
  ref: React.RefObject<HTMLElement>,
): [boolean, DragElementWrapper<DragSourceOptions>, DragElementWrapper<DragPreviewOptions>] => {
  const treeContext = useTreeContext<T>();

  useEffect(() => {
    const node = ref.current;
    node?.addEventListener('dragstart', handleDragStart);
    node?.addEventListener('touchstart', handleTouchStart, {
      passive: true,
    });

    return () => {
      node?.removeEventListener('dragstart', handleDragStart);
      node?.removeEventListener('touchstart', handleTouchStart);
    };
  }, [ref]);

  const [{ isDragging }, drag, preview]: [
    { isDragging: boolean },
    ConnectDragSource,
    ConnectDragPreview,
  ] = useDrag({
    type: ItemTypes.TREE_ITEM,
    item: (monitor) => {
      const dragItem: DragItem = { ref, ...item };

      if (treeContext.onDragStart) {
        treeContext.onDragStart(dragItem, monitor);
      }

      return dragItem;
    },
    end: (dragItemObj, monitor) => {
      const dragItem = dragItemObj as DragItem;

      if (treeContext.onDragEnd) {
        treeContext.onDragEnd(dragItem, monitor);
      }
    },
    canDrag: () => {
      const { canDrag } = treeContext;

      if (dragSourceElement !== ref.current) {
        return false;
      }

      if (canDrag) {
        return canDrag(item.id);
      }

      return true;
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return [isDragging, drag, preview];
};
