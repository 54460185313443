import {
  ListDynamicTagRulesetCount_dynamicTagRulesetList_rawRulesetList,
  ListDynamicTagRuleset_dynamicTagRulesetList_rawRulesetList,
} from '../../../Query';

type DynamicTagsCount = ListDynamicTagRulesetCount_dynamicTagRulesetList_rawRulesetList;

export type DynamicTagItem = ListDynamicTagRuleset_dynamicTagRulesetList_rawRulesetList &
  Partial<DynamicTagsCount>;

export type DynamicTagFormValues = {
  id?: number | null;
  tag: string;
  rawRuleset: TokenGroup;
  folder: string;
};

export interface TokenItem {
  source: string;
  operator: string;
  value: string | number | [number, number];
}

export enum TokenGroupType {
  OR = 'or',
  AND = 'and',
}

export interface TokenGroup {
  expression: TokenGroupType | string;
  tokens: (TokenItem | TokenGroup)[];
}
