import { MouseEvent } from 'react';
import { Flex, useMantineTheme } from '@mantine/core';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import { AccFastIcon } from 'Components/AccFastIcon';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import AccessControl from 'Components/AccessControl';
import { t } from 'Utilities/i18n';
import { IconEdit, IconTags, IconTrash } from 'icons/tag-cloud';

type TagActionsProps = {
  onEdit: (e: MouseEvent<HTMLButtonElement>) => void;
  onClone: (e: MouseEvent<HTMLButtonElement>) => void;
  onDelete: (e: MouseEvent<HTMLButtonElement>) => void;
  isDynamic: boolean;
  showAction: boolean;
};

export const TagActions = ({
  onEdit,
  onClone,
  onDelete,
  isDynamic,
  showAction,
}: TagActionsProps) => {
  const iconColor = useMantineTheme().colors.snorlax[4];

  if (!showAction) return null;

  return (
    <AccessControl>
      <Flex>
        <AccTooltip tooltip={isDynamic ? t('Edit dynamic tag') : t('Edit static tag')} old>
          <AccActionIcon variant="subtle" onClick={onEdit}>
            <AccFastIcon src={IconEdit} size={18} color={iconColor} />
          </AccActionIcon>
        </AccTooltip>
        {isDynamic && (
          <AccTooltip tooltip={t('Clone dynamic tag')} old>
            <AccActionIcon variant="subtle" onClick={onClone}>
              <AccFastIcon src={IconTags} size={18} color={iconColor} />
            </AccActionIcon>
          </AccTooltip>
        )}
        <AccTooltip tooltip={isDynamic ? t('Delete dynamic tag') : t('Delete static tag')} old>
          <AccActionIcon variant="subtle" onClick={onDelete}>
            <AccFastIcon src={IconTrash} size={18} color={iconColor} />
          </AccActionIcon>
        </AccTooltip>
      </Flex>
    </AccessControl>
  );
};
