import { CHART_NAMES } from 'Pages/Keywords/Overview/components/ChartBox';

export const HISTORY_CHARTS_TO_BACKEND_NAME = {
  [CHART_NAMES.AVERAGE_RANK]: 'average_rank',
  [CHART_NAMES.SHARE_OF_VOICE]: 'share_of_voice',
  [CHART_NAMES.RANKING_DISTRIBUTION]: 'ranking_distribution',
  [CHART_NAMES.ESTIMATED_VISITS]: 'estimated_visits',
  [CHART_NAMES.AVERAGE_CTR]: 'average_ctr',
  [CHART_NAMES.TRAFFIC_VALUE]: 'traffic_value',
  [CHART_NAMES.AVERAGE_BASE_RANK]: 'average_base_rank',
  [CHART_NAMES.AI_TRAFFIC_VALUE]: 'ai_traffic_value',
  [CHART_NAMES.BASE_RANKING_DISTRIBUTION]: 'base_ranking_distribution',
  [CHART_NAMES.PIXELS_FROM_TOP]: 'pixels_from_top',
};

export interface OverviewChartSeries {
  name: string;
  primary: boolean;
  color: string;
  data: DataPoint[];
  zoneAxis?: string;
  zones?: Highcharts.SeriesZonesOptionsObject[];
}

export interface DataPoint {
  x: number;
  y: number;
  totalKeywords: number;
  isFake?: boolean;
}
