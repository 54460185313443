import { memo } from 'react';
import { Flex } from '@mantine/core';
import isEqual from 'lodash/isEqual';
import { useRowContext } from 'Components/DataTable';
import { SEARCH_TYPE_NAVER, SearchEngineIds } from 'Components/Modal/Content/AddSearchEngine/types';
import { SearchIntent } from 'Types/Filter';
import { t } from 'Utilities/i18n';
import SearchIntentIcon from 'icons/search-intent/searchIntentIcon';
import NaCell from './helpers/NaCell';

type Props = {
  value: {
    loadingData?: boolean;
    data: {
      searchIntent: SearchIntent[];
      searchEngineId: number;
    };
  };
};

const searchIntentCellEqual = (prevProps: Props, nextProps: Props) => {
  return (
    prevProps.value.loadingData === nextProps.value.loadingData &&
    isEqual(prevProps.value.data?.searchIntent, nextProps.value.data?.searchIntent)
  );
};
const SearchIntentCell = (props: Props) => {
  const { inView } = useRowContext();
  const {
    value: {
      loadingData,
      data: { searchIntent, searchEngineId },
    },
  } = props;
  if (loadingData || !searchIntent || !inView) return null;

  if (searchEngineId === SearchEngineIds[SEARCH_TYPE_NAVER]) {
    return <NaCell tooltip={t('No Search Intent for this search engine')} />;
  }

  return (
    <Flex w="100%" miw={30} gap={2}>
      {searchIntent?.map?.((x) => (
        <SearchIntentIcon key={x} withTooltip searchIntent={x} size={16} fontSize="xs" faded />
      ))}
    </Flex>
  );
};

export default memo(SearchIntentCell, searchIntentCellEqual);
