import moment from 'moment';
import { SegmentedCompareToContent } from 'Components/PeriodFilter/CompareToContentSegmented';
import { getRangeToShow } from 'Components/PeriodFilter/CompareToDropdown';
import type { DateFilters } from 'Components/PeriodFilter/model';
import { parsePeriodFilterValue } from 'Components/PeriodFilter/model';
import { PublicDomainFragment } from 'Ghql';
import { FilterBase, PeriodFilter } from 'Types/Filter';
import { FilterTrackingKey } from 'Utilities/Analytics/mixpanel';
import { formatComfortableDate } from 'Utilities/format';
import { t } from 'Utilities/i18n';
import { useUpdateUrlFilter } from '../support/useUpdateUrlFilter';

const labelFunc = (labelPeriodFilter: PeriodFilter, minDate: Date, maxDate: Date) => {
  const dateRange = parsePeriodFilterValue(labelPeriodFilter);

  const { from, to } = getRangeToShow(dateRange, minDate, maxDate);
  return {
    value: `${formatComfortableDate(from)} - ${formatComfortableDate(to)}`,
    label: t('Period'),
  };
};

export const ReportPeriodSegmentedControl = ({
  domainInfo,
  filters,
  earliestStartDate,
}: {
  domainInfo: PublicDomainFragment | undefined;
  filters: FilterBase[];
  earliestStartDate: Date | undefined;
}) => {
  const periodFilter = filters.find((filter) => filter.attribute === 'period') as PeriodFilter;

  const updateUrl = useUpdateUrlFilter();

  const firstRefreshAt =
    earliestStartDate ||
    (domainInfo?.firstRefreshAt ? new Date(domainInfo?.firstRefreshAt) : undefined);

  const lastFullScrape = domainInfo?.lastFullScrape
    ? new Date(domainInfo.lastFullScrape)
    : undefined;

  const max =
    domainInfo &&
    (moment(domainInfo.lastFullScrape).isAfter(domainInfo.firstRefreshAt, 'day')
      ? lastFullScrape
      : firstRefreshAt);

  const handleSubmit = (dateFilters: DateFilters) => {
    updateUrl(dateFilters, FilterTrackingKey.PublicReport);
  };
  const customLabel = labelFunc(periodFilter, firstRefreshAt || new Date(0), max || new Date());

  return (
    <SegmentedCompareToContent
      partialScrapeWarningShouldShow={false}
      min={firstRefreshAt || new Date(0)}
      max={max || new Date()}
      periodFilter={periodFilter}
      onSubmit={handleSubmit}
      domainInfo={domainInfo}
      onlyPeriodFilter={true}
      message={undefined}
      label={customLabel.value}
      notViewingLatestData={false}
      listenToExternalFilterUpdate
    />
  );
};
