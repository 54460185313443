import { WithRowContext } from 'Components/DataTable';
import FormatNumber from 'Components/FormatNumber/new';
import './styles.scss';

type Props = {
  value: {
    data: {
      id: number;
    };
    loadingData: boolean;
  };
  colName: string;
  nDecimals?: number;

  updatingKeyword?: any;
} & WithRowContext;

const SimpleNumberCell = (props: Props): JSX.Element | null => {
  const {
    value: { data },
    colName,
    nDecimals,
  } = props;

  return (
    <FormatNumber
      minimumFractionDigits={nDecimals ?? 1}
      maximumFractionDigits={nDecimals ?? 1}
      value={data?.[colName]}
      className="text-right"
    />
  );
};

export default SimpleNumberCell;
