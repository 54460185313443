type Props = {
  value: {
    data: {
      dateAdded: string;
    };
    loadingData: boolean;
  };
};

const DateAddedCell = (props: Props) => {
  const {
    value: { data, loadingData },
  } = props;
  // if (loadingData) return <LoadingSpinner />;
  if (loadingData) return '';
  const { dateAdded } = data;
  return <>{dateAdded ? dateAdded : ''}</>;
};

export default DateAddedCell;
