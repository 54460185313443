import { useDispatch } from 'react-redux';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import transform from 'lodash/transform';
import {
  moveElement,
  removeElement,
  replaceElement,
  resetTemplate,
} from 'Actions/ReportTemplateAction';
import AccButton from 'Components/AccButton/AccButton';
import { ReportElement } from 'Types/ReportElement';
import { useHistory } from 'Utilities/Router/withRouter';
import { t } from 'Utilities/i18n/index';
import ElementsList from '../ElementsList';
import { useReportTemplate } from './useReportTemplate';
import './report-template.scss';

const BACK_LINK = '/reports/templates';

type ReportTemplateProps = {
  handleSave: (templateName: string, color: string, elements: string) => Promise<void>;
  backLink?: string;
};

const transformElement = (element: ReportElement) => {
  const settings = transform(
    omit(element, ['id', 'type']),
    (result, value, key) =>
      (result[key] = {
        value,
      }),
    {},
  );
  return { ...pick(element, ['type']), settings };
};

const ReportTemplate = ({ handleSave, backLink = BACK_LINK }: ReportTemplateProps) => {
  const { elements, name, color } = useReportTemplate();

  const dispatch = useDispatch();

  const history = useHistory();

  const handleBack = () => {
    history.push(backLink);
  };

  const handleSaveTemplate = () => {
    handleSave(name, color, JSON.stringify(elements.map(transformElement)));
  };

  const handleRemoveElement = (index: number) => {
    dispatch(removeElement(index));
  };
  const handleMoveElement = (from: number, to: number) => dispatch(moveElement(from, to));
  const handleReplaceElement = (element: ReportElement, index: number) =>
    dispatch(replaceElement(element, index));
  const handleResetTemplate = () => dispatch(resetTemplate());

  return (
    <div className="report-template">
      <div className="title">{t('Report Elements')} *</div>
      <ElementsList
        elements={elements}
        onRemove={handleRemoveElement}
        onMove={handleMoveElement}
        onReplace={handleReplaceElement}
      />
      <div className="actions">
        <AccButton variant="tertiary" onClick={handleBack}>
          {t('Back')}
        </AccButton>
        <AccButton variant="secondary" onClick={handleResetTemplate}>
          {t('Clear template')}
        </AccButton>
        <AccButton variant="primary" onClick={handleSaveTemplate}>
          {t('Save template')}
        </AccButton>
      </div>
    </div>
  );
};

export default ReportTemplate;
