import styles from './modalFooter.module.scss';

export const ModalFooter = ({
  primaryButtonSlot,
  secondaryButtonSlot,
  textSlot,
}: {
  primaryButtonSlot: JSX.Element;
  secondaryButtonSlot?: JSX.Element;
  textSlot?: JSX.Element;
}) => {
  return (
    <div className={styles.modalFooter}>
      <div>{textSlot}</div>
      <div className={styles.modalButtonsWrapper}>
        {secondaryButtonSlot}
        {primaryButtonSlot}
      </div>
    </div>
  );
};
