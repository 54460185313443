import { filtersYouCannotHaveMultipleOf } from 'Components/Filters/FiltersEditor';
import { store } from 'Store';
import type { FilterBase } from 'Types/Filter';
import { FilterAttribute } from 'Types/Filter';
import { KEYWORDS_FILTER_SET, isFilterInSet, isRequiredFilter } from 'Types/FilterSet';
import type { FilterSet } from 'Types/FilterSet';
import { encodeFilters } from './serialization';

type Props = {
  to: string;
  overwriteFilters?: FilterBase[];
  newFilters?: FilterBase[];
  filterSet?: FilterSet;
  removeFilters?: Array<string>;
  resetFilters?: boolean;
  maxLength?: number;
};

export default function linkWithFilters({
  to,
  overwriteFilters,
  filterSet,
  removeFilters,
  resetFilters,
  maxLength,
  newFilters,
}: Props) {
  const filterState = store.getState().filter;
  let segmentId = '';
  let filters = filterState.filterGroup.filters;

  if (filterSet && filterSet !== filterState.filterSet) {
    filters = filterState.filters.filter((filter) => isFilterInSet(filter.attribute, filterSet));
  }

  if (resetFilters) {
    filters = filters.filter((filter) =>
      isRequiredFilter(filter.attribute, filterSet || filterState.filterSet),
    );
  }

  if (removeFilters && removeFilters.length) {
    filters = filters.filter((filter) => {
      const isRemoved = removeFilters.find((removeFilter) => removeFilter === filter.attribute);
      return !isRemoved;
    });
  }

  if (overwriteFilters) {
    const existing = filters.filter((existingFilter) => {
      const isReplaced = overwriteFilters.find(
        (overwriteFilter) => overwriteFilter.attribute === existingFilter.attribute,
      );
      return !isReplaced;
    });
    filters = [...existing, ...overwriteFilters];
  }

  if (newFilters) {
    // Add filter, unless it is in the list of filters you cannot have multiple of
    filters = filters
      .filter(
        (f) =>
          !(
            newFilters.find((newF) => newF.attribute === f.attribute) &&
            f.attribute in filtersYouCannotHaveMultipleOf
          ),
      )
      .concat(newFilters);
  }

  if (resetFilters) {
    // Special case, default domain-specific segments for keywords filter set
    if (filterSet === KEYWORDS_FILTER_SET) {
      const domainsFilter = filters.find((filter) => filter.attribute === FilterAttribute.DOMAINS);

      if (domainsFilter && Array.isArray(domainsFilter.value) && domainsFilter.value.length === 1) {
        const domainId = domainsFilter.value[0];
        const defaultFilterGroup = filterState.filterGroups.find(
          (filterGroup) =>
            filterGroup.defaultForKeywords &&
            filterGroup.defaultForKeywords.indexOf(domainId) !== -1,
        );

        if (defaultFilterGroup) {
          filters = [...filters, ...defaultFilterGroup.filters];
          segmentId = defaultFilterGroup.id;
        }
      }
    }
  }

  const filterString = encodeFilters(filters, segmentId);

  if (maxLength) {
    return `${to}/${filterString}/`.slice(0, maxLength);
  }

  return `${to}/${filterString}/`;
}
