import { Flex, Select as MantineSelect } from '@mantine/core';
import { IconArrowNarrowRight } from '@tabler/icons-react';
import { DomainFavicon } from 'Components/DomainFavicon';
import AccText from 'Components/Text/AccText';
import styles from './styles.module.scss';

type Props = {
  properties: any[];
  value: string;
  onChange: (value: string) => void;
  domain?: any;
  style?: React.CSSProperties;
  loading?: boolean;
  defaultValue?: string;
};
const InputRow = (props: Props) => {
  const { domain, properties, style, value, loading, defaultValue, onChange } = props;

  return (
    <div style={style} className={styles.inputContainer}>
      <Flex
        className={styles.labelContainer}
        direction="column"
        align="flex-start"
        gap={0}
        maw={292}
      >
        <Flex maw={'100%'} align="center" gap={6}>
          <DomainFavicon domain={domain?.url} />
          <AccText overflowEllipsis size="12px">
            {domain?.label}
          </AccText>
        </Flex>
        <AccText size="10px" c="gray.5" ml={21}>
          {domain?.url}
        </AccText>
      </Flex>
      <IconArrowNarrowRight size={30} color="gray" />
      <MantineSelect
        value={value}
        defaultValue={defaultValue || ''}
        data={properties}
        disabled={loading}
        searchable
        clearable
        onChange={(selectedValue) => onChange(selectedValue || '')}
        w="100%"
      />
    </div>
  );
};

export default InputRow;
