import React from 'react';
import { Flex } from '@mantine/core';
import { observer } from 'mobx-react';
import AccText from 'Components/Text/AccText';
import { useFilters } from 'Hooks';
import { COMPARE_TO, DOMAINS, PERIOD } from 'Types/Filter';
import AccButton from '../../../../../Components/AccButton/AccButton';
import { t } from '../../../../../Utilities/i18n';
import style from '../../groupings.module.scss';

export const GroupEditActions = observer(
  ({ isAddMode, handleCancel, isEditingNode, handleSubmit, loading }) => {
    const enableAdd = isAddMode || isEditingNode;
    const filters = useFilters();
    const hasActiveFilters = filters.some(
      (filter) => ![DOMAINS, PERIOD, COMPARE_TO].includes(filter.attribute),
    );

    return (
      <Flex columnGap="sm" justify="start" mb="sm" mt="xl">
        {hasActiveFilters && (
          <>
            <AccText my="auto">
              {t('*Please note that filters are not applied while organizing folders.')}
            </AccText>
            <div className={style.separator} />
          </>
        )}
        <AccButton
          onClick={handleSubmit}
          disabled={enableAdd}
          variant={'primary'}
          loading={loading}
        >
          {loading ? t('Saving…') : t('Save')}
        </AccButton>
        <AccButton variant={'secondary'} onClick={handleCancel}>
          {t('Cancel')}
        </AccButton>
      </Flex>
    );
  },
);
