import { memo } from 'react';
import DescriptionCell from 'Components/AccuTable/CellRenderer/description';
import { useUpdateFolderDescriptionMutation } from 'Ghql';
import { useDomainId } from 'Hooks/data/domain/useQueryDomainInfo';
import { FolderCellProps } from 'Pages/Keywords/Groupings/ViewMode/utils/useFoldersTableConfig';
import { FolderColumnID } from 'Pages/Keywords/Groupings/support/constants';
import { useGroupingStore } from 'Pages/Keywords/Groupings/support/groupingsStore';
import { propsIsEqualComparison } from 'Utilities/compare';

export const FolderDescriptionCell = memo((props: FolderCellProps) => {
  const [updateDescription] = useUpdateFolderDescriptionMutation();
  const domainId = useDomainId() as string;
  const groupingStore = useGroupingStore();

  // Adapt to existing description cell from keyword table
  const descCellProps = {
    value: {
      data: { keywordDescription: props.rootNode.description, id: props.rootNode.path },
      loadingData: false,
    },
    onChange: (id: any, description: any) => {
      updateDescription({
        variables: {
          path: id,
          description,
          domainId: parseInt(domainId),
          isFolder: Boolean(props.rootNode.is_folder),
        },
        onCompleted: () => {
          groupingStore.refetch(true);
        },
        fetchPolicy: 'network-only',
      });
    },
  };

  return (
    <DescriptionCell
      {...descCellProps}
      key={FolderColumnID.DESCRIPTION}
      className={props.classes.rightTextAlign}
    />
  );
}, propsIsEqualComparison);
FolderDescriptionCell.displayName = 'FolderDescriptionCell';
