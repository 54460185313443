import { ApolloClient, useApolloClient } from '@apollo/client';
import { updateUserSettings } from 'Actions/UserAction';
import { GenericGetUserDocument, GenericGetUserQuery, GenericGetUserQueryVariables } from 'Ghql';
import { useActions } from 'Hooks/redux/useActions';
import { StoreUserType } from 'Types/Store';

const updateStoredUserSettings = (
  client: ApolloClient<object>,
  actions: { updateUserSettings: typeof updateUserSettings },
) => {
  client
    .query<GenericGetUserQuery, GenericGetUserQueryVariables>({
      fetchPolicy: 'network-only',
      query: GenericGetUserDocument,
    })
    .then((response) => {
      if (response.data?.user) {
        actions.updateUserSettings(response.data?.user);
      }
      if (response.errors || response.error) {
        response.errors && console.error(response.errors);
        response.error && console.error(response.error);
      }
    });
};

/**
 * Update all fields of the stored user settings.
 * This function initiates a new request to the backend,
 * overwriting all fields of the user object in the Redux store.
 *
 * @example
 * const resetUserSettings = useUpdateStoredUserSettings();
 *
 * // Call the method to update the stored user settings
 * resetUserSettings();
 */
// eslint-disable-next-line import/no-unused-modules
export const useUpdateStoredUserSettings = () => {
  const client = useApolloClient();
  const actions = useActions({ updateUserSettings });
  return () => updateStoredUserSettings(client, actions);
};

type UserSettingsFields = keyof Omit<StoreUserType, '__typename'>;

/**
 * Update a specific field in the stored user settings.
 * This function overwrites the specified field in the Redux store.
 * @example
 * updateUserSettingsField('defaultCompareTo', 'lastYear');
 */
const updateUserSettingsField = (fieldName: UserSettingsFields, newValue: unknown) => {
  updateUserSettings({ [fieldName]: newValue });
};

/**
 * Update a specific field in the stored user settings.
 * Returns a function to overwrite the specified field in the Redux store.
 * @example
 * const updateUserSettingsField = useUpdateUserSettingsField();
 *
 * // Call the method to update the user field in the Redux store
 * updateUserSettingsField('defaultCompareTo', 'lastYear');
 */
export const useUpdateUserSettingsField = () => {
  return updateUserSettingsField;
};
