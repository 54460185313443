import { CompareChartData } from 'Components/Chart/CompareChartData/CompareChartData';
import { useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import { useDisplayCurrency } from 'Hooks/displayCurrency';
import { LandingChartQuery, LandingChartQueryVariables } from 'Query';
import { getIsLandingPageRevenueEnabled } from '../../support/helpers';
import { LANDING_TABLE_ID, LandingTableIDs } from '../KeywordLandingTable/support/constants';
import { getIsDefaultLanding, getLandingTablePath } from '../KeywordLandingTable/support/helpers';
import {
  extractLandingChartData,
  extractLandingChartVariables,
  getLandingMetrics,
} from './support/helpers';
import { QUERY_LANDING_CHART } from './support/landing.query';
import styles from './keywords-landing-chart.module.scss';

export const KeywordLandingChart = () => {
  const { domainInfo, isNaverDomain } = useQueryDomainInfo();
  const enabledRevenue = getIsLandingPageRevenueEnabled(domainInfo);
  const { displayCurrency, loading } = useDisplayCurrency(true);
  return (
    <div className={styles.container}>
      <CompareChartData<LandingChartQuery, LandingChartQueryVariables>
        getMetrics={getLandingMetrics(enabledRevenue, isNaverDomain)}
        defaultMetric={LandingTableIDs.SHARE_OF_VOICE}
        tableId={LANDING_TABLE_ID}
        chartQuery={QUERY_LANDING_CHART}
        extractData={extractLandingChartData}
        getVariables={extractLandingChartVariables}
        displayCurrency={displayCurrency}
        skip={loading}
        extractColorLineId={getLandingTablePath}
        getIsDefaultSelect={getIsDefaultLanding}
      />
    </div>
  );
};
