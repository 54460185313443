import { useState } from 'react';
import { Box } from '@mantine/core';
import classNames from 'classnames';
import { AccFastIcon } from 'Components/AccFastIcon';
import {
  Ignore,
  IgnoreInShareOfVoice,
  IgnoredDomainsIcon,
  Snippet,
  SpellCheck,
} from 'Pages/Keywords/Table/Icon/Icons';
import { VIEW_MODE } from '../../../../Types/Table';
import { t } from '../../../../Utilities/i18n';
import AccTooltip from '../../../AccTooltip/AccTooltip';
import { TableSize } from '../../../DataTable';
import Cell from '../HelperComponents/AccuCell';
import { KwdExtraTagsCompactPlaceholder } from './components/KWExtraTagsCompactPlaceholder';
import { KeywordCellTagsList } from './components/KeywordCellTagsList';
import { KeywordExtraTagsCompact } from './components/KeywordExtraTagsCompact';
import * as helpers from './helpers';

type Props = {
  value: {
    data: {
      id: number;
      keyword: string;
      tags: string[];
      rankId: number;
      domainId: number;
      ignoreInShareOfVoice: boolean;
      ignoreLocalResults: boolean;
      ignoreFeaturedSnippet: boolean;
      enableAutocorrect: boolean;
      updatingKeyword: boolean;
      ignoredDomain: string;
    };
    loadingData: boolean;
  };
  showOptions: boolean;
  isLink?: boolean;
  onKeywordClick?: (...args: Array<any>) => any;
  // id, rankId, domainId
  onTagRemoveClick?: any;
  // id,tag
  onTagClick?: (...args: Array<any>) => any; // domainId, tag
  context?: any;
  dynamicTags?: any;
  updateRowData?: any;
  disableTags?: boolean;
  isDemoDomain?: boolean;
};
const possibleKeywordsOptions = [
  'ignoreInShareOfVoice',
  'ignoreLocalResults',
  'ignoreFeaturedSnippet',
  'enableAutocorrect',
  'ignoredDomain',
];

const getIconData = () => ({
  ignoreLocalResults: {
    icon: Ignore,
    label: t('Ignore local pack'),
  },
  ignoreFeaturedSnippet: {
    icon: Snippet,
    label: t('Ignore featured snippet'),
  },
  ignoreInShareOfVoice: {
    icon: IgnoreInShareOfVoice,
    label: t('Ignore in share of voice'),
  },
  enableAutocorrect: {
    icon: SpellCheck,
    label: t('Autocorrect enabled'),
  },
  ignoredDomain: {
    icon: IgnoredDomainsIcon,
    label: undefined, // Dynamic label here
  },
});

const KeywordCell = (props: Props) => {
  const {
    value: { data, loadingData },
    onKeywordClick,
    onTagRemoveClick: onTagRemove,
    showOptions,
    onTagClick,
    isLink = true,
    disableTags,
    isDemoDomain,
  } = props;
  const [renderExtraTags, setRenderExtraTags] = useState(false);
  if (loadingData) return '';
  const viewMode = props?.context?.viewMode;

  const { id, keyword, tags, dynamicTags, rankId, domainId }: any = data;
  const combinedTags = helpers.concatTags(tags, dynamicTags);

  const onTagRemoveClick = (tag) => {
    props.updateRowData({
      tags: data?.tags?.filter((e) => e !== tag),
    });
    onTagRemove(id, tag);
  };

  const handleTagClick = (tag: string) => {
    onTagClick?.(id, tag);
  };

  const baseProps = {
    keywordId: id,
    onTagRemoveClick,
    onTagClick: handleTagClick,
  };

  const iconsData = getIconData();

  const showTags = viewMode === 'default';

  const keywordCellClasses = classNames({
    'u-flex u-items-center accu-table-cell__keyword--no-tags': !showTags,
    'u-flex u-items-center': viewMode === VIEW_MODE.comfortable,
  });
  const keywordInfoIcons = possibleKeywordsOptions.filter((option) => data[option]);
  const iconOptions = showOptions && (
    <div className="keyword-options">
      {possibleKeywordsOptions.map((option, index) => {
        if (data[option]) {
          const iconData = iconsData[option] || {};
          const { icon: IconTag } = iconData;

          let label = iconData.label;

          if (option === 'ignoredDomain') {
            label = t('Ignored domain: %s', data.ignoredDomain);
          }

          return (
            <AccFastIcon
              fit="contain"
              key={index}
              color={'#ff4444'}
              tooltip={label}
              src={IconTag}
            />
          );
        }
      })}
      {!showTags && combinedTags?.length > 0 && (
        <Box ml="xs" className="accu-table-cell__tags--inline">
          {renderExtraTags ? (
            <KeywordExtraTagsCompact tags={combinedTags} {...baseProps} />
          ) : (
            <KwdExtraTagsCompactPlaceholder
              tagsLength={combinedTags?.length}
              onMouseEnter={() => !renderExtraTags && setRenderExtraTags(true)}
            />
          )}
        </Box>
      )}
    </div>
  );

  const updatingKeyword = props.value?.data?.updatingKeyword || rankId === 0; // Backend will send rank id '0' when no rank for this keyword id is found

  let tooltip;
  let cursorStyle;
  if (updatingKeyword) {
    tooltip = t('Rank history not available while updating keyword');
    cursorStyle = { cursor: 'not-allowed' };
  } else {
    tooltip = t('Show rank history for \'%s\'', keyword);
    cursorStyle = {};
  }

  return (
    <Cell className={classNames('accu-table-cell__keyword', keywordCellClasses)}>
      <div className="accu-table-cell__keyword-item">
        {onKeywordClick && isLink ? (
          <AccTooltip tooltip={tooltip}>
            {/*//If keyword has (most likely) elipsis, show tooltip*/}
            <a
              className="accu-table-cell_keyword__text-item"
              style={cursorStyle}
              onClick={(e) => {
                // Super user shortcut to get keyword id
                if (e.altKey && e.shiftKey && e.ctrlKey) {
                  alert(props.value.data.id);
                  return;
                }
                updatingKeyword ? undefined : onKeywordClick(id, rankId, domainId);
              }}
            >
              {keyword}
            </a>
          </AccTooltip>
        ) : (
          <span className="accu-table-cell_keyword__text-item">{keyword}</span>
        )}
        {!disableTags && viewMode === TableSize.DEFAULT && (
          <KeywordCellTagsList
            keywordId={id}
            domainId={domainId}
            tags={tags}
            dynamicTags={dynamicTags}
            onTagRemoveClick={isDemoDomain ? undefined : onTagRemoveClick}
            onTagClick={handleTagClick}
            infoIconsLength={keywordInfoIcons?.length}
          />
        )}
      </div>
      {iconOptions}
    </Cell>
  );
};
export default KeywordCell;
