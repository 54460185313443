import { t } from 'Utilities/i18n';
import {
  BaseOperations,
  NumberOperations,
  OperationType,
  StringOperation,
} from '../../../support/constants';

export const MAX_RULES_DEPTH = 4;

export const SourceKey = {
  KEYWORD: 'keyword',
  RANK: 'rank',
  LANDING_PAGE_URL: 'landingPageUrl',
  LANDING_PAGE_TITLE: 'landingPageTitle',
};

export const TITLE_CONTAINS_KEYWORD = 'title_contains_keyword';

export const TAG_SOURCE_OPTIONS = [
  {
    id: SourceKey.KEYWORD,
    operationType: OperationType.STRING,
    getLabel: () => t('Keyword'),
  },
  {
    id: SourceKey.LANDING_PAGE_URL,
    operationType: OperationType.STRING,
    getLabel: () => t('Landing page url'),
  },
  {
    id: SourceKey.LANDING_PAGE_TITLE,
    operationType: OperationType.STRING_AND_TITLE_CONTAINS_KEYWORD,
    getLabel: () => t('Landing page title tag'),
  },
  {
    id: SourceKey.RANK,
    operationType: OperationType.NUMBER,
    getLabel: () => t('Rank'),
  },
];

export const STRING_OPERATIONS = [
  { id: StringOperation.CONTAINS, getLabel: () => t('Contains') },
  { id: StringOperation.EQ, getLabel: () => t('Equals') },
  { id: StringOperation.NE, getLabel: () => t('Not equals') },
  { id: StringOperation.NOT_CONTAINS, getLabel: () => t('Not contains') },
  { id: StringOperation.STARTS_WITH, getLabel: () => t('Starts with') },
  { id: StringOperation.ENDS_WITH, getLabel: () => t('Ends with') },
  { id: StringOperation.REGEX, getLabel: () => t('RegEx') },
  { id: StringOperation.NOT_REGEX, getLabel: () => t('Not RegEx') },
];

export const STRING_OPERATIONS_AND_TITLE_CONTAINS_KEYWORD = [
  { id: TITLE_CONTAINS_KEYWORD, getLabel: () => t('Contains keyword') },
  ...STRING_OPERATIONS,
];

export const NUMBER_OPERATIONS = [
  { id: NumberOperations.BETWEEN, getLabel: () => t('Between') },
  { id: NumberOperations.EQ, getLabel: () => t('Equal =') },
  { id: NumberOperations.NE, getLabel: () => t('Not equals !=') },
  { id: NumberOperations.LT, getLabel: () => t('Less than <') },
  { id: NumberOperations.GT, getLabel: () => t('Greater than >') },
];

export const BASE_OPERATIONS = [
  { id: BaseOperations.ANY, getLabel: () => t('Any') },
  { id: BaseOperations.ALL, getLabel: () => t('All') },
  { id: BaseOperations.NONE, getLabel: () => t('None') },
  { id: BaseOperations.EMPTY, getLabel: () => t('Empty') },
];
