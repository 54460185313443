import { Component } from 'react';
import { AccMultiSelect } from 'Components/AccSelect';
import { t } from 'Utilities/i18n/index';

type Props = {
  value: Array<string>;
  onChange: (...args: Array<any>) => any;
  placeholder: string;
  showError?: boolean;
  options: Array<Record<string, any>>;
  disabled?: boolean;
  autoFocus?: boolean;
};

class EmailsInput extends Component<Props> {
  static defaultProps = {
    value: [],
    onChange: () => {},
    showError: false,
    options: [],
    disabled: false,
    autoFocus: false,
  };
  handleChange = (selectVal: Array<any>) => {
    this.props.onChange(selectVal);
  };

  render() {
    const { value, placeholder, showError, disabled, autoFocus } = this.props;

    return (
      <AccMultiSelect
        placeholder={placeholder}
        autoFocus={autoFocus}
        value={value}
        showError={showError}
        disabled={disabled}
        options={[]}
        onChange={this.handleChange}
        promptTextCreator={(label) => `${t('Add email')} "${label}"`}
        noResultsText={t('Add email')}
        size="default"
      />
    );
  }
}

export default EmailsInput;
