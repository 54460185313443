import { CountByCountryNode } from 'Ghql';
import { t } from 'Utilities/i18n';
import { TableStoreType } from '../../../Components/DataTable';

// We don't send default country, to prevent waiting for loading list of countries
// https://accuranker.myjetbrains.com/youtrack/issue/ARR-2010
export const getKeydisCountryFilterValue = (
  country?: CountByCountryNode | null,
  isFreeTextSearch?: boolean,
): string | undefined =>
  (!country?.default || isFreeTextSearch ? country?.country : undefined) ?? undefined;

export const getPlaceholderKeywords = (tableStore: TableStoreType) => {
  const items = tableStore?.data
    ?.filter((e) => tableStore.getIsRowSelected(e?.id))
    .map((e) => e.id);

  const totalKeywords = tableStore.totalSelectedCount;
  const additionalKeywords = totalKeywords - items?.length;
  if (additionalKeywords > 0) {
    items.push(
      items.length
        ? t('… and %s Keywords', additionalKeywords)
        : t('… %s Keywords', additionalKeywords),
    );
  }

  return items.map((e) => ({ keyword: e }));
};

/** Truncate string to a max length */
export const truncateString = (string = '', maxLength = 50) =>
  (string.length > maxLength ? `${string.substring(0, maxLength)}…` : string);

export const getIsCountByCountryNode = (e: unknown): e is CountByCountryNode => {
  return (e as CountByCountryNode)?.__typename === 'CountByCountryNode';
};
