import { useMemo } from 'react';
import { useDebouncedValue } from '@mantine/hooks';

const DELAY = 150; //ms
export const useDebouncedFilterValues = (value: string, comparison: string, type: string) => {
  const debouncedVariables = useMemo(
    () => ({
      debouncedValue: value,
      debouncedComparison: comparison,
      debouncedType: type,
    }),
    [value, comparison, type],
  );

  // Debounce the filter values to avoid unresponsive UI - debounce together to avoid multiple requests
  const [{ debouncedValue, debouncedComparison, debouncedType }] = useDebouncedValue(
    debouncedVariables,
    DELAY,
  );

  return { debouncedValue, debouncedComparison, debouncedType };
};
