import { useMantineTheme } from '@mantine/core';
import { IconDeviceDesktopAnalytics, IconListSearch } from '@tabler/icons-react';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import { AccFastIcon } from 'Components/AccFastIcon';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import AccessControl from 'Components/AccessControl';
import LinkToDomain, { PageIdToLink } from 'Components/Filters/LinkToDomain';
import styles from 'Components/Filters/filters.module.scss';
import { ProcessedFolderTreeNode } from 'Pages/Domains/TreeView/support/types';
import { getDomainId } from 'Utilities/generateSelectId';
import { t } from 'Utilities/i18n';
import { IconEdit, IconFolderPlus, IconTrash } from 'icons/tag-cloud';
import { useDomainActions } from './useDomainActions';
import { useFolderActions } from './useFolderActions';

type DomainActionsProps = {
  rootNode: ProcessedFolderTreeNode;
  showAction: boolean;
  isDemoDomain: boolean;
};

// eslint-disable-next-line import/no-unused-modules
export const DomainActions = ({ rootNode, showAction, isDemoDomain }: DomainActionsProps) => {
  const domainId = getDomainId(rootNode.path);
  const theme = useMantineTheme();
  const iconColor = theme.colors.snorlax[4];
  const iconBlue = theme.colors.blue[4];
  const { handleEditDomain, handleDeleteDomain } = useDomainActions(rootNode);
  const { handleAddFolder } = useFolderActions(rootNode);
  if (!showAction) return null;

  return (
    <>
      <LinkToDomain
        className={styles.link}
        domainId={domainId}
        reset={false}
        navigateTo={PageIdToLink.overview}
      >
        <AccTooltip tooltip={t('Go to dashboard')}>
          <AccActionIcon variant="subtle" tabIndex={-1}>
            <IconDeviceDesktopAnalytics size={16} color={iconBlue} />
          </AccActionIcon>
        </AccTooltip>
      </LinkToDomain>

      <LinkToDomain
        className={styles.link}
        domainId={domainId}
        reset={false}
        navigateTo={PageIdToLink.keywords}
      >
        <AccTooltip tooltip={t('Go to keywords list')}>
          <AccActionIcon variant="subtle" tabIndex={-1}>
            <IconListSearch size={16} color={iconBlue} />
          </AccActionIcon>
        </AccTooltip>
      </LinkToDomain>
      <AccessControl>
        {!isDemoDomain && (
          <>
            <AccTooltip tooltip={t('Create folder')} old>
              <AccActionIcon variant="subtle" onClick={handleAddFolder}>
                <AccFastIcon src={IconFolderPlus} size={18} color={iconColor} />
              </AccActionIcon>
            </AccTooltip>

            <AccTooltip tooltip={t('Edit domain')} old>
              <AccActionIcon variant="subtle" onClick={handleEditDomain}>
                <AccFastIcon src={IconEdit} size={18} color={iconColor} />
              </AccActionIcon>
            </AccTooltip>

            <AccTooltip tooltip={t('Delete domain')} old>
              <AccActionIcon variant="subtle" onClick={handleDeleteDomain}>
                <AccFastIcon src={IconTrash} size={18} color={iconColor} />
              </AccActionIcon>
            </AccTooltip>
          </>
        )}
      </AccessControl>
    </>
  );
};
