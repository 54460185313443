import colorScheme from 'Utilities/colors';
import { mantineTheme } from 'css/mantine/theme';

export const lineChartColors = {
  line: {
    DEFAULT_COLOR: colorScheme.all.purple,
    AXIS_COLOR: mantineTheme?.colors?.gray?.[1],
  },
  tooltip: {
    BG_COLOR: '#FFFFFF',
    BORDER_COLOR: '#E0E0E7',
  },
  navigator: {
    BORDER_COLOR: '#6c7096',
    SERIES_COLOR: '#a47bcd',
    MASK_FILL: `${mantineTheme?.colors?.snorlax?.[4]}25`,
  },
  scrollbar: {
    THUMB: mantineTheme?.colors?.snorlax?.[0],
    THUMB_RIFLE: mantineTheme?.colors?.snorlax?.[0],
    THUMB_BORDER: mantineTheme?.colors?.snorlax?.[4],
    TRACK: 'transparent',
    TRACK_BORDER: mantineTheme?.colors?.snorlax?.[4],
    BUTTON_ARROW: mantineTheme?.colors?.gray?.[9],
    BUTTON_ARROW_BACKGROUND: mantineTheme?.colors?.snorlax?.[0],
  },
} as const;

export const NOTES_SERIES_ID = 'notesSeriesId';

export const LINE_TOOLTIP_HEADER_FORMAT =
  '<div class="chart-tooltip-table"><div class="chart-tooltip-table-tr"><div class="chart-tooltip-table-th">{point.key}</div></div>';

export const CHART_HEIGHT = 330;

export const NOT_IN_TOP_100_VALUE = 101;
