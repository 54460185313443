import { useEffect, useRef } from 'react';
import { useUser } from './data/user/useUser';

const cleanupScript = (scriptRef: React.MutableRefObject<HTMLElement | null>) => {
  if (!scriptRef.current) return;
  document.body.removeChild(scriptRef.current);
  scriptRef.current = null;
};

export const useGoogleAdsTracking = (
  { isTrialConversionFlow }: { isTrialConversionFlow: boolean },
  skip = false,
) => {
  const gtmScriptRef = useRef<HTMLScriptElement | null>(null);
  const gtagScriptRef = useRef<HTMLScriptElement | null>(null);

  useEffect(() => {
    if (skip) return;

    // Load Google Tag Manager script (for Google Ads)
    if (!gtmScriptRef.current) {
      gtmScriptRef.current = document.createElement('script');
      gtmScriptRef.current.async = true;
      gtmScriptRef.current.src = 'https://www.googletagmanager.com/gtag/js?id=AW-983697634';
      document.body.appendChild(gtmScriptRef.current);
    }

    // Load inline GTag script (for Google Ads)
    if (!gtagScriptRef.current) {
      gtagScriptRef.current = document.createElement('script');
      gtagScriptRef.current.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-983697634');
        `;
      document.body.appendChild(gtagScriptRef.current);
    }

    if (isTrialConversionFlow && window.location.search.includes('firstVisit')) {
      (window as any)?.gtag?.('event', 'conversion', {
        send_to: 'AW-983697634/KKGWCNe_ve8YEOKRiNUD',
      });
    }

    return () => {
      cleanupScript(gtmScriptRef);
      cleanupScript(gtagScriptRef);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip]);
};

const useLinkedInTracking = ({ isTrialConversionFlow }: { isTrialConversionFlow: boolean }) => {
  const lintrkScriptRef = useRef<HTMLScriptElement | null>(null);

  useEffect(() => {
    // Load LinkedIn tracking script
    if (!lintrkScriptRef.current) {
      lintrkScriptRef.current = document.createElement('script');
      lintrkScriptRef.current.innerHTML = `
          (function(l) {
          if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
          window.lintrk.q=[]}
          var s = document.getElementsByTagName("script")[0];
          var b = document.createElement("script");
          b.type = "text/javascript";b.async = true;
          b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
          s.parentNode.insertBefore(b, s);})(window.lintrk);
        `;
      document.body.appendChild(lintrkScriptRef.current);
    }

    if (isTrialConversionFlow && window.location.search.includes('firstVisit')) {
      (window as any).lintrk?.('track', { conversion_id: 657737 });
    }

    return () => {
      cleanupScript(lintrkScriptRef);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const useCapterraTracking = ({ isTrialConversionFlow }: { isTrialConversionFlow: boolean }) => {
  const capterraImgRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    // Load Capterra Pixel
    if (!capterraImgRef.current) {
      capterraImgRef.current = document.createElement('img');
      capterraImgRef.current.src =
        'https://ct.capterra.com/capterra_tracker.gif?vid=2105362&vkey=87faf16f19c037a8a166503e427179ba';
    }

    if (isTrialConversionFlow && window.location.search.includes('firstVisit')) {
      document.body.appendChild(capterraImgRef.current);
    }

    return () => {
      cleanupScript(capterraImgRef);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const useMicrosoftAdsTracking = () => {
  const microsoftAdsScriptRef = useRef<HTMLScriptElement | null>(null);

  useEffect(() => {
    // Loads Microsoft tag
    if (!microsoftAdsScriptRef.current) {
      microsoftAdsScriptRef.current = document.createElement('script');
      microsoftAdsScriptRef.current.src = `
          <script>
            (function(w,d,t,r,u)
            {
                var f,n,i;
                w[u]=w[u]||[],f=function()
                {
                    var o={ti:"187089136", enableAutoSpaTracking: true};
                    o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")
                },
                n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function()
                {
                    var s=this.readyState;
                    s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)
                },
                i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)
            })
            (window,document,"script","//bat.bing.com/bat.js","uetq");
        </script>
        `;
      document.body.appendChild(microsoftAdsScriptRef.current);
    }

    return () => {
      cleanupScript(microsoftAdsScriptRef);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useGa4Tracking = () => {
  const user = useUser();

  const userCategory: 'trial_active' | 'trial_expired' | 'plan_active' | 'plan_expired' = user
    ?.organization?.errors?.trialExpired
    ? 'trial_expired'
    : user?.organization?.errors?.planExpired
    ? 'plan_expired'
    : user?.organization?.activePlan?.isTrial
    ? 'trial_active'
    : 'plan_active';

  const ga4ScriptRef = useRef<HTMLScriptElement | null>(null);
  const ga4TagScriptRef = useRef<HTMLScriptElement | null>(null);

  useEffect(() => {
    // Load Google Tag Manager script (for GA4)
    if (!ga4ScriptRef.current) {
      ga4ScriptRef.current = document.createElement('script');
      ga4ScriptRef.current.async = true;
      ga4ScriptRef.current.src = 'https://www.googletagmanager.com/gtag/js?id=G-D2SXNT78V1';
      document.body.appendChild(ga4ScriptRef.current);
    }

    // Load inline GTag script (for GA4)
    if (!ga4TagScriptRef.current) {
      ga4TagScriptRef.current = document.createElement('script');
      ga4TagScriptRef.current.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-D2SXNT78V1', {
            user_id: '${user?.id}',
          });

          gtag('set', 'user_properties', {
            user_category: '${userCategory}',
          });
        `;
      document.body.appendChild(ga4TagScriptRef.current);
    }

    return () => {
      cleanupScript(ga4ScriptRef);
      cleanupScript(ga4TagScriptRef);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useTrialConversionTracking = () => {
  useGoogleAdsTracking({ isTrialConversionFlow: true });
  useLinkedInTracking({ isTrialConversionFlow: true });
  useCapterraTracking({ isTrialConversionFlow: true });
  useMicrosoftAdsTracking();
  useGa4Tracking();
};
