import FormatNumber from 'Components/FormatNumber/new';
import { t, tct } from 'Utilities/i18n';
import { TableLabels } from '../../../types';
import { PageType } from './constants';

const range = (from: number, to: number, step: number = 1) => {
  let i = from;
  const pages: number[] = [];

  while (i <= to) {
    pages.push(i);
    i += step;
  }

  return pages;
};

export const getPageNumbers = ({
  totalDataLength,
  pageSize,
  pageNeighbours,
  currentPage,
}: {
  totalDataLength: number;
  pageSize: number;
  pageNeighbours: number;
  currentPage: number;
}): any[] => {
  const totalPages = Math.ceil(totalDataLength / pageSize);
  const totalNumbers = pageNeighbours * 2 + 3;
  const totalBlocks = totalNumbers + 2;
  let pages: any[] = range(1, totalPages);

  if (totalPages > totalBlocks) {
    const leftBound = currentPage - pageNeighbours;
    const rightBound = currentPage + pageNeighbours;
    const beforeLastPage = totalPages - 1;
    const startPage = leftBound > 2 ? leftBound : 2;
    const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

    pages = range(startPage, endPage);

    const pagesCount = pages.length;
    const singleSpillOffset = totalNumbers - pagesCount - 1;
    const leftSpill = startPage > 2;
    const rightSpill = endPage < beforeLastPage;
    const leftSpillPage = PageType.SPILL;
    const rightSpillPage = PageType.SPILL;

    if (leftSpill && !rightSpill) {
      const extraPages = range(startPage - singleSpillOffset, startPage - 1);
      pages = [leftSpillPage, ...extraPages, ...pages];
    } else if (!leftSpill && rightSpill) {
      const extraPages = range(endPage + 1, endPage + singleSpillOffset);
      pages = [...pages, ...extraPages, rightSpillPage];
    } else if (leftSpill && rightSpill) {
      pages = [leftSpillPage, ...pages, rightSpillPage];
    }

    pages = [1, ...pages, totalPages];
  }

  if (currentPage !== 1) {
    pages.unshift(PageType.PREVIOUS);
  }

  if (currentPage !== totalPages) {
    pages.push(PageType.NEXT);
  }

  return pages;
};

// display label in pagination component
export const getPaginationDisplayLabel = ({
  loading,
  tableLabels,
  totalDataLength,
  pageSize,
  currentPage,
}: {
  loading: boolean;
  totalDataLength: number;
  tableLabels: TableLabels | undefined;
  pageSize: number;
  currentPage: number;
}) => {
  const from = pageSize * (currentPage - 1) + 1;
  const to = Math.min(from + pageSize - 1, totalDataLength);

  const details = {
    from: from?.toString() || '0',
    to: to?.toString() || '0',
    total: (
      <FormatNumber
        formatSettings={{
          spaceBetweenNumberAndLabel: false,
          thousandsSettings: {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          },
          millionsSettings: {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          },
          billionsSettings: {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          },
        }}
        value={totalDataLength ?? 0}
      />
    ),
  };
  if (loading) {
    const showLoadingWithDetails = !isNaN(from) && !isNaN(to) && from <= to && totalDataLength;
    if (showLoadingWithDetails) {
      return (
        tableLabels?.paginationDetails?.(details) ??
        tct('Loading [from] to [to] of [total]', {
          total: (
            <FormatNumber
              formatSettings={{
                spaceBetweenNumberAndLabel: false,
                thousandsSettings: {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                },
                millionsSettings: {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                },
                billionsSettings: {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                },
              }}
              value={totalDataLength ?? 0}
            />
          ),
          from: from || '0',
          to: to || '0',
        })
      );
    }
    return tableLabels?.paginationLoading ?? t('Loading…');
  }

  return (
    tableLabels?.paginationDetails?.(details) ??
    tct('[from] to [to] of [total]', {
      from,
      to,
      total: <FormatNumber value={totalDataLength ?? 0} />,
    })
  );
};

export const scrollToFirstRow = (tableName: string) => {
  if (!tableName) return;

  requestAnimationFrame(() => {
    const actionsMenu = document.querySelector('#actionsMenuContainer[data-sticky="true"]');
    const filtersPanel = document.querySelector('#filtersPanel');

    const actionsMenuHeight = actionsMenu?.getBoundingClientRect()?.height || 0;
    const filtersPanelHeight = filtersPanel?.getBoundingClientRect()?.height || 0;

    const firstRowIndent =
      document.querySelector(`.data-table--${tableName}`)?.getBoundingClientRect().top ?? 0;

    const scrollTop = firstRowIndent + window.scrollY - actionsMenuHeight - filtersPanelHeight;
    if (firstRowIndent < 0) {
      window.scrollTo({
        top: scrollTop,
        behavior: 'smooth',
      });
    }
  });
};
