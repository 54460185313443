import { t } from 'Utilities/i18n';

export { default as TopOneKeywordsKpiBox } from './TopOneKeywords';
export { default as TopThreeKeywordsKpiBox } from './TopThreeKeywords';
export { default as FirstPageKeywordsKpiBox } from './FirstPageKeywords';
export { default as MovedUpInRankingsKpiBox } from './MovedUpInRankings';
export { default as MovedDownInRankingsKpiBox } from './MovedDownInRankings';

export const PUBLIC_KPI_NAMES = {
  TOP_ONE_KEYWORDS: 'KPI Top One Keywords',
  TOP_THREE_KEYWORDS: 'KPI Top Three Keywords',
  ON_PAGE_ONE: 'KPI on Page One Keywords',
  KEYWORDS_MOVED_UP: 'KPI Keywords Moved Up in Rankings',
  KEYWORDS_MOVED_DOWN: 'KPI Keywords Moved Down in rankings',
} as const;

export const GET_PUBLIC_KPI_DISPLAY_NAME = () => ({
  [PUBLIC_KPI_NAMES.TOP_ONE_KEYWORDS]: t('Ranked 1st on Page 1'),
  [PUBLIC_KPI_NAMES.TOP_THREE_KEYWORDS]: t('Ranked in Top 3 on Page 1'),
  [PUBLIC_KPI_NAMES.ON_PAGE_ONE]: t('On Page 1'),
  [PUBLIC_KPI_NAMES.KEYWORDS_MOVED_UP]: t('Moved Up in Rankings'),
  [PUBLIC_KPI_NAMES.KEYWORDS_MOVED_DOWN]: t('Moved Down in Rankings'),
});
