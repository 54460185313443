import { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { gql, useQuery } from '@apollo/client';
import { Flex } from '@mantine/core';
import { toJS } from 'mobx';
import { hideModal } from 'Actions/ModalAction';
import AccButton from 'Components/AccButton/AccButton';
import Checkbox from 'Components/Controls/Checkbox';
import { useTableStore } from 'Components/DataTable';
import LoadingSpinner from 'Components/LoadingSpinner';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import AccText from 'Components/Text/AccText';
import useKeyword from 'Hooks/useKeyword';
import { TableIDs } from 'Types/Table';
import { t } from 'Utilities/i18n';
import './remove-tags.scss';

type Props = {
  keywords: number[];
  domainId: number;
  isAllSelected?: boolean;
  filters?: any[];
  onClose?: (...args: Array<any>) => any;
  // string[]
  // auto
  hideModal: (...args: Array<any>) => any;
};
const domainQuery = gql`
  query removeTags_domainQuery($id: ID!) {
    domain(id: $id) {
      id
      tags
    }
  }
`;

const RemoveTags = (props: Props) => {
  const tableStore = useTableStore(TableIDs.KEYWORDS);
  const { hideModal: _hideModal, domainId, keywords, isAllSelected, filters, onClose } = props;
  const { loading: domainLoading, data: domainData } = useQuery(domainQuery, {
    variables: {
      id: domainId,
    },
    skip: !isAllSelected,
  });

  const [tagsToRemove, setTagsToRemove] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const { updateKeywordsTags } = useKeyword();

  const handleSubmit = () => {
    setLoading(true);
    tableStore?.updateSelectedItems((data) => {
      data.tags = data.tags?.filter((e) => !tagsToRemove?.includes(e)) ?? [];
    });
    updateKeywordsTags({
      keywords,
      isAllSelected,
      filters,
      tags: tagsToRemove,
      remove: true,
    }).then(() => {
      setLoading(false);
      onClose && onClose(tagsToRemove);

      _hideModal();
    });
  };

  const handleChange = (e) => {
    const tag = e.target.value;

    if (e.target.checked) {
      setTagsToRemove((old) => [...old, tag]);
    } else {
      setTagsToRemove((old) => old.filter((value) => value !== tag));
    }
  };

  const getTags = useCallback(() => {
    if (!isAllSelected && tableStore) {
      const keywordIds = keywords.map(Number);
      const selectedRows = toJS(tableStore.data).filter((keyword) =>
        keywordIds.includes(keyword.id),
      );
      const allTags: number[] = selectedRows.map((row) => row.tags);
      return [...new Set(allTags.flat())];
    }
  }, [isAllSelected, tableStore]);

  const renderTableRows = () => {
    const tags = !isAllSelected ? getTags() : domainData?.domain?.tags;
    return tags.map((tag) => (
      <>
        <Flex gap="xs">
          <Checkbox
            value={tag}
            onChange={handleChange}
            checked={tagsToRemove.some((tagToRemove) => tagToRemove === tag)}
          />
          <AccText>{tag}</AccText>
        </Flex>
      </>
    ));
  };

  const renderContent = () => {
    const tags = !isAllSelected ? getTags() : domainData?.domain?.tags;
    return tags?.length ? (
      <>
        <Flex direction="column" gap="xs">
          <AccText>{t('Select the tags you want to delete:')}</AccText>
          {renderTableRows()}
        </Flex>
        <ModalFooter
          primaryButtonSlot={
            <AccButton
              variant="primary"
              onClick={handleSubmit}
              disabled={loading || !tagsToRemove.length}
            >
              {t('Remove tags')}
            </AccButton>
          }
          secondaryButtonSlot={
            <AccButton variant="tertiary" onClick={_hideModal}>
              {t('Cancel')}
            </AccButton>
          }
          textSlot={
            <AccText variant="label">
              {t('Dynamic Tags can be deleted from the Tag Cloud tab.')}
            </AccText>
          }
        />
      </>
    ) : (
      <>
        <Flex gap="xs">
          <AccText>{t('Found no tags for the selected keywords.')}</AccText>
        </Flex>
        <ModalFooter
          primaryButtonSlot={
            <AccButton variant="secondary" onClick={_hideModal}>
              {t('Close this window')}
            </AccButton>
          }
          textSlot={
            <AccText variant="label">
              {t('Dynamic Tags can be deleted from the Tag Cloud tab.')}
            </AccText>
          }
        />
      </>
    );
  };

  return (
    <ModalBorder className="remove-tags" title={t('Delete Tags')} onClose={_hideModal}>
      {domainLoading ? <LoadingSpinner /> : renderContent()}
    </ModalBorder>
  );
};

export default connect(null, {
  hideModal,
})(RemoveTags);
