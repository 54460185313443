import { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'lodash/flowRight';
import { formValueSelector } from 'redux-form';
import { ReportType } from 'Constants/report';
import { t } from 'Utilities/i18n';
import { PDFPreview } from './PdfPreview';

type Props = {
  formValues: Record<string, any>;
};
type State = {};

class ScheduledReportPreview extends Component<Props, State> {
  hasRendered = false;

  shouldComponentUpdate(nextProps: Props) {
    const {
      formValues: { language, template, domain, group, isGroupReport, reportType },
    } = this.props;
    const {
      formValues: {
        language: nextLanguage,
        template: nextTemplate,
        domain: nextDomain,
        group: nextGroup,
        isGroupReport: nextIsGroupReport,
        reportType: nextReportType,
      },
    } = nextProps;

    if (!this.hasRendered) {
      return true;
    }

    const hasUpdated = (val, nextVal) => {
      if (!val) return !!nextVal;
      if (!nextVal) return true;
      if (
        typeof val === 'object' &&
        'value' in val &&
        typeof nextVal === 'object' &&
        'value' in nextVal
      ) {
        return val.value !== nextVal.value;
      }
      return val !== nextVal;
    };

    return (
      isGroupReport !== nextIsGroupReport ||
      hasUpdated(language, nextLanguage) ||
      hasUpdated(template, nextTemplate) ||
      hasUpdated(reportType, nextReportType) ||
      hasUpdated(domain, nextDomain) ||
      hasUpdated(group, nextGroup)
    );
  }

  buildPreviewUrl = () => {
    const {
      formValues: { isGroupReport, domain, group, language, template },
    } = this.props;
    const domainOrGroup = isGroupReport ? group : domain;
    return `/reports/${isGroupReport ? 'pdf_group_report' : 'pdf_report'}/${
      domainOrGroup?.value || domainOrGroup
    }/?preview=1&template_id=${template?.value || template}&language=${
      language?.value || language
    }&_=${Date.now()}&tags_filter_type=1#toolbar=0&navpanes=0`;
  };

  render() {
    const {
      formValues: { domain, group, reportType, template },
    } = this.props;
    const previewReady =
      (domain || group) &&
      template &&
      reportType &&
      (reportType.value || reportType === ReportType.PDF);
    this.hasRendered = this.hasRendered || previewReady;

    return previewReady ? (
      <PDFPreview url={this.buildPreviewUrl()} />
    ) : (
      <div className="empty-preview">
        {(reportType && (reportType.value || reportType)) === 1 ? (
          <p className="alert alert-info">{t('Please fill out the form to view the preview.')}</p>
        ) : (
          <p className="alert alert-info">{t('You can only preview PDF reports.')}</p>
        )}
      </div>
    );
  }
}

const formValuesSelector = formValueSelector('ScheduleReport');

const mapStateToProps = (state) => ({
  formValues: formValuesSelector(
    state,
    'isGroupReport',
    'domain',
    'group',
    'language',
    'template',
    'reportType',
  ),
});

export default compose(connect(mapStateToProps))(ScheduledReportPreview);
