import Breadcrumbs from 'Components/Breadcrumbs/Breadcrumbs';
import WalletsTable from './WalletsTable';
import './wallet.scss';

const Wallet = () => {
  return (
    <>
      <Breadcrumbs />
      <div className="wallet-table-wrapper">
        <WalletsTable />
      </div>
    </>
  );
};

export default Wallet;
