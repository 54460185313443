import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Progress } from '@mantine/core';
import noop from 'lodash/noop';
import { observer } from 'mobx-react';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import { useRefreshKeywordsMutation } from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import { useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import { useUser } from 'Hooks/data/user/useUser';
import toast, { MAX_NOTIFICATION_TIMEOUT } from 'Hooks/useToast';
import useUserPermission from 'Hooks/useUserPermission';
import LocaleSelector from 'Selectors/LocaleSelector';
import { EventName, trackEventMixpanel } from 'Utilities/Analytics/mixpanel';
import { t, tct } from 'Utilities/i18n';
import LoadingIcon from 'icons/loading.svg?inline';
import RefreshIcon from 'icons/refresh.svg?inline';
import { DomainTypeChoices } from '../../Query';
import AccTooltip from '../AccTooltip/AccTooltip';
import { refreshKeywordStore } from './RefreshKeywordStore';
import styles from './refresh-keywords.module.scss';

const RefreshKeywords = observer(({ disabled }: { disabled?: boolean }) => {
  const { domainInfo, refetch } = useQueryDomainInfo();
  const domainId = domainInfo?.id || '';
  const { showModal } = useModal();
  const [refreshKeywordsMutation] = useRefreshKeywordsMutation();
  const { userHasWriteAccess } = useUserPermission();
  const user = useUser();
  const fullLocale = useSelector(LocaleSelector);
  const keywordRefreshDisabled = user.organization?.errors?.keywordRefreshDisabled;
  const minutesBetweenRefresh = user.organization?.activePlan?.minutesBetweenRefresh;

  useEffect(() => {
    return () => {
      refreshKeywordStore.stopLoading(domainId);
    };
  }, []);

  const refreshKeywords = async () => {
    trackEventMixpanel(EventName.RefreshKeywordsDomain, '', { domainId });
    refreshKeywordStore.startLoading(domainId);
    const refreshTaskId = uuidv4();
    try {
      const res = await refreshKeywordsMutation({ variables: { domainId, refreshTaskId } });
      if (res.data?.scrapeDomain?.queued) {
        // Refetch domain info to update lastManualRefreshAt
        refetch();
        refreshKeywordStore.startLoading(domainId);
        toast.info(
          <div>
            {t('Keywords queued for refresh..')}
            <Progress.Root size="xl">
              <Progress.Section value={0} />
              <Progress.Label>0%</Progress.Label>
            </Progress.Root>
          </div>,
          {
            id: refreshTaskId,
            title: domainInfo
              ? tct('Refreshing all keywords on [domainName]...', {
                  domainName: domainInfo?.displayName || domainInfo?.domain,
                })
              : t('Refreshing all keywords... '),
            autoClose: MAX_NOTIFICATION_TIMEOUT,
            loading: true,
          },
        );
      } else {
        toast.error(res.data?.scrapeDomain?.reason);
        refreshKeywordStore.stopLoading(domainId);
      }
    } catch {
      refreshKeywordStore.stopLoading(domainId);
    }
  };

  const handleRefresh = () => {
    if (keywordRefreshDisabled) {
      showModal({
        modalType: 'Confirmation',
        modalProps: {
          title: t('Degraded Performance'),
          lockDuration: 0,
          description: (
            <div style={{ textAlign: 'left' }}>
              <p>{t('Your keywords will be automatically be refreshed every 24-hours.')}</p>
              <p>
                {t(
                  'We are currently having some internal issues on our network, we are working on fixing them. Your keywords will be automatically be refreshed every 24-hours.',
                )}
              </p>
              <p>{t('We apologize for the inconvenience.')}</p>
            </div>
          ),
          showCancelLabel: false,
          confirmLabel: t('Ok'),
          showExclamationInTitle: false,
        },
      });
      return;
    }
    showModal({
      modalType: 'Confirmation',
      modalProps: {
        title: t('Refresh Keywords'),
        lockDuration: 0,
        description: (
          <div style={{ textAlign: 'left' }}>
            <p>
              {tct(
                'Refresh all keywords on the domain: [domainName]. After refreshing, the next manual refresh will be available in 2 hours.',
                {
                  domainName: domainInfo?.displayName || domainInfo?.domain,
                },
              )}
            </p>
          </div>
        ),
        showCancelLabel: false,
        confirmLabel: t('Refresh keywords'),
        showExclamationInTitle: false,
        modalFooterTextSlot: t('Your keywords are automatically refreshed every 24-hours.'),
        action: refreshKeywords,
      },
    });
  };

  const UpdateButton = () => {
    const { canRefresh, lastManualRefreshAt } = domainInfo || {};

    if (domainId) {
      const now = moment(Date.now()).locale(fullLocale || 'en');
      const oldDate = moment(lastManualRefreshAt).locale(fullLocale || 'en');
      const MINUTES_BETWEEN_REFRESH = minutesBetweenRefresh || 120;
      const diff = MINUTES_BETWEEN_REFRESH - moment.duration(now.diff(oldDate)).asMinutes();
      const isDisabled = !canRefresh || diff > 0;
      const tooltip = !isDisabled
        ? t('Refresh all keywords')
        : t(
            'Next refresh available in %s',
            moment
              .duration(diff, 'minutes')
              .locale(fullLocale || 'en')
              .humanize(),
          );
      return (
        <AccTooltip placement="left" tooltip={tooltip}>
          {/* Added span to fix tooltip on disabled button  */}
          <span>
            <AccActionIcon
              variant="filled"
              disabled={disabled || isDisabled}
              onClick={canRefresh ? handleRefresh : noop}
              className={styles.refreshKeywordsButton}
            >
              {<RefreshIcon width={16} height={16} />}
            </AccActionIcon>{' '}
          </span>
        </AccTooltip>
      );
    }
    return null;
  };

  if (
    !domainInfo ||
    !userHasWriteAccess ||
    domainInfo?.domainType === DomainTypeChoices.A_7 // do not show refresh button for Naver
  ) {
    return null;
  }

  const lastManualRefreshAt = moment(domainInfo?.lastManualRefreshAt);
  // if lastManualRefreshAt has year 1970 then its null
  const isRefreshingKeywords =
    refreshKeywordStore.getIsRefreshing(domainId) || lastManualRefreshAt.year() <= 1970;

  if (isRefreshingKeywords) {
    return (
      <span className={styles.refreshKeywords}>
        {t('Refreshing data')}
        <Box ml="xs">
          <LoadingIcon className="loading-spinner --large" />
        </Box>
      </span>
    );
  } else if (domainInfo?.lastManualRefreshAt) {
    return (
      <span className={styles.refreshKeywords}>
        <UpdateButton />
      </span>
    );
  }

  return null;
});

export default RefreshKeywords;
