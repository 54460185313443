import { useState } from 'react';
import Breadcrumbs from 'Components/Breadcrumbs/Breadcrumbs';
import AccTitle from 'Components/Title/AccTitle';
import { t } from 'Utilities/i18n';
import reusableStyles from 'css/reusable-styles.module.scss';
import OwnersTable from '../OwnersTable/index';
import PendingOwnersTable from '../PendingOwnersTable/index';

export type ExternalAccessTablesProps = {
  dataKey: number;
  updateTables: () => void;
};

export default function ExternalAccessTables() {
  //used to force refetch of the tabledata
  const [dataKey, setDataKey] = useState(1);
  const updateTables = () => setDataKey((key) => key + 1);
  return (
    <div>
      <Breadcrumbs />
      <AccTitle mb="sm" type="h3">
        {t('Pending requests to access this account')}
      </AccTitle>
      <div className={reusableStyles.paper}>
        <PendingOwnersTable updateTables={updateTables} dataKey={dataKey} />
      </div>
      <AccTitle mb="sm" type="h3" mt="lg">
        {t('Organizations with access to this account')}
      </AccTitle>
      <div className={reusableStyles.paper}>
        <OwnersTable updateTables={updateTables} dataKey={dataKey} />
      </div>
    </div>
  );
}
