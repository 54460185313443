import NoScrapeOnCompareDateCell from 'Components/AccuTable/CellRenderer/helpers/NoScrapeOnCompareDateCell';
import ValueIndicator from 'Components/ValueIndicator/value-indicator';
import { t } from 'Utilities/i18n';
import Cell from './HelperComponents/AccuCell';
import NaCell from './helpers/NaCell';

type Props = {
  value: {
    data: {
      searchVolumeValue: number;
      shareOfVoiceValue: number;
      shareOfVoiceCompare: number;
      shareOfVoiceIsPercentage: boolean;
      shareOfVoicePercentageValue: number;
      shareOfVoicePercentageValueCompare: number;
      validForSearchVolume: boolean;
      updatingKeyword: boolean;
      noScrapeOnCompareDate?: boolean;
    };
    loadingData: boolean;
  };
};

const ShareOfVoiceChangeCell = (props: Props) => {
  const {
    value: { data, loadingData },
  } = props;
  if (loadingData) return '';
  const {
    updatingKeyword,
    searchVolumeValue,
    shareOfVoiceIsPercentage,
    shareOfVoicePercentageValue,
    shareOfVoicePercentageValueCompare,
    validForSearchVolume,
    noScrapeOnCompareDate,
  } = data;
  let { shareOfVoiceValue, shareOfVoiceCompare } = data;

  if (!validForSearchVolume) {
    return <NaCell tooltip={t('Share of Voice not available.')} align="right" />;
  }

  if (updatingKeyword || searchVolumeValue === null) {
    return <NaCell tooltip={t('Share of Voice not available.')} align="right" />;
  }

  if (noScrapeOnCompareDate) {
    return <NoScrapeOnCompareDateCell />;
  }

  if (shareOfVoiceValue === null) {
    shareOfVoiceValue = 0;
  }

  if (shareOfVoiceCompare === null) {
    shareOfVoiceCompare = 0;
  }

  return (
    <Cell className={'accu-table-cell__share-of-voice-change'} rightAligned={true}>
      <ValueIndicator
        beforeValue={
          shareOfVoiceIsPercentage ? shareOfVoicePercentageValueCompare : shareOfVoiceCompare
        }
        afterValue={shareOfVoiceIsPercentage ? shareOfVoicePercentageValue : shareOfVoiceValue}
        style={shareOfVoiceIsPercentage ? 'percent' : 'decimal'}
      />
    </Cell>
  );
};

export default ShareOfVoiceChangeCell;
