import moment from 'moment';
import { TableSize } from 'Components/DataTable';
import { ColumnOrderingState } from 'Components/DataTable/types';
import { TableID } from 'Types/Table';

const storageKey = {
  TABLE_STORE_STATE: 'default_table_store_state',
  SESSION_MODAL_CLOSED: 'session_modal_closed',
  KEYWORD_MODAL_CONTENT: 'keyword_modal_content',
};

type KeywordsPair = {
  value: string[];
  timestamp: string;
};
type KeywordModalContent = {
  [domainId: string]: KeywordsPair;
};

type TableConfig = { viewMode: TableSize; ordering: ColumnOrderingState | undefined };

class AppStorage {
  public setSessionModalClosed = (val: boolean) => {
    if (val) {
      return localStorage.setItem(storageKey.SESSION_MODAL_CLOSED, 'true');
    }
    return localStorage.removeItem(storageKey.SESSION_MODAL_CLOSED);
  };

  public getSessionModalClosed = () => {
    return localStorage.getItem(storageKey.SESSION_MODAL_CLOSED) === 'true';
  };

  public setTableStore = (tableName: TableID, tableConfig: TableConfig) => {
    localStorage.setItem(
      storageKey.TABLE_STORE_STATE.concat(tableName),
      JSON.stringify(tableConfig),
    );
  };

  public getTablesStore = (tableName: TableID): TableConfig | null => {
    try {
      const data = localStorage.getItem(storageKey.TABLE_STORE_STATE.concat(tableName));
      if (data) {
        try {
          return JSON.parse(data);
        } catch (e) {
          return null;
        }
      }
    } catch (e) {
      return null;
    }
    return null;
  };

  public clearKeywordModalContent = (): void =>
    localStorage.removeItem(storageKey.KEYWORD_MODAL_CONTENT);

  public setKeywordModalContent = (value: string[], domainId: string): void => {
    if (!domainId) return;
    const timestamp = moment(Date.now()).format();
    const item = localStorage.getItem(storageKey.KEYWORD_MODAL_CONTENT);
    let store: KeywordModalContent = item ? JSON.parse(item) : null;
    if (!store) store = {};
    if (value) {
      store[domainId] = { value, timestamp };
    } else {
      delete store[domainId];
    }
    localStorage.setItem(storageKey.KEYWORD_MODAL_CONTENT, JSON.stringify(store));
  };

  private TTL = 14; // days

  /** The contents of the textarea field in the "Add Keywords" modal" */
  public getKeywordModalContent = (domainId: string): string[] => {
    const item = localStorage.getItem(storageKey.KEYWORD_MODAL_CONTENT);
    if (!item) return [];
    let store: KeywordModalContent | undefined;
    try {
      store = JSON.parse(item) as KeywordModalContent;
    } catch (err) {
      this.clearKeywordModalContent();
      return [];
    }
    const entry = store && store[domainId];
    if (!entry) return [];

    // too old?
    const now = moment(Date.now());
    const tooOld = entry.timestamp && now.diff(entry.timestamp, 'days') > this.TTL;
    if (tooOld) {
      delete store[domainId];
      localStorage.setItem(storageKey.KEYWORD_MODAL_CONTENT, JSON.stringify(store));
      return [];
    }

    return entry.value;
  };
}

export const appStorage = new AppStorage();
