import * as React from 'react';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import { Progress } from '@mantine/core';
import { Severity, captureMessage } from '@sentry/react';
import compose from 'lodash/flowRight';
import AccButton from 'Components/AccButton/AccButton';
import { EventName, TrackingKey, trackEventMixpanel } from 'Utilities/Analytics/mixpanel';
import { t } from 'Utilities/i18n';
import './save-google-account.scss';

type Props = {
  oAuthResult: Record<string, any>;
  addGoogleAccount: (...args: Array<any>) => any;
  onSubmit: (...args: Array<any>) => any;
  onCancel: (...args: Array<any>) => any;
};
type State = {
  loading: boolean;
  error: string;
};

class SaveGoogleAccount extends React.Component<Props, State> {
  state = {
    error: '',
    loading: false,
  };

  componentDidMount() {
    const oAuthResult = this.props.oAuthResult;
    try {
      const oAuthData = JSON.parse(sessionStorage.getItem('oAuthData') || '{}');

      if (oAuthResult.error) {
        this.setState({
          error: oAuthResult.error,
        });
        return;
      }

      if (oAuthData.state !== oAuthResult.state) {
        // error potential attack
        this.setState({ error: t('Failed to verify state') });
        return;
      }

      this.setState({
        loading: true,
      });
      const input = {
        code: oAuthResult.code,
        description: oAuthData.description,
        redirectUri: oAuthData.redirectUri,
        type: parseInt(oAuthData.type),
      };
      this.props
        .addGoogleAccount({
          variables: {
            input,
          },
        })
        .then(
          ({ data: { addGoogleConnection } }) => {
            this.setState({
              loading: false,
            });
            this.props.onSubmit({
              connectionId: addGoogleConnection.connection.id,
            });
            trackEventMixpanel(EventName.ConnectToGSC, '', {
              Variant: 'Success',
              TrackingKey: TrackingKey.SaveGoogleAccount,
            });
          },
          () => {
            this.props.onCancel();
            this.setState({
              error: t('Failed to save OAuth token'),
              loading: false,
            });
            trackEventMixpanel(EventName.ConnectToGSC, '', {
              Variant: 'Error',
              TrackingKey: TrackingKey.SaveGoogleAccount,
            });
          },
        );
    } catch (e) {
      captureMessage('Failed to extract google account from local storage.', Severity.Critical);
      this.setState({ error: t('Failed to verify state') });
      trackEventMixpanel(EventName.ConnectToGSC, '', {
        Variant: 'Error',
        TrackingKey: TrackingKey.SaveGoogleAccount,
      });
    }
  }

  render() {
    const { loading, error } = this.state;
    let content: any = null;

    if (loading) {
      content = <Progress size="lg" animated value={100} />;
    }

    if (error) {
      content = (
        <div>
          <p>{error}</p>
          <AccButton
            variant="primary"
            onClick={() => {
              this.props.onCancel;
              trackEventMixpanel(EventName.ConnectToGSC, '', {
                Variant: 'Back',
                TrackingKey: TrackingKey.SaveGoogleAccount,
              });
            }}
          >
            {t('Back')}
          </AccButton>
        </div>
      );
    }

    return <div className="save-google-account">{content}</div>;
  }
}

const addGoogleAccountQuery = gql`
  mutation saveGoogleAccount_addGoogleConnection($input: AddGoogleConnectionInput!) {
    addGoogleConnection(input: $input) {
      connection {
        id
      }
    }
  }
`;
export default compose(
  graphql(addGoogleAccountQuery, {
    name: 'addGoogleAccount',
  }),
)(SaveGoogleAccount);
