import React from 'react';
import { DocumentNode } from '@apollo/client';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { useModal } from './base/useModal';
import useSubscription from './useSubscription';

type Props = {
  query: DocumentNode | TypedDocumentNode;
  variables?: any;
  onSubscriptionData: (...args: Array<any>) => any;
};

/*
Use this when you cant use the hook
 */
const Subscription = React.memo((props: Props) => {
  const disableMultipleSessions = true;
  const { showModal } = useModal();
  const { query, variables } = props;

  const handleSubscriptionData = (params) => {
    props.onSubscriptionData(params.subscriptionData, {
      showModal,
      disableMultipleSessions,
    });
  };

  useSubscription({
    query,
    variables,
    onSubscriptionData: handleSubscriptionData,
  });
  return null;
});
Subscription.displayName = 'Subscription';
export default Subscription;
