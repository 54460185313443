import { gql } from '@apollo/client';

export const nestedSitemapQuery = gql`
  query sitemapping_nestedSitemapQuery(
    $filters: [FilterInput]!
    $mode: String!
    $orderBy: String!
    $maxChildren: Int!
  ) {
    sitemappings(filters: $filters, mode: $mode, orderBy: $orderBy, maxChildren: $maxChildren) {
      sitemap
    }
  }
`;

// fetches competitors panel info, for all paths
export const unknownCompetitorDomainsQuery = gql`
  query sitemapping_unknownCompetitorDomainsQuery(
    $filters: [FilterInput]!
    $paths: [String]!
    $includeKeydis: Boolean!
  ) {
    unknownCompetitorDomains(filters: $filters, paths: $paths, includeKeydis: $includeKeydis) {
      unknownCompetitorDomains
    }
  }
`;
