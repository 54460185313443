/* eslint-disable @typescript-eslint/no-use-before-define */
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Flex, Group, Space } from '@mantine/core';
import { IconUpload } from '@tabler/icons-react';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import AccessControl from 'Components/AccessControl';
import AccTitle from 'Components/Title/AccTitle';
import { getDisabledDemoText } from 'Constants/messages';
import { useModal } from 'Hooks/base/useModal';
import { useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import { SourceKey } from 'Pages/DomainSettings/components/DynamicTagsBuilder/support/constants';
import { StringOperation } from 'Pages/DomainSettings/support/constants';
import { sitemapping_unknownCompetitorDomainsQuery } from 'Query';
import type { FilterBase } from 'Types/Filter';
import { FilterComparison } from 'Types/Filter';
import { FilterTrackingKey } from 'Utilities/Analytics/mixpanel';
import { t, tct } from 'Utilities/i18n';
import { unknownCompetitorDomainsQuery } from '../sitemap.query';
import {
  getAddTagTokenGroup,
  getPathFilter,
  linkToKeydis,
  linkToKeywordList,
  removeProtocolFromUrl,
} from '../support/helpers';
import { CompetitorDomains, PathToCompetitorDomains, SitemapNode } from '../support/types';
import { CompetitorTable } from './CompetitorTable';
import { OverviewPanel } from './OverviewPanel';
import { PanelData } from './PanelData';
import PathAndTitleView from './PathAndTitleView';
import { CompetitorCertaintyIndicator, SectionHeader } from './SectionHeader';
import { TagsSection } from './TagsSection';
import { useKeydisImportModal } from './useKeydisImportModal';

type SiteMappingRightColumnProps = {
  filters: FilterBase[];
  loading: boolean;
  selectedNode: SitemapNode | null;
  includeKeydis: boolean;
};

/*
Get the path segments to ask the backend for dynamic competitors for.
If a path is already in allCompetitorsTracked, we do not need to ask again
We ask for a path and all potential child paths. paths can be empty in which case the backend promptly gives
an empty object back.
 */
function getPathsForCompetitorsQuery(
  selectedNode: SitemapNode | null,
  allCompetitorsTracked: Map<string, CompetitorDomains[]>,
) {
  const paths: string[] = [];
  if (!selectedNode) {
    return paths;
  }

  if (!allCompetitorsTracked.get(selectedNode.pathSegment)) {
    const selectedPathSegment = selectedNode?.pathSegment !== '/' ? selectedNode.pathSegment : '';
    paths.push(selectedPathSegment);
  }
  // Comment out until we figure out better solution.. Not performant to ask on all subpaths.
  // if (
  //   selectedNode?.children &&
  //   Array.isArray(selectedNode.children) &&
  //   selectedNode.children.length > 0
  // ) {
  //   paths.push(
  //     ...selectedNode.children
  //       .filter((x) => x.pathSegment !== undefined && !allCompetitorsTracked.get(x.pathSegment)) // Filter away those we already fetched
  //       .map((x) => x.pathSegment),
  //   );
  // }

  return paths;
}

const dynamicCompetitorHelpTextRaw =
  'See who your competitors are [bold: based on the keywords that you are actively tracking on this landing page].\
[br][br]\
By default, the [italic: numbers] in the table are based on all keywords, both tracked and discovered. \
This can be changed with the switch in the top right of this page.\
[br][br]\
Click on a number in the "Shared" column to see keywords you track where your competitor\'s landing page appears in the SERP.\
[br][br]\
Click on the number in the "Disc." column to see untracked keywords where your competitor\'s landing page \
appears in the SERP.';

const SiteMappingRightColumn = ({
  loading,
  selectedNode,
  filters,
  includeKeydis,
}: SiteMappingRightColumnProps): JSX.Element | null => {
  // fetch competitors panel info, for all paths
  const [allCompetitorsTracked, setAllCompetitorsTracked] = useState<
    Map<string, CompetitorDomains[]>
  >(new Map());

  useEffect(() => {
    allCompetitorsTracked.clear();
    setAllCompetitorsTracked(new Map(allCompetitorsTracked));
  }, [includeKeydis, filters]);

  const { showModal } = useModal();

  const { isDemoDomain } = useQueryDomainInfo();

  const nodeHasChildren =
    selectedNode?.children &&
    Array.isArray(selectedNode.children) &&
    selectedNode.children.length > 0;

  const paths = getPathsForCompetitorsQuery(selectedNode, allCompetitorsTracked);

  useQuery<sitemapping_unknownCompetitorDomainsQuery>(unknownCompetitorDomainsQuery, {
    variables: { filters, paths, includeKeydis },
    skip: !paths,
    onCompleted: (data) => {
      data?.unknownCompetitorDomains?.unknownCompetitorDomains &&
        Object.entries(
          data?.unknownCompetitorDomains?.unknownCompetitorDomains as PathToCompetitorDomains,
        ).map(([key, value]) => {
          allCompetitorsTracked.set(key, value);
          setAllCompetitorsTracked(new Map(allCompetitorsTracked));
        });
    },
    notifyOnNetworkStatusChange: true,
  });

  const handleShowKeywordsModal = (data: number | undefined, includingChildren: boolean) => {
    const filterComparison = includingChildren ? FilterComparison.STARTS_WITH : FilterComparison.EQ;
    const highestRankingPageFilter = getPathFilter(selectedNode?.pathSegment, filterComparison);

    return useKeydisImportModal({
      filters: [...filters, highestRankingPageFilter],
      keywordCount: data || 0,
    });
  };

  const handleShowAddTagModal = () => {
    if (!selectedNode?.url) return;
    const urlForTag = selectedNode.url === '-' ? selectedNode.pathSegment : selectedNode.url;
    const compareOperation =
      selectedNode.url === '-' ? StringOperation.CONTAINS : StringOperation.EQ;
    showModal({
      modalType: 'CreateOrEditDynamicTag',
      modalTheme: 'light',
      modalProps: {
        isEdit: false,
        initialValues: {
          rawRuleset: JSON.stringify(
            getAddTagTokenGroup(SourceKey.LANDING_PAGE_URL, compareOperation, urlForTag),
          ),
        },
      },
    });
  };

  const linkToUntracked = (includingChildren: boolean) => {
    const filterComparison = includingChildren ? FilterComparison.STARTS_WITH : FilterComparison.EQ;
    return linkToKeydis(selectedNode?.url, filterComparison);
  };

  // eslint-disable-next-line no-confusing-arrow
  const linkToTracked = (includingChildren: boolean) =>
    selectedNode?.url
      ? linkToKeywordList(
          removeProtocolFromUrl(selectedNode?.url),
          includingChildren ? FilterComparison.CONTAINS : FilterComparison.ENDS_WITH, // Contains not always right, but neither is startswith
        )
      : undefined;
  const maxNumberOfSubpages = 40000;
  const numberOfSubpagesText =
    selectedNode && selectedNode?.nChild < maxNumberOfSubpages - 10 // Margin of 10 here on purpose
      ? selectedNode?.nChild
      : `>${maxNumberOfSubpages}`;

  const dynamicCompetitorHelpTextI18n = tct(dynamicCompetitorHelpTextRaw, {
    br: <br />,
    italic: <i />,
    bold: <b />,
  });

  return (
    <>
      <Flex direction="column" w="100%" py="md" px="lg" mih={810}>
        <Flex mb="md">
          <PathAndTitleView
            loading={loading}
            selectedNode={selectedNode}
            includingChildren={false}
          />
        </Flex>
        <Group style={{ gap: 0 }}>
          <OverviewPanel
            aligned="left"
            title={t('Tracked keywords')}
            leftSlot={
              <PanelData
                description={t('This page')}
                tooltipText={t('See %s tracked keywords', selectedNode?.kwECT)}
                data={selectedNode?.kwECT}
                link={linkToTracked(false)}
                loading={loading}
                filterTrackingKey={FilterTrackingKey.SiteExplorerToKeywords}
              />
            }
            rightSlot={
              <PanelData
                description={
                  nodeHasChildren
                    ? tct('Including [nSubpages] subpages', { nSubpages: numberOfSubpagesText })
                    : t('No subpages found')
                }
                tooltipText={t('See %s tracked keywords', selectedNode?.kwICT)}
                data={nodeHasChildren ? selectedNode?.kwICT : undefined}
                link={linkToTracked(true)}
                filterTrackingKey={FilterTrackingKey.SiteExplorerToKeywords}
                loading={loading}
              />
            }
          />
          <OverviewPanel
            aligned="right"
            title={t('Discovered keywords')}
            leftSlot={
              <PanelData
                link={linkToUntracked(false)}
                description={t('This page')}
                tooltipText={t(
                  'See %s available keywords in Keyword Discovery',
                  selectedNode?.kwECU,
                )}
                data={selectedNode?.kwECU}
                iconSlot={
                  <AccessControl>
                    <AddKeywordsIcon
                      onClick={handleShowKeywordsModal(selectedNode?.kwECU, false)}
                      tooltipText={
                        isDemoDomain
                          ? getDisabledDemoText()
                          : t('Import %s keywords', selectedNode?.kwECU)
                      }
                      data={selectedNode?.kwECU}
                      disabled={isDemoDomain}
                    />
                  </AccessControl>
                }
                loading={loading}
                filterTrackingKey={FilterTrackingKey.SiteExplorerToDiscovery}
              />
            }
            rightSlot={
              <PanelData
                link={linkToUntracked(true)}
                description={
                  nodeHasChildren
                    ? tct('Including [nSubpages] subpages', {
                        nSubpages: numberOfSubpagesText,
                      })
                    : t('No subpages found')
                }
                data={nodeHasChildren ? selectedNode?.kwICU : undefined}
                tooltipText={t(
                  'See %s available keywords in Keyword Discovery',
                  selectedNode?.kwICU,
                )}
                iconSlot={
                  <AccessControl>
                    <AddKeywordsIcon
                      data={selectedNode?.kwICU}
                      onClick={handleShowKeywordsModal(selectedNode?.kwICU, true)}
                      tooltipText={
                        isDemoDomain
                          ? getDisabledDemoText()
                          : t('Import %s keywords', selectedNode?.kwICU)
                      }
                      disabled={isDemoDomain}
                    />
                  </AccessControl>
                }
                loading={loading}
                filterTrackingKey={FilterTrackingKey.SiteExplorerToDiscovery}
              />
            }
          />
        </Group>
        <Space h="xxl" />
        <SectionHeader title={t('Tags for this page')} />
        <Space h="sm" />
        <TagsSection
          loading={loading}
          showAddTagModal={handleShowAddTagModal}
          includingChildren={false}
          selectedNode={selectedNode}
        />
        <Space h="xxl" />
        <SectionHeader
          title={''}
          titleTooltipSlot={
            <AccTitle type="h5" helper={dynamicCompetitorHelpTextI18n}>
              {t('Dynamic Competitor Landing Pages')}
            </AccTitle>
          }
          contentSlot={!loading && <CompetitorCertaintyIndicator selectedNode={selectedNode} />}
        />
        <Space h="sm" />
        <CompetitorTable
          isDemoDomain={isDemoDomain}
          allCompetitorsTracked={allCompetitorsTracked}
          selectedNode={selectedNode}
          loading={loading}
          handleShowKeywordsModal={handleShowKeywordsModal}
          linkToKeydis={linkToUntracked(true)}
        />
        <Space h="xl" />
      </Flex>
    </>
  );
};

function AddKeywordsIcon({
  onClick,
  tooltipText,
  data,
  disabled,
}: {
  onClick: () => void;
  tooltipText?: string;
  data: number | undefined;
  disabled?: boolean;
}) {
  return (
    <>
      {data ? (
        <AccTooltip tooltip={tooltipText}>
          <div>
            <AccActionIcon onClick={onClick} disabled={disabled}>
              <IconUpload />
            </AccActionIcon>
          </div>
        </AccTooltip>
      ) : (
        <IconUpload />
      )}
    </>
  );
}

export default SiteMappingRightColumn;
