import { useField } from 'react-final-form';
import { RadioButtonGroup, RadioButtonGroupProps } from 'Components/Controls/RadioButtonGroup';

type Props = {
  name: string;
} & Omit<RadioButtonGroupProps, 'value' | 'onChange'>;

export const RadioGroup = ({ name, ...rest }: Props) => {
  const { input } = useField(name);
  return <RadioButtonGroup value={input.value} onChange={input.onChange} {...rest} />;
};
