import { Flex } from '@mantine/core';
import AccButton from 'Components/AccButton/AccButton';
import { Field } from 'Components/Fields';
import { Form } from 'Components/Fields/Form/Form';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import AccText from 'Components/Text/AccText';
import { useDynamicTagRulesetExistsLazyQuery } from 'Ghql';
import { formatDatetime } from 'Pages/Sales/helpers';
import { EventName, useMixpanel } from 'Utilities/Analytics/mixpanel';
import { t } from 'Utilities/i18n';
import Validator from 'Utilities/validation';
import { DynamicTagFormValues } from '../../support/types';
import { BuilderTokenGroupField } from './components/BuilderTokenGroup/BuilderTokenGroupField';
import { DynamicTagsRulesCount } from './components/DynamicTagsRulesCount';
import styles from './dynamic-tags.module.scss';

interface Props {
  onSubmit: (values: DynamicTagFormValues) => Promise<Record<string, string> | undefined>;
  initialValues: DynamicTagFormValues;
  isEdit?: boolean;
  onClose: (...args: Array<any>) => any;
  createdBy: string | undefined;
  updatedAt: string | undefined;
  domainId: string;
}

export const DynamicTagsBuilder: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { domainId } = props;
  const [search] = useDynamicTagRulesetExistsLazyQuery();
  const trackEvent = useMixpanel();

  const validateTagExists = async (value) => {
    if (props.isEdit && value === props.initialValues.tag) {
      return undefined;
    }
    const { data } = await search({ variables: { tag: value, domainId: parseInt(domainId) } });
    return data?.dynamicTagRulesetExists ? t('Dynamic tag already exists.') : undefined;
  };

  const handleOnSubmit = async (values: DynamicTagFormValues) => {
    const event = props.isEdit ? EventName.EditDynamicTag : EventName.CreateDynamicTag;
    trackEvent(event, {
      Tag: values?.tag,
      Folder: values?.folder,
    });
    return props.onSubmit(values);
  };

  return (
    <Form onSubmit={handleOnSubmit} initialValues={props.initialValues}>
      {({ submitting }) => {
        return (
          <>
            <div className={styles.container}>
              <Field.TextInput
                name="tag"
                label={t('Tag Name')}
                placeholder={t('Tag name')}
                validate={[Validator.string, validateTagExists]}
                required
                autoFocus={true}
              />
              <Field.FolderInput
                name="folder"
                label={t('Folder')}
                placeholder={t('Select folder')}
                validate={[Validator.string]}
                required
              />
              <BuilderTokenGroupField name="rawRuleset" />
            </div>
            <ModalFooter
              primaryButtonSlot={
                <AccButton type="submit" variant="primary" loading={submitting}>
                  {t('Save')}
                </AccButton>
              }
              secondaryButtonSlot={
                <AccButton variant="tertiary" onClick={props.onClose}>
                  {t('Cancel')}
                </AccButton>
              }
              textSlot={
                <>
                  <DynamicTagsRulesCount domainId={domainId} />
                  {props.createdBy && props.updatedAt ? (
                    <Flex justify={'space-between'} gap={'lg'}>
                      <AccText size={'sm'} c={'gray'}>
                        {t('Tag created by: %s', props.createdBy)}
                      </AccText>
                      <AccText size={'sm'} c={'gray'}>
                        {t('Last updated at: %s', formatDatetime(props.updatedAt))}
                      </AccText>
                    </Flex>
                  ) : null}
                </>
              }
            />
          </>
        );
      }}
    </Form>
  );
};
