import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { noop } from '@mantine/core';
import { toJS } from 'mobx';
import { showModal } from 'Actions/ModalAction';
import { updateUserSettings } from 'Actions/UserAction';
import { useTableStore } from 'Components/DataTable';
import { useActions } from 'Hooks/redux/useActions';
import SpecificFilterSelector from 'Selectors/SpecificFilterSelector';
import { FilterAttribute } from 'Types/Filter';
import { TableIDs, ViewMode } from 'Types/Table';

const domainsFilterSelector = SpecificFilterSelector(FilterAttribute.DOMAINS);

export interface WithKeywordActionMenuDataProps {
  domainId?: number;
  filters?: any;
  totalSelected: number;
  manualSelectedKeywords: number[];
  isAllSelected: boolean;
  totalResults: number;
  tableSettingsColumns: any;
  featureAdvancedMetrics: any;
  viewMode: ViewMode;
  startLoading: () => void;
  showCompare?: boolean;
  actions: {
    showModal: (a: any) => any;
    updateUserSettings: (a: any) => Promise<any>;
  };
}

const MAX_VISIBLE_KEYWORDS_COMPARE = 10;

export const withKeywordActionMenuData = (Component: any) => {
  const result = (props: any) => {
    const tableStore = useTableStore(TableIDs.KEYWORDS);
    const manualSelectedKeywords: any = toJS(tableStore?.manualSelectedKeywords) ?? [];
    const isAllSelected = tableStore?.isAllSelected ?? false;
    const totalResults = tableStore?.totalDataLength ?? 0;
    const totalSelected = tableStore?.totalSelectedCount;

    const showCompare = Boolean(totalSelected && totalSelected <= MAX_VISIBLE_KEYWORDS_COMPARE);

    const startLoading = useCallback(() => {
      tableStore?.startLoading();
    }, [tableStore]);
    const { filters, domainId, featureAdvancedMetrics, viewMode } = useSelector((state: any) => {
      const domainFilter = domainsFilterSelector(state);
      const { user } = state;
      return {
        domainId: !!domainFilter && domainFilter.value.length === 1 ? domainFilter.value[0] : null,
        filters: state.filter.filterGroup.filters,
        featureAdvancedMetrics: user.organization.activePlan.featureAdvancedMetrics,
        viewMode:
          props.tableName && state.table[props.tableName]
            ? state.table[props.tableName].viewMode
            : state.table.viewMode,
      };
    });

    const actions: any = useActions({
      showModal,
      updateUserSettings,
    });
    return (
      <Component
        {...props}
        manualSelectedKeywords={manualSelectedKeywords}
        isAllSelected={isAllSelected}
        totalResults={totalResults}
        domainId={domainId}
        featureAdvancedMetrics={featureAdvancedMetrics}
        viewMode={viewMode}
        filters={filters}
        actions={actions}
        totalSelected={totalSelected}
        startLoading={startLoading}
        showCompare={showCompare}
        deselectAll={tableStore?.resetSelection ?? noop}
      />
    );
  };
  result.displayName = 'withKeywordActionMenuData';

  return result;
};
