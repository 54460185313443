import { colorScheme } from 'Utilities/colors';
import { t } from 'Utilities/i18n';
import { notEmpty } from 'Utilities/underdash';
import { OverviewChartSeries } from '../constants';
import { addLowDataPlaceholderDays } from '../support/lowData';

export type AvgRankSeries = OverviewChartSeries;

type Data = {
  avgRank?: number;
  date?: number;
  keywords?: number;
};

export const getAverageRankSeries = (data?: Data[] | null): AvgRankSeries[] => {
  const series: AvgRankSeries[] = [
    {
      name: t('Average rank'),
      primary: true,
      color: colorScheme.graphs.colors.darkBlue,
      data:
        data?.filter(notEmpty)?.map((e) => {
          return {
            x: e?.date || 0,
            y: e?.avgRank ?? 0,
            totalKeywords: e?.keywords || 0,
          };
        }) ?? [],
    },
  ];
  addLowDataPlaceholderDays(series[0]);
  return series;
};
