import { useMemo } from 'react';
import { useApolloClient } from '@apollo/client';
import uniqBy from 'lodash/uniqBy';
import { AccMultiSelect, SelectItem } from 'Components/AccSelect';
import { LandingPagesSearchDocument, LandingPagesSearchQuery, useLandingPagesQuery } from 'Ghql';
import { t } from 'Utilities/i18n';

type Props = {
  domains: string[];
  value: string[];
  onChange: (value: string[]) => void;
  showError?: boolean;
  disabled?: boolean;
};

const LandingPagesInput = (props: Props) => {
  const client = useApolloClient();
  const { data, loading } = useLandingPagesQuery({
    variables: { ids: props.value },
    skip: !props.value?.length,
  });

  const valueOptions = useMemo(
    () =>
      (data?.landingPages
        ?.map((e) => ({
          value: e?.id,
          label: e?.path,
        }))
        .filter((e) => e?.value) ?? []) as SelectItem<string>[],
    [data],
  );

  const optionsLoader = (query?: string) => {
    const variables = {
      domains: props.domains,
      query,
    };
    return client
      .query<LandingPagesSearchQuery>({
        query: LandingPagesSearchDocument,
        variables,
      })
      .then(
        ({ data: landingsData }) =>
          (landingsData.urls
            ?.map((urlData) => ({
              value: urlData?.id,
              label: urlData?.path,
            }))
            .filter((e) => e.value) || []) as SelectItem<string>[],
      )
      .then((newOptions) => uniqBy([...newOptions, ...valueOptions], 'value'));
  };

  return (
    <AccMultiSelect
      isLoading={loading}
      value={props.value}
      onChange={props.onChange}
      loadOptions={optionsLoader}
      showError={props.showError}
      disabled={props.disabled}
      loadingPlaceholderText={t('Fetching pages')}
      placeholder={t('Enter preferred URL')}
      inputMaxWidth={570}
      creatable={false}
      limit={50}
    />
  );
};

export default LandingPagesInput;
