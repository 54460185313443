import moment from 'moment';
import PageHeaderContainer from 'Components/PageHeaderContainer';
import PeriodFilter from 'Components/PeriodFilter';
import { useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import { PeriodFilterProps } from '../support/type';

export const PeriodFilterContainer = (props: PeriodFilterProps) => {
  const { domainInfo } = useQueryDomainInfo();
  // we set the min and max depending on the domainInfo.
  // we select by default last full scraped days and then use can select all non full scraped days.
  // firstRefreshAt will always have a value it will be date of last rank or date added of domain. the others cant be before this.
  const periodFilterMin = domainInfo?.firstRefreshAt;
  const periodFilterMax =
    domainInfo &&
    (moment(domainInfo.lastFullScrape).isAfter(domainInfo.firstRefreshAt, 'day')
      ? domainInfo.lastFullScrape
      : domainInfo.firstRefreshAt);
  const periodLastFullScrape =
    domainInfo &&
    (moment(domainInfo.lastFullScrapeCompleted).isAfter(domainInfo.firstRefreshAt, 'day')
      ? domainInfo.lastFullScrapeCompleted
      : domainInfo.firstRefreshAt);

  if (props.hidePeriodFilter) {
    return null;
  }

  return (
    <PageHeaderContainer>
      <PeriodFilter
        min={periodFilterMin}
        max={periodFilterMax}
        domainInfo={domainInfo}
        lastFullScrape={periodLastFullScrape}
        onlyPeriodFilter={props.onlyPeriodFilter}
      />
    </PageHeaderContainer>
  );
};
