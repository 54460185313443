import { t } from 'Utilities/i18n';

export enum AppLanguage {
  DANISH = 'da',
  ENGLISH = 'en',
}

type GetLanguageOptionsReturn = { value: string; id: string; label: string }[];

export const getLanguageOptions = (): GetLanguageOptionsReturn => [
  { value: AppLanguage.DANISH, id: AppLanguage.DANISH, label: t('Danish') },
  { value: AppLanguage.ENGLISH, id: AppLanguage.ENGLISH, label: t('English') },
];
