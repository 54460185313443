import { useDroppable } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { DraggableItemProps } from './DndBoard';
import SortableItem from './SortableItem';

const containerStyle = {
  background: '#dadada',
  padding: 10,
  flex: 1,
};

interface SortableContainerProps {
  id: string;
  items: string[];
  itemProps: DraggableItemProps;
  addColumnOptions?: string[];
}
export default function SortableContainer(props: SortableContainerProps) {
  const { id, items, itemProps } = props;

  const { setNodeRef, active } = useDroppable({
    id,
  });

  const isOver = id === active?.data?.current?.sortable?.containerId;
  return (
    <SortableContext id={id} items={items} strategy={verticalListSortingStrategy}>
      <div
        ref={setNodeRef}
        style={{
          ...containerStyle,
          background: isOver ? 'rgba(206, 208, 220, 0.38)' : 'transparent',
          overflow: 'hidden',
          paddingLeft: id === 'rightColumn' ? 12 : 10,
          paddingRight: id === 'leftColumn' ? 12 : 10,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {items.map((itemId, index) => {
          return (
            <SortableItem
              key={itemId}
              id={itemId}
              columnId={props.id}
              {...itemProps}
              index={index}
            />
          );
        })}
      </div>
    </SortableContext>
  );
}
