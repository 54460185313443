import { memo, useRef } from 'react';
import isEqual from 'lodash/isEqual';
import { ChartLoader } from 'Components/Chart/ChartLoader/ChartLoader';
import { COLUMN_CHART_HEIGHT } from 'Components/Chart/ColumnChart/support/constants';
import { ReactHighchartsChart } from 'Components/Chart/ReactHighcarts';
import Segment from 'Components/Segment';
import Watermark from 'Components/Watermark';
import { t } from 'Utilities/i18n';
import { ChartConfigProps, useChartConfig } from './support/useChartConfig';
import './serps-chart.scss';

const SERPChart = memo((props: ChartConfigProps) => {
  const chartConfig = useChartConfig(props);
  const exportContainer = useRef<HTMLDivElement>(null);
  const noAvailableData = !props.loading && !chartConfig.series?.length;

  return (
    <div
      className="serp-distribution-chart chart-container chart-content"
      ref={exportContainer}
      style={{
        height: `${COLUMN_CHART_HEIGHT}px`,
      }}
    >
      {noAvailableData ? (
        <Segment placeholder>{t('No SERP feature data for the selected filters')}</Segment>
      ) : (
        <>
          <Watermark big={props.watermarkBig || false} offset />
          <ChartLoader
            loaderType="column"
            loading={props.loading}
            containerBoxProps={{
              mb: 10,
              h: COLUMN_CHART_HEIGHT - 20,
            }}
          >
            <ReactHighchartsChart config={chartConfig} />
          </ChartLoader>
        </>
      )}
    </div>
  );
}, isEqual);

SERPChart.displayName = 'SERPChart';

export default SERPChart;
