import { useCallback } from 'react';
import { useSelector, useStore } from 'react-redux';
import isNil from 'lodash/isNil';
import { LATEST } from 'Components/PeriodFilter/model';
import { selectCurrentFilters } from 'Selectors/FilterSelector';
import { RequiredFiltersSelector } from 'Selectors/FiltersSelector';
import SpecificFilterSelector from 'Selectors/SpecificFilterSelector';
import {
  COMPARE_TO,
  DOMAINS,
  FREE_TEXT_DOMAIN,
  FilterAttribute,
  FilterAttributetype,
  FilterBase,
  FilterComparison,
  FilterValueType,
  PERIOD,
} from 'Types/Filter';

export const useFilters = () => {
  return useSelector(selectCurrentFilters);
};

const filterHash = (filters: FilterBase[]): string => {
  const str = JSON.stringify(filters);
  let hash1 = 5381;
  let hash2 = 52711;

  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash1 = (hash1 << 5) + hash1 + char;
    hash2 = (hash2 << 5) + hash2 + char;
  }

  // Combine the two hash values
  const combinedHash = (hash1 * 1566083941 + hash2) & 0x7fffffff;

  // Convert to a longer base 36 string
  const baseHash = (combinedHash >>> 0).toString(36);

  // Add more entropy by including part of the original string
  const extraEntropy = str
    .slice(0, 3)
    .split('')
    .map((c) => c.charCodeAt(0).toString(36))
    .join('');

  return baseHash + extraEntropy;
};

export const useFiltersWithHash = (): [FilterBase[], string] => {
  const f = useSelector(selectCurrentFilters);
  return [f, filterHash(f)];
};

/** alternative to useFilters, used to avoid re-rendering when filters change */
export const useGetFilters = () => {
  const store = useStore();
  return useCallback(() => selectCurrentFilters(store.getState()), [store]);
};

export const useRequiredFilters = () => {
  return useSelector(RequiredFiltersSelector);
};

export const useCompareToIsLatest = () => {
  const periodFilter = useSelector(SpecificFilterSelector(FilterAttribute.PERIOD));
  let isLatest = false;

  if (periodFilter && periodFilter.value) {
    isLatest = JSON.parse(periodFilter.value)[1] === LATEST;
  }

  return isLatest;
};

/** Use only filter domains, period and compare_to, but remove other filters. **/
export const useStandardFilters = () => {
  return useFilters().filter((x) =>
    [DOMAINS, FREE_TEXT_DOMAIN, PERIOD, COMPARE_TO].includes(x.attribute),
  );
};

export const formatFiltersWithRankChange = (filters: FilterBase[], winners): FilterBase[] => {
  const existingRankChangeFilter = filters.find((f) => f.attribute === FilterAttribute.RANK_CHANGE);

  if (existingRankChangeFilter) {
    return filters;
  }

  return [
    ...filters,
    isNil(winners)
      ? null
      : {
          attribute: FilterAttribute.RANK_CHANGE,
          comparison: winners ? FilterComparison.LT : FilterComparison.GT,
          value: 0,
          type: FilterValueType.NUMBER,
        },
  ].filter(Boolean) as FilterBase[];
};

/**Hook to select a specific filter */
export const useSpecificFilter = <T extends FilterAttributetype>(
  //hacky way to avoid type widening to string
  attribute: T,
) =>
  // Make sure typeScript returns the type of the specific filter
  useSelector(SpecificFilterSelector(attribute) ?? null) as
    | Extract<FilterBase, { attribute: T }>
    | undefined;
