import cn from 'classnames';
import Breadcrumbs from 'Components/Breadcrumbs/Breadcrumbs';
import { t, tct } from 'Utilities/i18n';
import reusableStyles from 'css/reusable-styles.module.scss';
import IntegrationCategory from './components/IntegrationCategory';
import Link from './components/Link';
import { useIntegrations } from './hooks/useIntegrations';
import './integrations.scss';

const IntegrationsPage = () => {
  const { defaultIntegrations, customIntegrations } = useIntegrations();

  const DefaultCategory = () => (
    <IntegrationCategory title={t('Standard integrations')} integrations={defaultIntegrations} />
  );

  const CustomCategory = () => (
    <IntegrationCategory
      title={t('Advanced integrations')}
      subtitle={tct(
        'These integrations utilize the [api:AccuRanker API]. API access is included in all plans.',
        {
          api: <Link href={'/api'} />,
        },
      )}
      integrations={customIntegrations}
    />
  );

  return (
    <>
      <Breadcrumbs />
      <div className={cn('integrations', reusableStyles.paperContainer)}>
        <DefaultCategory />
        <CustomCategory />
      </div>
    </>
  );
};

export default IntegrationsPage;
