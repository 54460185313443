/* eslint-disable import/no-unused-modules */
import { TreeNode } from 'Components/Table/TreeTable';

/**
 * tag[0]: n number og keywords as string
 *
 * tag[1]: tag as string
 *
 * tag[2]: dynamic
 */
export type Tag = [string, string, 0 | 1];

export interface SitemapNode extends TreeNode {
  name: string;
  /** aboveTheFoldExcludingChildrenTracked*/
  //atfECT: number;
  /** aboveTheFoldExcludingChildrenUntracked */
  // aboveTheFoldExcludingChildrenUntracked: number;
  /**aboveTheFoldIncludingChildrenTracked */
  //atfICT: number;
  /**aboveTheFoldIncludingChildrenUntracked */
  // aboveTheFoldIncludingChildrenUntracked: number;
  /** children */
  children: SitemapNode[] | null;
  /**costValueExcludingChildrenTracked */
  //cvECT: number;
  // costValueExcludingChildrenUntracked: number; // costValueExcludingChildrenUntracked
  //cvICT: number; // costValueIncludingChildrenTracked
  //costValueIncludingChildrenUntracked: number; // costValueIncludingChildrenUntracked
  endOfPath: string; // endOfPath
  /**keywordsExcludingChildrenTracked */
  kwECT: number;
  /**keywordsExcludingChildrenUntracked*/
  kwECU: number;
  /**keywordsIncludingChildrenTracked*/
  kwICT: number;
  /** keywordsIncludingChildrenUntracked */
  kwICU: number;
  /**  numberOfChildrenTracked */
  // nChildT: number;
  nChild: number;
  /** numberOfChildrenUntracked */
  // nChildU: number;
  /** pathSegment */
  pathSegment: string;
  /**searchVolumeExcludingChildrenTracked*/
  svECT: number;
  /**searchVolumeExcludingChildrenUntracked */
  svECU: number;
  /**  searchVolumeIncludingChildrenTracked */
  svICT: number;
  /**  searchVolumeIncludingChildrenUntracked */
  svICU: number;
  /**  trafficExcludingChildrenTracked */
  tECT: number;
  /**trafficExcludingChildrenUntracked */
  tECU: number;
  /**trafficIncludingChildrenTracked*/
  tICT: number;
  /**trafficIncludingChildrenUntracked*/
  tICU: number;
  /**traffic value excluding children untracked */
  tvECU: number;
  /**traffic value excluding children tracked */
  tvECT: number;
  tvICT: number;
  tvICU: number;
  /**  shareOfVoiceExcludingChildrenTracked */
  //sovECT: number;
  /** shareOfVoiceExcludingChildrenUntracked */
  // shareOfVoiceExcludingChildrenUntracked: number;
  //sovICT: number; // shareOfVoiceIncludingChildrenTracked
  /**  shareOfVoiceIncludingChildrenUntracked */
  // shareOfVoiceIncludingChildrenUntracked: number;
  /**  clickThroughRateExcludingChildrenTracked */
  ctrECT: number;
  /**  clickThroughRateExcludingChildrenUntracked */
  ctrECU: number;
  /** shareOfVoiceIncludingChildrenTracked */
  ctrICT: number;
  ctrICU: number;
  /** title */
  title: string;
  /** url*/
  url: string;
  /**
   * tag[0]: n number og keywords as string
   *
   * tag[1]: tag as string
   *
   * tag[2]: dynamic
   */
  tagsEC: Tag[]; // allTagsWithCounts
  /**
   * tag[0]: n number of keywords as string
   *
   * tag[1]: tag as string
   *
   * tag[2]: dynamic
   */
  tagsIC: Tag[]; // allTagsWithCounts;
  /**
   * Depth in the three.
   *
   * Root is 0, children: 1, grandChildren: 2, etc.
   */
  depth: number;
}

export interface CompetitorDomains {
  unknown_competitor_url: string;
  unknown_competitor_domain: string;
  score: number;
  n_matching_keywords: number;
  discovered_keywords: number;
  search_volume_value: number;
  dynamic_ctr_value: number;
  organic_traffic: number;
  organic_traffic_value: number;
  is_own_domain: boolean;
}

export interface PathToCompetitorDomains {
  [path: string]: CompetitorDomains[];
}

export interface UnknownCompetitorUrl {
  n_matching_keywords: number;
  url: string;
}

type DomainAndUrls = {
  domain: string;
  urls: string[];
};

export type DomainsAndUrls = Array<DomainAndUrls>;

export interface UntrackedCompetitorUrl {
  keywords: number;
  url: string;
}
export interface SingleCompetitorUntrackedData {
  keywords: number;
  urls: UntrackedCompetitorUrl[];
}
export interface AllCompetitorsUntrackedData {
  [competitorDomain: string]: SingleCompetitorUntrackedData;
}

export enum TreeOptionOrientation {
  Horizontal = 'Horizontal',
  Vertical = 'Vertical',
}

// eslint-disable-next-line no-confusing-arrow
export const toggleOrientation = (orientation: TreeOptionOrientation): TreeOptionOrientation =>
  orientation === TreeOptionOrientation.Horizontal
    ? TreeOptionOrientation.Vertical
    : TreeOptionOrientation.Horizontal;

export enum SiteMapMode {
  TRACKED_AND_UNTRACKED = 'tracked_and_untracked',
  TRACKED_ONLY = 'tracked_only',
}

export type SiteMapViewMode = {
  id: SiteMapMode;
  name: JSX.Element;
};

export enum TreeMode {
  TREE = 'tree',
  TABLE = 'table',
}

export type TreeViewMode = {
  id: string;
  name: JSX.Element;
};

/**Returns a color code */
export type ColorMap = (volume: number, totalVolume: number) => string;
