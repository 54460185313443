import { useMemo } from 'react';
import { ApolloError } from '@apollo/client';
import { useHistoryChartsQuery } from 'Ghql';
import { useFilters } from 'Hooks';
import { useDeepMemo } from 'Hooks/useDeep';
import { CHART_NAMES } from 'Pages/Keywords/Overview/components/ChartBox';
import { getAverageCtrSeries } from 'Pages/Keywords/Overview/components/HistoryCharts/AverageCTR/helpers';
import { getAverageRankSeries } from 'Pages/Keywords/Overview/components/HistoryCharts/AverageRank/helpers';
import { getEstVisitsSeries } from 'Pages/Keywords/Overview/components/HistoryCharts/EstimatedVisits/helpers';
import { getSovChartSeries } from 'Pages/Keywords/Overview/components/HistoryCharts/ShareOfVoice/helpers';
import {
  HISTORY_CHARTS_TO_BACKEND_NAME,
  OverviewChartSeries,
} from 'Pages/Keywords/Overview/components/HistoryCharts/constants';
import { FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import { useSmartQuery } from 'Utilities/Graphql/hooks/useSmartQuery';
import { useDomainContext } from '../components/DomainCard/components/DomainContext';

const CHARTS = [
  CHART_NAMES.AVERAGE_RANK,
  CHART_NAMES.SHARE_OF_VOICE,
  CHART_NAMES.ESTIMATED_VISITS,
  CHART_NAMES.AVERAGE_CTR,
];
const useFetchChartsData = (): {
  sovSeries: OverviewChartSeries[];
  avgCtrSeries: OverviewChartSeries[];
  avgRankSeries: OverviewChartSeries[];
  avgEstVisitsSeries: OverviewChartSeries[];
  loading: boolean;
  error?: ApolloError | undefined;
} => {
  const { id } = useDomainContext();
  const globalFilters = useFilters();
  const filters = useMemo(() => {
    let result = [...globalFilters];
    if (id) {
      result = result.filter((filter) => filter.attribute !== FilterAttribute.DOMAINS);
      result.push({
        attribute: FilterAttribute.DOMAINS,
        type: FilterValueType.LIST,
        comparison: FilterComparison.CONTAINS,
        value: [id.toString()],
      });
    }
    return result;
  }, [globalFilters, id]);
  const charts = useMemo(
    () =>
      CHARTS.map((chart) => HISTORY_CHARTS_TO_BACKEND_NAME[chart]).sort((a, b) =>
        a.localeCompare(b),
      ),
    [],
  );
  const { data, loading, error } = useSmartQuery(useHistoryChartsQuery, {
    variables: { filters, name: 'historyCharts', charts },
    skip: !id,
  });

  const { sovSeries, avgCtrSeries, avgRankSeries, avgEstVisitsSeries } = useDeepMemo(() => {
    const graphData = data?.graphs?.overviewHistoryGraphs;
    return {
      sovSeries: getSovChartSeries(graphData),
      avgCtrSeries: getAverageCtrSeries(graphData),
      avgRankSeries: getAverageRankSeries(graphData),
      avgEstVisitsSeries: getEstVisitsSeries(graphData),
    };
  }, [data]);

  return {
    sovSeries,
    avgCtrSeries,
    avgRankSeries,
    avgEstVisitsSeries,
    loading,
    error,
  };
};
export default useFetchChartsData;
