import { useResizeObserver } from '@mantine/hooks';
import uniqueId from 'lodash/uniqueId';
import WatermarkImage from 'icons/logo-watermark.svg?inline';

const watermarkHeight = 40; // Used for rotated calc

const spaceBetweenWatermarks = 120;

const smallImageStyle = {
  width: '100px',
  height: 'auto',
  position: 'absolute' as React.CSSProperties['position'],
  right: '8px',
  bottom: '32px',
  opacity: '0.4',
};

const MultipleWaterMarks = ({ cutNumber, offset }: Omit<WaterMarkProps, 'big'>) => {
  const [ref, rect] = useResizeObserver();

  const containerWidth = rect.width;

  const createRotatedWatermarkStyle = (iteration: number, left: number) => ({
    width: '120px',
    height: `${watermarkHeight}px`,
    position: 'absolute' as React.CSSProperties['position'],
    left: iteration * left,
    top: '35%',
    opacity: '0.45',
    pointerEvents: 'none' as React.CSSProperties['pointerEvents'],
    transform: 'rotate(-50deg)',
  });

  const RenderMultipleWaterMarks = () => {
    const fullWatermarkSize = watermarkHeight + spaceBetweenWatermarks;
    const totalWatermarks = Math.ceil(
      (containerWidth - (cutNumber || 0) * fullWatermarkSize) / fullWatermarkSize,
    );

    return totalWatermarks > 0
      ? [...Array(totalWatermarks)].map((_, iteration) => {
          return (
            <WatermarkImage
              key={uniqueId('watermark')}
              style={createRotatedWatermarkStyle(
                offset ? iteration + 1 : iteration,
                fullWatermarkSize,
              )}
            />
          );
        })
      : null;
  };

  return (
    <span ref={ref} style={{ width: '100%', height: '100%', position: 'absolute' }}>
      {containerWidth ? RenderMultipleWaterMarks() : null}
    </span>
  );
};

type WaterMarkProps = {
  big: boolean;
  offset?: boolean;
  cutNumber?: number;
};

const WaterMark = ({ big, offset = false, cutNumber = 2 }: WaterMarkProps) => {
  return (
    <>
      {big ? (
        <MultipleWaterMarks offset={offset} cutNumber={cutNumber} />
      ) : (
        <WatermarkImage style={smallImageStyle} />
      )}
    </>
  );
};

export default WaterMark;
