import AccTooltip from 'Components/AccTooltip/AccTooltip';
import TextInput from 'Components/Controls/TextInput';
import { Field } from 'Components/Fields';
import AccText from 'Components/Text/AccText';
import { UnrankedWeightConstants } from 'Constants';
import { t } from 'Utilities/i18n';
import { FieldName } from '..';
import { AverageRankSettingsProps } from '.';

type DisabledWeightProps = { isEnabled: boolean; isAccountDefault: boolean } & Pick<
  AverageRankSettingsProps,
  'organizationUnrankedWeight'
>;

export const DisabledWeight = ({
  isEnabled,
  isAccountDefault,
  organizationUnrankedWeight,
}: DisabledWeightProps) => {
  const value = isEnabled ? organizationUnrankedWeight : '';
  return (
    <AccTooltip
      tooltip={
        isAccountDefault
          ? isEnabled
            ? t('Controlled by account settings')
            : t(
                '"Not in top 100" rank keywords are not included in average rank calculation because of the above setting and the account setting',
              )
          : t(
              '"Not in top 100" rank keywords are not included in average rank calculation because of the above setting',
            )
      }
    >
      <TextInput
        type="text"
        disabled
        value={value}
        showError={false}
        placeholder={'N/A'}
        boxProps={{
          w: 200,
          h: 42,
        }}
        inheritWidthHeight
      />
    </AccTooltip>
  );
};

export const EnabledWeight = () => {
  return (
    <Field.TextInput
      isNumber
      min={UnrankedWeightConstants.MIN_UNRANKED_WEIGHT}
      max={UnrankedWeightConstants.MAX_UNRANKED_WEIGHT}
      name={FieldName.unrankedWeight}
      boxProps={{
        h: 42,
        w: 200,
      }}
      inheritWidthHeight
      fieldFormOnlyLabel
    />
  );
};

export const IncludeUnrankedHelperText = () => {
  return (
    <AccText size="xs">
      {t(
        'This settings controls if "Not in top 100" rank keywords are included in the average rank calculation. You can set it to "Yes" or "No" to override the account setting for this domain.',
      )}
    </AccText>
  );
};

export const UnrankedWeightHelperText = () => {
  return (
    <AccText size="xs">
      {t(
        'This setting controls how much "Not in top 100" rank keywords will affect the average rank calculation. If you set the value higher, these keywords will have more impact on the average rank.',
      )}
    </AccText>
  );
};
