import * as React from "react";
const SvgCursorClick = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", x: "0px", y: "0px", width: "24px", height: "24px", viewBox: "0 0 24 24", style: {
  enableBackground: "new 0 0 24 24"
}, xmlSpace: "preserve", ...props }, /* @__PURE__ */ React.createElement("g", { id: "Frame_-_24px" }, /* @__PURE__ */ React.createElement("rect", { y: 1e-3, style: {
  fill: "none"
}, width: 24, height: 24 })), /* @__PURE__ */ React.createElement("g", { id: "Line_Icons" }, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#a4a6b3"
}, d: "M20.414,19.001l-2.707-2.707l1.939-1.939c0.194-0.194,0.135-0.408-0.131-0.475l-7.03-1.758 c-0.267-0.066-0.431,0.098-0.364,0.365l1.758,7.029c0.066,0.267,0.28,0.326,0.475,0.131l1.939-1.94L19,20.416L20.414,19.001z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#a4a6b3"
}, d: "M10,6.001c2.205,0,4,1.794,4,4h2c0-3.309-2.691-6-6-6s-6,2.691-6,6c0,3.309,2.691,6,6,6v-2 c-2.205,0-4-1.794-4-4S7.795,6.001,10,6.001z" }))));
export default SvgCursorClick;
