import { useMemo } from 'react';
import { Grid } from '@mantine/core';
import { Field } from 'Components/Fields';
import { t } from 'Utilities/i18n';
import Validator from 'Utilities/validation';
import { FIELD_NAMES } from '..';

type Props = {
  isSocialSignup?: boolean;
};

type KeywordCount = {
  label: string;
  value: string;
};

const CompanyInfo = ({ isSocialSignup }: Props) => {
  const numberOfKeywordsOptions: KeywordCount[] = useMemo(() => {
    return [
      {
        label: t('Less than 1,000'),
        value: '0-1000',
      },
      {
        label: t('1,000 - 5,000'),
        value: '1000-5000',
      },
      {
        label: t('5,000 - 10,000'),
        value: '5000-10000',
      },
      {
        label: t('10,000 - 50,000'),
        value: '10000-50000',
      },
      {
        label: t('More than 50,000'),
        value: '50000+',
      },
      {
        label: t('Not tracking today'),
        value: '0',
      },
    ];
  }, []);

  return (
    <>
      <Grid gutter={'xs'} align="flex-end">
        <Grid.Col span={{ base: 12, md: 6 }}>
          <Field.TextInput
            positionErrorBelow
            label={t('Company name')}
            labelClassname="required"
            name={FIELD_NAMES.ORG_NAME}
            id={FIELD_NAMES.ORG_NAME}
            elementType="input"
            type="text"
            placeholder={t('Enter your company name')}
            required
            hideRequiredStar
            validate={[Validator.required, Validator.isNotObviouslyFakeCompany]}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6 }}>
          <Field.TextInput
            positionErrorBelow
            label={t('Company domain')}
            labelClassname="required"
            name={FIELD_NAMES.ORG_URL}
            id={FIELD_NAMES.ORG_URL}
            elementType="input"
            type="text"
            placeholder={t('Enter your company domain (e.g. example.com)')}
            autoComplete="url"
            required
            hideRequiredStar
            validate={[Validator.isUrl, Validator.isNotObviouslyFakeCompany]}
          />
        </Grid.Col>
      </Grid>

      <Field.Select
        label={t('How many keywords do you track today?')}
        name={FIELD_NAMES.KEYWORD_COUNT}
        id={FIELD_NAMES.KEYWORD_COUNT}
        searchable={false}
        placeholder={t('Please select')}
        options={numberOfKeywordsOptions}
        required
        hideRequiredStar
        validate={Validator.required}
      />

      {isSocialSignup || (
        <Field.Password
          positionErrorBelow
          label={t('Password')}
          name={FIELD_NAMES.PASSWORD}
          id={FIELD_NAMES.PASSWORD}
          placeholder={t('Enter your password')}
          autoComplete="off"
          required
          hideRequiredStar
          popoverProps={{ position: 'top' }}
          validate={[
            Validator.required,
            Validator.passwordNumber,
            Validator.passwordLetter,
            Validator.passwordMinLen,
            Validator.passwordMaxLen,
            Validator.passwordPwned,
          ]}
        />
      )}
    </>
  );
};

export default CompanyInfo;
