/* eslint-disable no-unused-vars,arrow-parens,no-console */
import { CHANGE_LANGUAGE, DISABLE_LOCALE_DEBUG, ENABLE_LOCALE_DEBUG } from 'Actions/UserAction';
import { setLocale, setLocaleDebug } from 'Utilities/i18n';

export default () => (next) => (action) => {
  if (action.type === CHANGE_LANGUAGE) {
    setLocale(action.language);
  } else if (action.type === ENABLE_LOCALE_DEBUG) {
    setLocaleDebug(true);
  } else if (action.type === DISABLE_LOCALE_DEBUG) {
    setLocaleDebug(false);
  }

  next(action);
};
