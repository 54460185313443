import { useMantineTheme } from '@mantine/core';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import { AccFastIcon } from 'Components/AccFastIcon';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import AccessControl from 'Components/AccessControl';
import {
  KEYWORDS_PAGE,
  LinkIconWithFolderFilter,
  OVERVIEW_PAGE,
} from 'Components/Filters/LinkWithFolders/linkWithFolders';
import { ProcessedFolderTreeNode } from 'Pages/Domains/TreeView/support/types';
import { getDomainId, getFolderTagPath } from 'Utilities/generateSelectId';
import { t } from 'Utilities/i18n';
import { IconEdit, IconTags, IconTrash } from 'icons/tag-cloud';
import { useTagActions } from './useTagActions';

type TagActionsProps = {
  rootNode: ProcessedFolderTreeNode;
  showAction: boolean;
  isDemoDomain: boolean;
};

export const TagActions = ({ rootNode, showAction, isDemoDomain }: TagActionsProps) => {
  const theme = useMantineTheme();
  const iconColor = theme.colors.snorlax[4];
  const {
    handleEditDynamicTag,
    handleCloneDynamicTag,
    handleDeleteDynamicTag,
    handleEditStaticTag,
    handleDeleteStaticTag,
  } = useTagActions(rootNode);
  const tagPath = getFolderTagPath(rootNode.path);
  const domainId = getDomainId(rootNode.path);
  if (!showAction || !tagPath) return null;
  const isDynamic = !!rootNode.is_dynamic;
  const onEdit = isDynamic ? handleEditDynamicTag : handleEditStaticTag;
  const onClone = isDynamic ? handleCloneDynamicTag : () => {};
  const onDelete = isDynamic ? handleDeleteDynamicTag : handleDeleteStaticTag;

  return (
    <>
      <LinkIconWithFolderFilter
        path={tagPath}
        isFolder={false}
        name={rootNode.name}
        pageToGoTo={OVERVIEW_PAGE}
        tooltipText={t('Go to dashboard and filter by tag')}
        domainId={domainId}
      />
      <LinkIconWithFolderFilter
        path={tagPath}
        isFolder={false}
        name={rootNode.name}
        pageToGoTo={KEYWORDS_PAGE}
        tooltipText={t('Go to keywords list and filter by tag')}
        domainId={domainId}
      />
      <AccessControl>
        {!isDemoDomain && (
          <>
            <AccTooltip tooltip={isDynamic ? t('Edit dynamic tag') : t('Edit static tag')} old>
              <AccActionIcon variant="subtle" onClick={onEdit}>
                <AccFastIcon src={IconEdit} size={18} color={iconColor} />
              </AccActionIcon>
            </AccTooltip>
            {isDynamic && (
              <AccTooltip tooltip={t('Clone dynamic tag')} old>
                <AccActionIcon variant="subtle" onClick={onClone}>
                  <AccFastIcon src={IconTags} size={18} color={iconColor} />
                </AccActionIcon>
              </AccTooltip>
            )}
            <AccTooltip tooltip={isDynamic ? t('Delete dynamic tag') : t('Delete static tag')} old>
              <AccActionIcon variant="subtle" onClick={onDelete}>
                <AccFastIcon src={IconTrash} size={18} color={iconColor} />
              </AccActionIcon>
            </AccTooltip>
          </>
        )}
      </AccessControl>
    </>
  );
};
