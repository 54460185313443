import React, { PropsWithChildren, ReactElement, createContext } from 'react';
import { useDragDropManager } from 'react-dnd';
import {
  DropOptions,
  TreeProps,
  TreeState,
} from 'Pages/Keywords/Groupings/EditMode/react-dnd-treeview/types';
import {
  getTreeItem,
  mutateTree,
} from 'Pages/Keywords/Groupings/EditMode/react-dnd-treeview/utils';
import { usePlaceholderContext } from './PlaceholderProvider';

type Props<T> = PropsWithChildren<TreeProps<T> & {}>;

export const TreeContext = createContext({});

export const TreeProvider = <T,>(props: Props<T> & { children: any }): ReactElement => {
  const placeholderContext = usePlaceholderContext();
  const monitor = useDragDropManager().getMonitor();
  const canDropCallback = props.canDrop;
  const canDragCallback = props.canDrag;

  const value: TreeState<T> = {
    extraAcceptTypes: [],
    listComponent: 'ul',
    listItemComponent: 'li',
    placeholderComponent: 'li',
    insertDroppableFirst: true,
    enableAnimateExpand: false,
    dropTargetOffset: 0,
    initialOpen: false,
    expandedNodes: [],
    ...props,
    onDrop: (dragSource, dropTargetId, placeholderIndex, direction) => {
      // if dragSource is null,
      // it means that the drop is from the outside of the react-dnd.
      if (!dragSource) {
        const options: DropOptions<T> = {
          dropTargetId,
          dropTarget: getTreeItem<T>(props.tree, dropTargetId),
          monitor,
          direction,
        };

        props.onDrop(props.tree, options, placeholderContext);
      } else {
        const options: DropOptions<T> = {
          dragSourceId: dragSource.id,
          dropTargetId,
          dragSource,
          dropTarget: getTreeItem<T>(props.tree, dropTargetId),
          monitor,
          direction,
          index: placeholderIndex,
        };

        let tree = props.tree;

        // If the dragSource does not exist in the tree,
        // it is an external node, so add it to the tree
        if (!getTreeItem(tree, dragSource.id)) {
          tree = [...tree, dragSource];
        }

        props.onDrop(mutateTree<T>(tree, dragSource.id, dropTargetId), options, placeholderContext);
      }
    },
    canDrop: canDropCallback
      ? (dragSourceId, dropTargetId) =>
          canDropCallback(
            props.tree,
            {
              dragSourceId,
              dropTargetId,
              dragSource: monitor.getItem(),
              dropTarget: getTreeItem(props.tree, dropTargetId),
              monitor,
              direction: undefined,
            },
            placeholderContext,
          )
      : undefined,
    canDrag: canDragCallback ? (id) => canDragCallback(getTreeItem(props.tree, id)) : undefined,
  };

  return <TreeContext.Provider value={value}>{props.children}</TreeContext.Provider>;
};
