import { Component } from 'react';
import { connect } from 'react-redux';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import compose from 'lodash/flowRight';
import { showModal } from 'Actions/ModalAction';
import AccButton from 'Components/AccButton/AccButton';
import LoadingSpinner from 'Components/LoadingSpinner';
import { t } from 'Utilities/i18n';
import PaymentWidget from '../../PaymentWidget/index';

type Props = {
  className?: string;
  updatePaymentMethod: (...args: Array<any>) => any;
  showModal: (...args: Array<any>) => any;
  onBack?: (...args: Array<any>) => any;
  onUpdate?: (...args: Array<any>) => any;
  buttonText?: string;
  threeDPriceData: Record<string, any>;
  companyInfoData?: any;
};
type State = {
  braintreeUniqueId: any;
  formSubmitting: boolean;
  braintreeInstance: any;
  threeDSecurePrice: any;
  showVatFields?: any;
  threeDSecure?: number;
};

class UpdatePaymentMethodWidget extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      showVatFields: true,
      formSubmitting: false,
      braintreeUniqueId: +new Date(),
      braintreeInstance: false,
      threeDSecurePrice: 1,
    };
  }

  createBraintreeInstance = (instance) => {
    this.setState({
      braintreeInstance: instance,
    });
  };

  componentDidUpdate(prevProps: Props) {
    const loading = this.props.threeDPriceData?.loading;
    if (!loading && loading !== prevProps?.threeDPriceData?.loading) {
      this.setState({
        threeDSecurePrice:
          this.props.threeDPriceData.user?.organization?.activePlan?.calculateThreedSecurePrice ||
          1.0,
      });
    }
  }

  handleSubmit = () => {
    const { onUpdate } = this.props;
    this.state.braintreeInstance.requestPaymentMethod(
      {
        threeDSecure: {
          challengeRequested: true,
          amount: this.state.threeDSecurePrice,
        },
      },
      (braintreeError, payload) => {
        if (braintreeError) {
          return;
        }

        this.setState({
          formSubmitting: true,
        });
        this.props
          .updatePaymentMethod({
            variables: {
              paymentNonce: payload.nonce,
            },
          })
          .then(
            ({
              data: {
                setPaymentMethod: { error, success },
              },
            }) => {
              if (!success) {
                this.setState({
                  formSubmitting: false,
                });
                this.props.showModal({
                  modalType: 'GeneralError',
                  modalProps: {
                    title: t('We could not update your payment information'),
                    errorType: error,
                    link: '/billing/',
                  },
                });
                return;
              }

              onUpdate && onUpdate();
            },
          );
      },
    );
  };
  handleBack = () => {
    const { onBack } = this.props;
    onBack && onBack();
    this.setState({
      formSubmitting: true, // Set the state here because in some cases we wait 5 seconds before returning
    });
  };

  render() {
    const { onBack, className, companyInfoData, threeDPriceData } = this.props;
    const { formSubmitting, braintreeInstance, threeDSecure } = this.state;
    const loadingSpinner = formSubmitting ? <LoadingSpinner /> : '';

    if (companyInfoData.loading || threeDPriceData.loading) {
      return <LoadingSpinner />;
    }

    return (
      <form className={className}>
        <PaymentWidget
          braintreeThreeDSecureAmount={threeDSecure || 0}
          braintreeVaultManager={false}
          standalone
          onCreate={this.createBraintreeInstance}
          uniqueid={this.state.braintreeUniqueId}
          country={this.props.companyInfoData.paymentContact.country.id}
        />
        <div className="text-right confirmation-button-wrapper">
          {loadingSpinner}
          {onBack && (
            <AccButton variant="tertiary" onClick={this.handleBack} disabled={formSubmitting}>
              {t('Back')}
            </AccButton>
          )}
          <AccButton
            disabled={formSubmitting || !braintreeInstance}
            variant="primary"
            onClick={this.handleSubmit}
          >
            {this.props.buttonText ? this.props.buttonText : t('Update')}
          </AccButton>
        </div>
      </form>
    );
  }
}

const updatePaymentMethodMutation = gql`
  mutation updatePaymentMethodWidget_updatePaymentMethod($paymentNonce: String!) {
    setPaymentMethod(paymentNonce: $paymentNonce) {
      success
      error
    }
  }
`;
const threeDSecurePriceQuery = gql`
  query updatePaymentMethodWidget_threeDSecurePriceQuery {
    user {
      id
      organization {
        id
        activePlan {
          id
          calculateThreedSecurePrice
        }
      }
    }
  }
`;
const companyInfoQuery = gql`
  query updatePaymentMethodWidget_paymentContact {
    paymentContact {
      id
      country {
        id
      }
    }
  }
`;
export default compose(
  connect(null, {
    showModal,
  }),
  graphql(updatePaymentMethodMutation, {
    name: 'updatePaymentMethod',
  }),
  graphql(threeDSecurePriceQuery, {
    name: 'threeDPriceData',
    options: () => ({
      fetchPolicy: 'network-only',
    }),
  }),
  graphql(companyInfoQuery, {
    name: 'companyInfoData',
    options: () => ({
      fetchPolicy: 'network-only',
    }),
  }),
)(UpdatePaymentMethodWidget);
