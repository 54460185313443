import { IconSquare } from '@tabler/icons-react';
import { clearEverything } from 'Actions/ResetAction';
import AccButton from 'Components/AccButton/AccButton';
import { useActions } from 'Hooks/redux/useActions';
import { t } from 'Utilities/i18n';
import { redirectToExternalUrl } from 'Utilities/underdash';

type Props = {
  collapsed?: boolean;
};

const StopImpersonating = (props: Props) => {
  const { collapsed } = props;

  const actions = useActions({ clearEverything });

  const handleButtonClick = () => {
    actions.clearEverything();
    redirectToExternalUrl('/accuranker_admin/impersonate/stop/');
  };

  return (
    <AccButton
      fullWidth
      radius={6}
      onClick={handleButtonClick}
      variant="destructive"
      p={collapsed ? '0 !important' : undefined}
    >
      {collapsed ? <IconSquare size={18} /> : t('Stop impersonating')}
    </AccButton>
  );
};

export default StopImpersonating;
