import { ChangeEvent, FocusEvent, ReactNode } from 'react';
import { useField } from 'react-final-form';
import { Box, PopoverProps } from '@mantine/core';
import Checkbox, { CheckboxKind, DefaultChecked } from 'Components/Controls/Checkbox';
import HelpTextPopover from 'Components/HelpTextPopover/HelpTextPopover';
import Hint from 'Components/Hint';
import AccText from 'Components/Text/AccText';

interface FieldCheckboxProps {
  name: string;
  label?: string | ReactNode;
  /**
   * "Indenterminate" is a partly selected state.
   *
   * Read more
   * {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/checkbox#indeterminate_state_checkboxes|mdn web docs}
   */
  defaultChecked?: DefaultChecked;
  hint?: string;
  helpTextPopover?: React.ReactNode;
  helpTextPopoverProps?: Omit<PopoverProps, 'children'>;
  kind?: CheckboxKind;
  disabled?: boolean;
  validate?: (values: any) => string | undefined | null;
  customOnChange?: (input: any) => void;
}

export const FieldCheckbox = ({
  name,
  label,
  defaultChecked = false,
  disabled = false,
  hint,
  kind,
  helpTextPopover,
  helpTextPopoverProps,
  validate,
  customOnChange,
}: FieldCheckboxProps) => {
  const initialValue = defaultChecked ? { initialValue: defaultChecked } : {};

  const { input } = useField(name, {
    type: 'checkbox',
    validate,
    ...initialValue,
  });

  // used to handle react-final-forms with validateOnBlur
  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { onChange } = input;
    onChange(event);
    customOnChange && customOnChange(event.target);
  };

  return (
    <Checkbox
      checked={input.checked}
      onChange={handleOnChange}
      onBlur={(event) => input.onBlur(event as FocusEvent<HTMLInputElement>)}
      disabled={disabled}
      defaultChecked={defaultChecked}
      kind={kind}
      name={input.name}
    >
      <AccText size="sm" inactive={disabled}>
        {label}
      </AccText>
      {hint && <Hint help={hint} />}
      {helpTextPopover && (
        <Box ml={8}>
          <HelpTextPopover text={helpTextPopover} {...helpTextPopoverProps} />
        </Box>
      )}
    </Checkbox>
  );
};
