import { useCallback } from 'react';
import { useBuildDomainUpdateDomainMutation } from 'Ghql';
import { extractFormErrors } from 'Hooks/Graphql/helpers';
import { useModal } from 'Hooks/base/useModal';
import toast from 'Hooks/useToast';
import { LocaleDataSearchEngine } from 'Utilities/Common/search-engines';
import { t } from 'Utilities/i18n';
import { SoMePlatform } from '../../../../../SoMeFormFields/SocialMediaIcons';

type Locale = {
  countrylocale: string;
  locations: string[];
  searchEngines: LocaleDataSearchEngine[];
  settings: {
    enableAutocorrect: boolean;
    ignoreFeaturedSnippet: boolean;
    ignoreLocalResults: boolean;
  };
};

type FormStateValues = {
  domain: string;
  displayName: string;
  defaultSearchLocales: Locale[];
  belongsToGroup: string;
  googleBusinessNameList: string[];
  includeSubdomains: boolean | undefined;
  exactMatch: boolean | undefined;
  shareOfVoicePercentage: boolean | undefined;
  paused?: boolean;
  [SoMePlatform.X]?: string;
  [SoMePlatform.FACEBOOK]?: string;
  [SoMePlatform.TIKTOK]?: string;
  [SoMePlatform.INSTAGRAM]?: string;
  [SoMePlatform.LINKEDIN]?: string;
};

type SubmitDomainProps = {
  primarySearchEngine: string | undefined;
  domainId?: string;
  onClose?: (...args: Array<any>) => void;
  onRefresh?: () => void;
};

/**
 * Submit- and changeLocale functions for `<AddDomainForm>`
*
* @example
* const { handleSubmit, handleChangeLocale } = useAddDomainForm({
  *  primarySearchEngine,
  mode,
  countrylocalesData,
  user,
  tableStore,
  * });
  */
// eslint-disable-next-line import/no-unused-modules
export const useUpdateDomain = (props: SubmitDomainProps) => {
  const { domainId, primarySearchEngine, onClose, onRefresh } = props;
  const { hideModal } = useModal();

  const [updateDomain] = useBuildDomainUpdateDomainMutation();

  const handleSubmit = useCallback(
    (values: FormStateValues) => {
      const {
        domain,
        displayName,
        defaultSearchLocales,
        belongsToGroup,
        googleBusinessNameList,
        x_handle,
        facebook_profile,
        tiktok_profile,
        instagram_profile,
        linkedin_profile,
        includeSubdomains,
        exactMatch,
        shareOfVoicePercentage,
        paused,
      } = values;
      const updateDomainInput = {
        id: domainId || '',
        domain,
        displayName: displayName || '',
        client: belongsToGroup,
        googleBusinessNameList: googleBusinessNameList || [],
        twitterHandle: x_handle || '',
        facebookProfile: facebook_profile || '',
        tiktokProfile: tiktok_profile || '',
        instagramProfile: instagram_profile || '',
        linkedinProfile: linkedin_profile || '',
        includeSubdomains: includeSubdomains ?? true,
        exactMatch: exactMatch ?? false,
        shareOfVoicePercentage: shareOfVoicePercentage ?? false,
        paused: paused || false,
        defaultSearchsettings: defaultSearchLocales.map((locale) => ({
          countrylocale: locale.countrylocale,
          locations: locale.locations.filter((loc) => !!loc?.length),
          searchEngines: locale.searchEngines
            .filter(({ searchTypes }) => !!searchTypes.length)
            .map(({ id, searchTypes }) => ({
              searchEngine: id,
              searchTypes: searchTypes.map((searchType) => searchType.toString()),
            })),
          primary: locale.countrylocale === primarySearchEngine,
          ...locale.settings,
        })),
      };

      return updateDomain({
        variables: {
          updateDomainInput,
        },
      }).then((res) => {
        const { errors } = res?.data?.updateDomain || {};

        if (errors && errors.length) {
          toast.error(errors[0]?.messages[0] || t('Something went wrong'));
          return extractFormErrors(res);
        }

        toast.success(t('Domain updated'));
        hideModal();
        onRefresh?.();
        onClose?.();
      });
    },
    [domainId, primarySearchEngine, onClose, onRefresh, updateDomain],
  );

  return handleSubmit;
};
