import * as React from 'react';
import { FormName } from 'redux-form';

function withFormName<InputProps extends {}>(
  Component: React.ComponentType<
    React.PropsWithChildren<
      InputProps & {
        formName: string;
      }
    >
  >,
): React.ComponentType<React.PropsWithChildren<InputProps>> {
  const WithFormName = function (props: InputProps) {
    return <FormName>{({ form }) => <Component {...props} formName={form} />}</FormName>;
  };

  return WithFormName;
}

export default withFormName;
