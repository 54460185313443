import { Box } from '@mantine/core';
import AccessControl from 'Components/AccessControl';
import AddDomain from './AddDomain';
import DomainCard from './DomainCard';
import { DomainContextNode } from './DomainCard/components/DomainContext';
import DomainCardLoader from './DomainCardLoader';
import NoDomains from './NoDomains';
import styles from '../cardview.module.scss';

type Props = {
  data: DomainContextNode[];
  pageSize: number;
  selectedGroup: number;
  loading: boolean;
};

const DomainsList = ({ data, pageSize, selectedGroup, loading }: Props) => {
  if (loading || !pageSize) {
    return (
      <Box className={styles.groupGrid}>
        {Array.from({ length: pageSize || 1 }, (_, index) => (
          <DomainCardLoader key={index} />
        ))}
      </Box>
    );
  }

  if (!data?.length) {
    return <NoDomains />;
  }

  return (
    <Box className={styles.groupGrid}>
      {data.map((item) => (
        <DomainCard key={item.id} domainNode={item} />
      ))}
      {data.length < pageSize && (
        <AccessControl>
          <AddDomain groupId={selectedGroup} />
        </AccessControl>
      )}
    </Box>
  );
};

export default DomainsList;
