import AccLazyPopover from 'Components/AccLazyPopover/AccLazyPopover';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import Top10DomainsLazy from 'Components/AccuTable/CellRenderer/HelperComponents/Top10DomainsPopover/top10Domains';
import { useRowContext } from 'Components/DataTable';
import { t } from 'Utilities/i18n';
import FormatNumber from '../../FormatNumber/new';
import Cell from './HelperComponents/AccuCell';

type Props = {
  value: {
    data: {
      dynamicCtrMax: number;
      updatingKeyword: boolean;
      baseRank: number;
    };
    loadingData: boolean;
  };
};

const MaxCTRCell = (props: Props) => {
  const { inView } = useRowContext();
  const {
    value: { data },
  } = props;
  const {
    dynamicCtrValue,
    dynamicCtrMax,
    rankId,
    id: keywordId,
    baseRank,
    searchEngineId,
    searchDate,
    rankValue,
  }: any = data;

  if (rankId === 0 || !dynamicCtrMax) {
    return null;
  }

  const isKeydis =
    window.location.href.includes('keyword_discovery') || window.location.href.includes('research');

  // Popover not available in Keydis as backend does not have that data
  if (isKeydis) {
    return (
      <Cell rightAligned>
        <FormatNumber value={Math.max(dynamicCtrMax, dynamicCtrValue)} style="percent" />
      </Cell>
    );
  }

  const trigger = (
    <AccTooltip tooltip={t('Show top 10 CTRs')}>
      {/* span is needed for the tooltip to work */}
      <span>
        <FormatNumber
          className="max-ctr-container"
          value={Math.max(dynamicCtrMax, dynamicCtrValue)}
          style="percent"
        />
      </span>
    </AccTooltip>
  );

  return (
    <Cell rightAligned>
      {inView ? (
        <AccLazyPopover target={trigger}>
          <Top10DomainsLazy
            rankId={rankId}
            searchDate={searchDate}
            keywordId={keywordId}
            ownRank={baseRank ?? rankValue}
            searchEngineId={searchEngineId}
          />
        </AccLazyPopover>
      ) : (
        trigger
      )}
    </Cell>
  );
};

export default MaxCTRCell;
