import { useEffect } from 'react';
import { useField, useFormState } from 'react-final-form';
import { Link } from 'react-router-dom';
import { captureException } from '@sentry/react';
import cn from 'classnames';
import { FORM_ERROR } from 'final-form';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import keyBy from 'lodash/keyBy';
import omitBy from 'lodash/omitBy';
import range from 'lodash/range';
import AccButton from 'Components/AccButton/AccButton';
import { SelectItem } from 'Components/AccSelect';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import Alert from 'Components/Alert';
import { Field, Form } from 'Components/Fields';
import AccTitle from 'Components/Title/AccTitle';
import {
  ImportColumnInput,
  ImportValidationPageUpdateImportMutationFn,
  UpdateUploadImportInput,
  useImportValidationPageUpdateImportMutation,
} from 'Ghql';
import toast from 'Hooks/useToast';
import { keywordType, rankType } from 'Pages/Import/importer_helpers';
import { t, tct } from 'Utilities/i18n/index';
import {
  ImportClients,
  UploadImport,
  UploadImportColumn,
  UploadImportMappableColumn,
} from '../types';
import {
  compareColumnFieldKeys,
  filterColumnFields,
  getFieldValueIsNotUnique,
  getGroupValueMissing,
  getMappableColumnByFieldKey,
  getMappedColumns,
  getMissingRequiredFields,
  groupFieldKey,
  toColumnFieldKey,
  toDefaultColumnValues,
  toExamplesLength,
  toGroupSelectItems,
  toMappableColumnSelectItems,
  toMappedInputColumns,
} from './newHelpers';
import styles from './new.module.scss';

const ColumnMapper = (props: {
  thisColumn: UploadImportColumn;
  mappableColumnItems: SelectItem<string>[];
  columns: UploadImportColumn[];
}) => {
  const { thisColumn, mappableColumnItems, columns } = props;

  const {
    input,
    meta: { error },
  } = useField(toColumnFieldKey(thisColumn));
  const { values: allFields } = useFormState();

  const columnFields = filterColumnFields(allFields);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  return (
    <tr>
      <td>{thisColumn.id}</td>
      <td>{thisColumn.name}</td>
      <td>
        <Field.Select
          name={toColumnFieldKey(thisColumn)}
          options={mappableColumnItems}
          className={styles.columnSelect}
          clearable
          searchable
          validate={(fieldValue: string) => {
            const fieldValueIsNotUnique = getFieldValueIsNotUnique(
              fieldValue,
              columnFields,
              thisColumn,
              columns,
              mappableColumnItems,
            );
            if (fieldValueIsNotUnique) {
              input.onChange('');
              return fieldValueIsNotUnique;
            }
          }}
        />
      </td>
    </tr>
  );
};

const PreviewTable = (props: {
  mappableColumns: UploadImportMappableColumn[];
  columns: UploadImportColumn[];
}) => {
  const { mappableColumns, columns } = props;
  const { values: allFields } = useFormState();

  const examplesLength = toExamplesLength(columns);
  const columnFields = filterColumnFields(allFields);
  const mappableColumnsByFieldKey = getMappableColumnByFieldKey(mappableColumns, columnFields);
  const columnsByFieldKey = keyBy(columns, toColumnFieldKey);
  return (
    <table
      className={cn('table', styles.previewTable)}
      data-length={Object.keys(columnFields).length}
    >
      <div className={cn(styles.previewTableRow, styles.previewTableHeaderRow)}>
        {Object.keys(columnFields)
          .sort(compareColumnFieldKeys)
          .map((fieldKey) => (
            <div className={styles.previewTableDataCell} key={`preview-header-${fieldKey}`}>
              {mappableColumnsByFieldKey[fieldKey].name}
            </div>
          ))}
      </div>
      {range(examplesLength).map((i) => (
        <div className={styles.previewTableRow} key={`preview-${i}`}>
          {Object.keys(columnFields)
            .sort(compareColumnFieldKeys)
            .map((fieldKey) => (
              <div key={`preview-${i}-${fieldKey}`} className={styles.previewTableDataCell}>
                <AccTooltip tooltip={columnsByFieldKey[fieldKey].examples[i]}>
                  <div className={styles.previewTableDataCellContent}>
                    {columnsByFieldKey[fieldKey].examples[i]}
                  </div>
                </AccTooltip>
              </div>
            ))}
        </div>
      ))}
    </table>
  );
};

const handleSubmit = (
  uploadImport: UploadImport,
  updateImportMutation: ImportValidationPageUpdateImportMutationFn,
) => {
  return (allFields: Record<string, string | undefined>) => {
    try {
      const client = allFields[groupFieldKey]!;

      const mappedColumns = getMappedColumns(uploadImport.columns, allFields);
      const mappedInputColumns: ImportColumnInput[] = toMappedInputColumns(mappedColumns);

      const input: UpdateUploadImportInput = {
        client,
        columns: mappedInputColumns,
        id: uploadImport.id,
      };

      updateImportMutation({ variables: { input } });
    } catch (error) {
      captureException({ error, allFields });
      toast.error(t('Oops! Something went wrong'));
      return;
    }
  };
};

const handleValidate = (mappableColumns: UploadImportMappableColumn[]) => {
  return (allFields: Record<string, string | undefined>) => {
    const formErrors: { group?: string; keyword?: string; rank?: string } = {};
    const errors: { [FORM_ERROR]?: typeof formErrors } = {};

    const columnFields = filterColumnFields(allFields);

    formErrors.group = getGroupValueMissing(allFields);
    formErrors.keyword = getMissingRequiredFields(mappableColumns, columnFields, keywordType);
    formErrors.rank = getMissingRequiredFields(mappableColumns, columnFields, rankType);

    if (!isEmpty(omitBy(formErrors, isNil))) {
      errors[FORM_ERROR] = formErrors;
    }

    return errors;
  };
};

export const ImportValidationStatusNew = (props: {
  uploadImport: UploadImport;
  clients: ImportClients[];
}) => {
  const { clients, uploadImport } = props;

  const [updateImportMutation] = useImportValidationPageUpdateImportMutation();

  const groupSelectItems = toGroupSelectItems(clients);
  const initialGroupValue = groupSelectItems.length === 1 ? groupSelectItems[0].value : undefined;

  const mappableColumns = uploadImport.mappableColumns;
  const mappableColumnSelectItems: SelectItem<string>[] =
    toMappableColumnSelectItems(mappableColumns);

  const initialColumnValues = toDefaultColumnValues(uploadImport.columns);

  return (
    <div className={styles.importPageNew}>
      <Alert type="warning">
        <p>
          {t(
            'Below you are able to map and import your CSV/excel file and import the data in the correct format into AccuRanker.',
          )}
        </p>
        <p>
          {tct(
            'If you need help with the structure of the columns or see allowed values, please refer to the [link:examples].',
            {
              link: <Link to="/import/examples" />,
            },
          )}
        </p>
      </Alert>
      <Form
        initialValues={{ group: initialGroupValue, ...initialColumnValues }}
        onSubmit={handleSubmit(uploadImport, updateImportMutation)}
        validate={handleValidate(mappableColumns)}
        formClassName={styles.formGrid}
      >
        {({ error: formErrors }) => (
          <>
            <div className={styles.gridItemColumnMapping}>
              <AccTitle
                type="h4"
                helper={t(
                  'In the table below you see a list of the columns in the uploaded CSV/Excel file. To start the import please map the column with the corresponding AccuRanker field.',
                )}
              >
                {t('Map Columns With Fields')}
              </AccTitle>
              {formErrors?.keyword && <Alert type="danger">{formErrors.keyword}</Alert>}
              {formErrors?.rank && <Alert type="danger">{formErrors.rank}</Alert>}
              <table className={cn('table', styles.columnMappingTable)}>
                <thead>
                  <tr>
                    <th>{t('Number')}</th>
                    <th>{t('Column name')}</th>
                    <th>{t('Map into field')}</th>
                  </tr>
                </thead>
                <tbody>
                  {uploadImport.columns.map((column) => (
                    <ColumnMapper
                      key={`mapper-${toColumnFieldKey(column)}`}
                      thisColumn={column}
                      mappableColumnItems={mappableColumnSelectItems}
                      columns={uploadImport.columns}
                    />
                  ))}
                </tbody>
              </table>
            </div>
            <div className={styles.gridItemGroup}>
              <AccTitle
                type="h4"
                helper={t(
                  'The group you select is where the domain and keywords will be imported.',
                )}
              >
                {t('Group')}
              </AccTitle>
              {formErrors?.group && <Alert type="danger">{formErrors.group}</Alert>}
              <Field.Select
                required
                searchable
                name={groupFieldKey}
                className={styles.groupSelect}
                options={groupSelectItems}
              />
            </div>

            <div className={styles.gridItemSubmit}>
              {!isEmpty(omitBy(formErrors, isNil)) ? (
                <AccTooltip
                  tooltip={t(
                    'There are errors with your setup, please correct the issues before importing.',
                  )}
                >
                  <AccButton variant="primary" type="submit" disabled>
                    {t('Submit')}
                  </AccButton>
                </AccTooltip>
              ) : (
                <AccButton variant="primary" type="submit">
                  {t('Submit')}
                </AccButton>
              )}
            </div>
            <div className={styles.gridItemPreview}>
              <AccTitle
                type="h4"
                helper={t(
                  'In the table below you can see a preview of how columns in the uploaded CSV/Excel file will be mapped to AccuRanker fields.',
                )}
              >
                {t('Preview')}
              </AccTitle>
              <PreviewTable mappableColumns={mappableColumns} columns={uploadImport.columns} />
            </div>
          </>
        )}
      </Form>
    </div>
  );
};
