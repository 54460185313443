import AccPaper from 'Components/AccPaper';
import CardFooter from './components/CardFooter';
import DetailsActions from './components/DomainActions';
import DomainCharts from './components/DomainCharts';
import DomainContext, { DomainContextNode } from './components/DomainContext';
import DomainKpi from './components/DomainKpi';
import style from './domainCard.module.scss';

type Props = {
  domainNode: DomainContextNode;
};
const DomainCard = ({ domainNode }: Props) => (
  <AccPaper px="0" py="0" mih="426" shadow="sm" className={style.domainCard}>
    <DomainContext.Provider value={domainNode}>
      <DetailsActions />
      <DomainKpi />
      <DomainCharts />
      <CardFooter />
    </DomainContext.Provider>
  </AccPaper>
);

export default DomainCard;
