import { KeyboardEvent, useEffect } from 'react';
import _Mousetrap from 'mousetrap';

const Mousetrap = _Mousetrap();

const bindings: Record<string, string> = {};
let isPaused = false;
// const orgStopCallback = Mousetrap.stopCallback;

function _belongsTo(element, ancestor) {
  if (element === null || element === document) {
    return false;
  }

  if (element === ancestor) {
    return true;
  }

  return _belongsTo(element.parentNode, ancestor);
}

// Function Highjacked from mousetrap library and modified slightly to not bug out with Vite
// We use our own custom implementation of stopCallback
const originalStopCallback = (e, element) => {
  // if the element has the class "mousetrap" then no need to stop
  if (` ${element.className} `.indexOf(' mousetrap ') > -1) {
    return false;
  }

  if (_belongsTo(element, Mousetrap.target)) {
    return false;
  }

  // Events originating from a shadow DOM are re-targetted and `e.target` is the shadow host,
  // not the initial event target in the shadow tree. Note that not all events cross the
  // shadow boundary.
  // For shadow trees with `mode: 'open'`, the initial event target is the first element in
  // the event’s composed path. For shadow trees with `mode: 'closed'`, the initial event
  // target cannot be obtained.
  if ('composedPath' in e && typeof e.composedPath === 'function') {
    // For open shadow trees, update `element` so that the following check works.
    const initialEventTarget = e.composedPath()[0];
    if (initialEventTarget !== e.target) {
      element = initialEventTarget;
    }
  }
  return (
    element.tagName === 'INPUT' ||
    element.tagName === 'SELECT' ||
    element.tagName === 'TEXTAREA' ||
    element.isContentEditable
  );
};

Mousetrap.stopCallback = (e, element, combo) => {
  if (combo === 'esc') return false; // always fire the callback
  if (isPaused) return true; // do not fire the callback
  if (
    element.tagName === 'INPUT' ||
    element.tagName === 'SELECT' ||
    element.tagName === 'TEXTAREA' ||
    element.isContentEditable
  ) {
    if (combo === 'd') return true; // do not break out of input components on "d" key event
    return false; // always fire the callback
  }
  return originalStopCallback(e, element);
};

export const bind = (
  combination: string,
  description: string,
  callback: (...args: Array<any>) => any,
  action?: string,
) => {
  bindings[combination] = description;
  return Mousetrap.bind(combination, callback, action);
};
export const unbind = (combination: Array<string>) => Mousetrap.unbind(combination);
export const getBindings = () => ({ ...bindings });
export const pause = () => (isPaused = true);
export const unpause = () => (isPaused = false);

type MousetrapKeyBinding = {
  combination: string | null; // if null, useMousetrap does nothing
  description: string;
  callback: (event: KeyboardEvent) => void;
  disabled?: boolean;
};

/**
 Hook for binding keyboard events.

 It has support for keypress, keydown, and keyup events on specific keys, keyboard combinations, or key sequences.

 @example
 const addFilterShortcut = activeAttribute ? (isMac() ? 'command+shift+f' : 'ctrl+shift+f') : null;
 const addFilterDescription = t('Open all filters');
 const onOpenAllFilters = () => { ... }
 useMousetrap({
   combination: addFilterShortcut,
   description: addFilterDescription,
   callback: onOpenAllFilters,
 });
 */
export const useMousetrap = (keybinding: MousetrapKeyBinding) => {
  const { combination, description, callback, disabled } = keybinding;
  useEffect(() => {
    if (combination && !disabled) {
      Mousetrap.bind(combination, (e) => {
        e.preventDefault();
        return callback(e);
      });
      bindings[combination] = description;
      // cleanup
      return () => {
        Mousetrap.unbind([combination]);
        delete bindings[combination];
      };
    }
  }, [keybinding]);
};

export default {
  bind,
  unbind,
  getBindings,
  pause,
  unpause,
};
