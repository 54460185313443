import { Checkbox } from '@mantine/core';
import { observer } from 'mobx-react';
import { TableStoreType } from '../../../../store/TableStore';

export const CheckboxHeaderCell = observer(({ tableStore }: { tableStore: TableStoreType }) => {
  if (!tableStore || !tableStore.enableSelectAll) {
    return null;
  }

  const selected = tableStore.isGroupSelected;

  const toggleSelection = () => {
    if (selected) {
      tableStore.resetSelection();
    } else if (tableStore.isAllSelected) {
      tableStore?.selectAll();
    } else {
      tableStore.selectAllPage();
    }
  };

  return (
    <Checkbox
      className="checkboxHeaderCell"
      color={'snorlax'}
      checked={!!selected}
      onChange={toggleSelection}
    />
  );
});
