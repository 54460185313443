import { useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { Box } from '@mantine/core';
import { LineChart, NotesType } from 'Components/Chart/LineChart';
import { TooltipConfiguration, chartTooltipTypes } from 'Components/Chart/LineChart/support/types';
import linkToKeywordListFromChart from 'Components/Filters/LinkToKeywordListFromChart';
import { useFilters } from 'Hooks';
import { MissingDataOverlay } from 'Pages/Keywords/Overview/MissingDataOverlay';
import { ChartContainer } from 'Pages/Keywords/Overview/components/ChartContainer/index';
import { FilterAttribute, FilterComparison } from 'Types/Filter';
import { colorScheme } from 'Utilities/colors';
import { t } from 'Utilities/i18n';
import { WithDragDetails } from '../../../support/types';
import { OverviewChartSeries } from '../constants';

type ChartContentProps = {
  chartSeries: OverviewChartSeries[];
  loading: boolean;
  isGroupView: boolean;
  isEmptyGroup: boolean;
  onMissingDataOverlayClick: (() => any) | undefined;
  navigateToKeywordslistFromChart: boolean;
  showFakeData: boolean;
  notes?: NotesType[];
  notesLoading?: boolean;
  includeKebabMenu?: boolean;
} & WithDragDetails;

export const AverageRankChartContent = (props: ChartContentProps) => {
  const {
    chartSeries,
    navigateToKeywordslistFromChart,
    showFakeData,
    loading,
    isGroupView,
    isEmptyGroup,
    includeKebabMenu = true,
    onMissingDataOverlayClick,
  } = props;
  const filters = useFilters();
  const intl = useIntl();
  const chartRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const tooltipConfig: TooltipConfiguration = {
    type: chartTooltipTypes.STOCK,
    title: t('Average rank'),
    showPercentage: false,
    bottomText: navigateToKeywordslistFromChart ? t('Click to see ranking keywords') : undefined,
    customNumFormatter: (num: number) =>
      intl?.formatNumber(num, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        style: 'decimal',
      }),
  };

  const extraOptions = useMemo(
    () => ({
      navigator: {
        series: {
          reversed: true,
          lineColor: colorScheme.graphs.colors.darkBlue,
        },
      },
      plotOptions: {
        areaspline: {
          fillColor: '#cad6f7',
        },
        series: navigateToKeywordslistFromChart
          ? {
              point: {
                events: {
                  click: (event) => {
                    if (event.point.category) {
                      navigate(
                        linkToKeywordListFromChart(
                          event.point.category,
                          FilterAttribute.RANK,
                          FilterComparison.LTE,
                          100,
                        ),
                      );
                    }
                  },
                },
              },
            }
          : undefined,
      },
    }),
    [],
  );

  const filtersAdded = filters.length > 3;
  const showFakeDataOnChart = !filtersAdded && showFakeData;

  return (
    <ChartContainer
      title={t('Average Rank')}
      dragRef={props.dragRef}
      boardControls={props.boardControls}
      includeKebabMenu={includeKebabMenu}
      chartSeries={chartSeries}
      chartRef={chartRef}
      tooltip={[
        t(
          'The graph displays the average rank over time for all keywords in the current filtering. Only keywords with ranks in the top 100 are included in the calculation.',
        ),
      ]}
    >
      <Box pos="relative" ref={chartRef}>
        {!loading && showFakeDataOnChart && (
          <MissingDataOverlay
            type="missingKeywords"
            onClick={onMissingDataOverlayClick}
            isGroupView={isGroupView}
            isEmptyGroup={isEmptyGroup}
          />
        )}
        <LineChart
          isRank
          series={chartSeries}
          tooltip={tooltipConfig}
          extraOptions={extraOptions}
          enableRangeSelector
          chartType="area"
          isLoading={props.dragging || loading || props.notesLoading}
          notes={props.notes}
          loaderType="line"
          disableLegend
          exportButton={false}
        />
      </Box>
    </ChartContainer>
  );
};
