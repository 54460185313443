import { useSelector } from 'react-redux';
import { allGroupsId, allGroupsUserRoles, defaultRole } from 'Constants/workspaces';
import { InviteUserInput, useAddUserFormInviteUserMutation } from 'Ghql';
import toast from 'Hooks/useToast';
import { StoreType } from 'Types/Store';
import { EventName, TrackingKey, useMixpanel } from 'Utilities/Analytics/mixpanel';
import { t } from 'Utilities/i18n';
import { mapFinalFormErrors } from 'Utilities/validation';

export const useCreateUser = (
  refresh?: (...args: Array<any>) => any,
  onClose?: (...args: Array<any>) => void,
) => {
  const trackEvent = useMixpanel();
  const orgId = useSelector((state: StoreType) => state?.user?.organization?.id || '');
  const [createUser] = useAddUserFormInviteUserMutation();

  const handleSubmit = ({ fullName, email, userType, workspaces }: InviteUserInput) => {
    const inviteUserInput = {
      fullName,
      email,
      userType: userType ?? defaultRole,
      organization: orgId,
      workspaces: (allGroupsUserRoles.includes(userType) ? [allGroupsId] : workspaces || []).map(
        Number,
      ),
    };
    return createUser({
      variables: {
        inviteUserInput,
      },
    }).then((result) => {
      const errors = result.data?.inviteUser?.errors;
      if (errors && errors.length) {
        toast.error('Something went wrong');
        const errorObjects = errors
          .filter((err) => err)
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          .map((err) => ({ field: err!.field, messages: err!.messages }));
        trackEvent(EventName.UsersPage, {
          'Tracking key': TrackingKey.AddFail,
          Message: errors[0]?.messages[0] ?? '',
        });
        return mapFinalFormErrors(errorObjects);
      }
      trackEvent(EventName.UsersPage, { 'Tracking key': TrackingKey.AddSuccess });
      toast.success(t('User invited'));

      refresh?.();
      onClose?.();
    });
  };
  return handleSubmit;
};
