import React from 'react';
import { connect } from 'react-redux';
import Highcharts from 'highcharts';
import LocaleSelector from 'Selectors/LocaleSelector';
import { t } from 'Utilities/i18n';

type Props = {
  fullLocale: string;
};

class HighchartsLang extends React.Component<Props> {
  componentDidMount() {
    this.changeLang();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.fullLocale !== this.props.fullLocale) {
      this.changeLang();
    }
  }

  changeLang = () => {
    Highcharts.setOptions({
      chart: { style: { fontFamily: 'Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif' } },
      lang: {
        downloadCSV: t('Download CSV'),
        downloadJPEG: t('Download JPEG'),
        downloadPDF: t('Download PDF document'),
        downloadPNG: t('Download PNG image'),
        downloadSVG: t('Download SVG vector image'),
        downloadXLS: t('Download XLS'),
        drillUpText: t('Back to {series.name}'),
        noData: t('No data to display'),
        printChart: t('Print chart'),
        loading: t('Loading…'),
        rangeSelectorZoom: '',
      } as any,
    });
  };

  render() {
    return null;
  }
}

const mapStateToProps = (state) => ({
  fullLocale: LocaleSelector(state),
});

export default connect(mapStateToProps)(HighchartsLang);
