import { useEffect, useRef } from 'react';

export const useDisableBodyScroll = () => {
  const prevOverflowRef = useRef('');

  useEffect(() => {
    if (document) {
      prevOverflowRef.current = document.body.style.overflow;
      document.body.style.overflow = 'hidden';
    }
    return () => {
      if (document) {
        document.body.style.overflow = prevOverflowRef.current;
      }
    };
  }, []);
};
