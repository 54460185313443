import AccButton from 'Components/AccButton/AccButton';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import AccText from 'Components/Text/AccText';
import { ErrorType } from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import { t } from 'Utilities/i18n';

type Props = {
  errors?: ErrorType[];
};

const RegisterFailed = ({ errors }: Props) => {
  const { hideModal } = useModal();
  return (
    <ModalBorder
      title={t('We were unable to create your account, please try again.')}
      onClose={hideModal}
    >
      <AccText>
        {errors?.map((e) => (
          <p key={e.field}>{e.messages}</p>
        ))}
      </AccText>
      <ModalFooter
        primaryButtonSlot={
          <AccButton onClick={hideModal} variant="secondary">
            {t('Back to sign up')}
          </AccButton>
        }
      />
    </ModalBorder>
  );
};

export default RegisterFailed;
