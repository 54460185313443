import { useCallback, useState } from 'react';
import { QueryOrder } from 'Constants';

export type SetOrderByProps = (
  orderKey: string,
  newOrder?: QueryOrder.ASC | QueryOrder.DESC,
) => void;
export function useOrderBy(defaultOrderBy: string = 'keywords') {
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [order, setOrder] = useState(QueryOrder.DESC);

  const handleSetOrderBy = useCallback(
    (orderKey: string, newOrder?: QueryOrder) => {
      if (newOrder) {
        setOrder(newOrder);
        setOrderBy(orderKey);
      } else if (!newOrder && orderBy === orderKey) {
        setOrder(order === QueryOrder.ASC ? QueryOrder.DESC : QueryOrder.ASC);
      } else {
        setOrderBy(orderKey);
        setOrder(QueryOrder.DESC);
      }
    },
    [orderBy, order],
  );
  return { orderBy, order, setOrderBy: handleSetOrderBy };
}
