import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import cn from 'classnames';
import { t } from 'Utilities/i18n';
import { PageType } from '../constants';

interface PaginationButtonProps {
  type: PageType;
  onClick?: () => void;
  loading: boolean;
  page?: number;
  active?: boolean;
}

export default function PaginationButton(props: PaginationButtonProps) {
  const baseClassName = cn('accu-pagination__button', {
    disabled: props.loading,
  });
  if ([PageType.NEXT, PageType.PREVIOUS].includes(props.type)) {
    const isNext = props.type === PageType.NEXT;
    return (
      <li className={cn(baseClassName, 'accu-pagination__arrow')} onClick={props.onClick}>
        {isNext ? (
          <a className="accu-pagination__link" onClick={(e) => e?.preventDefault()}>
            <IconChevronRight size={20} />
          </a>
        ) : (
          <a className="accu-pagination__link" onClick={(e) => e?.preventDefault()}>
            <IconChevronLeft size={20} />
          </a>
        )}
      </li>
    );
  }

  if (props.type === PageType.SPILL) {
    return (
      <li className={cn(baseClassName, 'accu-pagination__button--spill')}>
        <div className="accu-pagination__spill" aria-label={t('…')}>
          <span aria-hidden="true">...</span>
        </div>
      </li>
    );
  }

  return (
    <li
      className={cn(baseClassName, 'accu-pagination__button--page', { active: props.active })}
      onClick={props.onClick}
      aria-disabled={true}
    >
      <a className="accu-pagination__link" onClick={(e) => e?.preventDefault()}>
        {props.page}
      </a>
    </li>
  );
}
