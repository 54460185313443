import { Divider } from '@mantine/core';
import AccButton from 'Components/AccButton/AccButton';
import Alert from 'Components/Alert';
import { ColumnSetting } from 'Components/DataTable';
import { Form } from 'Components/Fields';
import { t } from 'Utilities/i18n';
import { TableSettingForm } from '../../support/types';
import { ColumnCheckboxGroupField } from './components/ColumnCheckboxGroupField';
import './table-settings-form.scss';

interface TableSettingsFormProps {
  onSave: (form: TableSettingForm) => Promise<void>;
  columns: ColumnSetting[];
  advancedMetricsColumns: ColumnSetting[];
  defaultColumnIds: string[];
  initialValues: { columns: string[] };
  hasAdvancedMetrics?: boolean;
  hasAnalytics?: boolean;
  disableTableViewType?: boolean;
}
export const TableSettingsForm = ({
  onSave,
  columns,
  initialValues,
  defaultColumnIds,
  advancedMetricsColumns,
  hasAnalytics,
  hasAdvancedMetrics,
}: TableSettingsFormProps) => {
  return (
    <Form onSubmit={onSave} formClassName="table-settings__form " initialValues={initialValues}>
      {({ submitting: loading, form }) => {
        const onReset = () => form.change('columns', defaultColumnIds);
        return (
          <>
            <Alert type="info">
              {t('Customize which columns you wish to see for this table.')}
            </Alert>
            <ColumnCheckboxGroupField
              name="columns"
              columns={columns}
              advancedMetricsColumns={advancedMetricsColumns}
              hasAnalytics={hasAnalytics}
              hasAdvancedMetrics={hasAdvancedMetrics}
            />
            <Divider my="sm" color="gray.1" />
            <div className="table-settings__form__actions u-flex u-justify-between">
              <AccButton variant="tertiary" onClick={onReset} disabled={loading}>
                {t('Reset')}
              </AccButton>
              <AccButton
                ml="auto"
                variant="primary"
                type="submit"
                loading={loading}
                disabled={loading}
              >
                <span>{t('Update')}</span>
              </AccButton>
            </div>
          </>
        );
      }}
    </Form>
  );
};
