import FormatNumber from 'Components/FormatNumber/new';
import Cell from './HelperComponents/AccuCell';

type Props = {
  value: {
    data: {
      searchVolumeValue: number;
      shareOfVoiceValue: number;
      shareOfVoiceIsPercentage: boolean;
      shareOfVoicePercentageValue: number;
      validForSearchVolume: boolean;
      updatingKeyword: boolean;
      maxSov: number;
    };
    loadingData: boolean;
  };
  isMaxSov?: boolean;
};

const ShareOfVoiceCell = (props: Props) => {
  const {
    value: { data },
    isMaxSov,
  } = props;
  const { shareOfVoiceIsPercentage, shareOfVoicePercentageValue, validForSearchVolume, maxSov } =
    data;
  let { shareOfVoiceValue } = data;

  // Dont take the editors suggestion to simplify this to !validForSearchVolume. We do not want null if its undefined.
  if (validForSearchVolume === false) {
    return null;
  }

  if (shareOfVoiceValue === null) {
    shareOfVoiceValue = 0;
  }

  return (
    <Cell rightAligned style={{ whiteSpace: 'nowrap' }}>
      <FormatNumber
        value={
          isMaxSov
            ? maxSov
            : shareOfVoiceIsPercentage
            ? shareOfVoicePercentageValue
            : shareOfVoiceValue
        }
        style={shareOfVoiceIsPercentage ? 'percent' : 'decimal'}
        minimumFractionDigits={shareOfVoiceIsPercentage ? 2 : 0}
        maximumFractionDigits={shareOfVoiceIsPercentage ? 2 : 0}
      />
    </Cell>
  );
};

export default ShareOfVoiceCell;
