import { useState } from 'react';
import { IconChevronDown } from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import type { Props } from 'Components/AccButton/AccButton';
import { AccDropdownMenu, DropdownItem } from 'Components/AccDropdownMenu';
import styles from '../register.module.scss';

type AccButtonWithOptionsProps = Props & {
  options: DropdownItem[];
  label?: string;
};

const AccButtonWithOptions = ({
  options,
  label,
  children,
  ...props
}: AccButtonWithOptionsProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  return (
    <div className={styles.btnWithOptions}>
      <AccButton {...props}>{children}</AccButton>
      <AccButton {...props} onClick={() => setIsMenuOpen(!isMenuOpen)}>
        <AccDropdownMenu
          bodyStyle={{ maxWidth: '140px' }}
          bodyMiw={120}
          items={options}
          label={label}
          offset={14}
          withinPortal
          opened={isMenuOpen}
        >
          <IconChevronDown size={18} />
        </AccDropdownMenu>
      </AccButton>
    </div>
  );
};

export default AccButtonWithOptions;
