import { makeAutoObservable } from 'mobx';

const TREE_VIEW_SUBMIT_DELAY = 3000; // Backend processes submits asynchronously, so we need to wait a bit before we can fetch the updated data

class TreeViewStore {
  refetchListener: number = 0;
  expandedDomains: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }
  refetch = async (immediate: boolean) => {
    await new Promise((resolve) => setTimeout(resolve, immediate ? 0 : TREE_VIEW_SUBMIT_DELAY));
    this.refetchListener++;
  };
  handleExpandDomain = (domainId: string) => {
    this.expandedDomains.includes(domainId)
      ? (this.expandedDomains = this.expandedDomains.filter((id) => id !== domainId))
      : this.expandedDomains.push(domainId);
  };
}

let treeViewStore: TreeViewStore;

export const useTreeViewStore = () => {
  if (!treeViewStore) {
    treeViewStore = new TreeViewStore();
  }
  return treeViewStore;
};
