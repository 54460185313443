import { RulesActiveGroupEvents } from '../../../support/types';

export const getActiveGroupDetails = (
  setActiveGroup?: Function,
  nestedLevel?: number,
  index?: number,
): { groupDataKey: string; activeEvents: RulesActiveGroupEvents } => {
  const groupDataKey = `${nestedLevel ?? 0}-${index ?? 0}`;
  return {
    groupDataKey,
    activeEvents: {
      onMouseEnter: () => {
        setActiveGroup?.(groupDataKey);
      },
      onMouseLeave: () => {
        setActiveGroup?.(undefined);
      },
      customOnClick: () => {
        setActiveGroup?.(undefined);
      },
    },
  };
};
