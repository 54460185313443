import { Table } from '@mantine/core';
import AccAlert from 'Components/AccAlert/AccAlert';
import AccButton from 'Components/AccButton/AccButton';
import AccountUsage from 'Components/AccountUsage';
import FormatNumber from 'Components/FormatNumber';
import { PlanFragment, useAccountOrganizationPlanInfoQuery } from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import useUserPermission from 'Hooks/useUserPermission';
import { t, tn } from 'Utilities/i18n';
import PlanSkeleton from './PlanSkeleton';
import styles from './billing-plan.module.scss';

type Props = {
  isActive: boolean;
};

const Plan = ({ isActive }: Props) => {
  const { isAdmin } = useUserPermission();
  const { showModal } = useModal();
  const { data, loading, error } = useAccountOrganizationPlanInfoQuery();

  const showCancelConfirmation = () => {
    showModal({
      modalType: 'CancelPlan',
      modalTheme: 'light',
    });
  };

  const renderPaymentInfo = (plan: PlanFragment | undefined) => {
    if (!plan) {
      return null;
    }

    return (
      <>
        {plan.billingCycleInMonths === 1 ? (
          <Table.Tr>
            <Table.Td>
              <strong>{t('Monthly price')}</strong>
            </Table.Td>
            <Table.Td>
              <FormatNumber currency={plan?.currency ?? undefined}>
                {plan.calculateFuturePrice || plan.priceMonthly}
              </FormatNumber>
            </Table.Td>
          </Table.Tr>
        ) : (
          <Table.Tr>
            <Table.Td>
              <strong>{t('Annual price')}</strong>
            </Table.Td>
            <Table.Td>
              <FormatNumber currency={plan?.currency ?? undefined}>
                {plan.calculateFuturePrice || plan.priceYearly}
              </FormatNumber>
            </Table.Td>
          </Table.Tr>
        )}
        <Table.Tr>
          <Table.Td>
            <strong>{t('Billing cycle')}</strong>
          </Table.Td>
          <Table.Td>
            {tn('Paid monthly', 'Paid every %s months', plan.billingCycleInMonths)}
          </Table.Td>
        </Table.Tr>
        {isActive && (
          <Table.Tr>
            <Table.Td>
              <strong>{t('Next payment')}</strong>
            </Table.Td>
            <Table.Td>{plan.endDate}</Table.Td>
          </Table.Tr>
        )}
      </>
    );
  };

  if (loading) {
    return <PlanSkeleton />;
  }
  if (error) {
    console.error('Error:', error);
    return <PlanSkeleton />;
  }
  const plan = data?.user?.organization?.activePlan;
  const nextPlan = data?.user?.organization?.nextPlan;
  const previousPlan = data?.user?.organization?.previousPlan;

  if (!isAdmin) {
    return (
      <div className="billing-box">{t('You need to be organization admin to update plan')}</div>
    );
  }
  if (!plan && !previousPlan) {
    return <div className="billing-box">{t('Failed to fetch data')}</div>;
  }

  const getPlanDescription = () => {
    if (!plan && previousPlan) {
      return t('%s with %s keywords', previousPlan.name, previousPlan.maxKeywords);
    }
    if (plan?.isFree) {
      return t('%s with %s keywords', plan.name, plan.maxKeywords);
    }
    return t('%s with %s keywords', plan?.category, plan?.maxKeywords);
  };

  const showPaymentInfo = !plan?.isFree && !plan?.isTrial;
  return (
    <div className="billing-box">
      {nextPlan && isActive && (
        <p className="alert alert-warning next-plan">
          {t(
            'The plan will expire on %s. You will be switched to the "%s" on %s.',
            plan?.endDate,
            nextPlan.name,
            nextPlan.startDate,
          )}
        </p>
      )}

      <Table className={styles.planTable}>
        <Table.Tbody>
          <Table.Tr>
            <Table.Td>
              <strong>{t('Plan')}</strong>
            </Table.Td>
            <Table.Td>{getPlanDescription()}</Table.Td>
          </Table.Tr>
          {showPaymentInfo && renderPaymentInfo(plan || previousPlan || undefined)}
          {showPaymentInfo && isActive && (
            <Table.Tr>
              <Table.Td colSpan={2} align={'right'}>
                <AccButton variant="tertiary" onClick={showCancelConfirmation}>
                  {t('Cancel my account')}
                </AccButton>
              </Table.Td>
            </Table.Tr>
          )}
          {showPaymentInfo && !isActive && plan && (
            <Table.Tr>
              <Table.Td colSpan={2} align={'center'}>
                <AccAlert severity={'error'} mt={'sm'} hideIcon>
                  <strong>{t('Plan cancelled')}</strong>
                </AccAlert>
              </Table.Td>
            </Table.Tr>
          )}
          {showPaymentInfo && !isActive && !plan && previousPlan && (
            <Table.Tr>
              <Table.Td colSpan={2} align={'center'}>
                <AccAlert severity={'error'} mt={'sm'} hideIcon>
                  <strong>{t('This plan is expired')}</strong>
                </AccAlert>
              </Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>
      </Table>
      <AccountUsage />
    </div>
  );
};

export default Plan;
