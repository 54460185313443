import React from 'react';
import Checkbox, { CheckboxProps } from 'Components/Controls/Checkbox';
import GroupCheckboxCheckedDisabled from './icons/group-checkbox-checked-disabled.svg?inline';
import GroupCheckboxChecked from './icons/group-checkbox-checked.svg?inline';
import GroupCheckboxUnChecked from './icons/group-checkbox-unchecked.svg?inline';
import OptionCheckboxCheckedDisabled from './icons/option-checkbox-checked-disabled.svg?inline';
import OptionCheckboxChecked from './icons/option-checkbox-checked.svg?inline';
import OptionCheckboxUnChecked from './icons/option-checkbox-unchecked.svg?inline';

type CustomCheckboxExternalProps = CheckboxProps & {
  disabledCheck?: boolean;
};
const CustomCheckbox = ({
  checkedIcon,
  unCheckedIcon,
  checkedDisableIcon,
  ...props
}: CustomCheckboxExternalProps & {
  checkedIcon: JSX.Element;
  unCheckedIcon: JSX.Element;
  checkedDisableIcon: JSX.Element;
}) => {
  const customFn: React.ComponentType<{ checked?: boolean; [key: string]: unknown }> = ({
    checked,
  }) =>
    (checked ? (props.disabledCheck ? checkedDisableIcon : checkedIcon) : unCheckedIcon) || null;
  return <Checkbox {...props} kind="custom" customIconRenderer={customFn} />;
};

export const TableSettingGroupCheckbox = (props: CustomCheckboxExternalProps) => (
  <CustomCheckbox
    {...props}
    checkedIcon={<GroupCheckboxChecked />}
    unCheckedIcon={<GroupCheckboxUnChecked />}
    checkedDisableIcon={<GroupCheckboxCheckedDisabled />}
    kind="custom"
  />
);

export const TableSettingOptionCheckbox = (props: CustomCheckboxExternalProps) => (
  <CustomCheckbox
    {...props}
    checkedIcon={<OptionCheckboxChecked />}
    unCheckedIcon={<OptionCheckboxUnChecked />}
    checkedDisableIcon={<OptionCheckboxCheckedDisabled />}
    kind="custom"
  />
);
