const CHECKBOX = 'checkbox';
const KEYWORD = 'keyword';
const COUNTRY_NAME = 'countryName';
const IMPRESSIONS = 'impressions';
const CLICKS = 'clicks';
const CTR = 'ctr';
const POSITION = 'position';
export const ColumnIDs = {
  CHECKBOX,
  KEYWORD,
  COUNTRY_NAME,
  IMPRESSIONS,
  CLICKS,
  CTR,
  POSITION,
} as const;
