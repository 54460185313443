import { Component } from 'react';
import { AccMultiSelect, SelectItem } from 'Components/AccSelect';
import Skeleton from 'Components/Skeleton';

type Props = {
  placeholder?: string;
  noValueItem?: string;
  items: Record<string, any>[];
  loading?: false;
  error?: string;
  value?: string[];
  onChange: (value: string[]) => void;
  showError?: boolean;
  disabled?: boolean;
};

class MultiOptionsInput extends Component<Props> {
  static defaultProps = {
    disabled: false,
  };
  handleChange = (newValue: string[]) => {
    this.props.onChange(newValue);
  };

  renderSkeleton() {
    return (
      <Skeleton
        linesConfig={[
          {
            type: 'text',
            options: {
              width: '120px',
              marginBottom: '10px',
            },
          },
        ]}
      />
    );
  }

  createOptionItem = (item) => {
    const { noValueItem } = this.props;
    return {
      label: item ? item : noValueItem,
      value: item,
    };
  };

  render() {
    const { value, items, placeholder, showError, disabled } = this.props;

    if (this.props.loading || this.props.error) {
      return this.renderSkeleton();
    }

    const itemsOptions = items.reduce((acc, item) => {
      acc.push(this.createOptionItem(item));
      return acc;
    }, []) as SelectItem<string>[];

    return (
      <AccMultiSelect
        autoFocus
        value={value}
        options={itemsOptions}
        onChange={this.handleChange}
        showError={showError}
        disabled={disabled}
        creatable={false}
        placeholder={placeholder}
        inputMaxWidth={570}
      />
    );
  }
}

export default (query) => query(MultiOptionsInput);
