import cn from 'classnames';
import './icon-container.scss';

interface IconContainerProps {
  size: 'sm' | 'md';
}

export const IconContainer: React.FC<React.PropsWithChildren<IconContainerProps>> = (props) => {
  return (
    <span className={cn('acc-icon-container', `acc-icon-container--${props.size}`)}>
      {props.children}
    </span>
  );
};
