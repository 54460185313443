import { Component } from 'react';
import { connect } from 'react-redux';
import { hideModal } from 'Actions/ModalAction';
import AccButton from 'Components/AccButton/AccButton';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import AccText from 'Components/Text/AccText';
import { t } from 'Utilities/i18n';
import { redirectToRoot } from 'Utilities/underdash';

type Props = {
  hideModal: (...args: Array<any>) => any;
};

class UpsellModal extends Component<Props> {
  addDomain = () => {
    redirectToRoot();
  };

  render() {
    return (
      <ModalBorder title={t('You Cannot Edit a Demo Domain')} onClose={this.props.hideModal}>
        <div>
          <AccText>
            {t(
              'You are trying to edit a demo account. If you wish to add or edit keywords please add a new domain on the dashboard.',
            )}
          </AccText>
          <ModalFooter
            primaryButtonSlot={
              <AccButton onClick={this.addDomain} variant="primary">
                {t('Go to dashboard')}
              </AccButton>
            }
            secondaryButtonSlot={
              <AccButton onClick={this.props.hideModal} variant="tertiary">
                {t('Back')}
              </AccButton>
            }
          />
        </div>
      </ModalBorder>
    );
  }
}

export default connect(null, {
  hideModal,
})(UpsellModal);
