import { SoMePlatform } from 'Components/SoMeFormFields/SocialMediaIcons';
import { useAddCompetitorMutation, useUpdateCompetitorMutation } from 'Ghql';
import { extractFormErrors } from 'Hooks/Graphql/helpers';
import { useModal } from 'Hooks/base/useModal';
import toast from 'Hooks/useToast';
import { throwNetworkError } from 'Utilities/errors';
import { t } from 'Utilities/i18n';

type FormStateValues = {
  domain: string;
  displayName: string;
  googleBusinessNameList: string[];
  delete?: boolean;
  [SoMePlatform.X]?: string;
  [SoMePlatform.FACEBOOK]?: string;
  [SoMePlatform.TIKTOK]?: string;
  [SoMePlatform.INSTAGRAM]?: string;
  [SoMePlatform.LINKEDIN]?: string;
};

type Props = {
  competitorId?: string;
  refresh: (...args: Array<any>) => any;
  domainId?: string;
};

export const useSubmitCompetitor = (props: Props) => {
  const { competitorId, refresh, domainId } = props;

  const [addCompetitor] = useAddCompetitorMutation();
  const [updateCompetitor] = useUpdateCompetitorMutation();

  const { hideModal } = useModal();

  const handleAddCompetitorSubmit = (formValues: FormStateValues) => {
    const addCompetitorInput = {
      domain: formValues?.domain || '',
      competitorForDomain: domainId || '',
      displayName: formValues?.displayName || '',
      googleBusinessNameList: formValues?.googleBusinessNameList || [],
      twitterHandle: formValues?.x_handle || '',
      facebookProfile: formValues?.facebook_profile || '',
      instagramProfile: formValues?.instagram_profile || '',
      tiktokProfile: formValues?.tiktok_profile || '',
      linkedinProfile: formValues?.linkedin_profile || '',
    };
    return addCompetitor({
      variables: {
        addCompetitorInput,
      },
    }).then((res) => {
      const { errors } = res?.data?.addCompetitor || {};
      if (errors && errors.length) {
        toast.error(errors[0]?.messages[0] || t('Something went wrong'));
        return extractFormErrors(res);
      }
      toast.success(t('Competitor added. Data will be available in around 10 seconds.'));
      setTimeout(() => {
        refresh?.();
      }, 3000);
      hideModal();
    }, throwNetworkError);
  };

  const handleEditCompetitorSubmit = (formValues: FormStateValues) => {
    const updateCompetitorInput = {
      id: competitorId || '',
      domain: formValues?.domain || '',
      displayName: formValues?.displayName || '',
      googleBusinessNameList: formValues?.googleBusinessNameList || [],
      twitterHandle: formValues?.x_handle || '',
      delete: formValues?.delete || false,
      facebookProfile: formValues?.facebook_profile || '',
      instagramProfile: formValues?.instagram_profile || '',
      tiktokProfile: formValues?.tiktok_profile || '',
      linkedinProfile: formValues?.linkedin_profile || '',
    };
    return updateCompetitor({
      variables: {
        updateCompetitorInput,
      },
    }).then((res) => {
      const errors = res?.data?.updateCompetitor?.errors || [];
      if (errors && errors.length) {
        toast.error(errors[0]?.messages[0] || t('Something went wrong'));
        return extractFormErrors(res);
      }
      toast.success(t('Competitor updated'));
      refresh?.({
        id: competitorId,
        domain: formValues?.domain,
        displayName: formValues?.displayName,
      });
      hideModal();
    });
  };

  return { handleSubmit: competitorId ? handleEditCompetitorSubmit : handleAddCompetitorSubmit };
};
