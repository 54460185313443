import { useMultiAccountInviteSentFromOwnAccountQuery } from 'Ghql';

export const useInviteSentFromOwnAccount = (code: string) => {
  const { data, loading } = useMultiAccountInviteSentFromOwnAccountQuery({
    variables: { multiAccountId: code },
  });

  const inviteSentFromOwnAccount = data?.user?.organization?.inviteSentFromOwnAccount ?? null;

  return { inviteSentFromOwnAccount, loading };
};
