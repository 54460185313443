import * as React from 'react';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import { IconGripVertical, IconX } from '@tabler/icons-react';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import './element-list-item.scss';

type Props = {
  title: string;
  elementIndex: number;
  element: any;
  onRemove: (...args: Array<any>) => any;
  onReplace: (...args: Array<any>) => any;
  editor: React.ComponentType<
    React.PropsWithChildren<{
      value: any;
      onChange: (...args: Array<any>) => any;
    }>
  >;
};
const DragHandle = SortableHandle(() => (
  <div className="drag-handle">
    <IconGripVertical size={20} />
  </div>
));

class ElementListItem extends React.PureComponent<Props> {
  handleRemove = () => {
    this.props.onRemove(this.props.elementIndex);
  };
  handleChange = (element) => {
    this.props.onReplace(element, this.props.elementIndex);
  };

  render() {
    const { editor, title, element } = this.props;
    const Editor = editor;
    return (
      <div className="element-list-item">
        <AccActionIcon className="remove-button" onClick={this.handleRemove}>
          <IconX />
        </AccActionIcon>
        <div className="header">
          <DragHandle />
          <div className="title">{title}</div>
        </div>
        <div className="element-container">
          <Editor value={element} onChange={this.handleChange} />
        </div>
      </div>
    );
  }
}

export default SortableElement<Props>(ElementListItem);
