import { useMemo, useRef } from 'react';
import { Box } from '@mantine/core';
import { Options } from 'highcharts';
import { LineChart, NotesType } from 'Components/Chart/LineChart';
import { chartTooltipTypes } from 'Components/Chart/LineChart/support/types';
import { useHistoryChartsQuery } from 'Ghql';
import { useFilters } from 'Hooks';
import { MissingDataOverlay } from 'Pages/Keywords/Overview/MissingDataOverlay';
import { ChartContainer } from 'Pages/Keywords/Overview/components/ChartContainer/index';
import { getAiTrafficValueSeries } from 'Pages/Keywords/Overview/components/HistoryCharts/AiTrafficValue/helpers';
import { useFakeData } from 'Pages/Keywords/Overview/hooks/useFakeData';
import { useGroupViewInfo } from 'Pages/Keywords/Overview/hooks/useGroupViewInfo';
import { WithDragDetails } from 'Pages/Keywords/Overview/support/types';
import { colorScheme } from 'Utilities/colors';
import { t } from 'Utilities/i18n';
import { useAddKeywordsModal } from '../support/util';

interface TrafficValueChartProps {
  domainId: number;
  notesLoading: boolean;
  notes: NotesType[] | undefined;
  enabledHistoryCharts: string[];
}

export const AiTrafficValueChart = (props: TrafficValueChartProps & WithDragDetails) => {
  const filters = useFilters();
  const { isEmptyGroup, isGroupView } = useGroupViewInfo(filters);
  const chartRef = useRef<HTMLDivElement>(null);
  const { SovChartFakeData } = useFakeData();
  const { data, loading, refetch } = useHistoryChartsQuery({
    variables: { filters, name: 'historyCharts', charts: props.enabledHistoryCharts },
    skip: isEmptyGroup,
  }); // use all history chart names to reuse query
  const addKeywordsModal = useAddKeywordsModal(props.domainId, refetch);

  const extraOptions: Options = useMemo(
    () => ({
      id: 'aiTrafficValueOverview',
      navigator: { series: { lineColor: colorScheme.graphs.stats.aiTrafficValue } },
      plotOptions: {
        areaspline: { fillColor: `${colorScheme.graphs.stats.aiTrafficValue}4D` },
      },
    }),
    [],
  );

  const chartSeries = getAiTrafficValueSeries(data?.graphs?.overviewHistoryGraphs);

  const filtersAdded = filters.length > 3;
  const showFakeData = !filtersAdded && !data?.graphs?.overviewHistoryGraphs?.length;

  return (
    <ChartContainer
      title={t('AI Traffic Value')}
      tooltip={[
        t('This chart shows the total AI traffic value for the keywords in the current filtering.'),
        t(
          'The AI traffic value for an individual keywords is estimated as the average CPC (from Google Ads) multiplied by the AI Share of Voice for the keyword.',
        ),
      ]}
      dragRef={props.dragRef}
      boardControls={props.boardControls}
      chartRef={chartRef}
      chartSeries={chartSeries}
    >
      <Box pos="relative" ref={chartRef}>
        {!loading && showFakeData && (
          <MissingDataOverlay
            type="missingKeywords"
            onClick={addKeywordsModal}
            isGroupView={isGroupView}
            isEmptyGroup={isEmptyGroup}
          />
        )}
        <LineChart
          series={showFakeData ? SovChartFakeData : chartSeries}
          tooltip={{
            type: chartTooltipTypes.STOCK,
            title: t('AI Traffic Value'),
            showPercentage: false,
          }}
          extraOptions={extraOptions}
          chartType="area"
          isLoading={props.dragging || loading || props.notesLoading}
          notes={props.notes}
          showPercentage={false}
          enableRangeSelector
          loaderType="line"
          disableLegend
          exportButton={false}
        />
      </Box>
    </ChartContainer>
  );
};
