import { memo, useState } from 'react';
import { ActionIcon, Checkbox, Flex, useMantineTheme } from '@mantine/core';
import cn from 'classnames';
import { AccFastIcon } from 'Components/AccFastIcon';
import AccTag from 'Components/AccTag';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import {
  KEYWORDS_PAGE,
  LinkIconWithFolderFilter,
  OVERVIEW_PAGE,
} from 'Components/Filters/LinkWithFolders/linkWithFolders';
import { RowIndentations } from 'Components/Table/TreeTable';
import virtualizedTableStyles from 'Components/Table/VirtualizedTable/virtualizedTable.module.scss';
import AccText from 'Components/Text/AccText';
import { useDomainId } from 'Hooks/data/domain/useQueryDomainInfo';
import { useIsSelected } from 'Hooks/useSelectedNodes';
import SERPCell from 'Pages/Keywords/Groupings/ViewMode/components/cells/SERPCell';
import { getIndentClassNames } from 'Utilities/Table/Tree/treeUtils';
import { propsIsEqualComparison } from 'Utilities/compare';
import { t } from 'Utilities/i18n';
import { IconChevronDown, IconChevronRight, IconFolder } from 'icons/tag-cloud';
import { FolderCellProps } from '../../../utils/useFoldersTableConfig';
import { FolderActions } from './FolderActions';
import { TagActions } from './TagActions';
import { usePathCellActions } from './usePathCellActions';
import styles from '../cells.module.scss';

const PathCell = (props: FolderCellProps & { isDemoDomain: boolean }) => {
  const { rootNode, isOpen, onToggle, classes, isHovered: hovered, isDemoDomain } = props;
  const domainId = useDomainId() as string;
  const gray = useMantineTheme().other.iconInactive;

  const {
    handleAddTag,
    handleEditDynamicTag,
    handleCloneDynamicTag,
    handleDeleteDynamicTag,
    handleEditStaticTag,
    handleDeleteStaticTag,
  } = usePathCellActions(domainId, rootNode);

  const [serpCellOpened, setSerpCellOpened] = useState(false);
  const isFolder = !!rootNode.is_folder;
  const showActions = !!(hovered || serpCellOpened);
  const isSelected = useIsSelected(rootNode[props.expandDataKey], props.expandDataKey);
  const indentClassNames = getIndentClassNames(rootNode, isOpen);
  return (
    <Flex
      className={cn(virtualizedTableStyles.tableScrollShadowItem, classes.noWrap, styles.pathCell, {
        [styles.clickable]: isFolder,
      })}
      onClick={(e) => {
        e.stopPropagation();
        if (isFolder) {
          onToggle(rootNode);
        }
      }}
    >
      <AccTooltip tooltip={t('No keywords to compare')} disable={rootNode?.keywords !== 0}>
        <Checkbox
          className={classes.favicon}
          px={8}
          disabled={rootNode?.keywords === 0}
          checked={isSelected}
          key={isSelected?.toString()}
          onChange={(event) => props.updateSelectedNode(props.rootNode, event)}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        />
      </AccTooltip>
      <RowIndentations
        depth={rootNode.deepness}
        expandIconWidth="28px"
        className={indentClassNames}
        hideConnectors={rootNode.hiddenConnectors}
      />
      {isFolder && (
        <AccFastIcon src={isOpen ? IconChevronDown : IconChevronRight} size={18} color={gray} />
      )}
      <Flex
        className={styles.iconAndPathContainer}
        align="center"
        gap={6}
        mr="auto"
        data-testid={`table-row-name-${rootNode.name}`}
      >
        {isFolder ? (
          <>
            <ActionIcon
              className={isOpen && rootNode?.children?.length ? 'connectorElement' : ''}
              pos="relative"
              aria-pressed={isOpen}
              aria-label={isOpen ? t('toggle row closed') : t('toggle row open')}
              variant="transparent"
            >
              <AccFastIcon src={IconFolder} size={26} color={gray} />
            </ActionIcon>
            <AccText size="sm" fw={600} overflowEllipsis>
              {rootNode.name}{' '}
              <AccText inline c={gray} fw={400}>
                {rootNode?.n_children ? `(${rootNode?.n_children})` : '(0)'}
              </AccText>
            </AccText>
          </>
        ) : (
          <AccTag tag={rootNode.name} dynamic={rootNode.is_dynamic} maxWidth="100%" />
        )}
      </Flex>
      <FolderActions
        onAddTag={handleAddTag}
        onAddFolder={(e) => {
          e.stopPropagation();
          props?.onDragEnd?.(
            e,
            t('You can only add folders in edit mode. Do you want to enter edit mode?'),
          );
        }}
        showAction={showActions && !isDemoDomain && isFolder}
      />
      <TagActions
        onEdit={rootNode.is_dynamic ? handleEditDynamicTag : handleEditStaticTag}
        onClone={handleCloneDynamicTag}
        onDelete={rootNode.is_dynamic ? handleDeleteDynamicTag : handleDeleteStaticTag}
        isDynamic={!!rootNode.is_dynamic}
        showAction={showActions && !isDemoDomain && !isFolder}
      />
      {showActions && (
        <Flex key={'cell-actions'} align={'center'}>
          <LinkIconWithFolderFilter
            path={rootNode.path}
            isFolder={isFolder}
            name={rootNode.name}
            pageToGoTo={OVERVIEW_PAGE}
            tooltipText={
              isFolder
                ? t('Go to dashboard and filter by folder')
                : t('Go to dashboard list and filter by tag')
            }
          />
          <LinkIconWithFolderFilter
            path={rootNode.path}
            isFolder={isFolder}
            name={rootNode.name}
            pageToGoTo={KEYWORDS_PAGE}
            tooltipText={
              isFolder
                ? t('Go to keywords list and filter by folder')
                : t('Go to keywords list and filter by tag')
            }
          />
          <AccTooltip tooltip={t('Show SERP features')} disable={serpCellOpened}>
            {/* stopPropagation avoids folder expand / disband.  */}
            <div onClick={(e) => e.stopPropagation()}>
              <SERPCell
                path={rootNode.path}
                isOpen={serpCellOpened}
                setIsOpen={setSerpCellOpened}
              />
            </div>
          </AccTooltip>
        </Flex>
      )}
    </Flex>
  );
};

export default memo(PathCell, propsIsEqualComparison);
