import React, { memo, useMemo } from 'react';
import { Box } from '@mantine/core';
import AccLazyPopover from 'Components/AccLazyPopover/AccLazyPopover';
import { useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import { FolderType } from 'Pages/Keywords/Groupings/groupingTypes';
import { BarChart } from 'Pages/Keywords/Overview/components/BarChart/BarChart';
import colorScheme from 'Utilities/colors';
import { propsIsEqualComparison } from 'Utilities/compare';
import { getFaviconByDomain } from 'Utilities/favicon';
import { t } from 'Utilities/i18n';

const DynamicCompetitorChart = ({
  top_ten_competitors,
  ownDomainPosition,
}: {
  top_ten_competitors: FolderType['top_ten_competitors'];
  ownDomainPosition: number | string;
}) => {
  const { displayNameMap } = useQueryDomainInfo();

  const getOwnDomainPosition = () => {
    return typeof ownDomainPosition === 'number' ? ownDomainPosition : '?';
  };

  const dataItems = useMemo(() => {
    return (
      top_ten_competitors?.map((e, idx) => {
        return {
          value: e?.score ?? 0,
          label: (displayNameMap && displayNameMap[e?.domain || '']) || e?.domain || '',

          background: e?.own_domain ? colorScheme.orange : colorScheme.shareVoice,
          isOwnDomain: e?.own_domain,
          icon: (
            <>
              <Box display="inline-block" w={30}>
                {' '}
                #{e?.own_domain ? getOwnDomainPosition() : idx + 1}{' '}
              </Box>
              <img src={e?.domain ? getFaviconByDomain(e.domain) : ''} alt="" />
            </>
          ),
          id: e?.domain,
        };
      }) ?? []
    );
  }, [top_ten_competitors, displayNameMap]);

  return <BarChart normalNumberOfItems={10} loading={false} items={dataItems} />;
};

const CompetitorPositionCell = ({
  rootNode,
  target,
}: {
  rootNode: FolderType;
  target: React.ReactElement;
}) => {
  return (
    <AccLazyPopover
      width={400}
      outerContainerProps={{ justify: 'end' }}
      target={target}
      dropdownTitle={t('Dynamic competitor score')}
    >
      {() => (
        <DynamicCompetitorChart
          top_ten_competitors={rootNode.top_ten_competitors}
          ownDomainPosition={rootNode.dynamic_competitor_position}
        />
      )}
    </AccLazyPopover>
  );
};

export default memo(CompetitorPositionCell, propsIsEqualComparison);
