import { showModal } from 'Actions/ModalAction';
import { store } from 'Store';

export const showPermissionDeniedModal = () => {
  store.dispatch(
    showModal({
      modalType: 'PermissionDeniedModal',
      modalProps: {
        autoRefreshAfter: Math.floor(Math.random() * 300) + 30, // Random number so we do not DDoS ourselves by reloading all at once!
      },
    }),
  );
};
