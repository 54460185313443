import { Box } from '@mantine/core';
import AccTooltip from 'Components/AccTooltip/AccTooltip';

type Props = {
  tooltip: string;
  align?: 'right' | 'center';
};

// "N/A"-cell,
// which will displaa a dash ("-") and a tooltip
const NaCell = ({ tooltip, align = 'center' }: Props) => (
  <Box ta={align}>
    <AccTooltip tooltip={tooltip}>
      <span>-</span>
    </AccTooltip>
  </Box>
);

export default NaCell;
