import { Component } from 'react';
import AccButton from 'Components/AccButton/AccButton';
import type { Integration } from 'Types/Integration';

type Props = {
  onSelect: (...args: Array<any>) => any;
  providers: Integration[];
};
export default class SelectProvider extends Component<Props> {
  render() {
    const { onSelect, providers } = this.props;
    return providers.map((option) => (
      <AccButton key={option.name} variant="secondary" onClick={() => onSelect(option)}>
        {option.name}
      </AccButton>
    ));
  }
}
