import { useSelector } from 'react-redux';
import { selectActivePlan, selectNumberOfKeywords } from '../../../Selectors/UserSelector';

export const useMaxKeywordsCount = (): number => {
  const activePlan = useSelector(selectActivePlan);
  return activePlan?.maxKeywords || 5000;
};

/** Get the number of remaining keywords on the plan */
// eslint-disable-next-line import/no-unused-modules
export const useAvailableKeywordsCount = (): number => {
  const maxKeywords = useMaxKeywordsCount();
  return maxKeywords - useSelector(selectNumberOfKeywords);
};
