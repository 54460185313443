import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { makeAutoObservable } from 'mobx';
import { observer } from 'mobx-react';
import { selectFilterBarFilters, selectHasActiveVisibleFilters } from 'Selectors/FilterSelector';
import { FilterBase } from 'Types/Filter';
import { STICKY_FILTER_HEIGHT } from '../../Constants/sticky';
import { filterGroupSelector, filterPristineSelector } from '../../Selectors/FilterGroupsSelector';
import FilterItemList from './FilterItemList';
import FiltersShell from './FiltersShell';
import ResetFilter from './ResetFilter';
import SaveFilterGroup from './SaveFilterGroup';
import SavedFilterGroups from './SavedFilterGroups';
import UpdateFilterGroup from './UpdateFilterGroup';
import './filters.scss';

const useFilterAnimation = (visibleFilters: FilterBase[]) => {
  const filterUpdateInProgress = useRef(false);
  const filterRef = useRef<HTMLDivElement>(null);

  const startAnimation = () => {
    filterUpdateInProgress.current = true;
    filterRef.current?.classList?.add('flash');
  };
  const endAnimation = () => {
    filterUpdateInProgress.current = false;
    filterRef.current?.classList?.remove('flash');
  };
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | null = null;

    if (visibleFilters.length) {
      endAnimation();
      startAnimation();
      timeout = setTimeout(endAnimation, 1000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [visibleFilters]);
  return [filterRef, filterUpdateInProgress.current];
};

const useCanUpdateSegment = () => {
  const filterGroup = useSelector(filterGroupSelector);
  const pristine = useSelector(filterPristineSelector);
  const hasFilters = useSelector(selectHasActiveVisibleFilters);
  return (
    filterGroup.id !== 'default' &&
    filterGroup.id !== '' &&
    !!filterGroup.id &&
    hasFilters &&
    !pristine
  );
};

/**
 * Handles the global visibility state of the filterbar.
 */
class FiltersVisibility {
  constructor() {
    makeAutoObservable(this);
  }

  visible = true;
  shellVisible = false;

  setVisibility = (nextVisibility: boolean) => {
    this.visible = nextVisibility;
  };
  setShellVisibility = (nextVisibility: boolean) => {
    this.shellVisible = nextVisibility;
    this.visible = !nextVisibility;
  };
}

/**
 * Global visibility state of the filterbar.
 *
 * This constant can be imported in page components to change the global state
 * from within the component.
 *
 * @example
 * import { filterVisibility } from 'Components/Filters';
 *
 * filterVisibility.setVisibility(true);
 */
export const filterVisibility = new FiltersVisibility();

const WithCanUpdateSegment = (props: React.PropsWithChildren) => {
  const canUpdate = useCanUpdateSegment();
  return <>{canUpdate ? props.children : null}</>;
};
const Filters = observer(
  ({ showSegments, disabled }: { showSegments?: boolean; disabled?: boolean }) => {
    const visibleFilters = useSelector(selectFilterBarFilters);

    const hasFilters = useSelector(selectHasActiveVisibleFilters);

    const [filterRef, isAnimating] = useFilterAnimation(visibleFilters);

    const compactSegments = visibleFilters?.length > 3;

    const visible = filterVisibility.visible;
    const shellVisible = filterVisibility.shellVisible;

    if ((shellVisible && !visible) || disabled) return <FiltersShell />;

    if (!visible) {
      return null;
    }
    return (
      <div
        id="filtersPanel"
        style={{ height: `${STICKY_FILTER_HEIGHT}px` }}
        className={cn('filters', {
          active: hasFilters,
          flash: isAnimating,
        })}
        ref={filterRef as React.MutableRefObject<HTMLDivElement>}
      >
        <FilterItemList visibleFilters={visibleFilters} />
        {showSegments && (
          <div className="actions">
            {hasFilters && <ResetFilter compact={compactSegments} />}
            <div className="filtergroup-buttons">
              {hasFilters && <SaveFilterGroup compact={compactSegments} />}
              {hasFilters && (
                <WithCanUpdateSegment>
                  <UpdateFilterGroup compact={compactSegments} />
                </WithCanUpdateSegment>
              )}
            </div>
            <SavedFilterGroups />
          </div>
        )}
      </div>
    );
  },
);

export default Filters;
