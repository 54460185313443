import React from 'react';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { t } from 'Utilities/i18n';
import Cell from './HelperComponents/AccuCell';
import './styles.scss';

const AiSearchVolumePlaceholderCell = (): JSX.Element | null => {
  return (
    <Cell rightAligned>
      <AccTooltip tooltip={t('Import keyword to see AI Search Volume')}>
        <span className={'blur-ai-sv'}>1234</span>
      </AccTooltip>
    </Cell>
  );
};

export default AiSearchVolumePlaceholderCell;
