import { Box } from '@mantine/core';
import { AccDropdownMenu } from 'Components/AccDropdownMenu';
import SimpleDropdownButton from 'Components/Controls/Dropdowns/SimpleDropdownButton';
import Skeleton from 'Components/Skeleton';
import { t } from 'Utilities/i18n';
import { useTableStore } from '../../../../../Components/DataTable';
import { TableIDs } from '../../../../../Types/Table';
import { OveruseRangeInput } from '../support/types';

type DateRangeProps = {
  range: OveruseRangeInput;
  rangeList: OveruseRangeInput[];
  setRange: (a: OveruseRangeInput) => void;
};

const formatRange = (range: OveruseRangeInput) => `${range?.periodFrom} - ${range?.periodTo}`;

export const DateRange = ({ range, rangeList, setRange }: DateRangeProps) => {
  const tableStore = useTableStore(TableIDs.ACCOUNT_KEYWORD_OVERUSE);

  const onRangeSelect = (nextRange: OveruseRangeInput) => {
    setRange(nextRange);
    setTimeout(() => tableStore?.getData(), 300);
  };

  if (!rangeList) {
    return null;
  } else if (!range?.periodTo || !range?.periodFrom) {
    return (
      <Box mb="sm">
        <Skeleton linesConfig={[{ type: 'text', options: { width: '144px', height: '35px' } }]} />
      </Box>
    );
  }
  const labelValue = {
    value: formatRange(range),
    label: t('Billing period'),
  };

  return (
    <AccDropdownMenu
      onSelect={(item) => {
        onRangeSelect(item?.value as any);
      }}
      items={
        rangeList?.map((e) => ({
          value: e,
          label: formatRange(e),
        })) ?? []
      }
    >
      <div className="menu-toggle">
        <SimpleDropdownButton
          item={labelValue}
          labelFunc={(item: any) => item.value}
          title={labelValue.label}
        />
      </div>
    </AccDropdownMenu>
  );
};
