import { useIntl } from 'react-intl';
import { LineChart } from 'Components/Chart/LineChart';
import { TooltipConfiguration, chartTooltipTypes } from 'Components/Chart/LineChart/support/types';
import { useQueryNotesByKeywordId } from 'Components/Chart/Notes/support/hooks';
import { useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import { Metrics, useMetrics } from 'Hooks/data/metrics/useMetrics';
import { useHistoryChartData } from './hook';
import styles from './keyword-chart.module.scss';

type Props = {
  keywordId: string;
  domain?: string;
};

export const KeywordHistoryChart = (props: Props) => {
  const visibility = { [props.domain || '']: true };
  const { colorMap } = useQueryDomainInfo();
  const { data, isLoading } = useHistoryChartData(props.keywordId, props.domain);
  const { notes, isLoadingNotes } = useQueryNotesByKeywordId(props.keywordId);
  const intl = useIntl();

  const tooltipConfig: TooltipConfiguration = {
    type: chartTooltipTypes.MULTI,
    customNumFormatter: (num) =>
      intl?.formatNumber(num, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        style: 'decimal',
      }),
  };
  const { metricToYAxisLabel } = useMetrics();

  return (
    <div className={styles.container}>
      <div className={styles.chart}>
        <LineChart
          series={data}
          isLoading={isLoading || isLoadingNotes}
          tooltip={tooltipConfig}
          notes={notes}
          loaderType={'default'}
          colors={colorMap}
          visibility={visibility}
          disableLegend={false}
          multiLineNavigator
          isRank={true}
          stoked
          yAxisLabel={metricToYAxisLabel(Metrics.Rank)}
        />
      </div>
    </div>
  );
};
