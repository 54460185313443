import { useState } from 'react';
import { captureMessage } from '@sentry/react';
import { reduxForm } from 'redux-form';
import AccButton from 'Components/AccButton/AccButton';
import { AccSelect } from 'Components/AccSelect';
import { useTableStore } from 'Components/DataTable';
import { DomainItemComponent } from 'Components/DomainItemComponent';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import { useMoveKeywordsFormGroupsAndDomainsQuery } from 'Ghql';
import toast from 'Hooks/useToast';
import formStyle from 'css/layout/form-layout.module.scss';
import useKeyword from '../../../../../Hooks/useKeyword';
import type { FilterBase } from '../../../../../Types/Filter';
import { TableIDs } from '../../../../../Types/Table';
import { t } from '../../../../../Utilities/i18n';

type Props = {
  domainId: string;
  isAllSelected: boolean;
  keywords: number[];
  filters: FilterBase[];
  onClose: (...args: Array<any>) => any;
  refresh?: (...args: Array<any>) => any;
  // auto
  invalid: boolean;
  submitting: boolean;
  handleSubmit: (...args: Array<any>) => any;
};

type DomainSelectItem = { value: string; label: string; group: string; domain: string };

const useSelectOptions = (currentDomain: string) => {
  const { loading, data } = useMoveKeywordsFormGroupsAndDomainsQuery();

  if (loading || !data) return [];

  const options: DomainSelectItem[] = data.clients.flatMap((client) =>
    (client.domains
      ? client.domains
          .filter((domain) => domain && domain.id !== currentDomain)
          .map((domain) => ({
            value: domain!.id,
            label: domain!.displayName || domain!.domain,
            group: client.name,
            domain: domain!.domain,
          }))
      : []),
  );

  return options;
};

const MoveKeywordsForm = (props: Props) => {
  const tableStore = useTableStore(TableIDs.KEYWORDS);
  const {
    invalid,
    submitting,
    domainId,
    filters,
    isAllSelected,
    onClose,
    refresh,
    keywords,
    handleSubmit,
  } = props;
  const { updateKeywordsMoveToDomain } = useKeyword();

  const options: DomainSelectItem[] = useSelectOptions(domainId);

  const [value, setValue] = useState<string | null>(null);

  const handleInternalSubmit = () => {
    if (!value) {
      captureMessage('MoveKeywordsForm: value is null in submit');
      toast.error(t('Oops! Something went wrong'));
      return;
    }
    tableStore?.deleteSelectedItems();
    return updateKeywordsMoveToDomain({
      moveToDomain: parseInt(value),
      filters,
      isAllSelected,
      keywords,
    }).then(() => {
      toast.success(t('Updated'));
      refresh?.();
      onClose?.();
    });
  };

  return (
    <form className="move-keywords-form" onSubmit={handleSubmit(handleInternalSubmit)}>
      <div className={formStyle.formRow}>
        <div className="form-label required">{t('Domain')}</div>
        <AccSelect
          groupHidden={false}
          value={value}
          onChange={setValue}
          options={options}
          searchable={true}
          autoFocus={true}
          itemComponent={DomainItemComponent}
          filter={(filter, item) =>
            item.label.toLowerCase().includes(filter.toLowerCase()) ||
            item.domain.toLowerCase().includes(filter.toLowerCase())
          }
          placeholder={t('Domain')}
        />
      </div>
      <ModalFooter
        primaryButtonSlot={
          <AccButton disabled={!value || invalid || submitting} type="submit" variant="primary">
            {t('Save')}
          </AccButton>
        }
        secondaryButtonSlot={
          <AccButton type="button" variant="tertiary" onClick={onClose}>
            {t('Cancel')}
          </AccButton>
        }
      />
    </form>
  );
};

export default reduxForm({
  form: 'MoveKeywordsForm',
})(MoveKeywordsForm);
