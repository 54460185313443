import React, { useCallback, useEffect, useRef } from 'react';
import isNumber from 'lodash/isNumber';
import { useActive_SessionsQuery } from 'Ghql';
import { appStorage } from 'Utilities/AppStorage';

const ACTIVE_SESSION_CHECK_TIME = 60 * 1000;
/**
 * 1. If we have multiple tabs open and we close modal on one of the tab, we need to hide it on other too
 * 2. We check some time before
 */
export const useSessionSync = (onClose: (notify?: boolean) => void) => {
  const { refetch: refetchActiveSessions } = useActive_SessionsQuery({
    fetchPolicy: 'network-only',
  });
  const checkModalStatus = useCallback(() => {
    if (appStorage.getSessionModalClosed()) {
      onClose();
    }
  }, []);

  const intervalRef = useRef<number | undefined>();
  React.useEffect(() => {
    intervalRef.current = setInterval(() => {
      refetchActiveSessions().then((r) => {
        if (isNumber(r?.data?.activeSessions) && r?.data?.activeSessions === 0) {
          onClose(true);
        }
      });
    }, ACTIVE_SESSION_CHECK_TIME) as unknown as number;

    return () => clearInterval(intervalRef.current);
  }, []);

  return useEffect(() => {
    window.addEventListener('storage', checkModalStatus);
    if (appStorage.getSessionModalClosed()) {
      appStorage.setSessionModalClosed(false);
    }

    return () => {
      window.removeEventListener('storage', checkModalStatus);
    };
  }, []);
};
