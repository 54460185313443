import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import type { FilterGroup } from 'Types/Filter';
import type { FilterSet } from 'Types/FilterSet';
import { getFilterSetType } from 'Types/FilterSet';
import { StoreType } from 'Types/Store';

const filterGroupsSelector = (state: StoreType) => state.filter.filterGroups;
export const filterGroupSelector = (state: StoreType) => state.filter.filterGroup;
export const filterGroupFiltersSelector = (state: StoreType) =>
  filterGroupSelector(state)?.filters ?? [];

const filterSetSelector = (state: StoreType) => state.filter.filterSet;
export const useFilterSet = () => useSelector(filterSetSelector);
export const filterPristineSelector = (state: StoreType) => state.filter.pristine;

export default createSelector(
  [filterGroupsSelector, filterSetSelector],
  (filterGroups: FilterGroup[], filterSet: FilterSet) => {
    return filterGroups.filter((filterGroup) => {
      const filterGroupType = parseInt(filterGroup.type.split('_')[1], 10);
      const filterSetType = getFilterSetType(filterSet);
      return filterGroupType === filterSetType;
    });
  },
);
