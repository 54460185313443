/* eslint-disable react/display-name */
import { SimpleGrid } from '@mantine/core';
import { Field } from 'Components/Fields';
import AccText from 'Components/Text/AccText';
import { t } from 'Utilities/i18n';
import { FieldName, KeywordSettingsFieldName } from '../types';

export default () => {
  return (
    <div>
      <AccText variant="label">{t('Advanced settings')}</AccText>
      <SimpleGrid cols={2}>
        <Field.Checkbox
          name={`${FieldName.KEYWORD_SETTINGS}.${KeywordSettingsFieldName.STARRED}`}
          label={t('Star Keywords')}
        />
        <Field.Checkbox
          name={`${FieldName.KEYWORD_SETTINGS}.${KeywordSettingsFieldName.IGNORE_IN_SHARE_OF_VOICE}`}
          label={t('Ignore in Share of Voice')}
          hint={t('This is usually used for branded keywords')}
        />
      </SimpleGrid>
    </div>
  );
};
