const TYPE = 'type';
const REPORT_FOR = 'report_for';
const DATE = 'date';
const ACTIONS = 'actions';
export const ColumnIDs = {
  TYPE,
  REPORT_FOR,
  DATE,
  ACTIONS,
};
