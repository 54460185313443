import AccButton from 'Components/AccButton/AccButton';
import { t } from 'Utilities/i18n';

type Props = {
  onBack: (...args: Array<any>) => any;
  acceptRequest: (...args: Array<any>) => any;
  declineRequest: (...args: Array<any>) => any;
  loadingDecline: boolean;
  loadingAccept: boolean;
  loadingQuery: boolean;
  isExpiredLink: boolean;
  inviteSentFromOwnAccount: boolean | null;
  name: string | null;
};
export default function MultiAccountActionButtons(props: Props) {
  const { name, loadingQuery, isExpiredLink, inviteSentFromOwnAccount } = props;
  const showControls =
    name !== null && !loadingQuery && !isExpiredLink && !inviteSentFromOwnAccount;
  return (
    <div className="base-card-footer">
      <div className="base-card-button-container">
        <AccButton mr={10} variant="tertiary" disabled={props.loadingQuery} onClick={props.onBack}>
          {t('Back')}
        </AccButton>
        {showControls && (
          <div className="base-card-button-container--actions">
            <AccButton
              variant="destructive"
              onClick={props.declineRequest}
              loading={props.loadingDecline}
              disabled={props.loadingQuery}
            >
              {t('Decline')}
            </AccButton>
            <AccButton
              variant="primary"
              onClick={props.acceptRequest}
              loading={props.loadingAccept}
              disabled={props.loadingQuery}
            >
              {t('Allow access')}
            </AccButton>
          </div>
        )}
      </div>
    </div>
  );
}
