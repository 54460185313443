import { useState } from 'react';
import uniq from 'lodash/uniq';
import { useTableStore } from 'Components/DataTable';
import { useModal } from 'Hooks/base/useModal';
import useKeyword from 'Hooks/useKeyword';
import { TableIDs } from 'Types/Table';
import { t } from 'Utilities/i18n';
import ModalBorder from '../../Layout/ModalBorder';
import AddTagsForm from './AddTagsForm';

type Props = {
  keywords: string[];
  isAllSelected?: boolean;
  filters?: any[];
  onClose?: (...args: Array<any>) => any;
};

const AddTags = (props: Props) => {
  const tableStore = useTableStore(TableIDs.KEYWORDS);
  const { keywords, isAllSelected, filters, onClose } = props;

  const [loading, setLoading] = useState(false);
  const { updateKeywordsTags } = useKeyword();
  const { hideModal } = useModal();

  const handleSubmit = ({ tags }) => {
    setLoading(true);
    tableStore?.updateSelectedItems((data) => {
      data.tags = uniq([...(data?.tags ?? []), ...(tags || [])]);
    });
    updateKeywordsTags({
      keywords: keywords.map((keyword) => parseInt(keyword)),
      isAllSelected,
      filters,
      tags,
      remove: false,
    }).then(() => {
      setLoading(false);
      onClose && onClose();

      hideModal();
    });
  };

  return (
    <ModalBorder className="add-tags" title={t('Add Tags')} onClose={hideModal}>
      <AddTagsForm
        initialValues={{
          tags: [],
        }}
        loading={loading}
        onCancel={hideModal}
        onSubmit={handleSubmit}
      />
    </ModalBorder>
  );
};

export default AddTags;
