import ErrorBoundary from 'Components/ErrorBoundary';
import KpiBox from 'Pages/Keywords/Overview/components/KpiBar/KpiBox';
import { KPI_NAMES } from 'Pages/Keywords/Overview/components/KpiBar/index';
import { t } from 'Utilities/i18n';
import { KpiBarQueryProps } from './useKpiBarQueryWithDisplayCurrency';

const AiShareOfVoiceKpiBox = ({
  size,
  kpiBarQueryProps,
  draggable,
}: {
  size: 'large' | 'small';
  kpiBarQueryProps: KpiBarQueryProps;
  draggable?: boolean;
}) => {
  const { displayCurrency, data, loading } = kpiBarQueryProps;

  const sovValue = data?.kpis?.allKpis?.aiShareOfVoiceSum ?? undefined;
  const sovBeforeValue = data?.kpis?.allKpis?.aiShareOfVoiceSumBefore ?? undefined;

  return (
    <ErrorBoundary>
      <KpiBox
        id={KPI_NAMES.AI_SHARE_OF_VOICE}
        size={size}
        title={t('AI Share of Voice')}
        loading={loading || !displayCurrency}
        value={sovValue}
        beforeValue={sovBeforeValue}
        precision={0}
        maxValue={data?.kpis?.allKpis?.maxAiShareOfVoiceSum}
        draggable={draggable}
      />
    </ErrorBoundary>
  );
};

export default AiShareOfVoiceKpiBox;
