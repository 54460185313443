import FieldWorkspace from 'Components/Fields/Field/FieldWorkspace';
import { defaultRole } from 'Constants/workspaces';
import { useUpdateUserOnChange } from './useUpdateUserOnChange';

type Props = {
  record: Record<string, any>;
};

const WorkspaceCell = ({ record }: Props) => {
  const prefix = `${record.id}_`;
  useUpdateUserOnChange(record);
  return (
    <FieldWorkspace
      name={`${prefix}workspaces`}
      defaultValue={record.workspaces?.map((role) => role?.id).filter(Boolean)}
      defaultUserRole={record.userType || defaultRole}
      userRoleFieldName={`${prefix}userRole`}
      searchMinWidth={50}
      size="sm"
      withinPortal
    />
  );
};

export default WorkspaceCell;
