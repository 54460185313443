import { Flex } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import { t } from 'Utilities/i18n';
import { RulesActiveGroupEvents } from '../../../support/types';
import { BuilderTokenActions } from '../support/hooks';

interface TokenGroupActionsProps {
  actions: BuilderTokenActions;
  hideDelete?: boolean;
  hideAddGroup?: boolean;
  activeEvents?: RulesActiveGroupEvents;
}
export const TokenGroupActions: React.FC<React.PropsWithChildren<TokenGroupActionsProps>> = ({
  actions,
  hideDelete,
  hideAddGroup,
}) => {
  return (
    <Flex align="center">
      <div>
        <AccButton h={32} p={'0 14px'} onClick={actions.addItem} variant="secondary" mr={8}>
          + {t('Add rule')}
        </AccButton>
        {!hideAddGroup && (
          <AccButton h={32} p={'0 14px'} onClick={actions.addGroupItem} variant="secondary">
            + {t('Add rule group')}
          </AccButton>
        )}
      </div>
      {!hideDelete && (
        <AccButton
          h={32}
          p={'0 14px'}
          onClick={actions.deleteGroup}
          variant="secondary"
          ml={8}
          leftSection={<IconTrash size={16} />}
        >
          {t('Delete group')}
        </AccButton>
      )}
    </Flex>
  );
};
