import isNil from 'lodash/isNil';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import Cell from 'Components/AccuTable/CellRenderer/HelperComponents/AccuCell';
import { CellRenderer } from 'Components/DataTable';
import FormatNumber from 'Components/FormatNumber';
import { t } from 'Utilities/i18n';
import { ChangeCell } from './ChangeCell';
import { CombinedChangeCell, PercentageChangeCell } from './CombinedChangeCell';
import { CombinedNumberCell } from './CombinedNumberCell';
import { NumberCell } from './NumberCell';

export const createCombinedChangeCell = <T extends {}>(config: {
  percentageKey: keyof T;
  percentageCompareKey: keyof T;
  valueKey: keyof T;
  compareKey: keyof T;
}) => {
  const resultComponent = ({ record, ...props }: any) => {
    const data = {
      currentValuePercentage: record?.[config.percentageKey],
      comparePercentage: record?.[config.percentageCompareKey],
      currentValue: record?.[config.valueKey],
      compare: record?.[config.compareKey],
    };
    return <CombinedChangeCell {...props} record={data} />;
  };
  resultComponent.displayName = 'CombinedChangeCell';

  return resultComponent;
};

export const createChangeCell = <T extends {}>(config: {
  valueKey: keyof T;
  deltaKey: keyof T;
}) => {
  const resultComponent = ({ record }: any) => (
    <ChangeCell currentValue={record?.[config.valueKey]} delta={record?.[config.deltaKey]} />
  );
  resultComponent.displayName = 'ChangeCell';

  return resultComponent;
};

export const createNumberCell = <T extends {}>(
  valueKey: keyof T,
  currency: boolean = false,
  minimumFractionDigits: number | undefined = undefined,
  maximumFractionDigits: number | undefined = undefined,
) => {
  const resultComponent = ({ record }: any) => (
    <NumberCell
      value={record?.[valueKey]}
      currency={currency}
      minimumFractionDigits={minimumFractionDigits}
      maximumFractionDigits={maximumFractionDigits}
    />
  );
  resultComponent.displayName = 'NumberCell';

  return resultComponent;
};

export const createCombinedNumberCell = <T extends {}>(config: {
  percentageKey: keyof T;
  valueKey: keyof T;
}) => {
  const result = ({ record, ...props }: any) => {
    const data = {
      currentValuePercentage: record?.[config.percentageKey],
      currentValue: record?.[config.valueKey],
    };
    return <CombinedNumberCell {...props} record={data} />;
  };
  result.displayName = 'CombinedNumberCell';

  return result;
};

export const createPercentageChangeCell = <T extends {}>(config: {
  percentageKey: keyof T;
  percentageCompareKey: keyof T;
}) => {
  const result = ({ record, ...props }: any) => {
    const data = {
      currentValuePercentage: record?.[config.percentageKey],
      comparePercentage: record?.[config.percentageCompareKey],
    };
    if (isNil(data.currentValuePercentage) || isNil(data.comparePercentage)) {
      return (
        <Cell rightAligned>
          <AccTooltip tooltip={t('No value for this date')}>
            <span>?</span>
          </AccTooltip>
        </Cell>
      );
    }

    return <PercentageChangeCell {...props} record={data} />;
  };
  result.displayName = 'PercentageChangeCell';

  return result;
};

export const createPercentageCell = <T extends object>(config: {
  percentageKey: keyof T;
  tooltip?: string;
  emptyValue?: string;
  multiplyByHundred?: boolean;
  precision?: number;
}): CellRenderer<T> => {
  const result = ({ record }) => {
    const currentValuePercentage = record?.[config.percentageKey];
    if (isNil(currentValuePercentage)) {
      const emptyValue = config?.emptyValue ?? null;
      return config.tooltip ? (
        <Cell rightAligned>
          <AccTooltip tooltip={config.tooltip}>
            <span>?</span>
          </AccTooltip>
        </Cell>
      ) : (
        emptyValue
      );
    }

    const valueToShow = config.multiplyByHundred
      ? currentValuePercentage * 100
      : currentValuePercentage;

    return (
      <Cell rightAligned>
        <FormatNumber percentage precision={config.precision || 2}>
          {valueToShow}
        </FormatNumber>
      </Cell>
    );
  };
  result.displayName = 'PercentageCell';

  return result;
};
