import { useContext } from 'react';
import { DragElementWrapper, useDrop } from 'react-dnd';
import { ItemTypes } from 'Pages/Keywords/Groupings/EditMode/react-dnd-treeview/ItemTypes';
import { useTreeContext } from 'Pages/Keywords/Groupings/EditMode/react-dnd-treeview/hooks/index';
import { PlaceholderContext } from 'Pages/Keywords/Groupings/EditMode/react-dnd-treeview/providers';
import { NodeModel } from 'Pages/Keywords/Groupings/EditMode/react-dnd-treeview/types';
import {
  getDropTarget,
  isDroppable,
  isNodeModel,
} from 'Pages/Keywords/Groupings/EditMode/react-dnd-treeview/utils';
import { hideLine, moveLine } from '../../components/TargetLine';

export const useDropRoot = <T>(
  ref: React.RefObject<HTMLElement>,
): [boolean, NodeModel, DragElementWrapper<HTMLElement>] => {
  const treeContext = useTreeContext<T>();
  const placeholderContext = useContext(PlaceholderContext);
  const [{ isOver, dragSource }, drop] = useDrop({
    accept: [ItemTypes.TREE_ITEM, ...treeContext.extraAcceptTypes],
    drop: (dragItem: any, monitor) => {
      const { onDrop } = treeContext;

      hideLine();
      if (
        monitor.isOver({ shallow: true }) &&
        placeholderContext?.dropTargetId !== undefined &&
        placeholderContext?.index !== undefined
      ) {
        // If the drag source is outside the react-dnd,
        // a different object is passed than the NodeModel.
        onDrop(
          isNodeModel(dragItem) ? dragItem : null,
          placeholderContext?.dropTargetId,
          placeholderContext?.index,
          placeholderContext?.direction,
        );
      }

      placeholderContext.hidePlaceholder();
    },
    canDrop: (dragItem, monitor) => {
      const { rootId } = treeContext;

      if (monitor.isOver({ shallow: true })) {
        if (dragItem === undefined) {
          return false;
        }

        return isDroppable(dragItem?.id, rootId, treeContext);
      }

      return false;
    },
    hover: (dragItem, monitor) => {
      if (monitor.isOver({ shallow: true })) {
        const { dropTargetId, index, showPlaceholder, hidePlaceholder } = placeholderContext;

        const dropTarget = getDropTarget<T>(null, ref.current, monitor, treeContext);

        const dropId = dropTarget?.id ?? treeContext.rootId;

        if (!monitor.canDrop()) {
          hideLine();
          hidePlaceholder();
          return;
        }

        if (dropId === null) {
          hidePlaceholder();
          return;
        }

        if (dropId !== dropTargetId || dropTarget?.index !== index) {
          showPlaceholder(dropId, dropTarget?.index ?? 0, undefined);
          moveLine(treeContext, dropId, undefined, dropTarget?.index);
        }
      }
    },
    collect: (monitor) => {
      const source: NodeModel = monitor.getItem();
      return {
        isOver: monitor.isOver({ shallow: true }) && monitor.canDrop(),
        dragSource: source,
      };
    },
  });

  return [isOver, dragSource, drop];
};
