import { useMantineTheme } from '@mantine/core';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import { AccFastIcon } from 'Components/AccFastIcon';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import AccessControl from 'Components/AccessControl';
import {
  KEYWORDS_PAGE,
  LinkIconWithFolderFilter,
  OVERVIEW_PAGE,
} from 'Components/Filters/LinkWithFolders/linkWithFolders';
import { ProcessedFolderTreeNode } from 'Pages/Domains/TreeView/support/types';
import { getDomainId } from 'Utilities/generateSelectId';
import { t } from 'Utilities/i18n';
import { IconFolderPlus } from 'icons/tag-cloud';
import IconTagPlus from 'icons/tag-plus.svg';
import { useFolderActions } from './useFolderActions';

type FolderActionsProps = {
  rootNode: ProcessedFolderTreeNode;
  showAction: boolean;
  isDemoDomain: boolean;
};

// eslint-disable-next-line import/no-unused-modules
export const FolderActions = ({ rootNode, showAction, isDemoDomain }: FolderActionsProps) => {
  const iconColor = useMantineTheme().colors.snorlax[4];
  const { handleAddTag, handleAddFolder } = useFolderActions(rootNode);
  const domainId = getDomainId(rootNode.path);
  if (!showAction) return null;

  return (
    <>
      <LinkIconWithFolderFilter
        path={rootNode.path}
        isFolder={true}
        name={rootNode.name}
        depth={rootNode.depth}
        pageToGoTo={OVERVIEW_PAGE}
        tooltipText={t('Go to dashboard and filter by folder')}
        domainId={domainId}
      />

      <LinkIconWithFolderFilter
        path={rootNode.path}
        isFolder={true}
        name={rootNode.name}
        depth={rootNode.depth}
        pageToGoTo={KEYWORDS_PAGE}
        tooltipText={t('Go to keywords list and filter by folder')}
        domainId={domainId}
      />
      <AccessControl>
        {!isDemoDomain && (
          <>
            <AccTooltip tooltip={t('Create tag')} old>
              <AccActionIcon variant="subtle" onClick={handleAddTag}>
                <AccFastIcon src={IconTagPlus} size={18} color={iconColor} />
              </AccActionIcon>
            </AccTooltip>

            <AccTooltip tooltip={t('Create folder')} old>
              <AccActionIcon variant="subtle" onClick={handleAddFolder}>
                <AccFastIcon src={IconFolderPlus} size={18} color={iconColor} />
              </AccActionIcon>
            </AccTooltip>
          </>
        )}
      </AccessControl>
    </>
  );
};
