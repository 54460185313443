import { Component } from 'react';
import { connect } from 'react-redux';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import compose from 'lodash/flowRight';
// actions
import { hideModal } from 'Actions/ModalAction';
import { linkToImportGSCWithDomains } from 'Components/Filters/LinkToDomain';
import AccountWizard, { STEPS, withProps } from 'Components/Modal/Wizard/Base/Account';
import toast from 'Hooks/useToast';
import { apolloClient } from 'Store';
import { IntegrationOAuthProviders } from 'Types/Integration';
import { withRouter } from 'Utilities/Router';
import { t } from 'Utilities/i18n';
import { graphqlOK } from 'Utilities/underdash';
import ConnectOAuthAccount from '../Common/ConnectOAuthAccount';
import SaveGoogleAccount from '../Common/GoogleAccounts/SaveGoogleAccount';
// steps
import SelectGoogleAccount from '../Common/GoogleAccounts/SelectGoogleAccount';
import { Group } from '../ConnectToGA/Group';
import GSCBulkWebsites from './GSCBulkWebsites';
import GSCWebsite from './GSCWebsite';

type OptionalGSCProps =
  | {
      domainId: string;
      bulkConnect?: never;
    }
  | {
      domainId?: never;
      bulkConnect: true;
    };

type Props = {
  refresh?: (...args: Array<any>) => any;
  oAuthResult?: Record<string, any>;
  skipImportKeywords?: boolean; // don't redirect to secret import page after this modal
  // automatic
  history: Record<string, any>;
  domainData: Record<string, any>;
  hideModal: (...args: Array<any>) => any;
  removeAccount: (...args: Array<any>) => any;
} & OptionalGSCProps;

const GSC_WEBSITE = 'selectGSCWebsite';
const GROUP = 'selectGroupStep';

class ConnectToGSC extends Component<Props> {
  handleRemoveAccount = () => {
    const { domainId, refresh } = this.props;
    this.props
      .removeAccount({
        variables: {
          input: {
            domainId,
          },
        },
      })
      .then(
        () => {
          toast.success(t('Account removed'));
          this.props.hideModal();
          //Invalidate cache
          apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName: 'user' });
          apolloClient.cache.gc();
          refresh && refresh();
        },
        () => {
          toast.error(t('Failed to remove account'));
        },
      );
  };
  handleSelectWebsite = () => {
    const { domainId, refresh, skipImportKeywords } = this.props;
    this.props.hideModal();
    refresh && refresh();
    if (!skipImportKeywords && domainId) {
      this.props.history.push(linkToImportGSCWithDomains(domainId));
    }
  };
  getConnectionId = (data) => {
    const source = data[STEPS.SELECT] || data[STEPS.SAVE];
    return source ? source.connectionId : null;
  };
  getGSCSteps = () => [
    {
      name: GSC_WEBSITE,
      title: t('Connect to Search Console'),
      component: ({ stepTo, data }) => (
        <>
          {this.props.bulkConnect ? (
            <GSCBulkWebsites
              onBack={() => stepTo(GROUP)}
              connectionId={this.getConnectionId(data) || ''}
              onSubmit={this.handleSelectWebsite}
              groupId={data?.[GROUP]?.groupId}
            />
          ) : (
            <GSCWebsite
              onBack={() => stepTo(STEPS.SELECT)}
              domainId={this.props.domainId}
              connectionId={this.getConnectionId(data) || ''} // TODO why do we need empty string when no connection?
              onSubmit={this.handleSelectWebsite}
            />
          )}
        </>
      ),
    },
    // Bulk connect requires an additional step to select a domainGroup.
    {
      name: GROUP,
      title: t('Select Group'),
      component: ({ stepTo }) => {
        return (
          <Group onSubmit={withProps(stepTo, GSC_WEBSITE)} onBack={() => stepTo(STEPS.SELECT)} />
        );
      },
    },
  ];

  render() {
    if (!graphqlOK(this.props, ['oAuthResult'], ['oAuthResult'])) {
      return null;
    }

    const account = this.props.domainData?.domain?.googleOauthConnectionGsc;
    const { domainId, oAuthResult, bulkConnect } = this.props;

    return (
      <AccountWizard
        className="connect-to-gsc"
        step={!oAuthResult ? STEPS.SELECT : STEPS.SAVE}
        selectStep={{
          title: t('Select Google Connection'),
          component: ({ stepTo }) => (
            <SelectGoogleAccount
              accountId={account && account.id}
              onAdd={() => stepTo(STEPS.CONNECT)}
              onRemove={this.handleRemoveAccount}
              handleSubmit={withProps(stepTo, bulkConnect ? GROUP : GSC_WEBSITE)}
              onCancel={this.props.hideModal}
            />
          ),
        }}
        connectStep={{
          title: t('Add Google Connection'),
          component: () => (
            <ConnectOAuthAccount
              modalParams={{
                modalType: 'ConnectToGSC',
                modalTheme: 'light',
                modalProps: {
                  domainId,
                  integration: IntegrationOAuthProviders.GOOGLE_ACCOUNT,
                },
              }}
              onCancel={this.props.hideModal}
            />
          ),
        }}
        saveStep={{
          title: t('Add Google connection'),
          component: ({ stepTo }) => (
            <SaveGoogleAccount
              onSubmit={withProps(stepTo, GSC_WEBSITE)}
              onCancel={withProps(stepTo, STEPS.CONNECT)}
              oAuthResult={oAuthResult}
            />
          ),
        }}
        postSteps={this.getGSCSteps()}
      />
    );
  }
}

const removeAccountQuery = gql`
  mutation connectToGSC_removeGoogleSearchConsoleAccount(
    $input: RemoveGoogleSearchConsoleAccountInput!
  ) {
    removeGoogleSearchConsoleAccount(input: $input) {
      domain {
        id
      }
    }
  }
`;
const domainQuery = gql`
  query connectToGSC_domain($id: ID!) {
    domain(id: $id) {
      id
      defaultCountrylocale {
        region
      }
      googleOauthConnectionGsc {
        id
      }
    }
  }
`;

export default compose(
  withRouter,
  connect(null, {
    hideModal,
  }),
  graphql(removeAccountQuery, {
    name: 'removeAccount',
  }),
  graphql(domainQuery, {
    name: 'domainData',
    skip: (props) => !props.domainId,
    options: (props: Props) => ({
      variables: {
        id: props.domainId,
      },
    }),
  }),
)(ConnectToGSC);
