import React, { useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { unstable_usePrompt } from 'react-router-dom';
import uniq from 'lodash/uniq';
import { TreeRowComponent, TreeTable } from 'Components/Table/TreeTable';
import { useClearSelectedOnUnmount } from 'Hooks/useSelectedNodes';
import reusableStyles from 'css/reusable-styles.module.scss';
import Loader from '../../../../Components/Loader';
import { STICKY_FILTER_HEIGHT } from '../../../../Constants/sticky';
import { t } from '../../../../Utilities/i18n';
import { AutoScrollWrapper } from './components/AutoScrollArea';
import { DragPreviewRender } from './components/DragPreviewRender';
import { EditModeEmptyContent } from './components/EditModeEmptyContent';
import { EditModeHeader, EditModeHeaderContext } from './components/EditModeHeader/EditModeHeader';
import { GroupEditActions } from './components/GroupEditActions';
import NodeItem from './components/NodeItem/NodeItem';
import { RootWrapper } from './components/RootWrapper';
import { TargetLine, hideLine } from './components/TargetLine';
import { DragLayer } from './react-dnd-treeview/DragLayer';
import { PlaceholderProvider } from './react-dnd-treeview/providers/PlaceholderProvider';
import { TreeProvider } from './react-dnd-treeview/providers/TreeProvider';
import { ROOT_ID, UNCATEGORIZED_FOLDER_ID } from './support/constants';
import { useAnimatedMove } from './support/hooks/useAnimatedMove';
import { useDeleteNode } from './support/hooks/useDeleteNode';
import { useHandleGroupCanDrop } from './support/hooks/useGroupCanDrop';
import { useGroupData } from './support/hooks/useGroupData';
import { useHandleUpdateState } from './support/hooks/useHandleUpdateState';
import useMultiSelectDragAndDropControls from './support/hooks/useMultiSelectDragAndDropControls';
import { useOnAdd } from './support/hooks/useOnAdd';
import { useOnDrop } from './support/hooks/useOnDrop';
import { useOnUpdateNode } from './support/hooks/useOnUpdateNode';
import { FolderStructureData } from './support/types';

type EditModeProps = {
  flatData: FolderStructureData[];
  setEditMode: (string) => void;
  initialExpanded?: string[];
  setInitialExpanded: (string) => void;
  loading?: boolean;
  refetch?(): void;
};

const EditMode = ({
  flatData,
  initialExpanded,
  setInitialExpanded,
  refetch,
  setEditMode,
  loading: loadingData,
}: EditModeProps) => {
  const [expandedNodes, setExpandedNodes] = useState(initialExpanded);
  useClearSelectedOnUnmount();

  const [isDirty, setIsDirty] = useState(false);
  unstable_usePrompt({
    when: isDirty,
    message: t('You have unsaved changes. Are you sure you want to leave?'),
  });

  const addExpandedNode = (node) => {
    setExpandedNodes((nodes) => {
      const nextExpandedNodes = uniq([...(nodes || []), node]);
      setInitialExpanded(nextExpandedNodes);
      return nextExpandedNodes;
    });
  };
  const {
    addTarget,
    getIsEditing,
    onCancelAdd,
    onStartAdd,
    isEditingNode,
    clearEditModes,
    createSetIsEdit,
    editNodes,
    cancelAllEdits,
  } = useHandleUpdateState(addExpandedNode);

  const setNextExpandedNodes = (nodes) => {
    onCancelAdd();
    cancelAllEdits();
    setExpandedNodes(nodes);
    setInitialExpanded(nodes);
    hideLine(true);
  };

  const isAddMode = addTarget !== null;

  const {
    handleSave,
    loading,
    setTreeNested,
    resultNestedData,
    normalizedTreeFiltered,
    normalizedTree,
  } = useGroupData({
    flatData,
    initialExpanded,
    isAddMode,
    refetch,
    setEditMode,
    addTarget,
  });

  const { onSelect, getSelectedNodes, handleDragStart, setSelectedNodes, monitorRef } =
    useMultiSelectDragAndDropControls(normalizedTreeFiltered, clearEditModes);

  const { animateMoveSelected, handleDragOver } = useAnimatedMove(monitorRef);

  useClearSelectedOnUnmount();

  const onDrop = useOnDrop(
    getSelectedNodes,
    setTreeNested,
    setSelectedNodes,
    setIsDirty,
    normalizedTree,
  );

  const onAdd = useOnAdd(onCancelAdd, clearEditModes, setTreeNested, addTarget, setIsDirty);
  const onUpdateNode = useOnUpdateNode(setTreeNested, onCancelAdd, setIsDirty);
  const onUpdate = (id, patch) => {
    setIsDirty(true);
    onUpdateNode(id, patch);
    clearEditModes();
    setSelectedNodes([]);
  };
  const onDelete = useDeleteNode(setTreeNested, setSelectedNodes, setIsDirty);

  const handleCancel = () => {
    setIsDirty(false);
    clearEditModes();
    setInitialExpanded(initialExpanded);
    setSelectedNodes([]);
    onCancelAdd();
    setEditMode(false);
  };

  const canDrop = useHandleGroupCanDrop();

  const RenderRow: TreeRowComponent = ({ rootNode: node, onToggle }) => {
    return (
      <NodeItem
        node={node}
        onAdd={onAdd}
        onSelect={onSelect}
        isEdit={getIsEditing(node.id)}
        setIsEdit={createSetIsEdit(node.id)}
        onCancelEdit={onCancelAdd}
        onDelete={onDelete}
        onUpdate={onUpdate}
        onUpdateNode={onUpdateNode}
        onStartAdd={onStartAdd}
        onDrop={onDrop}
        onToggle={onToggle}
      />
    );
  };

  const tableRowKey = (addTarget?.toString() ?? '').concat(isAddMode?.toString() ?? '').concat(
    Object.entries(editNodes || {})
      .map(([k, v]) => `${k}${v}`)
      .join('-') ?? '',
  );

  const loadingElement = useMemo(
    () => (
      <Loader
        style={{
          height: '400px',
        }}
        loadingText={t('Loading…')}
      />
    ),
    [],
  );
  const isLoading = loading || loadingData;
  return (
    <>
      <GroupEditActions
        isEditingNode={isEditingNode}
        handleCancel={handleCancel}
        handleSubmit={handleSave}
        isAddMode={isAddMode}
        loading={isLoading}
      />
      <DndProvider backend={HTML5Backend}>
        <EditModeHeaderContext.Provider
          value={{
            onDrop,
            onStartAdd,
            isDirty,
            isAddMode,
            isEditingNode,
            animateMoveSelected,
            addTarget,
          }}
        >
          <div onDragOver={handleDragOver} className={reusableStyles.paper}>
            <PlaceholderProvider>
              <TreeProvider
                tree={normalizedTreeFiltered}
                expandedNodes={expandedNodes}
                onDrop={onDrop}
                rootId={ROOT_ID}
                onDragStart={handleDragStart}
                onDragEnd={() => hideLine()}
                canDrop={canDrop}
                dragPreviewRender={DragPreviewRender}
                canDrag={(node) => {
                  return !isEditingNode && node?.id !== UNCATEGORIZED_FOLDER_ID;
                }}
              >
                <RootWrapper loading={isLoading}>
                  <TargetLine />
                  <DragLayer />
                  <TreeTable
                    data={resultNestedData ?? []}
                    saveExpandedNodes={setNextExpandedNodes}
                    expandedNodes={expandedNodes}
                    tableRowKey={tableRowKey}
                    bottomWrapper={AutoScrollWrapper}
                    headerComponent={EditModeHeader}
                    rowComponent={RenderRow}
                    loading={isLoading}
                    emptyContentComponent={EditModeEmptyContent}
                    topOffset={`${STICKY_FILTER_HEIGHT}px`}
                    disableBodyOnScroll={false}
                    loadingElement={loadingElement}
                  />
                </RootWrapper>
              </TreeProvider>
            </PlaceholderProvider>
          </div>
        </EditModeHeaderContext.Provider>
      </DndProvider>
    </>
  );
};

export default EditMode;
