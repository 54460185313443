import { ChangeEvent, useEffect, useState } from 'react';
import { useField } from 'react-final-form';
import { Box, Flex } from '@mantine/core';
import AccSwitch from 'Components/AccSwitch/Switch';
import CheckBox from 'Components/Controls/Checkbox';
import AccTitle from 'Components/Title/AccTitle';
import { SearchVolumeSettingInput } from 'Ghql';
import { LocalSearchHelperText } from 'Pages/DomainSettings/support/helpers';
import { useOrganizationSelector } from 'Selectors/OrganizationSelector';
import { t } from 'Utilities/i18n';
import { FieldName } from '..';

export const SearchSettings = () => {
  const organization = useOrganizationSelector();

  const field = useField(FieldName.localSearch);
  const localSearchValue = field.input.value;
  const setLocalSearchValue = field.input.onChange;

  const accountDefaultSelected = localSearchValue === SearchVolumeSettingInput.AccountDefault;

  const [accountSettingsChecked, setAccountSettingsChecked] = useState(accountDefaultSelected);
  const accountSetting = organization?.showLocalSearchVolume ?? false;
  const [switchToggled, setSwitchToggled] = useState(accountSetting);

  useEffect(() => {
    if (accountDefaultSelected) {
      setAccountSettingsChecked(true);
      setSwitchToggled(accountSetting);
    } else {
      setAccountSettingsChecked(false);
      setSwitchToggled(localSearchValue === SearchVolumeSettingInput.Local);
    }
  }, [accountDefaultSelected, accountSetting, localSearchValue]);

  const handleCheckboxChange = (checked: boolean) => {
    setAccountSettingsChecked(checked);
    const value = checked ? SearchVolumeSettingInput.Local : SearchVolumeSettingInput.Country;
    setLocalSearchValue(checked ? SearchVolumeSettingInput.AccountDefault : value);
  };

  const handleRadioGroupChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAccountSettingsChecked(false);
    const checked = event.target.checked;
    setSwitchToggled(checked);
    const value = checked ? SearchVolumeSettingInput.Local : SearchVolumeSettingInput.Country;
    setLocalSearchValue(value);
  };

  return (
    <Flex rowGap="md" direction="column">
      <AccTitle helper={<LocalSearchHelperText />} type="h5" boxProps={{ mt: 'sm' }}>
        {t('Use local search volume')}
      </AccTitle>
      <Flex columnGap="xl" align="center">
        <Box w={70}>
          <AccSwitch
            onChange={handleRadioGroupChange}
            checked={switchToggled}
            defaultChecked={accountSetting}
            label={switchToggled ? t('Yes') : t('No')}
          />
        </Box>
        <CheckBox
          checked={accountSettingsChecked}
          onChange={(event) => handleCheckboxChange(event.target.checked)}
        >
          {t('Use account settings')}
        </CheckBox>
      </Flex>
    </Flex>
  );
};
