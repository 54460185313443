import AccTooltip from 'Components/AccTooltip/AccTooltip';
import styles from './table.module.scss';

interface Props {
  filePath: string | null | undefined;
}

// Renders a file name as either:
// * If shorter than limit: Just the filename
// * If longer than limit: <name-part><token><extension>, with the full filename in a tooltip
const Component = ({ filePath }: Props): JSX.Element | null => {
  const limit = 150;
  const token = '[..]';
  if (!filePath) return null;

  const filename = filePath.split('/').slice(-1)[0];
  if (filename.length <= limit) return <>{filename}</>;

  const idx = filename.lastIndexOf('.');
  const extension = filename.substring(idx);
  const trunkName = filename.substring(0, limit - extension.length - token.length);

  return (
    <AccTooltip tooltip={filename}>
      <span>
        {trunkName}
        <span className={styles.nowrap}>{token}</span>
        {extension}
      </span>
    </AccTooltip>
  );
};
Component.displayName = 'ImportFilename';
export default Component;
