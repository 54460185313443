import { Field, Fields } from 'redux-form';
import { getComparisonLabels } from 'Components/Filters/Common/labelFunc';
import { DropdownField, TextFieldWithFilterCount } from 'Components/Forms/Fields';
import { FilterComparison } from 'Types/Filter';

type Props = {
  comparisonOptions: string[];
  placeholder: string;
};

const comparisonOptionValues = [
  FilterComparison.EQ,
  FilterComparison.NE,
  FilterComparison.CONTAINS,
  FilterComparison.NOT_CONTAINS,
  FilterComparison.STARTS_WITH,
  FilterComparison.ENDS_WITH,
  FilterComparison.REGEX,
  FilterComparison.NOT_REGEX,
];

const KeywordsEditor = (props: Props) => {
  const comparisonLabels = getComparisonLabels();

  const comparisonOptions = comparisonOptionValues.map((optionValue) => ({
    label: comparisonLabels[optionValue],
    value: optionValue,
  }));

  return (
    <>
      <Fields
        autoFocus={true}
        names={['comparison', 'value', 'attribute', 'type']}
        component={TextFieldWithFilterCount}
        placeholder={props.placeholder}
      />
      <Field
        name="comparison"
        className="filter-button"
        component={DropdownField}
        items={comparisonOptions}
      />
    </>
  );
};

export default KeywordsEditor;
