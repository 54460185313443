import * as React from "react";
const SvgCpc = (props) => /* @__PURE__ */ React.createElement("svg", { width: "100%", height: "100%", viewBox: "0 0 19 22", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlSpace: "preserve", "xmlns:serif": "http://www.serif.com/", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinejoin: "round",
  strokeMiterlimit: 2
}, ...props }, /* @__PURE__ */ React.createElement("path", { d: "M14.5,14.5l3.5,0l0,-2l-2,0l0,-1l-2,0l0,1.1c-1.1,0.2 -2,1.2 -2,2.4c0,1.4 1.1,2.5 2.5,2.5l1,0c0.3,0 0.5,0.2 0.5,0.5c0,0.3 -0.2,0.5 -0.5,0.5l-3.5,0l0,2l2,0l0,1l2,0l0,-1.1c1.1,-0.2 2,-1.2 2,-2.4c0,-1.4 -1.1,-2.5 -2.5,-2.5l-1,0c-0.3,0 -0.5,-0.2 -0.5,-0.5c0,-0.3 0.2,-0.5 0.5,-0.5", style: {
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("clipPath", { id: "_clip1" }, /* @__PURE__ */ React.createElement("path", { d: "M-3,0l22,0l0,25l-22,0l0,-25Zm12.97,18l0,3.153l8.726,0l0,-9.795l-4.417,0c-1.393,0 -2.662,0.799 -3.263,2.055c-0.033,0.069 -0.052,0.109 -0.052,0.109l-0.994,4.478Z" })), /* @__PURE__ */ React.createElement("g", { clipPath: "url(#_clip1)" }, /* @__PURE__ */ React.createElement("path", { d: "M13,20l-7,0c-0.379,0 -0.725,-0.214 -0.895,-0.553l-3,-6c-0.069,-0.139 -0.105,-0.292 -0.105,-0.447c-0,-0.549 0.451,-1 1,-1c-0,-0 2,0 2,0c0,0 -0,-6 -0,-6c-0,-0.549 0.451,-1 1,-1c0.107,-0 0.214,0.017 0.316,0.051l3,1c0.409,0.137 0.684,0.519 0.684,0.949l0,4.219l3.242,0.811c0.444,0.111 0.758,0.513 0.758,0.97l0,6c0,0.549 -0.451,1 -1,1Zm-6.382,-2l5.382,0l0,-4.219l-3.242,-0.81c-0.444,-0.111 -0.758,-0.513 -0.758,-0.971l0,-4.279l-1,-0.333l0,5.612c0,0.549 -0.451,1 -1,1l-1.382,0l2,4Z", style: {
  fillRule: "nonzero"
} }))), /* @__PURE__ */ React.createElement("path", { d: "M0,6l3,0l0,2l-3,0l0,-2Zm2.757,-4.656l2.123,2.121l-1.415,1.415l-2.122,-2.122l1.414,-1.414Zm3.243,-1.344l2,-0l0,3l-2,-0l0,-3Zm6.656,2.758l-2.121,2.122l-1.415,-1.415l2.121,-2.12l1.415,1.413Zm-1.656,3.242l3,-0l-0,2l-3,0l-0,-2Z", style: {
  fillRule: "nonzero"
} }));
export default SvgCpc;
