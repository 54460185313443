import NumberChangeEditor from 'Components/Filters/Common/NumberChangeEditor';
import { ChangeFilter, FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import NoFilterIcon from 'icons/no-filter.svg?inline';
import Icon from 'icons/sov.svg?inline';
import { changeFilterLabelFunc } from '../helpers';

const defaultValue: ChangeFilter = {
  attribute: FilterAttribute.ORGANIC_TRAFFIC_CHANGE,
  type: FilterValueType.NUMBER,
  comparison: FilterComparison.GT,
  value: '',
};

const getData = () => ({
  defaultValue,
  title: 'AI SoV change', // For unknown reasons tct([estimatedVisits] change) swallows the space in one place and not in another?!?
  icon: Icon,
  editor: NumberChangeEditor,
  editorProps: {
    isReversed: false,
  },
  tableEditor: NumberChangeEditor,
  tableEditorProps: {
    iconDropdown: true,
    isReversed: false,
    noFilterIcon: NoFilterIcon,
  },
  labelFunc: changeFilterLabelFunc,
});

export default getData;
