import React, { forwardRef } from 'react';
import { Group, Skeleton } from '@mantine/core';
import styles from './tree-table.module.scss';

interface TableProps {
  tableRows: JSX.Element;
  headerRow: JSX.Element;
  loadingElement?: JSX.Element;
  /**ClassName applied to the HTML `<table>` element */
  className?: string;
  /**ClassName applied to the HTML `<thead>` element */
  headerClassName?: string;
  loading: boolean;
}

const SkeletonTable = () => {
  const nElements = 9;
  const elementMargin = 16;
  const allButUrlColWidth = 13 + 20 + 60 + 60 + 60 + 55 + 60 + 55 + nElements * elementMargin;
  const urlColWidth = `calc(100% - ${allButUrlColWidth}px)`;
  return (
    <>
      {Array.from(Array(18)).map((_, i) => (
        <Group className={styles.skeletonTable} key={`skeleton-${i}`}>
          <Skeleton height={8} width={13} ml={10} />
          <Skeleton height={8} width={urlColWidth} ml={-5} />
          <Skeleton height={8} width={20} />
          <Skeleton height={8} width={60} />
          <Skeleton height={8} width={60} />
          <Skeleton height={8} width={60} />
          <Skeleton height={8} width={55} />
          <Skeleton height={8} width={60} />
          <Skeleton height={8} width={55} />
        </Group>
      ))}
    </>
  );
};

export const TableComponent = forwardRef<HTMLTableElement, TableProps>((props, ref) => {
  const { tableRows, headerRow, className, loading, loadingElement } = props;
  return (
    <div ref={ref} className={className} data-testid="tree-table-container">
      {headerRow}
      {loading && (loadingElement || <SkeletonTable />)}
      {!loading && tableRows}
    </div>
  );
});

TableComponent.displayName = 'TableComponent';
