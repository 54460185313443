import React from 'react';
import { Box, Group, Stack } from '@mantine/core';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { t } from 'Utilities/i18n';
import DoubleClickIcon from 'icons/tree/cursor-click-double.svg?inline';
import ClickIcon from 'icons/tree/cursor-click.svg?inline';
import PanIcon from 'icons/tree/cursor-move-all-direction.svg?inline';
import ZoomIcon from 'icons/tree/zoom-in.svg?inline';
import styles from '../siteMapping.module.scss';

const TreeHelpIcons = (): JSX.Element => {
  const IconWithTooltip = ({ Icon, tooltip }) => {
    return (
      <Box mb="xxs">
        <AccTooltip tooltip={tooltip}>{Icon}</AccTooltip>
      </Box>
    );
  };

  return (
    <div className={styles.treeHelpIcons}>
      <Group w={200} gap={'sm'}>
        <Stack gap={'sm'}>
          <IconWithTooltip Icon={<ClickIcon />} tooltip={t('Click to select a page')} />
          <IconWithTooltip Icon={<PanIcon />} tooltip={t('Click and drag to move the graph')} />
        </Stack>
        <Stack gap={'sm'}>
          <IconWithTooltip Icon={<DoubleClickIcon />} tooltip={t('Double-click to see subpages')} />
          <IconWithTooltip Icon={<ZoomIcon />} tooltip={t('Shift+Scroll to zoom')} />
        </Stack>
      </Group>
    </div>
  );
};

export default TreeHelpIcons;
