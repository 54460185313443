import { Box, Group, Image, Skeleton } from '@mantine/core';
import { IconLink, IconLinkPlus } from '@tabler/icons-react';
import AccBadge from 'Components/AccBadge';
import AccButton from 'Components/AccButton/AccButton';
import AccPaper from 'Components/AccPaper';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { DomainFavicon } from 'Components/DomainFavicon';
import AccTitle from 'Components/Title/AccTitle';
import { PublicDomainQuery } from 'Ghql';
import { useClipboard } from 'Hooks';
import { FilterBase } from 'Types/Filter';
import { RouteMatch, useRouteMatch } from 'Utilities/Router/hooks/useRouteMatch';
import { getFaviconByDomain } from 'Utilities/favicon';
import { t } from 'Utilities/i18n';
import { redirectToRoot } from 'Utilities/underdash';
import AccurankerIcon from 'icons/logo-brand.svg';
import { ReportPeriodSegmentedControl } from './reportPeriodSegmentedControl';
import styles from './publicReportPage.module.scss';

const DomainFaviconLarge = ({ domain }: { domain: string }) => {
  const faviconUrl = getFaviconByDomain(domain, 64);
  return <img width={48} height={48} src={faviconUrl} alt={domain ?? ''} />;
};

/** Get the URL with- our without filter hash */
function constructURL(match: RouteMatch, includeFilters: boolean): string {
  const { path, params } = match;
  const domain = new URL(window.location.href).origin;
  const paramsObject = params || {};
  //look for segments in the path that start with a colon followed by one or more word characters.
  // eg. :filter returns `filter`
  const url: string = path.replace(/:(\w+)/g, (_, key) => {
    if (!includeFilters && key === 'filter') {
      return '';
    }
    return paramsObject[key] || '';
  });
  return `${domain}/app${url}`;
}

export const Header = ({
  loading,
  isAdmin,
  domain,
  displayName,
  organizationLogo,
  earliestStartDate,
  publicDomainData,
  filters,
}: {
  loading: boolean;
  isAdmin: boolean;
  domain: string;
  displayName: string | null;
  organizationLogo: string | undefined;
  earliestStartDate: Date | undefined;
  publicDomainData: PublicDomainQuery | undefined;
  filters: FilterBase[];
}) => {
  const { copy, copied, error } = useClipboard({ timeout: 4000 });

  const match = useRouteMatch();

  const handleCopyPath = (includeFilters: boolean) => {
    const path = constructURL(match, includeFilters);
    copy(path);
    if (error) {
      console.error('Failed to copy path to clipboard', error);
    }
  };

  const linkText = t('Copy sharable link to clipboard');
  const linkTextWithFilters = t('Copy sharable link with current table sorting to clipboard');
  return (
    <>
      <Group align="center" justify="space-between" wrap="wrap" mt="xl">
        {isAdmin ? (
          <Group gap="sm">
            <AccTooltip
              tooltip={isAdmin ? t('Go to AccuRanker dashboard') : t('Go to AccuRanker.com')}
            >
              <button
                className={styles.accurankerLogoButton}
                onClick={() =>
                  (isAdmin
                    ? redirectToRoot()
                    : window.open('https://www.accuranker.com', '_self', 'noopener,noreferrer'))
                }
                aria-label={isAdmin ? t('Go to AccuRanker dashboard') : t('Go to AccuRanker.com')}
              >
                <img width={48} height={48} src={AccurankerIcon} alt={'Accuranker logo'} />
              </button>
            </AccTooltip>
            <AccTooltip old delay={0} tooltip={copied ? t('Copied!') : linkText}>
              <AccButton
                size="compact-xs"
                onClick={() => handleCopyPath(false)}
                variant="tertiary"
                aria-label={linkText}
                rightSection={<IconLink size={20} />}
              >
                {t('Copy link')}
              </AccButton>
            </AccTooltip>
            {match.params?.filter && (
              <AccTooltip old delay={0} tooltip={copied ? t('Copied!') : linkTextWithFilters}>
                <AccButton
                  size="compact-xs"
                  onClick={() => handleCopyPath(true)}
                  variant="tertiary"
                  aria-label={linkTextWithFilters}
                  rightSection={<IconLinkPlus size={20} />}
                >
                  {t('Copy link with sorting')}
                </AccButton>
              </AccTooltip>
            )}
          </Group>
        ) : (
          <div />
        )}
        <Box ml="auto">
          <ReportPeriodSegmentedControl
            earliestStartDate={earliestStartDate}
            domainInfo={publicDomainData?.publicDomain || undefined}
            filters={filters}
          />
        </Box>
      </Group>
      {(!!organizationLogo || loading) && (
        <Group align="center" justify="space-between" wrap="wrap" mb="xl">
          <AccPaper w="fit-content" p="xxl">
            {loading ? (
              <Skeleton width={480} height={128} />
            ) : (
              <Image src={organizationLogo} maw={480} mah={128} />
            )}
          </AccPaper>
        </Group>
      )}
      <Group align="center" wrap="nowrap" gap="xs">
        <DomainFaviconLarge domain={domain} />
        {loading ? (
          <Skeleton width={500} height={40} />
        ) : (
          <AccTitle type="h1" mb="0" className={styles.headline}>
            {t('Keyword ranking report for %s', displayName || domain)}
          </AccTitle>
        )}
      </Group>
      {!loading && displayName && (
        <div>
          <AccBadge
            type="gray"
            variant="outline"
            w="fit-content"
            ml="auto"
            size="lg"
            className={styles.domainBadge}
          >
            <Group gap="xs">
              <DomainFavicon domain={domain} />
              {domain}
            </Group>
          </AccBadge>
        </div>
      )}
    </>
  );
};
