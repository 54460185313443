import { memo, useRef } from 'react';
import { Box } from '@mantine/core';
import isEqual from 'lodash/isEqual';
import { BubbleChart } from 'Components/Chart/BubbleChart/BubbleChart';
import { BubbleChartData } from 'Components/Chart/BubbleChart/support/types';
import AccText from 'Components/Text/AccText';
import { useFilters } from 'Hooks';
import { useModal } from 'Hooks/base/useModal';
import { useDomainId } from 'Hooks/data/domain/useQueryDomainInfo';
import useUserPermission from 'Hooks/useUserPermission';
import { MissingDataOverlay } from 'Pages/Keywords/Overview/MissingDataOverlay';
import { ChartContainer } from 'Pages/Keywords/Overview/components/ChartContainer/index';
import { WithDragDetails } from 'Pages/Keywords/Overview/support/types';
import { t, tct } from 'Utilities/i18n/index';
import { useGroupViewInfo } from '../../hooks/useGroupViewInfo';
import { useBubbleCompetitors } from './hooks';

type CompetitorsBubbleChartProps = {
  /** Used on keyword discovery an keyword research to alter behaviour of the chart. */
  isKeydis: boolean;
  country: string | undefined;
  setDomain?: (
    event: Highcharts.SeriesLegendItemClickEventObject,
    domain: string | undefined,
  ) => void;
  /** Enable navigation to competitor domains directly from the chart legend. */
  changeDomainFromChart?: boolean;
};

const getCsvData = (data: BubbleChartData[]) => () =>
  `Name,Keyword Count,Search Volume,Share of Voice\n${data
    .map((e) => `${e.displayName},${e.x},${e.y},${e.z}`)
    .join('\n')}`;

const Tooltip = ({ inQuickActions }) => (
  <AccText size="xs">
    {inQuickActions
      ? tct(
          'This chart displays the selected domains competitors\' relative size in three dimensions.[br][br]',
          {
            br: <br />,
          },
        )
      : tct(
          'This chart displays your and your competitors\' relative size in three dimensions.[br][br]',
          {
            br: <br />,
          },
        )}
    <ul style={{ paddingLeft: 16, listStyleType: 'decimal' }}>
      <li style={{ marginBottom: '4px' }}>
        {t(
          'The keyword count on the x-axis shows the amount of keywords in the current filtering where the domain is in the top 100.',
        )}
      </li>
      <li style={{ marginBottom: '4px' }}>
        {t(
          'The search volume on the y-axis shows the total search volume for keywords where the domain is in the top 100.',
        )}
      </li>
      <li>{t('The size of the bubbles indicates the Share of Voice for the domain.')}</li>
    </ul>
    {t(
      'Hover the bubbles to see the exact numbers. Click and drag to zoom. Toggle a competitor on/off by clicking the competitor to the right.',
    )}
  </AccText>
);

export const CompetitorsBubbleChart = memo(
  ({
    isKeydis = false,
    country = undefined,
    setDomain,
    changeDomainFromChart = false,
    ...props
  }: CompetitorsBubbleChartProps & WithDragDetails) => {
    const filters = useFilters();
    const { isEmptyGroup, isGroupView } = useGroupViewInfo(filters);
    const chartRef = useRef<HTMLDivElement>(null);
    const { loading, data, refetch, fakeCompetitorData, isDemoDomain } = useBubbleCompetitors({
      isKeydis,
      country,
    });
    const { userHasWriteAccess } = useUserPermission();

    const nCompetitors = data?.length || 0;
    const noCompetitors = nCompetitors <= 1;
    const { showModal } = useModal();
    const domainId = useDomainId();

    const addCompetitorModal = () =>
      showModal({
        modalType: 'BuildCompetitor',
        modalTheme: 'light',
        modalProps: {
          domainId,
          refresh: () => refetch?.(),
        },
      });

    return (
      <ChartContainer
        title={t('Competitors')}
        tooltip={[<Tooltip inQuickActions={isKeydis} key="0" />]}
        chartRef={chartRef}
        chartSeries={data}
        refetch={refetch}
        getCsvData={() => getCsvData(data)}
        addCompetitor={!isKeydis && !isDemoDomain && userHasWriteAccess}
        draggable={!isKeydis}
        dragRef={props.dragRef}
        boardControls={props.boardControls}
      >
        <Box pos="relative" ref={chartRef} className="competitors-chart">
          {!loading && noCompetitors && (
            <MissingDataOverlay
              openModalAction={!isKeydis}
              onClick={addCompetitorModal}
              type="competitors"
              isGroupView={isGroupView}
              isEmptyGroup={isEmptyGroup}
            />
          )}
          {/* set key to force rerender of bubble chart after nCompetitors change */}
          <BubbleChart
            key={`${domainId}-${nCompetitors}`}
            data={noCompetitors ? fakeCompetitorData : data}
            loading={loading}
            setDomain={changeDomainFromChart ? setDomain : undefined}
          />
        </Box>
      </ChartContainer>
    );
  },
  isEqual,
);
CompetitorsBubbleChart.displayName = 'CompetitorsBubbleChart';
