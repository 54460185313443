import { ColumnType } from '../interface';

// Hence when we show such columns they should equally share the width extension
// coursed by a wide screen and/or few columns shown.
// https://accuranker.myjetbrains.com/youtrack/issue/ARR-1321
export const getFlexColumnWidth = (
  colWidths: readonly number[],
  columns?: readonly ColumnType<any>[],
  tableWidth?: number,
) => {
  const columnsDefaultWidth = colWidths.reduce((acc, e) => acc + e, 0);
  const flexColumns = columns?.filter((e) => e.flex).map((e) => e.width) ?? [];
  const flexColumnsWidth = flexColumns.reduce((acc, e) => (acc ?? 0) + (e ?? 0), 0) ?? 0;
  const flexDelta =
    tableWidth && columnsDefaultWidth < tableWidth
      ? tableWidth - columnsDefaultWidth + flexColumnsWidth
      : 0;
  const flexColumnLength = flexColumns?.length ?? 0;

  return Math.floor(flexDelta > 0 && flexColumnLength > 0 ? flexDelta / flexColumnLength : 0);
};
