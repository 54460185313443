import { observer } from 'mobx-react';
import { useTableFilterSync } from 'Components/DataTable/hooks/data/useFilterSync';
import { TableStoreType } from 'Components/DataTable/store/TableStore';

type Props = {
  children: JSX.Element;
  tableStore: TableStoreType;
  applyDefaultOrder?: boolean;
  syncFilters?: boolean;
};

const WithFilterSync = observer(
  ({ children, ...props }: Pick<Props, 'tableStore' | 'children' | 'applyDefaultOrder'>) => {
    useTableFilterSync(props);
    return <>{children}</>;
  },
);

export const WithOptionalFilterSync = observer(({ syncFilters, children, ...props }: Props) => {
  if (syncFilters) {
    return <WithFilterSync {...props}>{children}</WithFilterSync>;
  }

  return <>{children}</>;
});
