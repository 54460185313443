import React from 'react';
import { Flex } from '@mantine/core';
import Breadcrumbs from 'Components/Breadcrumbs/Breadcrumbs';
import InformationForm from 'Pages/Accounts/SubAccounts/components/InformationForm';
import { t } from 'Utilities/i18n/index';
import reusableStyles from 'css/reusable-styles.module.scss';
import { usePageRefresh } from '../../../Hooks/common/usePageRefresh';
import { useUser } from '../../../Hooks/data/user/useUser';
import { SubAccountActions } from './components/SubAccountActions';
import { SubAccountsTable } from './components/SubAccountsTable';

const SubAccounts = () => {
  const user = useUser();
  const [pageKey, updateTable] = usePageRefresh();
  const subAccountsEnabled = user?.organization?.isPartner;

  if (!subAccountsEnabled) {
    return (
      <Flex w={550} direction="column">
        <Breadcrumbs />
        <div className={reusableStyles.paperContainer}>
          <strong className="form-title">{t('Information')}</strong>
          <InformationForm />
        </div>
      </Flex>
    );
  }
  return (
    <React.Fragment>
      <Breadcrumbs />
      <SubAccountActions updatePage={updateTable} />
      <div className={reusableStyles.paper}>
        <SubAccountsTable pageKey={pageKey} updateTable={updateTable} />
      </div>
    </React.Fragment>
  );
};

export default SubAccounts;
