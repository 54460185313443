/* eslint-disable no-continue */
import { useEffect, useState } from 'react';
import { Box, Flex, Pagination } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import isEmpty from 'lodash/isEmpty';
import AccButton from 'Components/AccButton/AccButton';
import { SelectItem } from 'Components/AccSelect';
import 'Components/Fields/hooks/WithFieldForm/form-field.scss';
import Loader from 'Components/Loader';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import AccText from 'Components/Text/AccText';
import AccTitle from 'Components/Title/AccTitle';
import { useGa_Connect_DomainsListQuery } from 'Ghql';
import { useDisableBodyScroll } from 'Hooks/useDisableBodyScroll';
import { t } from 'Utilities/i18n';
import { useAvailableDomains, usePropertyOptionsMultiple } from '../Property/utils';
import InputRow from './InputRow';
import { matchDomainToProperty } from './matchDomainToProperty';
import styles from './styles.module.scss';

const getGAAccountId = (propertyOptions: SelectItem<string>[], propertyValue: string) => {
  const domainProperty = propertyOptions.find((option) => option.value === propertyValue);
  const gaAccountId =
    domainProperty && 'accountId' in domainProperty ? (domainProperty?.accountId as string) : '';
  return gaAccountId;
};

//maximum number of domains to query
const DOMAIN_LIMIT = 25;

const modalWidth = 720;
const modalPadding = 32;
export const formWidth = modalWidth - modalPadding * 2;

export type DomainPropertyPairs = {
  [x: string]: { propertyValue: string; gaAccountId: string };
};

type Props = {
  connectionId: string;
  groupId: string;
  accountIds: string[];
  onSubmit: (arg: { domainPropertyPairs: DomainPropertyPairs }) => any;
  onBack: (...args: Array<any>) => any;
};

export const PropertyBulkImport = ({
  connectionId,
  accountIds,
  onSubmit,
  onBack,
  groupId,
}: Props) => {
  const [propertyOptions, propertiesLoading] = usePropertyOptionsMultiple(
    connectionId,
    accountIds,
    true,
  );

  //pagination state
  const [activePage, setPage] = useState(1);

  const offset = (activePage - 1) * DOMAIN_LIMIT;

  const [domainPropertyPairs, setDomainPropertyPairs] = useState<DomainPropertyPairs>({});

  const { data, loading: domainsLoading } = useGa_Connect_DomainsListQuery({
    variables: {
      limit: DOMAIN_LIMIT,
      offset,
      onlyNotConnectedToGa: true,
      clientId: Number(groupId),
    },
  });

  /** Total number of domains in this group */
  const totalDomains = data?.domainsList?.[0]?.client.numberOfDomains || 0;

  const domains = useAvailableDomains(data);

  //Try matching domains to properties every time we load domains for a new page
  useEffect(() => {
    if (!domains?.length || domainsLoading || !propertyOptions?.length) {
      return;
    }
    const newDomainMatches: DomainPropertyPairs = {};
    for (let i = 0; i < domains.length; i++) {
      if ((domains[i].value as string) in domainPropertyPairs) {
        continue;
      }
      const match = matchDomainToProperty(domains[i], propertyOptions);

      if (!match?.value) continue;
      const gaAccountId = getGAAccountId(propertyOptions, match.value as string);
      newDomainMatches[domains[i].value as string] = {
        propertyValue: match.value as string,
        gaAccountId,
      };
    }
    setDomainPropertyPairs((currentPairs) => ({ ...currentPairs, ...newDomainMatches }));
  }, [domains.length, propertyOptions.length]);

  useDisableBodyScroll();

  const paginationPages =
    totalDomains % DOMAIN_LIMIT > 0 ? totalDomains / DOMAIN_LIMIT + 1 : totalDomains / DOMAIN_LIMIT;

  const handleUpdatePair = (propertyValue: string, domainId: string) => {
    if (propertyValue === '') {
      //remove the key-value pair of domainId
      setDomainPropertyPairs((currentPairs) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [domainId]: _, ...newPairs } = currentPairs;
        return newPairs;
      });
      return;
    }
    //add a value
    const gaAccountId = getGAAccountId(propertyOptions, propertyValue);
    setDomainPropertyPairs((currentPairs) => ({
      ...currentPairs,
      [domainId]: { propertyValue, gaAccountId },
    }));
  };

  return (
    <>
      <Flex direction="column" gap={16} w={formWidth}>
        {domainsLoading || propertiesLoading ? (
          <Loader
            style={{
              height: '641px', // Height identical to InputRow and headerContainer
            }}
            noBackdrop={true}
            loadingText={t('Loading…')}
          />
        ) : domains?.length ? (
          <>
            <div className={styles.headerContainer}>
              <AccTitle type="h5">{t('Domain')}</AccTitle>
              <div />
              <AccTitle type="h5">{t('Property')}</AccTitle>
            </div>
            <Box className={styles.bodyContainer} w={formWidth} h={600} mr={-10} pr={10}>
              {domains.map((domain) => {
                return (
                  <InputRow
                    key={domain.value as string}
                    value={domainPropertyPairs[domain.value as string]?.propertyValue || ''}
                    properties={propertyOptions}
                    onChange={(value) => handleUpdatePair(value, domain.value as string)}
                    domain={domain}
                    loading={domainsLoading || propertiesLoading}
                    defaultValue={domainPropertyPairs[domain.value as string]?.propertyValue || ''}
                  />
                );
              })}
            </Box>
          </>
        ) : (
          //Height identical to InputRow and headerContainer
          <Box h={637}>
            <AccText>{t('Could not find any domains')}</AccText>
          </Box>
        )}
      </Flex>
      <ModalFooter
        primaryButtonSlot={
          <AccButton
            variant="primary"
            onClick={() => onSubmit({ domainPropertyPairs })}
            disabled={propertiesLoading || domainsLoading || isEmpty(domainPropertyPairs)}
          >
            {t('Connect properties')}
          </AccButton>
        }
        secondaryButtonSlot={
          <AccButton variant="tertiary" onClick={onBack} leftSection={<IconArrowLeft />}>
            {t('Account')}
          </AccButton>
        }
        textSlot={
          <>
            {totalDomains > DOMAIN_LIMIT && (
              <Pagination
                value={activePage}
                onChange={setPage}
                total={paginationPages}
                size="sm"
                disabled={propertiesLoading}
              />
            )}
          </>
        }
      />
    </>
  );
};
