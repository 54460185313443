import * as React from 'react';
import { useContext } from 'react';
import { DashboardDomainNode } from 'Pages/Domains/support/types';

export type DomainContextNode = DashboardDomainNode & {
  clientId: number;
  selectId: string;
};
const DomainContext = React.createContext<DomainContextNode>({} as DomainContextNode);

export const useDomainContext = () => useContext(DomainContext);

export default DomainContext;
