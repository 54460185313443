import { useMemo } from 'react';
import { usePaymentContactQuery } from 'Ghql';
import useUserPermission from 'Hooks/useUserPermission';
import { t } from 'Utilities/i18n/index';
import PaymentContactSkeleton from './PaymentContactSkeleton';

const PaymentContact = () => {
  const { isAdmin } = useUserPermission();
  const { data, loading, error } = usePaymentContactQuery({
    fetchPolicy: 'network-only',
  });
  const contact = data?.user?.organization?.paymentcontact;
  const content = useMemo(() => {
    if (!isAdmin) {
      return <p>{t('You need to be organization admin to see billing information.')}</p>;
    }
    if (!contact) {
      return <p>{t('No billing information for this account')}</p>;
    }
    return (
      <div>
        <p>
          <span>
            <strong>{contact.companyName}</strong>{' '}
          </span>
          <br />
          <span>{contact.street}</span>
          <br />
          <span>
            {contact.zipcode} {contact.city}
          </span>
          <br />
          <span>{contact.country?.name}</span>
        </p>
        <p>
          <span>
            {t('P.O. number:')} {contact.poNumber || t('Not set')}
          </span>
        </p>
        <p>
          <span>
            {t('Email for invoices:')} {contact.emailInvoiceTo || t('Not set')}
          </span>
        </p>
      </div>
    );
  }, [contact, isAdmin]);

  if (loading || error) {
    return <PaymentContactSkeleton />;
  }

  return <div className="billing-box">{content}</div>;
};

export default PaymentContact;
