import moment from 'moment';
import CountryLocaleCell from 'Components/AccuTable/CellRenderer/countryLocale';
import KeywordCell from 'Components/AccuTable/CellRenderer/keyword';
import RankCell from 'Components/AccuTable/CellRenderer/rank';
import RankChangeCell from 'Components/AccuTable/CellRenderer/rankChange';
import SearchTypeEngineCell from 'Components/AccuTable/CellRenderer/searchTypeEngine';
import { ColumnType, withCellContext, withRowContext } from 'Components/DataTable';
import { DomainFavicon } from 'Components/DomainFavicon';
import { ReverseEllipsisLink } from 'Components/Link/ReverseEllipsisLink';
import { MenuItemIds } from 'Components/Modal/Content/KeywordInfo';
import { NotificationNode } from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import { useIsGroupDomains, useLanguage } from 'Hooks/data/domain/useQueryDomainInfo';
import { useMetaData } from 'Hooks/data/user/useMetaData';
import useKeyword from 'Hooks/useKeyword';
import { NotificationOverviewColumnId } from 'Pages/Keywords/Overview/components/OverviewNotifications/support/constants';
import { keywordOrderConfig } from 'Pages/Keywords/Table/hooks/keyword/constants';
import { IconContainer } from 'Pages/Keywords/Table/support/IconContainer/IconContainer';
import { KeywordColumnID } from 'Pages/Keywords/Table/support/constants';
import { t } from 'Utilities/i18n';
import DevicesIcon from 'icons/devices.svg?inline';
import LocationIcon from 'icons/location.svg?inline';

export const useNotificationsTableColumns = (): ColumnType<NotificationNode>[] => {
  const isGroupView = useIsGroupDomains();
  const language = useLanguage();
  const metaData = useMetaData();

  const { showModal } = useModal();

  const { showKeywordModal } = useKeyword({
    showModal,
  });

  return [
    isGroupView
      ? {
          id: KeywordColumnID.DOMAIN,
          onHeaderCell: () => ({
            tooltip: t('Sort by domain'),
            ordering: keywordOrderConfig.domain,
          }),
          width: 14,
          fixed: 'left',
          cellRenderer: ({ record }: { record: any }) => <DomainFavicon domain={record?.domain} />,
        }
      : null,
    {
      id: KeywordColumnID.SEARCH_TYPE,
      title: (
        <IconContainer size="md">
          <DevicesIcon />
        </IconContainer>
      ),
      onHeaderCell: () => ({
        tooltip: t('Sort by search type'),
        ordering: keywordOrderConfig.searchType,
      }),
      width: 52,
      fixed: 'left',
      cellRenderer: SearchTypeEngineCell,
      cellRendererParams: {
        searchTypes: metaData?.searchTypes,
        searchEngines: metaData?.searchEngines,
        cellClassName: 'vertical-alignment-top',
      },
    },
    {
      id: KeywordColumnID.COUNTRY_LOCALE,
      title: (
        <IconContainer size="md">
          <LocationIcon />
        </IconContainer>
      ),
      onHeaderCell: () => ({
        tooltip: t('Sort by location'),
        ordering: keywordOrderConfig.countryLocale,
      }),
      width: 48,
      fixed: 'left',
      cellRenderer: CountryLocaleCell,
      cellRendererParams: {
        countryLocales: metaData?.countrylocales,
        compact: true,
      },
    },
    {
      id: KeywordColumnID.KEYWORD,
      title: t('Keyword'),
      width: 170,
      fixed: 'left',
      cellRenderer: withCellContext<any>(KeywordCell as any) as any,
      cellRendererParams: {
        disableTags: true,
        onKeywordClick: (id: any, rankId: any, _domainId: any) => {
          showKeywordModal({
            keywordId: id,
            domainId: _domainId,
            rankId,
            initialTab: MenuItemIds.TAB_RANKS_HISTORY,
          });
        },
        showOptions: true,
      },
      onHeaderCell: () => ({
        tooltip: t('Sort by keyword'),
        ordering: keywordOrderConfig.keyword,
      }),
    },
    {
      id: KeywordColumnID.RANK,
      combineRow: KeywordColumnID.RANK_CHANGE,
      title: t('Rank'),
      onHeaderCell: () => ({
        tooltip: t('Sort by rank'),
        ordering: keywordOrderConfig.rank,
      }),
      width: 54,
      cellRenderer: withRowContext(RankCell as any),
      cellRendererParams: {
        compact: true,
      },
    },
    {
      id: KeywordColumnID.RANK_CHANGE,
      title: t('+/-'),
      onHeaderCell: () => ({
        tooltip: t('Change in rank between the two compared dates'),
        ordering: keywordOrderConfig.rankChange,
      }),
      width: 40,
      cellRenderer: RankChangeCell,
    },
    {
      id: NotificationOverviewColumnId.URL,
      title: t('URL'),
      width: 150,
      cellRenderer: ({ record }: { record: any }) => {
        return <ReverseEllipsisLink url={record?.highestRankingPage} />;
      },
    },
    {
      id: NotificationOverviewColumnId.TIME_AGO,
      title: t('Date'),
      width: 140,
      cellRenderer: ({ record }: { record: any }) =>
        moment(record.createdAt)
          .locale(language as any)
          .format('LL'),
    },
  ]
    .filter((e) => !!e)
    .map((e) => e as ColumnType);
};
