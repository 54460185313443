import { Center, Flex } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { IconPlus } from '@tabler/icons-react';
import AccText from 'Components/Text/AccText';
import { ROOT_ID } from 'Pages/Keywords/Groupings/EditMode/support/constants';
import { t } from 'Utilities/i18n';
import reusableStyles from '../../../../../../../css/reusable-styles.module.scss';

export const ClickToAddFolderButton = ({
  onStartAdd,
  resetScroll,
}: {
  onStartAdd: (target?: string | number | null) => void;
  resetScroll: () => void;
}) => {
  const { hovered, ref } = useHover();

  return (
    <Flex
      gap={0}
      w={250}
      ref={ref}
      onClick={() => {
        onStartAdd(ROOT_ID);
        resetScroll();
      }}
      className={reusableStyles.clickable}
      h={40}
      c={hovered ? 'orange' : undefined}
    >
      <Center ml={'4px'}>
        <IconPlus size={16} />
        <AccText ml={'xl'} size={'sm'} c={hovered ? 'orange' : undefined}>
          {t('Click to add folder...')}
        </AccText>
      </Center>
    </Flex>
  );
};
