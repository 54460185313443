import CheckIcon from 'icons/check.svg?inline';
import styles from './connected-label.module.scss';

type Props = {
  isConnected: boolean;
  description: string;
};
const ConnectedLabel = ({ isConnected, description }: Props) => {
  return (
    <span className={styles.connectedLabel}>
      {isConnected && <CheckIcon />}
      {description}
    </span>
  );
};

export default ConnectedLabel;
