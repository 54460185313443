import AccButton from 'Components/AccButton/AccButton';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import AccText from 'Components/Text/AccText';
import { useModal } from 'Hooks/base/useModal';
import { t } from 'Utilities/i18n';

const PermissionUpdatedModal = () => {
  const { hideModal } = useModal();
  return (
    <ModalBorder title={t('Your permission level changed')} onClose={hideModal}>
      <AccText>{t('Your permission level has been changed by your organization.')}</AccText>
      {/* <AccText>{t('Click on "Refresh AccuRanker" button or wait for auto refresh.')}</AccText> */}
      <AccText>{t('We’ve applied changes to your account.')}</AccText>
      <ModalFooter
        primaryButtonSlot={
          <AccButton variant="primary" onClick={hideModal}>
            {t('Close')}
          </AccButton>
        }
      />
    </ModalBorder>
  );
};

export default PermissionUpdatedModal;
