import { Component } from 'react';
import { connect } from 'react-redux';
import { hideModal } from 'Actions/ModalAction';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import type { FilterBase } from 'Types/Filter';
import { t } from 'Utilities/i18n';
import MoveKeywordsForm from './MoveKeywordsForm';
import './move-keywords.scss';

type Props = {
  hideModal: (...args: Array<any>) => any;
  onClose: (...args: Array<any>) => any;
  keywords: number[];
  domainId: string;
  filters: FilterBase[];
  isAllSelected: boolean;
};

class MoveKeywords extends Component<Props> {
  render() {
    const { onClose, keywords, domainId, filters, isAllSelected } = this.props;
    return (
      <ModalBorder
        className="move-keywords"
        title={t('Move to Other Domain')}
        onClose={this.props.hideModal}
      >
        <MoveKeywordsForm
          onClose={this.props.hideModal}
          refresh={onClose}
          keywords={keywords}
          domainId={domainId}
          filters={filters}
          isAllSelected={isAllSelected}
        />
      </ModalBorder>
    );
  }
}

export default connect(null, {
  hideModal,
})(MoveKeywords);
