import { useMemo } from 'react';
import { Flex } from '@mantine/core';
import {
  DataTable,
  QueryOrder,
  SelectConfig,
  SelectRowType,
  TableSize,
} from 'Components/DataTable';
import { useFilteredTableColumns } from 'Components/Modal/Content/TableSettingsModal/useFilteredColumns';
import { TableIDs } from 'Types/Table';
import { t } from 'Utilities/i18n';
import { MaxSelectableNodes } from '../support/const';
import { DomainColumnIDs } from '../support/types';
import TableViewToolbar from './components/TableViewToolbar';
import { useDomainsTableInfo } from './support/useDomainsTableInfo';
import { useFetchDomainsData } from './support/useFetchDomainsData';
import styles from './domains-table.module.scss';

const tableName = TableIDs.DOMAINS;

const DomainsTable = () => {
  const fetchDomains = useFetchDomainsData();

  const defaultOrdering = {
    order: QueryOrder.ASC,
    orderBy: DomainColumnIDs.DOMAIN_DISPLAY_NAME,
  };

  const { columns, tableLabels, dataKey } = useDomainsTableInfo();
  const resultComns = useFilteredTableColumns(columns, TableIDs.DOMAINS);
  const selectConfig: SelectConfig = useMemo(
    () => ({
      enableSelectAll: false,
      disableOnMaxSelect: true,
      maxSelectCount: MaxSelectableNodes,
      selectRowType: SelectRowType.ONE_PAGE_SELECT,
    }),
    [],
  );
  return (
    <>
      <Flex flex={1} gap="sm" direction="column">
        <DataTable
          className={styles.domainsTable}
          dataKey={dataKey.toString()}
          fetchData={fetchDomains}
          columns={resultComns}
          tableId={tableName}
          pagination={true}
          pageSize={100}
          viewMode={TableSize.DEFAULT}
          defaultOrdering={defaultOrdering}
          labels={tableLabels}
          selectConfig={selectConfig}
          emptyOptions={{
            title: t('No domains'),
            subTitle: t('There is currently no domains added to your account.'),
          }}
        />
      </Flex>
      <TableViewToolbar />
    </>
  );
};

export default DomainsTable;
