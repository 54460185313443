import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import AccButton from 'Components/AccButton/AccButton';
import { TextAreaField } from 'Components/Forms/Fields';
import { formatKeys } from 'Components/Modal/Content/Shortcuts';
import { ModalFooter } from 'Components/Modal/Layout/ModalFooter';
import { t, tct } from 'Utilities/i18n';
import Mousetrap from 'Utilities/mousetrap';
import { copyToClipboard, isMac } from 'Utilities/underdash';
import Skeleton from './Skeleton';
import './copy-to-clipboard-form.scss';

type Props = {
  hideModal: (...args: Array<any>) => any;
  value: string | Promise<string>;
  confirmButtonLabel?: string;
  initialize: (...args: Array<any>) => any;
  textAreaValue: string;
  removeDuplicates: boolean;
};
type State = {
  isLoading: boolean;
};
const copyShortcut = isMac() ? 'command+c' : 'ctrl+c';
const formName = 'CopyToClipboardForm';

export const doRemoveDuplicates = (value: string, removeDuplicates: boolean): string => {
  if (!removeDuplicates) return value;
  if (!value) return value;
  const arr: string[] = value.split(/\r?\n/);
  if (isEmpty(arr) || isNil(arr)) return value;

  return [...new Set(arr)].filter((s) => !!s).join('\n');
};

class CopyToClipboardForm extends Component<Props, State> {
  _mounted = false;
  _textareaRef: any = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
    Promise.resolve(props.value).then((value: string) => {
      const newState = {
        isLoading: false,
      };

      if (this._mounted) {
        this.setState(newState);
        this.props.initialize({
          copyToClipboard: doRemoveDuplicates(value, props.removeDuplicates),
        });
      } else {
        this.state = newState;
      }
    });
  }

  componentDidMount() {
    this._mounted = true;
    Mousetrap.bind(copyShortcut, 'Copy to clipboard', this.handleCopy);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.textAreaValue !== this.props.textAreaValue && prevProps.textAreaValue == null) {
      setTimeout(() => {
        if (this._textareaRef.current !== null) {
          this._textareaRef.current?.focus();
          this._textareaRef.current?.select();
        }
      });
    }
  }

  componentWillUnmount() {
    this._mounted = false;
    Mousetrap.unbind([copyShortcut]);
  }

  handleCopy = () => {
    copyToClipboard(this.props.textAreaValue);
    this.props.hideModal();
  };

  render() {
    const { confirmButtonLabel } = this.props;
    const { isLoading } = this.state;

    if (isLoading) {
      return <Skeleton />;
    }

    return (
      <form className="copy-to-clipboard-form">
        <span className="title">
          {tct('Press [shortcut] to copy to clipboard or just click on "[confirm]" button', {
            shortcut: <span className="shortcut">{formatKeys(copyShortcut)}</span>,
            confirm: confirmButtonLabel,
          })}
        </span>
        <div>
          <Field
            getRef={this._textareaRef}
            component={TextAreaField}
            name="copyToClipboard"
            autoFocus={true}
          />
        </div>
        <ModalFooter
          primaryButtonSlot={
            <AccButton variant="primary" onClick={this.handleCopy}>
              {confirmButtonLabel || t('Copy')}
            </AccButton>
          }
          secondaryButtonSlot={
            <AccButton variant="tertiary" onClick={this.props.hideModal}>
              {t('Cancel')}
            </AccButton>
          }
        />
      </form>
    );
  }
}

const selector = formValueSelector(formName);

const mapStateToProps = (state) => ({
  textAreaValue: selector(state, 'copyToClipboard'),
});

export default connect(mapStateToProps)(
  reduxForm({
    form: formName,
  })(CopyToClipboardForm),
) as any;
