import FormatNumber from 'Components/FormatNumber';
import { DashboardNode } from 'Pages/Domains/support/types';
import styles from '../domains-table.module.scss';

type Props = {
  domainNode: DashboardNode;
};

const ShareOfVoiceCell = (props: Props) => {
  const { domainNode } = props;

  const countKeywords = domainNode?.countKeywords ?? 0;
  const shareOfVoice = domainNode?.shareOfVoice ?? 0;
  const shareOfVoiceIsPercentage = domainNode?.shareOfVoiceIsPercentage ?? false;
  const shareOfVoicePercentageValue = domainNode?.shareOfVoicePercentageValue ?? 0;

  if (!countKeywords) return null;

  const sov = shareOfVoiceIsPercentage ? shareOfVoicePercentageValue : shareOfVoice;

  return (
    <FormatNumber
      className={styles.valueCell}
      percentage={shareOfVoiceIsPercentage}
      precision={shareOfVoiceIsPercentage ? 2 : 0}
    >
      {sov}
    </FormatNumber>
  );
};

export default ShareOfVoiceCell;
