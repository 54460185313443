/* eslint-disable no-fallthrough, no-duplicate-case */
import { captureException } from '@sentry/react';
import { importWebSocketEndpoint } from 'Utilities/runtimeConfig';

enum OutgoingMessage {
  START_UPLOAD = 'start_upload',
  END_UPLOAD = 'end_upload',
}

enum IncomingMessage {
  ACK_RECEIVE_METADATA = 'ack_receive_metadata',
  ACK_RECEIVE_CHUNK = 'ack_receive_chunk',
  ACK_END_UPLOAD = 'ack_end_upload',

  STARTED_SETUP = 'started_setup',
  FINISHED_SETUP = 'finished_setup',
}

type JSONMessageProtocol = {
  type: IncomingMessage | OutgoingMessage;
  payload?: Record<string, string | number>;
};

const MiB_1 = 1024 * 1024 * 1;

export class ImportWebsocketProducer {
  private ws: WebSocket = new WebSocket(importWebSocketEndpoint());
  private retries = 0;
  private uploadEnded = false;

  /**
   * This function is used to establish a connection with the server in cases where the connection may fail for some reason. When this happens, no exception is thrown, no error event is dispatched, and no close event is dispatched.
   *
   * To handle this, this function attempts to connect up to ten times. In most cases, only one retry is necessary to establish a successful connection. If the connection is still not successful after ten attempts, an error is thrown.
   */
  private async checkConnection(): Promise<boolean> {
    if (this.ws.readyState === WebSocket.CLOSED) {
      this.ws = new WebSocket(importWebSocketEndpoint());
      this.retries++;
    }

    if (this.retries === 10) {
      return false;
    }

    if (this.ws.readyState !== WebSocket.OPEN) {
      await new Promise((resolve) => setTimeout(resolve, 100));
      return this.checkConnection();
    }

    return true;
  }

  private sendJSON(message: JSONMessageProtocol) {
    this.ws.send(JSON.stringify(message));
  }

  private sendBytes(content: Blob | undefined) {
    if (content) {
      this.ws.send(content);
      return true;
    }
    return false;
  }

  private getMessage(event: MessageEvent) {
    const messageString: string | undefined = event.data;
    if (!messageString) {
      return;
    }
    try {
      const message: JSONMessageProtocol = JSON.parse(messageString);
      return message;
    } catch (error) {
      captureException(error);
      return;
    }
  }

  private getChunks(f: File) {
    const chunkSize = MiB_1; // It appears that increasing chunkSize does not improve performance and may actually slightly worsen performance.
    const numChunks = Math.ceil(f.size / chunkSize);

    const chunks: Blob[] = [];

    let start = 0;
    let end = chunkSize;

    for (let i = 0; i < numChunks; i++) {
      const chunk = f.slice(start, end);
      chunks.push(chunk);

      start += chunkSize;
      end += chunkSize;
    }

    return chunks;
  }

  /**
   * This function closes the WebSocket connection and should only be used for cleanup purposes, such as when the user navigates away from upload page.
   */
  forceClose() {
    if (this.ws) {
      this.ws.close();
    }
  }

  /**
   * This function uploads a file in chunks using the following protocol:
   * 1. The client sends the file metadata as a JSON string.
   * 2. The server responds with an acknowledge message, which triggers the client to send the first chunk of the file as bytes.
   * 3. As each chunk is received, the server sends an acknowledgement message with the current progress and triggers the client to send the next chunk.
   * 4. Once the client has sent the final chunk, it sends a message indicating the completion of the upload.
   * 5. The server sends a message to indicate that it has started processing the uploaded file.
   * 6. When processing is complete, the server sends another message to indicate this.
   * 7. The function then resolves with the ID of the processed file, allowing the user to be redirected to the validation page for the file.
   */
  sendFile(
    file: File,
    setUploadProgress: (progress: number) => void,
    setUploadProcessing: (uploadProcessing: boolean) => void,
  ) {
    const chunks = this.getChunks(file);
    type SendFileResult = {
      id?: number;
      error?: string;
    };

    return this.checkConnection().then((connected) => {
      return new Promise<SendFileResult>((resolve, reject) => {
        // error if websocket connection failed
        if (!connected) {
          reject({ error: 'Could not establish WebSocket connection' });
        }

        // 1. The client sends the file metadata as a JSON string.
        this.sendJSON({
          type: OutgoingMessage.START_UPLOAD,
          payload: { name: file.name, size: file.size, content_type: file.type },
        });

        this.ws.addEventListener('message', (event) => {
          const message = this.getMessage(event);

          // error if message format does not adhere to protocol
          if (!message) {
            this.ws.close();
            reject({ error: 'No message' });
          } else if (typeof message.type !== 'string') {
            this.ws.close();
            reject({ error: `Expected string property "type" on message ${message.type}` });
          } else if (!message.payload) {
            this.ws.close();
            reject({ error: `Expected property "payload" on message ${message.type}` });
          } else {
            // handle message
            switch (message.type) {
              case IncomingMessage.ACK_RECEIVE_METADATA:
              case IncomingMessage.ACK_RECEIVE_CHUNK:
              case IncomingMessage.ACK_END_UPLOAD:
                if (typeof message.payload.progress !== 'number') {
                  this.ws.close();
                  // error if payload does not adhere to protocol
                  reject({
                    error: `Expected number property "progress" on payload of message "${
                      message.type
                    }" but received "${typeof message.payload.progress}"`,
                  });
                  break;
                }
                setUploadProgress(message.payload?.progress as number);

              // 2. The server responds with an acknowledge message, which triggers the client to send the first chunk of the file as bytes.
              case IncomingMessage.ACK_RECEIVE_METADATA:
              // 3. As each chunk is received, the server sends an acknowledgement message with the current progress and triggers the client to send the next chunk.
              case IncomingMessage.ACK_RECEIVE_CHUNK:
                if (this.uploadEnded) {
                  // skip if upload has already ended
                  break;
                }
                if (!this.sendBytes(chunks.shift())) {
                  this.uploadEnded = true;
                  // 4. Once the client has sent the final chunk, it sends a message indicating the completion of the upload.
                  this.sendJSON({ type: OutgoingMessage.END_UPLOAD });
                }
                break;

              case IncomingMessage.STARTED_SETUP:
                // 5. The server sends a message to indicate that it has started processing the uploaded file.
                setUploadProcessing(true);
                break;

              case IncomingMessage.FINISHED_SETUP:
                // 6. When processing is complete, the server sends another message to indicate this.
                if (typeof message.payload.id !== 'number') {
                  // error if payload does not adhere to protocol
                  this.ws.close();
                  reject({
                    error: `Expected number property "id"  on payload of message "${
                      message.type
                    }" but received "${typeof message.payload.id}"`,
                  });
                  break;
                }
                this.ws.close();
                // 7. The function then resolves with the ID of the processed file, allowing the user to be redirected to the validation page for the file.
                resolve({ id: message.payload.id });
                break;

              default:
                // error if message type does not adhere to protocol
                this.ws.close();
                reject({ error: `Unexpected message: ${JSON.stringify(message)}` });
                break;
            }
          }
        });

        this.ws.addEventListener('error', () => {
          this.ws.close();
          reject({ error: 'Websocket error' });
        });
      });
    });
  }
}
