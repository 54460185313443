import isEmpty from 'lodash/isEmpty';

/**
 * Works properly with `cache-and-network`, without this helper it will show loading: true on background refetch.
 * This hook - show loading: false, in case we have cache and fetching backend in background,
 * if new data came - it will re-render data with latest version.
 */
export const useSmartQuery = <T extends (...args: any[]) => any>(
  useData: T,
  params: Parameters<T>[0],
): ReturnType<T> => {
  const { data: cachedData } = useData({
    ...params,
    fetchPolicy: 'cache-only',
  });
  const hasCache = Object.values(cachedData || {})?.some((e) => !isEmpty(e));

  const result = useData({ ...params, fetchPolicy: 'cache-and-network' });
  return { ...result, loading: hasCache ? false : result?.loading };
};
