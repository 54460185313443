import { OperationType } from '../../../support/constants';
import {
  BASE_OPERATIONS,
  NUMBER_OPERATIONS,
  STRING_OPERATIONS,
  STRING_OPERATIONS_AND_TITLE_CONTAINS_KEYWORD,
  TAG_SOURCE_OPTIONS,
} from './constants';
import { getOperationType } from './helpers';

const useOptionsTranslate = (options: any[]) => {
  return options?.map(({ getLabel, ...e }) => ({ ...e, label: getLabel(), value: e?.id })) ?? [];
};

export const useBuilderTokenInfo = (source: string) => {
  const sourceOptions = useOptionsTranslate(TAG_SOURCE_OPTIONS);
  const stringOperations = useOptionsTranslate(STRING_OPERATIONS);
  const stringAndTitleContainsKeywordOperations = useOptionsTranslate(
    STRING_OPERATIONS_AND_TITLE_CONTAINS_KEYWORD,
  );
  const numberOperations = useOptionsTranslate(NUMBER_OPERATIONS);
  const baseOperations = useOptionsTranslate(BASE_OPERATIONS);
  const operations = {
    [OperationType.BASE]: baseOperations,
    [OperationType.NUMBER]: numberOperations,
    [OperationType.STRING]: stringOperations,
    [OperationType.STRING_AND_TITLE_CONTAINS_KEYWORD]: stringAndTitleContainsKeywordOperations,
  };

  const operationSelected = getOperationType(source);
  const operationOptions = operations[operationSelected!] ?? [];
  return { sourceOptions, operationOptions, operationSelected };
};
