import { useMemo } from 'react';
import { CountryNode, RegisterPageCountriesQuery, RegisterPageUnconfirmedUserQuery } from 'Ghql';
import { CountryOption } from '..';

type Props = {
  countriesData?: RegisterPageCountriesQuery;
  userData?: RegisterPageUnconfirmedUserQuery;
};
export const useCountryAndVATFields = (props: Props) => {
  const { countriesData, userData } = props;

  const countryOptions = useMemo((): CountryOption[] => {
    return (countriesData?.countries ?? [])
      .map((c) => c as CountryNode)
      .map(
        ({ name, vatCode, id }) =>
          ({
            label: name,
            value: id,
            vatCode,
            countryCode: id,
          } as CountryOption),
      );
  }, [countriesData]);

  const vatOptions = useMemo(() => {
    return countryOptions
      .filter((option) => !!option.vatCode)
      .map(({ vatCode, countryCode }) => ({
        label: vatCode,
        value: vatCode,
        vatCode,
        countryCode,
      }));
  }, [countryOptions]);

  const showVatFields = useMemo(() => {
    return true;
  }, []);

  const currentCountryOption = useMemo(() => {
    const country = userData?.unconfirmedUser?.createdByCountry;
    return countryOptions.find(
      (option) => country && option?.value?.toLowerCase() === country.toLowerCase(),
    );
  }, [userData, countryOptions]);

  return {
    countryOptions,
    vatOptions,
    showVatFields,
    currentCountryOption,
  };
};
