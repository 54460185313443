import Skeleton from 'Components/Skeleton';

const CompanyInfoWidgetSkeleton = () => {
  const inputsSkeleton = Array(6)
    .fill([
      {
        type: 'text',
        options: {
          width: '30%',
        },
      },
      {
        type: 'input',
      },
    ])
    .flat();
  return (
    <Skeleton
      linesConfig={[
        ...inputsSkeleton,
        {
          type: 'subtitle',
          options: {
            width: '40%',
          },
        },
      ]}
    />
  );
};

export default CompanyInfoWidgetSkeleton;
