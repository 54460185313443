import { useValueStore } from 'Hooks/mobx/useValueStore';
import { viewStore } from '../store';

/**
 * Subscribe to data index of the current row, in case store not syncronized with props - we use index from props.
 */
export const useRowIndex = (staticIndex: number, propsIndex?: number, storeKey?: string) => {
  const getValue = (val) => val?.[staticIndex];
  const index = useValueStore(viewStore, 'indexMap', getValue);
  const currentStoreKey = useValueStore(viewStore, 'storeKey');

  if (storeKey !== currentStoreKey) {
    return propsIndex;
  }
  return index ?? propsIndex;
};
