import { useMemo } from 'react';
import isFunction from 'lodash/isFunction';
import { AccListSelect } from 'Components/AccListSelect';
import Loader from 'Components/Loader';
import { useUniqueKeywordsQuery } from 'Ghql';
import { useFilters } from 'Hooks';
import { MULTIPLE_KEYWORDS } from 'Types/Filter';

export const KeywordListSelect = (props) => {
  const { data: uniqueKeywordsData, loading } = useUniqueKeywordsQuery({
    variables: { filters: useFilters().filter((x) => x.attribute !== MULTIPLE_KEYWORDS) },
  });

  // This way of handling value and setValue is to make it compatible with redux form used in filters
  const value = useMemo(() => props.value || [], [props.value]);
  const setValue = (propsValue) => {
    const nextValue = isFunction(propsValue) ? propsValue(value) : propsValue;
    props.onChange(Array.from(nextValue || []));
  };

  const listHeight = 400;
  const rowHeight = 25;
  const rowWidth = 400;

  if (loading) {
    return (
      <Loader
        style={{
          height: `${listHeight}px`,
          width: rowWidth,
        }}
      />
    );
  }

  return (
    <AccListSelect
      options={uniqueKeywordsData?.uniqueKeywordsNode?.uniqueKeywords || []}
      value={value}
      setValue={setValue}
      listHeight={listHeight}
      rowHeight={rowHeight}
      rowWidth={rowWidth}
    />
  );
};
