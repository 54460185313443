import { Component } from 'react';
import cn from 'classnames';
import './text-area-control.scss';

type Props = {
  className?: string;
  value?: string;
  defaultValue?: string;
  onChange: (...args: Array<any>) => any;
  placeholder: string;
  type: string;
  showError?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  getRef: (...args: Array<any>) => any;
};

class TextArea extends Component<Props> {
  static defaultProps = {
    defaultValue: '',
    onChange: () => {},
    placeholder: '',
    type: 'text',
    showError: false,
    disabled: false,
    autoFocus: undefined,
    getRef: () => {},
  };

  render() {
    const {
      value,
      defaultValue,
      onChange,
      type,
      placeholder,
      showError,
      getRef,
      className,
      autoFocus,
      ...props
    } = this.props;
    const values =
      value !== undefined
        ? {
            value,
          }
        : {
            defaultValue,
          };
    const tempFixProps: any = {
      type,
    };
    return (
      <textarea
        {...props}
        ref={getRef}
        className={cn('text-area-control', className, {
          error: showError,
        })}
        {...values}
        onChange={onChange}
        data-autoFocus={autoFocus}
        placeholder={placeholder}
        {...tempFixProps}
      />
    );
  }
}

export default TextArea;
