import React, { useState } from 'react';
import { useTimeout } from '@mantine/hooks';
import Spinner from 'Components/Spinner';
import { t } from 'Utilities/i18n';

export const DelayedSpinner = ({
  loadingSearchPrompt,
  delay = 600,
}: {
  loadingSearchPrompt?: string;
  delay?: number;
}) => {
  const [showSpinner, setShowSpinner] = useState(false);
  useTimeout(() => setShowSpinner(true), delay, { autoInvoke: true });

  if (!showSpinner) {
    return <>{loadingSearchPrompt ?? t('Loading…')}</>;
  }
  return <Spinner />;
};
