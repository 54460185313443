import { useState } from 'react';
import { useTableStore } from 'Components/DataTable';
import { useModal } from 'Hooks/base/useModal';
import useKeyword from 'Hooks/useKeyword';
import type { FilterBase } from 'Types/Filter';
import { TableIDs } from 'Types/Table';
import { EventName, useMixpanel } from 'Utilities/Analytics/mixpanel';
import { formatDate } from 'Utilities/format';
import { t } from 'Utilities/i18n';
import ModalBorder from '../../Layout/ModalBorder';
import AddNoteForm from './AddNoteForm';

type Props = {
  keywords: number[];
  domainId: number;
  isAllSelected?: boolean;
  filters?: FilterBase[];
  onClose?: () => void;
  enableAddingKeywords?: boolean;
};

const AddNote = (props: Props) => {
  const { enableAddingKeywords, isAllSelected, filters, keywords, onClose, domainId } = props;
  const [loading, setLoading] = useState(false);
  const { updateKeywordsNotes } = useKeyword();
  const tableStore = useTableStore(TableIDs.NOTES);
  const { hideModal } = useModal();
  const trackEvent = useMixpanel();

  const handleSubmit = (form) => {
    const { createdAt, note, keywords: _keywords, forAllKeywords: _isAllSelected } = form;
    setLoading(true);
    const keywordIds = enableAddingKeywords ? _keywords : keywords;
    updateKeywordsNotes({
      isAllSelected: _isAllSelected,
      keywords: keywordIds,
      domain: domainId,
      note,
      createdAt: formatDate(createdAt),
      filters,
    }).then(() => {
      trackEvent(EventName.AddNote, {
        domainId,
        note,
        keywords: keywordIds?.length || 0,
        isAllSelected: !!_isAllSelected,
      });
      setLoading(false);
      onClose && onClose();
      hideModal();
      tableStore?.getData();
    });
  };

  return (
    <ModalBorder className="add-note" title={t('Add Note')} onClose={hideModal}>
      <AddNoteForm
        keywords={keywords}
        isAllSelected={isAllSelected}
        enableAddingKeywords={enableAddingKeywords}
        loading={loading}
        onCancel={hideModal}
        onSubmit={handleSubmit}
      />
    </ModalBorder>
  );
};

export default AddNote;
