import cn from 'classnames';
import { DashboardNode } from '../../support/types';
import styles from '../domains-table.module.scss';

type Props = {
  domainNode: DashboardNode;
};

const CreatedOnCell = (props: Props) => {
  const { dateAdded } = props.domainNode;

  return <div className={cn(styles.domainsCellContainer, styles.textCell)}>{dateAdded}</div>;
};

export default CreatedOnCell;
