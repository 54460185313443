import ErrorBoundary from 'Components/ErrorBoundary';
import { KpiBarQueryProps } from 'Pages/Keywords/Overview/components/KpiBar/Boxes/useKpiBarQueryWithDisplayCurrency';
import KpiBox, { KpiBoxSizeProps } from 'Pages/Keywords/Overview/components/KpiBar/KpiBox';
import { GET_PUBLIC_KPI_DISPLAY_NAME, PUBLIC_KPI_NAMES } from './index';

const MovedDownInRankingsKpiBox = ({
  size,
  kpiBarQueryProps,
  draggable,
}: KpiBoxSizeProps & { kpiBarQueryProps: KpiBarQueryProps }) => {
  const { data, loading } = kpiBarQueryProps;

  const allKpis = data?.kpis?.allKpis;

  const movedDown = allKpis && 'movedDown' in allKpis ? allKpis?.movedDown || 0 : 0;

  return (
    <ErrorBoundary>
      <KpiBox
        id={PUBLIC_KPI_NAMES.KEYWORDS_MOVED_DOWN}
        size={size}
        title={GET_PUBLIC_KPI_DISPLAY_NAME()[PUBLIC_KPI_NAMES.KEYWORDS_MOVED_DOWN]}
        loading={loading}
        value={loading ? '-' : movedDown}
        beforeValue={loading ? '-' : undefined}
        precision={0}
        maxValue={data?.kpis?.allKpis?.keywords}
        draggable={draggable}
      />
    </ErrorBoundary>
  );
};

export default MovedDownInRankingsKpiBox;
