import { useGroupsListQuery } from 'Ghql';
import { RawClient } from './useDropdownData';

export const useGroupData = (
  pagination,
  totalDomains,
  selectedGroup,
  setPagination,
  setSelectedGroup,
) => {
  const {
    data: groupData,
    loading: groupsLoading,
    error: groupDataError,
    refetch: refetchGroupData,
  } = useGroupsListQuery({
    skip: !pagination || !totalDomains,
    onCompleted: (responseData) => {
      const groups: RawClient[] = responseData.clientsRaw || [];
      const domainsInGroup =
        groups.find((client) => client.clientId === selectedGroup)?.totalDomains ||
        groups[0].totalKeywords ||
        0;
      responseData.clientsRaw && setPagination({ ...pagination, results: domainsInGroup });
      responseData.clientsRaw && !selectedGroup && setSelectedGroup(Number(groups[0].clientId));
    },
  });

  const groups = (groupData?.clientsRaw || []) as RawClient[];

  return {
    groupData,
    groupsLoading,
    groupDataError,
    refetchGroupData,
    groups,
  };
};
