import moment from 'moment-timezone';
import { DEFAULT_TOOLTIP_DATE_FORMAT } from 'Constants';

export const getPointDate = (point, includeTimeStamp?: boolean) => {
  const momentDate = moment(point?.points?.[0]?.key).tz('CET', true); // Data coming from backend is CET

  if (!momentDate.isValid()) {
    return '';
  }
  let date = momentDate.format(
    includeTimeStamp ? `${DEFAULT_TOOLTIP_DATE_FORMAT} HH:mm` : DEFAULT_TOOLTIP_DATE_FORMAT,
  );

  if (point?.points?.[0]?.point?.dataGroup?.length > 1) {
    const from = moment(point.points[0].point.dataGroup.options.start).format(
      DEFAULT_TOOLTIP_DATE_FORMAT,
    );
    const to = moment(point.points[0].point.dataGroup.options.end).format(
      DEFAULT_TOOLTIP_DATE_FORMAT,
    );
    if (from !== to) {
      date = `${from} - ${to}`;
    } else {
      date = date || from || to;
    }
  }
  return date;
};

export const getPointKeywordsCount = (point) => {
  return (
    point?.points?.[0]?.point?.totalKeywords ??
    point?.points?.[0]?.point?.dataGroup?.options?.totalKeywords ??
    point?.points?.[0]?.point?.options?.totalKeywords ??
    0
  );
};

export const getPointField = (points, field) => {
  return (
    points?.[0]?.point?.[field] ??
    points?.[0]?.point?.dataGroup?.options?.[field] ??
    points?.[0]?.point?.options?.[field] ??
    0
  );
};
