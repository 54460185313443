import { useEffect, useState } from 'react';
import { ActionIcon, Combobox, ColorPicker as MantineColorPicker, TextInput } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { IconClipboard } from '@tabler/icons-react';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { AccPopover } from 'Components/Popover/AccPopover';
import { useClipboard } from 'Hooks';
import { t } from 'Utilities/i18n';
import styles from './colorPicker.module.scss';

type Props = {
  value: string;
  onChange: (color: string) => void;
};

const ColorPicker = ({ value = '#20bbd7', onChange }: Props) => {
  const clipboard = useClipboard({ timeout: 1000 });

  const [color, setColor] = useState(value);
  const [debouncedColor] = useDebouncedValue(color, 200);

  useEffect(() => {
    onChange(debouncedColor);
  }, [debouncedColor]);

  return (
    <div className={styles.colorPicker}>
      <AccPopover
        target={
          <div className={styles.colorPickerToggle}>
            <div
              className={styles.colorValue}
              style={{
                background: value,
              }}
            />
            <div className={styles.dropdownButton}>
              <Combobox.Chevron />
            </div>
          </div>
        }
        width={202}
        body={{ p: 0 }}
      >
        <MantineColorPicker
          onChange={setColor}
          value={color}
          swatchesPerRow={7}
          swatches={[
            '#25262b',
            '#868e96',
            '#fa5252',
            '#e64980',
            '#be4bdb',
            '#7950f2',
            '#4c6ef5',
            '#228be6',
            '#15aabf',
            '#12b886',
            '#40c057',
            '#82c91e',
            '#fab005',
            '#f89734',
          ]}
          classNames={{ wrapper: styles.wrapper, swatches: styles.swatches, body: styles.body }}
        />
        <div className={styles.dropdownBody}>
          <TextInput
            value={color}
            onChange={(e) => setColor(e.target.value)}
            rightSection={
              <AccTooltip old tooltip={clipboard.copied && t('Copied!')}>
                <ActionIcon onClick={() => clipboard.copy(color)}>
                  <IconClipboard size={16} />
                </ActionIcon>
              </AccTooltip>
            }
          />
        </div>
      </AccPopover>
    </div>
  );
};

export default ColorPicker;
