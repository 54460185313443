/* eslint-disable react/display-name */
import AccButton from 'Components/AccButton/AccButton';
import { linkToImportGSCWithDomains } from 'Components/Filters/LinkToDomain';
import { useModal } from 'Hooks/base/useModal';
import { t } from 'Utilities/i18n';
import { useDomainInfo } from '../hooks';
import { ShowSuggestionsState } from '../types';

export default ({
  domainId,
  showSuggestions,
}: { domainId: string } & Pick<ShowSuggestionsState, 'showSuggestions'>) => {
  const domainInfo = useDomainInfo(domainId);
  const hasGSC = !!domainInfo?.googleOauthConnectionGsc?.id;
  const { showModal, hideModal } = useModal();

  const handleConnectToGSC = () => {
    showModal({
      modalType: 'ConnectToGSC',
      modalTheme: 'light',
      modalProps: {
        domainId,
      },
    });
  };

  if (hasGSC) {
    return (
      <AccButton
        variant="secondary"
        fullWidth
        disabled={showSuggestions}
        onClick={hideModal}
        link={linkToImportGSCWithDomains(domainId)}
      >
        {t('Import from Google Search Console')}
      </AccButton>
    );
  }

  return (
    <AccButton
      variant="secondary"
      fullWidth
      disabled={showSuggestions}
      onClick={handleConnectToGSC}
    >
      {t('Connect to Google Search Console')}
    </AccButton>
  );
};
