import { ColumnSetting } from 'Components/DataTable';
import { QueryOrder } from 'Components/DataTable/constants';
import { SORTING_TYPE } from 'Constants';
import { GetKeywordsQueryVariables } from 'Ghql';
import { t } from 'Utilities/i18n';
import { KeywordColumnID, KeywordSortOption, keywordColumnOrdering } from '../../support/constants';

export const keywordOrderConfig = {
  domain: {
    defaultOrder: QueryOrder.ASC,
    orderBy: KeywordSortOption.DOMAIN,
    sortingKey: SORTING_TYPE.alphabetical,
  },
  keyword: {
    defaultOrder: QueryOrder.ASC,
    orderBy: KeywordSortOption.KEYWORD,
    sortingKey: SORTING_TYPE.alphabetical,
  },
  pixelsFromTop: {
    defaultOrder: QueryOrder.ASC,
    orderBy: KeywordSortOption.PIXELS_FROM_TOP,
    sortingKey: SORTING_TYPE.numerical,
  },
  pixelsFromTopChange: {
    defaultOrder: QueryOrder.DESC,
    orderBy: KeywordSortOption.PIXELS_FROM_TOP_CHANGE,
    sortingKey: SORTING_TYPE.numerical,
  },
  aboveTheFold: {
    defaultOrder: QueryOrder.DESC,
    orderBy: KeywordSortOption.ABOVE_THE_FOLD,
    sortingKey: SORTING_TYPE.numerical,
  },
  searchType: {
    defaultOrder: QueryOrder.DESC,
    orderBy: KeywordSortOption.SEARCH_TYPE,
    sortingKey: SORTING_TYPE.alphabetical,
  },
  searchIntent: {
    defaultOrder: QueryOrder.DESC,
    orderBy: KeywordSortOption.SEARCH_INTENT,
    sortingKey: SORTING_TYPE.alphabetical,
  },
  description: {
    defaultOrder: QueryOrder.ASC,
    orderBy: KeywordSortOption.DESCRIPTION,
    sortingKey: SORTING_TYPE.alphabetical,
  },
  countryLocale: {
    defaultOrder: QueryOrder.ASC,
    orderBy: KeywordSortOption.LOCATION,
    sortingKey: SORTING_TYPE.alphabetical,
  },
  rank: {
    defaultOrder: QueryOrder.ASC,
    orderBy: KeywordSortOption.RANK,
    sortingKey: SORTING_TYPE.rank,
  },
  rankChange: {
    defaultOrder: QueryOrder.ASC,
    orderBy: KeywordSortOption.RANK_CHANGE,
    sortingKey: SORTING_TYPE.numerical,
  },
  baseRank: {
    defaultOrder: QueryOrder.ASC,
    orderBy: KeywordSortOption.BASE_RANK,
    sortingKey: SORTING_TYPE.rank,
  },
  baseRankChange: {
    defaultOrder: QueryOrder.ASC,
    orderBy: KeywordSortOption.BASE_RANK_CHANGE,
    sortingKey: SORTING_TYPE.numerical,
  },
  maxRank: {
    defaultOrder: QueryOrder.ASC,
    orderBy: KeywordSortOption.MAX_RANK,
    sortingKey: SORTING_TYPE.rank,
  },
  localPackRank: {
    defaultOrder: QueryOrder.ASC,
    orderBy: KeywordSortOption.LOCAL_PACK_RANK,
    sortingKey: SORTING_TYPE.rank,
  },
  localPackRankChange: {
    defaultOrder: QueryOrder.ASC,
    orderBy: KeywordSortOption.LOCAL_PACK_RANK_CHANGE,
    sortingKey: SORTING_TYPE.numerical,
  },
  aiTrafficValue: {
    defaultOrder: QueryOrder.ASC,
    orderBy: KeywordSortOption.AI_TRAFFIC_VALUE,
    sortingKey: SORTING_TYPE.numerical,
  },
  aiTrafficValueChange: {
    defaultOrder: QueryOrder.ASC,
    orderBy: KeywordSortOption.AI_TRAFFIC_VALUE_CHANGE,
    sortingKey: SORTING_TYPE.numerical,
  },
  maxAiTrafficValue: {
    defaultOrder: QueryOrder.ASC,
    orderBy: KeywordSortOption.MAX_AI_TRAFFIC_VALUE,
    sortingKey: SORTING_TYPE.numerical,
  },
  maxAiSov: {
    defaultOrder: QueryOrder.ASC,
    orderBy: KeywordSortOption.MAX_AI_SOV,
    sortingKey: SORTING_TYPE.numerical,
  },
  maxTrafficValue: {
    defaultOrder: QueryOrder.ASC,
    orderBy: KeywordSortOption.MAX_TRAFFIC_VALUE,
    sortingKey: SORTING_TYPE.numerical,
  },
  maxSov: {
    defaultOrder: QueryOrder.ASC,
    orderBy: KeywordSortOption.MAX_SOV,
    sortingKey: SORTING_TYPE.numerical,
  },
  url: {
    defaultOrder: QueryOrder.ASC,
    orderBy: KeywordSortOption.URL,
    sortingKey: SORTING_TYPE.alphabetical,
  },
  searchVolume: {
    defaultOrder: QueryOrder.DESC,
    orderBy: KeywordSortOption.SEARCH_VOLUME,
    sortingKey: SORTING_TYPE.numerical,
  },
  aiSearchVolume: {
    defaultOrder: QueryOrder.DESC,
    orderBy: KeywordSortOption.AI_SEARCH_VOLUME,
    sortingKey: SORTING_TYPE.numerical,
  },
  dynamicCtr: {
    defaultOrder: QueryOrder.DESC,
    orderBy: KeywordSortOption.DYNAMIC_CTR,
    sortingKey: SORTING_TYPE.numerical,
  },
  dynamicCtrChange: {
    defaultOrder: QueryOrder.DESC,
    orderBy: KeywordSortOption.DYNAMIC_CTR_CHANGE,
    sortingKey: SORTING_TYPE.numerical,
  },
  dynamicCtrMax: {
    defaultOrder: QueryOrder.DESC,
    orderBy: KeywordSortOption.DYNAMIC_CTR_MAX,
    sortingKey: SORTING_TYPE.numerical,
  },
  dynamicCtrPotential: {
    defaultOrder: QueryOrder.DESC,
    orderBy: KeywordSortOption.DYNAMIC_CTR_POTENTIAL,
    sortingKey: SORTING_TYPE.numerical,
  },
  organicTraffic: {
    defaultOrder: QueryOrder.DESC,
    orderBy: KeywordSortOption.ORGANIC_TRAFFIC,
    sortingKey: SORTING_TYPE.numerical,
  },
  organicTrafficChange: {
    defaultOrder: QueryOrder.DESC,
    orderBy: KeywordSortOption.ORGANIC_TRAFFIC_CHANGE,
    sortingKey: SORTING_TYPE.numerical,
  },
  cpc: {
    defaultOrder: QueryOrder.DESC,
    orderBy: KeywordSortOption.CPC,
    sortingKey: SORTING_TYPE.numerical,
  },
  adCompetition: {
    defaultOrder: QueryOrder.DESC,
    orderBy: KeywordSortOption.COMPETITION,
    sortingKey: SORTING_TYPE.numerical,
  },
  competition: {
    defaultOrder: QueryOrder.DESC,
    orderBy: KeywordSortOption.COMPETITION,
    sortingKey: SORTING_TYPE.numerical,
  },
  sov: {
    defaultOrder: QueryOrder.DESC,
    orderBy: KeywordSortOption.SOV,
    sortingKey: SORTING_TYPE.numerical,
  },
  sovChange: {
    defaultOrder: QueryOrder.DESC,
    orderBy: KeywordSortOption.SOV_CHANGE,
    sortingKey: SORTING_TYPE.numerical,
  },
  serp: {
    defaultOrder: QueryOrder.DESC,
    orderBy: KeywordSortOption.SERP,
    sortingKey: SORTING_TYPE.amount,
  },
  dateAdded: {
    defaultOrder: QueryOrder.DESC,
    orderBy: KeywordSortOption.DATE,
    sortingKey: SORTING_TYPE.recurency,
  },
  timeAgo: {
    defaultOrder: QueryOrder.DESC,
    orderBy: KeywordSortOption.TIME_AGO,
    sortingKey: SORTING_TYPE.recurency,
  },
  trafficValue: {
    defaultOrder: QueryOrder.DESC,
    orderBy: KeywordSortOption.TRAFFIC_VALUE,
    sortingKey: SORTING_TYPE.numerical,
  },
  trafficValueChange: {
    defaultOrder: QueryOrder.DESC,
    orderBy: KeywordSortOption.TRAFFIC_VALUE_CHANGE,
    sortingKey: SORTING_TYPE.numerical,
  },
};

export const keywordTableConfig: ColumnSetting[] = [
  {
    id: KeywordColumnID.CHECKBOX,
    queryWithClauses: ['id'],
    tableSetting: {
      getLabel: () => t('Checkbox'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: true,
      defaultHide: false,
    },
  },
  {
    id: KeywordColumnID.CHECKBOX_STARRED,
    queryWithClauses: ['id', 'starred'],
    tableSetting: {
      getLabel: () => t('Starred'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      defaultHide: false,
      required: true,
      groupingOrder: 'after',
    },
  },
  {
    id: KeywordColumnID.DOMAIN,
    queryWithClauses: ['displayName', 'domain', 'youtubeChannelName'],
    tableSetting: {
      getLabel: () => t('Domain'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: false,
      defaultHide: true,
      orderBy: keywordOrderConfig.domain.orderBy,
    },
  },
  {
    id: KeywordColumnID.KEYWORD,
    queryWithClauses: [
      'id',
      'keyword',
      'tags',
      'rankId',
      'domainId',
      'ignoreInShareOfVoice',
      'ignoreLocalResults',
      'ignoreFeaturedSnippet',
      'enableAutocorrect',
      'ignoredDomain',
    ],
    tableSetting: {
      getLabel: () => t('Keyword'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: true,
      defaultHide: false,
      orderBy: keywordOrderConfig.keyword.orderBy,
    },
  },
  {
    id: KeywordColumnID.SEARCH_TYPE,
    queryWithClauses: ['searchTypeId', 'searchEngineId'],
    tableSetting: {
      getLabel: () => t('Search type'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: true,
      defaultHide: false,
      groupingOrder: 'after',
      orderBy: keywordOrderConfig.searchType.orderBy,
    },
  },
  {
    id: KeywordColumnID.SEARCH_INTENT,
    queryWithClauses: ['searchIntent'],
    tableSetting: {
      getLabel: () => t('Search intent'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: false,
      defaultHide: false,
      groupingOrder: 'after',
      orderBy: keywordOrderConfig.searchIntent.orderBy,
    },
  },
  {
    id: KeywordColumnID.DESCRIPTION,
    queryWithClauses: ['id', 'keywordDescription'],
    tableSetting: {
      getLabel: () => t('Description'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: false,
      defaultHide: true,
      orderBy: keywordOrderConfig.description.orderBy,
    },
  },
  {
    id: KeywordColumnID.LOCATION,
    queryWithClauses: ['countrylocaleId', 'location'],
    tableSetting: {
      getLabel: () => t('Location'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: false,
      defaultHide: false,
      orderBy: keywordOrderConfig.countryLocale.orderBy,
    },
  },
  {
    id: KeywordColumnID.COUNTRY,
    queryWithClauses: ['countrylocaleId', 'location'],
    tableSetting: {
      getLabel: () => t('Country'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: false,
      defaultHide: false,
      orderBy: keywordOrderConfig.countryLocale.orderBy,
    },
  },
  {
    id: KeywordColumnID.LOCALE,
    queryWithClauses: ['countrylocaleId', 'location'],
    tableSetting: {
      getLabel: () => t('Locale'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: false,
      defaultHide: false,
      orderBy: keywordOrderConfig.countryLocale.orderBy,
    },
  },
  {
    id: KeywordColumnID.RANK,
    queryWithClauses: [
      'updatingKeyword',
      'rankValue',
      'hasExtraRanks',
      'searchEngineId',
      'extraRanks',
    ],
    tableSetting: {
      getLabel: () => t('Rank'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: false,
      defaultHide: false,
      orderBy: keywordOrderConfig.rank.orderBy,
    },
  },
  {
    id: KeywordColumnID.RANK_CHANGE,
    queryWithClauses: ['rankValue', 'rankCompare', 'updatingKeyword'],
    tableSetting: {
      getLabel: () => t('Rank +/-'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: false,
      defaultHide: false,
      grouping: KeywordColumnID.RANK,
      groupingOrder: 'after',
      orderBy: keywordOrderConfig.rankChange.orderBy,
    },
  },
  {
    id: KeywordColumnID.BASE_RANK,
    queryWithClauses: [
      'updatingKeyword',
      'baseRank',
      'hasExtraRanks',
      'searchEngineId',
      'extraRanks',
    ],
    tableSetting: {
      getLabel: () => t('Base Rank'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: false,
      defaultHide: true,
      orderBy: keywordOrderConfig.baseRank.orderBy,
    },
  },
  {
    id: KeywordColumnID.BASE_RANK_CHANGE,
    queryWithClauses: ['updatingKeyword', 'searchEngineId', 'baseRank', 'baseRankCompare'],
    tableSetting: {
      getLabel: () => t('Base Rank +/-'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: false,
      defaultHide: true,
      orderBy: keywordOrderConfig.baseRankChange.orderBy,
      grouping: KeywordColumnID.BASE_RANK,
      groupingOrder: 'after',
    },
  },
  {
    id: KeywordColumnID.MAX_RANK,
    queryWithClauses: [
      'updatingKeyword',
      'maxRank',
      'hasExtraRanks',
      'searchEngineId',
      'extraRanks',
    ],
    tableSetting: {
      getLabel: () => t('Max Base Rank'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: false,
      defaultHide: true,
      grouping: KeywordColumnID.BASE_RANK,
      orderBy: keywordOrderConfig.maxRank.orderBy,
    },
  },
  {
    id: KeywordColumnID.LOCAL_PACK_RANK,
    queryWithClauses: [
      'updatingKeyword',
      'localPackRank',
      'hasExtraRanks',
      'searchEngineId',
      'extraRanks',
    ],
    tableSetting: {
      getLabel: () => t('Local Pack Rank'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: false,
      defaultHide: true,
      orderBy: keywordOrderConfig.localPackRank.orderBy,
    },
  },
  {
    id: KeywordColumnID.LOCAL_PACK_RANK_CHANGE,
    queryWithClauses: [
      'updatingKeyword',
      'searchEngineId',
      'localPackRank',
      'localPackRankCompare',
    ],
    tableSetting: {
      getLabel: () => t('Local Pack Rank +/-'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: false,
      defaultHide: true,
      orderBy: keywordOrderConfig.localPackRankChange.orderBy,
      grouping: KeywordColumnID.LOCAL_PACK_RANK,
      groupingOrder: 'after',
    },
  },
  {
    id: KeywordColumnID.AI_TRAFFIC_VALUE,
    queryWithClauses: ['updatingKeyword', 'searchEngineId', 'aiTrafficValue'],
    tableSetting: {
      getLabel: () => t('AI Traffic Value'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: false,
      defaultHide: false,
      orderBy: keywordOrderConfig.aiTrafficValue.orderBy,
    },
  },
  {
    id: KeywordColumnID.AI_TRAFFIC_VALUE_CHANGE,
    queryWithClauses: [
      'updatingKeyword',
      'searchEngineId',
      'aiTrafficValue',
      'aiTrafficValueCompare',
    ],
    tableSetting: {
      getLabel: () => t('AI Traffic Value +/-'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: false,
      defaultHide: false,
      orderBy: keywordOrderConfig.aiTrafficValueChange.orderBy,
      grouping: KeywordColumnID.AI_TRAFFIC_VALUE,
      groupingOrder: 'after',
    },
  },
  {
    id: KeywordColumnID.MAX_AI_TRAFFIC_VALUE,
    queryWithClauses: ['updatingKeyword', 'searchEngineId', 'maxAiTrafficValue'],
    tableSetting: {
      getLabel: () => t('Max AI Traffic value'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: false,
      defaultHide: false,
      grouping: KeywordColumnID.AI_TRAFFIC_VALUE,
      orderBy: keywordOrderConfig.maxAiTrafficValue.orderBy,
    },
  },
  {
    id: KeywordColumnID.MAX_AI_SOV,
    queryWithClauses: ['updatingKeyword', 'searchEngineId', 'maxAiSov'],
    tableSetting: {
      getLabel: () => t('Max AI SoV'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: false,
      defaultHide: false,
      grouping: KeywordColumnID.ORGANIC_TRAFFIC,
      orderBy: keywordOrderConfig.maxAiSov.orderBy,
    },
  },
  {
    id: KeywordColumnID.MAX_TRAFFIC_VALUE,
    queryWithClauses: ['updatingKeyword', 'searchEngineId', 'maxTrafficValue'],
    tableSetting: {
      getLabel: () => t('Max Traffic Value'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: false,
      defaultHide: true,
      grouping: KeywordColumnID.SHARE_OF_VOICE_CPC,
      orderBy: keywordOrderConfig.maxTrafficValue.orderBy,
    },
  },
  {
    id: KeywordColumnID.MAX_SOV,
    queryWithClauses: ['updatingKeyword', 'searchEngineId', 'maxSov'],
    tableSetting: {
      getLabel: () => t('Max SoV'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: false,
      defaultHide: true,
      grouping: KeywordColumnID.SHARE_OF_VOICE,
      orderBy: keywordOrderConfig.maxSov.orderBy,
    },
  },
  {
    id: KeywordColumnID.TOP_DOMAIN,
    queryWithClauses: ['updatingKeyword', 'searchEngineId', 'topDomain', 'baseRank'], // Base rank needed for hover
    tableSetting: {
      getLabel: () => t('Top competitor'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: false,
      defaultHide: false,
      orderBy: undefined,
    },
  },
  {
    id: KeywordColumnID.PIXELS_FROM_TOP,
    queryWithClauses: ['updatingKeyword', 'searchEngineId', 'pixelsFromTop', 'aboveTheFold'],
    tableSetting: {
      getLabel: () => t('Pixels from top'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: false,
      defaultHide: true,
      orderBy: keywordOrderConfig.pixelsFromTop.orderBy,
    },
  },
  {
    id: KeywordColumnID.PIXELS_FROM_TOP_CHANGE,
    queryWithClauses: ['updatingKeyword', 'searchEngineId', 'pixelsFromTopCompare'],
    tableSetting: {
      getLabel: () => t('Pixels from top +/-'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: false,
      defaultHide: true,
      grouping: KeywordColumnID.PIXELS_FROM_TOP,
      orderBy: keywordOrderConfig.pixelsFromTopChange.orderBy,
    },
  },
  {
    id: KeywordColumnID.URL,
    queryWithClauses: [
      'id',
      'domainId',
      'title',
      'description',
      'highestRankingPage',
      'updatingKeyword',
      'preferredLandingPageId',
      'highestRankingPageStatus',
    ],
    tableSetting: {
      getLabel: () => t('URL'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: false,
      defaultHide: false,
      orderBy: keywordOrderConfig.url.orderBy,
    },
  },
  {
    id: KeywordColumnID.SEARCH_VOLUME,
    queryWithClauses: ['id', 'searchVolumeValue', 'validForSearchVolume', 'searchEngineId'],
    tableSetting: {
      getLabel: () => t('Search Volume'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: false,
      defaultHide: false,
      orderBy: keywordOrderConfig.searchVolume.orderBy,
    },
  },
  {
    id: KeywordColumnID.AI_SEARCH_VOLUME,
    queryWithClauses: ['id', 'aiSearchVolume', 'validForSearchVolume', 'searchEngineId'],
    tableSetting: {
      getLabel: () => t('AI Search Volume'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: false,
      defaultHide: false,
      orderBy: keywordOrderConfig.aiSearchVolume.orderBy,
    },
  },
  {
    id: KeywordColumnID.DYNAMIC_CTR,
    queryWithClauses: ['id', 'dynamicCtr'],
    tableSetting: {
      getLabel: () => t('CTR'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: false,
      defaultHide: false,
      orderBy: keywordOrderConfig.dynamicCtr.orderBy,
    },
  },
  {
    id: KeywordColumnID.DYNAMIC_CTR_CHANGE,
    queryWithClauses: ['id', 'dynamicCtr'],
    tableSetting: {
      getLabel: () => t('CTR +/-'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: false,
      defaultHide: false,
      grouping: KeywordColumnID.DYNAMIC_CTR,
      groupingOrder: 'after',
      orderBy: keywordOrderConfig.dynamicCtrChange.orderBy,
    },
  },
  {
    id: KeywordColumnID.DYNAMIC_CTR_MAX,
    queryWithClauses: ['id', 'dynamicCtrMax', 'baseRank'], // Base rank needed for hover
    tableSetting: {
      getLabel: () => t('Max CTR'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: false,
      defaultHide: false,
      grouping: KeywordColumnID.DYNAMIC_CTR,
      groupingOrder: 'after',
      orderBy: keywordOrderConfig.dynamicCtrMax.orderBy,
    },
  },

  {
    id: KeywordColumnID.ORGANIC_TRAFFIC,
    queryWithClauses: ['id', 'organicTraffic', 'validForSearchVolume'],
    tableSetting: {
      getLabel: () => t('AI SoV'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: false,
      defaultHide: false,

      orderBy: keywordOrderConfig.organicTraffic.orderBy,
    },
  },
  {
    id: KeywordColumnID.ORGANIC_TRAFFIC_CHANGE,
    queryWithClauses: [
      'searchVolumeValue',
      'organicTraffic',
      'organicTrafficCompare',
      'updatingKeyword',
      'validForSearchVolume',
    ],
    tableSetting: {
      getLabel: () => t('AI SoV +/-'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: true,
      required: false,
      defaultHide: false,
      grouping: KeywordColumnID.ORGANIC_TRAFFIC,
      groupingOrder: 'after',
      orderBy: keywordOrderConfig.organicTrafficChange.orderBy,
    },
  },
  {
    id: KeywordColumnID.CPC,
    queryWithClauses: ['avgCostPerClick', 'searchVolumeValue', 'validForSearchVolume'],
    tableSetting: {
      getLabel: () => t('CPC'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: false,
      defaultHide: false,
      orderBy: keywordOrderConfig.cpc.orderBy,
    },
  },
  {
    id: KeywordColumnID.AD_COMPETITION,
    queryWithClauses: ['searchVolumeValue', 'competition', 'validForSearchVolume'],
    tableSetting: {
      getLabel: () => t('Ad Competition'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: false,
      defaultHide: true,
      orderBy: keywordOrderConfig.adCompetition.orderBy,
    },
  },
  {
    id: KeywordColumnID.SHARE_OF_VOICE,
    queryWithClauses: [
      'searchVolumeValue',
      'shareOfVoiceValue',
      'shareOfVoiceIsPercentage',
      'shareOfVoicePercentageValue',
      'updatingKeyword',
      'validForSearchVolume',
    ],
    tableSetting: {
      getLabel: () => t('SoV'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: true,
      required: false,
      defaultHide: true,
      orderBy: keywordOrderConfig.sov.orderBy,
    },
  },
  {
    id: KeywordColumnID.SHARE_OF_VOICE_CHANGE,
    queryWithClauses: [
      'searchVolumeValue',
      'shareOfVoiceValue',
      'shareOfVoiceCompare',
      'shareOfVoiceIsPercentage',
      'shareOfVoicePercentageValue',
      'shareOfVoicePercentageValueCompare',
      'updatingKeyword',
      'validForSearchVolume',
    ],
    tableSetting: {
      getLabel: () => t('SoV +/-'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: true,
      required: false,
      defaultHide: true,
      grouping: KeywordColumnID.SHARE_OF_VOICE,
      groupingOrder: 'after',
      orderBy: keywordOrderConfig.sovChange.orderBy,
    },
  },
  {
    id: KeywordColumnID.SHARE_OF_VOICE_CPC,
    queryWithClauses: ['searchVolumeValue', 'shareOfVoiceCpcValue', 'validForSearchVolume'],
    tableSetting: {
      getLabel: () => t('Traffic Value'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: true,
      required: false,
      defaultHide: true,
      orderBy: keywordOrderConfig.trafficValue.orderBy,
    },
  },
  {
    id: KeywordColumnID.SHARE_OF_VOICE_CPC_CHANGE,
    queryWithClauses: [
      'searchVolumeValue',
      'shareOfVoiceCpcValue',
      'shareOfVoiceCpcCompare',
      'updatingKeyword',
      'validForSearchVolume',
    ],
    tableSetting: {
      getLabel: () => t('Traffic Value +/-'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: true,
      required: false,
      defaultHide: true,
      grouping: KeywordColumnID.SHARE_OF_VOICE_CPC,
      groupingOrder: 'after',
      orderBy: keywordOrderConfig.trafficValueChange.orderBy,
    },
  },
  {
    id: KeywordColumnID.SERP,
    queryWithClauses: ['id', 'domainId', 'rankId', 'updatingKeyword', 'serp'],
    tableSetting: {
      getLabel: () => t('SERP features'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: false,
      defaultHide: false,
      orderBy: keywordOrderConfig.serp.orderBy,
    },
  },
  {
    id: KeywordColumnID.DATE_ADDED,
    queryWithClauses: ['dateAdded'],
    tableSetting: {
      getLabel: () => t('Created on'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: false,
      defaultHide: true,
      orderBy: keywordOrderConfig.dateAdded.orderBy,
    },
  },
  {
    id: KeywordColumnID.KEYWORD_TIME_AGO,
    queryWithClauses: ['searchDate', 'updatingKeyword'],
    tableSetting: {
      getLabel: () => t('Age'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: false,
      defaultHide: false,
      orderBy: keywordOrderConfig.timeAgo.orderBy,
    },
  },
  {
    id: KeywordColumnID.ACTIONS,
    queryWithClauses: ['id', 'historyUrl', 'updatingKeyword'],
    tableSetting: {
      getLabel: () => t('Actions'),
      requiresAnalytics: false,
      requiresAdvancedMetrics: false,
      required: false,
      defaultHide: false,
    },
  },
].sort(
  (a, b) =>
    keywordColumnOrdering.indexOf(a?.id as any) - keywordColumnOrdering.indexOf(b?.id as any),
) as ColumnSetting[];

export const KEYWORD_QUERY_OPTIONS: Omit<
  GetKeywordsQueryVariables,
  'filters' | 'pagination' | 'ordering' | 'displayCurrency'
> & { displayCurrency: boolean; withAiSearchVolume: boolean } = {
  withId: false,
  withKeyword: false,
  withStarred: false,
  withTags: false,
  withDynamicTags: true,
  withDynamicCtr: false,
  withOrganicTraffic: false,
  withOrganicTrafficCompare: false,
  withSearchIntent: false,
  withIgnoreInShareOfVoice: false,
  withIgnoreLocalResults: false,
  withIgnoreFeaturedSnippet: false,
  withEnableAutocorrect: false,
  withIgnoredDomains: false,
  withSearchTypeId: false,
  withUpdatingKeyword: false,
  withSearchEngineId: false,
  withDomain: false,
  withDomainId: false,
  withDisplayName: false,
  withKeywordDescription: false,
  withLocation: false,
  withCountrylocaleId: false,
  withRankId: false,
  withRankValue: false,
  withRankCompare: false,
  withHasExtraRanks: false,
  withExtraRanks: false,
  withAboveTheFold: false,
  withTitle: false,
  withDescription: false,
  withPreferredLandingPageId: false,
  // required to calculate landing id
  withLandingPageId: true,
  withHighestRankingPage: false,
  // withHighestRankingPageStatus: false,
  withSearchVolumeValue: false,
  withValidForSearchVolume: false,
  withAiSearchVolume: false,
  withAvgCostPerClick: false,
  withCompetition: false,
  withAnalyticsPotential: false,
  withAnalyticsVisitors: false,
  withShareOfVoiceIsPercentage: false,
  withShareOfVoiceValue: false,
  withShareOfVoiceCompare: false,
  withShareOfVoicePercentageValue: false,
  withShareOfVoicePercentageValueCompare: false,
  withShareOfVoiceCpcValue: false,
  withShareOfVoiceCpcCompare: false,
  withSerp: false,
  withDateAdded: false,
  withSearchDate: false,
  withHistoryUrl: false,
  displayCurrency: true,
  withIgnoredDomain: false,
  withBaseRank: false,
  withBaseRankCompare: false,
  withMaxRank: false,
  withLocalPackRank: false,
  withLocalPackRankCompare: false,
  withMaxAiSov: false,
  withMaxAiTrafficValue: false,
  withAiTrafficValue: false,
  withAiTrafficValueCompare: false,
  withMaxTrafficValue: false,
  withMaxSov: false,
  withPixelsFromTop: false,
  withPixelsFromTopCompare: false,
  withTopDomain: false,
};

export const DEFAULT_KEYWORD_ORDERING = {
  order: QueryOrder.ASC,
  orderBy: KeywordColumnID.KEYWORD,
};

export const DEFAULT_RANK_ORDERING = {
  order: QueryOrder.ASC,
  orderBy: KeywordColumnID.RANK,
};

export const DEFAULT_KEYDIS_ORDERING = {
  order: QueryOrder.ASC,
  orderBy: KeywordColumnID.RANK,
};
