import FieldGroupAccess from 'Components/Fields/Field/FieldGroupAccess';
import { allGroupsId } from 'Constants/workspaces';

type Props = {
  record: Record<string, any>;
};

const GroupAccessCell = ({ record }: Props) => {
  const prefix = `${record.id}_`;
  return (
    <FieldGroupAccess
      name={`${prefix}group_access`}
      size="sm"
      withinPortal
      maxDisplayedValues={2}
      defaultValue={record.clients?.map((client) => client.id)}
      hasAllGroups={record.id === allGroupsId}
      searchMinWidth={50}
    />
  );
};

export default GroupAccessCell;
