/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ItemType, generateSelectId } from 'Utilities/generateSelectId';
import { notEmpty } from 'Utilities/underdash';
import { TreeItem } from '../useComparisonContext';

const getItemData = (
  item: TreeItem,
  path = '',
): {
  type: ItemType;
  label: string;
  selectId: string;
} => {
  if ('cid' in item) {
    return {
      type: 'group',
      label: item.cn || '',
      selectId: generateSelectId('group', item.cid!),
    };
  }
  if ('did' in item) {
    return {
      type: 'domain',
      label: item.dn || item.dd || '',
      selectId: generateSelectId('domain', item.did!, path),
    };
  }
  if ('p' in item) {
    return {
      type: 'folder',
      label: item.p || '',
      selectId: generateSelectId('folder', item.p!, path),
    };
  }
  if ('tn' in item) {
    return {
      type: 'tag',
      label: item.tn || '',
      selectId: generateSelectId('tag', item.tn!, path, !!item.dyn),
    };
  }
  throw new Error(`Unknown item type: ${JSON.stringify(item)}`);
};

export const processTreeStructure = (treeStructure: TreeItem[], path = ''): TreeItem[] => {
  return treeStructure
    .map((item) => {
      const { type, label, selectId } = getItemData(item, path);
      const newData = {
        type,
        label,
        selectId,
      };
      if (type === 'group') {
        return {
          ...newData,
          cid: item.cid,
          cn: item.cn,
          ds: processTreeStructure(item.ds || [], selectId),
          demo: item.demo,
        };
      }
      if (type === 'domain') {
        return {
          ...newData,
          did: item.did,
          dn: item.dn,
          cc: item.cc,
          dd: item.dd,
          fs: processTreeStructure(item.fs?.map((f) => f).flat() || [], selectId),
        };
      }
      if (type === 'folder') {
        const fs = processTreeStructure(item.fs?.map((f) => f).flat() || [], selectId);
        const ts = processTreeStructure(item.ts || [], selectId);
        // If folder has no name, no sub-folders and no tags, it's not needed.
        if (!item.p && !fs?.length && !ts?.length) return null;
        return {
          ...newData,
          p: item.p,
          fs,
          ts,
          disabled: !fs?.length && !ts?.length,
        };
      }
      return {
        ...newData,
        ...item,
      };
    })
    .filter(notEmpty);
};
