import startsWith from 'lodash/startsWith';

let titleTagPrefix = '';
let titleTagContent = 'AccuRanker';
let prevLocationPath = window.location.pathname;

const shouldClearPrefix = () => {
  const currentLocationPath = window.location.pathname;
  return !(
    ((startsWith(prevLocationPath, '/app/keywords') ||
      startsWith(prevLocationPath, '/app/notes')) &&
      (startsWith(currentLocationPath, '/app/keywords') ||
        startsWith(currentLocationPath, '/app/notes'))) ||
    startsWith(currentLocationPath, '/app/settings')
  );
};

export const updateTitleTag = ({ prefix, content }: any) => {
  if (prefix || (prefix === '' && shouldClearPrefix())) {
    titleTagPrefix = prefix;
  }

  if (content || content === '') {
    titleTagContent = content;
  }

  document.title = `${titleTagPrefix ? `${titleTagPrefix} / ` : ''}${titleTagContent} - AccuRanker`;
  prevLocationPath = window.location.pathname;
};
