import {
  Flex,
  FlexProps,
  RadioGroupProps as MantineRadioGroupProps,
  Radio,
  RadioProps,
  useMantineTheme,
} from '@mantine/core';
import merge from 'lodash/merge';
import styles from './radioButtonGroup.module.scss';

export type RadioButtonGroupProps = {
  value: string;
  onChange: (value: string) => void;
  options: { value: string; label?: string }[];
  flexProps?: FlexProps;
  radioProps?: Omit<RadioProps, 'value' | 'label'>;
  nowrap?: boolean;
  disabled?: boolean;
} & Omit<MantineRadioGroupProps, 'children'>;

export const RadioButtonGroup = ({
  value,
  onChange,
  options,
  flexProps,
  radioProps,
  nowrap = false,
  disabled = false,
  ...radioGroupProps
}: RadioButtonGroupProps) => {
  const theme = useMantineTheme();

  const defaultRadioGroupProps: typeof radioGroupProps = {};

  const defaultFlexProps: FlexProps = {
    className: styles.flexWrapper,
  };

  const defaultRadioProps: typeof radioProps = {
    styles: {
      radio: {
        cursor: 'pointer',
      },
      inner: {
        cursor: 'pointer',
      },
      label: {
        cursor: 'pointer',
        paddingLeft: theme.other.gutterSm,
        /* Below is to counter legacy global styling */
        margin: 0,
        lineHeight: '20px',
        fontSize: theme.fontSizes[radioProps?.size ?? 'xs'],
        fontWeight: 600,
      },
    },
  };

  const nowrapRadioProps: typeof radioProps = nowrap
    ? {
        styles: {
          label: {
            whiteSpace: 'nowrap',
          },
        },
      }
    : {};

  const disabledRadioProps: typeof radioProps = disabled
    ? {
        disabled: true,
        styles: {
          radio: {
            cursor: 'default',
          },
          inner: {
            cursor: 'default',
          },
          label: {
            cursor: 'default',
          },
        },
      }
    : {};

  return (
    <Radio.Group
      value={value}
      onChange={onChange}
      {...merge(defaultRadioGroupProps, radioGroupProps)}
    >
      <Flex {...merge(defaultFlexProps, flexProps)}>
        {options.map(({ value: v, label }) => (
          <Radio
            color="snorlax"
            key={v}
            value={v}
            label={label}
            {...merge(defaultRadioProps, nowrapRadioProps, disabledRadioProps, radioProps)}
          />
        ))}
      </Flex>
    </Radio.Group>
  );
};
