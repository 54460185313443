import { FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import type { TagsFilter } from 'Types/Filter';
import { t } from 'Utilities/i18n/index';
import TagsIcon from 'icons/tags.svg?inline';
import TagsEditor from '.';
import { withKeywordTagFilter } from './extension/keywords';

const defaultValue: TagsFilter = {
  attribute: FilterAttribute.TAGS,
  type: FilterValueType.ARRAY,
  comparison: FilterComparison.ANY,
  value: [],
};

const getData = () => {
  return {
    defaultValue,
    title: t('Tags'),
    icon: TagsIcon,
    editor: withKeywordTagFilter(TagsEditor),
    labelFunc: (filter: TagsFilter) => {
      if (
        filter.value?.length === 1 &&
        (filter.comparison === FilterComparison.ALL || filter.comparison === FilterComparison.ANY)
      ) {
        return t('Keywords with the tag: %s', `'${filter.value}'`);
      }

      if (filter.comparison === FilterComparison.ALL) {
        return t('Keywords with all of the tags: %s', `'${filter.value?.join(t('\', \''))}'`);
      }
      if (filter.comparison === FilterComparison.ANY) {
        return t('Keywords with any of the tags: %s', `'${filter.value?.join(t('\', \''))}'`);
      }
      if (filter.comparison === FilterComparison.NONE) {
        if (filter.value?.length === 1) {
          return t('Keywords without the tag: %s', `'${filter.value}'`);
        }
        return t('Keywords with none of the tags: %s', `'${filter.value?.join(t('\', \''))}'`);
      }
      if (filter.comparison === FilterComparison.EMPTY) {
        return t('Keywords with no tags');
      }
    },
  };
};

export default getData;
