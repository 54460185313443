/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { hideModal } from 'Actions/ModalAction';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { EventName, TrackingKey, useMixpanel } from 'Utilities/Analytics/mixpanel';
import { t } from 'Utilities/i18n';
import AddEditWorkspaceForm from '../AddEditWorkspaceForm';
import { useAddWorkspace } from './useAddWorkspace';

type Props = {
  refresh: (...args: Array<any>) => any;
};

/**
 * Renders a modal content for adding a new workspace.
 *
 * @param refresh - A function to refresh the workspaces list after adding a new workspace.
 * @returns The JSX element representing the modal content for adding a new workspace.
 */
const AddWorkspace = ({ refresh }: Props) => {
  const trackEvent = useMixpanel();

  useEffect(() => {
    trackEvent(EventName.WorkspacePage, {
      'Tracking key': TrackingKey.OpenAddModal,
    });
  }, []);

  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(hideModal());
  };

  const handleSubmit = useAddWorkspace(refresh, onClose);

  return (
    <ModalBorder title={t('Add New Workspace')} onClose={onClose}>
      <AddEditWorkspaceForm type="add" onClose={onClose} handleSubmit={handleSubmit} />
    </ModalBorder>
  );
};

AddWorkspace.defaultProps = {
  refresh: () => {},
};

export default AddWorkspace;
