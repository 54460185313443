import { SimpleGrid, Stack } from '@mantine/core';
import linkWithFilters from 'Components/Filters/linkWithFilters';
import FormatNumber from 'Components/FormatNumber/new';
import AccText from 'Components/Text/AccText';
import {
  AboveTheFoldFilter,
  DomainsFilter,
  FilterAttribute,
  FilterComparison,
  FilterValueType,
} from 'Types/Filter';
import { KEYWORDS_FILTER_SET } from 'Types/FilterSet';
import { t } from 'Utilities/i18n';
import { useDomainContext } from './DomainContext';
import styles from '../domainCard.module.scss';

const ABOVE_THE_FOLD_FILTER: AboveTheFoldFilter = {
  attribute: FilterAttribute.ABOVE_THE_FOLD,
  type: FilterValueType.BOOL,
  comparison: FilterComparison.EQ,
  value: true,
};

type SingleKpiProps = {
  title: string;
  value?: number | null;
  link?: string;
  align?: 'start' | 'center' | 'end';
};

const SingleKpi = ({ title, value, link, align = 'start' }: SingleKpiProps) => {
  const isNotAvailable = value === null || value === undefined;
  return (
    <Stack align={align} gap="xs">
      <AccText size="sm" c="gray-9" fw={600} ta={align}>
        {title}
      </AccText>
      {isNotAvailable ? (
        <AccText size="24px" fw={600}>
          N/A
        </AccText>
      ) : (
        <AccText href={link} size="24px" c="blue" fw={600}>
          <FormatNumber value={value ?? 0} />
        </AccText>
      )}
    </Stack>
  );
};

const DomainKpi = () => {
  const { id, countKeywords, untrackedKeywords, aboveTheFold } = useDomainContext();
  const domainId = id?.toString();

  const domainsFilter: DomainsFilter = {
    attribute: FilterAttribute.DOMAINS,
    type: FilterValueType.LIST,
    comparison: FilterComparison.CONTAINS,
    value: [domainId],
  };

  const linkToKeywordsList = (withAboveTheFoldFilter?: boolean) =>
    linkWithFilters({
      to: '/keywords/list',
      overwriteFilters: [domainsFilter, ...(withAboveTheFoldFilter ? [ABOVE_THE_FOLD_FILTER] : [])],
      filterSet: KEYWORDS_FILTER_SET,
      resetFilters: true,
    });

  const linkToKeywordDiscovery = () =>
    linkWithFilters({
      to: '/keywords/keyword_discovery',
      overwriteFilters: [domainsFilter],
      filterSet: KEYWORDS_FILTER_SET,
      resetFilters: true,
    });

  return (
    <SimpleGrid cols={3} spacing="xs" px="lg" py="lg" className={styles.domainKpi}>
      <SingleKpi
        title={t('Tracked Keywords')}
        value={countKeywords}
        align="start"
        link={linkToKeywordsList()}
      />
      <SingleKpi
        title={t('Discovered Keywords')}
        value={untrackedKeywords}
        align="center"
        link={linkToKeywordDiscovery()}
      />
      <SingleKpi
        title={t('Above the Fold')}
        value={aboveTheFold}
        align="end"
        link={linkToKeywordsList(true)}
      />
    </SimpleGrid>
  );
};

export default DomainKpi;
