import { useShowSuggestionsState } from './hooks';

export type KeydisImportConfig = {
  nKeywordsToAdd: number;
  includeKeywords?: string[];
  excludeKeywords?: string[];
  ordering?: any;
};

export enum FieldName {
  KEYWORDS = 'keywords',
  TAGS = 'tags',
  ENGINES = 'engines',
  KEYWORD_SETTINGS = 'keywordSettings',
}

export enum KeywordSettingsFieldName {
  STARRED = 'starred',
  IGNORE_IN_SHARE_OF_VOICE = 'ignoreInShareOfVoice',
}

export type MappedSearchEngine = {
  countrylocale: string;
  locations: string[];
  searchEngines: {
    id: string; // actually number?
    name: string;
    searchTypes: number[];
  }[];
  settings: {
    ignoreLocalResults: boolean;
    ignoreFeaturedSnippet: boolean;
    enableAutocorrect: boolean;
  };
};

export type ShowSuggestionsState = ReturnType<typeof useShowSuggestionsState>;

export enum AddKeywordsMode {
  IMPORT = 'import',
  DEFAULT = 'default',
}

export type AddKeywordsModalType = 'import' | 'default';

export type FormState = {
  [FieldName.KEYWORDS]: string[];
  [FieldName.TAGS]: string[];
  [FieldName.ENGINES]: MappedSearchEngine[];
  [FieldName.KEYWORD_SETTINGS]: {
    [KeywordSettingsFieldName.STARRED]: boolean;
    [KeywordSettingsFieldName.IGNORE_IN_SHARE_OF_VOICE]: boolean;
  };
};
