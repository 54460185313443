const SATURATION = 50;
const LIGHTNESS = 60;

export const stringToColor = (str: string, s = SATURATION, l = LIGHTNESS) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return `hsl(${hash % 360}, ${s}%, ${l}%)`;
};
