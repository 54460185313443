import Skeleton from 'Components/Skeleton';

const AddEditSkeleton = () => {
  return (
    <Skeleton
      className="indented-form-group form-group"
      linesConfig={[
        {
          type: 'text',
          options: {
            width: '30%',
          },
        },
        {
          type: 'input',
        },
        {
          type: 'text',
          options: {
            width: '30%',
          },
        },
        {
          type: 'input',
        },
        {
          type: 'text',
          options: {
            width: '30%',
          },
        },
        {
          type: 'text',
          options: {
            width: '45%',
          },
        },
        {
          type: 'text',
          options: {
            width: '25%',
          },
        },
        {
          type: 'text',
          options: {
            width: '40%',
          },
        },
        {
          type: 'text',
          options: {
            width: '25%',
          },
        },
        {
          type: 'button',
          options: {
            width: '45%',
            alignment: 'center',
          },
        },
      ]}
    />
  );
};

export default AddEditSkeleton;
