import { Component } from 'react';

type Props = {
  onSelect: (...args: Array<any>) => any;
  providers: any[]; // Integration from 'Types/Integration';
};

export default class ManualProvidersList extends Component<Props> {
  render() {
    const { onSelect, providers } = this.props;
    return (
      <div>
        <table className="data-table">
          <tbody>
            {providers.map((option: any) => (
              <tr key={option.party}>
                <td>
                  <a tabIndex={0} onClick={() => onSelect(option)}>
                    {option.party}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
