import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { ROW_SIZE } from '../support/constants';
import { useRowIndex } from '../support/hooks/useRowIndex';

export type RowRenderType<T> = (props: { node?: T; onToggle?: (item: T) => void }) => JSX.Element;

type TableRowProps<T> = {
  data: T[];
  staticIndex: number;
  rowRender: RowRenderType<T>;
  onToggle?: (item: T) => void;
  className?: string;
  propsIndex?: number;
  storeKey?: string;
  topPosition?: number;
};

export const TableRow = <T extends object>({
  staticIndex,
  data,
  rowRender: RowRender,
  onToggle,
  className,
  propsIndex,
  storeKey,
  topPosition,
}: TableRowProps<T>) => {
  const index = useRowIndex(staticIndex, propsIndex, storeKey);

  const rootNode = data[index] || {};
  const node = rootNode as Record<string, string>;
  const name = node?.name || node?.display_name;

  return (
    <div
      className={className}
      style={{
        display: !isEmpty(rootNode) ? 'flex' : 'none',
        height: ROW_SIZE,
        top: `${topPosition}px`,
      }}
      data-testid={`table-row-${name}`}
    >
      <RowRender node={rootNode} onToggle={onToggle} />
    </div>
  );
};
