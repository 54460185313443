import { useIntl } from 'react-intl';
import cn from 'classnames';
import { COLUMN_CHART_HEIGHT } from 'Components/Chart/ColumnChart/support/constants';
import { LineChart, SeriesItem } from 'Components/Chart/LineChart';
import { TooltipConfiguration, chartTooltipTypes } from 'Components/Chart/LineChart/support/types';
import Segment from 'Components/Segment';
import { useAvgSerpFeaturePositionHistoryQuery } from 'Ghql';
import { useFilters } from 'Hooks';
import { t } from 'Utilities/i18n';

const useAverageRightRankData = () => {
  const filters = useFilters();

  const { data, loading } = useAvgSerpFeaturePositionHistoryQuery({
    variables: {
      filters,
    },
  });

  return { series: data?.graphs?.avgSerpFeaturePositionHistory, loading };
};

export const RightRankChart = () => {
  const { series, loading } = useAverageRightRankData();

  const dataSeries: SeriesItem[] = [];

  const intl = useIntl();

  if (series) {
    for (const key in series[0]) {
      if (key !== 'x') {
        dataSeries.push({
          data: series.map((item) => ({ x: item.x, y: item[key] })),
          name: key,
        });
      }
    }
  }

  // Check if all data is null
  const allDataNull = dataSeries.every((item) => item.data.every((d) => d.y === null));

  if (series && allDataNull) {
    return (
      <div className={cn('chart-content', 'chart-container')} style={{ textAlign: 'center' }}>
        <Segment placeholder>
          <div style={{ padding: '24px' }}>
            {t(
              'There is no data to show for keywords in the selected filtering, as you have never ranked inside any of the following SERP features: ',
            )}
            {Object.keys(series[0])
              .filter((key) => key !== 'x')
              .join(', ')}
            .
          </div>
        </Segment>
      </div>
    );
  }

  const tooltipConfig: TooltipConfiguration = {
    type: chartTooltipTypes.MULTI,
    showPercentage: false,
    customNumFormatter: (num: number) =>
      intl?.formatNumber(num, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        style: 'decimal',
      }),
  };

  return (
    <LineChart
      series={dataSeries}
      isLoading={loading}
      stoked
      multiLineNavigator
      enableRangeSelector
      yAxisReversed
      height={COLUMN_CHART_HEIGHT}
      rankYAxis
      tooltip={tooltipConfig}
      enableMarkers
    />
  );
};
