import { useMemo, useRef } from 'react';
import { Box, useMantineTheme } from '@mantine/core';
import { TooltipFormatterContextObject } from 'highcharts';
import moment from 'moment';
import styles from 'Components/Chart/BubbleChart/styles.module.scss';
import { ColumnChart } from 'Components/Chart/ColumnChart/ColumnChart';
import { toMonth } from 'Components/Chart/ColumnChart/support/hooks/useColumnChartConfig';
import { DEFAULT_BACKEND_DATE_FORMAT } from 'Constants';
import { useAiSearchTrendQuery, useSearchTrendQuery } from 'Ghql';
import { useFilters } from 'Hooks';
import { ChartContainer } from 'Pages/Keywords/Overview/components/ChartContainer/index';
import { WithDragDetails } from 'Pages/Keywords/Overview/support/types';
import { colorScheme } from 'Utilities/colors';
import { t, tct } from 'Utilities/i18n';
import { MissingDataOverlay } from '../../MissingDataOverlay';
import { useFakeData } from '../../hooks/useFakeData';
import { useGroupViewInfo } from '../../hooks/useGroupViewInfo';

export type SearchTrendData = {
  value: number;
  date: number;
};

const toYear = (date: number) => {
  return moment.unix(date / 1000).year();
};

export function searchTrendTooltipFormatter(item: TooltipFormatterContextObject) {
  const points = item?.points?.[0].series.points;
  if (!points) return '';
  const x = item.x as number;
  const sameMonthPoints = points?.filter((e: any) => toMonth(x) === toMonth(e.category));
  const colorOrange = `color: ${colorScheme.orange}`;
  const header = sameMonthPoints
    .map((e: any) => `<th style='${x === e.x ? colorOrange : ''}'>${toYear(e?.x)}</th>`)
    .join('');
  const row = sameMonthPoints
    .map((e: any) => `<td style='${x === e.x ? colorOrange : ''}'>${e?.y?.toLocaleString()}</td>`)
    .join('');

  return `<table class='${styles.tooltip}'>
  <tr>
    <th></th>
    ${header}
  </tr>
  <tr>
    <td style='font-weight: bold'>${moment(new Date(x)).format('MMM')}</td>
    ${row}
  </tr>
  <tr>
  </tr>
</table>`;
}

const getCsvData = (resultData: SearchTrendData[]) => () =>
  `Date,Search Volume\n${resultData
    .slice()
    .reverse()
    .map((e) => `${moment.unix(e.date / 1000).format('YYYY-MM-DD')},${e.value}`)
    .join('\n')}`;

export const SearchTrend = (props: WithDragDetails & { useAi?: boolean }) => {
  const filters = useFilters();
  const theme = useMantineTheme();
  const { isEmptyGroup, isGroupView } = useGroupViewInfo(filters);
  const chartRef = useRef<HTMLDivElement>(null);

  const useAi = props.useAi ?? false;

  const { data: searchVolumeData, loading: searchVolumeLoading } = useSearchTrendQuery({
    variables: { filters, name: 'searchTrendGraph' },
    skip: useAi,
  });

  const { data: aiSearchVolumeData, loading: aiSearchVolumeLoading } = useAiSearchTrendQuery({
    variables: { filters, name: 'aiSearchTrendGraph' },
    skip: !useAi,
  });

  const data = useAi ? aiSearchVolumeData : searchVolumeData;
  const loading = useAi ? aiSearchVolumeLoading : searchVolumeLoading;

  const dataKey = useAi ? 'overviewAiSearchTrend' : 'overviewSearchTrend';

  const resultData = useMemo(
    () =>
      data?.graphs?.[dataKey]?.map((e) => ({
        value: e.searchVolumeTotal ?? 0,
        date: moment(e.month ?? '', DEFAULT_BACKEND_DATE_FORMAT)
          .toDate()
          .getTime(),
      })) ?? [],
    [data],
  );

  const filtersAdded = filters.length > 3;
  const showFakeData = !filtersAdded && !data?.graphs?.[dataKey]?.length;

  const { SearchTrendFakeData } = useFakeData();

  const title = useAi ? t('AI Search Trend') : t('Search Trend');

  return (
    <ChartContainer
      title={title}
      tooltip={[
        tct(
          'See the combined [ai]search volume of all your current keywords on a monthly basis ' +
            'from three years back to the most recent data.[br][br]' +
            'Data for the latest complete month will be available around the 15th of the following month.',
          { ai: useAi ? 'AI ' : '', br: <br />, b: <b /> },
        ),
      ]}
      getCsvData={resultData ? () => getCsvData(resultData) : undefined}
      dragRef={props.dragRef}
      boardControls={props.boardControls}
      chartRef={chartRef}
    >
      <Box>
        <Box pos="relative" ref={chartRef}>
          {!loading && showFakeData && (
            <MissingDataOverlay
              type="searchTrend"
              isGroupView={isGroupView}
              isEmptyGroup={isEmptyGroup}
            />
          )}
          <ColumnChart
            items={[
              {
                type: 'column',
                data: showFakeData ? SearchTrendFakeData : resultData,
                title: `${title}:`,
                color: `${theme.colors.orange[5]}`,
              },
            ]}
            loading={loading}
            tooltipFormatter={searchTrendTooltipFormatter}
          />
        </Box>
      </Box>
    </ChartContainer>
  );
};
