import { useRef } from 'react';
import { Carousel } from '@mantine/carousel';
import '@mantine/carousel/styles.css';
import { Divider, Rating } from '@mantine/core';
import Autoplay from 'embla-carousel-autoplay';
import AccText from 'Components/Text/AccText';
import styles from '../expired-page.module.scss';

type CustomerReviewType = {
  name: string;
  title: string;
  quote: string;
};

const CustomerReviews = () => {
  const autoplay = useRef(Autoplay({ delay: 20000 }));

  const customerReviews: CustomerReviewType[] = [
    {
      name: 'Rashed ul K.',
      title: 'Engineer',
      quote:
        'Accuranker provides the most perfect tracking of my KWs compared to other similar tools on the market. Its UI and features are very friendly and easy to use. Payment terms are OK I feel. When it comes to KW tracking, AccuRanker is the best.',
    },
    {
      name: 'Esben Rasmussen',
      title: 'Senior SEO Manager, TRANSACT',
      quote:
        'In the fast-paced agency environment at TRANSACT, managing multiple clients means we need tools that are not just effective but also incredibly fast. Its rapid keyword tracking capabilities allow us to deliver real-time insights to our clients, giving us the edge in a highly competitive market. It\'s not just about speed; it\'s about the ability to offer timely and accurate SEO strategies to a diverse portfolio of clients.',
    },
    {
      name: 'Rasmus Lindbacke',
      title: 'Head of Search, Saxo Bank',
      quote:
        'As Head of Search for Saxo Bank, I\'ve found that Accuranker\'s true strengths extend beyond its speed and accuracy. What truly sets it apart is the exceptional customer service, the tool\'s flexibility, and the team\'s responsiveness to user feedback. Accuranker don\'t just provide a service; they actively listen and adapt, continually evolving the tool with meaningful updates. This level of dedication and commitment to development makes Accuranker not just a tool but a valuable partner in our SEO strategy.',
    },
  ];
  return (
    <Carousel
      plugins={[autoplay.current]}
      mt="xl"
      w={640}
      mx="auto"
      withIndicators
      classNames={{
        root: styles.carouselRoot,
        slide: styles.slide,
        controls: styles.controls,
        control: styles.control,
        indicators: styles.indicators,
        indicator: styles.indicator,
      }}
    >
      {customerReviews.map((review) => (
        <Carousel.Slide key={review.name}>
          <div className={styles.review}>
            <AccText fw={300} my="auto" ta="center">
              {review.quote}
            </AccText>
            <Divider color="gray.1" my="sm" />
            <AccText ta="center" fw={600}>
              {review.name}
            </AccText>
            <AccText ta="center" fw={300}>
              {review.title}
            </AccText>
            <Rating readOnly value={5} mx="auto" color="orange" />
          </div>
        </Carousel.Slide>
      ))}
    </Carousel>
  );
};

export default CustomerReviews;
