import { useIntl } from 'react-intl';
import { LineChart } from 'Components/Chart/LineChart';
import { TooltipConfiguration, chartTooltipTypes } from 'Components/Chart/LineChart/support/types';
import { useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import { t } from 'Utilities/i18n';
import { useSpendinChartData } from './useSpendinChartData';
import styles from './spendingChart.module.scss';

type SpendingChartProps = {
  startDate: Date;
  endDate: Date;
};

export const SpendingChart = ({ startDate, endDate }: SpendingChartProps) => {
  const { colorMap } = useQueryDomainInfo();
  const { data, loading } = useSpendinChartData(startDate, endDate);
  const intl = useIntl();

  const tooltipConfig: TooltipConfiguration = {
    type: chartTooltipTypes.MULTI,
    showPercentage: false,
    includeTimeStamp: true,
    customNumFormatter: (num: number) =>
      intl?.formatNumber(num, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        style: 'decimal',
      }),
  };

  return (
    <div className={styles.container}>
      <div className={styles.chart}>
        <LineChart
          series={data}
          hideNotes
          isLoading={loading}
          tooltip={tooltipConfig}
          loaderType={'default'}
          colors={colorMap}
          disableLegend={true}
          isRank={false}
          stoked
          yAxisLabel={t('Requests')}
          showHoursOnXAxis
        />
      </div>
    </div>
  );
};
