import { Field, Fields } from 'redux-form';
import Validator from 'Utilities/validation';
import InputField from './InputField';

type Props = {
  iconDropdown?: boolean;
  handleSelect?: (...args: Array<any>) => any;
  autoFocus: boolean;
};

const NumberChangeEditor = (props: Props) => {
  return (
    <div className="number-filter-editor">
      <Fields
        names={['comparison', 'value', 'attribute', 'type']}
        component={InputField}
        {...props}
      />
      <Field name="value" component="div" validate={Validator.numeric} />
    </div>
  );
};

NumberChangeEditor.defaultProps = {
  iconDropdown: false,
  handleSelect: () => {},
};

export default NumberChangeEditor;
