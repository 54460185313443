import AccText from 'Components/Text/AccText';
import AccTitle from 'Components/Title/AccTitle';
import { t } from 'Utilities/i18n';

export const OveruseChartDescription = () => {
  return (
    <div>
      <AccTitle type="h4" mb="xs">
        {t('Daily keyword exceedings transactions')}
      </AccTitle>
      <AccText fw={400}>{t('Choose your billing period.')}</AccText>
    </div>
  );
};
