import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Divider, Flex } from '@mantine/core';
import { IconEye, IconEyeOff } from '@tabler/icons-react';
import config from 'config';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import AccButton from 'Components/AccButton/AccButton';
import { Field } from 'Components/Fields';
import { Form, OnSubmitCallback } from 'Components/Fields/Form/Form';
import AccText from 'Components/Text/AccText';
import { LoginInput } from 'Ghql';
import { t } from 'Utilities/i18n';
import Validator from 'Utilities/validation';
import AuthButtons from './AuthButtons';
import loginStyles from '../login-page.module.scss';
import styles from './LoginForm.module.scss';

export type LoginFormValues = Omit<LoginInput, 'clientMutationId'>;

type Props = { onSubmit: OnSubmitCallback<LoginFormValues> };

export default function LoginForm({ onSubmit }: Props): JSX.Element {
  const [hiddenPassword, setHiddenPassword] = useState(true);
  return (
    <Form<LoginFormValues> onSubmit={onSubmit} subscription={{ submitting: true }}>
      {({ submitting }) => (
        <>
          <Flex direction="column" rowGap="md">
            <Field.TextInput
              boxProps={{ mt: 'xxs' }}
              autoFocus
              name="username"
              placeholder={t('Work email address')}
              autocomplete="email"
              validate={[Validator.required, Validator.emailHtml]}
            />
            <Field.TextInput
              boxProps={{ mt: 'xxs' }}
              name="password"
              placeholder={t('Password')}
              type={hiddenPassword ? 'password' : 'text'}
              autoComplete="current-password"
              validate={Validator.required}
              buttonSlot={
                <AccActionIcon
                  variant="subtle"
                  onClick={() => setHiddenPassword((isHidden) => !isHidden)}
                >
                  {hiddenPassword ? <IconEyeOff /> : <IconEye />}
                </AccActionIcon>
              }
            />
            <AccText mt={-12} size="xs" ta="center" fw={400} ml="auto">
              <Link
                to="/forgotpassword"
                tabIndex={-1}
                style={{ display: 'flex', height: '100%', placeItems: 'center' }}
              >
                {t('Forgot your password?')}
              </Link>
            </AccText>
            <Form.Errors />

            <AccButton
              variant="primary"
              size="md"
              m="auto"
              loading={submitting}
              disabled={submitting}
              type="submit"
              fullWidth
            >
              {t('Sign in')}
            </AccButton>

            <Divider
              className={loginStyles.authPageOrDivider}
              labelPosition="center"
              label={t('OR SIGN IN WITH')}
            />
            <AuthButtons />
            <Flex justify="center" mt="xxs">
              <AccText size="xs" ta="center" inline mr={'xxs'}>
                {t('Don\'t have an account?')}
              </AccText>
              <AccText size="xs" inline fw={400} variant="link">
                <a href={config.registerUrl} tabIndex={-1} className={styles.signupLink}>
                  {t('Sign up')}
                </a>
              </AccText>
            </Flex>
          </Flex>
        </>
      )}
    </Form>
  );
}
