import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { updateDomain } from 'Actions/DomainsAction';
import { updateUserOrganization } from 'Actions/UserAction';
import { useDomainUnrankedWeightQuery, useOrganizationUnrankedWeightQuery } from 'Ghql';
import { selectDomainId, selectDomainUnrankedWeight } from 'Selectors/DomainSelector';
import {
  selectOrganizationId,
  selectOrganizationUnrankedWeight,
} from 'Selectors/OrganizationSelector';
import { useActions } from './redux/useActions';

function useDomainUnrankedWeight() {
  const reduxDomainUnrankedWeight = useSelector(selectDomainUnrankedWeight);
  const reduxDomainId = useSelector(selectDomainId);

  const { data: domainUnrankedWeightData, loading: domainUnrankedWeightLoading } =
    useDomainUnrankedWeightQuery({ variables: { id: reduxDomainId || '' }, skip: !reduxDomainId });

  const domainUnrankedWeight = domainUnrankedWeightData?.domain?.unrankedWeight ?? null;

  const reduxActions = useActions({ updateDomain });

  useEffect(() => {
    if (
      !domainUnrankedWeightLoading &&
      domainUnrankedWeight !== reduxDomainUnrankedWeight &&
      reduxDomainId
    ) {
      reduxActions.updateDomain(reduxDomainId, {
        unrankedWeight: domainUnrankedWeight,
      });
    }
  }, [domainUnrankedWeightLoading, domainUnrankedWeight, reduxDomainUnrankedWeight, reduxDomainId]);

  return domainUnrankedWeight;
}

const useOrganizationUnrankedWeight = () => {
  const reduxOrganizationId = useSelector(selectOrganizationId) || '';
  const reduxOrganizationUnrankedWeight = useSelector(selectOrganizationUnrankedWeight);

  const { data: organizationUnrankedWeightData, loading: organizationUnrankedWeightLoading } =
    useOrganizationUnrankedWeightQuery({
      variables: { id: reduxOrganizationId },
      skip: !reduxOrganizationId,
    });

  const organizationUnrankedWeight =
    organizationUnrankedWeightData?.organization?.unrankedWeight ?? null;

  const reduxActions = useActions({ updateUserOrganization });

  useEffect(() => {
    if (
      !organizationUnrankedWeightLoading &&
      organizationUnrankedWeightData &&
      organizationUnrankedWeight !== reduxOrganizationUnrankedWeight
    ) {
      reduxActions.updateUserOrganization({
        unrankedWeight: organizationUnrankedWeight,
      });
    }
  }, [
    organizationUnrankedWeightLoading,
    organizationUnrankedWeightData,
    organizationUnrankedWeight,
    reduxOrganizationUnrankedWeight,
  ]);

  return organizationUnrankedWeight;
};

export const useUnrankedWeight = () => {
  const domainUnrankedWeight = useDomainUnrankedWeight();
  const organizationUnrankedWeight = useOrganizationUnrankedWeight();

  return {
    domainUnrankedWeight,
    organizationUnrankedWeight,
  };
};
