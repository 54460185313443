import { useSelector } from 'react-redux';
import { useModal } from 'Hooks/base/useModal';
import { selectDomainId } from 'Selectors/DomainSelector';

interface KeywordCellProps<T> {
  record: T;
}

export const KeywordCell = <T extends { id: string; keyword: string }>(
  props: KeywordCellProps<T>,
) => {
  const domainId = useSelector(selectDomainId);
  const { showModal } = useModal();

  const onKeywordOpen = (event) => {
    event?.preventDefault();

    if (!props.record?.id) {
      return;
    }
    showModal({
      modalType: 'KeywordInfo',
      modalTheme: 'light',
      modalProps: {
        keywordId: props.record?.id,
        keyword: props.record?.keyword,
        domainId,
      },
    });
  };
  return (
    <div>
      <a href="" tabIndex={0} className="table-link" onClick={onKeywordOpen}>
        {props.record?.keyword}
      </a>
    </div>
  );
};
