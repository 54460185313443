import { t } from 'Utilities/i18n';

const bytesToSize = (bytes: number): string => {
  const sizes: string[] = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'n/a';
  const size: number = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString());
  if (size === 0) return `${bytes} ${sizes[size]}`;
  return `${(bytes / Math.pow(1024, size)).toFixed(1)} ${sizes[size]}`;
};

export const getIsValidFile = (
  file: File,
  validMimeTypes: string[],
  fileErrorMessage?: string,
): string | undefined => {
  const isValid = validMimeTypes.some((type) => type === file.type);
  return !isValid ? fileErrorMessage ?? t('Invalid file type.') : undefined;
};

export const getIsValidSize = (file: File, maxSize: number): string | undefined => {
  if (file.size > maxSize) {
    return t('File size is too big. Please provide files no larger than %s', bytesToSize(maxSize));
  }
};
