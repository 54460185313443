import { useState } from 'react';
import { SortEvent, SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import { AddKpiDropdown } from 'Pages/Keywords/Overview/components/KpiBar/components/AddKpiDropdown';
import { KPI_NAMES, KpiMap } from 'Pages/Keywords/Overview/components/KpiBar/index';
import { EventName, useMixpanel } from 'Utilities/Analytics/mixpanel';
import style from './kpi-box.module.scss';

const SortableItem = SortableElement(
  ({ id, kpiBarQueryProps, removeItem, disableDnd, dragged }) => {
    const KpiComponent = KpiMap[id];

    if (!KpiComponent) {
      return null;
    }

    return (
      <KpiComponent
        size="large"
        kpiBarQueryProps={kpiBarQueryProps}
        draggable={disableDnd ? undefined : ({ removeChart: () => removeItem(id), dragged } as any)}
      />
    );
  },
) as any;

const SortableContainerResult = SortableContainer<any>(({ children, isDragging }) => {
  return (
    <div data-dragging={isDragging} className={style.draggableList}>
      {children}
    </div>
  );
});

// Cancel drag on helper icon
function shouldCancelDrag(element: SortEvent | null) {
  let cur: HTMLElement | null = element?.target as HTMLElement;

  while (cur) {
    if (cur?.dataset?.noDnd) {
      return true;
    }
    cur = cur?.parentElement;
  }

  return false;
}

// gridTemplateColumns: 'repeat(auto-fill, minmax(160px, calc((100% - 5 * 24px) / 6)))',
const DraggableKpiBar = ({ kpis, kpiBarQueryProps, saveKpis, disableEdit }) => {
  const [items, saveItems] = useState<unknown[]>(kpis);
  const [isDragging, setIsDragging] = useState(false);

  const setItems = (fn) => {
    saveItems((val) => {
      const resultState = fn(val);
      saveKpis(resultState);
      return resultState;
    });
  };

  const trackEvent = useMixpanel();

  const trackDrag = () => {
    const extraProps = {
      Variant: 'KPI',
    };
    trackEvent(EventName.CustomizeDashboardDrag, extraProps);
  };

  const trackEditKPI = (variant: string, id: string) => {
    const extraProps = {
      Variant: variant,
      ID: id,
    };
    trackEvent(EventName.EditKPI, extraProps);
  };

  const [activeItemIndex, setActiveItem] = useState<null | number>(null);
  const onSortEnd = ({ oldIndex, newIndex }) => {
    trackDrag();
    setActiveItem(null);
    setItems((prevItems) => arrayMove(prevItems, oldIndex, newIndex));
    setIsDragging(false);
  };

  const onSortStart = (element) => {
    setActiveItem(element?.index ?? null);
    setIsDragging(true);
  };

  const removeItem = (id) => {
    trackEditKPI('Remove', id);
    setItems((prevItems) => prevItems.filter((e) => e !== id));
  };

  const addItem = (id) => {
    trackEditKPI('Add', id);
    setItems((prevItems) => [...prevItems, id]);
  };

  const addItemList = Object.values(KPI_NAMES).filter((id) => !items.includes(id));

  return (
    <div>
      <SortableContainerResult
        onSortEnd={onSortEnd}
        axis="xy"
        useDragHandle
        onSortStart={onSortStart}
        updateBeforeSortStart={onSortStart}
        isDragging={isDragging}
        shouldCancelStart={shouldCancelDrag}
      >
        {items.map((value, index) => (
          <SortableItem
            key={`item-${value}`}
            index={index}
            id={value}
            kpiBarQueryProps={kpiBarQueryProps}
            removeItem={removeItem}
            disabled={disableEdit}
            disableDnd={disableEdit}
            dragged={activeItemIndex === index}
          />
        ))}
      </SortableContainerResult>
      {!disableEdit && <AddKpiDropdown addItemList={addItemList} addItem={addItem} />}
    </div>
  );
};

export { DraggableKpiBar };
