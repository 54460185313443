import { Fragment, memo, useState } from 'react';
import { Flex, Group } from '@mantine/core';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import AccLazyPopover from 'Components/AccLazyPopover/AccLazyPopover';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { Flag } from 'Components/Flag';
import { MetaDataCountrylocaleNode } from 'Ghql';
import { useMetaData } from 'Hooks/data/user/useMetaData';
import { propsIsEqualComparison } from 'Utilities/compare';
import { t } from 'Utilities/i18n';
import { notEmpty } from 'Utilities/underdash';
import styles from './countryCell.module.scss';

const SHOW_NUMBER_OF_COUNTRIES = 3;

const ExtraFlags = ({ countryList }: { countryList: MetaDataCountrylocaleNode[] }) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  return (
    <AccLazyPopover
      withinPortal={false}
      popoverZIndex={1}
      target={
        <div className={styles.extraFlagsContainer}>
          <AccTooltip delay={0} tooltip={t('Show all countries')}>
            <AccActionIcon
              classNames={{ icon: styles.actionIcon, root: styles.actionIconRoot }}
              size="xs"
            >{`+${countryList.length}`}</AccActionIcon>
          </AccTooltip>
        </div>
      }
      classNames={{ dropdown: styles.flagPopover }}
      position="right"
      opened={popoverOpen}
      offset={2}
      onToggle={() => setPopoverOpen((open) => !open)}
    >
      <Flex ml={-24} wrap="wrap" gap={3} direction={'row'}>
        {countryList.map(({ countryCode, locale, region }) => (
          <Fragment key={countryCode}>
            {countryCode && (
              <AccTooltip delay={0} tooltip={`${locale} (${region})`}>
                <Flag fadedColors size="md" mr={2} country={countryCode} />
              </AccTooltip>
            )}
          </Fragment>
        ))}
      </Flex>
    </AccLazyPopover>
  );
};

interface CountryCellProps {
  countryCodes: string | string[];
}

export const CountryCell = memo(({ countryCodes }: CountryCellProps) => {
  const countryCodeList = Array.isArray(countryCodes) ? countryCodes : [countryCodes];
  const metaData = useMetaData();
  const countryLocales = metaData?.countrylocales || [];
  const countryList =
    countryCodeList
      ?.map((countryCode) => {
        return countryLocales.find((cl) => cl?.countryCode === countryCode.toUpperCase());
      })
      .filter(notEmpty) || [];

  const visibleCountries = countryList.slice(0, SHOW_NUMBER_OF_COUNTRIES);
  const extraCountries = countryList.slice(SHOW_NUMBER_OF_COUNTRIES, countryList.length);

  return (
    <Flex gap={0} justify="start">
      <Group gap="6px" align="center">
        {visibleCountries.map(
          (country) =>
            country.countryCode && (
              <div key={country.id} data-stacked={true} className={styles.flagWrapper}>
                <AccTooltip tooltip={`${country?.region}`}>
                  <Flag fadedColors size="md" country={country.countryCode} />
                </AccTooltip>
              </div>
            ),
        )}
      </Group>
      {!!extraCountries.length && <ExtraFlags countryList={extraCountries} />}
    </Flex>
  );
}, propsIsEqualComparison);
CountryCell.displayName = 'CountryCell';
