import React, { ReactElement } from 'react';
import { useTreeDragLayer } from 'Pages/Keywords/Groupings/EditMode/react-dnd-treeview/hooks';
import { DragLayerMonitorProps } from 'Pages/Keywords/Groupings/EditMode/react-dnd-treeview/types';
import { DragPreviewRender } from '../components/DragPreviewRender';

const rootStyle: React.CSSProperties = {
  height: '100%',
  left: 0,
  pointerEvents: 'none',
  position: 'fixed',
  top: 0,
  width: '100%',
  zIndex: 100,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getItemStyles = <T,>(monitorProps: DragLayerMonitorProps): React.CSSProperties => {
  const offset = monitorProps.clientOffset;

  if (!offset) {
    return {};
  }

  const { x, y } = offset;
  const transform = `translate(${x}px, ${y}px)`;

  return {
    pointerEvents: 'none',
    transform,
  };
};

export const DragLayer = (): ReactElement | null => {
  const monitorProps = useTreeDragLayer();
  const { isDragging, clientOffset } = monitorProps;

  if (!isDragging || !clientOffset) {
    return null;
  }

  return (
    <div style={rootStyle}>
      <div style={getItemStyles(monitorProps)}>
        <DragPreviewRender {...monitorProps} />
      </div>
    </div>
  );
};
