import { useMemo, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { useClientSideSearch } from 'Components/Table/TreeTable';
import { useUpdateFolderStructureMutation } from 'Ghql';
import { useDomainId } from 'Hooks/data/domain/useQueryDomainInfo';
import toast from 'Hooks/useToast';
import { normalizeTree } from 'Utilities/Table/Tree/treeUtils';
import { t } from 'Utilities/i18n';
import { ROOT_ID } from '../constants';
import { convertListToTree, convertNormalizedToExternalNode, getAddNodeItem } from '../helpers';
import { FolderStructureData, NestedTreeItem } from '../types';

const useOnSaveTree = ({ refetch, setEditMode, treeData, initialTreeData }) => {
  const domainId = useDomainId();
  const [isLoading, setIsLoading] = useState(false);
  const [updateFolderStructure, { client, loading }] = useUpdateFolderStructureMutation({
    onCompleted(data) {
      if (data.updateFolderStructure?.errors?.length) {
        toast.error(t('Failed! Please try again, and contact support if the problem persists.'));
      } else {
        setIsLoading(true);
        setTimeout(() => {
          setIsLoading(false);
          client.resetStore();
          refetch();
          setEditMode(false);
        }, 0);
      }
    },
    onError() {
      toast.error(t('Failed! Please try again, and contact support if the problem persists.'));
    },
  });

  const handleSubmit = () => {
    const oldTree = initialTreeData.map(convertNormalizedToExternalNode);
    const newTree = treeData.map(convertNormalizedToExternalNode);
    if (isEqual(oldTree, newTree)) {
      setEditMode(false);
      return;
    }

    updateFolderStructure({
      variables: {
        newTree,
        domainId: parseInt(domainId as string, 10),
      },
    });
  };

  return [handleSubmit, loading || isLoading];
};

export function addTreeInfo(tree, targetId, nextItem?: any) {
  const itemToAdd = nextItem || getAddNodeItem();

  const list = normalizeTree(tree).filter((e) => !e.isAdd);

  const isRootTarget = targetId === ROOT_ID;
  const index = isRootTarget ? 0 : list.findIndex((e) => e.id === targetId);

  if (index !== -1 && itemToAdd) {
    const targetIndex = Math.min(targetId === ROOT_ID ? 0 : index + 1, list.length);
    if (!isRootTarget) {
      list[index].state = { expanded: true };
    }
    list.splice(targetIndex, 0, { ...itemToAdd, parent: targetId });
  }

  return convertListToTree(list);
}

/**
 * Initially we receive normalized tree from server, to display it in `react-virtualized-tree`
 * - data in nested and non-nested formats,
 * - make filtering by search query
 * - add node for adding folder in add mode
 * - sync expanded rows between view and edit mode
 */
export function useGroupData(props: {
  flatData: FolderStructureData[];
  initialExpanded: string[] | undefined;
  isAddMode: boolean;
  refetch?(): void;
  setEditMode(v: boolean): void;
  addTarget: any;
}) {
  const [initListData] = useState<FolderStructureData[]>(props.flatData);
  const initTreeDataData: NestedTreeItem[] = useMemo(
    () => convertListToTree(initListData, props.initialExpanded),
    [initListData, props.initialExpanded],
  );
  const [treeNested, setTreeNested] = useState<NestedTreeItem[]>(initTreeDataData);
  const onChange = setTreeNested;

  const { nestedData, submitSearch, activeSearch } = useClientSideSearch<NestedTreeItem>(
    treeNested,
    'id',
    initTreeDataData,
  );

  const resultNestedData: NestedTreeItem[] = useMemo(() => {
    let resultData = [...nestedData];
    if (props.isAddMode) {
      resultData = addTreeInfo(resultData, props.addTarget);
    }
    return resultData;
  }, [nestedData, props.isAddMode, props.addTarget]);

  const normalizedTreeFiltered = useMemo(() => normalizeTree(resultNestedData), [resultNestedData]);
  const normalizedTree = useMemo(() => normalizeTree(treeNested), [treeNested]);

  const [handleSave, loading] = useOnSaveTree({
    refetch: props.refetch,
    setEditMode: props.setEditMode,
    treeData: normalizedTree,
    initialTreeData: initListData,
  });

  return {
    handleSave,
    loading: !!loading,
    initialTreeData: initListData,
    setTreeNested,
    onChange,
    submitSearch,
    resultNestedData,
    normalizedTreeFiltered,
    normalizedTree,
    activeSearch,
  };
}
