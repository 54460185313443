export const CHANGE_LANGUAGE = 'change_language';
export const UPDATE_USER_ORGANIZATION = 'update_user_organization';
export const ENABLE_LOCALE_DEBUG = 'enable_locale_debug';
export const DISABLE_LOCALE_DEBUG = 'disable_locale_debug';
export const CHANGE_IS_PARTNER = 'change_is_partner';
export const UPDATE_USER_SETTINGS = 'update_user_setting';
export const UPDATE_USER_GOOGLE_CONNECTIONS = 'update_user_google_connections';
export function updateUserSettings(userSettings) {
  return {
    type: UPDATE_USER_SETTINGS,
    userSettings,
  };
}

export function updateUserOrganization(payload) {
  return {
    type: UPDATE_USER_ORGANIZATION,
    payload,
  };
}

export function changeIsPartner(isPartner) {
  return {
    type: CHANGE_IS_PARTNER,
    isPartner,
  };
}
export function setLocaleDebug(enable = true) {
  return {
    type: enable ? ENABLE_LOCALE_DEBUG : DISABLE_LOCALE_DEBUG,
  };
}
export function changeLanguage(language) {
  return {
    type: CHANGE_LANGUAGE,
    language,
  };
}
export function updateUserGoogleConnections(googleConnections) {
  return {
    type: UPDATE_USER_GOOGLE_CONNECTIONS,
    googleConnections,
  };
}
