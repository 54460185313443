import { getKeywordFiltersCombo } from 'Components/Modal/Content/Shortcuts';
import { FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import type { DomainsFilter } from 'Types/Filter';
import { t } from 'Utilities/i18n';
import DomainsIcon from 'icons/mobile-devices.svg?inline';
import DomainsEditor from './';
import DomainsLabel from './DomainsLabel';

const defaultValue: DomainsFilter = {
  attribute: FilterAttribute.DOMAINS,
  type: FilterValueType.LIST,
  comparison: FilterComparison.CONTAINS,
  value: [],
};

const getData = () => ({
  defaultValue,
  title: t('Domains'),
  icon: DomainsIcon,
  keyboardCombo: getKeywordFiltersCombo(),
  editor: DomainsEditor,
  labelFunc: (filter: DomainsFilter) => <DomainsLabel domainIds={filter.value as any} />,
});

export default getData;
