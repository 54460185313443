import React from 'react';
import AccTooltip from 'Components/AccTooltip/AccTooltip';

type Props = {
  value: {
    data: {
      displayName: string;
      domain: string;
      youtubeChannelName: string;
    };
    loadingData: boolean;
  };
};

const DomainCell = (props: Props) => {
  const data = props?.value?.data;
  const resultName = data?.displayName || data?.domain || data?.youtubeChannelName || '';
  return (
    <React.Fragment>
      <AccTooltip tooltip={resultName}>
        <div className="accu_table-cell_domain">{resultName}</div>
      </AccTooltip>
    </React.Fragment>
  );
};

export default DomainCell;
