import { TABLE_ICON_SIZE } from 'Components/AccuTable/constants';
import {
  COMMERCIAL,
  INFORMATIONAL,
  NAVIGATIONAL,
  TRANSACTIONAL,
} from 'Components/Modal/Content/AddSearchEngine/types';
import { t } from 'Utilities/i18n';
import SearchIntentIcon from 'icons/search-intent/searchIntentIcon';

export const getItems = () => {
  return [
    {
      value: INFORMATIONAL,
      label: t('Informational'),
      Icon: () => <SearchIntentIcon searchIntent={INFORMATIONAL} size={TABLE_ICON_SIZE} />,
    },
    {
      value: NAVIGATIONAL,
      label: t('Navigational'),
      Icon: () => <SearchIntentIcon searchIntent={NAVIGATIONAL} size={TABLE_ICON_SIZE} />,
    },
    {
      value: COMMERCIAL,
      label: t('Commercial'),
      Icon: () => <SearchIntentIcon searchIntent={COMMERCIAL} size={TABLE_ICON_SIZE} />,
    },
    {
      value: TRANSACTIONAL,
      label: t('Transactional'),
      Icon: () => <SearchIntentIcon searchIntent={TRANSACTIONAL} size={TABLE_ICON_SIZE} />,
    },
  ];
};
