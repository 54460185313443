import { Component } from 'react';
import { connect } from 'react-redux';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import compose from 'lodash/flowRight';
import { Field, reduxForm } from 'redux-form';
import { hideModal } from 'Actions/ModalAction';
import AccButton from 'Components/AccButton/AccButton';
import { Checkbox, Select } from 'Components/Forms/Fields';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import toast from 'Hooks/useToast';
import { throwNetworkError, throwSubmitErrors } from 'Utilities/errors';
import { t } from 'Utilities/i18n';
import Validator from 'Utilities/validation';

type Props = {
  hideModal: (...args: Array<any>) => any;
  handleSubmit: (...args: Array<any>) => any;
  organizationId: number;
  invalid?: any;
  submitting?: any;
  addDemoContentMutation?: any;
};

class SalesDemoContent extends Component<Props> {
  handleSubmit = ({ organizations, isHidden }) => {
    const input = {
      organizationId: this.props.organizationId,
      organizations: organizations.map((org) => org.value),
      isHidden,
    };
    return this.props
      .addDemoContentMutation({
        variables: {
          input,
        },
      })
      .then(
        ({
          data: {
            addDemoContent: { errors },
          },
        }) => {
          if (errors.length > 0) {
            throwSubmitErrors(errors);
          } else {
            toast.success(t('Content added'));
            this.props.hideModal();
          }
        },
        throwNetworkError,
      );
  };

  render() {
    const { invalid, submitting } = this.props;
    const organizationOptions = [
      {
        label: 'Ecommerce/GDS Demo',
        value: 18140,
      },
      {
        label: 'Brand Demo',
        value: 18139,
      },
    ];
    return (
      <ModalBorder title={t('Add Demo Organizations')} onClose={this.props.hideModal}>
        <form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
          <div className="form-label required">{t('Organizations')}</div>
          <Field
            multi
            defaultBehaviour
            name="organizations"
            component={Select}
            validate={[Validator.required]}
            options={organizationOptions}
          />

          <div className="form-label">{t('Only visible using API')}</div>
          <Field defaultBehaviour name="isHidden" component={Checkbox} />

          <div className="footer">
            <AccButton variant="primary" type="submit" disabled={invalid || submitting}>
              {t('Save')}
            </AccButton>
          </div>
        </form>
      </ModalBorder>
    );
  }
}

const addDemoContentMutation = gql`
  mutation ($input: AddDemoContentInput!) {
    addDemoContent(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;
const SalesDemoContentForm = reduxForm({
  form: 'SalesDemoContentForm',
})(SalesDemoContent);
export default compose(
  graphql(addDemoContentMutation, {
    name: 'addDemoContentMutation',
  }),
  connect(null, {
    hideModal,
  }),
)(SalesDemoContentForm);
