import Skeleton, { LineConfig } from 'Components/Skeleton';

const commonLines: LineConfig[] = [
  {
    type: 'title',
    options: {
      width: '60%',
    },
  },
  {
    type: 'subtitle',
    options: {
      width: '70%',
    },
  },
  {
    type: 'spacer-underline',
  },
  {
    type: 'text',
  },
];

const registerLines: LineConfig[] = [
  {
    type: 'text',
    options: {
      width: '75%',
    },
  },
  {
    type: 'text',
    options: {
      width: '60%',
    },
  },
  {
    type: 'text',
    options: {
      width: '75%',
    },
  },
  {
    type: 'text',
  },
  {
    type: 'text',
  },
  {
    type: 'text',
  },
  {
    type: 'text',
  },
  {
    type: 'text',
    options: {
      width: '75%',
    },
  },
  {
    type: 'text',
    options: {
      width: '55%',
    },
  },
];

const otherLines: LineConfig[] = [
  {
    type: 'text',
  },
  {
    type: 'text',
  },
  {
    type: 'text',
  },
  {
    type: 'text',
  },
  {
    type: 'text',
    options: {
      width: '55%',
    },
  },
];

type Props = {
  isRegister: boolean;
};

const OrderWidgetSkeleton = ({ isRegister }: Props): JSX.Element => (
  <Skeleton linesConfig={[...commonLines, ...(isRegister ? registerLines : otherLines)]} />
);

export default OrderWidgetSkeleton;
