import { useMantineTheme } from '@mantine/core';

export const withMantineTheme = (Component) => {
  const ResultComponent = (props) => {
    const mantineTheme = useMantineTheme();
    return <Component {...props} mantineTheme={mantineTheme} />;
  };

  const displayName = Component.displayName || Component.name || 'Component';
  ResultComponent.displayName = `withMantineTheme(${displayName})`;

  return ResultComponent;
};
