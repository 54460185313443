import { Group } from '@mantine/core';
import { IconArrowsMaximize, IconArrowsMinimize } from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import { t } from 'Utilities/i18n';
import { useComparisonContext } from '../useComparisonContext';

const CollapseButtons = () => {
  const { expandAll, collapseAll } = useComparisonContext();
  return (
    <Group justify="stretch" gap="xs">
      <AccButton
        variant="secondary"
        flex="1"
        size="xs"
        pr="xs"
        radius="sm"
        leftSection={<IconArrowsMaximize size={18} />}
        onClick={expandAll}
      >
        {t('Expand all')}
      </AccButton>
      <AccButton
        variant="secondary"
        flex="1"
        size="xs"
        pr="xs"
        radius="sm"
        leftSection={<IconArrowsMinimize size={18} />}
        onClick={collapseAll}
      >
        {t('Collapse all')}
      </AccButton>
    </Group>
  );
};

export default CollapseButtons;
