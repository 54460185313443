import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { IconCirclePlus } from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import AccessControl from 'Components/AccessControl';
import Breadcrumbs from 'Components/Breadcrumbs/Breadcrumbs';
import SearchInput from 'Components/SearchInput';
import ActionbarContainer from 'Pages/Layout/ActionsMenu/components/ActionbarContainer';
import { t } from 'Utilities/i18n';
import * as Mousetrap from 'Utilities/mousetrap';
import { isMac } from 'Utilities/underdash';
import reusableStyles from 'css/reusable-styles.module.scss';
import { getSearchCombo } from '../../Components/Modal/Content/Shortcuts';
import ReportTemplatesTable from './ReportTemplatesTable';
import './report-templates-overview.scss';

type State = {
  searchTerm: string;
  isLoading: boolean;
};

const initialState = {
  searchTerm: '',
  isLoading: false,
};

const ReportTemplatesOverview = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [{ searchTerm, isLoading }, setState] = useState<State>(initialState);

  useEffect(() => {
    const shortcut = isMac() ? 'command+f' : 'ctrl+f';
    Mousetrap.bind(shortcut, '', () => {
      return inputRef?.current?.focus() || false;
    });
    return () => {
      Mousetrap.unbind(['command+f', 'ctrl+f']);
    };
  }, []);

  const handleChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      searchTerm: (event.target as HTMLInputElement).value,
    }));
  };

  const handleLoading = (loading: boolean) =>
    setState((prevState) => ({
      ...prevState,
      isLoading: loading,
    }));

  return (
    <>
      <Breadcrumbs />
      <ActionbarContainer mt="md">
        <AccessControl>
          <Link key="new" to="/reports/templates/builder">
            <AccButton variant="primary" leftSection={<IconCirclePlus size={18} />}>
              {t('New template')}
            </AccButton>
          </Link>
        </AccessControl>
        <SearchInput
          ref={inputRef}
          key="search-input"
          value={searchTerm}
          disabled={isLoading}
          onChange={handleChange}
          placeholder={t('Reports…')}
          keyboardShortcut={getSearchCombo()}
          autoFocus
        />
      </ActionbarContainer>
      <div className={reusableStyles.paper}>
        <ReportTemplatesTable searchTerm={searchTerm} handleLoading={handleLoading} />
      </div>
    </>
  );
};

export default ReportTemplatesOverview;
