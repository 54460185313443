import React, { memo } from 'react';
import cn from 'classnames';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import CompetitorPositionDropdownCell from 'Pages/Keywords/Groupings/ViewMode/components/cells/CompetitorPositionDropdownCell';
import styles from 'Pages/Keywords/Groupings/ViewMode/components/cells/leadercell.module.scss';
import { propsIsEqualComparison } from 'Utilities/compare';
import { getFaviconByDomain } from 'Utilities/favicon';
import { t } from 'Utilities/i18n';
import { FolderCellProps } from '../../utils/useFoldersTableConfig';

const CompetitorPositionCell = (props: FolderCellProps) => {
  const { classes, rootNode } = props;

  const { displayNameMap } = useQueryDomainInfo();
  const domain = props.rootNode.top_ten_competitors?.[0]?.domain;

  const positionIsNotNA =
    rootNode.dynamic_competitor_position && rootNode.dynamic_competitor_position !== '?';

  const position = positionIsNotNA ? `#${rootNode.dynamic_competitor_position}` : 'N/A';

  const tooltip = positionIsNotNA
    ? t('Your position (click to show more)')
    : t('Your dynamic competitor score is 0 (click to show more)');

  const target = domain ? (
    <AccTooltip tooltip={tooltip}>
      <div className={styles.wrapper}>
        <img
          src={getFaviconByDomain(props.rootNode.top_ten_competitors[0].domain)}
          alt=""
          className={styles.favicon}
        />
        <div className={styles.displayName}>
          {(displayNameMap && displayNameMap[domain || '']) || domain || ''}
        </div>
        <div className={cn(classes.rightTextAlign, 'table-link-like', styles.minWidth25)}>
          {position}
        </div>
      </div>
    </AccTooltip>
  ) : (
    <span />
  );

  return <CompetitorPositionDropdownCell rootNode={rootNode} target={target} />;
};

export default memo(CompetitorPositionCell, propsIsEqualComparison);
