import useUserPermission from 'Hooks/useUserPermission';

/**
 * Renders the children components based on the user's permission.
 * If `withSuperuserPermission` is set to `true`, it checks if the user has write access and is a master.
 * If `withSuperuserPermission` is set to `false` or not provided, it only checks if the user has write access.
 *
 * @param children - The components to be rendered if the user has the required permission.
 * @param withSuperuserPermission - Optional. If `true`, checks if the user has write access and is a master. Defaults to `false`.
 * @returns The rendered components if the user has the required permission, otherwise `null`.
 */
const AccessControl = ({
  children,
  withAdminPermission = false,
  withSuperuserPermission = false,
}: {
  children: React.ReactNode;
  withAdminPermission?: boolean;
  withSuperuserPermission?: boolean;
}): JSX.Element => {
  const { userHasWriteAccess, isAdmin, isSuperuser } = useUserPermission();
  if (withAdminPermission) return <>{isAdmin ? children : null}</>;
  if (withSuperuserPermission) return <>{isAdmin || isSuperuser ? children : null}</>;
  return <>{userHasWriteAccess ? children : null}</>;
};

export default AccessControl;
