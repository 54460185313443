/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { hideModal } from 'Actions/ModalAction';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import { defaultRole } from 'Constants/workspaces';
import { useGetUserByIdQuery } from 'Ghql';
import { useUser } from 'Hooks/data/user/useUser';
import { EventName, TrackingKey, useMixpanel } from 'Utilities/Analytics/mixpanel';
import { t } from 'Utilities/i18n';
import AddEditUserForm, { UserInitialValues } from '../AddEditUserForm';
import { useUpdateUser } from './useUpdateUser';

type Props = {
  refresh: (...args: Array<any>) => any;
  id: string;
};

/**
 * EditUser component for editing user details.
 *
 * @param refresh - A function to refresh the user list after editing a user.
 * @param id - The ID of the user being edited.
 * @returns The JSX element representing the modal content for editing a existing user.
 */
const EditUser = ({ refresh, id }: Props) => {
  const trackEvent = useMixpanel();

  useEffect(() => {
    trackEvent(EventName.UsersPage, {
      'Tracking key': TrackingKey.OpenEditModal,
    });
  }, []);

  const user = useUser();
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(hideModal());
  };

  const handleSubmit = useUpdateUser({ id, refresh, onClose });

  const { data, loading, error } = useGetUserByIdQuery({
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
  });

  /**
   * Initial values for the user form.
   */
  const initialValues: UserInitialValues = useMemo(
    () => ({
      fullName: data?.user?.fullName,
      email: data?.user?.email,
      userType: data?.user?.userType || defaultRole,
      workspaces: (data?.user?.workspaces?.map((role) => role?.id).filter(Boolean) ||
        []) as string[],
    }),
    [
      data?.user?.email,
      data?.user?.fullName,
      data?.user?.userType,
      data?.user?.workspaces?.toString(),
    ],
  );

  return (
    <ModalBorder title={t('Edit User')} onClose={onClose}>
      <AddEditUserForm
        onClose={onClose}
        handleSubmit={handleSubmit}
        initialValues={initialValues}
        loading={loading}
        error={error}
        disableUserRole={user.id === id}
        type="edit"
      />
    </ModalBorder>
  );
};

EditUser.defaultProps = {
  refresh: () => {},
};

export default EditUser;
