import { Component } from 'react';
import { IconArrowNarrowRight } from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import { t } from 'Utilities/i18n/index';
import './element-type.scss';

type Props = {
  type: string;
  title: string;
  description: string;
  onAdd: (...args: Array<any>) => any;
};

class ElementType extends Component<Props> {
  addHandler = () => {
    this.props.onAdd(this.props.type);
  };

  render() {
    const { title, description } = this.props;
    return (
      <div className="element-type">
        <div className="title">{title}</div>
        <div className="description">{description}</div>
        <div className="actions">
          <AccButton
            onClick={this.addHandler}
            rightSection={<IconArrowNarrowRight size={18} />}
            variant="secondary"
          >
            {t('Add')}
          </AccButton>
        </div>
      </div>
    );
  }
}

export default ElementType;
