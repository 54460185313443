import { CSSProperties, MouseEventHandler, useRef } from 'react';
import type { FC } from 'react';
import cn from 'classnames';
import styles from 'Components/Chart/ChartContainer/chart-container.module.scss';
import ExportButton from 'Components/ExportButton';
import Watermark from 'Components/Watermark';
import { CHART_HEIGHT } from '../LineChart/support/constants';

type Props = {
  height?: number;
  noPadding?: boolean;
  style?: CSSProperties;
  onClick?: MouseEventHandler<HTMLElement>;
  className?: string;
  exportButton?: boolean;
  fileName?: string;
  sparklineMode?: boolean;
};

export const ChartContainer: FC<React.PropsWithChildren<Props>> = ({
  children,
  className,
  onClick,
  height,
  noPadding,
  style,
  exportButton = true,
  fileName,
  sparklineMode = false,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  return (
    <div
      className={cn(styles.chart, className)}
      style={{ minHeight: `${height ?? CHART_HEIGHT}px` }}
    >
      <div
        className={cn(styles.chartArea, 'chart-content')}
        ref={containerRef}
        style={{ paddingTop: noPadding ? '' : '20px', ...style }}
        onClick={onClick}
      >
        {exportButton && <ExportButton content={() => containerRef.current} fileName={fileName} />}
        {!sparklineMode && <Watermark big offset />}
        {children}
      </div>
    </div>
  );
};
