import { SelectItem } from 'Components/AccSelect';
import { getUserTypeDescription, getUserTypeLabel } from 'Utilities/workspaces';

export type UserRole = 1 | 2 | 3 | 4 | number;

export const adminRole: UserRole = 1;
export const superuserRole: UserRole = 2;
export const writeUserRole: UserRole = 3;
export const readUserRole: UserRole = 4;
export const defaultRole: UserRole = readUserRole;

export const getAllUserRoleOptions: () => SelectItem[] = () =>
  [adminRole, superuserRole, writeUserRole, readUserRole].map((role: UserRole) => ({
    value: role,
    label: getUserTypeLabel(role),
    caption: getUserTypeDescription(role),
  }));

// eslint-disable-next-line import/no-unused-modules
export const allGroupsId = '1';
// eslint-disable-next-line import/no-unused-modules
export const allGroupsUserRoles: UserRole[] = [adminRole, superuserRole];
// eslint-disable-next-line import/no-unused-modules
export const hasWriteAccess: UserRole[] = [adminRole, superuserRole, writeUserRole];
