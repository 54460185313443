import AccTooltip from 'Components/AccTooltip/AccTooltip';
import FormatNumber from 'Components/FormatNumber/new';
import { SEARCH_TYPE_NAVER, SearchEngineIds } from 'Components/Modal/Content/AddSearchEngine/types';
import { t } from 'Utilities/i18n';
import Cell from './HelperComponents/AccuCell';
import NaCell from './helpers/NaCell';

type Props = {
  value: {
    data: {
      searchVolumeValue: number;
      shareOfVoiceCpcValue: number;
      aiTrafficValue: number;
      validForSearchVolume: boolean;
      updatingKeyword: boolean;
      avgCostPerClickCurrency: string;
      searchEngineId: number;
    };
    loadingData: boolean;
  };
  useAi?: boolean;
};

const ShareOfVoiceCPCCell = (props: Props) => {
  const {
    value: { data },
  } = props;

  const { aiTrafficValue, shareOfVoiceCpcValue, validForSearchVolume, avgCostPerClickCurrency } =
    data;

  if (!validForSearchVolume) {
    return <NaCell tooltip={t('No Traffic Value for this date.')} align={'right'} />;
  }

  if (data?.searchEngineId === SearchEngineIds[SEARCH_TYPE_NAVER]) {
    return <NaCell tooltip={t('No Traffic Value for this search engine.')} align={'right'} />;
  }

  return (
    <Cell className={'accu-table-cell__share-of-voice-cpc u-text-elipsis'} rightAligned>
      <FormatNumber
        value={props.useAi ? aiTrafficValue : shareOfVoiceCpcValue}
        renderProps
        minimumFractionDigits={0}
        maximumFractionDigits={0}
      >
        {(value) => (
          <AccTooltip tooltip={`${value} ${avgCostPerClickCurrency}`}>
            <div>{value}</div>
          </AccTooltip>
        )}
      </FormatNumber>
    </Cell>
  );
};

export default ShareOfVoiceCPCCell;
