import { useState } from 'react';
import { useRequestPasswordResetMutation } from 'Ghql';
import { t } from 'Utilities/i18n';
import { extractFormErrors } from '../../../Hooks/Graphql/helpers';
import AuthPageContainer from '../Shared/AuthPageContainer/AuthPageContainer';
import { ResetPasswordForm, ResetPasswordFormValues } from './components/ResetPasswordForm';
import { SuccessResetPassword } from './components/SuccessResetPassword';

export default function ResetPasswordPage() {
  const [isSent, setIsSent] = useState(false);
  const [resetPassword] = useRequestPasswordResetMutation();

  const requestPasswordReset = async (form: ResetPasswordFormValues) => {
    const res = await resetPassword({
      variables: {
        input: form,
      },
    });
    const formErrors = extractFormErrors(res);

    if (formErrors) {
      return formErrors;
    }

    setIsSent(true);
  };

  return (
    <AuthPageContainer heroText={t('The World\'s Fastest & Most Accurate Rank Tracker')}>
      {!isSent ? <ResetPasswordForm onSubmit={requestPasswordReset} /> : <SuccessResetPassword />}
    </AuthPageContainer>
  );
}
