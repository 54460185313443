import React, { useEffect, useState } from 'react';
import Checkbox, { CheckboxKind } from 'Components/Controls/Checkbox';
import { useRowContext } from 'Components/DataTable';

// eslint-disable-next-line import/no-unused-modules
export type CheckboxCellProps = {
  value: {
    data: { id: number; starred?: boolean; selected?: boolean; version?: any };
  };
  disabled?: boolean;
  onChange?: (...args: any) => void;
  type?: CheckboxKind;
  checkedDisabled?: boolean;
};

const CheckboxCell = ({ type, onChange, disabled, value, checkedDisabled }: CheckboxCellProps) => {
  const id = value.data?.id;
  const isStar = type === 'star';

  //_checked is the initial state - will not change on re-render as the component is wrapped in memo
  const _checked = isStar ? value.data.starred : value.data.selected;

  const { userHasWriteAccess } = useRowContext();
  const [checked, setChecked] = useState(_checked);

  const restricted = !userHasWriteAccess;

  useEffect(() => {
    if (checked !== _checked) {
      setChecked(_checked);
    }
  }, [_checked, value.data.version]);

  return (
    <Checkbox
      className={'accu-table-cell__checkbox'}
      kind={type}
      disabled={restricted || checkedDisabled ? true : disabled}
      checked={checkedDisabled ? true : !!checked}
      tooltip={restricted ? 'You do not have permission to edit this' : undefined}
      onChange={(val) => {
        setChecked(val.target.checked);
        onChange?.(id, val.target.checked);
      }}
    />
  );
};

export default React.memo(CheckboxCell);
