import { useEffect } from 'react';
import { DataTable, useTableStore } from 'Components/DataTable';
import { useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import { useDisplayCurrency } from 'Hooks/displayCurrency';
import { useValueStore } from 'Hooks/mobx/useValueStore';
import { DEFAULT_LANDING_ORDERING, LANDING_TABLE_ID } from './support/constants';
import { selectFirstLandingElement } from './support/helpers';
import { useFetchLandingTableData, useLandingTableInfo } from './support/hooks';
import reusableStyles from '../../../../../css/reusable-styles.module.scss';

/**
 * Selects the first row in the table if there are no selected rows
 * This is needed because DataTable initialRowSelect is deprecated and also selects on pagination, which is not desired
 */
const useSelectDefaultRow = () => {
  const tableStore = useTableStore(LANDING_TABLE_ID);
  const tableData = useValueStore(tableStore, 'data');
  const initialRow = selectFirstLandingElement(tableData);

  useEffect(() => {
    if (initialRow && !tableStore?.selectedRows?.length) {
      tableStore?.toggleRowSelected(initialRow);
    }
  }, [initialRow, tableData, tableStore]);
};

export const KeywordLandingTable = () => {
  const { domainInfo } = useQueryDomainInfo();
  const { displayCurrency, loading } = useDisplayCurrency(true);
  const fetchData = useFetchLandingTableData(loading, displayCurrency);
  const { columns } = useLandingTableInfo();
  useSelectDefaultRow();

  return (
    <DataTable
      className={reusableStyles.paper}
      tableId={LANDING_TABLE_ID}
      columns={columns}
      fetchData={fetchData}
      defaultOrdering={DEFAULT_LANDING_ORDERING}
      key={domainInfo?.id}
      unmountStore
    />
  );
};
