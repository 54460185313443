import * as React from 'react';

const withProps =
  (newProps: Record<string, any>) =>
  <ComponentProps extends {}>(
    Component: React.ComponentType<React.PropsWithChildren<ComponentProps>>,
  ) => {
    const ResultComponent = (props: Partial<ComponentProps>) =>
      (Component ? <Component {...(props as any)} {...newProps} /> : null);
    ResultComponent.displayName = `HOCWithProps(${
      ResultComponent?.displayName || ResultComponent?.name || 'Component'
    })`;

    return ResultComponent;
  };

export default withProps;
