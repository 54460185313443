import React, { useCallback, useRef } from 'react';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import cn from 'classnames';
import times from 'lodash/times';
import Loader from 'Components/Loader';
import { STICKY_TREE_TABLE_HEADER_WITH_ADD } from 'Constants/sticky';
import { t } from 'Utilities/i18n';
import { StickyVerticalScroll } from './components/StickyVerticalScroll';
import { RowRenderType, TableRow } from './components/TableRow';
import { ROW_SIZE, VIRTUALIZED_TABLE_ID } from './support/constants';
import { getStoreKey } from './support/helpers';
import { useTableMetaInfo } from './support/hooks/useTableItemsPositions';
import { useTableElementsPositionScrollSync } from './support/hooks/useTablePositionScroll';
import styles from './virtualizedTable.module.scss';

const BottomWrapper = ({
  children,
  wrapper,
}: {
  children: React.ReactNode;
  wrapper: React.ElementType;
}) => {
  if (wrapper) {
    const Component = wrapper;
    return <Component topStickyOffset={STICKY_TREE_TABLE_HEADER_WITH_ADD}>{children}</Component>;
  }
  return <>{children}</>;
};

type VirtualizedTableProps<T extends object> = {
  data?: T[];
  rowRender: RowRenderType<T>;
  headerRow: JSX.Element;
  placeholderContent?: JSX.Element | null;
  loading?: boolean;
  onToggle?: (item: T, config?: any) => void;
  topOffset?: string | number;
  bottomWrapper?: any;
  disableBodyOnScroll?: boolean;
};

/**
 * Table with virtualization:
 * - sticky header, columns,
 * - sticky bottom scroll bar
 * - global window scroll
 * @docs
 * [Documentation](./VirtualizedTable.md)
 */
export const VirtualizedTable = <T extends object = any>({
  data,
  rowRender,
  onToggle,
  headerRow,
  placeholderContent,
  loading,
  topOffset,
  bottomWrapper,
  disableBodyOnScroll = true,
}: VirtualizedTableProps<T>) => {
  const stickyListRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLTableElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);

  const itemsCount = data?.length ?? 0;
  const { itemsPerScreen, itemsPositions } = useTableMetaInfo(itemsCount, containerRef);

  useTableElementsPositionScrollSync(
    itemsPerScreen,
    containerRef,
    itemsCount,
    listRef,
    disableBodyOnScroll,
  );

  const handleShadow = useCallback((el: Element) => {
    const hasScrollLeft = el?.scrollLeft > 5;

    if (hasScrollLeft) {
      wrapperRef.current?.setAttribute('data-table-scroll-left-active', 'true');
    } else {
      wrapperRef.current?.removeAttribute('data-table-scroll-left-active');
    }
  }, []);

  const rowWidth = headerRef.current?.getBoundingClientRect?.()?.width ?? 0;

  return (
    <div ref={wrapperRef}>
      <ScrollSync vertical onSync={handleShadow}>
        <>
          <ScrollSyncPane>
            <div
              className={cn(styles.header, styles.noScrollbar)}
              style={{ top: topOffset ?? '0px' }}
            >
              <div className={styles.headerRowContainer} ref={headerRef}>
                {headerRow}
              </div>
            </div>
          </ScrollSyncPane>
          {loading ? (
            <Loader noBackdrop className={styles.loader} loadingText={t('Loading…')} />
          ) : null}
          {placeholderContent ??
            (!loading ? (
              <>
                <div
                  style={{
                    height: itemsCount * ROW_SIZE,
                    minHeight: (loading && !itemsCount) || itemsCount === 0 ? '300px' : undefined,
                  }}
                  className={styles.container}
                  id={VIRTUALIZED_TABLE_ID}
                  ref={containerRef}
                >
                  <ScrollSyncPane>
                    <div
                      className={cn(styles.listContainer, styles.noScrollbar)}
                      ref={stickyListRef}
                    >
                      <div
                        style={{ height: ROW_SIZE * itemsPerScreen }}
                        className={styles.listGroup}
                        ref={listRef}
                      >
                        {times(itemsPerScreen).map((_, i) => {
                          const item = itemsPositions[i];
                          return (
                            <TableRow<T>
                              key={i}
                              staticIndex={i}
                              rowRender={rowRender}
                              data={data ?? []}
                              onToggle={onToggle}
                              className={styles.listItem}
                              propsIndex={item?.index}
                              storeKey={getStoreKey(itemsPerScreen, itemsCount)}
                              topPosition={item?.top}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </ScrollSyncPane>
                </div>
                <BottomWrapper
                  wrapper={bottomWrapper}
                  {...(bottomWrapper ? { topStickyOffset: STICKY_TREE_TABLE_HEADER_WITH_ADD } : {})}
                >
                  <StickyVerticalScroll width={rowWidth} />
                </BottomWrapper>
              </>
            ) : null)}
        </>
      </ScrollSync>
    </div>
  );
};
