import { formatDisplayNumber } from 'Components/FormatNumber/formatNumberHelper';
import { t, tn } from 'Utilities/i18n';
import { TableStoreType } from '../../../store/TableStore';

export const getIsAllSelected = (tableStore: TableStoreType) => {
  return (
    !tableStore.unSelectedRows?.length &&
    (tableStore?.isAllSelected ||
      (tableStore?.isAllPageSelected && tableStore?.data.length >= tableStore.totalDataLength))
  );
};

export const getShowSelectAll = (tableStore: TableStoreType) => {
  return (
    tableStore?.isAllPageSelected &&
    tableStore?.totalDataLength > tableStore?.data?.length &&
    !tableStore?.unSelectedRows?.length
  );
};

export const getSelectButtonText = (
  hasVisibleFilter: boolean,
  maxSelectCount: number | undefined,
  hasMaxSelect: boolean,
) => {
  const maxSelectable = maxSelectCount || 0;
  if (hasMaxSelect) {
    return hasVisibleFilter
      ? t('Select first %s keywords that match the filter.', formatDisplayNumber(maxSelectable))
      : t('Select first %s keywords.', formatDisplayNumber(maxSelectable));
  }

  return hasVisibleFilter
    ? t('Select all %s keywords that match the filter.', formatDisplayNumber(maxSelectable))
    : t('Select all %s keywords.', formatDisplayNumber(maxSelectable));
};

export const getSelectedTextInfo = (
  totalSelectedCount: number,
  hasVisibleFilter: boolean,
  hasMaxSelect?: boolean,
) => {
  if (hasMaxSelect) {
    return hasVisibleFilter
      ? tn(
          'Selected the one keyword that match the filter.',
          'Selected %s keywords that match the filter.',
          totalSelectedCount,
        )
      : tn('Selected 1 keyword.', 'Selected %s keywords.', totalSelectedCount);
  }

  return hasVisibleFilter
    ? tn(
        'Selected the one keyword that match the filter.',
        'Selected all %s keywords that match the filter.',
        totalSelectedCount,
      )
    : tn('Selected 1 keyword.', 'Selected all %s keywords.', totalSelectedCount);
};
