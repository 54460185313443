import cn from 'classnames';
import AccText from 'Components/Text/AccText';
import { PausedDomainFragment } from 'Ghql';
import styles from 'Pages/Domains/DomainsTable/domains-table.module.scss';

type Props = {
  record: PausedDomainFragment;
};

const GroupCell = (props: Props) => {
  const { client } = props.record;

  return (
    <div className={cn(styles.domainsCellContainer, styles.textCell)}>
      <AccText>{client.name}</AccText>
    </div>
  );
};

export default GroupCell;
